import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';

export const getWeeklyReport = async (): Promise<{ locations: string[] }> =>
    fetch<{ locations: string[] }>(`/user/weekly-report`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const updateWeeklyReport = async (locationIds: string[]): Promise<void> =>
    fetch<void>(`/user/weekly-report`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ locationIds }),
    });
