import { WebhookEventTypes } from '../../../models/common';

export const filterWebhookEventTypes = (includeManually = false): WebhookEventTypes[] => {
    const filterManuallyTriggered = (type: WebhookEventTypes): boolean =>
        type !== WebhookEventTypes.manuallyTriggeredTest ||
        (type === WebhookEventTypes.manuallyTriggeredTest && includeManually);

    return Object.values(WebhookEventTypes).filter(type => filterManuallyTriggered(type));
};

export default filterWebhookEventTypes;
