import { v4 as uuidv4 } from 'uuid';
import { OutdoorSensorData } from 'commons/src/models/commonTypeScript';
import {
    FETCH_OUTDOOR_INSIGHT_DATA_SUCCESS,
    FetchOutdoorInsightData,
    FetchOutdoorInsightDataSuccess,
    InsightActionType,
} from '../actions/outdoorInsightActions';

export type OutdoorInsightState = {
    outdoorData: {
        history: { [sensorType: string]: OutdoorSensorData };
    };
    reportId?: string;
};

const initialState: OutdoorInsightState = {
    outdoorData: {
        history: {},
    },
    reportId: undefined,
};

const { FetchOutdoorInsightDataInit } = InsightActionType;

export default (
    state: OutdoorInsightState = initialState,
    action: FetchOutdoorInsightDataSuccess | FetchOutdoorInsightData
): OutdoorInsightState => {
    switch (action.type) {
        case FetchOutdoorInsightDataInit:
            return {
                ...state,
                reportId: undefined,
            };
        case FETCH_OUTDOOR_INSIGHT_DATA_SUCCESS:
            return {
                ...state,
                outdoorData: {
                    history: {
                        pressure: action.payload.history.pressure,
                        humidity: action.payload.history.humidity,
                        temp: {
                            data: action.payload.history.temperature.data,
                            unit: action.payload.history.temperature.unit,
                            weatherIcons: action.payload.history.temperature.weatherIcon,
                        },
                    },
                },
                reportId: uuidv4(), // this should be generated backend
            };
        default:
            return state;
    }
};
