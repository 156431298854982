/* eslint max-len: ["error", 15000] */
import React from 'react';

const RadonInsightIllustration = (): React.ReactElement => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        width="596px"
        height="300px"
        x="0px"
        y="0px"
        viewBox="0 0 596 302.5"
    >
        <rect x="105.2" y="94.6" fill="#FFFFFF" width="177.2" height="106.2" />
        <path
            fill="#42515A"
            d="M105.1,94.5c-0.2,0.1-0.2,0.6-0.3,0.7c0,0,0,0.1,0,0.2c0,0.2,0-0.3,0,0c0,0.1,0,0.2,0,0.3
            c0,0.2,0,0.3,0,0.4c0,0.3-0.1,0.8-0.1,1.2c0,0.5-0.1,1-0.1,1.4c0,0.3,0,0.6-0.1,0.8c0,0.1,0,0.2,0,0.3c0-0.3,0,0.1,0,0.2
            c0,0.2,0,0.3,0,0.5c0,1.4-0.2,2.9-0.3,4.3c-0.2,3.7-0.2,7.4-0.3,11.2c-0.1,4.5-0.2,9.1-0.3,13.7c0,5,0,10.1,0,15
            c0,5,0,10.2,0,15.3c0,4.8,0.1,9.7,0.2,14.4c0.1,4.1,0,8.2,0,12.3c0,3,0,6.1,0,9.1c0,1,0,2,0,3c0,0.3,0,0.7,0,1.1
            c0,0.4,0.2,0.8,0.3,1.1c0,0.2,0.1,0.3,0,0c0.1,0.3,0.2,0.4,0.3,0.5l-0.3-0.5c0,0.1,0-0.2,0-0.1c0.1-0.3,0.3-0.5,0.7-0.4
            c0.1,0,0.2,0,0.3,0.1c-0.7-0.5-1.3,0.6-0.7,1c0.5,0.3,0.9-0.2,0.9-0.6l-0.4,0.6c0.6-0.2,0.9-1.3,1-1.8c0-0.8,0-1.6,0-2.4
            c0-2.7,0.1-5.5,0.1-8.1c0-3.9,0-7.7,0.1-11.6c0-4.7,0.3-9.3,0.3-14c0-5,0-10.1,0-15.1s0-10.1,0-15.2c0-4.7,0-9.4-0.1-14.1
            l-0.3-11.9c0-1.6-0.1-3.2-0.2-4.8c0-0.7-0.1-1.3-0.1-2c0-0.2,0-0.3,0-0.4s0-0.2,0-0.3s0-0.3,0-0.3c0-0.3,0-0.6-0.1-0.8
            c-0.1-0.9-0.2-1.8-0.2-2.7v-0.5c0-0.1,0-0.2,0-0.3c0,0-0.1-0.2,0-0.1c0,0.1,0,0,0,0s0-0.1-0.1-0.1c0-0.2-0.1-0.3-0.1-0.5
            C105.2,94.5,105.3,94.5,105.1,94.5C105.2,94.6,105.2,94.5,105.1,94.5C105.2,94.5,105.2,94.5,105.1,94.5L105.1,94.5z"
        />
        <path
            fill="#42515A"
            d="M282.5,94.5c-0.2,0.1-0.2,0.6-0.2,0.7c0,0,0,0.1,0,0.2c0,0.2,0-0.3,0,0c0,0.1,0,0.2,0,0.3
            c0,0.2,0,0.3,0,0.4l-0.1,1.2l-0.1,1.4c0,0.3,0,0.6-0.1,0.8c0,0.1,0,0.2,0,0.3c0-0.3,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5
            c0,1.4-0.2,2.9-0.3,4.3c-0.2,3.7-0.2,7.4-0.3,11.2c-0.1,4.5-0.2,9.1-0.2,13.7c0,5,0,10.1,0,15c0,5,0,10.2,0,15.3
            c0,4.8,0.1,9.7,0.2,14.4c0.1,4.1,0.1,8.2,0.1,12.3c0,3,0,6.1,0,9.1c0,1,0,2,0,3c0,0.3,0,0.7,0,1.1c0,0.4,0.2,0.8,0.3,1.1
            c0,0.1,0,0.1,0.1,0.2c-0.2-0.3-0.1-0.1,0-0.1c0.1,0.2,0.1,0.3,0.3,0.4l-0.3-0.5c0,0.1,0-0.2,0-0.1c0.1-0.3,0.4-0.7,0.9-0.3
            c-0.7-0.5-1.3,0.6-0.7,1c0.5,0.3,0.9-0.2,0.9-0.6l-0.4,0.6c0.6-0.2,0.9-1.3,0.9-1.8c0-0.8,0-1.6,0-2.4c0-2.7,0-5.5,0-8.1
            c0-3.9,0-7.7,0-11.6c0-4.7,0.3-9.3,0.3-14c0-5,0-10.1,0-15.1c0-5,0-10.1,0-15.2c0-4.7,0-9.4-0.2-14.1l-0.3-11.9
            c0-1.6-0.1-3.2-0.2-4.8c0-0.7-0.1-1.3-0.1-2c0-0.2,0-0.3,0-0.4s0-0.2,0-0.3s0-0.3,0-0.3c0-0.3,0-0.6-0.1-0.8
            c-0.1-0.9-0.2-1.8-0.2-2.7v-0.5c0-0.1,0-0.2,0-0.3c0,0-0.1-0.3,0-0.1c0,0.2,0,0,0,0V95c0-0.2-0.1-0.3-0.1-0.5s0-0.2-0.1-0.1
            c0,0,0.1-0.1,0-0.2c0,0-0.1-0.1-0.2,0L282.5,94.5z"
        />
        <path
            fill="#42515A"
            d="M282.5,201.9c-0.3-0.2-0.8-0.2-1.2-0.2c-0.3,0-0.4,0-0.7-0.1c-0.3,0-0.5,0-0.8,0l-1.9-0.1
            c-4.1-0.2-8.3-0.3-12.4-0.4c-6.1-0.2-12.3-0.2-18.5-0.3c-7.6-0.1-15.2-0.2-22.7-0.2h-24.9H174c-8,0-16,0.1-24,0.2
            c-6.9,0.1-13.7,0-20.6,0c-5.1,0-10.2,0-15.4,0h-5.1c-1.1-0.1-2.2,0-3.3,0.3l-0.7,0.2c-0.3,0.1-0.4,0.3-0.4,0.4l0,0l0.6-0.4
            c-0.3,0,0.3,0.1,0.4,0.3l-0.1,0.8l-0.1,0.1c0.7-0.3,0.1-1.4-0.7-1l-0.1,0.1c-0.2,0.2-0.3,0.5-0.1,0.8c0.2,0.3,0.4,0.3,0.7,0.3
            l-0.6-0.5l0,0c0.1,0.3,0.3,0.4,0.4,0.4c0.7,0.3,1.4,0.4,2.2,0.4h4.2c4.6,0,9.2,0,13.8,0c6.5,0,13,0,19.6,0
            c7.7,0,15.5,0.3,23.2,0.3h25.2h25.3c7.8,0,15.7,0,23.5-0.1l19.6-0.3c4.7,0,9.4-0.2,14.1-0.4l4.4-0.2c0.3,0,0.5,0,0.8,0
            c-1.3,0.1-2.3-0.8-2.5-2.1c-0.1-1.3,0.8-2.3,2.1-2.5c1.3-0.2,2.3,0.8,2.5,2.1c0.1,1.2-0.7,2.3-1.8,2.4c0.1,0,0.3,0,0.3,0
            c0.3,0,0.6-0.1,0.9-0.1c0.1,0,0.3,0,0.3,0c0.1,0,0.1,0,0.2-0.1C282.6,201.9,282.6,201.9,282.5,201.9L282.5,201.9z"
        />
        <path
            fill="#42515A"
            d="M282.5,94.5c-0.3-0.2-0.8-0.1-1.2-0.1c-1.1-0.1-2.3-0.1-3.4-0.1c-4.1-0.1-8.3-0.2-12.4-0.3
            C259.3,94,253.2,94,247,94c-7.6,0-15.1-0.1-22.7-0.1h-24.9H174c-8,0-16,0-24,0.1c-6.9,0-13.7,0-20.6,0H114c-2.7,0-5.5-0.3-8.2,0.1
            l-0.7,0.1c-0.2,0-0.3,0.1-0.3,0.3l0,0l0.3-0.3c-0.3,0,0,0,0.1,0.2v0.3h-0.1c0.3-0.2,0-0.7-0.3-0.5h-0.1c-0.1,0.1-0.1,0.3,0,0.3
            c0.1,0.2,0.3,0.2,0.4,0.2l-0.3-0.3l0,0c0,0.1,0.1,0.2,0.3,0.3c2.1,0.3,4.2,0.3,6.3,0.3c4.6,0,9.1,0,13.8,0h19.4
            c7.7,0,15.4,0.1,23.2,0.1h25.2h25.3c7.8,0,15.6,0,23.4-0.1l19.7-0.2c4.7,0,9.4-0.1,14.2-0.3c2.3,0,4.4-0.1,6.7-0.3
            c0.1,0,0.3,0,0.3,0S282.5,94.5,282.5,94.5L282.5,94.5z"
        />
        <rect x="114.2" y="113.7" fill="#FFFFFF" width="177.2" height="106.2" />
        <path
            fill="#42515A"
            d="M114.2,113.7c-0.2,0.1-0.2,0.6-0.3,0.7c0,0,0,0.1,0,0.2c0,0.2,0-0.3,0,0c0,0.1,0,0.2,0,0.3
            c0,0.2,0,0.3,0,0.4c0,0.3-0.1,0.8-0.1,1.2c0,0.5-0.1,1-0.1,1.4c0,0.3,0,0.6-0.1,0.8c0,0.1,0,0.2,0,0.3c0-0.3,0,0.1,0,0.2
            c0,0.2,0,0.3,0,0.5c0,1.4-0.2,2.9-0.3,4.3c-0.2,3.7-0.2,7.4-0.3,11.2c-0.1,4.5-0.2,9.1-0.3,13.7c0,5,0,10,0,15
            c0,5.1,0,10.2,0,15.3c0,4.8,0.1,9.7,0.2,14.4c0.1,4.1,0,8.2,0,12.3c0,3,0,6.1,0,9.1c0,1,0,2,0,3c0,0.3,0,0.7,0,1.1
            c0,0.4,0.2,0.8,0.3,1.1c0,0.2,0.1,0.3,0,0c0,0.3,0.2,0.4,0.3,0.5l-0.3-0.5c0,0.1,0-0.2,0-0.1c0.1-0.3,0.3-0.5,0.7-0.4
            c0.1,0,0.2,0,0.3,0.1c-0.7-0.5-1.3,0.6-0.7,1c0.5,0.3,0.9-0.2,0.9-0.6l-0.4,0.6c0.6-0.2,0.9-1.3,1-1.8c0-0.8,0-1.6,0-2.4
            c0-2.7,0.1-5.5,0.1-8.1c0-3.9,0-7.7,0.1-11.6c0-4.7,0.3-9.3,0.3-14c0-5,0-10.1,0-15.1s0-10.1,0-15.2c0-4.7,0-9.4-0.1-14.1
            c-0.1-3.9-0.2-7.9-0.3-11.9c0-1.6-0.1-3.2-0.2-4.8c0-0.7-0.1-1.3-0.1-2c0-0.2,0-0.3,0-0.4s0-0.2,0-0.3s0-0.4,0-0.3
            c0-0.3,0-0.6-0.1-0.8c-0.1-0.9-0.2-1.8-0.2-2.7v-0.5c0-0.1,0-0.2,0-0.3c0,0-0.1-0.3,0-0.1c0,0.2,0,0,0,0v-0.1
            c0-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.2-0.1-0.1c0,0,0.1-0.1,0-0.2c0,0-0.1-0.1-0.2,0L114.2,113.7z"
        />
        <path
            fill="#42515A"
            d="M291.5,113.7c-0.2,0.1-0.2,0.6-0.3,0.7v0.2c0,0.2,0-0.3,0,0c0,0.1,0,0.2,0,0.3c0,0.2,0,0.3,0,0.4l-0.1,1.2
            l-0.1,1.4c0,0.3,0,0.6-0.1,0.8c0,0.1,0,0.2,0,0.3c0-0.3,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5c0,1.4-0.2,2.9-0.3,4.3
            c-0.2,3.7-0.2,7.4-0.3,11.2c-0.1,4.5-0.2,9.1-0.2,13.7c0,5,0,10.1,0,15c0,5.1,0,10.2,0,15.3c0,4.8,0.1,9.7,0.2,14.4
            c0.1,4.1,0.1,8.2,0.1,12.3c0,3,0,6.1,0,9.1c0,1,0,2,0,3c0,0.3,0,0.7,0,1.1c0,0.4,0.2,0.8,0.3,1.1l0.1,0.2c-0.2-0.3-0.1-0.1,0-0.1
            c0.1,0.2,0.1,0.3,0.3,0.4l-0.3-0.5c0,0.1,0-0.2,0-0.1c0.1-0.3,0.3-0.5,0.7-0.4c0.1,0,0.2,0,0.3,0.1c-0.7-0.5-1.3,0.6-0.7,1
            c0.5,0.3,0.9-0.2,0.9-0.6l-0.4,0.6c0.6-0.2,0.9-1.3,0.9-1.8c0-0.8,0-1.6,0-2.4c0-2.7,0-5.5,0-8.1c0-3.9,0-7.7,0-11.6
            c0-4.7,0.3-9.3,0.3-14c0-5,0-10.1,0-15.1c0-5,0-10.2,0-15.2c0-4.7,0-9.4-0.2-14.1l-0.3-11.9c0-1.6-0.1-3.2-0.2-4.8
            c0-0.7-0.1-1.3-0.1-2c0-0.2,0-0.3,0-0.4s0-0.2,0-0.3s0-0.4,0-0.3c0-0.3,0-0.6-0.1-0.8c-0.1-0.9-0.2-1.8-0.2-2.7v-0.5
            c0-0.1,0-0.2,0-0.3c0,0-0.1-0.3,0-0.1c0,0.2,0,0,0,0v-0.1c0-0.2-0.1-0.3-0.1-0.5s0-0.2-0.1-0.1c0,0,0-0.1,0-0.2s-0.1,0-0.1,0
            L291.5,113.7z"
        />
        <path
            fill="#42515A"
            d="M291.5,221c-0.3-0.1-0.8-0.2-1.2-0.2c-0.3,0-0.4,0-0.7-0.1c-0.3,0-0.5,0-0.8,0l-1.9-0.1
            c-4.1-0.2-8.3-0.3-12.4-0.4c-6.1-0.2-12.3-0.2-18.5-0.3c-7.6-0.1-15.2-0.2-22.7-0.2h-24.9H183c-8,0-16,0.1-24,0.2
            c-6.9,0.1-13.7,0-20.6,0h-15.4H118c-1.1-0.1-2.2,0-3.3,0.3l-0.7,0.2c-0.3,0.1-0.4,0.3-0.4,0.4l0,0l0.6-0.4c-0.3,0,0.3,0.1,0.4,0.3
            l-0.1,0.8h-0.2c0.7-0.3,0.1-1.4-0.6-1l-0.1,0.1c-0.2,0.2-0.3,0.5-0.1,0.8c0.2,0.3,0.4,0.3,0.7,0.3l-0.6-0.4l0,0
            c0.1,0.3,0.3,0.4,0.4,0.4c0.7,0.3,1.4,0.4,2.2,0.4h4.2c4.6,0,9.2,0,13.8,0c6.5,0,13,0,19.6,0c7.7,0,15.5,0.3,23.2,0.3h25.2h25.3
            c7.8,0,15.7,0,23.5-0.1l19.6-0.3c4.7,0,9.4-0.2,14.1-0.4l4.4-0.2c0.3,0,0.5,0,0.8,0c0.2,0,0.6-0.1,0.3,0c0.1,0,0.3,0,0.3,0
            c0.3,0,0.6-0.1,0.9-0.1c0.1,0,0.3,0,0.3,0C291.5,221.2,291.6,221.1,291.5,221L291.5,221z"
        />
        <path
            fill="#42515A"
            d="M291.5,113.6c-0.3-0.2-0.8-0.1-1.2-0.1c-1.1-0.1-2.3-0.1-3.4-0.1c-4.1-0.1-8.3-0.2-12.4-0.3
            c-6.2-0.1-12.3-0.1-18.5-0.1c-7.6,0-15.1-0.1-22.7-0.1h-24.9H183c-8,0-16,0-24,0.1c-6.9,0-13.7,0-20.6,0c-5.1,0-10.3,0-15.4,0
            c-2.7,0-5.5-0.3-8.3,0.1l-0.7,0.1c-0.2,0-0.3,0.1-0.3,0.3l0,0l0.3-0.3c-0.3,0,0,0,0.1,0.2v0.3h-0.1c0.3-0.2,0-0.7-0.3-0.5h-0.1
            c-0.1,0.1-0.1,0.3,0,0.3c0.1,0.2,0.3,0.2,0.4,0.2l-0.3-0.3l0,0c0,0.1,0.1,0.2,0.3,0.3c2.1,0.3,4.2,0.3,6.3,0.3h13.8h19.4
            c7.7,0,15.5,0.1,23.2,0.1h25.2h25.3c7.8,0,15.6,0,23.4-0.1l19.7-0.2c4.7,0,9.4-0.1,14.2-0.3c2.3,0,4.4-0.1,6.7-0.3
            c0.1,0,0.3,0,0.3,0S291.5,113.7,291.5,113.6L291.5,113.6z"
        />
        <rect x="95.5" y="133.1" fill="#FFFFFF" width="177.3" height="106.3" />
        <path
            fill="#42515A"
            d="M95.4,133c-0.2,0.1-0.2,0.6-0.3,0.7c0,0,0,0.1,0,0.2c0,0.2,0-0.3,0,0c0,0.1,0,0.2,0,0.3c0,0.2,0,0.3,0,0.4
            c0,0.3-0.1,0.8-0.1,1.2c0,0.5-0.1,1-0.1,1.4l-0.1,0.8c0,0.1,0,0.2,0,0.3c0-0.3,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5
            c-0.1,1.4-0.2,2.9-0.3,4.3c-0.2,3.7-0.2,7.5-0.3,11.2c-0.1,4.5-0.2,9.1-0.3,13.7v15.1c0,5.1,0,10.2,0,15.3c0,4.8,0.1,9.7,0.2,14.4
            c0.1,4.1,0,8.2,0,12.3c0,3,0,6.1,0,9.1c0,1,0,2,0,3c0,0.3,0,0.7,0,1.1c0,0.4,0.2,0.8,0.3,1.1c0.1,0.2,0.1,0.3,0,0
            c0.1,0.3,0.2,0.4,0.3,0.5l-0.3-0.5c0,0.1,0-0.2,0-0.1c0.1-0.3,0.4-0.7,0.9-0.3c-0.7-0.5-1.3,0.6-0.7,1c0.5,0.3,0.9-0.2,0.9-0.6
            l-0.4,0.6c0.6-0.2,0.9-1.3,1-1.8c0-0.8,0-1.6,0-2.4c0-2.7,0.1-5.5,0.1-8.1c0-3.9,0-7.7,0.1-11.6c0-4.7,0.3-9.3,0.3-14
            c0-5,0-10.1,0-15.1s0-10.2,0-15.2c0-4.7,0-9.4-0.1-14.1c-0.1-3.9-0.2-8-0.3-11.9c0-1.6-0.1-3.2-0.2-4.8c0-0.7-0.1-1.3-0.1-2
            c0-0.2,0-0.3,0-0.4s0-0.2,0-0.3c0-0.1,0-0.4,0-0.3c0-0.3,0-0.6-0.1-0.8c-0.1-0.9-0.2-1.8-0.2-2.7v-0.5c0-0.1,0-0.2,0-0.3
            c0,0-0.1-0.3,0-0.1s0,0,0,0v-0.1c0-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.2-0.1-0.1c0,0,0-0.1,0-0.2h-0.1L95.4,133z"
        />
        <path
            fill="#42515A"
            d="M272.9,133c-0.2,0.1-0.2,0.6-0.2,0.7c0,0,0,0.1,0,0.2c0,0.2,0-0.3,0,0c0,0.1,0,0.2,0,0.3
            c0,0.2,0,0.3,0,0.4l-0.1,1.2c0,0.5-0.1,1-0.1,1.4l-0.1,0.8c0,0.1,0,0.2,0,0.3c0-0.3,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5
            c0,1.4-0.2,2.9-0.3,4.3c-0.2,3.7-0.2,7.5-0.3,11.2c-0.1,4.5-0.2,9.1-0.2,13.7c0,5,0,10.1,0,15.1c0,5.1,0,10.2,0,15.3
            c0,4.8,0.1,9.7,0.2,14.4c0.1,4.1,0.1,8.2,0.1,12.3c0,3,0,6.1,0,9.1c0,1,0,2,0,3c0,0.3,0,0.7,0,1.1c0,0.4,0.2,0.8,0.3,1.1
            c0,0.1,0,0.1,0.1,0.2c-0.2-0.3-0.1-0.1,0-0.1c0.1,0.2,0.1,0.3,0.3,0.4l-0.3-0.5c0,0.1,0-0.2,0-0.1c0.1-0.3,0.3-0.5,0.7-0.4
            c0.1,0,0.2,0,0.3,0.1c-0.7-0.5-1.3,0.6-0.7,1c0.5,0.3,0.9-0.2,0.9-0.6l-0.4,0.6c0.6-0.2,0.9-1.3,0.9-1.8c0-0.8,0-1.6,0-2.4
            c0-2.7,0-5.5,0-8.1c0-3.9,0-7.7,0-11.6c0-4.7,0.3-9.3,0.3-14c0-5,0-10.1,0-15.1s0-10.2,0-15.2c0-4.7,0-9.4-0.2-14.1
            c-0.1-3.9-0.2-8-0.3-11.9c0-1.6-0.1-3.2-0.2-4.8c0-0.7-0.1-1.3-0.1-2c0-0.2,0-0.3,0-0.4s0-0.2,0-0.3s0-0.4,0-0.3
            c0-0.3,0-0.6-0.1-0.8l-0.2-2.7c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2,0-0.3c0,0-0.1-0.3,0-0.1c0.1,0.2,0,0,0,0v-0.1
            c0-0.2-0.1-0.3-0.1-0.5s0-0.2-0.1-0.1c0,0,0-0.1,0-0.2s-0.1,0-0.2,0L272.9,133z"
        />
        <path
            fill="#42515A"
            d="M272.9,240.5c-0.3-0.2-0.8-0.2-1.2-0.2c-0.3,0-0.4,0-0.7-0.1c-0.3,0-0.5,0-0.8,0l-1.9-0.1
            c-4.1-0.2-8.3-0.3-12.4-0.4c-6.1-0.2-12.3-0.2-18.5-0.3c-7.6-0.1-15.2-0.2-22.8-0.2h-25h-25.4c-8,0-16,0.1-24,0.2
            c-6.9,0.1-13.7,0-20.6,0c-5.1,0-10.2,0-15.4,0h-5.1c-1.1-0.1-2.2,0-3.3,0.3l-0.7,0.2c-0.3,0.1-0.4,0.3-0.4,0.4l0,0l0.6-0.4
            c-0.3,0,0.3,0.1,0.4,0.3l-0.1,0.8l-0.1,0.1c0.7-0.3,0.1-1.4-0.7-1l-0.1,0.1c-0.2,0.2-0.3,0.5-0.1,0.8c0.2,0.3,0.4,0.3,0.7,0.3
            l-0.6-0.4l0,0c0.1,0.3,0.3,0.4,0.4,0.4c0.7,0.3,1.4,0.4,2.2,0.4h4.2c4.6,0,9.2,0,13.8,0.1l19.6,0.1c7.7,0,15.5,0.3,23.2,0.3h25.2
            h25.3c7.8,0,15.7,0,23.5-0.1l19.6-0.3c4.7,0,9.4-0.2,14.1-0.4l4.4-0.2c0.3,0,0.5,0,0.8,0c0.2,0,0.6-0.1,0.3,0c0.1,0,0.3,0,0.3,0
            c0.3,0,0.6-0.1,0.9-0.1c0.1,0,0.3,0,0.3,0c0.1,0,0.1,0,0.2-0.1C272.9,240.6,272.9,240.6,272.9,240.5L272.9,240.5z"
        />
        <path
            fill="#42515A"
            d="M272.8,133c-0.3-0.2-0.8-0.1-1.2-0.1c-1.1-0.1-2.3-0.1-3.4-0.1c-4.1-0.1-8.3-0.2-12.4-0.3
            c-6.2-0.1-12.3-0.1-18.5-0.1c-7.6,0-15.1-0.1-22.7-0.1h-24.9h-25.3c-8,0-16,0-24,0.1c-6.9,0-13.7,0-20.6,0c-5.1,0-10.3,0-15.4,0
            c-2.7,0-5.5-0.3-8.3,0.1l-0.7,0.1c-0.2,0-0.3,0.1-0.3,0.3l0,0l0.3-0.3c-0.3,0,0,0,0.1,0.2v0.3h-0.1c0.3-0.2,0-0.7-0.3-0.5v0.3
            c-0.1,0.1-0.1,0.3,0,0.3c0.1,0.2,0.3,0.2,0.4,0.2l-0.4-0.3l0,0c0,0.1,0.1,0.2,0.3,0.3c2.1,0.3,4.2,0.3,6.3,0.3h13.8h19.5
            c7.7,0,15.5,0.1,23.2,0.1h25.2h25.3c7.8,0,15.6,0,23.4-0.1l19.7-0.2c4.7,0,9.4-0.1,14.2-0.3c2.3,0,4.4-0.1,6.7-0.3
            C272.7,133.1,272.8,133,272.8,133C272.9,133.1,272.9,133.1,272.8,133L272.8,133z"
        />
        <rect x="104" y="147.9" fill="#FFFFFF" width="177.3" height="106.3" />
        <path
            fill="#42515A"
            d="M103.9,147.9c-0.2,0.1-0.2,0.6-0.3,0.7v0.2c0,0.2,0-0.3,0,0c0,0.1,0,0.2,0,0.3c0,0.2,0,0.3,0,0.4
            c0,0.3-0.1,0.8-0.1,1.2c0,0.5-0.1,1-0.1,1.4l-0.1,0.8c0,0.1,0,0.2,0,0.3c0-0.3,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5
            c0,1.4-0.2,2.9-0.3,4.3c-0.2,3.7-0.2,7.5-0.3,11.2c-0.1,4.5-0.2,9.1-0.3,13.7l-0.1,15.1c0,5.1,0,10.2,0,15.3
            c0,4.8,0.1,9.7,0.2,14.4c0.1,4.1,0,8.2,0,12.3c0,3,0,6.1,0,9.1c0,1,0,2,0,3c0,0.3,0,0.7,0,1.1c0,0.4,0.2,0.8,0.3,1.1
            c0.1,0.1,0.1,0.3,0,0c0.1,0.3,0.2,0.4,0.3,0.5l-0.3-0.5c0,0.1,0-0.2,0-0.1c0.1-0.3,0.4-0.7,0.9-0.3c-0.7-0.5-1.3,0.6-0.7,1
            c0.5,0.3,0.9-0.2,0.9-0.6l-0.4,0.6c0.6-0.2,0.9-1.3,1-1.8c0-0.8,0-1.6,0-2.4c0-2.7,0.1-5.5,0.1-8.1c0-3.9,0-7.7,0.1-11.6
            c0-4.7,0.3-9.3,0.3-14c0-5,0-10.1,0-15.1c0-5,0-10.2,0-15.2c0-4.7,0-9.4-0.1-14.1c-0.1-3.9-0.2-8-0.3-11.9c0-1.6-0.1-3.2-0.2-4.8
            c0-0.7-0.1-1.3-0.1-2v-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0-0.3c0-0.3,0-0.6-0.1-0.8c-0.1-0.9-0.2-1.8-0.2-2.7c0-0.2,0-0.3,0-0.5
            c0-0.1,0-0.2,0-0.3c0,0-0.1-0.2,0-0.1c0,0.1,0,0,0,0v-0.1c0-0.2-0.1-0.3-0.1-0.5s0-0.2-0.1-0.1C104,148,104,147.9,103.9,147.9
            C104,147.8,103.9,147.8,103.9,147.9L103.9,147.9z"
        />
        <path
            fill="#42515A"
            d="M281.3,147.9c-0.2,0.1-0.2,0.6-0.2,0.7c0,0,0,0.1,0,0.2c0,0.2,0-0.3,0,0c0,0.1,0,0.2,0,0.3
            c0,0.2,0,0.3,0,0.4l-0.1,1.2L281,152c0,0.3,0,0.6-0.1,0.8c0,0.1,0,0.2,0,0.3c0-0.3,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5
            c0,1.4-0.2,2.9-0.3,4.3c-0.2,3.7-0.2,7.5-0.3,11.2c-0.1,4.5-0.2,9.1-0.2,13.7c0,5,0,10.1,0,15.1c0,5.1,0,10.2,0,15.3
            c0,4.8,0.1,9.7,0.2,14.4c0.1,4.1,0.1,8.2,0.1,12.3c0,3,0,6.1,0,9.1c0,1,0,2,0,3c0,0.3,0,0.7,0,1.1c0,0.4,0.2,0.8,0.3,1.1
            c0,0.1,0,0.1,0.1,0.2c-0.2-0.3-0.1-0.1,0-0.1c0.1,0.2,0.1,0.3,0.3,0.4l-0.3-0.5c0,0.1,0-0.2,0-0.1c0.1-0.3,0.3-0.5,0.7-0.4
            c0.1,0,0.2,0,0.3,0.1c-0.7-0.5-1.3,0.6-0.7,1c0.5,0.3,0.9-0.2,0.9-0.6l-0.4,0.6c0.6-0.2,0.9-1.3,0.9-1.8c0-0.8,0-1.6,0-2.4
            c0-2.7,0-5.5,0.1-8.1c0-3.9,0-7.7,0-11.6c0-4.7,0.3-9.3,0.3-14v-15.1c0-5,0-10.2,0-15.2c0-4.7,0-9.4-0.2-14.1l-0.3-11.9
            c0-1.6-0.1-3.2-0.2-4.8c0-0.7-0.1-1.3-0.1-2c0-0.2,0-0.3,0-0.4s0-0.2,0-0.3c0-0.1,0-0.3,0-0.3c0-0.3,0-0.6-0.1-0.8l-0.2-2.7
            c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2,0-0.3c0,0-0.1-0.2,0-0.1c0,0.1,0,0,0,0v-0.1c0-0.2-0.1-0.3-0.1-0.5s0-0.2-0.1-0.1c0,0,0-0.1,0-0.2
            h-0.1L281.3,147.9z"
        />
        <path
            fill="#42515A"
            d="M281.3,255.3c-0.3-0.1-0.8-0.2-1.2-0.2c-0.3,0-0.4,0-0.7-0.1c-0.3,0-0.5,0-0.8,0l-1.9-0.1
            c-4.1-0.2-8.3-0.3-12.4-0.4c-6.1-0.2-12.3-0.2-18.5-0.3c-7.6-0.1-15.2-0.2-22.8-0.2h-25h-25.4c-8,0-16,0.1-24,0.2
            c-6.9,0.1-13.7,0-20.6,0c-5.1,0-10.2,0-15.4,0c-1.7,0-3.4,0-5.1,0c-1.1-0.1-2.2,0-3.3,0.3l-0.7,0.2c-0.3,0.1-0.4,0.3-0.4,0.4l0,0
            l0.6-0.4c-0.3,0,0.3,0.1,0.4,0.3l-0.1,0.8l-0.1,0.1c0.7-0.3,0.1-1.4-0.7-1l-0.1,0.1c-0.2,0.2-0.3,0.5-0.1,0.8
            c0.2,0.3,0.4,0.3,0.7,0.3l-0.6-0.4l0,0c0.1,0.3,0.3,0.4,0.4,0.4c0.7,0.3,1.4,0.4,2.2,0.4h4.2c4.6,0,9.2,0,13.8,0.1
            c6.5,0,13,0,19.6,0.1c7.7,0,15.5,0.3,23.2,0.3h25.2h25.3c7.8,0,15.7,0,23.5-0.1l19.6-0.3c4.7,0,9.4-0.2,14.1-0.4l4.4-0.2
            c0.3,0,0.5,0,0.8,0c0.2,0,0.6-0.1,0.3,0c0.1,0,0.3,0,0.3,0c0.3,0,0.6-0.1,0.9-0.1c0.1,0,0.3,0,0.3,0c0.1,0,0.1,0,0.2-0.1
            C281.4,255.4,281.4,255.3,281.3,255.3L281.3,255.3z"
        />
        <path
            fill="#42515A"
            d="M281.3,147.8c-0.3-0.2-0.9-0.1-1.2-0.1c-1-0.1-2.3-0.1-3.4-0.1c-4.3-0.1-8.6-0.2-12.8-0.3
            c-6.3-0.1-12.6-0.1-19-0.1c-7.7,0-15.5-0.1-23.3-0.1h-25.6h-25.8c-8,0-16,0.1-24.1,0.2c-6.8,0-13.7,0-20.5,0c-5,0-9.8,0-14.7,0
            c-1.5,0-3.1,0-4.6,0c-0.6,0-1.1,0-1.7,0.1l-0.5,0.1c-0.1,0-0.4,0.1-0.3,0c0,0-0.1,0-0.2,0c0,0-0.2,0.1-0.3,0.1c-0.2,0,0.3,0,0.2,0
            c-0.3-0.1,0.2,0.2,0.3,0.2v0.4h-0.1c0.4-0.2,0-0.8-0.3-0.6h-0.1c-0.1,0.1-0.2,0.3,0,0.4c0.1,0.2,0.3,0.1,0.3,0.2c0,0,0,0,0.1,0
            c-0.4-0.2-0.1,0,0,0c0.3,0,0.6,0.1,0.8,0.2c1.2,0.2,2.3,0.2,3.5,0.2c4.3,0,8.6,0,12.8,0H140c7.7,0,15.5,0.1,23.3,0.1
            c8.6,0,17.1,0,25.7,0h25.8c8.1,0,16,0,24.1-0.1l20.4-0.2c4.9,0,9.7-0.1,14.7-0.3c2.3-0.1,4.7-0.1,7-0.3
            C281,147.9,281.3,147.9,281.3,147.8C281.3,147.9,281.4,147.9,281.3,147.8L281.3,147.8z"
        />
        <rect x="85.1" y="160.6" fill="#FFFFFF" width="177.3" height="106.3" />
        <path
            fill="#42515A"
            d="M262.7,213.7c-0.4-0.2-1-0.1-1.5-0.1h-3.4h-12.8h-19h-23.4h-25.3h-25.8h-24c-6.8,0-13.6,0-20.3,0
            c-5,0-9.8,0-14.8,0c-2.3,0-4.7,0-7,0c-0.7,0,0-0.1,0.3,0.3l-0.1,0.8l-0.2,0.1c0.8-0.3,0.1-1.6-0.7-1.2l-0.2,0.1
            c-0.3,0.3-0.3,0.6-0.1,0.8c0.3,0.5,1.3,0.3,1.7,0.3c1.1,0,2.3,0,3.4,0c4.3,0,8.5,0,12.8,0h19.1h23.3H170h25.8h24h20.4h14.7
            c2.3,0,4.7,0.1,7,0c0.3,0,0.4,0.1,0-0.1C262.8,215.3,263.5,214.1,262.7,213.7L262.7,213.7z"
        />
        <line
            fill="none"
            stroke="#42515A"
            strokeWidth="0.9616"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="214.3"
            y1="160.7"
            x2="214.3"
            y2="163.1"
        />
        <line
            fill="none"
            stroke="#42515A"
            strokeWidth="0.9616"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeDasharray="4.8938,4.8938"
            x1="214.3"
            y1="168"
            x2="214.3"
            y2="263.4"
        />
        <line
            fill="none"
            stroke="#42515A"
            strokeWidth="0.9616"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="214.3"
            y1="265.9"
            x2="214.3"
            y2="268.2"
        />
        <line
            fill="none"
            stroke="#42515A"
            strokeWidth="0.9616"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="131.2"
            y1="160.2"
            x2="131.2"
            y2="162.5"
        />
        <line
            fill="none"
            stroke="#42515A"
            strokeWidth="0.9616"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeDasharray="4.8938,4.8938"
            x1="131.2"
            y1="167.5"
            x2="131.2"
            y2="262.9"
        />
        <line
            fill="none"
            stroke="#42515A"
            strokeWidth="0.9616"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="131.2"
            y1="265.3"
            x2="131.2"
            y2="267.7"
        />
        <path
            fill="none"
            stroke="#42515A"
            strokeWidth="1.4909"
            strokeMiterlimit="10"
            d="M215.2,214.6c2.4-0.8,9.4-0.8,15.5-9.8 c6.1-9.1,12.3-34.2,17.9-12.6c2.9,10.8,6.3,29.3,13.6,4.8"
        />
        <path
            fill="none"
            stroke="#42515A"
            strokeWidth="1.4909"
            strokeMiterlimit="10"
            d="M85.1,196.8c0,0,13.8-28.1,19.6-7.5 c5.7,20.6,7.8-12.3,14.7,2.9c4.8,10.5,8.2,21.1,13.3,22.7"
        />
        <path
            fill="none"
            stroke="#42515A"
            strokeWidth="1.4909"
            strokeMiterlimit="10"
            d="M132.8,215c2.3,0.6,7,19.6,10.2,15.8 c10.7-12.7,14.2,45.1,24.7,20.2c7.4-17.5,7.4-22.6,11-20c3.4,2.3,9.9,32.4,20.4,12.6c1.2-2.3,2.1-4.5,2.9-7 c1.9-6.1,3.8-17.3,12.3-22"
        />
        <path
            fill="#42515A"
            d="M85,160.5c-0.2,0.1-0.2,0.6-0.3,0.7v0.2c0,0.2,0-0.3,0,0c0,0.1,0,0.2,0,0.3c0,0.2,0,0.3,0,0.4
            c0,0.3-0.1,0.8-0.1,1.2c0,0.5-0.1,1-0.1,1.4l-0.1,0.8c0,0.1,0,0.2,0,0.3c0-0.3,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5
            c0,1.4-0.2,2.9-0.3,4.3c-0.2,3.7-0.2,7.5-0.3,11.2c-0.1,4.5-0.2,9.1-0.3,13.7c0,5,0,10.1,0,15.1c0,5.1,0,10.2,0,15.3
            c0,4.8,0.1,9.7,0.2,14.4c0.1,4.1,0,8.3,0,12.3c0,3,0,6.1,0,9.1c0,1,0,2,0,3c0,0.3,0,0.7,0,1.1s0.2,0.8,0.3,1.1
            c0.1,0.1,0.1,0.3,0,0c0,0.3,0.2,0.4,0.3,0.5l-0.3-0.5c0,0.1,0-0.2,0-0.1c0.1-0.3,0.3-0.5,0.7-0.4c0.1,0,0.2,0,0.3,0.1
            c-0.7-0.5-1.3,0.6-0.7,1c0.5,0.3,0.9-0.2,0.9-0.6l-0.4,0.6c0.6-0.2,0.9-1.3,1-1.8c0-0.8,0-1.6,0-2.4c0-2.7,0.1-5.5,0.1-8.1
            c0-3.9,0-7.7,0.1-11.6c0-4.7,0.3-9.3,0.3-14c0-5,0-10.1,0-15.1s0-10.2,0-15.2c0-4.7,0-9.4-0.1-14.1c-0.1-3.9-0.2-8-0.3-11.9
            c0-1.6-0.1-3.2-0.2-4.8c0-0.7-0.1-1.3-0.1-2c0-0.2,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0-0.3c0-0.3,0-0.6-0.1-0.8
            c-0.1-0.9-0.1-1.8-0.2-2.7v-0.5c0-0.1,0-0.2,0-0.3c0,0-0.1-0.2,0-0.1c0,0.1,0,0,0,0v-0.1c0-0.2-0.1-0.3-0.1-0.5s0-0.2-0.1-0.1
            c0,0,0-0.1,0-0.2C85.1,160.5,85.1,160.5,85,160.5L85,160.5z"
        />
        <path
            fill="#42515A"
            d="M262.4,160.5c-0.2,0.1-0.2,0.6-0.3,0.7v0.2c0,0.2,0-0.3,0,0c0,0.1,0,0.2,0,0.3c0,0.2,0,0.3,0,0.4l-0.1,1.2
            l-0.1,1.4c0,0.3,0,0.6-0.1,0.8c0,0.1,0,0.2,0,0.3c0-0.3,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5c0,1.4-0.2,2.9-0.3,4.3
            c-0.2,3.7-0.2,7.5-0.3,11.2c-0.1,4.5-0.2,9.1-0.2,13.7c0,5,0,10.1,0,15.1c0,5.1,0,10.2,0,15.3c0,4.8,0.1,9.7,0.2,14.4
            c0.1,4.1,0.1,8.3,0.1,12.3c0,3,0,6.1,0,9.1c0,1,0,2,0,3c0,0.3,0,0.7,0,1.1s0.2,0.8,0.3,1.1l0.1,0.2c-0.2-0.3-0.1-0.1,0-0.1
            c0.1,0.2,0.1,0.3,0.3,0.4l-0.3-0.5c0,0.1,0-0.2,0-0.1c0.1-0.3,0.3-0.5,0.7-0.4c0.1,0,0.2,0,0.3,0.1c-0.7-0.5-1.3,0.6-0.7,1
            c0.5,0.3,0.9-0.2,0.9-0.6l-0.4,0.6c0.6-0.2,0.9-1.3,0.9-1.8c0-0.8,0-1.6,0-2.4c0-2.7,0-5.5,0-8.1c0-3.9,0-7.7,0-11.6
            c0-4.7,0.3-9.3,0.3-14c0-5,0-10.1,0-15.1s0-10.2,0-15.2c0-4.7,0-9.4-0.2-14.1l-0.3-11.9c0-1.6-0.1-3.2-0.2-4.8
            c0-0.7-0.1-1.3-0.1-2c0-0.2,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0-0.3c0-0.3,0-0.6-0.1-0.8c-0.1-0.9-0.2-1.8-0.2-2.7v-0.5
            c0-0.1,0-0.2,0-0.3c0,0-0.1-0.2,0-0.1c0,0.1,0,0,0,0v-0.1c0-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.2-0.1-0.1c0,0,0.1-0.1,0-0.2
            c0,0-0.1-0.1-0.2,0L262.4,160.5z"
        />
        <path
            fill="#42515A"
            d="M262.4,267.9c-0.3-0.1-0.8-0.2-1.2-0.2c-0.3,0-0.4,0-0.7-0.1c-0.3,0-0.5,0-0.8,0l-1.9-0.1
            c-4.1-0.2-8.3-0.3-12.4-0.4c-6.1-0.2-12.3-0.2-18.5-0.3c-7.6-0.1-15.2-0.2-22.8-0.2h-25h-25.3c-8,0-16,0.1-24,0.2
            c-6.9,0.1-13.7,0-20.6,0c-5.1,0-10.2,0-15.4,0c-1.7,0-3.4,0-5.1,0c-1.1-0.1-2.2,0-3.3,0.3l-0.7,0.2c-0.3,0.1-0.4,0.3-0.4,0.4l0,0
            l0.6-0.4c-0.3,0,0.3,0.1,0.4,0.3l-0.1,0.8l-0.1,0.1c0.7-0.3,0.1-1.4-0.7-1l-0.1,0.1c-0.2,0.2-0.3,0.5-0.1,0.8
            c0.2,0.3,0.4,0.3,0.7,0.3l-0.6-0.4l0,0c0.1,0.3,0.3,0.4,0.4,0.4c0.7,0.3,1.4,0.4,2.2,0.4h4.2c4.6,0,9.2,0,13.8,0.1l19.6,0.1
            c7.7,0,15.5,0.3,23.2,0.3H173h25.3c7.8,0,15.7,0,23.5-0.1l19.6-0.3c4.7,0,9.4-0.2,14.1-0.4l4.4-0.2c0.3,0,0.5,0,0.8,0
            c0.2,0,0.6-0.1,0.3,0c0.1,0,0.3,0,0.3,0c0.3,0,0.6-0.1,0.9-0.1c0.1,0,0.3,0,0.3,0C262.4,268.2,262.5,268.1,262.4,267.9
            L262.4,267.9z"
        />
        <path
            fill="#42515A"
            d="M262.4,160.5c-0.3-0.2-0.9-0.1-1.2-0.1c-1-0.1-2.3-0.1-3.4-0.1c-4.3-0.1-8.6-0.2-12.8-0.3
            c-6.3-0.1-12.6-0.1-19-0.1c-7.7,0-15.5-0.1-23.3-0.1h-25.6h-25.8c-8,0-16,0.1-24.1,0.2c-6.8,0-13.7,0-20.5,0c-5,0-9.8,0-14.7,0
            c-1.5,0-3.1,0-4.6,0c-0.6,0-1.1,0-1.7,0.1l-0.5,0.1c-0.1,0-0.4,0.1-0.3,0c0,0-0.1,0-0.2,0c-0.1,0-0.2,0.1-0.3,0.1
            c-0.2,0,0.3,0,0.2,0c-0.3-0.1,0.2,0.2,0.3,0.2l-0.1,0.4h-0.1c0.4-0.2,0-0.8-0.3-0.6h-0.1c-0.1,0.1-0.2,0.3-0.1,0.4
            c0.1,0.2,0.3,0.1,0.3,0.2h0.1c-0.4-0.2-0.1,0,0,0c0.3,0,0.6,0.1,0.8,0.2c1.2,0.2,2.3,0.2,3.5,0.2c4.3,0,8.6,0,12.8,0h19.2
            c7.7,0,15.5,0.1,23.3,0.1c8.6,0,17.1,0,25.7,0h25.8c8.1,0,16,0,24.1-0.1l20.4-0.2c4.9,0,9.7-0.1,14.7-0.3c2.3-0.1,4.7-0.1,7-0.3
            C262.2,160.6,262.4,160.6,262.4,160.5C262.4,160.7,262.4,160.6,262.4,160.5L262.4,160.5z"
        />
        <polygon fill="#FFFFFF" points="498.2,258 328,247.9 338.5,90.7 345.1,86.6 513.6,95.6 505.4,252.9" />
        <polygon fill="#FFC000" points="508.6,99 338.5,90.7 336.9,116.8 507.1,125.6" />
        <path
            fill="#42515A"
            d="M338.2,90.2c-0.9,10.4-1.9,20.8-2.7,31.3c-0.7,10.2-1.4,20.4-2.1,30.5c-1.3,20.1-2.7,40.2-4.1,60.3
            c-0.8,11.8-1.8,23.7-2.4,35.6c-0.1,1.3,2.1,1.3,2.2,0v-0.4c0.1-1.3-2.1-1.3-2.1,0v0.8c-0.1,1.3,2,1.3,2.1,0v-0.4
            c0.1-1.3-2-1.3-2.1,0v0.4h2.1l0.1-0.8H327v0.4h2.2c1.8-20.7,2.8-41.6,4.1-62.4c1.3-20.1,2.7-40.1,4-60.1
            c0.8-11.7,1.3-23.4,1.8-35.2C339,89.7,338.3,89.7,338.2,90.2L338.2,90.2z"
        />
        <path
            fill="#42515A"
            d="M339.7,91.2c12.6,0.3,25.1,1.4,37.7,2.3c12.7,1,25.3,1.8,37.9,1.7c6.4,0,12.8,0,19.1,0.3
            c6.4,0.3,12.8,0.8,19.1,1.3s12.8,0.8,19.2,0.8c1.4,0,3-0.2,4.4,0c0.6,0,1.2,0.2,1.8,0.2c1.6,0.2,3.1,0.3,4.7,0.3
            c7.7,0.4,15.4,0.7,23.1,0.8h1.8c1.2,0,1.2-1.8,0-1.8c-7.3-0.1-14.7-0.3-22.1-0.7c-3-0.2-6.3-0.3-9.2-0.7c-1.6-0.2-3.2,0-4.7,0
            c-1.6,0-3.2,0-4.8-0.1c-3.2-0.1-6.4-0.3-9.7-0.4c-12.8-0.8-25.5-2-38.3-2c-6.4,0-12.8,0-19.1-0.3c-6.3-0.3-12.5-0.7-18.7-1.1
            c-12.6-0.9-25.1-2-37.7-2.6c-1.6-0.1-3.2-0.1-4.7-0.2C338.5,89.3,338.5,91.2,339.7,91.2L339.7,91.2z"
        />
        <path
            fill="#42515A"
            d="M507.8,97.9c-1.2,20.8-2.3,41.7-3.5,62.5c-1.2,20.7-2.3,41.5-3.5,62.2c-0.7,11.6-1.3,23.2-1.9,34.7
            c-0.1,1.2,1.8,1.2,1.8,0c1.2-20.6,2.3-41.2,3.5-61.8c1.2-20.8,2.3-41.6,3.5-62.4c0.7-11.7,1.3-23.5,2-35.2
            C509.7,96.7,507.9,96.7,507.8,97.9L507.8,97.9z"
        />
        <path
            fill="#42515A"
            d="M345.1,87.7c12.3,0.3,24.4,1.1,36.7,1.9c11.7,0.8,23.3,1.6,35.1,2c6,0.3,11.9,0.1,17.8,0.3
            c6.5,0.3,13.1,0.7,19.6,1.2c6.5,0.5,13.2,0.9,19.7,1c1.7,0,3.4,0.1,5,0.1c1.3,0,2.9-0.2,4.2,0c1.4,0.2,2.9,0.3,4.4,0.4
            c8,0.6,15.9,0.8,23.9,1h1.9c0.8,0,0.8-1.3,0-1.3c-7.7-0.1-15.4-0.4-23.1-0.8c-1.8-0.1-3.5-0.3-5.2-0.3c-0.7-0.1-1.3-0.2-1.9-0.3
            c-1.4-0.2-2.9,0-4.3,0c-6.6,0-13.2-0.3-19.7-0.8c-6.5-0.4-13-0.9-19.6-1.3c-3.3-0.2-6.5-0.3-9.8-0.3c-3,0-6,0-9.1-0.1
            c-11.5-0.3-23-1.1-34.5-1.8c-12.2-0.8-24.3-1.6-36.6-2c-1.5-0.1-3-0.1-4.4-0.2C344.2,86.2,344.2,87.6,345.1,87.7L345.1,87.7z"
        />
        <path
            fill="#42515A"
            d="M512.9,95.1c-1.2,20.6-2.3,41.2-3.3,61.9c-1,20.3-2.1,40.6-3.1,61c-0.6,11.4-1.3,22.8-1.8,34.2
            c0,0.8,1.3,0.8,1.3,0c1.2-20.3,2.2-40.7,3.3-61c1-20.4,2.1-40.8,3.1-61.2c0.6-11.6,1.3-23.2,1.9-34.8
            C514.4,94.2,512.9,94.2,512.9,95.1L512.9,95.1z"
        />
        <path
            fill="#42515A"
            d="M326.6,247.9c11,1.4,22.2,1.9,33.2,2.6l33.7,2.2c22.5,1.4,45,2.9,67.4,4.4c12.3,0.8,24.8,2,37.3,2.1
            c1.4,0,1.3-2,0-2.2c-10.9-1.3-22-1.7-33-2.3l-33.6-2.2c-22.5-1.4-45-2.9-67.6-4.3c-12.4-0.8-25-1.9-37.5-1.9
            C325.5,246.2,325.6,247.7,326.6,247.9z"
        />
        <path
            fill="#42515A"
            d="M338.9,117.9c5.5,0.7,11.2,0.8,16.7,1.1l16,0.8l32.7,1.7l65.1,3.4c12.6,0.7,25.1,1.4,37.7,1.8
            c1,0,1-1.4,0-1.5c-22.1-1.6-44.2-2.3-66.3-3.5l-64.9-3.4l-18.2-0.9c-6.3-0.3-12.6-0.9-18.9-0.7C338,116.8,338.1,117.9,338.9,117.9 L338.9,117.9z"
        />
        <polygon fill="#04508B" points="358.8,174 344.2,173.2 345,159.8 359.6,160.6" />
        <polygon fill="#04508B" points="448.3,179.1 433.5,178.2 434.3,164.8 449.1,165.5" />
        <polygon fill="#04508B" points="471.1,180.4 456.2,179.5 457,165.9 471.9,166.8" />
        <polygon fill="#04508B" points="493.8,181.6 478.9,180.8 479.6,167.2 494.6,168" />
        <polygon fill="#04508B" points="381,175.3 366.3,174.4 367.2,161 381.8,161.8" />
        <polygon fill="#04508B" points="403.4,176.5 388.7,175.7 389.6,162.2 404.2,163.1" />
        <polygon fill="#04508B" points="449.7,159.1 434.9,158.3 435.6,144.8 450.5,145.6" />
        <polygon fill="#04508B" points="427.1,157.9 412.3,157.1 413.2,143.6 427.9,144.5" />
        <polygon fill="#04508B" points="472.3,160.4 457.4,159.6 458.2,146.1 473.1,146.8" />
        <polygon fill="#04508B" points="495.1,161.6 480.2,160.8 481,147.2 495.9,148.1" />
        <polygon fill="#04508B" points="425.9,177.9 411.1,176.9 411.9,163.5 426.7,164.3" />
        <polygon fill="#04508B" points="357.4,193.8 342.8,193 343.7,179.5 358.3,180.4" />
        <polygon fill="#04508B" points="447.2,199.1 432.4,198.3 433.2,184.7 448.1,185.5" />
        <polygon fill="#04508B" points="470,200.4 455.1,199.5 455.9,186 470.8,186.8" />
        <polygon fill="#04508B" points="492.8,201.8 477.8,200.9 478.6,187.3 493.5,188.2" />
        <polygon fill="#04508B" points="379.8,195.1 365.1,194.3 366,180.8 380.7,181.6" />
        <polygon fill="#04508B" points="402.2,196.5 387.5,195.6 388.4,182.1 403.2,183" />
        <polygon fill="#04508B" points="424.7,197.8 410,196.9 410.8,183.4 425.6,184.2" />
        <polygon fill="#04508B" points="356.2,213.7 341.5,212.9 342.4,199.3 357,200.2" />
        <polygon fill="#04508B" points="446,219.1 431.2,218.2 432,204.7 446.9,205.6" />
        <polygon fill="#04508B" points="468.9,220.6 453.9,219.7 454.7,206.1 469.6,206.9" />
        <polygon fill="#04508B" points="491.6,221.9 476.6,221 477.4,207.4 492.4,208.2" />
        <polygon fill="#04508B" points="378.6,215.1 363.9,214.2 364.7,200.7 379.4,201.5" />
        <polygon fill="#04508B" points="401.1,216.5 386.3,215.5 387.1,202 401.9,202.9" />
        <polygon fill="#04508B" points="423.6,217.8 408.7,216.9 409.5,203.4 424.4,204.2" />
        <polygon fill="#04508B" points="354.8,233.7 340.2,232.7 341.1,219.2 355.7,220.2" />
        <polygon fill="#04508B" points="444.9,239.3 429.9,238.4 430.8,224.8 445.6,225.6" />
        <polygon fill="#04508B" points="467.7,240.7 452.7,239.8 453.5,226.1 468.5,227" />
        <polygon fill="#04508B" points="377.2,235.1 362.5,234.2 363.5,220.6 378.1,221.5" />
        <polygon fill="#04508B" points="399.8,236.5 385,235.5 385.9,222 400.6,222.8" />
        <polygon fill="#04508B" points="422.3,237.9 407.5,236.9 408.3,223.3 423.1,224.3" />
        <path
            fill="#42515A"
            d="M356.2,95.6c-0.2-0.4,0-1.2-0.2-1.7c-0.3-0.9-0.6-1.8-0.8-2.9c-0.4-2.2-0.3-4.4,1.4-6
            c1.3-1.2,3.3-1.9,5-1.3c1.9,0.7,1.8,2.4,2.5,3.9c0.3,0.3,0.7,0.4,1,0.3c0.1-0.1,0.2-0.2,0.3-0.3c1-1.9-0.4-4.4-2.2-5.5
            c-2.1-1.3-4.7-0.8-6.7,0.3c-2.3,1.3-3.7,4-3.5,6.7c0.1,1.4,0.3,2.9,0.9,4.2c0.2,0.6,0.4,1.2,0.8,1.7c0.3,0.3,0.7,0.4,0.9,0.7
            C355.8,96.1,356.2,95.9,356.2,95.6L356.2,95.6z"
        />
        <path
            fill="#42515A"
            d="M377.5,96.6c-0.2-0.4,0-1.2-0.2-1.7c-0.3-0.9-0.6-1.8-0.8-2.9c-0.4-2.2-0.3-4.4,1.4-6
            c1.3-1.2,3.3-1.9,5-1.3c1.9,0.7,1.7,2.4,2.5,3.9c0.3,0.3,0.7,0.4,1,0.3c0.1-0.1,0.2-0.2,0.3-0.3c1-1.9-0.4-4.4-2.1-5.5
            c-2.1-1.3-4.7-0.8-6.7,0.3c-2.3,1.3-3.7,4-3.5,6.7c0.1,1.4,0.3,2.9,0.9,4.2c0.2,0.6,0.4,1.2,0.8,1.7c0.3,0.3,0.7,0.4,0.9,0.7
            C377.1,97.2,377.6,97,377.5,96.6L377.5,96.6z"
        />
        <path
            fill="#42515A"
            d="M398.6,98.2c-0.2-0.4,0-1.2-0.2-1.7c-0.3-0.9-0.6-1.8-0.8-2.9c-0.4-2.2-0.3-4.4,1.4-6
            c1.3-1.2,3.3-1.9,5-1.3c1.9,0.7,1.8,2.4,2.5,3.9c0.3,0.3,0.7,0.4,1,0.3c0.1-0.1,0.2-0.2,0.3-0.3c1-1.9-0.4-4.4-2.2-5.5
            c-2.1-1.3-4.7-0.8-6.7,0.3c-2.3,1.3-3.7,4-3.5,6.8c0.1,1.4,0.3,2.9,0.9,4.2c0.2,0.6,0.4,1.1,0.8,1.7c0.3,0.3,0.7,0.4,0.9,0.7
            C398.4,98.8,398.8,98.5,398.6,98.2L398.6,98.2z"
        />
        <path
            fill="#42515A"
            d="M423,99.5c-0.2-0.4,0-1.2-0.2-1.7c-0.3-0.9-0.6-1.8-0.8-2.9c-0.4-2.2-0.3-4.4,1.4-6c1.3-1.2,3.3-1.9,5-1.3
            c1.9,0.7,1.8,2.4,2.5,3.9c0.3,0.3,0.7,0.4,1,0.3c0.1-0.1,0.2-0.2,0.3-0.3c1-1.9-0.4-4.4-2.2-5.5c-2.1-1.3-4.7-0.8-6.7,0.3
            c-2.3,1.3-3.7,4-3.5,6.7c0.1,1.4,0.3,2.9,0.9,4.2c0.2,0.6,0.4,1.2,0.8,1.7c0.3,0.3,0.7,0.4,0.9,0.7C422.7,100,423.1,99.7,423,99.5
            L423,99.5z"
        />
        <path
            fill="#42515A"
            d="M446.1,100.1c-0.2-0.4,0-1.2-0.2-1.7c-0.3-0.9-0.6-1.8-0.8-2.9c-0.4-2.2-0.3-4.4,1.4-6
            c1.3-1.2,3.3-1.9,5-1.3c1.9,0.7,1.7,2.4,2.5,3.9c0.3,0.3,0.7,0.4,1,0.3c0.1-0.1,0.2-0.2,0.3-0.3c1-1.9-0.4-4.4-2.2-5.5
            c-2.1-1.3-4.7-0.8-6.7,0.3c-2.3,1.3-3.7,4-3.5,6.7c0.1,1.4,0.3,2.9,0.9,4.2c0.2,0.6,0.4,1.1,0.8,1.7c0.3,0.3,0.7,0.4,0.9,0.7
            C445.8,100.6,446.2,100.5,446.1,100.1L446.1,100.1z"
        />
        <path
            fill="#42515A"
            d="M468.7,101.5c-0.2-0.4,0-1.2-0.2-1.7c-0.3-0.9-0.6-1.8-0.8-2.9c-0.4-2.2-0.3-4.4,1.4-6
            c1.3-1.2,3.3-1.9,5-1.3c1.9,0.7,1.8,2.4,2.5,3.9c0.3,0.3,0.7,0.4,1,0.3c0.1-0.1,0.2-0.2,0.3-0.3c1-1.9-0.4-4.4-2.2-5.5
            c-2.1-1.3-4.7-0.8-6.7,0.3c-2.3,1.3-3.7,4-3.5,6.7c0.1,1.4,0.3,2.9,0.9,4.2c0.2,0.6,0.4,1.1,0.8,1.7c0.3,0.3,0.7,0.4,0.9,0.7
            C468.4,102.1,468.8,101.8,468.7,101.5L468.7,101.5z"
        />
        <path
            fill="#42515A"
            d="M491.1,102.9c-0.2-0.4,0-1.2-0.2-1.7c-0.3-0.9-0.6-1.8-0.8-2.9c-0.4-2.2-0.3-4.4,1.4-6
            c1.3-1.2,3.3-1.9,5-1.3c1.9,0.7,1.7,2.4,2.5,3.9c0.3,0.3,0.7,0.4,1,0.3c0.1-0.1,0.2-0.2,0.3-0.3c1-1.9-0.4-4.4-2.2-5.5
            c-2.1-1.3-4.7-0.8-6.7,0.3c-2.3,1.3-3.7,4-3.5,6.7c0.1,1.4,0.3,2.9,0.9,4.2c0.2,0.6,0.4,1.1,0.8,1.7c0.3,0.3,0.7,0.4,0.9,0.7
            C490.8,103.4,491.2,103.2,491.1,102.9L491.1,102.9z"
        />
        <path
            fill="#42515A"
            d="M344.7,86.5c-2,1-3.9,2.3-6,3.2c-0.3,0.1-0.6,0.5-0.5,0.8s0.5,0.6,0.8,0.5c2.3-0.8,4.3-2.3,6.4-3.3
            c0.3-0.2,0.4-0.6,0.3-0.9C345.5,86.4,345.1,86.2,344.7,86.5L344.7,86.5z"
        />
        <path
            fill="#42515A"
            d="M508.4,98.5c0.9-0.4,1.8-0.8,2.7-1.2c1-0.4,1.9-1,2.7-1.8c0.7-0.7-0.3-1.6-1-1c-0.5,0.5-1.1,1-1.8,1.3
            c-0.2,0.1-0.3,0.2-0.5,0.3c-1,0.3-1.9,0.8-2.9,1.3C507,97.8,507.6,99,508.4,98.5L508.4,98.5z"
        />
        <path
            fill="#42515A"
            d="M504.9,251.6c-0.8,1-1.8,1.9-2.7,2.9c-1.1,1-2.2,2-3.3,3c-0.7,0.6,0.3,1.6,1,1c1.1-1,2.2-2,3.3-3
            c1-0.9,1.9-1.8,2.7-2.9c0.3-0.3,0.3-0.7,0-1C505.5,251.4,505.1,251.4,504.9,251.6z"
        />
        <path
            fill="#FFC000"
            d="M160.4,187.7c0.1-1,0.4-2,0.8-3c0.5-1,1.3-1.8,2.3-2.3l-0.3-0.6c0,0-4-6.3-6.4-9.1
            c-1.6-1.8-5.9,4.4-6.6,5.9c-1.1,2.3-3.3,7.4-1.1,9.1C152.8,187.8,160.4,187.7,160.4,187.7z M152.1,185.4c-0.6,0-1.1,0-1.6,0
            c0.1-3.9,2.3-7.9,5.5-10.5c1.5,2.3,3.6,4.8,5.3,7c-0.6,1-1.8,2.1-2,3.4C156.5,185.3,154.4,185.3,152.1,185.4L152.1,185.4z"
        />
        <path
            fill="#FFC000"
            d="M182,182.2c-1.3-4-3.9-8.6-8.2-9.7c-2.2,3.1-6.2,10-6.2,10c0.8,0.3,1.6,1,2,1.8c0.4,0.8,0.8,1.8,0.9,2.7
            c0,0,8.6,0.1,10.4,0.6C183.9,187.9,182.4,184,182,182.2z M179.6,185.4c-2.5-0.1-5-0.1-7.6,0.1c-0.5-1.1-1.2-2.1-2-3
            c1.8-2.9,3-5,4.5-7.4c3.4,2.3,5.3,6.3,5.8,10.3C180.2,185.4,179.8,185.4,179.6,185.4L179.6,185.4z"
        />
        <path
            fill="#FFC000"
            d="M174.5,202.1c-1.7-3.4-5.6-10.5-5.6-10.5s-2,1-3,0.8c-0.8,0.1-1.9,0-2.6-0.4c-2,3-3.9,6.8-5.5,10.2
            C157,206.2,174.5,206.3,174.5,202.1z M170.1,202.6c-3.1,1-6.9,0.7-10-0.6c1.3-2.4,2.9-4.9,4-7.4c1.2,0.1,2.4,0,3.6-0.3
            c1.7,2.9,2.9,5,4.3,7.6C171.4,202.2,170.8,202.4,170.1,202.6z"
        />
        <path
            fill="#FFC000"
            d="M162,187.8c0.6,4.5,8,3.9,6.8-1C167.6,181.9,162,183.2,162,187.8z M164.1,186.8c1.3-3,4.3,1.6,1.9,2.1
            C163.9,189.4,163.9,187.9,164.1,186.8z"
        />
        <path
            fill="#FFBF00"
            d="M198.1,189c-0.3,0.4-0.4,0.9-0.8,1.3c-0.3,0.3-0.5,0.8-0.7,1.2c-0.3,0.9-0.7,1.8-1.1,2.8
            c-0.4,0.9-0.8,1.7-1.3,2.5l-0.2,0.3c0.2-5.5-0.1-11.2-0.1-16.7c0-0.4,0-0.9,0-1.3c0-0.1,0-0.2,0-0.3c0.2-0.3,0.2-0.6,0-0.8
            c-0.3-0.3-0.7-0.5-1-0.4c0-0.1,0-0.2,0-0.3c0-0.2-0.3-0.2-0.3,0v0.4c0,0-0.1,0.1-0.1,0.2c-0.2,0.3-0.2,0.7-0.2,0.9
            c-0.1,1,0,2.1,0,3.1c0,1.5,0,2.9,0.1,4.4c0,0.3,0,0.8,0,1.2c0,2.7,0,5.5,0,8.2c-0.2-0.3-0.4-0.7-0.6-0.9c-0.5-0.7-1-1.4-1.6-2.1
            c-0.6-0.7-1-1.3-1.7-2c-0.2-0.2-0.5-0.2-0.7,0.1c-0.1,0.1-0.1,0.3-0.1,0.3c0.3,0.8,0.6,1.5,0.9,2.3c0.3,0.8,0.7,1.7,1.1,2.4
            c0.8,1.5,1.7,3,2.7,4.4c0.4,0.7,1.4,0.8,1.8,0c1-1.8,2-3.4,2.9-5.4c0.4-0.9,0.8-1.9,1.1-2.9c0.3-1,0.2-2,0.3-3
            c0-0.2-0.1-0.3-0.3-0.4C198.3,188.9,198.2,188.9,198.1,189L198.1,189z M193.4,179.2L193.4,179.2L193.4,179.2L193.4,179.2z"
        />
    </svg>
);

export default RadonInsightIllustration;
