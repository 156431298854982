import React, { RefObject } from 'react';
import { ClickAwayListener, Grow, MenuItem, Paper, MenuList, Typography } from '@mui/material';
import Popper from '@mui/material/Popper';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DeviceIcon from 'commons/src/img/deviceIcons/icon_device';
import HubIcon from 'commons/src/img/deviceIcons/icon_hub';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import {
    DeviceType,
    HubData,
    FloorPlanType,
    DevicesTypeWithKey,
    AnyDeviceType,
    BuildingType,
} from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';
import style from './DeviceList.module.scss';

type StateProps = {
    hubs: HubData[];
    floorplans: { [locationId: string]: FloorPlanType[] };
    building: BuildingType;
    devices: DevicesTypeWithKey;
};

type ParentProps = {
    onSelectDevice: (serialNumber: string, deviceType: AnyDeviceType) => void;
    onCancel: () => void;
    locationId: string;
    isOpen: boolean;
    anchorRef: RefObject<HTMLDivElement>;
};

type DeviceListProps = ParentProps & StateProps;

export const DeviceListComponent = ({
    floorplans,
    building,
    onSelectDevice,
    onCancel,
    hubs,
    locationId,
    devices,
    isOpen,
    anchorRef,
}: DeviceListProps): React.ReactElement => {
    const { t: txt } = useTranslation();

    const handleClose = (event: Event | React.SyntheticEvent): void => {
        if (anchorRef?.current?.contains(event.target as HTMLElement)) {
            return;
        }
        onCancel();
    };

    const handleListKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === 'Tab') {
            event.preventDefault();
            onCancel();
        } else if (event.key === 'Escape') {
            onCancel();
        }
    };

    const createListElement = (
        serialNumber: string,
        deviceType: AnyDeviceType,
        name: string,
        index: number
    ): React.ReactElement => {
        return (
            <MenuItem
                key={index}
                className="device-list__item"
                onClick={(): void => onSelectDevice(serialNumber, deviceType)}
            >
                <Typography variant="inherit" noWrap>
                    {deviceType === DeviceTypeNames.hub ? <HubIcon /> : <DeviceIcon />}
                    {name}
                </Typography>
            </MenuItem>
        );
    };

    const floorplan = floorplans[locationId] || [];

    const availableDevices: DeviceType[] = building
        ? building.devices
              .filter(
                  serialNumber =>
                      !floorplan.find(floor =>
                          floor.zones.find(zone => !!zone.device && zone.device.serialNumber === serialNumber)
                      )
              )
              .map(serialNumber => devices[serialNumber])
              .filter(device => device)
              .sort((device1, device2) => device1.segmentName.localeCompare(device2.segmentName))
        : [];

    const availableHubs = hubs
        .filter(hub => {
            const hubInLocation = hub.locationId === locationId;
            let inFloorPlan = false;
            if (hubInLocation && building) {
                inFloorPlan = !!floorplan.find(floor =>
                    floor.zones.find(zone => !!zone.device && zone.device.serialNumber === hub.serialNumber)
                );
            }
            return hubInLocation && !inFloorPlan;
        })
        .sort((hub1, hub2) => hub1.name.localeCompare(hub2.name));

    const devicesElement = (): React.ReactElement | (React.ReactElement[] | React.ReactElement)[] => {
        if (availableDevices.length === 0 && availableHubs.length === 0) {
            return <div className="device-list__item device-list__item--empty">{txt('NoAvailableDevices')}</div>;
        }

        const hubListElements = availableHubs.map((device, index) =>
            createListElement(device.serialNumber, device.deviceType, device.name, index)
        );
        const deviceListElements = availableDevices.map((device, index) =>
            createListElement(device.serialNumber, device.type, device.segmentName, index + availableHubs.length - 1)
        );

        return [...hubListElements, deviceListElements];
    };

    return (
        <Popper
            open={isOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            className={style.wrapper}
        >
            {({ TransitionProps, placement }): React.ReactElement => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                >
                    <Paper className={style.paper}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                autoFocusItem={isOpen}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleListKeyDown}
                            >
                                {devicesElement()}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};

const mapStateToProps = (state: Store, parentProps: ParentProps): StateProps => {
    const {
        devices: { hubs, devices },
        floorplans: { floorplans },
        buildings: { buildings },
    } = state;

    const building = buildings[parentProps.locationId];
    return {
        hubs,
        floorplans,
        building,
        devices,
    };
};

export default connect(mapStateToProps)(DeviceListComponent);
