import { LocationsStatusResponse } from '../models/commonTypeScript';
import { CommonRequestType as RequestType } from '../reducers/requestReducer';
import { RequestActionType } from './requestActions';

export enum LocationStatusActions {
    FetchLocationsStatusInit = 'FETCH_LOCATIONS_STATUS/INIT',
    FetchLocationsStatusSuccess = 'FETCH_LOCATIONS_STATUS_SUCCESS',
    StopPollingLocationsStatus = 'STOP_POLLING_LOCATIONS_STATUS',
}

export interface FetchLocationsStatus {
    type: LocationStatusActions.FetchLocationsStatusInit;
    requestType: RequestType.FetchLocationsStatus;
    requestActionType: RequestActionType.Init;
}
export const fetchLocationsStatus = (): FetchLocationsStatus => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.FetchLocationsStatus,
    type: LocationStatusActions.FetchLocationsStatusInit,
});

export interface FetchLocationsStatusSuccess {
    response: LocationsStatusResponse;
    type: LocationStatusActions.FetchLocationsStatusSuccess;
    requestType: RequestType.FetchLocationsStatus;
    requestActionType: RequestActionType.Success;
}
export const fetchLocationsStatusSuccess = (response: LocationsStatusResponse): FetchLocationsStatusSuccess => ({
    requestActionType: RequestActionType.Success,
    requestType: RequestType.FetchLocationsStatus,
    type: LocationStatusActions.FetchLocationsStatusSuccess,
    response,
});

export interface StopPollingLocationsStatus {
    type: LocationStatusActions.StopPollingLocationsStatus;
}
export const stopPollingLocationsStatus = (): StopPollingLocationsStatus => ({
    type: LocationStatusActions.StopPollingLocationsStatus,
});

export type LocationsStatusAction = FetchLocationsStatusSuccess;
