import { takeEvery, call, put } from 'redux-saga/effects';
import {
    VALIDATE_SERIALNUMBER,
    validateSerialnumberSuccess,
    validateSerialnumberError,
    ValidateSerialnumber,
} from 'commons/src/actions/AddDeviceActions';
import { verifySerialNumber } from 'commons/src/api/devices';
import { toErrorType } from 'commons/src/sagas/isErrorType';

export function* validateSerialNumberSaga({ serialNumber, checkCode }: ValidateSerialnumber): Generator {
    try {
        yield call(verifySerialNumber, serialNumber, checkCode);
        yield put(validateSerialnumberSuccess({ serialNumber, checkCode }));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(validateSerialnumberError({ error: errorAsErrorType, serialNumber, checkCode }));
    }
}

export default function* ValidateSerialNumbersSagas(): Generator {
    yield takeEvery(VALIDATE_SERIALNUMBER, validateSerialNumberSaga);
}
