import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../buttons/PrimaryButton';
import styles from './DropdownList.module.scss';

type Props = {
    title: string;
    onSelect: (id: string) => void;
    options: { id: string; text: string }[];
    id: string;
    onButtonClick?: () => void;
    buttonText?: string;
};

const DropdownList = ({ options, onSelect, title, id, onButtonClick, buttonText }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className={styles.listWrapper}>
            <label htmlFor={id} className={styles.header}>
                {txt(title)}
            </label>
            <div
                id={id}
                role="list"
                className={classNames(styles.list, {
                    [styles.noSpacesList]: options.length === 0,
                })}
            >
                {options.length > 0 ? (
                    options.map(optionElement => (
                        <div
                            role="button"
                            id={optionElement.id}
                            tabIndex={0}
                            onKeyUp={(e): void => {
                                if (e.key === 'Enter') onSelect(optionElement.id);
                            }}
                            onClick={(): void => onSelect(optionElement.id)}
                            key={`dropdown-option-element-${optionElement.id}`}
                            className={styles.listElement}
                        >
                            {txt(optionElement.text)}
                        </div>
                    ))
                ) : (
                    <div className={styles.noSpaces}>{txt('SpaceFloorPlan.NoSpaces')}</div>
                )}
            </div>
            {onButtonClick && buttonText && (
                <div className={styles.button}>
                    <PrimaryButton onClick={onButtonClick} color="primary" title={txt(buttonText)} />
                </div>
            )}
        </div>
    );
};

export default DropdownList;
