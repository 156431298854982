import React from 'react';

const IconVoc = (): React.ReactElement => (
    <svg id="iconVoc" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 15C1 14.4477 1.4766 14 2.06452 14H17.6774C20.617 14 23 16.2386 23 19C23 21.7614 20.617 24 17.6774 24C14.7378 24 12.3548 21.7614 12.3548 19C12.3548 18.4477 12.8314 18 13.4194 18C14.0073 18 14.4839 18.4477 14.4839 19C14.4839 20.6569 15.9137 22 17.6774 22C19.4412 22 20.871 20.6569 20.871 19C20.871 17.3431 19.4412 16 17.6774 16H2.06452C1.4766 16 1 15.5523 1 15Z" />
        <path d="M1 9C1 9.55228 1.4766 10 2.06452 10H17.6774C20.617 10 23 7.76142 23 5C23 2.23858 20.617 -7.45058e-08 17.6774 -7.45058e-08C14.7378 -7.45058e-08 12.3548 2.23858 12.3548 5C12.3548 5.55228 12.8314 6 13.4194 6C14.0073 6 14.4839 5.55228 14.4839 5C14.4839 3.34315 15.9137 2 17.6774 2C19.4412 2 20.871 3.34315 20.871 5C20.871 6.65685 19.4412 8 17.6774 8H2.06452C1.4766 8 1 8.44772 1 9Z" />
    </svg>
);

export default IconVoc;
