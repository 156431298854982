import React from 'react';
import Modal from 'react-modal';
import ModalHeader from 'commons/src/components/modals/ModalHeader';
import { InvitedMember, Member } from '../../../models/common';
import InviteUserForm from './InviteUserForm';

export type Props = {
    onClose: () => void;
    isOpen: boolean;
    userToEdit: InvitedMember | Member;
};

const EditUserModal = ({ onClose, isOpen, userToEdit }: Props): React.ReactElement => {
    return (
        <Modal
            isOpen={isOpen}
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--size-medium"
            overlayClassName="modal modal__overlay"
        >
            <ModalHeader headerText="UserManagementSettings.EditUser" onClose={onClose} />
            <InviteUserForm inModalView={isOpen} userToEdit={userToEdit} />
        </Modal>
    );
};

export default EditUserModal;
