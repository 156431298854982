import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from 'commons/src/commonFunctions';
import CircleButton from 'commons/src/components/buttons/CircleButton';
import InputInfo from 'commons/src/components/input/InputInfo';
import { accountsRedirectUri } from 'commons/src/config';
import styles from './SSOClientContainer.module.scss';

export const RedirectUriToRegister = (): React.ReactElement => {
    const { t: txt } = useTranslation();

    const [displayConfirmCopy, setDisplayConfirmCopy] = useState(false);
    const onCopyToClipboard = (record: string): void => {
        copyToClipboard(record);
        setDisplayConfirmCopy(true);
    };

    return (
        <div className="form__row">
            <div className="form__field form__field--medium-width">
                <div className={styles.redirectBox}>
                    <div className={styles.redirectLabelBox}>
                        <div className={styles.redirectLabel}>{txt('SingleSignOn.RedirectUriToRegister')}</div>
                        <InputInfo infoText="SsoInfoText.RedirectUriInfo" />
                    </div>
                    <span>{accountsRedirectUri}</span>
                </div>
                <CircleButton
                    onClick={(): void => onCopyToClipboard(accountsRedirectUri)}
                    testAttr="copy-text-record"
                    iconName="content_copy"
                    color={displayConfirmCopy ? 'confirmed' : 'secondary'}
                />
            </div>
        </div>
    );
};

export default RedirectUriToRegister;
