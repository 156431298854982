import { RequestActionType } from 'commons/src/actions/requestActions';
import { FloorPlanType } from 'commons/src/models/commonTypeScript';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum FloorplanActionType {
    FetchLocationFloorplansInit = 'FETCH_LOCATION_FLOORPLANS/INIT',
    FetchLocationFloorplansSuccess = 'FETCH_LOCATION_FLOORPLANS/SUCCESS',
    UpdateFloorNameInit = 'UPDATE_FLOOR_NAME/INIT',
    UpdateFloorNameSuccess = 'UPDATE_FLOOR_NAME/SUCCESS',
    FetchFloorplanImageInit = 'FETCH_FLOORPLAN_IMAGE/INIT',
    FetchFloorplanImageSuccess = 'FETCH_FLOORPLAN_IMAGE/SUCCESS',
    FetchFloorplanImageError = 'FETCH_FLOORPLAN_IMAGE/ERROR',
    SetFloorplanModalVisisbleInit = 'SET_FLOORPLAN_MODAL_VISIBLE/INIT',
    SetActiveFloorplanInit = 'SET_ACTIVE_FLOORPLAN/INIT',
}

export type FetchLocationFloorplans = {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.FetchLocationFloorplans;
    type: FloorplanActionType.FetchLocationFloorplansInit;
    locationId: string;
};
export const fetchLocationFloorplans = (locationId: string): FetchLocationFloorplans => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.FetchLocationFloorplans,
    type: FloorplanActionType.FetchLocationFloorplansInit,
    locationId,
});

export type FetchLocationFloorplansSuccess = {
    type: FloorplanActionType.FetchLocationFloorplansSuccess;
    locationId: string;
    floorplans: FloorPlanType[];
};
export const fetchLocationFloorplansSuccess = (
    locationId: string,
    floorplans: FloorPlanType[]
): FetchLocationFloorplansSuccess => ({
    type: FloorplanActionType.FetchLocationFloorplansSuccess,
    locationId,
    floorplans,
});

export type FetchFloorplanImage = {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.FetchFloorplanImage;
    type: FloorplanActionType.FetchFloorplanImageInit;
    locationId: string;
    floorplanId: string;
};
export const fetchFloorplanImage = (locationId: string, floorplanId: string): FetchFloorplanImage => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.FetchFloorplanImage,
    type: FloorplanActionType.FetchFloorplanImageInit,
    locationId,
    floorplanId,
});

export type FetchFloorplanImageSuccess = {
    type: FloorplanActionType.FetchFloorplanImageSuccess;
    locationId: string;
    floorplanId: string;
    image: string;
};
export const fetchFloorplanImageSuccess = (
    locationId: string,
    floorplanId: string,
    image: string
): FetchFloorplanImageSuccess => ({
    type: FloorplanActionType.FetchFloorplanImageSuccess,
    locationId,
    floorplanId,
    image,
});

export type FetchFloorplanImageError = {
    type: FloorplanActionType.FetchFloorplanImageError;
    locationId: string;
};
export const fetchFloorplanImageError = (locationId: string): FetchFloorplanImageError => ({
    type: FloorplanActionType.FetchFloorplanImageError,
    locationId,
});

export type SetFloorplanModalVisible = {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.SetFloorplanModalVisisble;
    type: FloorplanActionType.SetFloorplanModalVisisbleInit;
    visible: boolean;
};
export const setFloorplanModalVisible = (visible: boolean): SetFloorplanModalVisible => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.SetFloorplanModalVisisble,
    type: FloorplanActionType.SetFloorplanModalVisisbleInit,
    visible,
});

export interface SetActiveFloorPlan {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.SetActiveFloorplan;
    type: FloorplanActionType.SetActiveFloorplanInit;
    floorId: string | undefined;
}
export const setActiveFloorPlan = (floorId?: string): SetActiveFloorPlan => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.SetActiveFloorplan,
    type: FloorplanActionType.SetActiveFloorplanInit,
    floorId,
});

export type UpdateFloorName = {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.UpdateFloorName;
    type: FloorplanActionType.UpdateFloorNameInit;
    floorId: string;
    name: string;
    locationId: string;
};
export const updateFloorName = (floorId: string, name: string, locationId: string): UpdateFloorName => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.UpdateFloorName,
    type: FloorplanActionType.UpdateFloorNameInit,
    floorId,
    name,
    locationId,
});

export interface UpdateFloorNameSuccess {
    type: typeof FloorplanActionType.UpdateFloorNameSuccess;
    floorId: string;
    name: string;
    locationId: string;
}
export const updateFloorNameSuccess = (floorId: string, name: string, locationId: string): UpdateFloorNameSuccess => ({
    type: FloorplanActionType.UpdateFloorNameSuccess,
    floorId,
    name,
    locationId,
});

export type FloorplanAction =
    | FetchLocationFloorplans
    | FetchFloorplanImage
    | SetActiveFloorPlan
    | SetFloorplanModalVisible;

export type FloorplanReducerAction =
    | FetchLocationFloorplans
    | FetchFloorplanImage
    | SetActiveFloorPlan
    | SetFloorplanModalVisible
    | UpdateFloorName
    | UpdateFloorNameSuccess
    | FetchFloorplanImageSuccess
    | FetchFloorplanImageError
    | FetchLocationFloorplansSuccess;
