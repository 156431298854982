import { SHOW_ALERT_BOX, HIDE_ALERT_BOX, IndexReducerActions } from '../actions';

export type NotificationBoxState = {
    display: boolean;
    message: string;
    error: boolean;
    fade: boolean;
};
const initialState: NotificationBoxState = {
    display: false,
    message: '',
    error: false,
    fade: false,
};

export default (state = initialState, action: IndexReducerActions): NotificationBoxState => {
    switch (action.type) {
        case SHOW_ALERT_BOX:
            return {
                ...state,
                display: true,
                message: action.message,
                error: action.error,
                fade: !!action.fade,
            };
        case HIDE_ALERT_BOX:
            return {
                ...state,
                display: false,
                error: false,
            };
        default:
            return state;
    }
};
