import React from 'react';
import { useTranslation } from 'react-i18next';

const NoConnection = ({ text, translated }: { text?: string; translated?: boolean }): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <span>
            <span className="sensor__line sensor__line--alert" />
            <span>{translated && text ? text : txt(text || 'NoHubConnection')}</span>
        </span>
    );
};

export default NoConnection;
