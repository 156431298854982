/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { colors } from 'commons/src/constants';

const generateDetailsConfig = (
    chartData: number[],
    lowerLimitChartData: number[],
    categories: string[],
    devicesInPoint: { serialNumber: string; name: string; overThresholdsTime: number; underThresholdsTime: number }[],
    onClick: (clickEvent: PointerEvent) => void,
    chartHeight: number,
    txt: (text: string) => string,
    displayLegends: boolean,
    highValue: string,
    unsetMax: boolean,
    allowGraphClick: boolean,
    graphId: string,
    lowValue?: string
) => ({
    credits: {
        enabled: false,
    },
    title: {
        text: '',
        margin: 0,
        floating: true,
    },
    chart: {
        id: graphId,
        animation: false,
        type: 'bar',
        height: chartHeight,
        className: 'building-insight__card__section__graph--details',
        spacingBottom: 5,
        style: {
            fontFamily: 'OpenSans-Regular',
        },
    },
    tooltip: {
        enabled: false,
    },
    xAxis: {
        type: 'category',
        categories,
        title: {
            text: null,
        },
        labels: {
            formatter(): string {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const { value } = this;
                const deviceProps = devicesInPoint.find(device => device.serialNumber === value);
                return (deviceProps && deviceProps.name) || '';
            },
        },
        min: 0,
        max: unsetMax ? undefined : 3,
        scrollbar: {
            enabled: true,
        },
    },
    yAxis: {
        min: 0,
        gridLineWidth: 1,
        title: {
            text: null,
        },
        tickInterval: 60,
        labels: {
            formatter(): string {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const { value } = this;
                const valueInHours = value / 60;
                return `${valueInHours}${txt('TimeUnit.HoursShort')}`;
            },
        },
    },
    legend: { enabled: displayLegends, align: 'center', verticalAlign: 'bottom' },
    series: [
        {
            data: chartData,
            name: `${txt('BuildingInsight.Over')} ${highValue}`,
            color: colors.redPersian,
            className: 'building-insight__card__section__graph',
        },
        {
            data: lowerLimitChartData,
            name: `${txt('BuildingInsight.Under')} ${lowValue}`,
            color: colors.blueCelestial,
            className: 'building-insight__card__section__graph--lower',
        },
    ],
    plotOptions: {
        series: {
            allowPointSelect: allowGraphClick,
            type: 'bar',
            stacking: 'normal',
            states: {
                hover: { lineWidthPlus: 0 },
            },
            animation: false,
            borderRadius: 4,
            events: {
                click(event: PointerEvent): void {
                    onClick(event);
                },
            },
        },
    },
    exporting: {
        sourceWidth: 800,
        sourceHeight: chartHeight,
        chartOptions: {
            subtitle: null,
        },
        buttons: {
            contextButton: {
                enabled: false,
            },
        },
    },
});

export default generateDetailsConfig;

export type GenerateDetailsConfig = ReturnType<typeof generateDetailsConfig>;
