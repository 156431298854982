import React from 'react';
import AccountPage from 'commons/src/features/settingsPage/AccountPage';
import { AccountCommonTabs } from 'commons/src/features/settingsPage/commonMenuConfig';
import { RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { TabOption } from 'commons/src/models/menuModels';
import { paths } from '../../constants';
import Overview from './Overview';

const ConsumerAccountsPage = (): React.ReactElement => {
    const consumerTabs: TabOption[] = [
        {
            text: 'Overview',
            path: `/${paths.settings}`,
            id: 'consumerSettingsOverview',
            testAttr: 'consumer-settings-overview',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            route: paths.settings,
            view: Overview,
            requiredGroupTypes: [],
        },
        ...AccountCommonTabs,
    ];

    return <AccountPage tabs={consumerTabs} />;
};

export default ConsumerAccountsPage;
