import fetchFromDashboardApi from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { FloorplanDevice, FloorPlanPosition, FloorPlanType } from 'commons/src/models/commonTypeScript';

export const addFloorplan = async (locationId: string, name: string, image: string): Promise<{ id: string }> => {
    const url = `/locations/${locationId}/floor-plans`;
    const floorplan = { name, image };
    const options = {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(floorplan),
    };

    return fetchFromDashboardApi<{ id: string }>(url, options);
};

export const deleteFloorplan = async (locationId: string, floorplanId: string): Promise<void> => {
    const url = `/locations/${locationId}/floor-plans/${floorplanId}`;
    const options = {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    };

    return fetchFromDashboardApi<void>(url, options);
};

export const getFloorplanImage = async (locationId: string, floorplanId: string): Promise<string> => {
    const url = `/locations/${locationId}/floor-plans/${floorplanId}/image`;
    const options = {
        method: 'GET',
        headers: await getFetchHeaders(),
    };

    const { image } = await fetchFromDashboardApi<{ image: string }>(url, options);
    return image;
};

export const addZoneToFloorplan = async (
    locationId: string,
    floorplanId: string,
    zoneBoundary: FloorPlanPosition[]
): Promise<{ id: string }> => {
    const url = `/locations/${locationId}/floor-plans/${floorplanId}/zone`;
    const options = {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ boundary: zoneBoundary }),
    };

    return fetchFromDashboardApi<{ id: string }>(url, options);
};

export const addDeviceToFloorplan = async (
    locationId: string,
    floorPlanId: string,
    zoneId: string,
    device: FloorplanDevice
): Promise<void> => {
    const url = `/locations/${locationId}/floor-plans/${floorPlanId}/zone/${zoneId}/device`;
    const options = {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(device),
    };

    return fetchFromDashboardApi<void>(url, options);
};

export const updateFloorPlanZone = async (
    locationId: string,
    floorPlanId: string,
    zoneId: string,
    boundary: FloorPlanPosition[]
): Promise<void> => {
    const url = `/locations/${locationId}/floor-plans/${floorPlanId}/zone/${zoneId}`;
    const options = {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ boundary }),
    };

    return fetchFromDashboardApi<void>(url, options);
};

export const deleteFloorPlanZone = async (locationId: string, floorPlanId: string, zoneId: string): Promise<void> => {
    const url = `/locations/${locationId}/floor-plans/${floorPlanId}/zone/${zoneId}`;
    const options = {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    };

    await fetchFromDashboardApi<void>(url, options);
};

export const updateFloorPlanZoneDevice = async (
    locationId: string,
    floorPlanId: string,
    zoneId: string,
    device: FloorplanDevice
): Promise<void> => {
    const url = `/locations/${locationId}/floor-plans/${floorPlanId}/zone/${zoneId}/device/${device.serialNumber}`;
    const options = {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ position: device.position }),
    };

    return fetchFromDashboardApi<void>(url, options);
};

export const updateFloorName = async (locationId: string, floorPlanId: string, name: string): Promise<void> => {
    const url = `/locations/${locationId}/floor-plans/${floorPlanId}`;
    const options = {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ name }),
    };
    await fetchFromDashboardApi<void>(url, options);
};

export const getLocationFloorPlans = async (locationId: string): Promise<{ floorPlans: FloorPlanType[] }> =>
    fetchFromDashboardApi(`/locations/${locationId}/floor-plans`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
