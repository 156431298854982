import React from 'react';
import classNames from 'classnames';
import L, { DivIcon, LatLng, LatLngExpression } from 'leaflet';
import { Marker, Polygon } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { deviceStatusColor } from 'commons/src/commonFunctions';
import { EnumStatusColors } from 'commons/src/models/commonEnums';
import { CurrentSensorValuesType, FloorPlanPosition } from 'commons/src/models/commonTypeScript';
import DeviceHealthStatus from 'commons/src/models/deviceHealthStatus';
import { SpaceFloorPlanMode } from '../../../models/spaceFloorPlanModels';
import { IndoorSpace, SpaceDevice } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import { spacesSelector } from '../../spaces/space/spaceSelectors';
import { FloorPlanMetadataSensors } from './FloorMapSensorsFilter';
import MarkerIcons from './MarkerIcons';
import styles from './SpaceZone.module.scss';

type Props = {
    zone: {
        id: string;
        boundary: FloorPlanPosition[];
    };
    selectedSpaceId: string | undefined;
    mode?: SpaceFloorPlanMode;
    selectedSensor?: string;
    locationId: string;
    setMode: (mode: SpaceFloorPlanMode | undefined) => void;
    setSelectedSpace: (spaceId: string) => void;
};

const SpaceZone = ({
    zone,
    selectedSpaceId,
    mode,
    selectedSensor,
    locationId,
    setMode,
    setSelectedSpace,
}: Props): React.ReactElement => {
    const zonePositions: L.LatLngExpression[] = zone.boundary.map(
        (points): LatLngExpression => [points.lat, points.lng]
    );
    const isSelected = selectedSpaceId === zone.id;

    const clickHandler = (): void => {
        if (!mode) {
            setMode(SpaceFloorPlanMode.INSPECT);
            setSelectedSpace(zone.id);
        } else if (mode === SpaceFloorPlanMode.INSPECT) {
            if (selectedSpaceId === zone.id) {
                setMode(undefined);
            } else {
                setSelectedSpace(zone.id);
            }
        }
    };

    const {
        spaces: { spaces },
    } = useSelector((store: Store) => spacesSelector(store, locationId));

    const space = spaces.find(it => it.id === zone.id) as IndoorSpace | undefined;
    if ((selectedSpaceId === zone.id && mode === SpaceFloorPlanMode.EDIT) || !space) {
        return <div />;
    }

    const markerOffsets = (numDevices: number): { lat: number; lng: number }[] => {
        const spacing = 22;
        const offsets: { lat: number; lng: number }[] = [];
        const startOffset: number = ((numDevices - 1) / 2) * spacing;

        let i = 0;
        while (i < numDevices) {
            const offsetLat: number = startOffset - i * spacing;
            offsets.push({ lat: offsetLat, lng: 0 });
            i += 1;
        }
        return offsets;
    };

    const getMarkerPosition = (index: number): { lng: number; lat: number } => {
        const centerPosition: LatLng = L.polygon(zonePositions).getBounds().getCenter();
        const offset: { lat: number; lng: number } = markerOffsets(space.devices.length)[index];
        return {
            lat: centerPosition.lat + offset.lat,
            lng: centerPosition.lng + offset.lng,
        };
    };

    const getSensorValue = (device: SpaceDevice): number | undefined =>
        device.currentSensorValues.find((it: CurrentSensorValuesType): boolean => it.type === selectedSensor)?.value;

    const allDevicesAreOffline: boolean = space.devices
        .flatMap(device => device.healthStatus)
        .every(it => [DeviceHealthStatus.offline, DeviceHealthStatus.notSynced].includes(it));

    const getMarkerIcon = (device: SpaceDevice): DivIcon => {
        let sensorIcon;
        if (allDevicesAreOffline) {
            sensorIcon = MarkerIcons.Offline;
        } else if (selectedSensor === FloorPlanMetadataSensors.battery) {
            sensorIcon = MarkerIcons.Battery(device.batteryPercentage);
        } else if (selectedSensor === FloorPlanMetadataSensors.connection) {
            sensorIcon = MarkerIcons.Rssi(device.rssi);
        } else {
            sensorIcon = MarkerIcons.SensorValue(getSensorValue(device));
        }
        return sensorIcon;
    };

    const allSensorValuesInSpace: CurrentSensorValuesType[] = space.devices
        .flatMap(it => it.currentSensorValues)
        .filter(it => it.type === selectedSensor);
    const statusColor: EnumStatusColors = selectedSensor
        ? deviceStatusColor([selectedSensor], allDevicesAreOffline, allSensorValuesInSpace)
        : EnumStatusColors.grey;

    return (
        <Polygon
            key={`${zone.id}-polygon-${statusColor}-${isSelected}`}
            positions={zonePositions}
            className={classNames(styles.zone, {
                [styles[`polygon-${statusColor}`]]: !isSelected,
                [styles[`polygon-${statusColor}-selected`]]: isSelected,
            })}
            pathOptions={{ weight: isSelected ? 3 : 1 }}
            eventHandlers={{ click: clickHandler }}
        >
            {space.devices.map((device: SpaceDevice, index: number) => (
                <Marker key={device.serialNumber} position={getMarkerPosition(index)} icon={getMarkerIcon(device)} />
            ))}
        </Polygon>
    );
};

export default SpaceZone;
