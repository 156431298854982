/* eslint max-len: ["error", 1000] */
import React from 'react';

const ForecastSnowRain = (): React.ReactElement => (
    <svg
        version="1.1"
        className="weather-icon"
        width="30px"
        height="30px"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
    >
        <path d="M19.5,26.6c0.3-0.5,0.1-1.1-0.4-1.4c-0.5-0.3-1.1-0.1-1.4,0.4l-1.1,2c-0.3,0.5-0.2,1.1,0.3,1.4 c0.2,0.1,0.3,0.2,0.5,0.2c0.3,0,0.7-0.2,0.9-0.5L19.5,26.6z" />
        <path d="M17.4,21.9c0.3-0.5,0.1-1.1-0.4-1.4c-0.5-0.3-1.1-0.1-1.4,0.4L14.4,23c-0.3,0.4-0.1,1,0.3,1.3 c0.2,0.1,0.3,0.2,0.5,0.2c0.3,0,0.7-0.2,0.9-0.5L17.4,21.9z" />
        <path d="M6.1,25.2C5.6,25,5,25.2,4.7,25.7l-1.2,2.1c-0.3,0.5-0.1,1.1,0.4,1.4c0.2,0.1,0.3,0.2,0.5,0.2 c0.3,0,0.7-0.2,0.9-0.5l1.1-2.1C6.8,26.2,6.6,25.6,6.1,25.2z" />
        <path
            d="M13.3,2.4C10.1,2.4,7.4,4.8,7,8L6.9,8.7L6.2,9c-1.7,0.6-2.8,2.1-2.8,3.9c0,2.3,1.8,4.1,4.1,4.1 h15c2.3,0,4.1-1.8,4.1-4.1s-1.8-4.1-4.1-4.1h-2.7l-0.2-1C19.1,4.7,16.4,2.4,13.3,2.4 M22.5,19.4h-15c-3.6,0-6.5-2.9-6.5-6.5
            C1,10.3,2.5,8.1,4.7,7c0.8-4,4.4-7,8.6-7c2.1,0,4.1,0.8,5.7,2.1c1.3,1.1,2.2,2.6,2.7,4.2h0.8c3.6,0,6.5,2.9,6.5,6.5 S26.1,19.4,22.5,19.4"
        />
        <path
            d="M23.9,21.6c-0.2-0.3-0.5-0.4-0.8-0.2l-0.7,0.4V21c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6v0.8l-0.7-0.4 c-0.3-0.2-0.7-0.1-0.8,0.2c-0.2,0.3-0.1,0.7,0.2,0.8l0.7,0.4l-0.7,0.4c-0.3,0.2-0.4,0.5-0.2,0.8c0.1,0.2,0.3,0.3,0.5,0.3
            c0.1,0,0.2,0,0.3-0.1l0.7-0.4v0.8c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6v-0.8l0.7,0.4c0.1,0.1,0.2,0.1,0.3,0.1 c0.2,0,0.4-0.1,0.5-0.3c0.2-0.3,0.1-0.7-0.2-0.8L23,22.8l0.7-0.4C24,22.2,24.1,21.9,23.9,21.6z"
        />
        <path
            d="M14.4,26.3c-0.2-0.3-0.5-0.4-0.8-0.2l-0.7,0.4v-0.8c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6v0.8L11,26.1c-0.3-0.2-0.7-0.1-0.8,0.2c-0.2,0.3-0.1,0.7,0.2,0.8l0.7,0.4l-0.7,0.4c-0.3,0.2-0.4,0.5-0.2,0.8c0.1,0.2,0.3,0.3,0.5,0.3
            c0.1,0,0.2,0,0.3-0.1l0.7-0.4v0.8c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6v-0.8l0.7,0.4c0.1,0.1,0.2,0.1,0.3,0.1 c0.2,0,0.4-0.1,0.5-0.3c0.2-0.3,0.1-0.7-0.2-0.8l-0.7-0.4l0.7-0.4C14.5,26.9,14.6,26.6,14.4,26.3z"
        />
        <path
            d="M11,21.6c-0.2-0.3-0.5-0.4-0.8-0.2l-0.7,0.4V21c0-0.3-0.3-0.6-0.6-0.6S8.3,20.7,8.3,21v0.8l-0.7-0.4 c-0.3-0.2-0.7-0.1-0.8,0.2c-0.2,0.3-0.1,0.7,0.2,0.8l0.7,0.4L7,23.2c-0.3,0.2-0.4,0.5-0.2,0.8c0.1,0.2,0.3,0.3,0.5,0.3
            c0.1,0,0.2,0,0.3-0.1l0.7-0.4v0.8c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6v-0.8l0.7,0.4c0.1,0.1,0.2,0.1,0.3,0.1 c0.2,0,0.4-0.1,0.5-0.3c0.2-0.3,0.1-0.7-0.2-0.8l-0.7-0.4l0.7-0.4C11.1,22.2,11.2,21.9,11,21.6z"
        />
    </svg>
);
export default ForecastSnowRain;
