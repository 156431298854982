import { SelectGroupActionType, SelectGroupReducerActions } from 'commons/src/actions/selectGroupActions';
import {
    OrganizationPropertiesAction,
    OrganizationPropertiesActionType,
} from '../actions/organizationPropertiesActions';

export interface OrganizationPropertiesStore {
    logoFileName?: string;
    logoImage?: string;
}

const organizationPropertiesStore = (
    state: OrganizationPropertiesStore = {},
    action: OrganizationPropertiesAction | SelectGroupReducerActions
): OrganizationPropertiesStore => {
    switch (action.type) {
        case OrganizationPropertiesActionType.GetOrganizationLogoSuccess:
            return {
                ...state,
                logoFileName: action.response.logoFileName,
                logoImage: action.response.logoImage,
            };
        case OrganizationPropertiesActionType.UpdateOrganizationLogoSuccess: {
            return {
                ...action.response,
            };
        }
        case SelectGroupActionType.SelectGroupInit:
            return {
                ...state,
                logoImage: undefined,
                logoFileName: undefined,
            };
        default:
            return {
                ...state,
            };
    }
};

export default organizationPropertiesStore;
