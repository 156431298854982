import React, { SyntheticEvent } from 'react';
import Input from '../../components/input/Input';

type Props = {
    invalidName: boolean;
    onInputChange: (e: SyntheticEvent<HTMLInputElement>) => void;
    getText: (term: string) => string;
};

const HomeUserInfo = (props: Props): React.ReactElement => {
    const { invalidName, onInputChange, getText } = props;

    return (
        <div className="radon-report__form__section radon-report__form__section--border">
            <h2 className="radon-report__form__section__header">{getText('PersonalInformationSectionHeader')}</h2>
            <div className="radon-report__form__section__row-resp-column-collapse">
                <Input
                    type="text"
                    id="name"
                    label="Name"
                    required
                    autoComplete="name"
                    maxLength={100}
                    hint="NameHint"
                    isValid={false}
                    markedMandatory
                    validate={invalidName}
                    onChange={onInputChange}
                />
            </div>
        </div>
    );
};

export default HomeUserInfo;
