/* eslint max-len: ["error", 1000] */
import React from 'react';

const IconOfflineDevice = (): React.ReactElement => (
    <svg
        id="offline-device-icon"
        width="24px"
        height="24px"
        viewBox="0 0 20 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.10050506,0.100505063 L18.4106347,16.4092381 L18.4106347,16.4092381 L21.1923882,19.1923882 L19.7781746,20.6066017 L17.1545221,17.9844627 C15.338234,19.844648 12.8032203,21 10,21 C4.48,21 0,16.52 0,11 C0,8.19627119 1.1557712,5.6608464 3.0165496,3.84448964 L0.686291501,1.51471863 L2.10050506,0.100505063 Z M4.42961536,5.25797015 C2.93116952,6.71189835 2,8.74720603 2,11 C2,15.418278 5.581722,19 10,19 C12.2528057,19 14.2881229,18.0688208 15.7420526,16.5703612 L11.4990649,12.3271322 C11.1332668,12.7403854 10.5982531,13 10,13
            C8.89142857,13 8,12.1085714 8,11 C8,10.4017469 8.25961461,9.86673322 8.67286776,9.50093509 Z M13.5,15 C13.7771429,15 14,15.2228571 14,15.5 C14,15.7771429 13.7771429,16 13.5,16 C13.2228571,16 13,15.7771429 13,15.5 C13,15.2228571 13.2228571,15 13.5,15 Z M6.5,15 C6.77714286,15 7,15.2228571 7,15.5 C7,15.7771429 6.77714286,16 6.5,16 C6.22285714,16 6,15.7771429 6,15.5 C6,15.2228571 6.22285714,15 6.5,15 Z M10,1 C15.52,1 20,5.48 20,11 C20,12.2424072 19.7730513,13.4321299 19.3583761,14.5299457 L17.7641245,12.9361195 C17.9182176,12.316162 18,11.6676317 18,11 C18,6.581722 14.418278,3 10,3 C9.33236835,3 8.68383799,3.08178236 8.06388046,3.23587554 L6.47005431,1.64162391 C7.56787015,1.22694874 8.75759281,1 10,1 Z"
        />
    </svg>
);

export default IconOfflineDevice;
