import { takeEvery, call } from 'redux-saga/effects';
import { SIGN_UP_NEWSLETTER, SignUpNewsletter } from '../../actions/SettingsActions';
import newsletterSignUp from '../../api/newsletterSignup';
import displayAlertBoxSaga from '../displayAlertBox';

export function* newsletterSignUpSaga({ payload, isB2B }: SignUpNewsletter): Generator {
    try {
        yield call(newsletterSignUp, payload, isB2B);
        yield call(displayAlertBoxSaga, 'NewsletterSignUpSuccess', false, true);
    } catch (error) {
        yield call(displayAlertBoxSaga, 'SomethingWentWrongTryAgain', true, true);
    }
}

export default function* newsletterSignUpSagas(): Generator {
    yield takeEvery(SIGN_UP_NEWSLETTER, newsletterSignUpSaga);
}
