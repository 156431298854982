import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { BoldText, FlexWrapper, FlexWrapperSpaced } from '../../../components/PDF/PdfConstants';
import { sensorUnits } from '../../../constants';
import { HomeRadonValues, PDFContext } from '../../../models/commonTypeScript';

const componentStyle = StyleSheet.create({
    paddedView: {
        marginBottom: '3vw',
    },
});

type BuildingProps = {
    txt: PDFContext;
    address: string;
    buildingType: string;
    buildingYear: number;
    roomType: string;
    floor: string;
    radonValue: HomeRadonValues;
    daysMeasured: number;
};

const BuildingDetails = ({
    txt,
    address,
    buildingType,
    buildingYear,
    roomType,
    floor,
    radonValue,
    daysMeasured,
}: BuildingProps): React.ReactElement => (
    <View>
        <View style={componentStyle.paddedView}>
            <FlexWrapperSpaced>
                <View>
                    <BoldText>{txt('Address')}</BoldText>
                    <Text>{address.replace(/,/g, '\n') || '-'}</Text>
                </View>
                <View>
                    <BoldText>{txt('BuildingType')}</BoldText>
                    <Text>{txt(`BuildingTypeOptions.${buildingType}`)}</Text>
                </View>
                <View>
                    <BoldText>{txt('BuildingYear')}</BoldText>
                    <Text>{buildingYear || ' - '}</Text>
                </View>
                <View />
                <View />
            </FlexWrapperSpaced>
        </View>
        <FlexWrapperSpaced>
            <View>
                <BoldText>{txt('RoomType')}</BoldText>
                <Text>{txt(`RoomTypeOptions.${roomType}`)}</Text>
            </View>
            <View>
                <BoldText>{txt('Floor')}</BoldText>
                <Text>{txt(`FloorTypeOptions.${floor}`)}</Text>
            </View>
            <View>
                <FlexWrapper>
                    <BoldText>{txt('CorentiumHomeRadonReport.MeasurementValue')}</BoldText>
                </FlexWrapper>
                <Text>
                    {radonValue.radonLongTermAvg}
                    {' ± '}
                    {radonValue.annualAverageCorrection} {sensorUnits[radonValue.unit]}
                </Text>
            </View>
            <View>
                <BoldText>{txt('DaysMeasured')}</BoldText>
                <Text>{daysMeasured}</Text>
            </View>
            <View />
            <View />
        </FlexWrapperSpaced>
    </View>
);

export default BuildingDetails;
