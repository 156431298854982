import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { limitSubscription } from 'commons/src/commonFunctions';
import AccordionButton from 'commons/src/components/buttons/AccordionButton';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import StatusPill from 'commons/src/components/pills/StatusPill';
import { PartnerCustomerSubscriptionStatus, PillStatus, SubscriptionType } from 'commons/src/models/commonEnums';
import { SubscriptionPlan } from 'commons/src/models/commonTypeScript';
import { getDeviceAndSeatCount } from '../../features/account/subscription/StartSubscription/subscriptionFunctions';
import DeviceAndSeatCount from './DeviceAndSeatCount';
import styles from './YourPlan.module.scss';

type Props = {
    deployedDevices: { [device: string]: number };
    subscriptionPlan?: SubscriptionPlan;
    partnerName?: string;
    deviceSeatsAlreadyPayedFor?: { [device: string]: number };
    openAddSeats?: (open: boolean) => void;
};
export const SubscriptionName = ({
    subscriptionType,
    alignRight,
}: {
    subscriptionType?: PartnerCustomerSubscriptionStatus | SubscriptionType;
    alignRight?: boolean;
}): React.ReactElement => {
    const { t: txt } = useTranslation();
    let subscriptionStatus = PillStatus.info;
    let showAstrix = false;
    if (
        subscriptionType === PartnerCustomerSubscriptionStatus.InsufficientSeats ||
        subscriptionType === PartnerCustomerSubscriptionStatus.NotFound
    ) {
        subscriptionStatus = PillStatus.alert;
        showAstrix = true;
    } else if (
        subscriptionType === PartnerCustomerSubscriptionStatus.Standard ||
        subscriptionType === PartnerCustomerSubscriptionStatus.Active ||
        subscriptionType === SubscriptionType.DEFAULT
    ) {
        subscriptionStatus = PillStatus.success;
    }

    return (
        <div className={alignRight ? styles.subscriptionWrapperRight : styles.subscriptionWrapper}>
            <StatusPill
                status={subscriptionStatus}
                outline
                text={`${subscriptionType ? txt(`Subscription.${subscriptionType}`) : 'Limited'}${
                    showAstrix ? '*' : ''
                }`}
            />
        </div>
    );
};

const YourPlan = ({
    deployedDevices,
    partnerName,
    deviceSeatsAlreadyPayedFor,
    openAddSeats,
    subscriptionPlan,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const [expandWhichToUse, setExpandWhichToUse] = React.useState(false);

    const onCreateSubscription = (): void => {
        navigate('start-subscription');
    };

    const subscriptionIsLimited = limitSubscription(subscriptionPlan);

    return (
        <div className="settings-details-container">
            <div className={styles.subscriptionInfoWrapper}>
                <div className={styles.subscriptionInfoHeader}>
                    {subscriptionIsLimited ? (
                        <div className={styles.subscriptionInfo}>
                            <SubscriptionName subscriptionType={subscriptionPlan?.subscriptionType} />
                        </div>
                    ) : (
                        <div />
                    )}
                    {partnerName && (
                        <div className={styles.managedByPartner}>
                            <div>{txt('Subscription.ManagedBy')}</div>
                            <div className={styles.partnerName}>{partnerName}</div>
                        </div>
                    )}
                </div>
                <div className={styles.deviceCounts}>
                    <DeviceAndSeatCount
                        deviceCounts={getDeviceAndSeatCount(deployedDevices, deviceSeatsAlreadyPayedFor || {})}
                        displaySeatCount={!!deviceSeatsAlreadyPayedFor}
                    />
                </div>
                {openAddSeats && (
                    <div className={styles.subscriptionButtonsWrapper}>
                        <TertiaryButton
                            onClick={(): void => openAddSeats(true)}
                            size="medium"
                            icon={<MaterialIcon name="add" />}
                            title="Subscription.AddSeatsHeader"
                        />
                        <TertiaryButton
                            onClick={onCreateSubscription}
                            size="medium"
                            icon={<MaterialIcon name="list_alt_add" />}
                            title="Subscription.CreateSubscription"
                        />
                        <AccordionButton
                            expanded={expandWhichToUse}
                            title="Subscription.WhichToUse"
                            onClick={(): void => setExpandWhichToUse(!expandWhichToUse)}
                            testId="expand-subscription-to-use"
                        />
                    </div>
                )}
                {expandWhichToUse && (
                    <div className={styles.whenToUseSection}>
                        <div className={styles.whenToUseSubSection}>
                            <div className={styles.whenToUseHeader}>{txt('Subscription.AddSeatsHeader')}</div>
                            {txt('Subscription.WhenToUseAddSeats')}
                        </div>
                        <div className={styles.whenToUseSubSection}>
                            <div className={styles.whenToUseHeader}>{txt('Subscription.CreateSubscription')}</div>
                            {txt('Subscription.WhenToUseAddSubscription')}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default YourPlan;
