import { produce } from 'immer';
import { SettingsActionType, SettingsReducerActions } from '../actions/SettingsActions';
import { PredefinedProperty, PropertyType } from '../models/commonEnums';
import { SpaceProperty, DeviceDetailsResponse, SensorThresholds } from '../models/commonTypeScript';

export interface ConfigStore {
    thresholds: { [sensor: string]: SensorThresholds };
    deviceDetails: { [deviceType: string]: DeviceDetailsResponse };
    spacePropDefs: {
        [propertyId: string]: SpaceProperty;
    };
}

export const initialState: ConfigStore = {
    thresholds: {},
    deviceDetails: {},
    spacePropDefs: {},
};

/* eslint-disable no-param-reassign */
export default (state = initialState, action: SettingsReducerActions): ConfigStore =>
    produce(state, draft => {
        switch (action.type) {
            case SettingsActionType.FetchSpecsSuccess: {
                draft.deviceDetails = action.payload.devices ?? {};
                draft.thresholds = action.payload.thresholds ?? {};
                draft.spacePropDefs = action.payload.spacePropDefs ?? {};
                break;
            }

            case SettingsActionType.DeletePropertyValueSuccess: {
                draft.spacePropDefs[action.propertyId].value = {
                    options:
                        draft.spacePropDefs[action.propertyId].value?.options.filter(
                            opt => opt.id !== action.valueId
                        ) ?? [],
                };
                break;
            }

            case SettingsActionType.UpdatePropertyValueSuccess: {
                const options =
                    draft.spacePropDefs[action.propertyId].value?.options.filter(opt => opt.id !== action.valueId) ??
                    [];
                draft.spacePropDefs[action.propertyId].value = {
                    options: [
                        ...options,
                        {
                            id: action.valueId,
                            name: action.name,
                        },
                    ].sort((a, b) => a.name.localeCompare(b.name)),
                };
                break;
            }

            case SettingsActionType.CreatePropertyValueSuccess: {
                const isSelection = action.propertyType === PropertyType.Selection;
                if (isSelection) {
                    const options = draft.spacePropDefs[action.propertyId].value?.options ?? [];
                    draft.spacePropDefs[action.propertyId].value = {
                        options: [
                            ...options,
                            {
                                id: action.createdValue.id,
                                name: action.createdValue.value,
                            },
                        ].sort((a, b) => a.name.localeCompare(b.name)),
                    };
                }
                break;
            }

            case SettingsActionType.CreatePropertyDefinitionSuccess: {
                draft.spacePropDefs[action.definitionId] = {
                    propertyName: action.name,
                    propertyType: action.propertyType,
                    predefinedType: PredefinedProperty.Custom,
                    value: {
                        options: [],
                    },
                };
                break;
            }

            case SettingsActionType.UpdatePropertyDefinitionSuccess: {
                const definition = draft.spacePropDefs[action.definitionId];
                draft.spacePropDefs[action.definitionId] = {
                    propertyName: action.name,
                    propertyType: definition.propertyType,
                    predefinedType: definition.predefinedType,
                    value: definition.value,
                };
                break;
            }

            case SettingsActionType.DeletePropertyDefinitionSuccess: {
                delete draft.spacePropDefs[action.definitionId];
                break;
            }

            default:
        }
    });
/* eslint-enable no-param-reassign */
