/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const buildingTileSparkLine = (data: { y: number; color: string }[]) => ({
    chart: {
        backgroundColor: null,
        borderWidth: 0,
        type: 'column',
        margin: [2, 0, 2, 0],
        width: 125,
        height: 34,
        style: {
            overflow: 'visible',
        },
        // small optimalization, saves 1-2 ms each sparkline
        skipClone: true,
    },
    title: {
        text: '',
    },
    credits: {
        enabled: false,
    },
    xAxis: {
        labels: { enabled: false },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
    },
    yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: { enabled: false },
        tickPositions: [0],
    },
    legend: { enabled: false },
    tooltip: { enabled: false },
    plotOptions: {
        series: {
            animation: false,
            lineWidth: 1,
            shadow: false,
            states: {
                hover: { enabled: false },
            },
        },
        column: {
            borderRadius: 4,
        },
    },
    series: [{ data }],
});

export default buildingTileSparkLine;

export type BuildingTileSparkLine = ReturnType<typeof buildingTileSparkLine>;
