import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { sensorRating } from 'commons/src/commonFunctions';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import { sensorUnits } from 'commons/src/constants';
import { Rating, SensorTypes } from 'commons/src/models/commonEnums';
import { ThresholdRange } from 'commons/src/models/commonTypeScript';

type Props = {
    ranges: ThresholdRange[];
    value: number | undefined;
    unit: keyof typeof sensorUnits;
    sensorType: string;
    headerText: string;
    large: boolean;
    hideCircle?: boolean;
};

export const CircledAverageComponent = (props: Props): React.ReactElement => {
    const { ranges, value, unit, sensorType, large, headerText, hideCircle } = props;

    const { t: txt } = useTranslation();
    const sensorHasValue = value !== undefined && value !== null && !Number.isNaN(value);
    const valueRange: Rating | undefined = sensorRating(ranges, value);
    const displayedUnit =
        sensorType === SensorTypes.temp ? `${sensorUnits[unit]}${(unit as string).toUpperCase()}` : sensorUnits[unit];
    const displayedValue = value && (displayedUnit === sensorUnits.pci ? value.toFixed(1) : value.toFixed(0));
    return (
        <div
            className={classNames('insight-tile__content', 'insight-tile__content--sensor', {
                'insight-tile__content--sensor--small-padded': !large,
            })}
        >
            <div
                className={classNames('list-tile__status-circle', {
                    'list-tile__status-circle--large': large,
                    'sensor__line--ok': valueRange === Rating.GOOD,
                    'sensor__line--alert': valueRange === Rating.POOR,
                    'sensor__line--warning': valueRange === Rating.FAIR,
                    'sensor__line--disabled': valueRange === undefined,
                    'sensor__line--hidden': hideCircle,
                })}
            >
                <div
                    className={classNames('list-tile__status-circle__middle', {
                        'list-tile__status-circle__middle--large': large,
                    })}
                />
                <div
                    className={classNames('list-tile__status-circle__content', {
                        'list-tile__status-circle__content--small': !large,
                    })}
                >
                    {hideCircle !== true && <SensorIcon sensor={sensorType} />}
                </div>
            </div>
            <div className="sensor__data">
                <div className={classNames('insight-sensor__top-text', { 'insight-sensor__top-text--small': !large })}>
                    {txt(headerText)}
                </div>
                <span className={classNames('insight-sensor__value', { 'insight-sensor__value--small': !large })}>
                    {sensorHasValue ? displayedValue : txt('NotAvailableAbbreviation')}
                    <span className="sensor__unit">{displayedUnit}</span>
                </span>
            </div>
        </div>
    );
};

export default CircledAverageComponent;
