import React, { SyntheticEvent, RefObject } from 'react';
import Input from '@mui/joy/Input';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '../MaterialIcon';
import styles from './SearchComponent.module.scss';

type Props = {
    onChange: (text: string) => void;
    placeholder?: string;
    testId?: string;
    parentRef?: RefObject<HTMLInputElement>;
    onFocus?: () => void;
    disabled?: boolean;
    expandable?: boolean;
    size?: 'sm' | 'md' | 'lg';
};

const SearchComponent = ({
    onChange,
    testId,
    parentRef,
    disabled,
    placeholder,
    expandable,
    onFocus,
    size = 'sm',
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [expanded, setExpanded] = React.useState(!expandable);

    const onSearchUpdate = (e: SyntheticEvent<HTMLInputElement>): void => {
        e.preventDefault();
        const text = e.currentTarget.value;
        onChange(text);
    };

    const focus = (): void => {
        if (onFocus) onFocus();
        if (expandable) {
            setExpanded(true);
        }
    };
    const onBlur = (e: SyntheticEvent<HTMLInputElement>): void => {
        const valueInForm = e.currentTarget.value;
        if (expandable && valueInForm.length < 1) {
            setExpanded(false);
        }
    };

    return (
        <div
            className={classNames(styles.component, {
                [styles.notExpanded]: expandable && !expanded,
                [styles.disabled]: disabled,
            })}
        >
            <Input
                onFocus={focus}
                placeholder={txt(placeholder || 'Search')}
                size={size}
                onBlur={onBlur}
                startDecorator={<MaterialIcon id="icon" name="search" />}
                className={styles.inputField}
                onChange={onSearchUpdate}
                disabled={disabled}
                {...{ 'data-testid': testId }}
                ref={parentRef}
            />
        </div>
    );
};

export default SearchComponent;
