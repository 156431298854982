import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Rating } from '../../models/commonEnums';
import { ThresholdRange } from '../../models/commonTypeScript';

type Props = {
    sensorType: string;
    unit: string;
    ranges: ThresholdRange[];
};

const ThresholdLimits = ({ ranges, sensorType, unit }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const sortFromHighToLow = [...ranges].sort((a, b) => (b.from || 0) - (a.from || 0));
    const sensorThresholds = (sortedRanges: ThresholdRange[]): ReactElement[] =>
        sortedRanges.map((threshold, index) => {
            const thresholdKey = `${sensorType}-threshold-${index}`;
            return (
                <div key={thresholdKey} className="modal__thresholds__sensor-wrapper__limit-row">
                    <div>
                        <div
                            className={classNames('sensor__line', 'sensor__line--large', {
                                'sensor__line--ok': threshold.rating === Rating.GOOD,
                                'sensor__line--alert': threshold.rating === Rating.POOR,
                                'sensor__line--warning': threshold.rating === Rating.FAIR,
                            })}
                        />
                        {threshold.from && ['≥ ', threshold.from]}
                        {threshold.from && threshold.to && [' ', txt('AndConnectionWord')]}
                        {threshold.to && [' < ', threshold.to]}
                        {unit}
                    </div>
                </div>
            );
        });
    return <div>{sensorThresholds(sortFromHighToLow)}</div>;
};

export default ThresholdLimits;
