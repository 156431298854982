import React from 'react';

const SpaceProIcon = (): React.ReactElement => (
    <svg
        className="device-svg-icons"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 11H16C8.8203 11 3 16.8203 3 24C3 31.1797 8.8203 37 16 37H32C39.1797 37 45 31.1797 45 24C45 16.8203 39.1797 11 32 11ZM16 9C7.71573 9 1 15.7157 1 24C1 32.2843 7.71573 39 16 39H32C40.2843 39 47 32.2843 47 24C47 15.7157 40.2843 9 32 9H16Z"
            fill="#21282D"
        />
        <path
            d="M20.8027 22.0234C20.8027 22.918 20.5098 23.6035 19.9238 24.0801C19.3379 24.5566 18.5039 24.7949 17.4219 24.7949H16.5312V28H15.1309V19.4336H17.5977C18.668 19.4336 19.4688 19.6523 20 20.0898C20.5352 20.5273 20.8027 21.1719 20.8027 22.0234ZM16.5312 23.6172H17.2754C17.9941 23.6172 18.5215 23.4922 18.8574 23.2422C19.1934 22.9922 19.3613 22.6016 19.3613 22.0703C19.3613 21.5781 19.2109 21.2109 18.9102 20.9688C18.6094 20.7266 18.1406 20.6055 17.5039 20.6055H16.5312V23.6172Z"
            fill="#21282D"
        />
        <path
            d="M25.6543 21.4023C25.9316 21.4023 26.1602 21.4219 26.3398 21.4609L26.2051 22.7441C26.0098 22.6973 25.8066 22.6738 25.5957 22.6738C25.0449 22.6738 24.5977 22.8535 24.2539 23.2129C23.9141 23.5723 23.7441 24.0391 23.7441 24.6133V28H22.3672V21.5195H23.4453L23.627 22.6621H23.6973C23.9121 22.2754 24.1914 21.9688 24.5352 21.7422C24.8828 21.5156 25.2559 21.4023 25.6543 21.4023Z"
            fill="#21282D"
        />
        <path
            d="M33.2949 24.748C33.2949 25.8066 33.0234 26.6328 32.4805 27.2266C31.9375 27.8203 31.1816 28.1172 30.2129 28.1172C29.6074 28.1172 29.0723 27.9805 28.6074 27.707C28.1426 27.4336 27.7852 27.041 27.5352 26.5293C27.2852 26.0176 27.1602 25.4238 27.1602 24.748C27.1602 23.6973 27.4297 22.877 27.9688 22.2871C28.5078 21.6973 29.2676 21.4023 30.248 21.4023C31.1855 21.4023 31.9277 21.7051 32.4746 22.3105C33.0215 22.9121 33.2949 23.7246 33.2949 24.748ZM28.5781 24.748C28.5781 26.2441 29.1309 26.9922 30.2363 26.9922C31.3301 26.9922 31.877 26.2441 31.877 24.748C31.877 23.2676 31.3262 22.5273 30.2246 22.5273C29.6465 22.5273 29.2266 22.7188 28.9648 23.1016C28.707 23.4844 28.5781 24.0332 28.5781 24.748Z"
            fill="#21282D"
        />
    </svg>
);

export default SpaceProIcon;
