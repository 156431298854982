import React, { useEffect } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { BUILDING_THRESHOLD_BREACH_DOWNLOAD_CSV } from 'commons/src/analytics/AnalyticsEvents';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ReactPdfDownloadModal from 'commons/src/components/PDF/ReactPdfDownloadModal';
import { dateFormats } from 'commons/src/constants';
import { Resolution, TimePeriod } from 'commons/src/models/commonEnums';
import { BuildingInsight } from '../../../models/buildingModels';
import { getCsvData, getDateHeaders } from './buildingInsightCommonFunctions';

type Props = {
    timezone?: string;
    dateFormat: keyof typeof dateFormats;
    buildingThresholdBreaches: BuildingInsight[];
    selectedPeriod: {
        toDate: string;
        fromDate: string;
        resolution: Resolution;
        name: TimePeriod;
    };
    setDownloadModalOpen: (open: boolean) => void;
    buildingName: string;
};

const ThresholdBreachCsvDownload = ({
    timezone,
    buildingThresholdBreaches,
    dateFormat,
    selectedPeriod,
    setDownloadModalOpen,
    buildingName,
}: Props): React.ReactElement => {
    const datesArray = getDateHeaders(selectedPeriod);
    const dateHeaders = datesArray.map(date => {
        const dateFormatted = moment(date).format(dateFormats[dateFormat].shortFormat);
        const timeFormatted = timezone
            ? `${moment.utc(date).tz(timezone).format(dateFormats[dateFormat].hourFormat)} -
            ${moment.utc(date).tz(timezone).add(1, 'hour').format(dateFormats[dateFormat].timeOfDay)}`
            : '';

        return selectedPeriod.resolution === Resolution.hour ? `${dateFormatted} ${timeFormatted}` : dateFormatted;
    });
    const csvHeaders = ['DeviceName', ...dateHeaders];
    const csvData = getCsvData(buildingThresholdBreaches, buildingName, datesArray, timezone);

    useEffect(() => {
        analyticsLogger(BUILDING_THRESHOLD_BREACH_DOWNLOAD_CSV, { pageType: PageType.Building, ...selectedPeriod });
    }, []);

    return (
        <ReactPdfDownloadModal
            title="DownloadCSVTitle"
            description="BuildingInsight.DownloadAllSensorThresholdsCSVDescription"
            onClose={(): void => setDownloadModalOpen(false)}
        >
            <div>
                {csvData.map(data => (
                    <div key={`csvDownloadElement-${data.fileName}`} className="flex flex--center small-padding-bottom">
                        <MaterialIcon name="download" />
                        <CSVLink
                            data={[csvHeaders, ...data.deviceList]}
                            separator=";"
                            filename={`${data.fileName}.csv`}
                        >
                            {data.fileName}
                        </CSVLink>
                    </div>
                ))}
            </div>
        </ReactPdfDownloadModal>
    );
};

export default ThresholdBreachCsvDownload;
