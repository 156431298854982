import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { BoldText, FlexWrapperSpaced } from '../../../../components/PDF/PdfConstants';
import { PDFContext } from '../../../../models/commonTypeScript';

type BuildingProps = {
    address: string;
    buildingType: string;
    buildingYear: string;
    ventilationType: string;
    txt: PDFContext;
};

const BuildingDetails = ({
    address,
    buildingYear,
    buildingType,
    ventilationType,
    txt,
}: BuildingProps): React.ReactElement => (
    <View>
        <View style={{ marginBottom: '3vw' }}>
            <FlexWrapperSpaced>
                <View>
                    <BoldText>{txt('Address')}</BoldText>
                    <Text>{address.replace(/,/g, '\n') || '-'}</Text>
                </View>
                <View>
                    <BoldText>{txt('BuildingType')}</BoldText>
                    <Text>{buildingType}</Text>
                </View>
                <View>
                    <BoldText>{txt('BuildingYear')}</BoldText>
                    <Text>{buildingYear}</Text>
                </View>
                <View>
                    <BoldText>{txt('Ventilation')}</BoldText>
                    <Text>{ventilationType}</Text>
                </View>
                <View />
            </FlexWrapperSpaced>
        </View>
    </View>
);

export default BuildingDetails;
