import { CLOSE_WELCOME_MODAL } from '../actions/DashboardActions';
import {
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LoginAndRegisterReducerActions,
    LOGOUT,
    LOGOUT_FAIL,
    LOGOUT_SUCCESS,
} from '../actions/LoginAndRegisterActions';
import {
    OrganizationInvitesActionType,
    OrganizationInvitesReducerActions,
} from '../actions/OrganizationInvitesActions';

export type LoginState = {
    error: boolean;
    loading: boolean;
    isLoggedIn: boolean;
    displayWelcomeModal: boolean;
    errorCode?: number;
    isFirstLogin: boolean;
    hasFetchedInvites: boolean;
};

const initialState = {
    error: false,
    loading: false,
    isLoggedIn: false,
    displayWelcomeModal: false,
    errorCode: undefined,
    isFirstLogin: false,
    hasFetchedInvites: false,
};

export default (
    state = initialState,
    action: OrganizationInvitesReducerActions | LoginAndRegisterReducerActions
): LoginState => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                isLoggedIn: true,
                errorCode: undefined,
            };
        case OrganizationInvitesActionType.GetOrganizationInvitesSuccess:
            return {
                ...state,
                hasFetchedInvites: true,
                displayWelcomeModal: action.invites.length > 0 ? false : state.isFirstLogin,
            };
        case OrganizationInvitesActionType.GetOrganizationInvitesError:
            return {
                ...state,
                displayWelcomeModal: state.isFirstLogin,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                error: true,
                errorCode: action.error.error_code,
                loading: false,
                isLoggedIn: false,
            };
        case LOGOUT:
            return {
                ...state,
                loading: true,
                errorCode: undefined,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                isLoggedIn: false,
                errorCode: undefined,
            };
        case LOGOUT_FAIL:
            return {
                ...state,
                loading: false,
                error: true,
                errorCode: undefined,
            };
        case CLOSE_WELCOME_MODAL:
            return {
                ...state,
                displayWelcomeModal: false,
            };
        default:
            return state;
    }
};
