import { RequestActionType } from 'commons/src/actions/requestActions';
import { GroupType } from 'commons/src/models/commonEnums';
import { BusinessActionRequestType } from '../models/common';
import { Subscription, SubscriptionsOrderRequest } from '../models/subscriptionModels';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum SubscriptionActions {
    GetSubscriptionForEndCustomerInit = 'GET_SUBSCRIPTION_FOR_END_CUSTOMER/INIT',
    GetSubscriptionForEndCustomerSuccess = 'GET_SUBSCRIPTION_FOR_END_CUSTOMER_SUCCESS',
    GetSubscriptionForPartnerCustomerInit = 'GET_SUBSCRIPTION_FOR_PARTNER_CUSTOMER/INIT',
    GetSubscriptionForPartnerCustomerSuccess = 'GET_SUBSCRIPTION_FOR_PARTNER_CUSTOMER_SUCCESS',
    CreateSubscriptionForPartnerCustomerInit = 'CREATE_SUBSCRIPTION_FOR_PARTNER_CUSTOMER/INIT',
    CreateSubscriptionForPartnerCustomerSuccess = 'CREATE_SUBSCRIPTION_FOR_PARTNER_CUSTOMER_SUCCESS',
    CreateSubscriptionForDirectCustomerInit = 'CREATE_SUBSCRIPTION_FOR_DIRECT_CUSTOMER/INIT',
    CreateSubscriptionForDirectCustomerSuccess = 'CREATE_SUBSCRIPTION_FOR_DIRECT_CUSTOMER_SUCCESS',
    AddSeatsToSubscriptionInit = 'ADD_SEATS_TO_SUBSCRIPTION/INIT',
    UpdatePoRefInit = 'UPDATE_PO_REF/INIT',
    UpdatePoRefSuccess = 'UPDATE_PO_REF_SUCCESS',
}

export interface GetSubscriptionForEndCustomer extends BusinessActionRequestType {
    type: SubscriptionActions.GetSubscriptionForEndCustomerInit;
}

export const getSubscriptionForEndCustomer = (): GetSubscriptionForEndCustomer => ({
    type: SubscriptionActions.GetSubscriptionForEndCustomerInit,
    requestType: RequestType.GetSubscriptionForEndCustomer,
    requestActionType: RequestActionType.Init,
});

export interface GetSubscriptionForEndCustomerSuccess {
    type: SubscriptionActions.GetSubscriptionForEndCustomerSuccess;
    subscriptions: Subscription[];
}

export const getSubscriptionForEndCustomerSuccess = (
    subscriptions: Subscription[]
): GetSubscriptionForEndCustomerSuccess => ({
    type: SubscriptionActions.GetSubscriptionForEndCustomerSuccess,
    subscriptions,
});

export interface GetSubscriptionForPartnerCustomer extends BusinessActionRequestType {
    type: SubscriptionActions.GetSubscriptionForPartnerCustomerInit;
    customerUserGroupId: string;
}

export const getSubscriptionForForPartnerCustomer = (
    customerUserGroupId: string
): GetSubscriptionForPartnerCustomer => ({
    type: SubscriptionActions.GetSubscriptionForPartnerCustomerInit,
    requestType: RequestType.GetSubscriptionForPartnerCustomer,
    requestActionType: RequestActionType.Init,
    customerUserGroupId,
});

export interface GetSubscriptionForPartnerCustomerSuccess {
    type: SubscriptionActions.GetSubscriptionForPartnerCustomerSuccess;
    subscriptions: Subscription[];
    customerUserGroupId: string;
}

export const getSubscriptionForPartnerCustomerSuccess = (
    subscriptions: Subscription[],
    customerUserGroupId: string
): GetSubscriptionForPartnerCustomerSuccess => ({
    type: SubscriptionActions.GetSubscriptionForPartnerCustomerSuccess,
    subscriptions,
    customerUserGroupId,
});

export interface CreateSubscriptionForDirectCustomer extends BusinessActionRequestType {
    type: SubscriptionActions.CreateSubscriptionForDirectCustomerInit;
    subscriptionDetails: SubscriptionsOrderRequest;
}

export const createSubscriptionForDirectCustomer = (
    subscriptionDetails: SubscriptionsOrderRequest
): CreateSubscriptionForDirectCustomer => ({
    type: SubscriptionActions.CreateSubscriptionForDirectCustomerInit,
    requestType: RequestType.CreateSubscriptionForDirectCustomer,
    requestActionType: RequestActionType.Init,
    subscriptionDetails,
});

export interface CreateSubscriptionForPartnerCustomer extends BusinessActionRequestType {
    type: SubscriptionActions.CreateSubscriptionForPartnerCustomerInit;
    customerUserGroupId: string;
    subscriptionDetails: SubscriptionsOrderRequest;
}

export const createSubscriptionForPartnerCustomer = (
    customerUserGroupId: string,
    subscriptionDetails: SubscriptionsOrderRequest
): CreateSubscriptionForPartnerCustomer => ({
    type: SubscriptionActions.CreateSubscriptionForPartnerCustomerInit,
    requestType: RequestType.CreateSubscriptionForPartnerCustomer,
    requestActionType: RequestActionType.Init,
    customerUserGroupId,
    subscriptionDetails,
});

export interface CreateSubscriptionForDirectCustomerSuccess {
    type: SubscriptionActions.CreateSubscriptionForDirectCustomerSuccess;
    subscriptionNumber: string;
}

export interface CreateSubscriptionForPartnerCustomerSuccess {
    type: SubscriptionActions.CreateSubscriptionForPartnerCustomerSuccess;
    subscriptionNumber: string;
}

export const createSubscriptionForPartnerCustomerSuccess = (
    subscriptionNumber: string
): CreateSubscriptionForPartnerCustomerSuccess => ({
    type: SubscriptionActions.CreateSubscriptionForPartnerCustomerSuccess,
    subscriptionNumber,
});

export const createSubscriptionForDirectCustomerSuccess = (
    subscriptionNumber: string
): CreateSubscriptionForDirectCustomerSuccess => ({
    type: SubscriptionActions.CreateSubscriptionForDirectCustomerSuccess,
    subscriptionNumber,
});

export interface AddSeatsToSubscription extends BusinessActionRequestType {
    type: SubscriptionActions.AddSeatsToSubscriptionInit;
    updatedSubscription: SubscriptionsOrderRequest;
    subscriptionNumber: string;
    groupType: GroupType;
    customerUserGroupId?: string;
}

export const addSeatsToSubscription = (
    updatedSubscription: SubscriptionsOrderRequest,
    subscriptionNumber: string,
    groupType: GroupType,
    customerUserGroupId?: string
): AddSeatsToSubscription => ({
    type: SubscriptionActions.AddSeatsToSubscriptionInit,
    requestType: RequestType.AddSeatsToSubscription,
    requestActionType: RequestActionType.Init,
    updatedSubscription,
    subscriptionNumber,
    groupType,
    customerUserGroupId,
});

export interface UpdatePoRef extends BusinessActionRequestType {
    type: SubscriptionActions.UpdatePoRefInit;
    subscriptionNumber: string;
    poRef: string;
    groupType: GroupType;
    customerUserGroupId?: string;
}
export const updatePoRef = (
    subscriptionNumber: string,
    poRef: string,
    groupType: GroupType,
    customerUserGroupId?: string
): UpdatePoRef => ({
    type: SubscriptionActions.UpdatePoRefInit,
    requestType: RequestType.UpdatePoRef,
    requestActionType: RequestActionType.Init,
    subscriptionNumber,
    poRef,
    groupType,
    customerUserGroupId,
});

export interface UpdatePoRefSuccess {
    type: SubscriptionActions.UpdatePoRefSuccess;
    subscriptionNumber: string;
    poRef: string;
}

export const updatePoRefSuccess = (subscriptionNumber: string, poRef: string): UpdatePoRefSuccess => ({
    type: SubscriptionActions.UpdatePoRefSuccess,
    subscriptionNumber,
    poRef,
});

export type SubscriptionAction =
    | GetSubscriptionForPartnerCustomerSuccess
    | GetSubscriptionForPartnerCustomer
    | CreateSubscriptionForPartnerCustomerSuccess
    | CreateSubscriptionForPartnerCustomer
    | UpdatePoRefSuccess;

export type SubscriptionEndCustomerAction =
    | GetSubscriptionForEndCustomerSuccess
    | CreateSubscriptionForDirectCustomerSuccess
    | CreateSubscriptionForDirectCustomer
    | UpdatePoRefSuccess;
