import React from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Input from 'commons/src/components/input/Input';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ModalWrapper from 'commons/src/components/modals/ModalWrapper';
import SearchIllustration from 'commons/src/img/Illustrations/search-illustration';
import { SearchLocation } from '../../../../../models/buildingModels';
import styles from './GlobalSearch.module.scss';
import SearchResult from './SearchResult';
import useGlobalSearch from './useGlobalSearch';

type SearchModalProps = {
    open: boolean;
    toggle: () => void;
    minCharacters: number;
    data: SearchLocation[];
};

const SearchModal = ({ open, toggle, minCharacters, data }: SearchModalProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { handleSearch, errorMessage, handleSearchInputChange, searchValue, cleanUp, noDataToDisplay, searchedData } =
        useGlobalSearch(minCharacters, data);

    const isSearchDisabled = searchValue.length < minCharacters;

    const onClose = (): void => {
        cleanUp();
        toggle();
    };

    const onEnterKey = (e: React.KeyboardEvent): void => {
        if (e.key === 'Enter' && !isSearchDisabled) {
            handleSearch();
        }
    };

    return (
        <ModalWrapper
            isOpen={open}
            onClose={onClose}
            header="DeviceAndHubSearch"
            size="medium"
            className={styles.modal}
        >
            <main className={styles.modalWrapper} data-testid="search_modal">
                <section className={styles.searchContainer}>
                    <div>
                        <Input
                            type="text"
                            id="searchOrg"
                            isValid={searchValue.length >= minCharacters}
                            validate={searchValue.length > 0}
                            autoComplete="off"
                            onChange={handleSearchInputChange}
                            currentValue={searchValue}
                            testId="search_org"
                            className={styles.searchInput}
                            onKeyUp={onEnterKey}
                            autoFocus
                        />
                        <PrimaryButton
                            onClick={handleSearch}
                            title="Search"
                            color="primary"
                            loading={false}
                            icon={<MaterialIcon name="search" />}
                            disabled={isSearchDisabled}
                        />
                    </div>
                    {errorMessage && <small className={styles.errorMessage}>{errorMessage}</small>}
                </section>
                <section>
                    {noDataToDisplay && (
                        <figure className={styles.notFound}>
                            <SearchIllustration />
                            <figcaption>{txt('BuildingHealth.SearchNoResults')}</figcaption>
                        </figure>
                    )}
                    {!noDataToDisplay && searchedData.length ? (
                        <ul className={styles.resultList}>
                            {searchedData.map(({ locationName, segmentName, serialNumber, locationId, spaceId }) => (
                                <SearchResult
                                    key={serialNumber}
                                    locationName={locationName}
                                    segmentName={segmentName}
                                    serialNumber={serialNumber}
                                    locationId={locationId}
                                    spaceId={spaceId}
                                />
                            ))}
                        </ul>
                    ) : null}
                </section>
            </main>
        </ModalWrapper>
    );
};

export default SearchModal;
