import React from 'react';

const IconPM = (): React.ReactElement => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        id="iconPM"
        viewBox="0 0 24 24"
        width="24px"
        height="24px"
    >
        <path d="M12 9.84375C10.8091 9.84375 9.84375 10.8091 9.84375 12C9.84375 13.1909 10.8091 14.1562 12 14.1562C13.1909 14.1562 14.1562 13.1909 14.1562 12C14.1562 10.8091 13.1909 9.84375 12 9.84375ZM12 8.15625C14.1228 8.15625 15.8438 9.87716 15.8438 12C15.8438 14.1228 14.1228 15.8438 12 15.8438C9.87716 15.8438 8.15625 14.1228 8.15625 12C8.15625 9.87716 9.87716 8.15625 12 8.15625Z" />
        <path d="M12 1.84375C11.3614 1.84375 10.8438 2.36142 10.8438 3C10.8438 3.63858 11.3614 4.15625 12 4.15625C12.6386 4.15625 13.1562 3.63858 13.1562 3C13.1562 2.36142 12.6386 1.84375 12 1.84375ZM12 0.15625C13.5706 0.15625 14.8438 1.42944 14.8438 3C14.8438 4.57056 13.5706 5.84375 12 5.84375C10.4294 5.84375 9.15625 4.57056 9.15625 3C9.15625 1.42944 10.4294 0.15625 12 0.15625Z" />
        <path d="M12 19.8438C11.3614 19.8438 10.8438 20.3614 10.8438 21C10.8438 21.6386 11.3614 22.1562 12 22.1562C12.6386 22.1562 13.1562 21.6386 13.1562 21C13.1562 20.3614 12.6386 19.8438 12 19.8438ZM12 18.1562C13.5706 18.1562 14.8438 19.4294 14.8438 21C14.8438 22.5706 13.5706 23.8438 12 23.8438C10.4294 23.8438 9.15625 22.5706 9.15625 21C9.15625 19.4294 10.4294 18.1562 12 18.1562Z" />
        <path d="M4 5.84375C3.36142 5.84375 2.84375 6.36142 2.84375 7C2.84375 7.63858 3.36142 8.15625 4 8.15625C4.63858 8.15625 5.15625 7.63858 5.15625 7C5.15625 6.36142 4.63858 5.84375 4 5.84375ZM1.15625 7C1.15625 5.42944 2.42944 4.15625 4 4.15625C5.57056 4.15625 6.84375 5.42944 6.84375 7C6.84375 8.57056 5.57056 9.84375 4 9.84375C2.42944 9.84375 1.15625 8.57056 1.15625 7Z" />
        <path d="M20 15.8438C19.3614 15.8438 18.8438 16.3614 18.8438 17C18.8438 17.6386 19.3614 18.1562 20 18.1562C20.6386 18.1562 21.1562 17.6386 21.1562 17C21.1562 16.3614 20.6386 15.8438 20 15.8438ZM17.1562 17C17.1562 15.4294 18.4294 14.1562 20 14.1562C21.5706 14.1562 22.8438 15.4294 22.8438 17C22.8438 18.5706 21.5706 19.8438 20 19.8438C18.4294 19.8438 17.1562 18.5706 17.1562 17Z" />
        <path d="M4 15.8438C3.36142 15.8438 2.84375 16.3614 2.84375 17C2.84375 17.6386 3.36142 18.1562 4 18.1562C4.63858 18.1562 5.15625 17.6386 5.15625 17C5.15625 16.3614 4.63858 15.8438 4 15.8438ZM1.15625 17C1.15625 15.4294 2.42944 14.1562 4 14.1562C5.57056 14.1562 6.84375 15.4294 6.84375 17C6.84375 18.5706 5.57056 19.8438 4 19.8438C2.42944 19.8438 1.15625 18.5706 1.15625 17Z" />
        <path d="M20 5.84375C19.3614 5.84375 18.8438 6.36142 18.8438 7C18.8438 7.63858 19.3614 8.15625 20 8.15625C20.6386 8.15625 21.1562 7.63858 21.1562 7C21.1562 6.36142 20.6386 5.84375 20 5.84375ZM17.1562 7C17.1562 5.42944 18.4294 4.15625 20 4.15625C21.5706 4.15625 22.8438 5.42944 22.8438 7C22.8438 8.57056 21.5706 9.84375 20 9.84375C18.4294 9.84375 17.1562 8.57056 17.1562 7Z" />
    </svg>
);

export default IconPM;
