import React, { SyntheticEvent, useState } from 'react';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import Input from 'commons/src/components/input/Input';
import SearchAddress from 'commons/src/components/maps/SearchAddress';
import { LocationAddressType, LocationType } from 'commons/src/models/commonTypeScript';

export type Props = {
    onClick: (e: SyntheticEvent<HTMLElement>) => void;
    validateLocationName: boolean;
    locationNameIsValid: boolean;
    defaultLocationName: string;
    onInputChange: (e: SyntheticEvent<HTMLInputElement>) => void;
    locations: LocationType[];
    setLocationCoords: (locationCoords: { lat: number; lng: number }) => void;
    displayLocationValidation: boolean;
};

export const NewLocationComponent = ({
    onClick,
    validateLocationName,
    locationNameIsValid,
    defaultLocationName,
    onInputChange,
    locations,
    setLocationCoords,
    displayLocationValidation,
}: Props): React.ReactElement => {
    const [address, setAddress] = useState('');
    const [countryCodeMissing, setCountryCodeMissing] = useState(false);

    const onUpdateAddress = (locationData: LocationAddressType): void => {
        if (locationData.countryCode) {
            setLocationCoords(locationData.coords);
            setAddress(locationData.address);
            setCountryCodeMissing(false);
        } else {
            setCountryCodeMissing(true);
        }
    };

    const invalidAddress = (displayLocationValidation && address.length === 0) || countryCodeMissing;
    return (
        <div className="add-location">
            <Input
                label="LocationName"
                type="text"
                id="location_name"
                maxLength={50}
                required
                hint="NewLocationHint"
                validate={validateLocationName}
                isValid={locationNameIsValid}
                defaultValue={defaultLocationName}
                onChange={onInputChange}
            />
            {locations.length > 0 && (
                <TertiaryButton title="PickExistingLocation" onClick={onClick} id="changeLocationButton" />
            )}
            <div className="location-pick-map__container input-container">
                <SearchAddress
                    onPlaceChanged={onUpdateAddress}
                    address={address}
                    mandatory
                    displayValidation={invalidAddress}
                    isValid={(address && address.length > 0) || false}
                    infoText="LocationRfRegion.InfoText"
                />
            </div>
        </div>
    );
};

export default NewLocationComponent;
