import React from 'react';

const WavePlusIcon = (): React.ReactElement => (
    <svg
        version="1.1"
        className="device-svg-icons"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
    >
        <path
            d="M24,2C11.9,2,2,11.9,2,24s9.9,22,22,22s22-9.9,22-22S36.1,2,24,2z M24,4c11,0,20,9,20,20
            s-9,20-20,20S4,35,4,24S13,4,24,4z M25.4,22.2h-1.4v3.6c0,0.4,0,0.4,0.1,0.9c0.1,0.4,0.1,0.4,0.4,0.7c0.3,0.3,0.3,0.3,0.7,0.5
            s0.4,0.2,1,0.2c0.6,0,0.6,0,1.1-0.3c0.4-0.3,0.4-0.3,0.6-0.6l0.1-0.1h0V28h1.4v-5.8h-1.4v3.2c0,0.3,0,0.3-0.1,0.6
            c-0.1,0.3-0.1,0.3-0.2,0.5s-0.2,0.2-0.4,0.4S26.8,27,26.4,27c-0.3,0-0.3,0-0.6-0.1c-0.2-0.1-0.2-0.1-0.3-0.4
            c-0.1-0.2-0.1-0.2-0.2-0.5c0-0.2,0-0.2,0-0.4l0-0.2V22.2z M33.2,22.1c-0.4,0-0.4,0-0.8,0.1s-0.4,0.1-0.7,0.3
            c-0.3,0.2-0.3,0.2-0.5,0.6s-0.2,0.3-0.2,0.8c0,0.4,0,0.4,0.1,0.7c0.1,0.3,0.1,0.3,0.4,0.5c0.2,0.2,0.2,0.2,0.5,0.3
            c0.3,0.1,0.3,0.1,0.6,0.2c0.7,0.2,0.7,0.2,1,0.3C34,26,34,26,34,26.4c0,0.2,0,0.2-0.1,0.3s-0.1,0.1-0.2,0.2
            C33.5,27,33.5,27,33.4,27c-0.2,0-0.2,0-0.3,0c-0.5,0-0.5,0-0.9-0.2c-0.3-0.2-0.3-0.2-0.5-0.4l-0.1-0.1l-0.9,0.9
            c0.4,0.5,0.4,0.5,1.1,0.7c0.6,0.2,0.6,0.2,1.3,0.2c0.5,0,0.5,0,0.9-0.1s0.4-0.1,0.8-0.3c0.3-0.2,0.3-0.2,0.5-0.6
            c0.2-0.4,0.2-0.4,0.2-0.9c0-0.4,0-0.4-0.2-0.7C35,25.2,35,25.2,34.8,25s-0.3-0.2-0.6-0.3c-0.3-0.1-0.3-0.1-0.7-0.2
            c-0.6-0.1-0.6-0.1-0.9-0.3c-0.3-0.1-0.3-0.1-0.3-0.5c0-0.3,0-0.3,0.3-0.5c0.3-0.1,0.3-0.1,0.6-0.1c0.4,0,0.4,0,0.7,0.2
            c0.3,0.1,0.3,0.1,0.4,0.3l0.1,0.1l0.9-0.8c-0.3-0.4-0.3-0.4-0.9-0.7C33.8,22.1,33.8,22.1,33.2,22.1z M15.6,19.5h-2.8V28h1.5v-3.5
            h1.2c0.7,0,0.7,0,1.3-0.1s0.6-0.1,1.1-0.4c0.4-0.3,0.4-0.3,0.7-0.8s0.3-0.5,0.3-1.2c0-0.7,0-0.7-0.2-1.1c-0.2-0.5-0.2-0.5-0.6-0.8
            c-0.4-0.3-0.4-0.3-1-0.4c-0.5-0.1-0.5-0.1-1-0.1L15.6,19.5z M21.9,18.9h-1.4V28h1.4V18.9z M15.5,20.8c0.3,0,0.3,0,0.7,0
            c0.3,0,0.3,0,0.6,0.2s0.3,0.1,0.4,0.4s0.2,0.2,0.2,0.6c0,0.4,0,0.4-0.2,0.6S17,22.8,16.7,23s-0.3,0.1-0.6,0.2
            c-0.3,0.1-0.3,0.1-0.7,0.1h-1.1v-2.4H15.5z"
        />
    </svg>
);

export default WavePlusIcon;
