import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { dateFormats } from 'commons/src/constants';
import { Store } from '../../../reducers';

type Props = {
    userName: string;
    dateFormat: keyof typeof dateFormats;
    reportId: string;
};

export const ReportFooterComponent = ({ userName, dateFormat, reportId }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className="insight__footer">
            <div className="insight__footer__details">
                <div className="insight__footer__details__element">
                    <span className="text-bold">
                        {txt('Report.Generated')}
                        {': '}
                    </span>
                    <span>
                        {moment().format(dateFormats[dateFormat].shortFormat)}{' '}
                        {moment().format(dateFormats[dateFormat].hourFormat)}
                    </span>
                </div>
                <div>
                    <span className="text-bold">
                        {txt('Report.MeasurementBy')}
                        {': '}
                    </span>
                    <span>{userName}</span>
                </div>
                <div>
                    <span className="text-bold">
                        {txt('Report.ReportId')}
                        {': '}
                    </span>
                    <span>{reportId}</span>
                </div>
                <div>
                    <span className="text-bold">
                        {txt('RadonInsight.SamplingMethod')}
                        {': '}
                    </span>
                    <span>{txt('RadonInsight.PassiveDiffusionChamber')}</span>
                </div>
                <div>
                    <span className="text-bold">
                        {txt('RadonInsight.MeasurementMethod')}
                        {': '}
                    </span>
                    <span>{txt('RadonInsight.ContinuousAlphaSpectrometry')}</span>
                </div>
            </div>
            <p>{txt('RadonInsight.FooterReportTechnicalInformation')}</p>
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        userSettings: { userName, dateFormat },
        radonInsight: { reportId },
    } = state;
    return {
        userName,
        dateFormat,
        reportId,
    };
};

export default connect(mapStateToProps)(ReportFooterComponent);
