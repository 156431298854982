import { all, call, put, takeEvery, CallEffect, PutEffect } from 'redux-saga/effects';
import { requestSuccess, requestError, RequestActionType } from 'commons/src/actions/requestActions';
import RequestActions from 'commons/src/models/RequestTypes';
import displayAlertBoxSaga from 'commons/src/sagas/displayAlertBox';
import { toErrorType, toErrorTypeWithMessage } from 'commons/src/sagas/isErrorType';
import history from 'commons/src/store/history';
import {
    ThirdPartyIntegrationActionType,
    UpdateNotificationAlert,
    AddNotificationAlert,
    updateNotificationAlertSuccess,
    addNotificationAlertSuccess,
    UpdateAlertActiveState,
    DeleteNotificationAlert,
    deleteNotificationAlertSuccess,
    fetchAlertEventsSuccess,
    AddNotificationAlertSuccess,
    FetchAlertEventsSuccess,
} from '../actions/thirdPartyIntegrationActions';
import {
    addAlertTrigger,
    deleteAlertTrigger,
    updateAlertTrigger,
    fetchAlertEvents,
    deleteThirdPartyIntegration,
} from '../api/integrationsApi';
import { paths, thirdPartyChannels } from '../constants';
import { NotificationAlertEvent } from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type AddNotificationAlertSagaReturnType = Generator<
    CallEffect<{ id: string }> | PutEffect<AddNotificationAlertSuccess> | RequestActions | CallEffect,
    void,
    { id: string }
>;
export function* addNotificationAlertSaga({
    integrationId,
    integrationType,
    notificationAlert,
}: AddNotificationAlert): AddNotificationAlertSagaReturnType {
    try {
        const response: { id: string } = yield call(addAlertTrigger, integrationId, notificationAlert);
        yield put(addNotificationAlertSuccess(notificationAlert, integrationId, integrationType, response.id));
        yield put(requestSuccess(RequestType.AddThirdPartyAlert, RequestActionType.Success));
        history.push(`/${paths.alerts}`);
        yield call(displayAlertBoxSaga, 'NotificationAlerts.AddSuccess', false, true);
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(requestError(errorWithMessage.error, RequestType.AddThirdPartyAlert, RequestActionType.Error));
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

export function* updateNotificationAlertSaga({ notificationAlert }: UpdateNotificationAlert): Generator {
    try {
        yield call(updateAlertTrigger, notificationAlert);
        yield put(updateNotificationAlertSuccess(notificationAlert));
        yield put(requestSuccess(RequestType.UpdateThirdPartyAlert, RequestActionType.Success));
        history.push(`/${paths.alerts}`);
        yield call(displayAlertBoxSaga, 'NotificationAlerts.EditSuccess', false, true);
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error, 'NotificationAlerts.EditError');
        yield put(requestError(errorWithMessage.error, RequestType.UpdateThirdPartyAlert, RequestActionType.Error));
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

export function* updateNotifcationAlertActiveStateSaga({ notificationAlert }: UpdateAlertActiveState): Generator {
    try {
        yield call(updateAlertTrigger, notificationAlert);
        yield put(requestSuccess(RequestType.UpdateAlertActiveState, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.UpdateAlertActiveState, RequestActionType.Error));
    }
}

export function* deleteNotificationAlertSaga({
    integrationId,
    triggerId,
    integrationType,
}: DeleteNotificationAlert): Generator {
    try {
        if (integrationType === thirdPartyChannels.EMAIL) {
            yield call(deleteThirdPartyIntegration, integrationId);
        } else {
            yield call(deleteAlertTrigger, integrationId, triggerId);
        }
        yield put(deleteNotificationAlertSuccess(integrationId, triggerId));
        history.replace(`/${paths.alerts}`);
        yield put(requestSuccess(RequestType.DeleteNotificationAlert, RequestActionType.Success));
        yield call(displayAlertBoxSaga, 'NotificationAlerts.DeleteNotificationAlertSuccess', false, true);
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(requestError(errorWithMessage.error, RequestType.DeleteNotificationAlert, RequestActionType.Error));
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

type GetAlertsHistorySagaReturnType = Generator<
    CallEffect<{ events: NotificationAlertEvent[] }> | PutEffect<FetchAlertEventsSuccess> | RequestActions | CallEffect,
    void,
    { events: NotificationAlertEvent[] }
>;
export function* getAlertsHistory(): GetAlertsHistorySagaReturnType {
    try {
        const response: { events: NotificationAlertEvent[] } = yield call(fetchAlertEvents);
        yield put(fetchAlertEventsSuccess(response.events));
        yield put(requestSuccess(RequestType.FetchAlertEvents, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchAlertEvents, RequestActionType.Error));
    }
}

export default function* notificationAlertSagas(): Generator {
    yield all([
        takeEvery(ThirdPartyIntegrationActionType.AddThirdPartyAlertInit, addNotificationAlertSaga),
        takeEvery(ThirdPartyIntegrationActionType.UpdateThirdPartyAlertInit, updateNotificationAlertSaga),
        takeEvery(
            ThirdPartyIntegrationActionType.UpdateNotificationAlertActiveStateInit,
            updateNotifcationAlertActiveStateSaga
        ),
        takeEvery(ThirdPartyIntegrationActionType.DeleteNotificationAlertInit, deleteNotificationAlertSaga),
        takeEvery(ThirdPartyIntegrationActionType.FetchAlertEventsInit, getAlertsHistory),
    ]);
}
