import React from 'react';
import { useTranslation } from 'react-i18next';
import { BuildingSummaryHealthStatus } from '../../../models/buildingModels';
import { EmptyTable } from '../../status/buildingStatus/components/common';
import styles from './MobileOfflineBuildingsTable.module.scss';

export type Props = {
    offlineBuildings: BuildingSummaryHealthStatus[];
    darkHeader?: boolean;
    emptyTableMessage: string;
};

const LocationName = ({ name }: { name: string }): React.ReactElement => {
    return (
        <div className={styles.nameWithInfo}>
            <div className={styles.deviceName}>{name}</div>
        </div>
    );
};

export default function MobileOfflineBuildingsTable({
    emptyTableMessage,
    offlineBuildings,
    darkHeader,
}: Props): React.ReactElement {
    const { t: txt } = useTranslation();
    return (
        <div className={styles.list}>
            {darkHeader && <div className={styles.mobileHeader}>{txt('BuildingStatus.OfflineBuildingsHeader')}</div>}
            {offlineBuildings.map(building => (
                <div className={styles.item} key={building.locationId}>
                    <div className={styles.itemHeader}>
                        <LocationName name={building.locationName} />
                    </div>
                    <div className={styles.itemOfflineInfo}>
                        <div>
                            <span>{txt('BuildingStatus.OfflineHubsHeader')}:</span>
                            <span>{txt('BuildingStatus.OfflineSensorDevicesHeader')}:</span>
                        </div>
                        <div>
                            <span>
                                {building.offlineHubs}/{building.hubs}
                            </span>
                            <span>
                                {building.offlineSensorDevices}/{building.sensorDevices}
                            </span>
                        </div>
                    </div>
                </div>
            ))}
            {offlineBuildings.length === 0 && <EmptyTable emptyTableText={emptyTableMessage} />}
        </div>
    );
}
