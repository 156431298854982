import { HomeReportData } from '../actions/HomeReportActions';
import { HomeReportResponse } from '../models/commonTypeScript';
import { fetchFromDashboardPublicApi } from './fetch';

export type ReportData = {
    serialNumber: string;
    data: {
        captchaToken: string;
        firstName: string;
        surname: string;
        email: string;
        locale: string;
        address: string;
        zipCode: string;
        city: string;
        buildingType: string;
        buildingYear?: number;
        roomType: string;
        floor: string;
        crc: string;
        duration: number;
        radonLongTermAvg: number;
        ended: string;
    };
};

type ValidateMeasurementDataPayload = {
    crc: string;
    duration: number;
    radonLongTermAvg: number;
};

export interface ValidateMeasurementDataResponse {
    valid: boolean;
}

export const validateMeasurementData = async (
    serialNumber: string,
    payload: ValidateMeasurementDataPayload
): Promise<ValidateMeasurementDataResponse> =>
    fetchFromDashboardPublicApi<ValidateMeasurementDataResponse>(`/home-report/${serialNumber}/validate`, {
        method: 'POST',
        body: JSON.stringify({
            ...payload,
        }),
    });

export const generateHomeReport = async (payload: HomeReportData): Promise<HomeReportResponse> => {
    return fetchFromDashboardPublicApi<HomeReportResponse>(`/home-report/${payload.serialNumber}/generate`, {
        method: 'POST',
        body: JSON.stringify({
            ...payload.data,
        }),
    });
};
