import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { GroupType } from 'commons/src/models/commonEnums';
import {
    BillingDetails,
    EstimatedSubscription,
    Subscription,
    SubscriptionRatePlansResponse,
    SubscriptionsOrderRequest,
} from '../models/subscriptionModels';

export const getSubscriptionForEndCustomer = async (): Promise<Subscription[]> => {
    return fetch<Subscription[]>('/subscriptions', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
};

export default getSubscriptionForEndCustomer;

export const getSubscriptionForPartnerCustomerApi = async (customerUserGroupId: string): Promise<Subscription[]> =>
    fetch<Subscription[]>(`/partner/subscriptions?partnerManagedUserGroupId=${customerUserGroupId}`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const getProductRatePlans = async (): Promise<SubscriptionRatePlansResponse> =>
    fetch(`/subscription/plans`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const getCostPreview = async (
    subscriptionDetails: SubscriptionsOrderRequest,
    groupType: GroupType,
    customerUserGroupId?: string
): Promise<EstimatedSubscription> => {
    const url =
        groupType === GroupType.partner
            ? `/partner/subscription/preview?partnerManagedUserGroupId=${customerUserGroupId}`
            : '/subscription/preview';
    return fetch(url, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(subscriptionDetails),
    });
};

export const getCostPreviewForUpdateSubscription = async (
    groupType: GroupType,
    customerUserGroupId: string,
    subscriptionDetails: SubscriptionsOrderRequest,
    subscriptionNumber: string
): Promise<EstimatedSubscription> => {
    const url =
        groupType === GroupType.partner
            ? `/partner/subscription/${subscriptionNumber}/preview?partnerManagedUserGroupId=${customerUserGroupId}`
            : '/subscription/preview';
    return fetch(url, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(subscriptionDetails),
    });
};

export const startSubscription = async (
    customerUserGroupId: string,
    subscriptionDetails: SubscriptionsOrderRequest
): Promise<{ subscriptionNumber: string }> =>
    fetch(`/partner/subscription?partnerManagedUserGroupId=${customerUserGroupId}`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(subscriptionDetails),
    });

export const startSubscriptionForDirectCustomer = async (
    subscriptionDetails: SubscriptionsOrderRequest
): Promise<{ subscriptionNumber: string }> =>
    fetch(`/subscription`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(subscriptionDetails),
    });

export const addSeats = async (
    subscriptionDetails: SubscriptionsOrderRequest,
    subscriptionNumber: string,
    groupType: GroupType,
    customerUserGroupId?: string
): Promise<{ subscriptionNumber: string }> => {
    const url =
        groupType === GroupType.partner
            ? `/partner/subscription/${subscriptionNumber}?partnerManagedUserGroupId=${customerUserGroupId}`
            : `/subscription/${subscriptionNumber}`;
    return fetch(url, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(subscriptionDetails),
    });
};

export const getSubscriptionBillingDetails = async (): Promise<BillingDetails> =>
    fetch<BillingDetails>('/subscription/billing-details', { method: 'GET', headers: await getFetchHeaders() });

export const updatePoRef = async (
    subscriptionNumber: string,
    poNumber: string,
    groupType: GroupType,
    customerUserGroupId?: string
): Promise<void> => {
    const url =
        groupType === GroupType.partner
            ? `/partner/subscription/${subscriptionNumber}/update-po?partnerManagedUserGroupId=${customerUserGroupId}`
            : `/subscription/${subscriptionNumber}/update-po`;
    return fetch(url, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ purchaseOrderNumber: poNumber }),
    });
};
