import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '../MaterialIcon';
import styles from './AccordionButton.module.scss';

type Props = {
    title?: string;
    onClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void | Promise<void>;
    id?: string;
    testId?: string;
    expanded: boolean;
    slim?: boolean;
    translated?: boolean;
};

const AccordionButton = ({ title, onClick, id, testId, expanded, translated, slim }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <button
            type="button"
            id={id}
            className={slim ? styles.slimButton : styles.button}
            onClick={onClick}
            {...{ 'data-testid': testId }}
        >
            {title && !translated ? txt(title) : title}
            <MaterialIcon name={expanded ? 'expand_less' : 'expand_more'} />
        </button>
    );
};

export default AccordionButton;
