import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { statusColorForHttpResponse } from 'commons/src/commonFunctions';
import { statusColors } from 'commons/src/constants';
import { WebhookEvent } from '../../../models/common';
import styles from './WebhookEventRow.module.scss';

export type Props = {
    event: WebhookEvent;
};

export const WebhookEventRowComponent = ({ event }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const statusColor = statusColorForHttpResponse(event.statusCode);
    return (
        <div className="list__row elevation-2">
            <div className="list__row__text-container">
                <div className={styles.completedAt}>{[txt('Webhooks.CompletedAt'), ': ', event.completedAt]}</div>
                <div className={styles.eventElement}>{[txt('Webhooks.CreatedAt'), ': ', event.createdAt]}</div>
                <div className={styles.eventElement}>{[txt('Webhooks.Url'), ': ', event.url]}</div>
                <div className={styles.eventElement}>{[txt('Webhooks.EventType'), ': ', event.eventType]}</div>
                <div className={styles.eventElement}>
                    <div
                        className={classNames('sensor__line', {
                            'sensor__line--ok': statusColor === statusColors.green,
                            'sensor__line--alert': statusColor === statusColors.red,
                            'sensor__line--disabled': statusColor === statusColors.grey,
                        })}
                    />
                    {[txt('Webhooks.StatusCode'), ': ', event.statusCode]}
                </div>
            </div>
        </div>
    );
};

export default WebhookEventRowComponent;
