import {
    ADD_WEBHOOK_SUCCESS,
    DELETE_WEBHOOK_SUCCESS,
    FETCH_WEBHOOKS_SUCCESS,
    FETCH_WEBHOOK_EVENTS_SUCCESS,
    UPDATE_WEBHOOK_SUCCESS,
    WebhooksActionType,
    WebhookReducerActions,
} from '../actions/integrationActions';
import { Webhook, WebhookEvent } from '../models/common';

export type WebhooksState = {
    webhooks: Webhook[];
    webhookEvents: WebhookEvent[];
};

const initialState: WebhooksState = {
    webhooks: [],
    webhookEvents: [],
};

const { UpdateWebhookActiveStateInit, FetchWebhooksInit } = WebhooksActionType;

export default (state: WebhooksState = initialState, action: WebhookReducerActions): WebhooksState => {
    switch (action.type) {
        case FetchWebhooksInit:
            return {
                ...state,
            };
        case FETCH_WEBHOOKS_SUCCESS:
            return {
                ...state,
                webhooks: action.webhooks,
            };
        case UpdateWebhookActiveStateInit: {
            const indexOfEditedWebhook = state.webhooks.findIndex(webhook => webhook.id === action.webhook.id);
            const copyOfWebhookList = [...state.webhooks];
            copyOfWebhookList[indexOfEditedWebhook] = action.webhook;
            return {
                ...state,
                webhooks: copyOfWebhookList,
            };
        }
        case UPDATE_WEBHOOK_SUCCESS: {
            const indexOfEditedWebhook = state.webhooks.findIndex(webhook => webhook.id === action.webhook.id);
            const copyOfWebhookList = [...state.webhooks];
            copyOfWebhookList[indexOfEditedWebhook] = action.webhook;
            return {
                ...state,
                webhooks: copyOfWebhookList,
            };
        }
        case ADD_WEBHOOK_SUCCESS:
            return {
                ...state,
                webhooks: [...state.webhooks, action.webhook],
            };
        case DELETE_WEBHOOK_SUCCESS:
            return {
                ...state,
                webhooks: state.webhooks.filter(webhook => webhook.id !== action.webhookId),
            };
        case FETCH_WEBHOOK_EVENTS_SUCCESS:
            return {
                ...state,
                webhookEvents: action.events,
            };
        default:
            return state;
    }
};
