import React, { useMemo } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { Cell, Column, Row, useExpanded, useSortBy, useTable } from 'react-table';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { fullwidthListElement } from 'commons/src/components/placeholder';
import { dateFormats } from 'commons/src/constants';
import useWindowSize from 'commons/src/hooks/useWindowSize';
import { DeviceTypeNames, GroupType } from 'commons/src/models/commonEnums';
import { Subscription } from '../../../models/subscriptionModels';
import { Store } from '../../../reducers';
import { getFormattedCost } from './SubscriptionCommons';
import styles from './SubscriptionTable.module.scss';
import SubscriptionTableExpandedView from './SubscriptionTableExpandedView';
import SubscriptionTableSlim from './SubscriptionTableSlim';

type StateProps = {
    dateFormat: keyof typeof dateFormats;
};

export type ParentProps = {
    subscriptions: Subscription[];
    childUserGroupId?: string;
    groupType: GroupType;
};

export type Props = StateProps & ParentProps;

const ExpandRow = ({ row, cell }: { row: Row; cell: Cell }): React.ReactElement => {
    return (
        <span className={styles.alignedCellContent}>
            <MaterialIcon name={row.isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'} />
            {cell.value}
        </span>
    );
};

export const NumberOfSeats = ({ cell }: { cell: Cell }): React.ReactElement => {
    const totalNumberOfSeats = cell.value.reduce(
        (seats: number, deviceType: { deviceType: DeviceTypeNames; seats: number }) => {
            return seats + deviceType.seats;
        },
        0
    );
    return <div>{totalNumberOfSeats}</div>;
};

const DateCell = ({ cell }: { cell: Cell }): React.ReactElement => {
    const columnData = cell.column;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { dateFormat } = columnData;
    const renewalDate = cell.value
        ? dayjs(cell.value, 'YYYY-MM-DD').format(dateFormats[dateFormat as keyof typeof dateFormats].calendarFormat)
        : '-';
    return <span>{renewalDate}</span>;
};

const TotalCostCell = ({ row, cell }: { row: Row; cell: Cell }): React.ReactElement => {
    const { currency } = row.original as Subscription;
    const formattedCost = getFormattedCost(currency, cell.value);
    return <span>{formattedCost}</span>;
};

const MOBILE_TABLE_BREAKPOINT = 770;

const SubscriptionTable = ({ dateFormat, subscriptions, childUserGroupId, groupType }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { width } = useWindowSize();

    const columns = useMemo<Column[]>(
        () => [
            {
                Header: txt('Subscription.Subscription'),
                accessor: 'subscriptionNumber',
                className: styles.firstCell,
                Cell: ExpandRow,
            },
            {
                Header: txt('Subscription.PurchaseOrder'),
                accessor: 'poNumber',
                className: styles.poNumberCell,
                sortType: (a: Row, b: Row) => (a.values.poNumber || '').localeCompare(b.values.poNumber || ''),
            },
            {
                Header: txt('Subscription.RenewalDate'),
                accessor: 'renewalDate',
                className: styles.textCell,
                dateFormat,
                Cell: DateCell,
                sortType: (a: Row, b: Row): number => {
                    const renewalDateA = a.values.renewalDate;
                    const renewalDateB = b.values.renewalDate;
                    if (!renewalDateA) return -1;
                    if (!renewalDateB) return 1;
                    const dataAUnix = dayjs(renewalDateA, 'YYYY-MM-DD').unix();
                    const dateBUnix = dayjs(renewalDateB, 'YYYY-MM-DD').unix();
                    return dataAUnix - dateBUnix;
                },
            },
            {
                Header: txt('Subscription.Seats'),
                accessor: 'deviceSeats',
                className: styles.seatsCell,
                Cell: NumberOfSeats,
                sortType: (a: Row, b: Row): number => {
                    const deviceSeatsA = a.values.deviceSeats.reduce(
                        (seats: number, deviceType: { deviceType: DeviceTypeNames; seats: number }) => {
                            return seats + deviceType.seats;
                        },
                        0
                    );
                    const deviceSeatsB = b.values.deviceSeats.reduce(
                        (seats: number, deviceType: { deviceType: DeviceTypeNames; seats: number }) => {
                            return seats + deviceType.seats;
                        },
                        0
                    );
                    return deviceSeatsA - deviceSeatsB;
                },
            },
            {
                Header: txt('Subscription.EstimatedTotalCost'),
                accessor: 'estimatedTotalCost',
                className: styles.rightAlignedCell,
                Cell: TotalCostCell,
                sortType: (a: Row, b: Row): number => a.values.estimatedTotalCost - b.values.estimatedTotalCost,
            },
        ],
        []
    );

    const expandedTable = (row: Row): React.ReactElement => {
        return (
            <div className={styles.expandedContentWrapper}>
                <SubscriptionTableExpandedView
                    subscription={row.original as Subscription}
                    groupType={groupType}
                    childUserGroupId={childUserGroupId}
                />
            </div>
        );
    };

    const initialState = {
        sortBy: [{ id: 'renewalDate', desc: true }],
    };

    const tableData = useMemo<Subscription[]>(() => subscriptions, [subscriptions]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleAllRowsExpanded } = useTable(
        {
            columns,
            data: tableData,
            initialState,
        },
        useSortBy,
        useExpanded
    );

    React.useEffect(() => {
        if (tableData.length === 1) toggleAllRowsExpanded(true);
    }, [tableData]);

    return (
        // returning slim table when in slim view else wide-table
        <ReactPlaceholder ready customPlaceholder={fullwidthListElement}>
            {width > MOBILE_TABLE_BREAKPOINT ? (
                <div {...getTableProps()} className={styles.table}>
                    {rows.length > 0 &&
                        headerGroups.map(headerGroup => (
                            <div {...headerGroup.getHeaderGroupProps({ className: styles.header })}>
                                {headerGroup.headers.map(column => {
                                    return (
                                        <div
                                            role="button"
                                            tabIndex={0}
                                            onKeyUp={(e): void => {
                                                if (e.key === 'Enter') column.toggleSortBy();
                                            }}
                                            {...column.getHeaderProps([
                                                {
                                                    className: classNames(styles.cell, column.className),
                                                },
                                                column.getSortByToggleProps(),
                                            ])}
                                        >
                                            <div className={styles.headerCell}>
                                                {column.render('Header')}
                                                {column.isSorted ? (
                                                    <MaterialIcon
                                                        name={column.isSortedDesc ? 'expand_less' : 'expand_more'}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                    <div {...getTableBodyProps()}>
                        {rows.length > 0 &&
                            rows.map(row => {
                                prepareRow(row);
                                return (
                                    <div {...row.getRowProps()}>
                                        <div
                                            role="button"
                                            tabIndex={0}
                                            onKeyUp={(e): void => {
                                                if (e.key === 'Enter') row.toggleRowExpanded();
                                            }}
                                            className={styles.row}
                                            {...row.getToggleRowExpandedProps()}
                                        >
                                            {row.cells.map(({ getCellProps, render, column }) => {
                                                return (
                                                    <div
                                                        {...getCellProps({
                                                            className: classNames(styles.cell, column.className),
                                                        })}
                                                    >
                                                        <span>{render('Cell')}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {row.isExpanded ? expandedTable(row) : null}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            ) : (
                <SubscriptionTableSlim
                    subscriptions={tableData}
                    childUserGroupId={childUserGroupId}
                    groupType={groupType}
                />
            )}
        </ReactPlaceholder>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = store;

    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(SubscriptionTable);
