import React, { SyntheticEvent } from 'react';
import moment, { Moment } from 'moment';
import { connect } from 'react-redux';
import DatePicker from '../../components/DatePicker';
import Input from '../../components/input/Input';
import ResponseBox from '../../components/responseMessages/ResponseBox';
import { dateFormats } from '../../constants';
import { Store } from '../../reducers';

export type ParentProps = {
    code: string;
    endDate: Moment | null;
    serialNumber: string;
    daysMeasured: string;
    invalidCode: boolean;
    invalidEndDate: boolean;
    invalidRadonLevel: boolean;
    invalidSerialNumber: boolean;
    invalidDaysMeasured: boolean;
    onDateChange: (date: Moment) => void;
    onInputChange: (e: SyntheticEvent<HTMLInputElement>) => void;
    getText: (term: string) => string;
    resetDeviceDataValidations: () => void;
    dateFormat: string;
    validDeviceData: boolean;
};

type StateProps = {
    dateFormat: keyof typeof dateFormats;
};

type Props = ParentProps & StateProps;

export const MeasurementFormSectionComponent = (props: Props): React.ReactElement => {
    const {
        dateFormat,
        invalidDaysMeasured,
        invalidRadonLevel,
        serialNumber,
        code,
        daysMeasured,
        endDate,
        getText,
        invalidCode,
        invalidEndDate,
        invalidSerialNumber,
        onDateChange,
        onInputChange,
        resetDeviceDataValidations,
        validDeviceData,
    } = props;

    const isValidInput = (value: string, pattern: RegExp): boolean => pattern.test(value);

    const onNumberChange = (e: SyntheticEvent<HTMLInputElement>): void => {
        const value = e.currentTarget.value.trim();
        if (isValidInput(value, /^\d*$/)) {
            onInputChange(e);
            resetDeviceDataValidations();
        }
    };

    const onInputChangeWrapper = (e: SyntheticEvent<HTMLInputElement>): void => {
        onInputChange(e);
        resetDeviceDataValidations();
    };

    const endDateInvalid = (day: Moment): boolean => {
        if (!moment.isMoment(day)) return false;
        const today = moment().endOf('day');
        return day.isAfter(today);
    };
    return (
        <div className="radon-report__form__section radon-report__form__section--border">
            <h2 className="radon-report__form__section__header">{getText('MeasurementFormSectionHeader')}</h2>
            <div className="radon-report__form__section__row-resp-column-collapse radon-report__form__section__row-resp-column-collapse--first-largest">
                <Input
                    type="text"
                    id="serialNumber"
                    label="SerialNumber"
                    markedMandatory
                    infoText="SerialNumberInfo"
                    autoComplete="serial-number"
                    required
                    maxLength={10}
                    hint={validDeviceData ? 'SerialNumberHint' : undefined}
                    isValid={false}
                    validate={invalidSerialNumber}
                    onChange={onNumberChange}
                    currentValue={serialNumber}
                />
                <DatePicker
                    id="endDate"
                    onChange={onDateChange}
                    dateFormat={dateFormat}
                    disabledDate={endDateInvalid}
                    selectedDate={endDate}
                    label="EndDate"
                    mandatory
                    displayDateValidation={invalidEndDate}
                    selectedDateHint="EndDateHint"
                />
            </div>
            <div
                className={
                    'radon-report__form__section__row-resp-column-collapse' +
                    ' radon-report__form__section__row-resp-column-collapse--first-largest'
                }
            >
                <Input
                    type="number"
                    id="radonLevel"
                    label="RadonLevel"
                    infoText="RadonLevelInfo"
                    required
                    autoComplete="radon-level"
                    markedMandatory
                    maxLength={6}
                    hint={validDeviceData ? 'RadonLevelHint' : undefined}
                    isValid={false}
                    validate={invalidRadonLevel}
                    onChange={onInputChangeWrapper}
                />
                <Input
                    type="text"
                    id="daysMeasured"
                    label="DaysMeasured"
                    required
                    infoText="DaysMeasuredInfo"
                    autoComplete="days-measured"
                    markedMandatory
                    maxLength={6}
                    hint={validDeviceData ? 'DaysMeasuredHint' : undefined}
                    isValid={false}
                    validate={invalidDaysMeasured}
                    onChange={onNumberChange}
                    currentValue={daysMeasured}
                />
            </div>
            <div className="radon-report__form__section__row-resp-column-collapse">
                <Input
                    type="text"
                    id="code"
                    label="Code"
                    infoText="CodeInfo"
                    required
                    markedMandatory
                    maxLength={4}
                    hint={validDeviceData ? 'CodeHint' : undefined}
                    isValid={false}
                    validate={invalidCode}
                    onChange={onNumberChange}
                    currentValue={code}
                />
            </div>
            {!validDeviceData && <ResponseBox text={getText('InvalidDeviceDataMessage')} />}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = state;
    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(MeasurementFormSectionComponent);
