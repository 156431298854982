import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import ModalHeader from '../modals/ModalHeader';

type Props = {
    title: string;
    onClose: () => void;
    children: ReactElement;
    description?: string;
};
const ReactPdfDownloadModal = ({ title, onClose, children, description }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <Modal
            isOpen
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--size-medium"
            overlayClassName="modal modal__overlay"
        >
            <ModalHeader headerText={title} onClose={onClose} />
            <div className="modal__content__body">
                <div className="modal__content__description modal__content__description--small">
                    {txt(description || 'DownloadReportModalText')}
                    <div className="modal__content__description__download">{children}</div>
                </div>
            </div>
        </Modal>
    );
};

export default ReactPdfDownloadModal;
