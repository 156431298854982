import React from 'react';
import { colors } from '../constants';

const standAloneSpinner = (): React.ReactElement => (
    <svg
        version="1.1"
        id="Layer_1"
        className="spinner spinner--large"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 168 168"
    >
        <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="53.1604"
            y1="162.0063"
            x2="110.517"
            y2="4.4204"
        >
            <stop offset="0.1" style={{ stopColor: colors.white }} />
            <stop offset="0.95" style={{ stopColor: colors.blueCerulean }} />
        </linearGradient>
        <path
            className="spinner--path"
            d="M84,153.8c-38.5,0-69.8-31.3-69.8-69.8S45.5,14.2,84,14.2s69.8,31.3,69.8,69.8c0,0.2-0.3,7,7.1,7 c6.9,0,6.9-6.8,6.9-7c0-46.2-37.6-83.8-83.8-83.8C37.8,0.2,0.2,37.8,0.2,84c0,46.2,37.6,83.8,83.8,83.8c0.4,0,7,0,7-6.9 C91,154.1,84.4,153.8,84,153.8z"
        />
    </svg>
);
export default standAloneSpinner;
