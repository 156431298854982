import React, { ReactElement, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as List } from 'react-window';
import Checkbox from '../../input/Checkbox';
import styles from './SimpleTreeView.module.scss';
import TreeViewListItem from './TreeViewListItem';

type TreeViewOption = {
    id: string;
    name: string;
};

export type TreeViewProps = {
    listId: string;
    options: TreeViewOption[];
    selectedOptions: string[];
    onChange: (args: { id: string; changeAll: boolean }) => void;
};

type RowProps = {
    index: number;
    style: React.CSSProperties;
    options: TreeViewOption[];
    selectedOptions: string[];
    onChangeInternal: (e: SyntheticEvent<HTMLElement>) => void;
};
const RowComponent = ({ index, style, options, selectedOptions, onChangeInternal }: RowProps): ReactElement | null => {
    const option = options && options[index];
    if (!option) return null;

    const isChecked = selectedOptions.findIndex(opt => opt === option.id) > -1;

    return (
        <div key={`device-selector-element-hub-${option.id}`} style={style}>
            <TreeViewListItem
                key={`tree-view-element-${option.id}`}
                onChange={onChangeInternal}
                checked={isChecked}
                name={option.name}
                id={option.id}
                level={0}
                canExpand={false}
                isExpanded={false}
            />
        </div>
    );
};

const SimpleTreeViewComponent = ({ listId, onChange, options, selectedOptions }: TreeViewProps): React.ReactElement => {
    const { t: txt } = useTranslation();

    const onChangeInternal = (e: SyntheticEvent<HTMLElement>, changeAll: boolean): void => {
        onChange({ id: e.currentTarget.id, changeAll });
    };

    const allSelected = selectedOptions.length === options.length;

    const listElementHeight = 36;
    const heightOfRows = options.length * listElementHeight;
    const heightOfList = heightOfRows > 290 ? 290 : heightOfRows;

    return (
        <div>
            <Checkbox
                label={txt('SelectAll')}
                onChange={(e): void => onChangeInternal(e, true)}
                checked={allSelected}
                key={`tree-view-item-checkbox-${listId}-select-all`}
                id={`${listId}-select-all`}
                testId={`data-${listId}-select-all`}
            />
            <div className={styles.treeView}>
                <ul id={listId}>
                    <List width="100%" height={heightOfList} itemCount={options.length} itemSize={listElementHeight}>
                        {({ index, style }): ReactElement => (
                            <RowComponent
                                index={index}
                                style={style}
                                options={options}
                                selectedOptions={selectedOptions}
                                onChangeInternal={(e): void => onChangeInternal(e, false)}
                            />
                        )}
                    </List>
                </ul>
            </div>
        </div>
    );
};

export default SimpleTreeViewComponent;
