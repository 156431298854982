import { INITIALIZE_SUCCESS, APP_ERROR, HIDE_COOKIE_BANNER, IndexReducerActions } from '../actions';

const cookies: { [key: string]: string } = {};
document.cookie.replace(/([^=;]+)=([^;]*)/gi, (m, key, value) => {
    cookies[key.trim()] = value.trim();
});

const displayCookieBanner = (): boolean => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return false;
    }
    return !cookies['accepts-cookies'];
};

export interface AppStore {
    error: boolean;
    loading: boolean;
    displayCookieBanner: boolean;
}

const initialState = {
    error: false,
    loading: true,
    displayCookieBanner: displayCookieBanner(),
};

export default (state = initialState, action: IndexReducerActions): AppStore => {
    switch (action.type) {
        case INITIALIZE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case APP_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case HIDE_COOKIE_BANNER:
            return {
                ...state,
                displayCookieBanner: false,
            };
        default:
            return state;
    }
};
