/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import * as Highcharts from 'highcharts';
import 'highcharts/modules/offline-exporting';
import 'highcharts/modules/export-data';
import { dateFormats, sensorUnits, tooltipBorderColor, colors } from 'commons/src/constants';

export const graphConfig = (
    chartData: [number, number | null][],
    thresholds: { value?: number; color: string }[],
    unit: string,
    chartHeight: number,
    minValue: number,
    dateFormat: keyof typeof dateFormats,
    plotBands: { color: string; from: number; to: number }[],
    sensor: string,
    graphId: string
) => ({
    credits: {
        enabled: false,
    },
    title: {
        text: '',
    },
    chart: {
        id: graphId,
        height: chartHeight,
        type: 'line',
        spacingBottom: 5,
        style: {
            fontFamily: 'OpenSans-Regular',
        },
    },
    tooltip: {
        borderColor: tooltipBorderColor,
        style: {
            color: colors.white,
        },
        hideDelay: 0,
        formatter(this: Highcharts.TooltipFormatterContextObject): string {
            const sensorValue = this.y as number;
            const timeValue = this.x as number;
            let yValue: string | number = Math.round(sensorValue * 10) / 10;
            yValue =
                yValue % 1 === 0 || unit === sensorUnits.bq
                    ? Highcharts.numberFormat(yValue, 0)
                    : Highcharts.numberFormat(yValue, 1);
            const dateTime = (): string => Highcharts.dateFormat(dateFormats[dateFormat].time, timeValue);
            const dayDate = (): string => `${Highcharts.dateFormat(dateFormats[dateFormat].weekDay, timeValue)}<br/>
                    ${Highcharts.dateFormat(dateFormats[dateFormat].date, timeValue)}<br/>`;
            return `${dayDate()}<br/>${dateTime()}<br />${yValue} ${unit}`;
        },
    },
    xAxis: {
        lineColor: 'transparent',
        tickLength: 0,
        plotBands,
        tickPixelInterval: 40,
        type: 'linear',
        gridLineWidth: 0,
        margin: 0,
        labels: {
            formatter(this: { value: number }): string {
                return Highcharts.dateFormat(dateFormats[dateFormat].axisDate.week, this.value);
            },
        },
    },
    yAxis: {
        min: minValue,
        gridLineWidth: 0,
        tickPixelInterval: 40,
        title: {
            text: null,
        },
    },
    series: [
        {
            type: 'line',
            lineWidth: 2.3,
            zones: thresholds,
            showInLegend: false,
            data: chartData,
            connectNulls: false,
            marker: {
                enabled: false,
                symbol: 'circle',
            },
            states: {
                hover: { lineWidthPlus: 0 },
            },
        },
    ],
    plotOptions: {
        series: {
            states: {
                hover: { lineWidthPlus: 0 },
            },
            animation: { duration: 1200 },
        },
    },
    exporting: {
        sourceWidth: 1000,
        sourceHeight: 250,
        chartOptions: {
            subtitle: null,
        },
        buttons: {
            contextButton: {
                enabled: false,
            },
        },
    },
});
export type SensorGraphConfig = ReturnType<typeof graphConfig>;
export default graphConfig;
