import { IdentityProvider } from 'commons/src/models/commonEnums';

export const identityProviderValue = (issuer: string): IdentityProvider => {
    if (issuer.includes('google')) {
        return IdentityProvider.Google;
    }
    if (issuer.includes('microsoft')) {
        return IdentityProvider.Microsoft;
    }
    return IdentityProvider.Other;
};

export const secretPlaceholder = '********';
