import moment from 'moment';
import { DayUsageHours } from 'commons/src/models/commonTypeScript';

export const minutesOpenForDay = (date: string, usageHours: { [day: string]: DayUsageHours }): number => {
    const weekDayString = moment(date).locale('en').format('dddd').toLowerCase();
    const usageHourForDay = usageHours[weekDayString];
    if (usageHourForDay) {
        const { closed, from, to } = usageHourForDay;
        if (!closed && from && to) {
            return moment(to, 'HH:mm').diff(moment(from, 'HH:mm'), 'minutes');
        }
    }
    return 0;
};

export default minutesOpenForDay;
