import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import SearchComponent from 'commons/src/components/input/SearchComponent';
import SelectorList from 'commons/src/components/lists/SelectorList';
import { ThirdPartyMappingLocation } from '../../../models/common';

type Props = {
    locations: ThirdPartyMappingLocation[];
    headerTitle: string;
    integrationType: string;
    inputId: string;
    onSearch: (value: string, id: string) => void;
    setSelectedLocation: (locationId: string, selectorId: string) => void;
    selectedLocation: ThirdPartyMappingLocation | undefined;
    selectorId: string;
    invertedMargin: boolean;
};

const LocationsSelectorSection = (props: Props): React.ReactElement => {
    const {
        locations,
        headerTitle,
        integrationType,
        inputId,
        onSearch,
        setSelectedLocation,
        selectedLocation,
        selectorId,
        invertedMargin,
    } = props;
    const { t: txt } = useTranslation();
    const selectLocation = (id: string): void => {
        setSelectedLocation(id, selectorId);
    };
    return (
        <div
            className={classNames('third-party__mapping__wrapper', {
                'third-party__mapping--invert-margin': invertedMargin,
            })}
        >
            <h3 className="third-party__mapping__selector-header">
                {txt(headerTitle, { integration: txt(`ThirdParty.${integrationType}`) })}
            </h3>
            <SearchComponent
                expandable={false}
                onChange={(value: string): void => onSearch(value, inputId)}
                placeholder={txt('ThirdParty.LocationSearch')}
                testId={inputId}
                size="lg"
            />
            <SelectorList
                options={locations}
                selectedOptions={selectedLocation ? [selectedLocation.id] : []}
                onSelect={selectLocation}
                center={false}
            />
        </div>
    );
};

export default LocationsSelectorSection;
