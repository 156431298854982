import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { integrationTypeLogos, thirdPartyChannels } from '../../constants';

const IntegrationLogo = ({ integrationType }: { integrationType: string }): React.ReactElement => {
    const { t: txt } = useTranslation();

    return integrationType === thirdPartyChannels.EMAIL ? (
        <div className="flex-center notification-alert__email">
            <MaterialIcon name="email" />
            <span className="notification-alert__email__text">{txt('Email')}</span>
        </div>
    ) : (
        <img
            src={integrationTypeLogos(integrationType)}
            className="notification-alert__img"
            alt={txt('ThirdParty.IntegrationTypeLogo', {
                type: txt(`ThirdParty.${integrationType}`),
            })}
        />
    );
};

export default IntegrationLogo;
