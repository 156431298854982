import delay from '@redux-saga/delay-p';
import { all, call, CallEffect, put, PutEffect, race, select, SelectEffect, take, takeEvery } from 'redux-saga/effects';
import RequestActions from 'commons/src/models/RequestTypes';
import {
    AddLocation,
    addLocationSuccess,
    AddLocationSuccess,
    DeleteLocation,
    deleteLocationSuccess,
    fetchLocationsError,
    fetchLocationsSuccess,
    LocationActions,
    ValidateLocationRfRegion,
    validateLocationRfRegionSuccess,
    ValidateLocationRfRegionSuccess,
} from '../../actions/LocationActions';
import { FETCH_LOCATIONS, POLL_DATA } from '../../actions/LocationActionTypes';
import { LOGOUT_SUCCESS } from '../../actions/LoginAndRegisterActions';
import { RequestActionType, requestError, requestSuccess } from '../../actions/requestActions';
import { fetchAllDevices } from '../../api/devices';
import { offlineError } from '../../api/fetch';
import { addLocation, deleteLocation, fetchLocations, validateLocationRfRegion } from '../../api/locations';
import { userIsHbs } from '../../components/findUserType';
import { paths, pollingDelayMs } from '../../constants';
import {
    AllDevicesResponse,
    BuildingResponseType,
    BuildingType,
    DevicesWithKeyInfoResponse,
    DeviceWithKeyInfo,
    ErrorType,
    LocationResponseType,
    LocationRfRegionResponse,
    LocationType,
    LocationsResponse,
} from '../../models/commonTypeScript';
import { isLoggedIn, locations as locationsInStore } from '../../reducers/reducerShortcuts';
import { BusinessRequestTypesUsedInCommons as RequestType, CommonRequestType } from '../../reducers/requestReducer';
import history from '../../store/history';
import displayAlertBoxSaga from '../displayAlertBox';
import { toErrorType } from '../isErrorType';

const addLocationToDevicesWithKeyInfo = (
    devices: { [serialNumber: string]: DeviceWithKeyInfo },
    locationId: string
): { [serialNumber: string]: DeviceWithKeyInfo } =>
    Object.values(devices)
        .map(device => ({ ...device, locationId }))
        .reduce((items, device) => ({ ...items, [device.serialNumber]: device }), {});

export const getDevicesFromLocationResponse = (
    locations: LocationResponseType[] | BuildingResponseType[]
): DevicesWithKeyInfoResponse => {
    const devices = locations
        .flatMap(location => ({
            devicesWithKeyInfo: addLocationToDevicesWithKeyInfo(location.devicesWithKeyInfo, location.id),
            hubsWithKeyInfo: addLocationToDevicesWithKeyInfo(location.hubsWithKeyInfo, location.id),
        }))
        .reduce(
            (items, device) => ({
                devicesWithKeyInfo: { ...items.devicesWithKeyInfo, ...device.devicesWithKeyInfo },
                hubsWithKeyInfo: { ...items.hubsWithKeyInfo, ...device.hubsWithKeyInfo },
            }),
            { devicesWithKeyInfo: {}, hubsWithKeyInfo: {} }
        );
    return {
        devicesWithKeyInfo: devices.devicesWithKeyInfo,
        hubsWithKeyInfo: devices.hubsWithKeyInfo,
    };
};

export const transformResponseToLocationType = (
    locations: (LocationResponseType | BuildingResponseType)[]
): LocationType[] | BuildingType[] =>
    locations.map((location: LocationResponseType | BuildingResponseType) => ({
        ...location,
        devices: Object.values(location.devicesWithKeyInfo).map(device => device.serialNumber),
        hubs: Object.values(location.hubsWithKeyInfo).map(device => device.serialNumber),
    }));

export function* deleteLocationSaga({ locationId }: DeleteLocation): Generator {
    try {
        yield call(deleteLocation, locationId);
        yield put(requestSuccess(RequestType.DeleteLocation, RequestActionType.Success));
        yield put(deleteLocationSuccess(locationId));
        yield call(displayAlertBoxSaga, 'BuildingDeleted', false, true);
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.DeleteLocation, RequestActionType.Error));
        yield call(displayAlertBoxSaga, `ErrorCodes.${errorAsErrorType.error}`, true, true);
    }
}

type AddLocationSagaActionType = Generator<
    CallEffect<{ id: string }> | CallEffect | PutEffect<AddLocationSuccess> | RequestActions | PutEffect | void,
    void,
    { id: string }
>;
export function* addLocationSaga({ payload }: AddLocation): AddLocationSagaActionType {
    const emptyLocationAttrs = {
        devices: [],
        usageHours: {},
        hubs: [],
    };

    try {
        const response = yield call(addLocation, payload);
        yield put(requestSuccess(RequestType.AddLocation, RequestActionType.Success));
        yield put(addLocationSuccess({ ...emptyLocationAttrs, ...payload, id: response.id }));
        yield history.push(`/${paths.buildings}/${response.id}`);
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.AddLocation, RequestActionType.Error));
        yield call(displayAlertBoxSaga, `ErrorCodes.${asErrorType.error}`, true, true);
    }
}

type ValidateRfRegionSagaActionType = Generator<
    CallEffect<LocationRfRegionResponse> | PutEffect<ValidateLocationRfRegionSuccess> | RequestActions,
    void,
    LocationRfRegionResponse
>;
export function* validateRfRegionSaga({
    locationId,
    countryCode,
}: ValidateLocationRfRegion): ValidateRfRegionSagaActionType {
    try {
        const response: LocationRfRegionResponse = yield call(validateLocationRfRegion, countryCode, locationId);
        yield put(validateLocationRfRegionSuccess(response));
        yield put(requestSuccess(CommonRequestType.ValidateLocationRfRegion, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.ValidateLocationRfRegion, RequestActionType.Error));
    }
}

type HandleLocationErrorReturnType = Generator<PutEffect | SelectEffect, void, LocationType[]>;
function* handleLocationError(error: ErrorType): HandleLocationErrorReturnType {
    const currentLocations = yield select(locationsInStore);
    const shouldFailGracefully = offlineError(error) && currentLocations.length > 0;
    if (!shouldFailGracefully) {
        yield put(fetchLocationsError(error));
    }
}

type GetLocationsForB2CReturnType = Generator<
    | CallEffect<{ locations: LocationResponseType[] }>
    | PutEffect
    | SelectEffect
    | CallEffect<AllDevicesResponse>
    | CallEffect<void>,
    void,
    { locations: LocationResponseType[] } & LocationType[] & AllDevicesResponse
>;
export function* getLocationsForB2C(): GetLocationsForB2CReturnType {
    try {
        const locations = yield call(fetchLocations);
        const allDevices = yield call(fetchAllDevices);
        const { devicesWithKeyInfo, hubsWithKeyInfo } = getDevicesFromLocationResponse(locations.locations);
        const locationsWithDeviceRef = transformResponseToLocationType(locations.locations);

        yield put(
            fetchLocationsSuccess({
                locations: locationsWithDeviceRef,
                devices: allDevices.devices,
                devicesWithKeyInfo,
                hubsWithKeyInfo,
                hubs: allDevices.hubs,
            })
        );
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield call(handleLocationError, asErrorType);
    }
}

type GetLocationsForB2BReturnType = Generator<
    CallEffect<LocationsResponse> | PutEffect | SelectEffect | CallEffect<void>,
    void,
    LocationsResponse & LocationType[]
>;
export function* getLocationsForB2B(limit?: number): GetLocationsForB2BReturnType {
    try {
        const limitPerCall = limit || 25000;
        let fetchedDevicesCount = limitPerCall;

        const { locations: initialLocations, totalDevices } = yield call(fetchLocations, limitPerCall, 0);
        let fetchedLocations = [...initialLocations];

        while (fetchedDevicesCount < totalDevices) {
            const { locations: updatedLocations } = yield call(fetchLocations, limitPerCall, fetchedDevicesCount);

            fetchedLocations = fetchedLocations.map(location => {
                const paginatedLocation = updatedLocations.find(loc => loc.id === location.id);

                if (paginatedLocation === undefined) return location;
                return {
                    ...location,
                    devicesWithKeyInfo: { ...location.devicesWithKeyInfo, ...paginatedLocation.devicesWithKeyInfo },
                    hubsWithKeyInfo: { ...location.hubsWithKeyInfo, ...paginatedLocation.hubsWithKeyInfo },
                };
            });

            fetchedDevicesCount += limitPerCall;
        }

        const { devicesWithKeyInfo, hubsWithKeyInfo } = getDevicesFromLocationResponse(fetchedLocations);
        const locations = transformResponseToLocationType(fetchedLocations);

        yield put(fetchLocationsSuccess({ locations, devices: {}, devicesWithKeyInfo, hubsWithKeyInfo }));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield call(handleLocationError, asErrorType);
    }
}

export function* getLocationsSaga(): Generator {
    const isB2B = userIsHbs();
    if (isB2B) yield call(getLocationsForB2B);
    else yield call(getLocationsForB2C);
}

export function* pollLocationsSaga(): Generator {
    if (userIsHbs()) return;

    while (true) {
        yield call(delay, pollingDelayMs); // call get dashboard data every 5 minutes
        const loggedIn = yield select(isLoggedIn);
        if (loggedIn) {
            yield call(getLocationsForB2C);
        }
    }
}

function* startPoll(): Generator {
    yield race({ task: call(pollLocationsSaga), cancel: take(LOGOUT_SUCCESS) });
}

export default function* LocationsSaga(): Generator {
    yield all([
        takeEvery(FETCH_LOCATIONS, getLocationsSaga),
        takeEvery(POLL_DATA, startPoll),
        takeEvery(LocationActions.DeleteLocationInit, deleteLocationSaga),
        takeEvery(LocationActions.AddLocationInit, addLocationSaga),
        takeEvery(LocationActions.ValidateLocationRfRegion, validateRfRegionSaga),
    ]);
}
