import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { DeviceTypeNames, GroupType } from 'commons/src/models/commonEnums';
import { Subscription } from '../../../models/subscriptionModels';
import SubscriptionTableExpandedView from './SubscriptionTableExpandedView';
import styles from './SubscriptionTableSlim.module.scss';

type Props = {
    subscriptions: Subscription[];
    childUserGroupId?: string;
    groupType: GroupType;
};

const SubscriptionSlimTableElement = ({
    subscription,
    firstElement,
    numberOfSubscriptions,
    childUserGroupId,
    groupType,
}: {
    subscription: Subscription;
    firstElement: boolean;
    numberOfSubscriptions: number;
    childUserGroupId?: string;
    groupType: GroupType;
}): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [rowExpanded, setRowExpanded] = useState(numberOfSubscriptions === 1);
    const totalNumberOfSeats = subscription.deviceSeats.reduce(
        (seats: number, deviceType: { deviceType: DeviceTypeNames; seats: number }) => {
            return seats + deviceType.seats;
        },
        0
    );
    return (
        <div>
            <div
                className={firstElement ? styles.firstTableElement : styles.tableElement}
                role="button"
                tabIndex={0}
                onClick={(): void => setRowExpanded(!rowExpanded)}
                onKeyUp={(e): void => {
                    if (e.key === 'Enter') setRowExpanded(!rowExpanded);
                }}
            >
                <div className={styles.expandIconWrapper}>
                    <MaterialIcon name={rowExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'} />
                </div>
                <h3 className={styles.header}>{subscription.subscriptionNumber}</h3>
                <div className={styles.elementContent}>
                    <div className={styles.items}>
                        <span>{txt('Subscription.PurchaseOrder')}:</span>
                        <span>{txt('Subscription.RenewalDate')}:</span>
                        <span>{txt('Subscription.Seats')}:</span>
                        <span>{txt('Subscription.EstimatedCost')}:</span>
                    </div>
                    <div className={styles.data}>
                        <span>{subscription.poNumber || '-'}</span>
                        <span>{subscription.renewalDate || '-'}</span>
                        <span>{totalNumberOfSeats || '-'}</span>
                        <span>{subscription.estimatedTotalCost}</span>
                    </div>
                </div>
            </div>
            {rowExpanded && (
                <SubscriptionTableExpandedView
                    subscription={subscription}
                    childUserGroupId={childUserGroupId}
                    groupType={groupType}
                />
            )}
        </div>
    );
};

const SubscriptionTableSlim = ({ subscriptions, childUserGroupId, groupType }: Props): React.ReactElement => {
    return (
        <div>
            {subscriptions.map((subscription, index) => (
                <SubscriptionSlimTableElement
                    key={subscription.subscriptionNumber}
                    subscription={subscription}
                    firstElement={index === 0}
                    numberOfSubscriptions={subscriptions.length}
                    childUserGroupId={childUserGroupId}
                    groupType={groupType}
                />
            ))}
        </div>
    );
};

export default SubscriptionTableSlim;
