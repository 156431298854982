import { B2BhubSpotEndPoint, B2ChubSpotEndPoint, ContentTypes } from '../constants';
import { NewsletterSignUpObject } from '../models/commonTypeScript';
import { fetchOutsideAirthings } from './fetch';
import getFetchHeaders from './getFetchHeaders';

export default async (data: NewsletterSignUpObject, isB2B: boolean): Promise<void> =>
    fetchOutsideAirthings<void>(
        isB2B ? B2BhubSpotEndPoint : B2ChubSpotEndPoint,
        {
            method: 'POST',
            headers: await getFetchHeaders(),
            body: JSON.stringify(data),
        },
        ContentTypes.JSON
    );
