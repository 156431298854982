import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { BoldText, ColoredDot, colorStyles } from 'commons/src/components/PDF/PdfConstants';
import { SensorThresholds } from 'commons/src/models/commonTypeScript';
import { PDFContext } from '../../../../models/common';
import Thresholds from '../IaqInsightPdf/Thresholds';

const componentStyles = StyleSheet.create({
    thresholdWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '1vw',
    },
});

type Props = {
    txt: PDFContext;
    devicesInEachLevel: { GOOD: number; FAIR: number; POOR: number };
    threshold: SensorThresholds;
};

const Summary = ({ txt, devicesInEachLevel, threshold }: Props): React.ReactElement => {
    const allDevicesGOOD = devicesInEachLevel.POOR + devicesInEachLevel.FAIR === 0;
    const numberOfDevicesWithHighestAlert =
        devicesInEachLevel.POOR > 0 ? devicesInEachLevel.POOR : devicesInEachLevel.FAIR;
    const warningTextKey =
        numberOfDevicesWithHighestAlert === 1
            ? 'RadonInsight.SingleDeviceAtWarningLevel'
            : 'RadonInsight.DevicesAtWarningLevel';
    const alertTextKey =
        numberOfDevicesWithHighestAlert === 1
            ? 'RadonInsight.SingleDeviceAtHighRisk'
            : 'RadonInsight.DevicesAtHighRisk';
    const numberOfDevicesWithAlertTextKey = devicesInEachLevel.POOR > 0 ? alertTextKey : warningTextKey;
    const text = allDevicesGOOD
        ? txt('RadonInsight.AllDevicesWithinRecommendedLevel')
        : txt(numberOfDevicesWithAlertTextKey, { numberOfDevices: numberOfDevicesWithHighestAlert.toString() });
    let color = 'grey';
    if (devicesInEachLevel.POOR === 0 && devicesInEachLevel.FAIR === 0) {
        color = 'green';
    } else if (devicesInEachLevel.POOR === 0 && devicesInEachLevel.FAIR > 0) {
        color = 'yellow';
    } else if (devicesInEachLevel.POOR > 0) {
        color = 'red';
    }
    return (
        <View>
            <View style={componentStyles.thresholdWrapper}>
                <ColoredDot style={colorStyles[color as keyof typeof colorStyles]} />
                <View>
                    <BoldText style={colorStyles.smallText}>{text}</BoldText>
                </View>
            </View>
            <Thresholds
                ranges={threshold.ranges}
                unit={threshold.unit}
                sensorType={threshold.type}
                txt={txt}
                devicesInEachLevel={devicesInEachLevel}
            />
        </View>
    );
};

export default Summary;
