import React from 'react';
import { useTranslation } from 'react-i18next';
import { sensorUnits } from '../../constants';
import { setSensorName } from '../outdoorAirQualityPage/PollutantSensor';
import styles from './OutdoorSensorValue.module.scss';

type Props = {
    unit?: string;
    concentration: number;
    sensor: string;
};

const OutdoorSensorValue = ({ sensor, unit, concentration }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const sensorHasValue = concentration !== undefined && !Number.isNaN(concentration);
    let value: string | number = concentration;
    if (sensorHasValue) {
        if (concentration > 9999) {
            value = `${(concentration / 1000).toFixed(0)}K`;
        } else {
            value = concentration.toFixed(0);
        }
    }

    return (
        <div className={styles.sensor}>
            {setSensorName(sensor)}
            <div className={styles.sensorValueBlock}>
                {sensorHasValue ? (
                    <div>
                        {value}
                        <span className={styles.unit}>{sensorUnits[unit]}</span>
                    </div>
                ) : (
                    txt('NotAvailableAbbreviation')
                )}
            </div>
        </div>
    );
};

export default OutdoorSensorValue;
