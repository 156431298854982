import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { DomainState, VerificationState } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { SingleSignOnClient } from '../../../../models/common';
import styles from './SSOTags.module.scss';

export const ActiveStatus = ({ active }: { active: boolean }): React.ReactElement => {
    const { t: txt } = useTranslation();
    if (active) {
        return <span className={styles.activeTag}>{txt(`${DomainState.Active}`)}</span>;
    }
    return <span className={styles.inactiveTag}>{txt(`${DomainState.Inactive}`)}</span>;
};

export const setVerificationStatus = (
    singleSignOnClient: SingleSignOnClient,
    verifyLoading: boolean,
    verifyError?: ErrorType
): keyof typeof VerificationState => {
    if (singleSignOnClient.domainTxtRecordVerifiedAt) return VerificationState.Verified;
    if (verifyLoading) return VerificationState.Waiting;
    if (verifyError) return VerificationState.Unsuccessful;
    return VerificationState.NotVerified;
};

export const VerificationStatus = ({ status }: { status: keyof typeof VerificationState }): React.ReactElement => {
    const { t: txt } = useTranslation();
    switch (status) {
        case VerificationState.Waiting:
            return (
                <span className={styles.waitingTag}>
                    <MaterialIcon name="watch_later" extraClass={styles.waitingIcon} />
                    {txt(`VerificationStatus.${status}`)}
                </span>
            );
        case VerificationState.Verified:
            return (
                <span className={styles.verificationTag}>
                    <MaterialIcon name="check_circle" fill extraClass={styles.successfulIcon} />
                    {txt(`VerificationStatus.${status}`)}
                </span>
            );
        case VerificationState.Unsuccessful:
            return (
                <span className={styles.verificationTag}>
                    <MaterialIcon name="dangerous" fill extraClass={styles.unsuccessfulIcon} />
                    {txt(`VerificationStatus.${status}`)}
                </span>
            );
        default:
            return (
                <span className={styles.verificationTag}>
                    <MaterialIcon name="info" extraClass={styles.icon} />
                    {txt(`VerificationStatus.${status}`)}
                </span>
            );
    }
};

export const DomainStatus = ({
    active,
    verificationStatus,
}: {
    active: boolean;
    verificationStatus: keyof typeof VerificationState;
}): React.ReactElement => {
    return (
        <div className={styles.domainStatus}>
            <ActiveStatus active={active} />
            <VerificationStatus status={verificationStatus} />
        </div>
    );
};
