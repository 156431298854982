import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MaterialIcon from 'commons/src/components/MaterialIcon';

type Props = {
    selectedHighThreshold: string;
    selectedLowThreshold?: string;
    loading: boolean;
};
const BuildingInsightEmptyState = ({
    loading,
    selectedHighThreshold,
    selectedLowThreshold,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [highThreshold, setHighThreshold] = useState(selectedHighThreshold);
    const [lowThreshold, setLowThreshold] = useState(selectedLowThreshold);

    useEffect(() => {
        if (!loading) {
            setHighThreshold(selectedHighThreshold);
            setLowThreshold(selectedLowThreshold);
        }
    }, [loading]);
    const noValuesText = lowThreshold
        ? txt('BuildingInsight.NoValuesAboveOrBelow', {
              highThreshold,
              lowThreshold,
          })
        : txt('BuildingInsight.NoValuesAbove', { highThreshold });
    return (
        <div className="building-insight__card__body building-insight__card__body--empty-state">
            <div className="flex-center flex-column">
                <MaterialIcon name="wb_sunny" extraClass="building-insight__card__body--empty-state__icon" />
                <div className="text-large">{noValuesText}</div>
                <div className="text-small">{txt('BuildingInsight.BreathOfFreshAir')}</div>
            </div>
        </div>
    );
};

export default BuildingInsightEmptyState;
