import React, { useEffect } from 'react';
import L from 'leaflet';
import { ImageOverlay, useMap } from 'react-leaflet';

type Props = {
    bounds: L.LatLngBounds;
    floorPlanImage: string;
    mapReady: boolean;
    onMapReady: (map: L.Map) => void;
};
const FloorMapImage = ({ bounds, floorPlanImage, onMapReady, mapReady }: Props): React.ReactElement => {
    const map = useMap();

    useEffect(() => {
        if (mapReady) {
            onMapReady(map);
        }
    }, [floorPlanImage]);

    useEffect(() => {
        map.fitBounds(bounds);
    }, [bounds]);

    return <ImageOverlay attribution="" bounds={bounds} url={floorPlanImage} />;
};

export default FloorMapImage;
