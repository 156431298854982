import { all, call, put, takeEvery, CallEffect, PutEffect } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import RequestActions from 'commons/src/models/RequestTypes';
import displayAlertBoxSaga from 'commons/src/sagas/displayAlertBox';
import { toErrorType, toErrorTypeWithMessage } from 'commons/src/sagas/isErrorType';
import {
    CreateThresholdBreachInsight,
    CreateThresholdBreachInsightSuccess,
    createThresholdBreachInsightSuccess,
    DeleteThresholdBreachInsight,
    DeleteThresholdBreachSuccess,
    deleteThresholdBreachSuccess,
    FetchThresholdBreachAllInsights,
    FetchThresholdBreachAllInsightsSuccess,
    fetchThresholdBreachAllInsightsSuccess,
    FetchThresholdBreachSingleInsight,
    FetchThresholdBreachSingleInsightSuccess,
    fetchThresholdBreachSingleInsightSuccess,
    ThresholdBreachInsight,
    UpdateThresholdBreachConfig,
    UpdateThresholdBreachConfigSuccess,
    updateThresholdBreachConfigSuccess,
} from '../actions/thresholdBreachInsightActions';
import {
    getBuildingSensorThresholdBreaches,
    deleteBuildingSensorThresholdBreach,
    updateBuildingSensorThresholdBreach,
    createBuildingSensorThresholdBreach,
    getSingleBuildingSensorThresholdBreaches,
} from '../api/buildingApi';
import { BuildingInsight } from '../models/buildingModels';
import { SensorBreachThresholds } from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type GetAllThresholdBreachInsightsForBuildingSagaReturnType = Generator<
    | CallEffect<{
          charts: BuildingInsight[];
          thresholds: SensorBreachThresholds;
      }>
    | PutEffect<FetchThresholdBreachAllInsightsSuccess>
    | RequestActions,
    void,
    {
        charts: BuildingInsight[];
        thresholds: SensorBreachThresholds;
    }
>;
function* getAllThresholdBreachInsightsForBuildingSaga({
    payload,
}: FetchThresholdBreachAllInsights): GetAllThresholdBreachInsightsForBuildingSagaReturnType {
    const { fromDate, toDate, withOpeningHours, locationId, resolution } = payload;
    try {
        const response = yield call(
            getBuildingSensorThresholdBreaches,
            locationId,
            fromDate,
            toDate,
            withOpeningHours,
            resolution
        );
        yield put(fetchThresholdBreachAllInsightsSuccess(response, locationId));
        yield put(requestSuccess(RequestType.FetchThresholdBreachAllInsights, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchThresholdBreachAllInsights, RequestActionType.Error));
    }
}

type GetSingleThresholdBreachInsightSagaReturnType = Generator<
    CallEffect | CallEffect<BuildingInsight> | PutEffect<FetchThresholdBreachSingleInsightSuccess> | RequestActions,
    void,
    BuildingInsight
>;

function* getSingleThresholdBreachInsightSaga({
    payload,
    thresholds,
    chartId,
}: FetchThresholdBreachSingleInsight): GetSingleThresholdBreachInsightSagaReturnType {
    const { fromDate, toDate, withOpeningHours, locationId, resolution } = payload;
    try {
        const response = yield call(
            getSingleBuildingSensorThresholdBreaches,
            locationId,
            fromDate,
            toDate,
            withOpeningHours,
            resolution,
            thresholds,
            chartId
        );
        yield put(fetchThresholdBreachSingleInsightSuccess(response, locationId, chartId));
        yield put(requestSuccess(RequestType.FetchThresholdBreachSingleInsight, RequestActionType.Success));
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error, 'SomethingWentWrongPleaseRefresh');
        yield put(
            requestError(errorWithMessage.error, RequestType.FetchThresholdBreachSingleInsight, RequestActionType.Error)
        );
        yield call(displayAlertBoxSaga, errorWithMessage.message, false, true);
    }
}

type CreateThresholdBreachInsightSagaReturnType = Generator<
    CallEffect | CallEffect<BuildingInsight> | PutEffect<CreateThresholdBreachInsightSuccess> | RequestActions,
    void,
    BuildingInsight
>;

function* createThresholdBreachInsightSaga({
    sensor,
    payload,
}: CreateThresholdBreachInsight): CreateThresholdBreachInsightSagaReturnType {
    const { fromDate, toDate, withOpeningHours, locationId, resolution } = payload;
    try {
        const response = yield call(
            createBuildingSensorThresholdBreach,
            locationId,
            fromDate,
            toDate,
            withOpeningHours,
            resolution,
            sensor
        );
        yield put(createThresholdBreachInsightSuccess(response, locationId));
        yield put(requestSuccess(RequestType.CreateThresholdBreachInsight, RequestActionType.Success));
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error, 'SomethingWentWrongPleaseRefresh');
        yield put(
            requestError(errorWithMessage.error, RequestType.CreateThresholdBreachInsight, RequestActionType.Error)
        );
        yield call(displayAlertBoxSaga, errorWithMessage.message, false, true);
    }
}

type DeleteThresholdBreachInsightSagaReturnType = Generator<
    CallEffect | PutEffect<DeleteThresholdBreachSuccess> | RequestActions,
    void,
    void
>;
function* deleteThresholdBreachInsightSaga({
    chartId,
    buildingId,
}: DeleteThresholdBreachInsight): DeleteThresholdBreachInsightSagaReturnType {
    try {
        yield call(deleteBuildingSensorThresholdBreach, chartId, buildingId);
        yield put(deleteThresholdBreachSuccess(chartId, buildingId));
        yield put(requestSuccess(RequestType.DeleteThresholdBreachInsight, RequestActionType.Success));
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error, 'SomethingWentWrongPleaseRefresh');
        yield put(
            requestError(errorWithMessage.error, RequestType.DeleteThresholdBreachInsight, RequestActionType.Error)
        );
        yield call(displayAlertBoxSaga, errorWithMessage.message, false, true);
    }
}

type UpdateThresholdBreachConfigSagaReturnType = Generator<
    CallEffect | PutEffect<UpdateThresholdBreachConfigSuccess> | RequestActions,
    void,
    void
>;
function* updateThresholdBreachConfigSaga({
    thresholds,
    sensor,
    chartId,
    buildingId,
}: UpdateThresholdBreachConfig): UpdateThresholdBreachConfigSagaReturnType {
    try {
        yield call(updateBuildingSensorThresholdBreach, buildingId, chartId, thresholds, sensor);
        yield put(updateThresholdBreachConfigSuccess(thresholds, chartId, buildingId));
        yield put(requestSuccess(RequestType.UpdateThresholdBreachConfig, RequestActionType.Success));
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error, 'SomethingWentWrongPleaseRefresh');
        yield put(
            requestError(errorWithMessage.error, RequestType.UpdateThresholdBreachConfig, RequestActionType.Error)
        );
        yield call(displayAlertBoxSaga, errorWithMessage.message, false, true);
    }
}

export default function* thresholdBreachInsightSaga(): Generator {
    yield all([
        takeEvery(
            ThresholdBreachInsight.FetchThresholdBreachAllInsightsInit,
            getAllThresholdBreachInsightsForBuildingSaga
        ),
        takeEvery(ThresholdBreachInsight.DeleteThresholdBreachInsightInit, deleteThresholdBreachInsightSaga),
        takeEvery(ThresholdBreachInsight.UpdateThresholdBreachConfigInit, updateThresholdBreachConfigSaga),
        takeEvery(ThresholdBreachInsight.CreateThresholdBreachInsightInit, createThresholdBreachInsightSaga),
        takeEvery(ThresholdBreachInsight.FetchThresholdBreachSingleInsightInit, getSingleThresholdBreachInsightSaga),
    ]);
}
