import React from 'react';

const WaveIcon = (): React.ReactElement => (
    <svg
        version="1.1"
        className="device-svg-icons"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
    >
        <path
            d="M24,2C11.9,2,2,11.9,2,24s9.9,22,22,22s22-9.9,22-22S36.1,2,24,2z M24,4c11,0,20,9,20,20
            s-9,20-20,20S4,35,4,24S13,4,24,4z M36.7,23.1c-0.6,0-0.6,0-1.2,0.2c-0.6,0.2-0.6,0.2-1,0.6c-0.4,0.4-0.4,0.4-0.6,1
            s-0.2,0.6-0.2,1.3c0,0.7,0,0.7,0.2,1.3c0.2,0.6,0.2,0.6,0.6,0.9c0.4,0.4,0.4,0.4,1,0.6c0.6,0.2,0.6,0.2,1.2,0.2c1.5,0,1.5,0,2.3-1
            l0.1-0.2l-1-0.8c-0.2,0.3-0.2,0.3-0.6,0.5C37.2,28,37.2,28,36.7,28c-0.3,0-0.3,0-0.6-0.1c-0.3-0.1-0.3-0.1-0.5-0.3
            c-0.2-0.2-0.2-0.2-0.4-0.4c-0.1-0.2-0.1-0.2-0.1-0.4l0-0.2h4.4l0-0.1l0,0l0-0.1v-0.2c0-0.8,0-0.8-0.2-1.4s-0.2-0.6-0.6-1
            s-0.4-0.4-0.9-0.6S37.3,23.1,36.7,23.1z M22.9,23.1c-0.7,0-0.7,0-1.3,0.2c-0.5,0.2-0.5,0.2-1,0.5L20.4,24l0.7,0.9
            c0.3-0.3,0.3-0.3,0.7-0.5s0.4-0.2,0.9-0.2c0.5,0,0.5,0,0.9,0.3c0.3,0.2,0.3,0.2,0.4,0.7l0,0.1v0.1c-0.7,0-0.7,0-1.4,0.1
            c-0.7,0.1-0.7,0.1-1.3,0.3s-0.6,0.2-0.9,0.6c-0.4,0.4-0.4,0.4-0.4,1c0,0.5,0,0.5,0.2,0.8c0.2,0.3,0.2,0.3,0.5,0.6
            c0.3,0.2,0.3,0.2,0.7,0.3c0.4,0.1,0.4,0.1,0.7,0.1c0.6,0,0.6,0,1.1-0.2c0.4-0.2,0.4-0.2,0.6-0.5l0.1-0.1h0V29h1.3v-3.5
            c0-0.4,0-0.4-0.1-0.9s-0.1-0.4-0.4-0.8c-0.3-0.3-0.3-0.3-0.8-0.6C23.6,23.1,23.6,23.1,22.9,23.1z M9.4,20.5H7.8l2.5,8.5h1.5l2-6.4
            h0l2,6.4h1.5l2.5-8.5h-1.6l-1.7,6.2h0l-1.8-6.2H13l-1.9,6.2h0L9.4,20.5z M28.2,23.2h-1.6l2.3,5.8h1.5l2.3-5.8h-1.5l-1.5,4.2h0
            L28.2,23.2z M24.1,26.4v0.2c0,0.7,0,0.7-0.4,1.1c-0.4,0.4-0.4,0.4-1.1,0.4c-0.2,0-0.2,0-0.4,0c-0.2,0-0.2,0-0.3-0.1
            c-0.1-0.1-0.1-0.1-0.2-0.2s-0.1-0.1-0.1-0.3c0-0.3,0-0.3,0.2-0.5c0.2-0.2,0.2-0.2,0.5-0.3c0.3-0.1,0.3-0.1,0.7-0.1
            c0.3,0,0.3,0,0.5,0l0.2,0H24.1z M36.6,24.2c0.3,0,0.3,0,0.6,0.1c0.3,0.1,0.3,0.1,0.4,0.3c0.2,0.2,0.2,0.2,0.2,0.4
            c0.1,0.3,0.1,0.3,0.1,0.5h-3c0-0.6,0-0.6,0.5-1S36,24.2,36.6,24.2z"
        />
    </svg>
);

export default WaveIcon;
