import React, { SyntheticEvent, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { analyticsLogger, PageType } from '../../analytics';
import { DASHBOARD_CLICKED_TILE, DEVICE_CHANGED_TIMEFRAME } from '../../analytics/AnalyticsEvents';
import { getDotColor } from '../../commonFunctions';
import SensorFilterButtons from '../../components/buttons/SensorFilterButtons';
import { userIsHbs } from '../../components/findUserType';
import { SensorComponent } from '../../components/sensors/SensorComponent';
import { sensorGraphHeights, sensorGraphPeriods } from '../../constants';
import { DashboardVisibility, SensorTypes } from '../../models/commonEnums';
import { SelectedPeriod } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import { chartOnMouseOver } from '../devicePage/SensorConfig';
import SensorGraph from '../devicePage/SensorGraph';
import TileHeaderMenu from './TileHeaderMenu';

export type Props = {
    visibility: DashboardVisibility;
    locationName: string;
    segmentName: string;
    sensorType: SensorTypes;
    chartData: { [interval: string]: number[][] };
    minValues: { [interval: string]: number };
    averageValues: { [interval: string]: number | undefined };
    width: number;
    serialNumber: string;
    unit: string;
    thresholds: number[];
    dateFormat: string;
    fetchSegment: (payload: {
        serialNumber: string;
        selectedInterval: SelectedPeriod;
        sensorType: string;
        tileId: string;
    }) => void;
    removeTile: (tileId: string, tileRef: string) => void;
    tileId: string;
    tileRef: string;
    updateTileWidth: (width: number, id: string, ref: string) => void;
    isDragging: boolean;
    singleWidthDashboard: boolean;
    pollSensorData: (payload: {
        serialNumber: string;
        selectedInterval: SelectedPeriod;
        sensorType: string;
        ref: string;
        tileId: string;
    }) => void;
};

export const DashboardSensorTileComponent = ({
    locationName,
    segmentName,
    sensorType,
    unit,
    removeTile,
    tileId,
    tileRef,
    width,
    updateTileWidth,
    singleWidthDashboard,
    serialNumber,
    thresholds,
    chartData,
    dateFormat,
    minValues,
    averageValues,
    isDragging,
    visibility,
    pollSensorData,
    fetchSegment,
}: Props): React.ReactElement => {
    const [selectedInterval, setSelectedInterval] = useState<SelectedPeriod>(sensorGraphPeriods.week);

    const selectGraphPeriod = (newInterval: SelectedPeriod): void => {
        analyticsLogger(DEVICE_CHANGED_TIMEFRAME, { pageType: PageType.Dashboard, timeframe: newInterval.period });

        const hasPeriod = !!chartData[newInterval.name];
        if (!hasPeriod) {
            fetchSegment({
                serialNumber,
                selectedInterval: newInterval,
                sensorType,
                tileId,
            });
        } else {
            pollSensorData({
                serialNumber,
                selectedInterval: newInterval,
                sensorType,
                ref: `${serialNumber}-${sensorType}`,
                tileId,
            });
        }
        if (selectedInterval.name !== newInterval.name) {
            setSelectedInterval(newInterval);
        }
    };

    const averageValue = averageValues[selectedInterval.name];
    const displayStatusBar = true;
    const averageDotColor = getDotColor(thresholds, sensorType, averageValue);

    const preventLink = (e: SyntheticEvent<HTMLElement>): void => {
        if (isDragging) {
            e.preventDefault();
        } else {
            analyticsLogger(DASHBOARD_CLICKED_TILE, { pageType: PageType.Dashboard, tileType: 'Sensor' });
        }
    };

    const sensorButtonWidth = width === 3 && window.innerWidth < 1286 ? 2 : width;

    const deviceInfo = useSelector((state: Store) => state.devices.devicesWithKeyInfo[serialNumber]);
    const userIsAfB = userIsHbs();
    const link = userIsAfB
        ? `/buildings/${deviceInfo?.locationId}/spaces/${deviceInfo?.spaceId}`
        : `/devices/${serialNumber}`;

    return (
        <NavLink
            to={link}
            onClick={preventLink}
            data-tile
            className={classNames(
                'tile-wrapper__link page-wrapper__column',
                { 'page-wrapper__column--three-cols': width === 3 },
                { 'page-wrapper__column--two-cols': width === 2 }
            )}
        >
            <div>
                <div className="tile-wrapper">
                    <div className="tile-header">
                        <div className="tile-header--info">
                            <div className="tile-header__title">
                                <h2 className="tile-header__title--name">{segmentName}</h2>
                                <span className="tile-header__title--address">{locationName}</span>
                            </div>
                            <SensorComponent
                                dotColor={averageDotColor}
                                value={averageValue}
                                sensorType={sensorType}
                                isRadonAverage={userIsAfB && sensorType === SensorTypes.radonShortTermAvg}
                                unit={unit}
                                displayStatusBar={displayStatusBar}
                            />
                        </div>
                        <div className="tile-header__controls">
                            {width !== 1 && !singleWidthDashboard && visibility === DashboardVisibility.user && (
                                <SensorFilterButtons
                                    selectGraphPeriod={selectGraphPeriod}
                                    tileWidth={sensorButtonWidth}
                                    selectedPeriod={selectedInterval}
                                    disabled={false}
                                />
                            )}
                            <TileHeaderMenu
                                onRemoveTile={(): void => removeTile(tileId, tileRef)}
                                updateTileWidth={(newWidth): void => updateTileWidth(newWidth, tileId, tileRef)}
                                selectGraphPeriod={selectGraphPeriod}
                                selectedInterval={selectedInterval}
                                tileWidth={width}
                                visibility={visibility}
                                singleWidthDashboard={singleWidthDashboard}
                                tileId={tileId}
                                isSensorTile
                            />
                        </div>
                    </div>
                    <div
                        className="tile-body tile-body--sensor"
                        onMouseLeave={(e): void => chartOnMouseOver(e.nativeEvent, true)}
                    >
                        <SensorGraph
                            chartData={chartData}
                            extraSeries={{}}
                            thresholds={thresholds}
                            chartHeight={sensorGraphHeights.dashboardView}
                            unit={unit}
                            selectedInterval={selectedInterval}
                            sensorType={sensorType}
                            dateFormat={dateFormat}
                            minValues={minValues}
                            averageValues={averageValues}
                            width={width}
                        />
                    </div>
                </div>
            </div>
        </NavLink>
    );
};

export default DashboardSensorTileComponent;
