import config from '../config';
import { TokenResponse } from '../models/commonTypeScript';
import { fetchFromAccounts } from './fetch';

export default (code: string): Promise<TokenResponse> =>
    fetchFromAccounts<TokenResponse>('/token', {
        method: 'POST',
        body: JSON.stringify({
            grant_type: 'authorization_code',
            client_id: config.dashboardClientId,
            client_secret: config.dashboardClientSecret,
            code,
            redirect_uri: config.dashboardRedirectUri,
        }),
    });

export const refreshAccessToken = (token: string): Promise<TokenResponse> =>
    fetchFromAccounts<TokenResponse>('/token', {
        method: 'POST',
        body: JSON.stringify({
            grant_type: 'refresh_token',
            client_id: config.dashboardClientId,
            client_secret: config.dashboardClientSecret,
            refresh_token: token,
        }),
    });

export const revokeRefreshTokensAccountsApi = (token: string): Promise<void> =>
    fetchFromAccounts<void>('/revoke?all_sessions=true&all_clients=true', {
        method: 'POST',
        body: JSON.stringify({
            client_id: config.dashboardClientId,
            client_secret: config.dashboardClientSecret,
            token,
        }),
    });

export const revokeDashboardRefreshTokenAccountsApi = (token: string): Promise<void> =>
    fetchFromAccounts<void>('/revoke?all_sessions=false&all_clients=false', {
        method: 'POST',
        body: JSON.stringify({
            client_id: config.dashboardClientId,
            client_secret: config.dashboardClientSecret,
            token,
        }),
    });
