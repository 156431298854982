import { PartnerCustomerDetails } from 'business-dashboard/src/models/partner';
import { Consumer } from '../../constants';
import { GroupType } from '../../models/commonEnums';
import { Group, SelectedGroup } from '../../models/commonTypeScript';

export const groupName = (group?: Group): string => (group ? group.organizationName || group.groupName || '' : '');

export const groupDisplayName = (group: Group, singleConsumer: boolean, userName: string | undefined): string =>
    group.groupType === GroupType.consumer && singleConsumer && userName ? userName : groupName(group);

export const userHasAccessToGroup = (groups: Group[], userGroupId: string): boolean =>
    !!groups.find(group => group.id && group.id === userGroupId);

export const getOrganizationId = (customer?: { userGroupId?: string; organizationId?: string }): string =>
    (customer && customer.organizationId) || Consumer;

export const getSelectedGroupForCustomer = (customer?: {
    userGroupId?: string;
    organizationId?: string;
    groupType?: keyof typeof GroupType;
}): SelectedGroup => ({
    organizationId: customer?.organizationId || Consumer,
    userGroupId: customer?.userGroupId || Consumer,
    groupType: customer?.groupType || GroupType.consumer,
});

export const getSelectedGroup = (group?: {
    id: string;
    organizationId?: string;
    groupType: keyof typeof GroupType;
}): SelectedGroup => ({
    organizationId: group?.organizationId || Consumer,
    userGroupId: group?.id || Consumer,
    groupType: group?.groupType || GroupType.consumer,
});

export const isB2B = (customer?: Group | PartnerCustomerDetails): boolean =>
    customer?.groupType === GroupType.business || customer?.groupType === GroupType.partner;
