import React from 'react';
import QRCodeSVG from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import HorizontalBusinessLogo from '../../img/logos/airthings_business_logo_horizontal';
import { Group } from '../../models/commonTypeScript';
import { Store } from '../../reducers';

interface ParentProps {
    shortUrl: string;
    url: string;
}

interface StateProps {
    selectedGroup?: Group;
    logoImage?: string;
}

type Props = StateProps & ParentProps;

const QRLabel = (props: Props): React.ReactElement => {
    const { shortUrl, url, selectedGroup, logoImage } = props;
    const { t: txt } = useTranslation();

    return (
        <div className="qr-label">
            <div className="qr-label__container qr-label__container--blue">
                <div className="qr-label__header">
                    <div className="qr-label__text--header">{txt('PublicIAQDashboard.StickerHeader')}</div>
                    <div className="qr-label__text--sub-header">{txt('PublicIAQDashboard.StickerDescription')}</div>
                </div>
                {HorizontalBusinessLogo('qr-label__airthings-logo')}
            </div>
            <div className="qr-label__container qr-label__container--white">
                <div className="qr-label__device-info-wrapper">
                    {logoImage ? (
                        <img
                            src={logoImage}
                            className="qr-label__organization-logo"
                            alt={txt('OrganizationProfile.LogoImage')}
                        />
                    ) : (
                        <div className="qr-label__text--organization">
                            {selectedGroup && selectedGroup.organizationName}
                        </div>
                    )}
                    <QRCodeSVG value={url} size={102} id="qrCode" />
                    <div className="qr-label__text--body">{shortUrl}</div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { selectedGroup },
        organizationProperties: { logoImage },
    } = store;
    return {
        selectedGroup,
        logoImage,
    };
};

export default connect(mapStateToProps)(QRLabel);
