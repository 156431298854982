import React from 'react';

const IconHumidity = (): React.ReactElement => (
    <svg
        version="1.1"
        id="iconHumidity"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        width="24px"
        height="24px"
    >
        <path d="M10.4795 1.71287C11.4255 0.76688 12.9593 0.76688 13.9053 1.71287L19.2886 7.09619C23.2077 11.0153 23.2077 17.3695 19.2886 21.2886C15.3695 25.2077 9.01531 25.2077 5.09619 21.2886C1.17708 17.3695 1.17708 11.0153 5.09619 7.09619L10.4795 1.71287ZM12.4371 3.18105C12.3019 3.04591 12.0828 3.04591 11.9477 3.18105L6.56437 8.56437C3.45611 11.6726 3.45611 16.7121 6.56437 19.8204C9.67264 22.9287 14.7121 22.9287 17.8204 19.8204C20.9287 16.7121 20.9287 11.6726 17.8204 8.56437L12.4371 3.18105Z" />
        <path d="M8 13C8.55228 13 9 13.4477 9 14C9 15.6569 10.3431 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19C9.23858 19 7 16.7614 7 14C7 13.4477 7.44772 13 8 13Z" />
    </svg>
);

export default IconHumidity;
