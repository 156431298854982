import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
    text: string;
    subtext?: string | React.ReactElement;
    greenBox?: boolean;
};

const ResponseBox = ({ text, subtext, greenBox }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div
            className={classNames('response-box response-box--error', {
                'response-box--message': greenBox,
            })}
        >
            <div className="response-box--content">{txt(text)}</div>
            {subtext && <div className="response-box__subtext">{subtext}</div>}
        </div>
    );
};

export default ResponseBox;
