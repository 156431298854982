import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { ColoredDot, colorStyles } from 'commons/src/components/PDF/PdfConstants';
import { sensorUnits } from 'commons/src/constants';
import { Rating } from 'commons/src/models/commonEnums';
import { ThresholdRange } from 'commons/src/models/commonTypeScript';
import { PDFContext } from '../../../../models/common';

const componentStyles = StyleSheet.create({
    thresholdWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '3vw',
        marginRight: '2vw',
    },
    thresholdsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginBottom: '2.5vw',
    },
});

type Props = {
    ranges: ThresholdRange[];
    unit: keyof typeof sensorUnits;
    sensorType: string;
    txt: PDFContext;
    devicesInEachLevel?: { GOOD: number; FAIR: number; POOR: number };
};

const ThresholdRanges = (props: Props): React.ReactElement => {
    const { devicesInEachLevel, ranges, unit, sensorType, txt } = props;
    const thresholdValues = (): React.ReactElement[] | null =>
        ranges.length > 0
            ? ranges.map((range, index) => {
                  let headerText = 'ThresholdKeys.NormalLevel';
                  let color = 'green';
                  if (range.rating === Rating.FAIR) {
                      headerText = 'ThresholdKeys.ActionLevel';
                      color = 'yellow';
                  } else if (range.rating === Rating.POOR) {
                      headerText = 'ThresholdKeys.WarningLevel';
                      color = 'red';
                  }
                  const devicesOnLevel = devicesInEachLevel && devicesInEachLevel[range.rating];
                  const elementKey = `${sensorType}-${index}-range`;

                  return (
                      <View style={componentStyles.thresholdWrapper} key={elementKey}>
                          <ColoredDot style={colorStyles[color as keyof typeof colorStyles]} />
                          <View>
                              <Text style={colorStyles.smallText}>{txt(headerText)}</Text>
                              <Text style={colorStyles.smallText}>
                                  {range.from ? '≥' : '<'}
                                  {range.from && [' ', range.from, sensorUnits[unit]]} {range.to && range.from && '<'}
                                  {range.to && [' ', range.to, sensorUnits[unit]]}
                              </Text>
                              {devicesInEachLevel && (
                                  <Text style={colorStyles.smallText}>
                                      {devicesOnLevel}{' '}
                                      {devicesOnLevel === 1 ? txt('DeviceLowerCase') : txt('DevicesLowerCase')}
                                  </Text>
                              )}
                          </View>
                      </View>
                  );
              })
            : null;
    return <View style={componentStyles.thresholdsContainer}>{thresholdValues()}</View>;
};

export default ThresholdRanges;
