import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '../MaterialIcon';
import styles from './OfflineDevice.module.scss';

type Props = {
    text: string;
    extraClass?: string;
    deviceOrHub: 'device' | 'hub';
};

const OfflineDevice = ({ text, extraClass, deviceOrHub }: Props): React.ReactElement => {
    const iconMap = {
        device: 'wifi_off',
        hub: 'cloud_off',
    };

    const icon = iconMap[deviceOrHub];

    const { t: txt } = useTranslation();
    return (
        <div className={styles.aligned}>
            <MaterialIcon name={icon} extraClass={styles.redIcon} />
            <span className={classNames(styles.offline, extraClass)}>{txt(text)}</span>
        </div>
    );
};

export default OfflineDevice;
