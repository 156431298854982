import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
    deleteEndedSegment,
    DeleteEndedSegment,
    fetchDeviceSegmentId,
    FetchDeviceSegmentId,
    fetchDeviceSegmentIdPeriod,
    FetchDeviceSegmentIdPeriod,
} from 'commons/src/actions/DeviceActions';
import { analyticsLogger, PageType } from '../../analytics';
import { ENDED_SEGMENT_DELETE } from '../../analytics/AnalyticsEvents';
import { getSegmentDetails } from '../../api/devices';
import { setCustomPeriod } from '../../commonFunctions';
import Error from '../../components/errorComponents/Error';
import { pageNotFoundUrl } from '../../components/errorComponents/PageNotFound';
import { userIsHbs } from '../../components/findUserType';
import { sensorPlaceholder } from '../../components/placeholder';
import { DeviceType, ErrorType, FullDeviceData, SelectedPeriod } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import DevicePageBody from './DevicePageBody';
import CSVDownloadModal from './dropdownOptions/CSVDownloadModal';
import DeleteModal from './dropdownOptions/DeleteModal';

type StateProps = {
    segments: { [segment: string]: FullDeviceData };
    devicePageLoading: boolean;
    dateFormat: string;
    fetching: boolean;
    error: boolean;
    demoMode: boolean;
};

export type ParentProps = {
    serialNumber: string;
    segmentId: string;
    isDeleteModalOpen: boolean;
    setDeleteModalOpen: (setOpen: boolean) => void;
    setDownloadCsvModalOpen: (setOpen: boolean) => void;
    downloadCsvModalOpen: boolean;
    timeZone?: string;
};

type ActionProps = {
    fetchSegmentWithId: (serialNumber: string, segmentId: string, segmentPeriod: SelectedPeriod) => void;
    fetchSegment: (serialNumber: string, segmentId: string, segmentPeriod: SelectedPeriod) => void;
    onDelete: (serialNumber: string, segmentId: string, locationId: string) => void;
};

type Props = StateProps & ActionProps & ParentProps;

export const EndedSegmentComponent = ({
    segments,
    error,
    fetching,
    dateFormat,
    demoMode,
    devicePageLoading,
    fetchSegment,
    fetchSegmentWithId,
    onDelete,
    serialNumber,
    segmentId,
    isDeleteModalOpen,
    setDeleteModalOpen,
    setDownloadCsvModalOpen,
    downloadCsvModalOpen,
    timeZone,
}: Props): React.ReactElement => {
    const navigate = useNavigate();
    const segment = segments[segmentId];
    const [selectedPeriod, setSelectedPeriod] = useState<SelectedPeriod | undefined>(undefined);
    const [endedMeasurement, setEndedMeasurement] = useState<DeviceType | undefined>();
    const [endedMeasurementError, setEndedMeasurementError] = useState<ErrorType>();

    const locationId = segment && segment.locationId;

    const segmentEndPoints = (): SelectedPeriod | undefined => {
        const segmentEnd = endedMeasurement && endedMeasurement.segmentEnd;
        let segmentStart = endedMeasurement && endedMeasurement.segmentStart;

        if (segmentStart && segmentEnd && moment(segmentEnd).diff(moment(segmentStart), 'years') > 0) {
            segmentStart = moment(segmentEnd).subtract(1, 'years').format();
        }
        const startDateInTimeZone = timeZone ? moment.utc(segmentStart).tz(timeZone) : moment(segmentStart);
        const endDateInTimeZone = timeZone ? moment.utc(segmentEnd).tz(timeZone) : moment(segmentEnd);
        return segmentStart && segmentEnd ? setCustomPeriod(startDateInTimeZone, endDateInTimeZone) : undefined;
    };

    const getPastMeasurementInfo = useCallback(async () => {
        const response = await getSegmentDetails(serialNumber, segmentId).catch(err => {
            setEndedMeasurementError(err);
        });
        if (response) {
            setEndedMeasurement(response);
        }
    }, []);

    useEffect(() => {
        getPastMeasurementInfo().catch(err => {
            setEndedMeasurementError(err);
        });
        if (!segmentId || !serialNumber)
            navigate(pageNotFoundUrl('segmentIdOrSerialNumberNotDefined', { serialNumber, segmentId }));
    }, []);

    useEffect(() => {
        const segmentPeriod = segmentEndPoints();
        if (segmentPeriod) {
            if (!selectedPeriod) {
                setSelectedPeriod(segmentPeriod);
            }
            if (!segment) {
                fetchSegmentWithId(serialNumber, segmentId, segmentPeriod);
            }
        }
    }, [endedMeasurement]);

    const onDeleteSegment = (): void => {
        if (demoMode) return;
        analyticsLogger(ENDED_SEGMENT_DELETE, { pageType: PageType.Device });
        onDelete(serialNumber, segmentId, locationId);
    };

    const updateSelectedPeriod = (period: SelectedPeriod): void => {
        setSelectedPeriod(period);
    };

    const fetchCustomSegment = (deviceId: string, period: SelectedPeriod): void => {
        fetchSegment(serialNumber, deviceId, period);
    };

    if (error || endedMeasurementError) {
        return <Error />;
    }

    const onDeleteModalClose = (): void => setDeleteModalOpen(false);
    return (
        <div className="container">
            <ReactPlaceholder ready={!!selectedPeriod} customPlaceholder={sensorPlaceholder}>
                {isDeleteModalOpen && (
                    <DeleteModal
                        isB2B={userIsHbs()}
                        locationId={locationId}
                        isEndedSegment
                        onDeleteDevice={onDeleteSegment}
                        isModalOpen={isDeleteModalOpen}
                        onModalClose={onDeleteModalClose}
                        segmentName={segment && segment.segmentName}
                    />
                )}
                {downloadCsvModalOpen && (
                    <CSVDownloadModal
                        segments={[
                            {
                                serialNumber,
                                deviceType: segment.deviceType,
                                segmentId: segment.segmentId,
                                segmentStartDate: segment.segmentStart,
                                segmentEndDate: segment.segmentEnd,
                                name: segment.segmentName,
                            },
                        ]}
                        onClose={(): void => setDownloadCsvModalOpen(false)}
                    />
                )}
                {selectedPeriod && (
                    <DevicePageBody
                        deviceId={segmentId}
                        devicePageLoading={devicePageLoading || !selectedPeriod}
                        fetching={fetching}
                        dateFormat={dateFormat}
                        device={segment || {}}
                        endedSegment
                        serialNumber={serialNumber}
                        fetchedDevices={segments}
                        fetchCustomSegment={fetchCustomSegment}
                        selectedPeriod={selectedPeriod}
                        updateSelectedPeriod={updateSelectedPeriod}
                    />
                )}
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        endedSegments: { segments, loading: devicePageLoading, fetching, errorCode },
        userSettings: { dateFormat, demoMode, loading: userSettingsLoading },
    } = state;
    return {
        segments,
        devicePageLoading: userSettingsLoading || devicePageLoading,
        fetching,
        dateFormat,
        error: !!errorCode,
        demoMode,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    fetchSegmentWithId: (serialNumber, segmentId, segmentPeriod): FetchDeviceSegmentId =>
        dispatch(fetchDeviceSegmentId(serialNumber, segmentId, segmentPeriod)),
    fetchSegment: (serialNumber, segmentId, selectedPeriod): FetchDeviceSegmentIdPeriod =>
        dispatch(fetchDeviceSegmentIdPeriod(serialNumber, segmentId, selectedPeriod)),
    onDelete: (serialNumber, segmentId, locationId): DeleteEndedSegment =>
        dispatch(deleteEndedSegment(serialNumber, segmentId, locationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EndedSegmentComponent);
