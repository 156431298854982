import { paths } from '../../constants';
import { RequiredRoleLevel } from '../../models/commonEnums';
import { TabOption } from '../../models/menuModels';

export const AccountCommonTabs: TabOption[] = [
    {
        text: 'Security',
        path: `/${paths.security}`,
        id: 'securitySettings',
        testAttr: 'security-settings',
        requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
        route: paths.security,
        requiredGroupTypes: [],
    },
];

export default AccountCommonTabs;
