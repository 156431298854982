import React, { SyntheticEvent } from 'react';
import { Radio, FormControlLabel, RadioGroup, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InputLabel from './InputLabel';
import styles from './Radio.module.scss';

type ButtonProps = {
    value?: string;
    label?: string | React.ReactElement;
    labelIsElement?: boolean;
    customStyle?: string;
    testId?: string;
    buttonChildren?: React.ReactElement;
};

type RadioProps = ButtonProps & {
    onChange: (event: SyntheticEvent<HTMLInputElement>) => void;
};

export const RadioButton = ({
    value,
    label,
    onChange,
    customStyle,
    labelIsElement = false,
    testId,
    buttonChildren,
}: RadioProps): React.ReactElement => {
    const { t: txt } = useTranslation();

    return (
        <>
            <FormControlLabel
                className={customStyle}
                control={
                    <Radio
                        size="small"
                        id={value}
                        value={value}
                        onChange={onChange}
                        inputProps={{ 'data-testid': testId || '' }}
                        color="primary"
                    />
                }
                label={<div className={styles.radioLabel}>{labelIsElement ? label : txt(label)}</div>}
            />
            {buttonChildren}
        </>
    );
};

type RadioButtonsProps = {
    row: boolean;
    buttons: ButtonProps[];
    onChange: (event: SyntheticEvent<HTMLInputElement>) => void;
    value?: string;
    header?: string;
    labelId: string;
    selectorName: string;
    markedMandatory?: boolean;
};

const RadioButtons = ({
    row,
    buttons,
    selectorName,
    header,
    labelId,
    value,
    onChange,
    markedMandatory,
}: RadioButtonsProps): React.ReactElement => {
    return (
        <FormControl>
            <InputLabel htmlFor={labelId} label={header} mandatory={markedMandatory} />
            <RadioGroup row={row} aria-labelledby={labelId} name={selectorName} value={value}>
                {buttons.map(button => (
                    <RadioButton
                        key={`${button.value}-${selectorName}`}
                        {...button}
                        onChange={onChange}
                        testId={button.testId || `${selectorName}-${button.value}`}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioButtons;
