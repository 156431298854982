import { ErrorType } from '../models/commonTypeScript';

export enum DownloadCSVActionType {
    DownloadCSVInit = 'DOWNLOAD_CSV/INIT',
    DownloadCSVSuccess = 'DOWNLOAD_CSV_SUCCESS',
    DownloadCSVError = 'DOWNLOAD_CSV_ERROR_ERROR',
    CloseCSVModalInit = 'CLOSE_CSV_MODAL/INIT',
}

export interface DownloadCSV {
    type: DownloadCSVActionType.DownloadCSVInit;
    serialNumber: string;
    from: string;
    to: string;
    segmentId?: string;
}
export const downloadCSV = (serialNumber: string, from: string, to: string, segmentId?: string): DownloadCSV => ({
    type: DownloadCSVActionType.DownloadCSVInit,
    serialNumber,
    from,
    to,
    segmentId,
});

export type CSVDownloadDetails = { filename: string; url: string };
export interface DownloadCSVSuccess {
    type: DownloadCSVActionType.DownloadCSVSuccess;
    CSVDownloadDetails: CSVDownloadDetails[];
}
export const downloadCSVSuccess = (csvDownloadDetails: CSVDownloadDetails[]): DownloadCSVSuccess => ({
    type: DownloadCSVActionType.DownloadCSVSuccess,
    CSVDownloadDetails: csvDownloadDetails,
});

export interface CloseCSVModal {
    type: DownloadCSVActionType.CloseCSVModalInit;
}
export const closeCSVModal = (): CloseCSVModal => ({ type: DownloadCSVActionType.CloseCSVModalInit });

export interface DownloadCSVError {
    type: DownloadCSVActionType.DownloadCSVError;
    error: ErrorType;
}
export const downloadCSVError = (error: ErrorType): DownloadCSVError => ({
    type: DownloadCSVActionType.DownloadCSVError,
    error,
});

export type DownloadCSVReducerActions = DownloadCSV | DownloadCSVSuccess | DownloadCSVError | CloseCSVModal;
