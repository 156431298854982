import React, { useEffect, useState, SyntheticEvent } from 'react';
import Slider from '@mui/material/Slider';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CheckBox from 'commons/src/components/input/Checkbox';
import Input from 'commons/src/components/input/Input';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import { sensorUnits } from 'commons/src/constants';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { SensorThresholds } from 'commons/src/models/commonTypeScript';
import { thresholdDurationLimits, alertRuleThresholdCategories, alertInsightSensors } from '../../../constants';
import { ThirdPartyIntegrationAlertRule } from '../../../models/common';

export type Props = {
    selectedThresholdValues: ThirdPartyIntegrationAlertRule[];
    updateAlertValue: (sensorTypes: string, thresholdValues: number[], duration: number, enabled: boolean) => void;
    sensor: SensorTypes;
    thresholds: { [sensor: string]: SensorThresholds };
};

export const SensorRuleComponent = (props: Props): React.ReactElement => {
    const { updateAlertValue, selectedThresholdValues, sensor, thresholds } = props;
    const [selectedDuration, setDuration] = useState(thresholdDurationLimits.durationDefault);
    const [thresholdTypes, setThresholdTypes] = useState<number[]>([]);
    const [sensorEnabled, setSensorEnabled] = useState(false);
    const isInsightSensor = alertInsightSensors.includes(sensor);

    const { t: txt } = useTranslation();

    const twoThresholdValues =
        sensor === SensorTypes.temp || sensor === SensorTypes.pressure || sensor === SensorTypes.humidity;

    const parseNumberInput = (numberAsString: string): number => {
        let duration = parseInt(numberAsString, 10);
        if (Number.isNaN(duration)) duration = 0;
        return duration;
    };

    const prependZero = (duration: number): string =>
        duration.toString().length < 2 ? `0${duration.toString()}` : duration.toString();

    const hourToMinuteConversion = (duration: string): number =>
        moment(duration, 'HH:mm').diff(moment().startOf('day'), 'minutes');

    const minuteToHourConversion = (fullMinutes: number): string => {
        const duration = fullMinutes;
        const hours = Math.floor(duration / 60);
        const minutes = duration % 60;
        return `${prependZero(hours)}:${prependZero(minutes)}`;
    };

    const loadSensorValues = (): void => {
        const highThreshold = selectedThresholdValues.filter(
            threshold => threshold.thresholdType === alertRuleThresholdCategories.allAbove
        );
        if (twoThresholdValues) {
            const lowThreshold = selectedThresholdValues.filter(
                threshold => threshold.thresholdType === alertRuleThresholdCategories.allBelow
            );
            setThresholdTypes([lowThreshold[0].thresholdValue, highThreshold[0].thresholdValue]);
        } else {
            setThresholdTypes([highThreshold[0].thresholdValue]);
        }
    };

    useEffect(() => {
        if (selectedThresholdValues.length === 0) {
            if (twoThresholdValues) {
                const sliderThresholds = [
                    thresholds[sensor]?.customOptions?.defaultLow || 0,
                    thresholds[sensor]?.customOptions?.defaultHigh || 1,
                ];
                if (sliderThresholds[0] === sliderThresholds[1])
                    sliderThresholds[1] += thresholds[sensor]?.customOptions?.thresholdDelta || 1;
                setThresholdTypes(sliderThresholds);
            } else {
                setThresholdTypes([thresholds[sensor]?.customOptions?.defaultHigh || 1]);
            }
        }
        if (selectedThresholdValues.length > 0) {
            const duration = minuteToHourConversion(selectedThresholdValues[0].duration);
            setDuration(duration);
            loadSensorValues();
            setSensorEnabled(true);
        }
    }, []);

    const toggleSensor = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { checked } = e.currentTarget;
        updateAlertValue(sensor, thresholdTypes, hourToMinuteConversion(selectedDuration), checked);
        setSensorEnabled(checked);
    };

    const updateSlider = (e: Event, sliderValues: number | number[]): void => {
        const thresholdValues = Array.isArray(sliderValues) ? sliderValues : [sliderValues];
        if (thresholdValues.length === 2 && thresholdValues[0] === thresholdValues[1]) {
            return;
        }
        setThresholdTypes(thresholdValues);
        updateAlertValue(sensor, thresholdValues, hourToMinuteConversion(selectedDuration), true);
    };

    const withinRange = (inputNumber: number, min: number, max: number): number =>
        Math.min(Math.max(inputNumber, min), max);

    const onBlurDurationChange = (e: SyntheticEvent<HTMLInputElement>): void => {
        const duration = e.currentTarget.value;
        let hhmmFormatTime: string;
        if (duration.includes(':')) {
            const hoursAndMinutes = duration.split(':').slice(0, 2);
            let hours = parseNumberInput(hoursAndMinutes[0]);
            let minutes = parseNumberInput(hoursAndMinutes[1]);
            hours = withinRange(hours, thresholdDurationLimits.hourMin, thresholdDurationLimits.hourMax);
            if (hours === thresholdDurationLimits.hourMax) {
                minutes = 0;
            } else {
                minutes =
                    hours === 0
                        ? withinRange(minutes, thresholdDurationLimits.minuteMin, thresholdDurationLimits.minuteMax)
                        : withinRange(minutes, 0, thresholdDurationLimits.minuteMax);
            }
            hhmmFormatTime = `${prependZero(hours)}:${prependZero(minutes)}`;
        } else {
            const parsedDuration = withinRange(
                parseNumberInput(duration),
                thresholdDurationLimits.hourMin,
                thresholdDurationLimits.hourMax
            );
            hhmmFormatTime =
                parsedDuration === 0
                    ? `${prependZero(parsedDuration)}:${prependZero(thresholdDurationLimits.minuteMin)}`
                    : `${prependZero(parsedDuration)}:00`;
        }
        setDuration(hhmmFormatTime);
        updateAlertValue(sensor, thresholdTypes, hourToMinuteConversion(hhmmFormatTime), true);
    };

    const onDurationChange = (e: SyntheticEvent<HTMLInputElement>): void => {
        let duration = e.currentTarget.value;
        if (!duration.includes(':') && duration.length === 2) {
            duration = `${duration}:`;
        }
        setDuration(duration);
    };

    const sensorUnit: keyof typeof sensorUnits | undefined = thresholds[sensor] && thresholds[sensor].unit;
    let sensorName = txt(sensor);
    if (sensor === SensorTypes.virusRisk) sensorName = txt(`VirusInsight.VirusRiskInsight`);
    else if (sensor === SensorTypes.mold) sensorName = txt('MoldRiskIndicator.MoldRiskIndicator');

    const sensorLabel = (
        <div className="notification-alert__icons">
            <div className="device-svg-icons notification-alert__icons__circle">
                <SensorIcon sensor={sensor} />
            </div>
            <div className="notification-alert__icons__text">
                <span
                    className={classNames({
                        'notification-alert__icons__text--uppercase': !isInsightSensor,
                    })}
                >
                    {sensorName}
                </span>
                {!isInsightSensor && (
                    <span className="notification-alert__icons__unit">
                        {['(', sensorUnit && sensorUnits[sensorUnit], ')']}
                    </span>
                )}
            </div>
        </div>
    );

    return (
        <div key={sensor}>
            <div className="form__row notification-alert__form form__row--padded-small">
                <div className="form__field">
                    <CheckBox
                        label={sensorLabel}
                        labelIsElement
                        id={sensor}
                        onChange={toggleSensor}
                        checked={sensorEnabled}
                        testId={`${sensor}-toggle-sensor`}
                    />
                </div>
            </div>
            {sensorEnabled && (
                <div className="form__row">
                    <div className="notification-alert__sensor-slider">
                        <div className="notification-alert__sensor-slider__text">
                            {twoThresholdValues
                                ? txt('NotificationAlerts.WhenOutsideRange')
                                : txt('NotificationAlerts.WhenAboveRange')}
                        </div>
                        <div>
                            <Slider
                                className="input__slider__line"
                                track="normal"
                                size="small"
                                id={sensor}
                                value={twoThresholdValues ? thresholdTypes : thresholdTypes[0]}
                                min={thresholds[sensor]?.customOptions?.minSelectableValue}
                                max={thresholds[sensor]?.customOptions?.maxSelectableValue}
                                valueLabelDisplay="on"
                                onChange={updateSlider}
                                step={thresholds[sensor]?.customOptions?.thresholdDelta || 1}
                                classes={{ valueLabel: 'input__slider__value-label' }}
                            />
                        </div>
                    </div>
                    <div className="notification-alert__sensor-input-container">
                        <div className="notification-alert__sensor-slider__text">
                            {txt('NotificationAlerts.ForMoreThan')}
                        </div>
                        <div className="notification-alert__sensor-input-container__input_field">
                            <Input
                                type="text"
                                id="duration"
                                testAttr="threshold-duration"
                                label=""
                                validate={false}
                                maxLength={5}
                                currentValue={selectedDuration}
                                onBlur={onBlurDurationChange}
                                onChange={onDurationChange}
                                testId={`${sensor}-threshold-duration`}
                            />
                            <div className="notification-alert__sensor-input-container__margin-sides">hh:mm</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SensorRuleComponent;
