import { MqttActions, MqttActionType } from '../actions/mqttIntegrationActions';
import { MqttClient, MqttClientCertificate } from '../models/common';

export type MqttState = {
    clients: MqttClient[];
    certificate: MqttClientCertificate | undefined;
};

const initialState: MqttState = {
    clients: [],
    certificate: undefined,
};

const {
    FetchMqttClientsInit,
    FetchMqttClientsSuccess,
    AddMqttClientSuccess,
    UpdateMqttClientSuccess,
    UpdateMqttClientActiveStateSuccess,
    DeleteMqttClientSuccess,
    FetchMqttClientCertificateSuccess,
    UploadMqttClientCertificateSuccess,
    RenewMqttClientCertificateSuccess,
    DeleteMqttClientCertificateSuccess,
} = MqttActionType;

export default (state: MqttState = initialState, action: MqttActions): MqttState => {
    switch (action.type) {
        case FetchMqttClientsInit:
            return {
                ...state,
            };
        case FetchMqttClientsSuccess:
            return {
                ...state,
                clients: action.clients,
            };
        case AddMqttClientSuccess:
            return {
                ...state,
                clients: [...state.clients, action.client],
            };
        case UpdateMqttClientSuccess: {
            const indexOfEditedClient = state.clients.findIndex(client => client.id === action.client.id);
            const copyOfClients = [...state.clients];
            copyOfClients[indexOfEditedClient] = action.client;
            return {
                ...state,
                clients: copyOfClients,
            };
        }
        case UpdateMqttClientActiveStateSuccess: {
            const indexOfEditedClient = state.clients.findIndex(client => client.id === action.client.id);
            const copyOfClients = [...state.clients];
            copyOfClients[indexOfEditedClient] = action.client;
            return {
                ...state,
                clients: copyOfClients,
            };
        }
        case DeleteMqttClientSuccess:
            return {
                ...state,
                clients: state.clients.filter(client => client.id !== action.clientId),
            };
        case FetchMqttClientCertificateSuccess:
        case UploadMqttClientCertificateSuccess:
        case RenewMqttClientCertificateSuccess:
            return {
                ...state,
                certificate: action.certificate,
            };
        case DeleteMqttClientCertificateSuccess:
            return {
                ...state,
                certificate: undefined,
            };
        default:
            return state;
    }
};
