import { BuildingHealthActionType, BuildingHealthActions } from '../actions/buildingHealthActions';
import { BuildingHealthSummary, BuildingHealthRowData } from '../models/buildingHealthModels';

export type BuildingHealth = BuildingHealthSummary & {
    hubs: BuildingHealthRowData[];
    devices: BuildingHealthRowData[];
    totalDevices: number;
};

const defaultBuildingHealth: BuildingHealth = {
    hubStats: { total: 0, offline: 0 },
    deviceStats: { total: 0, offline: 0 },
    totalDevices: 0,
    hubs: [],
    devices: [],
};

export type BuildingsHealthStatusState = {
    [locationId: string]: BuildingHealth;
};

export const initialState: BuildingsHealthStatusState = {};

export default (state = initialState, action: BuildingHealthActions): BuildingsHealthStatusState => {
    const building = { ...defaultBuildingHealth, ...state[action.locationId] } as BuildingHealth;
    switch (action.type) {
        case BuildingHealthActionType.FetchBuildingHealthSummarySuccess:
            return {
                ...state,
                [action.locationId]: { ...building, ...action.buildingHealthSummary },
            };

        case BuildingHealthActionType.FetchBuildingHealthHubsStatsSuccess:
            return {
                ...state,
                [action.locationId]: { ...building, hubs: action.hubs },
            };

        case BuildingHealthActionType.FetchBuildingHealthSummary:
            return {
                ...state,
                [action.locationId]: { ...building, devices: [] },
            };
        case BuildingHealthActionType.FetchBuildingHealthDevicesStatsSuccess:
            return {
                ...state,
                [action.locationId]: {
                    ...building,
                    devices: [...building.devices, ...action.devices],
                    totalDevices: action.totalDevices,
                },
            };
        default:
            return state;
    }
};
