import { t as txt } from 'i18next';
import { SensorTypes } from '../../models/commonEnums';

export const areaRangeData = (
    extraSeriesForSensor: { [seriesName: string]: number[][] },
    sensorType: SensorTypes
): number[][] | undefined => {
    const upperSeriesName =
        extraSeriesForSensor && Object.keys(extraSeriesForSensor).find(seriesName => seriesName.includes('Upper'));
    const lowerSeriesName =
        extraSeriesForSensor && Object.keys(extraSeriesForSensor).find(seriesName => seriesName.includes('Lower'));
    if (
        (sensorType === SensorTypes.occupants || sensorType === SensorTypes.airExchangeRate) &&
        upperSeriesName &&
        lowerSeriesName
    ) {
        const upperSeries = extraSeriesForSensor[upperSeriesName];
        const lowerSeries = extraSeriesForSensor[lowerSeriesName];
        return lowerSeries.map((dataPoint, index) => [...dataPoint, upperSeries[index][1]]);
    }
    return undefined;
};
export default areaRangeData;

export const timeFormatter = (minutes?: number): string => {
    if (minutes === undefined) return '';
    const hours = Math.floor(minutes / 60);
    const hoursValue = hours > 0 ? `${hours}${txt('TimeUnit.HoursShort')} ` : '';
    const minutestLeft = minutes % 60;
    const minutesValue = minutestLeft > 0 || hours === 0 ? `${minutestLeft}${txt('TimeUnit.MinutesShort')}` : '';
    return `${hoursValue}${minutesValue}`;
};
