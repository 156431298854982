import moment from 'moment';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import {
    FETCH_DEVICE_SEGMENTID_PERIOD,
    FetchDeviceSegmentIdPeriod,
    fetchDeviceSegmentIdPeriodError,
    fetchDeviceSegmentIdPeriodSuccess,
} from '../../actions/DeviceActions';
import { fetchSegmentWithId, fetchVirtualDeviceData } from '../../api/segment';
import {
    AverageSensorPeriodValues,
    DashboardSensorData,
    DeviceResponse,
    MinSensorPeriodValues,
    SelectedPeriod,
    SensorData,
} from '../../models/commonTypeScript';
import { segmentData } from '../../reducers/reducerShortcuts';
import { createChartData } from '../DashboardSagas/fetchDashboardSensorTile';

export function createGraphArrays(
    device: DeviceResponse,
    timeRange: SelectedPeriod,
    prevDevice: DashboardSensorData
): {
    fetchedIntervals: { [name: string]: boolean };
    sensorData: SensorData;
    minValues: MinSensorPeriodValues;
    averageValues: AverageSensorPeriodValues;
} {
    const segmentStartTime = moment.utc(device.segmentStart).valueOf();
    const sensorData = {};
    const minValues = {};
    const averageValues = {};

    device.sensors.forEach(sensor => {
        const graphData = createChartData(device, sensor, segmentStartTime);

        sensorData[sensor.type] = { ...prevDevice.sensorData[sensor.type], [timeRange.name]: graphData.chartData };
        minValues[sensor.type] = { ...prevDevice.minValues[sensor.type], [timeRange.name]: graphData.minValue };
        averageValues[sensor.type] = {
            ...prevDevice.averageValues[sensor.type],
            [timeRange.name]: graphData.averageValue,
        };
    });

    return {
        fetchedIntervals: { [timeRange.name]: true },
        sensorData,
        minValues,
        averageValues,
    };
}

export function* fetchSegmentWithIdPeriodSaga({
    serialNumber,
    segmentId,
    timeRange,
}: FetchDeviceSegmentIdPeriod): Generator {
    const { startDate, endDate } = timeRange;
    const toISO = endDate.toISOString();
    const to = toISO.slice(0, toISO.lastIndexOf('.'));
    const fromISO = startDate.toISOString();
    const from = fromISO.slice(0, fromISO.lastIndexOf('.'));

    try {
        const virtualSensorDataResponse = yield call(
            fetchVirtualDeviceData,
            serialNumber,
            {
                from,
                to,
                resolution: timeRange.resolution,
            },
            segmentId
        );

        const response = yield call(fetchSegmentWithId, serialNumber, segmentId, from, to, timeRange.resolution);
        const prevDevice = yield select(segmentData, segmentId);
        const virtualSensorData = createGraphArrays(virtualSensorDataResponse, timeRange, prevDevice);
        const sensorData = createGraphArrays(response, timeRange, prevDevice);
        const segmentSensorData = {
            ...sensorData,
            minValues: { ...sensorData.minValues, ...virtualSensorData.minValues },
            averageValues: { ...sensorData.averageValues, ...virtualSensorData.averageValues },
            sensorData: { ...sensorData.sensorData, ...virtualSensorData.sensorData },
        };
        yield put(fetchDeviceSegmentIdPeriodSuccess(segmentSensorData, segmentId));
    } catch (error) {
        yield put(fetchDeviceSegmentIdPeriodError());
    }
}

export default function* FetchSegmentWithIdPeriodSaga(): Generator {
    yield takeEvery(FETCH_DEVICE_SEGMENTID_PERIOD, fetchSegmentWithIdPeriodSaga);
}
