/* eslint max-len: ["error", 2000] */
import React from 'react';

const IconLight = (): React.ReactElement => (
    <svg
        id="iconLight"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
    >
        <path d="M12 0C12.4971 0 12.9 0.402944 12.9 0.9V2.7C12.9 3.19706 12.4971 3.6 12 3.6C11.5029 3.6 11.1 3.19706 11.1 2.7V0.9C11.1 0.402944 11.5029 0 12 0Z" />
        <path d="M12 20.4C12.4971 20.4 12.9 20.8029 12.9 21.3V23.1C12.9 23.5971 12.4971 24 12 24C11.5029 24 11.1 23.5971 11.1 23.1V21.3C11.1 20.8029 11.5029 20.4 12 20.4Z" />
        <path d="M24 12.0188C24 12.5158 23.5971 12.9188 23.1 12.9188H21.3C20.8029 12.9188 20.4 12.5158 20.4 12.0188C20.4 11.5217 20.8029 11.1188 21.3 11.1188H23.1C23.5971 11.1188 24 11.5217 24 12.0188Z" />
        <path d="M3.6 12.0188C3.6 12.5158 3.19706 12.9188 2.7 12.9188H0.9C0.402944 12.9188 -2.1727e-08 12.5158 0 12.0188C2.1727e-08 11.5217 0.402944 11.1188 0.9 11.1188H2.7C3.19706 11.1188 3.6 11.5217 3.6 12.0188Z" />
        <path d="M20.4856 3.51467C20.8371 3.86614 20.8371 4.43599 20.4856 4.78746L19.2128 6.06025C18.8614 6.41172 18.2915 6.41172 17.94 6.06025C17.5886 5.70878 17.5886 5.13893 17.94 4.78746L19.2128 3.51467C19.5643 3.1632 20.1341 3.1632 20.4856 3.51467Z" />
        <path d="M6.06042 17.9396C6.41189 18.2911 6.41189 18.861 6.06042 19.2124L4.78763 20.4852C4.43616 20.8367 3.86631 20.8367 3.51483 20.4852C3.16336 20.1338 3.16336 19.5639 3.51483 19.2124L4.78763 17.9396C5.1391 17.5882 5.70895 17.5882 6.06042 17.9396Z" />
        <path d="M20.472 20.4985C20.1205 20.85 19.5507 20.85 19.1992 20.4985L17.9264 19.2257C17.5749 18.8742 17.5749 18.3044 17.9264 17.9529C18.2779 17.6014 18.8477 17.6014 19.1992 17.9529L20.472 19.2257C20.8235 19.5772 20.8235 20.147 20.472 20.4985Z" />
        <path d="M6.04681 6.07353C5.69534 6.425 5.12549 6.425 4.77401 6.07353L3.50122 4.80074C3.14975 4.44927 3.14975 3.87942 3.50122 3.52795C3.85269 3.17647 4.42254 3.17647 4.77401 3.52795L6.04681 4.80074C6.39828 5.15221 6.39828 5.72206 6.04681 6.07353Z" />
        <path
            fillRule="evenodd"
            d="M12 13.8C12.9941 13.8 13.8 12.9941 13.8 12C13.8 11.0059 12.9941 10.2 12 10.2C11.0059 10.2 10.2 11.0059 10.2 12C10.2 12.9941 11.0059 13.8 12 13.8ZM12 15.6C13.9882 15.6 15.6 13.9882 15.6 12C15.6 10.0118 13.9882 8.4 12 8.4C10.0118 8.4 8.4 10.0118 8.4 12C8.4 13.9882 10.0118 15.6 12 15.6Z"
        />
    </svg>
);

export default IconLight;
