import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import {
    BoldText,
    colorStyles,
    DeviceIcon,
    FlexWrapper,
    sensorIconSource,
} from 'commons/src/components/PDF/PdfConstants';
import { SensorThresholds } from 'commons/src/models/commonTypeScript';
import { PDFContext, VirusRiskInsightData } from '../../../../models/common';
import { solutionsList } from '../../virusRisk/VirusDeviceResult';

const componentStyles = StyleSheet.create({
    solutionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '25%',
    },
    solutionTextWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '70%',
    },
    solutionText: {
        fontSize: 12,
    },
});

type Props = {
    deviceReport: VirusRiskInsightData;
    thresholds: { [sensor: string]: SensorThresholds };
    txt: PDFContext;
};

type ImpactFactorType = {
    solution: string;
    txt: PDFContext;
};

const HighImpactFactor = ({ solution, txt }: ImpactFactorType): React.ReactElement => {
    return (
        <View style={componentStyles.solutionWrapper}>
            <DeviceIcon src={sensorIconSource(solution) || ''} />
            <View style={componentStyles.solutionTextWrapper}>
                <Text style={componentStyles.solutionText}>{txt(`VirusInsight.Solution-${solution}`)}</Text>
            </View>
        </View>
    );
};

const Solutions = ({ deviceReport, thresholds, txt }: Props): React.ReactElement | null => {
    const listOfSolutions = solutionsList(deviceReport, thresholds);
    if (listOfSolutions.length === 0) {
        return null;
    }
    return (
        <View>
            <BoldText style={colorStyles.smallText}>{txt('InsightRiskFactor.Solutions')}</BoldText>
            <FlexWrapper>
                {listOfSolutions.map(riskFactor => (
                    <HighImpactFactor
                        key={`solution-button-${riskFactor.solution}-${deviceReport.serialNumber}`}
                        solution={riskFactor.solution}
                        txt={txt}
                    />
                ))}
            </FlexWrapper>
        </View>
    );
};

export default Solutions;
