import React from 'react';
import { useTranslation } from 'react-i18next';
import IconWarning from '../../img/icon-warning';

const NotSupported = (): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className="page-wrapper">
            <div className="no-content-page not-found">
                <IconWarning />
                <p className="no-content-page__title">{txt('NotSupported')}</p>
            </div>
        </div>
    );
};

export default NotSupported;
