/* eslint max-len: ["error", 1000] */
import React from 'react';

const Ethernet = (): React.ReactElement => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
        <path
            d="M20,2c1,0,1.9,0.8,2,1.9L22,4v16c0,1-0.8,1.9-1.9,2L20,22H4c-1,0-1.9-0.8-2-1.9L2,20V4
        c0-1,0.8-1.9,1.9-2L4,2H20z M20,4H4v16h16V4z M17,7c0.7,0,1.3,0.5,1.3,1.2l0,0.1v4.4c0,0.4-0.1,0.7-0.4,1c-0.2,0.2-0.5,0.4-0.8,0.4
        l-0.2,0h-0.1v0.6c0,0.6-0.5,1.2-1.1,1.2l-0.1,0h-1.2v0.6c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.6,0.3l-0.1,0h-2.5
        c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.4-0.3-0.6l0-0.1v-0.6H8.7c-0.3,0-0.6-0.1-0.9-0.4c-0.2-0.2-0.3-0.5-0.3-0.7l0-0.1v-0.6H7.3
        c-0.4,0-0.7-0.1-0.9-0.4C6.2,13.5,6,13.2,6,12.9l0-0.2V8.3C6,7.6,6.5,7.1,7.2,7l0.1,0H17z M17,8H7.3C7.2,8,7,8.1,7,8.3l0,0.1v4.4
        C7,12.9,7,13,7.1,13c0,0,0.1,0.1,0.2,0.1l0.1,0h1.1v1.6c0,0.1,0.1,0.2,0.2,0.2l0.1,0h2.2v1.6c0,0,0,0.1,0,0.1l0,0h2.5
        c0,0,0.1,0,0.1-0.1l0,0v-1.6h2.2c0.1,0,0.2-0.1,0.2-0.2l0-0.1v-1.6H17c0.2,0,0.3-0.1,0.3-0.3l0-0.1V8.3C17.3,8.1,17.2,8,17,8z
        M11,8.5c0.4,0,0.7,0.3,0.7,0.7v1.5c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7V9.2C10.3,8.8,10.6,8.5,11,8.5z M8.7,8.5
        c0.4,0,0.7,0.3,0.7,0.7v1.5c0,0.4-0.3,0.7-0.7,0.7S8,11.1,8,10.7V9.2C8,8.8,8.4,8.5,8.7,8.5z M13.3,8.5c0.4,0,0.7,0.3,0.7,0.7v1.5
        c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7V9.2C12.6,8.8,12.9,8.5,13.3,8.5z M15.6,8.5c0.4,0,0.7,0.3,0.7,0.7v1.5
        c0,0.4-0.3,0.7-0.7,0.7s-0.7-0.3-0.7-0.7V9.2C14.9,8.8,15.2,8.5,15.6,8.5z"
        />
    </svg>
);

export default Ethernet;
