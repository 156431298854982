import React from 'react';

type WindowSize = {
    width: number;
    height: number;
};

export default function useWindowSize(): WindowSize {
    const [windowSize, setWindowSize] = React.useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    function changeWindowSize(): void {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }

    React.useEffect(() => {
        window.addEventListener('resize', changeWindowSize);

        return (): void => {
            window.removeEventListener('resize', changeWindowSize);
        };
    }, []);

    return windowSize;
}
