import React from 'react';
import classNames from 'classnames';
import { statusColors } from '../../constants';

export type Props = {
    name: string;
    statusColor?: string;
    firstRow?: string;
    secondRow?: string;
    firstRowLogo?: React.ReactElement;
};

const IntegrationRowContent = (props: Props): React.ReactElement => {
    const { statusColor, name, firstRow, secondRow, firstRowLogo } = props;

    return (
        <div className="centered integration-row__text">
            {statusColor && (
                <div
                    className={classNames('sensor__line', {
                        'sensor__line--ok': statusColor === statusColors.green,
                        'sensor__line--alert': statusColor === statusColors.red,
                        'sensor__line--disabled': statusColor === statusColors.grey,
                        'sensor__line--warning': statusColor === statusColors.yellow,
                    })}
                />
            )}
            <div className="list__row__text-container">
                <h3 className="list__row__text-container__header">{name}</h3>
                <div className="list__row__text-container__single-line-content">
                    {firstRowLogo && (
                        <span className="list__row__text-container__single-line-content__img">{firstRowLogo}</span>
                    )}
                    <span className="list__row__text-container__single-line-content__text">{firstRow}</span>
                </div>
                <div className="list__row__text-container__single-line-content__text">{secondRow}</div>
            </div>
        </div>
    );
};

export default IntegrationRowContent;
