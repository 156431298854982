import { all, call, put, takeEvery, CallEffect, PutEffect } from 'redux-saga/effects';
import { FetchLocationsHistory, fetchLocationsHistorySuccess, LocationActions } from '../../actions/LocationActions';
import { RequestActionType, requestError, requestSuccess } from '../../actions/requestActions';
import { getLocationsHistory } from '../../api/locations';
import { EndedSegment, LocationsHistoryType } from '../../models/commonTypeScript';
import { CommonRequestType } from '../../reducers/requestReducer';
import { toErrorType } from '../isErrorType';

export const createEndedSegmentArray = (locations: LocationsHistoryType[]): EndedSegment[] => {
    const endedSegmentsInLocations = locations.map(location =>
        location.segments.map(segment => ({
            ...segment,
            locationId: location.locationId,
            locationName: location.locationName,
        }))
    );
    return [].concat(...endedSegmentsInLocations);
};

export function* getLocationsHistorySaga({
    locationId,
}: FetchLocationsHistory): Generator<
    CallEffect<{ locations: LocationsHistoryType[] }> | PutEffect,
    void,
    { locations: LocationsHistoryType[] }
> {
    try {
        const response = yield call(getLocationsHistory, locationId);
        const updatedList = createEndedSegmentArray(response.locations);
        yield put(fetchLocationsHistorySuccess(updatedList));
        yield put(requestSuccess(CommonRequestType.FetchLocationsHistory, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.FetchLocationsHistory, RequestActionType.Error));
    }
}

export default function* locationHistorySagas(): Generator {
    yield all([takeEvery(LocationActions.FetchLocationsHistory, getLocationsHistorySaga)]);
}
