import React from 'react';
import Modal from 'react-modal';
import ModalHeader from 'commons/src/components/modals/ModalHeader';
import { BuildingType } from 'commons/src/models/commonTypeScript';
import EditBuilding from './EditBuilding';

type Props = {
    onClose: () => void;
    building: BuildingType;
};

const BuildingSettingsModalComponent = ({ onClose, building }: Props): React.ReactElement => {
    return (
        <Modal
            isOpen
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--size-large"
            overlayClassName="modal modal__overlay"
        >
            <ModalHeader headerText="Building.BuildingSettings" onClose={onClose} />
            <div className="settings-details-container">
                <div className="page-wrapper__inner page-wrapper__inner--slim">
                    <EditBuilding closeOnSave building={building} onClose={onClose} locationId={building.id} />
                </div>
            </div>
        </Modal>
    );
};

export default BuildingSettingsModalComponent;
