import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButtons from './Radio';

export type Props = {
    headerKey: string;
    icon: React.ReactElement;
    options: { displayedValue: string; id: string }[];
    onSelect: (selectionName: string, selectedOptionId: string) => void;
    selectionName: string;
    selectedId: string;
};

const RadioSelectorComponent = (props: Props): React.ReactElement => {
    const { headerKey, icon, onSelect, options, selectionName, selectedId } = props;
    const { t: txt } = useTranslation();

    const changeSelection = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        const selectorName = e.currentTarget.name;
        onSelect(selectorName, value);
    };
    const radioOptions = options.map(opt => ({
        customStyle: 'input__radio-selector__radio',
        value: opt.id,
        label: opt.displayedValue,
    }));

    return (
        <div className="input__radio-selector">
            <div className="input__radio-selector__icon">
                <div className="device-svg-icons device-svg-icons--circled">{icon}</div>
                <div className="input__radio-selector__icon__text">{txt(headerKey)}</div>
            </div>
            <RadioButtons
                buttons={radioOptions}
                selectorName={selectionName}
                row
                onChange={changeSelection}
                value={selectedId}
                labelId={`user-settings-${selectionName}`}
            />
        </div>
    );
};

export default RadioSelectorComponent;
