import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import MaterialTableContextComponent from './context/MaterialTableContext';
import DesktopMaterialTable from './DesktopMaterialTable';
import styles from './DesktopMaterialTable.module.scss';
import MaterialTablePagination from './MaterialTablePagination';
import MobileMaterialTable from './MobileMaterialTable';
import { MaterialTableProps } from './models';

const MaterialTable = ({
    tableId,
    columns,
    data,
    isReady,
    size,
    idKey,
    pagination,
    totalDataLength,
    allowColumnManagement,
    locationId,
}: MaterialTableProps): React.ReactElement => {
    return (
        <MaterialTableContextComponent
            tableId={tableId}
            data={data}
            columns={columns}
            isReady={isReady}
            size={size}
            idKey={idKey}
            pagination={pagination}
            totalDataLength={totalDataLength}
            allowColumnManagement={allowColumnManagement}
        >
            <div className={styles.tableWrapper}>
                {!isReady && <LinearProgress variant="query" data-testid="materialTable-loader" />}
                <MobileMaterialTable tableId={tableId} size={size} locationId={locationId} />
                <DesktopMaterialTable tableId={tableId} size={size} locationId={locationId} />
            </div>
            <MaterialTablePagination />
        </MaterialTableContextComponent>
    );
};

export default MaterialTable;
