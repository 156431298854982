import React from 'react';

const EmailSummaryIllustration = (): React.ReactElement => (
    <svg
        className="insight-main-tile__image-wrapper--flex-center"
        width="153"
        height="151"
        viewBox="0 0 153 151"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_1054_18350)">
            <path
                d="M15.6399 92.2664L11.1366 73.7192C10.6955 71.9024 11.1771 69.9835 12.4242 68.583L57.9917 17.514C60.1828 15.0581 63.6789 14.2367 66.7343 15.4542L130.609 40.9536C132.323 41.639 133.599 43.1075 134.034 44.8983L138.303 62.4794"
                fill="#C9E2F0"
            />
            <path
                d="M15.6027 92.2754C14.091 86.6346 12.5446 80.298 11.0909 74.6038C10.8306 73.5968 10.579 72.5445 10.7647 71.504C10.9113 70.4494 11.3488 69.4382 12.0241 68.6094C16.8764 63.1781 23.4219 55.6794 28.2377 50.3907C38.1187 39.572 48.611 27.8887 58.2855 16.9509C60.7772 14.481 64.6051 14.0669 67.7113 15.575C71.3133 16.9785 81.0076 20.7899 84.7301 22.2506C100.085 28.2995 115.402 34.44 130.685 40.6674C132.422 41.3474 133.792 42.8915 134.246 44.6973L135.63 50.6249L138.346 62.4769L138.271 62.495L135.25 50.7172L133.757 44.8317C133.308 43.1901 132.049 41.8275 130.464 41.2208C115.095 35.207 99.7611 29.1138 84.4598 22.9261L73.1655 18.3465L67.5226 16.0497C64.8422 14.7018 61.4891 14.8275 59.1067 16.7397C58.4209 17.2485 57.5862 18.2499 56.9989 18.8882C47.7339 29.0892 37.8266 40.418 28.7164 50.8175C23.9776 56.1307 18.8348 63.6591 14 68.9995C12.7128 70.5278 12.5 71.504 12.5 73.9995L12.9716 80.4146C13.8906 84.362 14.7946 88.313 15.6837 92.2676L15.6092 92.2857L15.6027 92.2754Z"
                fill="#3B474F"
            />
            <path
                d="M82.397 98.0903L128.147 52.3592L120.335 20.1841C119.168 15.3801 114.317 12.4388 109.5 13.6084L20.6932 35.171C15.8724 36.3415 12.9142 41.183 14.0797 45.9833L22.1066 79.0427L82.4007 98.0894L82.397 98.0903Z"
                fill="#FBEAB1"
            />
            <path
                d="M82.3877 98.0532C82.3092 98.1037 128.369 51.7938 127.987 52.3981L122.451 29.7778L121.069 24.1211C120.63 22.5046 120.214 20.06 119.526 18.5626C117.912 15.099 113.895 12.9975 110.114 13.7345C109.364 13.8497 108.066 14.2241 107.286 14.3938C79.6357 21.1388 49.1462 29.2559 21.5 36C17.2991 37.0082 14.383 40.7354 15 45C15.1476 45.9006 15.7764 48.5629 16 49.5C18.5229 59.8094 19.9619 68.6493 22.4225 78.962L22.2037 78.7279C39.3077 84.112 65.5063 92.6426 82.3868 98.0494L82.3877 98.0532ZM82.4058 98.1275C65.4443 92.9724 38.9523 84.5996 21.8263 79.3035C19.4947 69.8958 17.1442 60.2804 14.8621 50.849C14.4713 49.1419 13.8411 46.8713 13.5286 45.1452C12.8906 40.8267 15.6524 36.3634 19.8306 35.0341C47.7234 28.1672 79.2519 20.7126 107.172 13.9257C107.769 13.8003 109.354 13.3683 110.032 13.2666C114.009 12.5056 118.243 14.7235 119.928 18.3786C120.65 19.9541 121.032 22.3558 121.484 24.0243C122.363 27.6941 127.317 48.2093 128.304 52.3249C128.549 52.5527 82.3671 97.9519 82.403 98.1321L82.4058 98.1275Z"
                fill="#3B474F"
            />
            <path
                d="M135.413 124.562L145.069 122.217C149.107 121.237 151.585 117.181 150.609 113.161L134.335 46.1355L37.9574 142.716"
                fill="white"
            />
            <path
                d="M135.369 124.384C137.138 123.946 141.239 122.048 142.964 121.625L144.344 121.29C147.534 120.558 149.956 117.507 149.941 114.242C149.965 113.303 149.728 112.409 149.5 111.5C146.118 97.4886 137.041 63.4689 133.5 49.0001L134.536 48.1042L112 71.5001C93.4774 90.1771 68.7311 112.413 50.1411 130.78C50.1226 130.768 37.9796 142.841 37.9329 142.746C37.8235 142.686 49.8935 130.556 49.8715 130.515C76.2585 103.641 107.386 72.6497 134.142 45.9461L134.489 45.6022L134.604 46.074C138.135 60.7458 147.106 98.0022 150.563 112.161C151.116 113.998 151.221 116.026 150.442 117.824C149.536 120.078 147.503 121.835 145.133 122.398C144.294 122.586 141.869 123.203 140.991 123.404L135.465 124.746C135.368 124.769 135.271 124.71 135.247 124.614C135.224 124.517 135.283 124.42 135.38 124.397L135.369 124.384Z"
                fill="#3B474F"
            />
            <path
                d="M82.3967 98.0903L11.6709 75.9188L27.9449 142.944C28.9219 146.968 32.9866 149.431 37.0207 148.452L125.346 127.006"
                fill="white"
            />
            <path
                d="M82.2832 98.4523L11.5574 76.2807L12.0465 75.8236C16.0664 92.2495 24.4993 126.737 28.4216 142.777C29.4615 146.963 33.7261 149.079 37.7807 147.728C38.5851 147.529 40.8566 146.981 41.6937 146.77L125.19 126.348C125.552 126.26 125.913 126.479 126.004 126.838C126.092 127.199 125.871 127.559 125.51 127.651C125.51 127.651 41.9455 147.791 41.9417 147.792C40.4198 148.142 38.2085 148.726 36.685 149.037C32.5554 149.851 28.3823 147.087 27.4494 143.017C27.3658 142.624 27.0722 141.48 26.9757 141.067C23.3058 125.854 14.8912 90.9217 11.1383 75.3517L11.7834 75.5531L82.5092 97.7246C82.9638 97.9054 82.7949 98.5759 82.2795 98.4532L82.2832 98.4523Z"
                fill="#3B474F"
            />
            <path
                d="M82.6326 71.3538C79.7386 72.7686 76.8071 74.0941 73.7112 75.0347C68.8982 76.5141 63.2963 74.9392 60.0874 71.0404C56.9375 67.2058 55.5051 62.1359 55.4978 57.2276C55.5938 51.8181 58.7617 47.7912 63.7868 45.9337C72.5045 42.259 83.293 44.7228 85.0747 55.1019C85.493 57.8489 86.5016 62.7344 84.2809 64.9221C83.7345 65.4364 83.0225 65.5936 82.3506 65.737C80.2902 66.3082 77.7672 65.2998 77.1464 63.1331C76.7275 61.5706 76.519 59.9963 75.9992 58.4897C75.1621 55.7737 73.3178 52.8772 70.1401 53.4324C68.7827 53.6636 67.2296 54.1941 66.2084 55.1267C63.8782 57.2859 65.4975 60.7681 66.1236 63.3466C67.1608 66.6593 69.3108 67.9038 72.5507 66.6293C74.8296 65.8517 77.3159 63.9615 76.7188 61.372L76.4402 60.2082L74.2475 50.8849C74.0786 50.0918 75.2466 49.7964 75.463 50.5898C75.7873 51.9746 78.0081 60.5357 78.2112 61.7297C78.4249 65.7971 74.0858 68.2198 70.504 68.5741C66.3467 68.8674 64.7381 64.8279 64.0268 61.4754C63.5823 59.8561 63.1725 58.071 63.6862 56.3489C64.3091 54.0849 66.5251 52.756 68.646 52.1899C70.771 51.4615 73.4094 51.8399 74.9565 53.5613C76.7903 55.52 77.4565 57.9549 78.0184 60.4152C78.143 61.0262 78.3411 62.1348 78.4997 62.6904C78.8804 64.0144 80.2174 64.6262 81.5459 64.5042C81.8716 64.496 82.3136 64.365 82.6369 64.3141C84.4579 63.9113 84.4279 61.495 84.4965 59.94C84.4287 56.864 84.2414 53.6046 82.7719 50.8374C80.1987 45.8006 73.846 43.9792 68.5467 45.0652C56.5286 47.7 54.6061 55.6854 58.7393 66.2687C61.1606 72.3221 67.0068 75.7812 73.5078 74.246C76.5773 73.489 79.591 72.3559 82.5751 71.2143C82.66 71.174 82.7259 71.3154 82.6363 71.3529L82.6326 71.3538Z"
                fill="#3B474F"
            />
            <path
                d="M42.7541 133.25C50.2492 127.744 57.5185 121.34 64.3422 115.068C78.8607 101.738 94.5994 85.5315 108.719 71.5984L108 75.5002C94.1123 90.8565 80.0005 102 64.6421 115.393C59.1419 120.383 53.2341 125.386 47.3621 129.967L42.8048 133.312L42.7615 133.248L42.7541 133.25Z"
                fill="#3B474F"
            />
        </g>
        <defs>
            <clipPath id="clip0_1054_18350">
                <rect width="127.003" height="124.545" fill="white" transform="translate(0 29.9658) rotate(-13.6475)" />
            </clipPath>
        </defs>
    </svg>
);

export default EmailSummaryIllustration;
