import React from 'react';
import useMaterialTableContext from '../materialTable/hooks/useMaterialTableContext';
import CollapsibleItem from './CollapsibleItem';
import styles from './CollapsibleTable.module.scss';
import { RenderedProps } from './models';

type CollapsibleTableProps = {
    renderHeader: (props: RenderedProps) => React.ReactNode;
    renderContent: (props: RenderedProps) => React.ReactNode;
};

const CollapsibleTableData = ({ renderHeader, renderContent }: CollapsibleTableProps): React.ReactElement => {
    const { paginatedData, idKey } = useMaterialTableContext();
    return (
        <tbody className={styles.tableBody}>
            {paginatedData.map(paginationItem => (
                <CollapsibleItem
                    key={paginationItem[idKey] as string}
                    id={paginationItem[idKey] as string}
                    data={paginationItem}
                    renderContent={renderContent}
                    renderHeader={renderHeader}
                />
            ))}
        </tbody>
    );
};

export default CollapsibleTableData;
