import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NumberInput from 'commons/src/components/input/Number';
import StatusPill from 'commons/src/components/pills/StatusPill';
import { PillStatus, Rating, SensorTypes } from 'commons/src/models/commonEnums';
import { SensorThresholds, ThresholdRange } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../../reducers';
import { getThresholdRanges } from '../spaceFunctions';
import styles from './SpaceThreshold.module.scss';

type Props = {
    selectedSensor: SensorTypes;
    customThresholds: { [sensor: string]: SensorThresholds };
    setCustomThresholds: (thresholds: { [sensor: string]: SensorThresholds }) => void;
    range: ThresholdRange;
    index: number;
};

type StateProps = {
    thresholds: { [sensor: string]: SensorThresholds };
};

const SpaceThreshold = ({
    selectedSensor,
    customThresholds,
    setCustomThresholds,
    range,
    index,
    thresholds,
}: Props & StateProps): ReactElement => {
    const ranges: { modifiedRanges: ThresholdRange[]; lowest: number; highest: number } = getThresholdRanges(
        customThresholds[selectedSensor].ranges,
        selectedSensor
    );
    const [thresholdRanges, setThresholdRanges] = useState(ranges);
    const thresholdInGraph = index === 0 ? ranges.lowest : ranges.highest;
    const [threshold, setThreshold] = useState(thresholdInGraph);
    const [validInput, setValidInput] = useState(true);
    const lessThan = '<';
    const greaterThanOrEqual = '≥';

    const updateThresholdRanges = (sensor: SensorTypes, newThreshold: number): ThresholdRange[] => {
        const isTempOrHumidity = sensor === SensorTypes.temp || sensor === SensorTypes.humidity;

        return thresholdRanges.modifiedRanges.map((thresholdRange, i) => {
            if (i === index) {
                if (isTempOrHumidity) {
                    return index === 0
                        ? { ...thresholdRange, to: newThreshold }
                        : { ...thresholdRange, from: newThreshold };
                }
                return { ...thresholdRange, from: newThreshold };
            }
            if (!isTempOrHumidity && i === 0 && index === 1) {
                return { ...thresholdRange, to: newThreshold };
            }
            return thresholdRange;
        });
    };

    const validThreshold = (value: number): boolean => {
        const minDefaultThreshold: number = thresholds[selectedSensor]?.customOptions?.minSelectableValue ?? 0;
        const maxDefaultThreshold: number = thresholds[selectedSensor]?.customOptions?.maxSelectableValue ?? 1;

        let validValue = false;
        if (index === 0) {
            validValue = !Number.isNaN(value) && value >= minDefaultThreshold && value < thresholdRanges.highest;
        } else if (index === 1) {
            validValue = !Number.isNaN(value) && value <= maxDefaultThreshold && value > thresholdRanges.lowest;
        }
        return validValue;
    };

    const updateThreshold = (event: SyntheticEvent<HTMLInputElement>): void => {
        const stringValue = event.currentTarget.value;
        const value = parseInt(stringValue, 10);
        setThreshold(value);
        const validValue = validThreshold(value);
        if (validValue) {
            setValidInput(true);
        } else {
            setValidInput(false);
        }
    };

    useEffect(() => {
        if (threshold !== thresholdInGraph) {
            setThreshold(thresholdInGraph);
            setValidInput(true);
            setThresholdRanges(getThresholdRanges(customThresholds[selectedSensor].ranges, selectedSensor));
        }
    }, [customThresholds]);

    useEffect(() => {
        const validValue = validThreshold(threshold);
        if (validValue) {
            const updatedThresholdRanges = updateThresholdRanges(selectedSensor, threshold);
            setCustomThresholds({
                ...customThresholds,
                [selectedSensor]: { ...customThresholds[selectedSensor], ranges: updatedThresholdRanges },
            });
        }
    }, [threshold]);

    return (
        <div className={styles.threshold}>
            <StatusPill
                status={range.rating === Rating.POOR ? PillStatus.alert : PillStatus.warning}
                outline
                size="small"
            />
            {index === 0 && range.rating === Rating.POOR ? <span>{lessThan}</span> : <span>{greaterThanOrEqual}</span>}
            <NumberInput
                id="threshold"
                validate={!validInput}
                disabled={false}
                size="small"
                width="minimum"
                currentValue={threshold.toString()}
                minValue={
                    index === 1 ? thresholdRanges.lowest : thresholds[selectedSensor]?.customOptions?.minSelectableValue
                }
                maxValue={
                    index === 0
                        ? thresholdRanges.highest
                        : thresholds[selectedSensor]?.customOptions?.maxSelectableValue
                }
                step={thresholds[selectedSensor]?.customOptions?.thresholdDelta || 1}
                onChange={updateThreshold}
            />
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        config: { thresholds },
    } = state;
    return {
        thresholds,
    };
};
export default connect(mapStateToProps)(SpaceThreshold);
