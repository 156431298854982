import React from 'react';
import { View } from '@react-pdf/renderer';
import { PageLine, FlexWrapperSpaced, Graph } from 'commons/src/components/PDF/PdfConstants';
import { DeviceWithKeyInfo, SegmentProperties, SensorThresholds } from 'commons/src/models/commonTypeScript';
import { PDFContext, VirusRiskInsightData } from '../../../../models/common';
import DeviceDetails from '../DeviceDetails';
import AverageValues from '../IaqInsightPdf/AverageValues';
import PossibleRiskFactors from './RiskFactors';
import Solutions from './Solutions';

type Props = {
    txt: PDFContext;
    device: DeviceWithKeyInfo;
    segmentProps: SegmentProperties;
    thresholds: { [sensor: string]: SensorThresholds };
    deviceReport: VirusRiskInsightData;
    listOfImages: { data: string; serialNumber: string }[];
    possibleRiskFactors: { riskName: string; sensorName: string }[];
};

const DeviceResult = (props: Props): React.ReactElement => {
    const { txt, thresholds, listOfImages, deviceReport, device, segmentProps, possibleRiskFactors } = props;
    const image =
        listOfImages.length > 0 && listOfImages.find(chart => chart.serialNumber === deviceReport.serialNumber);
    return (
        <View wrap={false}>
            <DeviceDetails txt={txt} device={device} deviceProps={segmentProps} />
            <View>
                <FlexWrapperSpaced>
                    <View>
                        <AverageValues
                            txt={txt}
                            value={deviceReport.averageValueWithinOpeningHours}
                            ranges={thresholds.virusRisk.ranges}
                            unit={thresholds.virusRisk.unit}
                            headerText="InsightIaq.AverageWithinOpeningHours"
                            sensor="virusRisk"
                            displayCircle
                        />
                    </View>
                    <PossibleRiskFactors
                        possibleRiskFactors={possibleRiskFactors}
                        deviceResult={deviceReport}
                        thresholds={thresholds}
                        txt={txt}
                    />
                </FlexWrapperSpaced>
            </View>
            {image && image.data && <Graph src={image.data} />}
            <Solutions deviceReport={deviceReport} thresholds={thresholds} txt={txt} />
            <PageLine />
        </View>
    );
};

export default DeviceResult;
