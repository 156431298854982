import React from 'react';

const IconTemp = (): React.ReactElement => (
    <svg
        version="1.1"
        id="iconTemp"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        width="24px"
        height="24px"
    >
        <path d="M11.8286 2.05714C10.8818 2.05714 10.1143 2.82465 10.1143 3.77143L10.113 14.2328C10.113 14.5689 9.94874 14.8837 9.67315 15.0761C8.69415 15.7594 8.05714 16.8911 8.05714 18.1714C8.05714 20.2543 9.74567 21.9428 11.8286 21.9428C13.9115 21.9428 15.6 20.2543 15.6 18.1714C15.6 16.8906 14.9625 15.7586 13.9829 15.0753C13.7071 14.883 13.5428 14.568 13.5428 14.2317L13.5428 10.2857H12.5143C11.9462 10.2857 11.4857 9.82521 11.4857 9.25715C11.4857 8.68908 11.9462 8.22858 12.5143 8.22858H13.5428L13.5428 6.17143H12.5143C11.9462 6.17143 11.4857 5.71092 11.4857 5.14286C11.4857 4.5748 11.9462 4.11429 12.5143 4.11429H13.5428L13.5429 3.77143C13.5428 2.82466 12.7753 2.05714 11.8286 2.05714ZM8.05714 3.7713C8.05721 1.68846 9.74571 0 11.8286 0C13.9115 0 15.6 1.68852 15.6 3.77143L15.5999 13.7273C16.857 14.7952 17.6571 16.3898 17.6571 18.1714C17.6571 21.3904 15.0476 24 11.8286 24C8.60954 24 6 21.3904 6 18.1714C6 16.3904 6.79956 14.7963 8.05593 13.7284L8.05714 3.7713Z" />
        <path d="M10 18C10 16.8954 10.8954 16 12 16C13.1046 16 14 16.8954 14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18Z" />
    </svg>
);

export default IconTemp;
