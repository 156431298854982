import { RequestActionType } from 'commons/src/actions/requestActions';
import {
    BusinessActionRequestType,
    EditMqttClient,
    MqttClient,
    MqttClientCertificate,
    NewMqttClient,
} from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum MqttActionType {
    FetchMqttClientsInit = 'FETCH_MQTT_CLIENTS/INIT',
    AddMqttClientInit = 'ADD_MQTT_CLIENT/INIT',
    UpdateMqttClientInit = 'UPDATE_MQTT_CLIENT/INIT',
    UpdateMqttClientActiveStateInit = 'UPDATE_MQTT_CLIENT_ACTIVE_STATE/INIT',
    DeleteMqttClientInit = 'DELETE_MQTT_CLIENT/INIT',
    FetchMqttClientCertificateInit = 'FETCH_MQTT_CLIENT_CERTIFICATE/INIT',
    UploadMqttClientCertificateInit = 'UPLOAD_MQTT_CLIENT_CERTIFICATE/INIT',
    RenewMqttClientCertificateInit = 'RENEW_MQTT_CLIENT_CERTIFICATE/INIT',
    DeleteMqttClientCertificateInit = 'DELETE_MQTT_CLIENT_CERTIFICATE/INIT',
    FetchMqttClientsSuccess = 'FETCH_MQTT_CLIENTS_SUCCESS',
    AddMqttClientSuccess = 'ADD_MQTT_CLIENT_SUCCESS',
    UpdateMqttClientSuccess = 'UPDATE_MQTT_CLIENT_SUCCESS',
    UpdateMqttClientActiveStateSuccess = 'UPDATE_MQTT_CLIENT_ACTIVE_STATE_SUCCESS',
    DeleteMqttClientSuccess = 'DELETE_MQTT_CLIENT_SUCCESS',
    FetchMqttClientCertificateSuccess = 'FETCH_MQTT_CLIENT_CERTIFICATE_SUCCESS',
    UploadMqttClientCertificateSuccess = 'UPLOAD_MQTT_CLIENT_CERTIFICATE_SUCCESS',
    RenewMqttClientCertificateSuccess = 'RENEW_MQTT_CLIENT_CERTIFICATE_SUCCESS',
    DeleteMqttClientCertificateSuccess = 'DELETE_MQTT_CLIENT_CERTIFICATE_SUCCESS',
}

export interface FetchMqttClients extends BusinessActionRequestType {
    type: MqttActionType.FetchMqttClientsInit;
}
export const fetchMqttClients = (): FetchMqttClients => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchMqttClients,
    type: MqttActionType.FetchMqttClientsInit,
});

export interface FetchMqttClientsSuccess {
    type: typeof MqttActionType.FetchMqttClientsSuccess;
    clients: MqttClient[];
}
export const fetchMqttClientsSuccess = (clients: MqttClient[]): FetchMqttClientsSuccess => ({
    type: MqttActionType.FetchMqttClientsSuccess,
    clients,
});

export interface AddMqttClient extends BusinessActionRequestType {
    type: MqttActionType.AddMqttClientInit;
    client: NewMqttClient;
}
export const addMqttClient = (client: NewMqttClient): AddMqttClient => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.AddMqttClient,
    type: MqttActionType.AddMqttClientInit,
    client,
});

export interface AddMqttClientSuccess {
    type: typeof MqttActionType.AddMqttClientSuccess;
    client: MqttClient;
}
export const addMqttClientSuccess = (client: MqttClient): AddMqttClientSuccess => ({
    type: MqttActionType.AddMqttClientSuccess,
    client,
});

export interface UpdateMqttClient extends BusinessActionRequestType {
    type: MqttActionType.UpdateMqttClientInit;
    client: EditMqttClient;
}
export const updateMqttClient = (client: EditMqttClient): UpdateMqttClient => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdateMqttClient,
    type: MqttActionType.UpdateMqttClientInit,
    client,
});

export interface UpdateMqttClientSuccess {
    type: typeof MqttActionType.UpdateMqttClientSuccess;
    client: MqttClient;
}
export const updateMqttClientSuccess = (client: MqttClient): UpdateMqttClientSuccess => ({
    type: MqttActionType.UpdateMqttClientSuccess,
    client,
});

export interface UpdateMqttClientActiveState extends BusinessActionRequestType {
    type: MqttActionType.UpdateMqttClientActiveStateInit;
    client: MqttClient;
    active: boolean;
}
export const updateMqttClientActiveState = (client: MqttClient, active: boolean): UpdateMqttClientActiveState => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdateMqttClientActiveState,
    type: MqttActionType.UpdateMqttClientActiveStateInit,
    client,
    active,
});

export interface UpdateMqttClientActiveStateSuccess {
    type: typeof MqttActionType.UpdateMqttClientActiveStateSuccess;
    client: MqttClient;
}
export const updateMqttClientActiveStateSuccess = (client: MqttClient): UpdateMqttClientActiveStateSuccess => ({
    type: MqttActionType.UpdateMqttClientActiveStateSuccess,
    client,
});

export interface DeleteMqttClient extends BusinessActionRequestType {
    type: MqttActionType.DeleteMqttClientInit;
    clientId: string;
}
export const deleteMqttClient = (clientId: string): DeleteMqttClient => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.DeleteMqttClient,
    type: MqttActionType.DeleteMqttClientInit,
    clientId,
});

export interface DeleteMqttClientSuccess {
    type: typeof MqttActionType.DeleteMqttClientSuccess;
    clientId: string;
}
export const deleteMqttClientSuccess = (clientId: string): DeleteMqttClientSuccess => ({
    type: MqttActionType.DeleteMqttClientSuccess,
    clientId,
});

export interface FetchMqttClientCertificate extends BusinessActionRequestType {
    type: MqttActionType.FetchMqttClientCertificateInit;
    clientId: string;
}
export const fetchMqttClientCertificate = (clientId: string): FetchMqttClientCertificate => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchMqttClientCertificate,
    type: MqttActionType.FetchMqttClientCertificateInit,
    clientId,
});

export interface FetchMqttClientCertificateSuccess {
    type: typeof MqttActionType.FetchMqttClientCertificateSuccess;
    certificate: MqttClientCertificate;
}
export const fetchMqttClientCertificateSuccess = (
    certificate: MqttClientCertificate
): FetchMqttClientCertificateSuccess => ({
    type: MqttActionType.FetchMqttClientCertificateSuccess,
    certificate,
});

export interface UploadMqttClientCertificate extends BusinessActionRequestType {
    type: MqttActionType.UploadMqttClientCertificateInit;
    clientId: string;
    certificate: string;
}
export const uploadMqttClientCertificate = (clientId: string, certificate: string): UploadMqttClientCertificate => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UploadMqttClientCertificate,
    type: MqttActionType.UploadMqttClientCertificateInit,
    clientId,
    certificate,
});

export interface UploadMqttClientCertificateSuccess {
    type: typeof MqttActionType.UploadMqttClientCertificateSuccess;
    certificate: MqttClientCertificate;
}
export const uploadMqttClientCertificateSuccess = (
    certificate: MqttClientCertificate
): UploadMqttClientCertificateSuccess => ({
    type: MqttActionType.UploadMqttClientCertificateSuccess,
    certificate,
});

export interface RenewMqttClientCertificate extends BusinessActionRequestType {
    type: MqttActionType.RenewMqttClientCertificateInit;
    clientId: string;
    certificate?: string;
}
export const renewMqttClientCertificate = (clientId: string, certificate?: string): RenewMqttClientCertificate => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.RenewMqttClientCertificate,
    type: MqttActionType.RenewMqttClientCertificateInit,
    clientId,
    certificate,
});

export interface RenewMqttClientCertificateSuccess {
    type: typeof MqttActionType.RenewMqttClientCertificateSuccess;
    certificate: MqttClientCertificate;
}
export const renewMqttClientCertificateSuccess = (
    certificate: MqttClientCertificate
): RenewMqttClientCertificateSuccess => ({
    type: MqttActionType.RenewMqttClientCertificateSuccess,
    certificate,
});

export interface DeleteMqttClientCertificate extends BusinessActionRequestType {
    type: MqttActionType.DeleteMqttClientCertificateInit;
    clientId: string;
}
export const deleteMqttClientCertificate = (clientId: string): DeleteMqttClientCertificate => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.DeleteMqttClientCertificate,
    type: MqttActionType.DeleteMqttClientCertificateInit,
    clientId,
});

export interface DeleteMqttClientCertificateSuccess {
    type: typeof MqttActionType.DeleteMqttClientCertificateSuccess;
}
export const deleteMqttClientCertificateSuccess = (): DeleteMqttClientCertificateSuccess => ({
    type: MqttActionType.DeleteMqttClientCertificateSuccess,
});

export type MqttActions =
    | FetchMqttClients
    | FetchMqttClientsSuccess
    | AddMqttClient
    | AddMqttClientSuccess
    | UpdateMqttClient
    | UpdateMqttClientSuccess
    | UpdateMqttClientActiveState
    | UpdateMqttClientActiveStateSuccess
    | DeleteMqttClient
    | DeleteMqttClientSuccess
    | FetchMqttClientCertificate
    | FetchMqttClientCertificateSuccess
    | UploadMqttClientCertificate
    | UploadMqttClientCertificateSuccess
    | RenewMqttClientCertificate
    | RenewMqttClientCertificateSuccess
    | DeleteMqttClientCertificate
    | DeleteMqttClientCertificateSuccess;
