import { RequestActionType } from 'commons/src/actions/requestActions';
import { ErrorType, SegmentProperties } from 'commons/src/models/commonTypeScript';
import { KeyValuePairType, BusinessActionRequestType } from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum SegmentPropertiesActionType {
    FetchSegmentPropertiesInit = 'FETCH_SEGMENT_PROPERTIES_INIT',
    FetchSegmentPropertiesSuccess = 'FETCH_SEGMENT_PROPERTIES_SUCCESS',
    FetchSegmentPropertiesError = 'FETCH_SEGMENT_PROPERTIES_ERROR',
    UpdateSegmentPropertiesSuccess = 'UPDATE_SEGMENT_PROPERTIES_SUCCESS',
    UpdateSegmentPropertiesError = 'UPDATE_SEGMENT_PROPERTIES_ERROR',
    UpdateSegmentPropertiesInit = 'UPDATE_SEGMENT_PROPERTIES/INIT',
    FetchSegmentLabelsInit = 'FETCH_SEGMENT_LABELS/INIT',
    UpdateSegmentLabelsInit = 'UPDATE_SEGMENT_LABELS/INIT',
    UpdateSegmentLabelsSuccess = 'UPDATE_SEGMENT_LABELS_SUCCESS',
    SetDevicePubliclyAvailableInit = 'SET_DEVICE_PUBLICLY_AVAILABLE/INIT',
    SetDevicePubliclyAvailableSuccess = 'SET_DEVICE_PUBLICLY_AVAILABLE_SUCCESS',
    FetchGeneratedSegmentPublicUrlInit = 'FETCH_GENERATED_SEGMENT_PUBLIC_URL/INIT',
    FetchGeneratedSegmentPublicUrlSuccess = 'FETCH_GENERATED_SEGMENT_PUBLIC_URL_SUCCESS',
    FetchGeneratedSegmentPublicUrlError = 'FETCH_GENERATED_SEGMENT_PUBLIC_URL_ERROR',
    FetchSegmentPropertyFiltersInit = 'FETCH_SEGMENT_PROPERTY_FILTERS/INIT',
    FetchSegmentPropertyFiltersSuccess = 'FETCH_SEGMENT_PROPERTY_FILTERS_SUCCESS',
}

export interface FetchSegmentProperties {
    type: SegmentPropertiesActionType.FetchSegmentPropertiesInit;
    requestType: RequestType.FetchSegmentProperties;
    requestActionType: RequestActionType.Init;
    serialNumber: string;
}
export const fetchSegmentProperties = (serialNumber: string): FetchSegmentProperties => ({
    type: SegmentPropertiesActionType.FetchSegmentPropertiesInit,
    requestType: RequestType.FetchSegmentProperties,
    requestActionType: RequestActionType.Init,
    serialNumber,
});

export interface FetchSegmentPropertiesSuccess {
    type: SegmentPropertiesActionType.FetchSegmentPropertiesSuccess;
    requestType: RequestType.FetchSegmentProperties;
    requestActionType: RequestActionType.Success;
    segmentProperties: SegmentProperties;
    serialNumber: string;
}
export const fetchSegmentPropertiesSuccess = (
    segmentProperties: SegmentProperties,
    serialNumber: string
): FetchSegmentPropertiesSuccess => ({
    type: SegmentPropertiesActionType.FetchSegmentPropertiesSuccess,
    requestType: RequestType.FetchSegmentProperties,
    requestActionType: RequestActionType.Success,
    segmentProperties,
    serialNumber,
});

export interface FetchSegmentPropertiesError {
    type: SegmentPropertiesActionType.FetchSegmentPropertiesError;
    requestType: RequestType.FetchSegmentProperties;
    requestActionType: RequestActionType.Error;
    error: ErrorType;
}
export const fetchSegmentPropertiesError = (error: ErrorType): FetchSegmentPropertiesError => ({
    type: SegmentPropertiesActionType.FetchSegmentPropertiesError,
    requestType: RequestType.FetchSegmentProperties,
    requestActionType: RequestActionType.Error,
    error,
});

export interface UpdateSegmentPropertiesSuccess {
    type: SegmentPropertiesActionType.UpdateSegmentPropertiesSuccess;
    segmentProperties: SegmentProperties;
    serialNumber: string;
}
export const updateSegmentPropertiesSuccess = (
    segmentProperties: SegmentProperties,
    serialNumber: string
): UpdateSegmentPropertiesSuccess => ({
    type: SegmentPropertiesActionType.UpdateSegmentPropertiesSuccess,
    segmentProperties,
    serialNumber,
});

export interface UpdateSegmentPropertiesError {
    type: SegmentPropertiesActionType.UpdateSegmentPropertiesError;
    error: ErrorType;
}
export const updateSegmentPropertiesError = (error: ErrorType): UpdateSegmentPropertiesError => ({
    type: SegmentPropertiesActionType.UpdateSegmentPropertiesError,
    error,
});

export interface SetSegmentProperties extends BusinessActionRequestType {
    type: SegmentPropertiesActionType.UpdateSegmentPropertiesInit;
    segmentProperties: SegmentProperties;
    serialNumber: string;
}
export const setSegmentProperties = (
    segmentProperties: SegmentProperties,
    serialNumber: string
): SetSegmentProperties => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.UpdateSegmentProperties,
    type: SegmentPropertiesActionType.UpdateSegmentPropertiesInit,
    segmentProperties,
    serialNumber,
});

export interface SetDevicePubliclyAvailableSuccess {
    type: SegmentPropertiesActionType.SetDevicePubliclyAvailableSuccess;
    publiclyAvailable: boolean;
    serialNumber: string;
}
export const setDevicePubliclyAvailableSuccess = (
    publiclyAvailable: boolean,
    serialNumber: string
): SetDevicePubliclyAvailableSuccess => ({
    type: SegmentPropertiesActionType.SetDevicePubliclyAvailableSuccess,
    publiclyAvailable,
    serialNumber,
});

export interface SetDevicePubliclyAvailable extends BusinessActionRequestType {
    type: SegmentPropertiesActionType.SetDevicePubliclyAvailableInit;
    publiclyAvailable: boolean;
    serialNumber: string;
    hasPublicUrl: boolean;
}
export const setDevicePubliclyAvailable = (
    publiclyAvailable: boolean,
    serialNumber: string,
    hasPublicUrl: boolean
): SetDevicePubliclyAvailable => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.SetDevicePubliclyAvailable,
    type: SegmentPropertiesActionType.SetDevicePubliclyAvailableInit,
    publiclyAvailable,
    serialNumber,
    hasPublicUrl,
});

export interface FetchSegmentLabels extends BusinessActionRequestType {
    type: SegmentPropertiesActionType.FetchSegmentLabelsInit;
    serialNumber: string;
}
export const fetchSegmentLabels = (serialNumber: string): FetchSegmentLabels => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.FetchSegmentLabels,
    type: SegmentPropertiesActionType.FetchSegmentLabelsInit,
    serialNumber,
});

export const FETCH_SEGMENT_LABELS_SUCCESS = 'FETCH_SEGMENT_LABELS_SUCCESS';
export interface FetchSegmentLabelsSuccess {
    type: typeof FETCH_SEGMENT_LABELS_SUCCESS;
    serialNumber: string;
    segmentLabels: KeyValuePairType[];
}
export const fetchSegmentLabelsSuccess = (
    serialNumber: string,
    segmentLabels: KeyValuePairType[]
): FetchSegmentLabelsSuccess => ({
    type: FETCH_SEGMENT_LABELS_SUCCESS,
    serialNumber,
    segmentLabels,
});

export interface SetSegmentLabels extends BusinessActionRequestType {
    type: SegmentPropertiesActionType.UpdateSegmentLabelsInit;
    segmentLabels: KeyValuePairType[];
    serialNumber: string;
}
export const setSegmentLabels = (serialNumber: string, segmentLabels: KeyValuePairType[]): SetSegmentLabels => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.UpdateSegmentLabels,
    type: SegmentPropertiesActionType.UpdateSegmentLabelsInit,
    segmentLabels,
    serialNumber,
});

export interface UpdateSegmentLabelsSuccess {
    type: SegmentPropertiesActionType.UpdateSegmentLabelsSuccess;
    segmentLabels: KeyValuePairType[];
    serialNumber: string;
}
export const updateSegmentLabelsSuccess = (
    segmentLabels: KeyValuePairType[],
    serialNumber: string
): UpdateSegmentLabelsSuccess => ({
    type: SegmentPropertiesActionType.UpdateSegmentLabelsSuccess,
    segmentLabels,
    serialNumber,
});

export interface FetchGeneratedSegmentPublicUrlInit extends BusinessActionRequestType {
    type: SegmentPropertiesActionType.FetchGeneratedSegmentPublicUrlInit;
    payload: { serialNumber: string };
}
export const fetchGeneratedSegmentPublicUrlInit = (payload: {
    serialNumber: string;
}): FetchGeneratedSegmentPublicUrlInit => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.FetchGeneratedSegmentPublicUrl,
    type: SegmentPropertiesActionType.FetchGeneratedSegmentPublicUrlInit,
    payload,
});

export interface FetchGeneratedSegmentPublicUrlSuccess extends BusinessActionRequestType {
    type: SegmentPropertiesActionType.FetchGeneratedSegmentPublicUrlSuccess;
    payload: { serialNumber: string; publicUrlPath: string };
}
export const fetchGeneratedSegmentPublicUrlSuccess = (payload: {
    serialNumber: string;
    publicUrlPath: string;
}): FetchGeneratedSegmentPublicUrlSuccess => ({
    requestActionType: RequestActionType.Success,
    requestType: RequestType.FetchGeneratedSegmentPublicUrl,
    type: SegmentPropertiesActionType.FetchGeneratedSegmentPublicUrlSuccess,
    payload,
});

export interface FetchGeneratedSegmentPublicUrlError extends BusinessActionRequestType {
    type: SegmentPropertiesActionType.FetchGeneratedSegmentPublicUrlError;
    error: ErrorType;
}
export const fetchGeneratedSegmentPublicUrlError = (error: ErrorType): FetchGeneratedSegmentPublicUrlError => ({
    requestActionType: RequestActionType.Error,
    requestType: RequestType.FetchGeneratedSegmentPublicUrl,
    type: SegmentPropertiesActionType.FetchGeneratedSegmentPublicUrlError,
    error,
});

export interface FetchSegmentPropsFilters extends BusinessActionRequestType {
    type: SegmentPropertiesActionType.FetchSegmentPropertyFiltersInit;
    locationId: string;
}

export const fetchSegmentPropsFilters = (locationId: string): FetchSegmentPropsFilters => ({
    type: SegmentPropertiesActionType.FetchSegmentPropertyFiltersInit,
    requestActionType: RequestActionType.Init,
    requestType: RequestType.FetchSegmentPropertyFilters,
    locationId,
});

export interface FetchSegmentPropsFiltersSuccess {
    type: SegmentPropertiesActionType.FetchSegmentPropertyFiltersSuccess;
    payload: { [filterType: string]: { name: string; devices: string[] }[] };
    locationId: string;
}

export const fetchSegmentPropsFiltersSuccess = (
    locationId: string,
    payload: {
        [filterType: string]: { name: string; devices: string[] }[];
    }
): FetchSegmentPropsFiltersSuccess => ({
    type: SegmentPropertiesActionType.FetchSegmentPropertyFiltersSuccess,
    payload,
    locationId,
});

export type SegmentPropertiesActions =
    | FetchSegmentPropertiesSuccess
    | UpdateSegmentPropertiesSuccess
    | FetchSegmentLabelsSuccess
    | UpdateSegmentLabelsSuccess
    | FetchGeneratedSegmentPublicUrlInit
    | FetchGeneratedSegmentPublicUrlSuccess
    | FetchGeneratedSegmentPublicUrlError
    | FetchSegmentPropsFiltersSuccess;

export type SegmentPropertiesDevicesReducerActions =
    | SetDevicePubliclyAvailableSuccess
    | FetchGeneratedSegmentPublicUrlSuccess;
