import React, { useState, SyntheticEvent } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { GOOGLE_MAP_API_KEY } from '../../constants';
import { LocationAddressType } from '../../models/commonTypeScript';
import Input from '../input/Input';

type Props = {
    address: string;
    onPlaceChanged: (address: LocationAddressType) => void;
    displayValidation: boolean;
    mandatory?: boolean;
    isValid: boolean;
    infoText?: string;
    addressHint?: string;
    infoTextTranslated?: boolean;
};

/* eslint-disable camelcase */
type AddressElementType = {
    long_name: string;
    short_name: string;
    types: string[];
};

const SearchAddress = (props: Props): React.ReactElement => {
    const {
        onPlaceChanged,
        address,
        displayValidation,
        isValid,
        infoText,
        addressHint = 'GoogleAddressHint',
        infoTextTranslated = false,
        mandatory,
    } = props;

    const { t: txt } = useTranslation();
    const [addressInField, setAddressInField] = useState(address);

    /**
     * Callback fixes this error: https://github.com/ErrorPro/react-google-autocomplete/issues/200
     * Unresolved as of 2023-02-07.
     * This component does not support passing the callback any other way.
     */
    const { ref } = usePlacesWidget({
        apiKey: `${GOOGLE_MAP_API_KEY}&callback=Function.prototype`,
        options: { types: ['geocode'] },
        onPlaceSelected: place => {
            const newAddress = place.formatted_address;
            const countryObject = place?.address_components?.find(
                (addressElement: AddressElementType) => addressElement.types[0] === 'country'
            );

            const coords = place.geometry.location;
            onPlaceChanged({
                coords: { lat: coords.lat(), lng: coords.lng() },
                address: newAddress,
                countryCode: countryObject && countryObject.short_name,
            });
            setAddressInField(newAddress);
        },
    });

    const inputChange = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        setAddressInField(value);
    };

    return (
        <div>
            <Input
                infoText={infoText}
                infoTextTranslated={infoTextTranslated}
                label="Address"
                type="text"
                id="searchAddress"
                validate={displayValidation && !isValid}
                isValid={isValid}
                currentValue={addressInField}
                placeholder={txt('Search')}
                hint={addressHint}
                markedMandatory={mandatory}
                onChange={inputChange}
                onBlur={(): void => setAddressInField(address)}
                innerRef={ref}
            />
        </div>
    );
};

export default SearchAddress;
