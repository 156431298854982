import { SubscriptionPlan, SelectedGroup } from '../models/commonTypeScript';
import { CommonActionRequestType, CommonRequestType } from '../reducers/requestReducer';
import { RequestActionType } from './requestActions';

export enum SelectGroupActionType {
    SelectGroupInit = 'SELECT_GROUP/INIT',
    SelectGroupSuccess = 'SELECT_GROUP_SUCCESS',
    GetSubscriptionForGroupInit = 'GET_SUBSCRIPTION_FOR_GROUP/INIT',
    GetSubscriptionForGroupSuccess = 'GET_SUBSCRIPTION_FOR_GROUP_SUCCESS',
}

export interface SelectGroup extends SelectedGroup {
    type: typeof SelectGroupActionType.SelectGroupInit;
}

export const selectGroup = (selectedGroup: SelectedGroup): SelectGroup => ({
    type: SelectGroupActionType.SelectGroupInit,
    ...selectedGroup,
});

export interface SelectGroupSuccess {
    type: SelectGroupActionType.SelectGroupSuccess;
}

export const selectGroupSuccess = (): SelectGroupSuccess => ({
    type: SelectGroupActionType.SelectGroupSuccess,
});

export interface GetSubscriptionForGroup extends CommonActionRequestType {
    type: SelectGroupActionType.GetSubscriptionForGroupInit;
}

export const getSubscriptionForGroup = (): GetSubscriptionForGroup => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.GetSubscriptionForGroup,
    type: SelectGroupActionType.GetSubscriptionForGroupInit,
});

export interface GetSubscriptionForGroupSuccess {
    type: SelectGroupActionType.GetSubscriptionForGroupSuccess;
    subscription: SubscriptionPlan;
}

export const getSubscriptionForGroupSuccess = (subscription: SubscriptionPlan): GetSubscriptionForGroupSuccess => ({
    type: SelectGroupActionType.GetSubscriptionForGroupSuccess,
    subscription,
});

export type SelectGroupReducerActions = SelectGroup | SelectGroupSuccess | GetSubscriptionForGroupSuccess;
