import React, { ReactElement, useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import ReactPlaceholder from 'react-placeholder';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_VIEWED_SPACES } from 'commons/src/analytics/AnalyticsEvents';
import { isVirtualDevice } from 'commons/src/commonFunctions';
import FlipButton from 'commons/src/components/buttons/FlipButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ModalWrapper from 'commons/src/components/modals/ModalWrapper';
import { fullWidthListPlaceholder } from 'commons/src/components/placeholder';
import { roleRestrictions } from 'commons/src/constants';
import EndedMeasurementsTile from 'commons/src/features/devices/EndedMeasurements/EndedMeasurementsTile';
import { FeatureToggleEnum, PlacementType } from 'commons/src/models/commonEnums';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';
import { getSpaceFloorPlans } from '../../../actions/floorPlanSpaceActions';
import { fetchSpaces } from '../../../actions/spaceActions';
import { IndoorSpace, OutdoorSpace } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import SpaceFloorPlanView from '../../spaceFloorPlan/SpaceFloorPlanView';
import SpaceAddModal from '../addEdit/SpaceAddModal';
import { spacesSelector } from '../space/spaceSelectors';
import EmptyBuilding from './EmptyBuilding';
import EmptySpacesAndFloors from './EmptySpacesAndFloors';
import styles from './SpacesLandingPage.module.scss';
import SpacesOutdoorElement from './SpacesOutdoorElement';
import SpacesView from './SpacesView';

const SpacesLandingPage = (): ReactElement => {
    const { buildingId = '' } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const userRole = useSelector((store: Store) => store.userSettings.selectedGroup?.role);
    const [addSpaceOpen, setAddSpaceOpen] = useState(false);
    const [showSpacesView, setShowSpacesView] = useState<boolean>(true);
    const [floorPlanFullScreenMode, setFloorPlanFullScreenMode] = useState<boolean>(false);

    const { dateFormat, featureToggles } = useSelector((store: Store) => store.userSettings);
    const endedMeasurementsToggleOn = useSelector((store: Store) => store.toggles.endedMeasurementsToggleOn);
    const showFloorPlansToggleOn: boolean = featureToggles.includes(FeatureToggleEnum.enableFloorPlans);

    const {
        spaces: { spaces, availableSensors },
        request: spaceRequest,
    } = useSelector((store: Store) => spacesSelector(store, buildingId));

    const { floorPlans } = useSelector((state: Store) => state.floorPlansSpace);
    const { loading: floorPlansLoading } = useSelector((state: Store) => state.requests.GET_SPACE_FLOOR_PLANS);

    const allEndedMeasurements = useSelector((store: Store) => store.devices.endedMeasurements);
    const endedMeasurements = endedMeasurementsToggleOn
        ? allEndedMeasurements.filter(
              measurement =>
                  measurement.locationId === buildingId && !isVirtualDevice(measurement.deviceType as AnyDeviceType)
          )
        : [];

    useEffect(() => {
        const viewQuery: string | null = searchParams.get('view');
        if (showFloorPlansToggleOn && viewQuery === 'floorplans') {
            setShowSpacesView(false);
        }
    }, [showFloorPlansToggleOn]);

    const [selectedFloorPlanId, setSelectedFloorPlanId] = useState<string | undefined>(undefined);

    const spacePropsLoading = useSelector((store: Store) => Object.keys(store.config.spacePropDefs).length === 0);
    const spacesByPlacement = groupBy(spaces, 'placement');
    const outdoorSpaces: OutdoorSpace[] = spacesByPlacement[PlacementType.OUTDOOR] as OutdoorSpace[];
    const roomSpaces: IndoorSpace[] = (spacesByPlacement[PlacementType.ROOM] || []) as IndoorSpace[];

    const isLoading = (spaceRequest.loading && spaces.length === 0) || floorPlansLoading;

    useEffect(() => {
        dispatch(fetchSpaces(buildingId));
        dispatch(getSpaceFloorPlans(buildingId));
        analyticsLogger(BUILDING_VIEWED_SPACES, { buildingId });
    }, [buildingId]);

    const redirectToFloorPlans = (): void => {
        navigate(`/buildings/${buildingId}/insights?subPage=floorPlan`);
    };

    const onChangeView = (): void => {
        searchParams.set('view', !showSpacesView ? 'spaces' : 'floorplans');
        setSearchParams(searchParams);
        setShowSpacesView(!showSpacesView);
    };

    const spacesView = (): ReactElement => {
        if (roomSpaces.length === 0) {
            return (
                <EmptyBuilding
                    userRole={userRole}
                    requiredRoleLevel={roleRestrictions.editDeviceOrBuilding}
                    onClick={(): void => setAddSpaceOpen(true)}
                />
            );
        }
        return (
            <>
                <SpacesView
                    spaces={roomSpaces}
                    addSpace={(): void => setAddSpaceOpen(true)}
                    availableSensors={availableSensors}
                    buildingId={buildingId}
                />
                {endedMeasurementsToggleOn && (
                    <EndedMeasurementsTile
                        listElements={endedMeasurements}
                        showLocation
                        dateFormat={dateFormat}
                        headerText="EndedMeasurements"
                        sortElement="segmentEnd"
                        sortByDate
                    />
                )}
            </>
        );
    };

    const renderFloorPlanView = (): ReactElement => {
        return floorPlanFullScreenMode ? (
            <ModalWrapper
                isOpen
                onClose={(): void => setFloorPlanFullScreenMode(false)}
                size="fullscreen"
                customHeader={<div />}
                isFullscreen
            >
                <SpaceFloorPlanView
                    locationId={buildingId}
                    toggleFullscreenMode={(): void => setFloorPlanFullScreenMode(false)}
                    fullscreenMode
                    selectedFloorPlanId={selectedFloorPlanId}
                    setSelectedFloorPlanId={setSelectedFloorPlanId}
                />
            </ModalWrapper>
        ) : (
            <SpaceFloorPlanView
                locationId={buildingId}
                toggleFullscreenMode={(): void => setFloorPlanFullScreenMode(!floorPlanFullScreenMode)}
                fullscreenMode={floorPlanFullScreenMode}
                selectedFloorPlanId={selectedFloorPlanId}
                setSelectedFloorPlanId={setSelectedFloorPlanId}
            />
        );
    };

    return (
        <ReactPlaceholder ready={!isLoading && !spacePropsLoading} customPlaceholder={fullWidthListPlaceholder}>
            <div className="container" data-building-no-spaces-page>
                <div className={styles.linkToFloorPlan}>
                    <SpacesOutdoorElement space={outdoorSpaces ? outdoorSpaces[0] : undefined} />
                    {!showFloorPlansToggleOn && (
                        <PrimaryButton
                            color="secondary"
                            onClick={redirectToFloorPlans}
                            title="Space.GoToFloorPlan"
                            trailingIcon={<MaterialIcon name="map" />}
                        />
                    )}
                </div>
                <div className="page-section--full-width">
                    {roomSpaces.length === 0 && floorPlans.length === 0 ? (
                        <EmptySpacesAndFloors
                            userRole={userRole}
                            requiredRoleLevel={roleRestrictions.editDeviceOrBuilding}
                            onClick={(): void => setAddSpaceOpen(true)}
                            locationId={buildingId}
                        />
                    ) : (
                        <>
                            {showFloorPlansToggleOn && (
                                <div className={styles.flipButton}>
                                    <FlipButton
                                        id="spaces-view"
                                        onClick={onChangeView}
                                        leftSelected={showSpacesView}
                                        leftText="Spaces.ListView"
                                        rightText="Spaces.FloorPlan"
                                        leftIcon={<MaterialIcon name="list" />}
                                        rightIcon={<MaterialIcon name="map" />}
                                        testIdOff="toggle-floor-plans-view"
                                        testIdOn="toggle-spaces-view"
                                    />
                                </div>
                            )}
                            {showSpacesView ? spacesView() : renderFloorPlanView()}
                        </>
                    )}
                </div>
                {buildingId && (
                    <SpaceAddModal
                        isOpen={addSpaceOpen}
                        onClose={(): void => setAddSpaceOpen(false)}
                        locationId={buildingId}
                    />
                )}
            </div>
        </ReactPlaceholder>
    );
};

export default SpacesLandingPage;
