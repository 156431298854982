import { ThresholdBreachActions, ThresholdBreachInsight } from '../actions/thresholdBreachInsightActions';
import { BuildingInsight } from '../models/buildingModels';
import { SensorBreachThresholds } from '../models/common';

export type ThresholdBreachInsightState = {
    thresholdBreachInsights: BuildingInsight[];
    thresholdOptions?: SensorBreachThresholds;
    locationId?: string;
};

const initialState: ThresholdBreachInsightState = {
    thresholdBreachInsights: [],
    locationId: undefined,
    thresholdOptions: undefined,
};

export default (
    state: ThresholdBreachInsightState = initialState,
    action: ThresholdBreachActions
): ThresholdBreachInsightState => {
    switch (action.type) {
        case ThresholdBreachInsight.FetchThresholdBreachAllInsightsInit:
            return {
                ...state,
                thresholdBreachInsights: [],
                locationId: action.payload.locationId,
            };
        case ThresholdBreachInsight.FetchThresholdBreachAllInsightsSuccess:
            return {
                ...state,
                thresholdBreachInsights: action.payload.charts,
                thresholdOptions: action.payload.thresholds,
                locationId: action.locationId,
            };
        case ThresholdBreachInsight.FetchThresholdBreachSingleInsightSuccess:
            return {
                ...state,
                thresholdBreachInsights: state.thresholdBreachInsights.map(insight => {
                    if (insight.id === action.chartId) {
                        return action.payload;
                    }
                    return insight;
                }),
            };
        case ThresholdBreachInsight.UpdateThresholdBreachConfigSuccess:
            return {
                ...state,
                thresholdBreachInsights: state.thresholdBreachInsights.map(insight => {
                    if (insight.id === action.chartId) {
                        return {
                            ...insight,
                            thresholds: action.thresholds,
                        };
                    }
                    return insight;
                }),
            };
        case ThresholdBreachInsight.DeleteThresholdBreachInsightSuccess:
            return {
                ...state,
                thresholdBreachInsights: state.thresholdBreachInsights.filter(insight => insight.id !== action.chartId),
            };
        case ThresholdBreachInsight.CreateThresholdBreachInsightSuccess:
            return {
                ...state,
                thresholdBreachInsights: [...state.thresholdBreachInsights, action.data],
            };
        default:
            return state;
    }
};
