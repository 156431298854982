import {
    UnsubscribeAlertEmailActionType,
    UnsubscribeAlertEmailReducerActions,
} from '../actions/UnsubscribeAlertEmailActions';
import { ErrorType } from '../models/commonTypeScript';

export interface UnsubscribeEmailAlertStore {
    loading: boolean;
    error: ErrorType | undefined;
}

const initialState: UnsubscribeEmailAlertStore = {
    loading: false,
    error: undefined,
};

const reducer = (
    store: UnsubscribeEmailAlertStore = initialState,
    action: UnsubscribeAlertEmailReducerActions
): UnsubscribeEmailAlertStore => {
    switch (action.type) {
        case UnsubscribeAlertEmailActionType.UnsubscribeFromEmailAlertInit:
            return {
                ...store,
                loading: true,
                error: undefined,
            };
        case UnsubscribeAlertEmailActionType.UnsubscribeFromEmailAlertSuccess:
            return {
                ...store,
                loading: false,
                error: undefined,
            };
        case UnsubscribeAlertEmailActionType.UnsubscribeFromEmailAlertError:
            return {
                ...store,
                loading: false,
                error: action.error,
            };
        default:
            return store;
    }
};

export default reducer;
