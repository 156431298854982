import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Dispatch } from 'redux';
import PageHeader from 'commons/src/components/headers/PageHeader';
import { roleRestrictions } from 'commons/src/constants';
import { TabOption } from 'commons/src/models/menuModels';
import { FetchThirdPartyIntegrations, fetchThirdPartyIntegrations } from '../../actions/thirdPartyIntegrationActions';
import { paths } from '../../constants';
import { Store } from '../../reducers';

type StateProps = {
    loadingThirdPartyIntegrations: boolean;
};

type ActionProps = {
    getThirdPartyIntegrations: () => void;
};

export type Props = StateProps & ActionProps;

export const AlertsPageLayoutComponent = (props: Props): React.ReactElement => {
    const { getThirdPartyIntegrations, loadingThirdPartyIntegrations } = props;
    const { t: txt } = useTranslation();

    useEffect((): void => {
        if (!loadingThirdPartyIntegrations) {
            getThirdPartyIntegrations();
        }
    }, []);

    const tabs: TabOption[] = [
        {
            text: 'NotificationAlerts.MyAlerts',
            id: 'myAlerts',
            testAttr: 'my-alerts',
            path: `/${paths.alerts}`,
            requiredRoleLevel: roleRestrictions.alerts,
            route: paths.alerts,
            requiredGroupTypes: [],
        },
        {
            text: 'NotificationAlerts.History',
            path: `/${paths.history}`,
            id: 'alertHistory',
            testAttr: 'alert-history',
            requiredRoleLevel: roleRestrictions.alerts,
            route: paths.history,
            requiredGroupTypes: [],
        },
    ];

    return (
        <>
            <PageHeader title={txt('NotificationAlerts.Alerts')} tabs={tabs} />
            <div className="page-wrapper__medium">
                <Outlet />
            </div>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        requests: {
            FETCH_THIRD_PARTY_INTEGRATIONS: { loading: loadingThirdPartyIntegrations },
        },
    } = state;
    return {
        loadingThirdPartyIntegrations,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    getThirdPartyIntegrations: (): FetchThirdPartyIntegrations => dispatch(fetchThirdPartyIntegrations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsPageLayoutComponent);
