import { t as txt } from 'i18next';

const convertRowTypeToText = (row: unknown): string => {
    switch (typeof row) {
        case 'object': {
            return JSON.stringify(row, null, 2);
        }
        case 'boolean': {
            return row ? txt('Commons.True') : txt('Commons.False');
        }
        default:
            return row as string;
    }
};

export default convertRowTypeToText;
