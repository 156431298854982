import { takeEvery, put, call } from 'redux-saga/effects';
import {
    changePasswordSuccess,
    changePasswordError,
    CHANGE_PASSWORD,
    ChangePassword,
} from '../../actions/SettingsActions';
import { changePassword } from '../../api/accountsApiUser';
import displayAlertBoxSaga from '../displayAlertBox';

export function* changePasswordSaga({ payload }: ChangePassword): Generator {
    try {
        yield call(changePassword, payload);
        yield put(changePasswordSuccess());
        yield call(displayAlertBoxSaga, 'PasswordChangedSuccessfully', false, true);
    } catch (error) {
        yield put(changePasswordError(error));
    }
}

export default function* ChangePasswordSagas(): Generator {
    yield takeEvery(CHANGE_PASSWORD, changePasswordSaga);
}
