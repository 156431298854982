import React, { useState } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { sensorUnits, dateFormats } from 'commons/src/constants';
import { DayUsageHours } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';
import generatingPlotBands from '../../reports/insightFunctions';
import SensorAndOutdoorGraphConfig, { DoubleGraphConfig } from './SensorAndOutdoorGraphConfig';

type ParentProps = {
    sensor: string;
    indoorData: number[][] | undefined;
    outdoorData: number[][];
    unit: keyof typeof sensorUnits;
    usageHours: { [day: string]: DayUsageHours };
    fromDate: Moment;
    toDate?: Moment;
    timeZone: string;
};
type StateProps = {
    dateFormat: keyof typeof dateFormats;
};

type Props = StateProps & ParentProps;

export const OutdoorSensorGraphComponent = ({
    sensor,
    dateFormat,
    indoorData,
    outdoorData,
    unit,
    usageHours,
    fromDate,
    toDate,
    timeZone,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const generatePlotBands = (): { color: string; from: number; to: number }[] => {
        return generatingPlotBands(usageHours, fromDate, timeZone);
    };

    const createConfig = (): DoubleGraphConfig =>
        SensorAndOutdoorGraphConfig(
            outdoorData,
            indoorData,
            { from: fromDate, to: toDate },
            txt(`OutdoorInsight.Outdoor${sensor}GraphName`),
            indoorData && indoorData.length > 0
                ? txt(`OutdoorInsight.Indoor${sensor}GraphName`)
                : txt('NotAvailableAbbreviation'),
            txt('OutdoorInsight.Indoor').toLowerCase(),
            txt('OutdoorInsight.Outdoor').toLowerCase(),
            dateFormat,
            sensorUnits[unit],
            generatePlotBands(),
            sensor
        );

    const [graphConfig] = useState(createConfig());

    return <HighchartsReact highcharts={Highcharts} options={graphConfig} />;
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = state;
    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(OutdoorSensorGraphComponent);
