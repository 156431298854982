import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { searchDataset } from '../../../../../components/search/Search';
import { SearchLocation } from '../../../../../models/buildingModels';

type GlobalSearchValues = {
    searchValue: string;
    handleSearch: () => void;
    errorMessage: string | null;
    handleSearchInputChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
    cleanUp: () => void;
    searchedData: SearchLocation[];
    noDataToDisplay: boolean;
};

const useGlobalSearch = (minCharacters: number, data: SearchLocation[]): GlobalSearchValues => {
    const { t: txt } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [searchApplied, setSearchApplied] = useState(false);
    const [searchedData, setSearchedData] = useState<SearchLocation[]>([]);

    const noDataToDisplay = Boolean(searchApplied && !searchedData.length);

    const cleanUp = (): void => {
        setSearchValue('');
        setErrorMessage(null);
        setSearchApplied(false);
        setSearchedData([]);
    };

    const handleSearch = (): void => {
        if (searchValue.length < minCharacters) return undefined;
        setSearchApplied(true);
        setSearchedData(searchDataset(data, ['segmentName', 'serialNumber'])(searchValue));
        return undefined;
    };

    const handleSearchInputChange = (e: React.SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        setSearchValue(value);
        setSearchApplied(false);
        if (value.length < minCharacters) {
            setErrorMessage(txt('BuildingHealth.SearchMinimumCharacters', { minCharacters }));
            setSearchedData([]);
            setSearchApplied(false);
        } else setErrorMessage(null);
    };

    return {
        searchValue,
        handleSearch,
        errorMessage,
        handleSearchInputChange,
        cleanUp,
        searchedData,
        noDataToDisplay,
    };
};

export default useGlobalSearch;
