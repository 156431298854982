import { all, call, put, takeEvery, CallEffect, PutEffect } from 'redux-saga/effects';
import {
    ADD_FLOORPLAN,
    ADD_FLOORPLAN_ZONE_AND_DEVICE,
    UPDATE_FLOORPLAN_ZONE_AND_DEVICE,
    REMOVE_FLOORPLAN_ZONE_DEVICE,
    addFloorplanError,
    addFloorplanSuccess,
    addFloorplanZoneAndDeviceError,
    addFloorplanZoneAndDeviceSuccess,
    removeDeviceFromFloorplanZoneSuccess,
    updateZoneAndDeviceFloorplanSuccess,
    updateZoneAndDeviceFloorplanError,
    deleteFloorplanError,
    deleteFloorplanSuccess,
    DELETE_FLOORPLAN,
    removeDeviceFromFloorplanZoneError,
    UpdateZoneAndDeviceFloorplanSuccess,
    UpdateZoneAndDeviceFloorplanError,
    UpdateZoneAndDeviceFloorplan,
    AddZoneAndDeviceToFloorplan,
    AddFloorplan,
    DeleteFloorplan,
    RemoveDeviceFromFloorplanZone,
    AddFloorplanZoneAndDeviceSuccess,
    AddFloorplanZoneAndDeviceError,
    AddFloorplanSuccess,
    AddFloorplanError,
} from 'commons/src/actions/FloorPlanActions';
import { requestSuccess, requestError, RequestActionType } from 'commons/src/actions/requestActions';
import { FloorPlanType, FloorplanZone } from 'commons/src/models/commonTypeScript';
import RequestActions from 'commons/src/models/RequestTypes';
import displayAlertBoxSaga from 'commons/src/sagas/displayAlertBox';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import {
    FetchFloorplanImage,
    fetchFloorplanImage,
    FetchFloorplanImageError,
    fetchFloorplanImageError,
    FetchFloorplanImageSuccess,
    fetchFloorplanImageSuccess,
    FetchLocationFloorplans,
    FetchLocationFloorplansSuccess,
    fetchLocationFloorplansSuccess,
    FloorplanActionType,
    UpdateFloorName,
    updateFloorNameSuccess,
} from '../actions/floorplanActions';
import {
    addDeviceToFloorplan,
    addFloorplan,
    addZoneToFloorplan,
    deleteFloorplan,
    getFloorplanImage,
    deleteFloorPlanZone,
    updateFloorPlanZoneDevice,
    updateFloorPlanZone,
    updateFloorName,
    getLocationFloorPlans,
} from '../api/floorplan';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type AddFloorplanSagaReturnType = Generator<
    | CallEffect
    | PutEffect<AddFloorplanSuccess>
    | PutEffect<AddFloorplanError>
    | PutEffect<FetchFloorplanImage>
    | RequestActions,
    void,
    FloorPlanType
>;
export function* addFloorplanSaga({
    locationId,
    floorplanName,
    floorplanImage,
}: AddFloorplan): AddFloorplanSagaReturnType {
    try {
        const response: { id: string } = yield call(addFloorplan, locationId, floorplanName, floorplanImage);
        yield put(
            addFloorplanSuccess(locationId, {
                id: response.id,
                name: floorplanName,
                zones: [],
            })
        );
        yield put(fetchFloorplanImage(locationId, response.id));
    } catch (error) {
        yield put(addFloorplanError());
    }
}
export function* deleteFloorplanSaga({ floorplanId, locationId }: DeleteFloorplan): Generator {
    try {
        yield call(deleteFloorplan, locationId, floorplanId);
        yield put(deleteFloorplanSuccess(floorplanId, locationId));
        yield call(displayAlertBoxSaga, 'FloorPlan.DeleteFloorPlanSuccess', false, true);
    } catch (error) {
        yield put(deleteFloorplanError());
    }
}

type FetchFloorplanImageSagaReturnType = Generator<
    CallEffect | PutEffect<FetchFloorplanImageSuccess> | PutEffect<FetchFloorplanImageError> | RequestActions,
    void,
    string
>;
export function* fetchFloorplanImageSaga({
    locationId,
    floorplanId,
}: FetchFloorplanImage): FetchFloorplanImageSagaReturnType {
    try {
        const image = yield call(getFloorplanImage, locationId, floorplanId);
        yield put(fetchFloorplanImageSuccess(locationId, floorplanId, image));
    } catch (error) {
        yield put(fetchFloorplanImageError(locationId));
    }
}

type AddZoneAndDeviceToFloorplanSagaReturnType = Generator<
    | CallEffect
    | PutEffect<AddFloorplanZoneAndDeviceSuccess>
    | PutEffect<AddFloorplanZoneAndDeviceError>
    | RequestActions,
    void,
    { zoneId: string }
>;
export function* addZoneAndDeviceToFloorplanSaga(
    action: AddZoneAndDeviceToFloorplan
): AddZoneAndDeviceToFloorplanSagaReturnType {
    const { floorplanId, zoneBoundary, devicePosition, serialNumber, locationId } = action;
    try {
        const { zoneId } = yield call(addZoneToFloorplan, locationId, floorplanId, zoneBoundary);
        const device = { serialNumber, position: devicePosition };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - no idea how to type a generator with two calls returning data
        yield call(addDeviceToFloorplan, locationId, floorplanId, zoneId, device);
        yield put(
            addFloorplanZoneAndDeviceSuccess(
                {
                    id: zoneId,
                    boundary: zoneBoundary,
                    device: {
                        serialNumber,
                        position: devicePosition,
                    },
                },
                locationId,
                floorplanId
            )
        );
    } catch (error) {
        yield put(addFloorplanZoneAndDeviceError());
    }
}

type UpdateZoneAndDeviceFloorplanSagaReturnType = Generator<
    | CallEffect
    | PutEffect<UpdateZoneAndDeviceFloorplanSuccess>
    | PutEffect<UpdateZoneAndDeviceFloorplanError>
    | RequestActions,
    void,
    FloorplanZone
>;
export function* updateZoneAndDeviceFloorplanSaga(
    action: UpdateZoneAndDeviceFloorplan
): UpdateZoneAndDeviceFloorplanSagaReturnType {
    const { floorplanId, serialNumber, zoneId, zoneBoundary, devicePosition, locationId } = action;
    const device = { serialNumber, position: devicePosition };
    try {
        yield call(updateFloorPlanZone, locationId, floorplanId, zoneId, zoneBoundary);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - no idea how to type a generator with two calls returning data
        yield call(updateFloorPlanZoneDevice, locationId, floorplanId, zoneId, device);
        yield put(
            updateZoneAndDeviceFloorplanSuccess(
                {
                    id: zoneId,
                    boundary: zoneBoundary,
                    device: {
                        serialNumber,
                        position: devicePosition,
                    },
                },
                locationId
            )
        );
    } catch (error) {
        yield put(updateZoneAndDeviceFloorplanError(zoneId));
    }
}

export function* removeFloorplanZoneSaga({
    floorplanId,
    zoneId,
    locationId,
}: RemoveDeviceFromFloorplanZone): Generator {
    try {
        yield call(deleteFloorPlanZone, locationId, floorplanId, zoneId);
        yield put(removeDeviceFromFloorplanZoneSuccess(floorplanId, zoneId, locationId));
    } catch (error) {
        yield put(removeDeviceFromFloorplanZoneError(floorplanId, zoneId, locationId));
    }
}

export function* editFloorNameSaga({ floorId, name, locationId }: UpdateFloorName): Generator {
    try {
        yield call(updateFloorName, locationId, floorId, name);
        yield put(updateFloorNameSuccess(floorId, name, locationId));
        yield put(requestSuccess(RequestType.UpdateFloorName, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.UpdateFloorName, RequestActionType.Error));
    }
}

type FetchLocationFloorPlansSagaReturnType = Generator<
    CallEffect | PutEffect<FetchLocationFloorplansSuccess> | RequestActions,
    void,
    { floorPlans: FloorPlanType[] }
>;
export function* fetchLocationFloorplansSaga({
    locationId,
}: FetchLocationFloorplans): FetchLocationFloorPlansSagaReturnType {
    try {
        const response: { floorPlans: FloorPlanType[] } = yield call(getLocationFloorPlans, locationId);
        yield put(fetchLocationFloorplansSuccess(locationId, response.floorPlans));
        yield put(requestSuccess(RequestType.FetchLocationFloorplans, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchLocationFloorplans, RequestActionType.Error));
    }
}

export default function* FloorPlanSagas(): Generator {
    yield all([
        takeEvery(FloorplanActionType.FetchFloorplanImageInit, fetchFloorplanImageSaga),
        takeEvery(ADD_FLOORPLAN, addFloorplanSaga),
        takeEvery(ADD_FLOORPLAN_ZONE_AND_DEVICE, addZoneAndDeviceToFloorplanSaga),
        takeEvery(REMOVE_FLOORPLAN_ZONE_DEVICE, removeFloorplanZoneSaga),
        takeEvery(UPDATE_FLOORPLAN_ZONE_AND_DEVICE, updateZoneAndDeviceFloorplanSaga),
        takeEvery(DELETE_FLOORPLAN, deleteFloorplanSaga),
        takeEvery(FloorplanActionType.UpdateFloorNameInit, editFloorNameSaga),
        takeEvery(FloorplanActionType.FetchLocationFloorplansInit, fetchLocationFloorplansSaga),
    ]);
}
