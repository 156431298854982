import React from 'react';
import { Table, TableContainer } from '@mui/material';
import styles from './MobileMaterialTable.module.scss';
import MobileMaterialTableData from './MobileMaterialTableData';
import MobileMaterialTableHeader from './MobileMaterialTableHeader';
import { MaterialTableProps } from './models';

const MobileMaterialTable = ({ tableId, size, locationId }: Partial<MaterialTableProps>): React.ReactElement => {
    return (
        <TableContainer className={styles.mobileTable}>
            <Table aria-label={tableId} id={tableId} className={styles.table} size={size ?? 'small'}>
                <MobileMaterialTableHeader />
                <MobileMaterialTableData locationId={locationId} />
            </Table>
        </TableContainer>
    );
};

export default MobileMaterialTable;
