import moment from 'moment';
import { takeEvery, put, call, select, all, CallEffect, AllEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import {
    FETCH_CUSTOM_DEVICE_SEGMENT,
    fetchCustomDeviceSegmentError,
    FetchCustomDeviceSegment,
    fetchCustomDeviceSegment,
    DeviceActionType,
    fetchCustomSegmentVirtualSensorsSuccess,
    fetchCustomDeviceSegmentSuccess,
    FetchCustomSegmentVirtualSensorsSuccess,
    FetchCustomDeviceSegmentSuccess,
    FetchCustomDeviceSegmentError,
    fetchCustomSegmentVirtualSensors,
    FetchCustomSegmentVirtualSensors,
} from '../../actions/DeviceActions';
import { RequestActionType, requestError, requestSuccess } from '../../actions/requestActions';
import fetchSegment, { fetchVirtualDeviceData } from '../../api/segment';
import {
    AverageSensorPeriodValues,
    DashboardSensorData,
    DeviceResponse,
    ExtraSeries,
    MinSensorPeriodValues,
    SelectedPeriod,
    SensorData,
} from '../../models/commonTypeScript';
import { RequestActions } from '../../models/RequestTypes';
import { deviceData } from '../../reducers/reducerShortcuts';
import { CommonRequestType } from '../../reducers/requestReducer';
import { createChartData } from '../DashboardSagas/fetchDashboardSensorTile';
import { toErrorType } from '../isErrorType';

export const getFetchAttributes = (timeRange: SelectedPeriod): { from: string; to: string } => {
    const { startDate, endDate, resolutionDuration } = timeRange;

    if (!endDate) throw Error('EndDate is required when fetching segment');

    const toISO = endDate.clone().add(resolutionDuration).toISOString();
    const to = toISO && toISO.slice(0, toISO.lastIndexOf('.'));

    const fromISOTime = startDate && startDate.toISOString();
    const fromISO = moment(fromISOTime).subtract(resolutionDuration).toISOString();
    const from = fromISO.slice(0, fromISO.lastIndexOf('.'));

    return { from, to };
};

export const createGraphArrays = (
    device: DeviceResponse,
    timeRange: SelectedPeriod,
    prevDevice: DashboardSensorData
): {
    fetchedIntervals: { [name: string]: boolean };
    sensorData: SensorData;
    minValues: MinSensorPeriodValues;
    averageValues: AverageSensorPeriodValues;
    extraSeries: ExtraSeries;
} => {
    const segmentStartTime = moment.utc(device.segmentStart).valueOf();
    const sensorData: SensorData = {};
    const extraSeries: ExtraSeries = {};
    const minValues: MinSensorPeriodValues = {};
    const averageValues: AverageSensorPeriodValues = {};

    device.sensors.forEach(sensor => {
        const graphData = createChartData(device, sensor, segmentStartTime);

        sensorData[sensor.type] = {
            ...(prevDevice.sensorData && prevDevice.sensorData[sensor.type]),
            [timeRange.name]: graphData.chartData,
        };
        minValues[sensor.type] = {
            ...(prevDevice.minValues && prevDevice.minValues[sensor.type]),
            [timeRange.name]: graphData.minValue,
        };
        averageValues[sensor.type] = {
            ...(prevDevice.averageValues && prevDevice.averageValues[sensor.type]),
            [timeRange.name]: graphData.averageValue,
        };
        extraSeries[sensor.type] = {
            ...(prevDevice.extraSeries && prevDevice.extraSeries[sensor.type]),
            [timeRange.name]: graphData.extraSeries,
        };
    });

    return {
        fetchedIntervals: { [timeRange.name]: true },
        sensorData,
        minValues,
        averageValues,
        extraSeries,
    };
};

type FetchCustomDeviceSegmentSagaType = Generator<
    | CallEffect<DeviceResponse>
    | SelectEffect
    | AllEffect<PutEffect>
    | PutEffect<FetchCustomSegmentVirtualSensors>
    | PutEffect<FetchCustomDeviceSegmentSuccess>
    | PutEffect<FetchCustomDeviceSegmentError>
    | void,
    void,
    DeviceResponse & DashboardSensorData
>;
export function* fetchCustomDeviceSegmentSaga({
    serialNumber,
    timeRange,
    extraParams,
}: FetchCustomDeviceSegment): FetchCustomDeviceSegmentSagaType {
    const { from, to } = getFetchAttributes(timeRange);

    try {
        yield put(fetchCustomSegmentVirtualSensors(serialNumber, timeRange, extraParams));
        const response = yield call(fetchSegment, serialNumber, {
            from,
            to,
            resolution: timeRange.resolution,
            ...extraParams,
        });
        if (response && response.childGroups) {
            yield all(response.childGroups.map(childGroup => put(fetchCustomDeviceSegment(childGroup, timeRange))));
        }
        const prevDevice = yield select(deviceData, serialNumber);
        yield put(fetchCustomDeviceSegmentSuccess(createGraphArrays(response, timeRange, prevDevice), serialNumber));
    } catch (error) {
        yield put(fetchCustomDeviceSegmentError(serialNumber, error));
    }
}

type FetchCustomSegmentVirtualSensorsSagaType = Generator<
    | CallEffect<DeviceResponse>
    | SelectEffect
    | AllEffect<PutEffect>
    | PutEffect<FetchCustomSegmentVirtualSensorsSuccess>
    | RequestActions,
    void,
    DeviceResponse & DashboardSensorData
>;

export function* fetchCustomSegmentVirtualSensorsSaga({
    serialNumber,
    timeRange,
    extraParams,
}: FetchCustomDeviceSegment): FetchCustomSegmentVirtualSensorsSagaType {
    const { from, to } = getFetchAttributes(timeRange);

    try {
        const response = yield call(fetchVirtualDeviceData, serialNumber, {
            from,
            to,
            resolution: timeRange.resolution,
            ...extraParams,
        });
        if (response && response.childGroups) {
            yield all(
                response.childGroups.map(childGroup => put(fetchCustomSegmentVirtualSensors(childGroup, timeRange)))
            );
        }
        const prevDevice = yield select(deviceData, serialNumber);
        yield put(
            fetchCustomSegmentVirtualSensorsSuccess(createGraphArrays(response, timeRange, prevDevice), serialNumber)
        );
        yield put(requestSuccess(CommonRequestType.FetchCustomSegmentVirtualSensors, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        requestError(errorAsErrorType, CommonRequestType.FetchCustomSegmentVirtualSensors, RequestActionType.Error);
    }
}

export default function* FetchCustomDeviceSegmentSaga(): Generator {
    yield all([
        takeEvery(FETCH_CUSTOM_DEVICE_SEGMENT, fetchCustomDeviceSegmentSaga),
        takeEvery(DeviceActionType.FetchCustomSegmentVirtualSensorsInit, fetchCustomSegmentVirtualSensorsSaga),
    ]);
}
