import delay from '@redux-saga/delay-p';
import { takeEvery, call, put, all, CallEffect, PutEffect } from 'redux-saga/effects';
import { requestSuccess, requestError, RequestActionType } from 'commons/src/actions/requestActions';
import RequestActions from 'commons/src/models/RequestTypes';
import displayAlertBoxSaga from 'commons/src/sagas/displayAlertBox';
import history from 'commons/src/store/history';
import {
    AddApiClient,
    AddApiClientSuccess,
    addApiClientSuccess,
    ApiClientsActionType,
    DeleteApiClient,
    deleteApiClientSuccess,
    FETCH_API_CLIENT_SECRET_SUCCESS,
    FetchApiClientSecret,
    fetchApiClientSecretSuccess,
    FetchApiClientSecretSuccess,
    FetchApiClientsSuccess,
    fetchApiClientsSuccess,
    hideApiClientSecret,
    UpdateApiClient,
    UpdateApiClientActiveState,
    updateApiClientSuccess,
} from '../actions/apiClientActions';
import {
    deleteClient,
    fetchClients,
    fetchClientSecret,
    registerApiClient,
    updateClient,
} from '../api/integrationClients';
import { paths } from '../constants';
import { ApiClient, ClientSecret } from '../models/apiClient';
import { CommonRequestType as RequestType } from '../reducers/requestReducer';
import { toErrorType } from './isErrorType';

export function* deleteApiClientSaga({ clientId }: DeleteApiClient): Generator {
    try {
        yield call(deleteClient, clientId);
        history.replace(`/${paths.apiIntegration}`);
        yield put(deleteApiClientSuccess(clientId));
        yield put(requestSuccess(RequestType.DeleteApiClient, RequestActionType.Success));
        yield call(displayAlertBoxSaga, 'ApiIntegration.DeleteSuccess', false, true);
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield call(displayAlertBoxSaga, 'ApiIntegration.DeleteError', true, true);
        yield put(requestError(asErrorType, RequestType.DeleteApiClient, RequestActionType.Error));
    }
}

type FetchClientSecretSagaReturnType = Generator<
    CallEffect<ClientSecret> | PutEffect<FetchApiClientSecretSuccess> | RequestActions,
    void,
    ClientSecret
>;
export function* fetchClientSecretSaga({ clientId }: FetchApiClientSecret): FetchClientSecretSagaReturnType {
    try {
        const response = yield call(fetchClientSecret, clientId);
        yield put(fetchApiClientSecretSuccess(response));
        yield put(requestSuccess(RequestType.FetchApiClientSecret, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.FetchApiClientSecret, RequestActionType.Error));
    }
}

type FetchMyClientsSagaReturnType = Generator<
    CallEffect<ApiClient[]> | PutEffect<FetchApiClientsSuccess> | RequestActions,
    void,
    ApiClient[]
>;
export function* fetchMyClientsSaga(): FetchMyClientsSagaReturnType {
    try {
        const response = yield call(fetchClients);
        yield put(fetchApiClientsSuccess(response));
        yield put(requestSuccess(RequestType.FetchApiClients, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.FetchApiClients, RequestActionType.Error));
    }
}

type RegisterApiClientSagaReturnType = Generator<
    CallEffect<ApiClient> | PutEffect<AddApiClientSuccess> | CallEffect | RequestActions,
    void,
    ApiClient
>;
export function* registerApiClientSaga({ apiClient }: AddApiClient): RegisterApiClientSagaReturnType {
    try {
        const response = yield call(registerApiClient, apiClient);
        yield put(addApiClientSuccess(response));
        history.push({ pathname: `/${paths.apiIntegration}/${response.id}` });
        yield put(requestSuccess(RequestType.AddApiClient, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield call(displayAlertBoxSaga, `ErrorCodes.${asErrorType.error}`, true, true);
        yield put(requestError(asErrorType, RequestType.AddApiClient, RequestActionType.Error));
    }
}

export function* updateApiClientSaga({ apiClient }: UpdateApiClient): Generator {
    try {
        yield call(updateClient, apiClient);
        yield put(updateApiClientSuccess(apiClient));
        yield put(requestSuccess(RequestType.UpdateApiClient, RequestActionType.Success));
        yield call(displayAlertBoxSaga, 'ApiIntegration.UpdateSuccess', false, true);
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield call(displayAlertBoxSaga, 'ApiIntegration.UpdateError', true, true);
        yield put(requestError(asErrorType, RequestType.UpdateApiClient, RequestActionType.Error));
    }
}

export function* updateApiClientActiveStateSaga({ apiClient }: UpdateApiClientActiveState): Generator {
    try {
        yield call(updateClient, apiClient);
        yield put(requestSuccess(RequestType.UpdateApiClientActiveState, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.UpdateApiClientActiveState, RequestActionType.Error));
    }
}

export function* removeClientSecret({ secret }: FetchApiClientSecretSuccess): Generator {
    yield call(delay, 15000);
    yield put(hideApiClientSecret(secret.clientId));
}

export default function* ApiClientSagas(): Generator {
    yield all([
        takeEvery(ApiClientsActionType.FetchApiClientsInit, fetchMyClientsSaga),
        takeEvery(ApiClientsActionType.AddApiClientInit, registerApiClientSaga),
        takeEvery(ApiClientsActionType.UpdateApiClientInit, updateApiClientSaga),
        takeEvery(ApiClientsActionType.FetchApiClientSecretInit, fetchClientSecretSaga),
        takeEvery(ApiClientsActionType.DeleteApiClientInit, deleteApiClientSaga),
        takeEvery(ApiClientsActionType.UpdateApiClientActiveStateInit, updateApiClientActiveStateSaga),
        takeEvery(FETCH_API_CLIENT_SECRET_SUCCESS, removeClientSecret),
    ]);
}
