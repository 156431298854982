import {
    DeviceType,
    DeviceWithKeyInfo,
    EndedSegment,
    ErrorType,
    HubData,
    LocationDetails,
    LocationDetailsType,
    LocationRfRegionResponse,
    LocationType,
} from '../models/commonTypeScript';
import { BusinessRequestTypesUsedInCommons as RequestType, CommonRequestType } from '../reducers/requestReducer';
import {
    ADD_LOCATION_SUCCESS,
    DELETE_LOCATION_SUCCESS,
    FETCH_LOCATIONS,
    FETCH_LOCATIONS_ERROR,
    FETCH_LOCATIONS_SUCCESS,
    POLL_DATA,
} from './LocationActionTypes';
import { RequestActionType } from './requestActions';

export enum LocationActions {
    DeleteLocationInit = 'DELETE_LOCATION/INIT',
    AddLocationInit = 'ADD_LOCATION/INIT',
    ValidateLocationRfRegion = 'VALIDATE_LOCATION_RF_REGION/INIT',
    FetchLocationsHistory = 'FETCH_LOCATIONS_HISTORY/INIT',
    FetchLocationsHistorySuccess = 'FETCH_LOCATIONS_HISTORY_SUCCESS',
}

export const UPDATE_LOCATION_DETAILS_SUCCESS = 'UPDATE_LOCATION_DETAILS_SUCCESS';
export interface UpdateLocationDetailsSuccess {
    type: typeof UPDATE_LOCATION_DETAILS_SUCCESS;
    locationDetails: LocationDetailsType;
}
export const updateLocationDetailsSuccess = (locationDetails: LocationDetailsType): UpdateLocationDetailsSuccess => ({
    type: UPDATE_LOCATION_DETAILS_SUCCESS,
    locationDetails,
});

export interface FetchLocations {
    type: typeof FETCH_LOCATIONS;
}
export const fetchLocations = (): FetchLocations => ({ type: FETCH_LOCATIONS });

export interface FetchLocationsSuccess {
    type: typeof FETCH_LOCATIONS_SUCCESS;
    payload: LocationsPayload;
}

export type LocationsPayload = {
    locations: LocationType[];
    devices: { [serialNumber: string]: DeviceType };
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    hubsWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    hubs?: HubData[];
};

export const fetchLocationsSuccess = (payload: LocationsPayload): FetchLocationsSuccess => ({
    type: FETCH_LOCATIONS_SUCCESS,
    payload,
});

interface PollData {
    type: typeof POLL_DATA;
}
export const pollData = (): PollData => ({ type: POLL_DATA });

export interface FetchLocationsHistory {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.FetchLocationsHistory;
    type: LocationActions.FetchLocationsHistory;
    locationId?: string;
}
export const fetchLocationsHistory = (locationId?: string): FetchLocationsHistory => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.FetchLocationsHistory,
    type: LocationActions.FetchLocationsHistory,
    locationId,
});

interface FetchLocationsHistorySuccess {
    type: LocationActions.FetchLocationsHistorySuccess;
    payload: EndedSegment[];
}
export const fetchLocationsHistorySuccess = (payload: EndedSegment[]): FetchLocationsHistorySuccess => ({
    type: LocationActions.FetchLocationsHistorySuccess,
    payload,
});

export interface FetchLocationsError {
    type: typeof FETCH_LOCATIONS_ERROR;
    error: ErrorType;
}
export const fetchLocationsError = (error: ErrorType): FetchLocationsError => ({ type: FETCH_LOCATIONS_ERROR, error });

export interface DeleteLocation {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.DeleteLocation;
    type: LocationActions.DeleteLocationInit;
    locationId: string;
}
export const deleteLocation = (locationId: string): DeleteLocation => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.DeleteLocation,
    type: LocationActions.DeleteLocationInit,
    locationId,
});

export interface DeleteLocationSuccess {
    type: typeof DELETE_LOCATION_SUCCESS;
    locationId: string;
}

export const deleteLocationSuccess = (locationId: string): DeleteLocationSuccess => ({
    type: DELETE_LOCATION_SUCCESS,
    locationId,
});

export interface AddLocation {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.AddLocation;
    type: LocationActions.AddLocationInit;
    payload: LocationDetails;
}
export const addLocation = (payload: LocationDetails): AddLocation => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.AddLocation,
    type: LocationActions.AddLocationInit,
    payload,
});

export interface AddLocationSuccess {
    type: typeof ADD_LOCATION_SUCCESS;
    payload: LocationType;
}
export const addLocationSuccess = (payload: LocationType): AddLocationSuccess => ({
    type: ADD_LOCATION_SUCCESS,
    payload,
});

export interface ValidateLocationRfRegion {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.ValidateLocationRfRegion;
    type: LocationActions.ValidateLocationRfRegion;
    countryCode: string;
    locationId?: string;
}
export const validateLocationRfRegion = (countryCode: string, locationId?: string): ValidateLocationRfRegion => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: CommonRequestType.ValidateLocationRfRegion,
    type: LocationActions.ValidateLocationRfRegion,
    countryCode,
    locationId,
});

export const VALIDATE_LOCATION_RF_REGION_SUCCESS = 'VALIDATE_LOCATION_RF_REGION_SUCCESS';
export interface ValidateLocationRfRegionSuccess {
    type: typeof VALIDATE_LOCATION_RF_REGION_SUCCESS;
    rfRegionValidationResponse: LocationRfRegionResponse;
}
export const validateLocationRfRegionSuccess = (
    rfRegionValidationResponse: LocationRfRegionResponse
): ValidateLocationRfRegionSuccess => ({
    type: VALIDATE_LOCATION_RF_REGION_SUCCESS,
    rfRegionValidationResponse,
});

export const VALIDATE_LOCATION_RF_REGION_CLEARS_STATE = 'VALIDATE_LOCATION_RF_REGION_CLEAR_STATE';
export interface ValidateLocationRfRegionClearState {
    type: typeof VALIDATE_LOCATION_RF_REGION_CLEARS_STATE;
}
export const validateLocationRfRegionClearState = (): ValidateLocationRfRegionClearState => ({
    type: VALIDATE_LOCATION_RF_REGION_CLEARS_STATE,
});

export type LocationAction = DeleteLocation | AddLocation;

export type LocationReducerActions =
    | FetchLocationsError
    | FetchLocations
    | FetchLocationsHistory
    | FetchLocationsHistorySuccess
    | FetchLocationsSuccess
    | DeleteLocationSuccess
    | AddLocationSuccess
    | UpdateLocationDetailsSuccess
    | ValidateLocationRfRegionSuccess
    | ValidateLocationRfRegionClearState;
