import React, { useState } from 'react';
import { Option } from 'commons/src/components/dropdown/MultipleAttrDropdown';
import CheckBox from 'commons/src/components/input/Checkbox';
import { sensorUnits } from 'commons/src/constants';
import { EmailSummarySensorRule } from '../../../models/emailSummaryModels';
import ThresholdInsightRule, { getThresholdsForSelectedSensor } from '../ThresholdInsightRule';

type ThresholdInsightRuleWithCheckboxProps = {
    sensor: string;
    thresholds?: {
        over: number[];
        under?: number[];
        unit: keyof typeof sensorUnits;
    };
    unit: string;
    selectedHighThreshold: EmailSummarySensorRule;
    selectedLowThreshold?: EmailSummarySensorRule;
    setSelectedHighThreshold: (option: Option) => void;
    setSelectedLowThreshold: (option: Option) => void;
    selectUnselectSensor: (sensor: string, checked: boolean) => void;
    enabled: boolean;
};

const ThresholdInsightRuleWithCheckboxComponent = (
    props: ThresholdInsightRuleWithCheckboxProps
): React.ReactElement => {
    const {
        sensor,
        thresholds,
        setSelectedHighThreshold,
        setSelectedLowThreshold,
        unit,
        selectedHighThreshold,
        selectedLowThreshold,
        selectUnselectSensor,
        enabled,
    } = props;

    const [checked, setChecked] = useState<boolean>(enabled);

    const { aboveThresholds, belowThresholds } = getThresholdsForSelectedSensor(thresholds);

    const onCheckboxChange = (): void => {
        setChecked(!checked);
        selectUnselectSensor(sensor, !checked);
    };

    return (
        <div className="flex flex--align-center">
            <CheckBox
                label=""
                onChange={onCheckboxChange}
                checked={checked}
                key={`checkbox-rule-${sensor}`}
                id={sensor}
                testId={`checkbox-${sensor}`}
            />
            <ThresholdInsightRule
                sensor={sensor}
                selectedHighThreshold={{
                    id: selectedHighThreshold.value.toString(),
                    inputValue: `${selectedHighThreshold.value.toString()}${
                        sensorUnits[unit as keyof typeof sensorUnits]
                    }`,
                }}
                selectedLowThreshold={
                    selectedLowThreshold
                        ? {
                              id: selectedLowThreshold.value.toString(),
                              inputValue: `${selectedLowThreshold.value.toString()}${
                                  sensorUnits[unit as keyof typeof sensorUnits]
                              }`,
                          }
                        : undefined
                }
                setSelectedHighThreshold={setSelectedHighThreshold}
                setSelectedLowThreshold={setSelectedLowThreshold}
                belowThresholds={belowThresholds}
                aboveThresholds={aboveThresholds}
                withSensorCircle
                boldText={false}
            />
        </div>
    );
};

export default ThresholdInsightRuleWithCheckboxComponent;
