import React, { useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Group } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import MaterialIcon from '../MaterialIcon';
import ChangeOrganizationModal from './ChangeOrganizationModal';

type StateProps = {
    userName: string;
    selectedGroup?: Group;
    groups: Group[];
};

export type ParentProps = {
    navigationType: string;
};

type Props = ParentProps & StateProps;

export const ProfileSettingsComponent = (props: Props): React.ReactElement => {
    const { userName, selectedGroup, navigationType, groups } = props;
    const [changeAccountModalOpen, setChangeAccountModalOpen] = useState(false);

    const flipChangeOrgState = (): void => {
        setChangeAccountModalOpen(!changeAccountModalOpen);
    };

    const showChangeAccountIcon = groups.length > 1;
    const selectedAccountName = selectedGroup && (selectedGroup.organizationName || userName);
    if (!showChangeAccountIcon) return <div />;

    return (
        <div className="sidebar-navigation__profile">
            {changeAccountModalOpen && <ChangeOrganizationModal onClose={flipChangeOrgState} />}
            <div
                tabIndex={0}
                role="button"
                onKeyUp={(e): void => {
                    if (e.key === 'enter') flipChangeOrgState();
                }}
                onClick={flipChangeOrgState}
                className={classNames(
                    'sidebar-navigation__items-container__item',
                    'sidebar-navigation__items-container__item--function-item'
                )}
            >
                <li className="sidebar-navigation__items-container__element">
                    <MaterialIcon
                        extraClass="sidebar-navigation__items-container__item__icon"
                        name="swap_horizontal_circle"
                    />
                    <span
                        className={classNames('sidebar-navigation__items-container__item--ellipsis', {
                            'sidebar-navigation__items-container__item--slim': navigationType === 'slim',
                        })}
                    >
                        {selectedAccountName}
                    </span>
                </li>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { userName, groups, selectedGroup },
    } = state;

    return {
        userName,
        selectedGroup,
        groups,
    };
};

export default connect(mapStateToProps)(ProfileSettingsComponent);
