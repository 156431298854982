import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getDotColor } from '../../commonFunctions';
import { roundSensorValue } from '../../components/sensors/SensorUnit';
import { sensorUnits, statusColors } from '../../constants';
import { ChartDataType, SelectedPeriod } from '../../models/commonTypeScript';

type Props = {
    chartData: ChartDataType;
    selectedInterval: SelectedPeriod;
    sensor: { type: string; unit: string; thresholds: Array<number> };
};

const UnitAndEdgeValues = ({ chartData, selectedInterval, sensor }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const unit = sensor.unit as keyof typeof sensorUnits;
    const selectedIntervalValues = chartData[selectedInterval.name].map(it => it[1]);
    const maxValue = Math.max(...selectedIntervalValues);
    const minValue = Math.min(...selectedIntervalValues);

    const edgeValueRow = (value: number, text: string): React.ReactElement => {
        const dotColor = getDotColor(sensor.thresholds, sensor.type, value);
        const sensorHasValue = Number.isFinite(value);
        const roundedValue = roundSensorValue(value, unit);

        return (
            <div className="sensor-row__current-value__edge-values">
                <span className="sensor-row__current-value__header">{`${txt(text)}: `}</span>
                <span
                    className={classNames('sensor__line sensor__line--top-padded', {
                        'sensor__line--ok': dotColor === statusColors.green,
                        'sensor__line--alert': dotColor === statusColors.red,
                        'sensor__line--warning': dotColor === statusColors.yellow,
                    })}
                />
                <span className="sensor__data">
                    <span className="sensor__value">
                        {sensorHasValue ? roundedValue : txt('NotAvailableAbbreviation')}
                        {sensorHasValue && (
                            <span
                                className={classNames('sensor__unit--font-regular', {
                                    sensor__unit: unit === 'pct',
                                    'sensor__unit--risk': unit === 'riskIndex',
                                })}
                            >
                                {sensorUnits[unit]}
                            </span>
                        )}
                    </span>
                </span>
            </div>
        );
    };

    return (
        <>
            <div className="sensor-row__current-value__lined" />
            {edgeValueRow(minValue, 'Min')}
            {edgeValueRow(maxValue, 'Max')}
        </>
    );
};

export default UnitAndEdgeValues;
