import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Dropdown, { Option } from 'commons/src/components/dropdown/MultipleAttrDropdown';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import { sensorUnits } from 'commons/src/constants';
import { SensorTypes } from 'commons/src/models/commonEnums';

type SensorRuleProps = {
    sensor: string;
    selectedHighThreshold: Option;
    selectedLowThreshold?: Option;
    setSelectedHighThreshold: (option: Option) => void;
    setSelectedLowThreshold: (option: Option) => void;
    aboveThresholds: Option[];
    belowThresholds: Option[];
    withSensorCircle?: boolean;
    boldText: boolean;
    noPadding?: boolean;
};
export const getThresholdsForSelectedSensor = (sensorThresholds?: {
    over: number[];
    under?: number[];
    unit: keyof typeof sensorUnits;
}): {
    aboveThresholds: { id: string; inputValue: string }[];
    belowThresholds: { id: string; inputValue: string }[];
} => {
    const aboveThresholds =
        (sensorThresholds &&
            sensorThresholds.over.map((t: number) => ({
                id: t.toString(),
                inputValue: `${t}${sensorUnits[sensorThresholds.unit as keyof typeof sensorUnits]}`,
            }))) ||
        [];
    const belowThresholds =
        (sensorThresholds &&
            sensorThresholds.under &&
            sensorThresholds.under.map((t: number) => ({
                id: t.toString(),
                inputValue: `${t}${sensorUnits[sensorThresholds.unit as keyof typeof sensorUnits]}`,
            }))) ||
        [];
    return { aboveThresholds, belowThresholds };
};

export const ThresholdInsightRuleComponent = (props: SensorRuleProps): React.ReactElement => {
    const {
        sensor,
        setSelectedHighThreshold,
        setSelectedLowThreshold,
        selectedHighThreshold,
        selectedLowThreshold,
        aboveThresholds,
        belowThresholds,
        withSensorCircle,
        boldText,
        noPadding,
    } = props;

    const { t: txt } = useTranslation();

    return (
        <div className={classNames('threshold-insight-rule', { 'threshold-insight-rule--no-padding': noPadding })}>
            <div
                className={classNames('margin-10-right', {
                    'device-svg-icons notification-alert__icons__circle': !!withSensorCircle,
                })}
            >
                <SensorIcon sensor={sensor} />
            </div>
            <span
                className={classNames('text-large small-bottom-padding margin-10-right', {
                    'text-bold': boldText,
                })}
            >
                {txt(sensor === SensorTypes.temp ? 'Temperature' : sensor)}
                {' - '}
                {txt('BuildingInsight.TimeOver')}
            </span>
            <Dropdown
                options={aboveThresholds}
                id={`thresholdSelector-${sensor}`}
                defaultOption={selectedHighThreshold.inputValue}
                value={selectedHighThreshold.inputValue}
                testAttr="threshold-selector"
                onSelect={setSelectedHighThreshold}
                title=""
                testId={`thresholds-high-selector-${sensor}`}
                optionsAlreadySorted
            />
            {selectedLowThreshold && (
                <div className="flex flex--align-center">
                    <span
                        className={classNames('text-large margin-10-right', {
                            'text-bold': boldText,
                        })}
                    >
                        {txt('BuildingInsight.AndTimeBelow')}
                    </span>
                </div>
            )}
            {selectedLowThreshold && (
                <div className="flex flex--align-center">
                    <Dropdown
                        options={belowThresholds}
                        id={`thresholdSelectorBelow${sensor}`}
                        defaultOption={selectedLowThreshold.inputValue}
                        value={selectedLowThreshold.inputValue}
                        testAttr="threshold-selector-low"
                        testId={`thresholds-low-selector-${sensor}`}
                        onSelect={setSelectedLowThreshold}
                        title=""
                        optionsAlreadySorted
                    />
                </div>
            )}
        </div>
    );
};

export default ThresholdInsightRuleComponent;
