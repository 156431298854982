import React, { useState } from 'react';
import { SingleSignOnClient } from '../../../../models/common';
import SSOClientForm from './ClientForm';
import SSOClientInfo from './ClientInfo';

type PassedProps = {
    singleSignOnClient?: SingleSignOnClient;
};

export type Props = PassedProps;

export const SSOClientContainer = ({ singleSignOnClient }: Props): React.ReactElement => {
    const [isEditMode, setIsEditMode] = useState(false);

    return (
        <div>
            <div className="settings-details-container__content">
                <form>
                    {singleSignOnClient && !isEditMode ? (
                        <SSOClientInfo singleSignOnClient={singleSignOnClient} setIsEditMode={setIsEditMode} />
                    ) : (
                        <SSOClientForm
                            singleSignOnClient={singleSignOnClient}
                            isEditMode={isEditMode}
                            setIsEditMode={setIsEditMode}
                        />
                    )}
                </form>
            </div>
        </div>
    );
};

export default SSOClientContainer;
