import { takeEvery, put, call, CallEffect, PutEffect } from 'redux-saga/effects';
import { fetchBuilding } from 'business-dashboard/src/actions/locationActions';
import RequestActions from 'commons/src/models/RequestTypes';
import { fetchDashboard } from '../../actions/DashboardActions';
import {
    CHANGE_LOCATION,
    changeLocationSuccess,
    changeLocationFailure,
    EDIT_DEVICE_SAVE,
    ChangeLocation,
    ChangeLocationSuccess,
} from '../../actions/DeviceActions';
import { fetchLocations } from '../../actions/LocationActions';
import { editVirtualSegment } from '../../api/devices';
import { addLocation } from '../../api/locations';
import { updateSegment } from '../../api/segment';
import { userIsHbs } from '../../components/findUserType';
import { CurrentDashboardResponse } from '../../models/commonTypeScript';
import displayAlertBoxSaga from '../displayAlertBox';

type ChangeLocationSagaActionType = Generator<
    CallEffect<{ id: string }> | CallEffect | PutEffect | PutEffect<ChangeLocationSuccess> | RequestActions | void,
    void,
    CurrentDashboardResponse
>;

export function* changeLocationSaga({ payload }: ChangeLocation): ChangeLocationSagaActionType {
    const { location, name, locationName, segmentId, serialNumber, locationId, ...rest } = payload;
    try {
        let newLocationId = locationId;
        if (location) {
            const response = yield call(addLocation, location);
            newLocationId = response.id;
        }
        const deviceAndLocationInfo = {
            name,
            locationName,
            serialNumber,
            locationId: newLocationId,
            ...rest,
        };
        yield call(updateSegment, serialNumber, segmentId, name, newLocationId);
        yield put(changeLocationSuccess(deviceAndLocationInfo));
        yield put(fetchDashboard());
        if (userIsHbs() && newLocationId) {
            yield put(fetchBuilding(newLocationId));
        } else {
            yield put(fetchLocations());
        }
        yield call(displayAlertBoxSaga, 'DeviceDetailsUpdated', false, true);
    } catch (error) {
        yield put(changeLocationFailure(error));
    }
}

export function* editVirtualDeviceSaga({ payload }: ChangeLocation): ChangeLocationSagaActionType {
    const { name, locationName, serialNumber, locationId, metaData } = payload;
    try {
        yield call(editVirtualSegment, serialNumber, name, metaData);
        yield put(
            changeLocationSuccess({
                serialNumber,
                locationName,
                name,
                locationId,
            })
        );
        yield call(displayAlertBoxSaga, 'DeviceDetailsUpdated', false, true);
    } catch (error) {
        yield put(changeLocationFailure(error));
    }
}

export default function* ChangeLocationSaga(): Generator {
    yield takeEvery(EDIT_DEVICE_SAVE, editVirtualDeviceSaga);
    yield takeEvery(CHANGE_LOCATION, changeLocationSaga);
}
