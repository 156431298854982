import { FloorPlanPosition } from 'commons/src/models/commonTypeScript';

export type SpaceFloorPlan = {
    id: string;
    name: string;
    floor?: number;
    image: string;
};

export type FloorPlanData = {
    image: string;
    spaces: {
        id: string;
        boundary: FloorPlanPosition[];
    }[];
};

export enum SpaceFloorPlanMode {
    CREATE = 'create',
    EDIT_NEW = 'edit_new',
    EDIT = 'edit',
    INSPECT = 'inspect',
}
