import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './FilterButton.module.scss';

type Props = {
    title: string;
    onClick: () => void;
    isSelected: boolean;
    icon?: React.ReactElement;
    filled?: boolean;
    testAttr?: string;
};

const FilterButton = ({ title, onClick, isSelected, icon, filled, testAttr }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const clickButton = (e: SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        onClick();
    };
    return (
        <button
            type="button"
            className={classNames(styles.button, {
                [styles.selected]: isSelected,
                [styles.filled]: filled,
            })}
            onClick={clickButton}
            onTouchEnd={clickButton}
            data-testid={testAttr && `data-${testAttr}`}
        >
            {icon && icon}
            <div>{txt(title)}</div>
        </button>
    );
};

export default FilterButton;
