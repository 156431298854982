export const DEVICE_VIEWED_DETAILS = 'Device: viewed details';
export const DEVICE_DOWNLOADED_CSV = 'Device: downloaded csv';
export const DEVICE_CHANGED_TIMEFRAME = 'Device: changed timeframe';
export const DEVICE_UNPAIRED_DEVICE = 'Device: unpaired device';
export const DEVICE_VIEWED_SETTINGS = 'Device: viewed settings';
export const DEVICE_ENABLED_FEATURE = 'Device: enabled feature';
export const DEVICE_TOGGLED_PUBLIC = 'Device: toggled public';
export const DEVICE_DOWNLOADED_QR = 'Device: downloaded qr';
export const GROUP_CLICKED_ADD = 'Group: clicked add';
export const GROUP_CREATE_GROUP = 'Group: created group';
export const DEVICE_SAVED_SETTINGS = 'Device: saved settings';
export const DEVICE_SAVED_DISPLAY_VIEW_PLUS = 'Device: saved display view plus';
export const DEVICE_SIMPLE_RADON_REPORT_DOWNLOADED = 'Device: Downloaded consumer or ended segment radon report';

export const ENDED_SEGMENT_DELETE = 'Ended segment: delete';

export const PUBLIC_DASHBOARD_CLICKED_PREVIEW = 'Public Dashboard: clicked preview';
export const PUBLIC_DASHBOARD_COPIED_LINK = 'Public Dashboard: copied link';
export const PUBLIC_DASHBOARD_CREATE = 'Public dashboard: create';
export const PUBLIC_DASHBOARD_EDIT = 'Public dashboard: edit';
export const PUBLIC_DASHBOARD_CREATE_CLICKED = 'Public dashboard: create clicked';

export const BUILDING_ADDED_BUILDING = 'Building: added building';
export const BUILDING_ADDED_EVENT = 'Building: added event';
export const BUILDING_VIEWED_OVERVIEW = 'Building: viewed overview';
export const BUILDING_VIEWED_BUILDING = 'Building: viewed building';
export const BUILDING_VIEWED_DEVICES = 'Building: viewed devices';
export const BUILDING_VIEWED_CONTROL = 'Building: viewed control';
export const BUILDING_CLICKED_ADD = 'Building: clicked add';
export const BUILDING_VIEWED_SETTINGS = 'Building: viewed settings';
export const BUILDING_SAVED_SETTINGS = 'Building: saved settings';
export const BUILDING_VIEWED_STATUS = 'Building: viewed status';
export const BUILDING_ADDED_LABEL = 'Building: added label';
export const BUILDING_TOGGLED_RESET_CONNECTION = 'Building: toggled reset';
export const BUILDING_CONFIGURED_GLOW_RING = 'Building: configured glow ring';
export const BUILDING_CONFIGURED_DISPLAY = 'Building: configured display';
export const BUILDING_CONFIGURED_BATTERY_LIFE = 'Building: configured battery';
export const BUILDING_ADDED_FLOORPLAN = 'Building: added floorplan';
export const BUILDING_SORT_DEVICES = 'Building: sort devices';
export const BUILDING_SHOW_HIDE_HUB_SECTION = 'Building: show or hide hub section';
export const BUILDING_THRESHOLD_BREACH_INSIGHT_FETCHED = 'Building: fetched insight';
export const BUILDING_THRESHOLD_BREACH_TOGGLE_TREND = 'Building: toggle trend';
export const BUILDING_THRESHOLD_BREACH_EXPAND_GRAPH = 'Building: expand graph';
export const BUILDING_THRESHOLD_BREACH_INSIGHT_SELECT_THRESHOLDS = 'Building: selected thresholds';
export const BUILDING_THRESHOLD_BREACH_INSIGHT_SAVE_NEW_THRESHOLDS = 'Building: saved thresholds';
export const BUILDING_THRESHOLD_BREACH_INSIGHT_DELETED = 'Building: deleted insight';
export const BUILDING_THRESHOLD_BREACH_INSIGHT_ADDED = 'Building: added insight';
export const BUILDING_THRESHOLD_BREACH_INSIGHT_BAR_SELECTED = 'Building: selected bar';
export const BUILDINGS_VIEWED_STATUS = 'Buildings: viewed status';
export const BUILDING_THRESHOLD_BREACH_DOWNLOAD_CSV = 'Building: download csv';
export const BUILDING_THRESHOLD_BREACH_CREATED_REPORT = 'Building: created report';
export const BUILDING_THRESHOLD_BREACH_DOWNLOADED_REPORT = 'Building: downloaded report';
export const BUILDING_ENABLED_EMAIL = 'Building: enabled email';
export const BUILDING_PREVIEWED_EMAIL = 'Building: previewed email';
export const BUILDING_SENSOR_DATA_EXPORT = 'Building: sensor data export';
export const BUILDING_VIEWED_SPACES = 'Building: viewed spaces';
export const BUILDING_FILTERED_SENSORS_IN_SPACE = 'Building: filtered sensors in space';
export const BUILDING_GROUPED_SPACES = 'Building: grouped spaces';
export const BUILDING_SORTED_SPACE_LIST = 'Building: sorted spaces';
export const BUILDING_HEATING_COOLING_EXPAND_GRAPH = 'Heating cooling: expand graph';
export const BUILDING_HEATING_COOLING_DOWNLOAD_CSV = 'Heating cooling: download csv';
export const BUILDING_HEATING_COOLING_INSIGHT_FETCHED = 'Heating cooling: fetched insight';
export const BUILDING_PRESENCE_INSIGHT_FETCHED = 'Presence insight: fetched insight';
export const BUILDING_PRESENCE_INSIGHT_FILTER = 'Presence insight: filtered';
export const BUILDING_PRESENCE_INSIGHT_SET_DATE_RANGE = 'Presence insight: custom dates';
export const BUILDING_PRESENCE_INSIGHT_TOGGLE_OPENING_HOURS = 'Presence insight: Toggled opening hours';
export const BUILDING_PRESENCE_INSIGHT_EXPAND_GRAPH = 'Presence insight: Graph expanded';
export const BUILDING_PRESENCE_INSIGHT_SELECT_HEATMAP_DATE = 'Presence insight: Heatmap clicked';
export const BUILDING_PRESENCE_INSIGHT_SELECT_FROM_CHART = 'Presence insight: Clicked graph';
export const BUILDING_PRESENCE_INSIGHT_DOWNLOAD_CSV = 'Presence insight: CSV downloaded';
export const BUILDING_ENABLE_LINDAB = 'Buidling: enable lindab';

export const BUILDINGS_SELECTED_FOCUS_SENSOR = 'Buildings: selected sensor';
export const BUILDINGS_FOCUS_SELECT_THRESHOLD = 'Buildings: selected threshold';
export const BUILDINGS_FOCUS_SET_THRESHOLD_AS_DEFAULT = 'Buildings: saved threshold';
export const BUILDINGS_FOCUS_SELECT_OPENING_HOURS = 'Buildings: toggled opening hours';
export const BUILDINGS_FOCUS_SELECT_TIME = 'Buildings: selected time';
export const BUILDINGS_REMOVE_FOCUS = 'Buildings: removed focus';

export const STATUS_EXPANDED_BUILDING_ROW = 'Status: expanded row';

export const ALERT_CLICKED_ADD = 'Alert: clicked add';
export const ALERT_ADDED_ALERT = 'Alert: added alert';
export const ALERT_TOGGLED_ALERT = 'Alert: toggled alert';
export const ALERT_DELETED_ALERT = 'Alert: deleted alert';

export const INSIGHT_VIEWED_REPORTS = 'Insight: viewed reports';
export const INSIGHT_SELECTED_REPORT = 'Insight: selected report';
export const INSIGHT_DOWNLOADED_REPORT = 'Insight: downloaded report';
export const INSIGHT_CREATED_REPORT = 'Insight: created report';

export const INTEGRATION_VIEWED_INTEGRATION = 'Integration: viewed integration';
export const INTEGRATION_CLICKED_ADD = 'Integration: clicked add';
export const INTEGRATION_ADDED_INTEGRATION = 'Integration: added integration';
export const INTEGRATION_AUTHORIZED_INTEGRATION = 'Integration: authorized integration';

export const DASHBOARD_VIEWED_DASHBOARD = 'Dashboard: viewed dashboard';
export const DASHBOARD_RESET_DASHBOARD = 'Dashboard: reset dashboard';
export const DASHBOARD_ADDED_TILE = 'Dashboard: added tile';
export const DASHBOARD_CLICKED_TILE = 'Dashboard: clicked tile';
export const DASHBOARD_DELETED_TILE = 'Dashboard: deleted tile';
export const DASHBOARD_EDITED_SIZE = 'Dashboard: edited size';

export const FLOORPLAN_SELECTED_FLOORPLAN = 'Floorplan: selected floorplan';
export const FLOORPLAN_CLICKED_ADD = 'Floorplan: clicked add';
export const FLOORPLAN_PLACED_DEVICE = 'Floorplan: placed device';
export const FLOORPLAN_SELECTED_FILTER = 'Floorplan: selected filter';
export const FLOORPLAN_CLICKED_SENSOR = 'Floorplan: clicked sensor';
export const FLOORPLAN_DELETED_FLOORPLAN = 'Floorplan: deleted floorplan';
export const FLOORPLAN_REMOVED_DEVICE = 'Floorplan: removed device';

export const ACCOUNT_SUBSCRIBED_NEWSLETTER = 'Account: subscribed newsletter';
export const ACCOUNT_INVITED_USER = 'Account: invited user';
export const ACCOUNT_REMOVE_USER = 'Account: remove user';
export const ACCOUNT_DOWNLOAD_USER_LIST = 'Account: download user list';
export const ACCOUNT_CHANGED_UNITS = 'Account: change units';
export const ACCOUNT_SELECTED_LANGUAGE = 'Account: selected language';
export const ACCOUNT_CHANGED_MEASURE_UNIT = 'Account: changed measure unit';
export const ACCOUNT_VIEWED_OVERVIEW = 'Account: viewed overview';
export const ACCOUNT_SWAPPED_ACCOUNT = 'Account: swapped account';
export const ACCOUNT_SAVED_SETTINGS = 'Account: saved settings';

export const ACCOUNT_SSO_ENABLE = 'SSO: enable';
export const ACCOUNT_SSO_ACTIVATE_DOMAIN = 'SSO: activate domain';
export const ACCOUNT_SSO_DEACTIVATE_DOMAIN = 'SSO: deactivate domain';
export const ACCOUNT_SSO_DELETE_DOMAIN = 'SSO: delete domain';
export const ACCOUNT_SSO_ADD_PROVIDER = 'SSO: add provider';

export const SUBSCRIPTION_CREATED = 'Subscription: created';
export const SUBSCRIPTION_SEATS_ADDED = 'Subscription: seats added';

export const SUBSCRIPTION_LIMITED = 'Subscription: Viewed limited';
export const SUBSCRIPTION_SOON_LIMITED = 'Subscription: Viewed warning banner';

export const SPACE_CREATED_SPACE = 'Space: Created space';
export const SPACE_VIEWED_SPACE = 'Space: Viewed space';
export const SPACE_CREATED_CUSTOM_PROPERTY = 'Space: Created custom property';
export const SPACE_CREATED_CUSTOM_PROPERTY_VALUE = 'Space: Created custom property value';
export const SPACE_DELETED_SPACE = 'Space: Deleted space';
export const SPACE_ADDED_DEVICE = 'Space: Added device';
export const SPACE_REMOVED_DEVICE = 'Space: Removed device';
export const SPACE_MOVED_DEVICE = 'Space: Moved device';
export const SPACE_SELECTED_SPARKLINE = 'Space: Selected sparkline';
export const SPACE_CHANGED_TIMEFRAME = 'Space: Changed timeframe';
export const SPACE_EXPANDED_SENSORS = 'Space: Expanded sensors';
export const SPACE_DEVICE_SETTINGS = 'Space: Device settings';
export const SPACE_COMPARE_SENSORS = 'Space: Compared sensors';

export const PARTNER_SUMMARY_PAGE_VIEWED = 'Customers: viewed partner summary page';
