/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Highcharts from 'highcharts';
import moment from 'moment';
import { tooltipBorderColor, colors } from '../../constants';
import { SensorTypes } from '../../models/commonEnums';
import { ExtraSeriesPeriod, SelectedPeriod } from '../../models/commonTypeScript';
import { timeFormatter } from './graphDataFunctions';
import { dateTime, dayDate, setXAxisLabelFormat, setXAxisMinValue } from './SensorConfig';

Highcharts.Point.prototype.highlight = function highlight(): void {
    this.onMouseOver();
};

/**
 * Override the reset function, we don't need to hide the tooltips
 */
Highcharts.Pointer.prototype.reset = (): undefined => undefined;

function syncExtremes(e): void {
    if (e.trigger === 'syncExtremes') {
        return;
    }
    Highcharts.each(Highcharts.charts, chart => {
        if (!chart || chart === this.chart) return;
        if (chart.xAxis[0].setExtremes) {
            chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, { trigger: 'syncExtremes' });
        }
    });
}

const occupancyGraph = 'OccupancyLegendName';
const mainGraph = 'PresenceLegendName';
const occupancyColumnRangeData = (extraSeries: ExtraSeriesPeriod, selectedInterval: SelectedPeriod) => {
    const seriesForInterval = (extraSeries || {})[selectedInterval.name];
    return seriesForInterval?.occupantsLower?.map((dataPoint, index) => [
        ...dataPoint,
        seriesForInterval.occupantsUpper[index][1],
    ]);
};

export default ({
    chartData,
    chartHeight,
    selectedInterval,
    dateFormat,
    width,
    animate,
    displayResetZoom,
    extraSeries,
    extraSeriesUnitsForSensor,
    showExtraSeriesName,
    text,
}: {
    chartData: number[][];
    chartHeight: number;
    selectedInterval: SelectedPeriod;
    dateFormat: string;
    width: number | undefined;
    animate: boolean;
    displayResetZoom: (() => void) | undefined;
    extraSeries?: ExtraSeriesPeriod;
    extraSeriesUnitsForSensor?: { type: SensorTypes; unit: string; thresholds: number[] }[];
    showExtraSeriesName?: boolean;
    text: (text: string) => string;
}) => ({
    credits: {
        enabled: false,
    },
    title: {
        text: '',
    },
    chart: {
        height: chartHeight,
        type: 'line',
        spacingBottom: 5,
        style: {
            fontFamily: 'OpenSans-Regular',
        },
        zoomType: !selectedInterval || selectedInterval.resolution === undefined ? 'x' : 'none',
        resetZoomButton: {
            theme: {
                display: 'none',
            },
        },
        events: {
            selection(): void {
                if (displayResetZoom) {
                    displayResetZoom();
                }
            },
        },
    },
    tooltip: {
        borderColor: tooltipBorderColor,
        style: {
            color: colors.white,
        },
        enabled: true,
        hideDelay: 0,
        shared: true,
        formatter() {
            const { resolution } = selectedInterval;
            const { x, points } = this;
            const renderSeries = ({ series: { name }, point }) => {
                const { y, options } = point;
                if (name === text(mainGraph)) return `${text('Presence')}: ${timeFormatter(y)}`;
                if (name === text(occupancyGraph)) {
                    const unitForSubGraph =
                        extraSeriesUnitsForSensor && extraSeriesUnitsForSensor[0] && extraSeriesUnitsForSensor[0].unit;
                    return `${text('Occupancy')}: ${options.low} - ${options.high} ${text(
                        `SensorUnit.${unitForSubGraph}` || ''
                    )}`;
                }
                return '';
            };
            const seriesValues = points.map(renderSeries);
            return [dayDate(x, dateFormat, resolution), dateTime(x, dateFormat, resolution), ...seriesValues].join(
                '<br/>'
            );
        },
    },
    xAxis: {
        lineColor: 'transparent',
        tickLength: 0,
        tickPixelInterval: 130,
        min: setXAxisMinValue(selectedInterval),
        max: selectedInterval.endDate ? moment(selectedInterval.endDate).valueOf() : moment().valueOf(),
        type: 'datetime',
        gridLineWidth: 1,
        margin: 0,
        labels: {
            formatter(): string {
                const { value } = this;
                return Highcharts.dateFormat(setXAxisLabelFormat(selectedInterval, dateFormat, width), value);
            },
        },
        events: {
            setExtremes: syncExtremes,
        },
    },
    yAxis: [
        {
            gridLineWidth: 0,
            title: {
                text: null,
            },
            labels: {
                formatter(): string {
                    const { value } = this;
                    return timeFormatter(value);
                },
            },
        },
        {
            min: 0,
            gridLineWidth: 0,
            title: {
                text: null,
            },
            labels: {
                format: '{value}',
                style: {
                    color: colors.blueVenice,
                },
            },
            opposite: true,
        },
    ],
    series: [
        {
            type: 'line',
            lineWidth: 2,
            name: text(mainGraph),
            color: colors.blueAura,
            showInLegend: showExtraSeriesName,
            data: chartData,
            connectNulls: false,
            marker: {
                enabled: false,
            },
            states: {
                hover: { lineWidthPlus: 0 },
            },
        },
        {
            name: text(occupancyGraph),
            type: 'columnrange',
            inverted: false,
            showInLegend: showExtraSeriesName,
            data: (extraSeries && occupancyColumnRangeData(extraSeries, selectedInterval)) || [],
            connectNulls: false,
            color: colors.greyRiverBed,
            yAxis: 1,
            lineWidth: 2,
            marker: {
                enabled: false,
                states: {
                    hover: {
                        enabled: false,
                    },
                },
            },
        },
    ],
    plotOptions: {
        series: {
            states: {
                hover: { lineWidthPlus: 0 },
            },
            animation: animate ? { duration: 1200 } : false,
        },
    },
    legend: {
        verticalAlign: 'top',
        align: 'center',
        floating: true,
        y: -10,
    },
});
