import React from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { dateFormats } from 'commons/src/constants';
import { Resolution, TimePeriod } from 'commons/src/models/commonEnums';
import { BuildingInsightChartData, BuildingInsightTimeData } from '../../../models/buildingModels';
import { Store } from '../../../reducers';
import { getChartDataWithStartAndEndDate } from './buildingInsightCommonFunctions';
import generateConfig from './BuildingInsightGraphConfig';

dayjs.extend(timezone);

type ParentProps = {
    trendData: {
        aggregatedDevices: BuildingInsightChartData[];
        devicesInTimeFrame: { [date: string]: BuildingInsightTimeData };
    };
    timeZone?: string;
    selectedPeriod: {
        toDate: string;
        fromDate: string;
        resolution: Resolution;
        name: TimePeriod;
    };
    highThreshold: string;
    lowThreshold?: string;
    onPointClick: (pointId: string) => void;
    displayLegends: boolean;
    allowGraphClick: boolean;
    graphId: string;
};

type StateProps = {
    dateFormat: keyof typeof dateFormats;
};

type Props = StateProps & ParentProps;

const TrendOverTime = ({
    selectedPeriod,
    onPointClick,
    timeZone,
    dateFormat,
    highThreshold,
    lowThreshold,
    displayLegends,
    trendData,
    allowGraphClick,
    graphId,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const chartDataForSelectedPeriod =
        selectedPeriod.name === TimePeriod.day
            ? trendData.aggregatedDevices.filter(data => {
                  const twentyFourHoursAgo = moment.utc().subtract(25, 'hours');
                  return moment.utc(data.date).isAfter(twentyFourHoursAgo);
              })
            : trendData.aggregatedDevices;
    const underThresholdBreaches: [number, number][] = [];
    const chartDataFormatted: [number, number][] = chartDataForSelectedPeriod.map(data => {
        const dateTime =
            selectedPeriod?.resolution === Resolution.hour ? dayjs.tz(data.date, timeZone) : moment.utc(data.date);
        underThresholdBreaches.push([dateTime.unix() * 1000, data.underThresholdsTime]);
        return [dateTime.unix() * 1000, data.overThresholdsTime];
    });

    const onGraphClick = (clickData: PointerEvent): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const pointOnXAxis = clickData.point.category;
        const indexOfPoint = chartDataFormatted.findIndex(data => data[0] === pointOnXAxis);
        const pointId = chartDataForSelectedPeriod[indexOfPoint].date;
        onPointClick(pointId);
    };

    const chartDataWithStartAndEndDate = getChartDataWithStartAndEndDate(
        chartDataFormatted,
        selectedPeriod.fromDate,
        selectedPeriod.toDate,
        timeZone
    );

    const isHourFormat = selectedPeriod.resolution === Resolution.hour;

    const graphConfig = generateConfig({
        chartData: chartDataWithStartAndEndDate,
        chartDataLowerThresholdBreaches: underThresholdBreaches,
        dateFormat,
        onClick: onGraphClick,
        displayLegends,
        isHourFormat,
        highValue: highThreshold,
        lowValue: lowThreshold || undefined,
        chartHeight: displayLegends ? 200 : 175,
        text: txt,
        allowGraphClick,
        graphId,
        timeZone,
    });

    return <HighchartsReact highcharts={Highcharts} options={graphConfig} />;
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = store;
    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(TrendOverTime);
