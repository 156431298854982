import React from 'react';
import { useTranslation } from 'react-i18next';
import noData from '../../img/icon-no-data';

type Props = {
    message: string;
};

export const NoDataTileBodyComponent = ({ message }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className="tile-body__inner tile-body__inner--centered-content">
            <div className="tile-body--icon-wrapper">{noData}</div>
            <div className="tile-body__message">{txt(message)}</div>
        </div>
    );
};

export default NoDataTileBodyComponent;
