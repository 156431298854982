import { useState } from 'react';
import { SortDirection } from '@mui/material';
import { MaterialTableColumn } from '../models';

type UseTableReturnValue = {
    tableColumns: MaterialTableColumn[];
    availableColumns: MaterialTableColumn[];
    sortChangeHandler: <T extends { accessor: string; sortable: boolean }>(header: T) => SortDirection;
    getSelectedColumn: (accessor: string) => SortDirection;
    updateColumns: (newColumns: MaterialTableColumn[]) => void;
};

const getColumnsWithoutDefaultHidden = (columns: MaterialTableColumn[]): MaterialTableColumn[] =>
    columns.filter(column => (column?.locked ? true : !column.defaultHidden));

const useTableColumns = (columns: MaterialTableColumn[]): UseTableReturnValue => {
    const [availableColumns] = useState<MaterialTableColumn[]>(columns);
    const [currentColumns, setCurrentColumns] = useState<MaterialTableColumn[]>(
        getColumnsWithoutDefaultHidden(columns)
    );

    const [selectedColumn, setSelectedColumn] = useState<string | null>(null);
    const [selectedColumnDirection, setSelectedColumnDirection] = useState<SortDirection>(false);

    const toggleSortingDirection = (current: SortDirection): SortDirection => {
        if (current === 'asc') return 'desc';
        if (current === 'desc') return false;
        return 'asc';
    };

    const assignSortingDirection = <T extends { accessor: string; sortable: boolean }>(header: T): SortDirection => {
        if (header.sortable) {
            if (selectedColumn !== header.accessor) {
                return 'asc';
            }
            return toggleSortingDirection(selectedColumnDirection);
        }
        return false;
    };

    const sortChangeHandler = <T extends { accessor: string; sortable: boolean }>(header: T): SortDirection => {
        const newDirection = assignSortingDirection(header);
        setSelectedColumn(header.accessor);
        setSelectedColumnDirection(newDirection);
        return newDirection;
    };

    const getSelectedColumn = (accessor: string): SortDirection =>
        accessor === selectedColumn ? selectedColumnDirection : false;

    const updateColumns = (newColumns: MaterialTableColumn[]): void => {
        setCurrentColumns(newColumns);
    };

    return { tableColumns: currentColumns, availableColumns, sortChangeHandler, getSelectedColumn, updateColumns };
};

export default useTableColumns;
