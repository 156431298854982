import { all } from 'redux-saga/effects';
import acceptedOrganizationInviteSaga from './acceptedOrganizationInviteSaga';
import apiClients from './apiClients';
import getOrganizationInvites from './authentication/getOrganizationInvites';
import getSubscriptionForGroup from './authentication/getSubscriptionsSaga';
import getUserInfoSaga from './authentication/getUserInfo';
import logOut from './authentication/logout';
import logoutAllUnits from './authentication/logoutAllUnits';
import organizationInvitesResponse from './authentication/organizationInviteResponse';
import dashboardConfig from './DashboardSagas/dashboardConfig';
import dashboardData from './DashboardSagas/dashboardData';
import fetchDashboardSensor from './DashboardSagas/fetchDashboardSensorTile';
import publicDashboard from './DashboardSagas/publicDashboard';
import changeLocation from './DevicePageSagas/changeLocation';
import deleteDevice from './DevicePageSagas/deleteDevice';
import deviceConfig from './DevicePageSagas/deviceConfig';
import downloadCSV from './DevicePageSagas/downloadCSV';
import fetchDeviceSegment from './DevicePageSagas/fetchCustomDeviceSegment';
import fetchSegmentWithId from './DevicePageSagas/fetchSegmentWithId';
import fetchSegmentWithIdPeriod from './DevicePageSagas/fetchSegmentWithIdPeriod';
import renameSegment from './DevicePageSagas/renameSegment';
import { alertBoxSagas } from './displayAlertBox';
import fetchLocationsStatus from './fetchLocationsStatusSaga';
import fetchSensorSegment from './fetchSensorSegment';
import generateRadonHomeReport from './generateRadonHomeReport';
import deleteHub from './HubSagas';
import initialize from './initializeApp';
import locations from './Locations/locations';
import locationsHistory from './Locations/locationsHistory';
import fetchOutdoorComparisonTileData from './OutdoorAirSagas/fetchIndoorVsOutdoorTileData';
import fetchOutdoorAirQuality from './OutdoorAirSagas/fetchOutdoorAirInfo';
import fetchOutdoorAirTileData from './OutdoorAirSagas/fetchOutdoorTileData';
import pollDashboardSensorData from './pollData/pollDashboardSensorData';
import pollDeviceData from './pollData/pollDeviceData';
import selectGroupSaga from './selectGroupSaga';
import changePassword from './SettingsSagas/changePassword';
import emailDomainLookupSaga from './SettingsSagas/emailDomainLookupSaga';
import newsletterSignUp from './SettingsSagas/newsletterSignUp';
import updateUserSettings from './SettingsSagas/updateUserSettingsSaga';
import unsubscribeEmailAlertSaga from './UnsubscribeAlertEmailSaga';

export default function* sagas(): Generator {
    yield all([
        acceptedOrganizationInviteSaga(),
        alertBoxSagas(),
        changeLocation(),
        changePassword(),
        dashboardConfig(),
        dashboardData(),
        deleteDevice(),
        deleteHub(),
        deviceConfig(),
        downloadCSV(),
        fetchDashboardSensor(),
        fetchDeviceSegment(),
        fetchLocationsStatus(),
        fetchOutdoorAirQuality(),
        fetchOutdoorAirTileData(),
        fetchOutdoorComparisonTileData(),
        fetchSegmentWithId(),
        fetchSegmentWithIdPeriod(),
        fetchSensorSegment(),
        generateRadonHomeReport(),
        getOrganizationInvites(),
        getSubscriptionForGroup(),
        getUserInfoSaga(),
        initialize(),
        locations(),
        locationsHistory(),
        logOut(),
        logoutAllUnits(),
        newsletterSignUp(),
        organizationInvitesResponse(),
        pollDashboardSensorData(),
        pollDeviceData(),
        publicDashboard(),
        renameSegment(),
        selectGroupSaga(),
        updateUserSettings(),
        unsubscribeEmailAlertSaga(),
        apiClients(),
        emailDomainLookupSaga(),
    ]);
}
