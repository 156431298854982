import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { DeviceIcon, sensorIconSource, SubHeader } from 'commons/src/components/PDF/PdfConstants';
import { PDFContext } from '../../../../models/common';

type SolutionProps = {
    sensor: string;
    header: string;
    description: string;
    txt: PDFContext;
};

const componentStyles = StyleSheet.create({
    solutionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 10,
        alignItems: 'center',
        // contentAlign: 'center',
        width: '78vw',
    },
    solutionHeader: {
        fontSize: 12,
        marginTop: 8,
    },
});

const RiskSolution = ({ sensor, header, description, txt }: SolutionProps): React.ReactElement => (
    <View>
        <View style={componentStyles.solutionWrapper}>
            <DeviceIcon src={sensorIconSource(sensor) || ''} />
            <View>
                <Text style={componentStyles.solutionHeader}>{txt(header)}</Text>
                <Text>{txt(description)}</Text>
            </View>
        </View>
    </View>
);

const PossibleSolutions = ({ txt }: { txt: PDFContext }): React.ReactElement => (
    <View>
        <SubHeader>{txt('InsightRiskFactor.PossibleSolutions')}</SubHeader>
        <View>
            <RiskSolution
                sensor="temp"
                header="VirusInsight.WarmerTemperature"
                description="InsightRiskFactor.WarmerTemperaturesSolutionDescription"
                txt={txt}
            />
            <RiskSolution
                sensor="decreaseOccupancy"
                header="VirusInsight.DecreaseOccupancy"
                description="InsightRiskFactor.DecreaseOccupancyDescription"
                txt={txt}
            />
            <RiskSolution
                sensor="ventilationRate"
                header="VirusInsight.IncreaseVentilationRate"
                description="InsightRiskFactor.IncreaseVentilationRateDescription"
                txt={txt}
            />
            <RiskSolution
                sensor="humidity"
                header="VirusInsight.RetainHumidityLevels"
                description="InsightRiskFactor.RetainHumidityLevelsDescription"
                txt={txt}
            />
        </View>
    </View>
);

export default PossibleSolutions;
