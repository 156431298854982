import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import {
    EditNotificationAlert,
    NewNotificationAlert,
    NewThirdPartyIntegration,
    NewThirdPartyMapping,
    NewWebhook,
    NotificationAlertEvent,
    ThirdPartyIntegration,
    ThirdPartyIntegrationMapping,
    Webhook,
    WebhookEvent,
    MqttClient,
    NewMqttClient,
    MqttClientCertificate,
    EditMqttClient,
} from '../models/common';

export default async (): Promise<{ webhooks: Webhook[] }> =>
    fetch('/webhooks', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const addNewWebhook = async (webhook: NewWebhook): Promise<{ id: string }> =>
    fetch('/webhooks', {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(webhook),
    });

export const updateWebhook = async (webhook: Webhook): Promise<void> =>
    fetch(`/webhooks/${webhook.id}`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(webhook),
    });

export const deleteWebhook = async (webhookId: string): Promise<void> =>
    fetch(`/webhooks/${webhookId}`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });

export const fetchWebhookEvents = async (webhookId: string): Promise<{ events: WebhookEvent[] }> =>
    fetch(`/webhooks/${webhookId}/events`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const testWebhook = async (webhookId: string): Promise<void> =>
    fetch(`/webhooks/${webhookId}/manual-trigger`, {
        method: 'POST',
        headers: await getFetchHeaders(),
    });

// third-party
export const fetchThirdPartyIntegrations = async (): Promise<{ integrations: ThirdPartyIntegration[] }> =>
    fetch('/alert-integrations', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const addAlertTrigger = async (
    integrationId: string,
    alert: NewNotificationAlert
): Promise<{ json: { id: string } }> =>
    fetch(`/alert-integrations/${integrationId}/triggers`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(alert),
    });

export const updateAlertTrigger = async (alert: EditNotificationAlert): Promise<void> =>
    fetch(`/alert-integrations/${alert.integrationId}/triggers/${alert.triggerId}`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(alert.trigger),
    });

export const deleteAlertTrigger = async (integrationId: string, triggerId: string): Promise<void> =>
    fetch(`/alert-integrations/${integrationId}/triggers/${triggerId}`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });

export const fetchAlertEvents = async (): Promise<{ events: NotificationAlertEvent[] }> =>
    fetch('/alert-integrations/events-history', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const addThirdPartyIntegration = async (integration: NewThirdPartyIntegration): Promise<{ id: string }> =>
    fetch('/alert-integrations', {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(integration),
    });

export const updateThirdPartyIntegration = async (
    integrationId: string,
    integration: NewThirdPartyIntegration
): Promise<void> =>
    fetch(`/alert-integrations/${integrationId}`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(integration),
    });

export const fetchThirdPartyIntegrationClientID = async (
    integrationType: string
): Promise<{ clientId: string; scope: string }> =>
    fetch(`/alert-integrations/credentials?channel=${integrationType}`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const registerThirdPartyIntegrationMapping = async (
    integrationId: string,
    thirdPartyMapping: NewThirdPartyMapping[]
): Promise<void> =>
    fetch(`/alert-integrations/${integrationId}/locations-mapping`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify({
            locations: thirdPartyMapping,
        }),
    });

export const fetchThirdPartyIntegrationMapping = async (integrationId: string): Promise<ThirdPartyIntegrationMapping> =>
    fetch(`/alert-integrations/${integrationId}/locations-mapping`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const deleteThirdPartyIntegration = async (integrationId: string): Promise<void> =>
    fetch(`/alert-integrations/${integrationId}`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });

// MQTT
export const fetchMqttClients = async (): Promise<{ clients: MqttClient[] }> =>
    fetch('/mqtt/client', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const addNewMqttClient = async (client: NewMqttClient): Promise<MqttClient> =>
    fetch('/mqtt/client', {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(client),
    });

export const updateMqttClient = async (client: EditMqttClient): Promise<MqttClient> =>
    fetch(`/mqtt/client/${client.id}`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(client),
    });

export const updateMqttClientActiveState = async (client: MqttClient, active: boolean): Promise<MqttClient> =>
    fetch(`/mqtt/client/${client.id}/certificate/${active ? 'activate' : 'deactivate'}`, {
        method: 'PATCH',
        headers: await getFetchHeaders(),
    });

export const deleteMqttClient = async (clientId: string): Promise<void> =>
    fetch(`/mqtt/client/${clientId}`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });

export const fetchMqttClientCertificate = async (clientId: string): Promise<MqttClientCertificate> =>
    fetch(`/mqtt/client/${clientId}/certificate`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const uploadMqttClientCertificate = async (
    clientId: string,
    certificate: string
): Promise<MqttClientCertificate> =>
    fetch(`/mqtt/client/${clientId}/certificate`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ certificate }),
    });

export const deleteMqttClientCertificate = async (clientId: string): Promise<void> =>
    fetch(`/mqtt/client/${clientId}/certificate`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });

export const renewMqttClientCertificate = async (
    clientId: string,
    certificate?: string
): Promise<MqttClientCertificate> =>
    fetch(`/mqtt/client/${clientId}/certificate/renew/${certificate ? 'self-signed' : 'aws'}`, {
        method: 'PATCH',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ certificate }),
    });
