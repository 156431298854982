import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { ApiClient, ClientSecret, NewApiClient } from '../models/apiClient';
import { fetchFromAccounts } from './fetch';

export const registerApiClient = async (client: NewApiClient): Promise<ApiClient> =>
    fetchFromAccounts<ApiClient>('/clients', {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(client),
    });
export const fetchClients = async (): Promise<ApiClient[]> =>
    fetchFromAccounts<ApiClient[]>('/clients/', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
export const updateClient = async (client: ApiClient): Promise<void> =>
    fetchFromAccounts<void>(`/clients/${client.id}`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(client),
    });
export const deleteClient = async (clientId: string): Promise<void> =>
    fetchFromAccounts<void>(`/clients/${clientId}`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });
export const fetchClientSecret = async (clientId: string): Promise<ClientSecret> =>
    fetchFromAccounts<ClientSecret>(`/clients/${clientId}/secret`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
