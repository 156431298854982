import {
    ADD_API_CLIENT_SUCCESS,
    DELETE_API_CLIENT_SUCCESS,
    FETCH_API_CLIENTS_SUCCESS,
    UPDATE_API_CLIENT_SUCCESS,
    FETCH_API_CLIENT_SECRET_SUCCESS,
    ApiClientsActionType,
    HIDE_API_CLIENT_SECRET,
    ApiClientReducerActions,
} from '../actions/apiClientActions';
import { ApiClient, ClientSecret } from '../models/apiClient';

export type ApiClientsState = {
    apiClients: ApiClient[];
    clientSecret: ClientSecret | undefined;
};

const initialState: ApiClientsState = {
    apiClients: [],
    clientSecret: undefined,
};

const { UpdateApiClientActiveStateInit, FetchApiClientSecretInit } = ApiClientsActionType;

export default (state: ApiClientsState = initialState, action: ApiClientReducerActions): ApiClientsState => {
    switch (action.type) {
        case FETCH_API_CLIENTS_SUCCESS:
            return {
                ...state,
                apiClients: action.apiClients,
            };
        case UpdateApiClientActiveStateInit: {
            const indexOfEditedClient = state.apiClients.findIndex(client => client.id === action.apiClient.id);
            const copyOfClientList = [...state.apiClients];
            copyOfClientList[indexOfEditedClient] = action.apiClient;
            return {
                ...state,
                apiClients: copyOfClientList,
            };
        }
        case UPDATE_API_CLIENT_SUCCESS: {
            const indexOfEditedClient = state.apiClients.findIndex(
                client => action.apiClient && client.id === action.apiClient.id
            );
            const copyOfClientList = [...state.apiClients];
            copyOfClientList[indexOfEditedClient] = action.apiClient;
            return {
                ...state,
                apiClients: copyOfClientList,
            };
        }
        case ADD_API_CLIENT_SUCCESS:
            return {
                ...state,
                apiClients: [...state.apiClients, action.apiClient],
            };
        case DELETE_API_CLIENT_SUCCESS:
            return {
                ...state,
                apiClients: state.apiClients.filter(client => client.id !== action.clientId),
            };
        case FetchApiClientSecretInit:
            return {
                ...state,
                clientSecret: undefined,
            };
        case FETCH_API_CLIENT_SECRET_SUCCESS:
            return {
                ...state,
                clientSecret: action.secret,
            };
        case HIDE_API_CLIENT_SECRET:
            return {
                ...state,
                clientSecret:
                    state.clientSecret && state.clientSecret.clientId === action.clientId
                        ? undefined
                        : state.clientSecret,
            };
        default:
            return state;
    }
};
