import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import moment, { unitOfTime } from 'moment';
import { getValidLanguageTagForMoment, isVirtualDevice } from 'commons/src/commonFunctions';
import i18n from 'commons/src/i18n';
import { Resolution, TimePeriod } from 'commons/src/models/commonEnums';
import { AnyDeviceType, DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { BuildingInsight } from '../../../models/buildingModels';

dayjs.extend(timezone);

type DurationConstructor = unitOfTime.DurationConstructor;

export const getChartDataWithStartAndEndDate = (
    formattedChartData: [number, number][],
    fromDate: string,
    toDate: string,
    timeZone?: string
): [number, number][] => {
    const startTime = (timeZone ? dayjs.tz(fromDate, timeZone) : dayjs(fromDate)).startOf('day').unix() * 1000;
    const endTime = (timeZone ? dayjs.tz(toDate, timeZone) : dayjs(toDate)).startOf('day').unix() * 1000; // minus one to prevent displaying the next day.
    const updatedWithStartAndEndDate: [number, number][] = [...formattedChartData].sort((element1, element2) => {
        return element1[0] - element2[0];
    });
    if (formattedChartData.length === 0 || startTime < formattedChartData[0][0]) {
        updatedWithStartAndEndDate.unshift([startTime, 0]);
    }
    if (formattedChartData.length === 0 || endTime > formattedChartData[formattedChartData.length - 1][0]) {
        updatedWithStartAndEndDate.push([endTime, 0]);
    }
    return updatedWithStartAndEndDate;
};

const humanizedTimeString = (outsideThresholdsTime: number, shortText?: boolean): string => {
    const validLanguageKey = getValidLanguageTagForMoment();
    const daysOutside = Math.floor(outsideThresholdsTime / (60 * 24));
    const hoursOutside = Math.floor(outsideThresholdsTime / 60 - daysOutside * 24);
    const minutesOutside = outsideThresholdsTime - (daysOutside * 24 * 60 + hoursOutside * 60);
    if (shortText) {
        let textString = '';
        if (daysOutside > 0) textString = `${textString} ${daysOutside}${i18n.t('TimeUnit.DaysShort')}`;
        if (hoursOutside > 0) textString = `${textString} ${hoursOutside}${i18n.t('TimeUnit.HoursShort')}`;
        if (minutesOutside > 0) textString = `${textString} ${minutesOutside}${i18n.t('TimeUnit.MinutesShort')}`;
        return textString;
    }
    moment.relativeTimeThreshold('h', 24); // to prevent humanize to round the numbers;
    moment.relativeTimeThreshold('m', 60);
    const daysInText = moment.duration(daysOutside, 'days').locale(validLanguageKey).humanize();
    const hoursInText = moment.duration(hoursOutside, 'hours').locale(validLanguageKey).humanize();
    const minutesInText = moment.duration(minutesOutside, 'minutes').locale(validLanguageKey).humanize();
    let dayText = daysOutside > 0 ? daysInText : '';
    const hourText = hoursOutside > 0 ? hoursInText : '';
    let minutesText = minutesOutside > 0 ? ` ${i18n.t('BuildingInsight.And')} ${minutesInText}` : '';
    if (daysOutside > 0 && hoursOutside > 0) dayText = `${daysInText}, `;
    if (hoursOutside === 0 && daysOutside === 0) minutesText = minutesInText;

    return `${dayText}${hourText}${minutesText}`;
};

export default humanizedTimeString;

export const getDateHeaders = (selectedPeriod: {
    toDate: string;
    fromDate: string;
    resolution: Resolution;
    name: TimePeriod;
}): string[] => {
    const duration =
        moment(selectedPeriod.toDate)
            .endOf('day')
            .diff(moment(selectedPeriod.fromDate), selectedPeriod.resolution as DurationConstructor) + 1;
    return Array.from(Array(duration).keys()).map(dayIndex =>
        moment(selectedPeriod.fromDate)
            .add(dayIndex, selectedPeriod.resolution as DurationConstructor)
            .format(selectedPeriod.resolution === Resolution.hour ? 'YYYY-MM-DDTHH' : 'YYYY-MM-DD')
    );
};

export const getCsvData = (
    thresholdBreachInsights: BuildingInsight[],
    buildingName: string,
    datesArray: string[],
    timeZone?: string
): { deviceList: (string | number)[][]; fileName: string }[] =>
    thresholdBreachInsights.map(thresholdBreach => {
        const { sensor } = thresholdBreach;
        const { unit } = thresholdBreach;
        const thresholdsString = thresholdBreach.thresholds.reduce((acc, threshold) => {
            const thresholdString =
                acc.length === 0
                    ? `Time ${threshold.type} ${threshold.value}${unit}`
                    : ` and ${threshold.type} ${threshold.value}${unit}`;
            return acc + thresholdString;
        }, '');
        const deviceList = thresholdBreach.totalDeviceData.devicesInTimeFrame.map(device => {
            const { serialNumber } = device;
            const deviceName = device.name;

            const datesWithBreachTime = datesArray.map(date => {
                const breachTimeForDate = thresholdBreach.totalDeviceData.devicesOverTime[serialNumber].find(
                    breachData => {
                        const dateFormatted = timeZone
                            ? moment.utc(breachData.date).tz(timeZone).format('YYYY-MM-DDTHH')
                            : moment.utc(breachData.date).format('YYYY-MM-DDTHH');
                        return dateFormatted.includes(date);
                    }
                );
                return breachTimeForDate
                    ? breachTimeForDate.underThresholdsTime + breachTimeForDate.overThresholdsTime
                    : '-';
            });
            return [deviceName, ...datesWithBreachTime];
        });
        return {
            fileName: `${buildingName} - ${sensor} - ${thresholdsString}`,
            deviceList,
        };
    });

export const numberOfPhysicalDevicesInBuilding = (
    devicesWithKeyInfo: { [snr: string]: DeviceWithKeyInfo },
    buildingDevices: string[]
): number =>
    (
        buildingDevices.filter(snr => {
            const deviceType = devicesWithKeyInfo[snr] && devicesWithKeyInfo[snr].type;
            return deviceType && !isVirtualDevice(deviceType as AnyDeviceType);
        }) || []
    ).length;
