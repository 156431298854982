import { RequiredRoleLevel, Role } from '../../models/commonEnums';

export const groupRequiredRole: { [name: string]: Role[] } = {
    ABOVE_ADMIN: [Role.ADMIN, Role.SUPPORT],
    ABOVE_EDITOR: [Role.USER, Role.ADMIN, Role.SUPPORT],
    ABOVE_USER: [Role.USER, Role.ADMIN, Role.SUPPORT],
    SERVICE_ACCOUNT: [Role.SERVICE_ACCOUNT],
    ANY_ROLE: [Role.USER, Role.ADMIN, Role.VIEWER, Role.SUPPORT],
    NA: [],
};

export const userRoleAboveRequiredLevel = (
    userRole: Role,
    requiredRole: RequiredRoleLevel,
    accessWithResourceFilter = true
): boolean => requiredRole && groupRequiredRole[requiredRole].includes(userRole) && accessWithResourceFilter;
