import { FeatureToggleEnum, GroupType } from '../../models/commonEnums';
import { FeatureToggleRequirement } from '../../models/featureToggleModels';

export const hasFeatureToggle = (featureToggle: FeatureToggleEnum, featureToggles?: FeatureToggleEnum[]): boolean =>
    !!featureToggle && (featureToggles || []).includes(featureToggle);

export const hasFeatureToggles = (
    requiredOrExcludedToggles: FeatureToggleEnum[],
    featureToggles?: FeatureToggleEnum[]
): boolean => {
    const filteredOutOldPartnerViewToggle = requiredOrExcludedToggles.filter(
        item => item !== FeatureToggleEnum.oldPartnerView
    );
    return (featureToggles || []).some(feature => filteredOutOldPartnerViewToggle.includes(feature));
};

const isRequiredGroupType = (
    requiredGroupTypes: GroupType[],
    excludedFeatureToggles: FeatureToggleEnum[],
    featureToggles: FeatureToggleEnum[],
    groupType?: GroupType
): boolean => {
    // first a hack to show everything for partners that still has businesses.
    const userShouldSeeEverything =
        excludedFeatureToggles.includes(FeatureToggleEnum.oldPartnerView) &&
        featureToggles.includes(FeatureToggleEnum.oldPartnerView);
    if (groupType === GroupType.partner && userShouldSeeEverything) return true;
    return !!groupType && requiredGroupTypes.includes(groupType);
};

const filterOnFeatureToggles = <T extends FeatureToggleRequirement>(
    items: T[],
    groupType?: GroupType,
    featureToggles?: FeatureToggleEnum[]
): T[] =>
    items
        .filter(
            (item: FeatureToggleRequirement) =>
                !item.requiredFeatureToggles ||
                item.requiredFeatureToggles.length === 0 ||
                hasFeatureToggles(item.requiredFeatureToggles || [], featureToggles || [])
        )
        .filter(
            (item: FeatureToggleRequirement) =>
                !item.excludedFeatureToggles ||
                item.excludedFeatureToggles.length === 0 ||
                !hasFeatureToggles(item.excludedFeatureToggles || [], featureToggles || [])
        )
        .filter(
            (item: FeatureToggleRequirement) =>
                !item.requiredGroupTypes ||
                item.requiredGroupTypes.length === 0 ||
                isRequiredGroupType(
                    item.requiredGroupTypes,
                    item.excludedFeatureToggles || [],
                    featureToggles || [],
                    groupType
                )
        );

export default filterOnFeatureToggles;
