/* eslint max-len: ["error", 1000] */
import React from 'react';

const ForecastHazy = (): React.ReactElement => (
    <svg
        version="1.1"
        className="weather-icon"
        width="30px"
        height="30px"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
    >
        <path d="M1.2,14.3h27.4c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3H1.3C0.6,11.7,0,12.3,0,13S0.5,14.3,1.2,14.3z" />
        <path d="M12.1,10.3h14c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3h-14c-0.7,0-1.3,0.6-1.3,1.3S11.4,10.3,12.1,10.3z" />
        <path d="M3.9,10.3H8c0.7,0,1.3-0.6,1.3-1.3S8.7,7.7,8,7.7H3.9C3.2,7.7,2.6,8.3,2.6,9S3.2,10.3,3.9,10.3z" />
        <path d="M6.4,19.7c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h17c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3H6.4z" />
        <path d="M17.9,15.7h-14c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h14c0.7,0,1.3-0.6,1.3-1.3S18.6,15.7,17.9,15.7z" />
        <path d="M26.1,15.7H22c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h4.1c0.7,0,1.3-0.6,1.3-1.3S26.8,15.7,26.1,15.7z" />
    </svg>
);
export default ForecastHazy;
