import { FloorPlanPosition, FloorPlanType, FloorplanZone } from '../models/commonTypeScript';

export const ADD_FLOORPLAN = 'ADD_FLOORPLAN_TO_LOCATION';
export interface AddFloorplan {
    type: typeof ADD_FLOORPLAN;
    locationId: string;
    floorplanName: string;
    floorplanImage: string;
}
export const addFloorplan = (locationId: string, floorplanName: string, floorplanImage: string): AddFloorplan => ({
    type: ADD_FLOORPLAN,
    locationId,
    floorplanName,
    floorplanImage,
});

export const ADD_FLOORPLAN_SUCCESS = 'ADD_FLOORPLAN_SUCCESS';
export interface AddFloorplanSuccess {
    type: typeof ADD_FLOORPLAN_SUCCESS;
    locationId: string;
    floorplan: FloorPlanType;
}
export const addFloorplanSuccess = (locationId: string, floorplan: FloorPlanType): AddFloorplanSuccess => ({
    type: ADD_FLOORPLAN_SUCCESS,
    locationId,
    floorplan,
});

export const ADD_FLOORPLAN_ERROR = 'ADD_FLOORPLAN_ERROR';
export interface AddFloorplanError {
    type: typeof ADD_FLOORPLAN_ERROR;
}
export const addFloorplanError = (): AddFloorplanError => ({ type: ADD_FLOORPLAN_ERROR });

export const DELETE_FLOORPLAN = 'DELETE_FLOORPLAN';
export interface DeleteFloorplan {
    type: typeof DELETE_FLOORPLAN;
    floorplanId: string;
    locationId: string;
}
export const deleteFloorplan = (floorplanId: string, locationId: string): DeleteFloorplan => ({
    type: DELETE_FLOORPLAN,
    floorplanId,
    locationId,
});

export const DELETE_FLOORPLAN_SUCCESS = 'DELETE_FLOORPLAN_SUCCESS';
export interface DeleteFloorplanSuccess {
    type: typeof DELETE_FLOORPLAN_SUCCESS;
    floorplanId: string;
    locationId: string;
}
export const deleteFloorplanSuccess = (floorplanId: string, locationId: string): DeleteFloorplanSuccess => ({
    type: DELETE_FLOORPLAN_SUCCESS,
    floorplanId,
    locationId,
});

export const DELETE_FLOORPLAN_ERROR = 'DELETE_FLOORPLAN_ERROR';
export interface DeleteFloorplanError {
    type: typeof DELETE_FLOORPLAN_ERROR;
}
export const deleteFloorplanError = (): DeleteFloorplanError => ({ type: DELETE_FLOORPLAN_ERROR });

export const ADD_FLOORPLAN_ZONE_AND_DEVICE = 'ADD_ZONE_AND_DEVICE_TO_FLOORPLAN';
export interface AddZoneAndDeviceToFloorplan {
    type: typeof ADD_FLOORPLAN_ZONE_AND_DEVICE;
    floorplanId: string;
    serialNumber: string;
    zoneBoundary: FloorPlanPosition[];
    devicePosition: FloorPlanPosition;
    locationId: string;
}
export const addZoneAndDeviceToFloorplan = (
    floorplanId: string,
    serialNumber: string,
    zoneBoundary: FloorPlanPosition[],
    devicePosition: FloorPlanPosition,
    locationId: string
): AddZoneAndDeviceToFloorplan => ({
    type: ADD_FLOORPLAN_ZONE_AND_DEVICE,
    floorplanId,
    serialNumber,
    zoneBoundary,
    devicePosition,
    locationId,
});

export const ADD_FLOORPLAN_ZONE_AND_DEVICE_SUCCESS = 'ADD_FLOORPLAN_ZONE_AND_DEVICE_SUCCESS';
export interface AddFloorplanZoneAndDeviceSuccess {
    type: typeof ADD_FLOORPLAN_ZONE_AND_DEVICE_SUCCESS;
    zone: FloorplanZone;
    locationId: string;
    floorPlanId: string;
}
export const addFloorplanZoneAndDeviceSuccess = (
    zone: FloorplanZone,
    locationId: string,
    floorPlanId: string
): AddFloorplanZoneAndDeviceSuccess => ({
    type: ADD_FLOORPLAN_ZONE_AND_DEVICE_SUCCESS,
    zone,
    locationId,
    floorPlanId,
});

export const ADD_FLOORPLAN_ZONE_AND_DEVICE_ERROR = 'ADD_FLOORPLAN_ZONE_AND_DEVICE_ERROR';
export interface AddFloorplanZoneAndDeviceError {
    type: typeof ADD_FLOORPLAN_ZONE_AND_DEVICE_ERROR;
}
export const addFloorplanZoneAndDeviceError = (): AddFloorplanZoneAndDeviceError => ({
    type: ADD_FLOORPLAN_ZONE_AND_DEVICE_ERROR,
});

export const UPDATE_FLOORPLAN_ZONE_AND_DEVICE = 'UPDATE_FLOORPLAN_ZONE_AND_DEVICE';
export interface UpdateZoneAndDeviceFloorplan {
    type: typeof UPDATE_FLOORPLAN_ZONE_AND_DEVICE;
    floorplanId: string;
    serialNumber: string;
    zoneId: string;
    zoneBoundary: FloorPlanPosition[];
    devicePosition: FloorPlanPosition;
    locationId: string;
}
export const updateZoneAndDeviceFloorplan = (
    floorplanId: string,
    serialNumber: string,
    zoneId: string,
    zoneBoundary: FloorPlanPosition[],
    devicePosition: FloorPlanPosition,
    locationId: string
): UpdateZoneAndDeviceFloorplan => ({
    type: UPDATE_FLOORPLAN_ZONE_AND_DEVICE,
    floorplanId,
    serialNumber,
    zoneId,
    zoneBoundary,
    devicePosition,
    locationId,
});

export const UPDATE_FLOORPLAN_ZONE_AND_DEVICE_SUCCESS = 'UPDATE_FLOORPLAN_ZONE_AND_DEVICE_SUCCESS';
export interface UpdateZoneAndDeviceFloorplanSuccess {
    type: typeof UPDATE_FLOORPLAN_ZONE_AND_DEVICE_SUCCESS;
    zone: FloorplanZone;
    locationId: string;
}
export const updateZoneAndDeviceFloorplanSuccess = (
    zone: FloorplanZone,
    locationId: string
): UpdateZoneAndDeviceFloorplanSuccess => ({
    type: UPDATE_FLOORPLAN_ZONE_AND_DEVICE_SUCCESS,
    zone,
    locationId,
});

export const UPDATE_FLOORPLAN_ZONE_AND_DEVICE_ERROR = 'UPDATE_FLOORPLAN_ZONE_AND_DEVICE_ERROR';
export interface UpdateZoneAndDeviceFloorplanError {
    type: typeof UPDATE_FLOORPLAN_ZONE_AND_DEVICE_ERROR;
    zoneId: string;
}
export const updateZoneAndDeviceFloorplanError = (zoneId: string): UpdateZoneAndDeviceFloorplanError => ({
    type: UPDATE_FLOORPLAN_ZONE_AND_DEVICE_ERROR,
    zoneId,
});

export const REMOVE_FLOORPLAN_ZONE_DEVICE = 'REMOVE_FLOORPLAN_ZONE_DEVICE';
export interface RemoveDeviceFromFloorplanZone {
    type: typeof REMOVE_FLOORPLAN_ZONE_DEVICE;
    floorplanId: string;
    zoneId: string;
    locationId: string;
}
export const removeDeviceFromFloorplanZone = (
    floorplanId: string,
    zoneId: string,
    locationId: string
): RemoveDeviceFromFloorplanZone => ({
    type: REMOVE_FLOORPLAN_ZONE_DEVICE,
    floorplanId,
    zoneId,
    locationId,
});

export const REMOVE_FLOORPLAN_ZONE_DEVICE_SUCCESS = 'REMOVE_FLOORPLAN_ZONE_DEVICE_SUCCESS';
export interface RemoveDeviceFromFloorplanZoneSuccess {
    type: typeof REMOVE_FLOORPLAN_ZONE_DEVICE_SUCCESS;
    floorplanId: string;
    zoneId: string;
    locationId: string;
}
export const removeDeviceFromFloorplanZoneSuccess = (
    floorplanId: string,
    zoneId: string,
    locationId: string
): RemoveDeviceFromFloorplanZoneSuccess => ({
    type: REMOVE_FLOORPLAN_ZONE_DEVICE_SUCCESS,
    floorplanId,
    zoneId,
    locationId,
});

export const REMOVE_FLOORPLAN_ZONE_DEVICE_ERROR = 'REMOVE_FLOORPLAN_ZONE_DEVICE_ERROR';
export interface RemoveDeviceFromFloorplanZoneError {
    type: typeof REMOVE_FLOORPLAN_ZONE_DEVICE_ERROR;
    floorplanId: string;
    zoneId: string;
    locationId: string;
}
export const removeDeviceFromFloorplanZoneError = (
    floorplanId: string,
    zoneId: string,
    locationId: string
): RemoveDeviceFromFloorplanZoneError => ({
    type: REMOVE_FLOORPLAN_ZONE_DEVICE_ERROR,
    floorplanId,
    zoneId,
    locationId,
});

export type FloorPlanReducerCommonActions =
    | AddFloorplan
    | AddFloorplanError
    | AddFloorplanSuccess
    | DeleteFloorplanSuccess
    | UpdateZoneAndDeviceFloorplanError
    | UpdateZoneAndDeviceFloorplan
    | AddFloorplanZoneAndDeviceSuccess
    | RemoveDeviceFromFloorplanZoneSuccess
    | AddZoneAndDeviceToFloorplan
    | RemoveDeviceFromFloorplanZone
    | UpdateZoneAndDeviceFloorplanSuccess
    | AddFloorplanZoneAndDeviceError
    | RemoveDeviceFromFloorplanZoneError
    | DeleteFloorplan
    | DeleteFloorplanError;
