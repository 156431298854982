import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { Units } from 'commons/src/models/commonTypeScript';
import styles from './PartnerSummary.module.scss';
import { AveragesCountCell, FirstColumnCell, TextColumnCell } from './PartnerSummaryCells';
import PartnerSummaryCSVDownload from './PartnerSummaryCsvDownload';
import {
    PartnerSummaryTablePerDevice,
    PartnerSummaryTablePerThreshold,
    PartnerSummaryCSVData,
    PartnerSummaryThresholdData,
    PartnerInsightAverage,
    SelectedPeriod,
} from './PartnerSummaryModels';
import PartnerSummaryTable from './PartnerSummaryTable';
import { convertValue } from './PartnerSummaryUtils';

type Props = {
    dataPerDevice: PartnerSummaryCSVData[];
    dataPerThreshold: PartnerSummaryThresholdData;
    dateFormat: string;
    selectedPeriod: SelectedPeriod;
    units: Units;
};

const PartnerSummaryDesktop = ({
    dataPerDevice,
    dataPerThreshold,
    dateFormat,
    selectedPeriod,
    units,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const perThresholdColumns: Column[] = useMemo<Column[]>(
        () => [
            {
                Header: '',
                accessor: 'rowHeader',
                Cell: FirstColumnCell,
                className: styles.firstColumnCell,
            },
            {
                Header: txt(SensorTypes.co2),
                accessor: 'co2',
                Cell: AveragesCountCell,
                className: styles.textColumnCell,
            },
            {
                Header: txt(SensorTypes.temp),
                accessor: 'temp',
                Cell: AveragesCountCell,
                className: styles.textColumnCell,
            },
            {
                Header: txt(SensorTypes.humidity),
                accessor: 'humidity',
                Cell: AveragesCountCell,
                className: styles.textColumnCell,
            },
        ],
        []
    );

    const renderAveragesLessThan = (sensor: string): PartnerInsightAverage => ({
        totalDevices: dataPerThreshold.averagesLessThan[sensor].totalDevices,
        sensorUnit: dataPerThreshold.averagesLessThan[sensor].sensorUnit,
        thresholds: [dataPerThreshold.averagesLessThan[sensor].thresholds[0]],
    });

    const renderAveragesBetween = (sensor: string): PartnerInsightAverage => ({
        totalDevices: dataPerThreshold.averagesBetween[sensor].totalDevices,
        sensorUnit: dataPerThreshold.averagesBetween[sensor].sensorUnit,
        thresholds: dataPerThreshold.averagesBetween[sensor].thresholds,
    });

    const renderAveragesHigherThan = (sensor: string): PartnerInsightAverage => ({
        totalDevices: dataPerThreshold.averagesHigherThan[sensor].totalDevices,
        sensorUnit: dataPerThreshold.averagesHigherThan[sensor].sensorUnit,
        thresholds: [dataPerThreshold.averagesHigherThan[sensor].thresholds[1]],
    });

    const renderThresholdRow = (
        header: string,
        renderAverage: (sensor: string) => PartnerInsightAverage
    ): PartnerSummaryTablePerDevice => {
        return {
            rowHeader: txt(header),
            co2: renderAverage(SensorTypes.co2),
            temp: renderAverage(SensorTypes.temp),
            humidity: renderAverage(SensorTypes.humidity),
        };
    };

    const perThresholdTableData: PartnerSummaryTablePerDevice[] = useMemo(
        () => [
            renderThresholdRow('Partner.DevicesWithAveragesLess', renderAveragesLessThan),
            renderThresholdRow('Partner.DevicesWithAveragesBetween', renderAveragesBetween),
            renderThresholdRow('Partner.DevicesWithAveragesAbove', renderAveragesHigherThan),
        ],
        [dataPerDevice]
    );

    const perDeviceColumns: Column[] = useMemo<Column[]>(
        () => [
            {
                Header: txt('Organization'),
                accessor: 'organizationName',
                Cell: FirstColumnCell,
                className: styles.firstColumnCell,
            },
            {
                Header: txt('Device'),
                accessor: 'segmentName',
                Cell: TextColumnCell,
                className: styles.textColumnCell,
            },
            {
                Header: txt('Partner.SummaryAverage', { sensorType: txt(SensorTypes.co2) }),
                accessor: 'co2',
                Cell: TextColumnCell,
                className: styles.textColumnCell,
            },
            {
                Header: txt('Partner.SummaryAverage', { sensorType: txt(SensorTypes.temp) }),
                accessor: 'temp',
                Cell: TextColumnCell,
                className: styles.textColumnCell,
            },
            {
                Header: txt('Partner.SummaryAverage', { sensorType: txt(SensorTypes.humidity) }),
                accessor: 'humidity',
                Cell: TextColumnCell,
                className: styles.textColumnCell,
            },
        ],
        []
    );

    const perDeviceTableData: PartnerSummaryTablePerThreshold[] = useMemo(
        () =>
            dataPerDevice.map(it => ({
                organizationName: it.organization,
                segmentName: it.device,
                co2: it.averages.co2?.toString(),
                temp: convertValue(SensorTypes.temp, units, it.averages.temp),
                humidity: it.averages.humidity?.toString(),
                serialNumber: it.serialNumber,
            })),
        [dataPerDevice]
    );

    return (
        <>
            <PartnerSummaryTable columns={perThresholdColumns} tableData={perThresholdTableData} />
            <PartnerSummaryCSVDownload data={dataPerDevice} selectedPeriod={selectedPeriod} dateFormat={dateFormat} />
            <PartnerSummaryTable columns={perDeviceColumns} tableData={perDeviceTableData} />
        </>
    );
};

export default PartnerSummaryDesktop;
