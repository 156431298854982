import React from 'react';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { BoldText } from 'commons/src/components/PDF/PdfConstants';
import { BuildingInsightChartData } from '../../../../models/buildingModels';
import humanizedTimeString from '../buildingInsightCommonFunctions';

const styles = StyleSheet.create({
    breakDownGraph: {
        width: '65vw',
        height: '65px',
        margin: '0 1vw',
    },
    deviceBreakDown: {
        margin: '3vw 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    nameWrapper: {
        fontSize: 10,
        width: '25vw',
    },
});

type Props = {
    graph: string;
    graphId: string;
    totalDeviceData: {
        devicesOverTime: { [serialNumber: string]: BuildingInsightChartData[] };
        devicesInTimeFrame: {
            serialNumber: string;
            name: string;
            overThresholdsTime: number;
            underThresholdsTime: number;
        }[];
    };
};

export const ThresholdBreachDeviceBreakDown = ({ graph, graphId, totalDeviceData }: Props): React.ReactElement => {
    const serialNumber = graphId.substring(graphId.lastIndexOf('-') + 1);
    const { devicesOverTime, devicesInTimeFrame } = totalDeviceData;
    const insightForDevice = devicesOverTime[serialNumber] || [];
    const selectedDeviceProps = devicesInTimeFrame.find(device => device.serialNumber === serialNumber);
    const totalBreachTime = insightForDevice.reduce((totalTime, data) => {
        return totalTime + data.underThresholdsTime + data.overThresholdsTime;
    }, 0);
    const readableTime = humanizedTimeString(totalBreachTime);
    return (
        <View style={styles.deviceBreakDown}>
            <View style={styles.nameWrapper}>
                <BoldText>{selectedDeviceProps?.name}</BoldText>
                <Text>{readableTime}</Text>
            </View>
            <Image key={graphId} style={styles.breakDownGraph} src={graph} />
        </View>
    );
};
export default ThresholdBreachDeviceBreakDown;
