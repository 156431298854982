import React, { KeyboardEvent, SyntheticEvent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { DeviceWithKeyInfo, SegmentProperties } from 'commons/src/models/commonTypeScript';
import { FetchSegmentProperties, fetchSegmentProperties } from '../../../actions/segmentPropertiesActions';
import DeviceSelectorWithDetails from '../../../components/DeviceSelectorWithDetails';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';

interface ActionProps {
    getSegmentProperties: (serialNumber: string) => void;
}

type StateProps = {
    segmentProperties: { [serialNumber: string]: SegmentProperties };
    loading: boolean;
};

type ParentProps = {
    device: DeviceWithKeyInfo;
    removeDeviceFromList: (e: SyntheticEvent<HTMLInputElement> | KeyboardEvent<HTMLButtonElement>) => void;
    slimView?: boolean;
};

type Props = StateProps & ActionProps & ParentProps;

export const GroupDeviceDetailsComponent = ({
    removeDeviceFromList,
    device,
    segmentProperties,
    loading,
    getSegmentProperties,
    slimView,
}: Props): React.ReactElement => {
    const deviceProps = segmentProperties[device.serialNumber];

    useEffect((): void => {
        if (!deviceProps) {
            getSegmentProperties(device.serialNumber);
        }
    }, []);

    const segmentPropsForDevice = segmentProperties[device.serialNumber];

    return (
        <DeviceSelectorWithDetails
            device={device}
            segmentProperties={segmentPropsForDevice}
            removeDeviceFromList={removeDeviceFromList}
            loading={!segmentPropsForDevice && loading}
            slimView={slimView}
        />
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        segmentPropertiesStore: { segmentProperties },
        requests: {
            [BusinessRequestType.FetchSegmentProperties]: { loading },
        },
        devices: { loading: deviceLoading },
    } = state;
    return {
        segmentProperties,
        loading: loading || deviceLoading,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    getSegmentProperties: (serialNumber: string): FetchSegmentProperties =>
        dispatch(fetchSegmentProperties(serialNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupDeviceDetailsComponent);
