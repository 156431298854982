import {
    AddLocationPayload,
    LocationDetails,
    LocationRfRegionResponse,
    LocationsHistoryType,
    LocationsResponse,
} from '../models/commonTypeScript';
import fetch from './fetch';
import getFetchHeaders from './getFetchHeaders';

export const fetchLocations = async (limit?: number, offset?: number): Promise<LocationsResponse> =>
    fetch<LocationsResponse>(
        limit === undefined || offset === undefined ? `/locations` : `/locations?limit=${limit}&offset=${offset}`,
        {
            method: 'GET',
            headers: await getFetchHeaders(),
        }
    );
export default fetchLocations;

export const addLocation = async (location: AddLocationPayload | LocationDetails): Promise<{ id: string }> =>
    fetch<{ id: string }>('/locations', {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(location),
    });

export const getLocationsHistory = async (locationId?: string): Promise<{ locations: LocationsHistoryType[] }> =>
    fetch<{ locations: LocationsHistoryType[] }>(`/locations/${locationId ? `${locationId}/` : ``}history`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const deleteLocation = async (locationId: string): Promise<void> =>
    fetch<void>(`/locations/${locationId}`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });

export const validateLocationRfRegion = async (
    countryCode: string,
    locationId?: string
): Promise<LocationRfRegionResponse> =>
    fetch<LocationRfRegionResponse>(
        `/locations/validate-country?countryCode=${countryCode}${
            locationId !== undefined ? `&locationId=${locationId}` : ''
        }`,
        {
            method: 'GET',
            headers: await getFetchHeaders(),
        }
    );
