import React from 'react';
import { CssVarsProvider as JoyCssVarsProvider, THEME_ID as JOY_THEME_ID } from '@mui/joy/styles';
import { ThemeProvider, THEME_ID as MATERIAL_THEME_ID } from '@mui/material/styles';
import { combineReducers } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { Route, Routes, unstable_HistoryRouter as UnstableHistoryRouter } from 'react-router-dom';
import { initialize } from 'commons/src/actions';
import ChatWidget from 'commons/src/components/banners/ChatWidget';
import TopCookieBanner from 'commons/src/components/banners/CookieBanner';
import DemoBanner from 'commons/src/components/banners/DemoBanner';
import SupportBanner from 'commons/src/components/banners/SupportBanner';
import NotSupported from 'commons/src/components/errorComponents/NotSupported';
import PageNotFound from 'commons/src/components/errorComponents/PageNotFound';
import SpinnerPage from 'commons/src/components/placeholder/SpinnerPage';
import NotificationBox from 'commons/src/components/responseMessages/NotificationBox';
import ScrollToTop from 'commons/src/components/ScrollToTop';
import {
    appDevicePagePath,
    publicHomeReportPath,
    roleRestrictions,
    unsubscribeFromAlertPath,
} from 'commons/src/constants';
import AddModule from 'commons/src/features/addModule/AddModule';
import AddModuleType from 'commons/src/features/addModule/AddModuleType';
import HomeReportV2 from 'commons/src/features/CorentiumHomeReportFormPage/HomeReportV2';
import Dashboard from 'commons/src/features/dashboard/Dashboard';
import AppDevicePage from 'commons/src/features/devicePage/AppDevicePage';
import GenerateRadonReportPage from 'commons/src/features/devicePage/dropdownOptions/SimpleRadonReport/GenerateRadonReportPage';
import DevicesPage from 'commons/src/features/devices/DevicesPage';
import HbsOnboardingModal from 'commons/src/features/HbsOnboarding/HbsOnboardingModal';
import AddEditApiClient from 'commons/src/features/integrations/apiIntegration/AddEditApiClient';
import ApiIntegrationPage from 'commons/src/features/integrations/apiIntegration/ApiIntegrationPage';
import OutdoorAirQualityPage from 'commons/src/features/outdoorAirQualityPage/OutdoorAirQualityPage';
import SecuritySettings from 'commons/src/features/settingsPage/security/SecuritySettings';
import UnsubscribePage from 'commons/src/features/unsubscribeEmailPage/UnsubscribePage';
import IntlWrapper from 'commons/src/i18n/IntlWrapper';
import Protected from 'commons/src/Protected';
import 'commons/src/polyfills';
import commonReducers from 'commons/src/reducers';
import configureStore from 'commons/src/store/configureStore';
import './styles/index.scss';
import history from 'commons/src/store/history';
import { theme, joyTheme } from 'commons/src/styleTheme';
import ConsumerAccountsPage from './features/accounts/ConsumerAccountsPage';
import Overview from './features/accounts/Overview';
import DeviceOverview from './features/devicePage/DeviceOverview';
import Device from './features/devicePage/DevicePage';
import DeviceSettings from './features/devicePage/dropdownOptions/DeviceSettings';
import PreviousMeasurementPage from './features/devicePage/PreviousMeasurementPage';
import HubPage from './features/hubs/HubPage';
import ConsumerIntegrationsOverview from './features/integrations/ConsumerIntegrationsOverview';
import ConsumerIntegrationsView from './features/integrations/ConsumerIntegrationsView';
import Navigation from './features/navigation/Navigation';

const rootReducer = combineReducers(commonReducers);
export type RootReducer = typeof rootReducer;
const store = configureStore(rootReducer);
export type RootState = typeof store;
const getStore = (): RootState => {
    store.dispatch(initialize());
    return store;
};

export const initialRoutes = [
    { path: '/unsupported', component: <NotSupported /> },
    { path: '/spinner', component: <SpinnerPage /> },
    { path: '/registration', component: <SpinnerPage /> },
    { path: appDevicePagePath, component: <AppDevicePage /> },
    { path: publicHomeReportPath, component: <HomeReportV2 /> },
    { path: unsubscribeFromAlertPath, component: <UnsubscribePage /> },
    { path: '*', component: <PageNotFound /> },
];

const ConsumerDashboard = (): React.ReactElement => {
    return (
        <Provider store={getStore()}>
            <IntlWrapper>
                <ThemeProvider theme={{ [MATERIAL_THEME_ID]: theme }}>
                    <JoyCssVarsProvider theme={{ [JOY_THEME_ID]: joyTheme }}>
                        <UnstableHistoryRouter history={history}>
                            <div>
                                <TopCookieBanner />
                                <DemoBanner />
                                <SupportBanner />
                                <NotificationBox />
                                <Navigation publicPaths={initialRoutes.map(path => path.path)} />
                                <ChatWidget />
                                <HbsOnboardingModal />
                                <ScrollToTop>
                                    <Routes>
                                        <Route index element={<Dashboard />} />
                                        <Route path="devices" element={<DevicesPage />} />
                                        <Route path="devices/:serialNumber">
                                            <Route path="*" element={<Device />}>
                                                <Route index element={<DeviceOverview />} />
                                                <Route path="change-location" element={<DeviceSettings />} />
                                            </Route>
                                            <Route path="radon-report" element={<GenerateRadonReportPage />} />
                                            <Route path=":segmentId">
                                                <Route index element={<PreviousMeasurementPage />} />
                                                <Route path="radon-report" element={<GenerateRadonReportPage />} />
                                            </Route>
                                        </Route>
                                        <Route path="hub/:hubId" element={<HubPage />} />
                                        <Route path="settings" element={<ConsumerAccountsPage />}>
                                            <Route index element={<Overview />} />
                                            <Route path="security" element={<SecuritySettings />} />
                                        </Route>
                                        <Route path="add-tile">
                                            <Route index element={<AddModule />} />
                                            <Route path=":type" element={<AddModuleType />} />
                                        </Route>
                                        <Route path="outdoor">
                                            <Route path=":locationId" element={<OutdoorAirQualityPage />} />
                                        </Route>
                                        <Route
                                            path="integrations"
                                            element={
                                                <Protected
                                                    minRoleLevel={roleRestrictions.thirdPartyIntegration}
                                                    accessWithResourceFilter={false}
                                                />
                                            }
                                        >
                                            <Route path="*" element={<ConsumerIntegrationsView />}>
                                                <Route index element={<ConsumerIntegrationsOverview />} />
                                                <Route path="api-integration">
                                                    <Route index element={<ApiIntegrationPage />} />
                                                </Route>
                                            </Route>
                                            <Route
                                                path="api-integration"
                                                element={
                                                    <Protected
                                                        minRoleLevel={roleRestrictions.api}
                                                        accessWithResourceFilter={false}
                                                    />
                                                }
                                            >
                                                <Route path="add-api-client" element={<AddEditApiClient />} />
                                                <Route path=":clientId" element={<AddEditApiClient />} />
                                            </Route>
                                        </Route>
                                        <Route path="404" element={<PageNotFound />} />
                                        {initialRoutes.map(route => (
                                            <Route
                                                key={`consumer-routes-${route}`}
                                                path={route.path}
                                                element={route.component}
                                            />
                                        ))}
                                    </Routes>
                                </ScrollToTop>
                            </div>
                        </UnstableHistoryRouter>
                    </JoyCssVarsProvider>
                </ThemeProvider>
            </IntlWrapper>
        </Provider>
    );
};

export default ConsumerDashboard;
