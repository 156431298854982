import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { paths } from 'commons/src/constants';
import EndedSegmentPage from 'commons/src/features/devicePage/EndedSegmentPage';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { FullDeviceData } from 'commons/src/models/commonTypeScript';
import { Store } from 'commons/src/reducers';
import { deviceActionButtons } from './DevicePageHeader';

type StateProps = {
    segments: { [segment: string]: FullDeviceData };
    demoMode: boolean;
};

export type Props = StateProps;

export const PreviousMeasurementPageComponent = ({ segments, demoMode }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { serialNumber, segmentId } = useParams() as { serialNumber: string; segmentId: string };
    const navigate = useNavigate();
    const segment = segments[segmentId];
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [downloadCsvModalOpen, setDownloadCsvModalOpen] = useState(false);

    const onBackClicked = (): void => navigate(`/${paths.devices}`);

    const onDeleteModalOpen = (): void => {
        if (demoMode) return;
        setIsDeleteModalOpen(true);
    };

    const deviceWithRadonSensor =
        segment && segment.sensors && segment.sensors.some(sensor => sensor.type === SensorTypes.radonShortTermAvg);
    const actionButtons =
        segment &&
        deviceActionButtons(() => setDownloadCsvModalOpen(true), deviceWithRadonSensor, navigate, onDeleteModalOpen);

    return (
        <div>
            <PageHeader
                title={segment && segment.segmentName}
                subHeaderClick={onBackClicked}
                subHeader={txt('AllDevices')}
            />
            <div className="container">
                <SubHeader actionButtons={actionButtons || []} />
            </div>
            <EndedSegmentPage
                serialNumber={serialNumber}
                segmentId={segmentId}
                isDeleteModalOpen={isDeleteModalOpen}
                setDeleteModalOpen={setIsDeleteModalOpen}
                setDownloadCsvModalOpen={setDownloadCsvModalOpen}
                downloadCsvModalOpen={downloadCsvModalOpen}
            />
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        endedSegments: { segments },
        userSettings: { demoMode },
    } = state;
    return {
        segments,
        demoMode,
    };
};

export default connect(mapStateToProps)(PreviousMeasurementPageComponent);
