import { fetchFromOldDashboardApi } from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { AddZoneGroupPayload } from '../models/common';

// TODO USES OLD DASHBOARD-API
export default async (payload: AddZoneGroupPayload, locationId: string): Promise<void> =>
    fetchFromOldDashboardApi(`/location/${locationId}/virtualdevices`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ ...payload, roomName: payload.segmentName }),
    });
