import { CLEAR_DEVICE_DATA, ClearDeviceData } from 'commons/src/actions/DeviceActions';
import { SelectGroupActionType, SelectGroupReducerActions } from 'commons/src/actions/selectGroupActions';
import { SensorTypes } from 'commons/src/models/commonEnums';
import {
    BuildingsTimeOverThresholdActions,
    BuildingsTimeOverThresholdActionType,
} from '../actions/buildingsTimeOverThresholdActions';
import { BreachType, BuildingFocusedSensorData } from '../models/buildingModels';
import { SensorBreachThresholds } from '../models/common';

export type BuildingsOverviewState = {
    focusedSensor?: SensorTypes;
    thresholds: { value: number; type: BreachType }[];
    thresholdOptions: SensorBreachThresholds;
    locationsData: { [locationId: string]: BuildingFocusedSensorData };
};

const initialState: BuildingsOverviewState = {
    focusedSensor: undefined,
    thresholds: [],
    thresholdOptions: {},
    locationsData: {},
};

export default (
    state: BuildingsOverviewState = initialState,
    action: BuildingsTimeOverThresholdActions | SelectGroupReducerActions | ClearDeviceData
): BuildingsOverviewState => {
    switch (action.type) {
        case BuildingsTimeOverThresholdActionType.BuildingsThresholdsSetFocusedSensorSuccess:
            return {
                ...state,
                focusedSensor: action.response.sensor,
                thresholds: action.response.thresholds,
                thresholdOptions: action.response.thresholdOptions,
            };
        case BuildingsTimeOverThresholdActionType.GetBuildingsFocusedSensorDataSuccess:
            return {
                ...state,
                thresholds: action.thresholds,
                locationsData: action.locationsData,
            };
        case BuildingsTimeOverThresholdActionType.BuildingsThresholdsUpdateConfigSuccess:
            return {
                ...state,
                thresholds: action.thresholds,
            };
        case SelectGroupActionType.SelectGroupSuccess:
        case CLEAR_DEVICE_DATA:
            return initialState;
        default:
            return state;
    }
};
