import { takeEvery, call, put, CallEffect, PutEffect } from 'redux-saga/effects';
import {
    getOrganizationInvitesSuccess,
    getOrganizationInvitesError,
    OrganizationInvitesActionType,
} from '../../actions/OrganizationInvitesActions';
import getOrganizationInvites, { GetInvitesResponse } from '../../api/organizationInvites';

export function* getOrganizationInvitesSaga(): Generator<CallEffect | PutEffect, void, GetInvitesResponse> {
    try {
        const response = yield call(getOrganizationInvites);
        yield put(getOrganizationInvitesSuccess(response.invites));
    } catch (error) {
        yield put(getOrganizationInvitesError());
    }
}

export default function* GetOrgInvitesSagas(): Generator {
    yield takeEvery(OrganizationInvitesActionType.GetOrganizationInvitesInit, getOrganizationInvitesSaga);
}
