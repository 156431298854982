import React from 'react';
import { useTranslation } from 'react-i18next';
import ResponseBox from '../responseMessages/ResponseBox';
import FlipButton from './FlipButton';

export type Props = {
    title?: string;
    description: string;
    enabled: boolean;
    onUpdate: () => void;
    error?: boolean;
    errorText?: string;
    errorSubtext?: string;
    testIdOn?: string;
    testIdOff?: string;
};

const FeatureToggle = (props: Props): React.ReactElement => {
    const { title, description, enabled, onUpdate, error, errorText, errorSubtext, testIdOn, testIdOff } = props;
    const { t: txt } = useTranslation();
    return (
        <div className="segment-properties-form form__row--padded-small">
            {title && <span className="text-large text-bold segment-properties-form__header">{txt(title)}</span>}
            <span className="segment-properties-form__text">{txt(description)}</span>
            <div className="form__row--padded-small">
                <FlipButton
                    leftSelected={enabled}
                    onClick={onUpdate}
                    leftText="On"
                    rightText="Off"
                    testIdOn={testIdOn}
                    testIdOff={testIdOff}
                />
            </div>
            {error && <ResponseBox text={errorText || ''} subtext={txt(errorSubtext || '')} />}
        </div>
    );
};

export default FeatureToggle;
