import moment from 'moment';
import { takeEvery, put, call, CallEffect, PutEffect } from 'redux-saga/effects';
import {
    FETCH_DEVICE_SEGMENTID,
    FetchDeviceSegmentId,
    FetchDeviceSegmentIdError,
    fetchDeviceSegmentIdError,
    FetchDeviceSegmentIdSuccess,
    fetchDeviceSegmentIdSuccess,
} from '../../actions/DeviceActions';
import { fetchSegmentWithId, fetchVirtualDeviceData } from '../../api/segment';
import { DeviceDataType, DeviceResponse } from '../../models/commonTypeScript';
import { createChartData } from '../DashboardSagas/fetchDashboardSensorTile';

export const createGraphArrays = (device: DeviceResponse): DeviceDataType => {
    const segmentStartTime = moment.utc(device.segmentStart).valueOf();
    const sensorData = {};
    const minValues = {};
    const averageValues = {};
    const sensors = device.sensors.map(sensor => {
        const graphData = createChartData(device, sensor, segmentStartTime);

        sensorData[sensor.type] = { custom: graphData.chartData };
        minValues[sensor.type] = { custom: graphData.minValue };
        averageValues[sensor.type] = { custom: graphData.averageValue };

        return {
            ...sensor,
            measurements: undefined,
        };
    });
    return {
        deviceData: {
            ...device,
            sensors,
            offsets: undefined,
            fetchedIntervals: { custom: true },
        },
        sensorData,
        minValues,
        averageValues,
    };
};

export type FetchSegmentWithIdSagaActionType = Generator<
    CallEffect<DeviceResponse> | PutEffect<FetchDeviceSegmentIdSuccess> | PutEffect<FetchDeviceSegmentIdError>,
    void,
    DeviceResponse
>;
export function* fetchSegmentWithIdSaga({
    serialNumber,
    segmentId,
    segmentPeriod,
}: FetchDeviceSegmentId): FetchSegmentWithIdSagaActionType {
    const { startDate, endDate } = segmentPeriod;

    const toISO = endDate.toISOString();
    const to = toISO.slice(0, toISO.lastIndexOf('.'));
    const fromISO = startDate.toISOString();
    const from = fromISO.slice(0, fromISO.lastIndexOf('.'));

    try {
        const virtualSensorDataResponse = yield call(
            fetchVirtualDeviceData,
            serialNumber,
            {
                from,
                to,
                resolution: segmentPeriod.resolution,
            },
            segmentId
        );

        const virtualSensorData = createGraphArrays(virtualSensorDataResponse);
        const response = yield call(fetchSegmentWithId, serialNumber, segmentId, from, to, segmentPeriod.resolution);
        const sensorData = createGraphArrays(response);
        const segmentData = {
            ...sensorData,
            minValues: { ...sensorData.minValues, ...virtualSensorData.minValues },
            averageValues: { ...sensorData.averageValues, ...virtualSensorData.averageValues },
            sensorData: { ...sensorData.sensorData, ...virtualSensorData.sensorData },
        };
        yield put(fetchDeviceSegmentIdSuccess(segmentData, segmentId));
    } catch (error) {
        yield put(fetchDeviceSegmentIdError());
    }
}

export default function* FetchSegmentWithIdSaga(): Generator {
    yield takeEvery(FETCH_DEVICE_SEGMENTID, fetchSegmentWithIdSaga);
}
