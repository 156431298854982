import { userRoleAboveRequiredLevel } from '../../features/authorization/userRoleAboveRequiredLevel';
import filterOnFeatureToggles from '../../features/featureToggle/filterOnFeatureToggles';
import { FeatureToggleEnum, GroupType, RequiredRoleLevel, Role } from '../../models/commonEnums';
import { MenuElement } from '../../models/menuModels';

const filterMenuOnUserRole = <T extends MenuElement>(
    menuArray: T[],
    userRole: Role | undefined,
    resourceFilterOn?: boolean
): T[] =>
    menuArray.filter(
        (item: MenuElement) =>
            !item.requiredRoleLevel ||
            item.requiredRoleLevel === RequiredRoleLevel.ANY_ROLE ||
            (!!userRole &&
                userRoleAboveRequiredLevel(
                    userRole,
                    item.requiredRoleLevel,
                    !resourceFilterOn || item.accessWithResourceFilter
                ))
    );

const filterMenuElements = <T extends MenuElement>(
    menuArray: T[],
    groupType?: GroupType,
    userRole?: Role,
    resourceFilterOn?: boolean,
    featureToggles?: FeatureToggleEnum[]
): T[] =>
    filterOnFeatureToggles(filterMenuOnUserRole(menuArray, userRole, resourceFilterOn), groupType, featureToggles);

export default filterMenuElements;
