import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { sensorUnits, sensorGraphColors, colors, dateFormats } from 'commons/src/constants';
import IconNoData from 'commons/src/img/icon-no-data';
import { DayUsageHours } from 'commons/src/models/commonTypeScript';
import { Store } from '../../reducers';
import GraphConfig, { GenerateConfig } from './GraphConfig';
import { Bands, getSortedWeek, timeToDecimal } from './insightFunctions';

type ParentProps = {
    chartData: number[];
    unit: keyof typeof sensorUnits;
    sensorType: string;
    thresholds: number[];
    loading: boolean;
    usageHours: { [day: string]: DayUsageHours };
    serialNumber: string;
};

type StateProps = {
    dateFormat: keyof typeof dateFormats;
};
type Props = StateProps & ParentProps;

export const InsightGraphComponent = (props: Props): React.ReactElement => {
    const { chartData, thresholds, unit, sensorType, dateFormat, loading, usageHours, serialNumber } = props;
    const { t: txt } = useTranslation();
    const setFromObjectWithStrings = (obj: { [prop: string]: string }, key: string): string => obj[key];
    type ZoneType = { value?: number; color: string };
    const setThresholds = (): ZoneType[] => {
        if (thresholds.length === 0) {
            return [{ color: colors.graphGreen }];
        }

        const thresholdZones: ZoneType[] = thresholds.map((threshold, i) => {
            const color = setFromObjectWithStrings(sensorGraphColors, `${i}${sensorType}`);
            return { value: threshold, color };
        }, []);
        thresholdZones.push({ color: colors.graphRed });
        return thresholdZones;
    };

    const plotBands = (): Bands => {
        const sortedWeek = getSortedWeek(usageHours);
        return sortedWeek.reduce((bands: Bands, day, index) => {
            const fromTime = usageHours[day].from;
            const toTime = usageHours[day].to;
            if (fromTime && toTime && !usageHours[day].closed) {
                const fromDecimal = timeToDecimal(fromTime);
                const toDecimal = timeToDecimal(toTime);
                bands.push({
                    color: colors.greyPorcelain,
                    from: fromDecimal + index * 24,
                    to: toDecimal + index * 24,
                });
            }
            return bands;
        }, []);
    };

    const createConfig = (): GenerateConfig => {
        const chartHeight = 240;
        const minValue = 0;
        const weekdays = [
            txt('Monday'),
            txt('Tuesday'),
            txt('Wednesday'),
            txt('Thursday'),
            txt('Friday'),
            txt('Saturday'),
            txt('Sunday'),
        ];

        const unitInUse = setFromObjectWithStrings(sensorUnits, unit as string);
        return GraphConfig(
            chartData,
            setThresholds(),
            unitInUse,
            chartHeight,
            minValue,
            dateFormat,
            weekdays,
            plotBands(),
            serialNumber
        );
    };

    const sensorHasData = !loading && chartData.length > 0;
    const config = createConfig();
    return sensorHasData ? (
        <HighchartsReact highcharts={Highcharts} options={config} />
    ) : (
        <div className="centered">
            <div className="centered__content">
                {IconNoData}
                <div>{txt(loading ? 'Loading' : 'NotEnoughData')}</div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = state;
    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(InsightGraphComponent);
