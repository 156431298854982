import dotProp from 'dot-prop-immutable';
import {
    CLEAR_DEVICE_DATA,
    DeviceReducerActions,
    FETCH_DEVICE_SEGMENTID_PERIOD_SUCCESS,
    FETCH_DEVICE_SEGMENTID_SUCCESS,
} from '../actions/DeviceActions';
import { LOGOUT_SUCCESS, LogOutSuccess } from '../actions/LoginAndRegisterActions';
import { AverageSensorPeriodValues, MinSensorPeriodValues, SensorData } from '../models/commonTypeScript';

export interface EndedSegmentDataStore {
    sensorData: { [segmentId: string]: SensorData };
    minValues: MinSensorPeriodValues;
    averageValues: AverageSensorPeriodValues;
}

const initialState = {
    sensorData: {},
    minValues: {},
    averageValues: {},
};

export default (state = initialState, action: DeviceReducerActions | LogOutSuccess): EndedSegmentDataStore => {
    switch (action.type) {
        case FETCH_DEVICE_SEGMENTID_SUCCESS:
            return {
                ...state,
                sensorData: dotProp.set(state.sensorData, action.segmentId, action.device.sensorData),
                minValues: dotProp.set(state.minValues, action.segmentId, action.device.minValues),
                averageValues: dotProp.set(state.averageValues, action.segmentId, action.device.averageValues),
            };
        case FETCH_DEVICE_SEGMENTID_PERIOD_SUCCESS:
            return {
                ...state,
                sensorData: dotProp.merge(state.sensorData, action.segmentId, action.segment.sensorData),
                minValues: dotProp.merge(state.minValues, action.segmentId, action.segment.minValues),
                averageValues: dotProp.merge(state.averageValues, action.segmentId, action.segment.averageValues),
            };
        case CLEAR_DEVICE_DATA:
        case LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
};
