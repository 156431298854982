import { OrganizationInviteType } from '../models/commonTypeScript';
import fetch from './fetch';
import getFetchHeaders from './getFetchHeaders';

export interface GetInvitesResponse {
    invites: OrganizationInviteType[];
}

export default async (): Promise<GetInvitesResponse> =>
    fetch('/invites', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const respondToInvite = async (invite: OrganizationInviteType, status: string): Promise<void> => {
    return fetch(`/invites/${invite.id}`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ status: status.toUpperCase() }),
    });
};
