import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import TertiaryButton from '../buttons/TertiaryButton';
import MaterialIcon from '../MaterialIcon';

type Props = {
    headerText: string;
    onClose?: () => void;
};

const ModalHeader = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { headerText, onClose } = props;
    return (
        <div
            className={classNames({
                'modal__content__header--close': !!onClose,
            })}
        >
            <h1 className="modal__content__title">{txt(headerText)}</h1>
            {onClose && (
                <TertiaryButton onClick={onClose} noColor icon={<MaterialIcon name="close" />} testAttr="close" />
            )}
        </div>
    );
};

export default ModalHeader;
