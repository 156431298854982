import React, { useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { fetchCustomDeviceSegment } from 'commons/src/actions/DeviceActions';
import { graphLoadingPlaceholder } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { customPeriodName, graphResolutions } from 'commons/src/constants';
import { Resolution, SensorTypes } from 'commons/src/models/commonEnums';
import {
    BuildingType,
    DeviceWithKeyInfo,
    OutdoorSensorData,
    SelectedPeriod,
    SensorData,
    Units,
} from 'commons/src/models/commonTypeScript';
import { fetchOutdoorInsightData } from '../../../actions/outdoorInsightActions';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import styles from './HeatingCoolingSelectedDevice.module.scss';
import SensorAndOutdoorGraph from './SensorAndOutdoorGraph';

export type ParentProps = {
    serialNumber?: string;
    selectedPoint: string;
    locationId: string;
};

type StateProps = {
    loading: boolean;
    units: Units;
    sensorData?: { [serialNumber: string]: SensorData };
    outdoorData: {
        history: { [sensorType: string]: OutdoorSensorData };
    };
    buildings: { [buildingId: string]: BuildingType };
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    error?: string;
};

type Props = ParentProps & StateProps;

const HeatingCoolingSelectedDevice = ({
    serialNumber,
    locationId,
    units,
    sensorData,
    outdoorData,
    buildings,
    selectedPoint,
    loading,
    devicesWithKeyInfo,
    error,
}: Props): React.ReactElement => {
    const dispatch = useDispatch();
    const { t: txt } = useTranslation();
    const device = devicesWithKeyInfo[serialNumber as keyof typeof devicesWithKeyInfo];
    const building = buildings[locationId];
    const selectedInterval: SelectedPeriod = {
        name: customPeriodName,
        label: '48hours',
        ...graphResolutions.full,
        startDate: moment(selectedPoint).locale(building?.timezone || 'UTC'),
        endDate: moment(selectedPoint).endOf('day'),
        number: 1,
        resolution: Resolution.hour,
    };

    const getData = (snr: string): void => {
        dispatch(fetchCustomDeviceSegment(snr, selectedInterval));
        dispatch(
            fetchOutdoorInsightData({
                from: moment(selectedPoint)
                    .startOf('day')
                    .locale(building?.timezone || 'UTC')
                    .utc()
                    .format('YYYY-MM-DD'),
                to: moment(selectedPoint)
                    .endOf('day')
                    .locale(building?.timezone || 'UTC')
                    .utc()
                    .format('YYYY-MM-DD'),
                locationId,
                unitPreferences: units,
            })
        );
    };

    useEffect(() => {
        if (serialNumber) {
            getData(serialNumber);
        }
    }, [serialNumber, selectedPoint]);

    if (!selectedPoint) return <div />;

    const sensorDataForDevice =
        sensorData &&
        serialNumber &&
        sensorData[serialNumber] &&
        sensorData[serialNumber][SensorTypes.temp] &&
        sensorData[serialNumber][SensorTypes.temp][customPeriodName];

    const outdoorTempData =
        outdoorData.history && outdoorData.history[SensorTypes.temp] && outdoorData.history[SensorTypes.temp].data;

    return (
        <div className={styles.wrapper}>
            {serialNumber ? (
                <div className={styles.deviceName} key={serialNumber}>
                    <Link
                        to={generatePath('/devices/:serialNumber', { serialNumber })}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {device?.segmentName}
                    </Link>
                </div>
            ) : (
                <div>{txt('BuildingInsight.SelectDevice')}</div>
            )}
            {serialNumber && (
                <ReactPlaceholder
                    ready={!loading && !!outdoorTempData && !!sensorDataForDevice}
                    customPlaceholder={graphLoadingPlaceholder(txt('Loading'))}
                >
                    <div>
                        {error ? (
                            <ResponseBox text={error} />
                        ) : (
                            <SensorAndOutdoorGraph
                                sensor={SensorTypes.temp}
                                indoorData={sensorDataForDevice || []}
                                outdoorData={outdoorTempData}
                                unit={units.tempUnit}
                                usageHours={building?.usageHours}
                                fromDate={moment(selectedPoint)}
                                toDate={moment(selectedPoint)}
                                timeZone={building?.timezone || 'UTC'}
                            />
                        )}
                    </div>
                </ReactPlaceholder>
            )}
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        buildings: { buildings },
        userSettings: { units },
        devices: { devicesWithKeyInfo },
        deviceSensorData: { sensorData },
        devicePage: { fetching, loading: loadingSensorData, error: errorSensorData },
        outdoorInsight: { outdoorData },
        requests: {
            [RequestType.FetchOutdoorInsightData]: { loading, error },
        },
    } = store;

    const errorText = error ? `ErrorCodes.${error.error}` : 'SomethingWentWrong';
    return {
        units,
        outdoorData,
        sensorData,
        buildings,
        loading: fetching || loading || loadingSensorData,
        error: error || !!errorSensorData ? errorText : undefined,
        devicesWithKeyInfo,
    };
};

export default connect(mapStateToProps)(HeatingCoolingSelectedDevice);
