import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import PageWrapper from 'commons/src/components/containers/PageWrapper';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { FeatureToggleEnum, RequiredRoleLevel, Role } from 'commons/src/models/commonEnums';
import SpacesIllustration from '../../../img/spaces-illustration';
import { Store } from '../../../reducers';
import AddFloorPlan from '../../spaceFloorPlan/addFloorPlan/AddFloorPlan';
import styles from './EmptySpacesAndFloors.module.scss';

type Props = {
    userRole?: Role;
    requiredRoleLevel: RequiredRoleLevel;
    onClick: () => void;
    locationId: string;
};

const EmptySpacesAndFloors = ({ userRole, requiredRoleLevel, onClick, locationId }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const accessToAddDevice: boolean = userRole ? userRoleAboveRequiredLevel(userRole, requiredRoleLevel) : false;

    const [addFloorPlanViewOn, setAddFloorPlanViewOn] = useState<boolean>(false);

    const { featureToggles } = useSelector((store: Store) => store.userSettings);
    const floorPlansEnabled: boolean = featureToggles.includes(FeatureToggleEnum.enableFloorPlans);

    if (floorPlansEnabled) {
        return (
            <PageWrapper pageType="wide">
                <div className={styles.body}>
                    <SpacesIllustration />
                    <div className={styles.content}>
                        {addFloorPlanViewOn ? (
                            <>
                                <div className={styles.text}>
                                    <div className={styles.title}>{txt('AddSpace.AddFloorPlanTitle')}</div>
                                    <div className={styles.subTitle}>{txt('AddSpace.AddFloorPlanSubTitle')}</div>
                                </div>
                                <AddFloorPlan locationId={locationId} />
                                <div className={styles.cancelButton}>
                                    <TertiaryButton
                                        id="AddSpaceButton"
                                        onClick={(): void => setAddFloorPlanViewOn(false)}
                                        title="Cancel"
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.text}>
                                    <div className={styles.title}>{txt('AddSpace.EmptySpacesAndFloorPlansTitle')}</div>
                                    <div className={styles.subTitle}>
                                        {txt('AddSpace.EmptySpacesAndFloorPlansSubTitle')}
                                    </div>
                                </div>
                                <div className={styles.buttons}>
                                    {accessToAddDevice ? (
                                        <>
                                            <PrimaryButton
                                                onClick={(): void => setAddFloorPlanViewOn(true)}
                                                title="FloorPlan.UploadFloorPlan"
                                                color="primary"
                                            />
                                            <div className={styles.suffix}>{txt('Or')}</div>
                                            <TertiaryButton
                                                onClick={onClick}
                                                title="AddSpace.AddSpace"
                                                noColor
                                                size="medium"
                                                icon={<MaterialIcon name="add" />}
                                            />
                                        </>
                                    ) : (
                                        <p className={styles.roleText}>
                                            {txt('Access.RoleDoesNotHaveAccess', { role: txt(userRole || '') })}
                                        </p>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </PageWrapper>
        );
    }

    // TODO Do delete when floor plan is released to all
    return (
        <div className={styles.noSpaces}>
            <SpacesIllustration />
            <div className={styles.content}>
                <div className={styles.text}>
                    <div className={styles.title}>{txt('AddSpace.AddYourFirstSpace')}</div>
                    <div className={styles.subText}>{txt('AddSpace.MapSpaces')}</div>
                </div>
                <div className={styles.buttonsV1}>
                    {accessToAddDevice ? (
                        <PrimaryButton
                            id="AddSpaceButton"
                            onClick={onClick}
                            color="primary"
                            title="AddSpace.AddSpace"
                        />
                    ) : (
                        <p className={styles.roleText}>
                            {txt('Access.RoleDoesNotHaveAccess', { role: txt(userRole || '') })}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EmptySpacesAndFloors;
