import React from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer';
import {
    ColoredCircle,
    coloredCircleStyles,
    DeviceIcon,
    InsightWrapper,
    SensorUnitText,
    ValueWrapper,
} from '../../../components/PDF/PdfConstants';
import { sensorUnits } from '../../../constants';
import RadonPng from '../../../img/sensorIcons/sensorPng/sensor-radon.png';
import { EnumStatusColors } from '../../../models/commonEnums';
import { HomeRadonValues } from '../../../models/commonTypeScript';

const componentStyles = StyleSheet.create({
    margin: { marginBottom: '1vw' },
    paddedAverage: { marginLeft: 50 },
    sensorValueWrapper: {
        fontSize: '10',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    sensorValue: {
        fontSize: 22,
        fontFamily: 'DemiBold',
        marginRight: 5,
    },
});

type Props = {
    report: HomeRadonValues;
};

const RadonLevel = ({ report }: Props): React.ReactElement => {
    const radonLevel = report.estimatedAnnualAverage ? report.estimatedAnnualAverage : report.radonLongTermAvg;
    const statusColor = radonLevel < report.referenceLevel ? EnumStatusColors.green : EnumStatusColors.red;
    return (
        <InsightWrapper style={componentStyles.margin}>
            <ColoredCircle style={[coloredCircleStyles[statusColor]]}>
                <DeviceIcon src={RadonPng} />
            </ColoredCircle>
            <ValueWrapper>
                <View style={componentStyles.sensorValueWrapper}>
                    <Text style={componentStyles.sensorValue}>{radonLevel}</Text>
                    <SensorUnitText>{sensorUnits[report.unit]}</SensorUnitText>
                </View>
            </ValueWrapper>
        </InsightWrapper>
    );
};

export default RadonLevel;
