import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import PageHeader from '../../components/headers/PageHeader';
import { TabOption } from '../../models/menuModels';

export type Props = {
    tabs: TabOption[];
};

export const AccountPageComponent = ({ tabs }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <>
            <PageHeader title={txt('Account')} tabs={tabs} />
            <div className="page-wrapper__medium">
                <Outlet />
            </div>
        </>
    );
};

export default AccountPageComponent;
