import { RequestActionType } from 'commons/src/actions/requestActions';
import { Resolution, SensorTypes } from 'commons/src/models/commonEnums';
import { BreachType, BuildingInsight } from '../models/buildingModels';
import { SensorBreachThresholds, BusinessActionRequestType } from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum ThresholdBreachInsight {
    FetchThresholdBreachAllInsightsInit = 'FETCH_THRESHOLD_BREACH_ALL_INSIGHTS/INIT',
    FetchThresholdBreachAllInsightsSuccess = 'FETCH_THRESHOLD_BREACH_ALL_INSIGHTS_SUCCESS',
    FetchThresholdBreachSingleInsightInit = 'FETCH_THRESHOLD_BREACH_SINGLE_INSIGHT/INIT',
    FetchThresholdBreachSingleInsightSuccess = 'FETCH_THRESHOLD_BREACH_SINGLE_INSIGHT_SUCCESS',
    DeleteThresholdBreachInsightInit = 'DELETE_THRESHOLD_BREACH_INSIGHT/INIT',
    DeleteThresholdBreachInsightSuccess = 'DELETE_THRESHOLD_BREACH_INSIGHT_SUCCESS',
    UpdateThresholdBreachConfigInit = 'UPDATE_THRESHOLD_BREACH_CONFIG/INIT',
    UpdateThresholdBreachConfigSuccess = 'UPDATE_THRESHOLD_BREACH_CONFIG_SUCCESS',
    CreateThresholdBreachInsightInit = 'CREATE_THRESHOLD_BREACH_INSIGHT/INIT',
    CreateThresholdBreachInsightSuccess = 'CREATE_THRESHOLD_BREACH_INSIGHT_SUCCESS',
}

export type FetchThresholdBreachAllInsightsType = {
    locationId: string;
    fromDate: string;
    toDate: string;
    withOpeningHours: boolean;
    resolution: Resolution;
};

export interface FetchThresholdBreachAllInsights extends BusinessActionRequestType {
    type: ThresholdBreachInsight.FetchThresholdBreachAllInsightsInit;
    payload: FetchThresholdBreachAllInsightsType;
}

export const fetchThresholdsBreachAllInsights = (
    payload: FetchThresholdBreachAllInsightsType
): FetchThresholdBreachAllInsights => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.FetchThresholdBreachAllInsights,
    type: ThresholdBreachInsight.FetchThresholdBreachAllInsightsInit,
    payload,
});

export interface FetchThresholdBreachAllInsightsSuccess {
    type: ThresholdBreachInsight.FetchThresholdBreachAllInsightsSuccess;
    payload: {
        charts: BuildingInsight[];
        thresholds: SensorBreachThresholds;
    };
    locationId: string;
}

export const fetchThresholdBreachAllInsightsSuccess = (
    payload: {
        charts: BuildingInsight[];
        thresholds: SensorBreachThresholds;
    },
    locationId: string
): FetchThresholdBreachAllInsightsSuccess => ({
    type: ThresholdBreachInsight.FetchThresholdBreachAllInsightsSuccess,
    payload,
    locationId,
});

export interface FetchThresholdBreachSingleInsight extends BusinessActionRequestType {
    type: ThresholdBreachInsight.FetchThresholdBreachSingleInsightInit;
    payload: FetchThresholdBreachAllInsightsType;
    chartId: string;
    thresholds: { value: number; type: BreachType }[];
}

export const fetchThresholdsBreachSingleInsight = (
    payload: FetchThresholdBreachAllInsightsType,
    chartId: string,
    thresholds: { value: number; type: BreachType }[]
): FetchThresholdBreachSingleInsight => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.FetchThresholdBreachSingleInsight,
    type: ThresholdBreachInsight.FetchThresholdBreachSingleInsightInit,
    payload,
    chartId,
    thresholds,
});

export interface FetchThresholdBreachSingleInsightSuccess {
    type: ThresholdBreachInsight.FetchThresholdBreachSingleInsightSuccess;
    payload: BuildingInsight;
    locationId: string;
    chartId: string;
}

export const fetchThresholdBreachSingleInsightSuccess = (
    payload: BuildingInsight,
    locationId: string,
    chartId: string
): FetchThresholdBreachSingleInsightSuccess => ({
    type: ThresholdBreachInsight.FetchThresholdBreachSingleInsightSuccess,
    payload,
    locationId,
    chartId,
});

export interface DeleteThresholdBreachInsight extends BusinessActionRequestType {
    type: ThresholdBreachInsight.DeleteThresholdBreachInsightInit;
    chartId: string;
    buildingId: string;
}

export const deleteThresholdBreachInsight = (chartId: string, buildingId: string): DeleteThresholdBreachInsight => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.DeleteThresholdBreachInsight,
    type: ThresholdBreachInsight.DeleteThresholdBreachInsightInit,
    chartId,
    buildingId,
});

export interface DeleteThresholdBreachSuccess {
    type: ThresholdBreachInsight.DeleteThresholdBreachInsightSuccess;
    chartId: string;
    buildingId: string;
}

export const deleteThresholdBreachSuccess = (chartId: string, buildingId: string): DeleteThresholdBreachSuccess => ({
    type: ThresholdBreachInsight.DeleteThresholdBreachInsightSuccess,
    chartId,
    buildingId,
});

export interface CreateThresholdBreachInsight extends BusinessActionRequestType {
    type: ThresholdBreachInsight.CreateThresholdBreachInsightInit;
    sensor: SensorTypes;
    payload: FetchThresholdBreachAllInsightsType;
}
export const createThresholdBreachInsight = (
    sensor: SensorTypes,
    payload: FetchThresholdBreachAllInsightsType
): CreateThresholdBreachInsight => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.CreateThresholdBreachInsight,
    type: ThresholdBreachInsight.CreateThresholdBreachInsightInit,
    sensor,
    payload,
});

export interface CreateThresholdBreachInsightSuccess {
    type: ThresholdBreachInsight.CreateThresholdBreachInsightSuccess;
    data: BuildingInsight;
    buildingId: string;
}

export const createThresholdBreachInsightSuccess = (
    data: BuildingInsight,
    buildingId: string
): CreateThresholdBreachInsightSuccess => ({
    type: ThresholdBreachInsight.CreateThresholdBreachInsightSuccess,
    data,
    buildingId,
});

export interface UpdateThresholdBreachConfig extends BusinessActionRequestType {
    type: ThresholdBreachInsight.UpdateThresholdBreachConfigInit;
    thresholds: { value: number; type: BreachType }[];
    sensor: SensorTypes;
    chartId: string;
    buildingId: string;
}
export const updateThresholdBreachConfig = (
    thresholds: { value: number; type: BreachType }[],
    sensor: SensorTypes,
    chartId: string,
    buildingId: string
): UpdateThresholdBreachConfig => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.UpdateThresholdBreachConfig,
    type: ThresholdBreachInsight.UpdateThresholdBreachConfigInit,
    thresholds,
    sensor,
    chartId,
    buildingId,
});

export interface UpdateThresholdBreachConfigSuccess {
    type: ThresholdBreachInsight.UpdateThresholdBreachConfigSuccess;
    thresholds: { value: number; type: BreachType }[];
    chartId: string;
    buildingId: string;
}
export const updateThresholdBreachConfigSuccess = (
    thresholds: { value: number; type: BreachType }[],
    chartId: string,
    buildingId: string
): UpdateThresholdBreachConfigSuccess => ({
    type: ThresholdBreachInsight.UpdateThresholdBreachConfigSuccess,
    thresholds,
    chartId,
    buildingId,
});

export type ThresholdBreachActions =
    | FetchThresholdBreachAllInsights
    | FetchThresholdBreachAllInsightsSuccess
    | FetchThresholdBreachSingleInsight
    | FetchThresholdBreachSingleInsightSuccess
    | DeleteThresholdBreachInsight
    | DeleteThresholdBreachSuccess
    | UpdateThresholdBreachConfig
    | UpdateThresholdBreachConfigSuccess
    | CreateThresholdBreachInsight
    | CreateThresholdBreachInsightSuccess;
