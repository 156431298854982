import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import useWindowSize from 'commons/src/hooks/useWindowSize';
import { BuildingSummaryHealthStatus } from '../../../models/buildingModels';
import offlineTablePlaceholder from '../../status/buildingStatus/components/OfflineTablePlaceholder';
import MobileOfflineBuildingsTable from './MobileOfflineBuildingsTable';
import OfflineBuildingsTable from './OfflineBuildingsTable';
import styles from './PartnerDesktopTable.module.scss';

type Props = {
    offlineLocations: BuildingSummaryHealthStatus[];
};
const MOBILE_TABLE_BREAKPOINT = 800;

const ExpandedCustomerRow = ({ offlineLocations }: Props): React.ReactElement => {
    const { width } = useWindowSize();

    return (
        <div className={styles.expandedRowWrapper}>
            <div className={styles.expandedRow}>
                <ReactPlaceholder ready={!!offlineLocations} customPlaceholder={offlineTablePlaceholder}>
                    <div style={{ order: offlineLocations.length === 0 ? 1 : 0 }}>
                        {width > MOBILE_TABLE_BREAKPOINT ? (
                            <OfflineBuildingsTable offlineBuildings={offlineLocations || []} darkHeader />
                        ) : (
                            <MobileOfflineBuildingsTable
                                offlineBuildings={offlineLocations || []}
                                emptyTableMessage="BuildingStatus.NoOfflineBuildings"
                                darkHeader
                            />
                        )}
                    </div>
                </ReactPlaceholder>
            </div>
        </div>
    );
};

export default ExpandedCustomerRow;
