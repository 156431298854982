import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { BoldText, colorStyles, ColoredDot } from '../../../../components/PDF/PdfConstants';
import { sensorUnits } from '../../../../constants';
import { PDFContext } from '../../../../models/commonTypeScript';

const componentStyles = StyleSheet.create({
    thresholdWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '2vw',
        marginTop: '2vw',
    },
    thresholdsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: '20vw',
        marginBottom: '2.5vw',
    },
});

type ThresholdProps = {
    headerValue: string;
    unit: keyof typeof sensorUnits;
    threshold: number;
    color: string;
    arrow: string;
    nextThreshold?: number;
};

const ThresholdComponent = ({
    headerValue,
    threshold,
    unit,
    color,
    arrow,
    nextThreshold,
}: ThresholdProps): React.ReactElement => (
    <View style={componentStyles.thresholdWrapper}>
        <ColoredDot style={colorStyles[color]} />
        <View>
            <BoldText style={colorStyles.smallText}>{headerValue}</BoldText>
            <Text style={colorStyles.smallText}>
                {arrow}
                {threshold}
                {sensorUnits[unit]}
                {nextThreshold && ['  ', '< ', nextThreshold, ' ', unit && sensorUnits[unit]]}
            </Text>
        </View>
    </View>
);

type Props = {
    unit: keyof typeof sensorUnits;
    thresholds: number[];
    txt: PDFContext;
};

const Thresholds = ({ txt, thresholds, unit }: Props): React.ReactElement => (
    <View style={componentStyles.thresholdsContainer}>
        <ThresholdComponent
            headerValue={txt('ThresholdKeys.AcceptedLevel')}
            threshold={thresholds[0]}
            unit={unit}
            color="green"
            arrow="<"
        />
        <ThresholdComponent
            headerValue={txt('ThresholdKeys.ActionLevel')}
            threshold={thresholds[0]}
            nextThreshold={thresholds[1]}
            unit={unit}
            color="yellow"
            arrow={'\u2265'}
        />
        <ThresholdComponent
            headerValue={txt('ThresholdKeys.WarningLevel')}
            threshold={thresholds[1]}
            unit={unit}
            color="red"
            arrow={'\u2265'}
        />
    </View>
);

export default Thresholds;
