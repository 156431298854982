import React, { useEffect, useState, useRef, MutableRefObject } from 'react';

import {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    EditToolbar,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Draw,
    LatLngExpression,
    LatLng,
    featureGroup,
    Handler,
    Polygon,
    Marker,
    FeatureGroup as FeatureGroupType,
} from 'leaflet';
import { FeatureGroup, useMap } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { FloorplanZone } from 'commons/src/models/commonTypeScript';
import DeviceMarkerIcons from './DeviceMarkerIcons';

type Props = {
    innerRef: React.MutableRefObject<{ zone: Polygon; marker: Marker } | undefined>;
    selectedFloorPlanZone: FloorplanZone | undefined;
    onZoneCreated: () => void;
};

const FloorEditor = ({ innerRef, selectedFloorPlanZone, onZoneCreated }: Props): React.ReactElement => {
    const map = useMap();

    const [zonePositions, setZonePositions] = useState<undefined | number[][] | LatLngExpression[][]>(undefined);
    const [markerPosition, setMarkerPosition] = useState<undefined | LatLng | [number, number]>(undefined);

    const editRef: MutableRefObject<{ editFeatureGroup: FeatureGroupType; editHandler: Handler } | undefined> =
        useRef();

    useEffect(() => {
        const editFeatureGroup = featureGroup();
        const editToolbar: EditToolbar = new EditToolbar({ featureGroup: editFeatureGroup });
        const editHandler = editToolbar.getModeHandlers(map)[0].handler;

        editRef.current = {
            editFeatureGroup,
            editHandler,
        };
    }, []);

    // This effect enables drawing of a new polygon (i.e. when adding new zone).
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        if (selectedFloorPlanZone) return (): void => {};

        const onPolygonCreated = (event: { layer: Polygon }): void => {
            const polygon = event.layer;
            polygon.remove();
            const newZonePositions = (polygon.getLatLngs() as LatLng[][])[0].map(position => [
                position.lat,
                position.lng,
            ]);
            const newMarkerPosition = polygon.getBounds().getCenter();
            setZonePositions(newZonePositions);
            setMarkerPosition(newMarkerPosition);
            onZoneCreated();
            map.off(Draw.Event.CREATED, onPolygonCreated);
        };

        map.on(Draw.Event.CREATED, onPolygonCreated);
        const polygonDrawer = new Draw.Polygon(map);
        polygonDrawer.enable();

        // eslint-disable-next-line no-param-reassign
        innerRef.current = {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            undo: (): void => polygonDrawer.deleteLastVertex(),
        };

        return (): void => {
            // eslint-disable-next-line no-param-reassign,@typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            innerRef.current.undo = undefined;
            polygonDrawer.disable();
        };
    }, [selectedFloorPlanZone]);

    // This effect sets zone and marker positions when editing an existing device.
    useEffect(() => {
        if (selectedFloorPlanZone && selectedFloorPlanZone.device) {
            const selectedMarkerPosition: [number, number] = [
                selectedFloorPlanZone.device.position.lat,
                selectedFloorPlanZone.device.position.lng,
            ];
            const selectedZonePositions = selectedFloorPlanZone.boundary.map(points => [points.lat, points.lng]);
            setZonePositions(selectedZonePositions);
            setMarkerPosition(selectedMarkerPosition);
        }
    }, [selectedFloorPlanZone]);

    // This effect enables editing of device position and zone boundary.
    // Because custom UI elements (e.g. the toolbar) are used for edit actions,
    // the marker and zone are added to and removed from the map manually.
    useEffect(() => {
        if (zonePositions && markerPosition && editRef && editRef.current) {
            const { editFeatureGroup, editHandler } = editRef.current;
            const editHandlerRef = editHandler;
            const editFeatureGroupRef = editFeatureGroup;
            const zone = new Polygon(zonePositions as LatLngExpression[][]);
            const marker = new Marker(markerPosition);
            marker.setIcon(DeviceMarkerIcons.Default);
            map.addLayer(zone);
            map.addLayer(marker);
            editFeatureGroup.addLayer(zone);
            editFeatureGroup.addLayer(marker);
            editHandler.enable();

            // eslint-disable-next-line no-param-reassign
            innerRef.current = {
                zone,
                marker,
            };

            return (): void => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line no-param-reassign
                innerRef.current = {};
                if (editHandlerRef) editHandlerRef.disable();
                if (editFeatureGroupRef) editFeatureGroupRef.removeLayer(zone);
                if (editFeatureGroupRef) editFeatureGroupRef.removeLayer(marker);
                map.removeLayer(zone);
                map.removeLayer(marker);
            };
        }
        return (): void => undefined;
    }, [zonePositions, markerPosition]);

    return (
        <FeatureGroup>
            <EditControl
                position="topright"
                draw={{
                    polyline: false,
                    polygon: false,
                    rectangle: false,
                    circle: false,
                    marker: false,
                    circlemarker: false,
                }}
                edit={{
                    edit: false,
                    remove: false,
                }}
            />
        </FeatureGroup>
    );
};

export default FloorEditor;
