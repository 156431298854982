import dotProp from 'dot-prop-immutable';

import { HubActionType, HubReducerActions } from '../actions/HubActions';
import {
    LocationReducerActions,
    UPDATE_LOCATION_DETAILS_SUCCESS,
    VALIDATE_LOCATION_RF_REGION_CLEARS_STATE,
    VALIDATE_LOCATION_RF_REGION_SUCCESS,
} from '../actions/LocationActions';
import {
    ADD_LOCATION_SUCCESS,
    DELETE_LOCATION_SUCCESS,
    FETCH_LOCATIONS,
    FETCH_LOCATIONS_ERROR,
    FETCH_LOCATIONS_SUCCESS,
} from '../actions/LocationActionTypes';
import { LOGOUT_SUCCESS, LogOutSuccess } from '../actions/LoginAndRegisterActions';
import { LocationRfRegionResponse, LocationType, LocationDetailsType } from '../models/commonTypeScript';

export type LocationStore = {
    error: boolean;
    loading: boolean;
    locations: LocationType[];
    errorCode?: string;
    rfRegionResponse?: LocationRfRegionResponse;
};

const initialState: LocationStore = {
    error: false,
    loading: false,
    locations: [],
    errorCode: undefined,
    rfRegionResponse: undefined,
};

const removeHubFromLocation = (
    hubSerialNumber: string,
    locationId: string,
    locations: LocationType[]
): LocationType[] =>
    locations.map(location => {
        if (location.id === locationId) {
            const indexOfHubToDelete = location.hubs.findIndex(serialNumber => serialNumber === hubSerialNumber);
            return {
                ...location,
                hubs: indexOfHubToDelete > -1 ? dotProp.delete(location.hubs, indexOfHubToDelete) : location.hubs,
            };
        }
        return location;
    });

const updateLocation = (updatedDetails: LocationDetailsType, locations: LocationType[]): LocationType[] =>
    locations.map(location => {
        if (location.id !== updatedDetails.id) return location;
        return {
            ...location,
            ...updatedDetails,
        };
    });

type LocationActions = LocationReducerActions | HubReducerActions | LogOutSuccess;

export default (state: LocationStore = initialState, action: LocationActions): LocationStore => {
    switch (action.type) {
        case FETCH_LOCATIONS:
            return {
                ...state,
                loading: true,
                error: false,
                errorCode: undefined,
            };
        case FETCH_LOCATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                locations: action.payload.locations,
                errorCode: undefined,
            };
        case ADD_LOCATION_SUCCESS:
            return {
                ...state,
                locations: [...state.locations, action.payload],
            };
        case FETCH_LOCATIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorCode: undefined,
            };
        case HubActionType.DeleteHubSuccess:
            return {
                ...state,
                locations: removeHubFromLocation(action.serialNumber, action.locationId, state.locations),
            };
        case UPDATE_LOCATION_DETAILS_SUCCESS:
            return {
                ...state,
                locations: updateLocation(action.locationDetails, state.locations),
            };
        case DELETE_LOCATION_SUCCESS:
            return {
                ...state,
                locations: state.locations.filter(location => location.id !== action.locationId),
            };
        case LOGOUT_SUCCESS:
            return initialState;
        case VALIDATE_LOCATION_RF_REGION_SUCCESS: {
            return {
                ...state,
                rfRegionResponse: action.rfRegionValidationResponse,
            };
        }
        case VALIDATE_LOCATION_RF_REGION_CLEARS_STATE: {
            return {
                ...state,
                rfRegionResponse: undefined,
            };
        }
        default:
            return state;
    }
};
