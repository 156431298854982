import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import { dateFormats } from '../../constants';
import { PDFContext } from '../../models/commonTypeScript';
import { FlexWrapperSpaced } from './PdfConstants';

type Props = {
    userName: string;
    dateFormat: keyof typeof dateFormats;
    txt: PDFContext;
    reportId: string;
    footerTextKey?: string;
};

const componentStyles = StyleSheet.create({
    smallTextParagraph: {
        fontSize: 8,
        padding: '2vw 0',
    },
    flexWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    smallText: {
        fontSize: 8,
    },
    details: {
        fontSize: 8,
        marginTop: '3vw',
    },
});

const ReportDetails = ({ userName, dateFormat, reportId, txt, footerTextKey }: Props): React.ReactElement => (
    <View style={componentStyles.details}>
        <FlexWrapperSpaced>
            <View>
                <View style={componentStyles.flexWrapper}>
                    <Text style={componentStyles.smallText}>
                        {txt('Report.Generated')}
                        {': '}
                    </Text>
                    <Text style={componentStyles.smallText}>
                        {moment().format(dateFormats[dateFormat].shortFormat)}{' '}
                        {moment().format(dateFormats[dateFormat].hourFormat)}
                    </Text>
                </View>
                <View style={componentStyles.flexWrapper}>
                    <Text style={componentStyles.smallText}>
                        {txt('Report.MeasurementBy')}
                        {': '}
                    </Text>
                    <Text style={componentStyles.smallText}>{userName}</Text>
                </View>
                <View style={componentStyles.flexWrapper}>
                    <Text style={componentStyles.smallText}>
                        {txt('Report.ReportId')}
                        {': '}
                    </Text>
                    <Text style={componentStyles.smallText}>{reportId}</Text>
                </View>
            </View>
            <View>
                <View style={componentStyles.flexWrapper}>
                    <Text style={componentStyles.smallText}>
                        {txt('RadonInsight.SamplingMethod')}
                        {': '}
                    </Text>
                    <Text style={componentStyles.smallText}>{txt('RadonInsight.PassiveDiffusionChamber')}</Text>
                </View>
                <View style={componentStyles.flexWrapper}>
                    <Text style={componentStyles.smallText}>
                        {txt('RadonInsight.MeasurementMethod')}
                        {': '}
                    </Text>
                    <Text style={componentStyles.smallText}>{txt('RadonInsight.ContinuousAlphaSpectrometry')}</Text>
                </View>
            </View>
        </FlexWrapperSpaced>
        <Text style={componentStyles.smallTextParagraph}>
            {txt(footerTextKey || 'RadonInsight.FooterReportTechnicalInformation')}
        </Text>
    </View>
);

export default ReportDetails;
