import React from 'react';
import { createRoot } from 'react-dom/client';
import BusinessDashboard from 'business-dashboard/src/index';
import { getSelectedGroupFromStorage } from 'commons/src/components/findUserType';
import 'commons/src/style/index.scss';
import { GroupType } from 'commons/src/models/commonEnums';
import ConsumerDashboard from 'consumer-dashboard/src/index';

const App = (): React.ReactElement => {
    const activeUserGroupType: GroupType | undefined = getSelectedGroupFromStorage()?.groupType;
    return activeUserGroupType === GroupType.consumer ? <ConsumerDashboard /> : <BusinessDashboard />;
};

createRoot(document.getElementById('root') as HTMLElement).render(<App />);

export default App;
