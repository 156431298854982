import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PlacementType, PredefinedProperty } from 'commons/src/models/commonEnums';
import { SpaceProperty } from 'commons/src/models/commonTypeScript';
import { spacesSelector } from '../features/spaces/space/spaceSelectors';
import {
    SpacePropertyDefinitionAndValue,
    IndoorSpace,
    PredefinedProperties,
    RoomProperties,
} from '../models/spaceModels';
import { Store } from '../reducers';

type UseCustomProperties = {
    predefinedProperties: PredefinedProperties;
    customProperties: SpacePropertyDefinitionAndValue[];
};

const useCustomProperties = (locationId: string, spaceId?: string): UseCustomProperties => {
    const spacePropDefs = useSelector((state: Store) => state.config.spacePropDefs);
    const { spaces } = useSelector((state: Store) => spacesSelector(state, locationId));

    const indoorSpaces = useMemo(() => {
        if (spaces?.spaces?.length)
            return spaces.spaces.filter(space => space.placement === PlacementType.ROOM) as IndoorSpace[];
        return [];
    }, [spaces]);

    const [predefinedSpecs, customSpecs] = useMemo(() => {
        const predefined = Object.entries(spacePropDefs).filter(
            spec => spec[1].predefinedType !== PredefinedProperty.Custom
        );
        const custom = Object.entries(spacePropDefs).filter(
            spec => spec[1].predefinedType === PredefinedProperty.Custom
        );
        return [predefined, custom];
    }, [spacePropDefs]);

    const spaceProperties = useMemo(() => {
        if (!locationId || !spaceId) return {};
        return indoorSpaces.find(({ id }) => id === spaceId)?.properties ?? {};
    }, [indoorSpaces, spaceId]);

    const propertyState = (
        propertyDefinition: SpaceProperty,
        propertyDefinitionId: string,
        spaceProps: { [key: string]: RoomProperties }
    ): SpacePropertyDefinitionAndValue => {
        const propertyValue = spaceProps?.[propertyDefinitionId];
        const value = propertyValue?.optionId ?? propertyValue?.value;
        const propertyOptions = propertyDefinition?.value?.options;
        return {
            propertyDefinition,
            propertyDefinitionId,
            propertyOptions,
            propertyValue: value,
        };
    };

    const predefinedProperties = useMemo(
        (): { [key in PredefinedProperty]?: SpacePropertyDefinitionAndValue } =>
            predefinedSpecs.reduce((combined, [propertyDefinitionId, propertyDefinition]) => {
                return {
                    ...combined,
                    [propertyDefinition.predefinedType]: {
                        ...propertyState(propertyDefinition, propertyDefinitionId, spaceProperties),
                    },
                };
            }, {}),
        [predefinedSpecs, spaceProperties]
    );

    const customProperties = useMemo(
        (): SpacePropertyDefinitionAndValue[] =>
            customSpecs.map(([propertyDefinitionId, propertyDefinition]) => {
                return propertyState(propertyDefinition, propertyDefinitionId, spaceProperties);
            }),
        [customSpecs, spaceProperties]
    );

    return {
        predefinedProperties,
        customProperties,
    };
};

export default useCustomProperties;
