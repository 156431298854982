import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import Moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { getValidLanguageTagForMoment, mapDeviceType } from '../../commonFunctions';
import AccordionButton from '../../components/buttons/AccordionButton';
import DeviceStatusPill from '../../components/pills/DeviceStatusPill';
import { fullwidthListElement } from '../../components/placeholder';
import BatteryComponent from '../../components/sensors/BatteryComponent';
import RssiComponent from '../../components/sensors/RssiComponent';
import { dateFormats } from '../../constants';
import { DeviceTypeNames } from '../../models/commonEnums';
import { AnyDeviceType, FullDeviceData } from '../../models/commonTypeScript';
import DeviceHealthStatus from '../../models/deviceHealthStatus';
import styles from './DeviceInfo.module.scss';

export type Props = {
    loading: boolean;
    lastRecord?: string;
    dateFormat: keyof typeof dateFormats;
    endedSegment: boolean;
    device: FullDeviceData;
    serialNumber: string;
};

export const DeviceInfoComponent = ({
    loading,
    lastRecord,
    dateFormat,
    endedSegment,
    device,
    serialNumber,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const language = getValidLanguageTagForMoment();
    const { batteryPercentage, segmentStart, segmentEnd, deviceType, rssi, healthStatus } = device;
    const lastSynced = lastRecord ? moment.utc(lastRecord).local().locale(language).fromNow() : txt('neverSynced');

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const deviceOffline = healthStatus === DeviceHealthStatus.offline;
    const toggleDropdown = (): void => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const { calendarFormat } = dateFormats[dateFormat];
    const hideStatusForRenew = deviceType === DeviceTypeNames.ap1 && deviceOffline;

    const syncedSection = (): React.ReactElement => {
        if (endedSegment) {
            return (
                <>
                    <div> {txt('SegmentEnded')} </div>
                    <div>{moment(segmentEnd).format(dateFormats[dateFormat].calendarFormat)}</div>
                </>
            );
        }
        if (!lastRecord || deviceOffline) {
            return <DeviceStatusPill status={healthStatus as DeviceHealthStatus} deviceType="device" />;
        }
        return (
            <div
                className={classNames([styles.aligned], {
                    [styles.offlineRssi]: deviceOffline,
                })}
            >
                <div> {txt('LastSyncedNoSemicolon')} </div>
                <div>{lastSynced}</div>
            </div>
        );
    };

    return (
        <ReactPlaceholder ready={!loading} customPlaceholder={fullwidthListElement}>
            <div className={styles.infoHeader}>
                <div className={styles.content}>
                    <div className={styles.deviceInfo}>
                        <div className={styles.serialNumberAndDevice}>
                            <div className={styles.chip}>{txt(mapDeviceType(deviceType as AnyDeviceType))}</div>
                            <div className={styles.serialNumber}>{serialNumber}</div>
                        </div>
                        {!hideStatusForRenew && <div className={styles.aligned}>{syncedSection()}</div>}
                        <div className={styles.batteryAndRssi}>
                            <BatteryComponent
                                batteryPercentage={batteryPercentage}
                                healthStatus={healthStatus as DeviceHealthStatus}
                            />
                            {!hideStatusForRenew && (
                                <RssiComponent rssi={rssi} healthStatus={healthStatus as DeviceHealthStatus} />
                            )}
                        </div>
                    </div>
                    <AccordionButton
                        onClick={toggleDropdown}
                        expanded={isDropdownOpen}
                        id="buttondropdown"
                        title={isDropdownOpen ? 'LessDetails' : 'MoreDetails'}
                    />
                </div>
                {isDropdownOpen && (
                    <div className={styles.dropdownContent}>
                        {txt('MeasurementStarted')}: {Moment(segmentStart).format(calendarFormat)}
                    </div>
                )}
            </div>
        </ReactPlaceholder>
    );
};

export default DeviceInfoComponent;
