import React from 'react';
import classNames from 'classnames';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DeviceInfo from 'commons/src/components/device/DeviceInfo';
import LabeledText from 'commons/src/components/LabeledText';
import { Rating, SensorTypes } from 'commons/src/models/commonEnums';
import { DayUsageHours, DeviceType, SegmentProperties, SensorThresholds } from 'commons/src/models/commonTypeScript';
import { VirusRiskInsightData } from '../../../models/common';
import { Store } from '../../../reducers';
import CircledAverage from '../iaqInsight/CircledAverage';
import InsightGraph from '../iaqInsight/InsightGraph';
import HighImpactFactor from './HighImpactFactor';
import deviceLevel from './VirusFunctions';

export type ParentProps = {
    usageHours: { [day: string]: DayUsageHours };
    deviceResult: VirusRiskInsightData;
    from: Moment | null;
    to: Moment | null;
    setInfoModalOpen: (props: { open: boolean; selectedTab: number }) => void;
    timeZone: string;
};

type StateProps = {
    devices: { [serialNumber: string]: DeviceType };
    segmentProperties: { [serialNumber: string]: SegmentProperties };
    possibleRiskFactors: { riskName: string; sensorName: string }[];
    thresholds: { [sensor: string]: SensorThresholds };
};

export type Props = ParentProps & StateProps;

export const riskLevel = (
    sensorName: string,
    deviceResult: VirusRiskInsightData,
    thresholds: { [sensor: string]: SensorThresholds }
): Rating => {
    const riskFactorValue = deviceResult.riskFactors[sensorName] && deviceResult.riskFactors[sensorName].value;
    const riskThresholds = thresholds[sensorName] ? thresholds[sensorName].ranges : [];
    return deviceLevel(riskFactorValue, riskThresholds);
};

export const solutionsList = (
    deviceResult: VirusRiskInsightData,
    thresholds: { [sensor: string]: SensorThresholds }
): { riskLevel: Rating; solution: string }[] => {
    const solutionsListWithDuplicates = Object.keys(deviceResult.riskFactors)
        .map(factor => {
            const { solutions } = deviceResult.riskFactors[factor];
            const solutionLevel = riskLevel(factor, deviceResult, thresholds);
            return solutions.map(solution => ({ solution, riskLevel: solutionLevel }));
        })
        .flat();

    return solutionsListWithDuplicates.reduce(
        (listWithoutDuplicates: { riskLevel: Rating; solution: string }[], solution, index) => {
            const solutionsOfType = solutionsListWithDuplicates.filter(
                (otherSolution, i) => i !== index && solution.solution === otherSolution.solution
            );
            const alreadyInList = listWithoutDuplicates.find(
                solutionElement => solutionElement.solution === solution.solution
            );
            let solutionToAdd = solution;
            const higherLevelRisk =
                solution.riskLevel !== Rating.POOR &&
                solutionsOfType.find(solutionElement => solutionElement.riskLevel === Rating.POOR);
            if (higherLevelRisk) solutionToAdd = higherLevelRisk;

            if (!alreadyInList) {
                return [...listWithoutDuplicates, solutionToAdd];
            }
            return listWithoutDuplicates;
        },
        []
    );
};

export const VirusDeviceResultComponent = (props: Props): React.ReactElement => {
    const {
        thresholds,
        devices,
        deviceResult,
        usageHours,
        segmentProperties,
        from,
        to,
        possibleRiskFactors,
        setInfoModalOpen,
        timeZone,
    } = props;
    const { t: txt } = useTranslation();
    const { serialNumber } = deviceResult;
    const device = devices[serialNumber];
    const virusThresholds = thresholds.virusRisk;
    const segmentProps: SegmentProperties = segmentProperties[serialNumber];
    const listOfSolutions = solutionsList(deviceResult, thresholds);
    const possibleRiskFactor = (riskFactor: { riskName: string; sensorName: string }): React.ReactElement => {
        const deviceOnLevel = riskLevel(riskFactor.sensorName, deviceResult, thresholds);
        return (
            <div className="insight-tile__risk-factors__factor" key={`${riskFactor.riskName}-${serialNumber}`}>
                <div
                    className={classNames('sensor__line', {
                        'sensor__line--ok': deviceOnLevel === Rating.GOOD,
                        'sensor__line--alert': deviceOnLevel === Rating.POOR,
                        'sensor__line--warning': deviceOnLevel === Rating.FAIR,
                    })}
                />
                <span>{txt(`InsightRiskFactor.${riskFactor.riskName}`)}</span>
            </div>
        );
    };

    return (
        <div className="form__wide-container insight-tile insight-tile--full-width">
            <div className="insight-tile__header">
                <h3 className="insight-tile__header__name">{device && device.segmentName}</h3>
                <DeviceInfo serialNumber={deviceResult.serialNumber} deviceType={device && device.type} />
                <div className="flex">
                    <div className="form__field--slim insight__slim-labeled-text">
                        <LabeledText
                            label="Floor"
                            id={`deviceFloor${deviceResult.serialNumber}`}
                            invalid={false}
                            value={segmentProps && segmentProps.floor !== undefined ? `${segmentProps.floor}` : '-'}
                        />
                    </div>
                    <div className="form__field--slim insight__slim-labeled-text">
                        <LabeledText
                            label="RoomType"
                            id={`roomType${deviceResult.serialNumber}`}
                            invalid={false}
                            value={
                                segmentProps && segmentProps.roomType
                                    ? txt(`RoomTypeOptions.${segmentProps.roomType}`)
                                    : ''
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="insight-tile__measurement-wrapper">
                <div className="insight-tile__measurement-wrapper__content">
                    <CircledAverage
                        ranges={virusThresholds.ranges}
                        value={deviceResult.averageValueWithinOpeningHours}
                        unit={virusThresholds.unit}
                        sensorType={SensorTypes.virusRisk}
                        headerText="VirusInsight.AverageRiskLevelOpeningHours"
                        large
                    />
                </div>
                <div className="insight-tile__measurement-wrapper__content">
                    <div className="text-large text-bold">{txt('VirusInsight.ContributingRiskFactors')}</div>
                    <div className="insight-tile__risk-factors">
                        {possibleRiskFactors.map(riskFactor => possibleRiskFactor(riskFactor))}
                    </div>
                </div>
            </div>
            <div id="insightGraph" className="insight-tile__content insight-tile__content--graph">
                <InsightGraph
                    sensor="virusRisk"
                    graphId={deviceResult.serialNumber}
                    usageHours={usageHours}
                    from={from}
                    to={to}
                    unit={virusThresholds.unit}
                    chartData={deviceResult.graph}
                    ranges={virusThresholds.ranges}
                    timeZone={timeZone}
                />
            </div>
            {listOfSolutions.length > 0 && (
                <>
                    <h3>{txt('InsightRiskFactor.Solutions')}</h3>
                    <div className="insight-tile__content insight-tile__content--wrap">
                        {listOfSolutions.map(riskFactor => (
                            <HighImpactFactor
                                key={`solution-button-${riskFactor.solution}-${deviceResult.serialNumber}`}
                                riskSolution={riskFactor.solution}
                                setInfoModalOpen={setInfoModalOpen}
                                warningLevel={riskFactor.riskLevel}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { devices },
        segmentPropertiesStore: { segmentProperties },
        virusRiskInsight: { thresholds, possibleRiskFactors },
    } = state;
    return {
        devices,
        segmentProperties,
        possibleRiskFactors,
        thresholds,
    };
};

export default connect(mapStateToProps)(VirusDeviceResultComponent);
