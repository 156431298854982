/* eslint max-len: ["error", 15000] */
import React from 'react';

const OccupancyInsightIllustration = (): React.ReactElement => (
    <svg width="596px" height="300px" viewBox="0 0 596 300" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path
            fill="#42515A"
            d="M335.1,236.6c2.3,1.6,4.8,2.7,7.5,3.6c2.6,1,5.3,1.7,8,2.1c5.4,1,11,1.1,16.5,0.6c5.5-0.5,10.8-1.8,16-3.6
            c4.9-1.9,9.6-4.4,13.8-7.4c4-3,7.8-6.4,10.8-10.4c0.3-0.5,0.6-1,1-1.5c0.4-0.7,0.2-1.7-0.5-2.1s-1.7-0.2-2.1,0.5l0,0
            c-0.6,1-1.3,1.9-1.9,2.9c-0.2,0.2-0.3,0.5-0.5,0.6c-0.1,0.1-0.2,0.2-0.2,0.3l-0.2,0.2c0.1-0.2,0.1-0.1,0,0
            c-0.3,0.5-0.7,0.9-1.1,1.4c-1.6,1.9-3.3,3.6-5.2,5.1l-0.3,0.2l-0.2,0.2c-0.2,0.1,0.1-0.1,0,0c-0.2,0.2-0.6,0.4-0.8,0.6
            c-0.5,0.3-1,0.7-1.5,1.1c-1.1,0.7-2.1,1.4-3.2,2c-2.2,1.3-4.5,2.4-6.9,3.5c-4.8,2-10,3.5-15.2,4.4c-5.3,0.8-10.7,0.9-16,0.3
            c-5.4-0.6-10.6-2.2-15.6-4.3c-0.6-0.2-1.3-0.5-1.9-0.7C335,236.2,335,236.6,335.1,236.6L335.1,236.6z"
        />
        <path
            fill="#42515A"
            d="M406.5,234.3c0.5-0.5,0.7-1.3,1-2c0.2-0.7,0.4-1.3,0.6-1.9c0.3-1.4,0.6-2.8,0.6-4.2
            c0.2-1.4,0.2-2.7,0.3-4.1c0-0.6,0.1-1.3,0.1-1.9c0-0.3,0-0.7-0.1-1.1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.2-0.3-0.6-0.3-0.8-0.2
            c-0.1,0-0.2,0.1-0.2,0.2c-0.2,0.2-0.3,0.5-0.5,0.7c-0.2,0.3-0.2,0.7-0.2,1.1c-0.1,0.6-0.1,1.3-0.1,1.9c-0.1,1.3-0.2,2.7-0.2,4
            c-0.1,1.3-0.3,2.6-0.6,3.8c-0.1,0.6-0.2,1.3-0.4,1.9c-0.2,0.6-0.5,1.3-0.5,2c0,0.4,0.3,0.8,0.8,0.8
            C406.1,234.4,406.4,234.4,406.5,234.3L406.5,234.3z"
        />
        <path
            fill="#42515A"
            d="M406.5,218.7c-0.5,0-1,0.3-1.4,0.6c-0.4,0.2-0.9,0.4-1.3,0.5c-1.1,0.3-2.3,0.6-3.5,0.8
            c-1.1,0.2-2.3,0.3-3.4,0.3c-0.6,0-1.1,0-1.7,0.1c-0.3,0-0.6,0-0.9,0.1H394c0.2,0-0.1,0-0.2,0c-0.4,0-0.7,0.4-0.7,0.8
            c0,0.3,0.2,0.6,0.6,0.7c1.7,0.6,3.7,0.3,5.5,0.1c2-0.2,4-0.7,5.7-1.5c0.4-0.2,0.8-0.4,1.2-0.6c0.3-0.2,0.9-0.6,1-1.1
            c0.1-0.3-0.1-0.7-0.5-0.8C406.6,218.7,406.5,218.7,406.5,218.7L406.5,218.7z"
        />
        <polygon fill="#FFFFFF" points="347.7,171.7 295.6,267.9 106.1,230.8 98.8,162.9 175.2,91.3 350.3,106.7" />
        <polygon fill="#FFC000" points="206.8,138.4 207.1,159 145.9,150.3 143,121.3 175.3,91.9 236.8,96.3" />
        <polygon fill="#FFC000" points="348.9,136.2 316.5,191.4 263.1,182.7 296,131.7 296.3,101.8 350.3,106.7" />
        <polyline fill="#FFC000" points="175.4,207.9 175.4,207.9 179.6,244.4 135.6,235.6 134,201.5" />
        <path
            fill="#FFC000"
            d="M466,201.7v-1.9h-4.6c0.2-0.5,0.6-1.1,1.6-1.8l0.8-0.6c1.5-1.1,2.3-2.3,2.3-3.3
            c0-0.8-0.4-1.6-1.1-2.1c-0.8-0.6-1.9-0.8-2.8-0.7c-1.1,0-2.3,0.2-3.3,0.6v1.7c0.8-0.4,1.8-0.6,2.7-0.7
            c1.2,0,1.8,0.5,1.8,1.5s-0.8,2.3-2.5,3.6l-1,0.7c-0.5,0.4-0.9,0.8-1.3,1.3v1.9L466,201.7L466,201.7L466,201.7z M446.1,195
            c-0.6,0-1.3-0.2-1.9-0.4c-0.6-0.2-1.1-0.6-1.5-1.1c-0.4-0.5-0.7-1.1-1-1.7c-0.5-1.3-0.5-2.8,0-4.1c0.2-0.6,0.6-1.1,1-1.6
            c1.4-1.5,3.6-1.9,5.4-1.1c0.6,0.2,1.1,0.6,1.5,1.1c0.4,0.5,0.7,1.1,1,1.6c0.2,0.6,0.3,1.3,0.3,2s-0.1,1.5-0.3,2.1
            c-0.2,0.6-0.6,1.2-1,1.7c-0.4,0.5-1,0.8-1.5,1.1C447.4,194.8,446.7,195,446.1,195z M446.1,198.6c1.3,0,2.5-0.2,3.6-0.6
            c1.1-0.4,2.1-1,2.9-1.8c0.8-0.8,1.5-1.8,1.9-2.8c0.5-1.1,0.7-2.4,0.6-3.6c0-1.3-0.2-2.5-0.6-3.6c-0.4-1.1-1.1-2-1.9-2.7
            c-0.8-0.8-1.9-1.4-2.9-1.8c-1.2-0.4-2.4-0.6-3.6-0.6c-1.2,0-2.5,0.2-3.6,0.6c-1.1,0.4-2,1-2.9,1.8c-0.8,0.8-1.5,1.7-1.9,2.7
            c-0.5,1.1-0.7,2.4-0.6,3.6c0,1.3,0.2,2.5,0.6,3.6c0.4,1.1,1.1,2,1.9,2.8c0.8,0.8,1.9,1.4,2.9,1.8
            C443.6,198.5,444.8,198.6,446.1,198.6L446.1,198.6z M429.3,198.6c1.3,0,2.6-0.2,3.8-0.7c1.1-0.5,2-1.1,2.7-2l-2.8-2.7
            c-0.3,0.5-0.9,1-1.5,1.2c-0.6,0.3-1.4,0.5-2.1,0.5c-0.6,0-1.3-0.1-1.9-0.3c-0.6-0.2-1.1-0.6-1.5-1.1c-0.4-0.5-0.7-1-1-1.6
            c-0.2-0.6-0.3-1.4-0.3-2.1c0-0.6,0.1-1.4,0.3-2c0.2-0.6,0.6-1.1,1-1.6s1-0.8,1.5-1.1c0.6-0.2,1.3-0.4,1.9-0.4
            c0.6,0,1.3,0.2,1.9,0.4c0.6,0.2,1.1,0.6,1.5,1.1l2.7-2.7c-0.7-0.8-1.7-1.5-2.8-1.9c-2.3-0.8-4.8-0.8-7.1,0
            c-1.1,0.4-2,1-2.9,1.8c-0.8,0.8-1.5,1.7-1.9,2.8c-1,2.3-1,4.9,0,7.3c0.4,1.1,1.1,2,1.9,2.8c0.8,0.8,1.8,1.4,2.8,1.8
            C426.8,198.5,428.1,198.6,429.3,198.6L429.3,198.6z"
        />
        <path
            fill="none"
            stroke="#FFC000"
            strokeWidth="0.3603"
            strokeMiterlimit="10"
            d="M466,201.7v-1.9h-4.6 c0.2-0.5,0.6-1.1,1.6-1.8l0.8-0.6c1.5-1.1,2.3-2.3,2.3-3.3c0-0.8-0.4-1.6-1.1-2.1c-0.8-0.6-1.9-0.8-2.8-0.7
            c-1.1,0-2.3,0.2-3.3,0.6v1.7c0.8-0.4,1.8-0.6,2.7-0.7c1.2,0,1.8,0.5,1.8,1.5s-0.8,2.3-2.5,3.6l-1,0.7c-0.5,0.4-0.9,0.8-1.3,1.3
            v1.9L466,201.7L466,201.7L466,201.7z M446.1,195c-0.6,0-1.3-0.2-1.9-0.4c-0.6-0.2-1.1-0.6-1.5-1.1c-0.4-0.5-0.7-1.1-1-1.7
            c-0.5-1.3-0.5-2.8,0-4.1c0.2-0.6,0.6-1.1,1-1.6c1.4-1.5,3.6-1.9,5.4-1.1c0.6,0.2,1.1,0.6,1.5,1.1c0.4,0.5,0.7,1.1,1,1.6
            c0.2,0.6,0.3,1.3,0.3,2s-0.1,1.5-0.3,2.1c-0.2,0.6-0.6,1.2-1,1.7c-0.4,0.5-1,0.8-1.5,1.1C447.4,194.8,446.7,195,446.1,195z
             M446.1,198.6c1.3,0,2.5-0.2,3.6-0.6c1.1-0.4,2.1-1,2.9-1.8c0.8-0.8,1.5-1.8,1.9-2.8c0.5-1.1,0.7-2.4,0.6-3.6
             c0-1.3-0.2-2.5-0.6-3.6c-0.4-1.1-1.1-2-1.9-2.7c-0.8-0.8-1.9-1.4-2.9-1.8c-1.2-0.4-2.4-0.6-3.6-0.6c-1.2,0-2.5,0.2-3.6,0.6
             c-1.1,0.4-2,1-2.9,1.8c-0.8,0.8-1.5,1.7-1.9,2.7c-0.5,1.1-0.7,2.4-0.6,3.6c0,1.3,0.2,2.5,0.6,3.6c0.4,1.1,1.1,2,1.9,2.8
             c0.8,0.8,1.9,1.4,2.9,1.8C443.6,198.5,444.8,198.6,446.1,198.6L446.1,198.6z M429.3,198.6c1.3,0,2.6-0.2,3.8-0.7
             c1.1-0.5,2-1.1,2.7-2l-2.8-2.7c-0.3,0.5-0.9,1-1.5,1.2c-0.6,0.3-1.4,0.5-2.1,0.5c-0.6,0-1.3-0.1-1.9-0.3c-0.6-0.2-1.1-0.6-1.5-1.1
             c-0.4-0.5-0.7-1-1-1.6c-0.2-0.6-0.3-1.4-0.3-2.1c0-0.6,0.1-1.4,0.3-2c0.2-0.6,0.6-1.1,1-1.6s1-0.8,1.5-1.1
             c0.6-0.2,1.3-0.4,1.9-0.4c0.6,0,1.3,0.2,1.9,0.4c0.6,0.2,1.1,0.6,1.5,1.1l2.7-2.7c-0.7-0.8-1.7-1.5-2.8-1.9
             c-2.3-0.8-4.8-0.8-7.1,0c-1.1,0.4-2,1-2.9,1.8c-0.8,0.8-1.5,1.7-1.9,2.8c-1,2.3-1,4.9,0,7.3c0.4,1.1,1.1,2,1.9,2.8
             c0.8,0.8,1.8,1.4,2.8,1.8C426.8,198.5,428.1,198.6,429.3,198.6L429.3,198.6z"
        />
        <path
            fill="#FFC000"
            d="M491.8,179c0.1-1.7,0.3-3.5,0.3-5.2s0-3.6,0-5.3c0.1-3.5,0.1-7,0.1-10.5c0-2-0.1-4-0.2-5.9
            c-0.1-1.3-2-1.3-2,0c-0.2,4-0.1,8.2,0,12.2c0,3.7,0.2,7.4,0.1,11.1c0,0.6,0,1.1,0,1.7v0.2c0,0.3,0.1-0.3,0-0.1c0,0.1-0.1,0.2,0,0
            s0.1-0.2,0-0.1c0.2-0.4,0.4-0.3,1-0.3c0.6,0,0.7,0,0.9,0.3c-0.6-1.2-2.6-0.2-1.9,1.1c0.3,0.6,1.1,0.7,1.6,0.4
            c0.2-0.2,0.5-0.4,0.6-0.7c0.1-0.4,0.2-0.8,0.1-1.2c0.1-1.4,0-2.8,0-4.3c0-4.4-0.2-8.9-0.2-13.3c0-2.3-0.1-4.7,0-7h-2
            c0.5,7,0.3,14,0.6,21c0.2,2,0.4,4,0.7,5.9C491.4,179.2,491.8,179.2,491.8,179L491.8,179z"
        />
        <path
            fill="#FFC000"
            d="M498.2,160.7c-0.2-0.6-0.3-1.1-0.6-1.6c-0.2-0.5-0.5-1-0.6-1.5c-0.5-1.1-1-2.1-1.6-3.2
            c-1.1-2-2.3-4-3.6-5.8c-0.6-0.7-1.7-0.9-2.2,0c-0.6,1.1-1.4,2.3-2,3.4c-0.6,1.1-1.2,2.3-1.7,3.5c-0.6,1.3-1.1,2.5-1.5,3.8
            c-0.1,0.3-0.2,0.6-0.3,1c-0.1,0.4-0.1,0.8,0,1.2c0,0.6,0,1.3-0.1,1.9c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.3-0.1,0.3-0.2
            c0.2-0.6,0.6-1.1,0.9-1.6c0.2-0.2,0.4-0.6,0.6-0.8c0.2-0.3,0.3-0.6,0.4-1.1c0.4-1.1,0.9-2.3,1.4-3.5c0.5-1.1,1.1-2.3,1.7-3.4
            c0.6-1.1,1.2-2.1,1.9-3.1l-2-0.2c1,2,2.2,4,3.5,5.9c0.6,1,1.3,1.9,1.9,2.8c0.3,0.4,0.6,0.8,1,1.3s0.7,1,1.2,1.4
            c0.2,0.2,0.6,0.2,0.9,0C498.2,161.1,498.2,160.9,498.2,160.7L498.2,160.7z"
        />
        <path fill="#FFBF00" d="M231.9,239.7L231.9,239.7L231.9,239.7L231.9,239.7z" />
        <path
            fill="#42515A"
            d="M138.5,159.7c0.7,0.2,1.5,0.3,2.3,0.4c1.7,0.3,3.4,0.6,5.1,0.9c2.4,0.4,4.8,0.8,7.3,1.2
            c2.9,0.5,5.7,1,8.6,1.4c3.2,0.5,6.3,1,9.5,1.5s6.3,1,9.5,1.5c3,0.5,6,0.9,9,1.4c2.6,0.4,5.2,0.7,7.8,1.1c2,0.3,4,0.6,6.1,0.8
            c1.1,0.2,2.2,0.3,3.3,0.3c0.1,0,0.2,0,0.3,0c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9c-0.9,0-1.8,0-2.7-0.2
            c-0.8-0.1-1.6-0.2-2.3-0.3l-1.4-0.2l-0.7-0.1h-0.1c0.2,0-0.1,0-0.1,0h-0.4c-2.4-0.3-4.9-0.6-7.4-1.1c-2.9-0.4-5.8-0.9-8.8-1.3
            c-3.2-0.5-6.3-1-9.5-1.5c-3.2-0.5-6.3-1-9.5-1.5c-3-0.5-5.9-1-8.9-1.5c-2.5-0.4-5.1-0.8-7.6-1.3c-1.9-0.3-3.8-0.6-5.7-1
            c-1-0.2-2-0.3-3.1-0.6L139,158C137.9,157.7,137.3,159.4,138.5,159.7L138.5,159.7z"
        />
        <path
            fill="#42515A"
            d="M177.7,120.3c4,0.6,8,1,12,1.4c6.3,0.6,12.6,1.3,18.9,1.9c2.3,0.2,4.8,0.4,7.2,0.4c1.1,0,1.1-1.8,0-1.8
            c-2,0-4.1-0.2-6.1-0.3c-3-0.2-6-0.5-9-0.8c-6-0.6-12-1.2-17.9-1.9l-3.2-0.3l-1.1-0.1h-0.3c-0.1,0-0.5-0.1,0,0
            c-0.5-0.1-1,0.2-1.1,0.6C177,119.7,177.2,120.3,177.7,120.3L177.7,120.3z"
        />
        <path
            fill="#42515A"
            d="M236.2,124.9c-0.4,0.5-0.8,1-1.1,1.5c-0.8,1.1-1.5,2.2-2.3,3.2c-2.5,3.4-4.9,6.9-7.4,10.3
            c-2.9,4.1-5.8,8.2-8.6,12.4c-2.4,3.6-4.8,7.1-7.2,10.7c-0.7,1.2-1.5,2.3-2.3,3.6c-0.3,0.6-1,1.5-1,2.3c0,0.3,0.1,0.6,0.3,0.7
            c0.3,0.3,1,0.3,1.3,0s0.3-0.9,0-1.3c0.2,0.2,0.2,0.3,0.2,0.6c0-0.2-0.1,0.3,0,0.1c0.1-0.2,0.2-0.4,0-0.1c0.2-0.3,0.3-0.6,0.5-1
            c0.6-1.1,1.3-2.3,2-3.3c2.3-3.5,4.5-6.9,6.9-10.3c2.8-4.1,5.7-8.2,8.6-12.3c2.5-3.6,5-7,7.5-10.6c0.9-1.2,1.7-2.3,2.6-3.6
            c0.3-0.4,0.6-0.7,0.8-1.1l0.3-0.4l0.1-0.2c0.2-0.2-0.2,0.2,0,0l0.1-0.1C238.2,125.3,237,124.1,236.2,124.9L236.2,124.9z"
        />
        <path
            fill="#42515A"
            d="M235.9,96.1L235.9,96.1C235.9,96.3,236,95.9,235.9,96.1c-0.1,0.2-0.2,0.3-0.2,0.5
            c-0.2,0.4-0.5,0.8-0.7,1.1c-0.7,1.2-1.5,2.3-2.3,3.5c-2.4,3.4-4.9,6.7-7.4,10.1c-2.9,3.9-5.8,7.8-8.7,11.6
            c-2.4,3.3-4.9,6.6-7.4,9.9c-0.7,1.1-1.5,2.2-2.3,3.3c-0.3,0.6-1,1.4-1,2.1c0,0.3,0.1,0.6,0.3,0.7c0.3,0.3,1,0.3,1.3,0
            c0.3-0.3,0.3-1,0-1.3c0.2,0.2,0.2,0.3,0.2,0.6c0-0.1-0.1,0.3,0,0.2v-0.1c0-0.2-0.2,0.2,0,0c0.2-0.3,0.3-0.6,0.6-1
            c0.6-1.1,1.3-2,2-3.1c2.3-3.2,4.7-6.5,7-9.6c2.9-3.9,5.7-7.7,8.6-11.6c2.6-3.4,5.1-6.8,7.6-10.3c0.9-1.2,1.8-2.4,2.6-3.6
            c0.5-0.6,1-1.5,1.3-2.2c0.1-0.2,0.2-0.3,0.2-0.5C238,95.5,236.2,95,235.9,96.1L235.9,96.1z"
        />
        <path
            fill="#42515A"
            d="M176.8,118.7l-0.2,0.2c-0.2,0.2,0.2-0.2,0-0.1l-0.1,0.2c-0.2,0.2-0.3,0.4-0.6,0.6
            c-0.6,0.6-1.2,1.3-1.8,1.9c-1.9,2-4,4-6,6c-2.7,2.7-5.5,5.4-8.3,8.2c-3.3,3.2-6.6,6.5-10,9.7c-3.6,3.5-7.2,6.9-10.8,10.4
            l-10.7,10.3c-3.3,3.2-6.6,6.4-10,9.6c-2.8,2.7-5.7,5.4-8.4,8.2c-2,1.9-4,3.9-6,5.9c-1,0.9-1.9,1.9-2.7,2.9
            c-0.2,0.2-0.3,0.5-0.3,0.8c0,1.1,1.8,1.1,1.8,0c0,0-0.2,0.6-0.1,0.3c0.1-0.2-0.2,0.2,0,0c0.2-0.2,0.2-0.3,0.4-0.4
            c0.5-0.6,1-1.1,1.5-1.5c1.8-1.8,3.6-3.6,5.3-5.3c2.7-2.6,5.3-5.1,7.9-7.7c3.2-3.2,6.5-6.2,9.7-9.4l10.7-10.3
            c3.6-3.5,7.2-6.9,10.8-10.4c3.4-3.3,6.8-6.5,10.2-9.9c2.9-2.8,5.8-5.7,8.7-8.6c2.2-2.2,4.4-4.4,6.5-6.5c1.2-1.2,2.4-2.4,3.5-3.7
            c0.2-0.2,0.3-0.4,0.4-0.6C179,118.7,177.5,117.7,176.8,118.7L176.8,118.7z"
        />
        <path
            fill="#42515A"
            d="M102.2,195.5c4.7,0.8,9.4,1.6,14.1,2.5c6.4,1.1,12.8,2.3,19.3,3.4c7.6,1.4,15.3,2.7,22.9,4.1
            c8.3,1.5,16.7,3,25,4.5l25.7,4.7l24.7,4.4l22.4,4.1c6.1,1.1,12.4,2.3,18.5,3.4l13,2.4c2,0.3,4,0.7,6.1,1.1h0.2
            c1.1,0.2,1.6-1.5,0.5-1.7l-4.2-0.7l-11.5-2.1l-17.2-3.2c-7.2-1.3-14.4-2.7-21.7-4l-24.4-4.4l-25.5-4.6l-25.4-4.5
            c-7.8-1.5-15.8-2.8-23.6-4.3c-6.8-1.2-13.6-2.4-20.3-3.6c-5.2-0.9-10.3-1.9-15.5-2.7l-2.8-0.5C101.5,193.6,101,195.4,102.2,195.5
            L102.2,195.5z"
        />
        <path
            fill="#42515A"
            d="M168.2,127.1l-9.2,10.5c-0.3,0.3-0.3,0.9,0,1.3c0.3,0.3,1,0.3,1.3,0l9.2-10.5c0.3-0.3,0.3-1,0-1.3
            C169.1,126.7,168.5,126.7,168.2,127.1z"
        />
        <path
            fill="#42515A"
            d="M166.1,126.9l-9.3,10.4c-0.7,0.9,0.5,2.1,1.3,1.3l9.3-10.4C168.1,127.3,166.9,126,166.1,126.9L166.1,126.9
            z"
        />
        <path
            fill="#42515A"
            d="M164.5,126.8l-9.5,10.4c-0.8,0.9,0.5,2.1,1.3,1.3l9.4-10.4C166.5,127.2,165.2,126,164.5,126.8L164.5,126.8
            z"
        />
        <path
            fill="#42515A"
            d="M164.4,127.3c-0.2,0.3-0.2,0.8,0.2,1.1c0.2,0.2,0.3,0.2,0.6,0.3c0.3,0.1,0.6,0.1,0.9,0.2
            c0.6,0.1,1.2,0.1,1.8,0.2h-0.2h0.2c0.5,0.2,1-0.2,1.1-0.6c0.2-0.5-0.2-1-0.6-1.1c-0.9-0.2-1.8-0.2-2.7-0.2h0.2
            c-0.2,0-0.2,0-0.3-0.1l0.2,0.1l0,0l0.2,0.2l0,0l0.2,0.2l0,0l0.1,0.2l0,0v0.2l0,0v0.2l0,0l-0.1,0.2l0,0c0.1-0.2,0.2-0.5,0.1-0.6
            c-0.2-0.5-0.6-0.7-1.1-0.6C164.7,127,164.6,127.1,164.4,127.3L164.4,127.3z"
        />
        <path
            fill="#42515A"
            d="M156,138.9l0.8,0.1h0.2c0.2,0-0.2,0,0,0l0.4,0.1c0.6,0.1,1.1,0.2,1.5,0.3c0.5,0.2,1-0.2,1.1-0.6
            c0.2-0.5-0.2-1-0.6-1.1c-1.1-0.2-2.3-0.4-3.5-0.5c-0.2,0-0.5,0.1-0.6,0.2c-0.2,0.2-0.2,0.4-0.2,0.6
            C155.1,138.5,155.5,138.8,156,138.9L156,138.9z"
        />
        <path
            fill="#42515A"
            d="M328.4,134.6c-4.8-0.4-9.8-0.9-14.6-1.3c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9c4.8,0.4,9.8,0.9,14.6,1.3
            c0.5,0,0.9-0.4,0.9-0.9S328.9,134.6,328.4,134.6z"
        />
        <path
            fill="#42515A"
            d="M329.1,132.9c-4.8-0.5-9.8-0.9-14.6-1.4c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9
            c4.8,0.5,9.8,0.9,14.6,1.4c0.5,0,0.9-0.4,0.9-0.9S329.6,132.9,329.1,132.9L329.1,132.9z"
        />
        <path
            fill="#42515A"
            d="M328.9,131.6c-4.9-0.5-9.8-1-14.6-1.5c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9c4.9,0.5,9.8,1,14.6,1.5
            c0.5,0,0.9-0.4,0.9-0.9C329.9,132,329.5,131.6,328.9,131.6z"
        />
        <path
            fill="#42515A"
            d="M327.1,131.7c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2,0,0.4,0,0.6c0.1,0.2,0.2,0.5,0.3,0.6
            c0.2,0.4,0.6,0.9,0.8,1.3c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.3,0.3,0.6,0.4c0.2,0.1,0.5,0,0.6-0.1c0.2-0.1,0.3-0.3,0.4-0.6V135
            c0-0.2,0-0.3-0.1-0.5c-0.2-0.4-0.4-0.7-0.6-1.1c-0.2-0.4-0.5-0.7-0.6-1.1l0.1,0.2c0,0,0-0.1,0-0.2v0.2l0,0v0.2l0,0l-0.1,0.2l0,0
            l-0.2,0.2l0,0l-0.2,0.2l0,0l-0.2,0.1l0,0c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.2,0.2-0.5,0.2-0.7c-0.1-0.2-0.2-0.4-0.4-0.6
            C327.6,131.7,327.4,131.7,327.1,131.7L327.1,131.7z"
        />
        <path
            fill="#42515A"
            d="M312.5,131.7c0,0,0.1,0.1,0.1,0.2L312.5,131.7c0,0.1,0.1,0.2,0.1,0.3L312.5,131.7c0.1,0.2,0.1,0.4,0.1,0.6
            V132c0,0.3-0.2,0.6-0.2,1c0,0.2-0.1,0.5,0,0.6c0,0.3,0.1,0.6,0.2,0.9c0.1,0.2,0.3,0.3,0.6,0.4c0.2,0.1,0.5,0,0.6-0.1
            s0.3-0.3,0.4-0.6c0-0.2,0-0.5-0.1-0.6l0,0l0.1,0.2c0-0.1-0.1-0.2-0.1-0.3v0.2c0-0.2,0-0.3,0-0.5v0.2c0.1-0.4,0.2-0.8,0.2-1.3
            s0-1-0.3-1.5c-0.2-0.4-0.7-0.6-1.2-0.4C312.5,130.6,312.3,131.2,312.5,131.7L312.5,131.7L312.5,131.7z"
        />
        <path
            fill="#42515A"
            d="M220.9,149c2.7,0.5,5.4,0.8,8.1,1.2c4.3,0.6,8.5,1.2,12.8,1.8c4.9,0.6,9.8,1.3,14.6,2
            c4.4,0.6,9,1.1,13.4,1.7c3.2,0.4,6.3,0.8,9.5,1.1l0.7,0.1c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9c-2.9-0.3-5.8-0.6-8.7-1
            c-4.4-0.6-8.6-1.1-13-1.7c-4.8-0.6-9.5-1.3-14.4-1.9c-4.4-0.6-8.7-1.2-13-1.9c-2.9-0.4-5.8-0.8-8.7-1.3l-0.6-0.1
            c-0.5-0.1-1,0.2-1.1,0.6C220.1,148.3,220.4,148.8,220.9,149L220.9,149z"
        />
        <path
            fill="#42515A"
            d="M130.3,165.9l-14.6,15.7c-0.8,0.9,0.5,2.1,1.3,1.3l14.6-15.8C132.4,166.3,131.1,165.1,130.3,165.9
            L130.3,165.9z"
        />
        <path
            fill="#42515A"
            d="M128,165.5L113.2,181c-0.8,0.8,0.5,2.1,1.3,1.3l14.8-15.5C130,165.9,128.8,164.7,128,165.5L128,165.5z"
        />
        <path
            fill="#42515A"
            d="M126.1,165.3l-14.9,15.4c-0.8,0.8,0.5,2.1,1.3,1.3l14.8-15.5C128.2,165.6,126.9,164.3,126.1,165.3z"
        />
        <path
            fill="#42515A"
            d="M125.9,165.7c-0.3,0.5-0.1,1.1,0.4,1.4l0,0c0.2,0.1,0.5,0.2,0.7,0.2c0.6,0.1,1.1,0.2,1.8,0.3
            c0.2,0,0.5,0.1,0.7,0.2c0.5,0.2,1-0.2,1.1-0.6l0,0c0.1-0.5-0.2-1-0.6-1.1c-0.2-0.1-0.5-0.2-0.7-0.2c-0.3-0.1-0.6-0.1-1-0.2
            c-0.3,0-0.6-0.1-0.9-0.2c-0.1,0-0.2-0.1-0.3-0.1c0.3,0.1,0.2,0.1,0.1,0c0.2,0.1,0.3,0.2,0.3,0.5v0.2l0,0v0.2
            c0-0.1-0.2,0.2-0.1,0.2c0.2-0.4,0.1-1-0.3-1.2C126.7,165.2,126.2,165.3,125.9,165.7L125.9,165.7z"
        />
        <path
            fill="#42515A"
            d="M111.9,182.5c1.2,0.2,2.4,0.6,3.6,1c0.5,0.2,1-0.2,1.1-0.6c0.1-0.5-0.2-1-0.6-1.1c-1.2-0.4-2.4-0.7-3.6-1
            c-0.5-0.2-1,0.2-1.1,0.6C111.2,181.7,111.5,182.3,111.9,182.5L111.9,182.5z"
        />
        <path
            fill="#42515A"
            d="M145,151.1h0.2c0.2,0-0.2,0,0,0h0.2l1.4,0.2c1.5,0.2,3.1,0.4,4.6,0.6c4.7,0.6,9.4,1.4,14.1,2l16.9,2.4
            c4.9,0.7,9.9,1.5,14.8,2.2c2.7,0.4,5.4,0.8,8.2,1.1h0.3c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-0.3c-0.1,0-0.3,0-0.1,0h-0.2
            l-1.5-0.2l-4.7-0.6c-4.8-0.7-9.5-1.4-14.3-2.1l-16.9-2.4l-14.6-2.1c-2.7-0.4-5.3-0.8-7.9-1.1h-0.2c-0.5,0-0.9,0.4-0.9,0.9
            C144.1,150.7,144.5,151.1,145,151.1L145,151.1z"
        />
        <path
            fill="#42515A"
            d="M193.6,184.3c-4.8,5.8-9.1,12-14,17.7c-1.5,1.8-3.2,3.6-4.8,5.3c-0.8,0.8,0.5,2.1,1.3,1.3
            c2.7-2.9,5.4-5.9,7.9-9c2.3-2.8,4.4-5.7,6.6-8.6c1.4-1.8,2.7-3.6,4.2-5.3c0.3-0.3,0.3-0.9,0-1.3
            C194.5,184.1,193.9,184.1,193.6,184.3L193.6,184.3z"
        />
        <path
            fill="#42515A"
            d="M159.9,204.7c-3.1,3.8-6.1,7.8-9.1,11.6c-3.3,4.3-6.7,8.6-10.2,12.7c-1.8,2.1-3.6,4.2-5.5,6.2
            c-0.6,0.6,0.3,1.6,1,1c3.6-3.7,6.8-7.7,10-11.6c3.3-4.2,6.6-8.4,9.9-12.7c1.6-2.1,3.2-4.2,4.9-6.2c0.2-0.2,0.2-0.6,0-1
            C160.6,204.4,160.2,204.4,159.9,204.7L159.9,204.7z"
        />
        <path
            fill="#42515A"
            d="M203.1,213.3c-3.1,3.8-6,7.8-8.9,11.6c-3.2,4.3-6.4,8.6-9.7,12.7c-1.7,2.1-3.6,4.2-5.4,6.2
            c-0.6,0.6,0.3,1.6,1,1c3.5-3.6,6.6-7.7,9.7-11.6c3.2-4.2,6.3-8.5,9.5-12.8c1.6-2.1,3.2-4.2,4.8-6.2c0.2-0.2,0.2-0.6,0-1
            C203.8,213,203.3,213,203.1,213.3L203.1,213.3z"
        />
        <path
            fill="#42515A"
            d="M129.3,199.8c-3,3.7-6.1,7.4-9.3,11c-3.5,4-7,7.9-10.5,11.8c-1.9,2-3.7,4-5.7,6c-0.6,0.6,0.3,1.6,1,1
            c3.5-3.6,6.8-7.3,10.2-11c3.5-3.9,6.9-7.8,10.3-11.8c1.7-2,3.3-4,5-6c0.2-0.2,0.2-0.6,0-1C130,199.7,129.6,199.7,129.3,199.8
            L129.3,199.8z"
        />
        <path
            fill="#42515A"
            d="M261.8,223.3c-2.6,4.6-5.4,9-8.3,13.5c-3.1,4.8-6.3,9.5-9.3,14.3c-1.5,2.4-3.1,4.9-4.4,7.4
            c-0.4,0.7,0.7,1.5,1.1,0.6c2.7-4.7,5.6-9.1,8.5-13.6c3.1-4.8,6.3-9.5,9.3-14.3c1.5-2.4,3-4.8,4.4-7.4
            C263.3,223.3,262.2,222.6,261.8,223.3L261.8,223.3z"
        />
        <path
            fill="#42515A"
            d="M241.5,180.5c7.3,0.9,14.4,2.3,21.6,3.2c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9
            c-7.2-0.9-14.4-2.3-21.6-3.2c-0.5,0-0.9,0.4-0.9,0.9C240.6,180,241,180.5,241.5,180.5L241.5,180.5z"
        />
        <path
            fill="#42515A"
            d="M179,154.4c-1.1,1-1.8,2.4-2.5,3.6c-0.8,1.5-1.6,2.9-2.7,4.1c-0.8,0.9,0.5,2.1,1.3,1.3
            c1.2-1.3,2-2.8,2.9-4.4c0.4-0.6,0.7-1.4,1.2-2c0.3-0.6,0.7-1,1.1-1.5c0.3-0.3,0.3-1,0-1.3C179.9,154,179.4,154,179,154.4
            L179,154.4z"
        />
        <path
            fill="#42515A"
            d="M178.1,119.3c0.4-1.1,0.3-2.3,0.3-3.5c0-1,0-1.9,0-3c0-1.6-0.1-3.2-0.2-4.8c-0.2-3-0.3-6.1-0.5-9
            c0-1.1-0.1-2.3-0.1-3.4c0-0.6,0-1.2,0-1.9c0-0.7,0-1.5-0.2-2.2c-0.1-0.2-0.3-0.3-0.6-0.3c-0.2,0-0.2,0.2-0.3,0.3
            c-0.4,1.1-0.2,2.4-0.2,3.6c0,1,0,2,0.1,3.1c0,1.7,0.1,3.3,0.2,5c0.2,3,0.4,6,0.5,9c0,1.1,0.1,2.2,0.1,3.2c0,1.3-0.1,2.7,0.4,3.8
            c0,0.2,0.2,0.2,0.3,0.2C178.1,119.5,178.1,119.5,178.1,119.3L178.1,119.3z"
        />
        <path
            fill="#42515A"
            d="M236.8,125.3c0.6-1,0.5-2.3,0.5-3.4c0-1.1,0.1-2.1,0.1-3.1v-5.3c0-3.3,0-6.6,0-10v-3.4
            c0-1.3,0.1-2.7-0.2-4c-0.2-0.7-1.3-0.7-1.5,0c-0.2,1.2-0.2,2.4-0.2,3.6v8.4c0,3.3,0,6.6,0,10c0,1.1,0,2.3,0.1,3.5
            c0,1.2-0.1,2.7,0.6,3.8c0.1,0.1,0.2,0.2,0.3,0.1C236.7,125.3,236.8,125.3,236.8,125.3L236.8,125.3z"
        />
        <path
            fill="#42515A"
            d="M176.6,92.1c6.1,0.9,12.4,1.2,18.7,1.7c8.6,0.7,17.2,1.5,25.8,2.2c9.5,0.8,19.1,1.7,28.6,2.5
            c8.8,0.8,17.6,1.6,26.3,2.3c3.6,0.3,7.4,0.6,11,1c2.9,0.2,5.8,0.6,8.6,0.5c0.6,0,1.1-0.1,1.7,0c1,0.1,1-1.4,0-1.5
            c-0.1,0-0.2,0-0.3,0l-0.3-0.1l-1-0.2c-0.6-0.1-1.4-0.2-2.1-0.2c-1.5-0.2-2.9-0.3-4.4-0.4c-3.5-0.2-7-0.6-10.5-0.8
            c-8.6-0.6-17.2-1.3-25.9-2c-9.5-0.7-19-1.5-28.4-2.4c-8.8-0.7-17.6-1.5-26.3-2.3c-3.6-0.3-7.4-0.6-11-0.9
            c-2.9-0.2-5.8-0.6-8.6-0.5h-1.7C176.2,91.3,176,92.1,176.6,92.1L176.6,92.1z"
        />
        <path
            fill="#42515A"
            d="M101.9,194.2c0.9-1.1,0.6-2.9,0.6-4.3c0-1.3,0-2.5-0.1-3.8c-0.1-1.9-0.2-3.9-0.3-5.8
            c-0.3-3.6-0.6-7.2-1.1-10.8c-0.2-1.4-0.2-2.7-0.4-4.2c-0.2-1.9-0.4-3.6-0.7-5.4c-0.2-0.8-1.5-0.6-1.5,0.2c0,1.6,0,3.2,0.2,4.8
            c0.1,1.3,0.2,2.6,0.3,3.9c0.2,2,0.4,4,0.6,6c0.3,3.6,0.6,7,0.8,10.6c0.1,1.4,0.1,2.7,0.2,4c0,0.7,0.1,1.5,0.1,2.3
            c0,0.9,0.2,1.8,0.7,2.4C101.7,194.4,101.9,194.4,101.9,194.2L101.9,194.2L101.9,194.2z"
        />
        <path
            fill="#42515A"
            d="M99.1,161.1c0.8-0.6,1.5-1.1,2.3-1.8l0.6-0.5l0.3-0.3c-0.2,0.1,0.1-0.1,0.2-0.1l0.2-0.2l1.4-1.2
            c2.3-1.9,4.6-4,6.9-5.9c2.8-2.4,5.6-4.9,8.4-7.4c3.2-2.7,6.3-5.5,9.4-8.2c3.3-2.9,6.6-5.8,9.9-8.7c3.3-2.9,6.5-5.8,9.8-8.7
            c3.1-2.7,6.1-5.5,9.2-8.2c2.7-2.4,5.4-4.8,8.1-7.3c2.2-2,4.4-4,6.5-5.9c0.8-0.7,1.7-1.5,2.4-2.3c0.6-0.6,1.3-1.1,1.9-1.8
            c0.1-0.1,0.2-0.2,0.3-0.3l0.2-0.2l0.1-0.1c0,0,0.2-0.2,0.1-0.1c-0.2,0.2,0,0,0,0c0.6-0.6-0.3-1.8-1.1-1.1
            c-0.2,0.2,0.4-0.3-0.1,0.1l-0.1,0.1l-0.2,0.2c-0.2,0.1-0.2,0.2-0.4,0.3c-0.3,0.2-0.6,0.6-0.8,0.8c-0.7,0.6-1.5,1.4-2.2,2
            c-2,1.9-4,3.6-6,5.5c-2.6,2.3-5.2,4.7-7.8,7c-3,2.7-6,5.3-9,8.1c-3.2,2.9-6.5,5.7-9.7,8.6c-3.3,2.7-6.6,5.7-9.9,8.6
            c-3.2,2.8-6.4,5.7-9.5,8.4c-2.9,2.6-5.7,5.1-8.6,7.6c-2.4,2.1-4.8,4.3-7.3,6.3c-1.8,1.5-3.5,3.1-5.3,4.7c-0.1,0.1-0.2,0.2-0.3,0.3
            c0.1-0.2-0.1,0.1-0.2,0.1l-0.4,0.4C98,160.5,98.5,161.3,99.1,161.1L99.1,161.1z"
        />
        <path
            fill="#42515A"
            d="M239.2,219.6c0,0,0.1-0.1,0.1-0.2l0.1-0.1c0.1-0.1-0.1,0.1,0,0c0.1-0.1,0.2-0.2,0.3-0.4l-0.4,0.2l0,0
            c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.2-0.3,0.3-0.4l0.2-0.2l0.1-0.1c0.2-0.2-0.3,0.3,0,0c0.3-0.4,0.6-0.8,0.8-1.2
            c0.6-0.9,1.2-1.9,1.9-2.7c1.7-2.5,3.3-5,5-7.4c2.1-3.2,4.3-6.3,6.4-9.5c2.4-3.6,4.9-7.2,7.4-10.8c2.6-3.8,5.3-7.7,7.8-11.5
            s5.2-7.7,7.7-11.5c2.3-3.6,4.8-7.2,7.1-10.7c2-3.1,4-6.2,5.9-9.4c1.5-2.4,2.8-4.8,4.1-7.4c0.8-1.5,1.6-3.1,2-4.8
            c0.1-0.2,0.1-0.5,0.1-0.8c0.1-0.8-1.2-0.8-1.3,0c0,0.2,0-0.2,0,0c0,0.1,0,0.1,0,0.2s-0.1,0.2-0.1,0.3c-0.1,0.3-0.2,0.6-0.3,0.8
            c0,0.1-0.1,0.3,0,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.6c-0.2,0.4-0.4,0.8-0.6,1.2c-1.1,2.2-2.4,4.4-3.7,6.5
            c-1.8,2.9-3.6,5.7-5.4,8.6c-2.2,3.4-4.4,6.8-6.6,10.1c-2.4,3.6-4.8,7.4-7.4,11c-2.5,3.7-5.1,7.5-7.6,11.2
            c-2.5,3.6-4.9,7.3-7.4,10.9c-2.3,3.3-4.4,6.5-6.7,9.9c-1.9,2.7-3.6,5.4-5.5,8.2c-1.3,2-2.6,4-3.7,6c-0.2,0.4-0.6,0.9-0.6,1.3
            l0.1-0.3c-0.1,0.2-0.2,0.3-0.2,0.5l-0.1,0.2c0,0.1-0.1,0.2-0.1,0.2c0,0,0.1-0.1,0,0c-0.1,0.2-0.1,0.4,0,0.6l0,0v-0.4
            c-0.1,0.3-0.2,0.6-0.2,0.9c-0.1,0.2,0,0.4,0.2,0.5C239,219.9,239.1,219.9,239.2,219.6L239.2,219.6z"
        />
        <path
            fill="#42515A"
            d="M207.5,169.3c0.4-0.7,0.6-1.6,0.6-2.4c0.1-0.9,0.2-1.8,0.2-2.7c0.1-2,0.1-4,0.1-5.9
            c0-4.3-0.1-8.5-0.3-12.8c-0.1-2.4-0.3-4.8-0.6-7.3c-0.1-0.6-1.1-0.4-1.1,0.2c-0.2,4.3,0.1,8.6,0.2,12.8c0.1,4,0.1,8.2,0.1,12.2
            c0,1,0,2,0,3s0,1.9,0.4,2.7C207.1,169.4,207.3,169.4,207.5,169.3C207.4,169.4,207.4,169.4,207.5,169.3L207.5,169.3z"
        />
        <path
            fill="#42515A"
            d="M235.7,125.7c0.3,0.2,0.7,0.3,1.2,0.4s1,0.2,1.5,0.2c1,0.1,1.9,0.2,2.8,0.2c1.9,0.2,3.8,0.3,5.7,0.5
            c4,0.3,8.1,0.7,12.1,1.1c8.1,0.8,16.1,1.7,24.2,2.5c2.1,0.2,4.1,0.4,6.2,0.6c2,0.2,4,0.3,6,0.3c0.8,0,1.1-1.4,0.2-1.5
            c-1.8-0.2-3.6-0.4-5.3-0.5c-1.9-0.2-3.6-0.2-5.5-0.4c-4-0.3-7.9-0.6-11.9-1c-8.2-0.7-16.2-1.6-24.3-2.3c-2.2-0.2-4.4-0.3-6.5-0.6
            c-1.1-0.1-2.1-0.2-3.2-0.2c-1,0-2-0.2-3,0.2C235.7,125.2,235.7,125.5,235.7,125.7C235.6,125.7,235.6,125.7,235.7,125.7
            L235.7,125.7z"
        />
        <path
            fill="#42515A"
            d="M234.4,96.3C234.5,96.3,234.5,96.3,234.4,96.3c0.1-0.1,0.1-0.2,0-0.2C234.4,96.1,234.3,96.1,234.4,96.3
            C234.4,96.2,234.4,96.3,234.4,96.3z"
        />
        <path
            fill="#42515A"
            d="M201.4,141.4C201.5,141.4,201.5,141.3,201.4,141.4c0.1-0.1,0.1-0.2,0-0.2
            C201.3,141.3,201.3,141.3,201.4,141.4C201.3,141.3,201.4,141.4,201.4,141.4z"
        />
        <path
            fill="#42515A"
            d="M142.8,121.2c-0.2,0.4-0.2,0.9-0.2,1.3c0,0.5,0,1,0.1,1.5c0,1,0.1,2,0.2,3.1c0.1,2,0.3,4,0.4,5.9
            c0.3,3.7,0.6,7.4,0.8,11.2c0.1,2,0.2,4.1,0.1,6.1c0,0.9,1.3,0.9,1.4,0c0.2-7.6-0.7-15.1-1.5-22.6c-0.1-1.1-0.2-2.2-0.3-3.3
            c0-0.6-0.1-1.2-0.2-1.8c0-0.5,0-1-0.3-1.5c-0.1-0.1-0.2-0.1-0.3,0C142.8,121.1,142.8,121.1,142.8,121.2L142.8,121.2z"
        />
        <path
            fill="#42515A"
            d="M135.6,127.6c-0.2,0.4-0.2,0.9-0.2,1.3c0,0.5,0,1.1,0.1,1.5c0,1,0.1,2,0.2,3c0.1,2,0.2,4,0.4,6.1
            c0.3,4,0.7,8.2,1.2,12.3c0.3,2.3,0.6,4.7,0.9,7c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6c-1.1-8.2-1.9-16.3-2.6-24.6
            c-0.1-1.1-0.2-2.3-0.3-3.4c0-0.6-0.1-1.1-0.1-1.6s0-1.1-0.3-1.6c-0.1-0.1-0.2-0.2-0.3-0.1C135.7,127.5,135.6,127.5,135.6,127.6
            L135.6,127.6z"
        />
        <path
            fill="#42515A"
            d="M100.8,192c0,0.1,0,0.2,0,0.2c0-0.3,0,0.2,0,0.3c0,0.2,0,0.4,0,0.6c0,0.5,0.1,0.9,0.1,1.4
            c0.1,1.1,0.2,2.2,0.3,3.2c0.2,2.7,0.5,5.4,0.8,8.2c0.6,5.8,1.1,11.6,1.7,17.4c0.2,1.3,0.2,2.7,0.5,4c0.2,1,0.3,1.9,0.5,2.8
            c0.1,0.6,0.8,0.3,0.9-0.1c0.2-1.9,0.2-3.9,0.2-5.8c-0.1-2.7-0.3-5.5-0.6-8.2c-0.5-5.8-1.1-11.6-1.7-17.4c-0.2-1.3-0.3-2.7-0.5-4
            c-0.1-1-0.2-1.9-0.5-2.8c-0.2-0.4-0.6-0.6-1-0.5C101.1,191.4,100.8,191.6,100.8,192L100.8,192z"
        />
        <path
            fill="#42515A"
            d="M133.2,201.5c-0.1,2.7,0.1,5.6,0.2,8.3c0.2,4.9,0.5,9.8,0.7,14.7c0.1,1.9,0.2,3.6,0.3,5.5l0.2,3
            c0,1.1,0.1,2.3,0.2,3.3c0.1,0.4,0.5,0.6,0.9,0.6s0.6-0.4,0.6-0.8c0-1,0-1.9-0.1-2.9c0-0.9-0.1-1.9-0.2-2.7
            c-0.1-1.6-0.2-3.3-0.2-4.9c-0.2-2.8-0.3-5.7-0.4-8.5c-0.2-3.9-0.4-7.7-0.6-11.6c0-1.3-0.1-2.7-0.1-4
            C134.8,200.5,133.2,200.5,133.2,201.5L133.2,201.5z"
        />
        <path
            fill="#42515A"
            d="M295,228.9c-0.1,3.3,0,6.6,0.1,9.9c0.1,5.7,0.1,11.3,0,17c0,1.9-0.1,3.9-0.2,5.8c0,1.1-0.1,2-0.1,3.1
            c-0.1,1.1-0.1,2.1-0.1,3.2c0,0.4,0.3,0.8,0.8,0.8c0.3,0,0.6-0.2,0.7-0.6c0.2-1,0.2-1.9,0.2-2.9c0-1,0.1-1.9,0.1-2.7
            c0.1-1.8,0.1-3.6,0.2-5.3c0.1-3.2,0.1-6.3,0.1-9.4c0-4.7-0.1-9.5-0.2-14.2c0-1.5-0.1-3.1-0.1-4.6
            C296.7,227.8,295.1,227.8,295,228.9L295,228.9z"
        />
        <path
            fill="#42515A"
            d="M237.2,219.5c0.2,3.3,0.3,6.5,0.5,9.9c0.2,5.7,0.5,11.4,0.6,17.1c0.1,1.9,0.2,3.9,0.2,5.8
            c0,1.1,0.1,2,0.2,3.1c0,1.1,0.1,2.2,0.2,3.2c0.1,0.4,0.5,0.6,0.9,0.6c0.4-0.1,0.6-0.4,0.6-0.8c0-1,0-1.9-0.1-2.8
            c0-1-0.1-1.9-0.1-2.7c-0.1-1.7-0.2-3.5-0.2-5.3c-0.2-3.2-0.2-6.3-0.3-9.5c-0.2-4.7-0.3-9.4-0.6-14.1c-0.1-1.5-0.2-3.1-0.3-4.6
            c0-0.4-0.3-0.8-0.8-0.8C237.6,218.7,237.2,219.1,237.2,219.5L237.2,219.5z"
        />
        <path
            fill="#42515A"
            d="M105.9,231.4c5.3,1.5,10.8,2.3,16.2,3.3c6,1.1,11.9,2.3,17.9,3.6c12.8,2.6,25.6,5.1,38.5,7.8l39.8,8.1
            c12.9,2.7,25.9,5.3,38.8,7.8c11.6,2.3,23,4.9,34.7,6.7l4,0.6c0.8,0.1,1.2-1.1,0.3-1.3c-11.3-2.5-22.7-4.6-34-6.9
            c-12.8-2.5-25.6-5.1-38.4-7.7c-13.3-2.7-26.7-5.4-40-8.1c-12.9-2.6-25.9-5.3-38.8-7.8c-11.6-2.3-23.1-4.7-34.7-6.8
            c-1.3-0.2-2.6-0.4-3.9-0.6C105.4,230.1,105.3,231.4,105.9,231.4L105.9,231.4z"
        />
        <path
            fill="#42515A"
            d="M295.9,131.6c0.3,0.1,0.7,0.2,1.1,0.3c0.3,0.2,0.7,0.2,1.1,0.3c0.8,0.2,1.5,0.2,2.3,0.3
            c1.6,0.2,3.2,0.3,4.8,0.5c3.5,0.3,6.9,0.7,10.3,1.1c6.9,0.8,13.9,1.8,20.9,2.4c1.9,0.2,3.6,0.3,5.5,0.5c0.9,0.1,1.8,0.2,2.7,0.2
            c0.8,0.1,1.7,0.1,2.5,0.1c0.6-0.1,1-1.1,0.2-1.3c-1.5-0.3-3.2-0.3-4.7-0.5c-1.6-0.2-3.2-0.3-4.8-0.5c-3.4-0.4-6.9-0.8-10.3-1.2
            c-7-0.8-14-1.7-21-2.4c-1.9-0.2-3.6-0.3-5.5-0.5c-0.9-0.1-1.7-0.2-2.6-0.2c-0.4,0-0.9,0-1.3,0.1c-0.4,0.1-0.8,0.2-1.2,0.2
            C295.6,131,295.7,131.4,295.9,131.6L295.9,131.6z"
        />
        <path
            fill="#42515A"
            d="M354.6,110.2c0.2,0,0.2-0.2,0.2-0.3c0-0.2-0.1-0.2-0.2-0.2c-0.2,0-0.2,0.2-0.2,0.3
            C354.4,110,354.4,110.2,354.6,110.2z"
        />
        <path
            fill="#42515A"
            d="M291.5,101.4c0.1,0.1,0.2,0.1,0.2,0.2c-0.2-0.2,0.2,0.2,0.2,0.2c0.2,0.1,0.4,0.1,0.6,0.1
            c0.4,0.1,0.9,0.2,1.3,0.2l2.6,0.2c1.9,0.2,3.6,0.3,5.5,0.5c4,0.3,8,0.8,12,1.2c8.2,0.9,16.4,1.8,24.6,2.5c2.1,0.2,4.1,0.4,6.2,0.6
            c1.9,0.2,3.9,0.3,5.7,0.3c0.7,0,1-1.2,0.2-1.3c-1.7-0.2-3.4-0.3-5.1-0.5c-1.9-0.2-3.6-0.3-5.5-0.5c-4-0.3-8-0.7-12-1.2
            c-8.2-0.9-16.3-1.7-24.6-2.5c-2-0.2-4.1-0.4-6.2-0.6c-1-0.1-2-0.2-3-0.2c-0.4,0-0.9,0-1.4,0c-0.2,0-0.4,0-0.6,0
            c-0.2,0.1-0.5,0.2-0.6,0.3c-0.2,0.1-0.2,0.2-0.1,0.4C291.4,101.4,291.4,101.4,291.5,101.4L291.5,101.4z"
        />
        <path
            fill="#42515A"
            d="M295.6,130.5c0.6-0.6,0.6-1.3,0.8-2c0.2-0.9,0.2-1.7,0.3-2.6c0.1-1.9,0.2-3.7,0.2-5.6
            c0.1-3.8,0.2-7.5,0.3-11.3c0.1-1.9,0.1-3.8,0-5.7c0-0.6-1.1-0.9-1.3-0.2c-0.3,1.7-0.5,3.4-0.6,5.1c-0.1,1.9-0.2,3.7-0.2,5.6
            c-0.1,3.8-0.2,7.6-0.3,11.4c0,1,0,2,0,3C295,128.9,295,129.8,295.6,130.5L295.6,130.5L295.6,130.5z"
        />
        <path
            fill="#42515A"
            d="M316.2,230c0.2-0.2,0.3-0.6,0.4-0.9c0.1-0.4,0.2-0.7,0.2-1.1c0.2-1.1,0.2-2.1,0.3-3.2
            c0.2-2.7,0.2-5.3,0.3-8c0.2-6,0.4-12,0.6-18c0.1-2.4,0.1-4.8,0.1-7.3c0-0.8-1.1-0.8-1.3,0c-0.6,4.9-0.7,9.9-1,14.9
            c-0.1,3.1-0.2,6.2-0.3,9.3c-0.1,2.7-0.2,5.5-0.2,8.2c0,1.2,0,2.4,0.1,3.6c0,0.5,0.2,1,0.2,1.5C315.9,229.3,316.1,229.7,316.2,230
            L316.2,230L316.2,230L316.2,230z"
        />
        <path
            fill="#42515A"
            d="M347.7,171.8c0.1-0.2,0.2-0.4,0.2-0.6c0-0.1,0.1-0.2,0.1-0.3c0,0,0.1-0.3,0,0c0-0.1,0.1-0.2,0.1-0.3
            c0.1-0.6,0.2-1.3,0.2-1.9c0.2-1.8,0.3-3.6,0.5-5.4c0.3-4.8,0.4-9.7,0.6-14.5c0.2-5.6,0.5-11.1,0.6-16.6c0.2-5,0.4-10,0.6-15
            c0.1-3.2,0.2-6.4,0.2-9.6c0-0.2,0-0.4,0-0.6c0-0.8-1.2-0.8-1.3,0l0,0c0-0.2,0-0.2,0,0.1c0,0,0,0,0,0.1v0.2c0,0.1,0,0.2,0,0.3
            c0,0.3,0,0.6-0.1,0.8l-0.2,1.9c-0.2,1.8-0.2,3.6-0.3,5.3c-0.2,4.8-0.4,9.7-0.6,14.5l-0.6,16.6c-0.2,5-0.4,9.9-0.6,14.9
            c0,1.9-0.1,3.8-0.1,5.7c0,1.3,0.1,2.7,0.3,3.9C347.6,171.4,347.6,171.6,347.7,171.8L347.7,171.8
            C347.8,171.8,347.8,171.8,347.7,171.8L347.7,171.8z"
        />
        <path
            fill="#42515A"
            d="M297,229.4c0.2-0.6,0.6-1.1,0.9-1.6c0,0,0.1-0.2,0-0.1c-0.1,0.1,0.1-0.2,0.2-0.2l0.1-0.2
            c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.4,0.5-0.9,0.6-1.3c1.3-2.3,2.6-4.7,3.9-7.1c1.7-3.2,3.4-6.3,5.2-9.5c2-3.7,4.1-7.4,6.2-11.2
            c2.3-4,4.5-8,6.8-12c2.3-4,4.6-8.1,6.9-12.1c2.2-3.9,4.4-7.8,6.6-11.6c2-3.5,4-6.9,5.9-10.3c1.5-2.8,3.2-5.6,4.6-8.4
            c1-1.9,2-3.8,2.9-5.7c0.1-0.2,0.2-0.3,0.2-0.5l0.1-0.2v-0.1c0.2-0.4,0,0,0-0.1c0.3-0.7-0.7-1.4-1.1-0.6c-0.7,1.7-1.7,3.3-2.7,4.9
            c-1.5,2.6-3,5.2-4.4,7.8c-1.9,3.3-3.8,6.6-5.7,9.9c-2.2,3.8-4.4,7.6-6.5,11.4c-2.3,4-4.6,8.1-6.9,12.1c-2.3,4-4.6,8.1-6.9,12.1
            c-2.1,3.8-4.3,7.6-6.3,11.4c-1.9,3.3-3.6,6.6-5.4,10c-1.4,2.7-2.7,5.3-3.9,8c-0.4,1-0.8,2-1.1,3c-0.1,0.2-0.2,0.4-0.2,0.6
            c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1-0.2,0,0c0,0,0,0.1,0,0.2c-0.1,0.3-0.2,0.6-0.2,1C297,228.9,297,229.2,297,229.4
            C296.9,229.4,297,229.4,297,229.4L297,229.4z"
        />
        <path
            fill="#42515A"
            d="M295.2,270.5c0.2-0.6,0.6-1.1,0.9-1.6c0,0,0-0.1,0.1-0.1c0.1-0.2-0.1,0.2,0,0c0.1-0.2,0.1-0.2,0.2-0.3
            s0.2-0.4,0.3-0.6c0.2-0.5,0.5-1,0.7-1.4c1.3-2.5,2.7-5,4-7.4c1.8-3.3,3.6-6.7,5.3-10c2.1-4,4.3-7.8,6.4-11.8
            c2.3-4.3,4.7-8.5,7-12.8c2.3-4.4,4.8-8.6,7.2-13c2.3-4.1,4.6-8.3,6.9-12.4c2-3.7,4.1-7.4,6.1-11.2c1.6-3.1,3.3-6.1,4.8-9.2
            c1.1-2.2,2.1-4.4,3.1-6.5l0.2-0.6l0.2-0.4c0,0,0-0.1,0-0.2c0.1-0.2-0.1,0.2,0,0c0.3-0.7-0.7-1.4-1.1-0.6c-0.8,1.9-1.9,3.8-2.9,5.6
            c-1.5,2.8-3.1,5.7-4.7,8.6c-2,3.6-4,7.2-5.9,10.8c-2.3,4-4.5,8.2-6.7,12.3c-2.3,4.4-4.8,8.6-7.2,13c-2.3,4.4-4.8,8.6-7.1,12.9
            c-2.2,4-4.4,8.1-6.5,12.1c-1.9,3.5-3.7,7-5.5,10.6c-1.4,2.7-2.8,5.6-4,8.5c-0.4,1-0.8,2-1.2,3.1c-0.1,0.2-0.2,0.4-0.2,0.6
            c0,0,0,0.1-0.1,0.2c0,0.1-0.1,0.3,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.3-0.2,0.6-0.2,1C295.3,270.1,295.2,270.4,295.2,270.5
            C295.2,270.5,295.3,270.5,295.2,270.5L295.2,270.5z"
        />
        <path
            fill="#42515A"
            d="M262.9,183.3c6.8,1.3,13.6,2.5,20.4,3.7c7.4,1.3,14.8,2.7,22.2,3.7c3.5,0.5,6.9,1,10.3,1.2
            c1.1,0.1,1.1-1.6,0-1.6c-6.5-0.4-13.1-1.5-19.6-2.4c-7.5-1.2-14.9-2.5-22.4-3.9c-3.6-0.6-7-1.3-10.6-1.8
            C262.5,182.1,262.2,183.1,262.9,183.3L262.9,183.3z"
        />
        <path
            fill="#42515A"
            d="M175.3,207.9L175.3,207.9c-0.2,0-0.4,0.2-0.4,0.4c0,0.2-0.1,0.6-0.2,1c-0.1,0.4,0,0.8,0,1.2c0,1,0,2,0,3
            c0.1,2.6,0.3,5.1,0.5,7.7c0.4,5.5,1,11,1.5,16.5c0.1,1.2,0.2,2.3,0.2,3.6c0,0.5,0,1,0.1,1.4c0,0.3,0.1,0.6,0.2,0.9l0.2,0.4
            c0.2,0.3,0.5,0.3,0.8,0.2c0.1,0,0.2-0.1,0.2-0.2c0.2-0.3,0.3-0.8,0.4-1.2c0-0.4,0-0.8,0-1.2c-0.1-1-0.2-2-0.2-3
            c-0.2-2.6-0.5-5.1-0.7-7.7c-0.6-5.5-1.1-11-1.6-16.4c-0.1-1.1-0.2-2.3-0.2-3.5c0-0.5,0-1-0.1-1.4c0-0.5-0.1-0.8-0.1-1.1
            c0-0.2-0.2-0.3-0.3-0.4C175.5,207.9,175.4,207.9,175.3,207.9L175.3,207.9z"
        />
        <path fill="#42515A" d="M255.9,194l-6.8,11.2c-0.6,1,0.9,1.9,1.5,0.9l6.8-11.2C258,193.9,256.5,193,255.9,194z" />
        <path
            fill="#42515A"
            d="M254.2,193l-6.9,11c-0.6,1,0.9,1.8,1.5,0.9l6.9-11C256.3,192.8,254.9,192,254.2,193L254.2,193z"
        />
        <path
            fill="#42515A"
            d="M252.8,193c-2.3,3.7-4.9,7.4-7.3,11c-0.6,1,0.9,1.8,1.5,0.9c2.3-3.7,4.9-7.4,7.3-11C254.9,192.8,253.4,192,252.8,193L252.8,193z"
        />
        <path
            fill="#42515A"
            d="M253,193.4c-0.1,0.4,0.1,0.9,0.5,1.1c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0.1,0.9,0.2,1.3,0.4l-0.2-0.1
            c0.2,0,0.2,0.1,0.3,0.2l-0.2-0.2l0,0c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6
            c-0.4-0.3-0.9-0.6-1.4-0.6c-0.3-0.1-0.7-0.2-1.1-0.3l0.2,0.1c-0.1,0-0.1,0-0.2-0.1l0.2,0.2l0,0l0.2,0.2l0,0l0.1,0.2l0,0v0.2l0,0
            v0.2l0,0c0.1-0.2,0-0.5-0.1-0.6c-0.2-0.4-0.7-0.6-1.2-0.3C253.2,193.1,253,193.2,253,193.4L253,193.4z"
        />
        <path
            fill="#42515A"
            d="M246.6,204.3c0.6,0.3,1.1,0.7,1.6,1.1l-0.2-0.2c0.3,0.2,0.6,0.6,1,0.8c0.2,0.2,0.4,0.2,0.6,0.2
            c0.5,0,0.9-0.4,0.9-0.9c0-0.2-0.1-0.5-0.2-0.6c-0.9-0.7-1.8-1.5-2.7-2.1c-0.4-0.2-1-0.1-1.2,0.3C246,203.5,246.2,204,246.6,204.3z
            "
        />
        <path
            fill="#FFC000"
            d="M411.9,169.8c1,0.2,2,0.3,3,0.4l2.9,0.1c1.9,0.1,3.9,0,5.8,0c3.8,0,7.7,0.1,11.5,0.1
            c7.7,0.1,15.3,0.2,22.9,0.2c2.2,0,4.4,0,6.5,0h3.3c1.1,0,2.3-0.2,3.4-0.2c0.6,0,1.3-0.6,1.3-1.2c0-0.6-0.6-1.3-1.2-1.3l0,0
            c-2-0.1-4-0.1-6-0.1c-2,0-4,0-5.9,0c-3.8,0-7.7,0-11.5,0c-7.7,0-15.3-0.1-22.9,0c-2.2,0-4.3,0-6.5,0.1c-1.1,0-2.3,0.1-3.3,0.2
            c-1.1,0.1-2.3,0.3-3.3,0.6C411.3,168.8,411.3,169.8,411.9,169.8L411.9,169.8z"
        />
        <path
            fill="#FFC000"
            d="M410.8,169.3c0.1-0.2,0.1-0.4,0.2-0.6c0-0.1,0.1-0.2,0.1-0.3c0,0,0.1-0.2,0-0.1c0,0.1,0,0,0-0.1
        c0.1-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.4,0.1-0.6c0-0.2,0,0,0,0V167c0-0.2,0.1-0.4,0.1-0.6c0-0.5,0.1-1,0.1-1.4c0-0.1,0-0.2,0-0.2
        c0,0,0,0.2,0,0.1s0-0.2,0-0.3c0-0.1,0-0.2,0-0.3s0.2-0.8,0.1-0.4c0.1-0.4,0.2-0.9,0.2-1.3c0.1-0.2,0.1-0.4,0.2-0.6
        c0-0.1,0.1-0.2,0.1-0.3s-0.1,0.2-0.1,0.2c0-0.1,0-0.2,0.1-0.2c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1,0.1-0.2,0.2-0.2c0.2-0.3-0.1,0.1,0,0
        c0.1-0.2,0.2-0.2,0.3-0.3c0.2-0.2,0.1,0,0,0l0.2-0.2c0,0,0.2-0.1,0.2-0.2c0.1-0.1-0.2,0.1-0.2,0.1c0.1,0,0.2,0,0.2-0.1
        c0.1,0,0.6-0.2,0.6-0.2c0.1,0.1-0.4,0-0.1,0h0.2c0.2,0,0.3,0,0.4,0h0.3c0.1,0,0.3,0,0.1,0s0.2,0,0.2,0c1,0.2,1.9-0.4,1.9-1.5
        c0-1.4,0-2.8,0.1-4.2c0-0.6,0.1-1.3,0.1-1.9c0-0.2,0.1-0.5,0.1-0.7c0,0.5,0,0.1,0,0c0-0.2,0-0.3,0.1-0.4c0.1-0.5,0.2-1,0.4-1.5
        c0-0.1,0.1-0.2,0.1-0.3s0-0.1,0.1-0.2c0.1-0.2-0.1,0.2-0.1,0.2c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.3-0.4,0.3-0.6
        c0,0-0.3,0.3-0.1,0.2l0.1-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.2,0.3-0.3,0.5-0.4c0.2-0.2-0.2,0.1-0.2,0.1c0.1,0,0.1-0.1,0.2-0.1
        l0.3-0.2c0.2-0.2,0.5-0.3,0.8-0.4c0.4-0.2-0.3,0.1,0.1,0c0.2-0.1,0.3-0.1,0.5-0.2c0.7-0.2,1.5-0.4,2.2-0.5c1.1-0.2,1.5-1.6,1-2.4
        c-0.1-0.2-0.2-0.3-0.3-0.6c-0.2-0.3,0.2,0.3,0,0l-0.1-0.3c-0.2-0.3-0.2-0.7-0.3-1.1c0-0.2-0.1-0.3-0.1-0.6c0-0.1,0-0.2,0-0.2
        c0.1,0.5,0,0.2,0,0c0-0.4-0.1-0.8-0.1-1.2c0-0.4,0-0.8,0.1-1.2v-0.2c0-0.3-0.1,0.4,0,0c0-0.2,0.1-0.5,0.1-0.6
        c0.2-0.7,0.3-1.5,0.6-2.1c0-0.1,0.2-0.4,0,0.1c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.2-0.3,0.2-0.4c0.2-0.3,0.3-0.6,0.6-1
        c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.2,0.2-0.1-0.1,0.1c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.4-0.5,0.6-0.6l0.2-0.2
        c0.2-0.2-0.3,0.2-0.1,0.1c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2-0.2,0.3-0.2c0.2-0.2-0.4,0.2-0.2,0.1l0.2-0.1c0.1,0,0.2,0,0.3-0.1
        c0.3-0.1-0.5,0-0.2,0h0.2c0.1,0,0.2,0,0.3,0c0.3,0-0.5-0.1-0.2,0c0.1,0,0.2,0,0.2,0.1l0.2,0.1c0.2,0.1-0.4-0.2-0.1,0l0.3,0.2
        c0.2,0.1,0.1,0.2-0.1-0.1c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0.1,0.2,0.2,0.3,0.3l0.2,0.2c0.2,0.2-0.2-0.3,0,0c0.1,0.2,0.2,0.3,0.3,0.6
        c0.3,0.5,0.6,1,0.7,1.5c0.3,0.6,0.9,0.9,1.5,0.9c0.6,0,1.2-0.3,1.5-0.9c0.2-0.3-0.2,0.3,0,0c0.1-0.1,0.2-0.2,0.2-0.2
        c0.1-0.1,0.2-0.2,0.3-0.3s0.2-0.2,0.4-0.3l0.1-0.1c-0.2,0.2-0.3,0.2-0.2,0.2l0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.2s0.1,0,0.2-0.1
        c-0.3,0.2-0.3,0.2-0.2,0.1l0.3-0.1c0.2-0.1,0.2,0.1-0.2,0c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.2,0c0.2,0-0.5-0.1-0.2,0
        c0.1,0,0.2,0,0.2,0.1c0.4,0.1-0.3-0.2,0,0l0.3,0.2c0.3,0.2-0.3-0.3,0,0c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.2,0.2
        s0.2,0.3-0.1-0.1c0,0.1,0.1,0.2,0.2,0.2c0.3,0.5,0.6,1,0.9,1.6c0,0.1,0.2,0.4,0-0.1c0,0.1,0.1,0.2,0.1,0.2
        c0.1,0.2,0.1,0.3,0.2,0.4c0.2,0.3,0.2,0.7,0.3,1.1c0.2,0.7,0.3,1.4,0.5,2.1c0.1,0.3,0.2,0.7,0.2,1.1c0,0.1,0,0.2,0,0.3
        c-0.1-0.5,0-0.2,0,0c0,0.2,0,0.4,0.1,0.6c0.1,0.8,0.1,1.6,0.1,2.4c0,0.4,0,0.8,0,1.2c0,0.2,0,0.3,0,0.5c0,0.2-0.1,0.3,0-0.1
        c0,0.1,0,0.2,0,0.3c-0.2,1.1,0.4,2.1,1.6,2.1c0.2,0,0.4,0,0.6,0c0.1,0,0.4,0,0.1,0s0,0,0.1,0c0.3,0.1,0.7,0.2,1.1,0.3
        c0.2,0.1,0.2,0.2,0,0c0.1,0,0.2,0.1,0.2,0.2c0.2,0.1,0.3,0.2,0.4,0.2l0.2,0.2c0.3,0.2-0.3-0.2,0,0c0.2,0.1,0.3,0.2,0.4,0.3
        s0.3,0.4,0.3,0.4c-0.1,0-0.2-0.3,0,0l0.2,0.2c0.1,0.2,0.2,0.3,0.3,0.5v0.1c0.1,0.2,0.1,0.2,0-0.1c0,0.1,0.1,0.2,0.1,0.2
        c0.2,0.4,0.2,0.9,0.3,1.3c0,0.3,0.1,0.1,0,0c0,0.2,0,0.2,0,0.4c0,0.3,0,0.6,0,0.9c0,1,0.7,1.7,1.7,1.7c0.7,0,1.4-0.5,1.6-1.2
        c0.2-0.8,0.3-1.5,0.6-2.3c0.2-0.4,0.3-0.9,0.4-1.3c0-0.1,0.3-0.6,0.2-0.6c0,0.1-0.2,0.3,0,0.1l0.1-0.2c0-0.1,0.1-0.2,0.2-0.3
        c0.2-0.4,0.4-0.8,0.7-1.2l0.1-0.1c0.1-0.2-0.1,0.2-0.1,0.2c0.1-0.1,0.2-0.2,0.2-0.2c0.2-0.2,0.3-0.3,0.5-0.5l0.1-0.1
        c0,0-0.3,0.2-0.2,0.1s0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.2-0.2l0.1-0.1c0.2-0.1-0.3,0.1-0.1,0c0.1,0,0.2-0.1,0.2-0.1
        s0.4-0.1,0.2,0c-0.3,0,0,0,0.1,0s0.2,0,0.3,0h0.2c0.2,0-0.4-0.1-0.2,0h0.1c0.2,0,0.3,0.1,0.6,0.2s-0.3-0.2-0.1,0
        c0.1,0,0.2,0.1,0.2,0.1l0.2,0.1c0.1,0,0.3,0.2,0.1,0.1c-0.2-0.2,0,0,0.1,0.1l0.2,0.2c0.1,0.1,0.2,0.3,0.3,0.4
        c-0.2-0.1-0.2-0.3,0,0c0,0.1,0.1,0.2,0.1,0.2c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.2,0.2,0.3,0.2,0.5c0,0-0.2-0.4-0.1-0.2v0.1
        c0,0.1,0.1,0.2,0.1,0.2c0.1,0.3,0.2,0.6,0.3,1.1c0.1,0.3,0.2,0.6,0.2,1.1c0,0.1,0,0.2,0,0.2c0.1,0.3,0-0.3,0-0.1
        c0,0.2,0,0.3,0.1,0.5c0.1,0.8,0.1,1.7,0.1,2.5c0,0.6,0.3,1.1,0.8,1.4c0.5,0.3,1.1,0.3,1.6,0c-0.7,0.3-0.3,0.2-0.2,0.1
        c0.2-0.1,0.3-0.2,0.6-0.2c0.3-0.1,0.6-0.2,0.8-0.2h0.2c-0.6,0.1-0.3,0-0.2,0s0.3,0,0.6,0s0.3,0,0.5,0c0.1,0,0.2,0,0.2,0
        c0,0-0.5-0.1-0.2,0h0.2c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.2,0.1,0.2,0.1c0.2,0.1-0.4-0.2-0.2-0.1l0.2,0.1c0.2,0.1,0.3,0.2,0.5,0.3
        c0.2,0.2-0.3-0.3-0.2-0.1l0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2l0.2,0.2c0.2,0.2-0.2-0.3-0.1-0.2c0,0,0.1,0.1,0.1,0.2
        c0.2,0.2,0.2,0.5,0.4,0.7c0.2,0.2-0.1-0.2-0.1-0.2l0.1,0.3c0.1,0.2,0.1,0.3,0.2,0.5c0,0.3,0.2,0.7,0.2,1.1c-0.1,0,0-0.3,0,0
        c0,0.1,0,0.2,0,0.3c0,0.2,0,0.5,0,0.7c0,0.6,0,1.1,0,1.6c0,0.9,1,1.8,1.9,1.5c0.1,0,0.2-0.1,0.3-0.1c0.3-0.2,0.2,0.2-0.6,0
        l-0.9-0.6l0,0l-0.2-1.1c0.1-0.3,0.3-0.6,0.6-0.7c0,0-0.1,0-0.2,0.1c-0.1,0.1-0.2,0.1,0,0c0.2-0.1,0.2-0.1,0.1,0
        c-0.2,0.1,0,0.1,0.6,0l0.6,0.3l0,0l0.3,0.6v0.7l-0.1,0.3c0-0.1,0,0-0.1,0.1c-0.2,0.2,0,0,0,0s0.2-0.2,0.2-0.2
        c0.1-0.1,0.2-0.2,0.2-0.2l0.1-0.1c0.2-0.2-0.2,0.2,0,0c0.3-0.2,0.7-0.5,1.1-0.6s-0.3,0.1,0,0l0.2-0.1c0.2-0.1,0.4-0.2,0.6-0.2
        l0.3-0.1c0.2,0-0.2,0-0.2,0c0.1,0,0.2,0,0.2,0c0.2,0,0.4,0,0.6,0h0.2c0.2,0-0.3-0.1-0.1,0h0.1c0.2,0,0.4,0.1,0.6,0.2
        c0,0,0.1,0,0.2,0c0.2,0-0.3-0.2-0.1,0l0.2,0.1c0.1,0,0.2,0.1,0.2,0.2l0.1,0.1c0.2,0.1-0.2-0.2,0,0c0.2,0.2,0.3,0.3,0.4,0.4
        c0.2,0.2-0.2-0.2,0,0c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.2,0.1,0.2,0.2,0.3c0.2,0.2-0.1-0.2,0,0.1c0.1,0.2,0.2,0.6,0.2,0.8v0.2
        c0,0,0.1,0.3,0,0.2c0-0.2,0,0.1,0,0.1v0.3c0,0.4,0,0.8,0,1.1c0,0.2,0,0.4,0,0.6c0,0.1,0,0.2,0,0.3c0,0-0.1,0.4,0,0.2
        c-0.1,0.5-0.2,1-0.3,1.5c-0.2,0.6,0.2,1.3,0.8,1.5c0.6,0.2,1.3-0.2,1.5-0.8l0,0c0.9-3.2,1.3-8-2.3-9.8c-1.3-0.6-2.7-0.7-4.1-0.4
        c-0.6,0.2-1.3,0.4-1.9,0.7c-0.3,0.2-0.6,0.3-0.9,0.6c-0.1,0.1-0.2,0.2-0.2,0.2c0,0-0.1,0-0.1,0.1l0.1-0.1l-0.2,0.2
        c-0.1,0.1-0.2,0.2-0.3,0.3c-0.6,0.6-1,1.6-0.2,2.3c0.6,0.6,1.4,0.5,2,0.2c0.7-0.3,1.1-1.1,1-1.8c-0.3-1.1-1.4-1.2-2.3-0.9l1.9,1.5
        c0.3-3-0.2-6.6-2.9-8.3c-1.6-1.1-3.6-1.1-5.4-0.6c-0.5,0.1-1,0.3-1.4,0.5l2.4,1.4c0.2-2.5-0.2-5-1.3-7.4c-0.8-1.7-2.3-3-4.1-3.3
        c-1.2-0.2-2.5,0-3.6,0.6c-1,0.6-1.8,1.4-2.3,2.4c-1.2,1.9-1.9,4.2-2.3,6.3l3.3,0.4c0-2.3-0.6-4.7-2.3-6.3
        c-1.5-1.5-3.4-2.3-5.5-2.2l1.6,2.1c0.6-3.3,0.2-6.9-0.7-10.1c-0.7-2.7-2.3-6.5-5.7-6.6c-2-0.1-3.6,1.5-4.7,3h3
        c-0.7-1.7-1.9-3.5-3.6-4.3c-1.5-0.7-3.3-0.6-4.7,0.3c-3,2-4.3,5.8-4.4,9.2c-0.2,2.2,0.3,4.4,1.4,6.3l1-2.4
        c-2.7,0.4-5.2,1.5-6.7,3.7c-1.4,2-1.6,4.6-1.6,6.9c0,1.7,0,3.3,0,4.9l1.9-1.5c-1.3-0.2-2.6-0.1-3.7,0.3c-1,0.4-1.7,1.1-2.2,2
        c-1.1,1.8-1.3,4-1.1,6.1c0,0.6,0.1,1.3,0.2,1.9c0,0.3,0.1,0.6,0.2,0.8c0.1,0.3,0.2,0.6,0.3,0.8c0.1,0.3,0.4,0.5,0.7,0.3
        c0.2-0.1,0.3-0.2,0.3-0.3L410.8,169.3z"
        />
        <path
            fill="#FFC000"
            d="M433.7,152.6c0.5-0.7,1.1-1.4,1.8-2c0.3-0.2,0.6-0.5,1.1-0.6c0.2-0.1,0.3-0.2,0.6-0.3l0.2-0.1
            c0.1,0,0.2,0,0.2-0.1c0.4-0.2,0.7-0.2,1.1-0.3c0.2,0,0.4-0.1,0.6-0.1c0,0,0.4,0,0.1,0h0.3c0.8-0.1,1.6-0.1,2.4,0
            c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4c-4,0-8.8,1.7-9.4,6.1C432.6,152.8,433.5,152.8,433.7,152.6L433.7,152.6z"
        />
    </svg>
);

export default OccupancyInsightIllustration;
