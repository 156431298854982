import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { sensorGraphColors, sensorUnits, statusColors, colors } from 'commons/src/constants';

type Props = {
    thresholds: number[];
    devicesInEachLevel: { good: number; fair: number; poor: number };
    unit: keyof typeof sensorUnits | undefined;
};

const ThresholdLevelsView = ({ thresholds, unit, devicesInEachLevel }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const thresholdColors = (colorIndex: number, sensorType: string): string => {
        if (colorIndex < 0) return statusColors.red;
        const indexedSensor: keyof typeof sensorGraphColors =
            `${colorIndex}${sensorType}` as keyof typeof sensorGraphColors;
        const colorCode = sensorGraphColors[indexedSensor];
        if (colorCode === colors.graphGreen) return statusColors.green;
        if (colorCode === colors.graphYellow) return statusColors.yellow;
        return statusColors.red;
    };
    const headerValues = ['ThresholdKeys.NormalLevel', 'ThresholdKeys.ActionLevel', 'ThresholdKeys.WarningLevel'];

    const thresholdValues = (): React.ReactElement | React.ReactElement[] =>
        thresholds.length > 0 ? (
            [thresholds[0], ...thresholds].map((threshold, index) => {
                const color = thresholdColors(index, 'radon');
                let devicesOnLevel = devicesInEachLevel.good;
                if (color === statusColors.red) devicesOnLevel = devicesInEachLevel.poor;
                else if (color === statusColors.yellow) devicesOnLevel = devicesInEachLevel.fair;
                const elementKey = `threshold-value-${color}-${devicesOnLevel}-${index}`;
                return (
                    <div
                        key={elementKey}
                        className={classNames('insight-tile__content__text', 'insight-tile__content__thresholds__row', {
                            'insight-tile__content__thresholds__row--no-border': index === thresholds.length,
                        })}
                    >
                        <div>
                            <div
                                className={classNames('sensor__line', {
                                    'sensor__line--ok': color === statusColors.green,
                                    'sensor__line--alert': color === statusColors.red,
                                    'sensor__line--warning': color === statusColors.yellow,
                                })}
                            />
                            <span className="insight-tile__content__text--large">{txt(headerValues[index])} </span>
                            <div className="insight-tile__content__thresholds__margin-row">
                                {index === 0 ? '<' : '≥'}
                                {threshold} {unit && sensorUnits[unit]}
                                {index !== 0 &&
                                    index !== thresholds.length && [
                                        '  ',
                                        '< ',
                                        thresholds[index],
                                        ' ',
                                        unit && sensorUnits[unit],
                                    ]}
                            </div>
                        </div>
                        <div className="text-large">
                            {devicesOnLevel} {devicesOnLevel === 1 ? txt('DeviceLowerCase') : txt('DevicesLowerCase')}
                        </div>
                    </div>
                );
            })
        ) : (
            <div />
        );
    return (
        <div className="insight-tile__content__thresholds">
            <h4>{txt('RadonInsight.DevicesWithinEachThreshold')}</h4>
            {thresholdValues()}
        </div>
    );
};

export default ThresholdLevelsView;
