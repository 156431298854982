import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DeviceInfo from 'commons/src/components/device/DeviceInfo';
import LabeledText from 'commons/src/components/LabeledText';
import { sensorUnits } from 'commons/src/constants';
import { DayUsageHours, SegmentProperties, DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { RadonInsightSensorData } from '../../../models/common';
import { Store } from '../../../reducers';
import InsightGraph from '../InsightGraph';
import InsightTile from '../InsightTile';

type ParentProps = {
    unit: keyof typeof sensorUnits;
    thresholds: number[];
    usageHours: { [day: string]: DayUsageHours };
    result: RadonInsightSensorData;
};

type StateProps = {
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    segmentProperties: { [serialNumber: string]: SegmentProperties };
};

type Props = ParentProps & StateProps;

export const DeviceResultComponent = ({
    thresholds,
    devicesWithKeyInfo,
    result,
    usageHours,
    unit,
    segmentProperties,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const device = result.serialNumber && devicesWithKeyInfo[result.serialNumber];
    const segmentProps: SegmentProperties = segmentProperties[result.serialNumber];

    if (!device) {
        return <div />;
    }

    return (
        <div className="form__wide-container insight-tile insight-tile--full-width">
            <div className="insight-tile__header">
                <h3 className="insight-tile__header__name">{device.segmentName}</h3>
                <DeviceInfo serialNumber={result.serialNumber} deviceType={device.type} />
                <div className="flex">
                    <div className="form__field--slim insight__slim-labeled-text">
                        <LabeledText
                            label="Floor"
                            id={`deviceFloor${result.serialNumber}`}
                            invalid={false}
                            value={segmentProps && segmentProps.floor !== undefined ? `${segmentProps.floor}` : ''}
                        />
                    </div>
                    <div className="form__field--slim insight__slim-labeled-text">
                        <LabeledText
                            label="RoomType"
                            id={`roomType${result.serialNumber}`}
                            invalid={false}
                            value={
                                segmentProps && segmentProps.roomType
                                    ? txt(`RoomTypeOptions.${segmentProps.roomType}`)
                                    : ''
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="insight-tile__measurement-wrapper">
                <InsightTile
                    header="RadonInsight.LongTermMeasurement"
                    thresholds={thresholds}
                    value={result.radonStep1}
                    unit={unit}
                    std={result.radonStep1StdDev}
                    lld={result.radonStep1Lld}
                    description="RadonInsight.LongTermMeasurementResultText"
                />
                <InsightTile
                    header="RadonInsight.UsageHourMeasurement"
                    thresholds={thresholds}
                    value={result.radonStep2}
                    unit={unit}
                    std={result.radonStep2StdDev}
                    lld={result.radonStep2Lld}
                    description="RadonInsight.WorkingHoursMeasurementResultText"
                />
            </div>
            <div id="insightGraph" className="insight-tile__content insight-tile__content--graph">
                <InsightGraph
                    loading={false}
                    unit={unit}
                    serialNumber={result.serialNumber}
                    thresholds={thresholds}
                    sensorType="radon"
                    chartData={result.radonData}
                    usageHours={usageHours}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { devicesWithKeyInfo },
        segmentPropertiesStore: { segmentProperties },
    } = state;
    return {
        devicesWithKeyInfo,
        segmentProperties,
    };
};

export default connect(mapStateToProps)(DeviceResultComponent);
