import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { pageNotFoundUrl } from 'commons/src/components/errorComponents/PageNotFound';
import PageHeader from 'commons/src/components/headers/PageHeader';
import { GroupType } from 'commons/src/models/commonEnums';
import { Group } from 'commons/src/models/commonTypeScript';
import { GetOrganizationLogo, getOrganizationLogo } from '../../actions/organizationPropertiesActions';
import {
    fetchPartnerOfflineDevices,
    FetchPartnerDetails,
    fetchPartnerDetails,
    FetchPartnerOfflineDevices,
} from '../../actions/partnerActions';
import { Store } from '../../reducers';
import { BusinessRequestType as RequestType } from '../../reducers/BusinessRequestType';
import PartnerMenuConfig from './PartnerMenuConfig';

type StateProps = {
    loading: boolean;
    getOfflineLoading: boolean;
    logoImage?: string;
    selectedGroup?: Group;
    partnerUserGroupId?: string;
};
type ActionProps = {
    getPartnerInfo: () => void;
    getLogo: () => void;
    getPartnerOfflineDevices: () => void;
};
export type Props = StateProps & ActionProps;

const PartnerPageComponent = ({
    loading,
    getOfflineLoading,
    getPartnerInfo,
    getLogo,
    logoImage,
    selectedGroup,
    partnerUserGroupId,
    getPartnerOfflineDevices,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();

    const isPartnerOrg = selectedGroup && selectedGroup.groupType === GroupType.partner;

    useEffect((): void => {
        getPartnerInfo();

        if (!logoImage) {
            getLogo();
        }
        if (!getOfflineLoading) {
            getPartnerOfflineDevices();
        }
    }, []);

    useEffect((): void => {
        if (!!selectedGroup && !loading && !isPartnerOrg) {
            navigate(pageNotFoundUrl('notAPartnerOrg'));
        }
    }, [isPartnerOrg, loading, partnerUserGroupId]);

    return (
        <>
            <PageHeader title={txt('Partner.Customers')} tabs={PartnerMenuConfig} />
            <Outlet />
        </>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { loading: userSettingsLoading, selectedGroup },
        organizationProperties: { logoImage },
        requests: {
            [RequestType.GetOrganizationLogo]: { loading: getLogoLoading },
            [RequestType.FetchPartnerDetails]: { loading },
            [RequestType.FetchPartnerOfflineDevices]: { loading: getOfflineLoading },
        },
        partner: { customers },
    } = store;

    return {
        loading: (loading || userSettingsLoading || getLogoLoading) && customers.length === 0,
        getOfflineLoading,
        logoImage,
        selectedGroup,
        partnerUserGroupId: selectedGroup && selectedGroup.id,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    getPartnerInfo: (): FetchPartnerDetails => dispatch(fetchPartnerDetails()),
    getLogo: (): GetOrganizationLogo => dispatch(getOrganizationLogo()),
    getPartnerOfflineDevices: (): FetchPartnerOfflineDevices => dispatch(fetchPartnerOfflineDevices()),
});
export default connect(mapStateToProps, mapDispatchToProps)(PartnerPageComponent);
