import { EnergyEvents, NetTypes, SensorTypes } from 'commons/src/models/commonEnums';

export type BuildingInsightTimeData = {
    devices: {
        serialNumber: string;
        name: string;
        overThresholdsTime: number;
        underThresholdsTime: number;
    }[];
};

export type BuildingInsightChartData = {
    date: string;
    overThresholdsTime: number;
    underThresholdsTime: number;
};

export type BuildingInsight = {
    sensor: SensorTypes;
    thresholds: { value: number; type: BreachType }[];
    unit: string;
    id: string;
    totalDeviceData: {
        devicesOverTime: { [serialNumber: string]: BuildingInsightChartData[] };
        devicesInTimeFrame: {
            serialNumber: string;
            name: string;
            overThresholdsTime: number;
            underThresholdsTime: number;
        }[];
    };
    trendData: {
        aggregatedDevices: BuildingInsightChartData[]; // new chartData
        devicesInTimeFrame: { [date: string]: BuildingInsightTimeData }; // new timeData
    };
};
export enum BreachType {
    over = 'over',
    under = 'under',
}
export type TimesOutsideThresholds = {
    from: string;
    to: string;
    maxMinValue: number;
    unit: string;
    breachType: BreachType;
};

export type DeviceWithLastSeen = {
    serialNumber: string;
    segmentName: string;
    lastSeen?: string;
    batteryPercentage?: number;
};

export type PoorSignalSensorDevice = {
    serialNumber: string;
    segmentName: string;
    lastSeen?: string;
    rssi?: number;
};
export type PoorSignalHub = {
    serialNumber: string;
    segmentName: string;
    lastSeen?: string;
    rssi?: number;
    rsrq?: number;
    sinr?: number;
    cell?: boolean;
    net?: NetTypes;
};

export type OrganizationHealthStatus = {
    locations: BuildingSummaryHealthStatus[];
};

export type BuildingSummaryHealthStatus = {
    locationId: string;
    locationName: string;
    offlineHubs: number;
    hubs: number;
    offlineSensorDevices: number;
    sensorDevices: number;
};

export type BuildingFocusedSensorData = {
    locationId: string;
    trendData: { date: string; overThresholdsTime: number; underThresholdsTime: number }[];
    deviceWithGreatestBreach: {
        serialNumber: string;
        totalMinutes: number;
        name: string;
    };
    numberOfDevicesWithBreach: number;
};

export type HeatingCoolingData = {
    timestamp: string;
    events: {
        eventType: EnergyEvents;
        devices: { serialNumber: string; deviceName: string }[];
    }[];
}[];

export type PresenceByDevice = { serialNumber: string; minutesUsed: number };

export type PresenceOverTime = {
    date: string;
    minutesUsed: number;
    devices: PresenceByDevice[];
};

export type PresenceWeekAggregation = {
    locationId: string;
    roomsUsed: number;
    totalRoomsInBuilding: number;
    peakWeekDay: string;
    peakWeekHour: number;
    usage: {
        weekHour: number;
        roomsInUse: number;
    }[];
};

export type PresenceRequest = {
    locationId: string;
    fromDate: string;
    toDate: string;
    openingHours: boolean;
    filter: { floor: string[]; roomType: string[] };
};

export type OrganizationHealthStatusSummary = {
    totalBuildings: number;
    totalHubs: number;
    totalDevices: number;
    offlineHubs: number;
    offlineDevices: number;
    lowBatteryDevices: number;
};

export type SearchLocation = {
    serialNumber: string;
    segmentName: string;
    locationId: string;
    locationName: string;
    type: string;
    spaceId?: string;
};

export type HealthStatusSummaryDevice = {
    type?: string;
    locationId: string;
    serialNumber: string;
    locationName: string;
    segmentName: string;
    batteryPercentage?: number;
    lastSeen?: string;
    rssi?: number;
    rsrq?: number;
    cell?: boolean;
    net?: NetTypes;
    deviceType?: string;
};

export enum CardIssue {
    Battery = 'battery',
    Offline = 'offline',
}

export type HealthStatusIssueDataSummary = {
    devices: HealthStatusSummaryDevice[];
    totalDevices: number;
};

export type HealthStatusIssueTableRow = {
    serialNumber: string;
    buildings: string;
    devices: string;
    name?: string;
    lastSeen?: string;
    connection?: string;
    battery?: string;
    isLast?: boolean;
};
