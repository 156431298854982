import { ErrorType } from '../models/commonTypeScript';

export enum InitializationActionType {
    DisplayAlertBoxSagaAction = 'DISPLAY_ALERT_BOX_SAGA_ACTION/INIT',
}

export const INITIALIZE = 'INITIALIZE';
export interface Initialize {
    type: typeof INITIALIZE;
}
export const initialize = (): Initialize => ({ type: INITIALIZE });

export const INITIALIZE_SUCCESS = 'INITIALIZE_SUCCESS';
export interface InitializeSuccess {
    type: typeof INITIALIZE_SUCCESS;
}
export const initializeSuccess = (): InitializeSuccess => ({ type: INITIALIZE_SUCCESS });

export const APP_ERROR = 'APP_ERROR';
export interface AppError {
    type: typeof APP_ERROR;
    error: ErrorType;
}
export const appError = (error: ErrorType): AppError => ({ type: APP_ERROR, error });

export const HIDE_COOKIE_BANNER = 'HIDE_COOKIE_BANNER';
export interface HideCookieBanner {
    type: typeof HIDE_COOKIE_BANNER;
}
export const hideCookieBanner = (): HideCookieBanner => ({ type: HIDE_COOKIE_BANNER });

// Alert box
export const SHOW_ALERT_BOX = 'SHOW_ALERT_BOX';
export interface ShowAlertBox {
    type: typeof SHOW_ALERT_BOX;
    message: string;
    error: boolean;
    fade: boolean;
}
export const showAlertBox = (message: string, error: boolean, fade: boolean): ShowAlertBox => ({
    type: SHOW_ALERT_BOX,
    message,
    error,
    fade,
});

export interface DisplayAlertBoxSagaAction {
    type: InitializationActionType.DisplayAlertBoxSagaAction;
    message: string;
    error: boolean;
    fade: boolean;
}

export const displayAlertBoxSagaAction = (
    message: string,
    error: boolean,
    fade: boolean
): DisplayAlertBoxSagaAction => ({
    type: InitializationActionType.DisplayAlertBoxSagaAction,
    message,
    error,
    fade,
});

export const HIDE_ALERT_BOX = 'HIDE_ALERT_BOX';
export interface HideAlertBox {
    type: typeof HIDE_ALERT_BOX;
}
export const hideAlertBox = (): HideAlertBox => ({ type: HIDE_ALERT_BOX });

export type IndexReducerActions = InitializeSuccess | AppError | HideCookieBanner | ShowAlertBox | HideAlertBox;
