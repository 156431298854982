import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './TertiaryButton.module.scss';

type Props = {
    title?: string;
    icon?: React.ReactElement;
    trailingIcon?: React.ReactElement;
    size?: 'small' | 'medium';
    onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
    id?: string;
    disabled?: boolean;
    testAttr?: string;
    noColor?: boolean;
    testId?: string;
    alert?: boolean;
};

const TertiaryButton = ({
    title,
    onClick,
    id,
    disabled,
    testAttr,
    icon,
    noColor,
    testId,
    size,
    trailingIcon,
    alert,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <button
            type="button"
            id={id}
            className={classNames(styles.button, {
                [styles.disabled]: disabled,
                [styles.noColor]: noColor,
                [styles.medium]: size === 'medium',
                [styles.alert]: alert,
            })}
            onClick={onClick}
            {...{ [`data-${testAttr || 'button'}`]: true }}
            {...{ 'data-testid': testId }}
        >
            {icon}
            {title && txt(title)}
            {trailingIcon}
        </button>
    );
};
export default TertiaryButton;
