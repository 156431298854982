import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import PageWrapper from 'commons/src/components/containers/PageWrapper';
import { paths } from '../../constants';
import EmptyBuildingIllustration from '../../img/empty-building-illustration';
import styles from './NoBuildings.module.scss';

const NoBuildings = (): ReactElement => {
    const { t: txt } = useTranslation();
    const navigate: NavigateFunction = useNavigate();

    const redirectToAddBuilding = (): void => {
        navigate({ pathname: `/${paths.addBuilding}` });
    };

    return (
        <PageWrapper pageType="wide">
            <div className={styles.body}>
                <EmptyBuildingIllustration />
                <div className={styles.content}>
                    <div className={styles.textContent}>
                        <div className={styles.title}>{txt('Building.NoBuildingsTitle')}</div>
                        <div className={styles.subTitle}>{txt('Building.NoBuildingsSubTitle')}</div>
                    </div>
                    <div>
                        <PrimaryButton
                            testId="add-building-button"
                            onClick={redirectToAddBuilding}
                            color="primary"
                            title="Building.AddBuilding"
                        />
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};

export default NoBuildings;
