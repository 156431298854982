import React, { useEffect, useState } from 'react';
import { Document, Font, PDFDownloadLink, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { analyticsLogger, PageType, ReportType } from 'commons/src/analytics';
import { INSIGHT_DOWNLOADED_REPORT } from 'commons/src/analytics/AnalyticsEvents';
import DownloadPDFView from 'commons/src/components/PDF/DownloadPdfView';
import {
    HeaderBlock,
    sensorIconSource,
    FlexWrapperSpaced,
    Logo,
    Body,
    BoldText,
} from 'commons/src/components/PDF/PdfConstants';
import { colors, dateFormats } from 'commons/src/constants';
import DemiBold from 'commons/src/fonts/open-sans-v28-latin-600.ttf';
import RegularFont from 'commons/src/fonts/open-sans-v28-latin-regular.ttf';
import { BuildingType } from 'commons/src/models/commonTypeScript';
import BusinessLogo from '../../../../img/logos/airthings_business_logo_horizontal_dark.png';
import { PDFContext } from '../../../../models/common';
import BlueWave from './BlueWave.png';

Font.register({ family: 'DemiBold', src: DemiBold });
Font.register({ family: 'Regular', src: RegularFont });

const styles = StyleSheet.create({
    contentWrapper: {
        padding: '12vw 4vw 0 4vw',
    },
    footerWave: {
        width: '100vw',
    },
    footer: {
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0,
        alignItems: 'baseline',
    },
    logoContainer: {
        width: '38vw',
    },
    headerUrl: {
        fontSize: 12,
        marginLeft: '7vw',
    },
    rightAlignedInfo: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    rightAlignedText: {
        width: '35vw',
        display: 'flex',
        textAlign: 'right',
        fontSize: 10,
    },
    bodyText: {
        fontSize: 13,
        margin: '2vw 0',
    },
    deviceIcon: {
        width: 20,
        height: 20,
        marginRight: 3,
    },
    sensorTickWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    sensorTick: {
        backgroundColor: colors.blueAura,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0 10 10 0',
        fontSize: 12,
        color: colors.white,
        borderRadius: 20,
        padding: '2 10',
    },
    headerText: {
        fontSize: 30,
        fontFamily: 'DemiBold',
    },
    bold: {
        fontFamily: 'DemiBold',
    },
    addressText: {
        fontSize: 20,
    },
    pageNumbers: {
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    businessColor: {
        color: colors.blueAura,
    },
});

export type GenerateProps = {
    dateFormat: keyof typeof dateFormats;
    building: BuildingType | undefined;
    sensors: string[];
};

type PdfProperties = {
    building: BuildingType;
    txt: PDFContext;
    sensors: string[];
    todayDate: string;
    inAYearDate: string;
};

// Create Document Component
const PdfDocument = React.memo(
    ({ building, sensors, txt, todayDate, inAYearDate }: PdfProperties): React.ReactElement => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Document>
            <Body>
                <FlexWrapperSpaced>
                    <View>
                        <View style={styles.logoContainer}>
                            <Logo src={BusinessLogo} />
                        </View>
                        <Text style={styles.headerUrl}>airthings.com/business</Text>
                    </View>
                    <View style={{ marginTop: '5' }}>
                        <Text>
                            {txt('InsightCertificate.Issued')}
                            {': '}
                            {todayDate}
                        </Text>
                        <Text>
                            {txt('InsightCertificate.Expires')}
                            {': '}
                            {inAYearDate}
                        </Text>
                    </View>
                </FlexWrapperSpaced>
                <View style={styles.rightAlignedInfo}>
                    <Text style={styles.rightAlignedText}>{txt('InsightCertificate.DevicesCertified')}</Text>
                </View>
                <View style={styles.contentWrapper}>
                    <HeaderBlock>
                        <View>
                            <Text style={[styles.headerText, styles.businessColor]}>
                                {txt('InsightCertificate.IAQCertificate')}
                            </Text>
                            <Text style={styles.headerText}>{building.name}</Text>
                            <Text style={styles.addressText}>{building.address}</Text>
                        </View>
                    </HeaderBlock>
                    <Text style={styles.bodyText}>{txt('InsightCertificate.CertificateBody')}</Text>
                    <Text style={styles.bodyText}>{txt('InsightCertificate.DevicesMonitoringParameters')}</Text>
                    <View style={styles.sensorTickWrapper}>
                        {sensors.map(sensor => (
                            <View style={styles.sensorTick} key={sensor}>
                                <Image style={styles.deviceIcon} src={sensorIconSource(sensor, true) || ''} />
                                <BoldText>{txt(sensor).replace('CO₂', 'CO2')}</BoldText>
                            </View>
                        ))}
                    </View>
                </View>
                <View style={styles.footer} fixed>
                    <Image style={styles.footerWave} src={BlueWave} />
                </View>
            </Body>
        </Document>
    )
);

const logDownloadReport = (): void => {
    analyticsLogger(INSIGHT_DOWNLOADED_REPORT, { pageType: PageType.Insight, reportType: ReportType.IAQCertificate });
};

export const GeneratePdfComponent = (props: GenerateProps): React.ReactElement => {
    const { dateFormat, building, sensors } = props;
    const { t: txt } = useTranslation();
    let pdfProps: PdfProperties | undefined;
    const todayDate = moment().format(dateFormats[dateFormat].shortFormat);
    const inAYearDate = moment().add(1, 'years').format(dateFormats[dateFormat].shortFormat);

    if (building) {
        pdfProps = {
            building,
            txt,
            sensors,
            todayDate,
            inAYearDate,
        };
    }
    const [properties, setProperties] = useState<PdfProperties | undefined>(undefined);

    useEffect((): void => {
        if (!properties) {
            setProperties(pdfProps);
        }
    }, [pdfProps]);

    if (!properties) {
        return <div />;
    }

    const fileName = `IAQCertificate_${properties.building.name}_${todayDate}.pdf`;
    return (
        <div
            className="form__button-container"
            onClick={logDownloadReport}
            onKeyDown={logDownloadReport}
            role="button"
            tabIndex={0}
        >
            <PDFDownloadLink document={<PdfDocument {...properties} />} fileName={fileName}>
                {({ loading }): React.ReactElement => <DownloadPDFView loading={loading} fileName={fileName} />}
            </PDFDownloadLink>
        </div>
    );

    // use this to get inline version of pdf.
    /* return (
        <div className="form form__wide-container">
            <PDFViewer>
                <PdfDocument {...properties} />
            </PDFViewer>
        </div>
    ); */
};

export default GeneratePdfComponent;
