import React, { DragEvent, useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import 'react-placeholder/lib/reactPlaceholder.css';
import 'react-grid-layout/css/styles.css';
import {
    FetchSensorSegment,
    fetchSensorSegment,
    PollDashboardSensorData,
    pollDashboardSensorData,
    stopPollDashboardSensorData,
    UpdateDashboardLayout,
    updateDashboardLayout,
} from '../../actions/DashboardActions';
import { analyticsLogger, PageType } from '../../analytics';
import { DASHBOARD_DELETED_TILE, DASHBOARD_EDITED_SIZE } from '../../analytics/AnalyticsEvents';
import NoContent from '../../components/emptyStates/NoContent';
import { userIsHbs } from '../../components/findUserType';
import { DashboardVisibility } from '../../models/commonEnums';
import {
    AnyDeviceType,
    DashboardProps,
    DashboardTile,
    DeviceTileContent,
    IndoorVsOutdoorTileContent,
    LocationTileContent,
    WeatherTileContent,
    SelectedPeriod,
    SensorData,
    SensorTileContent,
    SensorValueTileContent,
} from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import DeviceTile from '../devices/DeviceTile/DeviceTile';
import LocationTile from '../locations/LocationTile';
import DashboardSensorTile from './DashboardSensorTile';
import IndoorVsOutdoorTile from './IndoorVsOutdoorTile';
import OutdoorAirQualityTile from './OutdoorAirQualityTile';
import SensorValueTile from './SensorValueTile';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

type LayoutType = { i: string; x: number; y: number }[];

type StateProps = {
    dashboardTiles: DashboardTile[];
    hasDevices: boolean;
    dateFormat: string;
    hasLocations: boolean;
    dashboardProps?: DashboardProps;
    dashboardSensorData: {
        sensorData: SensorData;
        loading: boolean;
        minValues: { [tileId: string]: { [interval: string]: number } };
        averageValues: { [tileId: string]: { [interval: string]: number | undefined } };
    };
};

type ActionProps = {
    updateDashboardData: (config: { tiles: DashboardTile[]; dashboardId: string }) => void;
    fetchSegment: (payload: {
        serialNumber: string;
        selectedInterval: SelectedPeriod;
        sensorType: string;
        tileId: string;
    }) => void;
    pollSensorData: (payload: {
        serialNumber: string;
        selectedInterval: SelectedPeriod;
        sensorType: string;
        ref: string;
        tileId: string;
    }) => void;
};
type Props = StateProps & ActionProps;

export const DashboardTilesViewComponent = ({
    dashboardTiles,
    dateFormat,
    hasLocations,
    fetchSegment,
    dashboardSensorData,
    pollSensorData,
    dashboardProps,
    hasDevices,
    updateDashboardData,
}: Props): React.ReactElement => {
    const [isDraggable, setIsDraggable] = useState<boolean>(window.innerWidth > 801);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [layoutCols] = useState<{ [key: string]: number }>({ lg: 3, md: 2, sm: 2, xs: 1 });
    const [elementWasDragged, setElementWasDragged] = useState<boolean>(false);

    const dispatch = useDispatch();

    const onBreakpointChange = (newBreakPoint: string): void => {
        setIsDraggable(newBreakPoint !== 'xs');
    };

    useEffect(() => {
        return (): void => {
            dispatch(stopPollDashboardSensorData());
        };
    }, []);

    const onDragStop = (): void => {
        if (elementWasDragged) {
            setIsDragging(true);
            setElementWasDragged(false);
        } else {
            setIsDragging(false);
        }
    };

    const updateLayout = (tiles: DashboardTile[]): void => {
        const dashboardId = dashboardProps && dashboardProps.id;
        if (dashboardId) {
            updateDashboardData({ tiles, dashboardId });
        }
    };

    const dashboardConfigFromLayout = (layoutTiles: LayoutType, tiles: DashboardTile[]): DashboardTile[] =>
        layoutTiles
            .sort((tile1, tile2) => tile1.y - tile2.y || tile1.x - tile2.x)
            .map(layoutTile => ({ ...tiles.find(tile => tile.id === layoutTile.i) })) as DashboardTile[];

    const onLayoutChange = (layout: LayoutType): void => {
        const updatedDashboardTiles = dashboardConfigFromLayout(layout, dashboardTiles);
        const layoutIsUpdated = !!dashboardTiles.find(
            (tile, i) => tile && updatedDashboardTiles[i] && tile.ref !== updatedDashboardTiles[i].ref
        );
        if (layoutIsUpdated) {
            updateLayout(updatedDashboardTiles);
        }
    };

    const singleLayoutFromDashboardConfig = (
        cols: number,
        tiles: DashboardTile[]
    ): { x: number; y: number; w: number; h: number; i: string }[] =>
        tiles.reduce((layout, tile) => {
            const prev = layout[layout.length - 1];
            const isNewLine = !prev || prev.x + prev.w + tile.width > cols;
            layout.push({
                x: !prev || isNewLine ? 0 : prev.x + prev.w,
                y: !prev ? 0 : prev.y + (isNewLine ? 1 : 0),
                w: Math.min(tile.width, cols),
                h: 1,
                i: tile.id,
            });
            return layout;
        }, [] as { x: number; y: number; w: number; h: number; i: string }[]);

    const layoutsFromDashboardConfig = (tiles: DashboardTile[]): { [layoutName: string]: LayoutType } => {
        return Object.keys(layoutCols).reduce(
            (obj, layoutName) => ({
                ...obj,
                [layoutName]: singleLayoutFromDashboardConfig(layoutCols[layoutName], tiles),
            }),
            {}
        );
    };

    const dragLink = (e: DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
        setElementWasDragged(true);
    };

    const updateTileWidth = (width: number, id: string, ref: string): void => {
        const key = dashboardTiles.findIndex(tile => tile.id === id);
        const tileOnKey = dashboardTiles[key].ref === ref;
        if (tileOnKey) {
            const updatedTiles = [...dashboardTiles];
            updatedTiles[key] = { ...updatedTiles[key], width };
            updateLayout(updatedTiles);
            analyticsLogger(DASHBOARD_EDITED_SIZE, { pageType: PageType.Dashboard, width });
        }
    };

    const removeDashboardTile = (id: string, ref: string): void => {
        const key = dashboardTiles.findIndex(tile => tile.id === id);
        const removeTileOnKey = dashboardTiles[key].ref === ref;
        if (removeTileOnKey) {
            const updatedTiles = [...dashboardTiles];
            updatedTiles.splice(key, 1);
            updateLayout(updatedTiles);
            analyticsLogger(DASHBOARD_DELETED_TILE, { pageType: PageType.Dashboard });
        }
    };

    const visibility = (dashboardProps && dashboardProps.visibility) || DashboardVisibility.user;
    const noContentText = (): string => {
        if (hasDevices) return 'AddContentDashboard';
        if (userIsHbs()) return 'Business.AddDevicesToAddDashboardTiles';
        return 'Consumer.AddDevicesToAccount';
    };

    const tiles =
        dashboardTiles &&
        dashboardTiles
            .map(({ content, type, width, ref, id }) => {
                switch (type) {
                    case 'location': {
                        const locationTileContent = content as LocationTileContent;
                        return (
                            <div key={id} className="user-select" onDragStart={dragLink}>
                                <LocationTile
                                    visibility={visibility}
                                    location={locationTileContent}
                                    menuProps={{ tileRef: ref, tileId: id, removeTile: removeDashboardTile }}
                                    isDragging={isDragging}
                                />
                            </div>
                        );
                    }
                    case 'device':
                        return (
                            <div key={id} className="user-select" onDragStart={dragLink}>
                                <DeviceTile
                                    visibility={visibility}
                                    device={{
                                        ...(content as DeviceTileContent),
                                        type: (content as DeviceTileContent).deviceType as AnyDeviceType,
                                    }}
                                    showLocation
                                    menuProps={{ tileRef: ref, tileId: id, removeTile: removeDashboardTile }}
                                    isDragging={isDragging}
                                />
                            </div>
                        );
                    case 'sensorValue':
                        return (
                            <div key={id} className="user-select" onDragStart={dragLink}>
                                <SensorValueTile
                                    visibility={visibility}
                                    tile={content as SensorValueTileContent}
                                    menuProps={{ tileRef: ref, tileId: id, removeTile: removeDashboardTile }}
                                    isDragging={isDragging}
                                />
                            </div>
                        );
                    case 'sensor': {
                        const sensorTileData = content as SensorTileContent;
                        const serialNumberAndType = `${sensorTileData.serialNumber}-${sensorTileData.sensorType}`;

                        return (
                            <div key={id} className="user-select" onDragStart={dragLink}>
                                <DashboardSensorTile
                                    visibility={visibility}
                                    {...(sensorTileData as SensorTileContent)}
                                    width={width}
                                    dateFormat={dateFormat}
                                    removeTile={removeDashboardTile}
                                    updateTileWidth={updateTileWidth}
                                    tileId={id}
                                    singleWidthDashboard={!isDraggable}
                                    tileRef={ref}
                                    isDragging={isDragging}
                                    fetchSegment={fetchSegment}
                                    chartData={dashboardSensorData.sensorData[serialNumberAndType]}
                                    minValues={dashboardSensorData.minValues[serialNumberAndType]}
                                    averageValues={dashboardSensorData.averageValues[serialNumberAndType]}
                                    pollSensorData={pollSensorData}
                                />
                            </div>
                        );
                    }
                    case 'outdoor':
                        return (
                            <div key={id} className="user-select" onDragStart={dragLink}>
                                <OutdoorAirQualityTile
                                    visibility={visibility}
                                    {...(content as WeatherTileContent)}
                                    menuProps={{ tileRef: ref, tileId: id, removeTile: removeDashboardTile }}
                                    isDragging={isDragging}
                                    tileRef={ref}
                                />
                            </div>
                        );
                    case 'in_vs_out':
                        return (
                            <div key={id} className="user-select" onDragStart={dragLink}>
                                <IndoorVsOutdoorTile
                                    visibility={visibility}
                                    comparisonData={content as IndoorVsOutdoorTileContent}
                                    menuProps={{ tileRef: ref, tileId: id, removeTile: removeDashboardTile }}
                                    isDragging={isDragging}
                                />
                            </div>
                        );
                    default:
                        return null;
                }
            })
            .filter(tile => tile !== null);

    if (tiles && tiles.length > 0) {
        return (
            <ResponsiveReactGridLayout
                className="layout"
                breakpoints={{
                    lg: 1193,
                    md: 992,
                    sm: 795,
                    xs: 370,
                }}
                cols={layoutCols}
                rowHeight={265}
                layouts={layoutsFromDashboardConfig(dashboardTiles)}
                onLayoutChange={onLayoutChange}
                onBreakpointChange={onBreakpointChange}
                margin={[0, 28]}
                isResizable={false}
                isDraggable={isDraggable}
                useCSSTransforms={false}
                onDragStop={onDragStop}
            >
                {tiles}
            </ResponsiveReactGridLayout>
        );
    }
    return (
        <NoContent
            linkText={hasDevices ? 'AddNewTile' : ''}
            link={hasDevices ? '/add-tile' : ''}
            noContentText={noContentText()}
            hasLocations={hasLocations}
        />
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
        dashboardData: { dashboardTiles, dashboardProps },
        locations: { locations },
        dashboardSensorData,
    } = state;
    const hasDevices = locations.some(location => location.devices.length > 0);
    return {
        dashboardTiles,
        dateFormat,
        hasLocations: locations.length > 0,
        hasDevices,
        dashboardSensorData,
        dashboardProps,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    updateDashboardData: (config): UpdateDashboardLayout => dispatch(updateDashboardLayout(config)),
    fetchSegment: (payload): FetchSensorSegment => dispatch(fetchSensorSegment(payload)),
    pollSensorData: (payload): PollDashboardSensorData => dispatch(pollDashboardSensorData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTilesViewComponent);
