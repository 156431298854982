import React from 'react';
import { connect } from 'react-redux';
import SimpleTreeView from 'commons/src/components/lists/TreeView/SimpleTreeView';
import spinner from 'commons/src/img/spinner';
import { Member } from '../../../models/common';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';

type StateProps = {
    loadingMembers: boolean;
};

type ParentProps = {
    onUpdateEmails: (payload: { id: string; changeAll: boolean }) => void;
    members: Member[];
    emails: string[];
};

export type Props = ParentProps & StateProps;

export const EmailSelectorComponent = ({
    members,
    loadingMembers,
    onUpdateEmails,
    emails,
}: Props): React.ReactElement => {
    const treeViewEmailOptions = members
        .map(user => ({
            id: user.email || '',
            name: user.name,
        }))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    return (
        <div className="notification-alert__indented-row--padded">
            {loadingMembers ? (
                <div>{spinner}</div>
            ) : (
                <SimpleTreeView
                    listId="integration-email-trigger"
                    options={treeViewEmailOptions}
                    selectedOptions={emails}
                    onChange={onUpdateEmails}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        requests: {
            [RequestType.GetOrganizationMembers]: { loading: loadingMembers },
        },
    } = state;

    return {
        loadingMembers,
    };
};

export default connect(mapStateToProps)(EmailSelectorComponent);
