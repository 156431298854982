import React, { useEffect, useState } from 'react';
import { Document, View, PDFDownloadLink, Font, Text, Page, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import DownloadPDFView from 'commons/src/components/PDF/DownloadPdfView';
import Medium from 'commons/src/fonts/open-sans-v28-latin-500.ttf';
import DemiBold from 'commons/src/fonts/open-sans-v28-latin-600.ttf';
import Bold from 'commons/src/fonts/open-sans-v28-latin-700.ttf';
import RegularFont from 'commons/src/fonts/open-sans-v28-latin-regular.ttf';
import { PDFContext } from '../../../../models/common';
import { QRCard } from './GenerateQrLabel';

Font.register({ family: 'Bold', src: Bold });
Font.register({ family: 'DemiBold', src: DemiBold });
Font.register({ family: 'Medium', src: Medium });
Font.register({ family: 'Regular', src: RegularFont });

const styles = StyleSheet.create({
    nameHeader: {
        paddingTop: 15,
    },
    locationWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    body: {
        paddingTop: '6vw',
        paddingBottom: 90,
        paddingRight: '5vw',
        paddingLeft: '5vw',
        fontFamily: 'Regular',
    },
});

export type QrLabelProps = {
    locationName: string;
    devices: {
        serialNumber: string;
        organizationName: string;
        qrImage: string;
        logoImage?: string;
        url: string;
        deviceName: string;
    }[];
};

type Props = {
    qrLabels: QrLabelProps[];
};

interface PdfProps {
    txt: PDFContext;
    qrLabels: QrLabelProps[];
}

export const Body = ({ children }: { children: React.ReactElement | React.ReactElement[] }): React.ReactElement => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Page size="A4" style={styles.body}>
        {children}
    </Page>
);

const PdfDocument = ({ qrLabels, txt }: PdfProps): React.ReactElement => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Document>
        <Body>
            {qrLabels.map((location, index) => {
                const locationKey = `location-with-devices-${location.locationName}${index}`;
                return (
                    <View key={locationKey}>
                        <Text style={styles.nameHeader}>{location.locationName}</Text>
                        <View style={styles.locationWrapper}>
                            {location.devices.map(device => (
                                <QRCard {...device} txt={txt} key={device.serialNumber} />
                            ))}
                        </View>
                    </View>
                );
            })}
        </Body>
    </Document>
);

const PrintAllQrLabels = ({ qrLabels }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const [fontsRendered, setFontsRendered] = useState(false);

    useEffect(() => {
        setFontsRendered(true);
    }, []);

    const fileName = 'AirthingsPublicIAQLabels.pdf';
    return (
        <div>
            {fontsRendered && (
                <div className="form__button-container">
                    <PDFDownloadLink document={<PdfDocument qrLabels={qrLabels} txt={txt} />} fileName={fileName}>
                        {({ loading }): React.ReactElement => <DownloadPDFView loading={loading} fileName={fileName} />}
                    </PDFDownloadLink>
                </div>
            )}
        </div>
    );

    /* // use this to get inline version of pdf.
   return (
       <div className="form form__wide-container">
           <PDFViewer>
               <PdfDocument qrLabels={qrLabels} txt={txt} />
           </PDFViewer>
       </div>
   ); */
};

export default PrintAllQrLabels;
