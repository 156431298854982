/* eslint max-len: ["error", 1000] */
import React from 'react';

const ForecastOvercast = (): React.ReactElement => (
    <svg
        version="1.1"
        className="weather-icon"
        width="30px"
        height="30px"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
    >
        <path d="M29.1,16.8H1c-0.5,0-1,0.4-1,1s0.4,1,1,1h28c0.5,0,1-0.4,1-1S29.6,16.8,29.1,16.8z" />
        <path d="M27.2,19.8H2.8c-0.5,0-1,0.4-1,1s0.4,1,1,1h24.4c0.5,0,1-0.4,1-1S27.7,19.8,27.2,19.8z" />
        <path d="M24.7,15.8c0.5,0,1-0.4,1-1s-0.4-1-1-1H4.2c-0.5,0-1,0.4-1,1s0.4,1,1,1H24.7z" />
        <path
            d="M30,10.4c0-2.9-2.4-5.3-5.3-5.3H24c-0.4-1.3-1.1-2.5-2.2-3.4c-1.2-1-2.9-1.7-4.6-1.7c-2.8,0-5.2,1.6-6.4,3.9
            C10.4,3.8,10,3.8,9.6,3.8c-3.3,0-6.2,2.3-6.7,5.5C1.2,10.2,0,11.9,0,13.9c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1c0-1.4,0.9-2.6,2.2-3.1
            l0.6-0.2l0-0.6c0.3-2.5,2.4-4.4,5-4.4c2.4,0,4.5,1.8,5,4.3l0.1,0.8h2c1.1,0,2.5,0.5,2.7,1.4c0,0.6,0.5,1,1,1s1-0.5,1-1
            c0,0,0,0.1,0,0c0-0.8-1.3-3.3-4.8-3.3h-0.7C15.7,7.6,15,6.4,14,5.5c-0.4-0.3-0.8-0.6-1.3-0.8C13.7,3,15.3,2,17.2,2
            c2.5,0,4.7,1.9,5.1,4.4l0.2,0.8h2.2c1.9,0,3.3,1.5,3.3,3.3c0,0.9-0.4,1.7-0.8,2.2s-0.5,1.2,0,1.6c0.3,0.2,0.8,0.4,1.3,0
            C29.2,13.6,30,12.3,30,10.4z"
        />
    </svg>
);
export default ForecastOvercast;
