import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { analyticsLogger } from 'commons/src/analytics';
import {
    BUILDING_PRESENCE_INSIGHT_EXPAND_GRAPH,
    BUILDING_PRESENCE_INSIGHT_SELECT_FROM_CHART,
} from 'commons/src/analytics/AnalyticsEvents';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { businessPaths as paths, dateFormats } from 'commons/src/constants';
import useWindowSize from 'commons/src/hooks/useWindowSize';
import { TimePeriod } from 'commons/src/models/commonEnums';
import { DayUsageHours, DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { PresenceViewType } from '../../../models/commonEnums';
import { Store } from '../../../reducers';
import humanizedTimeString from '../../buildings/buildingInsight/buildingInsightCommonFunctions';
import { minutesOpenForDay } from './PresenceInsightCommons';
import PresenceSelectedDevice from './PresenceSelectedDevice';
import usageOverTimeDayConfig from './UsageOverTimeDayConfig';
import styles from './UsageOverTimeDayView.module.scss';

type ParentProps = {
    presenceOverTime: {
        date: string;
        minutesUsed: number;
        devices: { serialNumber: string; minutesUsed: number }[];
    }[];
    selectedDate: string;
    dateFormat: keyof typeof dateFormats;
    detailsExpanded: boolean;
    setDetailsExpanded: (expanded: boolean) => void;
    usageHours: {
        [day: string]: DayUsageHours;
    };
    timeZone?: string;
    withOpeningHours: boolean;
};
type StateProps = {
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
};

type Props = StateProps & ParentProps;

const UsageOverTimeDayView = ({
    selectedDate,
    presenceOverTime,
    dateFormat,
    devicesWithKeyInfo,
    detailsExpanded,
    setDetailsExpanded,
    usageHours,
    withOpeningHours,
    timeZone,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [selectedDevice, setSelectedDevice] = useState('');
    const [redraw, setRedraw] = useState(false);
    const { width } = useWindowSize();

    const hoursInUseForDay =
        withOpeningHours && selectedDate && usageHours ? minutesOpenForDay(selectedDate, usageHours) : 24 * 60;

    const selectedPoint =
        selectedDate &&
        `${moment(selectedDate, 'YYYY-MM-DD').format('dddd')} ${moment(selectedDate, 'YYYY-MM-DD').format(
            dateFormats[dateFormat].calendarFormat
        )}`;

    const usageOnSelectedDay = selectedDate && presenceOverTime.find(day => day.date === selectedDate);

    const expandDetails = (expanded: boolean): void => {
        if (detailsExpanded !== expanded) {
            setDetailsExpanded(expanded);
            analyticsLogger(BUILDING_PRESENCE_INSIGHT_EXPAND_GRAPH, {
                payload: { detailsExpanded: expanded, chart: 'usageOverTime' },
            });
        }
    };

    const waitAndRedrawChart = (): void => {
        setRedraw(true);
        setTimeout(() => {
            setRedraw(false);
        }, 10);
    };

    useEffect(() => {
        if (width > 1150) {
            waitAndRedrawChart();
        }
    }, [detailsExpanded]);

    const onDetailsClicked = (clickData: Highcharts.PointClickEventObject): void => {
        const selectedSerialNumber = clickData.point.category;
        if (selectedDevice === selectedSerialNumber) {
            setSelectedDevice('');
            expandDetails(false);
        } else {
            analyticsLogger(BUILDING_PRESENCE_INSIGHT_SELECT_FROM_CHART, { chart: 'usageOverTime' });
            setSelectedDevice(selectedSerialNumber as string);
        }
    };
    const devicesUsedSelectedDay = usageOnSelectedDay
        ? usageOnSelectedDay.devices.sort((device1, device2) => {
              if (device1.minutesUsed > device2.minutesUsed) return -1;
              return 1;
          })
        : [];

    const detailsData = devicesUsedSelectedDay.map(device => device.minutesUsed);
    const unusedTimeData = devicesUsedSelectedDay.map(device => hoursInUseForDay - device.minutesUsed);
    const categories = devicesUsedSelectedDay ? devicesUsedSelectedDay.map(device => device.serialNumber) : [];
    const selectedDevicesWithKeyInfo = devicesUsedSelectedDay.map(device => devicesWithKeyInfo[device.serialNumber]);

    const config = usageOverTimeDayConfig({
        chartData: detailsData,
        unusedTimeData,
        categories,
        selectedDevicesWithKeyInfo,
        onClick: onDetailsClicked,
        chartHeight: 210,
        txt,
        maxDevicesBeforeScroll: 3,
        tickInterval: 3 * 60,
        maxYAxis: withOpeningHours ? hoursInUseForDay : 24 * 60,
        allowGraphClick: true,
        graphId: `graphDetails${selectedDate}`,
    });

    const selectedDeviceInfo = selectedDevice ? devicesWithKeyInfo[selectedDevice] : undefined;
    const selectedDeviceData =
        selectedDevice && devicesUsedSelectedDay.find(device => device.serialNumber === selectedDevice);
    const timeInUse = selectedDeviceData && humanizedTimeString(selectedDeviceData.minutesUsed, true);
    const timeNotInUse =
        selectedDeviceData && humanizedTimeString(hoursInUseForDay - selectedDeviceData.minutesUsed, true);

    const selectedPeriod = {
        toDate: selectedDate,
        fromDate: selectedDate,
        name: TimePeriod.day,
        label: '12hours',
    };

    return (
        <div className={detailsExpanded ? styles.expandedCard : styles.card}>
            {selectedDevice && (
                <button
                    className={styles.expandButton}
                    type="button"
                    onClick={(): void => expandDetails(!detailsExpanded)}
                >
                    <MaterialIcon name={detailsExpanded ? 'expand_more' : 'expand_less'} />
                </button>
            )}
            <div className={detailsExpanded ? styles.expandedSelectedDevice : styles.selectedDevice}>
                <div className={styles.infoSection}>
                    {!selectedPoint && txt('BuildingInsight.SelectDate')}
                    <div className={styles.infoHeader}>{selectedPoint}</div>
                    {selectedDeviceData && (
                        <div className={styles.deviceInfo}>
                            <Link
                                className={styles.deviceName}
                                to={`/${paths.devices}/${selectedDevice}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {selectedDeviceInfo?.segmentName}
                            </Link>
                            <div className={styles.timeInUse}>
                                {txt('PresenceInsight.InUse')}:{timeInUse}, {txt('PresenceInsight.Available')}:{' '}
                                {timeNotInUse}
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.graphSection}>
                    {!redraw && <HighchartsReact highcharts={Highcharts} options={config} />}
                </div>
            </div>
            {selectedDevice && detailsExpanded && (
                <PresenceSelectedDevice
                    viewType={PresenceViewType.DayView}
                    timeZone={timeZone}
                    selectedDevice={selectedDevice}
                    devicesWithKeyInfo={devicesWithKeyInfo}
                    timeInUse={timeInUse}
                    selectedPeriod={selectedPeriod}
                    dateFormat={dateFormat}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { devicesWithKeyInfo },
    } = state;
    return { devicesWithKeyInfo };
};

export default connect(mapStateToProps)(UsageOverTimeDayView);
