import React from 'react';
import FlipButton from '../../components/buttons/FlipButton';
import IntegrationRowContent from './IntegrationRowContent';

export type Props = {
    name: string;
    active?: boolean;
    statusColor?: string;
    updateIntegration?: (isOn: boolean) => void;
    id: string;
    onClick: (id: string) => void;
    firstRow?: string;
    secondRow?: string;
    firstRowLogo?: React.ReactElement;
};

const IntegrationRow = (props: Props): React.ReactElement => {
    const { statusColor, updateIntegration, name, active, id, onClick, firstRow, secondRow, firstRowLogo } = props;
    const updateActiveState = (isOn: boolean): void => {
        if (updateIntegration) updateIntegration(isOn);
    };

    return (
        <div
            tabIndex={0}
            role="button"
            onKeyUp={(e): false | void => e.key === 'Enter' && onClick(id)}
            onClick={(): void => onClick(id)}
            className="list__row list__row--clickable"
        >
            <IntegrationRowContent
                name={name}
                statusColor={statusColor}
                firstRow={firstRow}
                secondRow={secondRow}
                firstRowLogo={firstRowLogo}
            />
            {active !== undefined && (
                <div className="integration-row__action-buttons">
                    <FlipButton onClick={updateActiveState} leftSelected={active} leftText="On" rightText="Off" />
                </div>
            )}
        </div>
    );
};

export default IntegrationRow;
