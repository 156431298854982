import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Store } from '../../reducers';

type Props = {
    demoMode: boolean;
};

const DemoBanner = ({ demoMode }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    if (!demoMode) {
        return <div />;
    }
    return (
        <div className="cookie-banner">
            <span className="cookie-banner__message">{txt('DemoMode')}</span>
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        userSettings: { demoMode },
    } = state;
    return {
        demoMode,
    };
};

export default connect(mapStateToProps)(DemoBanner);
