import { DeviceActionType, DeviceReducerActions } from 'commons/src/actions/DeviceActions';
import { FloorPlanReducerCommonActions } from 'commons/src/actions/FloorPlanActions';
import { HubActionType, HubReducerActions } from 'commons/src/actions/HubActions';
import {
    DeleteLocationSuccess,
    UPDATE_LOCATION_DETAILS_SUCCESS,
    UpdateLocationDetailsSuccess,
} from 'commons/src/actions/LocationActions';
import { DELETE_LOCATION_SUCCESS } from 'commons/src/actions/LocationActionTypes';
import { BuildingConfiguration, BuildingType, LocationDetailsType } from 'commons/src/models/commonTypeScript';
import { FloorplanReducerAction } from '../actions/floorplanActions';
import {
    BuildingReducerActions,
    FETCH_LOCATION_LABELS_SUCCESS,
    FETCH_LOCATION_RESET_CONNECTIONS_SUCCESS,
    GET_LOCATION_CONFIG_SUCCESS,
    LocationActionType,
    SET_LOCATION_LABELS_SUCCESS,
    UPDATE_LOCATION_CONFIG_SUCCESS,
    UPDATE_LOCATION_CONFIG_WITH_DEVICE_TYPE_SUCCESS,
} from '../actions/locationActions';
import { KeyValuePairType, ResetConnection } from '../models/common';

export type BuildingsState = {
    locationLabels: {
        [locationId: string]: KeyValuePairType[];
    };
    configurations: {
        [locationId: string]: BuildingConfiguration;
    };
    buildings: { [locationId: string]: BuildingType };
    resetConnections: ResetConnection[];
};

const initialState: BuildingsState = {
    locationLabels: {},
    configurations: {},
    resetConnections: [],
    buildings: {},
};

const updateLocation = (
    updatedDetails: LocationDetailsType,
    buildings: { [locationId: string]: BuildingType }
): { [locationId: string]: BuildingType } => {
    const buildingToUpdate = buildings[updatedDetails.id];
    if (!buildingToUpdate) {
        return buildings;
    }
    return {
        ...buildings,
        [updatedDetails.id]: { ...buildingToUpdate, ...updatedDetails },
    };
};

const removeHubFromBuilding = (
    hubSerialNumber: string,
    locationId: string,
    buildings: { [locationId: string]: BuildingType }
): { [locationId: string]: BuildingType } => {
    const buildingToUpdate = buildings[locationId];
    if (!buildingToUpdate) {
        return buildings;
    }
    return {
        ...buildings,
        [locationId]: {
            ...buildingToUpdate,
            hubs: buildingToUpdate.hubs.filter(serialNumber => serialNumber !== hubSerialNumber),
        },
    };
};

const removeDeviceFromBuilding = (
    serialNumber: string,
    locationId: string,
    buildings: { [locationId: string]: BuildingType }
): { [locationId: string]: BuildingType } => {
    const buildingToUpdate = buildings[locationId];
    if (!buildingToUpdate) {
        return buildings;
    }
    return {
        ...buildings,
        [locationId]: {
            ...buildingToUpdate,
            devices: buildingToUpdate.devices.filter(snr => snr !== serialNumber),
        },
    };
};

export default (
    state: BuildingsState = initialState,
    action:
        | BuildingReducerActions
        | UpdateLocationDetailsSuccess
        | FloorPlanReducerCommonActions
        | FloorplanReducerAction
        | DeleteLocationSuccess
        | DeviceReducerActions
        | HubReducerActions
): BuildingsState => {
    switch (action.type) {
        case HubActionType.DeleteHubSuccess:
            return {
                ...state,
                buildings: removeHubFromBuilding(action.serialNumber, action.locationId, state.buildings),
            };
        case DeviceActionType.DeleteDeviceSuccess:
            return {
                ...state,
                buildings: removeDeviceFromBuilding(action.serialNumber, action.locationId, state.buildings),
            };
        case UPDATE_LOCATION_DETAILS_SUCCESS:
            return {
                ...state,
                buildings: updateLocation(action.locationDetails, state.buildings),
            };
        case DELETE_LOCATION_SUCCESS: {
            const copyOfBuildings = { ...state.buildings };
            delete copyOfBuildings[action.locationId];
            return {
                ...state,
                buildings: copyOfBuildings,
            };
        }
        case FETCH_LOCATION_LABELS_SUCCESS:
        case SET_LOCATION_LABELS_SUCCESS:
            return {
                ...state,
                locationLabels: {
                    ...state.locationLabels,
                    [action.locationId]: action.labels,
                },
            };
        case GET_LOCATION_CONFIG_SUCCESS:
        case UPDATE_LOCATION_CONFIG_WITH_DEVICE_TYPE_SUCCESS:
            return {
                ...state,
                configurations: { ...state.configurations, [action.locationId]: action.config },
            };
        case UPDATE_LOCATION_CONFIG_SUCCESS: {
            const currentLocationConfig = state.configurations[action.locationId];
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    [action.locationId]: {
                        ...currentLocationConfig,
                        resetEnabled:
                            action.config.resetEnabled !== undefined
                                ? action.config.resetEnabled
                                : currentLocationConfig.resetEnabled,
                        configuration: {
                            ...currentLocationConfig.configuration,
                            ...action.config.configuration,
                        },
                    },
                },
            };
        }
        case FETCH_LOCATION_RESET_CONNECTIONS_SUCCESS:
            return {
                ...state,
                resetConnections: action.resetConnections,
            };
        case LocationActionType.FetchBuildingSuccess:
            return {
                ...state,
                buildings: {
                    ...state.buildings,
                    [action.locationId]: { ...state.buildings[action.locationId], ...action.location },
                },
            };
        default:
            return state;
    }
};

export const labels = (store: { buildings: BuildingsState }, locationId: string): KeyValuePairType[] =>
    store.buildings.locationLabels[locationId];
