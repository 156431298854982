import React from 'react';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { EstimatedSubscription } from '../../../../models/subscriptionModels';
import SubscriptionCostTable from '../SubscriptionCostTable';

export type Props = {
    error?: ErrorType;
    costPreview?: EstimatedSubscription;
    loading: boolean;
};

const CostPreview = ({ error, costPreview, loading }: Props): React.ReactElement => {
    return (
        <div>
            {error ? (
                <ResponseBox text={`ErrorCodes.${error.error}`} />
            ) : (
                <SubscriptionCostTable
                    subscription={costPreview}
                    estimatedCostText="Zuora.EstimatedCostExclTax"
                    noDataText="Subscription.AddSeatsToViewEstimate"
                    loading={loading}
                />
            )}
        </div>
    );
};

export default CostPreview;
