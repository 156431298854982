import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { BUILDING_THRESHOLD_BREACH_INSIGHT_BAR_SELECTED } from 'commons/src/analytics/AnalyticsEvents';
import { dateFormats } from 'commons/src/constants';
import { Resolution, TimePeriod } from 'commons/src/models/commonEnums';
import { BuildingInsight } from '../../../models/buildingModels';
import { Store } from '../../../reducers';
import InsightBreakDown from './InsightBreakDown';
import TrendOverTimeGraph from './TrendOverTime';

type StateProps = {
    dateFormat: keyof typeof dateFormats;
};
export type ParentProps = {
    insight: BuildingInsight;
    timeZone?: string;
    selectedPeriod: {
        toDate: string;
        fromDate: string;
        resolution: Resolution;
        name: TimePeriod;
    };
    selectedHighThreshold: { id: string; inputValue: string };
    selectedLowThreshold?: { id: string; inputValue: string };
};

export type Props = ParentProps & StateProps;

export const BuildingSensorTrendOverTime = ({
    insight,
    dateFormat,
    timeZone,
    selectedPeriod,
    selectedHighThreshold,
    selectedLowThreshold,
}: Props): React.ReactElement => {
    const [selectedPoint, setSelectedPoint] = useState<string | undefined>();
    const [detailsExpanded, setDetailsExpanded] = useState(false);
    const { sensor, thresholds, trendData } = insight;

    useEffect(() => {
        if (detailsExpanded) {
            setDetailsExpanded(false);
        }
    }, [selectedHighThreshold, selectedLowThreshold]);

    const onGraphClick = (pointId: string): void => {
        if (selectedPoint === pointId) setSelectedPoint(undefined);
        else {
            analyticsLogger(BUILDING_THRESHOLD_BREACH_INSIGHT_BAR_SELECTED, {
                pageType: PageType.Building,
                barType: 'time',
                sensor,
            });
            setSelectedPoint(pointId);
        }
    };

    const displayLegends = thresholds.length > 1;

    return (
        <div className="building-insight__card__body">
            {!detailsExpanded && (
                <div
                    className={classNames('building-insight__card__section', {
                        'building-insight__card__section--with-legend': displayLegends,
                    })}
                >
                    <div className="building-insight__card__section--content">
                        <TrendOverTimeGraph
                            timeZone={timeZone}
                            trendData={trendData}
                            lowThreshold={selectedLowThreshold ? selectedLowThreshold.inputValue : undefined}
                            highThreshold={selectedHighThreshold.inputValue}
                            selectedPeriod={selectedPeriod}
                            onPointClick={onGraphClick}
                            displayLegends={displayLegends}
                            allowGraphClick
                            graphId={`trendOverTimeGraph${sensor}`}
                        />
                    </div>
                </div>
            )}
            <InsightBreakDown
                detailsExpanded={detailsExpanded}
                insight={insight}
                setDetailsExpanded={setDetailsExpanded}
                selectedPoint={selectedPoint}
                dateFormat={dateFormat}
                resolution={selectedPeriod.resolution}
                timeZone={timeZone}
                selectedHighThreshold={selectedHighThreshold}
                selectedLowThreshold={selectedLowThreshold}
            />
        </div>
    );
};
const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = store;
    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(BuildingSensorTrendOverTime);
