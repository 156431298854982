import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Dropdown from 'commons/src/components/dropdown/MultipleAttrDropdown';
import Input from 'commons/src/components/input/Input';
import LabeledText from 'commons/src/components/LabeledText';
import { VirtualDeviceType } from 'commons/src/models/commonEnums';
import { BuildingType, InletAirMetaDataProperties } from 'commons/src/models/commonTypeScript';
import InletAirConfiguration from './InletAirConfiguration';

export type Props = {
    displayValidation: boolean;
    setGroupName: (name: string) => void;
    setGroupType: (group: { id: string; inputValue: string }) => void;
    inletAirConfig: InletAirMetaDataProperties;
    setInletAirConfig: (config: InletAirMetaDataProperties) => void;
    groupName: string;
    groupType?: { id: string; inputValue: string };
    deviceType?: VirtualDeviceType;
    building?: BuildingType;
};

export const GroupInfoComponent = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const {
        displayValidation,
        groupType,
        groupName,
        setGroupName,
        setGroupType,
        deviceType,
        building,
        setInletAirConfig,
        inletAirConfig,
    } = props;

    const updateName = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        setGroupName(value);
    };

    const onSetGroupType = (newGroup: { id: string; inputValue: string }): void => {
        setGroupType(newGroup);
    };

    const groupTypeOptions = [
        { id: VirtualDeviceType.aggregatedGroup, inputValue: txt(`${VirtualDeviceType.aggregatedGroup}FullName`) },
        { id: VirtualDeviceType.inletAirControl, inputValue: txt(`${VirtualDeviceType.inletAirControl}FullName`) },
    ];

    return (
        <>
            <div className={classNames({ form__row: !deviceType })}>
                <div className={classNames('form__field', { 'form__field--single-width': !deviceType })}>
                    <Input
                        type="text"
                        id="alert-name"
                        testAttr="group-name"
                        label="Name"
                        validate={displayValidation && groupName.length < 2}
                        isValid={groupName.length > 1}
                        maxLength={25}
                        currentValue={groupName}
                        onChange={updateName}
                        testId="group-name"
                    />
                </div>
            </div>
            {deviceType ? (
                <div className="form__field form__field--single-width">
                    <LabeledText label="Location" id="group-description" value={(building && building.name) || ''} />
                </div>
            ) : (
                <div className="form__row third-party__padding--medium">
                    <div className="form__field form__field--single-width">
                        <Dropdown
                            options={groupTypeOptions}
                            id="SetGroupType"
                            defaultOption="Select"
                            validate={displayValidation && !groupType}
                            isValid={!!groupType}
                            value={groupType && groupType.inputValue}
                            testAttr="select-group-type"
                            onSelect={onSetGroupType}
                            title="ZoneGrouping.GroupType"
                            testId="select-group-type"
                        />
                    </div>
                    <div className="form__field form__field--single-width">
                        {groupType && (
                            <LabeledText
                                label=""
                                id="group-description"
                                value={txt(`ZoneGrouping.${groupType && groupType.id}Description`)}
                            />
                        )}
                    </div>
                </div>
            )}
            {groupType && groupType.id === VirtualDeviceType.inletAirControl && (
                <InletAirConfiguration setInletAirConfig={setInletAirConfig} inletAirConfig={inletAirConfig} />
            )}
        </>
    );
};

export default GroupInfoComponent;
