import React, { SyntheticEvent, useEffect, useState } from 'react';
import Input from 'commons/src/components/input/Input';
import InputLabel from 'commons/src/components/input/InputLabel';
import Textarea from 'commons/src/components/input/Textarea';
import { MqttClient } from '../../../models/common';

export type Props = {
    client?: MqttClient;
    inputValid: boolean;
    onNameChanged: (newName: string) => void;
    onDescriptionChanged: (newDescription: string) => void;
};

const AddEditFormComponent = (props: Props): React.ReactElement => {
    const { client, inputValid, onNameChanged, onDescriptionChanged } = props;
    const [displayValidation, setDisplayValidation] = useState(!inputValid);

    useEffect(() => {
        setDisplayValidation(!inputValid);
    }, [inputValid]);

    const updateName = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        onNameChanged(value);
    };

    const updateDescription = (text: string): void => {
        onDescriptionChanged(text);
    };

    return (
        <>
            <div className="form__row">
                <div className="form__field form__field--single-width">
                    <Input
                        type="text"
                        id="mqtt-client-name"
                        validate={displayValidation}
                        isValid={!displayValidation}
                        hint="Mqtt.NameHint"
                        label="Mqtt.ClientName"
                        maxLength={84}
                        onChange={updateName}
                        defaultValue={client ? client.clientName : ''}
                    />
                </div>
                <div className="form__field">
                    {client && (
                        <div className="input-container">
                            <InputLabel htmlFor="mqtt-client-id" label="ApiIntegration.ClientId" />
                            <div className="form__attr input-container__string" id="mqtt-client-id">
                                {client ? client.id : ''}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="form__row">
                <div className="form__field">
                    <Textarea
                        autoComplete=""
                        id="mqtt-client-description"
                        label="Mqtt.Description"
                        maxRows={4}
                        maxLength={255}
                        rows={4}
                        onChange={updateDescription}
                        defaultValue={client ? client.description || '' : ''}
                        displayValidationResult={false}
                        isValid
                        markedMandatory={false}
                        required={false}
                    />
                </div>
            </div>
        </>
    );
};

export default AddEditFormComponent;
