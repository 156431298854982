import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import CommonDeviceOverview from 'commons/src/features/devicePage/CommonDeviceOverview';
import CSVDownloadModal from 'commons/src/features/devicePage/dropdownOptions/CSVDownloadModal';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { FullDeviceData } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { Store } from 'commons/src/reducers';
import { deviceActionButtons } from './DevicePageHeader';

type StateProps = {
    devicePageDevices: { [serialNumber: string]: FullDeviceData };
};

type Props = StateProps;

export const DeviceOverviewComponent = ({ devicePageDevices }: Props): React.ReactElement => {
    const { serialNumber } = useParams() as { serialNumber: string };
    const navigate = useNavigate();

    const [isCSVModalOpen, setIsCSVModalOpen] = useState(false);
    const device: FullDeviceData = devicePageDevices[serialNumber] || {};

    const deviceWithRadonSensor =
        device && device.sensors && device.sensors.some(sensor => sensor.type === SensorTypes.radonShortTermAvg);

    const actionButtons: ActionButton[] = deviceActionButtons(
        (): void => setIsCSVModalOpen(true),
        deviceWithRadonSensor,
        navigate
    );

    return (
        <div className="single-device">
            <CommonDeviceOverview
                actionButtons={actionButtons}
                serialNumber={serialNumber}
                topChildren={
                    isCSVModalOpen ? (
                        <CSVDownloadModal
                            onClose={(): void => setIsCSVModalOpen(false)}
                            segments={[
                                {
                                    serialNumber,
                                    deviceType: device.deviceType,
                                    segmentId: device.segmentId,
                                    segmentStartDate: device.segmentStart,
                                    name: device.segmentName,
                                },
                            ]}
                        />
                    ) : (
                        <div />
                    )
                }
            />
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devicePage: { devices: devicePageDevices },
    } = state;

    return {
        devicePageDevices,
    };
};

export default connect(mapStateToProps)(DeviceOverviewComponent);
