import { SubscriptionActions, SubscriptionEndCustomerAction } from '../actions/subscriptionActions';
import { Subscription } from '../models/subscriptionModels';

export interface SubscriptionStore {
    subscriptions: Subscription[];
    newSubscriptionNumber?: string;
}

const initialState: SubscriptionStore = {
    subscriptions: [],
    newSubscriptionNumber: undefined,
};
const reducer = (state: SubscriptionStore = initialState, action: SubscriptionEndCustomerAction): SubscriptionStore => {
    switch (action.type) {
        case SubscriptionActions.GetSubscriptionForEndCustomerSuccess:
            return {
                ...state,
                subscriptions: action.subscriptions,
            };
        case SubscriptionActions.CreateSubscriptionForDirectCustomerInit:
            return {
                ...state,
                newSubscriptionNumber: undefined,
            };
        case SubscriptionActions.CreateSubscriptionForDirectCustomerSuccess:
            return {
                ...state,
                newSubscriptionNumber: action.subscriptionNumber,
            };
        case SubscriptionActions.UpdatePoRefSuccess: {
            const subscriptionToUpdateIndex = state.subscriptions.findIndex(
                sub => sub.subscriptionNumber === action.subscriptionNumber
            );

            if (subscriptionToUpdateIndex > -1) {
                const updatedSubscriptions = [...state.subscriptions];
                updatedSubscriptions[subscriptionToUpdateIndex].poNumber = action.poRef;
                return {
                    ...state,
                    subscriptions: updatedSubscriptions,
                };
            }
            return state;
        }
        default:
            return state;
    }
};

export default reducer;
