import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SubNavigation, TabOption } from '../../models/menuModels';
import MaterialIcon from '../MaterialIcon';
import NavigationItem from '../menu/NavigationItem';
import styles from './SubMenuMobile.module.scss';

type Props = {
    tab: TabOption;
    subNavigation: SubNavigation[];
    closeMenu: () => void;
};

const SubMenuMobile = ({ tab, subNavigation, closeMenu }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const { pathname } = useLocation();
    const elementIsActive = tab.path === pathname;

    return (
        <div className={elementIsActive || subMenuOpen ? styles.subMenuWrapperActive : styles.subMenuWrapper}>
            <div
                role="button"
                tabIndex={0}
                onClick={(): void => setSubMenuOpen(!subMenuOpen)}
                className={styles.subMenuHeader}
                onKeyUp={(event): void => {
                    if (event.key !== 'Enter') return;
                    setSubMenuOpen(!subMenuOpen);
                }}
            >
                {txt(tab.text)} <MaterialIcon name={subMenuOpen ? 'arrow_drop_up' : 'arrow_drop_down'} />
            </div>
            {subMenuOpen &&
                subNavigation.map(menuItem => (
                    <NavigationItem
                        key={menuItem.id}
                        path={`${tab.path}?subPage=${menuItem.queryParam}`}
                        onClick={closeMenu}
                        title={menuItem.title}
                        navigationType="large"
                        id={menuItem.id}
                        invertedColors
                    />
                ))}
        </div>
    );
};

export default SubMenuMobile;
