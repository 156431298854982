import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import {
    BoldText,
    Paragraph,
    ParagraphNoPaddingTop,
    ColoredDot,
    colorStyles,
} from 'commons/src/components/PDF/PdfConstants';
import { PDFContext } from '../../../../models/common';

const componentStyles = StyleSheet.create({
    thresholdWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '3vw',
    },
});

type Props = {
    txt: PDFContext;
    devicesInEachLevel: { good: number; fair: number; poor: number };
};

const Summary = ({ txt, devicesInEachLevel }: Props): React.ReactElement => {
    const allDevicesGood = devicesInEachLevel.poor + devicesInEachLevel.fair === 0;
    const numberOfDevicesWithHighestAlert =
        devicesInEachLevel.poor > 0 ? devicesInEachLevel.poor : devicesInEachLevel.fair;
    const warningTextKey =
        numberOfDevicesWithHighestAlert === 1
            ? 'RadonInsight.SingleDeviceAtWarningLevel'
            : 'RadonInsight.DevicesAtWarningLevel';
    const alertTextKey =
        numberOfDevicesWithHighestAlert === 1
            ? 'RadonInsight.SingleDeviceAtHighRisk'
            : 'RadonInsight.DevicesAtHighRisk';
    const numberOfDevicesWithAlertTextKey = devicesInEachLevel.poor > 0 ? alertTextKey : warningTextKey;
    const text = allDevicesGood
        ? txt('RadonInsight.AllDevicesWithinRecommendedLevel')
        : txt(numberOfDevicesWithAlertTextKey, { numberOfDevices: numberOfDevicesWithHighestAlert.toString() });
    let color = 'grey';
    if (devicesInEachLevel.poor === 0 && devicesInEachLevel.fair === 0) {
        color = 'green';
    } else if (devicesInEachLevel.poor === 0 && devicesInEachLevel.fair > 0) {
        color = 'yellow';
    } else if (devicesInEachLevel.poor > 0) {
        color = 'red';
    }
    return (
        <View>
            <View style={componentStyles.thresholdWrapper}>
                <ColoredDot style={colorStyles[color as keyof typeof colorStyles]} />
                <View>
                    <BoldText style={colorStyles.smallText}>{text}</BoldText>
                </View>
            </View>
            <ParagraphNoPaddingTop>{txt('RadonInsight.RadonDescription')}</ParagraphNoPaddingTop>
            <Paragraph>{txt('RadonInsight.LongTermVsOpeningHoursDescription')}</Paragraph>
        </View>
    );
};

export default Summary;
