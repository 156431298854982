import React from 'react';
import ReadableBatteryLife from 'commons/src/components/ReadableBatteryLife';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { ErrorType } from 'commons/src/models/commonTypeScript';

type Props = {
    estimateBatteryError: ErrorType | undefined;
    estimatedBatteryLife: number | undefined;
};
const BatteryLevelDisplayedComponent = ({ estimateBatteryError, estimatedBatteryLife }: Props): React.ReactElement =>
    estimateBatteryError ? (
        <ResponseBox text={`ErrorCodes.${estimateBatteryError.error}`} />
    ) : (
        <ReadableBatteryLife estimatedBatteryLife={estimatedBatteryLife} />
    );

export default BatteryLevelDisplayedComponent;
