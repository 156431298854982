import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { createSearchParams, Navigate, Outlet } from 'react-router-dom';
import { userRoleAboveRequiredLevel } from './features/authorization/userRoleAboveRequiredLevel';
import { FeatureToggleEnum, RequiredRoleLevel, Role } from './models/commonEnums';
import { Store } from './reducers';

type WithChildren<T = Record<string, unknown>> = T & { children?: React.ReactNode | Element[] };

type Props = {
    minRoleLevel: RequiredRoleLevel;
    accessWithResourceFilter?: boolean;
    children?: ReactElement;
    featureToggle?: FeatureToggleEnum;
};

type State = {
    resourceFilterOn: boolean;
    userRole: Role;
    loading: boolean;
    featureToggles: FeatureToggleEnum[];
};

const ProtectedComponent = ({
    minRoleLevel,
    accessWithResourceFilter = true,
    resourceFilterOn,
    userRole,
    children,
    loading,
    featureToggle,
    featureToggles,
}: WithChildren<Props & State>): ReactElement => {
    const accessWithFeatureToggle = featureToggle ? featureToggles.includes(featureToggle) : true;
    const accessNotRestricted = accessWithResourceFilter || !resourceFilterOn;

    if (
        loading ||
        (userRoleAboveRequiredLevel(userRole, minRoleLevel, accessNotRestricted) && accessWithFeatureToggle)
    ) {
        return children || <Outlet />;
    }

    return <Navigate to={`/forbidden?${createSearchParams({ requiredRoleLevel: minRoleLevel }).toString()}`} replace />;
};

const mapStateToProps = (store: Store): State => {
    const {
        userSettings: { selectedGroup, loading, featureToggles },
    } = store;
    return {
        resourceFilterOn: (selectedGroup && selectedGroup.resourceFilterOn) || false,
        userRole: selectedGroup ? selectedGroup.role : Role.VIEWER,
        loading,
        featureToggles,
    };
};

export default connect(mapStateToProps)(ProtectedComponent);
