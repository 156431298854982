/* eslint max-len: ["error", 1000] */
import React from 'react';

const IconControlSignal = (): React.ReactElement => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="iconControlSignal">
        <path d="M13 0.15625C13.466 0.15625 13.8438 0.53401 13.8438 1V23C13.8438 23.466 13.466 23.8438 13 23.8438C12.534 23.8438 12.1562 23.466 12.1562 23V1C12.1562 0.53401 12.534 0.15625 13 0.15625Z" />
        <path d="M5.59662 7.40338C5.26712 7.07387 4.73288 7.07387 4.40338 7.40338C4.07387 7.73288 4.07387 8.26712 4.40338 8.59662L6.96301 11.1562H1C0.53401 11.1562 0.15625 11.534 0.15625 12C0.15625 12.466 0.53401 12.8438 1 12.8438H6.96301L4.40338 15.4034C4.07387 15.7329 4.07387 16.2671 4.40338 16.5966C4.73288 16.9261 5.26712 16.9261 5.59662 16.5966L9.59662 12.5966C9.92613 12.2671 9.92613 11.7329 9.59662 11.4034L5.59662 7.40338Z" />
        <path d="M21.5966 8.59662C21.9261 8.26712 21.9261 7.73288 21.5966 7.40338C21.2671 7.07387 20.7329 7.07387 20.4034 7.40338L16.4034 11.4034C16.0739 11.7329 16.0739 12.2671 16.4034 12.5966L20.4034 16.5966C20.7329 16.9261 21.2671 16.9261 21.5966 16.5966C21.9261 16.2671 21.9261 15.7329 21.5966 15.4034L19.037 12.8438H25C25.466 12.8438 25.8438 12.466 25.8438 12C25.8438 11.534 25.466 11.1562 25 11.1562H19.037L21.5966 8.59662Z" />
    </svg>
);

export default IconControlSignal;
