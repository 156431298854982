import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '../../components/MaterialIcon';
import styles from './InactiveSubscriptionContent.module.scss';

const InactiveSubscriptionContent = (): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className={styles.wrapper}>
            <MaterialIcon extraClass={styles.icon} name="lock" />
            <h1 className={styles.title}>{txt('Subscription.LimitedPlan')}</h1>
            <p className={styles.text}>{txt('Subscription.UpdatePlan')}</p>
        </div>
    );
};

export default InactiveSubscriptionContent;
