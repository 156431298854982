import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { sensorUnits } from 'commons/src/constants';
import { Resolution, TimePeriod } from 'commons/src/models/commonEnums';
import { BuildingInsight } from '../../../../models/buildingModels';
import generateDetailsConfig from '../BuildingInsightDetailsGraphConfig';
import TrendOverTimeGraph from '../TrendOverTime';
import styles from './ThresholdBreachReport.module.scss';

export type ParentProps = {
    selectedPeriod: {
        toDate: string;
        fromDate: string;
        resolution: Resolution;
        name: TimePeriod;
    };
    thresholdBreachForSensor: BuildingInsight;
    timeZone?: string;
};

type Props = ParentProps;

const ThresholdBreachSensorResult = ({
    thresholdBreachForSensor,
    selectedPeriod,
    timeZone,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const {
        trendData,
        thresholds,
        totalDeviceData: { devicesInTimeFrame },
        unit,
        sensor,
    } = thresholdBreachForSensor;

    const onGraphClick = (): void => {
        // do nothing
    };

    const highThreshold = thresholds.find(threshold => threshold.type === 'over');
    const lowThreshold = thresholds.find(threshold => threshold.type === 'under');

    const numberOfDevicesWithDataOutsideForTimeFrame = devicesInTimeFrame?.length;
    const displayLegends = thresholds.length > 1;

    const totalByDeviceDevicesSortedByOutsideTime = devicesInTimeFrame.sort((device1, device2) => {
        if (
            device1.overThresholdsTime + device1.underThresholdsTime >
            device2.overThresholdsTime + device2.underThresholdsTime
        )
            return -1;
        return 1;
    });

    const totalByDeviceChartHeight = numberOfDevicesWithDataOutsideForTimeFrame * 30 + 40;

    const totalByDeviceChartData = totalByDeviceDevicesSortedByOutsideTime.map(device => device.overThresholdsTime);
    const totalByDeviceLowerLimitChartData = totalByDeviceDevicesSortedByOutsideTime.map(
        device => device.underThresholdsTime
    );
    const totalByDeviceCategories = totalByDeviceDevicesSortedByOutsideTime.map(device => device.serialNumber);
    const totalByDeviceConfig = generateDetailsConfig(
        totalByDeviceChartData,
        totalByDeviceLowerLimitChartData,
        totalByDeviceCategories,
        devicesInTimeFrame,
        onGraphClick,
        displayLegends ? totalByDeviceChartHeight + 25 : totalByDeviceChartHeight,
        txt,
        displayLegends,
        `${highThreshold?.value}${sensorUnits[unit as keyof typeof sensorUnits]}`,
        true,
        false,
        'totalByDeviceGraph',
        lowThreshold ? `${lowThreshold.value}${sensorUnits[unit as keyof typeof sensorUnits]}` : undefined
    );

    return (
        <div id="thresholdBreachOverviewSection" className="form__wide-container insight-tile insight-tile--full-width">
            <h2 className={styles.overviewHeader}>{txt('Overview')}</h2>
            <p>{txt(`InsightIaq.${sensor}Description`)}</p>
            {txt('BuildingInsight.TrendOverTime')}
            <div className={styles.graphWrapper}>
                <div className={styles.graphWrapperContent}>
                    <TrendOverTimeGraph
                        timeZone={timeZone}
                        trendData={trendData}
                        lowThreshold={
                            lowThreshold
                                ? `${lowThreshold.value}${sensorUnits[unit as keyof typeof sensorUnits]}`
                                : undefined
                        }
                        highThreshold={`${highThreshold?.value}${sensorUnits[unit as keyof typeof sensorUnits]}`}
                        selectedPeriod={selectedPeriod}
                        onPointClick={onGraphClick}
                        displayLegends={displayLegends}
                        allowGraphClick={false}
                        graphId="trendOverTimeGraph"
                    />
                </div>
            </div>
            {txt('BuildingInsight.TotalByDevice')}
            <div className={styles.graphWrapper}>
                <div className={styles.graphWrapperContent}>
                    <HighchartsReact highcharts={Highcharts} options={totalByDeviceConfig} />
                </div>
            </div>
        </div>
    );
};

export default ThresholdBreachSensorResult;
