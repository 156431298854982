import {
    VALIDATE_SERIALNUMBER,
    VALIDATE_SERIALNUMBER_SUCCESS,
    VALIDATE_SERIALNUMBER_ERROR,
    VALIDATE_SERIALNUMBER_RESET,
    AddDeviceReducerActions,
} from '../actions/AddDeviceActions';
import { LOGOUT_SUCCESS, LogOutSuccess } from '../actions/LoginAndRegisterActions';
import { ErrorType } from '../models/commonTypeScript';

export type VerifySerialNumberStore = {
    error?: ErrorType;
    loading: boolean;
    serialNumberValid: boolean;
    verifiedDevice: {
        serialNumber: string;
        checkCode: string;
    };
};

const initialState: VerifySerialNumberStore = {
    error: undefined,
    loading: false,
    serialNumberValid: false,
    verifiedDevice: { serialNumber: '', checkCode: '' },
};

export default (
    state: VerifySerialNumberStore = initialState,
    action: AddDeviceReducerActions | LogOutSuccess
): VerifySerialNumberStore => {
    switch (action.type) {
        case VALIDATE_SERIALNUMBER:
            return {
                ...state,
                loading: true,
                error: undefined,
                serialNumberValid: false,
                verifiedDevice: { serialNumber: '', checkCode: '' },
            };
        case VALIDATE_SERIALNUMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                serialNumberValid: true,
                error: undefined,
                verifiedDevice: { serialNumber: action.payload.serialNumber, checkCode: action.payload.checkCode },
            };
        case VALIDATE_SERIALNUMBER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                serialNumberValid: false,
                verifiedDevice: { serialNumber: action.payload.serialNumber, checkCode: action.payload.checkCode },
            };
        case LOGOUT_SUCCESS:
        case VALIDATE_SERIALNUMBER_RESET:
            return initialState;
        default:
            return state;
    }
};
