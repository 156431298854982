import React from 'react';
import { SelectionItem } from '../models/commonTypeScript';
import ChipButton from './buttons/ChipButton';
import InputLabel from './input/InputLabel';
import styles from './LabeledTextWithChips.module.scss';

type Props = {
    label: string;
    id: string;
    infoText?: string;
    selectedElements: SelectionItem[];
};

const LabeledTextWithChips = ({ label, id, infoText, selectedElements }: Props): React.ReactElement => (
    <div className="labeled-text">
        <InputLabel htmlFor={id} label={label} infoText={infoText} />
        <div id="inputWithChips" className={styles.chips}>
            {selectedElements.map(elem => (
                <ChipButton key={`chip${elem.id}`} title={elem.name} id={elem.id} testId={elem.id} disabled />
            ))}
        </div>
    </div>
);

export default LabeledTextWithChips;
