/* eslint max-len: ["error", 15000] */
import React from 'react';

const IaqCertificateIllustration = (): React.ReactElement => (
    <svg width="596" height="299" viewBox="0 0 596 299" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M317.533 264.79C281.04 263.12 231.145 275.851 187.165 277.321C176.271 219.011 165.309 157.225 159.995 98.4474C195.253 107.069 236.325 98.8818 274.356 98.0798C287.089 169.322 291.734 196.89 317.533 264.79Z"
            fill="white"
        />
        <path
            d="M273.942 97.3342C272.237 97.1002 270.499 97.0668 268.762 97H263.348C259.605 97.0334 255.895 97.2005 252.152 97.401C244.533 97.8354 236.947 98.4369 229.36 99.0384C221.774 99.6398 214.054 100.241 206.368 100.542C198.682 100.843 191.229 101.077 183.676 100.642C176.37 100.308 169.105 99.3696 161.954 97.8354L160.651 97.5346C160.229 97.4901 159.804 97.4789 159.381 97.5012C159.296 97.5055 159.215 97.5364 159.15 97.5893C159.084 97.6423 159.036 97.7147 159.014 97.7962C158.992 97.8777 158.996 97.9641 159.026 98.0432C159.056 98.1222 159.11 98.1898 159.18 98.2364C159.541 98.4588 159.922 98.6488 160.316 98.8044C160.742 98.9399 161.178 99.0405 161.62 99.1052L164.226 99.6733C166.031 100.041 167.836 100.342 169.64 100.642C173.25 101.177 176.892 101.578 180.502 101.845C188.029 102.413 195.581 102.581 203.126 102.347C210.846 102.113 218.566 101.578 226.286 100.976C234.006 100.375 241.525 99.7067 249.145 99.0718C256.764 98.4369 263.882 98.0359 271.268 97.6683C272.137 97.6349 273.039 97.5681 273.908 97.4678C273.975 97.4344 274.042 97.3342 273.942 97.3342V97.3342Z"
            fill="#42515A"
        />
        <path
            d="M179.766 145.82C187.553 145.82 195.339 145.319 203.126 144.651C210.913 143.982 218.666 143.314 226.453 142.947C228.625 142.846 230.831 142.779 233.036 142.746H236.245C237.045 142.754 237.84 142.606 238.584 142.312L239.921 141.677C240.756 141.242 240.723 139.906 239.921 139.471L238.784 138.87C238.385 138.641 237.94 138.504 237.481 138.469L236.646 138.369C235.655 138.318 234.662 138.318 233.671 138.369L227.923 138.536C224.047 138.703 220.136 138.97 216.26 139.271C208.473 139.872 200.72 140.607 192.933 141.175C188.555 141.51 184.144 141.777 179.766 141.877C179.243 141.886 178.742 142.095 178.369 142.462C177.996 142.829 177.778 143.326 177.761 143.849C177.778 144.375 177.994 144.875 178.367 145.248C178.739 145.62 179.24 145.837 179.766 145.854V145.82Z"
            fill="#3CA9F6"
        />
        <path
            d="M181.471 156.914C187.586 156.948 193.635 156.547 199.718 156.045L208.774 155.31C211.815 155.076 214.856 154.943 217.898 154.675C219.602 154.542 221.306 154.508 222.977 154.341L225.551 154.041C226.52 153.94 227.456 153.573 228.425 153.339C229.394 153.105 229.862 150.766 228.425 150.365L227.422 150.097L226.286 149.763C225.393 149.603 224.486 149.524 223.579 149.529C222.075 149.429 220.538 149.463 219.034 149.496C215.993 149.563 212.952 149.797 209.91 150.031C203.862 150.498 197.813 151.067 191.73 151.434C188.322 151.635 184.879 151.869 181.471 151.969C180.167 152.002 178.931 153.071 178.998 154.442C179.015 155.092 179.281 155.711 179.741 156.171C180.201 156.631 180.82 156.897 181.471 156.914V156.914Z"
            fill="#3CA9F6"
        />
        <path
            d="M237.414 168.81C230.229 168.476 223.044 168.91 215.892 169.579C208.741 170.247 201.656 171.216 194.504 171.817L188.422 172.252C187.453 172.285 186.45 172.386 185.447 172.486C184.38 172.587 183.32 172.755 182.273 172.987C181.37 173.154 181.337 174.725 182.273 174.858C183.207 175.063 184.157 175.186 185.113 175.226C185.982 175.226 186.851 175.259 187.72 175.226C189.525 175.159 191.329 175.025 193.134 174.892C196.71 174.591 200.319 174.223 203.895 173.822C211.013 173.054 218.131 172.152 225.283 171.717C229.327 171.483 233.371 171.383 237.414 171.517C237.769 171.517 238.109 171.376 238.359 171.125C238.61 170.875 238.751 170.535 238.751 170.18C238.751 169.822 238.611 169.479 238.362 169.223C238.112 168.967 237.772 168.819 237.414 168.81Z"
            fill="#42515A"
        />
        <path
            d="M256.162 195.275C247.072 195.041 237.982 195.676 228.892 196.311C219.802 196.946 210.746 197.648 201.622 198.149C196.509 198.416 191.396 198.65 186.25 198.751C185.948 198.751 185.659 198.87 185.446 199.083C185.233 199.296 185.113 199.585 185.113 199.887C185.122 200.185 185.244 200.469 185.455 200.681C185.667 200.892 185.951 201.014 186.25 201.023C195.373 200.923 204.463 200.354 213.52 199.72C222.576 199.085 231.7 198.283 240.823 197.781L256.162 196.946C257.232 196.879 257.265 195.309 256.162 195.275Z"
            fill="#42515A"
        />
        <path
            d="M254.859 187.556C245.802 187.089 236.679 187.657 227.656 188.258C218.633 188.86 209.509 189.561 200.453 190.063C195.34 190.363 190.226 190.597 185.08 190.697C184.779 190.697 184.489 190.817 184.276 191.03C184.063 191.243 183.944 191.532 183.944 191.834C183.952 192.132 184.074 192.416 184.286 192.628C184.497 192.839 184.781 192.961 185.08 192.97C194.17 192.869 203.26 192.301 212.316 191.633C221.373 190.965 230.43 190.23 239.52 189.795C244.633 189.561 249.746 189.394 254.859 189.227C255.929 189.194 255.929 187.623 254.859 187.556V187.556Z"
            fill="#42515A"
        />
        <path
            d="M253.789 179.303C244.699 178.801 235.576 179.303 226.486 179.871C217.396 180.439 208.306 181.141 199.216 181.642C194.103 181.909 188.99 182.143 183.876 182.243C183.58 182.259 183.301 182.384 183.091 182.594C182.881 182.804 182.756 183.083 182.74 183.379C182.74 183.681 182.86 183.97 183.073 184.183C183.286 184.396 183.575 184.516 183.876 184.516C193 184.415 202.123 183.847 211.213 183.212C220.303 182.577 229.393 181.842 238.483 181.475L253.789 180.973C254.892 180.94 254.859 179.369 253.789 179.303Z"
            fill="#42515A"
        />
        <path
            d="M191.63 215.024L194.504 214.924L195.908 214.857H196.576C197.018 214.833 197.45 214.719 197.846 214.523C198.193 214.283 198.488 213.976 198.715 213.62L198.915 213.119C199.003 212.85 199.026 212.564 198.982 212.284C198.921 211.827 198.709 211.404 198.381 211.081C198.153 210.822 197.869 210.618 197.55 210.484C197.232 210.351 196.887 210.292 196.543 210.312H195.807L194.437 210.412L191.63 210.613C191.058 210.632 190.512 210.858 190.093 211.248C189.886 211.453 189.722 211.698 189.613 211.968C189.504 212.238 189.451 212.527 189.458 212.818C189.491 213.955 190.394 215.057 191.63 214.99V215.024Z"
            fill="#3CA9F6"
        />
        <path
            d="M206.334 214.389L210.245 214.222L212.216 214.122C212.679 214.134 213.14 214.078 213.586 213.955L214.556 213.621C215.391 213.286 216.126 212.551 216.093 211.616C216.088 211.16 215.936 210.718 215.658 210.357C215.381 209.995 214.994 209.734 214.556 209.611C213.797 209.383 213.008 209.27 212.216 209.276L210.245 209.41L206.334 209.677C205.718 209.69 205.131 209.941 204.697 210.379C204.474 210.59 204.297 210.843 204.176 211.125C204.055 211.407 203.994 211.71 203.995 212.017C204.062 213.253 205.031 214.422 206.334 214.389Z"
            fill="#3CA9F6"
        />
        <path
            d="M222.209 212.585H227.055C227.434 212.596 227.811 212.528 228.163 212.384C228.514 212.24 228.831 212.024 229.093 211.749C229.461 211.281 229.795 210.78 229.761 210.145C229.748 209.556 229.509 208.994 229.093 208.575C228.756 208.228 228.327 207.985 227.857 207.873C227.528 207.801 227.19 207.778 226.854 207.806L226.319 207.906L226.954 207.806C225.384 208.04 223.779 208.207 222.209 208.374C221.673 208.396 221.163 208.61 220.772 208.976C220.58 209.169 220.428 209.399 220.324 209.651C220.221 209.903 220.169 210.173 220.17 210.446C220.17 210.987 220.385 211.505 220.767 211.887C221.15 212.27 221.668 212.484 222.209 212.484V212.585Z"
            fill="#3CA9F6"
        />
        <path
            d="M236.311 211.415L238.985 211.281C239.595 211.26 240.2 211.171 240.79 211.014L242.06 210.613C242.46 210.487 242.811 210.239 243.063 209.903C243.314 209.568 243.454 209.161 243.463 208.742C243.43 207.94 242.895 207.071 242.06 206.904C241.224 206.737 240.021 206.436 238.985 206.503L236.311 206.637C235.673 206.637 235.061 206.89 234.61 207.341C234.159 207.792 233.905 208.404 233.905 209.043C233.916 209.669 234.167 210.267 234.607 210.713C234.83 210.938 235.096 211.116 235.388 211.236C235.681 211.357 235.995 211.418 236.311 211.415V211.415Z"
            fill="#3CA9F6"
        />
        <path
            d="M196.409 250.812C198.481 250.845 200.519 250.912 202.591 250.845L208.406 250.678L219.769 250.211L223.044 250.11C224.28 250.077 225.484 249.943 226.687 249.81C226.917 249.81 227.138 249.718 227.301 249.555C227.464 249.392 227.555 249.171 227.555 248.941C227.555 248.71 227.464 248.489 227.301 248.326C227.138 248.163 226.917 248.072 226.687 248.072C225.617 248.038 224.514 247.972 223.445 248.005L220.504 248.172L214.689 248.439C210.913 248.607 207.136 248.84 203.327 248.974L196.409 249.375C195.506 249.442 195.473 250.812 196.409 250.812Z"
            fill="#42515A"
        />
        <path
            d="M177.16 119.455C178.897 119.422 180.669 119.489 182.406 119.556L185.046 119.622C186.049 119.656 186.951 119.656 187.887 119.155C188.221 118.987 188.522 118.72 188.823 118.553L188.121 118.854L188.856 118.553C189.405 118.366 189.89 118.03 190.26 117.584C190.615 117.123 190.814 116.561 190.828 115.98C190.793 115.515 190.668 115.061 190.46 114.643C190.108 114.037 189.532 113.593 188.856 113.407L188.121 113.106L188.823 113.407L187.887 112.772C186.985 112.271 186.016 112.271 185.046 112.271L182.406 112.204C180.669 112.137 178.897 112.104 177.16 112.104C176.18 112.12 175.246 112.515 174.553 113.207C173.878 113.895 173.495 114.816 173.483 115.78C173.584 117.751 175.088 119.455 177.16 119.455V119.455Z"
            fill="#99B1C2"
        />
        <path
            d="M316.217 262.441C314.913 258.698 313.209 257.261 311.404 253.719C311.872 250.812 309.366 246.301 306.625 245.967C280.324 243.694 256.697 257.996 232.368 265.147C217.162 271.262 203.494 270.46 188.756 263.677C187.72 263.744 187.92 264.613 187.82 263.677C187.185 261.873 184.11 259.767 184.278 262.441C185.447 266.618 183.442 278.814 189.023 277.979C203.159 275.907 204.697 277.645 218.833 276.007C243.998 274.002 269.396 268.389 294.527 264.746C299.507 265.415 304.152 263.042 309.098 263.978C312.407 264.579 319.859 266.918 316.217 262.441Z"
            fill="#3CA9F6"
        />
        <path
            d="M159.447 97.8688C159.18 100.91 159.314 103.984 159.447 107.025C159.581 110.065 159.748 113.073 159.949 116.114C160.383 122.128 161.051 128.143 161.72 134.125C163.09 145.987 164.895 157.783 166.9 169.512C168.905 181.241 171.044 192.836 173.216 204.465C175.388 216.093 177.728 227.354 179.699 238.816C181.671 250.277 183.476 261.706 184.913 273.234C185.013 273.869 185.046 274.537 185.147 275.172C185.318 275.902 185.576 276.609 185.915 277.277C186.116 277.812 187.052 278.012 187.185 277.277C187.323 276.639 187.413 275.991 187.453 275.339C187.486 274.571 187.285 273.802 187.219 273.067C187.052 271.663 186.851 270.26 186.684 268.856C186.283 265.983 185.915 263.142 185.481 260.269C184.679 254.521 183.776 248.807 182.841 243.06C180.936 231.531 179.031 219.969 176.892 208.474C174.753 196.979 172.548 185.217 170.509 173.555C168.47 161.893 166.566 149.997 164.828 138.168C163.09 126.339 161.686 114.075 160.316 102.012C160.149 100.609 159.949 99.2388 159.681 97.8353C159.667 97.8157 159.648 97.8003 159.625 97.7908C159.603 97.7814 159.578 97.7782 159.554 97.7816C159.53 97.7851 159.508 97.795 159.489 97.8103C159.47 97.8257 159.456 97.8459 159.447 97.8688V97.8688Z"
            fill="#42515A"
        />
        <path
            d="M273.908 97.4678C273.941 100.809 274.443 104.151 274.911 107.426C275.378 110.7 275.713 113.875 276.214 117.083C277.15 123.164 278.152 129.28 279.155 135.395C281.026 146.555 283.165 157.672 285.571 168.743C287.844 179.069 290.317 189.327 293.124 199.486C295.931 209.644 299.24 219.669 302.682 229.627C306.358 240.32 310.402 250.845 314.713 261.271C314.98 261.973 315.214 262.675 315.548 263.343C315.926 263.952 316.362 264.523 316.852 265.047C317.186 265.448 318.021 265.114 317.955 264.579C317.939 263.857 317.838 263.139 317.654 262.441C317.42 261.772 317.086 261.071 316.818 260.402C316.284 259.032 315.715 257.696 315.181 256.326C314.078 253.619 313.042 250.912 311.972 248.206C310.001 243.026 308.062 237.813 306.224 232.567C302.749 222.643 299.808 212.585 296.934 202.46C294.06 192.335 291.553 182.143 289.247 171.884C286.808 161.024 284.669 150.064 282.53 139.137C280.124 126.874 278.019 114.543 275.345 102.347C275.027 100.69 274.625 99.0505 274.142 97.4344C274.075 97.3342 273.875 97.3342 273.908 97.4678V97.4678Z"
            fill="#42515A"
        />
        <path
            d="M317.787 265.248C314.646 263.777 311.037 263.376 307.661 263.009C303.873 262.652 300.063 262.597 296.265 262.842C287.677 263.31 279.188 264.613 270.733 266.25C261.677 267.988 252.62 269.959 243.53 271.697C238.918 272.599 234.273 273.401 229.594 274.203C224.915 275.005 220.604 275.64 216.059 276.041C207.337 276.809 198.481 276.977 189.792 275.64C189.277 275.511 188.751 275.432 188.221 275.406C187.679 275.42 187.141 275.499 186.617 275.64C186.049 275.774 186.049 276.576 186.483 276.843C186.908 277.161 187.368 277.43 187.854 277.645C188.355 277.778 188.923 277.812 189.458 277.912L192.766 278.347C194.938 278.614 197.077 278.781 199.25 278.881C203.65 279.06 208.056 279.004 212.45 278.714C221.767 278.119 231.041 276.97 240.221 275.272C249.378 273.568 258.468 271.597 267.592 269.792C276.047 268.121 284.535 266.451 293.091 265.482C300.409 264.68 307.962 264.312 315.314 265.248C316.116 265.348 316.885 265.415 317.721 265.448C317.821 265.482 317.888 265.281 317.787 265.248V265.248Z"
            fill="#42515A"
        />
        <path
            d="M415.575 277.807C379.081 276.136 329.186 288.868 285.207 290.338C274.312 232.028 263.351 170.242 258.037 111.464C293.294 120.085 334.366 111.899 372.397 111.097C385.13 182.339 389.775 209.907 415.575 277.807Z"
            fill="white"
        />
        <path
            d="M371.983 110.351C370.279 110.117 368.541 110.084 366.803 110.017H361.389C357.646 110.05 353.937 110.217 350.194 110.418C342.574 110.852 334.988 111.454 327.402 112.055C319.816 112.657 312.096 113.258 304.41 113.559C296.723 113.86 289.271 114.094 281.718 113.659C274.412 113.325 267.146 112.386 259.995 110.852L258.692 110.551C258.27 110.507 257.846 110.496 257.422 110.518C257.338 110.522 257.257 110.553 257.191 110.606C257.125 110.659 257.078 110.732 257.056 110.813C257.033 110.895 257.037 110.981 257.067 111.06C257.097 111.139 257.151 111.207 257.222 111.253C257.583 111.476 257.963 111.666 258.358 111.821C258.783 111.957 259.219 112.057 259.661 112.122L262.268 112.69C264.073 113.058 265.877 113.358 267.682 113.659C271.291 114.194 274.934 114.595 278.543 114.862C286.07 115.43 293.623 115.598 301.168 115.363C308.888 115.129 316.608 114.595 324.327 113.993C332.047 113.392 339.566 112.724 347.186 112.089C354.806 111.454 361.924 111.053 369.31 110.685C370.178 110.652 371.081 110.585 371.95 110.485C372.017 110.451 372.083 110.351 371.983 110.351V110.351Z"
            fill="#42515A"
        />
        <path
            d="M277.808 158.837C285.594 158.837 293.381 158.336 301.168 157.668C308.954 156.999 316.708 156.331 324.494 155.963C326.666 155.863 328.872 155.796 331.078 155.763H334.286C335.087 155.771 335.881 155.623 336.625 155.328L337.962 154.694C338.798 154.259 338.764 152.923 337.962 152.488L336.826 151.887C336.426 151.658 335.981 151.521 335.523 151.486L334.687 151.385C333.696 151.335 332.704 151.335 331.713 151.385L325.965 151.552C322.088 151.72 318.178 151.987 314.301 152.288C306.515 152.889 298.761 153.624 290.975 154.192C286.597 154.526 282.186 154.794 277.808 154.894C277.284 154.902 276.784 155.112 276.411 155.479C276.037 155.846 275.82 156.342 275.802 156.866C275.819 157.392 276.036 157.892 276.408 158.265C276.781 158.637 277.281 158.854 277.808 158.871V158.837Z"
            fill="#3CA9F6"
        />
        <path
            d="M279.512 169.931C285.628 169.964 291.677 169.563 297.759 169.062L306.816 168.327C309.857 168.093 312.898 167.96 315.939 167.692C317.644 167.559 319.348 167.525 321.019 167.358L323.592 167.057C324.561 166.957 325.497 166.589 326.466 166.356C327.435 166.122 327.903 163.783 326.466 163.382L325.464 163.114L324.327 162.78C323.434 162.619 322.528 162.541 321.62 162.546C320.117 162.446 318.579 162.479 317.075 162.513C314.034 162.58 310.993 162.814 307.952 163.047C301.903 163.515 295.854 164.083 289.772 164.451C286.363 164.651 282.921 164.885 279.512 164.986C278.209 165.019 276.972 166.088 277.039 167.458C277.056 168.109 277.322 168.728 277.782 169.188C278.242 169.648 278.862 169.914 279.512 169.931V169.931Z"
            fill="#3CA9F6"
        />
        <path
            d="M335.456 181.827C328.271 181.493 321.085 181.927 313.934 182.595C306.782 183.264 299.697 184.233 292.545 184.834L286.463 185.269C285.494 185.302 284.491 185.402 283.489 185.503C282.421 185.604 281.361 185.772 280.314 186.004C279.412 186.171 279.378 187.741 280.314 187.875C281.249 188.08 282.199 188.203 283.155 188.243C284.024 188.243 284.893 188.276 285.761 188.243C287.566 188.176 289.371 188.042 291.175 187.909C294.751 187.608 298.36 187.24 301.936 186.839C309.055 186.071 316.173 185.168 323.325 184.734C327.368 184.5 331.412 184.4 335.456 184.534C335.81 184.534 336.15 184.393 336.401 184.142C336.652 183.891 336.792 183.551 336.792 183.197C336.793 182.839 336.653 182.496 336.403 182.24C336.153 181.984 335.813 181.836 335.456 181.827Z"
            fill="#42515A"
        />
        <path
            d="M354.204 208.292C345.114 208.058 336.024 208.693 326.934 209.328C317.844 209.963 308.787 210.665 299.664 211.166C294.551 211.433 289.438 211.667 284.291 211.767C283.99 211.767 283.701 211.887 283.488 212.1C283.274 212.313 283.155 212.602 283.155 212.904C283.163 213.202 283.286 213.486 283.497 213.698C283.708 213.909 283.992 214.031 284.291 214.04C293.414 213.939 302.504 213.371 311.561 212.736C320.618 212.102 329.741 211.3 338.865 210.798L354.204 209.963C355.273 209.896 355.307 208.326 354.204 208.292Z"
            fill="#42515A"
        />
        <path
            d="M352.901 200.573C343.844 200.105 334.721 200.673 325.697 201.275C316.674 201.876 307.551 202.578 298.494 203.079C293.381 203.38 288.268 203.614 283.121 203.714C282.82 203.714 282.531 203.834 282.318 204.047C282.105 204.26 281.985 204.549 281.985 204.85C281.994 205.149 282.116 205.433 282.327 205.644C282.539 205.856 282.823 205.978 283.121 205.987C292.211 205.886 301.301 205.318 310.358 204.65C319.415 203.982 328.471 203.246 337.561 202.812C342.674 202.578 347.787 202.411 352.901 202.244C353.97 202.211 353.97 200.64 352.901 200.573V200.573Z"
            fill="#42515A"
        />
        <path
            d="M351.831 192.319C342.741 191.818 333.618 192.32 324.528 192.888C315.438 193.456 306.348 194.157 297.258 194.659C292.144 194.926 287.031 195.16 281.918 195.26C281.622 195.276 281.342 195.401 281.133 195.611C280.923 195.82 280.798 196.1 280.782 196.396C280.782 196.698 280.902 196.987 281.115 197.2C281.328 197.413 281.617 197.532 281.918 197.532C291.042 197.432 300.165 196.864 309.255 196.229C318.345 195.594 327.435 194.859 336.525 194.492L351.831 193.99C352.934 193.957 352.901 192.386 351.831 192.319Z"
            fill="#42515A"
        />
        <path
            d="M289.672 228.041L292.546 227.94L293.949 227.874H294.618C295.059 227.85 295.491 227.736 295.888 227.539C296.234 227.299 296.53 226.993 296.756 226.637L296.957 226.136C297.044 225.867 297.067 225.58 297.024 225.301C296.963 224.844 296.751 224.421 296.422 224.098C296.194 223.838 295.91 223.634 295.592 223.501C295.273 223.368 294.929 223.309 294.584 223.329H293.849L292.479 223.429L289.672 223.63C289.099 223.649 288.553 223.875 288.134 224.265C287.927 224.469 287.764 224.714 287.655 224.984C287.545 225.255 287.493 225.544 287.499 225.835C287.533 226.971 288.435 228.074 289.672 228.007V228.041Z"
            fill="#3CA9F6"
        />
        <path
            d="M304.376 227.406L308.286 227.239L310.258 227.139C310.72 227.151 311.182 227.095 311.628 226.971L312.597 226.637C313.433 226.303 314.168 225.568 314.134 224.632C314.13 224.177 313.977 223.735 313.7 223.374C313.423 223.012 313.036 222.75 312.597 222.627C311.838 222.4 311.05 222.287 310.258 222.293L308.286 222.427L304.376 222.694C303.76 222.707 303.172 222.958 302.738 223.396C302.516 223.606 302.338 223.86 302.218 224.142C302.097 224.424 302.035 224.727 302.037 225.033C302.103 226.27 303.073 227.439 304.376 227.406Z"
            fill="#3CA9F6"
        />
        <path
            d="M320.25 225.602H325.096C325.475 225.613 325.853 225.544 326.204 225.401C326.555 225.257 326.872 225.04 327.135 224.766C327.502 224.298 327.836 223.797 327.803 223.162C327.789 222.572 327.55 222.01 327.135 221.592C326.798 221.245 326.369 221.001 325.898 220.89C325.569 220.818 325.231 220.795 324.896 220.823L324.361 220.923L324.996 220.823C323.425 221.057 321.821 221.224 320.25 221.391C319.715 221.413 319.205 221.627 318.813 221.993C318.621 222.186 318.469 222.416 318.366 222.668C318.263 222.92 318.21 223.19 318.212 223.463C318.212 224.004 318.426 224.522 318.809 224.904C319.191 225.287 319.71 225.501 320.25 225.501V225.602Z"
            fill="#3CA9F6"
        />
        <path
            d="M334.353 224.432L337.026 224.298C337.636 224.277 338.242 224.187 338.831 224.031L340.101 223.63C340.501 223.504 340.852 223.256 341.104 222.92C341.356 222.584 341.496 222.178 341.505 221.759C341.471 220.957 340.937 220.088 340.101 219.921C339.266 219.754 338.062 219.453 337.026 219.52L334.353 219.653C333.715 219.653 333.103 219.907 332.652 220.358C332.2 220.809 331.947 221.421 331.947 222.059C331.957 222.686 332.209 223.284 332.649 223.73C332.871 223.955 333.137 224.133 333.43 224.253C333.723 224.374 334.036 224.435 334.353 224.432V224.432Z"
            fill="#3CA9F6"
        />
        <path
            d="M294.45 263.829C296.522 263.862 298.561 263.929 300.633 263.862L306.448 263.695L317.81 263.227L321.085 263.127C322.322 263.094 323.525 262.96 324.728 262.826C324.959 262.826 325.18 262.735 325.343 262.572C325.505 262.409 325.597 262.188 325.597 261.958C325.597 261.727 325.505 261.506 325.343 261.343C325.18 261.18 324.959 261.089 324.728 261.089C323.659 261.055 322.556 260.989 321.486 261.022L318.546 261.189L312.731 261.456C308.954 261.623 305.178 261.857 301.368 261.991L294.45 262.392C293.548 262.459 293.515 263.829 294.45 263.829Z"
            fill="#42515A"
        />
        <path
            d="M275.201 132.472C276.939 132.439 278.71 132.506 280.448 132.572L283.088 132.639C284.091 132.673 284.993 132.673 285.929 132.171C286.263 132.004 286.564 131.737 286.865 131.57L286.163 131.871L286.898 131.57C287.446 131.383 287.932 131.047 288.302 130.601C288.656 130.14 288.855 129.578 288.87 128.997C288.835 128.532 288.71 128.078 288.502 127.66C288.15 127.054 287.574 126.61 286.898 126.424L286.163 126.123L286.865 126.424L285.929 125.789C285.026 125.288 284.057 125.288 283.088 125.288L280.448 125.221C278.71 125.154 276.939 125.121 275.201 125.121C274.222 125.137 273.288 125.532 272.595 126.223C271.92 126.912 271.537 127.833 271.525 128.796C271.625 130.768 273.129 132.472 275.201 132.472V132.472Z"
            fill="#99B1C2"
        />
        <path
            d="M414.258 275.457C412.955 271.715 411.251 270.278 409.446 266.736C409.914 263.829 407.407 259.318 404.667 258.984C378.366 256.711 354.739 271.013 330.41 278.164C315.204 284.279 301.535 283.477 286.797 276.694C285.761 276.761 285.962 277.63 285.862 276.694C285.227 274.889 282.152 272.784 282.319 275.457C283.489 279.634 281.484 291.831 287.065 290.996C301.201 288.924 302.738 290.662 316.875 289.024C342.039 287.019 367.438 281.405 392.569 277.763C397.549 278.431 402.194 276.059 407.14 276.995C410.448 277.596 417.901 279.935 414.258 275.457Z"
            fill="#3CA9F6"
        />
        <path
            d="M257.489 110.886C257.221 113.926 257.355 117.001 257.489 120.041C257.623 123.082 257.79 126.09 257.99 129.131C258.425 135.145 259.093 141.16 259.761 147.142C261.132 159.004 262.936 170.8 264.941 182.529C266.946 194.258 269.085 205.853 271.258 217.481C273.43 229.11 275.769 240.371 277.741 251.833C279.713 263.294 281.517 274.722 282.954 286.251C283.054 286.886 283.088 287.554 283.188 288.189C283.359 288.919 283.617 289.626 283.957 290.294C284.157 290.829 285.093 291.029 285.227 290.294C285.365 289.656 285.454 289.008 285.494 288.356C285.528 287.587 285.327 286.819 285.26 286.084C285.093 284.68 284.893 283.277 284.725 281.873C284.324 279 283.957 276.159 283.522 273.285C282.72 267.538 281.818 261.824 280.882 256.076C278.977 244.548 277.072 232.986 274.934 221.491C272.795 209.996 270.589 198.234 268.551 186.572C266.512 174.91 264.607 163.014 262.869 151.185C261.132 139.356 259.728 127.092 258.358 115.029C258.191 113.626 257.99 112.256 257.723 110.852C257.708 110.833 257.689 110.817 257.667 110.808C257.644 110.798 257.62 110.795 257.596 110.798C257.572 110.802 257.549 110.812 257.53 110.827C257.511 110.843 257.497 110.863 257.489 110.886V110.886Z"
            fill="#42515A"
        />
        <path
            d="M371.95 110.485C371.983 113.826 372.484 117.168 372.952 120.442C373.42 123.717 373.754 126.892 374.256 130.1C375.191 136.181 376.194 142.296 377.197 148.411C379.068 159.572 381.207 170.688 383.613 181.76C385.886 192.086 388.359 202.344 391.166 212.502C393.973 222.661 397.282 232.686 400.724 242.643C404.4 253.336 408.444 263.862 412.755 274.288C413.022 274.99 413.256 275.691 413.59 276.36C413.968 276.969 414.404 277.54 414.893 278.064C415.228 278.465 416.063 278.131 415.996 277.596C415.981 276.874 415.88 276.156 415.695 275.457C415.462 274.789 415.127 274.087 414.86 273.419C414.325 272.049 413.757 270.712 413.222 269.342C412.12 266.636 411.084 263.929 410.014 261.222C408.042 256.043 406.104 250.83 404.266 245.584C400.791 235.659 397.85 225.601 394.976 215.476C392.102 205.352 389.595 195.16 387.289 184.901C384.85 174.041 382.711 163.081 380.572 152.154C378.166 139.89 376.06 127.56 373.387 115.363C373.069 113.707 372.667 112.067 372.184 110.451C372.117 110.351 371.916 110.351 371.95 110.485V110.485Z"
            fill="#42515A"
        />
        <path
            d="M415.829 278.264C412.687 276.794 409.078 276.393 405.703 276.026C401.915 275.669 398.104 275.613 394.307 275.859C385.718 276.326 377.23 277.63 368.775 279.267C359.718 281.005 350.661 282.976 341.571 284.714C336.96 285.616 332.314 286.418 327.636 287.22C322.957 288.022 318.646 288.657 314.101 289.058C305.378 289.826 296.522 289.993 287.833 288.657C287.319 288.527 286.793 288.449 286.263 288.423C285.721 288.437 285.182 288.516 284.659 288.657C284.09 288.79 284.09 289.592 284.525 289.86C284.95 290.178 285.41 290.447 285.895 290.662C286.396 290.795 286.965 290.829 287.499 290.929L290.808 291.363C292.98 291.631 295.119 291.798 297.291 291.898C301.691 292.076 306.098 292.021 310.492 291.731C319.809 291.136 329.082 289.987 338.263 288.289C347.42 286.585 356.51 284.613 365.633 282.809C374.088 281.138 382.577 279.467 391.132 278.498C398.451 277.696 406.004 277.329 413.356 278.264C414.158 278.365 414.927 278.432 415.762 278.465C415.862 278.498 415.929 278.298 415.829 278.264V278.264Z"
            fill="#42515A"
        />
    </svg>
);

export default IaqCertificateIllustration;
