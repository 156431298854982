/* eslint max-len: ["error", 1000] */
import React from 'react';

const IconBatteryLow = (): React.ReactElement => (
    <svg
        version="1.1"
        id="batteryLow"
        className="battery-icon"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 30 15"
    >
        <path
            style={{ fill: '#95A2A6' }}
            d="M28.1,2.8h-1.9C26.2,1.3,25,0,23.4,0H2.8C1.3,0,0,1.3,0,2.8v9.4C0,13.7,1.3,15,2.8,15h20.6 c1.5,0,2.8-1.3,2.8-2.8h1.9c1,0,1.9-0.8,1.9-1.9V4.7C30,3.7,29.1,2.8,28.1,2.8z M24.3,12.2c0,0.5-0.4,0.9-0.9,0.9H2.8 c-0.5,0-0.9-0.4-0.9-0.9V2.8c0-0.5,0.4-0.9,0.9-0.9h20.6c0.5,0,0.9,0.4,0.9,0.9V12.2z M28.1,10.3h-1.9V4.7h1.9V10.3z"
        />
        <path
            style={{ fill: '#D32E2F' }}
            d="M8.7,11.3h-4c-0.5,0-0.9-0.4-0.9-0.9V4.6c0-0.5,0.4-0.9,0.9-0.9H6c1,0,1.5,0.9,1.5,0.9l2.3,4.7 C10.2,10.3,9.8,11.3,8.7,11.3z"
        />
    </svg>
);

export default IconBatteryLow;
