import React from 'react';
import { connect } from 'react-redux';
import StandAloneSpinner from '../../img/StandAloneSpinner';
import { HomeRadonValues, HomeReportResponse } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import GeneratePdf from './PDF/GeneratePDF';

type Props = {
    reportData: HomeReportResponse | undefined;
    loading: boolean;
};

const HomeReportResult = (props: Props): React.ReactElement => {
    const { reportData, loading } = props;
    if (!reportData || loading) {
        return (
            <div className="modal__content__spinner">
                <StandAloneSpinner />
            </div>
        );
    }

    const {
        name,
        address,
        countryCode,
        estimatedAnnualAverage,
        buildingType,
        buildingYear,
        duration,
        unit,
        ended,
        floor,
        annualAverageCorrection,
        radonLongTermAvg,
        referenceLevel,
        roomType,
        reportId,
        serialNumber,
        standardDeviation,
        requiredMeasurementPeriodMonths,
    } = reportData;
    const radonValues: HomeRadonValues = {
        annualAverageCorrection: annualAverageCorrection
            ? Number(annualAverageCorrection.toFixed(2))
            : annualAverageCorrection,
        radonLongTermAvg,
        referenceLevel: referenceLevel ? Number(referenceLevel.toFixed(2)) : referenceLevel,
        estimatedAnnualAverage: estimatedAnnualAverage
            ? Number(estimatedAnnualAverage.toFixed(2))
            : estimatedAnnualAverage,
        standardDeviation,
        unit,
    };

    return (
        <GeneratePdf
            name={name}
            address={address}
            buildingType={buildingType}
            buildingYear={buildingYear}
            countryCode={countryCode}
            roomType={roomType}
            floor={floor}
            daysMeasured={duration}
            serialNumber={serialNumber}
            endDate={ended}
            radonValues={radonValues}
            reportId={reportId}
            dateFormat={unit === 'bq' ? 'EUR' : 'US'}
            requiredMeasurementPeriodMonths={requiredMeasurementPeriodMonths}
        />
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        generateHomeRadonReport: { reportData, loading },
    } = state;
    return {
        reportData,
        loading,
    };
};

export default connect(mapStateToProps)(HomeReportResult);
