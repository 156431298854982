import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { ServicePeriod } from '../../../../models/commonEnums';
import { SubscriptionSeats } from '../../../../models/subscriptionModels';
import styles from './AddSeats.module.scss';
import AddSeatsTable from './AddSeatsTable';

export type ParentProps = {
    servicePeriod: ServicePeriod;
    seatsPerDevice: SubscriptionSeats[];
    updateSeats: (newServicePeriod: ServicePeriod, newSeats: SubscriptionSeats[]) => void;
};

export const AddSeatsComponent = ({ servicePeriod, seatsPerDevice, updateSeats }: ParentProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [readMore, setReadMore] = useState(false);
    const updateSeatsCount = (
        updatedSeats: {
            deviceType: DeviceTypeNames;
            deployedSeats: number;
            seats: number;
        },
        newServicePeriod: ServicePeriod
    ): void => {
        const { deviceType } = updatedSeats;
        const updatedSeatsPerDevice = seatsPerDevice;
        const indexOfDevice = updatedSeatsPerDevice.findIndex(element => element.deviceType === deviceType);
        updatedSeatsPerDevice[indexOfDevice] = updatedSeats;
        updateSeats(newServicePeriod, [...updatedSeatsPerDevice]);
    };

    return (
        <div className={styles.ratePlanSection}>
            <div className={styles.header}>{txt('Subscription.AddSeatsHeader')}</div>
            <AddSeatsTable
                seatsPerDevice={seatsPerDevice}
                updateSeats={updateSeatsCount}
                servicePeriod={servicePeriod}
            />
            {readMore ? (
                <div className={styles.text}>
                    {txt('Subscription.AddSeatsDescription')}
                    <button className={styles.textButton} type="button" onClick={(): void => setReadMore(false)}>
                        {txt('ReadLess')}.
                    </button>
                </div>
            ) : (
                <div className={styles.text}>
                    {txt('Subscription.AddSeatsDescriptionShort')}
                    <button className={styles.textButton} type="button" onClick={(): void => setReadMore(true)}>
                        {txt('ReadMore')}.
                    </button>
                </div>
            )}
        </div>
    );
};

export default AddSeatsComponent;
