import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import { customPeriodName } from 'commons/src/constants';
import { DashboardSensorData } from 'commons/src/models/commonTypeScript';
import { getFetchAttributes } from 'commons/src/sagas/DevicePageSagas/fetchCustomDeviceSegment';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import { setFetchInterval } from 'commons/src/sagas/pollData/pollDeviceData';
import { FetchOutdoorData, fetchOutdoorDataSuccess, OutdoorDataActions } from '../actions/outdoorDataActions';
import getOutdoorGraphData from '../api/outdoorDataAPI';
import { OutdoorSensorData } from '../models/outdoorDataModels';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type FetchOutdoorDataReturnType = Generator<
    | RequestActionType
    | CallEffect<{ from: string; to: string; lastDataOlderThanResolutionDuration: boolean }>
    | CallEffect<{ sensors: OutdoorSensorData[] }>
    | PutEffect,
    void,
    {
        from: string;
        to: string;
        lastDataOlderThanResolutionDuration: boolean;
    } & { sensors: OutdoorSensorData[] }
>;

export function* fetchOutdoorData({ locationId, selectedInterval }: FetchOutdoorData): FetchOutdoorDataReturnType {
    try {
        const prevDeviceData = {};
        let fetchAttrs: { from: string; to: string; lastDataOlderThanResolutionDuration?: boolean };
        if (selectedInterval.name === customPeriodName && selectedInterval.startDate && selectedInterval.endDate) {
            fetchAttrs = getFetchAttributes(selectedInterval);
        } else {
            fetchAttrs = yield call(setFetchInterval, selectedInterval, prevDeviceData as DashboardSensorData);
        }
        const outdoorData = yield call(getOutdoorGraphData, locationId, {
            from: fetchAttrs.from,
            to: fetchAttrs.to,
            resolution: selectedInterval.resolution,
        });
        yield put(fetchOutdoorDataSuccess(outdoorData.sensors, selectedInterval.name, locationId));
        yield put(requestSuccess(RequestType.FetchOutdoorData, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.FetchOutdoorData, RequestActionType.Error));
    }
}
export default function* outdoorDataSagas(): Generator {
    yield all([takeEvery(OutdoorDataActions.FetchOutdoorDataInit, fetchOutdoorData)]);
}
