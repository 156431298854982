import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { mobileMax } from '../../constants';
import { ComponentTab } from '../../models/menuModels';
import SubMenuMobile from './ComponentTabMobileMenu';

type ParentProps = {
    tabs: ComponentTab[];
    activeTab: number;
    setActiveTab: (activeTab: number) => void;
};

export type Props = ParentProps;

export const ComponentTabNavigationComponent = ({ tabs, setActiveTab, activeTab }: Props): React.ReactElement => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [mobileMenuOpen, setMobileMnuOpen] = useState(false);

    const onWindowSizeChange = (): void => {
        setWidth(window.innerWidth);
    };

    useEffect((): (() => void) => {
        window.addEventListener('resize', onWindowSizeChange);
        return (): void => window.removeEventListener('resize', onWindowSizeChange);
    }, []);
    const isMobile = width <= mobileMax;

    const selectTab = (event: React.SyntheticEvent, newTab: number): void => {
        setActiveTab(newTab);
    };

    return (
        <>
            {mobileMenuOpen && <div className="menu-modal menu-modal--component-tab" />}
            {isMobile ? (
                <SubMenuMobile
                    tabs={tabs}
                    setMenuOpen={setMobileMnuOpen}
                    menuOpen={mobileMenuOpen}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            ) : (
                <div className="page-tabs page-tabs--centered">
                    <Tabs
                        textColor="inherit"
                        indicatorColor="secondary"
                        value={activeTab}
                        onChange={selectTab}
                        aria-label="inline tabs"
                        TabIndicatorProps={{ sx: { height: '3px' } }}
                    >
                        {tabs.map((tab, index) => (
                            <Tab
                                key={tab.id}
                                value={index}
                                {...{ [`data-tab-${tab.testAttr}`]: true }}
                                label={tab.title}
                            />
                        ))}
                    </Tabs>
                </div>
            )}
        </>
    );
};

export default ComponentTabNavigationComponent;
