import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column, Row } from 'react-table';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { PartnerOfflineDevicesResponse } from '../../../actions/partnerActions';
import { BuildingSummaryHealthStatus } from '../../../models/buildingModels';
import { DisplayValue } from '../../status/buildingsStatus/components/DesktopTable/DesktopTable';
import HealthStatusTable from '../../status/buildingsStatus/components/DesktopTable/HealthStatusTable';
import styles from './PartnerDesktopTable.module.scss';
import RedirectToDashboardButton from './RedirectToDashboardButton';

type ParentProps = {
    data: PartnerOfflineDevicesResponse[];
    numberOfDevicesInOrg: number;
    numberOfCustomers: number;
};

type RowData = {
    userGroupId: string;
    customerName: string;
    totalLocations: number;
    hubs: number;
    sensorDevices: number;
    buildings: number;
    offlineLocations: BuildingSummaryHealthStatus[];
    display: {
        buildings: string;
        sensorDevices: string;
        hubs: string;
    };
};

const toDisplayFraction = (nominator: number, denominator: number): string =>
    nominator === 0 ? '0' : `${nominator}/${denominator}`;

const toPercentage = (nominator: number, denominator: number): number =>
    nominator === 0 ? 0 : nominator / denominator;

const toRowData = ({
    userGroupId,
    customerName,
    totalLocations,
    offlineLocations,
}: PartnerOfflineDevicesResponse): RowData => {
    const totalOfflineHubs = offlineLocations.map(location => location.offlineHubs).reduce((acc, val) => acc + val, 0);
    const totalHubs = offlineLocations.map(location => location.hubs).reduce((acc, val) => acc + val, 0);
    const totalOfflineSensorDevices = offlineLocations
        .map(location => location.offlineSensorDevices)
        .reduce((acc, val) => acc + val, 0);
    const totalSensorDevices = offlineLocations
        .map(location => location.sensorDevices)
        .reduce((acc, val) => acc + val, 0);
    return {
        userGroupId,
        customerName,
        totalLocations,
        offlineLocations,
        buildings: toPercentage(offlineLocations.length, totalLocations),
        hubs: toPercentage(totalOfflineHubs, totalHubs),
        sensorDevices: toPercentage(totalOfflineSensorDevices, totalSensorDevices),
        display: {
            buildings: toDisplayFraction(offlineLocations.length, totalLocations),
            hubs: toDisplayFraction(totalOfflineHubs, totalHubs),
            sensorDevices: toDisplayFraction(totalOfflineSensorDevices, totalSensorDevices),
        },
    };
};

const ExpandRow = ({ row, cell }: { row: Row; cell: Cell }): React.ReactElement => {
    return (
        <span className={styles.alignedCellContent}>
            <span {...row.getToggleRowExpandedProps()} className={styles.expandTableElement}>
                <MaterialIcon name={row.isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'} />
            </span>
            {cell.value}
        </span>
    );
};

const RedirectToDashboard = ({ cell }: { cell: Cell }): React.ReactElement => (
    <RedirectToDashboardButton userGroupId={cell.value} />
);

type Props = ParentProps;

export default function PartnerDesktopTable({
    data,
    numberOfDevicesInOrg,
    numberOfCustomers,
}: Props): React.ReactElement {
    const { t: txt } = useTranslation();
    const numberOfOfflineData = data.filter(customer => customer.offlineLocations.length > 0).length;
    const columns = useMemo<Column[]>(
        () => [
            {
                Header: `${txt('Partner.Customer')} (${numberOfOfflineData}/${numberOfCustomers})`,
                accessor: 'customerName',
                className: styles.textCell,
                Cell: ExpandRow,
            },
            {
                Header: txt('BuildingStatus.OfflineBuildingsHeader'),
                accessor: 'buildings',
                Cell: DisplayValue,
                className: styles.fractionCell,
            },
            {
                Header: txt('BuildingStatus.OfflineHubsHeader'),
                accessor: 'hubs',
                Cell: DisplayValue,
                className: styles.fractionCell,
            },
            {
                Header: txt('BuildingStatus.OfflineSensorDevicesHeader'),
                accessor: 'sensorDevices',
                Cell: DisplayValue,
                className: styles.fractionCell,
            },
            {
                Header: '',
                accessor: 'userGroupId',
                Cell: RedirectToDashboard,
                className: styles.partnerButtonCell,
            },
        ],
        []
    );

    const offlineData = data.filter(customer => customer.offlineLocations.length > 0);
    const dataSorted = useMemo(
        () =>
            offlineData.sort(
                (a, b) => b.offlineLocations.length / b.totalLocations - a.offlineLocations.length / a.totalLocations
            ),
        [offlineData]
    );
    const tableData = useMemo<RowData[]>(() => dataSorted.map(toRowData), [dataSorted]);

    const emptyTableText = (): string => {
        if (numberOfDevicesInOrg > 0) return 'BuildingStatus.NoOfflineBuildings';
        if (data.length === 0) return 'BuildingStatus.NoCustomers';
        return 'BuildingStatus.NoDevicesInBuildings';
    };

    return <HealthStatusTable data={tableData} columns={columns} styles={styles} emptyTableText={emptyTableText()} />;
}
