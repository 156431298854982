import { createSelector } from '@reduxjs/toolkit';
import { BuildingType } from 'commons/src/models/commonTypeScript';
import { Store } from '../index';

const getBuildingDetails = (state: Store, buildingId: string): BuildingType => {
    return state.buildings.buildings[buildingId] || ({} as BuildingType);
};
export const buildingSelector = createSelector([getBuildingDetails], (building: BuildingType) => building);

export default buildingSelector;
