import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import {
    ActivatedClientResponse,
    CreateClientPayload,
    LookupDomainResponse,
    SingleSignOnClient,
    SingleSignOnResponse,
    VerifyClientDomainResponse,
} from '../models/common';

export const createClient = async (payload: CreateClientPayload): Promise<SingleSignOnClient> =>
    fetch(`/federated-client`, { method: 'POST', headers: await getFetchHeaders(), body: JSON.stringify(payload) });

export const editClient = async (payload: CreateClientPayload, singleSignOnId: string): Promise<void> =>
    fetch(`/federated-client/${singleSignOnId}`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(payload),
    });

export const toggleActiveClient = async (singleSignOnId: string, activate: boolean): Promise<ActivatedClientResponse> =>
    fetch(`/federated-client/${singleSignOnId}/toggle-active?activate=${activate}`, {
        method: 'POST',
        headers: await getFetchHeaders(),
    });

export const getClientInfo = async (): Promise<SingleSignOnResponse> =>
    fetch(`/federated-client`, { method: 'GET', headers: await getFetchHeaders() });

export const deleteClient = async (singleSignOnId: string): Promise<void> =>
    fetch(`/federated-client/${singleSignOnId}`, { method: 'DELETE', headers: await getFetchHeaders() });

export const verifyClientDomain = async (singleSignOnId: string): Promise<VerifyClientDomainResponse> =>
    fetch(`/federated-client/${singleSignOnId}/verify-domain`, { method: 'POST', headers: await getFetchHeaders() });

export const lookupSsoDomain = async (emailDomain: string): Promise<LookupDomainResponse> =>
    fetch(`/federated-client/domain-lookup?emailDomain=${emailDomain}`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
