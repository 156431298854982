import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { dateFormats } from 'commons/src/constants';
import { Resolution, SensorTypes, TimePeriod } from 'commons/src/models/commonEnums';
import { BuildingInsight } from '../../../models/buildingModels';
import { getChartDataWithStartAndEndDate } from './buildingInsightCommonFunctions';
import generateConfig from './BuildingInsightGraphConfig';

export type Props = {
    selectedDevice?: string;
    insight: BuildingInsight;
    selectedPeriod: {
        toDate: string;
        fromDate: string;
        resolution: Resolution;
        name: TimePeriod;
    };
    timeZone?: string;
    dateFormat: keyof typeof dateFormats;
    selectedHighThreshold: string;
    selectedLowThreshold?: string;
    language?: string;
    sensor: SensorTypes;
    allowGraphClick: boolean;
};

const DeviceBreakDown = ({
    insight,
    selectedPeriod,
    selectedDevice,
    timeZone,
    dateFormat,
    selectedHighThreshold,
    selectedLowThreshold,
    language,
    allowGraphClick,
    sensor,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const {
        totalDeviceData: { devicesOverTime },
    } = insight;
    const insightForDevice = selectedDevice ? devicesOverTime[selectedDevice] : [];
    const underThresholdBreaches: [number, number][] = [];

    const chartDataFormatted: [number, number][] = insightForDevice.map(data => {
        const dateTime = timeZone ? moment.utc(data.date).tz(timeZone) : moment(data.date);
        underThresholdBreaches.push([dateTime.unix() * 1000, data.underThresholdsTime]);
        return [dateTime.unix() * 1000, data.overThresholdsTime];
    });
    const chartDataWithStartAndEndDate = getChartDataWithStartAndEndDate(
        chartDataFormatted,
        selectedPeriod.fromDate,
        selectedPeriod.toDate,
        timeZone
    );

    const onGraphClick = (): void => {
        // do nothing;
    };

    const isHourFormat = selectedPeriod.resolution === Resolution.hour;
    const graphConfig = generateConfig({
        chartData: chartDataWithStartAndEndDate,
        chartDataLowerThresholdBreaches: underThresholdBreaches,
        dateFormat,
        onClick: onGraphClick,
        displayLegends: false,
        isHourFormat,
        highValue: selectedHighThreshold,
        lowValue: selectedLowThreshold || undefined,
        chartHeight: 125,
        text: txt,
        allowGraphClick,
        graphId: `deviceBreakDown-${sensor}-${selectedDevice}`,
        language,
        timeZone,
    });

    return <HighchartsReact highcharts={Highcharts} options={graphConfig} />;
};

export default DeviceBreakDown;
