import React, { useState } from 'react';
import { mapDeviceType } from '../../../commonFunctions';
import AccordionButton from '../../../components/buttons/AccordionButton';
import { AnyDeviceType, DeviceType } from '../../../models/commonTypeScript';
import LocationDevices from './LocationDevices';

export type Props = {
    group: { type: string; devices: DeviceType[] };
    url?: string;
};

export const GroupOfDevicesComponent = ({ group, url }: Props): React.ReactElement => {
    const [sectionOpen, setSectionOpen] = useState(true);
    return (
        <div className="page-section--full-width">
            <AccordionButton
                expanded={sectionOpen}
                title={`${mapDeviceType(group.type as AnyDeviceType)}FullName`}
                onClick={(): void => setSectionOpen(!sectionOpen)}
            />

            {sectionOpen && (
                <div className="page-wrapper">
                    <LocationDevices devices={group.devices} url={url} />
                </div>
            )}
        </div>
    );
};

export default GroupOfDevicesComponent;
