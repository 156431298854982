import React from 'react';
import * as Sentry from '@sentry/react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { getDashboardPath } from '../../commonFunctions';
import { groupRequiredRole } from '../../features/authorization/userRoleAboveRequiredLevel';
import { Role } from '../../models/commonEnums';
import { Store } from '../../reducers';
import MaterialIcon from '../MaterialIcon';

type StateProps = {
    userGroupId?: string;
    userRole?: Role;
};

type Props = StateProps;

const ForbiddenAccessComponent = ({ userRole, userGroupId }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [searchParams] = useSearchParams();
    const requiredRoleLevel = searchParams.get('requiredRoleLevel') || 'unknown';
    const allowedRoles = ((requiredRoleLevel && groupRequiredRole[requiredRoleLevel]) || []).join(',');
    if (Sentry) {
        Sentry.captureMessage(
            `Redirected to 403 due to user being: ${userRole}, role not in ${allowedRoles} for ${userGroupId}`,
            'warning'
        );
    }
    return (
        <div className="page-wrapper">
            <div className="no-content-page not-found">
                <div className="no-content-page__content">
                    <MaterialIcon extraClass="no-content-page__icon" name="lock" />
                    <p className="no-content-page__title no-content-page__title--medium-font">
                        {txt('ForbiddenPage.Title')}
                    </p>
                    <p className="no-content-page__text">
                        {txt('ForbiddenPage.AccessRole', { role: txt(`AuthorizationRole.${userRole}`) })}
                    </p>
                    <p className="no-content-page__text">{txt('ForbiddenPage.ContactAdmin')}</p>
                    <p className="no-content-page__text no-content-page__text--ultra-thin">
                        <Trans i18nKey="ForbiddenPage.GoToDashboard">
                            <Link className="no-content-page__text--link" to={getDashboardPath()} />
                        </Trans>
                    </p>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const { selectedGroup } = store.userSettings;
    return {
        userGroupId: selectedGroup && selectedGroup.id,
        userRole: selectedGroup && selectedGroup.role,
    };
};

export default connect(mapStateToProps)(ForbiddenAccessComponent);
