import dotProp from 'dot-prop-immutable';
import { LOGOUT_SUCCESS, LogOutSuccess } from '../actions/LoginAndRegisterActions';
import { OutdoorActionType, OutdoorReducerActions } from '../actions/OutdoorActions';
import { OutdoorData } from '../models/commonTypeScript';

const initialState = {
    locations: {},
};

export interface OutdoorAirInfoStore {
    locations: {
        [id: string]: {
            locationId: string;
            content: OutdoorData;
        };
    };
}

export default (state = initialState, action: OutdoorReducerActions | LogOutSuccess): OutdoorAirInfoStore => {
    switch (action.type) {
        case OutdoorActionType.FetchOutdoorAirInfoSuccess:
            return {
                ...state,
                locations: dotProp.set(state.locations, action.payload.locationId, action.payload),
            };
        case LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
};
