import React from 'react';

const SmartLinkIcon = (): React.ReactElement => {
    return (
        <svg className="svg-icon-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <title>Smartlink icon</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5 4.5C14.5 5.70948 13.6411 6.71836 12.5 6.94999V10H14C14.9665 10 15.75 10.7835 15.75 11.75V15.1623L18.4999 16.7499C18.956 16.1427 19.6821 15.75 20.5 15.75C21.8807 15.75 23 16.8693 23 18.25C23 19.6307 21.8807 20.75 20.5 20.75C19.1193 20.75 18 19.6307 18 18.25C18 18.0456 18.0245 17.847 18.0708 17.6569L15.671 16.2714C15.4491 16.9832 14.7849 17.5 14 17.5H10C9.19417 17.5 8.51556 16.9553 8.3122 16.2141L5.92922 17.5899C5.97548 17.78 6 17.9787 6 18.183C6 19.5637 4.88071 20.683 3.5 20.683C2.11929 20.683 1 19.5637 1 18.183C1 16.8023 2.11929 15.683 3.5 15.683C4.31788 15.683 5.04402 16.0758 5.50013 16.6829L8.25 15.0953V11.75C8.25 10.7835 9.0335 10 10 10H11.5V6.94999C10.3589 6.71836 9.5 5.70948 9.5 4.5C9.5 3.11929 10.6193 2 12 2C13.3807 2 14.5 3.11929 14.5 4.5ZM9.75 11.75C9.75 11.6119 9.86193 11.5 10 11.5H14C14.1381 11.5 14.25 11.6119 14.25 11.75V15.75C14.25 15.8881 14.1381 16 14 16H10C9.86193 16 9.75 15.8881 9.75 15.75V11.75Z"
            />
        </svg>
    );
};

export default SmartLinkIcon;
