import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import InputLabel from './InputLabel';
import styles from './Number.module.scss';

export type Props = {
    label?: string;
    id: string;
    hint?: string;
    validate: boolean;
    onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
    onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void;
    markedMandatory?: boolean;
    infoText?: string;
    currentValue?: string;
    step?: number;
    placeholder?: string;
    customLabel?: string;
    testId?: string;
    minValue?: number;
    maxValue?: number;
    size?: 'small' | 'medium';
    width?: 'minimum' | 'maximum' | 'standard';
    disabled?: boolean;
};

const NumberInput = (props: Props): React.ReactElement => {
    const {
        label,
        id,
        hint,
        validate,
        onChange,
        onBlur,
        markedMandatory,
        infoText,
        currentValue,
        step,
        placeholder,
        customLabel,
        testId,
        minValue,
        maxValue,
        size = 'medium',
        width = 'standard',
        disabled = false,
    } = props;
    const { t: txt } = useTranslation();
    return (
        <div className={styles.body}>
            {(label || customLabel) && (
                <InputLabel
                    htmlFor={id}
                    label={label}
                    customHeader={customLabel}
                    mandatory={markedMandatory}
                    infoText={infoText}
                />
            )}
            <input
                className={classNames(styles.input, {
                    [styles[`${size}`]]: size !== 'medium',
                    [styles.disabled]: disabled,
                    [styles.invalid]: validate,
                    [styles.validation]: validate && !!hint,
                    [styles[`${width}Width`]]: width !== 'standard',
                    [styles.noBottomMargin]: hint === undefined,
                })}
                type="number"
                id={id}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                step={step || 1}
                value={currentValue}
                data-testid={testId}
                min={minValue}
                max={maxValue}
                disabled={disabled}
            />
            {validate && hint && <div className={styles.error}>{hint && txt(hint)}</div>}
        </div>
    );
};

export default NumberInput;
