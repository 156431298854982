/* eslint max-len: ["error", 1000] */
import React from 'react';

const ForecastPartiallyCloudy = (): React.ReactElement => (
    <svg
        version="1.1"
        className="weather-icon"
        width="30px"
        height="30px"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
    >
        <path d="M4.2,5.5c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.9,0-1.2L4.2,3C3.9,2.7,3.4,2.7,3,3 S2.7,3.9,3,4.3L4.2,5.5z" />
        <path d="M3.5,10.3c0-0.5-0.3-0.9-0.9-0.9H0.9C0.4,9.5,0,9.8,0,10.3s0.3,0.9,0.9,0.9h1.7C3,11.2,3.5,10.8,3.5,10.3z" />
        <path d="M4.2,15.2L3,16.4c-0.3,0.3-0.3,0.9,0,1.2c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2l1.2-1.2 c0.3-0.3,0.3-0.9,0-1.2C5.1,14.9,4.7,14.9,4.2,15.2z" />
        <path d="M15.9,5.7c0.2,0,0.4-0.1,0.5-0.2l1.3-1.2C18,4,18,3.4,17.7,3.1c-0.3-0.3-0.9-0.3-1.2,0l-1.3,1.2 c-0.3,0.3-0.3,0.9,0,1.2C15.4,5.6,15.7,5.7,15.9,5.7z" />
        <path d="M10.4,3.4c0.5,0,0.9-0.3,0.9-0.9V0.9c0-0.5-0.3-0.9-0.9-0.9S9.5,0.3,9.5,0.9v1.7C9.5,3,9.8,3.4,10.4,3.4z" />
        <path
            d="M24.5,11.8h-0.6c-0.4-1.4-1.2-2.6-2.3-3.6c-1.4-1.1-3-1.8-4.9-1.8c-0.8,0-1.4,0.1-2.2,0.3c-1-1.2-2.5-2-4.2-2
            c-3.1,0-5.6,2.6-5.6,5.6c0,1.8,0.9,3.3,2.2,4.4c-0.4,0.8-0.6,1.7-0.6,2.7c0,3,2.5,5.5,5.5,5.5h12.7c3,0,5.5-2.6,5.5-5.6
            S27.5,11.8,24.5,11.8z M6.4,10.2c0-2.2,1.7-3.9,3.9-3.9c1,0,1.9,0.4,2.6,1c-1.8,1.1-3.1,2.8-3.6,5c-0.5,0.2-1,0.6-1.4,1.1
            C7,12.6,6.4,11.5,6.4,10.2z M24.5,20.8H11.8c-1.9,0-3.4-1.5-3.4-3.4c0-1.5,1-2.8,2.4-3.3l0.6-0.2l0.1-0.6c0.3-2.7,2.6-4.7,5.4-4.7
            c2.6,0,5,1.9,5.4,4.6l0.2,0.9h2.3c1.9,0,3.4,1.5,3.4,3.4S26.4,20.8,24.5,20.8z"
        />
    </svg>
);
export default ForecastPartiallyCloudy;
