import { takeEvery, put, call, CallEffect, PutEffect } from 'redux-saga/effects';
import {
    UpdateDashboardLayout,
    updateDashboardLayout,
    UpdateDashboardLayoutFailure,
    updateDashboardLayoutFailure,
} from '../../actions/DashboardActions';
import { FetchOutdoorTileData, OutdoorActionType } from '../../actions/OutdoorActions';
import fetchTileData from '../../api/fetchOutdoorAirInfo';
import { WeatherTileContent } from '../../models/commonTypeScript';
import { RequestActions } from '../../models/RequestTypes';
import { createDashboardConfig } from './fetchIndoorVsOutdoorTileData';

type FetchOutdoorTileDataSagaReturnType = Generator<
    | CallEffect<WeatherTileContent>
    | PutEffect<UpdateDashboardLayout>
    | PutEffect<UpdateDashboardLayoutFailure>
    | RequestActions,
    void,
    WeatherTileContent
>;
export function* fetchOutdoorTileDataSaga({ payload }: FetchOutdoorTileData): FetchOutdoorTileDataSagaReturnType {
    const { tiles, tileData, dashboardId } = payload;
    try {
        const response = yield call(fetchTileData, tileData.ref);
        yield put(
            updateDashboardLayout({
                tiles: createDashboardConfig(response, tileData, tiles),
                newModule: true,
                dashboardId,
            })
        );
    } catch (error) {
        yield put(updateDashboardLayoutFailure('AddNewTileError'));
    }
}

export default function* takeFetchOutdoorTileDataActions(): Generator {
    yield takeEvery(OutdoorActionType.FetchOutdoorTileDataInit, fetchOutdoorTileDataSaga);
}
