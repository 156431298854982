import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FetchCustomDeviceSegment, fetchCustomDeviceSegment } from 'commons/src/actions/DeviceActions';
import { setCustomPeriod } from 'commons/src/commonFunctions';
import {
    DayUsageHours,
    DeviceType,
    SegmentProperties,
    SelectedPeriod,
    SensorThresholds,
} from 'commons/src/models/commonTypeScript';
import { IaqInsightData } from '../../../models/common';
import { Store } from '../../../reducers';
import InsightTableRow from './InsightTable/InsightTableRow';

export type ParentProps = {
    sensorReport: IaqInsightData;
    sensor: string;
    usageHours: { [day: string]: DayUsageHours };
    from: Moment | null;
    to: Moment | null;
    timeZone: string;
};
type StateProps = {
    devices: { [serialNumber: string]: DeviceType };
    thresholds: { [sensor: string]: SensorThresholds };
    segmentProperties: { [serialNumber: string]: SegmentProperties };
};

type ActionProps = {
    fetchSegment: (serialNumber: string, timeRange: SelectedPeriod) => void;
};

type Props = StateProps & ParentProps & ActionProps;

export type DeviceReport = {
    name: string;
    serialNumber: string;
    average?: number;
    highestMeasurement?: number;
    lowestMeasurement?: number;
    roomType: string | undefined;
};

export const ResultTableComponent = (props: Props): React.ReactElement => {
    const {
        usageHours,
        from,
        to,
        devices,
        sensorReport,
        thresholds,
        sensor,
        fetchSegment,
        segmentProperties,
        timeZone,
    } = props;
    const { t: txt } = useTranslation();
    const [sneakPeakSensors, setSneakPeakSensor] = useState<string[]>([]);
    const sensorRanges = thresholds[sensor];
    const headers = ['AverageValue', 'InsightIaq.LowestMeasurement', 'InsightIaq.HighestMeasurement'];
    const columns = Object.keys(sensorReport.devicesOpeningHoursValues)
        .map(serialNumber => {
            const device = devices[serialNumber];
            const segmentProps = segmentProperties[serialNumber];
            return {
                ...sensorReport.devicesOpeningHoursValues[serialNumber],
                name: device.segmentName,
                serialNumber,
                roomType: segmentProps && segmentProps.roomType,
            };
        })
        .sort((deviceA, deviceB) => deviceA.name.localeCompare(deviceB.name));

    const clickSneakPeak = (serialNumber: string): void => {
        const alreadyOpen = sneakPeakSensors.includes(serialNumber);
        if (alreadyOpen) {
            setSneakPeakSensor(sneakPeakSensors.filter(serial => serial !== serialNumber));
        } else if (from) {
            const customPeriod = setCustomPeriod(
                moment(from.clone().subtract(1, 'days')),
                moment(to && to.clone().add(1, 'days'))
            );
            fetchSegment(serialNumber, customPeriod);
            setSneakPeakSensor([...sneakPeakSensors, serialNumber]);
        }
    };

    return (
        <div className="insight-tile__content insight-tile__content--table">
            <div className="insight-table">
                <div className="insight-table__row insight-table__row--header">
                    <div className="insight-table__cell insight-table__cell-header">
                        {txt('InsightIaq.DetailsWithinOpeningHours')}
                    </div>
                    {headers.map(headerText => (
                        <div className="insight-table__cell" key={`table-header-${headerText}`}>
                            {txt(headerText)}
                        </div>
                    ))}
                </div>
                {columns.map(device => (
                    <InsightTableRow
                        key={`table-element-${device.serialNumber}-${sensor}`}
                        deviceReport={device}
                        clickSneakPeak={clickSneakPeak}
                        showSneakPeak={sneakPeakSensors.includes(device.serialNumber)}
                        usageHours={usageHours}
                        sensorRanges={sensorRanges}
                        from={from}
                        to={to}
                        sensor={sensor}
                        sensorReports={sensorReport}
                        timeZone={timeZone}
                    />
                ))}
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { devices },
        segmentPropertiesStore: { segmentProperties },
        config: { thresholds },
    } = state;
    return {
        devices,
        thresholds,
        segmentProperties,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    fetchSegment: (serialNumber, timeRange): FetchCustomDeviceSegment =>
        dispatch(fetchCustomDeviceSegment(serialNumber, timeRange)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultTableComponent);
