import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { BoldText, ColoredDot, colorStyles } from 'commons/src/components/PDF/PdfConstants';
import { Rating } from 'commons/src/models/commonEnums';
import { SensorThresholds } from 'commons/src/models/commonTypeScript';
import { PDFContext, VirusRiskInsightData } from '../../../../models/common';
import { riskLevel } from '../../virusRisk/VirusDeviceResult';

const componentStyles = StyleSheet.create({
    riskContributorsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '50vw',
        marginBottom: 15,
    },
    riskFactorWrapper: {
        display: 'flex',
        width: '23vw',
        flexDirection: 'row',
        marginTop: 10,
    },
});

type Props = {
    possibleRiskFactors: { riskName: string; sensorName: string }[];
    deviceResult: VirusRiskInsightData;
    thresholds: { [sensor: string]: SensorThresholds };
    txt: PDFContext;
};

type RiskFactorProps = {
    riskFactor: { riskName: string; sensorName: string };
    deviceResult: VirusRiskInsightData;
    thresholds: { [sensor: string]: SensorThresholds };
    txt: PDFContext;
};

const RiskFactor = (props: RiskFactorProps): React.ReactElement => {
    const { riskFactor, deviceResult, thresholds, txt } = props;
    const rating = riskLevel(riskFactor.sensorName, deviceResult, thresholds);
    let color = 'green';
    if (rating === Rating.FAIR) {
        color = 'yellow';
    } else if (rating === Rating.POOR) {
        color = 'red';
    }

    return (
        <View style={componentStyles.riskFactorWrapper}>
            <ColoredDot style={colorStyles[color as keyof typeof colorStyles]} />
            <View>
                <Text>{txt(`InsightRiskFactor.${riskFactor.riskName}`)}</Text>
            </View>
        </View>
    );
};

const RiskFactorsComponent = ({ possibleRiskFactors, deviceResult, thresholds, txt }: Props): React.ReactElement => (
    <View>
        <BoldText>{txt('VirusInsight.ContributingRiskFactors')}</BoldText>
        <View style={componentStyles.riskContributorsWrapper}>
            {possibleRiskFactors.map(riskFactor => (
                <RiskFactor
                    key={`${riskFactor.riskName}-${deviceResult.serialNumber}`}
                    riskFactor={riskFactor}
                    deviceResult={deviceResult}
                    thresholds={thresholds}
                    txt={txt}
                />
            ))}
        </View>
    </View>
);

export default RiskFactorsComponent;
