import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import DeviceHealthStatus from '../../models/deviceHealthStatus';
import BatteryIcon from '../device/BatteryIcon';
import styles from './BatteryComponent.module.scss';

type Props = {
    batteryPercentage: number | undefined;
    healthStatus: DeviceHealthStatus;
    noBatteryText?: boolean;
};

const BatteryComponent = ({ batteryPercentage, healthStatus, noBatteryText }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const deviceOffline: boolean = healthStatus === DeviceHealthStatus.offline;
    const hasBatteryData: boolean = !!batteryPercentage && batteryPercentage >= 0;
    if (hasBatteryData) {
        return (
            <div
                className={classNames(styles.body, {
                    [styles.batteryGreyed]: deviceOffline,
                })}
            >
                <div className={styles.battery}>
                    <BatteryIcon batteryPercentage={batteryPercentage} isHubConnectionLost={deviceOffline} />
                    {`${batteryPercentage}% `} {noBatteryText !== true && txt('EstBatteryRemaining')}
                </div>
            </div>
        );
    }
    return <div />;
};

export default BatteryComponent;
