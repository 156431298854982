import React, { ReactElement, useEffect, useState } from 'react';
import moment, { Moment } from 'moment/moment';
import { setCustomPeriod } from '../../commonFunctions';
import { dateFormats } from '../../constants';
import { SelectedPeriod } from '../../models/commonTypeScript';
import DatePicker from '../DatePicker';
import CircleButton from './CircleButton';
import FilterButton from './FilterButton';
import SensorFilterButtons from './SensorFilterButtons';
import styles from './TimePeriodSelector.module.scss';

export type Props = {
    limitTimeSelector?: boolean;
    selectGraphPeriod: (selectedPeriod: SelectedPeriod, isCustom?: boolean) => void;
    selectedPeriod: SelectedPeriod;
    dateFormat: string;
    isMobile: boolean;
    loading: boolean;
    endedSegment: boolean;
    segmentStartDate?: string;
    segmentEndDate?: string;
    timeZone?: string;
};
const TimePeriodSelector = ({
    selectGraphPeriod,
    selectedPeriod,
    isMobile,
    dateFormat,
    loading,
    endedSegment,
    segmentEndDate,
    segmentStartDate,
    limitTimeSelector,
    timeZone,
}: Props): ReactElement => {
    const [startDate, setStartDate] = useState<Moment | null>(null);
    const [endDate, setEndDate] = useState<Moment | null>(null);
    const [currentSelectedPeriod, setCurrentSelectedPeriod] = useState(selectedPeriod);
    const [displayDatePicker, setDisplayDatePicker] = useState(endedSegment);

    useEffect(() => {
        if (endDate === null && endedSegment && segmentEndDate) {
            setEndDate(moment(segmentEndDate));
            setStartDate(moment(segmentStartDate));
        }
    });

    const onClickDateSelectionType = (): void => {
        if (!loading && currentSelectedPeriod.startDate) {
            selectGraphPeriod(currentSelectedPeriod, false);
        }
        setDisplayDatePicker(!displayDatePicker);
    };

    const onStartDateChanged = (date: Moment): void => {
        if (displayDatePicker && moment(date).startOf('day').valueOf() !== moment(startDate).valueOf()) {
            setStartDate(date);

            if (endDate && date) {
                if (date.endOf('day').isAfter(endDate.endOf('day'))) {
                    setEndDate(null);
                } else {
                    const customPeriod = setCustomPeriod(date, endDate, timeZone);
                    setCurrentSelectedPeriod(customPeriod);
                    selectGraphPeriod(customPeriod, true);
                }
            }
        }
    };

    const onEndDateChanged = (date: Moment): void => {
        if (displayDatePicker && moment(date).startOf('day').valueOf() !== moment(endDate).valueOf()) {
            setEndDate(date);
            if (startDate && date) {
                const customPeriod = setCustomPeriod(startDate, date, timeZone);
                setCurrentSelectedPeriod(customPeriod);
                selectGraphPeriod(customPeriod, true);
            }
        }
    };

    const isOutsideDateRange = (day: Moment): boolean => {
        if (!moment.isMoment(day)) return false;
        if (endedSegment) {
            const afterEndDate = moment(day).endOf('day').diff(moment(segmentEndDate).endOf('day'), 'days') > 0;
            const beforeStartDate = moment(day).endOf('day').diff(moment(segmentStartDate).endOf('day'), 'days') < 0;
            return afterEndDate || beforeStartDate;
        }

        const afterToday = day.diff(moment().endOf('day'), 'days') > -1;
        const beforeStartDate = moment(day).endOf('day').diff(moment(segmentStartDate).endOf('day'), 'days') < 0;
        const yearBeforeEndDate = endDate
            ? moment(day).endOf('day').diff(moment(endDate).subtract(1, 'years').endOf('day')) < 0
            : false;
        const yearAfterStartDate = startDate
            ? moment(day).endOf('day').diff(moment(startDate).add(1, 'years').endOf('day')) > 0
            : false;
        return afterToday || beforeStartDate || yearBeforeEndDate || yearAfterStartDate;
    };

    const isOutsideEndDateRange = (day: Moment): boolean => {
        if (moment(day).endOf('day').isBefore(moment(startDate).endOf('day'))) {
            return true;
        }
        return isOutsideDateRange(day);
    };

    const initialVisibleMonth = endedSegment ? moment(segmentEndDate) : moment();
    return (
        <>
            {displayDatePicker ? (
                <div className={styles.datePickerWrapper}>
                    <DatePicker
                        id="startDate"
                        onChange={onStartDateChanged}
                        dateFormat={dateFormat as keyof typeof dateFormats}
                        selectedDate={startDate}
                        initialVisibleMonth={initialVisibleMonth}
                        disabledDate={isOutsideDateRange}
                        placeholder="StartDate"
                        data-date-picker
                    />
                    <DatePicker
                        id="endDate"
                        onChange={onEndDateChanged}
                        dateFormat={dateFormat as keyof typeof dateFormats}
                        selectedDate={endDate}
                        initialVisibleMonth={initialVisibleMonth}
                        disabledDate={isOutsideEndDateRange}
                        placeholder="EndDate"
                    />
                </div>
            ) : (
                <SensorFilterButtons
                    selectGraphPeriod={selectGraphPeriod}
                    selectedPeriod={selectedPeriod}
                    disabled={loading}
                    isMobile={isMobile}
                    limitTimeSelector={limitTimeSelector}
                />
            )}
            {!endedSegment && !limitTimeSelector && (
                <div>
                    {!displayDatePicker ? (
                        <FilterButton
                            title="TimeSelector.Custom"
                            onClick={onClickDateSelectionType}
                            isSelected={false}
                            testAttr="date-picker"
                        />
                    ) : (
                        <CircleButton
                            iconName="close"
                            onClick={onClickDateSelectionType}
                            testAttr="date-picker"
                            color="tertiary"
                            size="small"
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default TimePeriodSelector;
