type DeviceTypeConfig = {
    prefix: string;
    type: string;
    validPrefixes: string[];
    sensors?: string[];
    businessSensors?: string[];
};

class DeviceType {
    prefix: string;

    validPrefixes: string[];

    type: string;

    sensors: string[] | undefined;

    constructor({ prefix, validPrefixes, type, sensors }: DeviceTypeConfig) {
        this.prefix = prefix;
        this.validPrefixes = validPrefixes;
        this.type = type;
        this.sensors = sensors;
    }

    isValidPrefix(prefix: string): boolean {
        if (prefix.length > 4) {
            // If its length is bigger than 4 we only check against the first 4 chars
            return this.isValidPrefix(prefix.slice(0, 4));
        }

        const validPrefixLength = this.validPrefixes[0] ? this.validPrefixes[0].toString().length : 4;
        const prefixWithCorrectLength = prefix.slice(0, validPrefixLength);
        return this.validPrefixes.includes(prefixWithCorrectLength);
    }
}

export default DeviceType;
