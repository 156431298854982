import React, { useState } from 'react';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import { useTranslation } from 'react-i18next';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_PRESENCE_INSIGHT_SELECT_HEATMAP_DATE } from 'commons/src/analytics/AnalyticsEvents';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats } from 'commons/src/constants';
import useWindowSize from 'commons/src/hooks/useWindowSize';
import { TimePeriod } from 'commons/src/models/commonEnums';
import { DayUsageHours, ErrorType } from 'commons/src/models/commonTypeScript';
import { PresenceOverTime } from '../../../models/buildingModels';
import UsageOverTimeDayView from './UsageOverTimeDayView';
import usageOverTimeHeatMapConfig, { getDate } from './usageOverTimeHeatMapConfig';
import styles from './UsageOverTimeSection.module.scss';

HighchartsHeatmap(Highcharts);
dayjs.extend(isoWeek);

type Props = {
    dateFormat: keyof typeof dateFormats;
    presenceOverTime: PresenceOverTime[];
    selectedPeriod: {
        toDate: string;
        fromDate: string;
        name: TimePeriod;
    };
    error?: ErrorType;
    usageHours: {
        [day: string]: DayUsageHours;
    };
    withOpeningHours: boolean;
    timeZone?: string;
};

const UsageOverTimeSection = ({
    dateFormat,
    presenceOverTime,
    selectedPeriod,
    error,
    usageHours,
    withOpeningHours,
    timeZone,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [selectedDate, setSelectedDate] = useState('');
    const { width } = useWindowSize();
    const [detailsExpanded, setDetailsExpanded] = useState(false);
    const { fromDate, toDate } = selectedPeriod;
    const fromDateWeekNumber = dayjs(fromDate).isoWeek();

    const firstDateOfFirstWeek = dayjs(fromDate).startOf('isoWeek').format();
    let highestValue = 0;

    const heatMapData = presenceOverTime.map(day => {
        const weekDay = dayjs(day.date).isoWeekday(); // weekdays from 1 to 7 monday being 1.
        const weekNumber = dayjs(day.date).isoWeek();
        if (day.minutesUsed > highestValue) highestValue = day.minutesUsed;
        const handlingDatesCrossNewYear = weekNumber < fromDateWeekNumber ? 52 + weekNumber : weekNumber;
        return [weekDay - 1, handlingDatesCrossNewYear - fromDateWeekNumber, day.minutesUsed];
    });

    const onSelectDate = (clickData: Highcharts.PointClickEventObject): void => {
        const { x, y } = clickData.point.options;
        const pointDate = getDate(x as number, y as number, firstDateOfFirstWeek);
        const formattedDate = pointDate.format('YYYY-MM-DD');
        setSelectedDate(formattedDate);
        const weekDayString = dayjs(formattedDate).locale('en').format('dddd').toLowerCase();
        analyticsLogger(BUILDING_PRESENCE_INSIGHT_SELECT_HEATMAP_DATE, {
            payload: { date: formattedDate, weekday: weekDayString },
        });
    };

    const getPaddedDays = (): number[][] => {
        const fromDateDayJs = dayjs(fromDate);
        const toDateDateJs = dayjs(toDate);
        const toDateWeekNumber = toDateDateJs.isoWeek();
        const toDateWeekNumberHandlingDatesCrossNewYear =
            toDateWeekNumber < fromDateWeekNumber ? 52 + toDateWeekNumber : toDateWeekNumber;
        const numberOfWeeksSelected = toDateWeekNumberHandlingDatesCrossNewYear - fromDateWeekNumber + 1;
        const numberOfWeeksToPad = 5 - numberOfWeeksSelected;

        const weekDayOfFromDate = fromDateDayJs.isoWeekday();
        const numberOfDaysInWeekBeforeFromDate = weekDayOfFromDate - 1;

        const weeksToPadOnStart = Math.ceil(numberOfWeeksToPad / 2);
        const paddedStart = Array.from({ length: weeksToPadOnStart }, (_, i) =>
            Array.from({ length: 7 }, (__, y) => [y, i - weeksToPadOnStart, 0])
        ).flat();

        const paddedBeforeFromDate = Array.from({ length: numberOfDaysInWeekBeforeFromDate }, (_, i) => [i, 0, 0]);

        const weekDayOfToDate = toDateDateJs.isoWeekday();
        const paddedEnd = Array.from({ length: 7 - weekDayOfToDate }, (_, i) => [
            weekDayOfToDate + i,
            numberOfWeeksSelected - 1,
            0,
        ]);

        const weeksToPadOnEnd = Math.floor(numberOfWeeksToPad / 2);
        const paddedAfterToDate = Array.from({ length: weeksToPadOnEnd }, (_, i) =>
            Array.from({ length: 7 }, (__, y) => [y, numberOfWeeksSelected + i, 0])
        ).flat();

        return [...paddedStart, ...paddedBeforeFromDate, ...paddedEnd, ...paddedAfterToDate];
    };

    const highestFullHour = Math.ceil(highestValue / 60);
    const heatMapConfig =
        heatMapData.length > 0 &&
        usageOverTimeHeatMapConfig({
            chartData: heatMapData,
            paddedDays: getPaddedDays(),
            firstDateOfFirstWeek,
            highestValue: highestFullHour,
            dateFormat,
            onClick: onSelectDate,
            txt,
            bottomLegend: width < 450,
        });

    return (
        <div className={styles.graphCard}>
            <h4 className={styles.header}>{txt('PresenceInsight.UsageOverTime')}</h4>
            {error ? (
                <ResponseBox text={error.error} />
            ) : (
                <div className={styles.cardContent}>
                    {!detailsExpanded && (
                        <div className={styles.heatMapWrapper}>
                            {heatMapConfig && <HighchartsReact highcharts={Highcharts} options={heatMapConfig} />}
                        </div>
                    )}
                    <UsageOverTimeDayView
                        timeZone={timeZone}
                        detailsExpanded={detailsExpanded}
                        setDetailsExpanded={setDetailsExpanded}
                        presenceOverTime={presenceOverTime}
                        selectedDate={selectedDate}
                        dateFormat={dateFormat}
                        usageHours={usageHours}
                        withOpeningHours={withOpeningHours}
                    />
                </div>
            )}
        </div>
    );
};

export default UsageOverTimeSection;
