import React, { useEffect, useState } from 'react';
import { Document, Font, StyleSheet, Text, View, Image, Page } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PdfDownloadButton from 'commons/src/components/PDF/PdfDownloadButton';
import { colors } from 'commons/src/constants';
import Medium from 'commons/src/fonts/open-sans-v28-latin-500.ttf';
import DemiBold from 'commons/src/fonts/open-sans-v28-latin-600.ttf';
import Bold from 'commons/src/fonts/open-sans-v28-latin-700.ttf';
import RegularFont from 'commons/src/fonts/open-sans-v28-latin-regular.ttf';
import { Store } from 'commons/src/reducers';
import HorizontalBusinessLogo from '../../../../img/logos/horizontal-business-logo.png';
import { PDFContext } from '../../../../models/common';

Font.register({ family: 'Bold', src: Bold });
Font.register({ family: 'DemiBold', src: DemiBold });
Font.register({ family: 'Medium', src: Medium });
Font.register({ family: 'Regular', src: RegularFont });

const styles = StyleSheet.create({
    body: {
        paddingTop: '6vw',
        paddingBottom: 65,
        paddingRight: '5vw',
        paddingLeft: '5vw',
        fontFamily: 'Regular',
    },
    labelWrapper: {
        width: 245,
        display: 'flex',
        flexDirection: 'row',
        border: '1 solid #B7CAD7',
    },
    blueContainer: {
        height: 153,
        width: 101,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.blueAura,
    },
    whiteContainer: {
        width: 143,
        height: 153,
        backgroundColor: colors.white,
        padding: '10 5',
    },
    airthingsLogo: {
        width: 69,
        paddingLeft: 8,
        paddingBottom: 12,
        marginTop: 'auto',
    },
    organizationLogo: {
        height: '100%',
        maxHeight: 20,
        maxWidth: '100%',
        width: 'auto',
    },
    qrImage: {
        width: 78,
        height: 78,
    },
    header: {
        paddingTop: 12,
        paddingLeft: 6,
    },
    deviceInfoWrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    cardWrapper: {
        width: 265,
        padding: 10,
    },
    deviceName: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        fontSize: 12,
        paddingTop: 5,
    },
    headerText: {
        fontFamily: 'Bold',
        color: colors.white,
        fontSize: 15,
    },
    subHeaderText: {
        fontFamily: 'Regular',
        color: colors.white,
        fontSize: 8,
        paddingTop: 7,
    },
    organizationNameWrapper: {
        width: '100%',
        textAlign: 'center',
        paddingLeft: 5,
        paddingRight: 5,
    },
    organizationNameText: {
        fontFamily: 'DemiBold',
        color: colors.greyRiverBed,
        fontSize: 14,
        textOverflow: 'ellipsis',
        maxLines: 1,
    },
    bodyText: {
        fontFamily: 'Regular',
        color: colors.greyRiverBed,
        fontSize: 6,
    },
});

type GenerateProps = {
    serialNumber: string;
    organizationName: string;
    qrImage: string;
    url: string;
    deviceName: string;
};

interface PdfProps {
    organizationName: string;
    qrImage: string;
    txt: PDFContext;
    url: string;
    logoImage?: string;
    deviceName: string;
}

interface StateProps {
    logoImage?: string;
}

type QrLabelProps = {
    organizationName: string;
    qrImage: string;
    txt: PDFContext;
    url: string;
    logoImage?: string;
};

export const Body = ({ children }: { children: React.ReactElement | React.ReactElement[] }): React.ReactElement => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Page size="A4" style={styles.body}>
        {children}
    </Page>
);
export const QrLabel = ({ url, organizationName, qrImage, txt, logoImage }: QrLabelProps): React.ReactElement => (
    <View style={styles.labelWrapper} wrap={false}>
        <View style={styles.blueContainer}>
            <View style={styles.header}>
                <Text style={styles.headerText}>{txt('PublicIAQDashboard.StickerHeader')}</Text>
                <Text style={styles.subHeaderText}>{txt('PublicIAQDashboard.StickerDescription')}</Text>
            </View>
            <Image style={styles.airthingsLogo} src={HorizontalBusinessLogo} />
        </View>
        <View style={styles.whiteContainer}>
            <View style={styles.deviceInfoWrapper}>
                {logoImage ? (
                    <Image style={styles.organizationLogo} src={logoImage} />
                ) : (
                    <View>
                        <Text style={styles.organizationNameText}>{organizationName}</Text>
                    </View>
                )}
                <Image style={styles.qrImage} src={qrImage} />
                <Text style={styles.bodyText}>{url}</Text>
            </View>
        </View>
    </View>
);

export const QRCard = (props: PdfProps): React.ReactElement => {
    const { deviceName } = props;
    return (
        <View style={styles.cardWrapper}>
            <QrLabel {...props} />
            <View style={styles.deviceName}>
                <Text>{deviceName}</Text>
            </View>
        </View>
    );
};

const PdfDocument = (props: PdfProps): React.ReactElement => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Document>
        <Body>
            <QRCard {...props} />
        </Body>
    </Document>
);

const GenerateQrLabel = (props: GenerateProps & StateProps): React.ReactElement => {
    const { serialNumber, organizationName, qrImage, url, deviceName, logoImage } = props;
    const { t: txt } = useTranslation();
    const pdfProps: PdfProps = {
        organizationName,
        qrImage,
        txt,
        logoImage,
        url,
        deviceName,
    };

    const [fontsRendered, setFontsRendered] = useState(false);

    useEffect(() => {
        setFontsRendered(true);
    }, []);

    return (
        <div>
            {fontsRendered && (
                <PdfDownloadButton
                    document={<PdfDocument {...pdfProps} />}
                    fileName={`AirthingsPublicIAQLabel_${serialNumber}.pdf`}
                    allPropsReady
                />
            )}
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        organizationProperties: { logoImage },
    } = store;
    return {
        logoImage,
    };
};

export default connect(mapStateToProps)(GenerateQrLabel);
