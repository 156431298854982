import React from 'react';
import { ButtonBase } from '@mui/material';
import classNames from 'classnames';
import { generatePath } from 'react-router-dom';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { RenderedProps } from '../../../components/collapsibleTable/models';
import { OrganizationBuildingOverview } from '../../../models/organizationHealthModels';
import HealthSummary from '../buildingStatus/summaryBar/HealthSummary';
import styles from './BuildingRow.module.scss';

type BuildingRowHeaderProps = RenderedProps;

const BuildingRowHeader = ({ data, toggle, opened, id }: BuildingRowHeaderProps): React.ReactElement => {
    const rowData = data as OrganizationBuildingOverview;
    const buildingTo = generatePath('/buildings/:locationId/status', { locationId: rowData.locationId });
    const noDataToDisplay = !rowData.hubStats.total && !rowData.deviceStats.total;

    return (
        <div className={styles.rowHeader}>
            <ButtonBase
                data-testid={`collapse_button_${id}`}
                className={classNames(styles.toggleButton, { [styles.visuallyHidden]: noDataToDisplay })}
                onClick={toggle}
                centerRipple={false}
                disabled={noDataToDisplay}
            >
                <MaterialIcon name={opened ? 'expand_less' : 'chevron_right'} />
            </ButtonBase>
            <header className={styles.headerContent} data-testid={`collapse_header_${id}`}>
                <div className={styles.buildingsInfo}>
                    <a
                        href={buildingTo}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.buildingName}
                        title={rowData.locationName}
                    >
                        {rowData.locationName}
                    </a>
                </div>
                <div className={styles.summaryWrapper}>
                    <HealthSummary isHub stats={rowData.hubStats} showOnlyValues />
                </div>
                <div className={styles.summaryWrapper}>
                    <HealthSummary isHub={false} stats={rowData.deviceStats} showOnlyValues />
                </div>
            </header>
        </div>
    );
};

export default BuildingRowHeader;
