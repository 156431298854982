import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { dateFormats, businessPaths as paths } from 'commons/src/constants';
import { Resolution, SensorTypes, TimePeriod } from 'commons/src/models/commonEnums';
import { BuildingInsight } from '../../../models/buildingModels';
import humanizedTimeString from './buildingInsightCommonFunctions';
import DeviceBreakDown from './DeviceBreakDown';

export type Props = {
    selectedDevice?: string;
    insight: BuildingInsight;
    selectedPeriod: {
        toDate: string;
        fromDate: string;
        resolution: Resolution;
        name: TimePeriod;
    };
    timeZone?: string;
    dateFormat: keyof typeof dateFormats;
    selectedHighThreshold: { id: string; inputValue: string };
    selectedLowThreshold?: { id: string; inputValue: string };
    language: string;
    sensor: SensorTypes;
};

const TotalByDeviceBreakDown = ({
    insight,
    selectedPeriod,
    selectedDevice,
    timeZone,
    dateFormat,
    selectedHighThreshold,
    selectedLowThreshold,
    language,
    sensor,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const {
        totalDeviceData: { devicesOverTime, devicesInTimeFrame },
    } = insight;
    const insightForDevice = selectedDevice ? devicesOverTime[selectedDevice] : [];
    const selectedDeviceProps =
        selectedDevice && devicesInTimeFrame.find(device => device.serialNumber === selectedDevice);

    const totalBreachTime = insightForDevice.reduce((totalTime, data) => {
        return totalTime + data.underThresholdsTime + data.overThresholdsTime;
    }, 0);

    return (
        <div className="building-insight__card__section building-insight__card__section--breakdown building-insight__card__section--breakdown--top-padded">
            {selectedDevice ? (
                <div>
                    <div className="text-medium text-bold">
                        <Link to={`/${paths.devices}/${selectedDevice}`} target="_blank" rel="noopener noreferrer">
                            {selectedDeviceProps && selectedDeviceProps.name}
                        </Link>
                    </div>
                    <div>
                        {txt('BuildingInsight.TotalTimeOutsideBoundary')}{' '}
                        <span className="text-medium small-padding-bottom">{humanizedTimeString(totalBreachTime)}</span>
                    </div>
                    <div className="building-insight__card__section--breakdown__graph-background">
                        <DeviceBreakDown
                            insight={insight}
                            selectedPeriod={selectedPeriod}
                            dateFormat={dateFormat}
                            selectedHighThreshold={selectedHighThreshold.inputValue}
                            selectedLowThreshold={selectedLowThreshold ? selectedLowThreshold.inputValue : undefined}
                            language={language}
                            timeZone={timeZone}
                            selectedDevice={selectedDevice}
                            sensor={sensor}
                            allowGraphClick
                        />
                    </div>
                </div>
            ) : (
                <div>{txt('BuildingInsight.SelectDevice')}</div>
            )}
        </div>
    );
};

export default TotalByDeviceBreakDown;
