import { all, call, put, takeEvery, CallEffect, PutEffect } from 'redux-saga/effects';
import { requestSuccess, requestError, RequestActionType } from 'commons/src/actions/requestActions';
import RequestActions from 'commons/src/models/RequestTypes';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import {
    FetchOutdoorInsightData,
    FetchOutdoorInsightDataSuccess,
    fetchOutdoorInsightDataSuccess,
    InsightActionType,
    OutdoorInsightResponse,
} from '../actions/outdoorInsightActions';
import getOutdoorInsightData from '../api/outdoorInsightApi';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type GetOutdoorInsightDataSagaReturnType = Generator<
    CallEffect | PutEffect<FetchOutdoorInsightDataSuccess> | RequestActions,
    void,
    OutdoorInsightResponse
>;
export function* getOutdoorInsightDataSaga({ payload }: FetchOutdoorInsightData): GetOutdoorInsightDataSagaReturnType {
    try {
        const response: OutdoorInsightResponse = yield call(getOutdoorInsightData, payload);
        yield put(fetchOutdoorInsightDataSuccess({ ...response }));
        yield put(requestSuccess(RequestType.FetchOutdoorInsightData, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchOutdoorInsightData, RequestActionType.Error));
    }
}

export default function* OutdoorSagas(): Generator {
    yield all([takeEvery(InsightActionType.FetchOutdoorInsightDataInit, getOutdoorInsightDataSaga)]);
}
