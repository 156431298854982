import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import MaterialIcon from '../../components/MaterialIcon';
import { roleRestrictions } from '../../constants';
import { Role } from '../../models/commonEnums';
import { Store } from '../../reducers';
import { userRoleAboveRequiredLevel } from '../authorization/userRoleAboveRequiredLevel';

type StateProps = {
    userRole?: Role;
};

type Props = StateProps & {
    name: string;
    renameSegment: (name: string) => void;
};

export const DeviceEditNameHeaderComponent = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { userRole, renameSegment, name } = props;
    const [isEditing, setIsEditing] = useState(false);
    const [updatedName, setUpdatedName] = useState(name);

    useEffect(() => {
        if (!isEditing) {
            setUpdatedName(name);
        }
    }, [name]);

    useEffect(() => {
        if (isEditing) {
            const editComponent = document.getElementById('editName');
            if (editComponent) editComponent.focus();
        }
    }, [isEditing]);

    const updateName = (newName: string): void => {
        let deviceName = updatedName;
        if (newName.length > 0 && newName !== deviceName) {
            deviceName = newName;
            renameSegment(newName);
        }
        setIsEditing(false);
        setUpdatedName(deviceName);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        e.preventDefault();
        if (e.key === 'Enter') {
            updateName(e.currentTarget.value.trim());
        }
    };

    const onBlur = (e: React.SyntheticEvent<HTMLInputElement>): void => {
        e.preventDefault();
        updateName(e.currentTarget.value.trim());
    };

    const allowedToEdit = (): boolean => {
        return !userRole || userRoleAboveRequiredLevel(userRole, roleRestrictions.editDeviceOrBuilding);
    };

    const editName = (): void => {
        if (allowedToEdit()) {
            setIsEditing(true);
        }
    };

    return (
        <div className="single-device__header__edit-container">
            {isEditing && (
                <div className="single-device__header__edit">
                    <input
                        className="single-device__header__edit__input"
                        type="text"
                        id="editName"
                        required
                        onBlur={onBlur}
                        onKeyUp={onKeyDown}
                        autoComplete="false"
                        defaultValue={updatedName}
                        maxLength={50}
                        data-testid="edit-name-input"
                    />
                </div>
            )}
            {!isEditing &&
                (allowedToEdit() ? (
                    <button
                        type="button"
                        title={txt('EditDeviceName')}
                        onClick={editName}
                        className="single-device__header__edit-button"
                        data-testid="edit-name-button"
                    >
                        <div className="single-device__header__edit-button__content">
                            <h1>{updatedName}</h1>
                            <MaterialIcon extraClass="single-device__header__edit-button__icon" name="edit" />
                        </div>
                    </button>
                ) : (
                    <div className="single-device__header__edit-button__content">
                        <h1>{updatedName}</h1>
                    </div>
                ))}
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const { selectedGroup } = store.userSettings;
    return {
        userRole: selectedGroup && selectedGroup.role,
    };
};

export default connect(mapStateToProps)(DeviceEditNameHeaderComponent);
