import fetchFromDashboardApi from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { PartnerCustomersResponse, PartnerDetailsResponse } from '../actions/partnerActions';
import { PartnerSummaryResponse } from '../features/partner/summary/PartnerSummaryModels';

export const fetchPartnerInfo = async (): Promise<PartnerDetailsResponse> =>
    fetchFromDashboardApi(`/partner/customers`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const toggleBranding = async (partnerBranding: boolean): Promise<void> =>
    fetchFromDashboardApi(`/partner/branding?partnerBranding=${partnerBranding}`, {
        method: 'PATCH',
        headers: await getFetchHeaders(),
    });

export const getOfflineDevices = async (): Promise<PartnerCustomersResponse> =>
    fetchFromDashboardApi('/partner/offline-devices', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const fetchPartnerSummaryCSVUrls = async (from: string, to: string): Promise<PartnerSummaryResponse> =>
    fetchFromDashboardApi(`/partner/bucket-urls?from=${from}&to=${to}`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
