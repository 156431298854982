import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess, requestStreaming } from 'commons/src/actions/requestActions';
import RequestActions from 'commons/src/models/RequestTypes';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import {
    FetchOrganizationBuildingData,
    fetchOrganizationBuildingSuccess,
    FetchOrganizationBuildingSuccess,
    FetchOrganizationHealthOverviewData,
    fetchOrganizationHealthOverviewSuccess,
    FetchOrganizationHealthOverviewSuccess,
    OrganizationHealthActionType,
    UpdateOrganizationBuildingRequest,
    updateOrganizationBuildingRequest,
} from '../actions/organizationHealthActions';
import { getOrganizationBuildingData, getOrganizationHealth } from '../api/organizationHealth';
import { BUILDING_HEALTH_TABLE_LENGTH, ORGANIZATION_HEALTH_TABLE_LENGTH } from '../constants';
import { OrganizationBuildingResponse, OrganizationHealthResponse } from '../models/organizationHealthModels';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

// ********** Organization Health Overview ********** //

type FetchOrganizationHealthOverviewEffects =
    | CallEffect
    | PutEffect<FetchOrganizationHealthOverviewSuccess>
    | PutEffect<FetchOrganizationHealthOverviewData>
    | CallEffect<OrganizationHealthResponse>
    | RequestActions;

type FetchOrganizationHealthOverviewReturnType = Generator<
    FetchOrganizationHealthOverviewEffects,
    void,
    OrganizationHealthResponse
>;

export function* fetchOrganizationHealthSaga(): FetchOrganizationHealthOverviewReturnType {
    try {
        let offset = 0;
        let locationsFetchedSoFar = 0;
        let totalLocationsToFetch = 0;
        do {
            const { totalLocations, locations } = yield call(
                getOrganizationHealth,
                ORGANIZATION_HEALTH_TABLE_LENGTH,
                offset
            );

            if (offset === 0) {
                yield put(requestStreaming(RequestType.FetchOrganizationHealth, RequestActionType.Streaming));
                totalLocationsToFetch = totalLocations;
            }
            offset += locations.length;
            locationsFetchedSoFar += locations.length;

            yield put(fetchOrganizationHealthOverviewSuccess(locations, totalLocations));
        } while (locationsFetchedSoFar < totalLocationsToFetch);
        yield put(requestSuccess(RequestType.FetchOrganizationHealth, RequestActionType.Success));
    } catch (error) {
        yield put(requestError(toErrorType(error), RequestType.FetchOrganizationHealth, RequestActionType.Error));
    }
}

// ********** Organization  Building ********** //

type FetchOrganizationBuildingEffects =
    | CallEffect
    | PutEffect<FetchOrganizationBuildingSuccess>
    | PutEffect<FetchOrganizationBuildingData>
    | PutEffect<UpdateOrganizationBuildingRequest>
    | CallEffect<OrganizationHealthResponse>;

type FetchOrganizationBuildingReturnType = Generator<
    FetchOrganizationBuildingEffects,
    void,
    OrganizationBuildingResponse
>;

export function* fetchOrganizationBuildingSaga({
    locationId,
}: FetchOrganizationBuildingData): FetchOrganizationBuildingReturnType {
    try {
        let offset = 0;
        let devicesFetchedSoFar = 0;
        let totalDevicesToFetch = 0;
        yield put(updateOrganizationBuildingRequest(locationId, RequestActionType.Init));
        do {
            const { totalDevices, devices } = yield call(
                getOrganizationBuildingData,
                locationId,
                BUILDING_HEALTH_TABLE_LENGTH,
                offset
            );

            if (offset === 0) {
                yield put(updateOrganizationBuildingRequest(locationId, RequestActionType.Streaming));
                totalDevicesToFetch = totalDevices;
            }
            offset += devices.length;
            devicesFetchedSoFar += devices.length;

            yield put(fetchOrganizationBuildingSuccess(locationId, devices, totalDevicesToFetch));
        } while (devicesFetchedSoFar < totalDevicesToFetch);
        yield put(updateOrganizationBuildingRequest(locationId, RequestActionType.Success));
    } catch (error) {
        yield put(updateOrganizationBuildingRequest(locationId, RequestActionType.Error, toErrorType(error)));
    }
}

export default function* BuildingsHealthStatusSagas(): Generator {
    yield all([
        takeEvery(OrganizationHealthActionType.FetchOrganizationHealthOverview, fetchOrganizationHealthSaga),
        takeEvery(OrganizationHealthActionType.FetchOrganizationBuildingData, fetchOrganizationBuildingSaga),
    ]);
}
