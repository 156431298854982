import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { analyticsLogger } from 'commons/src/analytics';
import { SPACE_CREATED_SPACE } from 'commons/src/analytics/AnalyticsEvents';
import ModalWrapper from 'commons/src/components/modals/ModalWrapper';
import { createSpace } from '../../../actions/spaceActions';
import { CreateSpacePayload } from '../../../models/spaceModels';
import SpaceAddForm from './SpaceAddForm';

export type Props = {
    isOpen: boolean;
    onClose: () => void;
    locationId: string;
};

const SpaceAddModal = ({ isOpen, onClose, locationId }: Props): ReactElement => {
    const [isLoading, setIsLoading] = useState(false);
    const [enableHeader, setEnableHeader] = useState(true);
    const dispatch: Dispatch = useDispatch();

    const onSave = (payload: CreateSpacePayload, redirectToSpace?: boolean): void => {
        dispatch(createSpace(payload, locationId, redirectToSpace ?? true));
        setIsLoading(true);
        analyticsLogger(SPACE_CREATED_SPACE, { payload, locationId });
    };

    return (
        <ModalWrapper
            isOpen={isOpen}
            onClose={onClose}
            header={enableHeader ? 'AddSpace.AddSpace' : undefined}
            size="medium"
        >
            <SpaceAddForm
                onClose={onClose}
                locationId={locationId}
                onSave={onSave}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setEnableHeader={setEnableHeader}
            />
        </ModalWrapper>
    );
};
export default SpaceAddModal;
