import { ErrorType, OrganizationInviteType, SelectedGroup } from '../models/commonTypeScript';

export enum OrganizationInvitesActionType {
    GetOrganizationInvitesInit = 'GET_ORGANIZATION_INVITES/INIT',
    GetOrganizationInvitesSuccess = 'GET_ORGANIZATION_INVITES_SUCCESS',
    GetOrganizationInvitesError = 'GET_ORGANIZATION_INVITES_ERROR',
    OrganizationInviteResponseInit = 'ORGANIZATION_INVITE_RESPONSE/INIT',
    OrganizationInviteResponseSuccess = 'ORGANIZATION_INVITE_RESPONSE_SUCCESS',
    OrganizationInviteResponseError = 'ORGANIZATION_INVITE_RESPONSE_ERROR',
    CloseHBSOnboardingModalInit = 'CLOSE_HBS_ONBOARDING_MODAL/INIT',
}

export interface GetOrganizationInvites {
    type: OrganizationInvitesActionType.GetOrganizationInvitesInit;
}
export const getOrganizationInvites = (): GetOrganizationInvites => ({
    type: OrganizationInvitesActionType.GetOrganizationInvitesInit,
});

export interface GetOrganizationInvitesSuccess {
    type: OrganizationInvitesActionType.GetOrganizationInvitesSuccess;
    invites: OrganizationInviteType[];
}
export const getOrganizationInvitesSuccess = (invites: OrganizationInviteType[]): GetOrganizationInvitesSuccess => ({
    type: OrganizationInvitesActionType.GetOrganizationInvitesSuccess,
    invites,
});

export interface GetOrganizationInvitesError {
    type: OrganizationInvitesActionType.GetOrganizationInvitesError;
}
export const getOrganizationInvitesError = (): GetOrganizationInvitesError => ({
    type: OrganizationInvitesActionType.GetOrganizationInvitesError,
});

export interface OrganizationInviteResponse {
    type: OrganizationInvitesActionType.OrganizationInviteResponseInit;
    invite: OrganizationInviteType;
    status: string;
}
export const organizationInviteResponse = (
    invite: OrganizationInviteType,
    status: string
): OrganizationInviteResponse => ({
    type: OrganizationInvitesActionType.OrganizationInviteResponseInit,
    invite,
    status,
});

export interface OrganizationInviteResponseSuccess {
    type: OrganizationInvitesActionType.OrganizationInviteResponseSuccess;
    inviteId: string;
}
export const organizationInviteResponseSuccess = (inviteId: string): OrganizationInviteResponseSuccess => ({
    type: OrganizationInvitesActionType.OrganizationInviteResponseSuccess,
    inviteId,
});

export interface OrganizationInviteResponseError {
    type: OrganizationInvitesActionType.OrganizationInviteResponseError;
    error: ErrorType;
}
export const organizationInviteResponseError = (error: ErrorType): OrganizationInviteResponseError => ({
    type: OrganizationInvitesActionType.OrganizationInviteResponseError,
    error,
});

export interface CloseHBSOnboardingModal {
    type: OrganizationInvitesActionType.CloseHBSOnboardingModalInit;
    selectedGroup: SelectedGroup;
}
export const closeHBSOnboardingModal = (selectedGroup: SelectedGroup): CloseHBSOnboardingModal => ({
    type: OrganizationInvitesActionType.CloseHBSOnboardingModalInit,
    selectedGroup,
});

export type OrganizationInvitesReducerActions =
    | GetOrganizationInvitesSuccess
    | GetOrganizationInvitesError
    | OrganizationInviteResponse
    | OrganizationInviteResponseSuccess
    | OrganizationInviteResponseError
    | CloseHBSOnboardingModal;
