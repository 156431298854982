import React, { SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../constants';
import { DashboardVisibility } from '../../models/commonEnums';
import { LocationTileContent } from '../../models/commonTypeScript';
import TileHeaderMenu from '../dashboard/TileHeaderMenu';
import LocationTileBody from './LocationTileBody';

type ParentProps = {
    location: LocationTileContent;
    visibility: DashboardVisibility;
    menuProps?: { tileId: string; tileRef: string; removeTile: (tileId: string, tileRef: string) => void };
    isDragging?: boolean;
};

type Props = ParentProps;

const LocationTile = ({ location, menuProps, isDragging, visibility }: Props): React.ReactElement => {
    const preventLink = (e: SyntheticEvent<HTMLElement>): void => {
        if (isDragging) {
            e.preventDefault();
        }
    };

    return (
        <Link
            to={`/${paths.buildings}/${location.locationId}`}
            onClick={preventLink}
            className="tile-wrapper__link page-wrapper__column"
            data-tile
        >
            <div className="tile-wrapper">
                <div className="tile-header">
                    <div className="tile-header__title tile-header__title--full-width">
                        <h2 className="tile-header__title--name">{location.name}</h2>
                    </div>
                    <div className="tile-header__controls">
                        {menuProps && (
                            <TileHeaderMenu
                                visibility={visibility}
                                tileId={menuProps.tileId}
                                onRemoveTile={(): void => menuProps.removeTile(menuProps.tileId, menuProps.tileRef)}
                                isSensorTile={false}
                            />
                        )}
                    </div>
                </div>
                <div className="tile-body">
                    <LocationTileBody numberOfDevices={location.deviceCount} />
                </div>
            </div>
        </Link>
    );
};

export default LocationTile;
