import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import ModalHeader from './ModalHeader';
import styles from './ModalWrapper.module.scss';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    children: ReactElement;
    header?: string;
    customHeader?: ReactElement;
    size: 'small' | 'medium' | 'large' | 'fullscreen';
    className?: string;
    isFullscreen?: boolean;
};
const ModalWrapper = ({
    isOpen,
    onClose,
    children,
    header,
    customHeader,
    size,
    className,
    isFullscreen,
}: Props): ReactElement => {
    return (
        <Modal
            isOpen={isOpen}
            appElement={document.body}
            onRequestClose={onClose}
            className={classNames(styles[size], className, {
                [styles.expandedView]: isFullscreen,
            })}
            overlayClassName={styles.overlay}
        >
            {customHeader || <ModalHeader headerText={header || ''} onClose={onClose} />} {children}
        </Modal>
    );
};

export default ModalWrapper;
