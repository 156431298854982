import React, { useEffect, useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { hubConnectionIsUp } from '../../commonFunctions';
import NoContent from '../../components/emptyStates/NoContent';
import { slimTilesPlaceholder } from '../../components/placeholder';
import { DeviceType, HubData } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import DeviceInfoTile from './DeviceInfoTile';

type StateProps = {
    devices: { [serialNumber: string]: DeviceType };
};

export type ParentProps = {
    displayedHub: HubData | undefined;
    loading: boolean;
};

type Props = StateProps & ParentProps;

export const HubPageContentComponent = ({ devices, displayedHub, loading }: Props): React.ReactElement => {
    const [initialHubLoading, setInitialHubLoading] = useState(loading);

    useEffect((): void => {
        if (initialHubLoading && !loading) {
            setInitialHubLoading(false);
        }
    });
    const hubDevices: DeviceType[] =
        displayedHub && displayedHub.metaData.devices
            ? Object.keys(displayedHub.metaData.devices)
                  .map(serialNumber => devices[serialNumber])
                  .filter(device => device !== undefined)
            : [];
    const hubRecentlySynced = displayedHub?.metaData?.lastSeen && hubConnectionIsUp(displayedHub.metaData.lastSeen);

    return (
        <ReactPlaceholder showLoadingAnimation ready={!initialHubLoading} customPlaceholder={slimTilesPlaceholder}>
            <div className="page-wrapper">
                {hubRecentlySynced && hubDevices.length > 0 ? (
                    hubDevices
                        .sort((device1, device2) => device1.segmentName.localeCompare(device2.segmentName))
                        .map(device => <DeviceInfoTile key={device.serialNumber} device={device} />)
                ) : (
                    <NoContent noContentText="HubStrings.NoConnectedDevices" />
                )}
            </div>
        </ReactPlaceholder>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { devices },
    } = state;
    return {
        devices,
    };
};

export default connect(mapStateToProps)(HubPageContentComponent);
