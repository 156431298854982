import React, { useEffect, useState, useRef } from 'react';
import L from 'leaflet';
import { Marker } from 'react-leaflet';
import { FloorplanDevice } from 'commons/src/models/commonTypeScript';
import DeviceMarkerIcons from './DeviceMarkerIcons';

type Props = {
    marker: FloorplanDevice | { serialNumber: string; position: { lat: number; lng: number } };
    onClick: () => void;
    isEditing: boolean;
    innerRef?: React.MutableRefObject<{ zone: L.Polygon; marker: L.Marker } | undefined>;
};
// type MarkerRef = { getLatLng: () => { lat: number; lng: number } } | null | undefined;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HubMarker = ({ marker, onClick, isEditing, innerRef }: Props): React.ReactElement => {
    const [markerPosition, setMarkerPosition] = useState(marker.position);
    const refMarker: React.Ref<L.Marker> = useRef(null);
    useEffect(() => {
        if (!innerRef) return;
        // eslint-disable-next-line no-param-reassign
        innerRef.current = {
            marker: new L.Marker(markerPosition),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            zone: [],
        };
    }, [markerPosition]);

    const onDragEnd = (): void => {
        const markerRef = refMarker.current;
        if (markerRef != null) {
            setMarkerPosition(markerRef.getLatLng());
        }
    };

    const icon: L.DivIcon = DeviceMarkerIcons.Hub;

    return (
        <Marker
            draggable={isEditing}
            ref={refMarker}
            position={markerPosition}
            icon={icon}
            eventHandlers={{ click: onClick, dragend: onDragEnd }}
        />
    );
};

export default HubMarker;
