import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import { BoldText, SubHeader, FlexWrapperSpaced } from 'commons/src/components/PDF/PdfConstants';
import { dateFormats } from 'commons/src/constants';
import { BuildingType, DayUsageHours } from 'commons/src/models/commonTypeScript';
import { PDFContext } from '../../../models/common';

const styles = StyleSheet.create({
    line2: {
        marginTop: '2vw',
        width: '33vw',
    },
    paddedView: {
        marginBottom: '3vw',
    },
});
type ScheduleProps = {
    usageHours: { [day: string]: DayUsageHours };
    txt: PDFContext;
    dateFormat: keyof typeof dateFormats;
};

const defaultUsageHours = {
    monday: { closed: false },
    tuesday: { closed: false },
    wednesday: { closed: false },
    thursday: { closed: false },
    friday: { closed: false },
    saturday: { closed: false },
    sunday: { closed: false },
};

export const dayLabels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const DaySchedule = ({ usageHours, txt, dateFormat }: ScheduleProps): React.ReactElement => {
    const updatedUsageHours: { [day: string]: DayUsageHours } = { ...defaultUsageHours, ...usageHours };
    const usageHoursAsArray = Object.keys(updatedUsageHours).map((day, i) => {
        const fromFormatted = updatedUsageHours[day]?.from
            ? moment(updatedUsageHours[day].from, 'HH:mm').format(dateFormats[dateFormat].hourFormat)
            : '';
        const toFormatted = updatedUsageHours[day]?.to
            ? moment(updatedUsageHours[day].to, 'HH:mm').format(dateFormats[dateFormat].hourFormat)
            : '';
        const value = updatedUsageHours[day]?.closed ? txt('Building.Closed') : `${fromFormatted} - ${toFormatted}`;
        return { label: dayLabels[i], value, day };
    });
    const linesOfUsageHours =
        dateFormat === 'US' ? [usageHoursAsArray.slice(0, 5), usageHoursAsArray.slice(5)] : [usageHoursAsArray];
    return (
        <View>
            <SubHeader>{txt('Building.DayScheduleOpeningHours')}</SubHeader>
            {linesOfUsageHours.map((line, i) => {
                const elementKey = `usageHoursLine${i}`;
                const elementStyle = i === 1 ? styles.line2 : undefined;
                return (
                    <FlexWrapperSpaced key={elementKey} style={elementStyle}>
                        {line.map(day => (
                            <View key={day.day}>
                                <BoldText>{txt(day.label)}</BoldText>
                                <Text>{day.value}</Text>
                            </View>
                        ))}
                    </FlexWrapperSpaced>
                );
            })}
        </View>
    );
};

type BuildingProps = {
    building: BuildingType;
    txt: PDFContext;
    dateFormat: keyof typeof dateFormats;
};

const BuildingDetails = ({ building, dateFormat, txt }: BuildingProps): React.ReactElement => (
    <View>
        <SubHeader>{building.name}</SubHeader>
        <View style={styles.paddedView}>
            <FlexWrapperSpaced>
                <View>
                    <BoldText>{txt('Address')}</BoldText>
                    <Text>{(building.address && building.address.replace(/,/g, '\n')) || '-'}</Text>
                </View>
                <View>
                    <BoldText>{txt('BuildingType')}</BoldText>
                    <Text>{building.buildingType}</Text>
                </View>
                <View>
                    <BoldText>{txt('BuildingYear')}</BoldText>
                    <Text>{building.buildingYear}</Text>
                </View>
                <View>
                    <BoldText>{txt('Ventilation')}</BoldText>
                    <Text>{building.ventilationType}</Text>
                </View>
                <View />
            </FlexWrapperSpaced>
        </View>
        <DaySchedule txt={txt} usageHours={building.usageHours} dateFormat={dateFormat} />
    </View>
);

export default BuildingDetails;
