import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { mediumSectionLoader } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { GroupType } from 'commons/src/models/commonEnums';
import { ErrorType, Group } from 'commons/src/models/commonTypeScript';
import { getSubscriptionBillingDetails } from '../../../../api/subscriptionApi';
import { A4BSupportMail } from '../../../../constants';
import { BillingDetails } from '../../../../models/subscriptionModels';
import styles from './BillingDetails.module.scss';

type Props = {
    selectedGroup?: Group;
};

const SubscriptionBillingDetails = ({ selectedGroup }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [billingDetails, setBillingDetails] = useState<BillingDetails>();
    const [billingDetailsLoading, setBillingDetailsLoading] = useState<boolean>(false);
    const [billingDetailsError, setBillingDetailsError] = useState<ErrorType>();

    const displayBillingDetails =
        (selectedGroup?.groupType === GroupType.business && !selectedGroup.managedByOrganization) ||
        selectedGroup?.groupType === GroupType.partner;

    const getBillingDetails = useCallback(async () => {
        const response = await getSubscriptionBillingDetails().catch(err => {
            setBillingDetailsError(err);
            setBillingDetailsLoading(false);
        });
        if (response) {
            setBillingDetailsLoading(false);
            setBillingDetails(response);
        }
    }, []);

    useEffect(() => {
        if (displayBillingDetails) {
            setBillingDetailsLoading(true);
            getBillingDetails().catch(err => {
                setBillingDetailsError(err);
            });
        }
    }, [displayBillingDetails]);

    if (!displayBillingDetails) {
        return <div />;
    }

    const stateAndZip = [billingDetails?.organizationDetails?.city, billingDetails?.organizationDetails?.state]
        .filter(Boolean)
        .join(', ');
    const billingAddressProperties = [
        billingDetails?.organizationDetails?.address1,
        billingDetails?.organizationDetails?.address2,
        `${stateAndZip} ${billingDetails?.organizationDetails?.zipCode || ''}`,
        billingDetails?.organizationDetails?.state,
        billingDetails?.organizationDetails?.country,
    ];
    const billingAddressAsString = billingAddressProperties.filter(Boolean).join('\n');
    const billingName =
        billingDetails?.primaryContact &&
        `${billingDetails?.primaryContact?.firstName || ''} ${billingDetails?.primaryContact?.lastName || ''}`;

    return (
        <div>
            <h2 className="settings__header">{txt('BillingDetails.BillingDetails')}</h2>
            <ReactPlaceholder ready={!billingDetailsLoading} customPlaceholder={mediumSectionLoader}>
                <div className={styles.billingDetailsSection}>
                    <div className={styles.section}>
                        <div className={styles.sectionHeader}>{txt('BillingDetails.PrimaryContact')}</div>
                        <div>{billingName || '-'}</div>
                        <div className={styles.details}>{billingDetails?.primaryContact?.email}</div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.sectionHeader}>{txt('BillingDetails.Organization')}</div>
                        <div>
                            {txt('BillingDetails.Vat')}: {billingDetails?.organizationDetails.vat || '-'}
                        </div>
                        <div>{billingAddressAsString}</div>
                    </div>
                    {billingDetailsError && (
                        <div className={styles.errorWrapper}>
                            <ResponseBox text={`ErrorCodes.${billingDetailsError?.error}`} />
                        </div>
                    )}
                    {selectedGroup.groupType === GroupType.partner && (
                        <div className={styles.supportInfo}>
                            <Trans i18nKey="Subscription.SubscriptionSupport">
                                <a id="open_intercom" href={`mailto: ${A4BSupportMail}`}>
                                    chat
                                </a>
                            </Trans>
                        </div>
                    )}
                </div>
            </ReactPlaceholder>
        </div>
    );
};

export default SubscriptionBillingDetails;
