import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import { dateFormats } from 'commons/src/constants';
import IntegrationRowContent from 'commons/src/features/integrations/IntegrationRowContent';
import { alertRuleThresholdCategories, alertSystemNotificationRules } from '../../../constants';
import { NotificationAlertEvent } from '../../../models/common';
import { Store } from '../../../reducers';

export type ParentProps = {
    alertEvent: NotificationAlertEvent;
};

type StateProps = {
    dateFormat: keyof typeof dateFormats;
};

export type Props = StateProps & ParentProps;

export const AlertEventRowComponent = ({ dateFormat, alertEvent }: Props): React.ReactElement => {
    const navigate = useNavigate();
    const { t: txt } = useTranslation();

    const goToAlertEvent = (id: string): void => navigate(`./${id}`);

    const segmentName = [txt('Device'), ': ', alertEvent.content.segmentName].join('');
    const locationName = [txt('Location'), ': ', alertEvent.content.locationName].join('');
    const hourFormat = moment
        .utc(alertEvent.dateTime)
        .local()
        .format(dateFormats[dateFormat as keyof typeof dateFormats].hourFormat);

    let rowTitle;
    if (alertEvent.content.type === 'deviceOffline') rowTitle = txt('NotificationAlerts.OfflineDeviceEventTitle');
    else {
        rowTitle = txt(alertEvent.content.type).concat(
            alertEvent.content.thresholdType === alertRuleThresholdCategories.allAbove
                ? txt('NotificationAlerts.IsHigh')
                : txt('NotificationAlerts.IsLow')
        );
    }

    return (
        <div
            tabIndex={0}
            role="button"
            onKeyUp={(e): false | void => e.key === 'Enter' && goToAlertEvent(alertEvent.id)}
            onClick={(): void => goToAlertEvent(alertEvent.id)}
            className="list__row list__row--left-aligned list__row--clickable"
            data-testid="go-to-alert-event"
        >
            <div className="notification-alert__history__sensor_content">
                <div className="notification-alert__history__sensor_content__text">{hourFormat}</div>
                <div className="notification-alert__icons">
                    <div
                        className={classNames('device-svg-icons notification-alert__icons__circle', {
                            'notification-alert__icons__circle--yellow':
                                alertEvent.icon === alertSystemNotificationRules.deviceOffline,
                        })}
                    >
                        <SensorIcon sensor={alertEvent.icon} />
                    </div>
                </div>
            </div>
            <IntegrationRowContent
                name={rowTitle}
                key={alertEvent.id}
                firstRow={segmentName}
                secondRow={locationName}
            />
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = state;

    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(AlertEventRowComponent);
