/* eslint max-len: ["error", 1000] */
import React from 'react';

const ForecastCloudy = (): React.ReactElement => (
    <svg
        version="1.1"
        className="weather-icon"
        width="30px"
        height="30px"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
    >
        <path
            d="M24.9,4.9h-0.6c-0.4-1.3-1.1-2.4-2.1-3.3c-1.3-1-2.8-1.6-4.5-1.6c-2.7,0-5.1,1.6-6.2,4
            c-0.6-0.2-1.2-0.2-1.8-0.2C6.4,3.8,3.6,6.2,3,9.3c-1.8,0.8-3,2.5-3,4.6C0,16.7,2.3,19,5.1,19h11.8c2.3,0,4.3-1.6,4.9-3.8h3.1
            c2.8,0,5.1-2.4,5.1-5.2S27.7,4.9,24.9,4.9z M16.9,17.1H5.1c-1.8,0-3.2-1.4-3.2-3.2c0-1.4,0.9-2.6,2.2-3.1l0.5-0.2L4.7,10
            c0.3-2.5,2.5-4.4,5-4.4c2.4,0,4.5,1.8,4.9,4.2l0.2,0.8h2.1c1.8,0,3.2,1.4,3.2,3.2S18.7,17.1,16.9,17.1z M24.9,13.3H22
            c-0.2-2.6-2.4-4.6-5.1-4.6h-0.6c-0.4-1.3-1.1-2.4-2.1-3.3c-0.3-0.2-0.6-0.5-0.9-0.7C14,3,15.7,1.9,17.7,1.9c2.4,0,4.5,1.8,4.9,4.2
            l0.2,0.8h2.1c1.8,0,3.2,1.4,3.2,3.2C28.1,11.9,26.7,13.3,24.9,13.3z"
        />
    </svg>
);
export default ForecastCloudy;
