import { extendTheme } from '@mui/joy/styles';
import { createTheme } from '@mui/material/styles';
import { colors } from './constants';

export const theme = createTheme({
    typography: {
        fontFamily: 'OpenSans-Regular, sans-serif',
        fontSize: 18,
    },
    palette: {
        primary: {
            main: '#3CA9F6',
        },
        secondary: {
            main: '#FFC000',
        },
        warning: {
            main: '#D32E2F',
        },
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: () => ({
                    textTransform: 'none',
                    minWidth: 150,
                    fontFamily: 'OpenSans-DemiBold, sans-serif',
                    height: 50,
                }),
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: 15,
                },
                head: {
                    fontFamily: 'OpenSans-DemiBold, sans-serif',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: 18,
                },
            },
        },
    },
});

export const joyTheme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                focusVisible: '#056AB8',
            },
        },
    },
    components: {
        JoyInput: {
            defaultProps: {},
            styleOverrides: {
                root: ({ ownerState }) => ({
                    borderRadius: '10px',
                    border: `1px solid ${colors.blackShark}`, // Black shark
                    background: colors.white,
                    ...(ownerState?.disabled && {
                        background: colors.greyPorcelain,
                    }),
                }),
            },
        },
        JoyAutocomplete: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    height: '46px',
                    border: `1px solid ${colors.greyMist}`,
                    background: colors.white,
                },
            },
        },
        JoyFormLabel: {
            styleOverrides: {
                root: {
                    color: colors.greyMist,
                },
            },
        },
    },
});
