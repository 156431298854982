import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { RequiredRoleLevel, Role } from 'commons/src/models/commonEnums';
import { getOrganizationMembers, GetOrganizationMembers } from '../../../actions/organizationMemberActions';
import { Store } from '../../../reducers';
import InviteUserForm from './InviteUserForm';
import MemberList from './MemberList';

type StateProps = {
    ownRole?: string;
};

type ActionProps = {
    onGetOrganizationMembers: () => void;
};

type Props = StateProps & ActionProps;

const UserManagement = ({ onGetOrganizationMembers, ownRole }: Props): React.ReactElement => {
    useEffect(() => {
        if (ownRole) {
            onGetOrganizationMembers();
        }
    }, [ownRole]);

    const { t: txt } = useTranslation();
    const roleAdminOrAbove = ownRole && userRoleAboveRequiredLevel(ownRole as Role, RequiredRoleLevel.ABOVE_ADMIN);

    return (
        <div>
            {roleAdminOrAbove && (
                <>
                    <h2 className="settings__header">{txt('AddUser')}</h2>
                    <InviteUserForm inModalView={false} />
                </>
            )}
            <MemberList />
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { selectedGroup },
    } = store;

    return {
        ownRole: selectedGroup && selectedGroup.role,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onGetOrganizationMembers: (): GetOrganizationMembers => dispatch(getOrganizationMembers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
