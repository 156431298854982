import React from 'react';
import { View } from '@react-pdf/renderer';
import { FlexWrapperSpaced, PageLineSlim, Graph } from 'commons/src/components/PDF/PdfConstants';
import { sensorUnits } from 'commons/src/constants';
import { DeviceWithKeyInfo, SegmentProperties } from 'commons/src/models/commonTypeScript';
import { PDFContext, RadonInsightSensorData } from '../../../../models/common';
import DeviceDetails from '../DeviceDetails';
import InsightTile from './InsightTile';

type Props = {
    thresholds: number[];
    data: RadonInsightSensorData;
    device: DeviceWithKeyInfo;
    unit: keyof typeof sensorUnits;
    txt: PDFContext;
    segmentProps: SegmentProperties;
    chartImages: { data: string; serialNumber: string }[];
};

const DeviceTile = (props: Props): React.ReactElement => {
    const { thresholds, device, data, unit, segmentProps, chartImages, txt } = props;

    const longTermRadonDescription = txt('RadonInsight.LongTermMeasurementResultText', {
        std: data.radonStep1StdDev ? `${data.radonStep1StdDev.toFixed(2)} ${sensorUnits[unit]}` : ' ',
        lld: data.radonStep1Lld ? `${data.radonStep1Lld.toFixed(2)} ${sensorUnits[unit]}` : ' ',
    });
    const workHoursRadonDescription = txt('RadonInsight.WorkingHoursMeasurementResultText', {
        std: data.radonStep2StdDev ? `${data.radonStep2StdDev.toFixed(2)} ${sensorUnits[unit]}` : ' ',
        lld: data.radonStep2Lld ? `${data.radonStep2Lld.toFixed(2)} ${sensorUnits[unit]}` : ' ',
    });
    const image = chartImages.length > 0 && chartImages.find(chart => chart.serialNumber === device.serialNumber);
    return (
        <View wrap={false}>
            <DeviceDetails txt={txt} device={device} deviceProps={segmentProps} />
            <FlexWrapperSpaced>
                <InsightTile
                    txt={txt}
                    thresholds={thresholds}
                    header="RadonInsight.LongTermMeasurement"
                    value={data.radonStep1}
                    lld={data.radonStep1Lld}
                    unit={unit}
                    description={longTermRadonDescription}
                />
                <InsightTile
                    txt={txt}
                    thresholds={thresholds}
                    header="RadonInsight.UsageHourMeasurement"
                    value={data.radonStep2}
                    lld={data.radonStep2Lld}
                    unit={unit}
                    description={workHoursRadonDescription}
                />
            </FlexWrapperSpaced>
            {image && image.data && <Graph src={image.data} />}
            <PageLineSlim />
        </View>
    );
};

export default DeviceTile;
