import Highcharts from 'highcharts';
import { colors } from 'commons/src/constants';
import { DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import styles from './UsageOverTimeDayView.module.scss';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

type Props = {
    chartData: number[];
    unusedTimeData: number[];
    categories: string[];
    selectedDevicesWithKeyInfo: DeviceWithKeyInfo[];
    onClick: (clickEvent: Highcharts.PointClickEventObject) => void;
    chartHeight: number;
    txt: (text: string) => string;
    maxDevicesBeforeScroll: number | undefined;
    allowGraphClick: boolean;
    graphId: string;
    tickInterval: number;
    maxYAxis: number;
};

const usageOverTimeDayConfig = ({
    chartData,
    unusedTimeData,
    categories,
    selectedDevicesWithKeyInfo,
    onClick,
    chartHeight,
    txt,
    maxDevicesBeforeScroll,
    allowGraphClick,
    graphId,
    tickInterval,
    maxYAxis,
}: Props) => ({
    credits: {
        enabled: false,
    },
    title: {
        text: '',
        margin: 0,
        floating: true,
    },
    chart: {
        id: graphId,
        animation: false,
        type: 'bar',
        height: chartHeight,
        spacingBottom: 5,
        backgroundColor: 'transparent',
        style: {
            fontFamily: 'OpenSans-Regular',
        },
    },
    tooltip: {
        enabled: false,
    },
    xAxis: {
        type: 'category',
        categories,
        title: {
            text: null,
        },
        labels: {
            formatter(this: Highcharts.AxisLabelsFormatterContextObject): string {
                const { value } = this;
                const deviceInfo = selectedDevicesWithKeyInfo.find(device => device.serialNumber === value);
                return deviceInfo?.segmentName || '';
            },
        },
        min: 0,
        max: maxDevicesBeforeScroll,
        scrollbar: {
            enabled: true,
        },
    },
    yAxis: {
        min: 0,
        gridLineWidth: 1,
        title: {
            text: null,
        },
        tickInterval,
        labels: {
            formatter(this: Highcharts.AxisLabelsFormatterContextObject): string {
                const { value } = this;
                const valueInHours = (value as number) / 60;
                return `${valueInHours}${txt('TimeUnit.HoursShort')}`;
            },
        },
        max: maxYAxis,
    },
    legend: { enabled: true, align: 'center', verticalAlign: 'top' },
    series: [
        {
            data: unusedTimeData,
            color: colors.greenSpring,
            name: txt('PresenceInsight.Available'),
            className: styles.graphNotInUse,
        },
        {
            data: chartData,
            color: colors.blueAura,
            name: txt('PresenceInsight.InUse'),
            className: styles.graph,
        },
    ],
    plotOptions: {
        series: {
            allowPointSelect: allowGraphClick,
            type: 'bar',
            stacking: 'normal',
            states: {
                hover: { lineWidthPlus: 0 },
            },
            animation: false,
            borderRadius: 4,
            events: {
                click(event: Highcharts.PointClickEventObject): void {
                    onClick(event);
                },
            },
        },
    },
    exporting: {
        sourceWidth: 800,
        sourceHeight: chartHeight,
        chartOptions: {
            subtitle: null,
        },
        buttons: {
            contextButton: {
                enabled: false,
            },
        },
    },
});

export default usageOverTimeDayConfig;

export type UsageOverTimeDayConfig = ReturnType<typeof usageOverTimeDayConfig>;
