import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { analyticsLogger, PageType } from '../../../analytics';
import { PUBLIC_DASHBOARD_CLICKED_PREVIEW, PUBLIC_DASHBOARD_COPIED_LINK } from '../../../analytics/AnalyticsEvents';
import { copyToClipboard, urlPublicDashboard } from '../../../commonFunctions';
import CircleButton from '../../../components/buttons/CircleButton';
import ModalHeader from '../../../components/modals/ModalHeader';
import { DashboardProps, Units } from '../../../models/commonTypeScript';
import { Store } from '../../../reducers';

type StateProps = {
    language: string;
    units: Units;
    dashboardProps?: DashboardProps;
};

type ParentProps = {
    onClose: () => void;
};

export type Props = ParentProps & StateProps;

export const SharedDashboardLinkModalComponent = ({
    language,
    units,
    onClose,
    dashboardProps,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const publicUrl = dashboardProps && dashboardProps.publicUrl;
    const [displayConfirmCopy, setDisplayConfirmCopy] = useState(false);
    const url = publicUrl ? urlPublicDashboard(publicUrl, language, units) : '';
    const redirectToPublicDashboard = (): void => {
        window.open(url, '_blank');
        analyticsLogger(PUBLIC_DASHBOARD_CLICKED_PREVIEW, { pageType: PageType.PublicDashboard });
    };
    const onCopyToClipboard = (): void => {
        copyToClipboard(url);
        setDisplayConfirmCopy(true);
        analyticsLogger(PUBLIC_DASHBOARD_COPIED_LINK, { pageType: PageType.PublicDashboard });
    };
    return (
        <Modal
            isOpen
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--size-medium"
            overlayClassName="modal modal__overlay"
        >
            <ModalHeader headerText="PublicDashboard.PreviewAndShareLink" onClose={onClose} />
            <div className="modal__content__body">
                <div className="flex flex--center text-large">
                    <a rel="noopener noreferrer" target="_blank" href={url}>
                        {url}
                    </a>
                </div>
                <div className="flex flex--center modal__content__body">
                    <div className="modal__content__body__fab-button-wrapper">
                        <CircleButton
                            onClick={redirectToPublicDashboard}
                            testAttr="open-public-dashboard-url"
                            iconName="open_in_new"
                            color="secondary"
                        />
                        <div className="text-small modal__content__body__fab-button-wrapper__text">
                            {txt('PublicDashboard.Preview')}
                        </div>
                    </div>
                    <div className="modal__content__body__fab-button-wrapper">
                        <CircleButton
                            onClick={onCopyToClipboard}
                            testAttr="copy-public-dashboard-url"
                            iconName="content_copy"
                            color={displayConfirmCopy ? 'confirmed' : 'secondary'}
                        />
                        <div className="text-small modal__content__body__fab-button-wrapper__text">
                            {txt(displayConfirmCopy ? 'Copied' : 'PublicDashboard.Copy')}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { language, units },
        dashboardData: { dashboardProps },
    } = store;
    return {
        language,
        units,
        dashboardProps,
    };
};

export default connect(mapStateToProps)(SharedDashboardLinkModalComponent);
