import { BusinessRequestType as RequestType } from 'business-dashboard/src/reducers/BusinessRequestType';
import { RequestActionType } from 'commons/src/actions/requestActions';

// Import models
import { BuildingHealthSummary, BuildingHealthRowData } from '../models/buildingHealthModels';
import { BusinessActionRequestType } from '../models/common';

export enum BuildingHealthActionType {
    FetchBuildingHealthSummary = 'FETCH_BUILDING_HEALTH_SUMMARY/INIT',
    FetchBuildingHealthSummarySuccess = 'FETCH_BUILDING_HEALTH_SUMMARY_SUCCESS',
    FetchBuildingHealthHubStats = 'FETCH_BUILDING_HEALTH_HUBS_STATS/INIT',
    FetchBuildingHealthDevicesStats = 'FETCH_BUILDING_HEALTH_DEVICES_STATS/INIT',
    FetchBuildingHealthHubsStatsSuccess = 'FETCH_BUILDING_HEALTH_HUBS_STATS_SUCCESS',
    FetchBuildingHealthDevicesStatsSuccess = 'FETCH_BUILDING_HEALTH_DEVICES_STATS_SUCCESS',
}

// ********** Summary ********* //

export interface FetchBuildingHealthSummaryData extends BusinessActionRequestType {
    type: BuildingHealthActionType.FetchBuildingHealthSummary;
    locationId: string;
}

export const fetchBuildingHealthSummary = (locationId: string): FetchBuildingHealthSummaryData => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.FetchBuildingHealthSummary,
    type: BuildingHealthActionType.FetchBuildingHealthSummary,
    locationId,
});

export interface FetchBuildingHealthSummarySuccess {
    type: BuildingHealthActionType.FetchBuildingHealthSummarySuccess;
    locationId: string;
    buildingHealthSummary: BuildingHealthSummary;
}

export const fetchBuildingHealthSummarySuccess = (
    locationId: string,
    buildingHealthSummary: BuildingHealthSummary
): FetchBuildingHealthSummarySuccess => ({
    type: BuildingHealthActionType.FetchBuildingHealthSummarySuccess,
    locationId,
    buildingHealthSummary,
});

// ********** Hub stats ********* //

export interface FetchBuildingHealthHubsStats extends BusinessActionRequestType {
    type: BuildingHealthActionType.FetchBuildingHealthHubStats;
    locationId: string;
}

export const fetchBuildingHealthHubStats = (locationId: string): FetchBuildingHealthHubsStats => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.FetchBuildingHealthHubsStats,
    type: BuildingHealthActionType.FetchBuildingHealthHubStats,
    locationId,
});

export interface FetchBuildingHealthHubsStatsSuccess {
    type: BuildingHealthActionType.FetchBuildingHealthHubsStatsSuccess;
    locationId: string;
    hubs: BuildingHealthRowData[];
}

export const fetchBuildingHealthHubsStatsSuccess = (
    locationId: string,
    hubs: BuildingHealthRowData[]
): FetchBuildingHealthHubsStatsSuccess => ({
    type: BuildingHealthActionType.FetchBuildingHealthHubsStatsSuccess,
    locationId,
    hubs,
});

// ********** Device stats ********* //

export interface FetchBuildingHealthDeviceStats extends BusinessActionRequestType {
    type: BuildingHealthActionType.FetchBuildingHealthDevicesStats;
    locationId: string;
}

export const fetchBuildingHealthDeviceStats = (locationId: string): FetchBuildingHealthDeviceStats => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.FetchBuildingHealthDevicesStats,
    type: BuildingHealthActionType.FetchBuildingHealthDevicesStats,
    locationId,
});

export interface FetchBuildingHealthDevicesStatsSuccess {
    type: BuildingHealthActionType.FetchBuildingHealthDevicesStatsSuccess;
    locationId: string;
    devices: BuildingHealthRowData[];
    totalDevices: number;
}

export const fetchBuildingHealthDevicesStatsSuccess = (
    locationId: string,
    devices: BuildingHealthRowData[],
    totalDevices: number
): FetchBuildingHealthDevicesStatsSuccess => ({
    type: BuildingHealthActionType.FetchBuildingHealthDevicesStatsSuccess,
    locationId,
    devices,
    totalDevices,
});

//* ***** Actions ******/
export type BuildingHealthActions =
    | FetchBuildingHealthSummaryData
    | FetchBuildingHealthSummarySuccess
    | FetchBuildingHealthHubsStats
    | FetchBuildingHealthDeviceStats
    | FetchBuildingHealthDevicesStatsSuccess
    | FetchBuildingHealthHubsStatsSuccess;
