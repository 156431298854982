import React from 'react';

const IconPressure = (): React.ReactElement => (
    <svg
        id="iconPressure"
        version="1.1"
        width="24px"
        height="24px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path d="M18.6286 6C19.0073 5.62129 19.6213 5.62129 20 6C24.4183 10.4183 24.4183 17.5817 20 22C19.7358 22.2642 19.3433 22.3531 18.991 22.2286C16.6504 21.401 14.3215 20.991 12 20.991C9.67855 20.991 7.34958 21.401 5.00896 22.2286C4.65669 22.3531 4.2642 22.2642 4 22C-0.418282 17.5817 -0.418281 10.4183 4 6C4.37871 5.62129 4.99272 5.62129 5.37143 6C5.75014 6.37871 5.75014 6.99272 5.37143 7.37143C1.85162 10.8912 1.716 16.5137 4.96458 20.1956C7.3012 19.4348 9.64741 19.0515 12 19.0515C14.3526 19.0515 16.6988 19.4348 19.0354 20.1956C22.284 16.5137 22.1484 10.8912 18.6286 7.37143C18.2499 6.99272 18.2499 6.37871 18.6286 6Z" />
        <path d="M16.7766 4.77082C16.5763 5.26753 16.0113 5.50783 15.5146 5.30753C13.2656 4.40067 10.729 4.39866 8.48253 5.30083C7.98554 5.50042 7.42084 5.25933 7.22125 4.76233C7.02166 4.26534 7.26275 3.70064 7.75975 3.50105C10.4715 2.41203 13.527 2.41483 16.2399 3.50877C16.7366 3.70907 16.9769 4.27411 16.7766 4.77082Z" />
        <path d="M16.6181 8.18467C17.0399 8.49055 17.1253 9.06913 16.809 9.47697L12.9909 14.4C12.6746 14.8078 12.0763 14.8905 11.6545 14.5846C11.2328 14.2787 11.1473 13.7002 11.4636 13.2923L15.2818 8.36928C15.5981 7.96144 16.1964 7.87879 16.6181 8.18467Z" />
        <path d="M10 13.8462C10 12.6566 10.9972 11.6923 12.2273 11.6923C13.4573 11.6923 14.4545 12.6566 14.4545 13.8462C14.4545 15.0357 13.4573 16 12.2273 16C10.9972 16 10 15.0357 10 13.8462Z" />
    </svg>
);

export default IconPressure;
