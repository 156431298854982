import React, { useState } from 'react';
import { Option } from 'commons/src/components/dropdown/MultipleAttrDropdown';
import ModalWrapper from 'commons/src/components/modals/ModalWrapper';
import { IndoorSpace, SpaceDeviceEntity } from '../../../../models/spaceModels';
import { ModalState } from './moveSpaceCommons';
import styles from './SpaceMoveDeviceModal.module.scss';
import SpaceMoveDeviceModalStep1 from './SpaceMoveDeviceStep1';
import SpaceMoveDeviceModalStep2 from './SpaceMoveDeviceStep2';
import SpaceMoveDeviceModalStep3 from './SpaceMoveDeviceStep3';

type MoveDeviceModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    locationId: string;
    space: IndoorSpace;
    device: SpaceDeviceEntity;
};

const SpaceMoveDeviceModal = ({
    isOpen,
    closeModal,
    space,
    locationId,
    device,
}: MoveDeviceModalProps): React.ReactElement => {
    const [modalState, setModalState] = useState<ModalState>(ModalState.STEP1);
    const [deviceName, setDeviceName] = useState<string>('');
    const [selectedBuilding, setSelectedBuilding] = useState<Option | undefined>(undefined);
    const [selectedSpace, setSelectedSpace] = useState<Option | undefined>(undefined);
    const [startNewSegment, setStartNewSegment] = useState<boolean | undefined>(undefined);

    return (
        <ModalWrapper
            isOpen={isOpen}
            onClose={closeModal}
            header={modalState === ModalState.STEP3 ? 'MoveSpaceDevice.ConfirmMove' : 'MoveSpaceDevice.MoveDevice'}
            size="medium"
            className={styles.modalHeight}
        >
            <div className={styles.body}>
                {modalState === ModalState.STEP1 && (
                    <SpaceMoveDeviceModalStep1
                        closeModal={closeModal}
                        locationId={locationId}
                        space={space}
                        deviceName={deviceName}
                        setDeviceName={setDeviceName}
                        setModalState={setModalState}
                        selectedSpace={selectedSpace}
                        setSelectedSpace={setSelectedSpace}
                        selectedBuilding={selectedBuilding}
                        setSelectedBuilding={setSelectedBuilding}
                    />
                )}
                {modalState === ModalState.STEP2 && selectedSpace && (
                    <SpaceMoveDeviceModalStep2
                        space={space}
                        setModalState={setModalState}
                        selectedSpace={selectedSpace}
                        setStartNewSegment={setStartNewSegment}
                        startNewSegment={startNewSegment}
                    />
                )}
                {modalState === ModalState.STEP3 &&
                    startNewSegment !== undefined &&
                    deviceName &&
                    selectedSpace &&
                    selectedBuilding && (
                        <SpaceMoveDeviceModalStep3
                            closeModal={closeModal}
                            locationId={locationId}
                            space={space}
                            device={device}
                            startNewSegment={startNewSegment}
                            selectedDeviceName={deviceName}
                            selectedSpace={selectedSpace}
                            selectedBuilding={selectedBuilding}
                            setModalState={setModalState}
                        />
                    )}
            </div>
        </ModalWrapper>
    );
};

export default SpaceMoveDeviceModal;
