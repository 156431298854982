import React from 'react';
import { Paper } from '@mui/material';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { RectShape, TextRow } from 'react-placeholder/lib/placeholders';
import { colors } from 'commons/src/constants';
import styles from './MaterialTablePlaceholder.module.scss';

type MaterialTablePlaceholderProps = {
    rows?: number;
    cells?: number;
};

const MaterialTablePlaceholder = ({ rows = 5, cells = 5 }: MaterialTablePlaceholderProps): React.JSX.Element => {
    return (
        <Paper sx={{ width: '100%', borderRadius: '10px', boxShadow: 1 }}>
            <table className={styles.container}>
                <thead className={classNames(styles.container, styles.header)}>
                    <tr>
                        <td>
                            <TextRow color={colors.greyAsh} className={styles.placeholderHeader} maxHeight={40} />
                        </td>
                    </tr>
                </thead>
                <tbody className={classNames(styles.container, styles.body)}>
                    {[...Array(rows)].map(() => {
                        return (
                            <tr className={styles.placeholderRow} key={uniqueId()}>
                                {[...Array(cells)].map(() => {
                                    return (
                                        <td className={styles.container} key={uniqueId()}>
                                            <RectShape
                                                color={colors.greyAsh}
                                                className={classNames(styles.placeholderCell, 'show-loading-animation')}
                                            />
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Paper>
    );
};

export default MaterialTablePlaceholder;
