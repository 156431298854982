import React from 'react';
import { connect } from 'react-redux';
import { dateFormats } from 'commons/src/constants';
import { Resolution, SensorTypes, TimePeriod } from 'commons/src/models/commonEnums';
import { BuildingInsight } from '../../../../models/buildingModels';
import { Store } from '../../../../reducers';
import humanizedTimeString from '../buildingInsightCommonFunctions';
import DeviceBreakDown from '../DeviceBreakDown';
import styles from './ThresholdBreachReport.module.scss';

type ParentProps = {
    insight: BuildingInsight;
    selectedPeriod: {
        toDate: string;
        fromDate: string;
        resolution: Resolution;
        name: TimePeriod;
    };
    highThreshold: string;
    lowThreshold?: string;
    timeZone?: string;
    serialNumber: string;
    sensor: SensorTypes;
};

type StateProps = {
    dateFormat: keyof typeof dateFormats;
};

type Props = StateProps & ParentProps;

const ThresholdBreachBreakDownPerDevice = ({
    dateFormat,
    timeZone,
    insight,
    highThreshold,
    lowThreshold,
    selectedPeriod,
    serialNumber,
    sensor,
}: Props): React.ReactElement => {
    const {
        totalDeviceData: { devicesOverTime, devicesInTimeFrame },
    } = insight;
    const insightForDevice = devicesOverTime[serialNumber] || [];
    const selectedDeviceProps = devicesInTimeFrame.find(device => device.serialNumber === serialNumber);

    const totalBreachTime = insightForDevice.reduce((totalTime, data) => {
        return totalTime + data.underThresholdsTime + data.overThresholdsTime;
    }, 0);
    return (
        <div className={styles.deviceBreakDown}>
            <div className={styles.breakDownNaming}>
                <div className="text-bold text-large">{selectedDeviceProps?.name}</div>
                <div className="text-medium">{humanizedTimeString(totalBreachTime)}</div>
            </div>
            <div className={styles.breakDownGraph}>
                <div className={styles.graphWrapperContent}>
                    <DeviceBreakDown
                        insight={insight}
                        selectedPeriod={selectedPeriod}
                        selectedLowThreshold={lowThreshold}
                        dateFormat={dateFormat}
                        selectedHighThreshold={highThreshold}
                        selectedDevice={serialNumber}
                        timeZone={timeZone}
                        sensor={sensor}
                        allowGraphClick={false}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = store;
    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(ThresholdBreachBreakDownPerDevice);
