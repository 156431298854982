export const countryInfoLinks = {
    AT: 'https://www.ages.at/en/topics/radiation-protection/radon-an-invisible-threat/radon-measurement/',
    BE: 'https://fank.fgov.be/de/dossiers/radon-und-radioaktivitaet-ihrer-umgebung/was-ist-radon',
    CA: 'https://www.canada.ca/en/health-canada/services/health-risks-safety/radiation/radon.html',
    CZ: 'http://www.radonovyprogram.cz/',
    DK: 'https://www.sst.dk/da/Viden/Straaling/Straaling-i-hverdagen/Radon',
    FI: 'http://www.stuk.fi/sateily-ymparistossa/radon/fi_FI/mita_radon_on/',
    FR: 'https://solidarites-sante.gouv.fr/sante-et-environnement/batiments/article/radon',
    DE: 'https://www.bfs.de/EN/topics/ion/environment/radon/radon_node.html',
    GR: 'https://eeae.gr/en/services/radon-measurements',
    IE: 'http://www.epa.ie/radon/',
    IT: 'https://www.iss.it/radon/',
    LU: 'https://sante.public.lu/fr/prevention/radon/index.html',
    NO: 'www.nrpa.no',
    PT: 'https://dre.pt/legislacao-consolidada/-/lc/118367403/201904180129/73663396/diploma/indice?p_p_state=maximized',
    ES: 'https://www.csn.es/radon',
    SI: 'https://www.zvd.si/media/medialibrary/2019/09/Zavod_za_varstvo_pri_delu_RDV_4_2018_Meritve_radona_po_Sloveniji.pdf',
    PL: 'https://isap.sejm.gov.pl/isap.nsf/download.xsp/WDU20010030018/U/D20010018Lj.pdf',
    SE: 'https://www.stralsakerhetsmyndigheten.se/omraden/radon/radon-i-bostader-och-allmanna-lokaler/',
    CH: 'http://www.ch-radon.ch',
    US: 'http://www.epa.gov/radon/ ',
    GB: 'https://www.ukradon.org/information/',
};
export const whoRadonInfoLink = 'https://www.who.int/news-room/fact-sheets/detail/radon-and-health';

export default countryInfoLinks;
