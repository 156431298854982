import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import CircleButton from 'commons/src/components/buttons/CircleButton';
import CheckBox from 'commons/src/components/input/Checkbox';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { dayIndex } from 'commons/src/constants';
import { DayUsageHours } from 'commons/src/models/commonTypeScript';
import { Days } from '../../../models/common';
import DaySchedule, { DayOpeningTime } from './DayUsageTime';

export type BuildingUsageHours = {
    [day: string]: DayUsageHours;
};

export type Props = {
    updateBuildingUsageHours: (usageHours: BuildingUsageHours) => void;
    buildingUsageHours: BuildingUsageHours;
    setValidDays: (validDays: { [day: string]: boolean }) => void;
    validDays: { [day: string]: boolean };
};

const BuildingOccupancySchedule = ({
    updateBuildingUsageHours,
    buildingUsageHours,
    validDays,
    setValidDays,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const defaultUsageHours = { from: '07:00', to: '17:00', closed: false };

    const enableDay = (e: SyntheticEvent<HTMLButtonElement>): void => {
        const { id } = e.currentTarget;
        updateBuildingUsageHours({
            ...buildingUsageHours,
            [id]: { ...defaultUsageHours, closed: !buildingUsageHours[id].closed },
        });
        setValidDays({ ...validDays, [id]: true });
    };

    const copyToAll = (day: string): void => {
        const dayToCopy = buildingUsageHours[day];
        const copiedUsageHours: { [day: string]: DayUsageHours } = {};
        Object.keys(buildingUsageHours).forEach(key => {
            const isActive = !buildingUsageHours[key].closed;
            if (isActive) {
                copiedUsageHours[key] = dayToCopy;
            } else copiedUsageHours[key] = buildingUsageHours[key];
        });
        updateBuildingUsageHours(copiedUsageHours);
    };

    const updateUsageHours = ({
        day,
        usageHours,
        dayIsValid,
    }: {
        day: string;
        usageHours: DayOpeningTime;
        dayIsValid: boolean;
    }): void => {
        updateBuildingUsageHours({
            ...buildingUsageHours,
            [day]: { ...defaultUsageHours, ...usageHours },
        });
        setValidDays({ ...validDays, [day]: dayIsValid });
    };

    const usageHoursEnabled = Object.keys(buildingUsageHours).some(key => !buildingUsageHours[key].closed);
    const enableOpeningHours = (): void => {
        const dayArray = [
            Days.monday,
            Days.tuesday,
            Days.wednesday,
            Days.thursday,
            Days.friday,
            Days.saturday,
            Days.sunday,
        ];
        const enabledDisabled: { [day: string]: DayUsageHours } = {};
        const allDaysValid: { [day: string]: boolean } = {};
        dayArray.forEach(key => {
            const dayIsWeekend = key === Days.saturday || key === Days.sunday;
            enabledDisabled[key] = { ...defaultUsageHours, closed: usageHoursEnabled || dayIsWeekend };
            allDaysValid[key] = true;
        });

        updateBuildingUsageHours(enabledDisabled);
        setValidDays(allDaysValid);
    };

    const getDayIndex = (obj: { [day: string]: number }, key: string): number => obj[key];
    const sortedWeek = Object.keys(buildingUsageHours).sort(
        (day1, day2) => getDayIndex(dayIndex, day1) - getDayIndex(dayIndex, day2)
    );

    const openDays = sortedWeek.filter((day: keyof typeof buildingUsageHours) => !buildingUsageHours[day].closed);

    return (
        <>
            <div className="form__row">
                <h3>{txt('Building.DayScheduleOpeningHours')}</h3>
            </div>
            <div className="form__row">
                <div className="flex flex--align-center">
                    <MaterialIcon extraClass="small-padding-right" name="info" />
                    <div className="text-small">{txt('Building.WhySetDaySchedule')}</div>
                </div>
            </div>
            <div className="form__row form__row--padded-small">
                <CheckBox
                    label="Building.EnableDayScheduleOpeningHours"
                    id="EnableOpeningHours"
                    onChange={enableOpeningHours}
                    checked={usageHoursEnabled}
                />
            </div>
            {usageHoursEnabled && (
                <>
                    <div className="form__row form__row--slim-margin form__row--padded-small">
                        {sortedWeek.map((day: keyof typeof buildingUsageHours) => {
                            const label = txt(day.toString().charAt(0).toUpperCase() + day.toString().slice(1));
                            return (
                                <CircleButton
                                    key={`enable-day-${day}`}
                                    id={day.toString()}
                                    onClick={enableDay}
                                    testAttr={`enable-day-${day}`}
                                    iconName={<div>{label.charAt(0).toUpperCase()}</div>}
                                    size="small"
                                    color={buildingUsageHours[day].closed ? 'secondary' : 'primary'}
                                />
                            );
                        })}
                    </div>
                    <div className="form__row form__row--padded-medium">
                        {openDays.map((day: keyof typeof buildingUsageHours, index) => {
                            const label = day.toString().charAt(0).toUpperCase() + day.toString().slice(1);
                            return (
                                <DaySchedule
                                    key={`selector-${day}`}
                                    label={label}
                                    id={day.toString()}
                                    currentValue={{ ...defaultUsageHours, ...buildingUsageHours[day] }}
                                    setSchedule={updateUsageHours}
                                    includeCopyButton={index === 0}
                                    copyToAll={copyToAll}
                                />
                            );
                        })}
                    </div>
                </>
            )}
        </>
    );
};

export default BuildingOccupancySchedule;
