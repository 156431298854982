import React, { useState } from 'react';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { sensorUnits, dateFormats, sensorFullNameKey } from 'commons/src/constants';
import { getWeatherIcon, dayWeatherCodes } from 'commons/src/features/outdoorAirQualityPage/WeatherAttrs';
import { BuildingType } from 'commons/src/models/commonTypeScript';
import { Store } from 'commons/src/reducers';
import { weekdayDateString } from '../insightFunctions';
import OutdoorSensorGraph from './OutdoorSensorGraph';

export type ParentProps = {
    header: string;
    loading: boolean;
    indoorData: { [fetchedPeriod: string]: number[][] };
    averageIndoorValue: { [fetchedPeriod: string]: number };
    outdoorData: number[][];
    unit: keyof typeof sensorUnits;
    building: BuildingType;
    weatherIcons: [number, keyof typeof dayWeatherCodes][];
    fromDate: Moment;
};

type StateProps = {
    dateFormat: keyof typeof dateFormats;
};

type Props = ParentProps & StateProps;

export const OutdoorInsightGraphTileComponent = ({
    header,
    fromDate,
    loading,
    indoorData,
    averageIndoorValue,
    outdoorData,
    unit,
    building,
    dateFormat,
    weatherIcons,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [dateLabel, setDateLabel] = useState('');
    const [weatherIconNum, setWeatherIconNum] = useState<keyof typeof dayWeatherCodes>();

    const updateDate = (date: number): void => {
        const formattedDate = weekdayDateString(date, dateFormat);
        if (dateLabel !== formattedDate && !loading) {
            setDateLabel(formattedDate);
            if (weatherIcons) {
                const dateWeatherIcon = weatherIcons.find(element => element[0] === date);
                if (dateWeatherIcon) {
                    setWeatherIconNum(dateWeatherIcon[1]);
                }
            }
        }
    };

    return (
        <div className="form__wide-container insight-tile outdoor-insight-tile insight-tile--full-width">
            <h3 className="outdoor-insight-tile__header__sensor-tile">{txt(sensorFullNameKey(header))}</h3>
            <div className="insight-tile__content outdoor-insight-tile__content--graph">
                <div className="outdoor-insight-view outdoor-insight-view__graph-date-info">
                    <p className="outdoor-insight-view__graph-date-info__paragraph text-small">
                        {dateLabel || (
                            <span className="outdoor-insight-view__graph-date-info__paragraph--no-uppercase">
                                {txt('OutdoorInsight.InitialDateText')}
                            </span>
                        )}
                    </p>
                    <div>
                        <div className="tile-header__weather">
                            {weatherIconNum && (
                                <div
                                    className="tile-header__weather--description"
                                    title={txt(`Weather${weatherIconNum}`)}
                                >
                                    {getWeatherIcon(weatherIconNum)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {!loading && (
                    <OutdoorSensorGraph
                        sensor={header}
                        updateDate={updateDate}
                        indoorData={indoorData.week}
                        outdoorData={outdoorData}
                        averageIndoorValue={averageIndoorValue.week}
                        unit={unit}
                        usageHours={building.usageHours}
                        fromDate={fromDate}
                        timeZone={(building && building.timezone) || 'UTC'}
                    />
                )}
            </div>
            <div className="inline-header-lined inline-header-lined--no-margin-top" />
            {indoorData.week.length === 0 && (
                <div className="device-insight__error-response device-insight__error-response--slim text-small">
                    <MaterialIcon extraClass="icon-color--red" name="error_outline" />
                    {txt('OutdoorInsight.MissingIndoorData')}
                </div>
            )}
            <h3>{txt(`OutdoorInsight.Outdoor${header}Title`)}</h3>
            <p className="insight-tile__content__text insight-tile__content__text-block">
                {txt(`OutdoorInsight.Outdoor${header}Description`)}
            </p>
            <div className="insight-tile__content insight-tile__content--spaced" />
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = state;
    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(OutdoorInsightGraphTileComponent);
