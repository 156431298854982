import { PredefinedProperty } from 'commons/src/models/commonEnums';
import { IndoorSpaceWithProps } from '../../../models/spaceModels';

export const compareNumbers = (a: number, b: number, ascendingNumber?: boolean): number => {
    const aNotANumber = Number.isNaN(a);
    const bNotANumber = Number.isNaN(b);
    if (!aNotANumber && !bNotANumber) return ascendingNumber ? a - b : b - a;
    if (aNotANumber && !bNotANumber) return 1;
    if (!aNotANumber && bNotANumber) return -1;
    if (ascendingNumber) return 1;
    return 0;
};

export const sortSpaces = (
    spaceList: IndoorSpaceWithProps[],
    sortByProp: string,
    asc: boolean
): IndoorSpaceWithProps[] => {
    const sortedList = spaceList.sort((a, b): number => {
        let sortValue = 0;
        if (sortByProp === 'floor') {
            const aFloor = a.spaceVariables[PredefinedProperty.Floor];
            const bFloor = b.spaceVariables[PredefinedProperty.Floor];
            sortValue = compareNumbers(parseInt(aFloor, 10), parseInt(bFloor, 10));
        } else if (sortByProp !== 'name') {
            const aDevices = a.devices
                .map(device => {
                    if ('currentSensorValues' in device) {
                        // remove this check once hubs have been removed from device list
                        const sensor = device.currentSensorValues.find(cv => cv.type === sortByProp);
                        return sensor?.value || undefined;
                    }
                    return undefined;
                })
                .filter(Number) as number[];

            const bDevices = b.devices
                .map(device => {
                    if ('currentSensorValues' in device) {
                        // remove this check once hubs have been removed from device list
                        const sensor = device.currentSensorValues.find(cv => cv.type === sortByProp);
                        return sensor?.value || undefined;
                    }
                    return undefined;
                })
                .filter(Number) as number[];
            const aEdgeValue = asc ? Math.min(...aDevices) : Math.max(...aDevices);
            const bEdgeValue = asc ? Math.min(...bDevices) : Math.max(...bDevices);
            const aValue = aDevices.length === 0 ? NaN : aEdgeValue;
            const bValue = bDevices.length === 0 ? NaN : bEdgeValue;
            sortValue = compareNumbers(aValue, bValue, asc);
        }
        if (sortByProp === 'name' || sortValue === 0) {
            return a.name.localeCompare(b.name);
        }
        return sortValue;
    });
    if (asc && sortByProp && (sortByProp === 'floor' || sortByProp === 'name')) return sortedList.reverse();
    return sortedList;
};
