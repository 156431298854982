import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Tooltip.module.scss';

type TooltipProps = {
    text: string;
    position?: 'top' | 'bottom' | 'left' | 'right';
    children: ReactElement;
};

const Tooltip = ({ text, position = 'top', children }: TooltipProps): ReactElement => {
    const { t: txt } = useTranslation();

    return (
        <div className={styles.tooltipContainer}>
            {children}
            <div className={`${styles.tooltip} ${styles[position]}`}>{txt(text)}</div>
        </div>
    );
};

export default Tooltip;
