import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { closeWelcomeModal, fetchDashboard, stopPollingDashboard } from '../../actions/DashboardActions';
import { pollData } from '../../actions/LocationActions';
import { analyticsLogger, PageType } from '../../analytics';
import { DASHBOARD_VIEWED_DASHBOARD } from '../../analytics/AnalyticsEvents';
import Error from '../../components/errorComponents/Error';
import PageHeader from '../../components/headers/PageHeader';
import WelcomeModal from '../../components/modals/WelcomeModal';
import { squareTilesPlaceholder } from '../../components/placeholder';
import { DashboardProps, DashboardTile, Group } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import DashboardSubHeader from './DashboardSubHeader';
import DashboardTilesView from './DashboardTilesView';

type StateProps = {
    loading: boolean;
    displayWelcomeModal: boolean;
    error: boolean;
    statusMessage: string;
    dashboardProps: DashboardProps | undefined;
    dashboardTiles: DashboardTile[];
    groups: Group[];
};

type Props = StateProps;

export const DashboardComponent = ({
    statusMessage,
    dashboardProps,
    loading,
    displayWelcomeModal,
    error,
    dashboardTiles,
    groups,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (groups.length > 0) {
            dispatch(fetchDashboard());
            dispatch(pollData());
        }
        return (): void => {
            dispatch(stopPollingDashboard());
        };
    }, [groups]);

    useEffect(() => {
        analyticsLogger(DASHBOARD_VIEWED_DASHBOARD, { pageType: PageType.Dashboard });
    }, []);

    const onClose = (): void => {
        dispatch(closeWelcomeModal());
    };

    if (error) {
        return <Error errorText={statusMessage} />;
    }

    const dashboardName =
        !dashboardProps || dashboardProps.name === 'Personal' ? txt('Dashboard') : dashboardProps.name;

    return (
        <div className="dashboard" data-dashboard>
            <PageHeader title={dashboardName} />
            {displayWelcomeModal && <WelcomeModal onClose={onClose} />}
            <ReactPlaceholder
                showLoadingAnimation
                ready={!loading || dashboardTiles.length > 0}
                customPlaceholder={squareTilesPlaceholder}
            >
                <DashboardSubHeader />
                <div className="page-wrapper page-wrapper--grid-layout" data-dashboard-finished-loading>
                    <DashboardTilesView />
                </div>
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        login: { displayWelcomeModal },
        userSettings: { loading: loadingUser, groups },
        dashboardData: { loading: dashboardLoading, error, statusMessage, dashboardProps, dashboardTiles },
        locations: { loading: locationsLoading },
        app: { error: appError },
    } = state;

    const loading = dashboardLoading || locationsLoading || loadingUser;
    return {
        loading,
        displayWelcomeModal,
        error: error || appError,
        statusMessage,
        dashboardProps,
        dashboardTiles,
        groups,
    };
};

export default connect(mapStateToProps)(DashboardComponent);
