import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_ENABLE_LINDAB } from 'commons/src/analytics/AnalyticsEvents';
import FlipButton from 'commons/src/components/buttons/FlipButton';
import LindabLogo from 'commons/src/img/lindabLogo';
import { BleMode } from 'commons/src/models/commonEnums';
import { BuildingConfiguration } from 'commons/src/models/commonTypeScript';
import { updateLocationConfigWithDeviceType } from '../../../actions/locationActions';
import { Store } from '../../../reducers';
import styles from './LindabConnectionSection.module.scss';

type StateProps = {
    configurations: { [locationId: string]: BuildingConfiguration };
};

type PassedProps = {
    locationId: string;
};

export type Props = StateProps & PassedProps;

export const LindabConnectionSectionComponent = ({ locationId, configurations }: Props): React.ReactElement => {
    const dispatch = useDispatch();
    const { t: txt } = useTranslation();

    const bleMode = configurations[locationId]?.configuration?.bleMode;

    const [toggleOn, setToggleOn] = useState<boolean>(bleMode !== undefined && bleMode === BleMode.Lindab);

    const onFlipToggle = (): void => {
        const newLindabStateIsOn = !toggleOn;
        setToggleOn(newLindabStateIsOn);
        const config = { bleMode: newLindabStateIsOn ? BleMode.Lindab : BleMode.Default };
        analyticsLogger(BUILDING_ENABLE_LINDAB, { lindab: newLindabStateIsOn ? 'on' : 'off' });
        dispatch(updateLocationConfigWithDeviceType(config, locationId));
    };

    return (
        <div className="settings-details-container__content">
            <LindabLogo />
            <div className={styles.content}>
                <div className={styles.paragraph}>{txt('LindabConnection.ToggleDescription')}</div>
                <FlipButton
                    onClick={onFlipToggle}
                    leftSelected={toggleOn}
                    leftText="On"
                    rightText="Off"
                    testIdOn="lindab-toggle-on"
                    testIdOff="lindab-toggle-off"
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        buildings: { configurations },
    } = state;

    return {
        configurations,
    };
};

export default connect(mapStateToProps)(LindabConnectionSectionComponent);
