import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import styles from './MobileTable.module.scss';

interface WithChildren {
    children: React.ReactElement | React.ReactElement[];
}

export const Row = ({ children }: WithChildren): React.ReactElement => (
    <div className="building-status-page-section__row">{children}</div>
);

const onlineIcon = [
    'BuildingStatus.NoOfflineBuildings',
    'BuildingStatus.NoOfflineHubs',
    'BuildingStatus.NoOfflineSensorDevices',
];

export const EmptyTable = ({ emptyTableText }: { emptyTableText: string }): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className={styles.emptyTable}>
            {onlineIcon.includes(emptyTableText) ? <MaterialIcon extraClass={styles.emptyTableIcon} name="wifi" /> : ''}
            {txt(emptyTableText)}
        </div>
    );
};
