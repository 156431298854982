import { ErrorType } from '../models/commonTypeScript';

export const VALIDATE_SERIALNUMBER = 'VALIDATE_SERIALNUMBER';
export interface ValidateSerialnumber {
    type: typeof VALIDATE_SERIALNUMBER;
    serialNumber: string;
    checkCode?: string;
}
export const validateSerialnumber = ({
    serialNumber,
    checkCode,
}: {
    serialNumber: string;
    checkCode?: string;
}): ValidateSerialnumber => ({
    type: VALIDATE_SERIALNUMBER,
    serialNumber,
    checkCode,
});

export const VALIDATE_SERIALNUMBER_SUCCESS = 'VALIDATE_SERIALNUMBER_SUCCESS';
export interface ValidateSerialnumberSuccess {
    type: typeof VALIDATE_SERIALNUMBER_SUCCESS;
    payload: { serialNumber: string; checkCode?: string };
}
export const validateSerialnumberSuccess = (payload: {
    serialNumber: string;
    checkCode?: string;
}): ValidateSerialnumberSuccess => ({
    type: VALIDATE_SERIALNUMBER_SUCCESS,
    payload,
});

export const VALIDATE_SERIALNUMBER_ERROR = 'VALIDATE_SERIALNUMBER_ERROR';
export interface ValidateSerialnumberError {
    type: typeof VALIDATE_SERIALNUMBER_ERROR;
    payload: { serialNumber: string; checkCode?: string; error: ErrorType };
}
export const validateSerialnumberError = (payload: {
    serialNumber: string;
    checkCode?: string;
    error: ErrorType;
}): ValidateSerialnumberError => ({
    type: VALIDATE_SERIALNUMBER_ERROR,
    payload,
});

export const VALIDATE_SERIALNUMBER_RESET = 'VALIDATE_SERIALNUMBER_RESET';
export interface ValidateSerialnumberReset {
    type: typeof VALIDATE_SERIALNUMBER_RESET;
}
export const validateSerialnumberReset = (): ValidateSerialnumberReset => ({ type: VALIDATE_SERIALNUMBER_RESET });

export type AddDeviceReducerActions =
    | ValidateSerialnumber
    | ValidateSerialnumberSuccess
    | ValidateSerialnumberError
    | ValidateSerialnumberReset;
