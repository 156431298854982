import React, { useState } from 'react';
import moment from 'moment';
import { analyticsLogger } from '../../../analytics';
import { BUILDING_SHOW_HIDE_HUB_SECTION } from '../../../analytics/AnalyticsEvents';
import AccordionButton from '../../../components/buttons/AccordionButton';
import { userIsHbs } from '../../../components/findUserType';
import { dateFormats } from '../../../constants';
import { HubData } from '../../../models/commonTypeScript';
import HubListElement from '../../hubs/HubListElement';

type Props = {
    hubs: HubData[];
    dateFormat: keyof typeof dateFormats;
};

export const HubSectionComponent = ({ dateFormat, hubs }: Props): React.ReactElement => {
    const [hubSectionOpen, setHubSectionOpen] = useState(true);
    const nowTime = moment();
    const isBusiness = userIsHbs();
    const oneHourAgo = nowTime.clone().subtract(1, 'hours').format('X');

    const numberOfDevicesRegisteredToHub = (hub: HubData): number =>
        hub.metaData.devices ? Object.keys(hub.metaData.devices).length : 0;

    const numberOfDevicesConnectedWithHub = (hub: HubData): number =>
        hub.metaData.devices
            ? Object.values(hub.metaData.devices).filter(deviceSynced => deviceSynced > parseInt(oneHourAgo, 10)).length
            : 0;

    const openCloseHubSection = (): void => {
        analyticsLogger(BUILDING_SHOW_HIDE_HUB_SECTION, { setOpen: !hubSectionOpen });
        setHubSectionOpen(!hubSectionOpen);
    };

    const renderHubs = (): React.ReactElement[] =>
        hubs
            .sort((hub1, hub2) => hub1.name.localeCompare(hub2.name))
            .map(hub => {
                const numberOfDevices = numberOfDevicesRegisteredToHub(hub);
                const numberOfConnectedDevices = numberOfDevicesConnectedWithHub(hub);
                return (
                    <HubListElement
                        key={hub.serialNumber}
                        name={hub.name}
                        metaData={hub.metaData}
                        serialNumber={hub.serialNumber}
                        numberOfConnectedDevices={numberOfConnectedDevices}
                        numberOfDevices={numberOfDevices}
                        dateFormat={dateFormat}
                    />
                );
            });

    return (
        <div className="page-wrapper">
            <div className="page-section--full-width">
                {isBusiness && hubs.length > 0 && (
                    <AccordionButton onClick={openCloseHubSection} expanded={hubSectionOpen} title="hubFullName" />
                )}
                {hubSectionOpen && <div className="page-wrapper">{renderHubs()}</div>}
            </div>
        </div>
    );
};

export default HubSectionComponent;
