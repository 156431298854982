import { RequestActionType } from 'commons/src/actions/requestActions';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { BusinessActionRequestType, GetOrganizationLogoResponse } from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum OrganizationPropertiesActionType {
    GetOrganizationLogoInit = 'GET_ORGANIZATION_LOGO/INIT',
    GetOrganizationLogoSuccess = 'GET_ORGANIZATION_LOGO/SUCCESS',
    GetOrganizationLogoError = 'GET_ORGANIZATION_LOGO/ERROR',
    UpdateOrganizationLogoInit = 'UPDATE_ORGANIZATION_LOGO/INIT',
    UpdateOrganizationLogoSuccess = 'UPDATE_ORGANIZATION_LOGO/SUCCESS',
    UpdateOrganizationPropertiesInit = 'UPDATE_ORGANIZATION_PROPERTIES/INIT',
    UpdateOrganizationPropertiesSuccess = 'UPDATE_ORGANIZATION_PROPERTIES/SUCCESS',
}

export interface GetOrganizationLogo extends BusinessActionRequestType {
    type: OrganizationPropertiesActionType.GetOrganizationLogoInit;
}
export const getOrganizationLogo = (): GetOrganizationLogo => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.GetOrganizationLogo,
    type: OrganizationPropertiesActionType.GetOrganizationLogoInit as OrganizationPropertiesActionType.GetOrganizationLogoInit,
});

export interface GetOrganizationLogoSuccess extends BusinessActionRequestType {
    type: OrganizationPropertiesActionType.GetOrganizationLogoSuccess;
    response: GetOrganizationLogoResponse;
}
export const getOrganizationLogoSuccess = (response: GetOrganizationLogoResponse): GetOrganizationLogoSuccess => ({
    requestActionType: RequestActionType.Success as RequestActionType.Success,
    requestType: RequestType.GetOrganizationLogo,
    type: OrganizationPropertiesActionType.GetOrganizationLogoSuccess as OrganizationPropertiesActionType.GetOrganizationLogoSuccess,
    response,
});

export interface GetOrganizationLogoError extends BusinessActionRequestType {
    type: OrganizationPropertiesActionType.GetOrganizationLogoError;
    error: ErrorType;
}
export const getOrganizationLogoError = (error: ErrorType): GetOrganizationLogoError => ({
    requestActionType: RequestActionType.Error as RequestActionType.Error,
    requestType: RequestType.GetOrganizationLogo,
    type: OrganizationPropertiesActionType.GetOrganizationLogoError as OrganizationPropertiesActionType.GetOrganizationLogoError,
    error,
});

export type UpdateOrganizationLogoType = { fileName: string; logoImage: string };

export interface UpdateOrganizationLogoInit extends BusinessActionRequestType {
    type: OrganizationPropertiesActionType.UpdateOrganizationLogoInit;
    fileName: string;
    logoImage: string;
}
export const updateOrganizationLogoInit = (fileName: string, logoImage: string): UpdateOrganizationLogoInit => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdateOrganizationLogo,
    type: OrganizationPropertiesActionType.UpdateOrganizationLogoInit as OrganizationPropertiesActionType.UpdateOrganizationLogoInit,
    fileName,
    logoImage,
});

export interface UpdateOrganizationLogoSuccess extends BusinessActionRequestType {
    type: OrganizationPropertiesActionType.UpdateOrganizationLogoSuccess;
    response: GetOrganizationLogoResponse;
}
export const updateOrganizationLogoSuccess = (
    response: GetOrganizationLogoResponse
): UpdateOrganizationLogoSuccess => ({
    requestActionType: RequestActionType.Success as RequestActionType.Success,
    requestType: RequestType.UpdateOrganizationLogo,
    type: OrganizationPropertiesActionType.UpdateOrganizationLogoSuccess as OrganizationPropertiesActionType.UpdateOrganizationLogoSuccess,
    response,
});

export type UpdateOrganizationPropertiesType = {
    name: string;
};

export interface UpdateOrganizationPropertiesInit extends BusinessActionRequestType {
    type: OrganizationPropertiesActionType.UpdateOrganizationPropertiesInit;
    properties: UpdateOrganizationPropertiesType;
}
export const updateOrganizationProperties = (
    properties: UpdateOrganizationPropertiesType
): UpdateOrganizationPropertiesInit => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdateOrganizationProperties,
    type: OrganizationPropertiesActionType.UpdateOrganizationPropertiesInit as OrganizationPropertiesActionType.UpdateOrganizationPropertiesInit,
    properties,
});

export type OrganizationPropertiesAction =
    | GetOrganizationLogo
    | GetOrganizationLogoSuccess
    | GetOrganizationLogoError
    | UpdateOrganizationLogoInit
    | UpdateOrganizationLogoSuccess
    | UpdateOrganizationPropertiesInit;
