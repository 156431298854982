import { getSelectedGroupFromStorage, setSelectedGroupInStorage } from '../../components/findUserType';
import config from '../../config';
import { LocalStorageKeys } from '../../constants';
import { SelectedGroup } from '../../models/commonTypeScript';

export const clearStorage = (): void => {
    const language = localStorage.getItem(LocalStorageKeys.language);
    const selectedGroup: SelectedGroup | null = getSelectedGroupFromStorage();

    localStorage.clear();
    if (language) localStorage.setItem(LocalStorageKeys.language, language);
    if (selectedGroup) setSelectedGroupInStorage(selectedGroup);
};

export const logoutAndClearStorage = (): void => {
    // duplicating this code instead of calling the clear storage function to make sure this is done before redirect.
    const language = localStorage.getItem(LocalStorageKeys.language);
    const selectedGroup: SelectedGroup | null = getSelectedGroupFromStorage();

    localStorage.clear();
    if (language) localStorage.setItem(LocalStorageKeys.language, language);
    if (selectedGroup) setSelectedGroupInStorage(selectedGroup);
    window.location.assign(config.accountsLogoutUrl);
};
