import React, { useEffect, useState } from 'react';
import { Document, Font, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { analyticsLogger, PageType, ReportType } from 'commons/src/analytics';
import { INSIGHT_DOWNLOADED_REPORT } from 'commons/src/analytics/AnalyticsEvents';
import { mapDeviceType } from 'commons/src/commonFunctions';
import DownloadPDFView from 'commons/src/components/PDF/DownloadPdfView';
import {
    HeaderBlock,
    Logo,
    Body,
    colorStyles,
    SmallLogo,
    Footer,
    LogoContainer,
} from 'commons/src/components/PDF/PdfConstants';
import { dateFormats, colors } from 'commons/src/constants';
import DemiBold from 'commons/src/fonts/open-sans-v28-latin-600.ttf';
import RegularFont from 'commons/src/fonts/open-sans-v28-latin-regular.ttf';
import { AnyDeviceType, DeviceType, LocationType } from 'commons/src/models/commonTypeScript';
import BusinessLogo from '../../../img/logos/business-logo.png';
import { PDFContext } from '../../../models/common';

Font.register({ family: 'DemiBold', src: DemiBold });
Font.register({ family: 'Regular', src: RegularFont });

const styles = StyleSheet.create({
    headerSubText: {
        fontSize: 13,
    },
    buildingAddress: {
        fontSize: 10,
        marginBottom: 10,
        marginTop: 10,
    },
    headerText: {
        fontSize: 30,
        fontFamily: 'DemiBold',
    },
    reportText: {
        fontSize: 10,
        fontFamily: 'Regular',
        marginBottom: 15,
    },
    table: {
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 0,
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderRightWidth: 0,
        fontSize: 10,
    },
    deviceTypeCol: {
        width: '15%',
    },
    serialNumberCol: {
        width: '18%',
    },
    deviceNameCol: {
        width: '35%',
        paddingRight: 5,
    },
    calibrationCol: {
        width: '17%',
    },
    dateAddedCol: {
        width: '15%',
    },
    tableHeader: {
        margin: 'auto',
        flexDirection: 'row',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: colors.greyNepal,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderRightWidth: 0,
        fontFamily: 'DemiBold',
        fontSize: 10,
    },
});

export type GenerateProps = {
    dateFormat: keyof typeof dateFormats;
    building: LocationType | undefined;
    devices: DeviceType[];
};

type PdfProperties = {
    building: LocationType;
    txt: PDFContext;
    todayDate: string;
    calibrationDate: string;
    devices: DeviceType[];
    dateFormat: keyof typeof dateFormats;
};

// Create Document Component
const PdfDocument = React.memo(
    ({ building, txt, todayDate, devices, calibrationDate, dateFormat }: PdfProperties): React.ReactElement => {
        const deviceTypeTranslated = (deviceType: AnyDeviceType): string => {
            return txt(`${mapDeviceType(deviceType)}FullName`).replace('CO₂', 'CO2');
        };
        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Document>
                <Body>
                    <HeaderBlock>
                        <View>
                            <Text style={styles.headerText}>{txt('Calibration.CalibrationReport')}</Text>
                            <Text style={styles.headerSubText}>
                                {txt('Report.Generated')}
                                {': '}
                                {todayDate}
                            </Text>
                            <Text style={styles.headerSubText}>{txt('Calibration.IncludesOnlyAirthingsDevices')}</Text>
                        </View>
                        <LogoContainer>
                            <Logo src={BusinessLogo} />
                        </LogoContainer>
                    </HeaderBlock>
                    <View>
                        <Text style={styles.headerSubText}>{building.name}</Text>
                        <Text style={styles.buildingAddress}>
                            {(building.address && building.address.replace(/,/g, '\n')) || '-'}
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.reportText}>{txt('Calibration.ReportText')}</Text>
                    </View>
                    <View style={styles.table}>
                        <View style={styles.tableHeader}>
                            <View style={styles.deviceTypeCol}>
                                <Text>{txt('DeviceType')}</Text>
                            </View>
                            <View style={styles.serialNumberCol}>
                                <Text>{txt('SerialNumber')}</Text>
                            </View>
                            <View style={styles.deviceNameCol}>
                                <Text>{txt('Calibration.DeviceName')}</Text>
                            </View>
                            <View style={styles.calibrationCol}>
                                <Text>{txt('Calibration.CalibrationDate')}</Text>
                            </View>
                            <View style={styles.dateAddedCol}>
                                <Text>{txt('Calibration.DateAdded')}</Text>
                            </View>
                        </View>
                        {devices.map(device => (
                            <View key={device.serialNumber} style={styles.tableRow}>
                                <View style={styles.deviceTypeCol}>
                                    <Text>{deviceTypeTranslated(device.type)}</Text>
                                </View>
                                <View style={styles.serialNumberCol}>
                                    <Text>{device.serialNumber}</Text>
                                </View>
                                <View style={styles.deviceNameCol}>
                                    <Text>{device.segmentName}</Text>
                                </View>
                                <View style={styles.calibrationCol}>
                                    <Text>{calibrationDate}</Text>
                                </View>
                                <View style={styles.dateAddedCol}>
                                    <Text>
                                        {device.segmentStart &&
                                            dayjs(device.segmentStart).format(dateFormats[dateFormat].shortFormat)}
                                    </Text>
                                </View>
                            </View>
                        ))}
                    </View>
                    <Footer fixed>
                        <Text
                            style={colorStyles.smallText}
                            render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }): string =>
                                `${pageNumber} / ${totalPages}`
                            }
                            fixed
                        />
                        <SmallLogo src={BusinessLogo} />
                    </Footer>
                </Body>
            </Document>
        );
    }
);

const logDownloadReport = (): void => {
    analyticsLogger(INSIGHT_DOWNLOADED_REPORT, { pageType: PageType.Insight, reportType: ReportType.Calibration });
};

export const GeneratePdfComponent = ({ dateFormat, building, devices }: GenerateProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    let pdfProps: PdfProperties | undefined;
    const todayDate = dayjs().format(dateFormats[dateFormat].shortFormat);
    const calibrationDate = dayjs().subtract(7, 'days').format(dateFormats[dateFormat].shortFormat);

    if (building) {
        pdfProps = {
            building,
            txt,
            todayDate,
            devices,
            calibrationDate,
            dateFormat,
        };
    }
    const [properties, setProperties] = useState<PdfProperties | undefined>(undefined);

    useEffect((): void => {
        if (!properties) {
            setProperties(pdfProps);
        }
    }, [pdfProps]);

    if (!properties) {
        return <div />;
    }

    const fileName = `Calibrated-devices_${properties.building.name}_${todayDate}.pdf`;
    return (
        <div
            className="form__button-container"
            onClick={logDownloadReport}
            onKeyDown={logDownloadReport}
            role="button"
            tabIndex={0}
        >
            <PDFDownloadLink document={<PdfDocument {...properties} />} fileName={fileName}>
                {({ loading }): React.ReactElement => <DownloadPDFView loading={loading} fileName={fileName} />}
            </PDFDownloadLink>
        </div>
    );

    // use this to get inline version of pdf.
    /*  return (
        <div className="form form__wide-container">
            <PDFViewer>
                <PdfDocument {...properties} />
            </PDFViewer>
        </div>
    ); */
};

export default GeneratePdfComponent;
