import { generatePath, Params } from 'react-router-dom';
import { roleRestrictions } from 'commons/src/constants';
import { TabOption } from 'commons/src/models/menuModels';
import { paths } from '../../../constants';
import { SortParams, SubAccountDetails } from '../../../models/partner';

type SimpleSubAccount = { userGroupId: string; organizationId?: string; pending?: boolean };

export interface PartnerUrlParams extends Params {
    childUserGroupId: string;
    serialNumber?: string;
}

const toParams = ({ userGroupId }: SimpleSubAccount): PartnerUrlParams => ({ childUserGroupId: userGroupId });

const overviewPath = (customer: SimpleSubAccount): string =>
    generatePath(paths.partnerCustomerPage, toParams(customer));

export const subAccountLandingPage = (customer: SimpleSubAccount): string => overviewPath(customer);

const partnerSubAccountMenus = (customer?: SubAccountDetails, sortHistory?: SortParams): TabOption[] => {
    if (!customer) return [];
    const devicePageId = 'partner-sub-account-devices';
    const menuItems: TabOption[] = [
        {
            text: 'Partner.SubAccountDetails',
            route: paths.partnerCustomerPage,
            path: `/${overviewPath(customer)}`,
            id: 'partner-sub-account-details',
            testAttr: 'partner-sub-account-details',
            requiredRoleLevel: roleRestrictions.partner,
            requiredGroupTypes: [],
            excludedFeatureToggles: [],
            requiredFeatureToggles: [],
            data: sortHistory,
        },
    ];
    return menuItems.filter(({ id }) => !(id === devicePageId && customer.pending));
};

export default partnerSubAccountMenus;
