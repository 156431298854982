import { HomeReportActionType, HomeReportReducerActions } from '../actions/HomeReportActions';
import { HomeReportResponse, ErrorType } from '../models/commonTypeScript';

const initialState = {
    loading: false,
    error: undefined,
    reportData: undefined,
};

export type GenerateRadonHomeReportState = {
    loading: boolean;
    error?: ErrorType;
    reportData: HomeReportResponse | undefined;
};

export default (state = initialState, action: HomeReportReducerActions): GenerateRadonHomeReportState => {
    switch (action.type) {
        case HomeReportActionType.GenerateRadonHomeReportInit:
            return {
                ...state,
                loading: true,
                error: undefined,
                reportData: undefined,
            };
        case HomeReportActionType.GenerateRadonHomeReportSuccess:
            return {
                ...state,
                loading: false,
                error: undefined,
                reportData: action.reportData,
            };
        case HomeReportActionType.GenerateRadonHomeReportError:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
