import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import RequestActions from 'commons/src/models/RequestTypes';
import displayAlertBoxSaga from 'commons/src/sagas/displayAlertBox';
import { toErrorType, toErrorTypeWithMessage } from 'commons/src/sagas/isErrorType';
import {
    FetchPartnerDetailsSuccess,
    fetchPartnerDetailsSuccess,
    fetchPartnerOfflineDevicesSuccess,
    FetchPartnerOfflineDevicesSuccess,
    PartnerActions,
    PartnerCustomersResponse,
    PartnerDetailsResponse,
    TogglePartnerBranding,
    togglePartnerBrandingSuccess,
} from '../../actions/partnerActions';
import { fetchPartnerInfo, getOfflineDevices, toggleBranding } from '../../api/partnerDetails';
import { BusinessRequestType as RequestType } from '../../reducers/BusinessRequestType';

type FetchPartnerDetailsSagaReturnType = Generator<
    CallEffect<PartnerDetailsResponse> | PutEffect<FetchPartnerDetailsSuccess> | RequestActions,
    void,
    PartnerDetailsResponse
>;
export function* fetchPartnerDetailsSaga(): FetchPartnerDetailsSagaReturnType {
    try {
        const response = yield call(fetchPartnerInfo);
        yield put(fetchPartnerDetailsSuccess(response));
        yield put(requestSuccess(RequestType.FetchPartnerDetails, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchPartnerDetails, RequestActionType.Error));
    }
}

export function* togglePartnerBrandingSaga({ partnerBranding }: TogglePartnerBranding): Generator {
    try {
        yield call(toggleBranding, partnerBranding);
        yield put(togglePartnerBrandingSuccess(partnerBranding));
        yield put(requestSuccess(RequestType.TogglePartnerBranding, RequestActionType.Success));
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(requestError(errorWithMessage.error, RequestType.TogglePartnerBranding, RequestActionType.Error));
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

type FetchPartnerOfflineDevicesSagaReturnType = Generator<
    CallEffect<PartnerCustomersResponse> | PutEffect<FetchPartnerOfflineDevicesSuccess> | RequestActions,
    void,
    PartnerCustomersResponse
>;
export function* fetchPartnerOfflineDevicesSaga(): FetchPartnerOfflineDevicesSagaReturnType {
    try {
        const response = yield call(getOfflineDevices);
        yield put(fetchPartnerOfflineDevicesSuccess(response));
        yield put(requestSuccess(RequestType.FetchPartnerOfflineDevices, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchPartnerOfflineDevices, RequestActionType.Error));
    }
}

export default function* PartnerSagas(): Generator {
    yield all([
        takeEvery(PartnerActions.FetchPartnerDetailsInit, fetchPartnerDetailsSaga),
        takeEvery(PartnerActions.TogglePartnerBrandingInit, togglePartnerBrandingSaga),
        takeEvery(PartnerActions.FetchOfflineDevicesInit, fetchPartnerOfflineDevicesSaga),
    ]);
}
