import { RequestActionType } from 'commons/src/actions/requestActions';
import { sensorUnits } from 'commons/src/constants';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { DeviceType, SensorThresholds } from 'commons/src/models/commonTypeScript';
import {
    BusinessActionRequestType,
    IaqInsightData,
    RadonInsightSensorData,
    VirusRiskInsightData,
} from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum InsightActionType {
    FetchRadonInsightDataInit = 'FETCH_RADON_INSIGHT_DATA/INIT',
    FetchIaqInsightDataInit = 'FETCH_IAQ_INSIGHT_DATA/INIT',
    FetchVirusRiskInsightDataInit = 'FETCH_VIRUS_RISK_INSIGHT_DATA/INIT',
}

export type FetchRadonInsightDataType = {
    from: string;
    to: string;
    serialNumbers: string[];
    locationId: string;
    includeLogo: boolean;
};

export interface FetchRadonInsightData extends BusinessActionRequestType {
    type: InsightActionType.FetchRadonInsightDataInit;
    payload: FetchRadonInsightDataType;
}

export const fetchRadonInsightData = (payload: FetchRadonInsightDataType): FetchRadonInsightData => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchRadonInsightData,
    type: InsightActionType.FetchRadonInsightDataInit,
    payload,
});

export interface RadonInsightResponse {
    locationId: string;
    reports: RadonInsightSensorData[];
    errors: { error: string; serialNumber: string }[];
    radonReportId: string;
    radonUnit: keyof typeof sensorUnits;
    thresholds: number[];
}

export interface RadonInsightSuccessData extends RadonInsightResponse {
    from: string;
    to: string;
    serialNumbers: string[];
    locationId: string;
    includeLogo: boolean;
}

export const FETCH_RADON_INSIGHT_DATA_SUCCESS = 'FETCH_RADON_INSIGHT_DATA_SUCCESS';
export interface FetchRadonInsightDataSuccess {
    type: typeof FETCH_RADON_INSIGHT_DATA_SUCCESS;
    payload: RadonInsightSuccessData;
}

export const fetchRadonInsightDataSuccess = (payload: RadonInsightSuccessData): FetchRadonInsightDataSuccess => ({
    type: FETCH_RADON_INSIGHT_DATA_SUCCESS,
    payload,
});

export type FetchIaqInsightDataType = {
    from: string;
    to: string;
    serialNumbers: string[];
    locationId: string;
    sensors: string[];
    includeLogo?: boolean;
};

export interface FetchIaqInsightData extends BusinessActionRequestType {
    type: InsightActionType.FetchIaqInsightDataInit;
    payload: FetchIaqInsightDataType;
}
export const fetchIaqInsightData = (payload: FetchIaqInsightDataType): FetchIaqInsightData => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchIaqInsightData,
    type: InsightActionType.FetchIaqInsightDataInit,
    payload,
});

export interface IaqInsightResponse {
    locationId: string;
    reportId: string;
    sensorReports: {
        [sensorName: string]: IaqInsightData;
    };
    serialNumbers: string[];
    includeLogo?: boolean;
}
export const FETCH_IAQ_INSIGHT_DATA_SUCCESS = 'FETCH_IAQ_INSIGHT_DATA_SUCCESS';
export interface FetchIaqInsightDataSuccess {
    type: typeof FETCH_IAQ_INSIGHT_DATA_SUCCESS;
    payload: IaqInsightResponse;
}
export const fetchIaqInsightDataSuccess = (payload: IaqInsightResponse): FetchIaqInsightDataSuccess => ({
    type: FETCH_IAQ_INSIGHT_DATA_SUCCESS,
    payload,
});

export type FetchVirusRiskInsightDataType = {
    from: string;
    to: string;
    serialNumbers: string[];
    locationId: string;
    includeLogo?: boolean;
};

export interface FetchVirusRiskInsightData extends BusinessActionRequestType {
    type: InsightActionType.FetchVirusRiskInsightDataInit;
    payload: FetchVirusRiskInsightDataType;
}

export const fetchVirusRiskInsightData = (payload: FetchVirusRiskInsightDataType): FetchVirusRiskInsightData => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchVirusRiskInsightData,
    type: InsightActionType.FetchVirusRiskInsightDataInit,
    payload,
});

export interface VirusRiskInsightResponse {
    locationId?: string;
    reportId: string;
    thresholds: { [sensor: string]: SensorThresholds };
    deviceReports: VirusRiskInsightData[];
    possibleRiskFactors: { riskName: string; sensorName: SensorTypes }[];
    errors: { error: string; serialNumber: string }[];
    serialNumbers?: string[];
    includeLogo?: boolean;
}
export const FETCH_VIRUS_RISK_INSIGHT_DATA_SUCCESS = 'FETCH_VIRUS_RISK_INSIGHT_DATA_SUCCESS';
export interface FetchVirusRiskInsightDataSuccess {
    type: typeof FETCH_VIRUS_RISK_INSIGHT_DATA_SUCCESS;
    payload: VirusRiskInsightResponse;
}
export const fetchVirusRiskInsightDataSuccess = (
    payload: VirusRiskInsightResponse
): FetchVirusRiskInsightDataSuccess => ({
    type: FETCH_VIRUS_RISK_INSIGHT_DATA_SUCCESS,
    payload,
});

export const SET_INSIGHT_SELECTED_DEVICES = 'SET_INSIGHT_SELECTED_DEVICES';
export interface SetInsightSelectedDevices {
    type: typeof SET_INSIGHT_SELECTED_DEVICES;
    devices: DeviceType[];
}
export const setInsightSelectedDevices = (devices: DeviceType[]): SetInsightSelectedDevices => ({
    type: SET_INSIGHT_SELECTED_DEVICES,
    devices,
});

export type SetInsightSelectedDevicesReturnType = ReturnType<typeof setInsightSelectedDevices>;
export type InsightAction = FetchRadonInsightData | FetchIaqInsightData;

export type AllInsightActions =
    | InsightAction
    | FetchRadonInsightDataSuccess
    | SetInsightSelectedDevices
    | FetchIaqInsightDataSuccess
    | FetchVirusRiskInsightDataSuccess;
