import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { validateSerialnumberReset } from 'commons/src/actions/AddDeviceActions';
import { clearError } from 'commons/src/actions/requestActions';
import { IndoorSpace, SpaceDevice } from '../../../../models/spaceModels';
import { BusinessRequestType as RequestType } from '../../../../reducers/BusinessRequestType';
import SpaceAddDevice from '../addDevice/SpaceAddDevice';
import styles from './SpaceDevices.module.scss';
import SpaceDevicesElement from './SpaceDevicesElement';

export type Props = {
    space: IndoorSpace;
    show: () => void;
    hide: () => void;
    showForm: boolean;
    devices: SpaceDevice[];
};

const SpaceDevices = ({ space, show, hide, showForm, devices }: Props): React.ReactElement => {
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        if (!devices?.length) {
            show();
        } else hide();
        dispatch(clearError(RequestType.AddDeviceToSpace));
        dispatch(validateSerialnumberReset());
    }, [space]);

    return (
        <div className={styles.spaceDevices}>
            <ul className={styles.overviewList}>
                {devices?.map(device => (
                    <SpaceDevicesElement device={device} space={space} key={device.serialNumber} />
                ))}
            </ul>
            {showForm && <SpaceAddDevice space={space} buildingId={space.locationId} close={hide} />}
        </div>
    );
};

export default SpaceDevices;
