import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import Checkbox from '../../input/Checkbox';
import styles from './TreeviewListItem.module.scss';

type Props = {
    onChange: (e: SyntheticEvent<HTMLElement>) => void;
    name: string;
    id: string;
    level: number;
    canExpand: boolean;
    isExpanded: boolean;
    setIsExpanded?: () => void;
    checked: boolean;
};
const TreeViewListItem = ({
    onChange,
    id,
    checked,
    setIsExpanded,
    name,
    level,
    canExpand,
    isExpanded,
}: Props): React.ReactElement => (
    <li
        key={id}
        className={classNames(styles.item, {
            'tree-view__option--level-1': level === 1,
        })}
    >
        {canExpand && setIsExpanded && (
            <i
                tabIndex={0}
                role="button"
                onClick={setIsExpanded}
                onKeyUp={(e): void | boolean => e.key === 'Enter' && setIsExpanded()}
                className="material-symbols-outlined"
            >
                {isExpanded ? 'arrow_drop_down' : 'arrow_right'}
            </i>
        )}
        <Checkbox
            label={name}
            onChange={onChange}
            checked={checked}
            key={`tree-view-item-checkbox-${id}`}
            id={id}
            testId={`data-${id}`}
        />
    </li>
);

export default TreeViewListItem;
