import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { statusColor } from 'commons/src/commonFunctions';
import {
    coloredCircleStyles,
    colorStyles,
    sensorIconSource,
    ColoredCircle,
    DeviceIcon,
    InsightWrapper,
    SensorUnitText,
} from 'commons/src/components/PDF/PdfConstants';
import { sensorUnits } from 'commons/src/constants';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { PDFContext, ThresholdRange } from 'commons/src/models/commonTypeScript';

type AverageValueProps = {
    txt: PDFContext;
    value: number;
    ranges: ThresholdRange[];
    unit: keyof typeof sensorUnits;
    headerText: string;
    sensor: string;
    displayCircle: boolean;
};

const componentStyles = StyleSheet.create({
    margin: { marginBottom: '1vw' },
    paddedAverage: { marginLeft: 50 },
    sensorValueWrapper: {
        fontSize: '10',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    valueWrapper: {
        paddingLeft: '2vw',
        paddingRight: '2vw',
    },
    paddedValueWrapper: {
        marginLeft: '50',
        paddingLeft: '2vw',
        paddingRight: '2vw',
    },
    sensorValue: {
        fontSize: '18',
        fontFamily: 'DemiBold',
    },
});

export const AverageValuesComponent = (props: AverageValueProps): React.ReactElement => {
    const { txt, value, ranges, unit, headerText, sensor, displayCircle } = props;
    const color = statusColor(ranges, value);
    const sensorHasValue = value !== undefined && value !== null && !Number.isNaN(value);
    const displayedValue =
        sensorUnits[unit] === sensorUnits.pci ? value && value.toFixed(1) : value && value.toFixed(0);
    const displayedUnit =
        sensor === SensorTypes.temp ? `${sensorUnits[unit]}${(unit as string).toUpperCase()}` : sensorUnits[unit];

    return (
        <InsightWrapper style={componentStyles.margin}>
            {displayCircle ? (
                <ColoredCircle style={[coloredCircleStyles[color as keyof typeof coloredCircleStyles]]}>
                    <DeviceIcon src={sensorIconSource(sensor) || ''} />
                </ColoredCircle>
            ) : undefined}
            <View style={displayCircle ? componentStyles.valueWrapper : componentStyles.paddedValueWrapper}>
                <Text style={colorStyles.smallText}>{txt(headerText)}</Text>
                <View style={componentStyles.sensorValueWrapper}>
                    <Text style={componentStyles.sensorValue}>
                        {sensorHasValue ? displayedValue : txt('NotAvailableAbbreviation')}
                    </Text>
                    <SensorUnitText>{displayedUnit}</SensorUnitText>
                </View>
            </View>
        </InsightWrapper>
    );
};

export default AverageValuesComponent;
