import { takeEvery, call, put, all } from 'redux-saga/effects';
import { fetchDashboard } from '../../actions/DashboardActions';
import {
    deleteDeviceSuccess,
    deleteEndedSegmentSuccess,
    DeleteDevice,
    DeleteEndedSegment,
    DeviceActionType,
} from '../../actions/DeviceActions';
import { fetchLocationsHistory } from '../../actions/LocationActions';
import { RequestActionType, requestError, requestSuccess } from '../../actions/requestActions';
import { deleteDevice } from '../../api/devices';
import { deleteEndedSegment } from '../../api/segment';
import { CommonRequestType } from '../../reducers/requestReducer';
import displayAlertBoxSaga from '../displayAlertBox';
import { toErrorType } from '../isErrorType';

export function* deleteDeviceSaga({ serialNumber, locationId }: DeleteDevice): Generator {
    try {
        yield call(deleteDevice, serialNumber.toString());
        yield put(deleteDeviceSuccess(serialNumber, locationId));
        yield put(requestSuccess(CommonRequestType.DeleteDevice, RequestActionType.Success));
        yield put(fetchLocationsHistory(locationId));
        yield put(fetchDashboard());
        yield call(displayAlertBoxSaga, 'DeleteDeviceSuccess', false, true);
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield call(displayAlertBoxSaga, 'DeleteDeviceError', true, true);
        yield put(requestError(errorAsErrorType, CommonRequestType.DeleteDevice, RequestActionType.Error));
    }
}

export function* deleteEndedSegmentSaga({ serialNumber, segmentId }: DeleteEndedSegment): Generator {
    try {
        yield call(deleteEndedSegment, serialNumber, segmentId);
        yield put(deleteEndedSegmentSuccess(serialNumber, segmentId));
        yield put(requestSuccess(CommonRequestType.DeleteEndedSegment, RequestActionType.Success));
        yield call(displayAlertBoxSaga, 'DeleteEndedSegmentSuccess', false, true);
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, CommonRequestType.DeleteEndedSegment, RequestActionType.Error));
    }
}

export default function* DeleteDeviceSaga(): Generator {
    yield all([
        takeEvery(DeviceActionType.DeleteDevice, deleteDeviceSaga),
        takeEvery(DeviceActionType.DeleteEndedSegment, deleteEndedSegmentSaga),
    ]);
}
