import React, { SyntheticEvent, useState } from 'react';
import moment from 'moment';
import CircleButton from 'commons/src/components/buttons/CircleButton';
import Input from 'commons/src/components/input/Input';

export type DayOpeningTime = { from: string; to: string; closed: boolean };

export type Props = {
    label: string;
    id: string;
    currentValue: DayOpeningTime;
    setSchedule: (payload: { usageHours: DayOpeningTime; day: string; dayIsValid: boolean }) => void;
    copyToAll: (day: string) => void;
    includeCopyButton: boolean;
};

const DaySchedule = (props: Props): React.ReactElement => {
    const timeRegex = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;
    const { setSchedule, currentValue, label, id, copyToAll, includeCopyButton } = props;
    const { from, to, closed } = currentValue;
    const [dayIsValid, setDayIsValid] = useState(true);
    const [fromDateValid, setFromDateValid] = useState(true);
    const [toDateValid, setToDateValid] = useState(true);

    const validateDay = (): void => {
        let validSchedule;
        const fromValid = timeRegex.test(from);
        const toValid = timeRegex.test(to);
        setFromDateValid(fromValid);
        setToDateValid(toValid);

        if (closed) {
            validSchedule = true;
        } else if (from.length === 0 || to.length === 0) {
            validSchedule = false;
        } else {
            validSchedule = moment(from, 'HH:mm').isBefore(moment(to, 'HH:mm'));
        }
        setDayIsValid(validSchedule);
        setSchedule({
            usageHours: currentValue,
            day: id,
            dayIsValid: validSchedule && fromValid && toValid,
        });
    };
    const copyAll = (): void => {
        copyToAll(id);
    };

    const onChangeTimeSlot = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        const timeId = e.currentTarget.id;
        const validCharacters = value.replace(/[^0-9:]/g, '');
        setSchedule({
            usageHours: {
                from: timeId.includes('From') ? validCharacters : from,
                to: timeId.includes('To') ? validCharacters : to,
                closed: false,
            },
            dayIsValid,
            day: id,
        });
        setFromDateValid(true);
        setToDateValid(true);
    };

    return (
        <div className="form__row form__row--slim-margin form__row--center-vertical">
            <div className="form__row__time-input">
                <Input
                    label={label}
                    onChange={onChangeTimeSlot}
                    currentValue={from}
                    type="time"
                    id={`${id}From`}
                    maxLength={5}
                    onBlur={validateDay}
                    validate={!fromDateValid}
                    hint="Building.ValidTimeFormat"
                    required
                    testId="from-time-input"
                />
            </div>
            <span className="labeled-text no-margin">{'\u2014'}</span>
            <div className="form__row__time-input">
                <Input
                    label=""
                    currentValue={to}
                    onChange={onChangeTimeSlot}
                    type="time"
                    id={`${id}To`}
                    maxLength={5}
                    validate={!dayIsValid || !toDateValid}
                    onBlur={validateDay}
                    hint={!toDateValid ? 'Building.ValidTimeFormat' : 'Building.DayScheduleFromBeforeTo'}
                    required
                    testId="to-time-input"
                />
            </div>
            {includeCopyButton && (
                <CircleButton
                    onClick={copyAll}
                    iconName="content_copy"
                    testAttr="copy-to-all"
                    hoverText="CopyToAll"
                    color="secondary"
                />
            )}
        </div>
    );
};

export default DaySchedule;
