import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getDotColor } from '../../commonFunctions';
import { sensorUnits, statusColors } from '../../constants';
import { SensorIcon } from './SensorUnit';

type Props = {
    thresholds: number[];
    value: number | undefined;
    unit: keyof typeof sensorUnits;
    sensorType: string;
    lld: number | undefined;
};

export const CircledSensorUnitComponent = ({ thresholds, value, unit, sensorType, lld }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const sensorHasValue = value !== undefined && value !== null && !Number.isNaN(value);
    const displayLldValue = !!(lld && value) && lld > value;
    let displayedValue: string | number | undefined = displayLldValue ? lld : value;
    const statusColor = getDotColor(thresholds, sensorType, displayedValue);
    const displayedUnit = unit && sensorUnits[unit];
    if (displayedValue) {
        displayedValue =
            displayedValue && displayedUnit === sensorUnits.pci ? displayedValue.toFixed(1) : displayedValue.toFixed(0);
    }

    return (
        <div className="insight-tile__content insight-tile__content--sensor">
            <div
                className={classNames('list-tile__status-circle list-tile__status-circle--large', {
                    'sensor__line--ok': statusColor === statusColors.green,
                    'sensor__line--alert': statusColor === statusColors.red,
                    'sensor__line--warning': statusColor === statusColors.yellow,
                    'sensor__line--disabled': statusColor === statusColors.grey,
                })}
            >
                <div className="list-tile__status-circle__middle list-tile__status-circle__middle--large" />
                <div className="list-tile__status-circle__content">
                    <SensorIcon sensor={sensorType} />
                </div>
            </div>
            <div className="sensor__data">
                <div className="sensor__unit--top">{displayedUnit}</div>
                <span className="sensor__value">
                    {sensorHasValue
                        ? `${displayLldValue ? '<' : ''}${displayedValue}`
                        : txt('NotAvailableAbbreviation')}
                </span>
            </div>
        </div>
    );
};

export default CircledSensorUnitComponent;
