import React from 'react';

const HorizontalBusinessLogo = (className: string): React.ReactElement => (
    <svg className={className} viewBox="0 0 368.8 53" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path
            className="st0"
            d="M42.3,42L32.2,18.3c-1.1-2.5-3.4-3.8-5.7-3.8s-4.6,1.3-5.7,3.8L10.7,42c-3.9-4-6.3-9.4-6.3-15.5
            c0-12.2,9.9-22.1,22.1-22.1s22.1,9.9,22.1,22.1C48.6,32.5,46.2,38,42.3,42L42.3,42z M0,26.5L0,26.5c0,7.1,2.8,13.7,7.8,18.8
            c5,5,11.7,7.8,18.7,7.8c7.1,0,13.7-2.8,18.7-7.8S53,33.6,53,26.5s-2.8-13.7-7.8-18.7S33.5,0,26.5,0C19.4,0,12.8,2.8,7.8,7.8
            S0,19.4,0,26.5L0,26.5z M18.9,34.1l6-14.1c0.4-0.9,1.2-1.1,1.6-1.1s1.2,0.1,1.6,1.1l6,14.1H18.9L18.9,34.1z"
        />
        <polygon className="st0" points="217.6,10.3 217.6,42.7 216.9,42.7 216.9,10.3 " />
        <g>
            <path
                className="st1"
                d="M364.5,18.7c0.8,0,1.6,0.1,2.4,0.4c0.7,0.3,1.3,0.7,1.8,1.2l-1.4,1.4c-0.3-0.4-0.7-0.7-1.2-1s-1-0.4-1.6-0.4
                c-0.3,0-0.7,0-1,0.1s-0.6,0.2-0.9,0.4c-0.3,0.2-0.5,0.4-0.7,0.7c-0.2,0.3-0.3,0.6-0.3,1s0.1,0.7,0.2,1c0.2,0.3,0.4,0.5,0.6,0.7
                c0.3,0.2,0.6,0.3,0.9,0.5c0.4,0.1,0.7,0.3,1.2,0.4s1,0.3,1.5,0.5s1,0.4,1.4,0.8c0.4,0.3,0.7,0.7,1,1.2s0.4,1.1,0.4,1.8
                c0,0.7-0.1,1.4-0.4,1.9s-0.6,1-1.1,1.4c-0.5,0.4-1,0.6-1.6,0.8s-1.2,0.3-1.9,0.3c-0.9,0-1.8-0.2-2.6-0.5c-0.8-0.4-1.6-0.9-2.1-1.6
                l1.6-1.3c0.3,0.5,0.8,0.9,1.4,1.2c0.6,0.3,1.2,0.5,1.9,0.5c0.3,0,0.7,0,1-0.1c0.3-0.1,0.6-0.2,0.9-0.4c0.3-0.2,0.5-0.5,0.7-0.8
                s0.3-0.7,0.3-1.1s-0.1-0.8-0.3-1.1c-0.2-0.3-0.4-0.5-0.7-0.7c-0.3-0.2-0.7-0.4-1.1-0.5s-0.9-0.3-1.3-0.4c-0.5-0.1-0.9-0.3-1.4-0.5
                c-0.4-0.2-0.8-0.4-1.2-0.8c-0.4-0.3-0.6-0.7-0.9-1.1c-0.2-0.4-0.3-1-0.3-1.7s0.1-1.3,0.4-1.9c0.3-0.5,0.7-0.9,1.1-1.3
                c0.5-0.3,1-0.6,1.6-0.7C363.3,18.8,363.9,18.7,364.5,18.7z"
            />
            <path
                className="st1"
                d="M290.9,19.1v9c0,0.5,0.1,1,0.2,1.5s0.3,0.9,0.6,1.3c0.3,0.4,0.6,0.7,1.1,0.9c0.5,0.2,1,0.3,1.7,0.3
                s1.2-0.1,1.7-0.3c0.5-0.2,0.8-0.5,1.1-0.9c0.3-0.4,0.5-0.8,0.6-1.3s0.2-1,0.2-1.5v-9h2v9.1c0,0.7-0.1,1.4-0.3,2.1
                c-0.2,0.7-0.6,1.3-1,1.8c-0.5,0.5-1,1-1.8,1.3c-0.7,0.3-1.5,0.5-2.5,0.5s-1.8-0.2-2.5-0.5s-1.3-0.7-1.8-1.3c-0.5-0.5-0.8-1.1-1-1.8
                c-0.2-0.7-0.3-1.4-0.3-2.1v-9.1C288.9,19.1,290.9,19.1,290.9,19.1z"
            />
            <path
                className="st1"
                d="M352.9,18.7c0.8,0,1.6,0.1,2.4,0.4c0.7,0.3,1.3,0.7,1.8,1.2l-1.4,1.4c-0.3-0.4-0.7-0.7-1.2-1s-1-0.4-1.6-0.4
                c-0.3,0-0.7,0-1,0.1s-0.6,0.2-0.9,0.4c-0.3,0.2-0.5,0.4-0.7,0.7c-0.2,0.3-0.3,0.6-0.3,1s0.1,0.7,0.2,1c0.2,0.3,0.4,0.5,0.6,0.7
                s0.6,0.3,0.9,0.5c0.4,0.1,0.7,0.3,1.2,0.4s1,0.3,1.5,0.5s1,0.4,1.4,0.8c0.4,0.3,0.7,0.7,1,1.2s0.4,1.1,0.4,1.8
                c0,0.7-0.1,1.4-0.4,1.9s-0.6,1-1.1,1.4c-0.5,0.4-1,0.6-1.6,0.8s-1.2,0.3-1.9,0.3c-0.9,0-1.8-0.2-2.6-0.5c-0.8-0.4-1.6-0.9-2.1-1.6
                l1.6-1.3c0.3,0.5,0.8,0.9,1.4,1.2c0.6,0.3,1.2,0.5,1.9,0.5c0.3,0,0.7,0,1-0.1c0.3-0.1,0.6-0.2,0.9-0.4c0.3-0.2,0.5-0.5,0.7-0.8
                s0.3-0.7,0.3-1.1s-0.1-0.8-0.3-1.1c-0.2-0.3-0.4-0.5-0.7-0.7c-0.3-0.2-0.7-0.4-1.1-0.5s-0.9-0.3-1.3-0.4c-0.5-0.1-0.9-0.3-1.4-0.5
                c-0.4-0.2-0.8-0.4-1.2-0.8c-0.4-0.3-0.6-0.7-0.9-1.1c-0.2-0.4-0.3-1-0.3-1.7s0.1-1.3,0.4-1.9c0.3-0.5,0.7-0.9,1.1-1.3
                c0.5-0.3,1-0.6,1.6-0.7C351.8,18.8,352.3,18.7,352.9,18.7z"
            />
            <path
                className="st1"
                d="M307.9,18.7c0.8,0,1.6,0.1,2.4,0.4c0.7,0.3,1.3,0.7,1.8,1.2l-1.4,1.4c-0.3-0.4-0.7-0.7-1.2-1s-1-0.4-1.6-0.4
                c-0.3,0-0.7,0-1,0.1s-0.6,0.2-0.9,0.4c-0.3,0.2-0.5,0.4-0.7,0.7s-0.3,0.6-0.3,1s0.1,0.7,0.2,1c0.2,0.3,0.4,0.5,0.6,0.7
                s0.6,0.3,0.9,0.5c0.4,0.1,0.7,0.3,1.2,0.4s1,0.3,1.5,0.5s1,0.4,1.4,0.8c0.4,0.3,0.7,0.7,1,1.2s0.4,1.1,0.4,1.8
                c0,0.7-0.1,1.4-0.4,1.9s-0.6,1-1.1,1.4c-0.5,0.4-1,0.6-1.6,0.8s-1.2,0.3-1.9,0.3c-0.9,0-1.8-0.2-2.6-0.5c-0.8-0.4-1.6-0.9-2.1-1.6
                l1.6-1.3c0.3,0.5,0.8,0.9,1.4,1.2c0.6,0.3,1.2,0.5,1.9,0.5c0.3,0,0.7,0,1-0.1c0.3-0.1,0.6-0.2,0.9-0.4c0.3-0.2,0.5-0.5,0.7-0.8
                s0.3-0.7,0.3-1.1s-0.1-0.8-0.3-1.1c-0.2-0.3-0.4-0.5-0.7-0.7c-0.3-0.2-0.7-0.4-1.1-0.5s-0.9-0.3-1.3-0.4c-0.5-0.1-0.9-0.3-1.4-0.5
                c-0.4-0.2-0.8-0.4-1.2-0.8c-0.4-0.3-0.6-0.7-0.9-1.1c-0.2-0.4-0.3-1-0.3-1.7s0.1-1.3,0.4-1.9c0.3-0.5,0.7-0.9,1.1-1.3
                c0.5-0.3,1-0.6,1.6-0.7C306.7,18.8,307.3,18.7,307.9,18.7z"
            />
            <polygon
                className="st1"
                points="345.5,19.1 345.5,20.9 338.3,20.9 338.3,25.2 345.1,25.2 345.1,26.9 338.3,26.9 338.3,31.7
                345.8,31.7 345.8,33.5 336.3,33.5 336.3,19.1"
            />
            <rect x="315" y="19.1" className="st1" width="2" height="14.4" />
            <polygon
                className="st1"
                points="238.5,19.1 238.5,20.9 231.5,20.9 231.5,25.3 238,25.3 238,27.1 231.5,27.1 231.5,33.5 229.5,33.5
                229.5,19.1"
            />
            <polygon
                className="st1"
                points="323.2,19.1 330.7,30.6 330.7,30.6 330.7,19.1 332.8,19.1 332.8,33.5 330.2,33.5 322.6,21.7
                322.6,21.7 322.6,33.5 320.5,33.5 320.5,19.1"
            />
            <path
                className="st1"
                d="M255,23.2c-0.4-0.9-0.9-1.7-1.6-2.4c-0.7-0.7-1.5-1.2-2.4-1.5c-0.9-0.4-2-0.5-3.1-0.5s-2.1,0.2-3,0.5
                c-0.9,0.4-1.7,0.9-2.4,1.5c-0.7,0.7-1.2,1.5-1.6,2.4c-0.4,0.9-0.6,2-0.6,3.1s0.2,2.1,0.6,3.1c0.4,0.9,0.9,1.7,1.6,2.4
                c0.7,0.7,1.5,1.2,2.4,1.6s1.9,0.6,3,0.6s2.1-0.2,3.1-0.6c0.9-0.4,1.7-0.9,2.4-1.6c0.7-0.7,1.2-1.5,1.6-2.4c0.4-0.9,0.6-2,0.6-3.1
                C255.6,25.1,255.4,24.1,255,23.2z M253,28.5c-0.3,0.7-0.6,1.3-1.1,1.8s-1.1,0.9-1.7,1.2c-0.7,0.3-1.4,0.4-2.3,0.4
                c-0.8,0-1.6-0.1-2.2-0.4c-0.7-0.3-1.2-0.7-1.7-1.2s-0.8-1.1-1.1-1.8c-0.3-0.7-0.4-1.5-0.4-2.3s0.1-1.5,0.4-2.2
                c0.3-0.7,0.6-1.3,1.1-1.8s1-0.9,1.7-1.3c0.7-0.3,1.4-0.5,2.2-0.5s1.6,0.2,2.3,0.5s1.2,0.7,1.7,1.3c0.5,0.5,0.8,1.1,1.1,1.8
                c0.3,0.7,0.4,1.4,0.4,2.2C253.4,27.1,253.2,27.8,253,28.5z"
            />
            <path
                className="st1"
                d="M264.5,26.9c1-0.2,1.9-0.6,2.5-1.2c0.7-0.7,1-1.5,1-2.6c0-0.7-0.1-1.4-0.4-1.9s-0.6-0.9-1.1-1.2
                c-0.5-0.3-1-0.5-1.6-0.7c-0.6-0.1-1.3-0.2-2-0.2h-4.6v14.4h2v-6.4h2.2l3.7,6.4h2.4L264.5,26.9z M262.6,25.4h-2.3v-4.6h2.5
                c0.4,0,0.8,0,1.2,0.1c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.3,0.7,0.3,1.1s-0.1,0.8-0.3,1.1
                c-0.2,0.3-0.4,0.5-0.7,0.7c-0.3,0.2-0.7,0.3-1.1,0.4C263.6,25.4,263.1,25.4,262.6,25.4z"
            />
            <path
                className="st1"
                d="M285.8,28c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.6-0.5-1-0.7s-0.8-0.3-1.3-0.4l0,0c0.8-0.2,1.4-0.6,1.8-1.1
                c0.5-0.5,0.7-1.2,0.7-2c0-0.7-0.1-1.3-0.4-1.7c-0.3-0.5-0.6-0.8-1-1.1c-0.4-0.3-0.9-0.5-1.5-0.6c-0.5-0.1-1.1-0.2-1.6-0.2h-5v14.4
                h4.7c0.7,0,1.4-0.1,2.1-0.3s1.2-0.4,1.7-0.8c0.5-0.3,0.9-0.8,1.2-1.3c0.3-0.5,0.4-1.1,0.4-1.8C286,28.9,286,28.4,285.8,28z
                M277.9,20.8h2.3c1,0,1.7,0.2,2.3,0.5c0.6,0.3,0.9,0.9,0.9,1.7c0,0.4-0.1,0.7-0.2,1s-0.4,0.5-0.6,0.7c-0.3,0.2-0.6,0.3-0.9,0.4
                s-0.7,0.1-1.1,0.1H278L277.9,20.8L277.9,20.8z M283.7,30.5c-0.2,0.3-0.5,0.6-0.8,0.8c-0.3,0.2-0.7,0.3-1.2,0.4
                c-0.4,0.1-0.9,0.1-1.3,0.1h-2.5v-4.9h2.7c1.1,0,1.9,0.2,2.5,0.6s0.9,1,0.9,1.9C284,29.8,283.9,30.2,283.7,30.5z"
            />
        </g>
        <g>
            <path
                className="st1"
                d="M201.2,16.5c1.3,0,2.4,0.2,3.3,0.7c0.5,0.2,0.9,0.5,1.2,0.8c0.5,0.4,0.5,1.1,0,1.5c-0.4,0.3-0.9,0.3-1.2,0
                c-0.3-0.3-0.7-0.5-1-0.8c-0.6-0.3-1.4-0.5-2.3-0.5c-0.5,0-0.9,0.1-1.4,0.2c-0.5,0.1-0.9,0.4-1.3,0.7c-0.4,0.3-0.7,0.7-0.9,1.1
                c-0.2,0.4-0.4,1-0.4,1.6c0,0.6,0.1,1.1,0.3,1.5c0.2,0.4,0.5,0.7,0.9,1c0.4,0.3,0.8,0.5,1.3,0.7c0.5,0.2,1,0.4,1.5,0.5
                c0.7,0.2,1.3,0.4,2,0.7c0.7,0.2,1.3,0.6,1.8,1c0.5,0.4,1,0.9,1.3,1.5c0.3,0.6,0.5,1.4,0.5,2.3c0,0.9-0.2,1.8-0.5,2.4
                c-0.4,0.7-0.8,1.2-1.4,1.7c-0.6,0.4-1.2,0.8-2,1c-0.7,0.2-1.5,0.3-2.2,0.3c-0.6,0-1.1-0.1-1.7-0.2c-0.6-0.1-1.1-0.3-1.6-0.5
                c-0.5-0.2-1-0.5-1.4-0.9c-0.2-0.1-0.3-0.2-0.4-0.4c-0.4-0.4-0.4-1.1,0.1-1.5l0.1,0c0.4-0.3,0.9-0.2,1.3,0.1c0.3,0.4,0.7,0.7,1.2,1
                c0.7,0.5,1.6,0.7,2.6,0.7c0.5,0,1-0.1,1.5-0.2c0.5-0.2,0.9-0.4,1.3-0.7c0.4-0.3,0.7-0.7,1-1.1c0.3-0.5,0.4-1,0.4-1.6
                c0-0.7-0.1-1.2-0.4-1.6c-0.3-0.4-0.6-0.8-1-1.1c-0.4-0.3-0.9-0.5-1.5-0.7c-0.6-0.2-1.1-0.4-1.7-0.6c-0.6-0.2-1.2-0.4-1.8-0.7
                c-0.6-0.2-1.1-0.6-1.6-1c-0.5-0.4-0.9-0.9-1.2-1.5c-0.3-0.6-0.4-1.3-0.4-2.2c0-0.9,0.2-1.7,0.5-2.3c0.4-0.7,0.8-1.2,1.4-1.6
                c0.6-0.4,1.2-0.7,1.9-0.9C199.8,16.6,200.5,16.5,201.2,16.5z"
            />
            <path
                className="st1"
                d="M184.5,16.5c1.5,0,2.8,0.2,3.9,0.7c0.8,0.3,1.5,0.7,2.2,1.2c0.4,0.3,0.4,0.9,0.1,1.3l-0.1,0.1
                c-0.3,0.3-0.8,0.3-1.1,0c-0.5-0.4-1.1-0.8-1.8-1.1c-0.9-0.4-1.9-0.6-3.1-0.6c-1.2,0-2.3,0.2-3.3,0.7c-1,0.4-1.8,1-2.5,1.8
                c-0.7,0.8-1.2,1.6-1.6,2.6c-0.4,1-0.5,2-0.5,3.1c0,1.1,0.2,2.1,0.5,3.1c0.4,1,0.9,1.9,1.6,2.6c0.7,0.7,1.5,1.3,2.5,1.8
                c1,0.4,2.1,0.7,3.3,0.7c1.1,0,2-0.1,2.9-0.3c0.6-0.2,1.2-0.4,1.7-0.6c0.3-0.1,0.5-0.4,0.5-0.8v-5.2h-3.7c-0.5,0-0.9-0.4-0.9-0.9
                s0.4-0.9,0.9-0.9h4.8c0.5,0,0.9,0.4,0.9,0.9V34c0,0.3-0.2,0.6-0.5,0.8c-0.9,0.5-1.9,0.9-2.9,1.1c-1.2,0.3-2.4,0.5-3.7,0.5
                c-1.4,0-2.7-0.3-3.9-0.7c-1.2-0.5-2.2-1.2-3.1-2.1c-0.9-0.9-1.5-1.9-2-3.1c-0.5-1.2-0.7-2.5-0.7-4c0-1.4,0.2-2.8,0.7-4
                c0.5-1.2,1.2-2.3,2.1-3.1c0.9-0.9,1.9-1.6,3.1-2.1C181.8,16.7,183.1,16.5,184.5,16.5z"
            />
            <rect x="86.8" y="16.9" className="st1" width="4.4" height="18.9" />
            <path
                className="st1"
                d="M157.2,16.9c0.5,0,1,0.3,1.3,0.7L169,33h0.1V17.9c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1v16.9c0,0.5-0.4,1-1,1h-0.6
                c-0.5,0-1-0.3-1.3-0.7l-10.5-15.5h-0.1v15.3c0,0.5-0.4,1-1,1s-1-0.4-1-1V17.9c0-0.5,0.4-1,1-1H157.2z"
            />
            <path
                className="st1"
                d="M149.6,16.9c0.5,0,1,0.4,1,1v16.9c0,0.5-0.4,0.9-0.8,1l-0.1,0c-0.5,0-1-0.4-1-1V17.9
                C148.7,17.4,149.1,16.9,149.6,16.9z"
            />
            <path
                className="st1"
                d="M142.6,16.9c0.5,0,1,0.4,1,1v16.9c0,0.5-0.4,1-1,1s-1-0.4-1-1v-8h-10.3v8c0,0.5-0.4,1-1,1c-0.5,0-1-0.4-1-1
                V17.9c0-0.5,0.4-0.9,0.8-1l0.1,0c0.5,0,1,0.4,1,1v7.2h10.3v-7.2C141.7,17.4,142.1,16.9,142.6,16.9z"
            />
            <path
                className="st1"
                d="M125.8,16.9c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9h-5.5V35c0,0.5-0.4,0.9-0.9,0.9h-0.2
                c-0.5,0-0.9-0.4-0.9-0.9V18.7h-5.5c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9H125.8z"
            />
            <path
                className="st1"
                d="M77.9,18.9c-0.5-1.2-1.7-2-3-2c-1.3,0-2.5,0.8-3,2l-7,16.9h4.6l1.5-4h7.8l1.6,4h4.3L77.9,18.9z M72.5,28.3
                l2.5-6.5l2.6,6.5H72.5z"
            />
            <path
                className="st1"
                d="M106.4,27.9c2.9-1.3,4.6-4.9,2.5-8.5c-0.8-1.3-2.7-2.4-4.2-2.4h-9.1v18.9h4.4v-7.5h1.5l3.4,6.3
                c0.4,0.7,1.2,1.2,2,1.2h3.7L106.4,27.9z M104.7,24.3c-0.3,0.3-0.7,0.5-1.1,0.5H100v-4.7h3.1C104.9,20.1,106.3,22.3,104.7,24.3z"
            />
        </g>
    </svg>
);

export default HorizontalBusinessLogo;
