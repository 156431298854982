import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Carousel, { ButtonGroupProps } from 'react-multi-carousel';
import { useSelector } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { SPACE_COMPARE_SENSORS } from 'commons/src/analytics/AnalyticsEvents';
import { sortSensors } from 'commons/src/commonFunctions';
import CircleButton from 'commons/src/components/buttons/CircleButton';
import MultipleAttrDropdown from 'commons/src/components/dropdown/MultipleAttrDropdown';
import { customPeriodName, sensorGraphPeriods } from 'commons/src/constants';
import { setXAxisMinValue } from 'commons/src/features/devicePage/SensorConfig';
import { FeatureToggleEnum, SensorTypes } from 'commons/src/models/commonEnums';
import { SelectedPeriod, SensorThresholds } from 'commons/src/models/commonTypeScript';
import { Digest } from '../../../../models/eventAggregationModels';
import { Store } from '../../../../reducers';
import InsightInfoButtons from '../../../device/InsightInfoButtons';
import DigestsComponent from '../eventAggregation/Digests';
import { spaceSensorData } from '../spaceSelectors';
import SpaceGraphActions from './GraphActions';
import { setThresholds } from './spaceGraphFunctions';
import styles from './SpaceGraphSection.module.scss';
import SpaceMainGraph from './SpaceMainGraph';
import SpaceSparkLine from './SpaceSparkLine';
import 'react-multi-carousel/lib/styles.css';
import SpaceThresholdsComponent from './SpaceThresholdsComponent';

type Props = {
    spaceId: string;
    locationId: string;
    fetchDataForPeriod: (period: SelectedPeriod) => void;
    fetchDigestForPeriod: (period: SelectedPeriod) => void;
};

const CarouselButtons = ({ next, previous, carouselState }: ButtonGroupProps): ReactElement => {
    const currentSlide = carouselState?.currentSlide || 0;
    const totalItems = carouselState?.totalItems || 0;
    const slidesToShow = carouselState?.slidesToShow || 1;
    const clickPrev = (): void => {
        const isNotOnFirstSlide = currentSlide !== 0;
        if (previous && isNotOnFirstSlide) previous();
    };

    const isOnLastSlide = currentSlide + slidesToShow === totalItems;
    const clickNext = (): void => {
        if (next && !isOnLastSlide) next();
    };

    return (
        <div className={styles.buttons}>
            <CircleButton
                onClick={clickPrev}
                size="small"
                color="secondary"
                iconName="keyboard_arrow_left"
                disabled={currentSlide === 0}
            />
            <CircleButton
                onClick={clickNext}
                size="small"
                color="secondary"
                iconName="keyboard_arrow_right"
                disabled={isOnLastSlide}
            />
        </div>
    );
};
const SpaceGraphSection = ({ spaceId, locationId, fetchDataForPeriod, fetchDigestForPeriod }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const { thresholds } = useSelector((state: Store) => state.config);
    const [selectedPeriod, setSelectedPeriod] = useState<SelectedPeriod>(sensorGraphPeriods.week);
    const [selectedSensor, setSelectedSensor] = useState<SensorTypes>(SensorTypes.temp);
    const [customThresholds, setCustomThresholds] = useState<{ [sensor: string]: SensorThresholds }>(thresholds);
    const [compareSensor, setCompareSensor] = useState<SensorTypes | undefined>(undefined);
    const {
        spaceData: { sensorData, sensorsInSpace },
    } = useSelector((state: Store) => spaceSensorData(state, spaceId));
    const { digests } = useSelector((state: Store) => state.eventAggregation.aggregates);
    const enableEventAggregationToggleOn = useSelector((state: Store) => state.toggles.enableEventAggregationToggleOn);

    const onSelectCompareSensor = (sensor: SensorTypes | string): void => {
        if (sensor === 'Space.ClearCompareSelection') {
            setCompareSensor(undefined);
        } else {
            setCompareSensor(sensor as SensorTypes);
            analyticsLogger(SPACE_COMPARE_SENSORS, { active: selectedSensor, comparison: sensor });
        }
    };

    useEffect(() => {
        setCustomThresholds(thresholds);
    }, [thresholds]);
    const showCustomThresholdsToggleOn = useSelector((store: Store) =>
        store.userSettings.featureToggles.includes(FeatureToggleEnum.enableCustomThresholds)
    );

    const updateSelectedSensor = (sensor: SensorTypes): void => {
        setCompareSensor(undefined);
        setSelectedSensor(sensor);
    };

    const order = [
        SensorTypes.temp,
        SensorTypes.co2,
        SensorTypes.voc,
        SensorTypes.hourlyRadon,
        SensorTypes.pm25,
        SensorTypes.pm1,
        SensorTypes.pm10,
        SensorTypes.humidity,
        SensorTypes.pressure,
        SensorTypes.sla,
        SensorTypes.light,
        SensorTypes.lux,
        SensorTypes.presence,
        SensorTypes.occupants,
        SensorTypes.airExchangeRate,
        SensorTypes.virusRisk,
        SensorTypes.mold,
    ];

    const hasThresholdRanges =
        Object.keys(thresholds).length > 0 &&
        thresholds[selectedSensor]?.ranges.length > 0 &&
        selectedSensor !== SensorTypes.virusRisk &&
        selectedSensor !== SensorTypes.mold;

    const sortedSensors = useMemo(
        () =>
            sortSensors(
                sensorsInSpace.map(sensor => ({ type: sensor })),
                order,
                sensorsInSpace.length
            ),
        [sensorsInSpace]
    );

    const findDeviceWithDataForSensorSparkLine = (sensor: SensorTypes): number[][] => {
        const device = Object.keys(sensorData).find(segmentId => {
            const withData = sensorData[segmentId]?.[sensor]?.[selectedPeriod.name];
            return withData && withData.length > 0;
        });
        return device ? sensorData[device]?.[sensor]?.[selectedPeriod.name] : [];
    };

    const sparkLineSensorData = useMemo(
        () =>
            sortedSensors.map(sensor => {
                const sensorGraphData = findDeviceWithDataForSensorSparkLine(sensor.type);
                const lastPoint = sensorGraphData?.length > 0 ? sensorGraphData[sensorGraphData.length - 1] : undefined;
                const ranges = (thresholds && thresholds[sensor.type]?.ranges) || [];
                const zoneColors = setThresholds(ranges || []);
                const graphName =
                    selectedPeriod.name === customPeriodName
                        ? `${selectedPeriod.startDate?.format()}-${selectedPeriod?.endDate?.format()}`
                        : selectedPeriod.name;
                const lastDataPoint = lastPoint && {
                    x: lastPoint[0],
                    y: lastPoint[1],
                    marker: { radius: 3, symbol: 'circle' },
                };
                return {
                    sensorData: {
                        data: sensorGraphData,
                        id: `spark-line-${sensor.type}-${graphName}`,
                        zones: zoneColors,
                    },
                    sensor: sensor.type as SensorTypes,
                    lastDataPoint,
                };
            }),
        [sensorData, sortedSensors]
    );

    const responsive = {
        sixSensors: {
            breakpoint: { max: 3000, min: 1220 },
            items: 6,
            slidesToSlide: 3,
        },
        fiveSensors: {
            breakpoint: { max: 1220, min: 1080 },
            items: 5,
            slidesToSlide: 3,
        },
        fourSensors: {
            breakpoint: { max: 1080, min: 910 },
            items: 4,
            slidesToSlide: 3,
        },
        threeSensors: {
            breakpoint: { max: 910, min: 730 },
            items: 3,
            slidesToSlide: 3,
        },
        twoSensors: {
            breakpoint: { max: 730, min: 460 },
            items: 2,
            slidesToSlide: 2,
        },
        oneSensors: {
            breakpoint: { max: 460, min: 0 },
            items: 1,
            slidesToSlide: 1,
        },
    };

    const graphStartAndEnd = useMemo(
        () => ({
            min: setXAxisMinValue(selectedPeriod),
            max: selectedPeriod.endDate ? moment(selectedPeriod.endDate).valueOf() : moment().valueOf(),
        }),
        [selectedPeriod]
    );

    const sensorCompareOptions = (
        compareSensor ? [{ type: 'Space.ClearCompareSelection' }, ...sortedSensors] : sortedSensors
    )
        .map(sensor => ({
            id: sensor.type,
            inputValue: txt(sensor.type),
        }))
        .filter(option => option.id !== selectedSensor);

    const handleStatusPillClick = (digest: Digest): void => {
        updateSelectedSensor(digest.sensorType as SensorTypes);
    };

    return (
        <>
            <SpaceGraphActions
                spaceId={spaceId}
                locationId={locationId}
                selectedPeriod={selectedPeriod}
                fetchDataForPeriod={fetchDataForPeriod}
                fetchDigestForPeriod={fetchDigestForPeriod}
                setSelectedPeriod={setSelectedPeriod}
            />
            <div className={styles.wrapper} data-space-graph>
                {enableEventAggregationToggleOn && (
                    <div>
                        <div className={styles.topBar}>
                            <DigestsComponent
                                spaceId={spaceId}
                                digests={digests}
                                selectedPeriod={selectedPeriod}
                                onStatusPillClick={handleStatusPillClick}
                            />
                        </div>
                    </div>
                )}
                <div className={styles.carouselWrapper}>
                    <Carousel
                        responsive={responsive}
                        ssr={false} // means to render carousel on server-side.
                        renderButtonGroupOutside
                        arrows={false}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        customButtonGroup={<CarouselButtons />}
                        containerClass={styles.carouselContainer}
                        removeArrowOnDeviceType={['tablet', 'mobile']}
                    >
                        {sparkLineSensorData.length > 0 ? (
                            sparkLineSensorData?.map(sensor => (
                                <SpaceSparkLine
                                    key={sensor.sensor}
                                    data={sensor.sensorData}
                                    sensor={sensor.sensor}
                                    selected={selectedSensor}
                                    onSelect={updateSelectedSensor}
                                    graphStartAndEnd={graphStartAndEnd}
                                    spaceId={spaceId}
                                    locationId={locationId}
                                />
                            ))
                        ) : (
                            <div className={styles.sparkLinePlaceholder} />
                        )}
                    </Carousel>
                </div>
                <div className={styles.advancedFeatures}>
                    {hasThresholdRanges && showCustomThresholdsToggleOn && (
                        <SpaceThresholdsComponent
                            selectedSensor={selectedSensor}
                            thresholds={customThresholds}
                            setThresholds={setCustomThresholds}
                        />
                    )}
                    <div className={styles.compare}>
                        <MultipleAttrDropdown
                            title=""
                            defaultOption="Compare"
                            options={sensorCompareOptions}
                            value={compareSensor ? txt(compareSensor) : undefined}
                            onSelect={({ id }): void => onSelectCompareSensor(id as SensorTypes)}
                            testAttr="compare-sensor-dropdown"
                            testId="compare-sensor-dropdown"
                            id="compareSensorDropdown"
                            optionsAlreadySorted
                            slim
                            noBottomMargin
                        />
                    </div>
                </div>
                <SpaceMainGraph
                    sensor={selectedSensor}
                    customThresholds={customThresholds[selectedSensor]}
                    spaceId={spaceId}
                    selectedInterval={selectedPeriod}
                    locationId={locationId}
                    graphStartAndEnd={graphStartAndEnd}
                    compareSensor={compareSensor}
                />
            </div>
            <InsightInfoButtons sensor={selectedSensor} />
        </>
    );
};

export default SpaceGraphSection;
