import React from 'react';
import classNames from 'classnames';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapDeviceType } from '../../commonFunctions';
import { dateFormats } from '../../constants';
import { AnyDeviceType } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import DeviceIcon from './DeviceIcon';

export type ParentProps = {
    serialNumber: string;
    segmentStart?: string;
    deviceType: AnyDeviceType;
    noMargin?: boolean;
};

type StateProps = {
    dateFormat: keyof typeof dateFormats;
};

type Props = StateProps & ParentProps;

export const DeviceInfoComponent = (props: Props): React.ReactElement => {
    const { serialNumber, segmentStart, dateFormat, deviceType, noMargin } = props;
    const { t: txt } = useTranslation();
    const { calendarFormat } = dateFormats[dateFormat];

    return (
        <div className={classNames('edit-device-info', { 'edit-device-info--no-margin': noMargin })}>
            <div className="edit-device-info__icon">
                <DeviceIcon deviceType={mapDeviceType(deviceType as AnyDeviceType)} />
            </div>
            <div className="edit-device-info__labels">
                <span>{[txt('DeviceType'), ': ', txt(`${mapDeviceType(deviceType as AnyDeviceType)}FullName`)]}</span>
                <span>{`${txt('SerialNumber')}: ${serialNumber}`}</span>
                {segmentStart && (
                    <span>{`${txt('MeasurementStarted')}: ${Moment(segmentStart).format(calendarFormat)}`}</span>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = state;
    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(DeviceInfoComponent);
