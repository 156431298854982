import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ResponseBox from './responseMessages/ResponseBox';

type Props = {
    estimatedBatteryLife?: number;
};

const ReadableBatteryLife = ({ estimatedBatteryLife }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    if (!estimatedBatteryLife) {
        return <ResponseBox text="SomethingWentWrongTryAgain" />;
    }
    const inDuration = moment.duration(estimatedBatteryLife, 'weeks');
    const years = inDuration.get('years');
    const subtractedYears = inDuration.subtract(years, 'years');
    const months = subtractedYears.get('months');
    const yearString = years ? `${years} ${txt('TimeUnit.Years')}` : '';
    const monthString = months ? `${months} ${txt('TimeUnit.Months')}` : '';
    return (
        <div className="segment-properties-form__battery-estimate--blue">
            {'~ '}
            {yearString}
            {years && months ? ', ' : ''}
            {monthString}
        </div>
    );
};

export default ReadableBatteryLife;
