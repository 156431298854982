import { ExtraSeries, SensorResponse } from '../../models/commonTypeScript';

const zipChartData = (
    timeOffsets: number[][],
    sensor: SensorResponse,
    segmentStartTime: number,
    initialMinValue?: number
): {
    newChartData: number[][];
    minValue?: number;
    extraSeries?: ExtraSeries;
} => {
    let minValue = initialMinValue;
    const sensorOffset = timeOffsets[sensor.offsetType];
    const chartData = sensor
        ? sensorOffset.map((interval, index) => {
              const measurementValue = sensor.measurements[index];
              if (minValue === undefined || minValue > measurementValue) {
                  minValue = measurementValue;
              }
              return [segmentStartTime + interval * 1000, measurementValue];
          })
        : [];

    // TODO: Remove the s.type hack!
    const extraSeries = ((sensor && sensor.series) || [])
        .map(s => {
            const seriesOffset = timeOffsets[s.offsetType];
            return {
                [s.aggregateType || s.type]: seriesOffset.map((interval, index) => {
                    const measurementValue = s.measurements[index];
                    if (minValue === undefined || minValue > measurementValue) {
                        minValue = measurementValue;
                    }
                    return [segmentStartTime + interval * 1000, measurementValue];
                }),
            };
        })
        .reduce((a, b) => ({ ...a, ...b }), {});

    return {
        newChartData: chartData,
        minValue,
        extraSeries,
    };
};

export default zipChartData;
