import React, { useState, SyntheticEvent, KeyboardEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './InputInfo.module.scss';

type Props = {
    infoText: string;
    infoTextTranslated?: boolean;
    infoDirectionDown?: boolean;
    style?: React.CSSProperties;
};
const InputInfo = ({ infoText, infoTextTranslated, infoDirectionDown, style }: Props): React.ReactElement => {
    const [displayInfo, setDisplayInfo] = useState(false);
    const { t: txt } = useTranslation();

    const onButtonClick = (e: KeyboardEvent<HTMLElement> | SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        setDisplayInfo(!displayInfo);
    };

    const onBlur = (): void => {
        setDisplayInfo(false);
    };
    return (
        <div className={styles.icon}>
            <i
                className="material-symbols-outlined"
                onClick={onButtonClick}
                onKeyUp={(e): void => {
                    if (e.key === 'Enter') onButtonClick(e);
                }}
                onBlur={onBlur}
                tabIndex={0}
                role="button"
            >
                info
            </i>
            {displayInfo && (
                <span
                    className={classNames(styles.hint, {
                        [styles.down]: infoDirectionDown,
                    })}
                    style={style}
                >
                    {infoTextTranslated ? infoText : txt(infoText)}
                </span>
            )}
        </div>
    );
};

export default InputInfo;
