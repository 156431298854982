import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import {
    BUILDING_PRESENCE_INSIGHT_FETCHED,
    BUILDING_PRESENCE_INSIGHT_FILTER,
    BUILDING_PRESENCE_INSIGHT_SET_DATE_RANGE,
    BUILDING_PRESENCE_INSIGHT_TOGGLE_OPENING_HOURS,
} from 'commons/src/analytics/AnalyticsEvents';
import SectionHeader from 'commons/src/components/headers/SectionHeader';
import { insightSectionPlaceHolder } from 'commons/src/components/placeholder';
import { dateFormats } from 'commons/src/constants';
import { TimePeriod } from 'commons/src/models/commonEnums';
import { BuildingType, ErrorType } from 'commons/src/models/commonTypeScript';
import { getPresenceData, getPresenceWeekAggregation } from '../../../actions/buildingOptimizationActions';
import { fetchSegmentPropsFilters } from '../../../actions/segmentPropertiesActions';
import { getBusinessIntercomArticleLink } from '../../../constants';
import {
    PresenceByDevice,
    PresenceOverTime,
    PresenceRequest,
    PresenceWeekAggregation,
} from '../../../models/buildingModels';
import { PresenceInsightFilterType } from '../../../models/common';
import { IntercomArticleId } from '../../../models/commonEnums';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import OpeningHoursComparison from './OpeningHoursComparison';
import { notSetOptionName } from './PresenceFilterModal';
import PresenceInsightFilter from './PresenceInsightFilter';
import styles from './PresenceInsightPage.module.scss';
import TimeSelector from './PresenceInsightTimeSelector';
import UsageByDevice from './UsageByDevice';
import UsageOverTimeSection from './UsageOverTimeSection';

type PresenceInsightSelectedPeriod = { fromDate: string; toDate: string; name: TimePeriod; label?: string };

type ParentProps = {
    buildingId: string;
    building: BuildingType;
};

type StateProps = {
    dateFormat: keyof typeof dateFormats;
    presenceWeekAggregation?: PresenceWeekAggregation;
    loadingAggregation: boolean;
    presenceInsight?: {
        presenceOverTime: PresenceOverTime[];
        presenceByDevice: PresenceByDevice[];
        selectedPeriod: PresenceRequest;
    };
    insightsError?: ErrorType;
    loadingInsights: boolean;
};

type Props = StateProps & ParentProps;

const initialSelectedPeriod: PresenceInsightSelectedPeriod = {
    toDate: moment().format('YYYY-MM-DD'),
    fromDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    name: TimePeriod.month,
};

type SelectedFilters = {
    filterType: PresenceInsightFilterType;
    selectedOptions: string[];
};

const PresenceInsightsPage = ({
    dateFormat,
    presenceWeekAggregation,
    loadingAggregation,
    presenceInsight,
    insightsError,
    loadingInsights,
    building,
    buildingId,
}: Props): React.ReactElement => {
    const dispatch = useDispatch();
    const { t: txt } = useTranslation();
    const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
        filterType: PresenceInsightFilterType.floors,
        selectedOptions: [],
    });

    const buildingHasUsageHours = !!building && Object.keys(building.usageHours).length > 0;
    const [selectedPeriod, setSelectedPeriod] = useState<PresenceInsightSelectedPeriod>({
        toDate: moment().format('YYYY-MM-DD'),
        fromDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        name: TimePeriod.month,
    });
    const [withOpeningHours, setWithOpeningHours] = useState(buildingHasUsageHours);

    const weekAggregationDataIsForThisBuilding = presenceWeekAggregation?.locationId === buildingId;
    const presenceInsightIsForThisBuilding = presenceInsight?.selectedPeriod.locationId === buildingId;

    const getFilterRequest = (filterValues: SelectedFilters): { floor: string[]; roomType: string[] } => {
        const optionsAsArray = filterValues.selectedOptions.map(option => {
            if (option === notSetOptionName) return 'undefined';
            return option;
        });
        return {
            floor: filterValues.filterType === PresenceInsightFilterType.floors ? optionsAsArray : [],
            roomType: filterValues.filterType === PresenceInsightFilterType.roomTypes ? optionsAsArray : [],
        };
    };

    useEffect(() => {
        if (buildingId) {
            dispatch(fetchSegmentPropsFilters(buildingId));
            if (!weekAggregationDataIsForThisBuilding) {
                analyticsLogger(BUILDING_PRESENCE_INSIGHT_FETCHED, {});
                dispatch(getPresenceWeekAggregation({ locationId: buildingId, filter: { floor: [], roomType: [] } }));
            }
            if (!presenceInsightIsForThisBuilding) {
                dispatch(
                    getPresenceData({
                        ...initialSelectedPeriod,
                        openingHours: withOpeningHours,
                        filter: { floor: [], roomType: [] },
                        locationId: buildingId,
                    })
                );
            }
        }
    }, [buildingId]);

    const selectPeriod = (newSelectedPeriod: { name: TimePeriod; fromDate: string; toDate: string }): void => {
        setSelectedPeriod(newSelectedPeriod);
        analyticsLogger(BUILDING_PRESENCE_INSIGHT_SET_DATE_RANGE, {
            fromDate: newSelectedPeriod.fromDate,
            toDate: newSelectedPeriod.toDate,
        });
        dispatch(
            getPresenceData({
                ...newSelectedPeriod,
                openingHours: withOpeningHours,
                filter: getFilterRequest(selectedFilters),
                locationId: buildingId,
            })
        );
    };

    const updateOpeningHours = (openingHours: boolean): void => {
        setWithOpeningHours(openingHours);
        analyticsLogger(BUILDING_PRESENCE_INSIGHT_TOGGLE_OPENING_HOURS, {
            withOpeningHours: openingHours,
        });
        dispatch(
            getPresenceData({
                ...selectedPeriod,
                openingHours,
                filter: getFilterRequest(selectedFilters),
                locationId: buildingId,
            })
        );
    };

    const clearFilterSelection = (): void => {
        setSelectedFilters({ filterType: PresenceInsightFilterType.floors, selectedOptions: [] });
        if (buildingId) {
            dispatch(getPresenceWeekAggregation({ locationId: buildingId, filter: { floor: [], roomType: [] } }));
            dispatch(
                getPresenceData({
                    ...initialSelectedPeriod,
                    openingHours: withOpeningHours,
                    locationId: buildingId,
                    filter: { floor: [], roomType: [] },
                })
            );
        }
    };

    const onUpdateFilter = (newFilterValues: SelectedFilters): void => {
        setSelectedFilters(newFilterValues);
        analyticsLogger(BUILDING_PRESENCE_INSIGHT_FILTER, { filter: newFilterValues });
        if (buildingId) {
            dispatch(getPresenceWeekAggregation({ locationId: buildingId, filter: getFilterRequest(newFilterValues) }));
            dispatch(
                getPresenceData({
                    ...selectedPeriod,
                    openingHours: withOpeningHours,
                    locationId: buildingId,
                    filter: getFilterRequest(newFilterValues),
                })
            );
        }
    };

    return (
        <div className="container">
            <SectionHeader
                headerType={3}
                headerName="PresenceInsight.SpaceUtilization"
                initialHeader
                infoText="PresenceInsight.SpaceUtilDescription"
                endOfLineButton={
                    <a
                        className={styles.articleLink}
                        href={getBusinessIntercomArticleLink(IntercomArticleId.SPACE_UTILIZATION)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {' '}
                        {txt('PresenceInsight.ReadAboutPresence')}
                    </a>
                }
            />
            <PresenceInsightFilter
                locationId={buildingId}
                selectedFilters={selectedFilters}
                setSelectedFilters={onUpdateFilter}
                clearFilterSelection={clearFilterSelection}
                building={building}
            />
            <ReactPlaceholder
                ready={!loadingAggregation && weekAggregationDataIsForThisBuilding}
                customPlaceholder={insightSectionPlaceHolder}
            >
                <OpeningHoursComparison
                    buildingId={buildingId || ''}
                    dateFormat={dateFormat}
                    presenceWeekAggregation={presenceWeekAggregation}
                />
            </ReactPlaceholder>
            <TimeSelector
                setSelectedPeriod={selectPeriod}
                selectedPeriod={selectedPeriod.name}
                buildingHasUsageHours={buildingHasUsageHours}
                setWithOpeningHours={updateOpeningHours}
                withOpeningHours={withOpeningHours}
                presenceInsight={presenceInsight}
                buildingId={buildingId}
                buildingName={building?.name}
            />
            <ReactPlaceholder
                ready={!loadingInsights && presenceInsightIsForThisBuilding}
                customPlaceholder={insightSectionPlaceHolder}
            >
                <>
                    <UsageOverTimeSection
                        timeZone={building.timezone}
                        dateFormat={dateFormat}
                        presenceOverTime={presenceInsight?.presenceOverTime || []}
                        selectedPeriod={selectedPeriod}
                        error={insightsError}
                        usageHours={building?.usageHours}
                        withOpeningHours={withOpeningHours}
                    />
                    <UsageByDevice
                        timeZone={building.timezone}
                        selectedPeriod={selectedPeriod}
                        presenceByDevice={presenceInsight?.presenceByDevice || []}
                        usageHours={building?.usageHours}
                        withOpeningHours={withOpeningHours}
                        dateFormat={dateFormat}
                    />
                </>
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { dateFormat },
        buildingOptimization: { presenceWeekAggregation, presenceInsight },
        requests: {
            [RequestType.GetPresenceWeekAggregation]: { loading: loadingAggregation },
            [RequestType.GetPresenceData]: { loading: loadingInsights, error: insightsError },
        },
    } = store;
    return {
        dateFormat,
        presenceWeekAggregation,
        loadingAggregation,
        presenceInsight,
        insightsError,
        loadingInsights,
    };
};

export default connect(mapStateToProps)(PresenceInsightsPage);
