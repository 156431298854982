import React from 'react';
import BacnetIcon from '../../img/deviceIcons/icon-bacnet';
import IconGroup from '../../img/deviceIcons/icon-group';
import RavenRadonIcon from '../../img/deviceIcons/icon-radon-raven';
import RenewIcon from '../../img/deviceIcons/icon-renew';
import SpaceCo2Icon from '../../img/deviceIcons/icon-space-co2';
import ViewPlusIcon from '../../img/deviceIcons/icon-view-plus';
import ViewPollutionIcon from '../../img/deviceIcons/icon-view-pollution';
import ViewRadonIcon from '../../img/deviceIcons/icon-view-radon';
import WaveEnhanceIcon from '../../img/deviceIcons/icon-wave-enhance';
import WaveNanoIcon from '../../img/deviceIcons/icon-wave-nano';
import HubIcon from '../../img/deviceIcons/new-icon-hub-text_48px';
import WaveMiniIcon from '../../img/deviceIcons/new-icon-mini_48px';
import SpaceCo2MiniIcon from '../../img/deviceIcons/new-icon-space-co2-mini_48px';
import SpaceProIcon from '../../img/deviceIcons/new-icon-space-pro_48px';
import WaveCo2Icon from '../../img/deviceIcons/new-icon-wave-co2_48px';
import WavePlusIcon from '../../img/deviceIcons/new-icon-wave-plus_48px';
import WaveIcon from '../../img/deviceIcons/new-icon-wave_48px';
import { DeviceTypeNames, VirtualDeviceType } from '../../models/commonEnums';
import { AnyDeviceType } from '../../models/commonTypeScript';

const DeviceIcon = ({ deviceType }: { deviceType: AnyDeviceType }): React.ReactElement => {
    switch (deviceType) {
        case DeviceTypeNames.wave:
            return <WaveIcon />;
        case DeviceTypeNames.wave2:
            return <WaveIcon />;
        case DeviceTypeNames.wavePlus:
            return <WavePlusIcon />;
        case DeviceTypeNames.waveEnhance:
            return <WaveEnhanceIcon />;
        case DeviceTypeNames.spacePlus:
            return <WavePlusIcon />;
        case DeviceTypeNames.spaceNano:
            return <WaveNanoIcon />;
        case DeviceTypeNames.waveCo2:
            return <WaveCo2Icon />;
        case DeviceTypeNames.mini:
            return <WaveMiniIcon />;
        case DeviceTypeNames.mist:
            return <WaveMiniIcon />;
        case DeviceTypeNames.hub:
        case DeviceTypeNames.spaceHub:
            return <HubIcon />;
        case DeviceTypeNames.spaceCo2Mini:
            return <SpaceCo2MiniIcon />;
        case DeviceTypeNames.viewPlus:
            return <ViewPlusIcon />;
        case DeviceTypeNames.viewPlusBusiness:
            return <SpaceProIcon />;
        case DeviceTypeNames.viewPollution:
            return <ViewPollutionIcon />;
        case DeviceTypeNames.viewRadon:
            return <ViewRadonIcon />;
        case DeviceTypeNames.viewCo2:
            return <SpaceCo2Icon />;
        case DeviceTypeNames.bacnet:
            return <BacnetIcon />;
        case VirtualDeviceType.inletAirControl:
        case VirtualDeviceType.aggregatedGroup:
            return <IconGroup />;
        case DeviceTypeNames.ap1:
            return <RenewIcon />;
        case DeviceTypeNames.ravenRadon:
            return <RavenRadonIcon />;
        default:
            return <WavePlusIcon />;
    }
};

export default DeviceIcon;
