import { fetchFromAccounts } from './fetch';
import getFetchHeaders from './getFetchHeaders';

type ChangePasswordType = { oldPassword: string; newPassword: string };
export const changePassword = async ({ oldPassword, newPassword }: ChangePasswordType): Promise<void> =>
    fetchFromAccounts<void>('/change-password', {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ oldPassword, newPassword }),
    });

export interface UserPersonalInfoResponse {
    name: string;
    email: string;
}

export const getUserProfile = async (): Promise<UserPersonalInfoResponse> =>
    fetchFromAccounts<UserPersonalInfoResponse>('/profile', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const updateUserProfile = async (name: string): Promise<void> =>
    fetchFromAccounts<void>('/profile', {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ name }),
    });
