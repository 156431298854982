import React from 'react';
import { Trans } from 'react-i18next';
import Modal from 'react-modal';
import ModalHeader from '../../components/modals/ModalHeader';
import { privacyNoticeLink } from '../../constants';

type Props = {
    onClose: () => void;
};

export const NewsletterSignUpConsentComponent = ({ onClose }: Props): React.ReactElement => {
    return (
        <Modal
            isOpen
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--size-medium"
            overlayClassName="modal modal__overlay"
        >
            <ModalHeader headerText="NewsletterConsentHeader" onClose={onClose} />
            <div className="modal__content__body">
                <Trans i18nKey="NewsletterConsentDescription">
                    <a href={privacyNoticeLink} target="_blank" rel="noopener noreferrer">
                        {privacyNoticeLink}
                    </a>
                </Trans>
            </div>
        </Modal>
    );
};
export default NewsletterSignUpConsentComponent;
