import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import InactiveSubscriptionContent from 'commons/src/features/inactiveSubscription/InactiveSubscriptionContent';
import graphPlaceholder from '../../../img/Space-graphs-limited-view.png';
import styles from './LimitedSpaceGraph.module.scss';

const LimitedSpaceGraph = (): ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className={styles.wrapper}>
            <img className={styles.backgroundImage} src={graphPlaceholder} alt={txt('Space.GraphPlaceholder')} />
            <div className={styles.overlay}>
                <div className={styles.messageWrapper}>
                    <InactiveSubscriptionContent />
                </div>
            </div>
        </div>
    );
};

export default LimitedSpaceGraph;
