import { generatePath } from 'react-router-dom';
import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { Resolution, SensorTypes } from 'commons/src/models/commonEnums';
import { RemoveDevicePayload, MoveDevicePayload } from '../actions/spaceActions';
import {
    AddDevicePayload,
    EditSpacePayload,
    Space,
    SpaceDeviceEntity,
    CreateSpacePayload,
    SpaceDataResponse,
} from '../models/spaceModels';

export const createSpace = async (newSpace: CreateSpacePayload, locationId: string): Promise<{ id: string }> =>
    fetch<{ id: string }>(`/locations/${locationId}/spaces`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(newSpace),
    });

export interface GetSpacesResponse {
    spaces: Space[];
    availableSensors: SensorTypes[];
}
const fetchSpaces = async (locationId: string): Promise<GetSpacesResponse> =>
    fetch(`/locations/${locationId}/spaces`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const fetchSpaceSensorData = async (
    locationId: string,
    spaceId: string,
    params: { from: string; to: string; resolution?: Resolution }
): Promise<SpaceDataResponse> => {
    const queryParams = Object.keys(params)
        .filter(p => !!params[p as keyof typeof params])
        .map(p => `${p}=${params[p as keyof typeof params]}`)
        .join('&');
    return fetch(`/locations/${locationId}/spaces/${spaceId}/samples?${queryParams}`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
};

export const fetchSpaceVirtualSensorData = async (
    locationId: string,
    spaceId: string,
    params: { from: string; to: string; resolution?: Resolution }
): Promise<SpaceDataResponse> => {
    const queryParams = Object.keys(params)
        .filter(p => !!params[p as keyof typeof params])
        .map(p => `${p}=${params[p as keyof typeof params]}`)
        .join('&');
    return fetch(`/locations/${locationId}/spaces/${spaceId}/virtual-samples?${queryParams}`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
};

export const editSpace = async (space: EditSpacePayload): Promise<void> => {
    return fetch(`/locations/${space.locationId}/spaces/${space.spaceId}`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify({
            name: space.name,
            updateDeviceNames: space.updateDeviceNames,
            properties: space.properties,
        }),
    });
};

export const deleteSpace = async (space: Space): Promise<void> =>
    fetch(`/locations/${space.locationId}/spaces/${space.id}`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });

export const addDeviceToSpace = async (
    locationId: string,
    spaceId: string,
    devicePayload: AddDevicePayload
): Promise<SpaceDeviceEntity> => {
    const url = generatePath('/locations/:locationId/spaces/:spaceId/devices', { locationId, spaceId });
    return fetch<SpaceDeviceEntity>(url, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(devicePayload),
    });
};

export const deleteDeviceFromSpace = async (payload: RemoveDevicePayload): Promise<void> => {
    const url = generatePath('/locations/:locationId/spaces/:spaceId/devices/:serialNumber', payload);
    return fetch<void>(url, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });
};

export const moveDeviceBetweenSpaces = async (
    locationId: string,
    spaceId: string,
    serialNumber: string,
    payload: MoveDevicePayload
): Promise<void> => {
    const url = generatePath('/locations/:locationId/spaces/:spaceId/devices/:serialNumber/move', {
        locationId,
        spaceId,
        serialNumber,
    });
    return fetch<void>(url, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(payload),
    });
};

export const migrateToSpaces = async (): Promise<void> => {
    return fetch<void>('/organization/space-migration', {
        method: 'POST',
        headers: await getFetchHeaders(),
    });
};

export default fetchSpaces;
