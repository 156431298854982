import React from 'react';
import classNames from 'classnames';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

type Props = {
    segmentStart: string;
    withText?: boolean;
};

const Calibrating = ({ segmentStart, withText }: Props): React.ReactElement | null => {
    const { t: txt } = useTranslation();
    const daysSinceSegmentStart = moment().diff(moment.utc(segmentStart).local(), 'days');

    if (!segmentStart || daysSinceSegmentStart > 6) return null;
    const title = txt('DaysCalibrating', { day: (daysSinceSegmentStart + 1).toString() });
    const calibrationLine = (): React.ReactElement[] =>
        [...Array(7)].map((element, i) => (
            <div
                key={`calibrating-day-${i + 1}`}
                className={classNames('sensor__calibrating', {
                    'sensor__calibrating--today': i === daysSinceSegmentStart,
                    'sensor__calibrating--left': i > daysSinceSegmentStart,
                })}
            />
        ));
    return (
        <div className="calibration-line">
            {calibrationLine()}
            {withText && <span className="sensor__calibrating-text">{title}</span>}
        </div>
    );
};

export default Calibrating;
