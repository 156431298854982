import React, { useMemo } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Cell, Column, Row, useTable } from 'react-table';
import { mapDeviceType } from 'commons/src/commonFunctions';
import { dateFormats } from 'commons/src/constants';
import spinner from 'commons/src/img/spinner';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';
import { DeviceSeat, EstimatedSubscription, Subscription } from '../../../../models/subscriptionModels';
import { getServicePeriodName } from '../StartSubscription/subscriptionFunctions';
import { getFormattedCost } from '../SubscriptionCommons';
import styles from './AddSeatsCostPreviewTable.module.scss';

type ParentProps = {
    oldSubscription: Subscription;
    subscription?: Subscription | EstimatedSubscription;
    loading?: boolean;
    dateFormat: keyof typeof dateFormats;
};

export type Props = ParentProps;

const ProductNameCell = ({ cell, row }: { cell: Cell; row: Row }): React.ReactElement => {
    const { t: txt } = useTranslation();
    const columnData = cell.column;
    const rowData = row.original as DeviceSeat;
    const { costPerDevice } = rowData;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { servicePeriod, currency } = columnData;
    const formattedCost = getFormattedCost(currency, costPerDevice);
    const deviceName = txt(`${mapDeviceType(cell.value as AnyDeviceType)}FullName`);
    return (
        <div>
            <div>{deviceName}</div>
            <div className={styles.subText}>
                {formattedCost}/{txt(`Subscription.${servicePeriod}`)}
            </div>
        </div>
    );
};

const CostRowTotalCost = ({ row }: { row: Row }): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { currency, cost, lastRow } = row.original as {
        currency: string;
        cost: number;
        description: string;
        lastRow: boolean;
    };
    const formattedCost = getFormattedCost(currency, cost);
    return (
        <div>
            <div>{formattedCost}</div>
            {lastRow && <div className={styles.exclTax}>({txt('AddSeats.ExcludingTax')})</div>}
        </div>
    );
};

const AddSeatsCostPreviewTable = ({
    subscription,
    oldSubscription,
    loading,
    dateFormat,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const servicePeriod = getServicePeriodName(oldSubscription.durationInYears);

    const columns = useMemo<Column[]>(
        () => [
            {
                Header: txt('Subscription.Product'),
                accessor: 'deviceType',
                className: styles.firstCell,
                servicePeriod,
                currency: oldSubscription.currency,
                Cell: ProductNameCell,
            },
            {
                Header: txt('AddSeats.NewTotalSeats'),
                accessor: 'seats',
                className: styles.seatsCell,
            },
            {
                Header: txt('AddSeats.AddedSeats'),
                accessor: 'addedSeats',
                className: styles.rightAlignedCell,
            },
        ],
        []
    );

    const costRowColumns = useMemo<Column[]>(
        () => [
            {
                accessor: 'description',
                className: styles.costDescriptionCell,
            },
            {
                accessor: 'cost',
                className: styles.rightAlignedCell,
                Cell: CostRowTotalCost,
            },
        ],
        []
    );

    const seatsFromOldSubscription = oldSubscription.deviceSeats;
    const sortedSubscriptionData = (
        subscription?.deviceSeats?.sort((a, b) =>
            txt(`${a.deviceType}FullName`).localeCompare(txt(`${b.deviceType}FullName`))
        ) || []
    ).map(device => {
        const initialSeatsForDevice = seatsFromOldSubscription.find(oldSub => oldSub.deviceType === device.deviceType);
        return {
            ...device,
            addedSeats: initialSeatsForDevice ? device.seats - initialSeatsForDevice.seats : device.seats,
        };
    });

    const {
        commitmentDiscountedCost,
        commitmentDiscountPercentage,
        discountedCost,
        discountPercentage,
        currency,
        estimatedTotalCost,
    } = subscription || {};

    const renewalDate = dayjs(oldSubscription.renewalDate).format(dateFormats[dateFormat].format);
    const estimatedCostDescription = txt('AddSeats.NewEstimatedRenewalTotal', { renewalDate });
    const estimatedCost = {
        description: estimatedCostDescription,
        cost: estimatedTotalCost,
        currency,
        lastRow: true,
    };

    const costRowData = [estimatedCost];
    if (discountedCost) {
        const discountData = {
            description: `${txt('Zuora.Discount')} (${discountPercentage}%)`,
            cost: discountedCost,
            currency,
            lastRow: false,
        };
        costRowData.unshift(discountData);
    }
    if (commitmentDiscountedCost) {
        const duration = oldSubscription.durationInYears;
        const billingPeriodDiscount = {
            description: `${duration} ${txt(duration === 1 ? 'Zuora.Year' : 'Zuora.Years')} ${txt(
                'Zuora.DiscountLowerCase'
            )} (${commitmentDiscountPercentage}%)`,
            cost: commitmentDiscountedCost,
            currency,
            lastRow: false,
        };
        costRowData.unshift(billingPeriodDiscount);
    }

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: sortedSubscriptionData,
    });

    const {
        getTableBodyProps: getCostTableBodyProps,
        rows: costRows,
        prepareRow: prepareCostRow,
    } = useTable({
        columns: costRowColumns,
        data: costRowData,
    });

    return (
        <div className={styles.previewWrapper}>
            {loading && <div className={styles.spinner}>{spinner}</div>}
            <div {...getTableProps()} className={loading ? styles.loadingTable : styles.table}>
                {headerGroups.map(headerGroup => (
                    <div {...headerGroup.getHeaderGroupProps({ className: styles.header })}>
                        {headerGroup.headers.map(column => {
                            return (
                                <div
                                    {...column.getHeaderProps([
                                        {
                                            className: classNames(styles.cell, column.className),
                                        },
                                    ])}
                                >
                                    <span>{column.render('Header')}</span>
                                </div>
                            );
                        })}
                    </div>
                ))}
                <div {...getTableBodyProps()}>
                    {rows.length > 0 &&
                        rows.map(row => {
                            prepareRow(row);
                            return (
                                <div {...row.getRowProps()}>
                                    <div className={styles.row}>
                                        {row.cells.map(({ getCellProps, render, column }) => {
                                            return (
                                                <div
                                                    {...getCellProps({
                                                        className: classNames(styles.cell, column.className),
                                                    })}
                                                >
                                                    <span>{render('Cell')}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <div {...getCostTableBodyProps()}>
                    {rows.length > 0 &&
                        costRows.map((row, index) => {
                            prepareCostRow(row);
                            return (
                                <div {...row.getRowProps()}>
                                    <div className={styles.row}>
                                        {row.cells.map(({ getCellProps, render, column }) => {
                                            return (
                                                <div
                                                    {...getCellProps({
                                                        className: classNames(
                                                            styles.cell,
                                                            column.className,
                                                            index === costRows.length - 1 && styles.boldText
                                                        ),
                                                    })}
                                                >
                                                    <span>{render('Cell')}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default AddSeatsCostPreviewTable;
