import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButtons from 'commons/src/components/input/Radio';
import { getUnitPreferenceSelectors, userSettingsSelectors } from 'commons/src/components/units/UserPreferenceOptions';
import { DeviceTypeNames, ViewDisplayUnits } from 'commons/src/models/commonEnums';
import { DisplayScreenOptions, DisplaySelectedScreen } from 'commons/src/models/commonTypeScript';

export type Props = {
    activeDeviceType: string;
    configuration: {
        screenOptions: DisplayScreenOptions[];
        selectedScreens: DisplaySelectedScreen[];
        measurementUnits: ViewDisplayUnits;
    };
    updateMeasurementUnits: (deviceType: string, measurementUnits: ViewDisplayUnits) => void;
};

const DisplayUnitSettings = ({
    activeDeviceType,
    updateMeasurementUnits,
    configuration,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { measurementUnits } = configuration;

    const setUnit = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        const selectorName = e.currentTarget.name.replace(/Unit/g, '');

        if (selectorName === 'temp') {
            updateMeasurementUnits(activeDeviceType, { ...measurementUnits, temperature: value });
        } else {
            updateMeasurementUnits(activeDeviceType, { ...measurementUnits, [selectorName]: value });
        }
    };

    const settingsRow = (
        settingId: string,
        options: { label: string; value: string }[],
        header: string,
        selectedValue: string
    ): React.ReactElement => {
        return (
            <div className="form__attr--element" key={settingId}>
                <RadioButtons
                    buttons={options}
                    selectorName={settingId}
                    row
                    header={header}
                    onChange={setUnit}
                    value={selectedValue}
                    labelId={`user-settings-${settingId}`}
                />
            </div>
        );
    };

    const excludedSensors =
        activeDeviceType === DeviceTypeNames.viewPlusBusiness ? [userSettingsSelectors.radonUnit] : [];

    return (
        <div className="list-selector--center">
            <div>
                <div className="centered margin-10-bottom">{txt('SensorUnit.EditDisplayUnits')}</div>
                {getUnitPreferenceSelectors(
                    settingsRow,
                    measurementUnits.radon,
                    measurementUnits.temperature,
                    measurementUnits.pressure,
                    measurementUnits.voc,
                    excludedSensors
                )}
            </div>
        </div>
    );
};

export default DisplayUnitSettings;
