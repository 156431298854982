import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import InputLabel from '../../components/input/InputLabel';
import styles from './ColunmsFlipButton.module.scss';

type Props = {
    checked: number;
    chooseOption: (width: number, e: SyntheticEvent<HTMLButtonElement>) => void;
};

export const ColumnsFlipButtonComponent = ({ checked, chooseOption }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div>
            <InputLabel htmlFor="selectTileWidth" label="TileWidth" />
            <div className={styles.button}>
                <button
                    type="button"
                    onClick={(e: SyntheticEvent<HTMLButtonElement>): void => chooseOption(1, e)}
                    className={classNames(styles.leftButton, {
                        [styles.selected]: checked === 1,
                    })}
                    data-columns-flip-1
                >
                    {txt('1column')}
                </button>
                <button
                    type="button"
                    onClick={(e: SyntheticEvent<HTMLButtonElement>): void => chooseOption(2, e)}
                    className={classNames(styles.middleButton, {
                        [styles.selected]: checked === 2,
                    })}
                    data-columns-flip-2
                >
                    {txt('2columns')}
                </button>
                <button
                    type="button"
                    onClick={(e: SyntheticEvent<HTMLButtonElement>): void => chooseOption(3, e)}
                    className={classNames(styles.rightButton, {
                        [styles.selected]: checked === 3,
                    })}
                    data-columns-flip-3
                >
                    {txt('3columns')}
                </button>
            </div>
        </div>
    );
};

export default ColumnsFlipButtonComponent;
