import { takeEvery, put, call } from 'redux-saga/effects';
import { logOutFailure, LOGOUT_ALL_UNITS } from '../../actions/LoginAndRegisterActions';
import { revokeRefreshTokensAccountsApi } from '../../api/accountsApiTokens';
import { logoutSaga } from './logout';

export function* logoutAllUnitsSaga(): Generator {
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
            yield call(revokeRefreshTokensAccountsApi, refreshToken);
        }
        yield call(logoutSaga);
    } catch (error) {
        yield put(logOutFailure('LogoutError'));
    }
}

export default function* logOutAll(): Generator {
    yield takeEvery(LOGOUT_ALL_UNITS, logoutAllUnitsSaga);
}
