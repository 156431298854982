import dotProp from 'dot-prop-immutable';
import {
    CLEAR_DEVICE_DATA,
    DeviceReducerActions,
    FETCH_DEVICE_SEGMENTID,
    FETCH_DEVICE_SEGMENTID_ERROR,
    FETCH_DEVICE_SEGMENTID_PERIOD,
    FETCH_DEVICE_SEGMENTID_PERIOD_ERROR,
    FETCH_DEVICE_SEGMENTID_PERIOD_SUCCESS,
    FETCH_DEVICE_SEGMENTID_SUCCESS,
} from '../actions/DeviceActions';
import { LOGOUT_SUCCESS, LogOutSuccess } from '../actions/LoginAndRegisterActions';
import { FullDeviceData } from '../models/commonTypeScript';

export interface EndedSegmentsStore {
    segments: { [segmentId: string]: FullDeviceData };
    loading: boolean;
    fetching: boolean;
    segmentId?: string;
    errorCode?: number;
}

const initialState = {
    segments: {},
    loading: true,
    fetching: false,
    segmentId: undefined,
    errorCode: undefined,
};

export default (state = initialState, action: DeviceReducerActions | LogOutSuccess): EndedSegmentsStore => {
    switch (action.type) {
        case FETCH_DEVICE_SEGMENTID:
            return {
                ...state,
                loading: true,
                fetching: true,
                segmentId: action.segmentId,
                error: undefined,
            };
        case FETCH_DEVICE_SEGMENTID_PERIOD:
            return {
                ...state,
                loading: false,
                fetching: true,
                segmentId: action.segmentId,
                error: undefined,
            };
        case FETCH_DEVICE_SEGMENTID_SUCCESS:
            return {
                ...state,
                loading: false,
                segments: dotProp.set(state.segments, action.segmentId, action.device.deviceData),
                fetching: false,
                segmentId: undefined,
                error: undefined,
            };
        case FETCH_DEVICE_SEGMENTID_ERROR:
        case FETCH_DEVICE_SEGMENTID_PERIOD_ERROR:
            return {
                ...state,
                loading: false,
                fetching: false,
                segmentId: undefined,
                error: true,
            };
        case FETCH_DEVICE_SEGMENTID_PERIOD_SUCCESS:
            return {
                ...state,
                loading: false,
                fetching: false,
                segments: dotProp.merge(
                    state.segments,
                    `${action.segmentId}.fetchedIntervals`,
                    action.segment.fetchedIntervals
                ),
                error: undefined,
                segmentId: undefined,
            };
        case CLEAR_DEVICE_DATA:
        case LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
};
