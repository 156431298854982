import React from 'react';
import { useTranslation } from 'react-i18next';
import { NetTypes } from '../../models/commonEnums';

type Props = {
    rssi?: number;
    rsrq?: number;
    cell?: boolean;
    net?: NetTypes;
};

const Connections = ({ rssi, rsrq, cell, net }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    if (!cell) return <span>{txt('HubStrings.Ethernet')}</span>;

    const strength = net === NetTypes['4G'] ? `(RSRQ: ${rsrq} dB)` : `(RSSI: ${rssi} dBm)`;
    const showStrength = net === NetTypes['4G'] ? Boolean(rsrq) : Boolean(rssi);

    return (
        <span>
            {`${txt('HubStrings.SIM')}, ${net ?? ''}`} {showStrength ? strength : null}
        </span>
    );
};

export default Connections;
