import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceHolder from 'react-placeholder';
import { useSelector } from 'react-redux';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { userlane } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import SmartLinkIcon from 'commons/src/img/icon-smartlink';
import DeviceIcon from '../../../img/icon_device';
import { OrganizationHealthStatusSummary } from '../../../models/buildingModels';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import { RootState } from '../../../store';
import styles from './BuildingsStatusSummaryBar.module.scss';
import GlobalSearch from './components/globalSearch/GlobalSearch';

export type Props = {
    summary?: OrganizationHealthStatusSummary;
    onDownloadCsv: () => void;
};
const BuildingsStatusSummaryBar = ({ summary, onDownloadCsv }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const { loading, error } = useSelector(
        (state: RootState) => state.requests[RequestType.FetchOrganizationHealthStatusSummary]
    );

    if (error && !loading) {
        return <ResponseBox text={txt(`ErrorCodes.${error.error}`)} subtext={txt('SomethingWentWrongTryAgain')} />;
    }

    const totalBuildings = summary?.totalBuildings;
    const totalHubs = summary?.totalHubs;
    const totalDevices = summary?.totalDevices;

    return (
        <ReactPlaceHolder ready={!loading} customPlaceholder={userlane}>
            <section className={styles.statusBarWrapper}>
                <div className={styles.statusBarTotal}>
                    <span className={styles.statusBarBold}> {txt('Summary')}</span>
                    <TertiaryButton
                        onClick={onDownloadCsv}
                        title="CSV"
                        size="medium"
                        icon={<MaterialIcon name="download" />}
                    />
                    <div className={styles.statusBarContent}>
                        <MaterialIcon name="domain" />
                        <span>{totalBuildings}</span>
                        <span>{txt('Building.BuildingsLowerCase')}</span>
                    </div>
                    <div className={styles.statusBarContent}>
                        <SmartLinkIcon />
                        <span>{totalHubs} </span>
                        <span> {txt('Building.HubsLowerCase')}</span>
                    </div>
                    <div className={styles.statusBarContent}>
                        <DeviceIcon />
                        <span> {totalDevices} </span>
                        <span>{txt('DevicesLowerCase')} </span>
                    </div>
                </div>
                <GlobalSearch />
            </section>
        </ReactPlaceHolder>
    );
};

export default BuildingsStatusSummaryBar;
