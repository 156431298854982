import { useState, useEffect } from 'react';

type UsePaginationValue<T> = {
    paginatedData: T[];
    selectedPage: number;
    handlePaginationChange: (newPage: number) => void;
    paginationMetadata: PaginationMetadata;
};

export type PaginationMetadata = {
    pageFrom: number;
    pageTo: number;
    pagesCount: number;
};

const usePagination = <T>(data: T[], paginationLength?: number, totalData?: number): UsePaginationValue<T> => {
    const [totalLength, setTotalLength] = useState<number>(data.length);
    const [selectedPage, setSelectedPage] = useState<number>(1);

    const length = paginationLength ?? data.length;

    const getPaginationMetadata = (page: number): PaginationMetadata => {
        const pagesCount = Math.ceil(data.length / length);
        const pageFrom = Math.max(length * (page - 1));
        const pageTo = Math.min(length * page, data.length);
        return { pagesCount, pageFrom, pageTo };
    };

    const [paginationMetadata, setPaginationMetadata] = useState<PaginationMetadata>({
        pageTo: length,
        pageFrom: 0,
        pagesCount: Math.ceil(totalData ?? 0 / length),
    });
    const [paginatedData, setPaginatedData] = useState<T[]>(data.slice(0, length));

    const resetPagination = (): void => {
        setSelectedPage(1);
        setPaginatedData(data.slice(0, length));
        setTotalLength(data.length);
        const metadata = getPaginationMetadata(1);
        setPaginationMetadata(metadata);
    };

    useEffect((): void => {
        if (data.length !== totalLength) {
            // Once user applies search (possibility of narrowing dataset) start at page 1
            resetPagination();
        } else {
            setPaginatedData(data.slice(paginationMetadata.pageFrom, paginationMetadata.pageTo));
        }
    }, [data]);

    const handlePaginationChange = (newPage: number): void => {
        setSelectedPage(newPage);
        const metadata = getPaginationMetadata(newPage);
        setPaginationMetadata(metadata);
        setPaginatedData(data.slice(metadata.pageFrom, metadata.pageTo));
    };

    return { paginatedData, selectedPage, handlePaginationChange, paginationMetadata };
};

export default usePagination;
