import React from 'react';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import { SpacePropertyDefinitionAndValue } from '../../../models/spaceModels';
import SpaceAddCustomProperty from './SpaceAddCustomProperty';
import styles from './SpaceCustomProperties.module.scss';
import SpacePropertyComboBox from './SpacePropertyComboBox';

type CustomPropertiesProps = {
    customProperties: SpacePropertyDefinitionAndValue[];
    selectProperty: (payload: { propertyId?: string; propertyValue: string | null }) => void;
    selectedCustomProps: { [propertyId: string]: string | null };
    editProperty: (propertyId: string) => void;
};

const SpaceCustomProperties = ({
    customProperties,
    selectProperty,
    selectedCustomProps,
    editProperty,
}: CustomPropertiesProps): React.ReactElement => {
    const exists = (newLabel: string | null): boolean =>
        customProperties.some(property => property.propertyDefinition.propertyName === newLabel);

    return (
        <section className={styles.customPropertiesWrapper}>
            {customProperties.map(customProperty => (
                <div className={styles.row} key={customProperty.propertyDefinitionId}>
                    <SpacePropertyComboBox
                        property={customProperty}
                        onSelect={selectProperty}
                        selectedValue={selectedCustomProps[customProperty.propertyDefinitionId] || undefined}
                        key={customProperty.propertyDefinitionId}
                    />
                    <div className={styles.editButton}>
                        <TertiaryButton
                            title="Edit"
                            onClick={(): void => editProperty(customProperty.propertyDefinitionId)}
                        />
                    </div>
                </div>
            ))}
            <SpaceAddCustomProperty exists={exists} />
        </section>
    );
};

export default SpaceCustomProperties;
