import React from 'react';

const ViewPlusIcon = (): React.ReactElement => (
    <svg
        className="device-svg-icons"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
    >
        <path
            d="M32,9c8.3,0,15,6.7,15,15s-6.7,15-15,15H16C7.7,39,1,32.3,1,24S7.7,9,16,9H32z M32,11H16
            C8.8,11,3,16.8,3,24c0,7.1,5.7,12.8,12.7,13l0.3,0h16c7.2,0,13-5.8,13-13c0-7.1-5.7-12.8-12.7-13L32,11z M14.7,22.6l3.4-8.5h-1.6
            l-2.4,6.5h0l-2.3-6.5H10l3.4,8.5H14.7z M19.7,15.7c0.3,0,0.5-0.1,0.7-0.2c0.2-0.2,0.3-0.4,0.3-0.6s-0.1-0.5-0.3-0.6
            c-0.2-0.2-0.4-0.2-0.7-0.2s-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2,0.1,0.4,0.3,0.6C19.2,15.6,19.4,15.7,19.7,15.7z
            M20.4,22.6v-5.8H19v5.8H20.4z M25.2,22.7c1.1,0,1.9-0.4,2.5-1.2l0,0l-1-0.8c-0.2,0.2-0.4,0.4-0.6,0.5c-0.2,0.1-0.6,0.2-0.9,0.2
            c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.2-0.5-0.3s-0.3-0.3-0.4-0.4c-0.1-0.2-0.1-0.4-0.2-0.6l0,0H28c0-0.1,0-0.1,0-0.2l0,0v-0.2
            c0-0.5-0.1-1-0.2-1.4c-0.1-0.4-0.3-0.7-0.6-1c-0.2-0.3-0.5-0.5-0.9-0.6s-0.7-0.2-1.1-0.2c-0.4,0-0.8,0.1-1.2,0.2
            c-0.4,0.1-0.7,0.4-1,0.6c-0.3,0.3-0.5,0.6-0.6,1c-0.2,0.4-0.2,0.8-0.2,1.3c0,0.5,0.1,0.9,0.2,1.3c0.2,0.4,0.4,0.7,0.6,0.9
            c0.3,0.3,0.6,0.5,1,0.6S24.8,22.7,25.2,22.7z M26.5,19.1h-3c0-0.4,0.2-0.7,0.5-1c0.3-0.3,0.7-0.4,1.1-0.4c0.2,0,0.4,0,0.6,0.1
            c0.2,0.1,0.3,0.2,0.4,0.3s0.2,0.3,0.2,0.4C26.5,18.8,26.5,18.9,26.5,19.1L26.5,19.1z M32.4,22.6l1.3-4h0l1.3,4h1.5l2-5.8h-1.5
            l-1.2,4.1h0l-1.2-4.1h-1.5l-1.2,4.1h0l-1.2-4.1h-1.6l2,5.8H32.4z M14.8,34.6v-3.5H16c0.4,0,0.9,0,1.3-0.1c0.4-0.1,0.8-0.2,1-0.4
            c0.3-0.2,0.5-0.5,0.7-0.8c0.2-0.3,0.3-0.7,0.3-1.2c0-0.4-0.1-0.8-0.2-1.1s-0.4-0.6-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.4
            c-0.4-0.1-0.8-0.1-1.3-0.1l0,0h-2.8v8.5H14.8z M15.9,29.8h-1.1v-2.4h1.1c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0.1,0.6,0.2
            c0.2,0.1,0.3,0.2,0.4,0.4s0.2,0.4,0.2,0.6c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.2-0.6,0.2
            C16.4,29.8,16.1,29.8,15.9,29.8L15.9,29.8z M22.4,34.6v-9.1h-1.4v9.1H22.4z M26.5,34.7c0.4,0,0.8-0.1,1.1-0.3
            c0.3-0.2,0.5-0.5,0.7-0.8l0,0h0v0.9h1.4v-5.8h-1.4V32c0,0.2,0,0.4-0.1,0.6s-0.1,0.4-0.2,0.5s-0.2,0.3-0.4,0.4
            c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.6-0.1c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.2,0-0.4,0-0.5l0,0v-3.2
            h-1.4v3.6c0,0.3,0,0.6,0.1,0.9s0.2,0.5,0.4,0.7c0.2,0.2,0.4,0.4,0.7,0.5S26.1,34.7,26.5,34.7z M33.4,34.7c0.3,0,0.6,0,0.9-0.1
            s0.5-0.2,0.8-0.3c0.2-0.2,0.4-0.4,0.5-0.6s0.2-0.5,0.2-0.9c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.4-0.4-0.5s-0.4-0.2-0.6-0.3
            s-0.4-0.1-0.7-0.2c-0.4-0.1-0.7-0.2-0.9-0.3c-0.2-0.1-0.3-0.2-0.3-0.5c0-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.4-0.1,0.6-0.1
            c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.5,0.4l0,0l0.9-0.8c-0.2-0.3-0.5-0.5-0.9-0.7s-0.8-0.2-1.2-0.2c-0.3,0-0.6,0-0.8,0.1
            s-0.5,0.2-0.7,0.3s-0.4,0.3-0.5,0.6s-0.2,0.5-0.2,0.8c0,0.3,0,0.5,0.1,0.7s0.2,0.3,0.4,0.5c0.2,0.1,0.3,0.2,0.5,0.3
            c0.2,0.1,0.4,0.1,0.6,0.2c0.4,0.1,0.8,0.2,1,0.3s0.3,0.3,0.3,0.5c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.2
            c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.6-0.5l0,0l-0.9,0.9
            c0.3,0.3,0.6,0.5,1.1,0.7C32.6,34.7,33,34.7,33.4,34.7z"
        />
    </svg>
);

export default ViewPlusIcon;
