import { ErrorType } from '../models/commonTypeScript';

export enum RequestActionType {
    Success = 'SUCCESS',
    Error = 'ERROR',
    Init = 'INIT',
    ClearError = 'CLEAR_ERROR',
    Streaming = 'STREAMING', // Some data will come in the background
}

export const requestError = <RequestType>(
    error: ErrorType,
    requestType: RequestType,
    requestActionType: RequestActionType
): { requestActionType: RequestActionType.Error; requestType: RequestType; type: string; error: ErrorType } => ({
    requestActionType: RequestActionType.Error as RequestActionType.Error,
    requestType,
    type: `${requestType}/${requestActionType}`,
    error,
});

export const requestSuccess = <RequestType>(
    requestType: RequestType,
    requestActionType: RequestActionType
): { requestActionType: RequestActionType.Success; requestType: RequestType; type: string } => ({
    requestActionType: RequestActionType.Success as RequestActionType.Success,
    requestType,
    type: `${requestType}/${requestActionType}`,
});

export const requestStreaming = <RequestType>(
    requestType: RequestType,
    requestActionType: RequestActionType
): { requestActionType: RequestActionType.Streaming; requestType: RequestType; type: string } => ({
    requestActionType: RequestActionType.Streaming as RequestActionType.Streaming,
    requestType,
    type: `${requestType}/${requestActionType}`,
});

export const clearError = <RequestType>(
    requestType: RequestType
): { requestActionType: RequestActionType.ClearError; requestType: RequestType; type: RequestActionType } => ({
    requestActionType: RequestActionType.ClearError as RequestActionType.ClearError,
    requestType,
    type: RequestActionType.ClearError,
});
