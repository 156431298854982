import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_TOGGLED_RESET_CONNECTION } from 'commons/src/analytics/AnalyticsEvents';
import FeatureToggle from 'commons/src/components/buttons/FeatureToggle';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import spinner from 'commons/src/img/spinner';
import {
    BuildingConfiguration,
    ErrorType,
    LocationDevicesConfigurationRequest,
    UpdateBuildingConfig,
} from 'commons/src/models/commonTypeScript';
import {
    FetchLocationResetConnections,
    fetchLocationResetConnections,
    UpdateLocationConfig,
    updateLocationConfig,
} from '../../../actions/locationActions';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import ResetConnectionsModal from './ResetConnectionsModal';

export type ParentProps = {
    locationId: string;
};

type StateProps = {
    configurations: { [locationId: string]: BuildingConfiguration };
    error?: ErrorType;
    loadingResetConnections: boolean;
};
export type ActionProps = {
    onUpdateBuildingConfig: (config: UpdateBuildingConfig, locationId: string) => void;
    onFetchResetConnections: (locationId: string) => void;
};
export type Props = StateProps & ParentProps & ActionProps;

export const ResetConnectionComponent = (props: Props): React.ReactElement => {
    const {
        locationId,
        configurations,
        onUpdateBuildingConfig,
        error,
        loadingResetConnections,
        onFetchResetConnections,
    } = props;
    const { t: txt } = useTranslation();
    const buildingConfig = configurations[locationId];

    const [resetEnabledToggle, setResetEnabledToggle] = useState(!!buildingConfig && buildingConfig.resetEnabled);
    const [displayResetModal, setDisplayResetModal] = useState(false);

    useEffect(() => {
        if (resetEnabledToggle) {
            onFetchResetConnections(locationId);
        }
    }, [resetEnabledToggle]);

    const onSubmit = (): void => {
        const newResetValue = !resetEnabledToggle;
        setResetEnabledToggle(newResetValue);
        const devicesConfig: LocationDevicesConfigurationRequest = {
            ledBehaviour: buildingConfig && buildingConfig.configuration.ledBehaviour,
            ledBrightness: buildingConfig && buildingConfig.configuration.ledBrightness,
            thresholds: buildingConfig && buildingConfig.configuration.thresholds,
            staleAir: buildingConfig && buildingConfig.configuration.staleAir,
        };
        onUpdateBuildingConfig(
            {
                configuration: devicesConfig,
                resetEnabled: newResetValue,
            },
            locationId
        );

        analyticsLogger(BUILDING_TOGGLED_RESET_CONNECTION, {
            resetConnection: newResetValue,
        });
    };

    return (
        <form className="edit-building__form">
            {displayResetModal && <ResetConnectionsModal onClose={(): void => setDisplayResetModal(false)} />}
            <FeatureToggle
                description="ResetConnection.ToggleDescription"
                enabled={resetEnabledToggle}
                onUpdate={onSubmit}
                testIdOn="toggle-on"
                testIdOff="toggle-off"
            />
            {loadingResetConnections && <div className="centered">{spinner}</div>}
            {!loadingResetConnections && resetEnabledToggle && (
                <div className="edit-building__align-margin">
                    <TertiaryButton
                        onClick={(): void => setDisplayResetModal(true)}
                        title="ResetConnection.DisplayConnections"
                    />
                </div>
            )}
            {error && <ResponseBox text="SomethingWentWrong" subtext={txt(`ErrorCodes.${error.error}`)} />}
        </form>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        requests: {
            [RequestType.GetLocationConfiguration]: { error },
            [RequestType.UpdateLocationConfiguration]: { error: configError },
            [RequestType.FetchLocationResetConnections]: { loading: loadingResetConnections },
        },
        buildings: { configurations },
    } = state;

    return {
        configurations,
        error: configError || error,
        loadingResetConnections,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onUpdateBuildingConfig: (config: UpdateBuildingConfig, locationId: string): UpdateLocationConfig =>
        dispatch(updateLocationConfig(config, locationId)),
    onFetchResetConnections: (locationId: string): FetchLocationResetConnections =>
        dispatch(fetchLocationResetConnections(locationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetConnectionComponent);
