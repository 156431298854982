import React from 'react';
import classNames from 'classnames';
import { Column, useSortBy, useTable } from 'react-table';
import styles from './PartnerSummary.module.scss';
import { PartnerSummaryTablePerDevice, PartnerSummaryTablePerThreshold } from './PartnerSummaryModels';

type Props = {
    columns: Column[];
    tableData: PartnerSummaryTablePerThreshold[] | PartnerSummaryTablePerDevice[];
};

const PartnerSummaryTable = ({ columns, tableData }: Props): React.ReactElement => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data: tableData,
        },
        useSortBy
    );

    if (rows.length === 0) return <div />;

    return (
        <div {...getTableProps()} className={styles.table}>
            {headerGroups.map(headerGroup => (
                <div
                    {...headerGroup.getHeaderGroupProps({
                        className: styles.header,
                    })}
                >
                    {headerGroup.headers.map(column => (
                        <div
                            {...column.getHeaderProps([
                                { className: classNames(styles.cell, column.className) },
                                column.getSortByToggleProps(),
                            ])}
                        >
                            <span>{column.render('Header')}</span>
                        </div>
                    ))}
                </div>
            ))}
            <div {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <div {...row.getRowProps({ className: styles.row })}>
                            {row.cells.map(({ getCellProps, render, column }) => {
                                return (
                                    <div
                                        {...getCellProps({
                                            className: classNames(styles.cell, column.className),
                                        })}
                                    >
                                        <span>{render('Cell')}</span>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PartnerSummaryTable;
