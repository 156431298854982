import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { Link } from 'react-router-dom';
import monthAndWeekConfig from 'commons/src/commonHighChartsFunctions';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { energyInsightPlaceholder } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats, colors } from 'commons/src/constants';
import { EnergyEvents } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { getBusinessIntercomArticleLink } from '../../../constants';
import { HeatingCoolingData } from '../../../models/buildingModels';
import { IntercomArticleId } from '../../../models/commonEnums';
import heatingCoolingConfig from './HeatingCoolingConfig';
import styles from './HeatingCoolingGraph.module.scss';
import HeatingCoolingSelectedBar from './HeatingCoolingSelectedBar';

type ParentProps = {
    data: HeatingCoolingData;
    language: string;
    dateFormat: keyof typeof dateFormats;
    loading: boolean;
    error?: ErrorType;
    locationId: string;
    openingHoursEnabled: boolean;
    numberOfDevicesInLocation: number;
};

type Props = ParentProps;

const eventColors = {
    [EnergyEvents.colderOutsideOpeningHours]: colors.blueBondi,
    [EnergyEvents.coolingOnClosedDay]: colors.greenJade,
    [EnergyEvents.colderThan5C]: colors.blueAzul,
    [EnergyEvents.heatingOnClosedDay]: colors.yellowHotButter,
    [EnergyEvents.warmerOutsideOpeningHours]: colors.redAuburn,
};

const HeatingCoolingGraph = ({
    data,
    dateFormat,
    loading,
    error,
    language,
    locationId,
    openingHoursEnabled,
    numberOfDevicesInLocation,
}: Props): React.ReactElement => {
    const alphabet = 'ABCDE';
    const { t: txt } = useTranslation();
    const [detailsExpanded, setDetailsExpanded] = useState(false);
    const [selectedPoint, setSelectedPoint] = useState<string | undefined>(undefined);

    const setLanguage = (): void => {
        const translatedOptions = monthAndWeekConfig(txt);
        Highcharts.setOptions(translatedOptions);
    };

    useEffect(() => {
        if (loading) {
            setDetailsExpanded(false);
            setSelectedPoint(undefined);
        }
    }, [loading]);

    useEffect(() => {
        setLanguage();
    }, []);

    if (error) {
        return <ResponseBox text={error.error} />;
    }

    type EventType = {
        name: string;
        id: EnergyEvents;
        data: [number, number | null][];
        devices: string[];
        color: string;
        className: string;
        dataLabels?: { align: string; format: string }[];
    };

    const initialSeries = Object.keys(EnergyEvents).map(
        (event): EventType => ({
            name: txt(`BuildingEnergy.${event}`),
            id: event as EnergyEvents,
            data: [],
            devices: [],
            color: eventColors[event as keyof typeof eventColors],
            className: styles[`${event}Bar`],
        })
    );

    const updatedInitSeries = initialSeries;
    data.forEach(day => {
        const dateTime = moment.utc(day.timestamp);
        const unixTimeStamp = dateTime.unix() * 1000;

        initialSeries.forEach(series => {
            const initialSeriesOfType = updatedInitSeries.find(s => s.id === series.id);
            const eventForSeries = day.events.find(event => event.eventType === series.id);
            const devicesInEvent = eventForSeries?.devices.map(device => device.serialNumber) || [];
            const devicesNotInEventList = devicesInEvent.filter(device => !series.devices.includes(device));
            if (initialSeriesOfType) {
                initialSeriesOfType.devices.push(...devicesNotInEventList);
                initialSeriesOfType.data.push([unixTimeStamp, eventForSeries?.devices.length || null]);
            }
        });
    });

    const seriesWithData = updatedInitSeries
        .filter(series => series.devices.length > 0)
        .map((series, index) => ({
            ...series,
            name: `${alphabet.charAt(index)}. ${series.name}`,
            dataLabels: [{ align: 'center', format: alphabet.charAt(index) }],
        }));
    const devicesWithOptimisationPossibilities = [...new Set(initialSeries.flatMap(series => series.devices))].length;

    const onSelectGraphEvent = (clickData: PointerEvent): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const pointOnXAxis = clickData.point.category;
        const pointAsTimestamp = moment.utc(pointOnXAxis).format('YYYY-MM-DD');
        if (selectedPoint === pointAsTimestamp) {
            setSelectedPoint(undefined);
        } else setSelectedPoint(pointAsTimestamp);
    };

    const graphConfig = heatingCoolingConfig({
        dateFormat,
        graphId: 'heatingCoolingGraph',
        language,
        text: txt,
        series: seriesWithData || [],
        onClick: onSelectGraphEvent,
    });

    return (
        <ReactPlaceholder ready={!loading} customPlaceholder={energyInsightPlaceholder}>
            {updatedInitSeries ? (
                <div className={styles.graphCard}>
                    <div className={styles.graphHeader}>
                        <div className={styles.boldHeader}>
                            <div>
                                {devicesWithOptimisationPossibilities}/{numberOfDevicesInLocation}
                            </div>
                            {txt('DevicesLowerCase')}
                        </div>
                        {txt('BuildingEnergy.ShowPossibleOptimization')}
                    </div>
                    <div className={styles.cardContent}>
                        {!detailsExpanded && (
                            <div className={styles.graphWrapper}>
                                <div className={styles.graph}>
                                    <HighchartsReact highcharts={Highcharts} options={graphConfig} />
                                </div>
                            </div>
                        )}
                        <HeatingCoolingSelectedBar
                            detailsExpanded={detailsExpanded}
                            setDetailsExpanded={setDetailsExpanded}
                            heatingCoolingData={data}
                            selectedPoint={selectedPoint}
                            dateFormat={dateFormat}
                            locationId={locationId}
                        />
                    </div>
                    <div className={styles.graphFooter}>
                        {txt('BuildingEnergy.HeatingCoolingConsiderResults')}
                        <a
                            className={styles.link}
                            href={getBusinessIntercomArticleLink(IntercomArticleId.HEATING_COOLING)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <MaterialIcon name="arrow_forward" />
                            {txt('BuildingEnergy.LearnAboutHeatingCoolingCategories')}
                        </a>
                    </div>
                </div>
            ) : (
                <div className={styles.noData}>
                    {txt('BuildingEnergy.NoHeatingCoolingEvents')}
                    {!openingHoursEnabled && (
                        <div className={styles.enableOpeningHours}>
                            <Trans i18nKey="BuildingEnergy.OpeningHoursRequired">
                                <Link to={`/buildings/${locationId}/settings`}>
                                    {txt('Building.DayScheduleOpeningHours')}
                                </Link>
                            </Trans>
                        </div>
                    )}
                </div>
            )}
        </ReactPlaceholder>
    );
};

export default HeatingCoolingGraph;
