/* eslint max-len: ["error", 1000] */
import React from 'react';

const IconMoistGuard = (): React.ReactElement => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="iconMoistGuard">
        <g id="opacity_24px">
            <path
                d="M12 2.34497L17.66 7.99497C19.22 9.55497 20 11.635 20 13.635C20 15.635 19.22 17.745 17.66 19.305C16.1
                 20.865 14.05 21.655 12 21.655C9.95 21.655 7.9 20.865 6.34 19.305C4.78 17.745 4 15.635 4 13.635C4 11.635
                 4.78 9.55497 6.34 7.99497L12 2.34497ZM7.76 9.59497C6.62 10.725 6.01 11.995 6 13.995H18C17.99 11.995 17.38
                 10.765 16.24 9.64497L12 5.26497L7.76 9.59497Z"
            />
        </g>
    </svg>
);

export default IconMoistGuard;
