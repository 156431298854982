import React, { SyntheticEvent } from 'react';
import CheckBox from 'commons/src/components/input/Checkbox';
import MaterialIcon from 'commons/src/components/MaterialIcon';

export type Props = {
    toggleSystemNotification: (id: string) => void;
    id: string;
    icon: string | React.ReactElement;
    description: string;
    checked: boolean;
};

export const SystemNotificationRuleComponent = (props: Props): React.ReactElement => {
    const { toggleSystemNotification, id, icon, description, checked } = props;

    const ruleLabel = (
        <div className="notification-alert__icons">
            <div className="device-svg-icons notification-alert__icons__circle notification-alert__icons__circle--yellow">
                {typeof icon === 'string' ? <MaterialIcon name="icon" /> : icon}
            </div>
            <div className="notification-alert__icons__system-notification-text">{description}</div>
        </div>
    );

    const toggleRule = (event: SyntheticEvent<HTMLInputElement>): void => {
        const ruleId = event.currentTarget.id;
        toggleSystemNotification(ruleId);
    };

    return (
        <div className="notification-alert__form">
            <div className="form__field">
                <CheckBox
                    label={ruleLabel}
                    labelIsElement
                    id={id}
                    onChange={toggleRule}
                    checked={checked}
                    testId={id}
                />
            </div>
        </div>
    );
};

export default SystemNotificationRuleComponent;
