import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'react-i18next';

import ReactPlaceholder from 'react-placeholder';
import { getValidLanguageTagForMoment, mapDeviceType } from 'commons/src/commonFunctions';
import SectionHeader from 'commons/src/components/headers/SectionHeader';
import DeviceStatusPill from 'commons/src/components/pills/DeviceStatusPill';
import DeviceName from 'commons/src/components/tableCells/DeviceName';
import Firmware from 'commons/src/components/tableCells/Firmware';
import { batteryLevels } from 'commons/src/constants';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';
import DeviceHealthStatus from 'commons/src/models/deviceHealthStatus';
import MaterialTable from '../../../components/materialTable/MaterialTable';
import MaterialTablePlaceholder from '../../../components/materialTable/MaterialTablePlaceholder';
import { MaterialTableColumn } from '../../../components/materialTable/models';
import { BUILDING_HEALTH_TABLE_LENGTH } from '../../../constants';
import { BuildingHealthRowData } from '../../../models/buildingHealthModels';
import styles from './BuildingStatus.module.scss';

dayjs.extend(relativeTime);

type Props = {
    devices: BuildingHealthRowData[];
    isReady: boolean;
    totalDevices: number;
    isLoading?: boolean;
    locationId: string;
};

const DevicesTable = ({ devices, isReady, totalDevices, isLoading, locationId }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const columns: MaterialTableColumn[] = [
        {
            accessor: 'segmentName',
            label: txt('HealthStatus.Name'),
            sortable: true,
            width: '150px',
            renderer: ({ rowData }): React.ReactNode => (
                <DeviceName
                    name={rowData.segmentName as string}
                    serialNumber={rowData.serialNumber as string}
                    locationId={rowData.locationId as string}
                    spaceId={rowData.spaceId as string}
                />
            ),
            locked: true,
        },
        {
            accessor: 'type',
            label: txt('HealthStatus.Type'),
            sortable: true,
            width: '110px',
            defaultHidden: true,
            renderer: ({ rowData }): React.ReactNode => {
                const type = rowData?.type as AnyDeviceType;
                if (!type) return null;
                return <span>{txt(`${mapDeviceType(type)}FullName`)}</span>;
            },
        },
        { accessor: 'floor', label: txt('HealthStatus.Floor'), sortable: true, width: '70px' },
        {
            accessor: 'batteryPercentage',
            label: txt('HealthStatus.Battery'),
            sortable: true,
            renderer: ({ rowData }): React.ReactNode => {
                const batteryPercentage = rowData.batteryPercentage as number;
                const batteryPercentageColor =
                    batteryPercentage <= batteryLevels.low || !batteryPercentage ? styles.batteryWarning : '';
                if (rowData?.batteryPercentage === undefined) return null;
                return <span className={batteryPercentageColor}>{`${rowData.batteryPercentage}%`}</span>;
            },
            width: '80px',
        },
        {
            accessor: 'rssi',
            label: txt('HealthStatus.RSSI'),
            sortable: true,
            width: '80px',
            renderer: ({ rowData }): React.ReactNode => (rowData.rssi ? `${rowData.rssi} dBm` : null),
            infoBox: txt('HealthStatus.RSSITooltip'),
        },
        {
            accessor: 'firmware',
            label: txt('HealthStatus.Firmware'),
            sortable: false,
            width: '200px',
            defaultHidden: true,
            renderer: ({ rowData }): React.ReactNode => <Firmware firmware={rowData.firmware as object} />,
            infoBox: txt('HealthStatus.FirmwareTooltip'),
        },
        {
            accessor: 'lastSeen',
            label: txt('HealthStatus.LastSynced'),
            sortable: true,
            width: '120px',
            renderer: ({ rowData }): React.ReactNode => {
                const validLanguageKey = getValidLanguageTagForMoment();
                if (!rowData.lastSeen) return null;
                const lastSeen = dayjs
                    .utc(rowData.lastSeen as string)
                    .local()
                    .locale(validLanguageKey)
                    .fromNow(true);
                return <span>{lastSeen}</span>;
            },
        },
        {
            accessor: 'healthStatus',
            label: txt('HealthStatus.Status'),
            sortable: true,
            renderer: ({ rowData }): React.ReactNode => (
                <DeviceStatusPill status={rowData.healthStatus as DeviceHealthStatus} deviceType="device" />
            ),
            width: '280px',
        },
    ];

    return (
        <div>
            <SectionHeader headerType={3} headerName={txt('Devices')} initialHeader />
            <ReactPlaceholder ready={!isLoading} customPlaceholder={<MaterialTablePlaceholder rows={10} cells={6} />}>
                <MaterialTable
                    idKey="serialNumber"
                    tableId="building_health_devices"
                    columns={columns}
                    data={devices}
                    isReady={isReady}
                    pagination={BUILDING_HEALTH_TABLE_LENGTH}
                    totalDataLength={totalDevices}
                    allowColumnManagement
                    locationId={locationId}
                />
            </ReactPlaceholder>
        </div>
    );
};

export default DevicesTable;
