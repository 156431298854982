/* eslint max-len: ["error", 15000] */
import React from 'react';

const OutdoorVsIndoorIllustration = (): React.ReactElement => (
    <svg width="596px" height="300px" viewBox="0 0 596 300" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path
            fill="#FFFFFF"
            d="M444.1,258.2l-1.6,8.6l-35.5-7.6c0,0-75.1-1.6-75.1-3.1l-1.2-127l-9-0.2l44.9-44.2l156.8-0.2l1.6,173.3L444.1,258.2z"
        />
        <path
            fill="#42515A"
            d="M76.2,221.2c8.3,0.3,16.6,0.2,24.9,0.2H126c16.6,0,33.3,0.2,49.9,0.2c33.1,0,66.2,0,99.2,0
        c18.7,0,37.3,0.2,56-0.2c0.2,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5c-16.6-0.4-33.3-0.2-49.9-0.2c-16.6,0-33.3,0-49.9,0
        c-33.1,0-66.2,0-99.2,0.2c-9.3,0.1-18.7,0-28,0s-18.7-0.2-28,0.2c-0.2,0-0.3,0.2-0.3,0.3C75.9,221,76,221.2,76.2,221.2L76.2,221.2z
        "
        />
        <path
            fill="#42515A"
            d="M77.3,201.5c4.4-1.9,8.4-4.4,12.5-6.7c4.4-2.5,9.1-4.4,13.6-6.7c8.6-4.4,16.2-9.9,22.6-17.1
        c1.7-2,3.2-4,4.9-5.9c1.2-1.4,3.9-4,5.7-2.1c0.3,0.3,0.9,0.3,1.2,0c0,0,0.1-0.1,0.1-0.2c2-3.4,3.9-6.7,5.8-10l-1.4,0.2
        c4.9,4.2,8.7,9.5,11.3,15.4c2.5,5.6,4.2,11.8,8.1,16.6c3.5,4.4,8.6,7.1,13.9,9c6.3,2.3,12.9,3.9,19.3,5.9c3.6,1.1,7.2,2.1,10.7,3.1
        c3.1,0.8,6.4,1.6,9.6,1.3c3.1-0.3,5.3-1.9,8-3.4c1.2-0.7,2.5-1.4,3.9-1.2c1.9,0.2,3.6,1.3,5.2,2.2c11.3,6.2,24.5,8.6,37.2,6.7
        c14.3-2.1,29.4-9.6,43.7-3.7c0.3,0.1,0.7-0.1,0.9-0.3c2.7-6.2,11.1-9.3,16.4-4.3c0.7,0.7,1.7-0.4,1.1-1.1c-6-5.4-15.7-2.6-18.8,4.5
        l0.9-0.3c-12.2-5.1-25.4-0.6-37.6,2.1c-6.5,1.4-13,2.3-19.6,1.8c-6.7-0.6-13.2-2.2-19.3-5c-1.4-0.6-2.7-1.3-4-2.1
        c-1.5-0.8-3-1.7-4.6-2.2c-2.9-0.7-5.3,0.6-7.6,2.1c-2.9,1.8-5.8,2.6-9.2,2.3c-3.4-0.3-6.7-1.5-9.9-2.5c-6.9-2.1-13.8-4.1-20.8-6.2
        c-5.5-1.6-11.1-3.5-15.5-7.3c-5.3-4.7-7.3-11.6-10-17.9c-2.7-6.5-6.8-12.3-12.2-16.9c-0.3-0.3-0.9-0.3-1.2,0c0,0-0.1,0.1-0.1,0.2
        l-5.8,10l1.4-0.2c-3-3.3-7.1,0.7-9,3.1c-3.1,4-6.6,7.8-10.4,11.2c-3.9,3.4-8,6.2-12.4,8.8c-4.7,2.6-9.8,4.6-14.5,7.3
        c-4.9,2.9-9.7,5.9-14.8,8.6C76.2,200.9,76.7,201.7,77.3,201.5L77.3,201.5z"
        />
        <path
            fill="#42515A"
            d="M120,183.1c1.2-0.9,2.2-1.7,3.4-2.7c1.1-1,2.1-2,3.1-3c2.1-2.1,4-4.2,5.9-6.4c1.1-1.2,2.1-2.5,3.1-3.9
        l-1.2-0.3c0.2,1.8,0.6,3.8,1.6,5.3c0.9,1.3,2.4,2,3.9,1.6c0.7-0.2,1.3-0.6,1.7-1.2c0.2-0.2,0.3-0.6,0.5-0.9c0-0.2,0.1-0.4,0.1-0.6
        c0-0.2,0-0.5,0-0.7l0,0c0-0.1,0-0.1-0.2,0.1c0.1,0,0.2,0.1,0.2,0.1c0.2,0.1,0.3,0.2,0.6,0.4c0.4,0.2,0.8,0.6,1.2,0.8
        c0.7,0.6,1.6,1.1,2.3,1.6c0.8,0.5,1.8,0.8,2.8,0.9c1,0.1,2-0.3,2.5-1.2c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.4-0.2-0.6-0.1
        c-1.4,0.7-2.8,0.3-4.1-0.4c-0.7-0.4-1.5-0.9-2.1-1.4c-0.7-0.5-1.3-1.1-2.1-1.4c-1.1-0.5-1.9,0.2-2,1.3c0,0.6,0,1.2-0.5,1.5
        c-0.8,0.7-2.1,0.6-2.8-0.2c-1.3-1.4-1.6-3.5-1.8-5.3c-0.1-0.7-1-0.6-1.3-0.2c-3.5,4.6-7.2,9-11.3,13c-1.2,1.2-2.2,2.3-3.4,3.5
        C119.5,183,119.8,183.2,120,183.1L120,183.1z"
        />
        <rect x="370.6" y="178.2" fill="#C3E3F2" width="37.7" height="76.9" />
        <path
            fill="#42515A"
            d="M367.8,252l-0.1-22.9l-0.1-22.9l-0.1-22.9c0-0.9,0-1.9,0-2.8c0-0.7-0.1-1.5,0.4-2.1
        c0.4-0.4,1.1-0.6,1.6-0.6c0.8-0.1,1.7-0.1,2.6-0.1c3.8-0.1,7.6-0.1,11.3,0c7.6,0.1,15,0.5,22.5,1.2c1,0.1,1.9,0.2,2.8,0.2l-0.7-0.7
        l-0.2,30.8l-0.2,30.8l-0.1,17.2c0,0.9,1.4,0.9,1.4,0l0.2-30.8l0.2-30.8l0.1-17.2c0-0.4-0.3-0.7-0.7-0.7c-7.6-0.7-15.4-1.2-23.1-1.4
        c-3.9-0.1-7.7-0.1-11.6,0c-0.9,0-1.9,0-2.8,0.1c-0.8,0-1.6,0-2.4,0.1c-0.7,0.1-1.4,0.3-2,0.8c-0.6,0.6-0.9,1.2-0.9,2.1
        c-0.1,0.9-0.1,1.8,0,2.7v2.9v5.8v11.7l0.1,23.2l0.1,23.3v2.9c0,0.9,1.4,0.9,1.4,0L367.8,252z"
        />
        <path
            fill="#42515A"
            d="M408.6,179.1c8.6-2,17.3-3.7,26-5.3c2.5-0.4,4.9-0.8,7.3-1.2c0.3-0.1,0.6-0.5,0.5-0.8
        c-0.1-0.3-0.5-0.6-0.8-0.5c-8.7,1.4-17.3,3.1-26,4.9c-2.5,0.5-4.9,1.1-7.3,1.6C407.4,178,407.8,179.3,408.6,179.1L408.6,179.1z"
        />
        <path
            fill="#42515A"
            d="M441.4,172.5l0.7,36.8l0.7,36.6l0.3,20.6c0,0.9,1.4,0.9,1.4,0l-0.7-36.8l-0.7-36.6l-0.3-20.6
        C442.8,171.7,441.3,171.7,441.4,172.5z"
        />
        <path
            fill="#42515A"
            d="M407.7,259c8.3,3.1,16.9,5.6,25.5,7.2c2.5,0.5,4.9,0.8,7.4,1.2c0.4,0,0.7-0.3,0.7-0.7
        c0-0.3-0.3-0.7-0.7-0.7c-8.6-1.2-17.2-3.1-25.5-5.8c-2.4-0.8-4.8-1.6-7.1-2.5C407.2,257.3,406.9,258.7,407.7,259L407.7,259z"
        />
        <path
            fill="#42515A"
            d="M435.5,173.1l1.1,35.8l1.1,35.7l0.6,20.1c0,0.9,1.4,0.9,1.4,0l-1.1-35.8l-1.1-35.7l-0.6-20.1
        C436.8,172.2,435.4,172.2,435.5,173.1z"
        />
        <path
            fill="#42515A"
            d="M443.2,265.9c-1.6,0.2-3.1,0.2-4.7-0.3c-0.3-0.1-0.7,0.1-0.8,0.5c-0.1,0.3,0.1,0.7,0.5,0.8
        c1.7,0.6,3.6,0.7,5.3,0.3c0.3-0.1,0.6-0.5,0.5-0.8C443.9,266.1,443.6,265.9,443.2,265.9L443.2,265.9z"
        />
        <path
            fill="#42515A"
            d="M407.9,258.4c-10.8,0.3-21.5,0.7-32.3-0.2c-3-0.2-6.1-0.6-9-1.1c-0.3-0.1-0.7,0.1-0.8,0.5
        c-0.1,0.3,0.1,0.7,0.5,0.8c10.8,1.6,21.7,1.8,32.6,1.6c3-0.1,6.1-0.2,9.1-0.2C408.8,259.6,408.8,258.4,407.9,258.4L407.9,258.4z"
        />
        <path
            fill="#42515A"
            d="M366.1,83.5c-7.3,7.9-15,15.5-22.9,22.6c-4,3.6-8.1,7.1-12.3,10.5c-0.2,0.2,0.3-0.2,0,0l-0.2,0.2
        c-0.2,0.2-0.5,0.3-0.7,0.5l-1.6,1.2c-1.3,0.9-2.5,1.8-3.8,2.8c-1.2,1-2.3,2.2-2.9,3.7c-0.6,1.6,0.1,3.1,1.5,4
        c0.7,0.3,1.5,0.2,1.9-0.5c0.3-0.7,0.2-1.5-0.5-1.9c-0.2-0.2,0,0,0,0s-0.2-0.1-0.2-0.2c0,0-0.2-0.2-0.2-0.2c0.2,0.1,0.2,0.2,0.1,0.1
        c0,0,0-0.1-0.1-0.2c-0.2-0.2,0.1,0.2,0.1,0.2c0-0.1,0-0.2-0.1-0.2c-0.1-0.2,0.1-0.1,0,0.2c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
        c0-0.2,0.1,0.1,0,0.2c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.2c0.1-0.2-0.2,0.3,0,0c0.1-0.2,0.2-0.5,0.4-0.7l0.1-0.1
        c0.2-0.2-0.2,0.2,0,0c0.1-0.2,0.2-0.2,0.3-0.4c0.5-0.5,0.9-1,1.4-1.4c0.2-0.2,0.6-0.4,0.8-0.7l0.2-0.2c0.2-0.2-0.2,0.2,0,0l0.5-0.3
        c0.6-0.4,1.2-0.8,1.7-1.2c1-0.7,2-1.3,2.9-2.1c4-3.2,7.9-6.6,11.8-10c3.9-3.4,7.7-7.1,11.4-10.7c4.2-4,8.2-8.2,12.2-12.5
        c0.5-0.6,0.5-1.4,0-2C367.5,83,366.7,83,366.1,83.5L366.1,83.5z"
        />
        <path
            fill="#42515A"
            d="M431.8,222.6c-1.2-0.3-2.3,0.4-2.8,1.4c-0.2,0.6-0.3,1.2-0.2,1.7c0.2,0.6,0.5,1.1,1,1.4
        c0.9,0.6,2,0.6,2.9,0.1c1.1-0.6,1.5-1.7,1.2-2.9c-0.2-0.7-0.7-1.2-1.2-1.6c-0.6-0.4-1.3-0.6-2-0.4c-0.3,0.1-0.6,0.5-0.5,0.8
        c0.1,0.3,0.5,0.6,0.8,0.5c-0.2,0-0.1,0,0,0h0.2h0.2c0,0-0.2,0-0.1,0c0.1,0,0.1,0,0.1,0h0.2h0.1c-0.1,0,0,0,0.1,0l0.2,0.1l0.2,0.1
        c0.1,0.1-0.1-0.1,0,0c0.1,0.1,0.2,0.2,0.2,0.2c0,0,0.1,0.1,0,0.1c-0.1,0,0,0,0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.2v0.1c0,0.1,0-0.2,0,0
        c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.2c0,0,0,0,0,0.1c0,0.2,0,0,0,0v0.2c0,0,0,0,0,0.1c0,0.1,0,0,0,0s0-0.1,0,0v0.1c0,0-0.1,0.2,0,0.2
        c0,0,0.1-0.2,0,0c0,0.1-0.1,0.2-0.2,0.2c0-0.1,0.1-0.1,0,0l0,0l-0.1,0.1l-0.1,0.1c0,0-0.1,0,0,0c0,0,0,0-0.1,0s-0.2,0.1-0.2,0.2
        c0,0-0.1,0-0.1,0.1c0,0,0.2,0,0.1,0h-0.1c-0.1,0-0.2,0-0.2,0.1h-0.1c-0.2,0,0.2,0,0,0c-0.2,0-0.3,0-0.5,0c0.2,0,0.1,0,0,0h-0.2
        h-0.1h-0.1c-0.1,0,0,0,0.1,0c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1,0-0.1-0.1c0,0,0.1,0.1,0.1,0h-0.1l-0.1-0.1c0,0-0.1-0.2,0,0
        c0.1,0.1,0,0,0,0s-0.1-0.1-0.1-0.2c-0.1-0.2,0,0.1,0,0v-0.1c0,0,0-0.2,0-0.1c0,0.1,0,0,0-0.1c0-0.1,0-0.1,0-0.2s0,0,0-0.1
        c0,0,0,0,0,0.1c0-0.1,0.1-0.2,0.1-0.2l0.1-0.2c0,0-0.1,0.2,0,0.1v-0.1c0.1-0.1,0.1-0.2,0.2-0.2c0-0.1,0,0,0,0s0-0.1,0.1-0.1
        l0.1-0.2l0.1-0.1l0,0c0.1-0.1-0.1,0.1,0,0c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1-0.1,0-0.1,0h0.1h0.2h0.1c0.1,0,0,0-0.1,0
        c0.1,0,0.1,0,0.2,0c0,0,0.2,0,0.1,0c-0.1,0,0.1,0,0.1,0c0.3,0.1,0.7-0.1,0.8-0.5C432.4,223.1,432.2,222.6,431.8,222.6L431.8,222.6z
        "
        />
        <path
            fill="#42515A"
            d="M366.4,252.5l0.2,3.7c0,0.4,0.3,0.7,0.7,0.7c0.3,0,0.6-0.2,0.7-0.7l-0.2-3.7c0-0.4-0.2-0.7-0.7-0.7
        S366.4,252.1,366.4,252.5L366.4,252.5z"
        />
        <path
            fill="#42515A"
            d="M331.3,128.8c-0.2,0.4-0.2,0.9-0.3,1.4c-0.1,0.5-0.1,1-0.1,1.5c-0.1,1.2-0.2,2.2-0.2,3.3
        c-0.1,2-0.1,4-0.1,6.1c0,4.1,0,8.2-0.1,12.4c-0.1,8.2-0.1,16.5-0.1,24.7v49.5c0,9.3-0.2,18.6,0.2,27.8c0,0.6,0.5,1.1,1.1,1.1
        c0.6,0,1.1-0.5,1.1-1.1c0.4-8.2,0.2-16.5,0.2-24.7V206c0-16.5,0.2-33,0-49.5c-0.1-4.6-0.1-9.3-0.1-13.9c0-2.2,0-4.5-0.1-6.8
        c0-2.2,0-4.7-0.7-6.9c-0.1-0.2-0.2-0.3-0.5-0.2C331.4,128.6,331.3,128.8,331.3,128.8L331.3,128.8z"
        />
        <path fill="#42515A" d="M367.6,85.2h154.6c1.7,0,1.7-2.7,0-2.7H367.6C365.8,82.5,365.8,85.2,367.6,85.2z" />
        <path fill="#42515A" d="M323.1,125h191.5c0.9,0,0.9-1.4,0-1.4H323.1C322.2,123.6,322.2,125,323.1,125z" />
        <path
            fill="#42515A"
            d="M370.2,178.4c-0.4,2.4-0.3,4.9-0.3,7.3v7.3c0,2.5,0.2,4.9,0.2,7.3s0,4.9,0,7.3c-0.1,4.9-0.2,9.8-0.2,14.6
        c-0.1,4.9,0,9.8-0.1,14.6v8.2c0,1.3,0,2.7,0,4c0,1.4,0,2.8,0.2,4.2c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0.1-1.2,0.2-2.5,0.2-3.8
        c0-1.2,0-2.4,0-3.6c0-2.5,0-4.9,0-7.3c0-4.9,0-9.8,0-14.6c-0.1-4.9-0.2-9.8-0.2-14.6c0-2.5-0.1-4.9-0.1-7.3s0.1-4.9,0.1-7.3
        c0.1-2.7,0-5.5,0-8.2c0-2.8,0.1-5.5-0.3-8.2c-0.1-0.2-0.4-0.5-0.7-0.3C370.5,178,370.3,178.2,370.2,178.4L370.2,178.4z"
        />
        <path
            fill="#42515A"
            d="M366,256.6c-1.2,0-2.2-0.2-3.4-0.2l-3.4-0.1c-2.2,0-4.5,0-6.8,0c-4.5,0-9-0.1-13.6-0.1l-7.7-0.1l1.1,1.4
        c0-0.2,0.1-0.4,0.2-0.6c0.2-0.5,0.1-1.1-0.4-1.3c-0.2-0.1-0.5-0.2-0.7-0.1c-0.7,0.2-1.2,0.7-1.3,1.5c-0.2,0.7,0.2,1.4,1.1,1.4
        c4.5,0.1,9,0.2,13.6,0.2c2.2,0,4.5,0.1,6.8,0.1c2.3,0,4.5,0.2,6.8,0.2c1.2,0,2.5,0,3.8,0c1.3,0,2.6-0.1,3.9-0.1
        C367.5,259,367.5,256.6,366,256.6L366,256.6z"
        />
        <path
            fill="#42515A"
            d="M322.7,129.1c1.6,0.1,3.1,0.3,4.7,0.4c1.6,0.1,3.3,0.1,4.9,0.1c3.1,0,6.3,0,9.5,0h19.4h39.5h39.9
        c13.2,0,26.4,0.2,39.5,0.2c12.9,0,25.7,0.1,38.6,0.1h4.7c1.8,0,1.8-2.8,0-2.8c-12.8,0-25.7,0.1-38.4,0.1c-13.2,0-26.3,0.2-39.5,0.2
        h-39.9H366h-19.5c-6.4,0-12.7-0.2-19.2,0.2c-1.6,0.1-3.1,0.3-4.7,0.4c-0.2,0-0.3,0.2-0.3,0.4C322.3,128.9,322.5,129.1,322.7,129.1
        L322.7,129.1z"
        />
        <path
            fill="#42515A"
            d="M464.8,155.6c0.2,0.9,0.4,1.8,0.5,2.7c0.1,1,0,2,0,3c0,2.1,0.1,4.2,0.1,6.3c0.1,4.2,0.2,8.5,0.2,12.7
        l0.1,3.6c0,1.2-0.1,2.5,0.2,3.7c0.2,0.7,1.3,0.7,1.4,0c0.2-1.1,0.1-2.1,0.1-3.2l-0.1-3.3c-0.1-2.1-0.1-4.3-0.2-6.5
        c-0.1-4.4-0.2-8.6-0.3-13c0-1.2-0.1-2.2-0.2-3.4c-0.1-1.2-0.2-2.2-0.8-3.2c-0.2-0.2-0.6-0.3-0.8-0.2
        C464.8,155.2,464.7,155.5,464.8,155.6L464.8,155.6z"
        />
        <path
            fill="#42515A"
            d="M465.9,155.9c0.6,0.2,1.2,0.3,1.9,0.3s1.3,0,2,0c1.2,0,2.5,0,3.8,0h7.6c5.1,0,10.2,0,15.3,0
        c1.4,0,2.9,0,4.3,0s2.9-0.2,4.4-0.4c0.2,0,0.2-0.2,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-1.3-0.2-2.6-0.3-3.9-0.4
        c-1.2-0.1-2.5-0.1-3.8-0.1h-7.6c-5.1,0-10.2,0-15.3,0c-1.4,0-2.9,0-4.3,0c-0.7,0-1.4,0-2.1,0c-0.7,0-1.4,0.2-2.1,0.4
        c-0.2,0-0.2,0.2-0.2,0.3C465.8,155.7,465.8,155.8,465.9,155.9L465.9,155.9z"
        />
        <path
            fill="#42515A"
            d="M504.7,156.5c-0.2,4.4-0.2,9-0.1,13.4l0.2,13.4c0,1.2,0,2.5,0.1,3.7v1.9c0,0.7,0,1.3,0.2,2
        c0.1,0.3,0.4,0.5,0.7,0.4c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.6,0.2-1.1,0.1-1.6v-1.8c0-1.2,0-2.2,0-3.3l-0.1-6.7
        c-0.1-4.5-0.1-9-0.2-13.4c-0.1-2.5-0.2-5-0.3-7.6c0-0.2-0.2-0.4-0.4-0.4S504.7,156.3,504.7,156.5L504.7,156.5z"
        />
        <path
            fill="#42515A"
            d="M466.2,189.3c1.2,0.2,2.5,0.3,3.9,0.3c1.2,0,2.5,0.1,3.7,0.1c2.5,0.1,5.1,0.2,7.6,0.2
        c5.1,0.1,10.2,0.2,15.3,0.2c2.9,0,5.8,0.2,8.6,0.1c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8c-2.5-0.2-5.1-0.1-7.6-0.1
        c-2.5,0-5.1-0.1-7.6-0.1c-5.1-0.1-10.2-0.2-15.3-0.2c-1.4,0-2.9,0-4.3,0c-1.4,0-2.8,0-4.2,0.2c-0.2,0-0.3,0.2-0.3,0.3
        C465.8,189,465.9,189.3,466.2,189.3L466.2,189.3z"
        />
        <path
            fill="#42515A"
            d="M466.2,189.5c0,0,0.2-0.2,0.2-0.2c0.2,0,0.5-0.2,0.5-0.5c0-0.2-0.1-0.3-0.2-0.4c-0.4-0.2-0.8-0.2-1.2-0.3
        c-0.3-0.1-0.7-0.2-1.2-0.2c-0.5-0.1-0.9,0-1.4,0.2c-0.4,0.2-0.7,0.7-0.7,1.2c-0.2,0.8-0.2,1.6-0.2,2.5c0,0.4,0,0.8,0,1.2
        c0,0.2,0,0.4,0,0.6c0,0.2,0.2,0.6,0.2,0.8c0.3,0.7,1.3,0.4,1.5-0.2c0.2-0.6,0.1-1.4,0.1-2.1c0-0.3,0-0.7,0.1-1.1
        c0-0.2,0-0.3,0.1-0.5v-0.1c0,0.1,0,0.1,0,0s0-0.2,0-0.2c0-0.2,0-0.2,0.1-0.4c0,0,0-0.1,0-0.2c0.1-0.2-0.1,0.2,0,0
        c0,0.1-0.1,0.1,0,0c-0.1,0-0.2,0.1-0.1,0.1h0.2c-0.2,0,0.1,0,0.1,0c0.3,0,0.7-0.1,1.2-0.2c0.3,0,0.7-0.1,1.1-0.2l-0.5-0.1h0.1v-0.3
        l-0.2,0.2C465.8,189.3,466,189.7,466.2,189.5L466.2,189.5z"
        />
        <path
            fill="#42515A"
            d="M462.7,195.1c1.5,0.2,3,0.2,4.5,0.2c1.5,0,3,0,4.5,0c3,0,6,0,9-0.1l17.9-0.2c3.4,0,6.7,0,10-0.2
        c0.5,0,0.8-0.3,0.8-0.8c0.2-1.6,0.1-3.3-0.3-4.9c-0.1-0.2-0.2-0.4-0.5-0.5c-1.1-0.2-2.1-0.2-3.2-0.2c-0.3,0-0.7,0.3-0.6,0.7
        c0.1,0.4,0.3,0.7,0.7,0.6h0.1c0.9-0.2,1.8-0.1,2.6,0.2l-0.5-0.7c0.2,1.5,0.2,3,0,4.5l0.8-0.6c-3,0-6,0.1-9,0.1c-3,0-6,0.1-9,0.2
        c-6,0.1-12,0.2-18,0.3c-1.7,0-3.4,0.1-5,0.2c-1.7,0-3.4,0.2-5,0.3C462.1,194.4,462.3,195.1,462.7,195.1L462.7,195.1z"
        />
        <path
            fill="#42515A"
            d="M462.7,151.1h-0.2c-0.1,0-0.2,0-0.2,0l-0.1,0.1c0,0,0,0.1,0.1,0.1c0.1,0,0.2,0,0.2,0h0.2
        C462.8,151.3,462.8,151.3,462.7,151.1L462.7,151.1C462.8,151.1,462.8,151.1,462.7,151.1L462.7,151.1z"
        />
        <path
            fill="#42515A"
            d="M463.5,188.4c0-0.2,0.2-0.6,0.2-0.8c0-0.2,0.1-0.6,0.1-0.9c0-0.6,0-1.2,0-1.8c0-1.2,0-2.4,0-3.6V174
        c0-4.8,0-9.6,0-14.4c0-2.7,0.1-5.3-0.1-8.1c0-0.3-0.2-0.6-0.6-0.6c-0.3,0-0.6,0.2-0.6,0.6c-0.2,2.4-0.1,4.8-0.1,7.2v7.2
        c0,4.8,0,9.6,0,14.4c0,1.3,0,2.6,0,3.9c0,0.7,0,1.3,0,2c0,0.3,0,0.7,0.1,1.1c0,0.3,0.2,0.7,0.2,1c0,0.2,0.2,0.3,0.3,0.3
        C463.4,188.6,463.5,188.5,463.5,188.4L463.5,188.4z"
        />
        <path
            fill="#42515A"
            d="M462.8,151.9c0.7,0.2,1.4,0.2,2.1,0.2h2.3c1.5,0,3,0,4.5,0c3,0,6.1,0,9.1,0c6.1,0,12.1,0,18.2-0.1
        c1.7,0,3.4,0,5.1,0h2.6c0.8,0,1.7,0.1,2.5-0.2c0.2-0.1,0.3-0.3,0.2-0.6c0-0.2-0.2-0.2-0.2-0.2c-0.7-0.2-1.4-0.2-2.1-0.2h-2.3
        c-1.5,0-3,0-4.5,0c-3,0-6.1,0-9.1-0.1c-6.1,0-12.2,0-18.2,0c-1.7,0-3.4,0-5.1,0h-2.5c-0.8,0-1.7,0-2.5,0.2c-0.2,0-0.2,0.2-0.2,0.4
        C462.6,151.7,462.7,151.9,462.8,151.9L462.8,151.9z"
        />
        <path
            fill="#42515A"
            d="M507.9,151.6c-0.2,0.6-0.2,1.2-0.2,1.7s0,1.2,0,1.8c0,1.2-0.1,2.4-0.1,3.6c0,2.4,0,4.8,0,7.2
        c0,4.8,0.1,9.6,0.1,14.4c0,1.3,0,2.6,0,3.9c0,0.7,0,1.3,0,2c0,0.7,0.1,1.4,0.2,2.1c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.2,0.3-0.3
        c0.1-0.6,0.2-1.2,0.2-1.8c0-0.6,0-1.2,0-1.8c0-1.2,0-2.4,0-3.6c0-2.4,0-4.8,0-7.2c0-4.8,0.1-9.6,0.1-14.4c0-1.3,0-2.6,0-3.9
        c0-0.7,0-1.3,0-2s0-1.4-0.2-2.1c-0.1-0.2-0.2-0.2-0.5-0.2C508.1,151.4,508,151.5,507.9,151.6L507.9,151.6z"
        />
        <path
            fill="#42515A"
            d="M483.8,157c-0.1,3.9,0.1,8,0.2,11.9c0.1,3.9,0.2,7.9,0.3,11.8l0.2,6.7c0,1.2,1.9,1.2,1.8,0
        c-0.2-7.9-0.6-15.8-1-23.8c-0.2-2.2-0.2-4.5-0.5-6.7c0-0.2-0.3-0.5-0.6-0.5C483.9,156.5,483.8,156.7,483.8,157L483.8,157z"
        />
        <path
            fill="#42515A"
            d="M466.4,174.8c2.5,0.1,4.9-0.1,7.2-0.2c2.4-0.1,4.8-0.2,7.1-0.2c4.8-0.2,9.6-0.2,14.4,0
        c2.7,0.1,5.4,0.2,8.2,0.3c1.2,0.1,1.2-1.8,0-1.9c-4.9-0.2-9.7-0.4-14.6-0.4c-4.8,0-9.6,0.1-14.4,0.5c-2.7,0.2-5.4,0.4-8.1,1
        C465.8,174,465.8,174.8,466.4,174.8L466.4,174.8z"
        />
        <path
            fill="#42515A"
            d="M522.2,257.8c-2.5-0.4-5-0.7-7.5-0.7c-2.5,0-5-0.1-7.6-0.1c-5.1,0-10.1,0-15.1,0c-10,0-20.1,0-30.1,0
        c-5.7,0-11.3,0-17,0.2c-0.5,0-0.8,0.5-0.8,0.9s0.4,0.8,0.8,0.8c5.1,0.2,10.1,0.2,15.1,0.2c5.1,0,10.1,0,15.1,0c10,0,20.1,0,30.1,0
        c2.8,0,5.6,0,8.5-0.1c2.8,0,5.7-0.1,8.5-0.7c0.2,0,0.2-0.2,0.2-0.3C522.4,257.8,522.3,257.8,522.2,257.8L522.2,257.8z"
        />
        <path
            fill="#458EAD"
            d="M133.7,110.5c0.6,0.1,1.2,0.2,1.8,0.2c0.7,0,1.3,0.1,2,0.1c1.2,0,2.5,0,3.7,0l7.4,0.1
        c4.9,0,9.8,0.1,14.7,0.1h29.6h8.2c2.8,0,5.7-0.2,8.5-0.2c0.6,0,1-0.6,1-1.2c0-0.6-0.5-0.9-1-1c-2.5-0.1-4.9-0.2-7.4-0.2h-7.4H180
        c-9.9,0-19.7,0-29.6,0.1l-8.2,0H138c-0.7,0-1.4,0-2.1,0.1c-0.7,0-1.4,0.2-2.1,0.2c-0.4,0-0.7,0.3-0.7,0.8
        C132.9,110.2,133.3,110.5,133.7,110.5L133.7,110.5z"
        />
        <path
            fill="#C3E3F2"
            d="M134.2,108.8c4-0.3,7.6-2.5,9.9-5.9c0.6-0.8,1.1-1.7,1.9-2.3c1.7-1.1,4.2-0.1,5.8-1.4
        c1.4-1.2,1.1-3.4,1.7-5.1c1.2-3.1,5.4-3.6,7.9-5.8c1.8-1.6,3-4.3,5.4-4.4c1.1-0.1,2.1,0.4,2.9,1.1c5.3,4.3,3,13.6,7.8,18.4
        c0.9-2.4,4.8-2.8,6.2-0.6c0.8-2.9,2.3-6.3,5.3-6.5c2.5-0.2,4.4,2.2,5.6,4.6c1.1,2.3,2,4.9,4.3,6.2c3.3,1.8,8.2-0.1,10.5,2.9"
        />
        <path
            fill="#458EAD"
            d="M134.2,108.9c2.5,0.3,4.9-0.6,6.8-2c1.6-1.1,2.8-2.4,3.9-3.9c0.3-0.6,0.7-1.1,1.2-1.5
        c0.8-0.7,2.1-0.7,3.1-0.7c1.2-0.1,2.4-0.2,3.3-1.2c1.6-1.6,0.9-4.2,2.2-6c1.2-1.6,3.3-2.3,4.9-3.2c0.9-0.5,1.8-1.1,2.5-1.7
        c0.8-0.7,1.5-1.7,2.2-2.5c0.7-0.7,1.4-1.4,2.4-1.6c1.2-0.2,2.3,0.6,3.1,1.4c1.4,1.4,2.1,3.2,2.5,5c1.1,3.9,1.2,8.3,3.6,11.7
        c0.3,0.4,0.7,0.8,1,1.2c0.2,0.2,0.7,0.2,1,0c0.1-0.1,0.1-0.2,0.2-0.2c0.7-1.8,3.9-2.1,4.9-0.4c0.2,0.3,0.7,0.4,1,0.2
        c0.2-0.1,0.2-0.2,0.2-0.3c0.6-1.9,1.4-4.3,3.1-5.4c0.8-0.6,1.9-0.7,2.8-0.3c1.1,0.3,2,1.3,2.5,2.2c1.4,2,2,4.4,3.5,6.4
        c0.7,1,1.7,1.7,2.8,2.2c1.1,0.4,2.1,0.6,3.2,0.7c2.1,0.2,4.9-0.1,6.4,1.8c0.2,0.2,0.7,0.2,1,0c0.2-0.2,0.2-0.7,0-1
        c-1.5-1.9-4-2-6.2-2.1c-1.2,0-2.3-0.2-3.4-0.5c-1.1-0.3-2.1-1-2.7-2c-1.6-2.1-2.1-4.8-3.7-6.7c-0.7-1.1-1.8-1.9-3-2.4
        c-1.2-0.4-2.4-0.2-3.5,0.2c-2.4,1.2-3.5,4.1-4.2,6.5l1.3-0.2c-1.7-2.7-6.2-2.2-7.5,0.7l1.2-0.3c-3-3-3.1-7.6-4-11.4
        c-0.5-2-1.2-3.9-2.3-5.5c-1.4-1.9-3.9-3.5-6.2-2.5c-2.1,0.9-3.1,3.1-4.8,4.5c-1.6,1.3-3.7,1.9-5.4,3c-0.9,0.6-1.6,1.3-2.1,2.2
        c-0.6,1-0.7,2.1-0.8,3.3c-0.2,1-0.4,2.1-1.4,2.5c-1,0.4-2.2,0.2-3.3,0.4c-1.3,0.2-2.5,0.8-3.2,1.9c-0.7,1-1.3,2.1-2.2,3
        c-0.8,0.9-1.8,1.6-2.9,2.2c-1.1,0.6-2.2,1.1-3.4,1.5c-0.6,0.2-1.2,0.4-1.7,0.6c-0.1,0-0.1,0.1-0.1,0.2
        C134,108.9,134.1,108.9,134.2,108.9L134.2,108.9z"
        />
        <path fill="#C3E3F2" d="M131.9,129.1H79.6" />
        <path
            fill="#458EAD"
            d="M132,129c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.2-0.3-0.3-0.6-0.3c-6.7,0-13.5,0-20.2,0H91.1c-3.9,0-7.6,0-11.5,0.2
        c-0.2,0-0.5,0.2-0.4,0.5c0,0.2,0.2,0.4,0.4,0.4c6.7,0.3,13.6,0.2,20.3,0.2c6.7,0,13.4,0,20.1,0h11.4c0.2,0,0.5-0.1,0.6-0.3
        C132,129.2,132.1,129.1,132,129L132,129z"
        />
        <path
            fill="#C3E3F2"
            d="M131.6,128.5c-0.3,0.2-1.1-1.1-2.1-1.6c-1.7-0.8-3.8-0.6-5.7-0.7c-2.4-0.2-6.2-0.4-8.2-1.4
        c-1.3-0.7-1.1-1.2-1.9-2.3c-1.2-1.5-3-1.6-4.3-3c-1.8-1.9-1.7-6.4-6-4c-0.9-1.6-0.8-4.7-2.9-5.6c-2.4-1.1-4.2,2-4.6,4.2
        c-0.3,2,1.2,3.3,1.1,4.9c-0.1,1.2-1.5,2.2-2.2,3.1c-0.9,1.2-1,2.1-2.5,2.5c-1.2,0.3-2.7-0.3-3.9-0.1c-2.5,0.6-6.1,1.2-7.5,3.5"
        />
        <path
            fill="#458EAD"
            d="M131.5,128.3C131.6,128.2,131.5,128.3,131.5,128.3c0-0.2-0.1-0.2-0.2-0.2c-0.2-0.2-0.3-0.4-0.5-0.6
        c-0.4-0.5-1-0.9-1.6-1.2c-1.5-0.6-3-0.6-4.6-0.6c-1.3,0-2.6-0.2-3.9-0.3c-1.3-0.1-2.5-0.3-3.9-0.7c-0.6-0.2-1-0.3-1.5-0.7
        c-0.2-0.3-0.5-0.7-0.7-1.1c-0.7-1.2-1.7-2-3-2.5c-0.9-0.5-1.8-0.9-2.4-1.7c-0.4-0.7-0.7-1.5-1.2-2.2c-0.4-0.8-1-1.6-1.9-1.9
        c-1.2-0.4-2.3,0.1-3.3,0.7l0.8,0.2c-0.6-1-0.6-2.2-1-3.2c-0.4-1.2-1.2-2.2-2.3-2.7c-2.8-1.1-4.8,2-5.3,4.3
        c-0.2,0.7-0.2,1.5-0.1,2.2c0.2,0.7,0.4,1.2,0.7,1.9c0.2,0.5,0.4,1.2,0.2,1.6c-0.2,0.6-0.8,1.2-1.2,1.6c-0.5,0.5-0.9,1.1-1.3,1.7
        c-0.2,0.4-0.5,0.9-0.9,1.2c-0.6,0.2-1.2,0.3-1.9,0.2c-0.7-0.2-1.6-0.2-2.3-0.2c-1.6,0.2-3,0.6-4.5,1.2c-1.5,0.6-2.9,1.4-3.8,2.8
        c-0.6,0.8,0.8,1.6,1.4,0.8c1.4-2.1,4.4-2.5,6.7-3c1.4-0.2,2.8,0.3,4.2,0c0.7-0.2,1.2-0.5,1.7-0.9c0.3-0.4,0.7-0.9,1-1.4
        c0.7-1.2,1.9-2,2.5-3.2c0.6-1.2-0.2-2.5-0.6-3.7c-0.2-0.6-0.3-1.2-0.2-1.8c0.1-0.6,0.2-1.2,0.5-1.6c0.4-0.9,1.2-2.1,2.2-2.2
        c1.2-0.2,2,0.8,2.4,1.8c0.4,1.2,0.7,2.4,1.2,3.5c0.2,0.2,0.6,0.4,0.8,0.2c0.5-0.2,1-0.5,1.6-0.6c0.2,0,0.6,0,0.8,0.1
        c0.6,0.2,0.9,1,1.2,1.6c0.3,0.7,0.7,1.5,1.1,2.2c0.7,1,1.6,1.6,2.6,2.1c0.6,0.2,1.1,0.6,1.6,0.9c0.4,0.4,0.7,0.8,1,1.4
        c0.6,1.2,1.5,1.6,2.6,2.1c2.5,0.8,5.3,0.9,7.8,1.1c1.6,0.1,3.5-0.2,5.1,0.5c0.6,0.2,1,0.7,1.5,1.2c0.2,0.2,0.8,0.5,1.1,0.2
        C132,128.5,131.8,128.1,131.5,128.3L131.5,128.3z"
        />
        <path
            fill="#42515A"
            d="M370.6,255.1c0.6,0.2,1.1,0.2,1.6,0.3c0.7,0,1.3,0,2,0.1c1.2,0,2.4,0,3.6,0c2.5,0,4.9,0,7.4,0h14.6
        c1.4,0,2.8,0,4.1,0h2.1c0.7,0,1.4-0.1,2.1-0.2c0.2,0,0.2-0.2,0.2-0.4c0-0.1-0.2-0.2-0.2-0.2c-0.6-0.1-1.2-0.2-1.7-0.2h-2
        c-1.2,0-2.4,0-3.6,0h-7.4c-4.9,0-9.8,0-14.6,0c-1.4,0-2.8,0-4.1,0c-0.7,0-1.4,0-2.1,0.1c-0.3,0-0.7,0-1.1,0.1
        c-0.3,0-0.7,0.2-0.9,0.2C370.5,255,370.4,255.1,370.6,255.1C370.5,255.1,370.6,255.3,370.6,255.1L370.6,255.1z"
        />
        <path
            fill="#42515A"
            d="M370.9,253.6c-1.6,1.2-2.9,2.7-4.6,3.5c-0.3,0.2-0.4,0.6-0.2,0.9c0.2,0.3,0.6,0.4,0.9,0.2
        c0.9-0.5,1.7-1.1,2.5-1.7c0.3-0.3,0.7-0.7,1.1-0.9c0.3-0.3,0.7-0.6,1.1-0.8c0.3-0.2,0.4-0.6,0.2-0.9
        C371.6,253.6,371.2,253.5,370.9,253.6L370.9,253.6z"
        />
        <path
            fill="#FFBF00"
            stroke="#FFC000"
            strokeWidth="0.1436"
            strokeMiterlimit="10"
            d="M277,169.6L277,169.6
        c-0.1-4.1-0.2-5.7-0.2-9.8c0-2.1-0.1-4.1-0.2-6.2c0-1.7,0-1.3-0.7-2.9c-1.1-2.4-4.1-3.5-5.8-1.2c-1,1.3-1,0.8-1.1,2.4
        c-0.1,2-0.1,4-0.1,6.1s0.1,1.4,0.2,3.5c0,2.7-0.1,5.3-0.1,8v0.1c-2.9,1.3-4.9,4-5.4,7.2c-0.6,3.5,1.1,6.7,3.9,8.8
        c2.8,2.1,6.6,2.5,9.7,0.8c3-1.6,5.3-4.8,5.3-8.3C282.4,174.7,280.3,171.3,277,169.6z M277.6,185.1c-2.5,1.7-5.8,1.8-8.5,0.3
        c-2.6-1.5-4.5-4.2-4.4-7.3c0.1-3.5,2.1-6.7,5.3-8.1c0.2-0.2,0.1-0.7-0.2-0.6l0,0h0.1c0.1,0,0.2-0.1,0.2-0.2c0.1,0,0.2,0,0.2-0.1
        V169c0.1-0.3,0.1-0.7,0.1-1.2c0-0.7-0.1-1.4-0.2-2c0-0.1-0.1-0.1-0.1,0c0-0.7-0.1-1.3-0.2-2c0-1.9,0-3.9,0-5.8c0-3.5,0.2-2.1,0-5.7
        c0.2-1.5,0.7-3.1,2.3-3.5c1.8-0.4,3.1,1.5,3.5,3c0.4,1.7,0.2,1.3,0.3,3c0,1.7,0.1,0.8,0.1,2.5c0.1,3.9,0.2,7.9,0.2,11.8
        c-0.5,0-0.6,0.8-0.1,1c2.9,1.2,4.8,3.9,5.2,7.1C281.6,180.5,280.1,183.4,277.6,185.1L277.6,185.1z"
        />
        <path
            fill="#FFC000"
            d="M276.9,175.8c-0.6-1.3-1.6-2.2-3-2.7c0.4-0.6,0.2-1.4,0.2-2.1c-0.1-1-0.1-2-0.2-3c-0.1-2-0.2-3.9-0.2-5.9
        c-0.1-2-0.2-1.3-0.2-3.3c0-1.8,0.1-3.9-0.6-5.7c0-0.1-0.2-0.2-0.3,0c-0.7,1.6-0.7,3.7-0.7,5.5c0,2.1,0,1.5,0,3.5
        c0,2,0.1,3.9,0.2,5.9c0,1.1,0.1,2.1,0.2,3.1c0,0.7,0,1.3,0.2,1.8c-0.8,0.2-1.6,0.7-2.1,1.4c-0.2,0.2-0.6,0.4-0.8,0.7
        c-0.7,0.7-1.2,1.7-1.1,2.8c0,1.2,0.3,2.2,1,3c1,1.3,2.5,2,4.2,1.6h0.1h0.1c1.3-0.3,2.4-1.2,3-2.3c0.6-1.1,0.7-2.3,0.2-3.4
        C277.1,176.5,277,176.2,276.9,175.8z"
        />
        <path
            fill="none"
            stroke="#FFC000"
            strokeWidth="2.2999"
            strokeMiterlimit="10"
            d="M277,169.6L277,169.6
        c-0.1-4.1-0.2-5.7-0.2-9.8c0-2.1-0.1-4.1-0.2-6.2c0-1.7,0-1.3-0.7-2.9c-1.1-2.4-4.1-3.5-5.8-1.2c-1,1.3-1,0.8-1.1,2.4
        c-0.1,2-0.1,4-0.1,6.1s0.1,1.4,0.2,3.5c0,2.7-0.1,5.3-0.1,8v0.1c-2.9,1.3-4.9,4-5.4,7.2c-0.6,3.5,1.1,6.7,3.9,8.8
        c2.8,2.1,6.6,2.5,9.7,0.8c3-1.6,5.3-4.8,5.3-8.3C282.4,174.7,280.3,171.3,277,169.6z"
        />
        <path
            fill="#FFBF00"
            d="M302.9,164.7c-0.2,0.5-0.5,1-0.8,1.5c-0.2,0.4-0.6,0.8-0.7,1.3c-0.4,1.1-0.8,2.1-1.2,3.1
        c-0.4,1.1-0.9,2-1.4,2.9c-0.1,0.1-0.1,0.2-0.2,0.3c0.2-6.2-0.1-12.6-0.1-18.8c0-0.5,0-1,0-1.5c0-0.1,0-0.2,0-0.2
        c0.2-0.2,0.2-0.7,0-0.9c-0.2-0.4-0.7-0.6-1.2-0.4c0-0.1,0-0.2,0-0.2c0-0.2-0.3-0.2-0.4,0c0,0.2,0,0.3,0,0.5c0,0-0.1,0.1-0.1,0.2
        c-0.2,0.3-0.2,0.7-0.2,1.1c-0.1,1.2,0,2.3,0,3.5c0,1.6,0,3.4,0.1,5c0,0.4,0,0.9,0,1.3c0,3.1,0,6.2,0,9.3c-0.2-0.3-0.5-0.7-0.7-1.1
        c-0.6-0.8-1.2-1.6-1.7-2.3c-0.6-0.7-1.2-1.6-1.9-2.2c-0.2-0.2-0.6-0.2-0.7,0c-0.1,0.2-0.2,0.3-0.2,0.5c0.2,0.9,0.7,1.7,1.1,2.6
        c0.4,0.9,0.8,1.9,1.2,2.8c0.9,1.7,2,3.5,3.1,5.1c0.5,0.7,1.6,0.8,2.1,0c1.2-2,2.3-3.9,3.2-6c0.5-1.1,0.9-2.2,1.2-3.3
        c0.3-1.2,0.2-2.2,0.2-3.4c0-0.2-0.1-0.4-0.2-0.4C303.1,164.5,303,164.5,302.9,164.7z M297.6,153.7L297.6,153.7L297.6,153.7
        L297.6,153.7z"
        />
        <path
            fill="#FFBF00"
            d="M235,165.1c0.2-0.5,0.5-1,0.8-1.5c0.2-0.4,0.6-0.8,0.7-1.3c0.4-1.1,0.8-2.1,1.3-3.1c0.5-1,0.9-2,1.4-2.9
        c0.1-0.1,0.1-0.2,0.2-0.3c-0.2,6.2,0.1,12.6,0.1,18.8c0,0.5,0,1,0,1.5c0,0.1,0,0.2,0,0.2c-0.2,0.2-0.2,0.7,0,0.9
        c0.2,0.4,0.7,0.6,1.2,0.4c0,0.1,0,0.2,0,0.2c0,0.2,0.3,0.2,0.4,0c0.1-0.2,0-0.3,0-0.5c0,0,0.1-0.1,0.1-0.2c0.2-0.3,0.2-0.7,0.2-1.1
        c0.1-1.2,0-2.3,0-3.5c0-1.6,0-3.4-0.1-5c0-0.4,0-0.9,0-1.3c0-3.1,0-6.2,0-9.3c0.2,0.3,0.5,0.7,0.7,1.1c0.6,0.8,1.2,1.6,1.7,2.3
        c0.6,0.7,1.2,1.6,1.9,2.2c0.2,0.2,0.6,0.2,0.7,0c0.1-0.2,0.2-0.3,0.2-0.5c-0.2-0.9-0.7-1.7-1.1-2.6c-0.4-0.9-0.8-1.9-1.2-2.8
        c-0.9-1.7-2-3.5-3.1-5.1c-0.5-0.7-1.6-0.8-2.1,0c-1.2,2-2.3,3.9-3.2,6c-0.5,1.1-0.9,2.2-1.2,3.3c-0.3,1.2-0.2,2.2-0.2,3.4
        c0,0.2,0.2,0.3,0.3,0.3C234.8,165.3,234.9,165.1,235,165.1z M240.2,176.2L240.2,176.2L240.2,176.2L240.2,176.2z"
        />
        <path
            fill="#458EAD"
            d="M287,273.5c0.6-1.6,1.2-3.2,2-4.8c0.7-1.4,1.5-2.8,2.3-4.2c0.8-1.4,1.7-2.6,2.6-3.9l0.3-0.4
        c0.2-0.2,0,0,0,0l0.2-0.2c0.2-0.2,0.5-0.6,0.7-0.9c0.5-0.6,0.9-1.2,1.4-1.6c1-1.1,2.1-2.1,3.1-3c0.6-0.5,1.1-0.9,1.6-1.4
        c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.6-0.4,0.2-0.1c0.2-0.2,0.6-0.4,0.8-0.7c2.2-1.6,4.6-3.1,7.2-4.3c0.6-0.2,1.2-0.6,1.9-0.8
        l0.9-0.4c0.2-0.1-0.4,0.2-0.1,0l0.2-0.1l0.6-0.2c1.3-0.6,2.6-1,3.9-1.4c2.7-0.9,5.4-1.6,8.2-2.2c2.8-0.7,5.7-1.2,8.5-1.6
        c1.4-0.2,2.8-0.4,4.2-0.6l0.5-0.1h-0.1h0.2l1.2-0.2c0.7-0.1,1.4-0.2,2.1-0.2c5.7-0.7,11.3-1.3,16.9-2.1c4.9-0.7,9.9-1.6,14.7-3.3
        c4.4-1.5,8.7-3.6,12.2-6.8c3.5-3.3,6-7.5,7.2-12.2c1.6-6,2-13.6-3.1-18c-2-1.6-4.5-2.8-7.1-2.5c-2.8,0.2-5,2.2-5.8,4.9
        c-0.4,1.6-0.2,3.1,0,4.7c0.2,1.1,1.6,1.7,2.5,1.4c1.2-0.3,1.6-1.4,1.4-2.5c0.1,0.7,0,0.1,0-0.1s0-0.3,0-0.5c0-0.3,0-0.7,0-1.1
        c0-0.2,0.2-0.7,0,0c0-0.2,0-0.2,0.1-0.4c0.1-0.2,0.2-0.6,0.2-0.8s0.2-0.6-0.1,0.1c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.2-0.2
        c0.2-0.2,0.4-0.4-0.1,0c0.2-0.2,0.2-0.3,0.4-0.5c0.1-0.1,0.2-0.2,0.2-0.2c-0.5,0.5-0.2,0.2-0.1,0.1c0.2-0.2,0.3-0.2,0.6-0.2
        c-0.7,0.3-0.3,0.2-0.2,0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3-0.1c0.5-0.1-0.4,0-0.2,0c0.2,0,0.5,0,0.7,0h0.3
        c0.5,0-0.3-0.1-0.2,0c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.5,0.2,0.7,0.2c0.5,0.2-0.5-0.2,0,0l0.4,0.2c0.2,0.2,0.5,0.2,0.7,0.4
        c0.1,0.1,0.2,0.2,0.3,0.2c0.4,0.3-0.3-0.2,0,0c0.5,0.4,0.9,0.8,1.3,1.3c0,0.1,0.1,0.2,0.2,0.2c0,0-0.3-0.5-0.2-0.2
        c0.1,0.2,0.2,0.2,0.2,0.4c0.2,0.2,0.3,0.6,0.6,0.9c0.1,0.3,0.2,0.6,0.4,0.9c-0.2-0.3-0.2-0.3-0.1-0.1l0.1,0.2
        c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.3,0.2,0.7,0.2,1.1c0,0.2,0.1,0.3,0.1,0.6c0,0.1,0,0.2,0,0.2c0.1,0.5,0-0.4,0,0
        c0.1,0.9,0.1,1.7,0.1,2.6c0,0.4-0.1,0.9-0.1,1.3c0,0.2,0,0.3-0.1,0.5c0.1-0.7,0-0.1,0,0.2c-0.1,0.7-0.2,1.2-0.3,1.9
        c-0.2,1.2-0.6,2.4-1,3.5c-0.1,0.2-0.2,0.6-0.2,0.8l-0.2,0.4c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.3,0.2-0.5,0.1-0.2
        c-0.2,0.5-0.5,1.1-0.7,1.5c-0.5,1-1.1,1.9-1.7,2.8c-0.2,0.2-0.7,0.8-0.2,0.2c-0.2,0.2-0.3,0.4-0.6,0.7c-0.3,0.4-0.7,0.8-1.1,1.2
        c-0.7,0.7-1.5,1.4-2.3,2.1c-0.4,0.3,0.3-0.2,0.1,0l-0.2,0.2c-0.2,0.2-0.4,0.3-0.7,0.5c-0.4,0.3-0.9,0.6-1.3,0.8
        c-0.9,0.6-1.9,1.1-2.9,1.6c-0.5,0.2-1,0.5-1.5,0.7c-0.2,0.1,0.2-0.1,0.2-0.1l-0.2,0.1l-0.3,0.2c-0.2,0.1-0.6,0.2-0.8,0.3
        c-4.5,1.7-9.4,2.7-14.1,3.5c-1.3,0.2-2.6,0.4-3.9,0.6l-2,0.2l-0.5,0.1h0.2h-0.2l-1.1,0.2c-2.8,0.3-5.5,0.7-8.2,1.1
        c-5.7,0.7-11.4,1.5-17.1,2.7c-5.6,1.2-11,2.9-16.1,5.2c-5.1,2.3-9.9,5.3-13.8,9.3c-2.1,2.1-4,4.4-5.7,6.9c-0.8,1.3-1.6,2.6-2.3,3.9
        c-0.7,1.5-1.4,3-2,4.5s-1.2,3.1-1.6,4.8C285,274.1,286.7,274.7,287,273.5L287,273.5z"
        />
        <path
            fill="#458EAD"
            d="M286.9,273.5c0.7-2.5,1.4-5,2.4-7.4c0.2-0.6,0.4-1.2,0.7-1.7c0.1-0.2,0.1-0.2,0.2-0.4l0.1-0.2
        c-0.1,0.2,0,0,0,0c0.1-0.2,0.2-0.6,0.3-0.8c0.5-1.1,1-2.1,1.5-3c0.5-0.9,1.1-1.9,1.6-2.8c0.2-0.5,0.6-0.9,0.9-1.3
        c0.2-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.3-0.5,0.5-0.7c0,0-0.2,0.2-0.1,0.1l0.1-0.1l0.2-0.2c0.2-0.2,0.3-0.4,0.5-0.6
        c0.3-0.4,0.7-0.7,1-1.2c0.7-0.7,1.4-1.5,2.1-2.1c0.7-0.7,1.5-1.4,2.3-2l0.2-0.2l0.2-0.1c0,0-0.3,0.2-0.1,0.1l0.6-0.5
        c0.4-0.3,0.8-0.6,1.3-0.9c1.7-1.2,3.5-2.3,5.3-3.3c2-1.1,4-2,6.1-2.9l0.4-0.2c0.1,0,0.6-0.2,0.1,0c0.2-0.1,0.6-0.2,0.8-0.3
        c0.6-0.2,1.1-0.4,1.6-0.7c1.2-0.4,2.2-0.9,3.4-1.3c5-1.8,10.1-3.5,15.2-5.1c2.6-0.8,5.3-1.7,7.7-3c2.1-1.1,3.9-2.4,5.7-3.9
        c2.8-2.5,4.8-5.7,5.8-9.3c0.8-2.8,0.9-5.9,0.1-8.7c-0.7-2.3-2.1-4.5-4.4-5.3c-1.2-0.3-2.3-0.2-3.4,0.2c-1.3,0.6-2.4,1.6-3.1,2.8
        c-0.2,0.2-0.3,0.6-0.5,0.8c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1-0.1,0.2-0.1,0.2c0,0.1-0.4,0.8-0.2,0.3c-0.5,0.7-0.2,1.6,0.3,2.1
        c0.7,0.5,1.6,0.2,2.1-0.3c0-0.1,0.1-0.1,0.1-0.2c-0.2,0.5,0,0,0.1-0.1l0.1-0.1l0.2-0.2c0.2-0.2-0.1,0.1,0,0.1c0,0,0.1-0.2,0.2-0.2
        c0.2-0.2,0.4-0.5,0.7-0.7c0.2-0.2,0.4-0.4,0.7-0.6c0.1-0.1,0.4-0.3-0.2,0.1c0.2-0.1,0.3-0.2,0.5-0.3l0.3-0.2
        c0.2-0.2-0.5,0.3-0.2,0.2l0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.1,0.2-0.2c0.3-0.2-0.6,0.2-0.1,0c0.2-0.1,0.4-0.1,0.6-0.2
        c0.2-0.1-0.6,0-0.3,0h0.2c0.1,0,0.2,0,0.2,0c0.2,0,0.3,0.1-0.2,0h0.2c0.1,0,0.2,0,0.2,0.1h0.2c0.2,0.1-0.5-0.2-0.2-0.1
        c0.2,0.1,0.3,0.2,0.5,0.2l0.1,0.1c0.2,0.2-0.4-0.3-0.2-0.2c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0.2,0.3,0.2,0.4,0.5l0.1,0.1
        c0.1,0.2-0.3-0.5-0.2-0.2l0.2,0.2c0.2,0.2,0.2,0.4,0.3,0.7c0.1,0.1,0.1,0.2,0.2,0.3l0.1,0.2c0.1,0.2-0.2-0.6-0.1-0.2
        c0.2,0.5,0.3,1.1,0.5,1.6c0.1,0.2,0.1,0.6,0.2,0.8c0.1,0.3,0-0.4,0-0.2c0,0.1,0,0.2,0,0.2c0,0.2,0,0.3,0,0.5c0,0.7,0,1.3,0,2
        c0,0.2,0,0.3,0,0.5c0,0.2-0.2,0.9,0,0.2c-0.1,0.3-0.1,0.7-0.2,1.1c-0.2,0.7-0.3,1.4-0.6,2.1c-0.1,0.2-0.1,0.3-0.2,0.5
        c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.2,0.1-0.2,0.1-0.2s-0.1,0.2-0.1,0.2c-0.2,0.3-0.3,0.7-0.5,1.1c-0.4,0.7-0.8,1.5-1.3,2.1
        c-0.1,0.2-0.2,0.3-0.3,0.5c0.3-0.5,0.1-0.2,0,0l-0.2,0.2c-0.2,0.3-0.6,0.7-0.9,1.1c-0.7,0.7-1.4,1.4-2.2,2c-0.2,0.2,0.2-0.2,0,0
        l-0.3,0.2c-0.2,0.2-0.4,0.3-0.7,0.5c-0.5,0.3-0.9,0.6-1.4,0.9c-1.1,0.6-2.1,1.2-3.1,1.6c-0.2,0.1-0.2,0.2-0.4,0.2
        c0,0,0.4-0.2,0.1,0l-0.1-0.1c-0.2,0.2-0.6,0.2-0.9,0.3c-0.7,0.2-1.3,0.5-2,0.7c-5.1,1.6-10.1,3.3-15.1,5.1
        c-4.5,1.6-8.9,3.4-13.1,5.5c-3.8,1.9-7.3,4.2-10.5,7c-3.1,2.7-5.8,5.9-7.8,9.5c-2.3,4.1-3.9,8.5-4.8,13.2c-0.2,1.2-0.4,2.5-0.6,3.8
        c-0.1,0.3,0.1,0.7,0.5,0.8C286.4,274.1,286.8,273.9,286.9,273.5L286.9,273.5z"
        />
        <path
            fill="#458EAD"
            d="M294.5,271.8c3.5-3.2,7-6.5,10.9-9.2c3.6-2.5,7.6-4.8,11.6-6.6c3.9-1.7,8.1-3.1,12.4-4.2
        c4.4-1.1,8.7-1.7,13.2-2.1c4.7-0.4,9.4-0.6,14.1-0.6c2.5,0,4.9,0.1,7.4,0.2c1.2,0.1,2.5,0.2,3.8,0.3l0.5,0.1c0.1,0,0.1,0,0.2,0
        l0.9,0.2c0.6,0.1,1.2,0.2,1.9,0.3c2.5,0.5,4.8,1.2,7.1,2l0.4,0.2c0.5,0.2-0.2-0.2,0.2,0.1c0.2,0.1,0.6,0.2,0.8,0.3
        c0.6,0.2,1.1,0.6,1.6,0.8c1.1,0.6,2.1,1.2,3,2c0,0,0.4,0.3,0.2,0.1c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0.2,0.4,0.3,0.6,0.5
        c0.4,0.3,0.7,0.7,1.2,1.1c0.3,0.3,0.7,0.7,1.1,1.2c0,0.1,0.3,0.4,0.1,0.1l0.2,0.2c0.2,0.2,0.3,0.5,0.6,0.7c0.6,0.8,1.1,1.7,1.5,2.7
        l0.2,0.3c0,0.1,0.2,0.5,0,0.1c0.1,0.2,0.2,0.5,0.2,0.7c0.2,0.5,0.3,1,0.5,1.4c0.2,0.5,0.2,1,0.3,1.5c0.1,0.2,0.1,0.5,0.1,0.7
        c0,0,0-0.4,0-0.1c0,0.2,0,0.3,0,0.5c0,0.5,0.1,1,0,1.5c0,0.2,0,0.5,0,0.7c0,0.2,0,0.7-0.1,0.7v-0.2c0,0.1,0,0.2,0,0.2
        c0,0.1,0,0.2-0.1,0.3c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.5-0.2,0.9-0.4,1.4c0,0.1-0.1,0.2-0.2,0.3c0,0,0.2-0.4,0,0
        c-0.1,0.2-0.2,0.4-0.3,0.7c-0.2,0.4-0.5,0.8-0.7,1.2l-0.1,0.1c-0.1,0.2-0.1,0.2,0.1-0.1c-0.1,0.1-0.2,0.2-0.2,0.2
        c-0.2,0.2-0.3,0.3-0.5,0.6c-0.2,0.2-0.3,0.3-0.6,0.6c-0.1,0.1-0.2,0.2-0.2,0.2c-0.2,0.2,0.3-0.2,0,0c-0.4,0.2-0.8,0.4-1.2,0.7
        c0.4-0.2,0.2-0.1,0,0l-0.3,0.1c-0.2,0.1-0.5,0.2-0.7,0.2l-0.3,0.1c-0.4,0.1,0.6,0,0.1,0c-0.2,0-0.5,0-0.7,0s-0.5,0-0.7,0h-0.3
        c-0.5,0,0.6,0.1,0.1,0c-0.5-0.1-0.9-0.2-1.4-0.3l-0.3-0.1c-0.4-0.2,0.5,0.2,0.1,0c-0.2-0.1-0.4-0.2-0.7-0.3s-0.4-0.2-0.6-0.3
        c-0.1-0.1-0.2-0.2-0.2-0.2c-0.3-0.2,0.4,0.3,0.1,0.1c-0.2-0.2-0.3-0.3-0.6-0.5c-0.2-0.2-0.7-0.8-0.2-0.2c-0.3-0.5-0.8-0.8-1.4-1.1
        c-0.6-0.2-1.2-0.1-1.7,0.2c-0.9,0.6-1.6,2.1-0.8,3.1c1.4,2,3.6,3.4,6.1,3.8c2.5,0.3,4.9,0.1,7.1-1.2c2-1.2,3.5-3.1,4.4-5.1
        c0.9-2.1,1.4-4.3,1.4-6.5c-0.1-4.6-1.7-9-4.7-12.6c-3.1-3.9-7.7-6.5-12.4-8.1c-5-1.7-10.4-2.3-15.6-2.5c-5-0.2-9.9,0-15,0.4
        c-9.4,0.7-18.8,2.4-27.6,5.9c-8.8,3.5-17,8.9-23.3,16.1c-0.8,0.9-1.5,1.9-2.2,2.9C293.8,271.6,294.3,271.9,294.5,271.8L294.5,271.8
        z"
        />
        <path
            fill="#458EAD"
            d="M400.6,209.1c-0.2,0.3-0.2,0.7-0.2,1.1c0,0.3,0.1,0.7,0.2,0.9c0.1,0.7,0.1,1.2,0.1,1.9c0,0.7,0,1.3-0.1,2
        c0,0-0.1,0.6,0,0.3l-0.1,0.4c-0.1,0.3-0.2,0.7-0.2,1c-0.2,0.7-0.3,1.3-0.6,1.9c-0.1,0.3-0.2,0.6-0.3,0.9c0.1-0.2,0,0.1-0.1,0.2
        c-0.1,0.2-0.2,0.3-0.2,0.5c-0.2,0.6-0.7,1.2-0.9,1.7c-0.1,0.2-0.2,0.3-0.2,0.5l-0.1,0.2l-0.1,0.2c-0.2,0.3-0.3,0.7-0.5,1.2
        c-0.3,0.9,0.8,1.6,1.5,1.2c1.4-0.7,2.3-2.1,3-3.5c1.2-2.8,1.7-5.8,1.2-8.9c-0.1-0.7-0.2-1.3-0.5-2c-0.1-0.4-0.2-0.8-0.4-1.2
        c-0.2-0.2-0.5-0.6-0.8-0.7c-0.2-0.2-0.5,0-0.7,0.2C400.6,208.9,400.6,209.1,400.6,209.1L400.6,209.1z"
        />
        <path
            fill="#458EAD"
            d="M355.8,198.8c0.6,1.2,1.2,1.9,1.8,2.9c0.3,0.5,0.6,1.1,0.8,1.6c0,0.1,0.2,0.5,0.1,0.2
        c0,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.6,0.2,0.9c0.2,0.6,0.3,1.2,0.5,1.7c0.1,0.2,0.2,0.6,0.2,0.8c0,0.2,0,0.2,0.1,0.4
        c0,0,0,0.2,0,0s0,0,0,0.1c0.1,0.6,0.1,1.2,0.1,1.8c0,0.2,0,0.6,0,0.8c0,0.2,0,0.2,0,0.4v0.2c0,0.2,0.1-0.3,0,0
        c-0.2,1.3-0.5,2.6-0.8,3.9c-0.2,0.8,0.2,1.6,1.1,1.8c0.7,0.2,1.3-0.1,1.6-0.7c1.6-2.2,1.9-5.2,1.6-7.9c-0.2-2.8-1.3-5.5-3.1-7.7
        c-0.9-1.2-2-2.2-3.5-2.6c-0.3-0.1-0.7,0.2-0.8,0.5C355.6,198.4,355.6,198.6,355.8,198.8L355.8,198.8z"
        />
        <path
            fill="#42515A"
            d="M354.3,214.2c0.2,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5C353.8,214,354,214.2,354.3,214.2z"
        />
        <path
            fill="#458EAD"
            d="M389.9,248.9c0.6,0.2,1.2,0.6,1.6,1c0.5,0.5,1,0.9,1.4,1.4c0.2,0.2,0.3,0.4,0.5,0.7
        c0.2,0.3,0.4,0.6,0.6,0.9c0.4,0.7,0.7,1.3,1.1,2l0.1,0.2c-0.1-0.2,0,0.1,0,0.1c0.1,0.2,0.2,0.3,0.2,0.5c0.2,0.3,0.2,0.7,0.4,1.2
        c0.2,0.7,0.4,1.5,0.6,2.2s0.3,1.5,0.5,2.3c0.1,0.3,0.2,0.7,0.2,1.1c0,0.2,0.1,0.3,0.1,0.6c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0-0.1
        c0,0.1,0,0.2,0,0.2c0.1,0.8,0.2,1.7,0.2,2.5c0,0.3,0.1,0.7,0.2,1.2c0,0,0-0.2,0-0.1c0,0.1,0,0.2,0,0.3s0,0.3,0.1,0.5
        c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2s0-1.3,0-2c0-0.8-0.1-1.6-0.2-2.5c-0.2-1.6-0.6-3.1-1-4.6c-0.8-3-2.2-6.3-4.5-8.6
        c-0.6-0.6-1.2-1.2-2-1.5c-0.7-0.3-1.6-0.6-2.3-0.8C389.5,247.6,389.2,248.5,389.9,248.9L389.9,248.9z"
        />
        <path
            fill="#458EAD"
            d="M281.3,263.8c-0.1-1.2-0.2-2.5-0.1-3.7c0-0.6,0-1.2,0.1-1.8c0-0.2,0-0.6,0.1-0.9c0-0.2,0-0.2,0-0.4
        c0-0.1,0-0.2,0,0c0-0.1,0-0.2,0-0.2s0.1-0.8,0.1-0.6c0,0.2,0-0.2,0-0.3c0-0.3,0.1-0.6,0.2-0.9c0.1-0.6,0.2-1.2,0.5-1.8
        c0.2-0.6,0.3-1.2,0.6-1.7c0.1-0.2,0.2-0.6,0.3-0.8c0.1-0.2-0.1,0.2,0,0c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.2-0.3,0.2-0.5
        c0.6-1.2,1.2-2.2,1.9-3.4c0.3-0.6,0.7-1.1,1.2-1.6c0-0.1,0.2-0.2,0,0.1c0.1-0.2,0.2-0.2,0.3-0.4c0-0.1,0.2-0.2,0,0.1l0.2-0.2
        c0.2-0.2,0.4-0.6,0.7-0.8c0.8-1.1,1.8-2,2.8-2.9c0.5-0.5,1.1-0.9,1.6-1.3c0.2-0.2-0.2,0.2,0,0l0.2-0.2l0.4-0.2
        c0.2-0.2,0.6-0.4,0.9-0.6c2.7-2,5.6-3.5,8.7-4.9c0.6-0.2,0.9-0.8,0.8-1.4c-0.2-0.6-0.8-1-1.4-0.8c-3.1,0.7-6.2,2-8.9,3.6
        c-2.5,1.6-4.8,3.5-6.7,5.7c-3.7,4.1-6,9.3-6.7,14.7c-0.2,1.4-0.2,2.8,0,4.3s0.5,3,1,4.4C280.2,264.7,281.4,264.7,281.3,263.8
        L281.3,263.8z"
        />
        <path
            fill="#FFC000"
            d="M213,147.5c-0.9,1.2-1.9,2.5-2.8,3.7c-0.9,1.2-1.8,2.7-2.7,4.1c-1.7,2.9-3.1,5.8-4.4,9
        c-1.1,2.7-1.9,5.6-2.1,8.5c-0.2,2.3,0.1,4.9,1.5,6.9c1.6,2.4,4.4,3.5,7.2,3.8c1.7,0.2,3.5,0.2,5.2-0.1c2-0.2,3.9-0.7,5.8-1.5
        c2.5-1.2,4.5-3.3,5.1-6.1s0-5.8-0.9-8.5c-1.1-3.1-2.3-6.2-3.9-9.1c-0.7-1.4-1.5-2.9-2.2-4.3c-0.3-0.7-0.7-1.4-1-2.1
        c-0.2-0.3-0.2-0.7-0.4-1c0.2,0.3-0.1-0.2-0.1-0.2c-0.1-0.2-0.2-0.5-0.3-0.7c0.2,0.4-0.2-0.3-0.2-0.4c0-0.1-0.2-0.5-0.1-0.2
        c-0.2-0.4-0.3-0.7-0.7-1.1l0.2,0.3v-0.1c-0.2-1.1-1.9-0.6-1.6,0.5v0.1v-0.5c-0.1,0.4-0.1,0.8,0,1.2c0-0.4,0.1,0.3,0.1,0.5
        c0,0.2,0.1,0.3,0.1,0.6c0.2,0.7,0.4,1.3,0.7,2c0.5,1.4,1,2.8,1.6,4.2c0.6,1.6,1.3,3.1,2,4.6c0.3,0.7,0.7,1.4,1,2.1
        c0.2,0.3,0.3,0.7,0.5,1.1c0,0.1,0.2,0.7,0.1,0.2c0.1,0.2,0.2,0.4,0.2,0.6c0.6,1.4,1.1,2.7,1.4,4.2c0.2,0.7,0.3,1.3,0.4,2
        c0,0.2,0,0.3,0.1,0.5c0,0.3,0-0.4,0-0.1c0,0.1,0,0.2,0,0.2c0,0.3,0.1,0.6,0.1,0.9s0,0.6,0,0.9c0,0.2,0,0.2,0,0.4
        c0,0.2-0.2,0.6,0,0.1c-0.2,0.6-0.2,1.1-0.4,1.6c0,0.2-0.2,0.2,0.1-0.2c0,0.1,0,0.2-0.1,0.2c-0.1,0.2-0.1,0.2-0.2,0.3
        c-0.2,0.2-0.3,0.5-0.5,0.7c0.2-0.4,0.1-0.2,0,0c-0.1,0.2-0.2,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.2l-0.2,0.2l-0.1,0.1
        c-0.2,0.1-0.1,0.1,0.2-0.1c-0.2,0-0.6,0.3-0.7,0.5c-0.3,0.2-0.7,0.3-1,0.6c-0.1,0-0.2,0.1-0.2,0.1c0.3-0.2,0.2-0.1,0.1,0
        c-0.2,0.1-0.3,0.2-0.5,0.2c-0.4,0.2-0.8,0.2-1.2,0.4c-0.4,0.1-0.8,0.2-1.2,0.2c-0.2,0-0.5,0.1-0.7,0.2l-0.3,0.1h-0.2
        c0.2,0,0.2,0,0,0c-0.9,0.1-1.8,0.2-2.7,0.2c-0.8,0-1.6,0-2.3-0.1h-0.2c-0.2,0-0.3,0,0.1,0c-0.2,0-0.3,0-0.5-0.1
        c-0.3-0.1-0.7-0.2-0.9-0.2c-0.2-0.1-0.6-0.2-0.8-0.2l-0.2-0.1c-0.2-0.1-0.1-0.2,0.1,0c-0.2-0.1-0.2-0.2-0.4-0.2
        c-0.2-0.1-0.5-0.2-0.7-0.4l-0.2-0.2c-0.2-0.2,0.2,0.2,0.1,0.1l-0.2-0.2c-0.2-0.2-0.3-0.3-0.6-0.6c-0.1-0.1-0.2-0.2-0.2-0.2
        c-0.1-0.1-0.1-0.2,0.1,0.2c0-0.1-0.1-0.2-0.2-0.2c-0.2-0.2-0.2-0.4-0.4-0.7c-0.1-0.1-0.1-0.2-0.2-0.3l-0.1-0.2
        c-0.2-0.2,0.1,0.2,0,0.1c-0.2-0.5-0.3-1-0.4-1.5c0-0.2,0-0.2-0.1-0.4c0-0.1,0-0.2,0-0.2c0.1,0.3,0,0.2,0,0.1c0-0.2-0.1-0.6-0.1-0.9
        c0-0.6,0-1.2,0-1.8c0-0.2,0-0.6,0.1-0.8c0-0.1,0-0.3,0,0c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.4,0.1-0.6c0.2-1.4,0.6-2.7,1-4.1
        c0.5-1.4,1-2.9,1.6-4.3c0.2-0.2-0.1,0.2,0-0.1l0.1-0.2l0.2-0.5c0.2-0.4,0.3-0.7,0.5-1.2c0.3-0.7,0.7-1.4,1.1-2.1
        c1.4-2.9,2.8-5.6,4.3-8.4c0.3-0.7,0.7-1.2,1.2-1.9c0.3-0.5-0.4-0.9-0.7-0.4L213,147.5z"
        />
        <path
            fill="#FFC000"
            d="M207.4,169.1c-0.3,0.2-0.6,0.6-0.7,1c-0.1,0.4-0.2,0.8-0.2,1.2c0,0.8,0.1,1.6,0.4,2.4
        c0.6,1.5,1.6,3.1,3.1,3.9c0.6,0.2,1.3,0.2,1.6-0.4s0.2-1.3-0.4-1.6c0,0-0.2-0.1-0.2-0.2c0,0.1,0.2,0.2,0,0
        c-0.2-0.2-0.3-0.2-0.6-0.4c-0.2-0.1,0.2,0.2,0,0l-0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.1-0.2-0.2-0.3-0.3l-0.2-0.2
        c0.1,0.2,0,0,0,0c-0.2-0.2-0.4-0.6-0.6-0.9c-0.1-0.2-0.2-0.2-0.2-0.4c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.2,0-0.1
        c-0.1-0.3-0.2-0.6-0.2-0.9c0,0,0-0.2,0-0.1c0-0.1,0-0.2,0-0.2c0-0.2,0-0.2,0-0.4c0-0.3,0-0.6,0-0.9
        C208.2,169,207.7,168.8,207.4,169.1L207.4,169.1z"
        />
        <path
            fill="#42515A"
            d="M523.1,84.4c-0.2,11.3-0.1,22.8-0.1,34.1s0,22.8-0.1,34.1c-0.1,22.6-0.1,45.3-0.2,68v19.1
        c0,6.3-0.1,12.7,0.1,19c0,0.6,0.9,0.6,0.9,0v-1.5c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4v1.5c0,0.6,0.9,0.6,0.9,0
        c0.3-11.3,0.1-22.6,0.1-34s0-22.8,0-34.1c0-22.6-0.2-45.3-0.2-68c0-12.7,0.2-25.6-0.1-38.3c0-0.2-0.2-0.2-0.3-0.2
        C523.2,84.1,523.1,84.2,523.1,84.4L523.1,84.4z"
        />
    </svg>
);

export default OutdoorVsIndoorIllustration;
