import React, { useMemo } from 'react';
import moment from 'moment';
import { dateFormats } from 'commons/src/constants';
import { CSVFiles } from '../../../models/common';
import DownloadCsvButton from '../../status/buildingStatus/components/DownloadCsvButton';
import styles from './PartnerSummary.module.scss';
import { PartnerSummaryCSVData, SelectedPeriod } from './PartnerSummaryModels';

type Props = {
    data: PartnerSummaryCSVData[];
    dateFormat: string;
    selectedPeriod: SelectedPeriod;
};

const PartnerSummaryCSVDownload = ({ data, dateFormat, selectedPeriod }: Props): React.ReactElement => {
    const csvData = useMemo(
        () =>
            data.map(avg => ({
                organization: avg.organization,
                device: avg.device,
                co2: avg.averages.co2 || '-',
                temp: avg.averages.temp || '-',
                humidity: avg.averages.humidity || '-',
            })) || {},
        [data]
    );
    // TODO: check that filename is correct with dates. Probably did not work before. (Partner summary)
    const csvFiles: CSVFiles[] = [
        {
            fileName: `averages ${moment(selectedPeriod.fromDate).format(
                dateFormats[dateFormat as keyof typeof dateFormats].shortFormat
            )}-${moment(selectedPeriod.toDate).format(
                dateFormats[dateFormat as keyof typeof dateFormats].shortFormat
            )}`,
            content: csvData,
        },
    ];

    return (
        <div className={styles.csvButton}>
            <DownloadCsvButton csvFiles={csvFiles} modalDescription="Partner.SummaryCSVDownload" />
        </div>
    );
};

export default PartnerSummaryCSVDownload;
