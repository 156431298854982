import dotProp from 'dot-prop-immutable';
import {
    CLEAR_DEVICE_DATA,
    DeviceActionType,
    DeviceReducerActions,
    FETCH_CUSTOM_DEVICE_SEGMENT_SUCCESS,
    POLL_DEVICE_DATA,
    STOP_POLL_DEVICE_DATA,
} from '../actions/DeviceActions';
import { LOGOUT_SUCCESS, LogOutSuccess } from '../actions/LoginAndRegisterActions';
import {
    AggregateTypeValues,
    AverageSensorPeriodValues,
    ExtraSeriesValues,
    MinSensorPeriodValues,
    SensorData,
    VirtualDeviceData,
} from '../models/commonTypeScript';

export interface DeviceSensorDataState {
    sensorData: { [serialNumber: string]: SensorData };
    extraSeries: ExtraSeriesValues;
    extraVirtualSeries: ExtraSeriesValues;
    virtualSensorData: { [serialNumber: string]: SensorData };
    aggregateTypes: AggregateTypeValues;
    minValues: MinSensorPeriodValues;
    averageValues: AverageSensorPeriodValues;
    pollingDeviceInterval?: string;
    virtualMinValues: MinSensorPeriodValues;
    virtualAverageValues: AverageSensorPeriodValues;
    virtualDeviceData: {
        [serialNumber: string]: VirtualDeviceData;
    };
}

const initialState = {
    sensorData: {},
    minValues: {},
    averageValues: {},
    virtualSensorData: {},
    extraSeries: {},
    extraVirtualSeries: {},
    aggregateTypes: {},
    virtualMinValues: {},
    virtualAverageValues: {},
    pollingDeviceInterval: undefined,
    virtualDeviceData: {},
};

export default (state = initialState, action: DeviceReducerActions | LogOutSuccess): DeviceSensorDataState => {
    switch (action.type) {
        case DeviceActionType.FetchDeviceSegmentSuccess:
        case FETCH_CUSTOM_DEVICE_SEGMENT_SUCCESS:
            return {
                ...state,
                sensorData: dotProp.merge(state.sensorData, action.serialNumber, action.segment.sensorData),
                minValues: dotProp.merge(state.minValues, action.serialNumber, action.segment.minValues),
                averageValues: dotProp.merge(state.averageValues, action.serialNumber, action.segment.averageValues),
                extraSeries: dotProp.merge(state.extraSeries, action.serialNumber, action.segment.extraSeries),
                aggregateTypes: dotProp.merge(state.aggregateTypes, action.serialNumber, action.segment.aggregateTypes),
            };

        case DeviceActionType.GetVirtualDeviceSensorDataSuccess:
            return {
                ...state,
                virtualSensorData: dotProp.merge(
                    state.virtualSensorData,
                    action.serialNumber,
                    action.segment.sensorData
                ),
                extraVirtualSeries: dotProp.merge(
                    state.extraVirtualSeries,
                    action.serialNumber,
                    action.segment.extraSeries
                ),
                virtualMinValues: dotProp.merge(state.virtualMinValues, action.serialNumber, action.segment.minValues),
                virtualAverageValues: dotProp.merge(
                    state.virtualAverageValues,
                    action.serialNumber,
                    action.segment.averageValues
                ),
                virtualDeviceData: dotProp.merge(
                    state.virtualDeviceData,
                    action.serialNumber,
                    action.segment.deviceData
                ),
            };
        case DeviceActionType.FetchCustomSegmentVirtualSensorsSuccess:
            return {
                ...state,
                virtualSensorData: dotProp.merge(
                    state.virtualSensorData,
                    action.serialNumber,
                    action.segment.sensorData
                ),
                extraVirtualSeries: dotProp.merge(
                    state.extraVirtualSeries,
                    action.serialNumber,
                    action.segment.extraSeries
                ),
                virtualMinValues: dotProp.merge(state.virtualMinValues, action.serialNumber, action.segment.minValues),
                virtualAverageValues: dotProp.merge(
                    state.virtualAverageValues,
                    action.serialNumber,
                    action.segment.averageValues
                ),
            };
        case DeviceActionType.DeleteDeviceSuccess:
            return {
                ...state,
                sensorData: dotProp.delete(state.sensorData, action.serialNumber),
                virtualSensorData: dotProp.delete(state.virtualSensorData, action.serialNumber),
                extraVirtualSeries: dotProp.delete(state.extraVirtualSeries, action.serialNumber),
                minValues: dotProp.delete(state.minValues, action.serialNumber),
                virtualMinValues: dotProp.delete(state.virtualMinValues, action.serialNumber),
                averageValues: dotProp.delete(state.averageValues, action.serialNumber),
                virtualAverageValues: dotProp.delete(state.virtualAverageValues, action.serialNumber),
                extraSeries: dotProp.delete(state.extraSeries, action.serialNumber),
                aggregateTypes: dotProp.delete(state.aggregateTypes, action.serialNumber),
                virtualDeviceData: dotProp.delete(state.virtualDeviceData, action.serialNumber),
            };
        case POLL_DEVICE_DATA:
            return {
                ...state,
                pollingDeviceInterval: action.payload.selectedInterval.name,
            };
        case STOP_POLL_DEVICE_DATA:
            return {
                ...state,
                pollingDeviceInterval: undefined,
            };
        case CLEAR_DEVICE_DATA:
        case LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
};
