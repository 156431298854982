import React from 'react';

const SpaceCo2Icon = (): React.ReactElement => (
    <svg
        className="device-svg-icons"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 11H16C8.8203 11 3 16.8203 3 24C3 31.1797 8.8203 37 16 37H32C39.1797 37 45 31.1797 45 24C45 16.8203 39.1797 11 32 11ZM16 9C7.71573 9 1 15.7157 1 24C1 32.2843 7.71573 39 16 39H32C40.2843 39 47 32.2843 47 24C47 15.7157 40.2843 9 32 9H16Z"
            fill="#21282D"
        />
        <path
            d="M17.7754 20.5117C16.9707 20.5117 16.3379 20.7969 15.877 21.3672C15.416 21.9375 15.1855 22.7246 15.1855 23.7285C15.1855 24.7793 15.4062 25.5742 15.8477 26.1133C16.293 26.6523 16.9355 26.9219 17.7754 26.9219C18.1387 26.9219 18.4902 26.8867 18.8301 26.8164C19.1699 26.7422 19.5234 26.6484 19.8906 26.5352V27.7363C19.2188 27.9902 18.457 28.1172 17.6055 28.1172C16.3516 28.1172 15.3887 27.7383 14.7168 26.9805C14.0449 26.2188 13.709 25.1309 13.709 23.7168C13.709 22.8262 13.8711 22.0469 14.1953 21.3789C14.5234 20.7109 14.9961 20.1992 15.6133 19.8438C16.2305 19.4883 16.9551 19.3105 17.7871 19.3105C18.6621 19.3105 19.4707 19.4941 20.2129 19.8613L19.709 21.0273C19.4199 20.8906 19.1133 20.7715 18.7891 20.6699C18.4688 20.5645 18.1309 20.5117 17.7754 20.5117Z"
            fill="#21282D"
        />
        <path
            d="M29.3418 23.7051C29.3418 25.0996 28.9922 26.1836 28.293 26.957C27.5977 27.7305 26.6113 28.1172 25.334 28.1172C24.041 28.1172 23.0469 27.7344 22.3516 26.9688C21.6602 26.1992 21.3145 25.1074 21.3145 23.6934C21.3145 22.2793 21.6621 21.1934 22.3574 20.4355C23.0566 19.6777 24.0527 19.2988 25.3457 19.2988C26.6191 19.2988 27.6035 19.6836 28.2988 20.4531C28.9941 21.2227 29.3418 22.3066 29.3418 23.7051ZM22.8027 23.7051C22.8027 24.7598 23.0156 25.5605 23.4414 26.1074C23.8672 26.6504 24.498 26.9219 25.334 26.9219C26.166 26.9219 26.793 26.6523 27.2148 26.1133C27.6406 25.5742 27.8535 24.7715 27.8535 23.7051C27.8535 22.6543 27.6426 21.8574 27.2207 21.3145C26.8027 20.7715 26.1777 20.5 25.3457 20.5C24.5059 20.5 23.8711 20.7715 23.4414 21.3145C23.0156 21.8574 22.8027 22.6543 22.8027 23.7051Z"
            fill="#21282D"
        />
        <path
            d="M34.7505 31H30.3955V30.2178L32.0522 28.5522C32.5415 28.0513 32.8652 27.6968 33.0234 27.4888C33.1846 27.2778 33.3018 27.0801 33.375 26.8955C33.4482 26.7109 33.4849 26.5132 33.4849 26.3022C33.4849 26.0122 33.397 25.7837 33.2212 25.6167C33.0483 25.4497 32.8081 25.3662 32.5005 25.3662C32.2544 25.3662 32.0156 25.4116 31.7842 25.5024C31.5557 25.5933 31.2905 25.7573 30.9888 25.9946L30.4307 25.3135C30.7881 25.0117 31.1353 24.7979 31.4722 24.6719C31.8091 24.5459 32.168 24.4829 32.5488 24.4829C33.1465 24.4829 33.6255 24.6396 33.9858 24.9531C34.3462 25.2637 34.5264 25.6826 34.5264 26.21C34.5264 26.5 34.4736 26.7754 34.3682 27.0361C34.2656 27.2969 34.106 27.5664 33.8892 27.8447C33.6753 28.1201 33.3179 28.4937 32.8169 28.9653L31.7007 30.0464V30.0903H34.7505V31Z"
            fill="#21282D"
        />
    </svg>
);

export default SpaceCo2Icon;
