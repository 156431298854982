import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { LatLngExpression } from 'leaflet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PageWrapper from 'commons/src/components/containers/PageWrapper';
import SpinnerBlocker from 'commons/src/components/modals/ModalSpinnerBlocker';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { FloorPlanPosition } from 'commons/src/models/commonTypeScript';
import {
    createFloorPlanSpaceZone,
    deleteFloorPlanSpaceZone,
    editFloorPlanSpaceZone,
} from '../../../actions/floorPlanSpaceActions';
import { FloorPlanData, SpaceFloorPlanMode } from '../../../models/spaceFloorPlanModels';
import { CreateSpacePayload } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import AddFloorPlan from '../addFloorPlan/AddFloorPlan';
import FloorMap from './FloorMap';
import FloorPlanHeader from './FloorPlanHeader';
import styles from './FloorPlanView.module.scss';

export type Props = {
    floorPlanId?: string;
    floorPlanData?: FloorPlanData;
    addingNewFloor: boolean;
    locationId: string;
    fullscreenMode: boolean;
    toggleFullScreenMode: () => void;
};
const FloorPlanView = ({
    floorPlanId,
    floorPlanData,
    addingNewFloor,
    locationId,
    fullscreenMode,
    toggleFullScreenMode,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const floorPlans = useSelector((state: Store) => state.floorPlansSpace.floorPlans);
    const selectedFloorPlan = floorPlans.find(floorPlan => floorPlan.id === floorPlanId);
    const { loading, error } = useSelector((state: Store) => state.requests.GET_FLOOR_PLAN_DATA);
    const [mode, setMode] = useState<SpaceFloorPlanMode | undefined>(undefined);
    const [spaceZone, setSpaceZone] = useState<undefined | number[][] | LatLngExpression[][]>(undefined);

    const [selectedSpace, setSelectedSpace] = useState<string | undefined>(undefined);
    const [createSpacePayload, setCreateSpacePayload] = useState<CreateSpacePayload | undefined>(undefined);

    const onSaveSpaceZone = (): void => {
        if (selectedFloorPlan && spaceZone) {
            const boundary = spaceZone.map(zone => ({ lat: zone[0], lng: zone[1] }));
            const payload: [{ boundary: FloorPlanPosition[] }, string, string] = [
                { boundary: boundary as FloorPlanPosition[] },
                locationId,
                selectedFloorPlan.id,
            ];
            if (mode === SpaceFloorPlanMode.EDIT_NEW) {
                dispatch(createFloorPlanSpaceZone(...payload, selectedSpace, createSpacePayload));
            } else if (mode === SpaceFloorPlanMode.EDIT && selectedSpace) {
                dispatch(editFloorPlanSpaceZone(...payload, selectedSpace));
            }
        }
    };

    const onSetMode = (spaceMode: SpaceFloorPlanMode | undefined): void => {
        if (spaceMode === undefined) {
            setSpaceZone(undefined);
            setSelectedSpace(undefined);
            setCreateSpacePayload(undefined);
        } else if (spaceMode === SpaceFloorPlanMode.EDIT) {
            const zoneToEdit = floorPlanData?.spaces.find(zone => zone.id === selectedSpace);
            setSpaceZone(zoneToEdit?.boundary.map(zone => [zone.lat, zone.lng]));
        }
        setMode(spaceMode);
    };

    const onDeleteZone = (): void => {
        if (selectedSpace && floorPlanId) {
            dispatch(deleteFloorPlanSpaceZone(locationId, floorPlanId, selectedSpace));
        }
    };

    return (
        <div className={classNames(styles.wrapper, { [styles.fullscreenMode]: fullscreenMode })}>
            {selectedFloorPlan && !addingNewFloor ? (
                <>
                    <FloorPlanHeader
                        selectedFloorPlan={selectedFloorPlan}
                        locationId={locationId}
                        floorPlanId={floorPlanId}
                        mode={mode}
                        setMode={onSetMode}
                        saveZone={onSaveSpaceZone}
                        deleteZone={onDeleteZone}
                    />
                    <SpinnerBlocker isLoading={loading && !error} className={styles.spinnerBlock}>
                        {floorPlanData?.image ? (
                            <FloorMap
                                floorData={floorPlanData}
                                mode={mode}
                                locationId={locationId}
                                setSelectedSpace={setSelectedSpace}
                                setMode={onSetMode}
                                selectedSpaceId={selectedSpace}
                                setSpaceZone={setSpaceZone}
                                spaceZone={spaceZone}
                                fullscreenMode={fullscreenMode}
                                toggleFullScreenMode={toggleFullScreenMode}
                                setCreateSpacePayload={setCreateSpacePayload}
                            />
                        ) : (
                            <ResponseBox text={`ErrorCodes.${error?.error}`} />
                        )}
                    </SpinnerBlocker>
                </>
            ) : (
                <div className={styles.newFloorPlanWrapper}>
                    <PageWrapper pageType="slim">
                        <h3 className={styles.newFloorPlanHeader}>{txt('SpaceFloorPlan.AddNewFloorPlan')}</h3>
                    </PageWrapper>
                    <AddFloorPlan locationId={locationId} />
                </div>
            )}
        </div>
    );
};
export default FloorPlanView;
