import delay from '@redux-saga/delay-p';
import { takeEvery, call, put, CallEffect, PutEffect, race, take, select, SelectEffect } from 'redux-saga/effects';
import RequestActions from 'commons/src/models/RequestTypes';
import {
    FetchLocationsStatusSuccess,
    fetchLocationsStatusSuccess,
    LocationStatusActions,
} from '../actions/LocationsStatusActions';
import { RequestActionType, requestError, requestSuccess } from '../actions/requestActions';
import fetchLocationsStatus from '../api/fetchLocationsStatus';
import { pollingDelayMs } from '../constants';
import { LocationsStatusResponse } from '../models/commonTypeScript';
import { isLoggedIn } from '../reducers/reducerShortcuts';
import { CommonRequestType as RequestType } from '../reducers/requestReducer';
import { toErrorType } from './isErrorType';

type FetchLocationsStatusSagaReturnType = Generator<
    CallEffect | SelectEffect | RequestActions | PutEffect<FetchLocationsStatusSuccess>,
    void,
    LocationsStatusResponse
>;
export function* fetchLocationsStatusSaga(): FetchLocationsStatusSagaReturnType {
    while (true) {
        try {
            const loggedIn = yield select(isLoggedIn);
            if (loggedIn) {
                const response = yield call(fetchLocationsStatus);
                yield put(fetchLocationsStatusSuccess(response));
                yield put(requestSuccess(RequestType.FetchLocationsStatus, RequestActionType.Success));
            }
        } catch (error) {
            const asErrorType = toErrorType(error);
            yield put(requestError(asErrorType, RequestType.FetchLocationsStatus, RequestActionType.Error));
        } finally {
            yield call(delay, pollingDelayMs);
        }
    }
}

function* startPoll(): Generator {
    yield race({
        task: call(fetchLocationsStatusSaga),
        cancel: take(LocationStatusActions.StopPollingLocationsStatus),
    });
}

export default function* fetchLocationsStatusSagas(): Generator {
    yield takeEvery(LocationStatusActions.FetchLocationsStatusInit, startPoll);
}
