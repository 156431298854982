import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import PageHeader from 'commons/src/components/headers/PageHeader';
import { FullDeviceData } from 'commons/src/models/commonTypeScript';
import { fetchPartnerDetails, FetchPartnerDetails } from '../../../actions/partnerActions';
import { paths } from '../../../constants';
import { SubAccountDetails } from '../../../models/partner';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import partnerSubAccountMenus, { PartnerUrlParams } from './PartnerSubAccountMenus';

type StateProps = {
    customers: SubAccountDetails[];
    partnerUserGroupId?: string;
    loading: boolean;
    devices?: {
        [serialNumber: string]: FullDeviceData;
    };
};

type ActionProps = {
    getPartnerInfo: () => void;
};

export type Props = StateProps & ActionProps;

const PartnerSubAccountLayoutComponent = ({
    customers,
    partnerUserGroupId,
    getPartnerInfo,
    devices,
    loading,
}: Props): React.ReactElement => {
    const { childUserGroupId, serialNumber } = useParams<PartnerUrlParams>();
    const { t: txt } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const customer = customers.find(customerDetails => customerDetails.userGroupId === childUserGroupId);
    const name = customer?.organizationName;
    const [waitForFetchingData, setWaitForFetchingData] = useState(true);

    const deviceName = serialNumber && devices && devices[serialNumber] && devices[serialNumber].segmentName;

    const title = [name, deviceName].filter(n => !!n).join(' - ');

    useEffect((): void => {
        if (customers.length === 0) {
            getPartnerInfo();
        }
        if (partnerUserGroupId) {
            setWaitForFetchingData(false);
        }
    }, [partnerUserGroupId]);

    useEffect(() => {
        // redirect back to customers if customer do not exist
        if (!loading && !customer && !waitForFetchingData) {
            navigate({ pathname: `/${paths.partnerPage}` });
        }
    }, [loading, customer]);

    const menuTabs = partnerSubAccountMenus(customer, location.state);
    const tabMenuItems = menuTabs.length > 1 ? menuTabs : [];
    const returnToCustomerList = (): void => navigate({ pathname: `/${paths.partnerPage}` });
    return (
        <div className="partner-portal">
            <PageHeader
                title={title}
                tabs={tabMenuItems}
                subHeader={txt('Partner.AllCustomers')}
                subHeaderClick={returnToCustomerList}
            />
            <Outlet />
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    getPartnerInfo: (): FetchPartnerDetails => dispatch(fetchPartnerDetails()),
});

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { selectedGroup },
        partner: { customers },
        devicePage: { devices },
        requests: {
            [RequestType.FetchPartnerDetails]: { loading },
        },
    } = store;

    return {
        customers: customers || [],
        partnerUserGroupId: selectedGroup && selectedGroup.id,
        loading,
        devices,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerSubAccountLayoutComponent);
