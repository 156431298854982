import { DeviceActionType, DeviceReducerActions } from '../actions/DeviceActions';
import { SensorSampleInterval, DisplayScreenOptions, DeviceConfig } from '../models/commonTypeScript';

export type DeviceConfigStore = {
    estimatedBatteryLife?: number;
    screenOptions: DisplayScreenOptions[];
    deviceConfigs: { [serialNumber: string]: DeviceConfig };
};

const initialState: DeviceConfigStore = {
    screenOptions: [],
    deviceConfigs: {},
    estimatedBatteryLife: undefined,
};

const updateConfigIntervals = (
    serialNumber: string,
    currentConfigs: { [serialNumber: string]: DeviceConfig },
    newIntervals: { [sensor: string]: number }
): SensorSampleInterval[] => {
    const currentIntervals = currentConfigs[serialNumber].sensorIntervals;
    return currentIntervals
        ? currentIntervals.map(interval => ({ ...interval, current: newIntervals[interval.sensorType] }))
        : [];
};

export default (state = initialState, action: DeviceReducerActions): DeviceConfigStore => {
    switch (action.type) {
        case DeviceActionType.FetchDeviceConfigSuccess:
            return {
                ...state,
                deviceConfigs: {
                    ...state.deviceConfigs,
                    [action.serialNumber]: action.config,
                },
                screenOptions: action.config.screenOptions,
            };
        case DeviceActionType.UpdateDeviceConfigSuccess:
            return {
                ...state,
                deviceConfigs: {
                    ...state.deviceConfigs,
                    [action.serialNumber]: {
                        selectedScreens: action.config.screens,
                        sensorIntervals: updateConfigIntervals(
                            action.serialNumber,
                            state.deviceConfigs,
                            action.config.sensorIntervals
                        ),
                        batteryMode: action.config.batteryMode,
                        turboMode: action.config.turboMode,
                    },
                },
            };
        case DeviceActionType.EstimateBatteryLifeSuccess:
            return {
                ...state,
                estimatedBatteryLife: action.estimatedBatteryLifetime,
            };

        default:
            return state;
    }
};
