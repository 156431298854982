import React, { ReactElement } from 'react';
import MaterialIcon from '../MaterialIcon';
import styles from './ChipButton.module.scss';

type Props = {
    title: string;
    id: string;
    onDelete?: (id: string) => void;
    testId: string;
    disabled?: boolean;
};

const ChipButton = ({ id, onDelete, title, testId, disabled }: Props): ReactElement => {
    const onDeleteHandler = (): void => {
        if (!disabled && onDelete) {
            onDelete(id);
        }
    };
    return (
        <div
            className={disabled ? styles.disabled : styles.chip}
            data-testid={testId}
            tabIndex={disabled ? -1 : 0}
            role="button"
            onKeyUp={(e): void => {
                if (e.key === 'Enter') onDeleteHandler();
            }}
            onClick={onDeleteHandler}
            id={id}
        >
            {title}
            {!disabled && <MaterialIcon name="cancel" fill />}
        </div>
    );
};

export default ChipButton;
