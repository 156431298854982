import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { Resolution } from 'commons/src/models/commonEnums';
import { OutdoorSensorData } from '../models/outdoorDataModels';

export const getOutdoorSensorData = async (
    locationId: string,
    params: { from: string; to: string; resolution?: Resolution }
): Promise<{ sensors: OutdoorSensorData[] }> => {
    const queryParams = Object.keys(params)
        .filter(p => !!params[p as keyof typeof params])
        .map(p => `${p}=${params[p as keyof typeof params]}`)
        .join('&');
    return fetch(`/locations/${locationId}/outdoor-samples?${queryParams}`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
};

export default getOutdoorSensorData;
