import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import Error from 'commons/src/components/errorComponents/Error';
import PageHeader from 'commons/src/components/headers/PageHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import config from 'commons/src/config';
import { Environment } from 'commons/src/constants';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import getQuicksightEmbeddedUrlApi from '../../api/analyticsApi';
import styles from './AnalyticsPage.module.scss';

const forDevelopmentUseEmbeddedUrl =
    'https://us-east-1.quicksight.aws.amazon.com/embed/b05ff5794308466fad61f2bbc8fe0236/dashboards/8a2cbdaa-8ca2-4743-b62a-4907a11f1296?code=AYABeJ5wmIHtxocrhmAyblRwGv8AAAABAAdhd3Mta21zAEthcm46YXdzOmttczp1cy1lYXN0LTE6MjU5NDgwNDYyMTMyOmtleS81NGYwMjdiYy03MDJhLTQxY2YtYmViNS0xNDViOTExNzFkYzMAuAECAQB4xtoTZf7IGoPQKGWjcNLglYg8fHKEoB_X6wbByfSPUT0BYoPSxNpVhN5nYAviKLeM8QAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDPoBxujniM6APYCW6wIBEIA7vneGUw6ObHw859G9T9KvHJoqMjA3-rBAQ3gPRX16vIKgLzkemNcyAqwjGbSYoscgf4ggxF9XEhkLcywCAAAAAAwAABAAAAAAAAAAAAAAAAAASzksXxbhPLbuhwtKUzhb-P____8AAAABAAAAAAAAAAAAAAABAAAAm6lDnuHE8NHZu2lCysi-_MiIeqlNRIJsxFg-cIbD1DnriVXsudSq9H5Z95qXVnn-cUttrI288VFWHJ7jpKjhBHmRXIBtFtKEghI3pLjrUsAjAXUSACBHh3LerW4gedVsODps621-QY_UfGdz7mkIRVcEp3dk1eu03HquUHo2lmsfBJ_oPMbr0r2Ey8BzbXzavY9g9qdtDZRNnl-WVoeSRlBPBgN4zcvzXs_l-A%3D%3D&identityprovider=quicksight&isauthcode=true';

/**
 * The API endpoint will not work outside PROD because DEV does not have access to the INSIGHT account.
 * A hardcoded URL is rendered for DEV so as not to crash. This URL is expired and will show a no-access page. If you
 * want to render a functioning URL, use Postman with PROD credentials to generate an url and post it above.
 *
 * NB: The URL expires immediately after use.
 */
const AnalyticsPage = (): React.ReactElement => {
    const { t: txt } = useTranslation();

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<ErrorType | undefined>(undefined);
    const [embeddingUrl, setEmbeddingUrl] = useState<string | undefined>(undefined);

    const fetchEmbeddingUrl = useCallback(async (): Promise<void> => {
        setLoading(true);

        if (config.env === Environment.prod) {
            await getQuicksightEmbeddedUrlApi()
                .then(response => setEmbeddingUrl(response.embeddingUrl))
                .catch(err => setError(err));
        } else {
            setEmbeddingUrl(forDevelopmentUseEmbeddedUrl);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchEmbeddingUrl().catch();
    }, [fetchEmbeddingUrl]);

    if (error) {
        return <Error />;
    }

    return (
        <div>
            <PageHeader title={txt('Analytics.AnalyticsBeta')} />
            <h2 className={styles.description}>{txt('Analytics.QuicksightDescription')}</h2>
            <ReactPlaceholder ready={!loading} customPlaceholder={mediumFormLoader}>
                <div className={styles.pageWrapper}>
                    <div className={styles.iframeContainer}>
                        <iframe
                            title={txt('Analytics.QuicksightDashboard')}
                            src={embeddingUrl}
                            className={styles.iframe}
                        />
                    </div>
                </div>
            </ReactPlaceholder>
        </div>
    );
};

export default AnalyticsPage;
