import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { t as txt } from 'i18next';
import MaterialTableContextComponent from '../materialTable/context/MaterialTableContext';
import MaterialTableHeaders from '../materialTable/MaterialTableHeaders';
import Pagination from '../materialTable/MaterialTablePagination';

import { MaterialTableColumn, Row } from '../materialTable/models';
import styles from './CollapsibleTable.module.scss';
import CollapsibleTableData from './CollapsibleTableData';
import { RenderedProps } from './models';

type CollapsibleTableProps = {
    tableId: string;
    isReady?: boolean;
    data: Row[];
    renderHeader: (props: RenderedProps) => React.ReactNode;
    renderContent: (props: RenderedProps) => React.ReactNode;
    columns: MaterialTableColumn[];
    pagination?: number;
    totalDataLength?: number;
    idKey: string;
};

const CollapsibleTable = ({
    data,
    columns,
    renderContent,
    renderHeader,
    tableId,
    isReady,
    pagination,
    totalDataLength,
    idKey,
}: CollapsibleTableProps): React.ReactElement => {
    return (
        <MaterialTableContextComponent
            tableId={tableId}
            columns={columns}
            data={data}
            isReady={!!isReady}
            idKey={idKey}
            pagination={pagination}
            totalDataLength={totalDataLength ?? data.length}
        >
            <Box sx={{ width: '100%', borderRadius: '10px', overflow: 'hidden' }}>
                {!isReady && <LinearProgress variant="query" data-testid="materialTable-loader" />}
                <table className={styles.wrapper} data-testid={tableId}>
                    <MaterialTableHeaders className={styles.tableHeader} />
                    <CollapsibleTableData renderHeader={renderHeader} renderContent={renderContent} />
                </table>
            </Box>
            <Pagination type={txt('Building.BuildingsLowerCase')} />
        </MaterialTableContextComponent>
    );
};

export default CollapsibleTable;
