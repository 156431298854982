import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { ALERT_ADDED_ALERT } from 'commons/src/analytics/AnalyticsEvents';
import WizardSectionHeader from 'commons/src/components/headers/WizardSectionHeader';
import { DeviceWithKeyInfo, LocationType, Units } from 'commons/src/models/commonTypeScript';
import {
    AddEmailTrigger,
    addEmailTrigger,
    AddNotificationAlert,
    addNotificationAlert,
    FetchThirdPartyIntegrationMapping,
    fetchThirdPartyIntegrationMapping,
    UpdateEmailTrigger,
    updateEmailTrigger,
    UpdateNotificationAlert,
    updateNotificationAlert,
} from '../../../actions/thirdPartyIntegrationActions';
import { grantType, thirdPartyChannels } from '../../../constants';
import {
    ChannelDetails,
    EditNotificationAlert,
    NewNotificationAlert,
    NewThirdPartyIntegration,
    ThirdPartyIntegration,
    ThirdPartyIntegrationAlert,
    ThirdPartyIntegrationAlertRule,
    ThirdPartyIntegrationMapping,
} from '../../../models/common';
import { Store } from '../../../reducers';
import AlertLocationsOrDevices from './AlertLocationsOrDevices';
import AlertRuleForm from './AlertRuleForm';
import SetChannel from './SetChannel';

type StateProps = {
    notificationAlerts: ThirdPartyIntegrationAlert[];
    thirdPartyIntegrations: ThirdPartyIntegration[];
    updateAlertLoading: boolean;
    addAlertLoading: boolean;
    addEmailTriggerLoading: boolean;
    updateEmailTriggerLoading: boolean;
    thirdPartyMapping: ThirdPartyIntegrationMapping | undefined;
    devices: { [serialNumber: string]: DeviceWithKeyInfo };
    locations: LocationType[];
    units: Units;
};

type ActionProps = {
    fetchThirdPartyMapping: (integrationId: string) => void;
    createNotificationAlert: (integrationId: string, integrationType: string, trigger: NewNotificationAlert) => void;
    editNotificationAlert: (trigger: EditNotificationAlert) => void;
    onAddEmailTrigger: (integration: NewThirdPartyIntegration, trigger: NewNotificationAlert) => void;
    onUpdateEmailTrigger: (integration: NewThirdPartyIntegration, trigger: EditNotificationAlert) => void;
};

export type Props = StateProps & ActionProps;

export const AlertFormComponent = ({
    notificationAlerts,
    thirdPartyIntegrations,
    fetchThirdPartyMapping,
    thirdPartyMapping,
    updateAlertLoading,
    addAlertLoading,
    devices,
    locations,
    createNotificationAlert,
    editNotificationAlert,
    onAddEmailTrigger,
    addEmailTriggerLoading,
    onUpdateEmailTrigger,
    updateEmailTriggerLoading,
    units,
}: Props): React.ReactElement => {
    const { alertId } = useParams<'alertId'>() as { alertId: string };
    const alertToEdit =
        alertId && notificationAlerts.length > 0 ? notificationAlerts.find(alert => alert.id === alertId) : undefined;
    const { t: txt } = useTranslation();

    const getIntegrationChannelNameFromId = (integrationId: string): { id: string; inputValue: string } => {
        const integrationChannel = thirdPartyIntegrations.find(integration => integration.id === integrationId);
        return integrationChannel
            ? { id: integrationId, inputValue: integrationChannel.name }
            : { id: integrationId, inputValue: '' };
    };

    const getChannelEmails = (integrationId: string): string[] => {
        const integrationChannel = thirdPartyIntegrations.find(
            integration => integration.id === integrationId && integration.emails
        );
        return integrationChannel?.emails ? integrationChannel.emails : [];
    };

    const [displayChannelSection, setDisplayChannelSection] = useState(true);
    const [displayLocationsOrDevices, setDisplayLocationsOrDevices] = useState(!!alertToEdit);
    const [displaySensorSection, setDisplaySensorSection] = useState(!!alertToEdit);

    // Channel props
    const [alertName, setAlertName] = useState(alertToEdit ? alertToEdit.name : '');
    const [selectedIntegrationType, setSelectedIntegrationType] = useState(
        alertToEdit ? alertToEdit.integrationType : ''
    );
    const [selectedChannel, setSelectedChannel] = useState(
        alertToEdit ? getIntegrationChannelNameFromId(alertToEdit.integrationId) : { id: '', inputValue: '' }
    );
    const [selectedEmails, setSelectedEmails] = useState<string[]>(
        alertToEdit ? getChannelEmails(alertToEdit.integrationId) : []
    );

    // Building or device props
    const getSelectedBuildingsOrDevices = (): { id: string; name: string }[] => {
        if (!alertToEdit) return [];
        if (alertToEdit.locationIds.length > 0) {
            return locations
                .filter(location => alertToEdit.locationIds.includes(location.id))
                .map(location => ({ id: location.id, name: location.name }));
        }
        return alertToEdit.serialNumbers.map(serialNumber => {
            const device = devices[serialNumber];
            return { id: device.serialNumber, name: device.segmentName };
        });
    };
    const [selectedBuildingsOrDevices, setSelectedBuildingsOrDevices] = useState<{ id: string; name: string }[]>(
        getSelectedBuildingsOrDevices()
    );
    const [locationViewSelected, setLocationViewSelected] = useState<boolean>(
        !!alertToEdit && alertToEdit?.locationIds?.length > 0
    );

    // Rule props
    const [isActive, setActiveState] = useState(alertToEdit ? alertToEdit.active : true);

    const onUpdateChannelSection = (updatedDetails: ChannelDetails): void => {
        const { name, integrationType, integrationChannel, emails } = updatedDetails;

        setAlertName(name);
        setSelectedIntegrationType(integrationType);
        setSelectedEmails(emails);

        if (integrationChannel.id && selectedChannel.id !== integrationChannel.id) {
            if (integrationType !== thirdPartyChannels.EMAIL) {
                fetchThirdPartyMapping(integrationChannel.id);
            }
            setSelectedChannel(integrationChannel);
            setDisplayLocationsOrDevices(false);
            setDisplaySensorSection(false);
            setSelectedBuildingsOrDevices([]);
        }
    };

    const onNextChannelSection = (): void => {
        setDisplayChannelSection(false);
        setDisplayLocationsOrDevices(true);
    };
    const onNextBuildingOrDeviceSection = (): void => {
        setDisplayLocationsOrDevices(false);
        setDisplaySensorSection(true);
    };

    const locationOrDeviceHeader = (): string => {
        if (selectedBuildingsOrDevices.length === 1) {
            return `${selectedBuildingsOrDevices[0].name}`;
        }
        if (selectedBuildingsOrDevices.length > 1) {
            if (locationViewSelected) {
                const moreLocationsText =
                    selectedBuildingsOrDevices.length === 2
                        ? 'NotificationAlerts.MoreLocation'
                        : 'NotificationAlerts.MoreLocations';
                return `${selectedBuildingsOrDevices[0].name} + ${selectedBuildingsOrDevices.length - 1} ${txt(
                    moreLocationsText
                )}`;
            }
            const moreDevicesText =
                selectedBuildingsOrDevices.length === 2
                    ? 'NotificationAlerts.MoreDevice'
                    : 'NotificationAlerts.MoreDevices';
            return `${selectedBuildingsOrDevices[0].name} + ${selectedBuildingsOrDevices.length - 1} ${txt(
                moreDevicesText
            )}`;
        }
        return '';
    };

    const onSubmitAlert = (
        sensorAlertRules: ThirdPartyIntegrationAlertRule[],
        notifyOfflineDevice: boolean,
        notifyLowBattery: boolean,
        timeCondition: string
    ): void => {
        const elementIds = selectedBuildingsOrDevices.map(element => element.id);
        const createdAlert = {
            active: isActive,
            locationIds: locationViewSelected ? elementIds : [],
            serialNumbers: !locationViewSelected ? elementIds : [],
            name: alertName,
            rules: sensorAlertRules,
            notifyOfflineDevice,
            notifyLowBattery,
            timeCondition,
            units,
        };

        const emailIntegrationPayload = {
            type: thirdPartyChannels.EMAIL,
            name: thirdPartyChannels.EMAIL,
            grantType: grantType.none,
            parameters: {
                emails: selectedEmails,
            },
        };

        const isEmailIntegration = selectedIntegrationType === thirdPartyChannels.EMAIL;
        if (alertToEdit) {
            const updatedAlert = {
                trigger: createdAlert,
                triggerId: alertToEdit.id,
                integrationType: alertToEdit.integrationType,
                integrationId: alertToEdit.integrationId,
            };

            if (isEmailIntegration) {
                onUpdateEmailTrigger(emailIntegrationPayload, updatedAlert);
            } else {
                editNotificationAlert(updatedAlert);
            }
        } else if (isEmailIntegration) {
            onAddEmailTrigger(emailIntegrationPayload, createdAlert);
        } else {
            createNotificationAlert(selectedChannel.id, selectedIntegrationType, createdAlert);
        }

        analyticsLogger(ALERT_ADDED_ALERT, {
            pageType: PageType.Alert,
            sensorAlertRules,
            alarmSource: locationViewSelected ? 'buildings' : 'devices',
            channel: selectedIntegrationType,
            timeCondition,
            notifyOfflineDevice,
            notifyLowBattery,
        });
    };

    const openCloseSection = (e: SyntheticEvent<HTMLButtonElement> | SyntheticEvent<HTMLDivElement>): void => {
        const sectionId = e.currentTarget.id;
        if (sectionId === 'channelSection') {
            setDisplayChannelSection(!displayChannelSection);
        }
        if (sectionId === 'buildingSection' && selectedChannel && selectedChannel.inputValue) {
            setDisplayLocationsOrDevices(!displayLocationsOrDevices);
        }
    };

    const selectableLocations =
        selectedIntegrationType === thirdPartyChannels.EMAIL
            ? {
                  locations: locations.map(location => ({ id: location.id, name: location.name })),
                  externalLocations: [],
                  mapping: [],
              }
            : thirdPartyMapping;

    const submissionLoading =
        updateAlertLoading || addAlertLoading || addEmailTriggerLoading || updateEmailTriggerLoading;

    return (
        <>
            <div className="page-wrapper page-wrapper__medium page-wrapper__medium--white">
                <WizardSectionHeader
                    sectionNumber="1. "
                    name={txt('NotificationAlerts.Channels')}
                    description={selectedChannel.inputValue}
                    openClose={openCloseSection}
                    headerAsButton={!displaySensorSection}
                    headerClosed={!displayChannelSection}
                    headerInactive={false}
                    sectionId="channelSection"
                />
                {displayChannelSection && (
                    <SetChannel
                        editingAlert={!!alertToEdit}
                        channel={selectedChannel}
                        selectedIntegration={selectedIntegrationType}
                        name={alertName}
                        onUpdate={onUpdateChannelSection}
                        onNext={onNextChannelSection}
                        emails={selectedEmails}
                    />
                )}
            </div>
            <div className="page-wrapper page-wrapper__medium page-wrapper__medium--white">
                <WizardSectionHeader
                    sectionNumber="2. "
                    name={txt('NotificationAlerts.SelectBuildingsAndDevices')}
                    description={locationOrDeviceHeader()}
                    openClose={openCloseSection}
                    headerAsButton={!!selectedChannel.inputValue}
                    headerClosed={!displayLocationsOrDevices}
                    headerInactive={!selectedChannel.inputValue}
                    sectionId="buildingSection"
                />
                {displayLocationsOrDevices && selectableLocations && devices && (
                    <AlertLocationsOrDevices
                        devices={devices}
                        editingAlert={!!alertToEdit}
                        thirdPartyMapping={selectableLocations}
                        selectedIntegrationType={selectedIntegrationType}
                        selectedElements={selectedBuildingsOrDevices}
                        onNext={onNextBuildingOrDeviceSection}
                        setSelectedAlertElements={setSelectedBuildingsOrDevices}
                        locationViewSelected={locationViewSelected}
                        setLocationViewSelected={setLocationViewSelected}
                    />
                )}
            </div>
            <div className="page-wrapper page-wrapper__medium page-wrapper__medium--white">
                <WizardSectionHeader
                    sectionNumber="3. "
                    name={txt('NotificationAlerts.SetRules')}
                    description=""
                    headerAsButton={false}
                    headerClosed={!displayLocationsOrDevices}
                    headerInactive={!displaySensorSection}
                    sectionId="ruleSection"
                />
                {displaySensorSection && (
                    <AlertRuleForm
                        disableSubmit={!alertName || selectedBuildingsOrDevices.length === 0}
                        onSubmitAlert={onSubmitAlert}
                        alertToEdit={alertToEdit}
                        isActive={isActive}
                        setActiveState={setActiveState}
                        submissionLoading={submissionLoading}
                        selectedThresholdValues={alertToEdit ? alertToEdit.rules : []}
                    />
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        thirdPartyIntegrations: { thirdPartyIntegrations, thirdPartyMapping, notificationAlerts },
        userSettings: { units },
        devices: { devicesWithKeyInfo },
        requests: {
            UPDATE_THIRD_PARTY_ALERT: { loading: updateAlertLoading },
            ADD_THIRD_PARTY_ALERT: { loading: addAlertLoading },
            ADD_EMAIL_TRIGGER: { loading: addEmailTriggerLoading },
            UPDATE_EMAIL_TRIGGER: { loading: updateEmailTriggerLoading },
        },
        locations: { locations },
    } = state;

    return {
        devices: devicesWithKeyInfo,
        thirdPartyIntegrations,
        notificationAlerts,
        thirdPartyMapping,
        updateAlertLoading,
        addAlertLoading,
        addEmailTriggerLoading,
        updateEmailTriggerLoading,
        locations,
        units,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    fetchThirdPartyMapping: (integrationId: string): FetchThirdPartyIntegrationMapping =>
        dispatch(fetchThirdPartyIntegrationMapping(integrationId)),
    createNotificationAlert: (
        integrationId: string,
        integrationType: string,
        trigger: NewNotificationAlert
    ): AddNotificationAlert => dispatch(addNotificationAlert(integrationId, integrationType, trigger)),
    editNotificationAlert: (trigger: EditNotificationAlert): UpdateNotificationAlert =>
        dispatch(updateNotificationAlert(trigger)),
    onAddEmailTrigger: (integration: NewThirdPartyIntegration, trigger: NewNotificationAlert): AddEmailTrigger =>
        dispatch(addEmailTrigger(integration, trigger)),
    onUpdateEmailTrigger: (integration, trigger): UpdateEmailTrigger =>
        dispatch(updateEmailTrigger(integration, trigger)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertFormComponent);
