import delay from '@redux-saga/delay-p';
import moment from 'moment';
import { select, takeEvery, put, call, all, race, take, CallEffect, SelectEffect, PutEffect } from 'redux-saga/effects';
import RequestActions from 'commons/src/models/RequestTypes';
import {
    FETCH_DASHBOARD,
    fetchDashboardSuccess,
    fetchDashboardFailure,
    SelectDashboard,
    selectDashboardSuccess,
    DashboardActionType,
    DashboardResponse,
    SelectDashboardSuccess,
    FetchDashboardSuccess,
    FetchDashboardFailure,
} from '../../actions/DashboardActions';
import { POLL_DATA } from '../../actions/LocationActionTypes';
import { RequestActionType, requestError, requestSuccess } from '../../actions/requestActions';
import fetchDashboardData, { selectDashboard } from '../../api/dashboard';
import { offlineError } from '../../api/fetch';
import { validDashboardTiles, pollingDelayMs, deviceTypes } from '../../constants';
import { DeviceTypeNames } from '../../models/commonEnums';
import {
    CurrentDashboardResponse,
    DashboardSensorData,
    DashboardsResponse,
    DashboardTile,
    RawLocationTileContent,
    RawSensorTileContent,
    SensorData,
} from '../../models/commonTypeScript';
import { dashboardSensorData, dashboardTiles, isLoggedIn } from '../../reducers/reducerShortcuts';
import { CommonRequestType } from '../../reducers/requestReducer';
import { toErrorType } from '../isErrorType';
import { generateAverageValue } from './fetchDashboardSensorTile';

export function createSensorGraphArrays(
    dashboardData: CurrentDashboardResponse,
    prevDashboardSensorData: DashboardSensorData
): DashboardResponse {
    const sensorData: SensorData = {};
    const minValues: { [tileId: string]: { [interval: string]: number } } = {};
    const averageValues: { [tileId: string]: { [interval: string]: number | undefined } } = {};
    const validTiles = dashboardData.tiles.filter(tile => tile && validDashboardTiles.includes(tile.type));
    const tiles: DashboardTile[] = validTiles.map(tile => {
        if (tile.type === 'sensor') {
            const { segmentStart, measurements, offsets, serialNumber, sensorType } =
                tile.content as RawSensorTileContent;
            const segmentStartTime = moment(`${segmentStart}Z`).valueOf();
            let minValue = measurements[0];
            const chartData = offsets.map((interval, index) => {
                const measurement = measurements[index];
                if (minValue === undefined || minValue > measurement) {
                    minValue = measurement;
                }
                return [segmentStartTime + interval * 1000, measurement];
            });

            const averageValue = measurements.length > 0 ? generateAverageValue(chartData) : undefined;
            const ref = `${serialNumber}-${sensorType}`;
            sensorData[ref] = { ...prevDashboardSensorData.sensorData[ref], week: chartData };
            minValues[ref] = { ...prevDashboardSensorData.minValues[ref], week: minValue };
            averageValues[ref] = { ...prevDashboardSensorData.averageValues[ref], week: averageValue };
            return { ...tile };
        }
        if (tile.type === 'location') {
            const { devices } = tile.content as RawLocationTileContent;
            const [listOfHubs, listOfOtherDevices] = devices.reduce(
                ([hubs, other]: [string[], string[]], serialNumber: string): [string[], string[]] =>
                    deviceTypes[DeviceTypeNames.hub].isValidPrefix(serialNumber.substring(0, 4))
                        ? [[...hubs, serialNumber], other]
                        : [hubs, [...other, serialNumber]],
                [[], []]
            );
            return {
                ...tile,
                content: {
                    ...tile.content,
                    devices: listOfOtherDevices,
                    hubs: listOfHubs,
                },
            };
        }
        return tile as DashboardTile;
    });
    return {
        ...dashboardData,
        tiles,
        sensorData,
        minValues,
        averageValues,
    };
}

type GetDashboardDataSagaReturnType = Generator<
    CallEffect | SelectEffect | PutEffect<FetchDashboardSuccess> | PutEffect<FetchDashboardFailure>,
    void,
    DashboardsResponse & DashboardSensorData & DashboardTile[]
>;

export function* getDashboardDataSaga(): GetDashboardDataSagaReturnType {
    try {
        const prevDashboardSensorData: DashboardSensorData = yield select(dashboardSensorData);
        const dashboardData = yield call(fetchDashboardData);
        yield put(
            fetchDashboardSuccess(
                createSensorGraphArrays(dashboardData.currentDashboard, prevDashboardSensorData as DashboardSensorData),
                dashboardData.dashboards
            )
        );
    } catch (error) {
        const asErrorType = toErrorType(error);
        const currentDashboardTiles = yield select(dashboardTiles);
        const shouldFailGracefully = offlineError(asErrorType) && currentDashboardTiles.length > 0;
        if (!shouldFailGracefully) {
            yield put(fetchDashboardFailure(asErrorType.error));
        }
    }
}

export function* pollDashboardSaga(): Generator {
    while (true) {
        yield call(delay, pollingDelayMs);
        const loggedIn = yield select(isLoggedIn);
        if (loggedIn) {
            yield call(getDashboardDataSaga);
        }
    }
}

function* startPoll(): Generator {
    yield race({
        task: call(pollDashboardSaga),
        cancel: take(DashboardActionType.StopPollingDashboard),
    });
}

type SwitchDashboardSagaActionType = Generator<
    CallEffect<CurrentDashboardResponse> | PutEffect<SelectDashboardSuccess> | RequestActions,
    void,
    CurrentDashboardResponse
>;

export function* switchDashboardSaga({ id }: SelectDashboard): SwitchDashboardSagaActionType {
    try {
        const response = yield call(selectDashboard, id);
        yield put(
            selectDashboardSuccess(
                createSensorGraphArrays(response, { sensorData: {}, minValues: {}, averageValues: {} }),
                id
            )
        );
        yield put(requestSuccess(CommonRequestType.SelectDashboard, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.SelectDashboard, RequestActionType.Error));
    }
}

export default function* DashboardDataSaga(): Generator {
    yield all([
        takeEvery(FETCH_DASHBOARD, getDashboardDataSaga),
        takeEvery(POLL_DATA, startPoll),
        takeEvery(DashboardActionType.SelectDashboard, switchDashboardSaga),
    ]);
}
