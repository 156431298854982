import { analyticsLogger, PageType } from 'commons/src/analytics';

type MixPanelEventName = string;

type MixPanelEventPayload = {
    pageType: PageType;
} & Record<string, unknown>;

type MixPanelEvent = {
    type: MixPanelEventName;
    payload: MixPanelEventPayload;
};

export default function sendAnalyticsEvent({ type, payload }: MixPanelEvent): void {
    analyticsLogger(type, payload);
}
