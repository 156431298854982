import { sensorUnits } from 'commons/src/constants';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { BreachType } from '../../../../models/buildingModels';
import { Days } from '../../../../models/common';
import { EmailSummaryResponse } from '../../../../models/emailSummaryModels';
import { EmailPreviewContent } from '../EmailSummaryModels';

const emailSummaryConfig = {
    schedule: Days.monday,
    emailSubject: 'My weekly summary',
    selectedRecipients: [],
    selectedRules: [
        {
            sensor: 'co2',
            checked: true,
            rules: [{ type: BreachType.over, value: 1000 }],
        },
    ],
};

export const emailSummaryThresholdsAndUsers = {
    users: [
        {
            name: 'Phil',
            userId: 'dedc6ae7-89af-416a-8556-08e3e0ef7e2a',
        },
        {
            name: 'Bob',
            userId: 'dedc6ae7-89af-416a-8556-08e3e0ef7e2b',
        },
        {
            name: 'Stewart',
            userId: 'dedc6ae7-89af-416a-8556-08e3e0ef7e2c',
        },
        {
            name: 'Edward',
            userId: 'dedc6ae7-89af-416a-8556-08e3e0ef7e2d',
        },
    ],
    thresholds: {
        temp: { over: [22, 23, 24, 25, 26], under: [16, 17, 18, 19, 20], unit: 'c' },
        co2: { over: [800, 1000, 1200, 1500, 2000], unit: 'ppm' },
        pm25: { over: [10, 20, 50], unit: 'mgpc' },
        voc: { over: [250, 1000, 2000], unit: 'ppb' },
        humidity: { over: [60, 80], under: [20, 30], unit: 'pct' },
    },
};

export const mockGetEmailSummaryResponse: EmailSummaryResponse = {
    ...emailSummaryThresholdsAndUsers,
    config: emailSummaryConfig,
};

/**
 * Mock for the email preview component. Do not change, used to display
 * hardcoded data to customers.
 */
export const mockEmailPreview: EmailPreviewContent[] = [
    {
        sensorType: SensorTypes.co2,
        sensorUnit: 'ppm' as keyof typeof sensorUnits,
        rules: [{ type: BreachType.over, value: 800 }],
        locations: [
            {
                name: 'Airthings HQ - Oslo',
                totalDevices: 28,
                numOfBreachedDevices: 3,
                segments: [
                    {
                        name: 'Besseggen',
                        minutesOutside: 800,
                    },
                    {
                        name: 'Albatross',
                        minutesOutside: 800,
                    },
                    {
                        name: 'Hemsedal',
                        minutesOutside: 700,
                    },
                    {
                        name: 'Marketing area',
                        minutesOutside: 20,
                    },
                    {
                        name: 'Gålå',
                        minutesOutside: 125,
                    },
                ],
            },
            {
                name: 'Airthings Sweden - Stockholm',
                totalDevices: 9,
                numOfBreachedDevices: 3,
                segments: [
                    {
                        name: 'Conference room 1',
                        minutesOutside: 142,
                    },
                    {
                        name: 'Room 801',
                        minutesOutside: 15,
                    },
                    {
                        name: 'Ground floor',
                        minutesOutside: 60,
                    },
                ],
            },
        ],
    },
    {
        sensorType: SensorTypes.temp,
        sensorUnit: 'c' as keyof typeof sensorUnits,
        rules: [
            { type: BreachType.over, value: 24 },
            { type: BreachType.under, value: 18 },
        ],
        locations: [
            {
                name: 'Airthings HQ - Oslo',
                totalDevices: 28,
                numOfBreachedDevices: 6,
                segments: [
                    {
                        name: 'Ozone',
                        minutesOutside: 412,
                    },
                    {
                        name: 'Koster',
                        minutesOutside: 210,
                    },
                    {
                        name: 'PM 2.5',
                        minutesOutside: 20,
                    },
                    {
                        name: 'Kragerø',
                        minutesOutside: 115,
                    },
                    {
                        name: 'Besseggen',
                        minutesOutside: 30,
                    },
                ],
            },
            {
                name: 'Airthings Sweden - Stockholm',
                totalDevices: 9,
                numOfBreachedDevices: 7,
                segments: [
                    {
                        name: 'Nox',
                        minutesOutside: 142,
                    },
                    {
                        name: 'Reception Area',
                        minutesOutside: 50,
                    },
                    {
                        name: 'Ops area',
                        minutesOutside: 550,
                    },
                    {
                        name: 'Room 801',
                        minutesOutside: 59,
                    },
                    {
                        name: 'Marketing area',
                        minutesOutside: 20,
                    },
                ],
            },
        ],
    },
];
