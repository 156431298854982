import React from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';
import { getDotColor } from 'commons/src/commonFunctions';
import {
    coloredCircleStyles,
    SubHeader,
    TileContainer,
    InsightWrapper,
    ColoredCircle,
    DeviceIcon,
    ValueWrapper,
    SensorValueMedium,
} from 'commons/src/components/PDF/PdfConstants';
import { sensorUnits } from 'commons/src/constants';
import RadonPng from 'commons/src/img/sensorIcons/sensorPng/sensor-radon.png';
import { PDFContext } from '../../../../models/common';

const componentStyles = StyleSheet.create({
    tileDescription: {
        width: '23vw',
    },
});

type InsightTileProps = {
    txt: PDFContext;
    header: string;
    value: number;
    unit: keyof typeof sensorUnits;
    description: string;
    thresholds: number[];
    lld: number | undefined;
};

const InsightTile = (props: InsightTileProps): React.ReactElement => {
    const { txt, header, value, unit, description, thresholds, lld } = props;
    const displayLldValue: boolean = !!(lld && value) && lld > value;
    let displayedValue: string | number | undefined = displayLldValue ? lld : value;
    const statusColor = getDotColor(thresholds, 'radon', displayLldValue ? lld : value);
    if (displayedValue) {
        displayedValue = sensorUnits[unit] === sensorUnits.pci ? displayedValue.toFixed(1) : displayedValue.toFixed(0);
    }
    return (
        <TileContainer>
            <SubHeader>{txt(header)}</SubHeader>
            <InsightWrapper>
                <ColoredCircle style={[coloredCircleStyles[statusColor]]}>
                    <DeviceIcon src={RadonPng} />
                </ColoredCircle>
                <ValueWrapper>
                    <Text>{unit && sensorUnits[unit]}</Text>
                    <SensorValueMedium>{`${displayLldValue ? '<' : ''}${displayedValue}`}</SensorValueMedium>
                </ValueWrapper>
                <Text style={componentStyles.tileDescription}>{description}</Text>
            </InsightWrapper>
        </TileContainer>
    );
};

export default InsightTile;
