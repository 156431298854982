import React, { useState } from 'react';
import Collapse from '@mui/material/Collapse';
import classNames from 'classnames';
import { Row } from '../materialTable/models';
import styles from './CollapsibleTable.module.scss';
import { RenderedProps } from './models';

type CollapsibleItemProps = {
    data: Row;
    id: string;
    renderHeader: (props: RenderedProps) => React.ReactNode;
    renderContent: (props: RenderedProps) => React.ReactNode;
};

const CollapsibleItem = ({ data, id, renderHeader, renderContent }: CollapsibleItemProps): React.ReactElement => {
    const [opened, setOpened] = useState(false);

    const toggle = (): void => setOpened(current => !current);

    return (
        <tr className={styles.tableRow}>
            <td className={styles.cellHeader}>{renderHeader({ data, id, opened, toggle })}</td>
            <td className={classNames(styles.cellBody, { [styles.hideRow]: !opened })}>
                <Collapse key={id} id={id} in={opened} unmountOnExit={false} data-testid={`collapse_element_${id}`}>
                    {renderContent({ data, id, opened, toggle })}
                </Collapse>
            </td>
        </tr>
    );
};

export default CollapsibleItem;
