import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CollapsableSectionHeader from 'commons/src/components/headers/CollapsableSectionHeader';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { SingleSignOnClient } from '../../../../models/common';
import { Store } from '../../../../reducers';
import { BusinessRequestType } from '../../../../reducers/BusinessRequestType';
import SSOClient from './SSOClientContainer';
import { DomainStatus, setVerificationStatus } from './SSOTags';
import styles from './SSOTile.module.scss';

type StateProps = {
    verifyLoading: boolean;
    verifyError?: ErrorType;
};

type PassedProps = {
    openSection: boolean;
    singleSignOnClient?: SingleSignOnClient;
};

export type Props = PassedProps & StateProps;

export const SSOTile = ({ openSection, singleSignOnClient, verifyLoading, verifyError }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [ssoSectionOpen, setSsoSectionOpen] = useState(openSection);

    return (
        <div className={styles.tile}>
            <div className="settings-details-container">
                {singleSignOnClient ? (
                    <div className={styles.headerPadding}>
                        <CollapsableSectionHeader
                            openSection={setSsoSectionOpen}
                            headerIsOpen={ssoSectionOpen}
                            translateHeaderText={false}
                            headerText={txt('DomainStatus.DomainHeader', {
                                emailDomain: singleSignOnClient.domain,
                            })}
                            translateSubText={false}
                            subtext=""
                            showSubComponent
                            subComponent={
                                <DomainStatus
                                    active={singleSignOnClient.active}
                                    verificationStatus={setVerificationStatus(
                                        singleSignOnClient,
                                        verifyLoading,
                                        verifyError
                                    )}
                                />
                            }
                        />
                    </div>
                ) : (
                    ssoSectionOpen && (
                        <div className={styles.newDomainHeader}>
                            <p className={styles.headerText}>{txt('SingleSignOn.NewDomain')}</p>
                        </div>
                    )
                )}
                {ssoSectionOpen && <SSOClient singleSignOnClient={singleSignOnClient} />}
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        requests: {
            [BusinessRequestType.VerifySsoClientDomain]: { loading: verifyLoading, error: verifyError },
        },
    } = state;
    return {
        verifyLoading,
        verifyError,
    };
};

export default connect(mapStateToProps)(SSOTile);
