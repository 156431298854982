import { ApiClient, NewApiClient, ClientSecret } from '../models/apiClient';
import { CommonRequestType as RequestType } from '../reducers/requestReducer';
import { RequestActionType } from './requestActions';

export enum ApiClientsActionType {
    FetchApiClientsInit = 'FETCH_API_CLIENTS/INIT',
    FetchApiClientSecretInit = 'FETCH_API_CLIENT_SECRET/INIT',
    UpdateApiClientInit = 'UPDATE_API_CLIENT/INIT',
    UpdateApiClientActiveStateInit = 'UPDATE_API_CLIENT_ACTIVE_STATE/INIT',
    AddApiClientInit = 'ADD_API_CLIENT/INIT',
    DeleteApiClientInit = 'DELETE_API_CLIENT/INIT',
}
export type FetchApiClients = {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.FetchApiClients;
    type: ApiClientsActionType.FetchApiClientsInit;
};
export const fetchApiClients = (): FetchApiClients => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.FetchApiClients,
    type: ApiClientsActionType.FetchApiClientsInit,
});

export const FETCH_API_CLIENTS_SUCCESS = 'FETCH_API_CLIENT_SUCCESS';
export interface FetchApiClientsSuccess {
    type: typeof FETCH_API_CLIENTS_SUCCESS;
    apiClients: ApiClient[];
}
export const fetchApiClientsSuccess = (apiClients: ApiClient[]): FetchApiClientsSuccess => ({
    type: FETCH_API_CLIENTS_SUCCESS,
    apiClients,
});

export type UpdateApiClientActiveState = {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.UpdateApiClientActiveState;
    type: ApiClientsActionType.UpdateApiClientActiveStateInit;
    apiClient: ApiClient;
};
export const updateApiClientActiveState = (apiClient: ApiClient): UpdateApiClientActiveState => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdateApiClientActiveState,
    type: ApiClientsActionType.UpdateApiClientActiveStateInit,
    apiClient,
});

export type UpdateApiClient = {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.UpdateApiClient;
    type: ApiClientsActionType.UpdateApiClientInit;
    apiClient: ApiClient;
};

export const updateApiClient = (apiClient: ApiClient): UpdateApiClient => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.UpdateApiClient,
    type: ApiClientsActionType.UpdateApiClientInit,
    apiClient,
});

export const UPDATE_API_CLIENT_SUCCESS = 'UPDATE_API_CLIENT_SUCCESS';
export interface UpdateApiClientSuccess {
    type: typeof UPDATE_API_CLIENT_SUCCESS;
    apiClient: ApiClient;
}
export const updateApiClientSuccess = (apiClient: ApiClient): UpdateApiClientSuccess => ({
    type: UPDATE_API_CLIENT_SUCCESS,
    apiClient,
});

export type AddApiClient = {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.AddApiClient;
    type: ApiClientsActionType.AddApiClientInit;
    apiClient: NewApiClient;
};
export const addApiClient = (apiClient: NewApiClient): AddApiClient => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.AddApiClient,
    type: ApiClientsActionType.AddApiClientInit,
    apiClient,
});

export const ADD_API_CLIENT_SUCCESS = 'ADD_API_CLIENT_SUCCESS';
export interface AddApiClientSuccess {
    type: typeof ADD_API_CLIENT_SUCCESS;
    apiClient: ApiClient;
}
export const addApiClientSuccess = (apiClient: ApiClient): AddApiClientSuccess => ({
    type: ADD_API_CLIENT_SUCCESS,
    apiClient,
});

export type DeleteApiClient = {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.DeleteApiClient;
    type: ApiClientsActionType.DeleteApiClientInit;
    clientId: string;
};

export const deleteApiClient = (clientId: string): DeleteApiClient => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.DeleteApiClient,
    type: ApiClientsActionType.DeleteApiClientInit,
    clientId,
});

export const DELETE_API_CLIENT_SUCCESS = 'DELETE_API_CLIENT_SUCCESS';

export interface DeleteApiClientSuccess {
    type: typeof DELETE_API_CLIENT_SUCCESS;
    clientId: string;
}

export const deleteApiClientSuccess = (clientId: string): DeleteApiClientSuccess => ({
    type: DELETE_API_CLIENT_SUCCESS,
    clientId,
});

export type FetchApiClientSecret = {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.FetchApiClientSecret;
    type: ApiClientsActionType.FetchApiClientSecretInit;
    clientId: string;
};
export const fetchApiClientSecret = (clientId: string): FetchApiClientSecret => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.FetchApiClientSecret,
    type: ApiClientsActionType.FetchApiClientSecretInit,
    clientId,
});

export const FETCH_API_CLIENT_SECRET_SUCCESS = 'FETCH_API_CLIENT_SECRET_SUCCESS';

export interface FetchApiClientSecretSuccess {
    type: typeof FETCH_API_CLIENT_SECRET_SUCCESS;
    secret: ClientSecret;
}

export const fetchApiClientSecretSuccess = (secret: ClientSecret): FetchApiClientSecretSuccess => ({
    type: FETCH_API_CLIENT_SECRET_SUCCESS,
    secret,
});

export const HIDE_API_CLIENT_SECRET = 'HIDE_API_CLIENT_SECRET';

export interface HideApiClientSecret {
    type: typeof HIDE_API_CLIENT_SECRET;
    clientId: string;
}

export const hideApiClientSecret = (clientId: string): HideApiClientSecret => ({
    type: HIDE_API_CLIENT_SECRET,
    clientId,
});

export type ApiClientsAction =
    | FetchApiClients
    | UpdateApiClient
    | UpdateApiClientActiveState
    | AddApiClient
    | DeleteApiClient
    | FetchApiClientSecret;

export type ApiClientReducerActions =
    | ApiClientsAction
    | UpdateApiClientSuccess
    | FetchApiClientsSuccess
    | AddApiClientSuccess
    | DeleteApiClientSuccess
    | FetchApiClientSecretSuccess
    | HideApiClientSecret;
