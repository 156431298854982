import { Resolution, TimePeriod } from 'commons/src/models/commonEnums';
import { BuildingOptimizationActions, BuildingOptimizationActionType } from '../actions/buildingOptimizationActions';
import {
    HeatingCoolingData,
    PresenceByDevice,
    PresenceOverTime,
    PresenceRequest,
    PresenceWeekAggregation,
} from '../models/buildingModels';
import { PresenceViewType } from '../models/commonEnums';

export type BuildingOptimizationState = {
    heatingCoolingData?: {
        selectedPeriod: { from: string; to: string; name: TimePeriod; locationId: string; resolution: Resolution };
        data: HeatingCoolingData;
    };
    presenceWeekAggregation?: PresenceWeekAggregation;
    presenceInsight?: {
        presenceOverTime: PresenceOverTime[];
        presenceByDevice: PresenceByDevice[];
        selectedPeriod: PresenceRequest;
    };
    presenceDaySensorData: {
        [serialNumber: string]: {
            sensorData: number[][];
            averageValue: number | undefined;
            minValue: number | undefined;
        };
    };
    presenceDeviceSensorData: {
        [serialNumber: string]: {
            sensorData: number[][];
            averageValue: number | undefined;
            minValue: number | undefined;
        };
    };
};

const initialState: BuildingOptimizationState = {
    heatingCoolingData: undefined,
    presenceWeekAggregation: undefined,
    presenceDaySensorData: {},
    presenceDeviceSensorData: {},
};

export default (
    state: BuildingOptimizationState = initialState,
    action: BuildingOptimizationActions
): BuildingOptimizationState => {
    switch (action.type) {
        case BuildingOptimizationActionType.GetHeatingCoolingDataSuccess:
            return {
                ...state,
                heatingCoolingData: { selectedPeriod: action.selectedPeriod, data: action.payload },
            };
        case BuildingOptimizationActionType.GetPresenceWeekAggregationSuccess:
            return {
                ...state,
                presenceWeekAggregation: action.payload,
            };
        case BuildingOptimizationActionType.GetPresenceDataSuccess:
            return {
                ...state,
                presenceInsight: { ...action.payload, selectedPeriod: action.selectedOptions },
            };
        case BuildingOptimizationActionType.GetPresenceSensorDataSuccess: {
            if (action.viewType === PresenceViewType.DayView) {
                return {
                    ...state,
                    presenceDaySensorData: {
                        [action.serialNumber]: {
                            sensorData: action.sensorData.chartData,
                            averageValue: action.sensorData.averageValue,
                            minValue: action.sensorData.minValue,
                        },
                    },
                };
            }
            if (action.viewType === PresenceViewType.DeviceView) {
                return {
                    ...state,
                    presenceDeviceSensorData: {
                        [action.serialNumber]: {
                            sensorData: action.sensorData.chartData,
                            averageValue: action.sensorData.averageValue,
                            minValue: action.sensorData.minValue,
                        },
                    },
                };
            }
            return state;
        }
        default:
            return state;
    }
};
