import React, { PropsWithChildren, createContext, useState, useMemo } from 'react';

type SearchProps = {
    minCharacters?: number;
};

type SearchValues = {
    bind: OnSearchHandler;
    searchValue: string | null;
    search: (searchTerm: string | null) => <T extends Record<string, unknown>>(data: T[]) => T[];
};

export type OnSearchHandler = (searchText: string) => void;

export const SearchContext = createContext<SearchValues>({
    bind: () => null,
    searchValue: null,
    search:
        () =>
        <T extends Record<string, unknown>>(data: T[]): T[] =>
            data,
});

const cleanUpSearchText = (input: string): string | null => {
    if (!input) return null;
    // Remove extra whitespaces (multiple instances, single whitespaces stay) and trim ends
    // f.e. " some		test      value " -> "some test value"
    return input.replace(/\s\s+/g, ' ').trim();
};

export const searchDataset =
    <T extends Record<string, unknown>>(dataset: T[], searchTerms: string[]) =>
    (searchingValue: string): T[] => {
        const lowerCaseSearch = searchingValue.toLowerCase();
        const filteredResults = dataset.filter(currentElement => {
            return searchTerms.some(term => {
                const searchValue = (currentElement[term] as string).toLowerCase();
                return searchValue.includes(lowerCaseSearch);
            });
        });
        return filteredResults;
    };

/*
    Why this component?
        This is a context provider that allows to share values related to searching across all children
        This component:
        - binds the actual input element and handles onChange event
        - validates and cleans input value
        - abstracts actual search implementation (to reuse this component you do not have to know anything about fuse.js)
        - is agnostic of how each child wants to handle its own dataset search
 */

const Search = ({ children, minCharacters = 2 }: PropsWithChildren<SearchProps>): React.ReactElement => {
    const [searchValue, setSearchValue] = useState<string | null>(null);

    const search =
        (searchTerm: string | null) =>
        <T extends Record<string, unknown>>(data: T[]): T[] => {
            if (!searchTerm || searchTerm.length < minCharacters) return data;

            return searchDataset(data, ['serialNumber', 'segmentName'])(searchTerm);
        };

    const onSearchHandler: OnSearchHandler = text => {
        const inputText = cleanUpSearchText(text);
        setSearchValue(inputText);
    };

    const value = useMemo(
        (): SearchValues => ({ bind: onSearchHandler, searchValue, search }),
        [onSearchHandler, searchValue, search]
    );

    return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export default Search;
