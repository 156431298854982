import React, { useEffect, useState } from 'react';
import CircleButton from 'commons/src/components/buttons/CircleButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { ThirdPartyMappedLocations } from '../../../models/common';

export type Props = {
    mapping: ThirdPartyMappedLocations;
    onUnlink?: (mappedOption: ThirdPartyMappedLocations) => void;
    displayCircle: boolean;
};

export const MappedTileComponent = (props: Props): React.ReactElement => {
    const { mapping, onUnlink, displayCircle } = props;
    const [mobileView, setMobileView] = useState(false);

    const unLinkMapping = (): void => {
        if (onUnlink) onUnlink(mapping);
    };

    const nameBlock = (name: string): React.ReactElement => (
        <div className="third-party__mapping__tile__block">
            <div className="third-party__mapping__tile__block__text">{name}</div>
        </div>
    );

    const unlinkButton = (): React.ReactElement => (
        <div className="third-party__mapping__unlink-button">
            <CircleButton
                onClick={unLinkMapping}
                testAttr="unlink-mapping"
                color="alert"
                iconName={<MaterialIcon name="delete_forever" />}
            />
        </div>
    );

    const handleResizeEvent = (): void => {
        if (window.innerWidth <= 550) setMobileView(true);
        else setMobileView(false);
    };

    useEffect(() => {
        handleResizeEvent();

        window.addEventListener('resize', handleResizeEvent);
        return (): void => window.removeEventListener('resize', handleResizeEvent);
    }, []);

    if (mobileView) {
        return (
            <div className="third-party__mapping__tile">
                <div className="third-party__mapping__tile__block-wrapper">
                    {nameBlock(mapping.externalLocation.name)}
                    {nameBlock(mapping.location.name)}
                </div>
                <div className="third-party__mapping__tile__square-line">{displayCircle && unlinkButton()}</div>
            </div>
        );
    }
    return (
        <div className="third-party__mapping__tile">
            {nameBlock(mapping.externalLocation.name)}
            <div className="third-party__mapping__tile__straight-line">{displayCircle && unlinkButton()}</div>
            {nameBlock(mapping.location.name)}
        </div>
    );
};

export default MappedTileComponent;
