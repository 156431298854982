import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { sensorUnits } from '../../constants';
import styles from './PollutantSensor.module.scss';

const sensors = {
    co: <div>CO</div>,
    no2: (
        <div>
            NO
            <sub>2</sub>
        </div>
    ),
    o3: (
        <div>
            O<sub>3</sub>
        </div>
    ),
    pm10: (
        <div>
            PM
            <sub>10</sub>
        </div>
    ),
    pm25: (
        <div>
            PM
            <sub>2.5</sub>
        </div>
    ),
    so2: (
        <div>
            SO
            <sub>2</sub>
        </div>
    ),
};

type Props = {
    unit?: keyof typeof sensorUnits;
    concentration: number;
    sensor: keyof typeof sensors;
};

export const setSensorName = (sensorType: keyof typeof sensors): ReactElement | null => sensors[sensorType] || null;

const PollutantSensor = ({ sensor, unit, concentration }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const sensorHasValue = concentration !== undefined && !Number.isNaN(concentration);
    let value: string | number = concentration;
    if (sensorHasValue) {
        if (concentration > 9999) {
            value = `${(concentration / 1000).toFixed(0)}K`;
        } else if (concentration) {
            value = concentration.toFixed(0);
        }
    }

    return (
        <div className={styles.sensor}>
            {setSensorName(sensor)}
            <div className={styles.valueBlock}>
                {sensorHasValue ? (
                    <div>
                        {value}
                        <span className={styles.unit}>{unit && sensorUnits[unit]}</span>
                    </div>
                ) : (
                    txt('NotAvailableAbbreviation')
                )}
            </div>
        </div>
    );
};

export default PollutantSensor;
