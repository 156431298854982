import React, { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { displayAlertBoxSagaAction } from 'commons/src/actions';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { BUILDING_ENABLED_EMAIL } from 'commons/src/analytics/AnalyticsEvents';
import WizardSectionHeader from 'commons/src/components/headers/WizardSectionHeader';
import { toErrorTypeWithMessage } from 'commons/src/sagas/isErrorType';
import { putEmailSummary } from '../../../api/buildingsApi';
import { BreachType } from '../../../models/buildingModels';
import { SensorBreachThresholds } from '../../../models/common';
import {
    EmailSummaryConfig,
    EmailSummaryPutRequest,
    EmailSummarySensor,
    EmailSummarySensorRule,
    EmailSummaryUser,
} from '../../../models/emailSummaryModels';
import EmailSummaryNamingSection from './EmailSummaryNamingSection';
import EmailSummaryRecipientsSection from './EmailSummaryRecipientsSection';
import EmailSummaryRulesSection from './EmailSummaryRulesSection';

const section = {
    namingSection: 'emailNamingSection',
    recipientsSection: 'emailRecipientsSection',
    rulesSection: 'emailRulesSection',
};

type ParentProps = {
    users: EmailSummaryUser[];
    thresholds: SensorBreachThresholds;
    config?: EmailSummaryConfig;
};

export type Props = ParentProps;

export const EmailSummaryFormComponent = (props: Props): React.ReactElement => {
    const { users, config, thresholds } = props;

    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const buildSensorRules = (): { [sensorType: string]: EmailSummarySensor } => {
        return Object.entries(thresholds).reduce((acc, [sensor, sensorThresholds]) => {
            const selectedSensor = config?.selectedRules?.find(it => it.sensor === sensor);
            const lowerThreshold = sensorThresholds.under !== undefined && {
                type: BreachType.under,
                value: sensorThresholds.under[0],
            };
            const upperThreshold = { type: BreachType.over, value: sensorThresholds.over[0] };
            const newThresholdRule = lowerThreshold ? [upperThreshold, lowerThreshold] : [upperThreshold];
            const newRules: EmailSummarySensorRule[] = !selectedSensor ? newThresholdRule : [...selectedSensor.rules];

            return {
                ...acc,
                [sensor]: {
                    unit: sensorThresholds.unit,
                    checked: !!selectedSensor,
                    sensor,
                    rules: newRules.filter(elem => Object.keys(elem).length !== 0),
                },
            };
        }, {});
    };

    const [displayNamingSection, setDisplayNamingSection] = useState(true);
    const [displayRecipientsSection, setDisplayRecipientsSection] = useState(!!config);
    const [displayRulesSection, setDisplayRulesSection] = useState(!!config);

    const [emailSubject, setEmailSubject] = useState<string>(config?.emailSubject || '');
    const [selectedRecipients, setSelectedRecipients] = useState<string[]>(config?.selectedRecipients || []);
    const [rules, setRules] = useState<{ [sensorType: string]: EmailSummarySensor }>(buildSensorRules());

    const [updateLoading, setUpdateLoading] = useState<boolean>(false);

    const openCloseSection = (e: SyntheticEvent<HTMLButtonElement> | SyntheticEvent<HTMLDivElement>): void => {
        const sectionId = e.currentTarget.id;
        if (sectionId === section.namingSection) {
            setDisplayNamingSection(!displayNamingSection);
        }
        if (sectionId === section.recipientsSection && emailSubject.length > 0) {
            setDisplayRecipientsSection(!displayRecipientsSection);
        }
    };

    const onUpdateRule = (sensor: string, options: EmailSummarySensorRule[], enabled: boolean): void => {
        setRules(prevRules => ({
            ...prevRules,
            [sensor]: {
                ...rules[sensor],
                rules: options,
                checked: enabled,
            },
        }));
    };

    const selectUnselectSensor = (sensor: string, checked: boolean): void => {
        setRules(prevRules => ({
            ...prevRules,
            [sensor]: {
                ...rules[sensor],
                checked,
            },
        }));
    };

    const onDisplayRecipientsSection = (): void => {
        setDisplayNamingSection(false);
        setDisplayRecipientsSection(true);
    };

    const onDisplayRulesSection = (): void => {
        setDisplayRecipientsSection(false);
        setDisplayRulesSection(true);
    };

    const onUpdateSelectedRecipients = ({ id: user, changeAll }: { id: string; changeAll: boolean }): void => {
        if (changeAll) {
            if (users.length === selectedRecipients.length) {
                setSelectedRecipients([]);
            } else {
                setSelectedRecipients(users.map(rep => rep.userId));
            }
        } else {
            const newSelectedRecipients = selectedRecipients.includes(user)
                ? selectedRecipients.filter(rep => rep !== user)
                : [...selectedRecipients, user];
            setSelectedRecipients(newSelectedRecipients);
        }
    };

    const recipientsHeader = (): string => {
        if (selectedRecipients.length === 1) {
            return `${selectedRecipients.length} ${txt('EmailSummary.Recipient')}`;
        }
        if (selectedRecipients.length > 1) {
            return `${selectedRecipients.length} ${txt('EmailSummary.RecipientsLowerCase')}`;
        }
        return '';
    };

    const rulesHeader = (): string => {
        const selectedRules = Object.values(rules).filter(rule => rule.checked);
        if (selectedRules.length === 1) {
            return `${selectedRules[0].sensor.toUpperCase()}`;
        }
        if (selectedRules.length > 1) {
            return `${selectedRules[0].sensor.toUpperCase()} + ${selectedRules.length - 1} ${txt(
                selectedRules.length === 2 ? 'EmailSummary.Other' : 'EmailSummary.Others'
            ).toLowerCase()}`;
        }
        return '';
    };

    const onUpdateEmailSummary = useCallback(async (payload: EmailSummaryPutRequest): Promise<void> => {
        setUpdateLoading(true);
        await putEmailSummary(payload)
            .then(() => {
                dispatch(displayAlertBoxSagaAction('EmailSummary.EmailUpdated', false, true));
            })
            .catch(err => {
                dispatch(displayAlertBoxSagaAction(toErrorTypeWithMessage(err).message, true, true));
            });
        setUpdateLoading(false);
    }, []);

    const onSave = (): void => {
        const thresholdsOrder = [BreachType.under, BreachType.over];

        const selectedRules = Object.entries(rules).reduce((acc, [sensor, data]) => {
            if (data.checked)
                return {
                    ...acc,
                    [sensor]: data.rules.sort(
                        (a, b) =>
                            thresholdsOrder.indexOf(a.type as BreachType) -
                            thresholdsOrder.indexOf(b.type as BreachType)
                    ),
                };
            return { ...acc };
        }, {});

        onUpdateEmailSummary({ emailSubject, selectedRecipients, selectedRules }).catch();
        if (!config) {
            analyticsLogger(BUILDING_ENABLED_EMAIL, {
                pageType: PageType.Building,
                recipients: selectedRecipients.length,
                sensors: selectedRules,
            });
        }
    };

    return (
        <>
            <div className="page-wrapper page-wrapper__medium page-wrapper__medium--white">
                <WizardSectionHeader
                    sectionNumber="1. "
                    name={txt('EmailSummary.NameOfEmail')}
                    description={config?.emailSubject || ''}
                    openClose={openCloseSection}
                    headerAsButton={!displayRulesSection}
                    headerClosed={!displayNamingSection}
                    headerInactive={false}
                    sectionId={section.namingSection}
                />
                {displayNamingSection && (
                    <EmailSummaryNamingSection
                        emailSubject={emailSubject}
                        onNext={onDisplayRecipientsSection}
                        onUpdate={(name: string): void => setEmailSubject(name)}
                        showButton={!config}
                    />
                )}
            </div>
            <div className="page-wrapper page-wrapper__medium page-wrapper__medium--white">
                <WizardSectionHeader
                    sectionNumber="2. "
                    name={txt('EmailSummary.Recipients')}
                    description={recipientsHeader()}
                    openClose={openCloseSection}
                    headerAsButton={emailSubject.length > 0}
                    headerClosed={!displayRecipientsSection}
                    headerInactive={emailSubject.length === 0}
                    sectionId={section.recipientsSection}
                />
                {displayRecipientsSection && (
                    <EmailSummaryRecipientsSection
                        onNext={onDisplayRulesSection}
                        users={users}
                        selectedRecipients={selectedRecipients}
                        updateSelectedRecipients={onUpdateSelectedRecipients}
                        disableButton={!config}
                    />
                )}
            </div>
            <div className="page-wrapper page-wrapper__medium page-wrapper__medium--white">
                <WizardSectionHeader
                    sectionNumber="3. "
                    name={txt('EmailSummary.SetRules')}
                    description={rulesHeader()}
                    openClose={openCloseSection}
                    headerAsButton={false}
                    headerClosed={!displayRecipientsSection}
                    headerInactive={!displayRulesSection}
                    sectionId={section.rulesSection}
                />
                {displayRulesSection && (
                    <EmailSummaryRulesSection
                        thresholds={thresholds}
                        setRules={onUpdateRule}
                        selectUnselectSensor={selectUnselectSensor}
                        rules={rules}
                        onSave={onSave}
                        buttonLoading={updateLoading}
                        buttonDisabled={emailSubject.trim().length === 0}
                    />
                )}
            </div>
        </>
    );
};

export default EmailSummaryFormComponent;
