import React from 'react';
import { connect } from 'react-redux';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { RequiredRoleLevel, Role } from 'commons/src/models/commonEnums';
import { Store } from '../../../reducers';
import OrganizationProfile from './OrganizationProfile';
import SSOSection from './sso/SSOSection';

interface StateProps {
    ownRole?: string;
}

export const OrganizationConfigComponent = ({ ownRole }: StateProps): React.ReactElement => {
    const roleAdminOrAbove = ownRole && userRoleAboveRequiredLevel(ownRole as Role, RequiredRoleLevel.ABOVE_ADMIN);

    return (
        <div>
            <OrganizationProfile />
            {roleAdminOrAbove && <SSOSection />}
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { selectedGroup },
    } = store;

    return {
        ownRole: selectedGroup && selectedGroup.role,
    };
};

export default connect(mapStateToProps)(OrganizationConfigComponent);
