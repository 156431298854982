import React from 'react';

const HubIcon = (): React.ReactElement => (
    <svg
        version="1.1"
        className="device-svg-icons"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
    >
        <path
            d="M43,1c2.2,0,4,1.8,4,4v38c0,2.2-1.8,4-4,4H5c-2.2,0-4-1.8-4-4V5c0-2.2,1.8-4,4-4H43z M43,3H5
            C3.9,3,3,3.9,3,5l0,0v38c0,1.1,0.9,2,2,2l0,0h38c1.1,0,2-0.9,2-2l0,0V5C45,3.9,44.1,3,43,3L43,3z M23.2,22.2v3.2l0,0.2
            c0,0.2,0,0.2,0,0.4c0,0.3,0,0.3,0.2,0.5c0.1,0.2,0.1,0.2,0.3,0.4C24,27,24,27,24.3,27c0.3,0,0.3,0,0.6-0.1c0.3-0.1,0.3-0.1,0.4-0.4
            c0.2-0.2,0.2-0.2,0.2-0.5c0.1-0.3,0.1-0.3,0.1-0.6l0,0v-3.2h1.4V28h-1.4v-0.9h0l-0.1,0.1c-0.2,0.3-0.2,0.3-0.6,0.6
            c-0.5,0.3-0.5,0.3-1.1,0.3c-0.6,0-0.6,0-1-0.2s-0.4-0.2-0.7-0.5s-0.3-0.3-0.4-0.7c-0.1-0.4-0.1-0.4-0.1-0.9l0,0v-3.6H23.2z
            M30.6,18.9v4h0l0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.2-0.2,0.4-0.3c0.2-0.1,0.2-0.1,0.5-0.2s0.3-0.1,0.7-0.1
            c0.6,0,0.6,0,1.1,0.2c0.5,0.2,0.5,0.2,0.9,0.6c0.4,0.4,0.4,0.4,0.6,1c0.2,0.6,0.2,0.6,0.2,1.2c0,0.6,0,0.6-0.2,1.2
            c-0.2,0.6-0.2,0.6-0.6,1c-0.4,0.4-0.4,0.4-0.9,0.7c-0.5,0.2-0.5,0.2-1.2,0.2c-0.6,0-0.6,0-1.1-0.3c-0.5-0.3-0.5-0.3-0.8-0.8l0,0h0
            V28h-1.4v-9.1H30.6z M14.2,19.5v3.4h4v-3.4h1.5V28h-1.5v-3.8h-4V28h-1.5v-8.5H14.2z M32.3,23.2c-0.4,0-0.4,0-0.7,0.2
            c-0.3,0.2-0.3,0.2-0.5,0.4s-0.2,0.3-0.3,0.6s-0.1,0.3-0.1,0.7c0,0.3,0,0.3,0.1,0.7s0.1,0.3,0.3,0.6s0.2,0.3,0.5,0.4
            s0.3,0.2,0.7,0.2c0.4,0,0.4,0,0.7-0.2c0.3-0.2,0.3-0.2,0.5-0.4s0.2-0.3,0.3-0.6s0.1-0.3,0.1-0.7c0-0.3,0-0.3-0.1-0.7
            s-0.1-0.3-0.3-0.6s-0.2-0.3-0.5-0.4S32.7,23.2,32.3,23.2z"
        />
    </svg>
);

export default HubIcon;
