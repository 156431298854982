import { roleRestrictions } from 'commons/src/constants';
import ApiIntegrationPage from 'commons/src/features/integrations/apiIntegration/ApiIntegrationPage';
import { TabOption } from 'commons/src/models/menuModels';
import { Path, paths } from '../../constants';
import ConsumerIntegrationsOverview from './ConsumerIntegrationsOverview';

const IntegrationsSubMenus: TabOption[] = [
    {
        text: 'Integrations.Overview',
        route: paths.integrations as Path,
        path: `/${paths.integrations}`,
        id: 'consumerIntegrationsOverview',
        testAttr: 'consumer-integrations-overview',
        view: ConsumerIntegrationsOverview,
        requiredRoleLevel: roleRestrictions.thirdPartyIntegration,
        requiredGroupTypes: [],
    },
    {
        text: 'ApiIntegration.ApiIntegration',
        route: paths.apiIntegration as Path,
        path: `/${paths.apiIntegration}`,
        id: 'consumerApiIntegrations',
        testAttr: 'consumer-api-integrations',
        view: ApiIntegrationPage,
        requiredRoleLevel: roleRestrictions.api,
        requiredGroupTypes: [],
    },
];

export default IntegrationsSubMenus;
