import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import { FlexWrapperSpaced } from '../../../components/PDF/PdfConstants';
import { dateFormats } from '../../../constants';
import { HomeRadonValues, PDFContext } from '../../../models/commonTypeScript';

const styles = StyleSheet.create({
    footerParagraph: {
        fontSize: 8,
        paddingTop: '2vw',
    },
    details: {
        fontSize: 8,
        marginTop: '1vw',
    },
    smallText: {
        fontSize: 8,
    },
    flexWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
});

type Props = {
    name: string;
    dateFormat: keyof typeof dateFormats;
    txt: PDFContext;
    reportId: string;
    serialNumber: string;
    radonValues: HomeRadonValues;
    requiredMeasurementPeriodMonths: number;
    mdaValue: string;
};

const ReportDetails = ({
    name,
    dateFormat,
    reportId,
    txt,
    serialNumber,
    radonValues,
    requiredMeasurementPeriodMonths,
    mdaValue,
}: Props): React.ReactElement => (
    <View style={styles.details}>
        <FlexWrapperSpaced>
            <View>
                <View style={styles.flexWrapper}>
                    <Text style={styles.smallText}>
                        {txt('Report.Generated')}
                        {': '}
                    </Text>
                    <Text style={styles.smallText}>
                        {moment().format(dateFormats[dateFormat].shortFormat)}{' '}
                        {moment().format(dateFormats[dateFormat].hourFormat)}
                    </Text>
                </View>
                <View style={styles.flexWrapper}>
                    <Text style={styles.smallText}>
                        {txt('Report.MeasurementBy')}
                        {': '}
                    </Text>
                    <Text style={styles.smallText}>{name}</Text>
                </View>
                <View style={styles.flexWrapper}>
                    <Text style={styles.smallText}>
                        {txt('Report.ReportId')}
                        {': '}
                    </Text>
                    <Text style={styles.smallText}>{reportId}</Text>
                </View>
            </View>
            <View>
                <View style={styles.flexWrapper}>
                    <Text style={styles.smallText}>
                        {txt('RadonInsight.SamplingMethod')}
                        {': '}
                    </Text>
                    <Text style={styles.smallText}>{txt('RadonInsight.PassiveDiffusionChamber')}</Text>
                </View>
                <View style={styles.flexWrapper}>
                    <Text style={styles.smallText}>
                        {txt('RadonInsight.MeasurementMethod')}
                        {': '}
                    </Text>
                    <Text style={styles.smallText}>{txt('RadonInsight.ContinuousAlphaSpectrometry')}</Text>
                </View>
                <View style={styles.flexWrapper}>
                    <Text style={styles.smallText}>
                        {txt('Serialnumber')}
                        {': '}
                    </Text>
                    <Text style={styles.smallText}>{serialNumber}</Text>
                </View>
            </View>
        </FlexWrapperSpaced>
        <Text style={styles.footerParagraph}>
            {txt('CorentiumHomeRadonReport.MeasurementValueExplained', {
                standardDeviation: radonValues.standardDeviation.toString(),
            })}
            {'\n'}
            {radonValues.estimatedAnnualAverage && [
                txt('CorentiumHomeRadonReport.AnnualAverageExplained', {
                    requiredMeasurementPeriodMonths: requiredMeasurementPeriodMonths.toString(),
                }),
                '\n',
            ]}
            {txt('CorentiumHomeRadonReport.MDAExplained', { mdaValue })}
            {'\n\n'}
            {txt('RadonInsight.FooterReportTechnicalInformation')}
        </Text>
    </View>
);

export default ReportDetails;
