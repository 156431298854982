import L, { DivIcon } from 'leaflet';
import DeviceMarker from '../../../img/floorplan_device-marker.svg';
import HubMarker from '../../../img/floorplan_hub-marker.svg';

const DeviceMarkerIcons = {
    Default: new L.Icon({
        iconUrl: DeviceMarker,
        iconSize: [40, 40],
    }),
    Hub: new L.Icon({
        iconUrl: HubMarker,
        iconSize: [40, 40],
    }),
    SensorValueIcon: (value: string, color: string): DivIcon =>
        L.divIcon({
            html: `${value}`,
            className: `sensor-value sensor-value--${color}`,
            iconAnchor: [15, 15],
        }),
};

export default DeviceMarkerIcons;
