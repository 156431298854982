import React, { ReactElement, useEffect, useState } from 'react';
import { SpaceProperty } from 'commons/src/models/commonTypeScript';
import { SpacePropertyEditState } from './SpacePropertyEditCommon';
import SpacePropertyEditOption from './SpacePropertyEditOption';
import styles from './SpacePropertyEditOptions.module.scss';

export type Props = {
    property: { id: string; data: SpaceProperty };
    activeSection?: SpacePropertyEditState;
    setActiveSection: (section: SpacePropertyEditState) => void;
};

const SpacePropertyEditOptions = ({ property, activeSection, setActiveSection }: Props): ReactElement => {
    const [selectedOptionId, setSelectedOptionId] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (activeSection !== SpacePropertyEditState.EDIT_OPTIONS) {
            setSelectedOptionId(undefined);
        }
    }, [activeSection]);

    const onSetSelectedOptionId = (id: string): void => {
        setSelectedOptionId(id);
        setActiveSection(SpacePropertyEditState.EDIT_OPTIONS);
    };

    const options: { id: string; name: string }[] = property.data.value?.options ?? [];
    return (
        <div className={styles.body}>
            {options.map(option => (
                <SpacePropertyEditOption
                    option={option}
                    setSelectedOptionId={onSetSelectedOptionId}
                    activeOptionId={selectedOptionId}
                    key={option.id}
                    propertyId={property.id}
                    options={options.filter(it => it.id !== option.id)}
                />
            ))}
        </div>
    );
};

export default SpacePropertyEditOptions;
