import { all, call, put, takeEvery, CallEffect, PutEffect } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import RequestActions from 'commons/src/models/RequestTypes';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import {
    FetchIaqInsightData,
    FetchIaqInsightDataSuccess,
    fetchIaqInsightDataSuccess,
    FetchRadonInsightData,
    FetchRadonInsightDataSuccess,
    fetchRadonInsightDataSuccess,
    FetchVirusRiskInsightData,
    FetchVirusRiskInsightDataSuccess,
    fetchVirusRiskInsightDataSuccess,
    IaqInsightResponse,
    InsightActionType,
    RadonInsightResponse,
    VirusRiskInsightResponse,
} from '../actions/insightActions';
import getInsightData, { getIaqReport, getVirusRiskReport } from '../api/insightApi';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type GetRadonInsightDataSagaReturnType = Generator<
    CallEffect<RadonInsightResponse> | PutEffect<FetchRadonInsightDataSuccess> | RequestActions,
    void,
    RadonInsightResponse
>;
export function* getRadonInsightDataSaga({ payload }: FetchRadonInsightData): GetRadonInsightDataSagaReturnType {
    const { from, to, serialNumbers, locationId } = payload;
    try {
        const response: RadonInsightResponse = yield call(getInsightData, {
            from,
            to,
            serialNumbers,
            locationId,
        });
        yield put(fetchRadonInsightDataSuccess({ ...response, ...payload }));
        yield put(requestSuccess(RequestType.FetchRadonInsightData, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchRadonInsightData, RequestActionType.Error));
    }
}

type GetIaqInsightDataSagaReturnType = Generator<
    CallEffect<IaqInsightResponse> | PutEffect<FetchIaqInsightDataSuccess> | RequestActions,
    void,
    IaqInsightResponse
>;
export function* getIaqInsightDataSaga({ payload }: FetchIaqInsightData): GetIaqInsightDataSagaReturnType {
    try {
        const response: IaqInsightResponse = yield call(getIaqReport, payload);
        yield put(fetchIaqInsightDataSuccess({ ...payload, ...response }));
        yield put(requestSuccess(RequestType.FetchIaqInsightData, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchIaqInsightData, RequestActionType.Error));
    }
}

type GetVirusRiskDataSagaReturnType = Generator<
    CallEffect<VirusRiskInsightResponse> | PutEffect<FetchVirusRiskInsightDataSuccess> | RequestActions,
    void,
    VirusRiskInsightResponse
>;
export function* getVirusRiskInsightDataSaga({ payload }: FetchVirusRiskInsightData): GetVirusRiskDataSagaReturnType {
    const { locationId, includeLogo, serialNumbers } = payload;
    try {
        const response: VirusRiskInsightResponse = yield call(getVirusRiskReport, payload);
        yield put(fetchVirusRiskInsightDataSuccess({ locationId, includeLogo, serialNumbers, ...response }));
        yield put(requestSuccess(RequestType.FetchVirusRiskInsightData, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchVirusRiskInsightData, RequestActionType.Error));
    }
}

export default function* InsightSagas(): Generator {
    yield all([
        takeEvery(InsightActionType.FetchRadonInsightDataInit, getRadonInsightDataSaga),
        takeEvery(InsightActionType.FetchIaqInsightDataInit, getIaqInsightDataSaga),
        takeEvery(InsightActionType.FetchVirusRiskInsightDataInit, getVirusRiskInsightDataSaga),
    ]);
}
