import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceHolder from 'react-placeholder';
import { useDispatch, useSelector } from 'react-redux';
import { mapDeviceType } from 'commons/src/commonFunctions';
import DeviceStatusPill from 'commons/src/components/pills/DeviceStatusPill';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';
import DeviceHealthStatus from 'commons/src/models/deviceHealthStatus';
import { fetchOrganizationBuildingData } from '../../../actions/organizationHealthActions';
import { RenderedProps } from '../../../components/collapsibleTable/models';
import MaterialTable from '../../../components/materialTable/MaterialTable';
import MaterialTablePlaceholder from '../../../components/materialTable/MaterialTablePlaceholder';
import { MaterialTableColumn } from '../../../components/materialTable/models';
import { BUILDING_HEALTH_TABLE_LENGTH } from '../../../constants';
import { Store } from '../../../reducers';
import styles from './BuildingRow.module.scss';

type BuildingRowContentProps = RenderedProps;

const BuildingRowContent = ({ opened, id }: BuildingRowContentProps): React.ReactNode => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const { data, totalData } = useSelector((state: Store) => state.organizationHealth.locations[id]);
    const { loading, streaming, done } = useSelector((state: Store) => state.organizationHealth.requests[id]);

    useEffect(() => {
        if (opened && !done) {
            dispatch(fetchOrganizationBuildingData(id));
        }
    }, [opened, done]);

    const columns: MaterialTableColumn[] = [
        {
            accessor: 'segmentName',
            label: txt('HealthStatus.Name'),
            sortable: true,
            width: '150px',
            locked: true,
            renderer: ({ rowData }): React.ReactNode => {
                const name = rowData.segmentName as string;
                return (
                    <span title={name} className={styles.deviceName}>
                        {name}
                    </span>
                );
            },
        },
        {
            accessor: 'type',
            label: txt('HealthStatus.Type'),
            sortable: true,
            width: '130px',
            renderer: ({ rowData }): React.ReactNode => {
                const type = rowData?.type as AnyDeviceType;
                if (!type) return null;
                return <span>{txt(`${mapDeviceType(type)}FullName`)}</span>;
            },
        },
        {
            accessor: 'serialNumber',
            label: txt('BuildingHealth.SerialNumber'),
            sortable: true,
            width: '100px',
        },
        {
            accessor: 'floor',
            label: txt('HealthStatus.Floor'),
            sortable: true,
            width: '70px',
            renderer: ({ rowData }): React.ReactNode => {
                const type = rowData?.type;
                if (type === DeviceTypeNames.hub) return 'n/a';

                const floor = rowData?.floor as string;
                return floor ?? '-';
            },
        },
        {
            accessor: 'healthStatus',
            label: txt('HealthStatus.Status'),
            sortable: true,
            renderer: ({ rowData }): React.ReactNode => {
                const type = rowData?.type as AnyDeviceType;
                const deviceType = type === DeviceTypeNames.hub ? 'hub' : 'device';
                return <DeviceStatusPill status={rowData.healthStatus as DeviceHealthStatus} deviceType={deviceType} />;
            },
            width: '280px',
        },
    ];

    return (
        <main className={styles.bodyWrapper} data-testid={`collapse_content_${id}`}>
            <ReactPlaceHolder ready={!loading} showLoadingAnimation customPlaceholder={<MaterialTablePlaceholder />}>
                <MaterialTable
                    tableId={`org_table_building_${id}`}
                    columns={columns}
                    data={data}
                    isReady={!streaming}
                    idKey="serialNumber"
                    pagination={BUILDING_HEALTH_TABLE_LENGTH}
                    totalDataLength={totalData}
                />
            </ReactPlaceHolder>
        </main>
    );
};

export default BuildingRowContent;
