import { ErrorType } from '../models/commonTypeScript';

export enum UnsubscribeAlertEmailActionType {
    UnsubscribeFromEmailAlertInit = 'UNSUBSCRIBE_FROM_EMAIL_ALERT/INIT',
    UnsubscribeFromEmailAlertSuccess = 'UNSUBSCRIBE_FROM_EMAIL_ALERT_SUCCESS',
    UnsubscribeFromEmailAlertError = 'UNSUBSCRIBE_FROM_EMAIL_ALERT_ERROR',
}

export interface UnsubscribeFromEmailAlert {
    type: UnsubscribeAlertEmailActionType.UnsubscribeFromEmailAlertInit;
    email: string;
    id: string;
    externalAuthId: string;
}
export const unsubscribeFromEmailAlert = (
    email: string,
    id: string,
    externalAuthId: string
): UnsubscribeFromEmailAlert => ({
    type: UnsubscribeAlertEmailActionType.UnsubscribeFromEmailAlertInit,
    email,
    id,
    externalAuthId,
});

export interface UnsubscribeFromEmailAlertSuccess {
    type: UnsubscribeAlertEmailActionType.UnsubscribeFromEmailAlertSuccess;
}
export const unsubscribeFromEmailAlertSuccess = (): UnsubscribeFromEmailAlertSuccess => ({
    type: UnsubscribeAlertEmailActionType.UnsubscribeFromEmailAlertSuccess,
});

export interface UnsubscribeFromEmailAlertError {
    type: UnsubscribeAlertEmailActionType.UnsubscribeFromEmailAlertError;
    error: ErrorType;
}
export const unsubscribeFromEmailAlertError = (error: ErrorType): UnsubscribeFromEmailAlertError => ({
    type: UnsubscribeAlertEmailActionType.UnsubscribeFromEmailAlertError,
    error,
});

export type UnsubscribeAlertEmailReducerActions =
    | UnsubscribeFromEmailAlert
    | UnsubscribeFromEmailAlertError
    | UnsubscribeFromEmailAlertSuccess;
