import { RequestActionType } from 'commons/src/actions/requestActions';
import { SelectGroup } from 'commons/src/actions/selectGroupActions';
import { BusinessActionRequestType } from '../models/common';
import { NewPartnerCustomerRequest, PartnerCustomerDetails, UpdatePartnerCustomerRequest } from '../models/partner';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum PartnerCustomerActions {
    CreatePartnerCustomerInit = 'CREATE_PARTNER_CUSTOMER/INIT',
    CreatePartnerCustomerSuccess = 'CREATE_PARTNER_CUSTOMER_SUCCESS',
    UpdatePartnerCustomerInit = 'UPDATE_PARTNER_CUSTOMER/INIT',
    UpdatePartnerCustomerSuccess = 'UPDATE_PARTNER_CUSTOMER_SUCCESS',
}

export interface CreatePartnerCustomer extends BusinessActionRequestType {
    type: PartnerCustomerActions.CreatePartnerCustomerInit;
    customer: NewPartnerCustomerRequest;
}

export const createPartnerCustomer = (customer: NewPartnerCustomerRequest): CreatePartnerCustomer => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.CreatePartnerCustomer,
    type: PartnerCustomerActions.CreatePartnerCustomerInit,
    customer,
});

export interface UpdatePartnerCustomer extends BusinessActionRequestType {
    type: PartnerCustomerActions.UpdatePartnerCustomerInit;
    customer: UpdatePartnerCustomerRequest;
}

export const updatePartnerCustomer = (customer: UpdatePartnerCustomerRequest): UpdatePartnerCustomer => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdatePartnerCustomer,
    type: PartnerCustomerActions.UpdatePartnerCustomerInit,
    customer,
});

export interface CreatePartnerCustomerSuccess {
    type: PartnerCustomerActions.CreatePartnerCustomerSuccess;
    customer: PartnerCustomerDetails;
}

export interface UpdatePartnerCustomerSuccess {
    type: PartnerCustomerActions.UpdatePartnerCustomerSuccess;
}

export const createPartnerCustomerSuccess = (customer: PartnerCustomerDetails): CreatePartnerCustomerSuccess => ({
    type: PartnerCustomerActions.CreatePartnerCustomerSuccess,
    customer,
});
export const updatePartnerCustomerSuccess = (): UpdatePartnerCustomerSuccess => ({
    type: PartnerCustomerActions.UpdatePartnerCustomerSuccess,
});

export type PartnerCustomerAction = CreatePartnerCustomer | UpdatePartnerCustomer;
export type PartnerCustomerReducerActions = CreatePartnerCustomerSuccess | SelectGroup;
