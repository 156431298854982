import React from 'react';

const RenewIcon = (): React.ReactElement => (
    <svg
        className="device-svg-icons"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43 3H5C3.89543 3 3 3.89543 3 5V43C3 44.1046 3.89543 45 5 45H43C44.1046 45 45 44.1046 45 43V5C45 3.89543 44.1046 3 43 3ZM5 1C2.79086 1 1 2.79086 1 5V43C1 45.2091 2.79086 47 5 47H43C45.2091 47 47 45.2091 47 43V5C47 2.79086 45.2091 1 43 1H5Z"
            fill="#21282D"
        />
        <path
            d="M39.4551 27.9941L38.6348 24.9766C38.5957 24.8281 38.5488 24.6484 38.4941 24.4375C38.4395 24.2266 38.3848 24.0098 38.3301 23.7871C38.2754 23.5605 38.2246 23.3516 38.1777 23.1602C38.1348 22.9688 38.1016 22.8203 38.0781 22.7148H38.0312C38.0117 22.8203 37.9805 22.9688 37.9375 23.1602C37.8945 23.3516 37.8457 23.5605 37.791 23.7871C37.7363 24.0137 37.6816 24.2344 37.627 24.4492C37.5762 24.6641 37.5293 24.8477 37.4863 25L36.625 27.9941H35.1133L33.2969 21.5078H34.6914L35.5352 24.7305C35.5898 24.9492 35.6426 25.1855 35.6934 25.4395C35.748 25.6934 35.7949 25.9355 35.834 26.166C35.873 26.3965 35.9023 26.5859 35.9219 26.7344H35.9688C35.9844 26.6289 36.0059 26.4961 36.0332 26.3359C36.0605 26.1719 36.0918 26.002 36.127 25.8262C36.1621 25.6465 36.1973 25.4785 36.2324 25.3223C36.2676 25.1621 36.3008 25.0332 36.332 24.9355L37.3281 21.5078H38.834L39.7891 24.9355C39.832 25.084 39.8789 25.2715 39.9297 25.498C39.9805 25.7207 40.0254 25.9453 40.0645 26.1719C40.1074 26.3945 40.1348 26.5801 40.1465 26.7285H40.1934C40.209 26.5957 40.2363 26.416 40.2754 26.1895C40.3184 25.959 40.3672 25.7148 40.4219 25.457C40.4766 25.1953 40.5332 24.9531 40.5918 24.7305L41.4531 21.5078H42.8242L40.9961 27.9941H39.4551Z"
            fill="#21282D"
        />
        <path
            d="M29.8223 21.3906C30.4004 21.3906 30.8965 21.5098 31.3105 21.748C31.7246 21.9863 32.043 22.3242 32.2656 22.7617C32.4883 23.1992 32.5996 23.7227 32.5996 24.332V25.0703H28.2695C28.2852 25.6992 28.4531 26.1836 28.7734 26.5234C29.0977 26.8633 29.5508 27.0332 30.1328 27.0332C30.5469 27.0332 30.918 26.9941 31.2461 26.916C31.5781 26.834 31.9199 26.7148 32.2715 26.5586V27.6777C31.9473 27.8301 31.6172 27.9414 31.2812 28.0117C30.9453 28.082 30.543 28.1172 30.0742 28.1172C29.4375 28.1172 28.877 27.9941 28.3926 27.748C27.9121 27.498 27.5352 27.127 27.2617 26.6348C26.9922 26.1426 26.8574 25.5312 26.8574 24.8008C26.8574 24.0742 26.9805 23.457 27.2266 22.9492C27.4727 22.4414 27.8184 22.0547 28.2637 21.7891C28.709 21.5234 29.2285 21.3906 29.8223 21.3906ZM29.8223 22.4277C29.3887 22.4277 29.0371 22.5684 28.7676 22.8496C28.502 23.1309 28.3457 23.543 28.2988 24.0859H31.252C31.248 23.7617 31.1934 23.4746 31.0879 23.2246C30.9863 22.9746 30.8301 22.7793 30.6191 22.6387C30.4121 22.498 30.1465 22.4277 29.8223 22.4277Z"
            fill="#21282D"
        />
        <path
            d="M22.9902 21.3906C23.7207 21.3906 24.2891 21.5801 24.6953 21.959C25.1055 22.334 25.3105 22.9375 25.3105 23.7695V28H23.9336V24.0273C23.9336 23.5234 23.8301 23.1465 23.623 22.8965C23.416 22.6426 23.0957 22.5156 22.6621 22.5156C22.0332 22.5156 21.5957 22.709 21.3496 23.0957C21.1074 23.4824 20.9863 24.043 20.9863 24.7773V28H19.6094V21.5137H20.6816L20.875 22.3926H20.9512C21.0918 22.166 21.2656 21.9805 21.4727 21.8359C21.6836 21.6875 21.918 21.5762 22.1758 21.502C22.4375 21.4277 22.709 21.3906 22.9902 21.3906Z"
            fill="#21282D"
        />
        <path
            d="M15.2793 21.3906C15.8574 21.3906 16.3535 21.5098 16.7676 21.748C17.1816 21.9863 17.5 22.3242 17.7227 22.7617C17.9453 23.1992 18.0566 23.7227 18.0566 24.332V25.0703H13.7266C13.7422 25.6992 13.9102 26.1836 14.2305 26.5234C14.5547 26.8633 15.0078 27.0332 15.5898 27.0332C16.0039 27.0332 16.375 26.9941 16.7031 26.916C17.0352 26.834 17.377 26.7148 17.7285 26.5586V27.6777C17.4043 27.8301 17.0742 27.9414 16.7383 28.0117C16.4023 28.082 16 28.1172 15.5312 28.1172C14.8945 28.1172 14.334 27.9941 13.8496 27.748C13.3691 27.498 12.9922 27.127 12.7188 26.6348C12.4492 26.1426 12.3145 25.5312 12.3145 24.8008C12.3145 24.0742 12.4375 23.457 12.6836 22.9492C12.9297 22.4414 13.2754 22.0547 13.7207 21.7891C14.166 21.5234 14.6855 21.3906 15.2793 21.3906ZM15.2793 22.4277C14.8457 22.4277 14.4941 22.5684 14.2246 22.8496C13.959 23.1309 13.8027 23.543 13.7559 24.0859H16.709C16.7051 23.7617 16.6504 23.4746 16.5449 23.2246C16.4434 22.9746 16.2871 22.7793 16.0762 22.6387C15.8691 22.498 15.6035 22.4277 15.2793 22.4277Z"
            fill="#21282D"
        />
        <path
            d="M7.58008 19.4336C8.31836 19.4336 8.92773 19.5234 9.4082 19.7031C9.89258 19.8828 10.252 20.1562 10.4863 20.5234C10.7246 20.8906 10.8438 21.3574 10.8438 21.9238C10.8438 22.3457 10.7656 22.7051 10.6094 23.002C10.4531 23.2988 10.248 23.5449 9.99414 23.7402C9.74023 23.9355 9.46875 24.0918 9.17969 24.209L11.6113 28H10.0234L7.95508 24.5664H6.57812V28H5.17188V19.4336H7.58008ZM7.48633 20.6055H6.57812V23.4062H7.55078C8.20312 23.4062 8.67578 23.2852 8.96875 23.043C9.26562 22.8008 9.41406 22.4434 9.41406 21.9707C9.41406 21.4746 9.25586 21.123 8.93945 20.916C8.62695 20.709 8.14258 20.6055 7.48633 20.6055Z"
            fill="#21282D"
        />
    </svg>
);

export default RenewIcon;
