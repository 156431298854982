import { useState, useContext, useEffect } from 'react';
import { SortDirection } from '@mui/material';
import { orderBy } from 'lodash';
import { SearchContext } from '../../search/Search';
import useSearch from '../../search/useSearch';
import usePagination, { PaginationMetadata } from './usePagination';

type SortDataType<T> = (direction: SortDirection, accessor?: string) => T[];

type UseTableDataReturnType<T> = {
    sortData: SortDataType<T>;
    tableData: T[];
    handlePaginationChange: (page: number) => void;
    paginatedData: T[];
    paginationMetadata: PaginationMetadata;
    selectedPage: number;
};

type SortSettingsType = {
    accessor?: string;
    direction: SortDirection;
};

const useTableData = <T extends Record<string, unknown>>(data: T[], pagination?: number): UseTableDataReturnType<T> => {
    const { search, searchValue } = useContext(SearchContext);
    const [tableData, setTableData] = useState<T[]>(data); // Data that is both sorted and searched
    const [sortSettings, setSortSettings] = useState<SortSettingsType | null>(null);

    const { paginatedData, handlePaginationChange, paginationMetadata, selectedPage } = usePagination(
        tableData,
        pagination
    );

    useEffect(() => {
        setTableData(data);
    }, [data]);

    const innerSortData =
        (dataToSort: T[]) =>
        (direction: SortDirection, accessor?: string): T[] => {
            // If sorting order is present (asc or desc) we can just order narrowed (searched) dataset
            if (direction) {
                return orderBy(dataToSort, accessor, direction);
            }
            // In order to get the original sorting order we must re-search the original dataset manually
            // If there is no search applied by user then we will get full, original data
            return search(searchValue)(data);
        };

    // Run this callback function any time user changes search term
    useSearch(searchData => {
        const searchedData = searchData(data);
        // Was the data you searched previously sorted?
        // If so, apply sorting function with the same parameters as before
        if (sortSettings?.accessor) {
            const searchDataSorted = innerSortData(searchedData)(sortSettings.direction, sortSettings.accessor);
            setTableData(searchDataSorted);
        }
        // Otherwise set narrowed data in the original sorting order
        else {
            setTableData(searchedData);
        }
    });

    // Header clicked - manually sort data
    const sortData: SortDataType<T> = (direction, accessor): T[] => {
        const sortedData = innerSortData(tableData)(direction, accessor);
        setSortSettings({ accessor, direction });
        setTableData(sortedData);
        return sortedData;
    };

    return { sortData, tableData, handlePaginationChange, paginatedData, paginationMetadata, selectedPage };
};

export default useTableData;
