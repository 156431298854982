import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
    ValidateLocationRfRegion,
    validateLocationRfRegion,
    ValidateLocationRfRegionClearState,
    validateLocationRfRegionClearState,
} from '../../actions/LocationActions';
import Input from '../../components/input/Input';
import SearchAddress from '../../components/maps/SearchAddress';
import spinner from '../../img/spinner';
import { LocationAddressType, LocationRfRegionResponse } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import { CommonRequestType } from '../../reducers/requestReducer';
import RegionChangeResponseBox from './RegionChangeResponseBox';

interface ActionProps {
    clearRfRegionState: () => void;
    validateRfRegion: (countryCode: string, locationId?: string) => void;
}

interface StateProps {
    rfRegionValidationLoading: boolean;
}

export interface PassedProps {
    validateLocationName: boolean;
    locationNameIsValid: boolean;
    locationName: string;
    updateLocationName: (locationName: string) => void;
    setAddress: (locationData: LocationAddressType) => void;
    address: string;
    displayAddressValidation: boolean;
    countryCode?: string;
    rfRegionResponse?: LocationRfRegionResponse;
}

type Props = ActionProps & PassedProps & StateProps;

export const AddNewLocation = (props: Props): React.ReactElement => {
    const {
        validateLocationName,
        locationNameIsValid,
        locationName,
        displayAddressValidation,
        setAddress,
        address,
        updateLocationName,
        rfRegionResponse,
        rfRegionValidationLoading,
        clearRfRegionState,
        countryCode,
        validateRfRegion,
    } = props;
    const { t: txt } = useTranslation();

    const onChangeLocationName = (e: React.SyntheticEvent<HTMLInputElement>): void => {
        const name = e.currentTarget.value;
        updateLocationName(name);
    };

    useEffect((): (() => void) => {
        return (): void => clearRfRegionState();
    }, []);

    const onUpdateAddress = (locationData: LocationAddressType): void => {
        if (locationData.countryCode) {
            setAddress(locationData);
        }
        if (locationData.countryCode !== countryCode) {
            validateRfRegion(locationData.countryCode || '');
        } else clearRfRegionState();
    };

    return (
        <div className="form form--medium-padding">
            <Input
                label="LocationName"
                type="text"
                id="location_name"
                maxLength={50}
                required
                hint="NewLocationHint"
                currentValue={locationName}
                validate={validateLocationName}
                isValid={locationNameIsValid}
                onChange={onChangeLocationName}
                markedMandatory
                testId="location-name"
            />
            <div className="location-pick-map__container input-container">
                <SearchAddress
                    onPlaceChanged={onUpdateAddress}
                    address={address}
                    mandatory
                    displayValidation={displayAddressValidation}
                    isValid={!!address && address.length > 0}
                    infoText="LocationRfRegion.InfoText"
                />
            </div>
            {rfRegionValidationLoading ? (
                <div className="centered">{spinner}</div>
            ) : (
                <RegionChangeResponseBox
                    rfRegionResponse={rfRegionResponse}
                    onFailText="LocationRfRegion.InvalidChange"
                    onSuccessText="LocationRfRegion.ValidRegion"
                    onFailSubText={txt('LocationRfRegion.InvalidChangeDescription')}
                    onSuccessSubText={txt('LocationRfRegion.ValidRegionDescription', {
                        region: rfRegionResponse ? txt(`${rfRegionResponse.newRfRegion}`) : '',
                    })}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        commonRequests: {
            [CommonRequestType.ValidateLocationRfRegion]: { loading: rfRegionValidationLoading },
        },
    } = state;
    return {
        rfRegionValidationLoading,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    clearRfRegionState: (): ValidateLocationRfRegionClearState => dispatch(validateLocationRfRegionClearState()),
    validateRfRegion: (countryCode: string, locationId?: string): ValidateLocationRfRegion =>
        dispatch(validateLocationRfRegion(countryCode, locationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewLocation);
