/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

type Props = {
    chartData: number[][];
    weekDayArray: string[];
    plotBands: { color: string; from: number; to: number }[];
};
const midDayTickPositions: number[] = [11, 35, 59, 83, 107, 131, 155];
const openingHoursComparisonConfig = ({ chartData, weekDayArray, plotBands }: Props) => ({
    credits: {
        enabled: false,
    },
    title: {
        text: '',
    },
    legend: {
        enabled: false,
    },
    chart: {
        type: 'column',
        height: 155,
        marginBottom: 25,
        style: {
            fontFamily: 'OpenSans-Regular',
        },
    },
    tooltip: {
        enabled: false,
    },
    xAxis: {
        tickLength: 0,
        tickInterval: 24,
        min: 0,
        max: 167,
        plotBands,
        tickPositions: midDayTickPositions,
        labels: {
            formatter(this: { value: number }) {
                const { value } = this;
                const indexOfDay = midDayTickPositions.findIndex(tick => tick === value);
                return weekDayArray[indexOfDay] || '';
            },
        },
    },
    yAxis: {
        name: 'DevicesWithPresence',
        title: {
            enabled: false,
        },
        labels: {
            enabled: false,
        },
    },
    series: [{ data: chartData, showInLegend: false }],
});

export default openingHoursComparisonConfig;

export type OpeningHoursComparisonConfig = ReturnType<typeof openingHoursComparisonConfig>;
