import React, { useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import {
    BUILDING_PRESENCE_INSIGHT_EXPAND_GRAPH,
    BUILDING_PRESENCE_INSIGHT_SELECT_FROM_CHART,
} from 'commons/src/analytics/AnalyticsEvents';
import FilterButton from 'commons/src/components/buttons/FilterButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { dateFormats } from 'commons/src/constants';
import { TimePeriod } from 'commons/src/models/commonEnums';
import { DayUsageHours, DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { PresenceByDevice } from '../../../models/buildingModels';
import { PresenceViewType } from '../../../models/commonEnums';
import { Store } from '../../../reducers';
import humanizedTimeString from '../../buildings/buildingInsight/buildingInsightCommonFunctions';
import PresenceSelectedDevice from './PresenceSelectedDevice';
import styles from './UsageByDevice.module.scss';
import usageOverTimeDayConfig from './UsageOverTimeDayConfig';

type ParentProps = {
    presenceByDevice: PresenceByDevice[];
    dateFormat: keyof typeof dateFormats;
    selectedPeriod: {
        toDate: string;
        fromDate: string;
        name: TimePeriod;
    };
    usageHours: {
        [day: string]: DayUsageHours;
    };
    withOpeningHours: boolean;
    timeZone?: string;
};
type StateProps = {
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
};

type Props = StateProps & ParentProps;

const UsageByDeviceView = ({
    presenceByDevice,
    selectedPeriod,
    usageHours,
    devicesWithKeyInfo,
    withOpeningHours,
    dateFormat,
    timeZone,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [selectedDevice, setSelectedDevice] = useState('');
    const [graphExpanded, setGraphExpanded] = useState(false);
    const { fromDate, toDate } = selectedPeriod;
    const [sortByLeastUsed, setSortByLeastUsed] = useState(false);
    const [isChangingHeightOfGraph, setIsChangingHeightOfGraph] = useState(false);

    const numberOfDays = moment(toDate).endOf('day').diff(moment(fromDate), 'days') + 1;
    const totalTimeUsage = (): number => {
        if (!withOpeningHours) {
            return numberOfDays * 24 * 60;
        }
        let totalTimeInUse = 0;
        let date = fromDate;
        while (date !== toDate) {
            const weekDayString = moment(date).locale('en').format('dddd').toLowerCase();
            const usageHourForDay = usageHours[weekDayString];
            if (usageHourForDay) {
                const { closed, from, to } = usageHourForDay;
                if (!closed && from && to) {
                    const hoursInUseThisDay = moment(to, 'HH:mm').diff(moment(from, 'HH:mm'), 'minutes');
                    totalTimeInUse += hoursInUseThisDay;
                }
            }
            date = moment(date).add(1, 'day').format('YYYY-MM-DD');
        }
        return totalTimeInUse;
    };

    const expandDetails = (): void => {
        setIsChangingHeightOfGraph(true);
        setTimeout(() => {
            setIsChangingHeightOfGraph(false);
            setGraphExpanded(!graphExpanded);
        }, 50); // This is needed to make sure the graph renders correctly on expand, as the graph renders funny if you scroll before expanding
        analyticsLogger(BUILDING_PRESENCE_INSIGHT_EXPAND_GRAPH, {
            payload: { detailsExpanded: !graphExpanded, chart: 'usageByDevice' },
        });
    };

    const onDeviceClick = (clickData: Highcharts.PointClickEventObject): void => {
        const selectedSerialNumber = clickData.point.category;
        if (selectedDevice === selectedSerialNumber) {
            setSelectedDevice('');
        } else {
            analyticsLogger(BUILDING_PRESENCE_INSIGHT_SELECT_FROM_CHART, { chart: 'usageByDevice' });
            setSelectedDevice(selectedSerialNumber as string);
        }
    };

    const sortedDevices = presenceByDevice.sort((device1, device2) => {
        if (sortByLeastUsed) return device1.minutesUsed - device2.minutesUsed;
        return device2.minutesUsed - device1.minutesUsed;
    });
    const totalMinutesForTimePeriod = totalTimeUsage();
    const detailsData = sortedDevices.map(device => device.minutesUsed);
    const unusedTimeData = sortedDevices.map(device => totalMinutesForTimePeriod - device.minutesUsed);
    const categories = sortedDevices.map(device => device.serialNumber);
    const selectedDevicesWithKeyInfo = sortedDevices.map(device => devicesWithKeyInfo[device.serialNumber]);
    const numberOfFullHours = Math.floor(totalMinutesForTimePeriod / 60);
    const heightOfAllDevices = detailsData.length * 40 + 76;
    const expandedChartHeight = heightOfAllDevices > 400 ? 400 : heightOfAllDevices;
    const chartHeight = graphExpanded && expandedChartHeight > 230 ? expandedChartHeight : 230;
    const maxDevicesInChart = heightOfAllDevices > 400 ? 7 : undefined;

    const config = usageOverTimeDayConfig({
        chartData: detailsData,
        unusedTimeData,
        categories,
        selectedDevicesWithKeyInfo,
        onClick: onDeviceClick,
        chartHeight,
        txt,
        maxDevicesBeforeScroll: graphExpanded ? maxDevicesInChart : 3,
        tickInterval: Math.ceil(numberOfFullHours / 7) * 60,
        maxYAxis: totalMinutesForTimePeriod,
        allowGraphClick: true,
        graphId: 'usageByDevice',
    });

    const selectedDeviceData =
        selectedDevice && presenceByDevice.find(device => device.serialNumber === selectedDevice);
    const timeInUse = selectedDeviceData && humanizedTimeString(selectedDeviceData.minutesUsed, true);

    const changeSort = (): void => {
        setSortByLeastUsed(!sortByLeastUsed);
    };

    return (
        <div className={styles.card}>
            <h4 className={styles.header}>{txt('PresenceInsight.UsageByDevice')}</h4>
            <div className={styles.cardContent}>
                <div className={styles.graph}>
                    <div className={styles.sortButton}>
                        <FilterButton
                            title={sortByLeastUsed ? 'PresenceInsight.ViewMostUsed' : 'PresenceInsight.ViewLeastUsed'}
                            isSelected={false}
                            onClick={changeSort}
                            icon={<MaterialIcon name="import_export" />}
                        />
                    </div>
                    {!isChangingHeightOfGraph && <HighchartsReact highcharts={Highcharts} options={config} />}
                    {detailsData.length > 4 && (
                        <button className={styles.expandButton} type="button" onClick={expandDetails}>
                            <MaterialIcon name={graphExpanded ? 'expand_less' : 'expand_more'} />
                        </button>
                    )}
                </div>
                <PresenceSelectedDevice
                    timeZone={timeZone}
                    selectedDevice={selectedDevice}
                    devicesWithKeyInfo={devicesWithKeyInfo}
                    timeInUse={timeInUse}
                    selectedPeriod={selectedPeriod}
                    dateFormat={dateFormat}
                    viewType={PresenceViewType.DeviceView}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { devicesWithKeyInfo },
    } = state;
    return { devicesWithKeyInfo };
};

export default connect(mapStateToProps)(UsageByDeviceView);
