import React, { useState, useRef } from 'react';
import { ClickAwayListener, Grow, MenuItem, Paper, MenuList, Typography } from '@mui/material';
import Popper from '@mui/material/Popper';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { mapDeviceType } from '../../commonFunctions';
import { AnyDeviceType } from '../../models/commonTypeScript';
import SearchComponent from '../input/SearchComponent';
import styles from './SearchDropdown.module.scss';

export type Props = {
    listOfElements: { id: string; name: string; deviceType?: string }[];
    onSelect: (element: { id: string; name: string }) => void;
    icon?: React.ReactElement;
};

const SearchDropdown = (props: Props): React.ReactElement => {
    const { listOfElements, onSelect, icon } = props;
    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const { t: txt } = useTranslation();
    const anchorRef = useRef<HTMLInputElement>(null);

    const handleClose = (event: Event | React.SyntheticEvent): void => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handleListKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef?.current?.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const onSearchUpdate = (text: string): void => {
        setOpen(true);
        setSearchText(text);
    };

    const onSelectElement = (element: { id: string; name: string }): void => {
        onSelect(element);
        setOpen(false);
    };

    const elementsMatchingSearch = listOfElements
        .filter(option => option.name.toLowerCase().includes(searchText.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name));

    return (
        <div className="form__attr">
            <SearchComponent
                testId="search"
                onChange={onSearchUpdate}
                onFocus={(): void => setOpen(true)}
                parentRef={anchorRef}
                expandable
            />
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                className={styles.wrapper}
            >
                {({ TransitionProps, placement }): React.ReactElement => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper className={styles.paper}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={false}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {elementsMatchingSearch.length > 0 ? (
                                        elementsMatchingSearch.map(element => (
                                            <MenuItem
                                                className={classNames('device-list__item', {
                                                    'device-list__item--spaced': !!element.deviceType,
                                                })}
                                                onClick={(): void => onSelectElement(element)}
                                                key={element.id}
                                                data-testid={`select-${element.id}`}
                                            >
                                                {icon}
                                                <Typography variant="inherit" noWrap>
                                                    {element.name}
                                                </Typography>
                                                {element.deviceType && (
                                                    <div className="tile-header__info--chip device-list__item__chip">
                                                        {txt(mapDeviceType(element.deviceType as AnyDeviceType))}
                                                    </div>
                                                )}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <div className="device-list__item" key="noOptions">
                                            {txt('NoAvailableOptions')}
                                        </div>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default SearchDropdown;
