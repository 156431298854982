import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { PartnerOfflineDevicesResponse } from '../../../actions/partnerActions';
import { EmptyTable } from '../../status/buildingStatus/components/common';
import ExpandedCustomerRow from './ExpandedCustomerRow';
import styles from './PartnerMobileTable.module.scss';
import RedirectToDashboardButton from './RedirectToDashboardButton';

type Props = {
    data: PartnerOfflineDevicesResponse[];
    numberOfDevicesInOrg: number;
};

export default function PartnerMobileTable({ data, numberOfDevicesInOrg }: Props): React.ReactElement {
    const { t: txt } = useTranslation();
    const [openRows, setOpenRows] = useState<string[]>([]);

    const onRowClick = (userGroupId: string): void => {
        if (openRows.includes(userGroupId)) {
            setOpenRows(openRows.filter(id => id !== userGroupId));
        } else {
            setOpenRows([...openRows, userGroupId]);
        }
    };

    const offlineCustomers = data.filter(customer => customer.offlineLocations.length > 0);
    const customers = useMemo(
        () =>
            offlineCustomers.sort(
                (a, b) => b.offlineLocations.length / b.totalLocations - a.offlineLocations.length / a.totalLocations
            ),
        [offlineCustomers]
    );
    const emptyTableText = (): string => {
        if (numberOfDevicesInOrg > 0) return 'BuildingStatus.NoOfflineBuildings';
        if (data.length === 0) return 'BuildingStatus.NoCustomers';
        return 'BuildingStatus.NoDevicesInBuildings';
    };

    return (
        <div className={styles.list}>
            {offlineCustomers.map(({ userGroupId, customerName, totalLocations, offlineLocations }) => (
                <div className={styles.itemWrapper} key={userGroupId}>
                    <div className={styles.item}>
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={(): void => onRowClick(userGroupId)}
                            onKeyUp={(e): void => {
                                if (e.key === 'Enter') onRowClick(userGroupId);
                            }}
                            className={styles.expandButton}
                        >
                            <MaterialIcon
                                name={openRows.includes(userGroupId) ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
                            />
                        </div>
                        <div className={styles.itemHeader}>{customerName}</div>
                        <div className={styles.itemOfflineInfo}>
                            <div>
                                <span>{txt('BuildingStatus.OfflineBuildingsHeader')}</span>
                                <span>{txt('BuildingStatus.OfflineHubsHeader')}</span>
                                <span>{txt('BuildingStatus.OfflineSensorDevicesHeader')}</span>
                                <span>
                                    <RedirectToDashboardButton userGroupId={userGroupId} />
                                </span>
                            </div>
                            <div>
                                <span>
                                    {offlineLocations.length}/{totalLocations}
                                </span>
                                <span>
                                    {offlineLocations
                                        .map(location => location.offlineHubs)
                                        .reduce((acc, val) => acc + val, 0)}
                                    /
                                    {offlineLocations.map(location => location.hubs).reduce((acc, val) => acc + val, 0)}
                                </span>
                                <span>
                                    {offlineLocations
                                        .map(location => location.offlineSensorDevices)
                                        .reduce((acc, val) => acc + val, 0)}
                                    /
                                    {offlineLocations
                                        .map(location => location.sensorDevices)
                                        .reduce((acc, val) => acc + val, 0)}
                                </span>
                            </div>
                        </div>
                    </div>
                    {openRows.includes(userGroupId) && <ExpandedCustomerRow offlineLocations={offlineLocations} />}
                </div>
            ))}
            {customers.length === 0 && <EmptyTable emptyTableText={emptyTableText()} />}
        </div>
    );
}
