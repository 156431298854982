import React, { KeyboardEvent, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ModalHeader from 'commons/src/components/modals/ModalHeader';
import { Subscription } from '../../models/subscriptionModels';
import styles from './AddSeatsSubscriptionSelector.module.scss';

export type Props = {
    onClose: () => void;
    isOpen: boolean;
    subscriptions: Subscription[];
};

type RowProps = {
    subscription: Subscription;
};

const RowElement = ({ subscription }: RowProps): React.ReactElement => {
    const { subscriptionNumber, poNumber } = subscription;
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);

    const onNavigate = (): void => {
        navigate(`add-seats/${subscriptionNumber}`);
    };
    const onKeyUp = (e: KeyboardEvent<HTMLDivElement>): void => {
        if (e.key === 'Enter') {
            onNavigate();
        }
    };

    return (
        <div
            tabIndex={0}
            role="button"
            className={styles.row}
            onKeyUp={onKeyUp}
            onClick={onNavigate}
            onMouseOver={(): void => setHover(true)}
            onFocus={(): void => setHover(true)}
            onBlur={(): void => setHover(false)}
            onMouseLeave={(): void => setHover(false)}
        >
            <div className={styles.subscriptionNumberCell}>{subscriptionNumber}</div>
            <div className={styles.poCell}>
                <div className={styles.poNumber}>{poNumber}</div> {hover && <MaterialIcon name="arrow_forward" />}
            </div>
        </div>
    );
};

const AddSubscriptionSelector = ({ onClose, isOpen, subscriptions }: Props): React.ReactElement => {
    return (
        <Modal
            isOpen={isOpen}
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--size-medium"
            overlayClassName="modal modal__overlay"
        >
            <ModalHeader headerText="AddSeats.SelectSubscriptionToAddSeats" onClose={onClose} />
            <div className={styles.content}>
                {subscriptions.map(sub => (
                    <RowElement key={sub.subscriptionNumber} subscription={sub} />
                ))}
            </div>
        </Modal>
    );
};

export default AddSubscriptionSelector;
