import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Dropdown from 'commons/src/components/dropdown/MultipleAttrDropdown';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import { sensorFullNameKey } from 'commons/src/constants';
import { SensorTypes } from 'commons/src/models/commonEnums';

type Props = {
    onSelect: (sensorName: string, value: number) => void;
    interval: {
        sensorType: string;
        options: number[];
        current: number;
    };
    selectedInterval: number;
    disabled: boolean;
    slimWidth?: boolean;
};

const BatterySettingsSelector = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { onSelect, interval, selectedInterval, disabled, slimWidth } = props;
    const changeSelection = ({ id }: { id: string; inputValue: string }): void => {
        onSelect(interval.sensorType, parseInt(id, 10));
    };
    const readableOption = (optionValue: number): string => (optionValue ? `${optionValue / 60} ${txt('min')}` : 'off');
    const intervalOptions = (options: number[]): { id: string; inputValue: string }[] =>
        options &&
        options.map(option => ({
            id: option.toString(),
            inputValue: readableOption(option),
        }));

    let sensorName = sensorFullNameKey(interval.sensorType);
    if (interval.sensorType === SensorTypes.pm25) sensorName = 'DeviceSettings.Pm25And1';
    if (interval.sensorType === SensorTypes.co2) sensorName = 'DeviceSettings.Co2TempHumidity';
    if (interval.sensorType === SensorTypes.pressure) sensorName = 'DeviceSettings.PressureHumidityTemp';

    const dropdownOptions = intervalOptions(interval.options);
    const customHeader = (
        <>
            {![SensorTypes.co2, SensorTypes.pressure].includes(interval.sensorType as SensorTypes) && (
                <div className="device-svg-icons--margin-right">
                    <SensorIcon sensor={interval.sensorType} />
                </div>
            )}
            {txt(sensorName)}
        </>
    );
    return (
        <div
            className={classNames('input-container--large-margin-bottom', {
                'form__field--medium-slim': slimWidth,
                'form__field--standard-width': !slimWidth,
            })}
        >
            <Dropdown
                title=""
                customHeader={customHeader}
                disabled={disabled}
                options={dropdownOptions}
                defaultOption=""
                value={readableOption(selectedInterval)}
                onSelect={changeSelection}
                testAttr={`battery-options-${interval.sensorType.toLowerCase()}`}
                id={`batteryOptions${interval.sensorType}`}
                optionsAlreadySorted
            />
        </div>
    );
};

export default BatterySettingsSelector;
