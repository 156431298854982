import { paths as commonPaths } from 'commons/src/constants';

export type Path = keyof typeof paths;

export const paths = {
    ...commonPaths,
    devices: 'devices',
    devicePage: 'devices/:device?',
    hubPage: 'hub/:hubId',
    changeLocation: 'devices/:device?/change-location',
    radonReport: 'devices/:serialNumber?/radon-report',
    pastSegment: 'devices/:device?/:segment?',
    pastSegmentRadonReport: 'devices/:serialNumber?/:segmentId?/radon-report',
    settings: 'settings',
    addTile: 'add-tile',
    addTileOfType: 'add-tile/:type',
    outdoor: 'outdoor/:location',
    somethingWentWrong: '404',
};

export default paths;
