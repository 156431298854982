import { RatePlansPerPeriod } from '../../../models/subscriptionModels';

const servicePeriodTranslation = (
    servicePeriod: string,
    periodText: string,
    discount: string,
    ratePlans?: RatePlansPerPeriod
): string => {
    const percentage = ratePlans ? ratePlans[servicePeriod]?.discountPercentage : undefined;
    if (percentage) {
        return `${periodText} (${percentage}% ${discount})`;
    }
    return periodText;
};

export default servicePeriodTranslation;
