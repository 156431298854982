/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Highcharts from 'highcharts';
import moment from 'moment';
import { dateFormats, sensorUnits, tooltipBorderColor, colors } from '../../constants';
import { Resolution } from '../../models/commonEnums';
import { ExtraSeriesPeriod, SelectedPeriod } from '../../models/commonTypeScript';

export const dottedLineGraph = (
    chartData: (number | null)[][],
    { startDate, number, period }: SelectedPeriod
): (number | null)[][] => {
    if (!chartData || chartData.length === 0) {
        return [];
    }
    const nowTime = moment().valueOf();
    const minPoint = startDate ? moment(startDate).valueOf() : moment().subtract(number, period).valueOf();

    const dottedLineChart = chartData.reduce(
        (nullPoints: (number | null)[][], dataPoint: (number | null)[], index: number) => {
            if (dataPoint[1] === null) {
                if (nullPoints.length > 0) {
                    const nullPointXValue = chartData[index - 1][0] - 1;
                    if (nullPointXValue > chartData[index - 2][0]) {
                        nullPoints.push([nullPointXValue, null]);
                    }
                }
                nullPoints.push(chartData[index - 1], chartData[index + 1]);
            }
            return nullPoints;
        },
        []
    );

    if (dottedLineChart.length > 0) {
        // pushing null point before end dots
        const nullPointXValue = chartData[chartData.length - 1][0] - 1;
        if (nullPointXValue > chartData[chartData.length - 2][0]) {
            dottedLineChart.push([nullPointXValue, null]);
        }
    }
    // adding dots to end of graph
    dottedLineChart.push(chartData[chartData.length - 1], [nowTime, chartData[chartData.length - 1][1]]);
    if (minPoint < chartData[0][0]) {
        // adding dots to beginning of graph
        if (chartData.length > 1 && chartData[0][0] !== dottedLineChart[0][0]) {
            dottedLineChart.unshift([chartData[0][0] + 1, null]);
        }
        dottedLineChart.unshift([minPoint, chartData[0][1]], chartData[0]);
    }
    return dottedLineChart;
};

export const chartOnMouseOver = (e: MouseEvent | TouchEvent, mouseIsOut: boolean): void =>
    Highcharts.charts.forEach(chart => {
        const event = chart && chart.pointer.normalize(e);
        const point = chart && chart.series.length > 1 && chart.series[1].searchPoint(event, true);

        if (point && chart) {
            if (mouseIsOut) {
                chart.tooltip.hide(point);
            } else {
                point.highlight(event);
            }
        }
    });

export const redrawChart = (): void => Highcharts.charts?.forEach(chart => chart && chart.reflow());

export const zoomOut = (): void => Highcharts.charts?.forEach(chart => chart && chart.zoomOut());

Highcharts.Point.prototype.highlight = function highlight(): void {
    this.onMouseOver();
};

/**
 * Override the reset function, we don't need to hide the tooltips
 */
Highcharts.Pointer.prototype.reset = (): undefined => undefined;

function syncExtremes(e): void {
    if (e.trigger === 'syncExtremes') {
        return;
    }
    Highcharts.each(Highcharts.charts, chart => {
        if (!chart || chart === this.chart) return;
        if (chart.xAxis[0].setExtremes) {
            chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, { trigger: 'syncExtremes' });
        }
    });
}

export const setXAxisMinValue = ({ startDate, number, period }: SelectedPeriod): number =>
    startDate ? moment(startDate).valueOf() : moment().subtract(number, period).valueOf();

export const setXAxisLabelFormat = (
    { label, name }: { label?: string; name: string },
    dateFormat: string,
    width?: number
): string => {
    const displayWeekDay = (name === 'week' || label === 'week') && (!width || width > 1);
    return displayWeekDay ? dateFormats[dateFormat].axisDateWithDay : dateFormats[dateFormat].axisDate[label || name];
};

function getExtras(extraSeries: ExtraSeriesPeriod | undefined, selectedInterval: SelectedPeriod) {
    return (extraSeries || {})[selectedInterval.name] || {};
}

export const dateTime = (timestamp: number, dateFormat: string, resolution?: Resolution): string => {
    if (!resolution) {
        return Highcharts.dateFormat(dateFormats[dateFormat].time, timestamp);
    }
    if (resolution === Resolution.hour || resolution === Resolution.fourHours) {
        const toTime = resolution === Resolution.hour ? timestamp + 3600000 : timestamp + 4 * 3600000;
        return `${Highcharts.dateFormat(dateFormats[dateFormat].hourTime, timestamp)} -
                ${Highcharts.dateFormat(dateFormats[dateFormat].hourTime, toTime)}<br/>`;
    }
    return '';
};
export const dayDate = (timestamp: number, dateFormat: string, resolution?: string): string => {
    if (resolution === Resolution.threeDays) {
        const toDate = timestamp + 3 * 24 * 3600000;
        return `${Highcharts.dateFormat(dateFormats[dateFormat].date, timestamp)} - <br />
                ${Highcharts.dateFormat(dateFormats[dateFormat].date, toDate)}`;
    }
    return `${Highcharts.dateFormat(dateFormats[dateFormat].weekDay, timestamp)}
                ${Highcharts.dateFormat(dateFormats[dateFormat].date, timestamp)}`;
};

export const yVal = (y: number | undefined, unit: keyof typeof sensorUnits): string => {
    const yValue = Math.round(y * 10) / 10;
    return yValue % 1 === 0 || unit === sensorUnits.bq
        ? Highcharts.numberFormat(yValue, 0)
        : Highcharts.numberFormat(yValue, 1);
};

const noDataGraph = 'noDataGraph';

type ConfigProps = {
    chartData: number[][];
    thresholds: { value?: number; color: string }[];
    unit: string;
    chartHeight: number;
    selectedInterval: SelectedPeriod;
    minValues: { [p: string]: number };
    averageValues: { [p: string]: number };
    dateFormat: string;
    width: number | undefined;
    animate: boolean;
    displayResetZoom: (() => void) | undefined;
    mainGraph?: string;
    graphType?: string;
    extraSeries?: ExtraSeriesPeriod;
    areaRangeData?: number[][];
};

export default ({
    chartData,
    thresholds,
    unit,
    chartHeight,
    selectedInterval,
    minValues,
    averageValues,
    dateFormat,
    width,
    animate,
    displayResetZoom,
    mainGraph = 'mainGraph',
    graphType,
    extraSeries,
    areaRangeData,
}: ConfigProps) => ({
    credits: {
        enabled: false,
    },
    title: {
        text: '',
    },
    chart: {
        height: chartHeight,
        type: 'areaspline',
        spacingBottom: 5,
        style: {
            fontFamily: 'OpenSans-Regular',
        },
        zoomType: !selectedInterval || selectedInterval.resolution === undefined ? 'x' : 'none',
        resetZoomButton: {
            theme: {
                display: 'none',
            },
        },
        events: {
            selection(): void {
                if (displayResetZoom) {
                    displayResetZoom();
                }
            },
        },
    },
    tooltip: {
        borderColor: tooltipBorderColor,
        style: {
            color: colors.white,
        },
        enabled: true,
        hideDelay: 0,
        shared: true,
        formatter(this: Highcharts.TooltipFormatterContextObject): string {
            const { resolution } = selectedInterval;
            const { x, points } = this;
            const renderSeries = ({
                series: { name },
                point: { y, options },
            }: {
                series: Highcharts.Series;
                point: Highcharts.Point;
            }): string => {
                if (name === noDataGraph) return '';
                if (name === mainGraph) return `${yVal(y, unit)} ${unit}`;
                if (name === 'AreaRangeGraph') {
                    return options.high && options.low
                        ? `Min: ${yVal(options.low, unit)}, Max: ${yVal(options.high, unit)}`
                        : '';
                }
                return `${yVal(y, unit)} ${unit}${name ? ` (${name})` : ''}`;
            };
            const seriesValues = points?.map(renderSeries) || [];
            return [
                dayDate(x as number, dateFormat, resolution),
                dateTime(x as number, dateFormat, resolution),
                ...seriesValues,
            ].join('<br/>');
        },
    },
    xAxis: {
        lineColor: 'transparent',
        tickLength: 0,
        tickPixelInterval: 130,
        min: setXAxisMinValue(selectedInterval),
        max: selectedInterval.endDate ? moment(selectedInterval.endDate).valueOf() : moment().valueOf(),
        type: 'datetime',
        gridLineWidth: 1,
        margin: 0,
        labels: {
            formatter(this: Highcharts.AxisLabelsFormatterContextObject): string {
                const { value } = this;
                return Highcharts.dateFormat(setXAxisLabelFormat(selectedInterval, dateFormat, width), value);
            },
        },
        events: {
            setExtremes: syncExtremes,
        },
    },
    yAxis: {
        min: minValues[selectedInterval.name],
        gridLineWidth: 0,
        tickPixelInterval: 40,
        title: {
            text: null,
        },
        plotLines: [
            {
                zIndex: 5,
                color: 'grey',
                dashStyle: 'Dash',
                value: averageValues[selectedInterval.name],
                width: 1,
            },
        ],
        labels: {
            formatter(): string {
                const { value } = this;
                const yValue = Math.round(value * 10) / 10;
                return yValue % 1 === 0 || unit === sensorUnits.bq
                    ? Highcharts.numberFormat(yValue, 0)
                    : Highcharts.numberFormat(yValue, 1);
            },
        },
    },
    series: [
        {
            name: noDataGraph,
            type: 'spline',
            showInLegend: false,
            data: dottedLineGraph(chartData, selectedInterval),
            connectNulls: false,
            color: colors.greyMist,
            dashStyle: 'shortDot',
            lineWidth: 2,
            marker: {
                enabled: false,
                states: {
                    hover: {
                        enabled: false,
                    },
                },
            },
        },
        {
            type: graphType || 'areaspline',
            lineWidth: 2.3,
            name: mainGraph,
            fillColor: {
                linearGradient: [0, 0, 0, 300],
                stops: [
                    [0, 'rgba(242,245,245,1)'],
                    [1, 'rgba(242,245,245,0.2)'],
                ],
            },
            zones: thresholds,
            showInLegend: false,
            data: chartData,
            connectNulls: false,
            marker: {
                enabled: false,
                symbol: 'circle',
            },
            states: {
                hover: { lineWidthPlus: 0 },
            },
        },
        ...Object.keys(getExtras(extraSeries, selectedInterval)).map(s => ({
            type: graphType || 'areaspline',
            lineWidth: 2.3,
            name: s,
            fillColor: {
                linearGradient: [0, 0, 0, 300],
                stops: [
                    [0, 'rgba(242,245,245,1)'],
                    [1, 'rgba(242,245,245,0.2)'],
                ],
            },
            zones: thresholds,
            showInLegend: false,
            data: getExtras(extraSeries, selectedInterval)[s],
            connectNulls: false,
            marker: {
                enabled: false,
                symbol: 'circle',
            },
            states: {
                hover: { lineWidthPlus: 0 },
            },
        })),
        {
            type: 'arearange',
            lineWidth: 0,
            name: 'AreaRangeGraph',
            fillColor: 'rgba(38, 160, 247, 0.3)',
            showInLegend: false,
            data: areaRangeData,
            connectNulls: false,
            marker: {
                enabled: false,
            },
            states: {
                hover: { enabled: false },
            },
        },
    ],
    plotOptions: {
        series: {
            states: {
                hover: { lineWidthPlus: 0 },
            },
            animation: animate ? { duration: 1200 } : false,
        },
    },
});
