import {
    OrganizationInvitesActionType,
    OrganizationInvitesReducerActions,
} from '../actions/OrganizationInvitesActions';
import { FETCH_USER_INFO_SUCCESS, SettingsReducerActions } from '../actions/SettingsActions';
import { organizationInviteResponses } from '../constants';
import { Group, OrganizationInviteType } from '../models/commonTypeScript';

export type OrganizationInvites = {
    invites: OrganizationInviteType[];
    inviteModalOpen: boolean;
    acceptInviteLoading: boolean;
    invitesFetched: boolean;
    acceptedInviteId?: string;
    groups: Group[];
};
const initialState: OrganizationInvites = {
    invites: [],
    acceptedInviteId: undefined,
    inviteModalOpen: false,
    acceptInviteLoading: false,
    invitesFetched: false,
    groups: [],
};

export default (
    state = initialState,
    action: OrganizationInvitesReducerActions | SettingsReducerActions
): OrganizationInvites => {
    switch (action.type) {
        case OrganizationInvitesActionType.GetOrganizationInvitesSuccess:
            return {
                ...state,
                acceptedInviteId: undefined,
                invites: action.invites,
                invitesFetched: true,
                inviteModalOpen: action.invites.length > 0,
            };
        case OrganizationInvitesActionType.OrganizationInviteResponseInit:
            return {
                ...state,
                acceptedInviteId: undefined,
                inviteModalOpen: !(action.status === organizationInviteResponses.deny),
                acceptInviteLoading: action.status === organizationInviteResponses.accept,
            };
        case OrganizationInvitesActionType.OrganizationInviteResponseSuccess:
            return {
                ...state,
                acceptedInviteId: action.inviteId,
                acceptInviteLoading: false,
            };
        case FETCH_USER_INFO_SUCCESS:
            return {
                ...state,
                groups: action.groups,
                inviteModalOpen: state.inviteModalOpen,
            };
        case OrganizationInvitesActionType.OrganizationInviteResponseError:
            return {
                ...state,
                acceptInviteLoading: false,
                inviteModalOpen: false,
            };
        case OrganizationInvitesActionType.CloseHBSOnboardingModalInit:
            return {
                ...state,
                inviteModalOpen: false,
            };
        default:
            return state;
    }
};
