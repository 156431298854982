import { useContext } from 'react';
import { MaterialTableContext } from '../context/MaterialTableContext';
import { MaterialTableContextValues } from '../models';

const useMaterialTableContext = (): MaterialTableContextValues => {
    const values = useContext(MaterialTableContext);
    if (!values) {
        throw new Error('This hook can not be used outside its provider');
    }
    return values;
};

export default useMaterialTableContext;
