import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import RequestActions from 'commons/src/models/RequestTypes';
import {
    DeviceActionType,
    FetchDevice,
    FetchDeviceConfig,
    FetchDeviceConfigSuccess,
    fetchDeviceConfigSuccess,
    fetchDeviceSuccess,
    FetchDeviceSuccess,
    UpdateDeviceConfig,
    UpdateDeviceConfigSuccess,
    updateDeviceConfigSuccess,
} from '../../actions/DeviceActions';
import { RequestActionType, requestError, requestSuccess } from '../../actions/requestActions';
import { getDeviceConfig, getSegmentDetails, updateDeviceConfig } from '../../api/devices';
import { DeviceConfigResponse, DeviceType } from '../../models/commonTypeScript';
import { CommonRequestType } from '../../reducers/requestReducer';
import displayAlertBoxSaga from '../displayAlertBox';
import { toErrorType } from '../isErrorType';

type FetchDeviceConfigSagaActionType = Generator<
    CallEffect<DeviceConfigResponse> | PutEffect<FetchDeviceConfigSuccess> | RequestActions,
    void,
    DeviceConfigResponse
>;
export function* fetchDeviceConfigSaga({
    serialNumber,
    segmentId,
}: FetchDeviceConfig): FetchDeviceConfigSagaActionType {
    try {
        const response = yield call(getDeviceConfig, serialNumber, segmentId);
        yield put(fetchDeviceConfigSuccess(response, serialNumber));
        yield put(requestSuccess(CommonRequestType.FetchDeviceConfig, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.FetchDeviceConfig, RequestActionType.Error));
    }
}

type UpdateDeviceConfigSagaActionType = Generator<
    CallEffect | PutEffect<UpdateDeviceConfigSuccess> | RequestActions,
    void
>;
export function* updateDeviceConfigSaga({
    config,
    serialNumber,
    segmentId,
    displaySuccessMessage,
}: UpdateDeviceConfig): UpdateDeviceConfigSagaActionType {
    try {
        yield call(updateDeviceConfig, config, serialNumber, segmentId);
        yield put(updateDeviceConfigSuccess(config, serialNumber));
        yield put(requestSuccess(CommonRequestType.UpdateDeviceConfig, RequestActionType.Success));
        if (displaySuccessMessage) {
            yield call(displayAlertBoxSaga, 'DeviceSettings.DeviceConfigUpdated', false, true);
        }
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.UpdateDeviceConfig, RequestActionType.Error));
    }
}

type FetchDeviceSagaActionType = Generator<
    CallEffect<DeviceType> | PutEffect<FetchDeviceSuccess> | RequestActions,
    void,
    DeviceType
>;
export function* fetchDeviceSaga({ serialNumber, segmentId }: FetchDevice): FetchDeviceSagaActionType {
    try {
        const device = yield call(getSegmentDetails, serialNumber, segmentId);
        yield put(fetchDeviceSuccess(device, serialNumber));
        yield put(requestSuccess(CommonRequestType.FetchDevice, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.FetchDevice, RequestActionType.Error));
    }
}

export default function* DeviceConfigSaga(): Generator {
    yield all([
        takeEvery(DeviceActionType.UpdateDeviceConfig, updateDeviceConfigSaga),
        takeEvery(DeviceActionType.FetchDeviceConfig, fetchDeviceConfigSaga),
        takeEvery(DeviceActionType.FetchDevice, fetchDeviceSaga),
    ]);
}
