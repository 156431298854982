import React from 'react';
import { useTranslation } from 'react-i18next';
import LabeledText from 'commons/src/components/LabeledText';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { dateFormats } from 'commons/src/constants';
import { EstimatedSubscription, Subscription } from '../../../../models/subscriptionModels';
import AddSeatsCostPreviewTable from './AddSeatsCostPreviewTable';
import styles from './SubscriptionSumary.module.scss';

type ParentProps = {
    oldSubscription: Subscription;
    costPreview?: EstimatedSubscription;
    dateFormat: keyof typeof dateFormats;
    poNumber: string;
    displayDiscounts: boolean;
};
const SubscriptionSummary = ({
    oldSubscription,
    costPreview,
    dateFormat,
    poNumber,
    displayDiscounts,
}: ParentProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { subscriptionNumber, durationInYears } = oldSubscription;
    return (
        <div className={styles.infoWrapper}>
            <div className={styles.subscriptionInfo}>
                <LabeledText
                    noPadding
                    label="Subscription.Subscription"
                    value={subscriptionNumber}
                    id="subscriptionNumber"
                    largeText
                />
                <LabeledText
                    noPadding
                    label="Subscription.ServicePeriod"
                    value={durationInYears.toString()}
                    id="servicePeriod"
                    largeText
                />
                <LabeledText noPadding label="Subscription.PONumber" value={poNumber} id="purchaseOrderRef" largeText />
            </div>
            <div>
                <AddSeatsCostPreviewTable
                    subscription={costPreview}
                    loading={false}
                    oldSubscription={oldSubscription}
                    dateFormat={dateFormat}
                />
                {displayDiscounts && (
                    <div className={styles.proratedAmount}>
                        <MaterialIcon name="payments" />
                        {txt('AddSeats.ProratedAmountChargeToday')}
                    </div>
                )}
            </div>
        </div>
    );
};
export default SubscriptionSummary;
