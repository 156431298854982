import React from 'react';
import { useTranslation } from 'react-i18next';
import { MemberDetail } from '../../../models/common';

const MemberDetailsComponent = ({ name, email, role }: MemberDetail): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className="list__row__text-container">
            <h3 className="list__row__text-container__header">{name}</h3>
            <span>{email}</span>
            <span>{`${txt('Role')}: ${txt(`AuthorizationRole.${role}`)}`}</span>
        </div>
    );
};

export default MemberDetailsComponent;
