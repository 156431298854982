import React, { ReactElement } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import spinner from '../../img/spinner';
import styles from './PdfDownloadButton.module.scss';

type Props = {
    document: ReactElement;
    fileName: string;
    onClick?: () => void;
    allPropsReady: boolean;
};

const PdfDownloadButton = ({ document, fileName, onClick, allPropsReady }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const onClickProps = onClick ? { onClick, onKeyDown: onClick, role: 'button', tabIndex: 0 } : {};
    return (
        <div className={styles.buttonWrapper} {...onClickProps}>
            {allPropsReady && (
                <PDFDownloadLink document={document} fileName={fileName} className={styles.button}>
                    {({ loading }): React.ReactElement | string => (loading ? spinner : txt('Download'))}
                </PDFDownloadLink>
            )}
        </div>
    );
};

export default PdfDownloadButton;
