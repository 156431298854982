/* eslint max-len: ["error", 15000] */
import React from 'react';

const IaqInsightIllustration = (): React.ReactElement => (
    <svg width="596px" height="300px" viewBox="0 0 596 300" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path
            fill="#FFFFFF"
            d="M264.8,173.8l-41.3-18.8c0,0-20.7,2.9-20.7,1.3l-0.4-32c0,0-4-2.3-9.7-5.4l-0.4-7.6l-38.5-21.6l-51.1,16.5
            v7.6l-5.2,2.1L96,254.3l168.6-0.8C265.6,253.4,264.8,173.8,264.8,173.8z"
        />
        <path
            fill="#FFBF00"
            stroke="#42515A"
            strokeWidth="0.1528"
            strokeMiterlimit="10"
            d="M386,235.5L386,235.5
            c-0.1-4.4-0.2-6-0.3-10.4c0-2.2-0.1-4.4-0.2-6.5c0-1.8,0-1.4-0.7-3.1c-1.1-2.5-4.3-3.7-6.1-1.2c-1.1,1.4-1.1,0.9-1.1,2.5
            c-0.1,2.1-0.1,4.3-0.1,6.5c0,2.1,0.1,1.5,0.3,3.7c0,2.8-0.1,5.7-0.1,8.5v0.1c-3.1,1.4-5.3,4.3-5.8,7.7c-0.6,3.7,1.1,7.2,4.1,9.4
            c3,2.3,7,2.6,10.3,0.9c3.2-1.7,5.6-5.1,5.5-8.8C391.8,240.8,389.5,237.2,386,235.5z M386.6,251.9c-2.7,1.8-6.1,1.9-9,0.4
            c-2.8-1.6-4.8-4.5-4.7-7.8c0.1-3.8,2.3-7.1,5.7-8.7c0.3-0.2,0.1-0.7-0.3-0.6l0,0h0.1c0.1,0,0.2-0.1,0.2-0.2c0.1,0,0.2,0,0.2-0.1
            v-0.2c0.1-0.4,0.1-0.8,0.1-1.2c0-0.7-0.1-1.5-0.2-2.1c0-0.1-0.1-0.1-0.1,0c0-0.7-0.1-1.4-0.2-2.1c0-2,0-4.1,0-6.1
            c0-3.7,0.2-2.3,0-6c0.2-1.6,0.8-3.3,2.5-3.7c1.9-0.4,3.3,1.6,3.8,3.2c0.4,1.8,0.3,1.4,0.4,3.2c0,1.8,0.1,0.9,0.1,2.7
            c0.1,4.2,0.2,8.4,0.3,12.5c-0.5,0-0.6,0.9-0.1,1.1c3.1,1.3,5.1,4.2,5.5,7.4C390.9,247,389.4,250.1,386.6,251.9z"
        />
        <path
            fill="#42515A"
            d="M385.9,242.1c-0.6-1.4-1.8-2.4-3.2-2.9c0.4-0.6,0.2-1.5,0.2-2.2c-0.1-1.1-0.1-2.1-0.2-3.2
            c-0.1-2.1-0.3-4.2-0.3-6.3c-0.1-2.1-0.2-1.4-0.2-3.5c0-1.9,0.1-4.2-0.6-6c0-0.1-0.3-0.2-0.4,0c-0.8,1.8-0.7,3.9-0.8,5.9
            c0,2.2,0,1.6,0,3.8c0,2.1,0.1,4.2,0.2,6.3c0,1.1,0.1,2.3,0.2,3.3c0,0.7,0,1.4,0.3,1.9c-0.9,0.2-1.8,0.7-2.3,1.5
            c-0.3,0.2-0.6,0.4-0.8,0.7c-0.8,0.8-1.2,1.8-1.1,3c0,1.2,0.4,2.3,1.1,3.2c1.1,1.4,2.7,2.1,4.5,1.8h0.1h0.1
            c1.4-0.4,2.5-1.2,3.2-2.5c0.6-1.1,0.7-2.5,0.3-3.6C386.2,242.7,386.1,242.4,385.9,242.1z"
        />
        <path
            fill="none"
            stroke="#42515A"
            strokeWidth="2.4439"
            strokeMiterlimit="10"
            d="M386,235.5L386,235.5
            c-0.1-4.4-0.2-6-0.3-10.4c0-2.2-0.1-4.4-0.2-6.5c0-1.8,0-1.4-0.7-3.1c-1.1-2.5-4.3-3.7-6.1-1.2c-1.1,1.4-1.1,0.9-1.1,2.5
            c-0.1,2.1-0.1,4.3-0.1,6.5c0,2.1,0.1,1.5,0.3,3.7c0,2.8-0.1,5.7-0.1,8.5v0.1c-3.1,1.4-5.3,4.3-5.8,7.7c-0.6,3.7,1.1,7.2,4.1,9.4
            c3,2.3,7,2.6,10.3,0.9c3.2-1.7,5.6-5.1,5.5-8.8C391.8,240.8,389.5,237.2,386,235.5z"
        />
        <path
            fill="#FFC000"
            d="M371.8,107.5c0.2-1.2,0.5-2.5,1.1-3.6c0.6-1.2,1.7-2.2,2.9-2.8l-0.3-0.7c0,0-4.8-7.5-7.7-11
            c-1.8-2.2-7.1,5.3-8.1,7c-1.3,2.7-3.9,8.9-1.3,10.9C362.6,107.6,371.8,107.5,371.8,107.5z M361.9,104.8c-0.6,0-1.2,0-1.8,0
            c0.1-4.7,2.8-9.5,6.6-12.6c1.8,2.7,4.3,5.7,6.3,8.4c-0.7,1.2-2.1,2.5-2.4,4C367.1,104.6,364.7,104.6,361.9,104.8z"
        />
        <path
            fill="#FFC000"
            d="M397.8,101c-1.5-4.8-4.6-10.4-9.9-11.7c-2.6,3.7-7.4,12-7.4,12c1.1,0.4,1.8,1.2,2.4,2.2
            c0.5,1.1,0.9,2.1,1.1,3.2c0,0,10.3,0.2,12.5,0.6C400,107.8,398.1,103,397.8,101z M394.8,104.8c-3-0.1-6-0.1-9,0.1
            c-0.6-1.3-1.4-2.5-2.4-3.6c2.1-3.5,3.6-6,5.4-8.9c4,2.8,6.4,7.5,7,12.3C395.5,104.8,395.1,104.8,394.8,104.8L394.8,104.8z"
        />
        <path
            fill="#FFC000"
            d="M388.7,124.7c-2-4.1-6.7-12.6-6.7-12.6s-2.5,1.1-3.7,1c-1,0.1-2.3,0-3.1-0.5c-2.4,3.7-4.6,8.2-6.6,12.3
            C367.7,129.8,388.7,129.9,388.7,124.7z M383.5,125.4c-3.8,1.1-8.2,0.8-12-0.7c1.6-3,3.4-5.9,4.8-8.9c1.5,0.1,2.9,0,4.3-0.4
            c2,3.6,3.4,6,5.1,9.1C385,124.9,384.2,125.2,383.5,125.4L383.5,125.4z"
        />
        <path
            fill="#FFC000"
            d="M373.6,107.6c0.7,5.4,9.6,4.7,8.2-1.1C380.4,100.6,373.7,102.2,373.6,107.6z M376.2,106.5
            c1.6-3.6,5.2,1.8,2.3,2.5C376,109.6,376,107.7,376.2,106.5L376.2,106.5z"
        />
        <path fill="#9EA9B0" stroke="#FFC000" strokeWidth="0.3742" strokeMiterlimit="10" d="M457.5,237L457.5,237z" />
        <path
            fill="#9EA9B0"
            stroke="#FFC000"
            strokeWidth="0.3742"
            strokeMiterlimit="10"
            d="M457.7,237.3L457.7,237.3L457.7,237.3z"
        />
        <path
            fill="#42515A"
            d="M98,254.7c0.4-3,0.6-6,0.7-8.9c0.1-2.8,0.1-5.6,0.1-8.4c0.1-5,0-10,0-15c0-8.2,0-16.5,0.1-24.8
            c0-7.4,0-14.7,0-22.2v-24.2v-14.5c0-5.5,0.2-11.1-0.1-16.6c-0.1-1.5-0.3-3.1-0.4-4.6c0-0.5-0.5-1-1.1-0.9c-0.5,0-0.9,0.4-0.9,0.9
            c-0.3,3-0.4,6-0.4,9v8.4c0,5,0,10,0,15v24.8v22.2c0,8.1,0,16.1,0.1,24.3v14.4c0,5.5-0.1,11.1,0.4,16.7c0.1,1.5,0.3,3,0.5,4.5
            c0.1,0.2,0.3,0.3,0.4,0.3C97.8,254.9,97.9,254.8,98,254.7L98,254.7z"
        />
        <path
            fill="#42515A"
            d="M97.7,114.5C97.7,114.5,97.7,114.6,97.7,114.5c0.3,0.4,0.7,0.6,1,0.4c7-1.8,13.8-3.8,20.8-5.7
            c7.9-2.2,15.8-4.3,23.6-6.3c3.7-1,7.4-1.9,11-3c0.8-0.3,0.4-1.3-0.4-1.2c-3.3,0.5-6.6,1.2-9.8,2c-3.9,1-7.6,1.9-11.5,3
            c-7.9,2.1-15.7,4.2-23.6,6.5c-3.6,1.1-7.2,2-10.8,3.2C97.9,113.5,97.6,114,97.7,114.5C97.6,114.1,97.7,114.5,97.7,114.5
            L97.7,114.5z"
        />
        <path
            fill="#42515A"
            d="M154.2,98.4c-0.2,0.4-0.3,0.9-0.4,1.3c-0.1,0.4-0.1,0.9-0.1,1.4c-0.1,0.9-0.1,1.8-0.1,2.7
            c0,1.6,0,3.2,0,4.8c0,2.6,0,5.2,0,7.8c0,3.6,0,7.2,0,10.8v18.5c0,5.6-0.2,11.3,0.1,16.9c0,0.9,0.2,1.8,0.2,2.8
            c0,0.4,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6c0.4-6,0.3-12,0.3-18v-19c0-3.4,0-6.8,0-10.2c0-2.5,0-4.9,0-7.3v-4.5c0-1.8,0-3.4-0.1-5.1
            c0-0.4,0-0.9-0.1-1.3c-0.1-0.5-0.3-1-0.4-1.4C154.6,98.4,154.4,98.3,154.2,98.4C154.3,98.1,154.2,98.2,154.2,98.4L154.2,98.4z"
        />
        <path
            fill="#42515A"
            d="M154.5,98.5C154.6,98.5,154.6,98.5,154.5,98.5C154.6,98.5,154.6,98.5,154.5,98.5L154.5,98.5L154.5,98.5z"
        />
        <path
            fill="#42515A"
            d="M154.3,98.6c0.9,0.8,1.8,1.5,2.8,2c1.3,0.8,2.7,1.6,4,2.3c3.4,1.8,6.9,3.6,10.4,5.3
            c3.9,1.9,7.8,3.9,11.6,5.8c3.6,1.8,7.2,3.6,10.7,5.4c2.5,1.3,5,2.8,7.4,4.2l0.3,0.2l0.2,0.1c0,0,0.3,0.2,0.2,0.1
            c0.6,0.4,1.1-0.5,0.6-1c0.2,0.2,0.1,0.1,0,0l-0.1-0.1c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.2-0.4-0.3-0.6-0.4c-0.5-0.4-1.1-0.7-1.6-1
            c-1.3-0.8-2.6-1.5-3.9-2.3c-3.4-1.8-6.9-3.6-10.3-5.3c-3.9-1.9-7.8-3.9-11.6-5.8c-3.6-1.8-7.2-3.5-10.8-5.3
            c-1.5-0.7-2.9-1.5-4.4-2.3c-1.1-0.6-2.3-1.2-3.4-1.8l-0.6-0.3C154.5,98.1,154.2,98.5,154.3,98.6L154.3,98.6z"
        />
        <path
            fill="#42515A"
            d="M102.2,106.1c-0.1,1.3-0.1,2.6-0.2,3.9v1.9c0,0.7-0.2,1.5,0.3,2c0.3,0.3,0.7,0.3,1,0
            c0.4-0.5,0.3-1.3,0.3-2v-2c0-1.3-0.1-2.6-0.2-3.9c0-0.4-0.3-0.6-0.6-0.6C102.4,105.5,102.2,105.7,102.2,106.1z"
        />
        <path
            fill="#42515A"
            d="M102.3,106.2c0.3,0.1,0.4,0.2,0.7,0.1s0.6-0.2,0.9-0.3c0.6-0.2,1.2-0.4,1.9-0.5c1.2-0.3,2.4-0.6,3.6-0.9
            c2.4-0.6,4.8-1.2,7.2-1.9c3.3-1.1,6.3-2.5,9.5-3.6c6.3-2.2,12.9-3.9,19.3-5.7c2.9-0.8,5.7-1.6,8.6-2.6c0.9-0.3,0.5-1.7-0.4-1.4
            c-2.5,0.9-4.9,1.6-7.4,2.3c-3.3,1-6.7,1.8-10,2.8c-3.2,0.9-6.3,1.8-9.5,2.9c-1.2,0.4-2.5,0.9-3.8,1.3c-1.8,0.7-3.4,1.5-5.2,2.1
            c-2.6,0.9-5.3,1.6-8,2.4c-1.3,0.4-2.6,0.7-3.9,1.1c-0.7,0.2-1.4,0.4-2.1,0.6c-0.4,0.1-0.7,0.3-1.1,0.4c-0.3,0.2-0.4,0.4-0.5,0.6
            C102.2,106.1,102.2,106.2,102.3,106.2C102.2,106.2,102.3,106.2,102.3,106.2L102.3,106.2z"
        />
        <path
            fill="#42515A"
            d="M153.2,89.9c1.3,0.9,2.8,1.5,4.2,2.3c1.4,0.7,2.9,1.5,4.4,2.3c3,1.6,5.8,3.2,8.7,5c2.5,1.5,4.8,3,7.3,4.5
            c2.3,1.3,4.6,2.6,7,3.9c1.3,0.7,2.6,1.4,3.9,2.1c0.6,0.4,1.2,0.6,1.8,1c0.6,0.3,1.1,0.5,1.8,0.7c0.3,0.1,0.5-0.1,0.6-0.3
            c0-0.2,0-0.4-0.1-0.4c-0.4-0.4-0.8-0.7-1.2-1.1c-0.5-0.3-1-0.6-1.5-0.9c-1.1-0.6-2.3-1.2-3.4-1.8c-2.4-1.3-4.8-2.5-7.2-3.9
            c-2.4-1.3-4.7-2.8-7.1-4.2c-2.9-1.8-5.8-3.3-8.8-4.8c-1.7-0.9-3.3-1.7-5-2.5c-1.6-0.7-3.3-1.7-5-2.1c-0.1,0-0.3,0-0.3,0.1
            C153,89.7,153.1,89.9,153.2,89.9L153.2,89.9z"
        />
        <path
            fill="#42515A"
            d="M191.7,110.7c-0.2,1.1-0.3,2.2-0.4,3.3c0,0.4,0,0.7,0,1.1c0,0.6-0.1,1.4,0.2,1.9c0.2,0.3,0.6,0.4,0.9,0.3
            c0.1,0,0.2-0.1,0.3-0.3c0.3-0.6,0.2-1.3,0.2-1.9c0-0.4,0-0.7,0-1.1c0-1.1,0-2.1-0.2-3.2c0-0.3-0.2-0.4-0.4-0.4
            C192.1,110.4,191.8,110.5,191.7,110.7L191.7,110.7z"
        />
        <path
            fill="#42515A"
            d="M153.8,165.9c1.5-0.1,2.9-0.4,4.4-0.6c1.2-0.2,2.5-0.4,3.7-0.6l6.1-1.1c3.9-0.6,7.8-1.3,11.6-1.9
            c4.7-0.8,9.4-1.5,14.1-2.1c4.9-0.6,9.7-1.2,14.6-1.8c2.4-0.3,4.8-0.6,7.2-0.9c2.2-0.3,4.5-0.4,6.7-0.8c0.4-0.1,0.9-0.2,1.4-0.3
            c0.9-0.2,0.7-1.6-0.3-1.6c-2.1,0.1-4.1,0.4-6.1,0.6l-7.2,0.9c-4.9,0.6-9.9,1.1-14.8,1.8c-4.8,0.6-9.5,1.4-14.3,2.1
            c-4,0.6-8.1,1.3-12,2.1c-4.4,0.8-8.8,1.6-13.2,2.5c-0.8,0.2-1.5,0.3-2.2,0.5C152.9,165,153,165.9,153.8,165.9L153.8,165.9z"
        />
        <path
            fill="#42515A"
            d="M222.7,155c-0.3,7.8-0.4,15.5-0.4,23.3c0,5,0,9.9,0.1,14.9c0.1,3.9,0.3,8,0.4,12c0.2,4.7,0.3,9.5,0.4,14.2
            c0.1,7.2,0.1,14.4,0.1,21.7c0,1.2,0,2.4,0,3.5c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.4,0.7-0.7c0,0.3-0.1,0.5-0.4,0.6l-0.4,0.1
            l-0.6-0.4l-0.1-0.2l0,0v-0.2l0,0v0.1c0,0.1,0,0.2,0,0.3c0,0.4,0,0.8,0,1.1c0,0.5,0,1.1,0,1.6c0,0.6,0,1.2,0,1.8
            c0,0.6,0,1.2-0.1,1.9c0,0.6,0,1.1-0.1,1.8c0,0.4,0,0.9-0.1,1.3c0,0.2,0,0.3,0,0.4v0.1c0,0.2,0-0.3,0-0.1v0.1c0,0,0,0.1,0-0.1
            c0.3-0.4,0.7-0.4,1.1-0.2c0,0,0,0,0.1,0.1c0.1,0.1,0.2,0.4,0.1,0.1v-0.2v-0.1c0-0.2,0,0.2,0,0c0-0.3,0-0.5-0.1-0.7
            c0-0.3,0-0.6-0.3-0.9l0.2,0.4c0-0.8,0-1.6-0.1-2.4c0-1.1,0-2.4-0.1-3.5c0-1.6,0-3.2-0.1-4.8c0-2.1,0-4.2-0.1-6.3
            c0-2.6,0-5.3,0-7.9c0-3.2,0-6.5,0-9.6c0-3.9,0-7.7,0-11.6c0-4.6,0-9,0-13.6c0-5.3,0-10.6,0-15.8c0-6,0-12.2,0-18.2
            c0-1.6,0-3.2,0-4.8c0-0.1-0.1-0.2-0.2-0.2s-0.2,0.1-0.2,0.2c0,6.3-0.1,12.5-0.2,18.8c0,5.4-0.1,10.9-0.2,16.4
            c0,4.7-0.1,9.5-0.1,14.2c0,4,0,8.1-0.1,12.1c0,3.3,0,6.7,0,10.1c0,2.8,0,5.5,0,8.3c0,2.2,0,4.5,0,6.7c0,1.8,0,3.4,0,5.2
            c0,1.2,0,2.5,0,3.9c0,0.9,0,1.8,0,2.6c0,0.2,0,0.3,0,0.4l0.2-0.4c-0.4,0.4-0.3,1.4-0.2,1.8c0,0.2,0,0.3,0,0.4
            c0.1,0.4,0.5,0.6,0.9,0.5c0.2,0,0.3-0.1,0.4-0.3c0.2-0.3,0.3-0.6,0.3-1c0-0.4,0.1-0.9,0.1-1.2c0-0.6,0-1.1,0.1-1.8
            c0-0.6,0-1.3,0.1-2c0-0.6,0-1.3,0-1.9c0-0.6,0-1.1,0-1.8c0-0.4,0-0.8,0-1.2c0-0.4,0-1-0.5-1.1c-0.6-0.2-0.9,0.3-0.9,0.7h1.5
            c0-7.7,0-15.4,0-23.1c-0.1-4.9-0.2-9.9-0.4-14.8c-0.1-3.9-0.3-8-0.4-11.9c-0.1-4.8-0.2-9.5-0.2-14.3c0-7.3,0.1-14.5,0.2-21.8
            c0-1.2,0-2.4,0-3.5C224,154.2,222.7,154.2,222.7,155z"
        />
        <path
            fill="#42515A"
            d="M81.8,255.2c7.4,0.3,14.7,0.4,22.2,0.4c7.4,0,14.7,0,22.2,0h44.2c29.3,0,58.7,0,88,0
            c16.5,0,33.1,0.1,49.6,0c1.2,0,1.2-1.9,0-1.9c-29.5-0.1-58.9,0-88.4,0h-88.1c-8.3,0-16.5,0-24.8,0c-4,0-8.1,0-12.2,0
            c-4.2,0.1-8.4,0.3-12.6,0.4c-0.3,0-0.5,0.3-0.5,0.6C81.3,254.9,81.6,255.2,81.8,255.2L81.8,255.2z"
        />
        <path
            fill="#42515A"
            d="M223.2,155.5c1.6,0.9,3.3,1.4,5.1,2.1c1.5,0.6,3,1.2,4.5,1.8c2.5,1.1,5.1,2.2,7.6,3.4
            c4.8,2.3,9.5,4.8,14.3,7.2c1.5,0.8,3.1,1.5,4.6,2.2c1.8,0.9,3.6,1.8,5.4,2.3c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.3,0-0.6-0.3-0.8
            c-1.5-1-3.1-1.7-4.6-2.4c-1.4-0.6-2.8-1.3-4.2-2c-2.5-1.2-4.9-2.5-7.4-3.7c-4.8-2.5-9.6-4.8-14.6-6.8c-1.6-0.7-3.2-1.3-4.9-1.9
            c-1.8-0.7-3.9-1.5-5.8-1.8C223,154.4,222.6,155.1,223.2,155.5L223.2,155.5z"
        />
        <path
            fill="#42515A"
            d="M264.5,173.5c-0.1,0.4-0.2,0.6-0.3,1l-0.1,1.3l-0.1,2.3c-0.1,1.5-0.1,2.9-0.1,4.4c0,2.5-0.1,4.9-0.1,7.4
            c0,3.6,0,7.2,0,10.9v23.6c0,3.2,0,6.4,0,9.6c0,5,0,9.9,0.1,14.9c0,1.5,0,3.1,0.1,4.6c0,0.4,0.4,0.6,0.7,0.6c0.3,0,0.6-0.3,0.6-0.6
            c0.2-1.5,0.1-3.1,0.1-4.6v-4.3c0-2.5,0-4.9,0-7.4v-10.8c0-2.2,0-4.4,0-6.6v-17.1c0-3.2,0-6.5,0-9.7c0-5,0-10-0.3-15l-0.1-2.1
            c0-0.4,0-0.8-0.1-1.2c-0.1-0.4-0.1-0.7-0.3-1.1C264.8,173.4,264.6,173.3,264.5,173.5C264.6,173.5,264.6,173.5,264.5,173.5
            L264.5,173.5z"
        />
        <path
            fill="#42515A"
            d="M202.4,124.3c-0.1,0.4-0.3,0.9-0.3,1.4l-0.1,1.7c-0.1,1.1-0.1,2.1-0.1,3.2c-0.1,2.2-0.1,4.4-0.1,6.5
            c0,4.3,0,8.6,0,12.9v3.3c0,0.5,0,1.1,0,1.7c0,0.6,0.1,1.1,0.3,1.8c0.1,0.4,0.5,0.6,0.9,0.4c0.3-0.1,0.4-0.3,0.4-0.4
            c0.1-0.4,0.3-1,0.3-1.4s0-1.1,0-1.6v-9.3c0-4.4,0.1-8.7,0-13c0-1.2,0-2.4-0.1-3.5l-0.1-1.8c0-0.3,0-0.5-0.1-0.9
            c-0.1-0.3-0.2-0.6-0.3-0.9c0-0.2-0.3-0.3-0.4-0.3C202.5,124.1,202.4,124.2,202.4,124.3L202.4,124.3z"
        />
        <path
            fill="#42515A"
            d="M241,254c0-2.7,0.2-5.4,0.3-8.2c0.1-1.3,0.1-2.7,0.2-4.1c0-0.7,0-1.4,0-2.1c0.1-0.7,0-1.4-0.3-2.1
            c-0.2-0.3-0.4-0.4-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.6-0.3,1.3-0.3,1.9c0,0.7,0,1.4,0,2.1c0,1.4-0.1,2.8-0.2,4.2
            c-0.1,2.7-0.3,5.4-0.3,8.2C239.6,254.9,240.9,254.9,241,254L241,254z"
        />
        <path
            fill="#42515A"
            d="M241.2,238.3h0.2l-0.3-0.2c0.3,0.4,0.5,0.4,1,0.4c0.3,0,0.6,0,0.9,0.1c0.6,0.1,1.3,0.3,2,0.4
            c0.7,0.1,1.4,0.3,2.1,0.4c0.7,0.2,1.3,0.2,2,0.1c0.5-0.1,0.5-1,0-1.1c-0.5-0.1-1.1-0.1-1.8-0.2c-0.7-0.1-1.3-0.3-2-0.4
            c-1.2-0.3-2.4-0.4-3.6-0.4c-0.2,0-0.4,0.1-0.4,0.3l-0.1,0.1l0.3-0.2l-0.2,0.1c-0.2,0-0.3,0.3-0.3,0.4
            C240.9,238.2,241,238.3,241.2,238.3L241.2,238.3z"
        />
        <path
            fill="#42515A"
            d="M248.6,239.6c-0.1,0.6-0.3,1.1-0.3,1.8c0,0.6-0.1,1.2-0.1,1.8c0,1.3,0,2.5,0,3.9v3.6c0,1.1,0,2.1,0.1,3.1
            c0.1,0.3,0.4,0.4,0.6,0.4c0.2,0,0.3-0.2,0.4-0.4c0.1-1,0.2-2,0.1-3v-3.6c0-1.2,0-2.5,0-3.8c0-0.6,0-1.2-0.1-1.8
            c-0.1-0.6-0.3-1.2-0.3-1.8c0-0.1-0.1-0.1-0.2-0.1C248.7,239.6,248.6,239.6,248.6,239.6L248.6,239.6z"
        />
        <path
            fill="#42515A"
            d="M127.3,262.5c4.9,0,9.9-0.1,14.9,0.1c4.9,0.2,9.9,0.2,14.9,0.3c9.9,0.1,19.8,0.1,29.7,0.1
            c19.7,0,39.4,0.1,59.1,0.1c11.1,0,22.2,0.1,33.4,0c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8c-9.9-0.2-19.8-0.1-29.7-0.1
            c-9.9,0-19.8,0.1-29.7,0.1c-19.7,0-39.4,0.1-59.1,0.2c-5.5,0-11.1,0-16.7,0.2s-11.1,0.3-16.7,0.3c-0.2,0-0.3,0.2-0.3,0.3
            C127,262.4,127.1,262.5,127.3,262.5L127.3,262.5z"
        />
        <path
            fill="#FFBF00"
            d="M500.3,237.1c-0.3,0.5-0.5,1.1-0.9,1.6c-0.3,0.4-0.6,0.9-0.8,1.4c-0.4,1.1-0.9,2.2-1.4,3.3
            c-0.5,1.1-1,2.1-1.5,3.1l-0.2,0.4c0.2-6.7-0.1-13.4-0.1-20.1c0-0.5,0-1.1,0-1.6V225c0.2-0.3,0.2-0.7,0-1c-0.3-0.4-0.8-0.6-1.2-0.4
            c0-0.1,0-0.2,0-0.3c0-0.3-0.4-0.2-0.4,0c0,0.2,0,0.4,0,0.5c0,0-0.1,0.1-0.1,0.2c-0.2,0.4-0.3,0.7-0.3,1.1c-0.1,1.2,0,2.5,0,3.8
            c0,1.8,0,3.6,0.1,5.3c0,0.4,0,1,0,1.4c0,3.3,0,6.6,0,9.9c-0.3-0.4-0.5-0.8-0.7-1.1c-0.6-0.9-1.2-1.7-1.8-2.5
            c-0.6-0.8-1.2-1.7-2-2.4c-0.3-0.2-0.6-0.2-0.8,0c-0.1,0.2-0.2,0.4-0.2,0.5c0.3,1,0.7,1.8,1.1,2.8c0.4,1,0.9,2,1.3,3
            c1,1.8,2.1,3.7,3.3,5.3c0.5,0.8,1.8,0.9,2.2,0c1.2-2.1,2.5-4.2,3.4-6.4c0.5-1.1,1-2.4,1.3-3.5c0.4-1.2,0.3-2.4,0.3-3.6
            c0-0.2-0.1-0.4-0.3-0.4C500.6,237,500.3,237,500.3,237.1z M494.7,225.4L494.7,225.4L494.7,225.4L494.7,225.4z"
        />
        <path
            fill="#FFBF00"
            d="M405.6,109.3c0.3-0.5,0.5-1.1,0.9-1.6c0.3-0.4,0.6-0.9,0.8-1.4c0.4-1.1,0.9-2.2,1.3-3.3
            c0.4-1.1,1-2.1,1.5-3.1l0.2-0.4c-0.2,6.7,0.1,13.4,0.1,20.1c0,0.5,0,1.1,0,1.6c0,0.1,0,0.2,0,0.3c-0.2,0.3-0.2,0.7,0,1
            c0.3,0.4,0.8,0.6,1.2,0.4c0,0.1,0,0.2,0,0.3c0,0.3,0.4,0.2,0.4,0s0-0.4,0-0.5c0,0,0.1-0.1,0.1-0.2c0.2-0.4,0.3-0.7,0.3-1.1
            c0.1-1.2,0-2.5,0-3.8c0-1.8,0-3.6-0.1-5.3c0-0.4,0-1,0-1.4c0-3.3,0-6.6,0-9.9c0.3,0.4,0.5,0.8,0.7,1.1c0.6,0.9,1.2,1.7,1.8,2.5
            c0.6,0.8,1.2,1.7,2,2.4c0.3,0.2,0.6,0.2,0.8,0c0.1-0.2,0.2-0.4,0.2-0.5c-0.3-1-0.7-1.8-1.1-2.8c-0.4-1-0.9-2-1.3-3
            c-1-1.8-2.1-3.7-3.3-5.3c-0.5-0.8-1.8-0.9-2.2,0c-1.2,2.1-2.4,4.2-3.4,6.4c-0.5,1.1-1,2.4-1.3,3.5c-0.4,1.2-0.3,2.4-0.3,3.6
            c0,0.2,0.1,0.4,0.3,0.4C405.3,109.6,405.5,109.5,405.6,109.3z M411.2,121.1L411.2,121.1L411.2,121.1L411.2,121.1z"
        />
        <polygon fill="#04508B" points="115.4,129.5 105.6,131.7 105.6,121 115.4,118.6" />
        <polygon fill="#04508B" points="129.7,126.6 119.4,128.9 119.4,117.9 129.7,115.4" />
        <polygon fill="#04508B" points="144.7,123.7 133.9,126 133.9,114.7 144.7,112.1" />
        <polygon fill="#04508B" points="115.4,144.9 105.6,146.8 105.6,136 115.4,133.9" />
        <polygon fill="#04508B" points="129.7,142.2 119.4,144.3 119.4,133.3 129.7,131.1" />
        <polygon fill="#04508B" points="144.6,139.6 133.9,141.8 133.9,130.4 144.7,128.1" />
        <polygon fill="#04508B" points="115.4,160.2 105.6,161.7 105.6,151 115.4,149.2" />
        <polygon fill="#04508B" points="129.7,157.9 119.4,159.7 119.4,148.6 129.7,146.6" />
        <polygon fill="#04508B" points="144.6,155.5 133.9,157.5 133.9,146.2 144.6,144.2" />
        <polygon fill="#FFD555" points="115.4,175.4 105.6,176.8 105.6,166.1 115.4,164.5" />
        <polygon fill="#04508B" points="129.7,173.5 119.4,175 119.4,164 129.7,162.4" />
        <polygon fill="#04508B" points="144.6,171.7 133.9,173.1 133.9,161.8 144.6,160.1" />
        <polygon fill="#04508B" points="115.4,190.7 105.6,191.8 105.6,181.1 115.4,179.8" />
        <polygon fill="#04508B" points="129.7,189.2 119.4,190.4 119.4,179.4 129.7,177.9" />
        <polygon fill="#04508B" points="144.6,187.5 133.9,188.8 133.9,177.6 144.6,176.1" />
        <polygon fill="#04508B" points="115.4,205.9 105.6,206.8 105.6,196.1 115.4,194.9" />
        <polygon fill="#04508B" points="129.7,204.8 119.4,205.7 119.4,194.7 129.7,193.5" />
        <polygon fill="#04508B" points="144.6,203.5 133.9,204.4 133.9,193.2 144.6,192.1" />
        <polygon fill="#04508B" points="115.4,221.2 105.6,221.8 105.6,211.1 115.4,210.3" />
        <polygon fill="#04508B" points="129.7,220.3 119.4,221 119.4,210 129.7,209.1" />
        <polygon fill="#04508B" points="144.6,219.5 133.9,220.2 133.9,209 144.6,207.9" />
        <polygon fill="#04508B" points="115.4,236.4 105.6,236.8 105.6,226 115.4,225.4" />
        <polygon fill="#04508B" points="129.7,235.9 119.4,236.3 119.4,225.3 129.7,224.7" />
        <polygon fill="#04508B" points="144.5,235.4 133.8,235.7 133.9,224.5 144.5,223.8" />
        <polygon fill="#04508B" points="160.7,185.8 149.4,187.1 149.4,175.5 160.7,173.9" />
        <polygon fill="#04508B" points="177.2,184 165.3,185.5 165.3,173.5 177.2,172" />
        <polygon fill="#04508B" points="194.4,182.2 181.8,183.6 181.9,171.5 194.5,169.8" />
        <polygon fill="#04508B" points="160.7,202.2 149.4,203.2 149.4,191.7 160.7,190.4" />
        <polygon fill="#04508B" points="177.2,200.8 165.2,201.8 165.3,190 177.2,188.6" />
        <polygon fill="#04508B" points="194.4,199.3 181.8,200.5 181.8,188.4 194.4,186.9" />
        <polygon fill="#04508B" points="160.7,218.4 149.4,219.2 149.4,207.6 160.7,206.8" />
        <polygon fill="#FFD555" points="177.1,217.5 165.2,218.3 165.2,206.3 177.1,205.5" />
        <polygon fill="#FFD555" points="194.4,216.4 181.8,217.3 181.8,205.2 194.4,204.1" />
        <polygon fill="#04508B" points="160.6,234.8 149.4,235.2 149.4,223.7 160.6,223" />
        <polygon fill="#04508B" points="177,234.2 165.1,234.7 165.2,222.8 177.1,222.1" />
        <polygon fill="#04508B" points="194.3,233.5 181.8,234.1 181.8,221.9 194.4,221.2" />
        <polygon fill="#04508B" points="212.5,180.3 199.2,181.8 199.2,169.3 212.5,167.5" />
        <polygon fill="#04508B" points="212.4,197.8 199.1,199.1 199.1,186.6 212.4,185.1" />
        <polygon fill="#04508B" points="212.3,215.4 199.1,216.2 199.1,203.7 212.4,202.7" />
        <polygon fill="#04508B" points="212.2,232.9 199,233.5 199.1,221 212.3,220.2" />
        <path
            fill="#42515A"
            d="M463.3,150c-1,1.3-2,2.6-3,3.9c-1,1.3-1.9,2.9-2.9,4.4c-1.8,3.1-3.3,6.2-4.6,9.5c-1.1,2.9-2,6-2.3,9
            c-0.2,2.5,0.1,5.3,1.6,7.4c1.8,2.5,4.6,3.6,7.4,4c1.8,0.2,3.8,0.2,5.6-0.1c2.1-0.2,4.1-0.7,6-1.5c2.7-1.2,4.9-3.5,5.5-6.5
            c0.6-3,0-6.1-1-9c-1.1-3.3-2.5-6.7-4.1-9.8c-0.8-1.5-1.5-3-2.4-4.5c-0.4-0.7-0.7-1.5-1.1-2.2c-0.2-0.4-0.3-0.7-0.4-1.1
            c-0.2-0.4,0.1,0.3,0,0l-0.1-0.3c-0.1-0.3-0.3-0.5-0.3-0.8c0.2,0.4-0.2-0.4-0.3-0.4c-0.1-0.1-0.3-0.5-0.1-0.3
            c-0.2-0.4-0.4-0.8-0.7-1.1l0.3,0.4v-0.1c-0.3-1.1-2-0.6-1.8,0.5v0.1v-0.5c-0.1,0.4-0.1,0.9,0,1.3c-0.1-0.4,0.1,0.4,0.1,0.5
            c0,0.2,0.1,0.4,0.1,0.5c0.2,0.7,0.4,1.4,0.7,2.1c0.5,1.5,1.1,3,1.7,4.5c0.1,0.2,0.2,0.4,0.3,0.6c-0.1-0.2-0.3-0.6,0.1,0.2l0.3,0.6
            c0.2,0.4,0.4,0.9,0.5,1.2c0.4,0.8,0.7,1.5,1.1,2.4c0.4,0.8,0.7,1.5,1.1,2.3c0.2,0.4,0.3,0.7,0.4,1.1c0,0.1,0.1,0.2,0.1,0.3
            c0.2,0.4-0.2-0.4,0,0l0.3,0.6c0.6,1.5,1.1,2.9,1.5,4.5c0.2,0.7,0.4,1.4,0.4,2.1l0.1,0.5c0,0.4,0-0.4,0-0.1c0,0.1,0,0.2,0,0.3
            c0,0.4,0.1,0.6,0.1,1c0,0.4,0,0.6,0,1c0,0.1,0,0.3,0,0.4s0,0.2,0,0.3c0,0.4,0.1-0.4,0-0.1c-0.1,0.6-0.3,1.1-0.4,1.8
            c-0.1,0.3,0.2-0.4,0.1-0.2c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.3-0.4,0.4-0.5,0.7c0.2-0.4,0.2-0.3,0.1-0.1
            l-0.2,0.2c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.3l-0.2,0.1l-0.1,0.1c-0.1,0.1-0.1,0.1,0.2-0.1
            c-0.2,0.1-0.3,0.2-0.4,0.3c-0.2,0.1-0.3,0.2-0.4,0.3c-0.3,0.2-0.6,0.4-1,0.5l-0.3,0.1c-0.3,0.2,0.4-0.2,0,0
            c-0.2,0.1-0.4,0.2-0.6,0.3c-0.4,0.2-0.9,0.3-1.2,0.4c-0.4,0.1-0.9,0.3-1.4,0.3c-0.3,0-0.5,0.1-0.8,0.2h-0.4c-0.4,0,0.3,0-0.1,0
            c-1,0.1-1.9,0.2-2.9,0.3c-0.9,0-1.7,0-2.5-0.1h-0.3c-0.2,0-0.4,0,0.1,0c-0.2,0-0.4,0-0.5-0.1c-0.4-0.1-0.7-0.2-1.1-0.3
            c-0.3-0.1-0.6-0.2-0.9-0.3l-0.3-0.1c-0.3-0.1,0.4,0.2,0.1,0c-0.2-0.1-0.3-0.2-0.4-0.3c-0.3-0.1-0.4-0.3-0.7-0.4
            c-0.1-0.1-0.3-0.2-0.4-0.3c0.4,0.2,0.3,0.2,0.1,0.1l-0.2-0.2c-0.2-0.2-0.4-0.4-0.5-0.5c-0.1-0.1-0.2-0.2-0.3-0.3
            c-0.2-0.2,0.3,0.3,0.1,0.1l-0.2-0.2c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.1-0.3-0.2-0.4l-0.1-0.2c-0.1-0.3,0.2,0.4,0,0
            c-0.2-0.5-0.4-1.1-0.4-1.6c0-0.2,0-0.3-0.1-0.4c-0.1-0.4,0,0.4,0,0s-0.1-0.6-0.1-1c0-0.6,0-1.2,0-1.9c0-0.3,0.1-0.6,0.1-0.9
            c0-0.1,0-0.4,0,0c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.4,0.1-0.6c0.3-1.5,0.6-2.9,1.1-4.3c0.5-1.5,1.1-3.1,1.7-4.6
            c0.1-0.3-0.1,0.3,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.4,0.4-0.8,0.5-1.1c0.4-0.8,0.7-1.5,1.1-2.3
            c1.5-3.1,3-5.9,4.6-8.9c0.4-0.7,0.8-1.3,1.2-2C464.4,150,463.6,149.7,463.3,150L463.3,150z"
        />
        <path
            fill="#42515A"
            d="M457.3,173.1c-0.4,0.3-0.6,0.6-0.8,1.1c-0.1,0.4-0.2,0.9-0.3,1.3c0,0.9,0.1,1.8,0.4,2.5
            c0.6,1.6,1.8,3.3,3.3,4.1c0.6,0.3,1.4,0.2,1.8-0.4c0.4-0.6,0.2-1.4-0.4-1.8c0,0-0.2-0.1-0.3-0.2c0,0.1,0.2,0.2,0,0
            c-0.2-0.2-0.4-0.3-0.6-0.4c-0.1-0.1,0.2,0.2,0,0l-0.3-0.2c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.1-0.3-0.3-0.4-0.4l-0.2-0.3
            c0.1,0.2,0,0,0,0c-0.3-0.3-0.4-0.6-0.6-1c-0.1-0.2-0.2-0.3-0.3-0.4c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2,0-0.1
            c-0.1-0.4-0.3-0.6-0.3-1c0,0,0-0.3,0-0.1c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3,0-0.4c0-0.4,0-0.6,0-1c0-0.3-0.3-0.5-0.5-0.5
            C457.5,172.9,457.4,172.9,457.3,173.1L457.3,173.1z"
        />
        <path
            fill="#42515A"
            d="M360.2,185.6c0.8,0.2,1.6,0.3,2.4,0.4l2.5,0.1c1.6,0,3.2,0,4.8,0c3.2,0,6.3,0,9.5,0.1
            c6.3,0.1,12.6,0.2,18.9,0.2c3.7,0,7.4,0,11-0.3c1.3-0.1,1.3-2,0-2.1c-3.2-0.1-6.5,0-9.7,0c-3.2,0-6.3,0-9.5,0
            c-6.3,0-12.6,0-18.9,0c-1.8,0-3.6,0-5.4,0.1c-0.9,0-1.8,0.1-2.7,0.2c-1,0.1-1.8,0.3-2.8,0.4c-0.3,0.1-0.4,0.4-0.3,0.6
            C359.9,185.3,360,185.5,360.2,185.6L360.2,185.6z"
        />
        <path
            fill="#42515A"
            d="M359.4,185.1c0.2-0.5,0.3-1,0.4-1.5c0-0.4,0.1-0.9,0.2-1.4c0-0.4,0.1-0.9,0.2-1.3c0-0.4,0-0.4,0-0.2v-0.1
            c0-0.2,0-0.3,0.1-0.4c0-0.3,0.1-0.4,0.2-0.6c0.1-0.3,0.1-0.4,0.2-0.6c0.1-0.2-0.1,0.2,0-0.1c0-0.1,0.1-0.2,0.2-0.3l0.2-0.3
            l0.1-0.2c-0.1,0.1-0.1,0.2,0,0c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.3-0.3,0.1-0.1c-0.2,0.2,0.1-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1
            c0.1,0,0.1,0,0,0h0.2l0.3-0.1c0.1,0,0.3,0,0,0c0.2,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0s0.2,0,0.3,0c-0.1,0-0.1,0,0,0
            c0.7,0.2,1.4-0.3,1.6-0.9c0-0.1,0-0.2,0-0.3c0-1.1,0-2.4,0.1-3.5c0-0.5,0-1,0.1-1.5c0-0.3,0-0.4,0.1-0.7c0-0.1,0,0.3,0,0
            c0-0.1,0-0.3,0.1-0.4c0.1-0.4,0.2-0.8,0.3-1.2c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.3,0-0.1c-0.1,0.2,0-0.1,0.1-0.2
            c0-0.1,0.1-0.2,0.2-0.3c0-0.1,0.2-0.3,0.3-0.4c0-0.1-0.2,0.3,0,0c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.3,0.4-0.4
            c-0.2,0.2-0.2,0.1,0,0c0.2-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.4-0.3,0.6-0.4c0.3-0.2-0.2,0,0.1,0l0.4-0.1c0.6-0.2,1.2-0.4,1.8-0.4
            c0.9-0.2,1.2-1.3,0.9-2c-0.1-0.2-0.2-0.3-0.3-0.4c0-0.1-0.1-0.3,0,0c0-0.1,0-0.2-0.1-0.2c-0.1-0.3-0.2-0.6-0.3-0.9
            c0-0.2-0.1-0.4-0.1-0.5c0-0.1,0-0.3,0,0c0-0.1,0-0.2,0-0.2c0-0.4,0-0.7,0-1c0-0.3,0-0.6,0-0.9v-0.2c0-0.4,0,0.3,0,0s0-0.4,0.1-0.5
            c0.1-0.6,0.3-1.2,0.5-1.8c0-0.1,0.1-0.3,0,0l0.1-0.2c0-0.1,0.1-0.3,0.2-0.4c0.2-0.3,0.3-0.5,0.4-0.8c0.1-0.1,0.2-0.2,0.2-0.3
            c0.1-0.2,0,0-0.1,0.1l0.1-0.2c0.2-0.2,0.4-0.4,0.5-0.6l0.1-0.1c0.2-0.2-0.1,0.1-0.1,0.1l0.3-0.2c0.1,0,0.2-0.1,0.3-0.2
            c0.1,0,0,0.1-0.2,0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.2,0,0.3-0.1c0.2,0-0.4,0,0,0c0.1,0,0.2,0,0.3,0c0,0-0.4-0.1-0.2,0
            c0.1,0,0.1,0,0.2,0h0.1c0.3,0-0.3-0.2-0.1,0c0.1,0.1,0.3,0.1,0.3,0.2l0.1,0.1c-0.2-0.2-0.2-0.2-0.1-0.1h0.1
            c0.1,0.1,0.3,0.2,0.3,0.3c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1-0.2-0.3,0,0c0.2,0.3,0.3,0.4,0.4,0.5c0.3,0.4,0.4,0.8,0.6,1.2
            c0.3,0.4,0.7,0.7,1.2,0.7c0.5,0,1-0.3,1.2-0.7l0,0l0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.3
            c0.2-0.2-0.1,0.1-0.1,0.1l0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2-0.2,0,0,0c0.1,0,0.2,0,0.3-0.1c0.2-0.1-0.2,0-0.2,0h0.2
            c0.4,0-0.1,0-0.1,0c0.1,0,0.2,0,0.3,0.1c0.3,0.1-0.3-0.2-0.1,0c0.1,0.1,0.2,0.1,0.3,0.2c0,0-0.3-0.3,0,0c0.1,0.1,0.2,0.2,0.3,0.3
            c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.2-0.1-0.1-0.1-0.1c0,0.1,0.1,0.1,0.2,0.2c0.3,0.4,0.5,0.9,0.7,1.3c0.2,0.3-0.1-0.3,0,0
            c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.2,0.4c0.1,0.3,0.2,0.6,0.3,0.9c0.2,0.6,0.3,1.1,0.4,1.8c0.1,0.3,0.1,0.6,0.2,0.9
            c0,0.3,0-0.2,0,0.2c0,0.2,0,0.4,0.1,0.5c0.1,0.7,0.1,1.3,0.1,2c0,0.3,0,0.6,0,1c0,0.2,0,0.3,0,0.4c0,0.2,0,0.3,0-0.1
            c0,0.1,0,0.2,0,0.3c-0.2,0.9,0.4,1.8,1.3,1.8h0.5c0,0,0.3,0,0.1,0c-0.2,0,0,0,0.1,0c0.3,0.1,0.6,0.2,0.9,0.3s-0.2-0.1,0,0l0.2,0.1
            c0.1,0.1,0.3,0.1,0.4,0.2l0.3,0.2c0.2,0.1,0,0,0,0c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3s-0.1-0.2,0,0
            c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.3,0.3,0.4c0,0.1,0.1,0.2,0.1,0.2c-0.1-0.3,0,0,0,0c0.1,0.4,0.2,0.7,0.3,1.1c0,0.1,0,0.2,0,0
            c0,0.1,0,0.2,0,0.3c0,0.3,0,0.5,0,0.7c0,1.5,2.4,1.9,2.7,0.4c0.1-0.6,0.3-1.3,0.4-1.9c0.1-0.4,0.2-0.7,0.4-1.1
            c0.1-0.2,0.1-0.4,0.2-0.5c-0.1,0.3,0,0,0-0.1c0,0,0.1-0.2,0.2-0.3c0.2-0.4,0.4-0.6,0.5-1c0,0,0.3-0.4,0-0.1c0,0,0.1-0.1,0.1-0.2
            c0.1-0.2,0.3-0.3,0.4-0.4c0.3-0.3-0.3,0.2,0,0l0.2-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2,0-0.1,0c0.2,0,0.3-0.1,0.4-0.1
            c-0.4,0.1,0,0,0,0h0.3c0,0,0.4,0,0.1,0s0,0,0.1,0c0.2,0,0.3,0.1,0.4,0.1c0.3,0.1-0.2-0.1-0.1,0c0,0,0.1,0.1,0.2,0.1
            c0.1,0.1,0.5,0.4,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.4,0.4,0.1,0.1c0.2,0.3,0.4,0.5,0.4,0.8c0,0.1,0.3,0.5,0.1,0.2
            c0,0.1,0.1,0.3,0.2,0.4c0.1,0.3,0.2,0.6,0.3,0.9s0.1,0.5,0.2,0.8c0,0.2,0.2,0.5,0,0.1c0,0.2,0,0.3,0,0.4c0.1,0.6,0.1,1.3,0.1,2
            c0,0.4,0.3,0.9,0.6,1.1c0.4,0.3,0.9,0.3,1.3,0c-0.7,0.3,0.1,0,0.4-0.1c0.3-0.1,0.4-0.1,0.6-0.2h0.2c0,0-0.4,0-0.1,0
            c0.2,0,0.3,0,0.4,0c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0c0.1,0-0.3,0-0.2,0c0,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.4,0.1
            c0.1,0,0.2,0,0.3,0.1c-0.1,0-0.3-0.2,0,0c0.2,0.1,0.3,0.2,0.4,0.3c0.2,0.1-0.3-0.3,0,0c0.1,0.1,0.2,0.1,0.3,0.2
            c0.1,0.1,0.2,0.2,0.2,0.2s-0.2-0.3-0.1-0.1l0.1,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0-0.1,0-0.1c0,0.1,0.1,0.2,0.1,0.3
            c0,0.1,0.1,0.3,0.1,0.4s0.1,0.3,0.1,0.4c0,0.1,0,0.2,0,0.2s0,0.4,0,0.2c0-0.2,0,0.1,0,0.1s0,0.2,0,0.3c0,0.2,0,0.4,0,0.6
            c0,0.4,0,0.9,0,1.3c0,0.7,0.8,1.5,1.6,1.2c0.1,0,0.2,0,0.3-0.1c0.3-0.1,0.4,0.1-0.4,0l-0.7-0.6l0,0l-0.1-0.9l0,0l0.3-0.5
            c0.1-0.1,0.3-0.2,0,0c-0.1,0-0.2,0,0.2,0c-0.2,0,0.4,0,0.2,0l0.6,0.2l0,0l0.5,0.7v0.6c0,0.1-0.3,0.4-0.1,0.3l0.2-0.2
            c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.3-0.2,0,0c0.3-0.2,0.6-0.4,0.9-0.5c0.2-0.1-0.3,0.1,0,0c0.1,0,0.2,0,0.3-0.1
            c0.2,0,0.4-0.1,0.5-0.2c0.1,0,0.2,0,0.3,0s-0.3,0,0,0c0.2,0,0.4,0,0.5,0h0.3c0.2,0-0.3,0,0,0c0.2,0,0.3,0.1,0.4,0.1
            c0.3,0.1-0.2-0.1,0,0c0.1,0,0.2,0.1,0.2,0.1l0.2,0.1c0.3,0.2-0.1-0.1,0.1,0c0.2,0.2,0.3,0.3,0.4,0.4c0.2,0.2-0.1-0.1,0,0
            c0,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0-0.2,0,0.1c0.1,0.3,0.1,0.4,0.2,0.6c0,0.1,0.1,0.4,0.1,0.4c0-0.1,0,0,0,0
            c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0,1c0,0.7-0.2,1.5-0.3,2.2c-0.3,1.2,1.6,1.8,1.9,0.5c0.8-2.7,1.1-6.7-2.1-8.1
            c-1.1-0.4-2.4-0.6-3.5-0.3c-0.5,0.2-1.1,0.4-1.6,0.7c-0.3,0.2-0.5,0.4-0.8,0.6c0.4-0.3-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
            c-0.6,0.7-0.4,1.8,0.5,2.1c0.4,0.1,0.9,0,1.2-0.3c0.6-0.3,0.9-1.1,0.6-1.7c0,0,0,0,0-0.1c-0.4-0.7-1.1-0.7-1.8-0.4l1.6,1.2
            c0.3-2.5-0.1-5.4-2.4-6.9c-1.3-0.9-3-0.9-4.5-0.5c-0.4,0.1-0.8,0.3-1.2,0.4l2,1.1c0.2-2.1-0.3-4.2-1.1-6.1
            c-0.6-1.4-1.9-2.4-3.4-2.7c-2.1-0.4-3.8,0.7-4.8,2.5c-1.1,1.6-1.5,3.5-1.9,5.3l2.7,0.4c0-1.9-0.5-3.9-1.8-5.3
            c-1.2-1.2-2.9-1.8-4.6-1.8l1.3,1.8c0.4-2.8,0.3-5.7-0.6-8.4c-0.6-2.2-1.9-5.3-4.6-5.4c-1.7,0-2.9,1.2-3.9,2.5h2.5
            c-0.6-1.4-1.5-2.9-3-3.6c-1.2-0.6-2.7-0.5-3.9,0.3c-2.5,1.6-3.6,4.8-3.8,7.7c-0.1,1.8,0.3,3.6,1.1,5.3l0.9-2
            c-2.2,0.4-4.3,1.2-5.5,3.1c-1.1,1.7-1.3,3.8-1.4,5.7c0,1.4,0,2.7,0,4.1l1.6-1.2c-1.2-0.2-2.5-0.2-3.6,0.6
            c-0.9,0.6-1.6,1.6-1.8,2.7c-0.3,1.1-0.4,2.2-0.4,3.3c0,0.6,0.1,1.2,0.2,1.8c0,0.3,0.1,0.6,0.2,0.8c0.1,0.3,0.2,0.5,0.3,0.8
            c0.1,0.3,0.4,0.4,0.6,0.3c0.2,0,0.3-0.2,0.3-0.3L359.4,185.1z"
        />
        <path
            fill="#42515A"
            d="M378.2,171.1c0.4-0.6,0.9-1.1,1.5-1.7c0.3-0.2,0.6-0.4,0.9-0.6c0.2-0.1,0.3-0.2,0.4-0.3l0.2-0.1l0.2-0.1
            c0.3-0.1,0.6-0.2,1-0.3c0.2,0,0.4-0.1,0.5-0.1c0,0,0.4,0,0.1,0h0.3c0.6-0.1,1.3-0.1,2,0c0.6,0.1,1.2-0.4,1.3-1s-0.4-1.2-1-1.3
            c-0.1,0-0.3,0-0.4,0c-3.3,0-7.3,1.4-7.8,5.1C377.4,171.5,378.1,171.6,378.2,171.1L378.2,171.1z"
        />
        <path
            fill="#42515A"
            d="M447,109.1c0.9-0.1,1.8-0.1,2.7-0.2c1.1,0,2.1-0.2,3.2-0.3c2.5-0.2,4.8-0.4,7.3-0.9
            c4.9-1,9.9-2.8,13.2-6.7c1.2-1.5,2.5-3.3,2.4-5.4c-0.1-1.6-0.8-3.1-1.9-4.2c-2.2-2.1-5.6-3.2-8.6-2.8c-1.6,0.2-3.2,0.6-4.3,1.8
            c-1.2,1.3-1.7,3.3-1.1,5.1c0.2,0.5,0.4,1,0.7,1.4c0.4,0.8,1.4,1,2.2,0.6c0.6-0.4,0.9-1.1,0.7-1.8c0-0.1,0-0.2,0-0.3
            c0-0.3,0-0.1,0,0.1c0-0.2,0-0.4,0-0.6c0-0.4,0-0.6,0-1c0-0.2,0-0.3,0-0.4c0-0.4,0,0.3,0,0.3s0-0.2,0-0.3c0-0.1,0.1-0.3,0.1-0.4
            c0.1-0.4-0.2,0.4-0.1,0.3c0,0,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.2-0.3c0.1-0.2-0.4,0.4-0.2,0.2c0,0,0.1-0.1,0.2-0.2
            c0.1-0.1,0.2-0.2,0.3-0.3c0.3-0.3-0.4,0.3-0.2,0.2c0.1,0,0.1-0.1,0.2-0.1s0.3-0.2,0.3-0.2c0.2-0.1,0.7-0.3,0,0
            c0.3-0.1,0.5-0.2,0.8-0.3c0.2,0,0.3-0.1,0.4-0.1h0.3c0.4-0.1-0.4,0-0.2,0c0.3,0,0.6,0,1,0c0.3,0,0.6,0,1,0h0.3c0.4,0-0.3,0-0.2,0
            c0.2,0,0.4,0,0.5,0.1c0.6,0.1,1.3,0.3,1.9,0.5c0.3,0.1-0.4-0.2-0.1,0l0.3,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.3,0.2,0.5,0.3,0.8,0.5
            l0.2,0.1c0.1,0-0.4-0.3-0.1-0.1c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.3,0.3,0.4l0.1,0.2c0.1,0.1-0.3-0.4,0,0
            c0.2,0.3,0.3,0.5,0.4,0.7c-0.1-0.1-0.2-0.4-0.1-0.1c0,0.1,0,0.1,0.1,0.2c0,0.2,0.1,0.3,0.1,0.4c0.1,0.3,0-0.4,0-0.2
            c0,0.1,0,0.2,0,0.3c0,0.2,0,0.3,0,0.4c0,0.1,0,0.2,0,0.3c0-0.2,0.1-0.4,0-0.1c0,0.2-0.1,0.4-0.1,0.5s-0.1,0.3-0.2,0.4
            c0,0.2,0.2-0.4,0,0c0,0.1-0.1,0.3-0.2,0.3c-0.3,0.4-0.5,0.9-0.8,1.2c-0.1,0.1-0.3,0.4-0.1,0.1l-0.3,0.3c-0.2,0.3-0.4,0.4-0.6,0.6
            c-0.4,0.4-0.8,0.8-1.2,1.2l-0.3,0.3l-0.1,0.1c0.3-0.2,0.1,0,0,0c-0.3,0.2-0.4,0.4-0.7,0.5c-0.4,0.3-1,0.6-1.5,0.9
            s-1.1,0.5-1.6,0.8l-0.4,0.2c-0.1,0-0.4,0.2-0.2,0.1c-0.3,0.1-0.5,0.3-0.9,0.4c-1.1,0.4-2.2,0.8-3.3,1.1c-4,1.1-8.2,1.5-12.3,2.4
            c-0.4,0.1-0.8,0.2-1.1,0.3C446.3,108.1,446.4,109.2,447,109.1L447,109.1z"
        />
        <path
            fill="#42515A"
            d="M446.3,111.1c0.4,0.1,0.6,0.3,1.1,0.4c0.4,0.1,0.9,0.3,1.2,0.4c1,0.3,1.9,0.4,2.9,0.5c2,0.3,4,0.4,6,0.5
            c1.9,0.1,3.9,0.2,5.9,0.4c0.9,0,1.7,0.1,2.5,0.2c0.4,0,0.7,0.1,1.1,0.1c0.1,0,0.2,0,0.2,0c-0.5,0-0.2,0,0,0c0.2,0,0.4,0.1,0.6,0.1
            c0.7,0.1,1.4,0.3,2,0.5l0.4,0.2c0.3,0.1-0.4-0.2-0.1,0l0.3,0.1c0.3,0.2,0.6,0.3,0.9,0.5c0,0,0.2,0.1,0.3,0.2
            c-0.1-0.2-0.3-0.3-0.1-0.1c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1-0.3-0.4-0.1-0.1c0,0.1,0.1,0.2,0.2,0.3
            c0,0.1,0.2,0.3,0.3,0.4c0.2,0.3-0.1-0.4,0-0.1c0,0.1,0,0.1,0.1,0.2c0,0.2,0.1,0.3,0.1,0.4c0,0.1,0,0.2,0,0.3c-0.1-0.4,0-0.3,0,0
            c0,0.2,0,0.4,0,0.6c0,0.1,0,0.3,0,0.4s0,0.3,0-0.1c0,0.5-0.2,1-0.4,1.5c-0.1,0.3-0.2,0.4-0.3,0.7c-0.2,0.4,0.2-0.4,0,0
            c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.4-0.4,0.7-0.6,1c-0.2,0.3,0.3-0.3,0.1-0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.3,0.3-0.4,0.4
            c-0.1,0.1-0.3,0.3-0.4,0.3c-0.3,0.3,0.4-0.3,0.1,0l-0.3,0.2c-0.3,0.2-0.5,0.3-0.8,0.4c0.4-0.3,0.3-0.1,0.2-0.1l-0.2,0.1
            c-0.2,0-0.4,0.1-0.5,0.2h-0.2c-0.4,0.1,0.4,0,0.1,0c-0.2,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.4,0c-0.4,0,0.5,0.1,0.2,0h-0.3
            c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.1-0.3-0.2,0.2,0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.3-0.2-0.4-0.3l-0.2-0.1
            c-0.3-0.2,0.4,0.4,0.1,0c-0.1-0.1-0.3-0.3-0.4-0.4c0,0-0.1-0.1-0.2-0.2c-0.3-0.3,0.3,0.4,0.1,0.1s-0.4-0.6-0.6-1
            c-0.1-0.2-0.4-0.8-0.1-0.2c-0.1-0.2-0.2-0.4-0.3-0.6c0,0,0-0.1-0.1-0.2c-0.1-0.2-0.1-0.2,0.1,0.2c0-0.3-0.2-0.4-0.3-0.7
            c-0.1-0.4-0.3-0.6-0.4-1c-0.6-1.2-2.5-0.9-2.9,0.4c-0.6,2.1,0.3,4.4,1.8,5.9c1.2,1.2,2.8,1.9,4.6,1.9c3.8,0,6.6-3.2,7.4-6.6
            c0.5-1.9,0.5-4-0.6-5.8c-1-1.5-2.5-2.5-4.2-3.1c-1.6-0.5-3.2-0.8-4.8-0.9c-1.9-0.2-3.9-0.2-5.8-0.2c-2.5,0-4.9,0-7.4,0
            c-1.1,0-2.3,0-3.4,0.1c-0.5,0-1.1,0.1-1.6,0.1c-0.4,0.1-1,0.1-1.5,0.2c-0.3,0-0.4,0.2-0.4,0.4C446,110.9,446.1,111,446.3,111.1
            L446.3,111.1z"
        />
        <path
            fill="#42515A"
            d="M468,108.1c2,0,4-0.3,6-0.4c1.1-0.1,2.1-0.1,3.2,0h0.4c0.4,0-0.3,0,0.2,0c0.3,0,0.5,0.1,0.7,0.2
            c0.3,0.1,0.4,0.1,0.6,0.2c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.5,0.3,0.3,0.1c0.4,0.2,0.9,0.4,1.2,0.7c-0.3-0.2,0,0,0.1,0.1l0.2,0.2
            c0.3,0.3,0.4,0.4,0.7,0.7c-0.2-0.3,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.3,0.3,0.4,0.4,0.6c0.1,0.2,0,0,0,0
            c0,0.1,0.1,0.3,0.1,0.3c0.1,0.3,0.2,0.5,0.2,0.7c0.1,0.4,0-0.4,0,0c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0,0.6c0,0,0.1-0.4,0-0.1
            c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.3l-0.1,0.2c-0.1,0.2,0,0.1,0-0.1c-0.1,0.3-0.3,0.6-0.4,0.8c0.2-0.1,0.2-0.3,0,0
            c0,0-0.1,0.1-0.2,0.2v0.1c-0.3,0.3,0.3-0.1,0,0c-0.1,0-0.1,0.1-0.2,0.1l-0.1,0.1c-0.2,0.1-0.1,0,0.2-0.1c-0.1,0-0.3,0-0.4,0.1
            c-0.3,0.1,0.4,0,0.2,0c-0.1,0-0.2,0-0.3,0c-0.3,0,0.4,0.1,0.2,0s-0.2-0.2,0.1,0c-0.1-0.1-0.3-0.2-0.4-0.2
            c-0.3-0.2,0.3,0.3,0.1,0.1c0,0-0.3-0.3-0.2-0.3c0,0,0.3,0.4,0,0c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.3-0.4-0.4-0.6
            c-0.6-1.1-2.5-0.6-2.4,0.6c0,1.2,0.5,2.4,1.5,3c0.8,0.5,1.8,0.6,2.7,0.4c1.8-0.5,3.2-2.3,3.4-4.1c0.4-2.6-0.9-5.1-2.9-6.7
            c-1.9-1.5-4.6-2-7.1-1.8c-2.7,0.3-5.3,1-8,1.9c-0.3,0.1-0.4,0.4-0.4,0.7C467.5,108,467.7,108.1,468,108.1L468,108.1z"
        />
        <path fill="#42515A" d="M436.4,246L436.4,246L436.4,246L436.4,246L436.4,246z" />
        <path
            fill="#42515A"
            d="M446.5,254.5c0.2-0.2,0.4-0.4,0.6-0.5c0.2-0.2,0.4-0.4,0.6-0.6c0.6-0.4,1.2-0.7,1.8-1
            c1.6-0.6,3.3-1.1,5-1.3c0.4-0.1,0.8-0.2,1.2-0.2l0.5-0.1h0.1h0.4c0.6-0.1,1.2-0.1,1.8-0.2c2.1-0.2,4.3,0,6.3,0.5
            c1.2,0.3,2.5,0.7,3.7,1.2c0.6,0.3,1.1,0.6,1.8,0.9c0.6,0.4,1.1,0.9,1.8,1c0.6,0.2,1.3-0.3,1.5-0.9c0.1-0.3,0-0.6-0.1-0.9
            c-0.4-0.6-0.9-1.1-1.6-1.3c-0.5-0.4-1.1-0.6-1.7-0.9c-1.2-0.5-2.4-1-3.7-1.3c-2.5-0.6-5.1-0.9-7.6-0.7c-1.5,0.1-2.9,0.4-4.4,0.6
            c-1.8,0.3-3.6,0.9-5.3,1.6c-1.5,0.7-3.4,1.8-3.7,3.5C445.7,254.5,446.2,254.9,446.5,254.5L446.5,254.5z"
        />
        <path
            fill="#42515A"
            d="M443.5,249.8c0-0.5,0.5-0.3,0.1-0.4c-0.1,0-0.2,0-0.3,0l0.4,0.1l0,0c-0.4-0.2-0.9-0.1-1.1,0.3
            c-0.2,0.2-0.2,0.4-0.1,0.7c0.3,0.9,1.8,0.6,1.5-0.4c-0.4-1.5-0.9-2.9-1.2-4.3c-0.2-0.7-0.4-1.4-0.5-2.1c-0.2-0.7-0.3-1.3-0.4-1.9
            c-0.4-2.5-0.2-5.2,0.6-7.7c0.4-1.4,1.1-2.7,1.8-3.9c0.4-0.8,0.9-1.5,1.4-2.3c0.1-0.2,0.2-0.3,0.3-0.4l0.2-0.3c0,0,0.2-0.2,0,0
            c-0.1,0.2,0,0,0,0l0.2-0.2c0.1-0.2,0.3-0.3,0.4-0.4c0.4-0.4,0.9-0.9,1.3-1.3c0.3-0.2,0.4-0.4,0.7-0.6l0.2-0.2c0,0,0.2-0.2,0,0
            c-0.2,0.1,0,0,0.1,0l0.3-0.2c1.1-0.7,2.1-1.3,3.2-1.8c-0.3,0.1,0.1,0,0.2-0.1l0.4-0.2c0.3-0.1,0.6-0.3,1-0.3
            c0.6-0.2,1.2-0.4,1.8-0.4c0.1,0,0.3,0,0.4-0.1h0.3h0.3h0.5c0.6,0,1.3-0.1,1.9,0c0.3,0,0.6,0,1,0.1h0.4c0,0,0.3,0,0.1,0
            s0.3,0,0.2,0c0.6,0.1,1.3,0.3,1.9,0.4c0.7,0.2,1.3,0.4,2,0.6c0.1,0,0.5,0.2,0.3,0.1c0.1,0.1,0.3,0.1,0.4,0.2
            c0.4,0.2,0.7,0.3,1,0.5c0.5,0.3,1.1,0.6,1.6,1c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0,0-0.1,0c0.1,0,0.1,0.1,0.2,0.2
            c0.3,0.2,0.5,0.4,0.8,0.6c1,0.9,1.8,1.8,2.7,2.9c0,0,0.1,0.1,0.1,0.2c0.2,0.2-0.1-0.2,0,0s0.2,0.3,0.3,0.4
            c0.2,0.3,0.4,0.5,0.6,0.9c0.4,0.6,0.8,1.2,1.1,1.8c0.4,0.6,0.6,1.2,0.9,1.8c0,0.1,0.1,0.1,0.1,0.2s0.1,0.3,0.1,0.1
            c0-0.2,0,0,0,0.1l0.1,0.3c0.1,0.3,0.3,0.6,0.3,0.9c0.2,0.6,0.4,1.2,0.5,1.8l0.1,0.4c0,0.1,0.1,0.5,0,0.3c0-0.3,0,0.3,0,0.3
            c0,0.2,0,0.3,0,0.4c0,0.7,0,1.4-0.1,2.1c0,0.2,0,0.4,0,0.5c0,0.1-0.1,0.5,0,0.3c0,0.4-0.1,0.8-0.2,1.1c-0.2,0.7-0.4,1.5-0.6,2.1
            c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.2-0.1,0.4-0.2,0.5c0,0-0.1,0.3,0,0l-0.1,0.3c-0.6,1.3-1.3,2.6-2.2,3.8c-0.4,0.6-0.9,1.1-1.2,1.8
            c-0.4,0.6-0.2,1.4,0.4,1.8c0.5,0.4,1.3,0.4,1.8-0.1c2.3-2.1,3.9-5.3,4.8-8.1c0.9-2.9,1.3-6.1,0.7-9.1c-0.5-2.7-1.8-5.3-3.4-7.7
            c-1.6-2.5-3.7-4.6-6-6.4c-2.4-1.7-5.2-2.7-8.1-3.2c-2.9-0.4-5.9-0.3-8.7,0.6c-2.7,0.9-5.2,2.3-7.3,4.2c-2.3,2.2-4.1,4.8-5.3,7.7
            c-1.2,2.9-1.8,6-1.7,9.1c0.3,3.1,1.1,6,2.4,8.8c0.4,0.7,0.8,1.4,1.1,2.1l1.3-0.8l0,0l0.1,0.4l0,0l-0.1,0.4
            c0.1-0.2,0.2-0.4,0.1-0.6l-0.1,0.6c-0.1,0.1,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.2,0.2-0.3C443.7,250.3,443.6,249.9,443.5,249.8
            c-0.2-0.1-0.4,0-0.6,0.2C443.6,249.7,443.5,249.8,443.5,249.8L443.5,249.8z"
        />
        <path
            fill="#42515A"
            d="M459.4,236.5v0.3c0-0.1,0,0,0,0.1c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.1,0.1s-0.3,0.3-0.2,0.1
            l-0.3,0.1c0.4-0.1,0.6-0.3,0.8-0.6l-0.4,0.4c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.3,0.2-0.4,0.2-0.7c0-0.5-0.3-1-0.7-1.2
            c-0.4-0.3-0.9-0.4-1.4-0.3c-0.3,0.1-0.4,0.2-0.7,0.3c-0.3,0.2-0.6,0.4-0.9,0.6c-0.5,0.5-0.9,1.1-1.1,1.8c-0.3,0.7-0.3,1.4,0,2.1
            c0.2,0.4,0.4,0.9,0.8,1.2c0.4,0.4,0.9,0.6,1.3,0.8c0.8,0.3,1.8,0.4,2.5,0.2c0.8-0.2,1.5-0.7,1.9-1.3c0.9-1.1,0.7-2.7,0-3.9
            c-0.4-0.7-1-1.2-1.8-1.7c-0.4-0.2-0.8-0.4-1.2-0.4c-0.6-0.2-1.3-0.2-1.9,0c-0.7,0.3-1.2,0.9-1.2,1.7c0,0.2,0,0.3,0.1,0.4
            c0.2,0.6,0.6,1.1,1.2,1.2l0.6,0.1l-0.4-0.1c0.3,0,0.5,0.1,0.9,0.1c0.1,0,0.3,0,0.4,0l-0.4-0.1c0.3,0,0.5,0.1,0.8,0.3l-0.4-0.2
            c0.3,0.1,0.4,0.3,0.6,0.4l-0.4-0.3c0.2,0.2,0.3,0.3,0.4,0.4l-0.3-0.4c0.1,0.2,0.3,0.4,0.3,0.5l-0.2-0.4c0.1,0.2,0.2,0.4,0.2,0.6
            l-0.1-0.4c0,0.2,0,0.4,0,0.5l0.1-0.4c0,0.2-0.1,0.3-0.1,0.4l0.2-0.4c0,0.1-0.1,0.3-0.2,0.4l0.3-0.4c-0.1,0.1-0.2,0.2-0.3,0.3
            l0.4-0.3c-0.1,0.1-0.3,0.2-0.4,0.2l0.4-0.2c-0.2,0.1-0.3,0.1-0.4,0.1l0.4-0.1c-0.3,0-0.6,0-0.9,0l0.4,0.1c-0.3,0-0.4-0.1-0.7-0.2
            l0.4,0.2c-0.2-0.1-0.3-0.2-0.4-0.3l0.4,0.3c-0.1-0.1-0.2-0.2-0.3-0.3l0.3,0.4c-0.1-0.1-0.2-0.3-0.2-0.4l0.2,0.4
            c-0.1-0.2-0.1-0.3-0.1-0.5l0.1,0.4c0-0.2,0-0.4,0-0.5l-0.1,0.4c0-0.3,0.1-0.4,0.2-0.7l-0.2,0.4c0.1-0.3,0.3-0.4,0.4-0.6l-0.3,0.3
            c0.2-0.3,0.4-0.4,0.6-0.6l-0.3,0.3c0.1-0.1,0.2-0.2,0.3-0.2l-1.1,0.2c0.1,0,0.2,0,0.3,0c0.4,0.1,0.8,0,1.1-0.2l-0.4,0.2l0,0h-0.4
            l0,0h-0.4l0,0l-0.3-0.1l0,0l-0.6-0.8c0,0.3,0.2,0.4,0.4,0.6l-0.4-0.6c0.1,0.5,0.5,0.9,1,1.1c0.6,0.3,1.3-0.4,1.3-1L459.4,236.5z"
        />
        <path
            fill="#42515A"
            d="M466.5,230.2c-1.3,1.1-2.7,2.1-4,3.1c-0.6,0.4-1.2,0.9-1.8,1.3c-0.4,0.3-0.8,0.6-1.2,0.9
            c-0.8,0.5-1.6,1-1.7,2c0,0.8,0.6,1.2,1.3,1.3c0.9,0.1,1.5-0.7,2.1-1.2c0.4-0.3,0.7-0.6,1.1-0.9c0.6-0.5,1.2-1,1.8-1.5
            c1.3-1.2,2.5-2.5,3.5-3.9c0.3-0.3,0.3-0.7,0-1C467.2,230,466.8,230,466.5,230.2L466.5,230.2z"
        />
    </svg>
);

export default IaqInsightIllustration;
