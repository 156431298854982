import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { colors, dateFormats } from 'commons/src/constants';
import { BuildingType, ErrorType } from 'commons/src/models/commonTypeScript';
import { PresenceWeekAggregation } from '../../../models/buildingModels';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import { getSortedWeek } from '../../reports/insightFunctions';
import styles from './OpeningHoursComparison.module.scss';
import openingHoursComparisonConfig from './openingHoursComparisonConfig';

type StateProps = {
    buildings: { [buildingId: string]: BuildingType };
    error?: ErrorType;
};

type ParentProps = {
    buildingId: string;
    presenceWeekAggregation?: PresenceWeekAggregation;
    dateFormat: keyof typeof dateFormats;
};

export type Bands = {
    color: string;
    from: number;
    to: number;
}[];

type Props = ParentProps & StateProps;

const OpeningHoursComparison = ({
    buildings,
    buildingId,
    dateFormat,
    presenceWeekAggregation,
    error,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const weekDayArray = [
        txt('Monday'),
        txt('Tuesday'),
        txt('Wednesday'),
        txt('Thursday'),
        txt('Friday'),
        txt('Saturday'),
        txt('Sunday'),
    ];
    const building = buildings[buildingId];
    const usageHours = building?.usageHours;
    const sortedWeek = usageHours ? getSortedWeek(usageHours) : [];

    const plotBands = usageHours
        ? sortedWeek.reduce((bands: Bands, day, index) => {
              const usageHoursForDay = usageHours[day];
              if (!usageHoursForDay || usageHoursForDay.closed || !usageHoursForDay.from || !usageHoursForDay.to) {
                  return bands;
              }
              const fromTimeAsArray = usageHoursForDay.from.split(':');
              const toTimeAsArray = usageHoursForDay.to.split(':');
              const fromHour = parseInt(fromTimeAsArray[0], 10) - 1 + 24 * index;
              const toTimeMinutes = parseInt(toTimeAsArray[1], 10);
              const toHour = parseInt(toTimeAsArray[0], 10) - (toTimeMinutes > 0 ? 0 : 1) + 24 * index;

              return [
                  ...bands,
                  {
                      to: toHour,
                      from: fromHour,
                      color: colors.greyPorcelain,
                  },
              ];
          }, [])
        : [];

    const chartData = presenceWeekAggregation?.usage?.map(point => [point.weekHour, point.roomsInUse]) || [];
    const graphConfig = openingHoursComparisonConfig({ chartData, weekDayArray, plotBands });
    const peakHourAtDay = presenceWeekAggregation && presenceWeekAggregation.peakWeekHour % 24;
    const dayOfPeakHour =
        presenceWeekAggregation && peakHourAtDay && (presenceWeekAggregation.peakWeekHour - peakHourAtDay) / 24;
    const formattedTime = moment(`${peakHourAtDay}:00`, 'h:mm').format(dateFormats[dateFormat].timeOfDay);
    const formattedToTime =
        peakHourAtDay && moment(`${peakHourAtDay + 1}:00`, 'h:mm').format(dateFormats[dateFormat].timeOfDay);

    return (
        <div className={styles.graphCard}>
            <h4 className={styles.header}>{txt('PresenceInsight.BuildingUsageTrend')}</h4>
            <p className={styles.descriptionParagraph}>{txt('PresenceInsight.BuildingUsageTrendDescription')}</p>
            {error ? (
                <ResponseBox text={error.error} />
            ) : (
                <div className={styles.contentWrapper}>
                    <div className={styles.infoWrapper}>
                        <div className={styles.infoSection}>
                            <label className={styles.label} htmlFor="roomsInUse">
                                {txt('PresenceInsight.SpacesInUse')}
                            </label>
                            <div id="roomsInUse">
                                {presenceWeekAggregation?.roomsUsed}/{presenceWeekAggregation?.totalRoomsInBuilding}
                            </div>
                        </div>
                        <div className={styles.infoSection}>
                            <label className={styles.label} htmlFor="peakDay">
                                {txt('PresenceInsight.PeakDay')}
                            </label>
                            <div id="peakDay">
                                {presenceWeekAggregation?.peakWeekDay ? txt(presenceWeekAggregation?.peakWeekDay) : '-'}
                            </div>
                        </div>
                        <div className={styles.infoSection}>
                            <label className={styles.label} htmlFor="peakHour">
                                {txt('PresenceInsight.PeakHour')}
                            </label>
                            <div id="peakHour">
                                {dayOfPeakHour
                                    ? `${weekDayArray[dayOfPeakHour]} ${formattedTime} - ${formattedToTime}`
                                    : '-'}
                            </div>
                        </div>
                    </div>
                    <div className={styles.graphWrapper}>
                        <div className={styles.scrollWrapper}>
                            <div className={styles.graph}>
                                <HighchartsReact highcharts={Highcharts} options={graphConfig} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        buildings: { buildings },
        requests: {
            [RequestType.GetPresenceWeekAggregation]: { error },
        },
    } = store;
    return {
        buildings,
        error,
    };
};

export default connect(mapStateToProps)(OpeningHoursComparison);
