import { all, call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import { selectGroup } from 'commons/src/actions/selectGroupActions';
import { fetchUserInfo } from 'commons/src/actions/SettingsActions';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { ACCOUNT_REMOVE_USER } from 'commons/src/analytics/AnalyticsEvents';
import { getSelectedGroupFromStorage } from 'commons/src/components/findUserType';
import { getSelectedGroup } from 'commons/src/features/group';
import { InviteStatus, Role } from 'commons/src/models/commonEnums';
import { Group, SelectedGroup } from 'commons/src/models/commonTypeScript';
import RequestActions from 'commons/src/models/RequestTypes';
import displayAlertBoxSaga from 'commons/src/sagas/displayAlertBox';
import { toErrorType, toErrorTypeWithMessage } from 'commons/src/sagas/isErrorType';
import {
    ChangeMembershipRole,
    changeMembershipRoleSuccess,
    DeleteInvite,
    deleteInviteError,
    deleteInviteSuccess,
    getOrganizationMembersError,
    GetOrganizationMembersError,
    getOrganizationMembersSuccess,
    GetOrganizationMembersSuccess,
    OrganizationMemberActionType,
    RemoveMember,
    removeMemberError,
    removeMemberSuccess,
    ResendInvite,
    SendInvite,
    sendInviteError,
    SendInviteError,
    sendInviteSuccess,
    SendInviteSuccess,
} from '../actions/organizationMemberActions';
import {
    GetOrganizationLogoError,
    getOrganizationLogoError,
    GetOrganizationLogoSuccess,
    getOrganizationLogoSuccess,
    OrganizationPropertiesActionType,
    UpdateOrganizationLogoInit,
    UpdateOrganizationLogoSuccess,
    updateOrganizationLogoSuccess,
    UpdateOrganizationPropertiesInit,
} from '../actions/organizationPropertiesActions';
import getOrganizationMembers, {
    changeMembershipRole,
    deleteInvite,
    getOrganizationLogo,
    inviteMember,
    removeMember,
    resendInvite,
    updateOrganizationLogo,
    updateOrganizationProperties,
} from '../api/organizationsApi';
import { GetOrganizationLogoResponse, GetOrganizationResponse } from '../models/common';
import { Store } from '../reducers';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type GetOrganizationSagaReturnType = Generator<
    | CallEffect<GetOrganizationResponse>
    | PutEffect<GetOrganizationMembersSuccess>
    | PutEffect<GetOrganizationMembersError>,
    void,
    GetOrganizationResponse
>;
function* getOrganizationSaga(): GetOrganizationSagaReturnType {
    try {
        const response = yield call(getOrganizationMembers);
        yield put(getOrganizationMembersSuccess(response));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(getOrganizationMembersError(errorAsErrorType));
    }
}

function* resendInviteSaga({ inviteId }: ResendInvite): Generator {
    try {
        yield call(resendInvite, inviteId);
        yield put(requestSuccess(RequestType.ResendInvite, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.ResendInvite, RequestActionType.Error));
    }
}

type SendInviteSagaReturnType = Generator<
    CallEffect | PutEffect<SendInviteSuccess> | PutEffect<SendInviteError>,
    void,
    { inviteId: string }
>;
function* sendInviteSaga({ body }: SendInvite): SendInviteSagaReturnType {
    try {
        const { inviteId } = yield call(inviteMember, body);
        yield put(
            sendInviteSuccess({
                inviteId,
                invitedUserName: body.name,
                invitedUserEmail: body.email,
                status: InviteStatus.PENDING,
                role: body.role as Role,
            })
        );
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(sendInviteError(errorAsErrorType));
    }
}

function* deleteInviteSaga({ inviteId }: DeleteInvite): Generator {
    try {
        yield call(deleteInvite, inviteId);
        yield put(deleteInviteSuccess(inviteId));
    } catch (e) {
        const errorAsErrorType = toErrorType(e);
        yield put(deleteInviteError(errorAsErrorType));
    }
}

type RemoveMemberSagaReturnType = Generator<SelectEffect | PutEffect | CallEffect, void, Group[] & string>;
export function* removeMemberSaga({ userId }: RemoveMember): RemoveMemberSagaReturnType {
    try {
        const targetUser = userId;
        const subjectUser = yield select((store: Store) => store.userSettings.userId);
        yield call(removeMember, userId);
        if (subjectUser === targetUser) {
            // Removed self from organization, must now change organization

            const selectedGroup: SelectedGroup | null = getSelectedGroupFromStorage();

            const groups: Group[] = yield select((store: Store) => store.userSettings.groups);
            const otherOrganizationGroup = groups.filter(
                group => group.organizationId && group.organizationId !== selectedGroup?.organizationId
            )[0];
            const nextSelectedGroup: SelectedGroup = getSelectedGroup(otherOrganizationGroup);
            yield put(selectGroup(nextSelectedGroup));
        }
        analyticsLogger(ACCOUNT_REMOVE_USER, { pageType: PageType.Account });
        yield put(removeMemberSuccess(userId));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(removeMemberError(errorAsErrorType));
    }
}

type GetOrganizationLogoSagaReturnType = Generator<
    | CallEffect<GetOrganizationLogoResponse>
    | PutEffect<GetOrganizationLogoSuccess>
    | PutEffect<GetOrganizationLogoError>,
    void,
    GetOrganizationLogoResponse
>;
function* getOrganizationLogoSaga(): GetOrganizationLogoSagaReturnType {
    try {
        const response = yield call(getOrganizationLogo);
        yield put(getOrganizationLogoSuccess(response));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(getOrganizationLogoError(errorAsErrorType));
    }
}

type UpdateOrganizationLogoSagaReturnType = Generator<
    CallEffect<GetOrganizationLogoResponse> | PutEffect<UpdateOrganizationLogoSuccess> | RequestActions,
    void,
    GetOrganizationLogoResponse
>;
function* updateOrganizationLogoSaga({
    fileName,
    logoImage,
}: UpdateOrganizationLogoInit): UpdateOrganizationLogoSagaReturnType {
    try {
        yield call(updateOrganizationLogo, fileName, logoImage);
        yield put(requestSuccess(RequestType.UpdateOrganizationProperties, RequestActionType.Success));
        yield put(updateOrganizationLogoSuccess({ logoFileName: fileName, logoImage }));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.UpdateOrganizationLogo, RequestActionType.Error));
    }
}

function* updateOrganizationPropertiesSaga({ properties }: UpdateOrganizationPropertiesInit): Generator {
    try {
        yield call(updateOrganizationProperties, properties);
        yield put(requestSuccess(RequestType.UpdateOrganizationProperties, RequestActionType.Success));
        yield put(fetchUserInfo());
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.UpdateOrganizationProperties, RequestActionType.Error));
    }
}

function* changeMembershipRoleSaga({ userId, payload }: ChangeMembershipRole): Generator {
    try {
        yield call(changeMembershipRole, userId, payload);
        yield put(changeMembershipRoleSuccess(userId, payload));
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(requestError(errorWithMessage.error, RequestType.ChangeMembershipRole, RequestActionType.Error));
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

export default function* requestSagas(): Generator {
    yield all([
        takeEvery(OrganizationMemberActionType.GetOrganizationMembersInit, getOrganizationSaga),
        takeEvery(OrganizationMemberActionType.SendInviteInit, sendInviteSaga),
        takeEvery(OrganizationMemberActionType.ResendInviteInit, resendInviteSaga),
        takeEvery(OrganizationMemberActionType.DeleteInviteInit, deleteInviteSaga),
        takeEvery(OrganizationMemberActionType.RemoveMemberInit, removeMemberSaga),
        takeEvery(OrganizationMemberActionType.ChangeMembershipRoleInit, changeMembershipRoleSaga),
        takeEvery(OrganizationPropertiesActionType.GetOrganizationLogoInit, getOrganizationLogoSaga),
        takeEvery(OrganizationPropertiesActionType.UpdateOrganizationLogoInit, updateOrganizationLogoSaga),
        takeEvery(OrganizationPropertiesActionType.UpdateOrganizationPropertiesInit, updateOrganizationPropertiesSaga),
    ]);
}
