import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import CircleButton from '../../components/buttons/CircleButton';
import TimePeriodSelector from '../../components/buttons/TimePeriodSelector';
import { SelectedPeriod } from '../../models/commonTypeScript';

export type Props = {
    selectGraphPeriod: (selectedPeriod: SelectedPeriod, isCustom?: boolean) => void;
    selectedPeriod: SelectedPeriod;
    onResize: (e: React.SyntheticEvent<HTMLElement>) => void;
    dateFormat: string;
    resetZoom: () => void;
    isExpanded: boolean;
    isMobile: boolean;
    loading: boolean;
    endedSegment: boolean;
    segmentStartDate?: string;
    segmentEndDate?: string;
    zoomActive: boolean;
    timeZone?: string;
};

export const OptionsRowComponent = (props: Props): React.ReactElement => {
    const {
        selectGraphPeriod,
        selectedPeriod,
        onResize,
        isExpanded,
        isMobile,
        dateFormat,
        loading,
        endedSegment,
        segmentEndDate,
        segmentStartDate,
        resetZoom,
        zoomActive,
        timeZone,
    } = props;
    const { t: txt } = useTranslation();

    return (
        <div
            className={classNames('single-device__options-row', 'elevation-6', {
                'single-device__options-row--fit-height': isMobile,
            })}
        >
            {!isMobile && (
                <div className="single-device__header-row">
                    <div className="single-device__header-row__real-time">
                        <span className="single-device__header-row__text">
                            {txt(`PeriodTypes.${selectedPeriod.name}Avg`)}
                        </span>
                    </div>
                    <div className="single-device__header-row__historical">
                        <CircleButton
                            onClick={onResize}
                            color="tertiary"
                            testAttr="resize"
                            iconName={isExpanded ? 'unfold_less' : 'unfold_more'}
                        />
                        <span className="single-device__header-row__text">{txt('HistoricalData')}</span>
                    </div>
                    {zoomActive && (
                        <div className="single-device__header-row__reset-zoom">
                            <CircleButton
                                onClick={resetZoom}
                                color="tertiary"
                                testAttr="resize"
                                iconName="zoom_out_map"
                            />
                            <span className="single-device__header-row__button-text">{txt('ResetZoom')}</span>
                        </div>
                    )}
                </div>
            )}

            <div
                className={classNames('options-row__action-container', {
                    'options-row__action-container--full-width': isMobile,
                })}
            >
                <TimePeriodSelector
                    selectGraphPeriod={selectGraphPeriod}
                    selectedPeriod={selectedPeriod}
                    dateFormat={dateFormat}
                    isMobile={isMobile}
                    loading={loading}
                    endedSegment={endedSegment}
                    segmentEndDate={segmentEndDate}
                    segmentStartDate={segmentStartDate}
                    timeZone={timeZone}
                />
            </div>
        </div>
    );
};

export default OptionsRowComponent;
