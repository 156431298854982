import React from 'react';
import { withTranslation, I18nextProvider } from 'react-i18next';
import AntdConfigProvider from './AntdConfigProvider';
import i18n from './index';

type Props = {
    children?: React.ReactElement;
};

const IntlWrapper = ({ children }: Props): React.ReactElement => {
    return (
        <AntdConfigProvider>
            <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
        </AntdConfigProvider>
    );
};

export default withTranslation()(IntlWrapper);
