import React from 'react';
import { DeviceType } from '../../../models/commonTypeScript';
import DeviceListElement from '../DeviceTile/DeviceListElement';

export type Props = {
    devices: DeviceType[];
    url?: string;
};

export const LocationDevicesComponent = ({ devices, url }: Props): React.ReactElement => {
    const renderLocationDevices = (deviceList: DeviceType[]): React.ReactElement[] =>
        deviceList.map((device: DeviceType) => (
            <DeviceListElement key={`device-list-element-${device.serialNumber}`} device={device} url={url} />
        ));

    return <>{renderLocationDevices(devices)}</>;
};

export default LocationDevicesComponent;
