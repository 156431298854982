import React from 'react';
import { connect } from 'react-redux';
import SimpleTreeView from 'commons/src/components/lists/TreeView/SimpleTreeView';
import { LocationType } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';

export type ParentProps = {
    setSelectedLocations: (locations: string[]) => void;
    selectedLocations: string[];
};

type StateProps = {
    locations: LocationType[];
    loading: boolean;
};

export type Props = StateProps & ParentProps;

export const LocationsSelectorComponent = ({
    locations,
    setSelectedLocations,
    selectedLocations,
    loading,
}: Props): React.ReactElement => {
    const updateSelection = ({ id, changeAll }: { id: string; changeAll: boolean }): void => {
        let selectedItems: string[] = selectedLocations;
        if (changeAll) {
            if (selectedLocations.length === locations.length) {
                selectedItems = [];
            } else {
                selectedItems = locations.map(loc => loc.id);
            }
        } else if (selectedItems.includes(id)) {
            selectedItems = [...selectedItems].filter(el => el !== id);
        } else {
            selectedItems = [...selectedItems, id];
        }
        setSelectedLocations(selectedItems);
    };

    if (loading) return <div />;
    return (
        <SimpleTreeView
            listId="locations-selector"
            options={locations.map(it => ({ name: it.name, id: it.id }))}
            selectedOptions={selectedLocations}
            onChange={updateSelection}
        />
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { locations, loading },
    } = state;
    return {
        locations,
        loading,
    };
};

export default connect(mapStateToProps)(LocationsSelectorComponent);
