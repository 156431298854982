import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { hubConnectionIsUp, lastSyncedDate } from '../../commonFunctions';
import MaterialIcon from '../../components/MaterialIcon';
import { dateFormats } from '../../constants';
import Ethernet from '../../img/icon-ethernet';

export type Props = {
    name: string;
    dateFormat: string;
    serialNumber: string;
    lastSeen?: string;
    numberOfConnectedDevices: number;
    numberOfDevices: number;
    connectionTypeCell: boolean | undefined;
    spaceId?: string;
    locationId: string;
};

const HubTile = (props: Props): React.ReactElement => {
    const {
        name,
        serialNumber,
        lastSeen,
        connectionTypeCell,
        dateFormat,
        numberOfConnectedDevices,
        numberOfDevices,
        spaceId,
        locationId,
    } = props;
    const { t: txt } = useTranslation();
    const hubRecentlySynced = hubConnectionIsUp(lastSeen);
    const numberOfLostDevices = hubRecentlySynced ? numberOfDevices - numberOfConnectedDevices : 0;
    const lastSynced = lastSeen && lastSyncedDate(lastSeen, dateFormat as keyof typeof dateFormats);
    const linkToSpaces = `/buildings/${locationId}/spaces`;
    const to = spaceId ? `${linkToSpaces}/${spaceId}` : linkToSpaces;

    return (
        <NavLink to={to} className="tile-wrapper__link page-wrapper__column" data-tile>
            <div className="tile-wrapper">
                <div className="tile-header">
                    <div className="tile-header__title">
                        <h2 className="tile-header__title--name">{name}</h2>
                        <span className="tile-header__title--address">{serialNumber}</span>
                        {!hubRecentlySynced && (
                            <div className="tile-header__last-synced">
                                {[txt('LastConnection'), ': ', lastSeen ? lastSynced : txt('neverSynced')]}
                            </div>
                        )}
                    </div>
                    <div className="tile-header__controls">
                        <div className="tile-header__info">
                            <div className="tile-header__info--chip">{txt('Hub')}</div>
                            <div className="battery-container battery-container--hub">
                                <MaterialIcon name={hubRecentlySynced ? 'cloud' : 'cloud_off'} />
                                {connectionTypeCell !== undefined && (
                                    <div className="hub-info-header__content__specs__container">
                                        {connectionTypeCell ? (
                                            <MaterialIcon extraClass="icon-rotated" name="sim_card" />
                                        ) : (
                                            <Ethernet />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tile-body">
                    <div className="tile-body__hub">
                        {numberOfLostDevices > 0 && (
                            <div className="sensor__line sensor__line--alert sensor__line--bottom" />
                        )}
                        <div className="tile-body--table">
                            <div>
                                <span className="building-tile__content__text--large">
                                    {hubRecentlySynced ? numberOfConnectedDevices : 0}
                                </span>{' '}
                                {txt('HubStrings.ConnectedDevices')}
                            </div>
                            <div>
                                {numberOfLostDevices > 0 && (
                                    <>
                                        <span className="building-tile__content__text--large">
                                            {numberOfLostDevices}
                                        </span>{' '}
                                        {txt('HubStrings.LostRecently')}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </NavLink>
    );
};

export default HubTile;
