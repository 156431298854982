import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { sensorRating } from 'commons/src/commonFunctions';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import { sensorUnits } from 'commons/src/constants';
import { Rating } from 'commons/src/models/commonEnums';
import { SensorThresholds } from 'commons/src/models/commonTypeScript';
import { IaqInsightData } from '../../../models/common';

type Props = {
    sensor: string;
    sensorReports: { [sensor: string]: IaqInsightData };
    thresholds: { [sensor: string]: SensorThresholds };
};
export const SensorAverageComponent = (props: Props): React.ReactElement => {
    const { sensor, sensorReports, thresholds } = props;
    const { t: txt } = useTranslation();

    const sensorDetails: IaqInsightData = sensorReports[sensor];
    const value = sensorDetails.averageValueWithinOpeningHours;
    const sensorThreshold = thresholds[sensor];
    const sensorHasValue = value !== undefined && value !== null && !Number.isNaN(value);
    const valueRange: Rating | undefined = sensorRating(sensorThreshold.ranges, value);
    const displayedUnit = sensorUnits[sensorThreshold.unit];
    const displayedValue = value && (displayedUnit === sensorUnits.pci ? value.toFixed(1) : value.toFixed(0));
    return (
        <div
            key={`${sensor}-average`}
            className="insight-tile__content insight-tile__content--sensor insight-tile__content--sensor--padding-bottom"
        >
            <div
                className={classNames('list-tile__status-circle list-tile__status-circle--large', {
                    'sensor__line--ok': valueRange === Rating.GOOD,
                    'sensor__line--alert': valueRange === Rating.POOR,
                    'sensor__line--warning': valueRange === Rating.FAIR,
                    'sensor__line--disabled': valueRange === undefined,
                })}
            >
                <div className="list-tile__status-circle__middle list-tile__status-circle__middle--large" />
                <div className="list-tile__status-circle__content">
                    <SensorIcon sensor={sensor} />
                </div>
            </div>
            <div className="sensor__data">
                <span className="insight-sensor__value">
                    {sensorHasValue ? displayedValue : txt('NotAvailableAbbreviation')}
                    <span className="sensor__unit">{displayedUnit}</span>
                </span>
                <span className="insight-sensor__sensor">{txt(sensor)}</span>
            </div>
        </div>
    );
};

export default SensorAverageComponent;
