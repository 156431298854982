import { SelectGroupActionType, SelectGroupReducerActions } from 'commons/src/actions/selectGroupActions';
import { PartnerActions, PartnerOfflineDevicesResponse, PartnerReducerActions } from '../actions/partnerActions';
import { PartnerCustomerActions, PartnerCustomerReducerActions } from '../actions/partnerCustomerActions';
import { SubscriptionAction, SubscriptionActions } from '../actions/subscriptionActions';
import { PartnerCustomerDetails } from '../models/partner';
import { Subscription } from '../models/subscriptionModels';

export type PartnerState = {
    customers: PartnerCustomerDetails[];
    partnerBranding: boolean;
    totalCustomers: number;
    offlineDevices: PartnerOfflineDevicesResponse[];
    customerSubscription?: { subscriptions: Subscription[]; userGroupId: string };
    newSubscriptionNumber?: string;
};

const initialState: PartnerState = {
    customers: [],
    partnerBranding: false,
    customerSubscription: undefined,
    totalCustomers: 0,
    offlineDevices: [],
    newSubscriptionNumber: undefined,
};
export default (
    state: PartnerState = initialState,
    action: PartnerReducerActions | PartnerCustomerReducerActions | SubscriptionAction | SelectGroupReducerActions
): PartnerState => {
    switch (action.type) {
        case PartnerActions.FetchPartnerDetailsSuccess:
            return {
                ...state,
                customers: action.payload.customers,
                partnerBranding: action.payload.partnerBranding,
            };
        case PartnerCustomerActions.CreatePartnerCustomerSuccess:
            return {
                ...state,
                customers: [...state.customers, action.customer],
            };
        case PartnerActions.TogglePartnerBrandingSuccess:
            return {
                ...state,
                partnerBranding: action.partnerBranding,
            };
        case SelectGroupActionType.SelectGroupInit: {
            return initialState;
        }
        case PartnerActions.FetchOfflineDevicesSuccess:
            return {
                ...state,
                totalCustomers: action.payload.totalCustomers,
                offlineDevices: action.payload.customers,
            };
        case SubscriptionActions.GetSubscriptionForPartnerCustomerInit:
            return {
                ...state,
                customerSubscription: undefined,
            };
        case SubscriptionActions.GetSubscriptionForPartnerCustomerSuccess:
            return {
                ...state,
                customerSubscription: { subscriptions: action.subscriptions, userGroupId: action.customerUserGroupId },
            };
        case SubscriptionActions.CreateSubscriptionForPartnerCustomerInit:
            return {
                ...state,
                newSubscriptionNumber: undefined,
            };
        case SubscriptionActions.CreateSubscriptionForPartnerCustomerSuccess:
            return {
                ...state,
                newSubscriptionNumber: action.subscriptionNumber,
            };
        case SubscriptionActions.UpdatePoRefSuccess: {
            const subscriptionToUpdateIndex = state.customerSubscription
                ? state.customerSubscription.subscriptions.findIndex(
                      sub => sub.subscriptionNumber === action.subscriptionNumber
                  )
                : -1;
            if (state.customerSubscription && subscriptionToUpdateIndex > -1) {
                const updatedSubscriptions = [...state.customerSubscription.subscriptions];
                updatedSubscriptions[subscriptionToUpdateIndex].poNumber = action.poRef;
                return {
                    ...state,
                    customerSubscription: { ...state.customerSubscription, subscriptions: updatedSubscriptions },
                };
            }
            return state;
        }
        default:
            return state;
    }
};
