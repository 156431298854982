import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { BuildingSummaryHealthStatus } from '../../../models/buildingModels';
import BuildingHealthTable from '../../status/buildingStatus/components/BuildingHealthTable';
import styles from './OfflineBuildingsTable.module.scss';

type Props = {
    offlineBuildings: BuildingSummaryHealthStatus[];
    darkHeader?: boolean;
};

const DisplayValue = ({ cell }: { cell: Cell }): React.ReactElement => <span>{cell.value}</span>;

const OfflineTable = ({ offlineBuildings, darkHeader }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const columns = useMemo<Column[]>(
        () => [
            {
                Header: txt('Building.Building'),
                accessor: 'name',
                Cell: DisplayValue,
                className: styles.nameCell,
            },
            {
                Header: txt('BuildingStatus.OfflineHubsHeader'),
                accessor: 'offlineHubs',
                Cell: DisplayValue,
                className: styles.fractionCell,
            },
            {
                Header: txt('BuildingStatus.OfflineSensorDevicesHeader'),
                accessor: 'offlineSensorDevices',
                Cell: DisplayValue,
                className: styles.fractionCell,
            },
        ],
        []
    );

    const tableData = useMemo(
        () =>
            offlineBuildings.map(building => {
                return {
                    id: building.locationId,
                    name: building.locationName,
                    offlineHubs: `${building.offlineHubs}/${building.hubs}`,
                    offlineSensorDevices: `${building.offlineSensorDevices}/${building.sensorDevices}`,
                };
            }),
        [offlineBuildings]
    );

    return (
        <BuildingHealthTable
            tableData={tableData}
            columns={columns}
            darkHeader={darkHeader}
            styles={styles}
            emptyTableMessage="BuildingStatus.NoOfflineBuildings"
        />
    );
};
export default OfflineTable;
