import { environmentPrefix } from './commonFunctions';
import {
    ACCOUNTS_API_URL_DEV,
    ACCOUNTS_API_URL_PROD,
    ACCOUNTS_LOGOUT_URL_DEV,
    ACCOUNTS_LOGOUT_URL_PROD,
    ACCOUNTS_REDIRECT_URI_DEV,
    ACCOUNTS_REDIRECT_URI_PROD,
    ACCOUNTS_REGISTRATION_URL_DEV,
    ACCOUNTS_REGISTRATION_URL_PROD,
    ACCOUNTS_URL_DEV,
    ACCOUNTS_URL_PROD,
    OLD_API_URL_DEV,
    OLD_API_URL_PROD,
    BACKOFFICE_URL_DEV,
    BACKOFFICE_URL_PROD,
    dashboardClientId,
    dashboardUri,
    Environment,
    INTEGRATIONS_URL_DEV,
    INTEGRATIONS_URL_PROD,
    API_URL_DEV,
    API_URL_PROD,
    PUBLIC_API_URL_DEV,
    PUBLIC_API_URL_PROD,
    THIRD_PARTY_REDIRECT_CALLBACK_URL_DEV,
    THIRD_PARTY_REDIRECT_CALLBACK_URL_PROD,
} from './constants';

const { hostname } = window.location;

// Split out the environment from the url: dashboard.[dev-env].airthings.com --> [dev-env]
const envPrefix = environmentPrefix(hostname);
const env: Environment = envPrefix ? Environment.dev : Environment.prod;

const apiUrl = envPrefix ? OLD_API_URL_DEV.replace('{{environment}}', envPrefix) : OLD_API_URL_PROD;
const newApiUrl = envPrefix ? API_URL_DEV.replace('{{environment}}', envPrefix) : API_URL_PROD;
const publicApiUrl = envPrefix ? PUBLIC_API_URL_DEV.replace('{{environment}}', envPrefix) : PUBLIC_API_URL_PROD;
const dashboardRedirectUri = envPrefix ? window.location.origin : dashboardUri;

const thirdPartyRedirectUrl = envPrefix
    ? THIRD_PARTY_REDIRECT_CALLBACK_URL_DEV
    : THIRD_PARTY_REDIRECT_CALLBACK_URL_PROD;

const integrationsUrl = envPrefix ? INTEGRATIONS_URL_DEV.replace('{{environment}}', envPrefix) : INTEGRATIONS_URL_PROD;

const devAccountsUrlAttrs = {
    '{{environment}}': envPrefix,
    '{{redirectUri}}': window.location.origin,
    '{{clientId}}': dashboardClientId,
};

// eslint-disable-next-line security/detect-non-literal-regexp
const re = new RegExp(Object.keys(devAccountsUrlAttrs).join('|'), 'gi');

const accountsApiUrl = envPrefix ? ACCOUNTS_API_URL_DEV.replace('{{environment}}', envPrefix) : ACCOUNTS_API_URL_PROD;
const accountsUrl = envPrefix
    ? ACCOUNTS_URL_DEV.replace(re, matchedKey => devAccountsUrlAttrs[matchedKey])
    : ACCOUNTS_URL_PROD;
const accountsLogoutUrl = envPrefix
    ? ACCOUNTS_LOGOUT_URL_DEV.replace(re, matchedKey => devAccountsUrlAttrs[matchedKey])
    : ACCOUNTS_LOGOUT_URL_PROD;
export const accountsRedirectUri = envPrefix
    ? ACCOUNTS_REDIRECT_URI_DEV.replace('{{environment}}', envPrefix)
    : ACCOUNTS_REDIRECT_URI_PROD;
const backofficeUrl = envPrefix
    ? BACKOFFICE_URL_DEV.replace(re, matchedKey => devAccountsUrlAttrs[matchedKey])
    : BACKOFFICE_URL_PROD;

const accountsRegisterUrl = envPrefix
    ? ACCOUNTS_REGISTRATION_URL_DEV.replace(re, matchedKey => devAccountsUrlAttrs[matchedKey])
    : ACCOUNTS_REGISTRATION_URL_PROD;

const dashboardClientSecret = envPrefix
    ? 'e333140d-4a85-4e3e-8cf2-bd0a6c710e0d'
    : 'e333140d-4a85-4e3e-8cf2-bd0a6c710aaa';
export const cookieDomain = hostname === 'localhost' ? 'localhost' : '.airthings.com';

const config = {
    apiUrl,
    newApiUrl,
    publicApiUrl,
    accountsApiUrl,
    accountsLogoutUrl,
    accountsUrl,
    accountsRegisterUrl,
    backofficeUrl,
    dashboardClientSecret,
    dashboardClientId,
    env,
    cookieDomain,
    dashboardRedirectUri,
    thirdPartyRedirectUrl,
    integrationsUrl,
};

export default config;
