import moment from 'moment';
import { GroupType } from '../../models/commonEnums';
import { Group, MixpanelChatCompanyType } from '../../models/commonTypeScript';

const groupToMixpanelCompany = (selectedGroup: Group): MixpanelChatCompanyType => {
    const isPartner = selectedGroup.groupType === GroupType.partner;
    return {
        name: selectedGroup.organizationName || '',
        company_id: selectedGroup.organizationId || '',
        user_group_id: selectedGroup.id,
        isAirthingsForBusiness: true,
        is_B2B2B_partner: isPartner,
        is_B2B2C_partner: false,
        created_at: moment.utc(selectedGroup.createdAt).unix(),
    };
};

export default groupToMixpanelCompany;
