/* eslint max-len: ["error", 2000] */
import React from 'react';

const IconVirusRisk = (): React.ReactElement => (
    <svg
        id="iconVirusRisk"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        width="24px"
        height="24px"
    >
        <path d="M1.27748 16.328C0.907507 16.6991 0.907507 17.3009 1.27748 17.672C3.04269 19.4427 5.90467 19.4427 7.66989 17.672C8.69517 16.6435 10.3575 16.6435 11.3827 17.672C11.7527 18.0431 12.3526 18.0431 12.7225 17.672C13.0925 17.3009 13.0925 16.6991 12.7225 16.328C10.9573 14.5573 8.09533 14.5573 6.33011 16.328C5.30483 17.3565 3.64253 17.3565 2.61726 16.328C2.24729 15.9569 1.64745 15.9569 1.27748 16.328Z" />
        <path d="M1.27748 11.328C0.907507 11.6991 0.907507 12.3009 1.27748 12.672C3.04269 14.4427 5.90467 14.4427 7.66989 12.672C8.69517 11.6435 10.3575 11.6435 11.3827 12.672C11.7527 13.0431 12.3526 13.0431 12.7225 12.672C13.0925 12.3009 13.0925 11.6991 12.7225 11.328C10.9573 9.55732 8.09533 9.55732 6.33011 11.328C5.30483 12.3565 3.64253 12.3565 2.61726 11.328C2.24729 10.9569 1.64745 10.9569 1.27748 11.328Z" />
        <path d="M1.27748 6.32803C0.907507 6.69915 0.907507 7.30085 1.27748 7.67197C3.04269 9.44268 5.90467 9.44268 7.66989 7.67197C8.69517 6.64351 10.3575 6.64351 11.3827 7.67197C11.7527 8.04309 12.3526 8.04309 12.7225 7.67197C13.0925 7.30085 13.0925 6.69915 12.7225 6.32803C10.9573 4.55732 8.09533 4.55732 6.33011 6.32803C5.30483 7.35649 3.64253 7.35649 2.61726 6.32803C2.24729 5.95691 1.64745 5.95691 1.27748 6.32803Z" />
        <path d="M11 3.38462C11 2.87481 11.4157 2.46154 11.9286 2.46154C17.2279 2.46154 21.5238 6.73205 21.5238 12C21.5238 17.2679 17.2279 21.5385 11.9286 21.5385C11.4157 21.5385 11 21.1252 11 20.6154C11 20.1056 11.4157 19.6923 11.9286 19.6923C16.2022 19.6923 19.6667 16.2483 19.6667 12C19.6667 7.75166 16.2022 4.30769 11.9286 4.30769C11.4157 4.30769 11 3.89442 11 3.38462Z" />
        <path d="M11.9286 0C12.4414 0 12.8571 0.413276 12.8571 0.923077V3.38462C12.8571 3.89442 12.4414 4.30769 11.9286 4.30769C11.4157 4.30769 11 3.89442 11 3.38462V0.923077C11 0.413276 11.4157 0 11.9286 0Z" />
        <path d="M11.9286 19.6923C12.4414 19.6923 12.8571 20.1056 12.8571 20.6154V23.0769C12.8571 23.5867 12.4414 24 11.9286 24C11.4157 24 11 23.5867 11 23.0769V20.6154C11 20.1056 11.4157 19.6923 11.9286 19.6923Z" />
        <path d="M15.7976 18.6617C16.2417 18.4068 16.8097 18.5581 17.0661 18.9996L18.3042 21.1314C18.5606 21.5729 18.4084 22.1374 17.9643 22.3923C17.5202 22.6472 16.9523 22.4959 16.6958 22.0544L15.4577 19.9227C15.2013 19.4812 15.3535 18.9166 15.7976 18.6617Z" />
        <path d="M18.6298 15.8462C18.8862 15.4047 19.4541 15.2534 19.8982 15.5083L22.0426 16.7391C22.4868 16.994 22.6389 17.5585 22.3825 18C22.1261 18.4415 21.5582 18.5928 21.1141 18.3379L18.9696 17.1071C18.5255 16.8522 18.3733 16.2877 18.6298 15.8462Z" />
        <path d="M19.6667 12C19.6667 11.4902 20.0824 11.0769 20.5952 11.0769H23.0714C23.5843 11.0769 24 11.4902 24 12C24 12.5098 23.5843 12.9231 23.0714 12.9231H20.5952C20.0824 12.9231 19.6667 12.5098 19.6667 12Z" />
        <path d="M18.6298 8.15385C18.3733 7.71235 18.5255 7.1478 18.9696 6.8929L21.1141 5.66213C21.5582 5.40723 22.1261 5.5585 22.3825 6C22.6389 6.4415 22.4868 7.00605 22.0426 7.26095L19.8982 8.49172C19.4541 8.74662 18.8862 8.59535 18.6298 8.15385Z" />
        <path d="M15.7976 5.33826C15.3535 5.08336 15.2013 4.51882 15.4577 4.07732L16.6958 1.94556C16.9523 1.50406 17.5202 1.35279 17.9643 1.60769C18.4084 1.86259 18.5606 2.42714 18.3042 2.86864L17.0661 5.00039C16.8097 5.4419 16.2417 5.59316 15.7976 5.33826Z" />
    </svg>
);

export default IconVirusRisk;
