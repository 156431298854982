import React from 'react';
import { useTranslation } from 'react-i18next';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { Units } from 'commons/src/models/commonTypeScript';
import { renderThresholdsRow } from './PartnerSummaryCells';
import PartnerSummaryCSVDownload from './PartnerSummaryCsvDownload';
import styles from './PartnerSummaryMobile.module.scss';
import {
    PartnerInsightAverage,
    PartnerSummaryCSVData,
    PartnerSummaryThresholdData,
    SelectedPeriod,
} from './PartnerSummaryModels';
import { convertValue } from './PartnerSummaryUtils';

type Props = {
    perDeviceData: PartnerSummaryCSVData[];
    dateFormat: string;
    selectedPeriod: SelectedPeriod;
    perThresholdData: PartnerSummaryThresholdData;
    units: Units;
};

const sensors = [SensorTypes.co2, SensorTypes.temp, SensorTypes.humidity];

const PartnerSummaryMobile = ({
    perDeviceData,
    dateFormat,
    selectedPeriod,
    perThresholdData,
    units,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const devicesBreachingThresholdForSensors = (
        row: string,
        averages: { [sensor: string]: PartnerInsightAverage }
    ): React.ReactElement => (
        <div className={styles.itemRow}>
            <div className={styles.sensorColumn}>
                {Object.keys(averages).map(sensorType => (
                    <span key={`averages${sensorType}`}>{txt(sensorType)}</span>
                ))}
            </div>
            <div className={styles.itemColumnPerThresholdValues}>
                {Object.keys(averages).map(sensorType => (
                    <div key={`averagesColunm${sensorType}`} className={styles.sensorBreachWrapper}>
                        <div>{averages[sensorType].totalDevices}</div>
                        <div className={styles.thresholdText}>
                            {renderThresholdsRow(row, averages[sensorType].sensorUnit, averages[sensorType].thresholds)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <div className={styles.page}>
            <div className={styles.list}>
                <div className={styles.item}>
                    <div className={styles.itemHeader}>{txt('Partner.DevicesWithAveragesLess')}</div>
                    {devicesBreachingThresholdForSensors('0', perThresholdData.averagesLessThan)}
                </div>
                <div className={styles.item}>
                    <div className={styles.itemHeader}>{txt('Partner.DevicesWithAveragesBetween')}</div>
                    {devicesBreachingThresholdForSensors('1', perThresholdData.averagesBetween)}
                </div>
                <div className={styles.item}>
                    <div className={styles.itemHeader}>{txt('Partner.DevicesWithAveragesAbove')}</div>
                    {devicesBreachingThresholdForSensors('2', perThresholdData.averagesHigherThan)}
                </div>
            </div>
            <PartnerSummaryCSVDownload data={perDeviceData} selectedPeriod={selectedPeriod} dateFormat={dateFormat} />
            <div className={styles.list}>
                {perDeviceData.map(device => (
                    <div className={styles.item}>
                        <div className={styles.itemHeader}>{device.organization}</div>
                        <div className={styles.itemRow}>
                            <div className={styles.itemColumnDevice}>
                                <div>{[txt('Name'), ':']}</div>
                                {sensors.map(sensor => (
                                    <span>{[txt('Partner.SummaryAverage', { sensorType: txt(sensor) }), ':']}</span>
                                ))}
                            </div>
                            <div className={styles.itemColumnDevice}>
                                <div>{device.device}</div>
                                {sensors.map(sensor => (
                                    <div>{convertValue(sensor, units, device.averages[sensor])}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PartnerSummaryMobile;
