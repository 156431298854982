import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import InputInfo from './InputInfo';
import styles from './InputLabel.module.scss';

type Props = {
    label?: string;
    customHeader?: ReactElement | string;
    mandatory?: boolean;
    infoText?: string;
    infoTextTranslated?: boolean;
    htmlFor: string;
    invalid?: boolean;
};
const InputLabel = ({
    customHeader,
    label,
    infoText,
    infoTextTranslated,
    mandatory,
    htmlFor,
    invalid,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <label htmlFor={htmlFor} className={invalid ? styles.invalidLabel : styles.inputLabel}>
            {customHeader && customHeader}
            {label && txt(label)}
            {mandatory && <span className={styles.mandatory} />}
            {infoText && <InputInfo infoText={infoText} infoTextTranslated={infoTextTranslated} />}
        </label>
    );
};

export default InputLabel;
