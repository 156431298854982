import React from 'react';
import { useTranslation } from 'react-i18next';
import LabeledText from 'commons/src/components/LabeledText';
import { VirtualDeviceType } from 'commons/src/models/commonEnums';
import { DeviceWithKeyInfo, SegmentProperties } from 'commons/src/models/commonTypeScript';

type Props = {
    segmentProps?: SegmentProperties;
    device: DeviceWithKeyInfo;
    loading: boolean;
};

const DeviceRequiredPropsSection = (props: Props): React.ReactElement => {
    const { device, segmentProps, loading } = props;
    const { t: txt } = useTranslation();
    if ([VirtualDeviceType.aggregatedGroup, VirtualDeviceType.inletAirControl].some(dt => dt === device.type)) {
        return <div />;
    }
    return (
        <>
            <div className="form__field--slim">
                <LabeledText
                    label="Floor"
                    id={`deviceFloor${device.serialNumber}`}
                    value={segmentProps && segmentProps.floor !== undefined ? segmentProps.floor.toString() : '-'}
                    loading={loading}
                />
            </div>
            <div className="form__field--slim">
                <LabeledText
                    label="RoomType"
                    id={`roomType${device.serialNumber}`}
                    value={
                        segmentProps && segmentProps.roomType ? txt(`RoomTypeOptions.${segmentProps.roomType}`) : '-'
                    }
                    loading={loading}
                />
            </div>
        </>
    );
};

export default DeviceRequiredPropsSection;
