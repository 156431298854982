import React from 'react';
import { SelectionItem } from '../../models/commonTypeScript';
import ChipButton from '../buttons/ChipButton';
import styles from './ElementsSelectorWithChip.module.scss';
import SearchDropdown from './SearchDropdown';

export type Props = {
    selectedElements: SelectionItem[];
    onSelect: (element: SelectionItem) => void;
    onRemove: (id: string) => void;
    elementsNotSelected: SelectionItem[];
};

const ElementsSelectorWithChips = ({
    onSelect,
    onRemove,
    selectedElements,
    elementsNotSelected,
}: Props): React.ReactElement => {
    return (
        <div>
            <div className="form__field form__field--single-width">
                <SearchDropdown listOfElements={elementsNotSelected} onSelect={onSelect} />
            </div>
            <div className="form__row">
                <div className={styles.chips}>
                    {selectedElements.map(elem => (
                        <ChipButton
                            key={elem.id}
                            title={elem.name}
                            id={elem.id}
                            onDelete={onRemove}
                            testId={`remove-chip-${elem.id}`}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ElementsSelectorWithChips;
