import React, { ReactElement } from 'react';
import styles from './WhiteSectionContainer.module.scss';

type Props = {
    children: ReactElement;
};
const WhiteSectionContainer = ({ children }: Props): ReactElement => {
    return <div className={styles.container}>{children}</div>;
};

export default WhiteSectionContainer;
