import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_HEATING_COOLING_EXPAND_GRAPH } from 'commons/src/analytics/AnalyticsEvents';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { dateFormats } from 'commons/src/constants';
import { EnergyEvents } from 'commons/src/models/commonEnums';
import styles from './HeatingCoolingSelectedBar.module.scss';
import HeatingCoolingSelectedDevice from './HeatingCoolingSelectedDevice';

type HeatingCoolingEvents = {
    timestamp: string;
    events: {
        eventType: EnergyEvents;
        devices: {
            serialNumber: string;
            deviceName: string;
        }[];
    }[];
};

export type Props = {
    heatingCoolingData: HeatingCoolingEvents[];
    selectedPoint?: string;
    dateFormat: keyof typeof dateFormats;
    detailsExpanded: boolean;
    setDetailsExpanded: (expanded: boolean) => void;
    locationId: string;
};

const HeatingCoolingSelectedBar = ({
    selectedPoint,
    heatingCoolingData,
    dateFormat,
    detailsExpanded,
    setDetailsExpanded,
    locationId,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [selectedDevice, setSelectedDevice] = useState<string>('');
    const eventsForPoint: HeatingCoolingEvents | undefined = selectedPoint
        ? heatingCoolingData.find(day => day.timestamp.includes(selectedPoint))
        : undefined;

    const selectDevice = (serialNumber: string): void => {
        setSelectedDevice(serialNumber);
        setDetailsExpanded(true);
    };

    const expandDetails = (expanded: boolean): void => {
        if (detailsExpanded !== expanded) {
            setDetailsExpanded(expanded);
            analyticsLogger(BUILDING_HEATING_COOLING_EXPAND_GRAPH, {
                payload: { detailsExpanded: expanded },
            });
        }
    };
    return (
        <div className={detailsExpanded ? styles.expandedWrapper : styles.wrapper}>
            {selectedPoint && (
                <button
                    className={styles.expandButton}
                    type="button"
                    onClick={(): void => expandDetails(!detailsExpanded)}
                >
                    <MaterialIcon name={detailsExpanded ? 'expand_more' : 'expand_less'} />
                </button>
            )}
            <div className={styles.subWrapper}>
                <h3 className={styles.dateHeader}>
                    {selectedPoint
                        ? moment(selectedPoint, 'YYYY-MM-DD').format(dateFormats[dateFormat].format)
                        : txt('BuildingEnergy.SelectBar')}
                </h3>
                {eventsForPoint?.events.map(event => (
                    <div key={event.eventType}>
                        <div className={styles.eventName}>
                            <div key={event.eventType} className={styles[event.eventType]} />
                            {txt(`BuildingEnergy.${event.eventType}`)}
                        </div>
                        {event.devices.map(device => (
                            <div key={device.serialNumber}>
                                <div
                                    role="button"
                                    tabIndex={0}
                                    className={
                                        selectedDevice === device.serialNumber
                                            ? styles.clickableDeviceNameSelected
                                            : styles.clickableDeviceName
                                    }
                                    onKeyUp={(e): void => {
                                        if (e.key === 'Enter') selectDevice(device.serialNumber);
                                    }}
                                    onClick={(): void => selectDevice(device.serialNumber)}
                                >
                                    {device.deviceName}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            {detailsExpanded && selectedPoint && (
                <HeatingCoolingSelectedDevice
                    serialNumber={selectedDevice}
                    selectedPoint={selectedPoint}
                    locationId={locationId}
                />
            )}
        </div>
    );
};

export default HeatingCoolingSelectedBar;
