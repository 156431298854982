import React, { useEffect } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_HEATING_COOLING_DOWNLOAD_CSV } from 'commons/src/analytics/AnalyticsEvents';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ReactPdfDownloadModal from 'commons/src/components/PDF/ReactPdfDownloadModal';
import { dateFormats } from 'commons/src/constants';
import { Resolution, TimePeriod } from 'commons/src/models/commonEnums';
import { HeatingCoolingData } from '../../../models/buildingModels';
import { getDateHeaders } from '../../buildings/buildingInsight/buildingInsightCommonFunctions';

type Props = {
    dateFormat: keyof typeof dateFormats;
    heatingCoolingData: HeatingCoolingData;
    selectedPeriod: {
        toDate: string;
        fromDate: string;
        resolution: Resolution;
        name: TimePeriod;
    };
    setDownloadModalOpen: (open: boolean) => void;
    locationName: string;
};

const HeatingCoolingCsvDownload = ({
    locationName,
    dateFormat,
    selectedPeriod,
    setDownloadModalOpen,
    heatingCoolingData,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const datesArray = getDateHeaders(selectedPeriod);
    const dateHeaders = datesArray.map(date => moment(date).format(dateFormats[dateFormat].shortFormat));
    const csvHeaders = ['name', 'serialNumber', ...dateHeaders];

    const csvData: { [device: string]: { serialNumber: string; name: string; [timestamp: string]: string } } = {};
    heatingCoolingData.forEach(date => {
        const { timestamp } = date;
        date.events.forEach(event => {
            const { eventType } = event;
            event.devices.forEach(device => {
                const formattedTime = moment(timestamp).format(dateFormats[dateFormat].shortFormat);
                if (csvData[device.serialNumber]) {
                    csvData[device.serialNumber] = {
                        ...csvData[device.serialNumber],
                        [formattedTime]: txt(`BuildingEnergy.${eventType}`),
                    };
                } else {
                    csvData[device.serialNumber] = {
                        serialNumber: device.serialNumber,
                        name: device.deviceName,
                        [formattedTime]: txt(`BuildingEnergy.${eventType}`),
                    };
                }
            });
        });
    });
    const fromDate = moment(selectedPeriod.fromDate).format(dateFormats[dateFormat].shortFormat);
    const toDate = moment(selectedPeriod.toDate).format(dateFormats[dateFormat].shortFormat);

    useEffect(() => {
        analyticsLogger(BUILDING_HEATING_COOLING_DOWNLOAD_CSV, { ...selectedPeriod });
    }, []);

    const fileName = `Heating-cooling-${locationName}-${fromDate}-${toDate}`;
    return (
        <ReactPdfDownloadModal
            title="DownloadCSVTitle"
            description="BuildingInsight.DownloadAllSensorThresholdsCSVDescription"
            onClose={(): void => setDownloadModalOpen(false)}
        >
            <div>
                <div className="flex flex--center small-padding-bottom">
                    <MaterialIcon name="download" />
                    <CSVLink
                        data={Object.values(csvData)}
                        separator=";"
                        filename={`${fileName}.csv`}
                        headers={csvHeaders}
                    >
                        {fileName}
                    </CSVLink>
                </div>
            </div>
        </ReactPdfDownloadModal>
    );
};

export default HeatingCoolingCsvDownload;
