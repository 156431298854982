import { Initialize, INITIALIZE } from '../actions';
import { FETCH_USER_INFO_SUCCESS, FetchUserInfoSuccess } from '../actions/SettingsActions';

export type TogglesStore = {
    email?: string;
    enableDowngradeViewToggleOn?: boolean;
    enableEventAggregationToggleOn?: boolean;
    endedMeasurementsToggleOn?: boolean;
};

const initialState: TogglesStore = {
    email: undefined,
    enableDowngradeViewToggleOn: true,
    enableEventAggregationToggleOn: false,
    endedMeasurementsToggleOn: false,
};

const updateLocalStorage = (key: string, value: string): void => {
    if (localStorage) localStorage.setItem(key, value);
};

const emailWithAirthingsDomain = (email: string | null | undefined, key?: string, value?: string): boolean => {
    const domain = !!email && email.substr(email.indexOf('@') + 1);
    const isAirthingsUser = domain === 'airthings.com' || domain === 'localhost' || email === 'ml@laurenzano.it'; // ml@laurenzano.it is Marco
    if (isAirthingsUser && key && value) updateLocalStorage(key, value);
    return isAirthingsUser;
};

export default (state: TogglesStore = initialState, action: FetchUserInfoSuccess | Initialize): TogglesStore => {
    switch (action.type) {
        case FETCH_USER_INFO_SUCCESS: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
            const displayForAirthingsUsers = emailWithAirthingsDomain(action.email);
            return {
                ...state,
                email: action.email,
            };
        }
        case INITIALIZE:
            return {
                ...state,
                enableEventAggregationToggleOn: localStorage.getItem('enableEventAggregationToggleOn') === 'true',
                endedMeasurementsToggleOn: localStorage.getItem('endedMeasurementsToggleOn') === 'true',
            };
        default:
            return state;
    }
};
