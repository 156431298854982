import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import CheckBox from '../input/Checkbox';
import styles from './SelectorList.module.scss';

export type Props = {
    options: { id: string; name: string; icon?: React.ReactElement; subText?: string; disabled?: boolean }[];
    selectedOptions: string[];
    onSelect: (id: string) => void;
    fullHeight?: boolean;
    maxSelectable?: number;
    center: boolean;
};
const SelectorListComponent = ({
    options,
    onSelect,
    selectedOptions,
    fullHeight,
    maxSelectable,
    center,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const cannotSelectMore = maxSelectable !== undefined && selectedOptions.length === maxSelectable;

    return (
        <div
            className={classNames([styles.listWrapper], {
                [styles.centerList]: center,
            })}
        >
            <div
                className={classNames(styles.list, {
                    [styles.fullHeightList]: fullHeight,
                })}
            >
                {options.map(option => (
                    <CheckBox
                        label={
                            <div
                                className={
                                    cannotSelectMore && !selectedOptions.includes(option.id)
                                        ? styles.disabled
                                        : styles.label
                                }
                            >
                                {option.icon}
                                <div className={styles.labelText}>
                                    <div>{option.name}</div>
                                    <div className={styles.subText}>{option.subText && txt(option.subText)}</div>
                                </div>
                            </div>
                        }
                        testId={`data-select-${option.id.toLowerCase()}`}
                        id={option.id}
                        onChange={(): void => onSelect(option.id)}
                        checked={selectedOptions.includes(option.id)}
                        labelIsElement
                        key={option.id}
                        disabled={cannotSelectMore && !selectedOptions.includes(option.id)}
                    />
                ))}
            </div>
        </div>
    );
};

export default SelectorListComponent;
