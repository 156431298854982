import { takeEvery, call, put, CallEffect, PutEffect, all } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from '../../actions/requestActions';
import {
    GetSubscriptionForGroupSuccess,
    getSubscriptionForGroupSuccess,
    SelectGroupActionType,
} from '../../actions/selectGroupActions';
import { getSubscriptionStatusForUserGroup } from '../../api/updateSettings';
import { SubscriptionPlan } from '../../models/commonTypeScript';
import CommonRequestActions from '../../models/RequestTypes';
import { CommonRequestType } from '../../reducers/requestReducer';
import { toErrorType } from '../isErrorType';

type GetGroupSubscriptionSagaReturnType = Generator<
    CallEffect<SubscriptionPlan> | PutEffect<GetSubscriptionForGroupSuccess> | CommonRequestActions,
    void,
    SubscriptionPlan
>;
export function* getGroupSubscriptionSaga(): GetGroupSubscriptionSagaReturnType {
    try {
        const response = yield call(getSubscriptionStatusForUserGroup);
        yield put(getSubscriptionForGroupSuccess(response));
        yield put(requestSuccess(CommonRequestType.GetSubscriptionForGroup, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.GetSubscriptionForGroup, RequestActionType.Error));
    }
}

export default function* GetSubscriptionForGroupSagas(): Generator {
    yield all([takeEvery(SelectGroupActionType.GetSubscriptionForGroupInit, getGroupSubscriptionSaga)]);
}
