import moment from 'moment';
import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import { Resolution, TimePeriod } from 'commons/src/models/commonEnums';
import RequestActions from 'commons/src/models/RequestTypes';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import {
    BuildingsTimeOverThresholdActionType,
    SetFocusedSensorSuccess,
    setFocusedSensorSuccess,
    getBuildingsFocusedSensorDataSuccess,
    GetBuildingsFocusedSensorDataSuccess,
    SetBuildingsFocusedSensorDefault,
    setBuildingsFocusedSensorDefaultSuccess,
    SetBuildingsFocusedSensorDefaultSuccess,
    GetBuildingsFocusSensorData,
    getBuildingsSensorData,
} from '../actions/buildingsTimeOverThresholdActions';
import {
    deleteThresholdBreachDefaults,
    GetDefaultConfigResponse,
    getThresholdBreachDefaults,
    getThresholdBreachesForAllBuildings,
    setConfigThresholdBreach,
    ThresholdBreachResponse,
} from '../api/buildingsApi';
import { BuildingFocusedSensorData, BuildingSummaryHealthStatus } from '../models/buildingModels';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type SetFocusedThresholdsSagaReturnType = Generator<
    | CallEffect<GetDefaultConfigResponse>
    | PutEffect<SetFocusedSensorSuccess>
    | RequestActions
    | PutEffect<SetBuildingsFocusedSensorDefaultSuccess>,
    void,
    { locations: BuildingSummaryHealthStatus[] } & GetDefaultConfigResponse
>;

export function* setFocusedThresholdsSaga({
    thresholds,
    sensor,
}: SetBuildingsFocusedSensorDefault): SetFocusedThresholdsSagaReturnType {
    try {
        yield call(setConfigThresholdBreach, { sensor, thresholds });
        yield put(setBuildingsFocusedSensorDefaultSuccess(thresholds, sensor));
        yield put(requestSuccess(RequestType.BuildingsThresholdsUpdateConfig, RequestActionType.Success));
    } catch (error) {
        yield put(
            requestError(toErrorType(error), RequestType.BuildingsThresholdsUpdateConfig, RequestActionType.Error)
        );
    }
}

type SetFocusedSensorSagaReturnType = Generator<
    | CallEffect<GetDefaultConfigResponse>
    | PutEffect<SetFocusedSensorSuccess>
    | PutEffect<GetBuildingsFocusSensorData>
    | RequestActions,
    void,
    GetDefaultConfigResponse
>;

export function* setFocusedSensorSaga({
    sensor,
}: SetBuildingsFocusedSensorDefaultSuccess): SetFocusedSensorSagaReturnType {
    try {
        const defaultValuesForSensor = yield call(setConfigThresholdBreach, { sensor });
        const requestPayload = {
            sensor,
            thresholds: defaultValuesForSensor.thresholds,
            useOpeningHours: true,
            to: moment().subtract(1, TimePeriod.day).format('YYYY-MM-DD'),
            from: moment().subtract(1, TimePeriod.week).format('YYYY-MM-DD'),
            resolution: Resolution.day,
        };
        yield put(getBuildingsSensorData(requestPayload));
        yield put(
            setFocusedSensorSuccess({
                thresholds: defaultValuesForSensor.thresholds,
                sensor,
                thresholdOptions: { [sensor]: defaultValuesForSensor.thresholdDefaults },
            })
        );
        yield put(requestSuccess(RequestType.BuildingsThresholdsSetFocusedSensor, RequestActionType.Success));
    } catch (error) {
        yield put(
            requestError(toErrorType(error), RequestType.BuildingsThresholdsSetFocusedSensor, RequestActionType.Error)
        );
    }
}

type GetBuildingsSensorDataSagaReturnType = Generator<
    | CallEffect
    | PutEffect<SetFocusedSensorSuccess>
    | PutEffect<GetBuildingsFocusedSensorDataSuccess>
    | CallEffect<ThresholdBreachResponse>
    | RequestActions,
    void,
    { locations: BuildingSummaryHealthStatus[] } & ThresholdBreachResponse
>;

export function* getBuildingsSensorDataSaga({
    requestDetails,
}: GetBuildingsFocusSensorData): GetBuildingsSensorDataSagaReturnType {
    try {
        const requestPayload = {
            sensor: requestDetails.sensor,
            thresholds: requestDetails.thresholds,
            useOpeningHours: requestDetails.useOpeningHours,
            to: requestDetails.to,
            from: requestDetails.from,
            resolution: requestDetails.resolution,
        };

        let offset = 0;
        const limit = 4;
        let allDataFetched = false;
        let sensorBreachData: { [locationId: string]: BuildingFocusedSensorData } = {};

        while (!allDataFetched) {
            const response = yield call(getThresholdBreachesForAllBuildings, requestPayload, offset, limit);
            sensorBreachData = { ...sensorBreachData, ...response.locationData };
            if (response.totalLocations <= offset + limit) {
                allDataFetched = true;
            } else {
                offset += limit;
            }
        }
        yield put(getBuildingsFocusedSensorDataSuccess(sensorBreachData, requestDetails.thresholds));
        yield put(requestSuccess(RequestType.GetBuildingsFocusedSensorData, RequestActionType.Success));
    } catch (error) {
        yield put(requestError(toErrorType(error), RequestType.GetBuildingsFocusedSensorData, RequestActionType.Error));
    }
}
type GetFocusedSensorSagaReturnType = Generator<
    | RequestActions
    | CallEffect<{ config: GetDefaultConfigResponse }>
    | PutEffect<SetFocusedSensorSuccess>
    | PutEffect<GetBuildingsFocusSensorData>,
    void,
    { config: GetDefaultConfigResponse }
>;
export function* getFocusedSensorSaga(): GetFocusedSensorSagaReturnType {
    try {
        const { config } = yield call(getThresholdBreachDefaults);
        const requestPayload = {
            sensor: config?.sensor,
            thresholds: config?.thresholds,
            useOpeningHours: true,
            to: moment().subtract(1, TimePeriod.day).format('YYYY-MM-DD'),
            from: moment().subtract(1, TimePeriod.week).format('YYYY-MM-DD'),
            resolution: Resolution.day,
        };
        if (config) {
            yield put(getBuildingsSensorData(requestPayload));
            yield put(
                setFocusedSensorSuccess({
                    thresholds: config.thresholds,
                    sensor: config.sensor,
                    thresholdOptions: { [config.sensor]: config.thresholdDefaults },
                })
            );
        }
        yield put(requestSuccess(RequestType.GetBuildingsThresholdsFocusedSensor, RequestActionType.Success));
    } catch (error) {
        yield put(
            requestError(toErrorType(error), RequestType.GetBuildingsThresholdsFocusedSensor, RequestActionType.Error)
        );
    }
}

type UnsetDefaultSagaReturnType = Generator<
    RequestActions | CallEffect<void> | PutEffect<SetFocusedSensorSuccess>,
    void,
    void
>;

export function* unsetDefaultSaga(): UnsetDefaultSagaReturnType {
    try {
        yield call(deleteThresholdBreachDefaults);
        yield put(
            setFocusedSensorSuccess({
                thresholds: [],
                sensor: undefined,
                thresholdOptions: {},
            })
        );
        yield put(requestSuccess(RequestType.BuildingsThresholdsUnsetDefault, RequestActionType.Success));
    } catch (error) {
        yield put(
            requestError(toErrorType(error), RequestType.BuildingsThresholdsUnsetDefault, RequestActionType.Error)
        );
    }
}

export default function* BuildingsTimeOverThresholdSaga(): Generator {
    yield all([
        takeEvery(BuildingsTimeOverThresholdActionType.GetFocusedSensorInit, getFocusedSensorSaga),
        takeEvery(BuildingsTimeOverThresholdActionType.BuildingsThresholdsSetFocusedSensorInit, setFocusedSensorSaga),
        takeEvery(BuildingsTimeOverThresholdActionType.BuildingsThresholdsUpdateConfigInit, setFocusedThresholdsSaga),
        takeEvery(BuildingsTimeOverThresholdActionType.GetBuildingsFocusedSensorDataInit, getBuildingsSensorDataSaga),
        takeEvery(BuildingsTimeOverThresholdActionType.BuildingsThresholdsUnsetDefaultInit, unsetDefaultSaga),
    ]);
}
