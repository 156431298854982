import React from 'react';

const VirusRiskInsightIllustration = (): React.ReactElement => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="596px"
        height="300px"
        viewBox="0 0 596 300"
    >
        <path
            fill="#FFEAAA"
            d="M247.4,185.2c-3-0.9-6.4,0.8-7.6,3.7l-7.1-3.7c0.4-4.8,0.3-9.6-0.6-14.3l3.5-2c5.1,5.4,14.6-0.2,12.2-7.4
		l-1.2-4.3c-2.1-7.8-14-6.5-14.5,1.5l-3.7,0.3c-0.4-1-0.9-1.8-1.4-2.8c-0.1-0.3-0.3-0.7-0.4-1.1c-0.4-1-1-1.8-1.7-2.7
		c-0.8-1-1.7-1.9-2.8-2.7c0-0.7-0.3-1.5-0.8-1.9l3.9-7.2c4.1,1.8,9.2-1.6,8.9-6.1c0.3-3.9-6.4-8-8.6-10.5c-5.8-5.5-14.5,2.9-9.4,8.8
		l-6.5,4.6c-3.8-2.5-8.1-4.5-12.5-5.9l-0.2-4c8.3-4.5,4-14.8-4.8-15c-7.6-1.7-12.6,8.8-6.3,13.4l-1.5,3.3c-3.5-0.2-7.1,0-10.5,0.5
		l-4.3-7.1c3.7-2.5,3.3-8.6-0.7-10.7c-3.2-2.4-10.2,1.4-13.5,2.1c-7.7,2.3-5.1,13.8,2.9,12.5l0.6,7.9c-4.3,2.2-8.3,4.9-11.8,8.2
		l-4.6-2.4c1.1-3.8-2.4-8.1-6.5-7.8c-3.4-0.1-6,4.4-8.1,6.5c-4.9,5.2,2.1,13.3,7.9,9.4l2.9,3.9c-3.3,5.1-5.7,10.6-7,16.6l-8.3,1.4
		c-1.2-4.4-7.2-6.4-10.8-3.1c-3.4,2.3,0.4,9.4-0.1,12.9c1.3,8,8.4,10.1,10.4,2.3l9.2,0.7c0.2,1.2,0.7,4.7,0.7,5.9s-1,2.2-0.7,3.3
		c0.3,1.3,0.7,2.5,1.1,3.7c0,0.3,1,0.3,1.2,0.7c0.5,1.3,3.4,6.3,4,7.6l-6.6,3.3c-5.8-4.3-11.3,1.5-10.1,7.1l1.6,4.6
		c3.4,6.2,12.4,3.1,11.6-3.8l7.5-2.4c3.5,4.2,7.6,7.8,12.1,10.6l-1.7,8.9c-8.1-1-11.4,10.9-3.7,14c3.4,1.1,9.9,5.9,13.6,3.4
		c3.6-2.6,4.4-9.7,0.8-12.7l5.4-7.4c7,1.8,14.3,2.1,21.4,1l4.4,7.1c-3.6,2.6-3.1,8.6,0.9,10.7c3.2,2.3,10.1-1.5,13.4-2.3
		c7.6-2.2,4.7-14-3-12.5l-0.7-7.9c4.1-2.1,7.8-4.8,11.2-7.9l4.7,2.4c-0.7,8.7,10.1,11.1,14.5,3.8c5.1-5.5-1.5-14.7-8.3-11.6l-3-4
		c1.7-2.5,3.1-5.2,4.4-8l8.2,0.2c0.3,7,11,7.9,12.6,1.1C250.9,196.6,255.2,187.3,247.4,185.2z"
        />
        <path
            fill="#FFC000"
            d="M131.5,185.8c-0.1,2.4,0.1,4.8,0.7,7.1c0.3,1,0.6,2.1,1,3.1c0.5,1.2,1,2.7,1.9,3.6c0.4,0.4,1.1,0.5,1.7,0.2
		c0.5-0.3,0.8-1,0.6-1.6c-0.2-1.1-1-2.3-1.4-3.3c-0.4-0.9-0.8-1.8-1-2.8c-0.5-2.1-1-4.2-1-6.4C133.8,184.2,131.6,184.2,131.5,185.8
		L131.5,185.8z"
        />
        <path fill="#FFC000" d="M221.4,153.6" />
        <path
            fill="#FFC000"
            d="M247.3,185.2L247.3,185.2c-3.1-0.9-6.4,0.7-7.7,3.7l-7.1-3.7c0.4-4.8,0.3-9.6-0.6-14.4l3.5-2
		c5.1,5.4,14.6-0.2,12.2-7.4l-1.3-4.3c-2-7.8-14-6.5-14.4,1.5l-3.7,0.3l-0.3-0.5c0.1-1.3-0.5-2.6-1-3.7c-0.4-0.9-1-1.7-1.5-2.5
		c-1-1.3-2.2-2.4-3.6-3.4c-0.2-0.4-0.4-0.8-0.7-1l3.9-7.2c4.1,1.8,9.2-1.6,8.9-6.1c0.3-3.9-6.4-8-8.6-10.5
		c-5.8-5.5-14.5,2.9-9.4,8.8l-6.5,4.6c-3.9-2.5-8.1-4.5-12.5-5.9l-0.2-4c8.2-4.5,4-14.8-4.8-15c-7.6-1.7-12.6,8.8-6.3,13.4l-1.5,3.3
		c-3.5-0.2-7.1,0-10.5,0.5l-4.3-7.1c3.7-2.6,3.3-8.6-0.7-10.7c-3.2-2.4-10.1,1.4-13.4,2c-7.7,2.3-5.1,13.9,2.8,12.5l0.7,7.9
		c-4.3,2.2-8.3,4.9-11.9,8.2l-4.5-2.4c1-3.8-2.4-8.1-6.5-7.8c-3.4-0.1-6.1,4.4-8.1,6.5c-4.9,5.2,2.1,13.3,7.9,9.4l2.9,3.9
		c-3.3,5.1-5.7,10.6-7,16.6l-8.2,1.3c-1.2-4.4-7.3-6.4-10.8-3.1c-3.4,2.3-2.1,9.8-2.2,12.9c-0.1,3.1,1.9,2.7,2.5,2.7
		c1.5,0,2.2-0.9,2.1-2.7l0,0c0-2-1.6-8.6,0.2-9.6c1.7-1,4.4,0,4.3,2.2c-0.1,1.2,0.8,2.2,2,2.3c0.2,0,0.3,0,0.4,0
		c2.4-1,13.2-0.6,13.6-4.2c1.2-5.9,3.6-11.6,7-16.6c0.7-1,0.7-2.4,0-3.4c-0.9-1-4.9-7.7-6.4-7.4s-2.1,2-3.6,2
		c-1.6,0-2.8-2.2-1.6-3.5c1.2-1.4,4.3-6.9,6.7-4.6c1.7,1.4-0.3,2.9,0,4.4c0.3,1.6,7.2,4.3,8.2,5.1c1,0.8,2.2-0.3,3.1-1.1
		c3.6-3.4,7.8-6.3,12.2-8.5c1-0.5,2-1,1.9-2.3c-0.1-1.2-0.3-12.5-1.8-13c-1.6-0.3-2.4,0-3.5,0.3c-2.9,0.9-4.1-4.4-1.1-5.2
		c1.4,0,9-3.6,10.2-2.4c1.2,1.1,1.3,4.5-0.6,5.1c-0.7,0.2-1.3,0.6-1.6,1.3c-1.1,1.2,5.6,10.4,5.9,11.6c0.3,1.2,1.8,1,3.1,0.7
		c12-2.6,12.2,4.6,16.6-7.5c1.1-3.1-4.4-3.1-3.2-6.6c1-3.6,4.6-2.8,7.1-2.1c1.8,0.3,3.1,2.1,2.9,4c-0.1,3-4.4,2.1-4.4,4.9
		s-0.8,8.5,2.4,9.2c4.6,1.4,9,3.4,13,6.1c1,0.7,2.3,0.6,3.3,0l9.4-6.5c1-0.7,1.1-2,0.5-3c-0.1-0.1-0.2-0.3-0.3-0.3
		c-1.4-1-2.9-2.9-1.4-4.4c2.5-2.8,7.8,5,9.7,5.9c1,0.9,1,2.4,0.1,3.2l0,0c-1.9,1.8-3.2-0.9-5.1-0.7c-1.9,0.2-6.1,10.2-7,11.1
		c-0.8,0.9-0.2,3,0.9,4.1c0.2,0.3,0.3,0.4,0.6,0.5l0.6,0.3c0.1,0.1,0.4,0.3,0.5,0.3c0.1,0.1-0.2-0.2-0.1-0.1l0.1,0.1l0.2,0.2
		l0.5,0.4l0.9,1l0,0l0.1,0.3l0.4,0.6c0.3,0.3,0.4,0.8,0.7,1.1l0.3,0.6v0.1c-0.3,0.6-0.3,1.2,0.1,1.8c0.6,1.1,1.2,2.4,1.7,3.5
		c1,3.1,7.4,1,9.7,1.3c3.7-0.6,0-5.8,4.1-6.5c4.2-0.8,4.4,4.2,5.1,6.5c1.1,3.7-4.1,5.8-5.8,2.4c-0.3-0.5-0.7-0.9-1.3-1
		c-1.2-0.8-6.4,3.3-7.5,3.6c-1.1,0.6-1.7,1.9-1.4,3.1c0.9,4.2,1.1,8.4,0.8,12.6c-0.2,1.7-0.7,4.1,1.4,5.1c1,0.3,10.8,6.4,11.9,5.3
		c2.2-1,2.2-5.3,5.7-4.5c3.5,0.8-0.7,9.3-0.7,11.4c-0.8,2.9-6.4,1.5-5.5-1.4c0.4-1.1-0.2-2.4-1.2-2.7c-0.2-0.1-0.4-0.1-0.7-0.2
		c-2.4,0.5-13.2-1.9-14.1,1.6c-1,3.5-6.8,9.3-4.7,12.2c1.5,1.2,4.9,9.2,7.7,6.6c2.8-2.5,6.9,1.7,4.4,4.5c-1.4,1.8-3.7,4.1-5.9,2.3
		c-2.2-1.8,0.6-5-1.9-6.4c-2.3-0.7-8-5.8-10.4-3.1c-3.5,3.3-7.4,6-11.7,8.2c-1,0.5-1.7,1.7-1.6,2.9l1,11.4c0.3,2.3,2.9,2.1,4.4,1.3
		c2.9-1,4.1,4.4,1.2,5.2c-1.4,0-9,3.7-10.1,2.5c-1.1-1.2-1.4-4.4,0.5-5.1c0.7-0.2,1.3-0.7,1.6-1.4c1.1-1.2-5.8-10.4-6.1-11.5
		c-0.6-1-1.8-1.6-3.1-1.4c-3.9,0.8-7.9,1-11.9,0.7c-3.6,0.5-10.2-3.3-12.5-0.5l-7.3,9.9c-0.7,1-0.4,2.3,0.5,3
		c0.1,0.1,0.2,0.2,0.3,0.2c2.9,0.9,2.4,5.6,0.9,7.2c-1.3,1-8.4-3.4-9.7-3.6s-2.3-3.1-1.6-4.8c1.7-4.2,6.4,0.9,7.5-3.1
		c-0.1-2.4,4.1-13.6,0.8-15c-4.7-2.9-8.9-6.5-12.3-10.8c-0.8-1-2-1.3-3.2-1c-0.6,0.5-11.6,3.1-11.4,4.5c-0.5,1.7,1.2,3.8-1,5
		c-1.6,0.9-2.8-0.1-3.7-1.5c-1-1.4-1.7-4.1-3.9-3.1c-2.3,1-0.3,4,1,6.4c3.8,5.1,12.9,1.9,12.1-4.6l7.5-2.5
		c3.5,4.2,7.6,7.8,12.1,10.7l-1.6,8.9c-8.1-1-11.4,10.9-3.7,14c3.4,1.1,9.9,5.9,13.6,3.3s4.4-9.6,0.8-12.7l5.4-7.3
		c7,1.8,14.3,2.1,21.4,1l4.4,7c-3.6,2.6-3.1,8.7,0.9,10.8c3.3,2.3,10.2-1.5,13.4-2.3c7.7-2.2,4.8-14-3-12.5l-0.7-8
		c4.1-2.1,7.8-4.7,11.2-7.8l4.7,2.4c-0.7,8.7,10.1,11.1,14.5,3.8c5.1-5.5-1.5-14.7-8.3-11.6l-3-4c1.7-2.5,3.1-5.2,4.4-8l8.2,0.2
		c0.3,7,11,7.9,12.6,1.1C250.9,196.6,255.1,187.3,247.3,185.2z"
        />
        <path
            fill="#FFC000"
            d="M156.9,164.8c-1.1-4.3,0.4-9.8,5.4-13.3c9.2-7.5,21.6,1.6,14.1,13.4c-1.6,2.3,1.7,4.9,3.2,2.5
		c6.4-9.2,2.1-22.1-8.4-23c-10.2-1.5-21.1,10.7-18.6,21.5c0.3,1.4,1.6,1.9,3.1,1.5C157.3,167,156.9,164.8,156.9,164.8z"
        />
        <path
            fill="#FFC000"
            d="M212.5,158.2c0.7-10.3-14-11.3-14.8-1C196.9,167.4,211.8,168.5,212.5,158.2z M201.2,157.4
		c0.3-4.8,8.3-4.3,7.9,0.5C208.7,162.7,200.7,162.1,201.2,157.4L201.2,157.4z"
        />
        <path
            fill="#FFC000"
            d="M115.8,114.9c0.8-12.6-18.3-13.9-19.3-1.3S114.9,127.6,115.8,114.9z M100.7,113.9c0.5-7.1,11.4-6.4,10.9,0.7
		C111.1,121.7,100.3,121,100.7,113.9z"
        />
        <path
            fill="#FFC000"
            d="M127.6,262.3c0.8-12.6-18.4-13.9-19.3-1.3C107.4,273.6,126.7,274.9,127.6,262.3z M112.5,261.3
		c0.5-7.1,11.4-6.4,10.9,0.7C123,269.1,112,268.4,112.5,261.3z"
        />
        <path
            fill="#FFC000"
            d="M214.4,190.4c0.6-9.8-14.3-10.8-15-1C198.7,199.2,213.6,200.3,214.4,190.4z M203.5,189.7
		c0.3-4.3,7-3.8,6.6,0.4C209.9,194.4,203.2,194,203.5,189.7L203.5,189.7z"
        />
        <path
            fill="#FFC000"
            d="M130.1,89.8c0.7-9.8-14.3-10.8-15-1S129.4,99.5,130.1,89.8z M119.3,89c0.3-4.4,7-3.8,6.6,0.4
		C125.5,93.7,118.8,93.2,119.3,89L119.3,89z"
        />
        <path
            fill="#FFC000"
            d="M271.3,128.1c0.6-9.8-14.3-10.8-15-1C255.6,136.8,270.6,137.9,271.3,128.1z M260.5,127.3
		c0.2-4.4,7-3.8,6.6,0.4C266.8,132,260.2,131.6,260.5,127.3z"
        />
        <path
            fill="#FFC000"
            d="M184.5,222.3c9.6,0.6,10.6-13.9,1-14.6C176,206.9,175,221.6,184.5,222.3z M185.3,211.8
		c4.1,0.3,3.7,6.7-0.4,6.4C180.8,217.7,181.2,211.4,185.3,211.8L185.3,211.8z"
        />
        <circle fill="#FFC000" cx="195.5" cy="176.8" r="2.8" />
        <circle fill="#FFC000" cx="190.1" cy="144.8" r="2.8" />
        <circle fill="#FFC000" cx="201" cy="207.2" r="2.8" />
        <circle fill="#FFC000" cx="270.2" cy="140" r="2.8" />
        <circle fill="#FFC000" cx="97.9" cy="268.8" r="2.8" />
        <circle fill="#FFC000" cx="97.6" cy="97.1" r="2.8" />
        <path
            fill="#FFC000"
            d="M132.4,284.6c7.2,6.7,17.4-4.1,10.4-10.9C135.7,266.8,125.3,277.7,132.4,284.6z M139.8,276.7
		c3.1,3-1.5,7.8-4.5,4.8C132.2,278.5,136.8,273.7,139.8,276.7L139.8,276.7z"
        />
        <circle fill="#FFC000" cx="137.8" cy="257.2" r="2.8" />
        <circle fill="#FFC000" cx="165.9" cy="214.6" r="2.8" />
        <path
            fill="#FFC000"
            d="M93.4,185.1c2.3,1.4,4.6,2.4,7.1,3.1c2.6,0.6,5.2,1,7.9,1c2.7,0,5.5-0.3,8.1-0.7c2.6-0.4,5.1-1,7.7-1.9
		c4.9-1.6,9.4-3.7,14.1-5.7l1.5-0.7c0.2-0.1,0.6-0.2,0,0l0.5-0.2l0.9-0.3l3.6-1.4c2.4-0.9,4.8-1.6,7.2-2.2l2-0.4l1.1-0.2l0.4-0.1
		h0.2c1.4-0.2,2.9-0.3,4.3-0.3l2.3,0.1l1,0.1h0.6l0.5,0.1l1.2,0.2h0.3c0.3,0,0.3,0-0.1,0l0.3,0.1l1.6,0.2c0.6-0.1,1.3-0.3,1.8-0.6
		c0.8-0.5,1.1-1.6,0.7-2.4c-0.3-0.5-0.7-1-1.1-1.3c-0.3-0.3-0.8-0.4-1.2-0.5c-0.7-0.3-1.4-0.4-2.2-0.5c-1.5-0.3-3-0.4-4.4-0.4
		c-2.8-0.1-5.6,0.1-8.3,0.6c-5.1,0.9-10.1,2.8-14.9,4.8l-6.6,2.9l-1.4,0.6l-0.9,0.3l-0.3,0.1c-0.4,0.2,0.6-0.3-0.1,0l-3.5,1.4
		c-2.4,0.9-5,1.7-7.5,2.4c-1.3,0.3-2.6,0.6-3.9,0.9l-1.7,0.3l-0.5,0.1c0.7-0.1,0.1,0,0,0l-1,0.1c-1.4,0.1-2.9,0.3-4.3,0.3
		c-0.7,0-1.5,0-2.2-0.1h-1l-0.5-0.1c-0.1,0-0.7-0.1-0.1,0c-1.4-0.2-2.8-0.4-4.1-0.8c-0.7-0.2-1.4-0.4-2.1-0.7l-0.3-0.1l-0.4-0.2
		l-1.1-0.5c-0.8-0.3-1.7-0.2-2.2,0.6C92.4,183.7,92.7,184.7,93.4,185.1L93.4,185.1z"
        />
        <path
            fill="#FFC000"
            d="M97.1,196.6c4.7,2.4,10,3.7,15.3,3.8c2.7,0,5.4-0.3,8-0.8c2.6-0.5,5.2-1.5,7.8-2.4c5.5-1.9,10.8-4.2,16-6.5
		l3.9-1.8l1.7-0.8c-0.6,0.3,0,0,0.1,0l0.3-0.1l1-0.4c2.4-1,5-1.7,7.6-2.2l1-0.2l0.5-0.1c0.1,0,0.7-0.2,0.1,0
		c0.7-0.1,1.4-0.2,2.1-0.2c1.4-0.1,2.7-0.1,4.1,0l1,0.1h0.5c-0.7-0.2-0.2,0,0,0l2.1,0.4l1.9,0.4l1.1,0.2c0.5,0,1-0.1,1.5-0.3
		c0.8-0.3,1.3-1.1,1-2c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.8-0.9-1.4-1.6-1.7c-0.7-0.3-1.4-0.6-2.1-0.8c-1.2-0.3-2.5-0.6-3.8-0.8
		c-2.4-0.3-5-0.3-7.4,0c-2.7,0.3-5.4,0.9-8,1.7c-2.7,0.9-5.3,2-7.9,3.2l-7.8,3.5l-1,0.4l-0.4,0.2c-0.6,0.3,0.4-0.2-0.2,0l-2,0.9
		l-3.8,1.5c-2.6,1-5.2,1.9-7.9,2.8c-1.3,0.4-2.5,0.7-3.8,1l-1.7,0.3l-0.5,0.1c-0.1,0-0.4,0.1,0,0c-0.3,0.1-0.7,0.1-1,0.1
		c-2.9,0.3-5.7,0.3-8.5,0c-0.6-0.1,0.4,0-0.2,0l-0.5-0.1l-1-0.2l-2.2-0.5c-1.4-0.3-2.8-0.9-4.2-1.4c-0.7-0.3-1.6,0.3-1.8,0.9
		C96.2,195.4,96.5,196.2,97.1,196.6L97.1,196.6z"
        />
        <path
            fill="#FFC000"
            d="M98.9,206.5c2.6,0.9,5.3,1.7,8,2.4c2.8,0.7,5.7,1.1,8.5,1.3c5.6,0.3,11.2-0.3,16.5-2c5.1-1.6,9.8-3.9,14.7-6.1
		l3.6-1.5c0.5-0.3-0.5,0.2,0.1,0l0.3-0.2l1-0.3l1.9-0.7c2.5-1,5.1-1.8,7.7-2.5l2.1-0.4l1-0.2h0.6c1.4-0.2,2.9-0.3,4.3-0.2l1,0.1h0.7
		c0.3,0,0.3,0-0.1,0c0.3,0,0.7,0.1,1,0.2l1.1,0.2c1.6,0.4,3.1,1,4.9,1c1.7,0.1,2.3-2,1.3-3.1c-2.2-2.2-5.7-2.8-8.5-3.1
		c-2.7-0.3-5.4-0.1-8.1,0.4c-5.3,1-10.5,3-15.4,5.1l-7.1,3.1l-1.7,0.8l-0.9,0.3l-0.5,0.3h-0.1l-3.7,1.5c-2.4,0.9-4.9,1.7-7.4,2.2
		l-2,0.3l-0.5,0.1c0.5-0.1,0.1,0,0,0l-1.2,0.1c-1.4,0.1-2.8,0.2-4.2,0.2c-1.4,0-3.1,0-4.6-0.2l-2.3-0.2h-0.8l-1.5-0.2l-2.3-0.3
		c0.1,0,0.3,0.1-0.1,0l-0.8-0.1l-1.1-0.2l-4.6-0.9c-0.8-0.3-1.6,0.2-1.8,1l0,0C97.7,205.6,98.2,206.4,98.9,206.5L98.9,206.5z"
        />
        <path
            fill="#FFFFFF"
            d="M398,255.2c-6.5,0.3-17.3,5.8-17,13c0,1.2,1.2,0,1.8-1c2.2-3.6,7.1-8.4,12.1-8.6l36.5-5.6
		c5.9-1,13.4,2.1,12.8,7.8c0,0.4,0.3,0.9,0.9,0.9c0.3,0,0.6-0.2,0.8-0.5c2.8-6.6-9-12.5-14.9-11l-3.9,0.6l-3.3-7.1
		c46.6-19.7,25-95.1-25.6-85.9c-51,6.3-49.5,84.7,0.9,89.7L398,255.2z M401.3,254.6l1-7c6.3,0.3,12.5-0.6,18.5-2.8l3,6.4
		L401.3,254.6z M363.3,208.7c-7.9-54.4,74.7-66.8,83.1-12.5S371.7,263,363.3,208.7z"
        />
        <path
            fill="none"
            d="M419.4,244.8l-19.1,2.4c-1.9,7.3-3.4,5.9-2.9,9.9l-11.4,3.6c-1,1-7.7,11.4-6.5,12.3c1.7,1.5,5.9,3.6,8.9,3.1
		l48.8-8.3c3.7-0.5,6.5-3.7,6.5-7.3c0-0.4-3.8-6.5-4.3-6.7l-9.3-0.5C423.9,251.9,420.9,253.7,419.4,244.8z"
        />
        <path
            fill="none"
            d="M409.4,198.8c-0.7-0.7-3-2.7-3.8-3.1c0.3-4.3,9.7-7.1,9.8-10.1c-2.9-16.6-32-11.1-29.5,4.4
		c0.5,3.4,2.9,7.6,5.9,10.8c1.4,1.6,3.4,3.6,2.9,5.8c-4.3,1.1-8.6-7.1-13.3-4.4c-17.6,15.5,15.5,44.6,26,8.1
		c4.2,3.1,1.1,12.3,5.8,15C438.1,232.3,440.8,188.1,409.4,198.8z"
        />
        <circle fill="#FFFFFF" cx="400.9" cy="204.7" r="6.4" />
        <path
            fill="#42515A"
            d="M400.6,153.6c-1-3.5-1.7-7.1-2.4-10.6c-0.9-4.4-1.7-8.6-2.6-13c-0.5-2.2-1-4.4-1.5-6.7s-1.2-4.3-1.7-6.4
		c-0.7-3.3-0.3-7,1.7-9.6c1-1.3,2.4-2.3,3.9-2.9c1.6-0.6,3.3-0.9,5-1.3l10.8-2.7c8.5-2.2,16.9-4.3,25.4-6.2c4.3-1,8.5-1.8,12.7-2.7
		l6.1-1c1.8-0.3,3.7-0.4,5.6-0.3c5.1,0.3,9.6,3.5,11.8,8.1c0.8,1.6,1.1,3.4,1.5,5.1c1.5,8.2,3.4,16.3,5.2,24.5s4.1,17.3,6,26
		c0.9,3.8,1.7,7.8,2.5,11.6c0.2,0.7,0.2,1.4-0.1,2.1c-0.3,0.8-0.9,1.5-1.5,2.1c-0.6,0.6-1.2,1-2,1.3c-1.1,0.3-2.4,0.6-3.6,0.9
		c-10,2.4-20,5.1-30.2,7.1c-2.4,0.5-4.6,0.9-7,1.3c-0.7,0.2-1,0.9-0.9,1.5l0,0c0.2,0.7,0.9,1,1.5,0.9l0,0
		c10.2-1.7,20.3-4.4,30.3-6.9l7.8-1.9c1.1-0.2,2.3-0.5,3.4-1c0.9-0.4,1.7-1,2.4-1.8c1.4-1.5,2.4-3.4,2.1-5.5s-0.8-3.7-1.2-5.7
		c-1.9-8.6-3.9-17.3-5.9-25.8c-2-8.6-4-17.3-5.8-26l-1.1-5.8c-0.3-1.9-0.9-3.7-1.7-5.5c-1.7-3.2-4.4-5.8-7.8-7.3
		c-1.7-0.8-3.7-1.3-5.6-1.5c-2-0.2-4-0.1-5.9,0.3c-4.2,0.7-8.4,1.5-12.5,2.4c-8.9,1.8-17.7,4-26.5,6.3l-11.9,3l-5.1,1.3
		c-1.7,0.4-3.5,0.7-5.1,1.5c-3.4,1.6-5.9,4.6-6.5,8.4c-0.4,1.9-0.3,4,0,5.9c0.2,1,0.5,2.1,0.8,3.1c0.3,1,0.6,2.2,0.9,3.3
		c2.2,8.6,3.7,17.4,5.8,26.1c0.3,1.6,0.7,3.1,1.2,4.8C399.2,155.7,400.9,154.8,400.6,153.6L400.6,153.6z"
        />
        <path
            fill="#42515A"
            d="M413,226.3c26.1,7.3,29.9-39.7-3.2-28.4c-1-1-2-1.7-3.2-2.4c0.3-4.5,10.2-6.1,9.6-11.6
		c-3-17.5-33.8-11.7-31.1,4.7c0.5,3.6,3,8,6.3,11.4c1.5,1.7,3.6,2.9,3.1,5.3c-4.4,1.2-9.1-6.5-14.1-3.7
		c-18.6,16.3,16.3,48.4,27.5,9.8C412,214.6,407.9,223.5,413,226.3z M404,209.3c-7.1,1.1-8.8-10-1.7-11
		C409.5,197.4,411.1,208.4,404,209.3z M393.6,197.7c-17.8-16.5,13.3-31.3,19.3-13.3c-0.7,3-7.8,5.3-8.7,10.7
		c-3.5-0.3-6.9,1.3-8.6,4.4C394.8,198.9,394.2,198.3,393.6,197.7z M395.6,225.1c-10.9,6.9-24.4-13.5-13.7-20.8
		c2.9-0.7,8,4.8,13.3,3.5c1.5,3,4.5,4.8,7.8,4.9C402.2,217.8,398.8,223,395.6,225.1L395.6,225.1z M411.9,202.5
		c-0.1-0.6-0.3-1.2-0.4-1.7c27-8.6,22.5,27.9,3,22.7c-2.2-2.8,0.5-9.4-4.4-14.1C411.6,207.4,412.3,204.9,411.9,202.5L411.9,202.5z"
        />
        <path
            fill="#42515A"
            d="M441.3,198.2c-1.3-8.9-5.8-17-12.4-23c-1.6-1.4-2.2,1-0.5,2.4c6.1,5.5,9.8,13,11,21.1
		c5.1,28.7-29.9,51.2-54.3,35.6c-0.8-0.4-1.7-0.2-2.3,0.6c-0.4,0.8-0.2,1.7,0.5,2.2l0,0C410,254,447,229.5,441.3,198.2z"
        />
        <path
            fill="#42515A"
            d="M366.6,209.2c-5.1-28.4,29.3-52.2,53.8-37.1c0.8,0.4,2.2,1.4,2.7,0.6c0.4-0.8,0.2-1.7-0.5-2.2l0,0
		c-26.8-16.5-63,8.2-57.4,39.2c1.3,8.6,5.5,16.6,11.9,22.5c1.6,1.5,2.2-0.9,0.6-2.4C371.7,224.4,367.7,217.2,366.6,209.2z"
        />
        <path
            fill="#42515A"
            d="M396.2,256.7c-6.5,0.3-17.2,5.8-17,13c0,1.2,1.2,0,1.8-1c2.3-3.6,7.1-9.2,12.2-9.4l36.5-5.6
		c5.8-1,13.3,2.9,12.7,8.6c-0.1,0.4,0.3,1,0.7,1c0.4,0.1,0.9-0.2,1-0.6c2.8-6.5-9-12.4-15-11l-3.9,0.6l-3.3-7.2
		c46.6-19.6,24.9-95-25.6-85.9c-51.1,6.3-49.5,84.7,0.9,89.8L396.2,256.7z M399.6,256.1l0.5-7c6.4,0.3,12.9-0.6,18.9-2.8l2.1,6.4
		L399.6,256.1z M361.6,210.2c-7.9-54.4,73.4-69.2,83.1-12.5C454.1,251.9,370.1,264.5,361.6,210.2z"
        />
        <path
            fill="#42515A"
            d="M380.9,267.5c-0.5,0.3-1,0.8-1.3,1.2c-0.3,0.5-0.7,1-0.9,1.6c-0.3,0.6-0.5,1.2-0.6,1.8
		c-0.1,0.8,0.2,1.5,0.7,2.1c1.5,1.7,3.7,2.4,5.8,2.7c2.2,0.3,4.8-0.1,7.1-0.4c2.4-0.3,5.4-0.7,8.1-1.1c10.8-1.7,21.6-3.4,32.3-5.7
		l4.4-1c1.3-0.3,2.7-0.6,3.9-1.1c1.4-0.5,2.6-1.5,3.6-2.7c0.3-0.5,0.6-1.1,0.8-1.8c0.3-0.8,0.2-1.7-0.3-2.4
		c-0.3-0.5-0.9-0.8-1.5-0.6c-0.5,0.2-1,0.7-1,1.3c0-0.2,0,0.1,0,0.2c-0.1,0.2-0.1,0.4-0.1,0.6c0-0.3-0.1,0.3-0.1,0.3
		c-0.1,0.3-0.3,0.7-0.5,1c-0.6,0.7-1.4,1.2-2.3,1.5c-1,0.3-2.2,0.6-3.3,0.9l-3.7,0.8c-5,1-9.9,2.1-14.9,2.9c-5,0.8-9.9,1.7-15,2.6
		c-5.1,0.8-9.7,1.7-14.6,2.2c-1,0.1-2.2,0.1-3.2,0c-1-0.1-1.8-0.3-2.7-0.7c-0.4-0.2-0.8-0.5-1.1-0.8c-0.3-0.3-0.3-0.5-0.2-1
		c0.1-0.6,0.3-1.1,0.6-1.7c0.2-0.3,0.3-0.6,0.3-0.9c0.1-0.3,0.2-0.6,0.3-0.9c0.2-0.3,0.1-0.6-0.2-0.8
		C381.4,267.3,381.1,267.3,380.9,267.5L380.9,267.5z"
        />
        <path
            fill="#42515A"
            d="M384.4,272.1c1.7,0.2,3.6-0.1,5.2-0.4c1.7-0.3,3.5-0.8,5.1-1.2c3.5-1,7-1.7,10.5-2.4
		c7.1-1.2,14.2-1.8,21.3-2.9c4-0.5,8-1.3,11.9-2.4c1.1-0.3,0.7-2.1-0.5-1.7c-6.9,1.8-14,2.6-21.1,3.6c-7.1,1-14.1,1.9-21,3.9
		l-5.7,1.6c-2,0.4-3.9,0.7-5.9,1C383.7,271.2,383.9,272,384.4,272.1L384.4,272.1z"
        />
        <path
            fill="#42515A"
            d="M440.3,116.5c-6.4-3.5-12.5,6.9-6.4,10.8C440.8,131,446.9,120.6,440.3,116.5 M461.7,116.1
		c3.5,2.1,0.2,7.5-3.3,5.4C454.9,119.3,458.3,114,461.7,116.1L461.7,116.1z M414.5,126.6c-2.7,0.6-3.2,4.5-0.8,5.8
		C418.9,134.9,420.1,125.5,414.5,126.6z M472.1,137.6c0,0,0.8,2.4,1.5,3.3c1.5,1.7,3.1-2,2.6-4.3l0,0c-1.3-6.3-2.8-13.4-9.2-15.9
		c1-3.2-0.4-6.7-3.4-8.4c-5.5-3.4-13.2,2.7-10,8.3s-0.5,3.7-1.4,7c1.3,0.7,2.4,4,3.2,1.7c1.9-5,6.4-4.1,9.2-4.8
		C470.3,123.5,470.9,133.3,472.1,137.6z M413.1,152c0,0,1,2.8,1,4c0,2.3-4.4-0.8-5.1-3c-1.5-5.7-4.1-13.5,1-18.1
		c-9.4-12.7,14.6-18.4,12.4-2.9c1,0.1,3.4,0.9,3.8,1.7c0.4,0.7-0.6,3-0.8,3.8c-1-0.6-5-2.1-6-1.8c-1.6,1.3-3.6,1.3-5.5,1.4
		C409,140.3,412.2,147.5,413.1,152L413.1,152z M434.8,112.4c8.8-2.3,15.2,6.7,11.9,14.1c4.4,1.7,8.7,5.8,9.7,10.1
		c0.1,0.4,3.3,13.4,3.3,13.6c0.6,2.2-2,6.4-3.1,5c-4-5.8-3.2-24.6-13-25c-2.5,2.2-5.8,2.4-8.8,2.2c-8,5.4,1.8,22.3,0.2,28.1
		c-0.6,2.2-4.5-1.2-5-3.1c-0.1-0.6-2.6-13.6-2.7-13.9c-1.4-4.8,0.8-9.8,3.4-13.3c-6.9-5.1-4.2-15.9,4.2-17.8L434.8,112.4z"
        />
    </svg>
);

export default VirusRiskInsightIllustration;
