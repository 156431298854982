import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { INSIGHT_SELECTED_REPORT } from 'commons/src/analytics/AnalyticsEvents';
import CalibrationIllustration from 'commons/src/img/Illustrations/calibration-illustration';
import EmailSummaryIllustration from 'commons/src/img/Illustrations/email-summary-illustration';
import IaqCertificateIllustration from 'commons/src/img/Illustrations/iaq-certificate-illustration';
import IaqInsightIllustration from 'commons/src/img/Illustrations/iaq-insight-illustration';
import OccupancyInsightIllustration from 'commons/src/img/Illustrations/occupancy-insight-illustration';
import OutdoorVsIndoorIllustration from 'commons/src/img/Illustrations/outdoor-vs-indoor-illustration';
import RadonInsightIllustration from 'commons/src/img/Illustrations/radon-insight-illustration';
import VirusRiskInsightIllustration from 'commons/src/img/Illustrations/virus-risk-insight-illustration';
import styles from './InsightMainTile.module.scss';

const illustrations = {
    occupancy: <OccupancyInsightIllustration />,
    radon: <RadonInsightIllustration />,
    iaq: <IaqInsightIllustration />,
    outdoorVsIndoor: <OutdoorVsIndoorIllustration />,
    virusRisk: <VirusRiskInsightIllustration />,
    iaqCertificate: <IaqCertificateIllustration />,
    emailSummary: <EmailSummaryIllustration />,
    calibration: <CalibrationIllustration />,
};

export type Props = {
    headerText: string;
    description: string;
    href?: string;
    type: keyof typeof illustrations;
    buttonText?: string;
};

export const InsightIllustrations = (type: keyof typeof illustrations): React.ReactElement | null =>
    illustrations[type] || null;

export const InsightMainTileComponent = ({
    headerText,
    description,
    href,
    type,
    buttonText,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    return (
        <div className="insight-main-tile">
            <h2 className="insight-main-tile__header">{txt(headerText)}</h2>
            <div className="insight-main-tile__content">
                <div className="text-large">{txt(description)}</div>
                <div className="insight-main-tile__content__elements">
                    <div className="insight-main-tile__image-wrapper">{InsightIllustrations(type)}</div>
                    <div className="insight-main-tile__content__button">
                        {href ? (
                            <Link
                                className={styles.button}
                                to={href}
                                onClick={(): void =>
                                    analyticsLogger(INSIGHT_SELECTED_REPORT, {
                                        pageType: PageType.Insight,
                                        reportType: type,
                                    })
                                }
                            >
                                {txt(buttonText || 'Insight.Explore')}
                            </Link>
                        ) : (
                            <div className={styles.disabled}>{txt('Insight.ComingSoon')}</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InsightMainTileComponent;
