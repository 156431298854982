import React from 'react';
import StandAloneSpinner from 'commons/src/img/StandAloneSpinner';
import MaterialIcon from '../MaterialIcon';

const DownloadPDFView = ({ loading, fileName }: { loading: boolean; fileName: string }): React.ReactElement => {
    if (loading) {
        return (
            <div className="modal__content__spinner">
                <StandAloneSpinner />
            </div>
        );
    }
    return (
        <div className="modal__content__description modal__content__description--small">
            <div className="modal__content__description__download">
                <MaterialIcon extraClass="modal__content__description__download__icon" name="download" />
                {fileName}
            </div>
        </div>
    );
};

export default DownloadPDFView;
