import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PillStatus } from '../../models/commonEnums';
import MaterialIcon from '../MaterialIcon';
import styles from './StatusPill.module.scss';

type Props = {
    status: PillStatus;
    outline?: boolean;
    size?: 'small' | 'medium';
    text?: string;
    icon?: string;
    rotate?: number;
};
const StatusPill = ({ status, outline, size, text, icon, rotate }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const rotateStyle = rotate ? styles[`rotate${rotate}`] : {};
    return (
        <div
            className={classNames(styles[status], {
                [styles[`outline-${status}`]]: outline,
                [styles.small]: size === 'small',
            })}
        >
            {icon && <MaterialIcon name={icon} extraClass={rotateStyle} />}
            {text || txt(`PillStatus.${status}`)}
        </div>
    );
};

export default StatusPill;
