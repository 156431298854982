import { ErrorType } from '../models/commonTypeScript';
import { CloseWelcomeModal } from './DashboardActions';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export interface LoginSuccess {
    type: typeof LOGIN_SUCCESS;
}
export const loginSuccess = (): LoginSuccess => ({ type: LOGIN_SUCCESS });

export const LOGIN_FAIL = 'LOGIN_FAIL';
export interface LoginFailure {
    type: typeof LOGIN_FAIL;
    error: ErrorType;
}
export const loginFailure = (error: ErrorType): LoginFailure => ({ type: LOGIN_FAIL, error });

export const LOGOUT = 'LOGOUT';
export interface LogOut {
    type: typeof LOGOUT;
}
export const logOut = (): LogOut => ({ type: LOGOUT });

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export interface LogOutSuccess {
    type: typeof LOGOUT_SUCCESS;
}
export const logOutSuccess = (): LogOutSuccess => ({ type: LOGOUT_SUCCESS });

export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export interface LogOutFailure {
    type: typeof LOGOUT_FAIL;
    statusMessage: string;
}
export const logOutFailure = (statusMessage: string): LogOutFailure => ({ type: LOGOUT_FAIL, statusMessage });

export const LOGOUT_ALL_UNITS = 'LOGOUT_ALL_UNITS';
export interface LogoutAllUnits {
    type: typeof LOGOUT_ALL_UNITS;
}
export const logoutAllUnits = (): LogoutAllUnits => ({ type: LOGOUT_ALL_UNITS });

export type LoginAndRegisterReducerActions =
    | LogOutSuccess
    | LoginFailure
    | LoginSuccess
    | LogOut
    | LogOutFailure
    | CloseWelcomeModal;
