import React from 'react';
import { useTranslation } from 'react-i18next';
import InputInfo from '../input/InputInfo';
import styles from './SectionHeader.module.scss';

type Props = {
    headerName: string;
    infoText?: string;
    headerType: number;
    endOfLineButton?: React.ReactElement;
    initialHeader?: boolean;
};

const SectionHeader = ({
    headerName,
    headerType,
    infoText,
    endOfLineButton,
    initialHeader,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const header = (): React.ReactElement => {
        if (headerType === 3) {
            return <h3 className={styles.subHeader}>{txt(headerName)}</h3>;
        }
        if (headerType === 4) {
            return <h4 className={styles.subHeader}>{txt(headerName)}</h4>;
        }
        return <h2 className={styles.subHeader}>{txt(headerName)}</h2>;
    };

    return (
        <div className={styles.headerAndLinksWrapper}>
            <div className={initialHeader ? styles.initialHeaderWrapper : styles.subHeaderWrapper}>
                {header()}
                {infoText && <InputInfo infoText={infoText} />}
            </div>
            {endOfLineButton}
        </div>
    );
};

export default SectionHeader;
