import delay from '@redux-saga/delay-p';
import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import RequestActions, { RequestErrorAction, RequestSuccessAction } from 'commons/src/models/RequestTypes';
import displayAlertBoxSaga from 'commons/src/sagas/displayAlertBox';
import { toErrorType, toErrorTypeWithMessage } from 'commons/src/sagas/isErrorType';
import history from 'commons/src/store/history';
import {
    AddMqttClient,
    addMqttClientSuccess,
    AddMqttClientSuccess,
    DeleteMqttClient,
    deleteMqttClientSuccess,
    DeleteMqttClientSuccess,
    deleteMqttClientCertificate as deleteMqttClientCertificateAction,
    DeleteMqttClientCertificate,
    deleteMqttClientCertificateSuccess,
    DeleteMqttClientCertificateSuccess,
    FetchMqttClientCertificate,
    fetchMqttClientCertificateSuccess,
    FetchMqttClientCertificateSuccess,
    fetchMqttClientsSuccess,
    FetchMqttClientsSuccess,
    MqttActionType,
    RenewMqttClientCertificate,
    renewMqttClientCertificateSuccess,
    RenewMqttClientCertificateSuccess,
    UpdateMqttClient,
    UpdateMqttClientActiveState,
    UpdateMqttClientActiveStateSuccess,
    updateMqttClientActiveStateSuccess,
    updateMqttClientSuccess,
    UpdateMqttClientSuccess,
    UploadMqttClientCertificate,
    uploadMqttClientCertificateSuccess,
    UploadMqttClientCertificateSuccess,
} from '../actions/mqttIntegrationActions';
import {
    addNewMqttClient,
    deleteMqttClient,
    deleteMqttClientCertificate,
    fetchMqttClientCertificate,
    fetchMqttClients,
    renewMqttClientCertificate,
    updateMqttClient,
    updateMqttClientActiveState,
    uploadMqttClientCertificate,
} from '../api/integrationsApi';
import { paths } from '../constants';
import { EditMqttClient, MqttClient, MqttClientCertificate } from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type FetchMqttClientsSagaReturnType = Generator<
    CallEffect<{ clients: MqttClient[] }> | PutEffect<FetchMqttClientsSuccess> | RequestActions,
    void,
    { clients: MqttClient[] }
>;
export function* fetchMqttClientsSaga(): FetchMqttClientsSagaReturnType {
    try {
        const response: { clients: MqttClient[] } = yield call(fetchMqttClients);
        yield put(fetchMqttClientsSuccess(response.clients));
        yield put(requestSuccess(RequestType.FetchMqttClients, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchMqttClients, RequestActionType.Error));
    }
}

type AddMqttClientSagaReturnType = Generator<
    CallEffect<MqttClient> | PutEffect<AddMqttClientSuccess> | RequestSuccessAction | CallEffect | RequestErrorAction,
    void,
    MqttClient
>;

export function* addMqttClientSaga({ client }: AddMqttClient): AddMqttClientSagaReturnType {
    try {
        const response: MqttClient = yield call(addNewMqttClient, client);
        yield put(addMqttClientSuccess(response));
        yield put(requestSuccess(RequestType.AddMqttClient, RequestActionType.Success));
        history.replace({ pathname: `/${paths.mqtt}/${response.id}` });
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(requestError(errorWithMessage.error, RequestType.AddMqttClient, RequestActionType.Error));
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

type UpdateMqttClientSagaReturnType = Generator<
    | CallEffect<EditMqttClient>
    | PutEffect<UpdateMqttClientSuccess>
    | RequestSuccessAction
    | CallEffect
    | RequestErrorAction,
    void,
    MqttClient
>;

export function* updateMqttClientSaga({ client }: UpdateMqttClient): UpdateMqttClientSagaReturnType {
    try {
        const response: MqttClient = yield call(updateMqttClient, client);
        history.push({ pathname: `/${paths.mqtt}/${response.id}` });
        yield put(updateMqttClientSuccess(response));
        yield put(requestSuccess(RequestType.UpdateMqttClient, RequestActionType.Success));
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(requestError(errorWithMessage.error, RequestType.UpdateMqttClient, RequestActionType.Error));
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

type UpdateMqttClientActiveStateSagaReturnType = Generator<
    | CallEffect<MqttClient>
    | PutEffect<UpdateMqttClientActiveStateSuccess>
    | RequestSuccessAction
    | CallEffect
    | RequestErrorAction,
    void,
    MqttClient
>;

export function* updateMqttClientActiveStateSaga({
    client,
    active,
}: UpdateMqttClientActiveState): UpdateMqttClientActiveStateSagaReturnType {
    try {
        const response: MqttClient = yield call(updateMqttClientActiveState, client, active);
        yield put(updateMqttClientActiveStateSuccess(response));
        yield put(requestSuccess(RequestType.UpdateMqttClientActiveState, RequestActionType.Success));
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(
            requestError(errorWithMessage.error, RequestType.UpdateMqttClientActiveState, RequestActionType.Error)
        );
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

type DeleteMqttClientSagaReturnType = Generator<
    CallEffect<string> | PutEffect<DeleteMqttClientSuccess> | RequestSuccessAction | CallEffect | RequestErrorAction,
    void,
    string
>;

export function* deleteMqttClientSaga({ clientId }: DeleteMqttClient): DeleteMqttClientSagaReturnType {
    try {
        const response: string = yield call(deleteMqttClient, clientId);
        yield put(deleteMqttClientSuccess(response));
        history.push({ pathname: `/${paths.mqtt}` });
        yield put(requestSuccess(RequestType.DeleteMqttClient, RequestActionType.Success));
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(requestError(errorWithMessage.error, RequestType.DeleteMqttClient, RequestActionType.Error));
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

type DeleteMqttClientCertificateSagaReturnType = Generator<
    | CallEffect<DeleteMqttClientCertificate>
    | PutEffect<DeleteMqttClientCertificateSuccess>
    | RequestSuccessAction
    | CallEffect
    | RequestErrorAction,
    void
>;

export function* deleteMqttClientCertificateSaga({
    clientId,
}: DeleteMqttClientCertificate): DeleteMqttClientCertificateSagaReturnType {
    try {
        yield call(deleteMqttClientCertificate, clientId);
        yield put(deleteMqttClientCertificateSuccess());
        yield put(requestSuccess(RequestType.DeleteMqttClientCertificate, RequestActionType.Success));
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(
            requestError(errorWithMessage.error, RequestType.DeleteMqttClientCertificate, RequestActionType.Error)
        );
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

type FetchMqttClientCertificateSagaReturnType = Generator<
    | CallEffect<string>
    | PutEffect<FetchMqttClientCertificateSuccess>
    | RequestSuccessAction
    | CallEffect
    | RequestErrorAction,
    void,
    MqttClientCertificate
>;

export function* fetchMqttClientCertificateSaga({
    clientId,
}: FetchMqttClientCertificate): FetchMqttClientCertificateSagaReturnType {
    try {
        const response: MqttClientCertificate = yield call(fetchMqttClientCertificate, clientId);
        yield put(fetchMqttClientCertificateSuccess(response));
        yield put(requestSuccess(RequestType.FetchMqttClientCertificate, RequestActionType.Success));
        // Make sure certificate is downloaded before calling delete
        yield call(delay, 100);
        yield call(deleteMqttClientCertificateSaga, deleteMqttClientCertificateAction(clientId));
        yield call(fetchMqttClientsSaga);
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(
            requestError(errorWithMessage.error, RequestType.FetchMqttClientCertificate, RequestActionType.Error)
        );
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

type UploadMqttClientCertificateSagaReturnType = Generator<
    | CallEffect<UploadMqttClientCertificate>
    | PutEffect<UploadMqttClientCertificateSuccess>
    | RequestSuccessAction
    | CallEffect
    | RequestErrorAction,
    void,
    MqttClientCertificate
>;

export function* uploadMqttClientCertificateSaga({
    clientId,
    certificate,
}: UploadMqttClientCertificate): UploadMqttClientCertificateSagaReturnType {
    try {
        const response: MqttClientCertificate = yield call(uploadMqttClientCertificate, clientId, certificate);
        yield put(uploadMqttClientCertificateSuccess(response));
        yield put(requestSuccess(RequestType.UploadMqttClientCertificate, RequestActionType.Success));
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(
            requestError(errorWithMessage.error, RequestType.UploadMqttClientCertificate, RequestActionType.Error)
        );
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

type RenewMqttClientCertificateSagaReturnType = Generator<
    | CallEffect<string>
    | PutEffect<RenewMqttClientCertificateSuccess>
    | RequestSuccessAction
    | CallEffect
    | RequestErrorAction,
    void,
    MqttClientCertificate
>;

export function* renewMqttClientCertificateSaga({
    clientId,
    certificate,
}: RenewMqttClientCertificate): RenewMqttClientCertificateSagaReturnType {
    try {
        const response: MqttClientCertificate = yield call(renewMqttClientCertificate, clientId, certificate);
        yield put(renewMqttClientCertificateSuccess(response));
        yield put(requestSuccess(RequestType.RenewMqttClientCertificate, RequestActionType.Success));
        if (!certificate) {
            // Make sure certificate is downloaded before calling delete
            yield call(delay, 100);
            yield call(deleteMqttClientCertificateSaga, deleteMqttClientCertificateAction(clientId));
            yield call(fetchMqttClientsSaga);
        }
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(
            requestError(errorWithMessage.error, RequestType.RenewMqttClientCertificate, RequestActionType.Error)
        );
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

export default function* mqttSagas(): Generator {
    yield all([
        takeEvery(MqttActionType.FetchMqttClientsInit, fetchMqttClientsSaga),
        takeEvery(MqttActionType.AddMqttClientInit, addMqttClientSaga),
        takeEvery(MqttActionType.UpdateMqttClientInit, updateMqttClientSaga),
        takeEvery(MqttActionType.UpdateMqttClientActiveStateInit, updateMqttClientActiveStateSaga),
        takeEvery(MqttActionType.DeleteMqttClientInit, deleteMqttClientSaga),
        takeEvery(MqttActionType.FetchMqttClientCertificateInit, fetchMqttClientCertificateSaga),
        takeEvery(MqttActionType.UploadMqttClientCertificateInit, uploadMqttClientCertificateSaga),
        takeEvery(MqttActionType.RenewMqttClientCertificateInit, renewMqttClientCertificateSaga),
        takeEvery(MqttActionType.DeleteMqttClientCertificateInit, deleteMqttClientCertificateSaga),
    ]);
}
