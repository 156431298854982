import { uniqBy } from 'lodash';
import { RequestActionType } from 'commons/src/actions/requestActions';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { RequestState } from 'commons/src/reducers/requestReducer';
import { OrganizationHealthActions, OrganizationHealthActionType } from '../actions/organizationHealthActions';
import { BuildingHealthRowData } from '../models/buildingHealthModels';
import { OrganizationBuildingOverview } from '../models/organizationHealthModels';

export type OrgRequestType = RequestState & { done?: boolean };

export type OrganizationBuildingType = OrganizationBuildingOverview & {
    data: BuildingHealthRowData[];
    totalData: number;
};

const defaultBuildingHealthSummary: OrganizationBuildingType = {
    locationId: '',
    locationName: '',
    hubStats: { total: 0, offline: 0 },
    deviceStats: { total: 0, offline: 0 },
    data: [],
    totalData: 0,
};

const defaultRequestState: OrgRequestType = { loading: false, error: undefined, done: false };

export type OrganizationHealthStatusState = {
    totalLocations: number;
    locations: {
        [locationId: string]: OrganizationBuildingType;
    };
    requests: { [locationId: string]: OrgRequestType };
};

export const initialState: OrganizationHealthStatusState = {
    totalLocations: 0,
    locations: {},
    requests: {},
};

const getNewRequestState = (action: RequestActionType, error?: ErrorType): OrgRequestType => {
    switch (action) {
        case RequestActionType.Init: {
            return { ...defaultRequestState, loading: true };
        }
        case RequestActionType.Success: {
            return { ...defaultRequestState, done: true };
        }
        case RequestActionType.Streaming: {
            return { ...defaultRequestState, streaming: true };
        }
        case RequestActionType.Error: {
            return { ...defaultRequestState, error };
        }
        default:
            return defaultRequestState;
    }
};

export default (state = initialState, action: OrganizationHealthActions): OrganizationHealthStatusState => {
    switch (action.type) {
        case OrganizationHealthActionType.FetchOrganizationHealthOverviewSuccess: {
            const newLocations = action.buildings.reduce((current, next) => {
                return { ...current, [next.locationId]: { ...defaultBuildingHealthSummary, ...next } };
            }, state.locations);
            const requests = action.buildings.reduce(
                (current, next) => ({ ...current, [next.locationId]: defaultRequestState }),
                state.requests
            );
            return { ...state, locations: newLocations, totalLocations: action.totalLocations, requests };
        }

        case OrganizationHealthActionType.FetchOrganizationBuildingDataSuccess: {
            const building = state.locations[action.locationId] ?? defaultBuildingHealthSummary;
            const data = uniqBy([...building.data, ...action.data], 'serialNumber');
            const locations = {
                ...state.locations,
                [action.locationId]: { ...building, data, totalData: action.totalData },
            };
            return { ...state, locations };
        }

        case OrganizationHealthActionType.UpdateOrganizationBuildingRequestState: {
            const request = getNewRequestState(action.requestState, action.error);
            const requests = { ...state.requests, [action.locationId]: request };
            return { ...state, requests };
        }

        default:
            return state;
    }
};
