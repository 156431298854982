import moment from 'moment';
import {
    DashboardSensorData,
    DeviceDataType,
    DeviceResponse,
    ExtraSeries,
    FullDeviceData,
    SelectedPeriod,
    SensorResponse,
} from '../../models/commonTypeScript';
import { generateAverageValue } from '../DashboardSagas/fetchDashboardSensorTile';
import zipChartData from './zipChartData';

const combineChartData = (oldData: number[][], newData: number[][]): number[][] => {
    const lastTimestampOfCurrentData = oldData.length > 0 ? oldData[oldData.length - 1][0] : 0;
    const indexOfNewData = newData.findIndex(dataPoint => dataPoint[0] > lastTimestampOfCurrentData);
    return indexOfNewData >= 0 ? [...oldData, ...newData.slice(indexOfNewData)] : oldData;
};

export const combineNewWithOld = ({
    prevDeviceData,
    sensor,
    selectedInterval,
    newChartData,
}: {
    prevDeviceData: DashboardSensorData;
    sensor: SensorResponse;
    selectedInterval: SelectedPeriod;
    newChartData: number[][];
}): number[][] => {
    const oldChartData =
        prevDeviceData.sensorData[sensor.type] && prevDeviceData.sensorData[sensor.type][selectedInterval.name]
            ? prevDeviceData.sensorData[sensor.type][selectedInterval.name]
            : [];
    return combineChartData(oldChartData, newChartData);
};

const createChartData = (
    newDeviceData: DeviceResponse,
    sensor: SensorResponse,
    segmentStartTime: number,
    selectedInterval: SelectedPeriod,
    prevDeviceData: DashboardSensorData,
    prevDeviceInfo: { device?: FullDeviceData },
    nowTime = moment.utc()
): {
    chartData: number[][];
    minValue?: number;
    averageValue?: number | undefined;
    extraSeries: ExtraSeries;
    aggregateType?: string;
} => {
    const initialMinValue = sensor && sensor.measurements && sensor.measurements[0];
    const aggregateType = (sensor && sensor.aggregateType) || sensor.type;
    const { newChartData, minValue, extraSeries } = zipChartData(
        newDeviceData.offsets,
        sensor,
        segmentStartTime,
        initialMinValue
    );

    // check if device has been moved and polling receives a new segment
    const isSameSegment = prevDeviceInfo.device && prevDeviceInfo.device.segmentId === newDeviceData.segmentId;
    if (!isSameSegment) {
        const averageValue = newChartData.length > 0 ? generateAverageValue(newChartData) : undefined;
        return { chartData: newChartData, minValue, averageValue, extraSeries, aggregateType };
    }
    const updatedChartData = combineNewWithOld({ prevDeviceData, sensor, selectedInterval, newChartData });

    if (updatedChartData.length > 0) {
        const beginningOfDataPeriod = nowTime
            .clone()
            .subtract(selectedInterval.number, selectedInterval.period)
            .valueOf();
        const indexOfFirstSampleInSelectedPeriod = updatedChartData.findIndex(
            dataPoint => dataPoint[0] >= beginningOfDataPeriod
        );

        const sensorData = updatedChartData.slice(
            indexOfFirstSampleInSelectedPeriod > 0 ? indexOfFirstSampleInSelectedPeriod - 1 : 0
        );
        const averageValue = generateAverageValue(sensorData);
        const prevMinValue =
            prevDeviceData.minValues[sensor.type] && prevDeviceData.minValues[sensor.type][selectedInterval.name];

        const prevExtra =
            (prevDeviceData.extraSeries &&
                prevDeviceData.extraSeries[sensor.type] &&
                prevDeviceData.extraSeries[sensor.type][selectedInterval.name]) ||
            {};

        const extras = Object.keys(extraSeries)
            .map(agg => ({ [agg]: combineChartData(prevExtra[agg] || [], extraSeries[agg] || []) }))
            .reduce((a, b) => ({ ...a, ...b }), {});

        return {
            chartData: sensorData,
            minValue: prevMinValue === undefined || minValue < prevMinValue ? minValue : prevMinValue,
            averageValue,
            extraSeries: extras,
            aggregateType,
        };
    }
    return { chartData: [], extraSeries, aggregateType };
};

const createGraphArrays = (
    newDeviceData: DeviceResponse,
    selectedInterval: SelectedPeriod,
    prevDeviceData: DashboardSensorData,
    prevDeviceInfo: { device?: FullDeviceData }
): DeviceDataType => {
    const segmentStartTime = moment.utc(newDeviceData.segmentStart).valueOf();
    const sensorData = {};
    const minValues = {};
    const averageValues = {};
    const extraSeries = {};
    const aggregateTypes = {};

    const sensors = newDeviceData.sensors.map(sensor => {
        const graphData = createChartData(
            newDeviceData,
            sensor,
            segmentStartTime,
            selectedInterval,
            prevDeviceData,
            prevDeviceInfo
        );

        sensorData[sensor.type] = {
            ...(prevDeviceData.sensorData && prevDeviceData.sensorData[sensor.type]),
            [selectedInterval.name]: graphData.chartData,
        };

        minValues[sensor.type] = {
            ...(prevDeviceData.minValues && prevDeviceData.minValues[sensor.type]),
            [selectedInterval.name]: graphData.minValue,
        };

        averageValues[sensor.type] = {
            ...(prevDeviceData.averageValues && prevDeviceData.averageValues[sensor.type]),
            [selectedInterval.name]: graphData.averageValue,
        };

        extraSeries[sensor.type] = {
            ...(prevDeviceData.extraSeries && prevDeviceData.extraSeries[sensor.type]),
            [selectedInterval.name]: graphData.extraSeries,
        };

        aggregateTypes[sensor.type] = graphData.aggregateType;
        return {
            ...sensor,
            measurements: undefined,
        };
    });
    const alreadyFetchedIntervals = prevDeviceInfo.device && prevDeviceInfo.device.fetchedIntervals;

    return {
        deviceData: {
            ...newDeviceData,
            sensors,
            offsets: undefined,
            fetchedIntervals: { ...alreadyFetchedIntervals, [selectedInterval.name]: true },
        },
        sensorData,
        minValues,
        averageValues,
        extraSeries,
        aggregateTypes,
    };
};

export default createGraphArrays;
