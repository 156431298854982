import { DashboardVisibility, SensorTypes } from '../models/commonEnums';
import {
    DashboardProps,
    DashboardTile,
    ErrorType,
    DashboardWithChartData,
    SelectedPeriod,
    SensorData,
    TileData,
} from '../models/commonTypeScript';
import { CommonActionRequestType, CommonRequestType } from '../reducers/requestReducer';
import { RequestActionType } from './requestActions';

export enum DashboardActionType {
    AddPublicDashboard = 'ADD_PUBLIC_DASHBOARD/INIT',
    DeletePublicDashboard = 'DELETE_PUBLIC_DASHBOARD/INIT',
    SelectDashboard = 'SELECT_DASHBOARD/INIT',
    EditPublicDashboardSettings = 'EDIT_PUBLIC_DASHBOARD_SETTINGS/INIT',
    StopPollingDashboard = 'STOP_POLLING_DASHBOARD/INIT',
}

export interface DashboardTilePayload {
    tiles: DashboardTile[];
    newModule?: boolean;
    dashboardId: string;
}
export const UPDATE_DASHBOARD_LAYOUT = 'UPDATE_DASHBOARD_LAYOUT';
export interface UpdateDashboardLayout {
    type: typeof UPDATE_DASHBOARD_LAYOUT;
    config: DashboardTilePayload;
}
export const updateDashboardLayout = (config: DashboardTilePayload): UpdateDashboardLayout => ({
    type: UPDATE_DASHBOARD_LAYOUT,
    config,
});

export const UPDATE_DASHBOARD_LAYOUT_SUCCESS = 'UPDATE_DASHBOARD_LAYOUT_SUCCESS';
export interface UpdateDashboardLayoutSuccess {
    type: typeof UPDATE_DASHBOARD_LAYOUT_SUCCESS;
    statusMessage: string;
}
export const updateDashboardLayoutSuccess = (statusMessage: string): UpdateDashboardLayoutSuccess => ({
    type: UPDATE_DASHBOARD_LAYOUT_SUCCESS,
    statusMessage,
});

export const UPDATE_DASHBOARD_LAYOUT_FAILURE = 'UPDATE_DASHBOARD_LAYOUT_FAILURE';
export interface UpdateDashboardLayoutFailure {
    type: typeof UPDATE_DASHBOARD_LAYOUT_FAILURE;
    statusMessage: string;
}
export const updateDashboardLayoutFailure = (statusMessage: string): UpdateDashboardLayoutFailure => ({
    type: UPDATE_DASHBOARD_LAYOUT_FAILURE,
    statusMessage,
});

export const FETCH_DASHBOARD = 'FETCH_DASHBOARD';
export interface FetchDashboard {
    type: typeof FETCH_DASHBOARD;
}
export const fetchDashboard = (): FetchDashboard => ({ type: FETCH_DASHBOARD });

export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export type DashboardResponse = {
    tiles: DashboardTile[];
    sensorData: SensorData;
    minValues: { [tileId: string]: { [interval: string]: number } };
    averageValues: { [tileId: string]: { [interval: string]: number } };
    name: string;
    id: string;
    visibility: DashboardVisibility;
    publicUrl?: string;
};

export interface FetchDashboardSuccess {
    type: typeof FETCH_DASHBOARD_SUCCESS;
    dashboardData: DashboardResponse;
    dashboards: { name: string; id: string }[];
}
export const fetchDashboardSuccess = (
    dashboardData: DashboardResponse,
    dashboards: { name: string; id: string }[]
): FetchDashboardSuccess => ({
    type: FETCH_DASHBOARD_SUCCESS,
    dashboardData,
    dashboards,
});

export const FETCH_DASHBOARD_FAIL = 'FETCH_DASHBOARD_FAIL';
export interface FetchDashboardFailure {
    type: typeof FETCH_DASHBOARD_FAIL;
    statusMessage: string;
}
export const fetchDashboardFailure = (statusMessage: string): FetchDashboardFailure => ({
    type: FETCH_DASHBOARD_FAIL,
    statusMessage,
});

export const FETCH_SENSOR_SEGMENT = 'FETCH_SENSOR_SEGMENT';
export interface FetchSensorSegment {
    type: typeof FETCH_SENSOR_SEGMENT;
    payload: {
        serialNumber: string;
        selectedInterval: SelectedPeriod;
        sensorType: string;
        tileId: string;
    };
}
export const fetchSensorSegment = (payload: {
    serialNumber: string;
    selectedInterval: SelectedPeriod;
    sensorType: string;
    tileId: string;
}): FetchSensorSegment => ({ type: FETCH_SENSOR_SEGMENT, payload });

export const FETCH_SENSOR_SEGMENT_SUCCESS = 'FETCH_SENSOR_SEGMENT_SUCCESS';
export interface FetchSensorSegmentSuccess {
    type: typeof FETCH_SENSOR_SEGMENT_SUCCESS;
    payload: {
        ref: string;
        sensorData: DashboardWithChartData;
    };
}
export const fetchSensorSegmentSuccess = (payload: {
    ref: string;
    sensorData: DashboardWithChartData;
}): FetchSensorSegmentSuccess => ({ type: FETCH_SENSOR_SEGMENT_SUCCESS, payload });

export const FETCH_SENSOR_SEGMENT_ERROR = 'FETCH_SENSOR_SEGMENT_ERROR';
export interface FetchSensorSegmentError {
    type: typeof FETCH_SENSOR_SEGMENT_ERROR;
    payload: ErrorType;
}
export const fetchSensorSegmentError = (payload: ErrorType): FetchSensorSegmentError => ({
    type: FETCH_SENSOR_SEGMENT_ERROR,
    payload,
});

export const FETCH_DASHBOARD_SENSOR = 'FETCH_DASHBOARD_SENSOR';
export interface DashboardSensorPayload extends DashboardTilePayload {
    sensor: { serialNumber: string; selectedInterval: SelectedPeriod; sensorType: SensorTypes };
    sensorData: TileData;
}
export interface FetchDashboardSensor {
    type: typeof FETCH_DASHBOARD_SENSOR;
    payload: DashboardSensorPayload;
}
export const fetchDashboardSensor = (payload: DashboardSensorPayload): FetchDashboardSensor => ({
    type: FETCH_DASHBOARD_SENSOR,
    payload,
});

export const FETCH_DASHBOARD_DEVICE_TILE = 'FETCH_DASHBOARD_DEVICE_TILE';
export interface DashboardDeviceTilePayload extends DashboardTilePayload {
    tileData: TileData;
    serialNumber: string;
    sensor?: string;
}
export interface FetchDashboardDeviceTile {
    type: typeof FETCH_DASHBOARD_DEVICE_TILE;
    payload: DashboardDeviceTilePayload;
}
export const fetchDashboardDeviceTile = (payload: DashboardDeviceTilePayload): FetchDashboardDeviceTile => ({
    type: FETCH_DASHBOARD_DEVICE_TILE,
    payload,
});

export const POLL_DASHBOARD_SENSOR_DATA = 'POLL_DASHBOARD_SENSOR_DATA';
export interface PollDashboardSensorData {
    type: typeof POLL_DASHBOARD_SENSOR_DATA;
    payload: {
        serialNumber: string;
        selectedInterval: SelectedPeriod;
        sensorType: string;
        ref: string;
        tileId: string;
    };
}
export const pollDashboardSensorData = (payload: {
    serialNumber: string;
    selectedInterval: SelectedPeriod;
    sensorType: string;
    ref: string;
    tileId: string;
}): PollDashboardSensorData => ({ type: POLL_DASHBOARD_SENSOR_DATA, payload });

export const STOP_POLL_DASHBOARD_SENSOR_DATA = 'STOP_POLL_DASHBOARD_SENSOR_DATA';
export interface StopPollDashboardSensorData {
    type: typeof STOP_POLL_DASHBOARD_SENSOR_DATA;
}
export const stopPollDashboardSensorData = (): StopPollDashboardSensorData => ({
    type: STOP_POLL_DASHBOARD_SENSOR_DATA,
});

export const CLOSE_WELCOME_MODAL = 'CLOSE_WELCOME_MODAL';
export interface CloseWelcomeModal {
    type: typeof CLOSE_WELCOME_MODAL;
}
export const closeWelcomeModal = (): CloseWelcomeModal => ({ type: CLOSE_WELCOME_MODAL });

export interface AddPublicDashboard extends CommonActionRequestType {
    type: DashboardActionType.AddPublicDashboard;
    payload: { name: string; visibility: DashboardVisibility };
}
export const addPublicDashboard = (payload: { name: string; visibility: DashboardVisibility }): AddPublicDashboard => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: CommonRequestType.AddPublicDashboard,
    type: DashboardActionType.AddPublicDashboard,
    payload,
});

export interface EditPublicDashboardSettings extends CommonActionRequestType {
    type: DashboardActionType.EditPublicDashboardSettings;
    payload: DashboardProps;
}
export const editPublicDashboardSettings = (payload: DashboardProps): EditPublicDashboardSettings => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: CommonRequestType.EditPublicCDashboardSettings,
    type: DashboardActionType.EditPublicDashboardSettings,
    payload,
});

export interface SelectDashboard extends CommonActionRequestType {
    type: DashboardActionType.SelectDashboard;
    id: string;
}
export const selectDashboard = (id: string): SelectDashboard => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: CommonRequestType.SelectDashboard,
    type: DashboardActionType.SelectDashboard,
    id,
});

export interface DeletePublicDashboard extends CommonActionRequestType {
    type: DashboardActionType.DeletePublicDashboard;
    id: string;
}
export const deletePublicDashboard = (id: string): DeletePublicDashboard => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.DeletePublicDashboard,
    type: DashboardActionType.DeletePublicDashboard,
    id,
});

export const SELECT_DASHBOARD_SUCCESS = 'SELECT_DASHBOARD_SUCCESS';
export interface SelectDashboardSuccess {
    type: typeof SELECT_DASHBOARD_SUCCESS;
    id: string;
    dashboardData: DashboardResponse;
}
export const selectDashboardSuccess = (dashboardData: DashboardResponse, id: string): SelectDashboardSuccess => ({
    type: SELECT_DASHBOARD_SUCCESS,
    dashboardData,
    id,
});

export const EDIT_PUBLIC_DASHBOARD_SETTINGS_SUCCESS = 'EDIT_PUBLIC_DASHBOARD_SETTINGS_SUCCESS';
export interface EditPublicDashboardSettingsSuccess {
    type: typeof EDIT_PUBLIC_DASHBOARD_SETTINGS_SUCCESS;
    payload: DashboardProps;
}
export const editPublicDashboardSettingsSuccess = (payload: DashboardProps): EditPublicDashboardSettingsSuccess => ({
    type: EDIT_PUBLIC_DASHBOARD_SETTINGS_SUCCESS,
    payload,
});

export const ADD_PUBLIC_DASHBOARD_SUCCESS = 'ADD_PUBLIC_DASHBOARD_SUCCESS';
export interface AddPublicDashboardSuccess {
    type: typeof ADD_PUBLIC_DASHBOARD_SUCCESS;
    dashboardData: DashboardResponse;
}
export const addPublicDashboardSuccess = (dashboardData: DashboardResponse): AddPublicDashboardSuccess => ({
    type: ADD_PUBLIC_DASHBOARD_SUCCESS,
    dashboardData,
});

export interface StopPollingDashboard {
    type: DashboardActionType.StopPollingDashboard;
}
export const stopPollingDashboard = (): StopPollingDashboard => ({
    type: DashboardActionType.StopPollingDashboard,
});

export type DashboardReducerActions =
    | FetchDashboard
    | FetchDashboardSuccess
    | FetchDashboardFailure
    | UpdateDashboardLayout
    | UpdateDashboardLayoutSuccess
    | UpdateDashboardLayoutFailure
    | AddPublicDashboardSuccess
    | SelectDashboardSuccess
    | EditPublicDashboardSettingsSuccess
    | FetchSensorSegment
    | FetchDashboardSensor
    | FetchDashboardDeviceTile
    | FetchSensorSegmentSuccess
    | FetchSensorSegmentError
    | PollDashboardSensorData
    | StopPollDashboardSensorData;
