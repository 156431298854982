import { RequestActionType } from 'commons/src/actions/requestActions';
import {
    ActivatedClientResponse,
    BusinessActionRequestType,
    SingleSignOnClient,
    SingleSignOnResponse,
    VerifyClientDomainResponse,
} from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum SingleSignOnActionType {
    CreateFederatedClientInit = 'CREATE_FEDERATED_CLIENT/INIT',
    CreateFederatedClientSuccess = 'CREATE_FEDERATED_CLIENT_SUCCESS',
    EditFederatedClientInit = 'EDIT_FEDERATED_CLIENT/INIT',
    EditFederatedClientSuccess = 'EDIT_FEDERATED_CLIENT_SUCCESS',
    ToggleActiveFederatedClientInit = 'TOGGLE_ACTIVE_FEDERATED_CLIENT/INIT',
    ToggleActiveFederatedClientSuccess = 'TOGGLE_ACTIVE_FEDERATED_CLIENT_SUCCESS',
    GetFederatedClientsInit = 'GET_FEDERATED_CLIENTS/INIT',
    GetFederatedClientsSuccess = 'GET_FEDERATED_CLIENTS_SUCCESS',
    DeleteSsoClientInit = 'DELETE_SSO_CLIENT/INIT',
    DeleteSsoClientSuccess = 'DELETE_SSO_CLIENT_SUCCESS',
    VerifySsoClientDomainInit = 'VERIFY_SSO_CLIENT_DOMAIN/INIT',
    VerifySsoClientDomainSuccess = 'VERIFY_SSO_CLIENT_DOMAIN_SUCCESS',
}

export interface CreateFederatedClient extends BusinessActionRequestType {
    type: SingleSignOnActionType.CreateFederatedClientInit;
    request: {
        issuer: string;
        domain: string;
        clientId: string;
        clientSecret: string;
        scope: string;
    };
}

export const createFederatedClient = (request: {
    issuer: string;
    domain: string;
    clientId: string;
    clientSecret: string;
    scope: string;
}): CreateFederatedClient => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.CreateFederatedClient,
    type: SingleSignOnActionType.CreateFederatedClientInit,
    request,
});

export interface CreateFederatedClientSuccess {
    type: SingleSignOnActionType.CreateFederatedClientSuccess;
    response: SingleSignOnClient;
}

export const createFederatedClientSuccess = (response: SingleSignOnClient): CreateFederatedClientSuccess => ({
    type: SingleSignOnActionType.CreateFederatedClientSuccess,
    response,
});

export interface EditFederatedClient extends BusinessActionRequestType {
    type: SingleSignOnActionType.EditFederatedClientInit;
    request: {
        issuer: string;
        domain: string;
        clientId: string;
        clientSecret: string;
        scope: string;
    };
    singleSignOnId: string;
}

export const editFederatedClient = (
    request: { issuer: string; domain: string; clientId: string; clientSecret: string; scope: string },
    singleSignOnId: string
): EditFederatedClient => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.EditFederatedClient,
    type: SingleSignOnActionType.EditFederatedClientInit,
    request,
    singleSignOnId,
});

export interface EditFederatedClientSuccess {
    type: SingleSignOnActionType.EditFederatedClientSuccess;
    response: { singleSignOnId: string; clientId: string; scope: string; issuer: string };
}

export const editFederatedClientSuccess = (response: {
    singleSignOnId: string;
    clientId: string;
    scope: string;
    issuer: string;
}): EditFederatedClientSuccess => ({
    type: SingleSignOnActionType.EditFederatedClientSuccess,
    response,
});

export interface ToggleActiveFederatedClient extends BusinessActionRequestType {
    type: SingleSignOnActionType.ToggleActiveFederatedClientInit;
    singleSignOnId: string;
    active: boolean;
}

export const toggleActiveFederatedClient = (clientId: string, activate: boolean): ToggleActiveFederatedClient => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.ToggleActiveFederatedClient,
    type: SingleSignOnActionType.ToggleActiveFederatedClientInit,
    singleSignOnId: clientId,
    active: activate,
});

export interface ToggleActiveFederatedClientSuccess {
    type: SingleSignOnActionType.ToggleActiveFederatedClientSuccess;
    response: ActivatedClientResponse;
}

export const toggleActiveFederatedClientSuccess = (
    response: ActivatedClientResponse
): ToggleActiveFederatedClientSuccess => ({
    type: SingleSignOnActionType.ToggleActiveFederatedClientSuccess,
    response,
});

export interface GetFederatedClients extends BusinessActionRequestType {
    type: SingleSignOnActionType.GetFederatedClientsInit;
}

export const getFederatedClients = (): GetFederatedClients => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.GetFederatedClients,
    type: SingleSignOnActionType.GetFederatedClientsInit,
});

export interface GetFederatedClientsSuccess {
    type: SingleSignOnActionType.GetFederatedClientsSuccess;
    response: SingleSignOnResponse;
}

export const getFederatedClientsSuccess = (response: SingleSignOnResponse): GetFederatedClientsSuccess => ({
    type: SingleSignOnActionType.GetFederatedClientsSuccess,
    response,
});

export interface DeleteSsoClient extends BusinessActionRequestType {
    type: SingleSignOnActionType.DeleteSsoClientInit;
    singleSignOnId: string;
}
export const deleteSsoClient = (singleSignOnId: string): DeleteSsoClient => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.DeleteSsoClient,
    type: SingleSignOnActionType.DeleteSsoClientInit,
    singleSignOnId,
});

export interface DeleteSsoClientSuccess {
    type: SingleSignOnActionType.DeleteSsoClientSuccess;
    singleSignOnId: string;
}
export const deleteSsoClientSuccess = (singleSignOnId: string): DeleteSsoClientSuccess => ({
    type: SingleSignOnActionType.DeleteSsoClientSuccess,
    singleSignOnId,
});

export interface VerifySsoClientDomain extends BusinessActionRequestType {
    type: SingleSignOnActionType.VerifySsoClientDomainInit;
    singleSignOnId: string;
}
export const verifySsoClientDomain = (singleSignOnId: string): VerifySsoClientDomain => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.VerifySsoClientDomain,
    type: SingleSignOnActionType.VerifySsoClientDomainInit,
    singleSignOnId,
});

export interface VerifySsoClientDomainSuccess {
    type: SingleSignOnActionType.VerifySsoClientDomainSuccess;
    response: VerifyClientDomainResponse;
}
export const verifySsoClientDomainSuccess = (response: VerifyClientDomainResponse): VerifySsoClientDomainSuccess => ({
    type: SingleSignOnActionType.VerifySsoClientDomainSuccess,
    response,
});

export type SingleSignOnAction =
    | CreateFederatedClient
    | CreateFederatedClientSuccess
    | EditFederatedClient
    | EditFederatedClientSuccess
    | ToggleActiveFederatedClient
    | ToggleActiveFederatedClientSuccess
    | GetFederatedClients
    | GetFederatedClientsSuccess
    | DeleteSsoClient
    | DeleteSsoClientSuccess
    | VerifySsoClientDomain
    | VerifySsoClientDomainSuccess;
