import { generatePath } from 'react-router-dom';
import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import DeviceHealthStatus from 'commons/src/models/deviceHealthStatus';
import { BUILDING_HEALTH_TABLE_LENGTH } from '../constants';
import {
    BuildingHealthSummary,
    BuildingHealthStats,
    FetchDevicesHealthResponse,
    FetchHubsHealthResponse,
} from '../models/buildingHealthModels';
import { HealthStatusIssueDataSummary, OrganizationHealthStatusSummary } from '../models/buildingModels';

export const fetchDevicesHealthData = async (
    locationId: string,
    limit: number = BUILDING_HEALTH_TABLE_LENGTH,
    offset = 0
): Promise<FetchDevicesHealthResponse> => {
    const baseUrl = generatePath('/locations/:locationId/health-status/devices', { locationId });
    const queryString = new URLSearchParams({ limit: String(limit), offset: String(offset) });
    const url = `${baseUrl}?${queryString.toString()}`;
    return fetch<FetchDevicesHealthResponse>(url, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
};

export const fetchHubsHealtData = async (locationId: string): Promise<FetchHubsHealthResponse> => {
    const url = generatePath('/locations/:locationId/health-status/hubs', { locationId });
    return fetch<FetchHubsHealthResponse>(url, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
};

export const getBuildingHealthSummary = async (locationId: string): Promise<BuildingHealthSummary> => {
    const url = generatePath('/locations/:locationId/health-status/summary', { locationId });
    const data = await fetch<{ hubs: BuildingHealthStats; devices: BuildingHealthStats }>(url, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
    return { hubStats: data.hubs, deviceStats: data.devices } as BuildingHealthSummary;
};

export const getOrganizationHealthStatusSummary = async (): Promise<OrganizationHealthStatusSummary> =>
    fetch<OrganizationHealthStatusSummary>(`/health-status/summary`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const getHealthStatusIssueData = async (status: DeviceHealthStatus): Promise<HealthStatusIssueDataSummary> =>
    fetch<HealthStatusIssueDataSummary>(`/health-status/list?healthStatus=${status}`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const getBuildingHealthStatusCSVData = async (): Promise<{ url: string; fileName: string }> =>
    fetch<{ url: string; fileName: string }>('/health-status/list/csv', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
