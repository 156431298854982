import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import config from '../../config';
import { Role } from '../../models/commonEnums';
import { Group } from '../../models/commonTypeScript';
import { Store } from '../../reducers';

type Props = {
    groups: Group[];
};

export const SupportBanner = ({ groups }: Props): React.ReactElement | null => {
    const { t: txt } = useTranslation();
    const supportUser = groups.length === 1 && groups[0].role === Role.SUPPORT ? groups[0] : undefined;
    if (!supportUser) {
        return null;
    }

    const userGroupId = (supportUser && supportUser.id) || '';
    const url = `${config.backofficeUrl}/usergroup/${userGroupId}`;

    return (
        <div className="support-banner">
            <span className="support-banner__message">
                {txt('SupportBanner.Title')}
                <a href={url} target="_blank" rel="noopener noreferrer">
                    {txt('SupportBanner.Here')}.
                </a>
            </span>
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        userSettings: { groups },
    } = state;
    return {
        groups,
    };
};

export default connect(mapStateToProps)(SupportBanner);
