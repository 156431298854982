import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '../MaterialIcon';
import styles from './NotSynced.module.scss';

type Props = {
    text: string;
    extraClass?: string;
};

const NotSynced = ({ text, extraClass }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className={styles.aligned}>
            <MaterialIcon name="sync_problem" extraClass={styles.yellowIcon} />
            <span className={classNames(styles.notSynced, extraClass)}>{txt(text)}</span>
        </div>
    );
};

export default NotSynced;
