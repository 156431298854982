import React from 'react';
import QRCodeSVG from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import LindabLogo from '../../img/lindabLogo';

type Props = {
    macAddress: string;
};

export const LindabTileComponent = ({ macAddress }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className="info-header info-header--large-gap">
            <div>
                <LindabLogo extraClass="margin-10-bottom" />
                <div>{txt('LindabConnection.UltraBtMode')}</div>
            </div>
            <QRCodeSVG value={macAddress} size={82} id={`qrCode${macAddress}`} />
            <div>{txt('LindabConnection.MacAddr', { addr: macAddress })}</div>
        </div>
    );
};

export default LindabTileComponent;
