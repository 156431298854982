import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './GlowRingButton.module.scss';

type Props = {
    glowRingDescription: string;
    glowColor: string;
    selected: boolean;
};

const GlowRingButton = ({ glowRingDescription, glowColor, selected }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className={styles.ledButton}>
            <div className={styles.imageWrapper}>
                <div
                    className={classNames({
                        [styles.white]: glowColor === 'white',
                        [styles.red]: glowColor === 'red',
                        [styles.selected]: selected,
                    })}
                />
            </div>
            <span>{txt(glowRingDescription)}</span>
        </div>
    );
};

export default GlowRingButton;
