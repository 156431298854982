import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { lookupSsoDomain } from 'business-dashboard/src/api/singleSignOnApi';
import { LookupDomainResponse } from 'business-dashboard/src/models/common';
import RequestActions from 'commons/src/models/RequestTypes';
import { RequestActionType, requestError, requestSuccess } from '../../actions/requestActions';
import {
    DomainLookupActionType,
    LookupDomain,
    lookupDomainSuccess,
    LookupDomainSuccess,
} from '../../actions/SettingsActions';
import { CommonRequestType } from '../../reducers/requestReducer';
import { toErrorType } from '../isErrorType';

type LookupDomainReturnType = Generator<
    CallEffect<LookupDomainResponse> | PutEffect<LookupDomainSuccess> | RequestActions,
    void,
    LookupDomainResponse
>;

export function* lookupDomain({ emailDomain }: LookupDomain): LookupDomainReturnType {
    try {
        const response: LookupDomainResponse = yield call(lookupSsoDomain, emailDomain);
        yield put(lookupDomainSuccess(response));
        yield put(requestSuccess(CommonRequestType.LookupDomain, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, CommonRequestType.LookupDomain, RequestActionType.Error));
    }
}

export default function* emailDomainLookupSaga(): Generator {
    yield all([takeEvery(DomainLookupActionType.LookupDomainInit, lookupDomain)]);
}
