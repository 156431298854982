import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CircleButton from 'commons/src/components/buttons/CircleButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import RssiSignalStrength from 'commons/src/components/sensors/RssiSignalStrength';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import { sensorUnits } from 'commons/src/constants';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { SensorThresholds } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';
import styles from './FloorMapSensorFilter.module.scss';

export enum FloorPlanMetadataSensors {
    connection = 'connection',
    battery = 'battery',
}

type Props = {
    availableSensors: SensorTypes[];
    selectedSensor?: string;
    setSelectedSensor: (sensor: string) => void;
};

export const FloorMapSensorsFilterComponent = ({
    availableSensors,
    selectedSensor,
    setSelectedSensor,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const iconElement = (sensor: string): ReactElement => {
        let icon: ReactElement;
        if (sensor === FloorPlanMetadataSensors.connection) {
            icon = <RssiSignalStrength rssi={1} />;
        } else if (sensor === FloorPlanMetadataSensors.battery) {
            icon = <MaterialIcon name="battery_horiz_050" extraClass={styles.battery} />;
        } else {
            icon = <SensorIcon sensor={sensor} />;
        }
        return icon;
    };

    const buttonElement = (sensor: string): ReactElement => (
        <CircleButton
            key={`sensor-${sensor}`}
            onClick={(): void => setSelectedSensor(sensor)}
            iconName={iconElement(sensor)}
            size="small"
            color={sensor === selectedSensor ? 'primary' : 'quaternary'}
            testAttr={`filter-sensor-${sensor.toLowerCase()}`}
        />
    );

    const thresholds: { [p: string]: SensorThresholds } = useSelector((state: Store) => state.config.thresholds);
    return (
        <div className={styles.body}>
            {Object.values(FloorPlanMetadataSensors).map(sensor => (
                <div className={styles.sensor} key={sensor}>
                    {selectedSensor === sensor && (
                        <div className={styles.sensorInfo}>{txt(`DeviceMetaData.${sensor}`)}</div>
                    )}
                    {buttonElement(sensor)}
                </div>
            ))}
            {availableSensors.map(sensor => (
                <div className={styles.sensor} key={sensor}>
                    {selectedSensor === sensor && (
                        <div className={styles.sensorInfo}>
                            {txt(sensor)}
                            <div className={styles.unit}>
                                {
                                    sensorUnits[
                                        (sensor.includes(sensor)
                                            ? thresholds[sensor].unit
                                            : undefined) as keyof typeof sensorUnits
                                    ]
                                }
                            </div>
                        </div>
                    )}
                    {buttonElement(sensor)}
                </div>
            ))}
        </div>
    );
};

export default FloorMapSensorsFilterComponent;
