import React, { FocusEvent, useEffect } from 'react';
import classNames from 'classnames';
import { SubNavigation, TabOption } from '../../models/menuModels';
import NavigationItem from '../menu/NavigationItem';
import styles from './TabSubNavigation.module.scss';

type Props = {
    tab: TabOption;
    index: number;
    closeSubMenu: () => void;
    subNavigation: SubNavigation[];
    onBlur: (e: FocusEvent<HTMLElement>, index: number) => void;
};

const TabSubNavigation = ({ tab, subNavigation, closeSubMenu, index, onBlur }: Props): React.ReactElement => {
    const closeMenu = (): void => {
        // Need the timer to allow mobile to redirect before closing the menu
        setTimeout(() => {
            closeSubMenu();
        }, 100);
    };
    const blur = (e: FocusEvent<HTMLElement>): void => {
        onBlur(e, index);
    };

    useEffect(() => {
        const focusedItem = document.getElementById(subNavigation[0].id);
        if (focusedItem) {
            focusedItem.focus();
        }
    }, []);

    const dropdownPlacement = 90 + 150 * index;
    return (
        <ul
            className={classNames('tab-menu-mobile__dropdown', styles.dropdown)}
            style={{ left: `${dropdownPlacement}px` }}
            onBlur={blur}
        >
            {subNavigation.map(menuItem => (
                <NavigationItem
                    key={menuItem.id}
                    path={`${tab.path}?subPage=${menuItem.queryParam}`}
                    onClick={closeMenu}
                    title={menuItem.title}
                    navigationType="large"
                    id={menuItem.id}
                />
            ))}
        </ul>
    );
};
export default TabSubNavigation;
