import { all, call, put, takeEvery, CallEffect, PutEffect } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import { businessPaths } from 'commons/src/constants';
import RequestActions from 'commons/src/models/RequestTypes';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import history from 'commons/src/store/history';
import { fetchBuilding } from '../actions/locationActions';
import { AddZoneGroup, ZoneGroupActions } from '../actions/zoneGroupActions';
import addGroup from '../api/zoneGroupApi';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type AddZoneGroupSaga = Generator<CallEffect | PutEffect | RequestActions, void, void>;
export function* addZoneGroupSaga({ payload, locationId }: AddZoneGroup): AddZoneGroupSaga {
    try {
        yield call(addGroup, payload, locationId);
        yield put(fetchBuilding(locationId));
        yield put(requestSuccess(RequestType.AddZoneGroup, RequestActionType.Success));
        history.push(`/${businessPaths.buildings}/${locationId}`);
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.AddZoneGroup, RequestActionType.Error));
    }
}

export default function* zoneGroupSagas(): Generator {
    yield all([takeEvery(ZoneGroupActions.AddZoneGroupInit, addZoneGroupSaga)]);
}
