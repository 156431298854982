import { sensorUnits } from 'commons/src/constants';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { Units } from 'commons/src/models/commonTypeScript';
import { PartnerInsightAverage, PartnerSummaryCSVData, PartnerSummaryThresholdData } from './PartnerSummaryModels';

const thresholds: { [sensorType: string]: number[] } = {
    [SensorTypes.co2]: [1000, 1500],
    [SensorTypes.temp]: [20, 26],
    [SensorTypes.humidity]: [30, 60],
};

export const parseCSV = (csvData: string): PartnerSummaryCSVData[] => {
    const content: string[] = csvData.split(/[\n;]/).slice(6);

    const data: PartnerSummaryCSVData[] = [];
    for (let i = 0; i < content.length; i += 6) {
        const chunk: string[] = content.slice(i, i + 6);
        data.push({
            serialNumber: chunk[0],
            organization: chunk[1],
            device: chunk[2],
            averages: {
                [SensorTypes.co2]: parseInt(chunk[3], 10) || undefined,
                [SensorTypes.temp]: parseInt(chunk[4], 10) || undefined,
                [SensorTypes.humidity]: parseInt(chunk[5], 10) || undefined,
            },
        });
    }
    return data;
};

const groupArrayValuesBySerialNumber = (
    csvData: PartnerSummaryCSVData[]
): { [serialNumber: string]: PartnerSummaryCSVData[] } =>
    csvData.reduce((hash: { [serialNumber: string]: PartnerSummaryCSVData[] }, obj) => {
        const { serialNumber } = obj;
        if (serialNumber === undefined) return hash;
        return Object.assign(hash, { [serialNumber]: (hash[serialNumber] || []).concat(obj) });
    }, {});

export const averageValuesFromMultipleLists = (arrays: PartnerSummaryCSVData[][]): PartnerSummaryCSVData[] => {
    const allCSVData: PartnerSummaryCSVData[] = arrays.flatMap(it => it);
    const groupedBySerialNumber: { [serialNumber: string]: PartnerSummaryCSVData[] } =
        groupArrayValuesBySerialNumber(allCSVData);

    return Object.values(groupedBySerialNumber).reduce((acc, item) => {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const co2 = item.filter(it => it.averages.co2 !== undefined).map(it => it.averages.co2!);
        const temp = item.filter(it => it.averages.temp !== undefined).map(it => it.averages.temp!);
        const humidity = item.filter(it => it.averages.humidity !== undefined).map(it => it.averages.humidity!);
        /* eslint-enable @typescript-eslint/no-non-null-assertion */

        const result = {
            device: item[0].device,
            organization: item[0].organization,
            serialNumber: item[0].serialNumber,
            averages: {
                [SensorTypes.co2]: co2.length > 0 ? co2.reduce((accum, obj) => accum + obj) / co2.length : undefined,
                [SensorTypes.temp]:
                    temp.length > 0 ? temp.reduce((accum, obj) => accum + obj) / temp.length : undefined,
                [SensorTypes.humidity]:
                    humidity.length > 0 ? humidity.reduce((accum, obj) => accum + obj) / humidity.length : undefined,
            },
        };
        return [...acc, result];
    }, []);
};

export const convertValue = (sensor: string, units: Units, value?: number): string => {
    if (value === undefined) return '-';
    if (sensor === SensorTypes.temp) {
        if (units.tempUnit === 'f') {
            return (value * 1.8 + 32).toFixed(2).toString();
        }
    }
    return value.toFixed(2).toString();
};

const thresholdBreachesLessThan = (
    data: PartnerSummaryCSVData[],
    sensorUnit: string,
    sensorType: string
): { [sensorType: string]: PartnerInsightAverage } => ({
    [sensorType]: {
        sensorUnit,
        thresholds: thresholds[sensorType as keyof typeof SensorTypes],
        totalDevices: data.filter(it => {
            const value: number | undefined = it.averages[sensorType];
            if (value === undefined) return false;
            return value < thresholds[sensorType][0];
        }).length,
    },
});

const thresholdBreachesHigherThan = (
    data: PartnerSummaryCSVData[],
    sensorUnit: string,
    sensorType: string
): { [sensorType: string]: PartnerInsightAverage } => ({
    [sensorType]: {
        sensorUnit,
        thresholds: thresholds[sensorType as keyof typeof SensorTypes],
        totalDevices: data.filter(it => {
            const value: number | undefined = it.averages[sensorType];
            if (value === undefined) return false;
            return value > thresholds[sensorType][1];
        }).length,
    },
});

const thresholdBreachesBetween = (
    data: PartnerSummaryCSVData[],
    sensorUnit: string,
    sensorType: string
): { [sensorType: string]: PartnerInsightAverage } => ({
    [sensorType]: {
        sensorUnit,
        thresholds: thresholds[sensorType],
        totalDevices: data.filter(it => {
            const value: number | undefined = it.averages[sensorType];
            if (value === undefined) return false;
            return value >= thresholds[sensorType][0] && value <= thresholds[sensorType][1];
        }).length,
    },
});

export const getThresholdBreachCounts = (
    data: PartnerSummaryCSVData[],
    unitsParam: Units
): PartnerSummaryThresholdData => ({
    averagesLessThan: {
        ...thresholdBreachesLessThan(data, sensorUnits.ppm, SensorTypes.co2),
        ...thresholdBreachesLessThan(data, sensorUnits[unitsParam.tempUnit], SensorTypes.temp),
        ...thresholdBreachesLessThan(data, sensorUnits.pct, SensorTypes.humidity),
    },
    averagesBetween: {
        ...thresholdBreachesBetween(data, sensorUnits.ppm, SensorTypes.co2),
        ...thresholdBreachesBetween(data, sensorUnits[unitsParam.tempUnit], SensorTypes.temp),
        ...thresholdBreachesBetween(data, sensorUnits.pct, SensorTypes.humidity),
    },
    averagesHigherThan: {
        ...thresholdBreachesHigherThan(data, sensorUnits.ppm, SensorTypes.co2),
        ...thresholdBreachesHigherThan(data, sensorUnits[unitsParam.tempUnit], SensorTypes.temp),
        ...thresholdBreachesHigherThan(data, sensorUnits.pct, SensorTypes.humidity),
    },
});
