import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { getDotColor, listSensorOrder, sortSensors } from '../../commonFunctions';
import MaterialIcon from '../../components/MaterialIcon';
import { fullwidthListElement } from '../../components/placeholder';
import { SensorComponent } from '../../components/sensors/SensorComponent';
import { SensorTypes } from '../../models/commonEnums';
import { CurrentSensorValuesType } from '../../models/commonTypeScript';
import { Store } from '../../reducers';

type StateProps = {
    loading: boolean;
};

type PassedProps = {
    serialNumber: string;
    deviceType: string;
    currentSensorValues?: CurrentSensorValuesType[];
    hubSyncAndConnectionLost?: boolean;
};

export type Props = PassedProps & StateProps;

export const CurrentValuesTileComponent = (props: Props): React.ReactElement => {
    const { loading, serialNumber, deviceType, currentSensorValues, hubSyncAndConnectionLost } = props;
    const { t: txt } = useTranslation();

    const order = listSensorOrder[deviceType];
    const orderedSensors = currentSensorValues ? sortSensors(currentSensorValues, order) : [];

    if (hubSyncAndConnectionLost) {
        return (
            <div className="info-header flex--center">
                <div className="text-whitespace text-left">{txt('DeviceSettings.DeviceMissingHubConnectionDueTo')}</div>
                <div className="info-header__content__spesifications info-header__content--padded">
                    <div className="flex flex--align-center text-medium">
                        <MaterialIcon name="battery_alert" />
                    </div>
                    <div className="text-small">{txt('LowBattery')}</div>
                </div>
                <div className="info-header__content__spesifications info-header__content--padded">
                    <div className="flex flex--align-center text-medium">
                        <MaterialIcon name="signal_wifi_statusbar_not_connected" />
                    </div>
                    <div className="text-small">{txt('DeviceSettings.OutsideHubReach')}</div>
                </div>
                <div className="info-header__content__spesifications info-header__content--padded">
                    <div className="flex flex--align-center text-medium">
                        <MaterialIcon name="cloud_off" />
                    </div>
                    <div className="text-small">{txt('DeviceSettings.OfflineHub')}</div>
                </div>
            </div>
        );
    }
    return (
        <ReactPlaceholder ready={!loading} customPlaceholder={fullwidthListElement}>
            <div className="info-header info-header--block fit-height sensors-tile">
                <div className="info-header__content">
                    <h2 className="text-large text-bold">{txt('CurrentValues')}</h2>
                </div>
                <div className="info-header__content sensors-tile__sensors-grid">
                    {orderedSensors.map(sensor => {
                        const dotColor = getDotColor(sensor.thresholds, sensor.type, sensor.value);
                        return (
                            <div
                                className="sensors-tile__sensors-grid-item"
                                key={`current-value-${serialNumber}-${sensor.type}`}
                            >
                                <SensorComponent
                                    dotColor={dotColor}
                                    value={sensor.value}
                                    sensorType={sensor.type as SensorTypes}
                                    isRadonAverage={sensor.type === SensorTypes.radonShortTermAvg}
                                    unit={sensor.unit}
                                    displayStatusBar
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </ReactPlaceholder>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        app: { loading: loadingApp },
    } = state;

    return {
        loading: loadingApp,
    };
};

export default connect(mapStateToProps)(CurrentValuesTileComponent);
