import React, { ReactElement } from 'react';
import classNames from 'classnames';
import spinner from '../img/spinner';
import InputLabel from './input/InputLabel';

type Props = {
    label?: string;
    customLabel?: string | ReactElement;
    value: string;
    largeText?: boolean;
    invalid?: boolean;
    id: string;
    loading?: boolean;
    noPadding?: boolean;
    infoText?: string;
};

const LabeledText = ({
    label,
    value,
    largeText,
    invalid,
    id,
    loading,
    noPadding,
    infoText,
    customLabel,
}: Props): React.ReactElement => (
    <div className="labeled-text">
        <InputLabel htmlFor={id} label={label} customHeader={customLabel} infoText={infoText} invalid={invalid} />
        {loading ? (
            spinner
        ) : (
            <span
                id={id}
                className={classNames('labeled-text__value', {
                    'text-large': largeText,
                    'labeled-text__value--no-padding': noPadding,
                })}
            >
                {value}
            </span>
        )}
    </div>
);

export default LabeledText;
