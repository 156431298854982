import {
    ADD_FLOORPLAN,
    ADD_FLOORPLAN_ERROR,
    ADD_FLOORPLAN_SUCCESS,
    ADD_FLOORPLAN_ZONE_AND_DEVICE,
    ADD_FLOORPLAN_ZONE_AND_DEVICE_ERROR,
    ADD_FLOORPLAN_ZONE_AND_DEVICE_SUCCESS,
    DELETE_FLOORPLAN,
    DELETE_FLOORPLAN_ERROR,
    DELETE_FLOORPLAN_SUCCESS,
    FloorPlanReducerCommonActions,
    REMOVE_FLOORPLAN_ZONE_DEVICE,
    REMOVE_FLOORPLAN_ZONE_DEVICE_ERROR,
    REMOVE_FLOORPLAN_ZONE_DEVICE_SUCCESS,
    UPDATE_FLOORPLAN_ZONE_AND_DEVICE,
    UPDATE_FLOORPLAN_ZONE_AND_DEVICE_ERROR,
    UPDATE_FLOORPLAN_ZONE_AND_DEVICE_SUCCESS,
} from 'commons/src/actions/FloorPlanActions';
import { FloorPlanType, FloorplanZone } from 'commons/src/models/commonTypeScript';
import { FloorplanActionType, FloorplanReducerAction } from '../actions/floorplanActions';

export type FloorplanError = keyof typeof FloorplanErrors;

export const FloorplanErrors = {
    NONE: 'NONE',
    ADD_FLOORPLAN_ERROR: 'ADD_FLOORPLAN_ERROR',
    ADD_FLOORPLAN_ZONE_ERROR: 'ADD_FLOORPLAN_ZONE_ERROR',
    UPDATE_FLOORPLAN_ZONE_ERROR: 'UPDATE_FLOORPLAN_ZONE_ERROR',
    REMOVE_FLOORPLAN_ZONE_ERROR: 'REMOVE_FLOORPLAN_ZONE_ERROR',
    FETCH_FLOORPLAN_IMAGE_ERROR: 'FETCH_FLOORPLAN_IMAGE_ERROR',
};

export const floorPlanZoneHasDevice = (floorplanZone: FloorplanZone): floorplanZone is Required<FloorplanZone> => {
    return !!floorplanZone.device;
};

type FloorplansType = { [locationId: string]: FloorPlanType[] };
export type FloorplanState = {
    modalVisible: boolean;
    adding: boolean;
    loadingImage: boolean;
    updating: boolean;
    error: FloorplanError | string;
    images: { [floorId: string]: string };
    locationId: string | undefined;
    activeFloorPlanId: string | undefined;
    deleteFloorLoading: boolean;
    deleteFloorError: boolean;
    floorplans: FloorplansType;
    previousFloorPlan: FloorplansType;
};

const initialState: FloorplanState = {
    modalVisible: false,
    adding: false,
    loadingImage: false,
    updating: false,
    error: FloorplanErrors.NONE,
    images: {},
    locationId: undefined,
    activeFloorPlanId: undefined,
    deleteFloorLoading: false,
    deleteFloorError: false,
    floorplans: {},
    previousFloorPlan: {},
};

const addFloorplan = (locationId: string, floorplan: FloorPlanType, currentFloors: FloorplansType): FloorplansType => ({
    ...currentFloors,
    [locationId]: [...(currentFloors[locationId] || []), floorplan],
});

const updateFloorName = (
    floorId: string,
    name: string,
    locationId: string,
    currentFloors: FloorplansType
): FloorplansType => {
    const indexOfFloorToEdit = currentFloors[locationId].findIndex(floor => floor.id === floorId);
    const copyOfLocationFloors = [...currentFloors[locationId]];
    if (indexOfFloorToEdit >= 0) copyOfLocationFloors[indexOfFloorToEdit].name = name;
    return {
        ...currentFloors,
        [locationId]: copyOfLocationFloors,
    };
};

const removeFloorplan = (
    floorplanId: string,
    locationId: string,
    currentFloorplans: FloorplansType
): FloorplansType => ({
    ...currentFloorplans,
    [locationId]: currentFloorplans[locationId].filter(floorplan => floorplan.id !== floorplanId),
});

const addFloorplanZone = (
    zone: FloorplanZone,
    locationId: string,
    floorPlanId: string,
    currentFloors: FloorplansType
): FloorplansType => ({
    ...currentFloors,
    [locationId]: currentFloors[locationId].map(floorplan => {
        if (floorplan.id !== floorPlanId) return floorplan;
        return {
            ...floorplan,
            zones: [...floorplan.zones, zone],
        };
    }),
});

const removeFloorplanZone = (
    floorplanId: string,
    zoneId: string,
    locationId: string,
    currentFloors: FloorplansType
): FloorplansType => ({
    ...currentFloors,
    [locationId]: currentFloors[locationId].map(floorplan => {
        if (floorplan.id !== floorplanId) return floorplan;
        return {
            ...floorplan,
            zones: floorplan.zones.filter(zone => zone.id !== zoneId),
        };
    }),
});

const updateFloorplanZone = (
    updatedZone: FloorplanZone,
    locationId: string,
    floorPlanId: string,
    currentFloors: FloorplansType
): FloorplansType => ({
    ...currentFloors,
    [locationId]: currentFloors[locationId].map(floorplan => {
        if (floorplan.id !== floorPlanId) return floorplan;
        return {
            ...floorplan,
            zones: floorplan.zones.map(existingZone =>
                existingZone.id === updatedZone.id ? updatedZone : existingZone
            ),
        };
    }),
});

export default (
    state: FloorplanState = initialState,
    action: FloorPlanReducerCommonActions | FloorplanReducerAction
): FloorplanState => {
    switch (action.type) {
        case FloorplanActionType.SetActiveFloorplanInit:
            return {
                ...state,
                activeFloorPlanId: action.floorId,
            };
        case ADD_FLOORPLAN:
            return {
                ...state,
                adding: true,
                error: FloorplanErrors.NONE,
            };
        case ADD_FLOORPLAN_SUCCESS:
            return {
                ...state,
                modalVisible: false,
                adding: false,
                activeFloorPlanId: action.floorplan.id,
                error: FloorplanErrors.NONE,
                floorplans: addFloorplan(action.locationId, action.floorplan, state.floorplans),
            };
        case DELETE_FLOORPLAN_SUCCESS:
            return {
                ...state,
                deleteFloorLoading: false,
                deleteFloorError: false,
                activeFloorPlanId: undefined,
                floorplans: removeFloorplan(action.floorplanId, action.locationId, state.floorplans),
            };
        case ADD_FLOORPLAN_ZONE_AND_DEVICE_SUCCESS:
            return {
                ...state,
                error: FloorplanErrors.NONE,
                updating: false,
                floorplans: addFloorplanZone(action.zone, action.locationId, action.floorPlanId, state.floorplans),
            };
        case REMOVE_FLOORPLAN_ZONE_DEVICE_SUCCESS:
            return {
                ...state,
                error: FloorplanErrors.NONE,
                updating: false,
                floorplans: removeFloorplanZone(action.floorplanId, action.zoneId, action.locationId, state.floorplans),
            };
        case UPDATE_FLOORPLAN_ZONE_AND_DEVICE: {
            // This is an optimistic update, meaning that the zone is updated before a success response
            // is received from the server. The purpose is to make the floor map editor more responsive.
            const { floorplanId, serialNumber, zoneId, zoneBoundary, devicePosition, locationId } = action;
            const updatedZone = {
                id: zoneId,
                boundary: zoneBoundary,
                device: {
                    serialNumber,
                    position: devicePosition,
                },
            };
            return {
                ...state,
                updating: true,
                floorplans: updateFloorplanZone(updatedZone, locationId, floorplanId, state.floorplans),
                previousFloorPlan: state.floorplans,
            };
        }
        case UPDATE_FLOORPLAN_ZONE_AND_DEVICE_ERROR:
            return {
                ...state,
                error: FloorplanErrors.UPDATE_FLOORPLAN_ZONE_ERROR,
                updating: false,
                floorplans: state.previousFloorPlan,
            };
        case FloorplanActionType.UpdateFloorNameSuccess:
            return {
                ...state,
                floorplans: updateFloorName(action.floorId, action.name, action.locationId, state.floorplans),
            };
        case ADD_FLOORPLAN_ERROR:
            return {
                ...state,
                adding: false,
                error: FloorplanErrors.ADD_FLOORPLAN_ERROR,
            };
        case ADD_FLOORPLAN_ZONE_AND_DEVICE:
        case REMOVE_FLOORPLAN_ZONE_DEVICE:
            return {
                ...state,
                updating: true,
            };
        case UPDATE_FLOORPLAN_ZONE_AND_DEVICE_SUCCESS:
            return {
                ...state,
                error: FloorplanErrors.NONE,
                updating: false,
            };
        case ADD_FLOORPLAN_ZONE_AND_DEVICE_ERROR:
            return {
                ...state,
                error: FloorplanErrors.ADD_FLOORPLAN_ZONE_ERROR,
                updating: false,
            };
        case REMOVE_FLOORPLAN_ZONE_DEVICE_ERROR:
            return {
                ...state,
                error: FloorplanErrors.REMOVE_FLOORPLAN_ZONE_ERROR,
                updating: false,
            };
        case FloorplanActionType.SetFloorplanModalVisisbleInit:
            return {
                ...state,
                modalVisible: action.visible,
            };
        case FloorplanActionType.FetchFloorplanImageInit:
            return {
                ...state,
                error: FloorplanErrors.NONE,
                loadingImage: true,
            };
        case FloorplanActionType.FetchFloorplanImageSuccess:
            return {
                ...state,
                loadingImage: false,
                error: FloorplanErrors.NONE,
                images: {
                    ...state.images,
                    [action.floorplanId]: action.image,
                },
                locationId: action.locationId,
            };
        case FloorplanActionType.FetchFloorplanImageError:
            return {
                ...state,
                loadingImage: false,
                error: FloorplanErrors.FETCH_FLOORPLAN_IMAGE_ERROR,
            };
        case DELETE_FLOORPLAN:
            return {
                ...state,
                deleteFloorLoading: true,
                deleteFloorError: false,
            };
        case DELETE_FLOORPLAN_ERROR:
            return {
                ...state,
                deleteFloorLoading: false,
                deleteFloorError: true,
            };
        case FloorplanActionType.FetchLocationFloorplansSuccess:
            return {
                ...state,
                floorplans: { ...state.floorplans, [action.locationId]: action.floorplans },
            };
        default:
            return state;
    }
};
