import React from 'react';
import DropdownOptions from '../../components/dropdown/DropdownOptions';
import { roleRestrictions, sensorGraphPeriods } from '../../constants';
import { DashboardVisibility } from '../../models/commonEnums';
import { SelectedPeriod } from '../../models/commonTypeScript';
import { MenuItem } from '../../models/menuModels';

type Props = {
    onRemoveTile: () => void;
    visibility?: DashboardVisibility;
    updateTileWidth?: (width: number) => void;
    selectGraphPeriod?: (interval: SelectedPeriod) => void;
    selectedInterval?: SelectedPeriod;
    tileWidth?: number;
    isSensorTile: boolean;
    tileId: string;
    singleWidthDashboard?: boolean;
};

const tileHeaderMenu = ({
    onRemoveTile,
    updateTileWidth,
    isSensorTile,
    tileWidth,
    tileId,
    selectGraphPeriod,
    selectedInterval,
    singleWidthDashboard,
    visibility,
}: Props): React.ReactElement => {
    const dotMenuOptions: MenuItem[] = [
        {
            onClick: onRemoveTile,
            text: 'RemoveFromDashboard',
            requiredRoleLevel: roleRestrictions.dashboardTiles,
            requiredGroupTypes: [],
        },
    ];

    const sensorMenuOptions: MenuItem[] = [
        {
            onClick: (): void => updateTileWidth && updateTileWidth(1),
            text: '1column',
            selector: true,
            isSelected: tileWidth === 1,
            requiredRoleLevel: roleRestrictions.dashboardTiles,
            requiredGroupTypes: [],
        },
        {
            onClick: (): void => updateTileWidth && updateTileWidth(2),
            text: '2columns',
            selector: true,
            isSelected: tileWidth === 2,
            requiredRoleLevel: roleRestrictions.dashboardTiles,
            requiredGroupTypes: [],
        },
        {
            onClick: (): void => updateTileWidth && updateTileWidth(3),
            text: '3columns',
            isSelected: tileWidth === 3,
            selector: true,
            bottomLine: true,
            requiredRoleLevel: roleRestrictions.dashboardTiles,
            requiredGroupTypes: [],
        },
        {
            onClick: onRemoveTile,
            text: 'RemoveFromDashboard',
            requiredRoleLevel: roleRestrictions.dashboardTiles,
            requiredGroupTypes: [],
        },
    ];

    const sensorTileGraphPeriodOptions = (): MenuItem[] =>
        (!visibility || visibility === DashboardVisibility.user) &&
        (tileWidth === 1 || singleWidthDashboard) &&
        selectGraphPeriod &&
        selectedInterval
            ? [
                  {
                      onClick: (): void => selectGraphPeriod(sensorGraphPeriods.halfDay),
                      text: '12hours',
                      selector: true,
                      isSelected: selectedInterval.name === sensorGraphPeriods.halfDay.name,
                      requiredRoleLevel: roleRestrictions.dashboardTiles,
                      requiredGroupTypes: [],
                  },
                  {
                      onClick: (): void => selectGraphPeriod(sensorGraphPeriods.twoDays),
                      text: '48hours',
                      selector: true,
                      isSelected: selectedInterval.name === sensorGraphPeriods.twoDays.name,
                      requiredRoleLevel: roleRestrictions.dashboardTiles,
                      requiredGroupTypes: [],
                  },
                  {
                      onClick: (): void => selectGraphPeriod(sensorGraphPeriods.week),
                      text: 'Week',
                      selector: true,
                      isSelected: selectedInterval.name === sensorGraphPeriods.week.name,
                      requiredRoleLevel: roleRestrictions.dashboardTiles,
                      requiredGroupTypes: [],
                  },
                  {
                      onClick: (): void => selectGraphPeriod(sensorGraphPeriods.month),
                      text: 'Month',
                      selector: true,
                      isSelected: selectedInterval.name === sensorGraphPeriods.month.name,
                      requiredRoleLevel: roleRestrictions.dashboardTiles,
                      requiredGroupTypes: [],
                  },
                  {
                      onClick: (): void => selectGraphPeriod(sensorGraphPeriods.year),
                      text: 'Year',
                      selector: true,
                      isSelected: selectedInterval.name === sensorGraphPeriods.year.name,
                      bottomLine: true,
                      requiredRoleLevel: roleRestrictions.dashboardTiles,
                      requiredGroupTypes: [],
                  },
              ]
            : [];

    return (
        <DropdownOptions
            options={isSensorTile ? [...sensorTileGraphPeriodOptions(), ...sensorMenuOptions] : dotMenuOptions}
            id={`dropdownButton${tileId}`}
            dotMenu
        />
    );
};

export default tileHeaderMenu;
