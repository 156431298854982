import { all, call, put, takeEvery, CallEffect, PutEffect } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import config from 'commons/src/config';
import RequestActions from 'commons/src/models/RequestTypes';
import displayAlertBoxSaga from 'commons/src/sagas/displayAlertBox';
import { toErrorType, toErrorTypeWithMessage } from 'commons/src/sagas/isErrorType';
import history from 'commons/src/store/history';
import {
    AddEmailTrigger,
    AddThirdPartyIntegration,
    AddThirdPartyIntegrationSuccess,
    addThirdPartyIntegrationSuccess,
    DeleteThirdPartyIntegration,
    deleteThirdPartyIntegrationSuccess,
    FetchThirdPartyIntegrationClientID,
    FetchThirdPartyIntegrationMapping,
    FetchThirdPartyIntegrationMappingSuccess,
    fetchThirdPartyIntegrationMappingSuccess,
    FetchThirdPartyIntegrationsSuccess,
    fetchThirdPartyIntegrationsSuccess,
    RegisterThirdPartyIntegrationMapping,
    RegisterThirdPartyIntegrationMappingSuccess,
    registerThirdPartyIntegrationMappingSuccess,
    ThirdPartyIntegrationActionType,
    UpdateEmailTrigger,
    UpdateThirdPartyIntegrationMapping,
    updateThirdPartyIntegrationMappingSuccess,
} from '../actions/thirdPartyIntegrationActions';
import {
    addAlertTrigger,
    addThirdPartyIntegration,
    deleteThirdPartyIntegration,
    fetchThirdPartyIntegrationClientID,
    fetchThirdPartyIntegrationMapping,
    fetchThirdPartyIntegrations,
    registerThirdPartyIntegrationMapping,
    updateAlertTrigger,
    updateThirdPartyIntegration,
} from '../api/integrationsApi';
import { paths, integrationTypes, grantType } from '../constants';
import {
    NewThirdPartyIntegration,
    ThirdPartyIntegration,
    ThirdPartyIntegrationAlert,
    ThirdPartyIntegrationMapping,
} from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export const extractTriggers = (integrations: ThirdPartyIntegration[]): ThirdPartyIntegrationAlert[] =>
    integrations.reduce((triggersList, integration) => {
        if (integration.triggers.length > 0) {
            integration.triggers.forEach(currentTrigger => {
                const trigger = {
                    ...{ integrationType: integration.type, integrationId: integration.id },
                    ...currentTrigger,
                };
                triggersList.push(trigger);
            });
        }
        return triggersList.flat();
    }, [] as ThirdPartyIntegrationAlert[]);

type FetchThirdPartyIntegrationsSagaReturnType = Generator<
    | CallEffect<{ integrations: ThirdPartyIntegration[] }>
    | PutEffect<FetchThirdPartyIntegrationsSuccess>
    | RequestActions,
    void,
    { integrations: ThirdPartyIntegration[] }
>;
export function* fetchThirdPartyIntegrationsSaga(): FetchThirdPartyIntegrationsSagaReturnType {
    try {
        const thirdPartyIntegrations: { integrations: ThirdPartyIntegration[] } = yield call(
            fetchThirdPartyIntegrations
        );
        const triggers: ThirdPartyIntegrationAlert[] = extractTriggers(thirdPartyIntegrations.integrations);
        yield put(
            fetchThirdPartyIntegrationsSuccess({
                integrations: thirdPartyIntegrations.integrations.sort((item1, item2) =>
                    item1.type.localeCompare(item2.type)
                ),
                notificationAlerts: triggers.sort((item1, item2) =>
                    item1.integrationType.localeCompare(item2.integrationType)
                ),
            })
        );
        yield put(requestSuccess(RequestType.FetchThirdPartyIntegrations, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchThirdPartyIntegrations, RequestActionType.Error));
    }
}

type AddThirdPartyIntegrationSagaReturnType = Generator<
    CallEffect<{ id: string }> | CallEffect | PutEffect<AddThirdPartyIntegrationSuccess> | RequestActions,
    void,
    { id: string }
>;
export function* addThirdPartyIntegrationSaga({
    integration,
}: AddThirdPartyIntegration): AddThirdPartyIntegrationSagaReturnType {
    try {
        const response: { id: string } = yield call(addThirdPartyIntegration, integration);
        yield put(addThirdPartyIntegrationSuccess({ id: response.id, name: integration.name, type: integration.type }));
        yield put(requestSuccess(RequestType.AddThirdPartyIntegration, RequestActionType.Success));
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(requestError(errorWithMessage.error, RequestType.AddThirdPartyIntegration, RequestActionType.Error));
        if (integration.grantType !== grantType.oauthAuthorizationCode) {
            yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
        }
    }
}

type FetchThirdPartyIntegrationMappingSagaReturnType = Generator<
    CallEffect<ThirdPartyIntegrationMapping> | PutEffect<FetchThirdPartyIntegrationMappingSuccess> | RequestActions,
    void,
    ThirdPartyIntegrationMapping
>;
export function* fetchThirdPartyIntegrationMappingSaga({
    integrationId,
}: FetchThirdPartyIntegrationMapping): FetchThirdPartyIntegrationMappingSagaReturnType {
    try {
        const thirdPartyMapping: ThirdPartyIntegrationMapping = yield call(
            fetchThirdPartyIntegrationMapping,
            integrationId
        );
        yield put(fetchThirdPartyIntegrationMappingSuccess(thirdPartyMapping));
        yield put(requestSuccess(RequestType.FetchThirdPartyIntegrationMapping, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(
            requestError(errorAsErrorType, RequestType.FetchThirdPartyIntegrationMapping, RequestActionType.Error)
        );
    }
}

type RegisterThirdPartyIntegrationMappingSagaReturnType = Generator<
    CallEffect | PutEffect<RegisterThirdPartyIntegrationMappingSuccess> | RequestActions,
    void
>;
export function* registerThirdPartyIntegrationMappingSaga({
    integrationId,
    thirdPartyMapping,
}: RegisterThirdPartyIntegrationMapping): RegisterThirdPartyIntegrationMappingSagaReturnType {
    try {
        yield call(registerThirdPartyIntegrationMapping, integrationId, thirdPartyMapping);
        yield put(registerThirdPartyIntegrationMappingSuccess());
        yield put(requestSuccess(RequestType.RegisterThirdPartyIntegrationMapping, RequestActionType.Success));
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(
            requestError(
                errorWithMessage.error,
                RequestType.RegisterThirdPartyIntegrationMapping,
                RequestActionType.Error
            )
        );
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

export function* updateThirdPartyIntegrationMappingSaga({
    integrationId,
    thirdPartyMapping,
}: UpdateThirdPartyIntegrationMapping): Generator {
    try {
        yield call(registerThirdPartyIntegrationMapping, integrationId, thirdPartyMapping);
        yield put(updateThirdPartyIntegrationMappingSuccess());
        yield put(requestSuccess(RequestType.UpdateThirdPartyIntegrationMapping, RequestActionType.Success));
        yield call(displayAlertBoxSaga, 'ThirdParty.UpdateIntegrationSuccess', false, true);
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(
            requestError(
                errorWithMessage.error,
                RequestType.UpdateThirdPartyIntegrationMapping,
                RequestActionType.Error
            )
        );
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

export function* deleteThirdPartyIntegrationSaga({ integrationId }: DeleteThirdPartyIntegration): Generator {
    try {
        yield call(deleteThirdPartyIntegration, integrationId);
        history.replace(`/${paths.thirdParty}`);
        yield put(deleteThirdPartyIntegrationSuccess(integrationId));
        yield put(requestSuccess(RequestType.DeleteThirdPartyIntegration, RequestActionType.Success));
        yield call(displayAlertBoxSaga, 'ThirdParty.DeleteIntegrationSuccess', false, true);
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(
            requestError(errorWithMessage.error, RequestType.DeleteThirdPartyIntegration, RequestActionType.Error)
        );
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}
export const storeIntegrationInLocalStorage = (integration: NewThirdPartyIntegration): string => {
    const state = uuidv4();
    localStorage.setItem('integration_form', JSON.stringify({ state, integration }));
    return state;
};

type FetchThirdPartyIntegrationClientIDSagaReturnType = Generator<
    CallEffect | RequestActions,
    void,
    { clientId: string; scope: string }
>;
export function* fetchThirdPartyIntegrationClientIDSaga({
    integration,
}: FetchThirdPartyIntegrationClientID): FetchThirdPartyIntegrationClientIDSagaReturnType {
    try {
        const response: { clientId: string; scope: string } = yield call(
            fetchThirdPartyIntegrationClientID,
            integration.type
        );
        const state = storeIntegrationInLocalStorage(integration);
        const { clientId, scope } = response;
        const { authUrl } = integrationTypes[integration.type];
        window.location.href = `${authUrl}?response_type=code&client_id=${clientId}&scope=${scope}&redirect_uri=${config.thirdPartyRedirectUrl}&state=${state}`;
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.AddThirdPartyIntegration, RequestActionType.Error));
    }
}

type AddEmailTriggerSagaReturnType = Generator<CallEffect | PutEffect | RequestActions, void, { id: string }>;
export function* addEmailTriggerSaga({ integration, trigger }: AddEmailTrigger): AddEmailTriggerSagaReturnType {
    try {
        const response: { id: string } = yield call(addThirdPartyIntegration, integration);
        yield call(addAlertTrigger, response.id, trigger);
        yield put(requestSuccess(RequestType.AddEmailTrigger, RequestActionType.Success));
        history.push(`/${paths.alerts}`);
        yield call(displayAlertBoxSaga, 'NotificationAlerts.AddSuccess', false, true);
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield put(requestError(errorWithMessage.error, RequestType.AddEmailTrigger, RequestActionType.Error));
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

export function* updateEmailTriggerSaga({ integration, trigger }: UpdateEmailTrigger): Generator {
    try {
        yield call(updateThirdPartyIntegration, trigger.integrationId, integration);
        yield call(updateAlertTrigger, trigger);
        yield put(requestSuccess(RequestType.UpdateEmailTrigger, RequestActionType.Success));
        history.push(`/${paths.alerts}`);
        yield call(displayAlertBoxSaga, 'NotificationAlerts.EditSuccess', false, true);
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error, 'NotificationAlerts.EditError');
        yield put(requestError(errorWithMessage.error, RequestType.UpdateEmailTrigger, RequestActionType.Error));
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
    }
}

export default function* thirdPartyIntegrationSagas(): Generator {
    yield all([
        takeEvery(ThirdPartyIntegrationActionType.FetchThirdPartyIntegrationsInit, fetchThirdPartyIntegrationsSaga),
        takeEvery(ThirdPartyIntegrationActionType.AddThirdPartyIntegrationInit, addThirdPartyIntegrationSaga),
        takeEvery(
            ThirdPartyIntegrationActionType.FetchThirdPartyIntegrationClientIDInit,
            fetchThirdPartyIntegrationClientIDSaga
        ),
        takeEvery(
            ThirdPartyIntegrationActionType.FetchThirdPartyIntegrationMappingInit,
            fetchThirdPartyIntegrationMappingSaga
        ),
        takeEvery(
            ThirdPartyIntegrationActionType.RegisterThirdPartyIntegrationMappingInit,
            registerThirdPartyIntegrationMappingSaga
        ),
        takeEvery(ThirdPartyIntegrationActionType.DeleteThirdPartyIntegrationInit, deleteThirdPartyIntegrationSaga),
        takeEvery(
            ThirdPartyIntegrationActionType.UpdateThirdPartyIntegrationMappingInit,
            updateThirdPartyIntegrationMappingSaga
        ),
        takeEvery(ThirdPartyIntegrationActionType.AddEmailTriggerInit, addEmailTriggerSaga),
        takeEvery(ThirdPartyIntegrationActionType.UpdateEmailTriggerInit, updateEmailTriggerSaga),
    ]);
}
