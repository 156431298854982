import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import RequestActions from 'commons/src/models/RequestTypes';
import displayAlertBoxSaga from 'commons/src/sagas/displayAlertBox';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import { DownloadCSVActionType, DownloadSensorDataCSV } from '../actions/downloadCSVActions';
import { downloadCsv } from '../api/buildingApi';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type DownloadSensorDataCSVReturnType = Generator<CallEffect<void> | CallEffect | PutEffect | RequestActions, void>;

export function* downloadSensorDataCsv({
    payload,
    locationId,
}: DownloadSensorDataCSV): DownloadSensorDataCSVReturnType {
    try {
        yield call(downloadCsv, locationId, payload);
        yield put(requestSuccess(RequestType.DownloadSensorDataCsv, RequestActionType.Success));
        yield call(displayAlertBoxSaga, 'CsvExport.SuccessMessage', false, true);
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.DownloadSensorDataCsv, RequestActionType.Error));
    }
}

export default function* downloadCSVSaga(): Generator {
    yield all([takeEvery(DownloadCSVActionType.DownloadSensorDataCsvInit, downloadSensorDataCsv)]);
}
