import React from 'react';
import StandAloneSpinner from '../../img/StandAloneSpinner';

const SpinnerPage = (): React.ReactElement => (
    <div className="page-wrapper">
        <div className="modal__content__spinner modal__content__spinner--margin-top">
            <StandAloneSpinner />
        </div>
    </div>
);

export default SpinnerPage;
