import React from 'react';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import ResponseBox from './ResponseBox';

type Props = {
    children: React.ReactElement;
    error?: ErrorType;
};

// Similar concept to `react-placeholder`
// Show/hide comopnent based on error state

const RenderComponentOrError = ({ children, error }: Props): React.ReactElement => {
    if (error) return <ResponseBox text={`ErrorCodes.${error.error}`} />;
    return children ?? null;
};

export default RenderComponentOrError;
