import React from 'react';
import { useTranslation } from 'react-i18next';
import CircleButton from 'commons/src/components/buttons/CircleButton';
import { integrationTypeLogos, integrationTypes } from '../../../constants';

type Props = {
    selectIntegrationType: (type: string) => void;
};

export const AddThirdPartyIntegrationSelectViewComponent = ({ selectIntegrationType }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className="page-wrapper__medium page-wrapper__medium--white">
            <div className="form form__wide-container">
                <h2>{txt('ThirdParty.SelectService')}</h2>
                <div className="third-party__integrations-list">
                    {Object.keys(integrationTypes).map(type => (
                        <div className="third-party__integrations-list__row" key={type}>
                            <div className="third-party__integrations-list__row--padding-top">
                                <img
                                    src={integrationTypeLogos(type)}
                                    alt={txt('ThirdParty.IntegrationTypeLogo', {
                                        type: txt(`ThirdParty.${type}`),
                                    })}
                                />
                                <div className="third-party__integrations-list__row__text">
                                    {txt('ThirdParty.ConnectToIntegration', { integration: txt(`ThirdParty.${type}`) })}
                                </div>
                            </div>
                            <CircleButton
                                onClick={(): void => selectIntegrationType(type)}
                                testAttr={`${type.toLowerCase()}-select-integration-type`}
                                iconName="arrow_forward"
                                color="secondary"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AddThirdPartyIntegrationSelectViewComponent;
