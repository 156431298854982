import React from 'react';
import { connect } from 'react-redux';
import Error from 'commons/src/components/errorComponents/Error';
import NewsletterSignUp from 'commons/src/features/settingsPage/NewsletterSignup';
import UserInfo from 'commons/src/features/settingsPage/UserSettings/UserInfo';
import UserPreferences from 'commons/src/features/settingsPage/UserSettings/UserPreferences';
import { Store } from '../../reducers';

type Props = {
    appError: boolean;
};

const BusinessAccountOverviewComponent = (props: Props): React.ReactElement => {
    const { appError } = props;
    if (appError) {
        return <Error />;
    }

    return (
        <>
            <UserInfo />
            <UserPreferences />
            <NewsletterSignUp />
        </>
    );
};

const mapStateToProps = (store: Store): Props => {
    return {
        appError: store.app.error,
    };
};

export default connect(mapStateToProps)(BusinessAccountOverviewComponent);
