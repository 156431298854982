/* eslint max-len: ["error", 1000] */
import React from 'react';

const spinner = (
    <svg
        className="spinner"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 80 80"
        style={{ enableBackground: 'new 0 0 80 80' }}
    >
        <linearGradient
            id="spinner-circle-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0.4995"
            y1="40"
            x2="79.4995"
            y2="40"
            gradientTransform="matrix(4.489659e-11 1 -1 4.489659e-11 79.9995 5.017417e-04)"
        >
            <stop className="stop-1" offset="0.1" />
            <stop className="stop-2" offset="1" />
        </linearGradient>
        <ellipse
            className="spinner__circle"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -16.5687 39.9996)"
            cx="40"
            cy="40"
            rx="38"
            ry="38"
        />
    </svg>
);
export default spinner;
