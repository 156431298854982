import React from 'react';
import DeviceName from 'commons/src/components/tableCells/DeviceName';
import styles from './GlobalSearch.module.scss';

type SearchResultProps = {
    locationName: string;
    segmentName: string;
    serialNumber: string;
    locationId: string;
    spaceId?: string;
};

const SearchResult = ({
    locationName,
    segmentName,
    serialNumber,
    locationId,
    spaceId,
}: SearchResultProps): React.ReactElement => {
    return (
        <li className={styles.listItem}>
            <span title={locationName} className={styles.locationName}>
                {locationName}
            </span>
            <span className={styles.deviceLink}>
                <DeviceName name={segmentName} serialNumber={serialNumber} locationId={locationId} spaceId={spaceId} />
            </span>
        </li>
    );
};

export default SearchResult;
