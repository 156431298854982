import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DatePicker from 'commons/src/components/DatePicker';
import { dateFormats } from 'commons/src/constants';
import { Store } from '../../../reducers';

export type ParentProps = {
    segmentEndDate: string | undefined;
    segmentStartDate: string | undefined;
    setStartDate: (date: Moment) => void;
    setEndDate: (date: Moment) => void;
    startDate: Moment | null;
    endDate: Moment | null;
    minNumberOfDays: number;
    maxNumberOfDays?: number;
    validateSelection: boolean;
    setDatesAreValid: (valid: boolean) => void;
};

type StateProps = {
    dateFormat: keyof typeof dateFormats;
};

type Props = StateProps & ParentProps;

export const DateToFromSelectorComponent = (props: Props): React.ReactElement => {
    const {
        dateFormat,
        segmentEndDate,
        segmentStartDate,
        setStartDate,
        setEndDate,
        startDate,
        endDate,
        minNumberOfDays,
        maxNumberOfDays,
        validateSelection,
        setDatesAreValid,
    } = props;
    const { t: txt } = useTranslation();
    const [displayValidationStartDate, setDisplayValidationStartDate] = useState(false);
    const [displayValidationEndDate, setDisplayValidationEndDate] = useState(false);
    const [endDateHint, setEndDateHint] = useState(txt('RadonReportEndDateHint', { minDays: (5).toString() }));

    const onEndDateChange = (date: Moment): void => {
        const isSegmentEndDate = date && segmentEndDate && moment(date).isSame(segmentEndDate, 'day');
        const endOfSelectedDay = date && date.endOf('day');
        setEndDate(isSegmentEndDate && segmentEndDate ? moment.utc(segmentEndDate) : endOfSelectedDay);
        setDisplayValidationEndDate(false);
        setDisplayValidationStartDate(false);
    };

    const onStartDateChange = (date: Moment): void => {
        const isSegmentStartDate = date && segmentStartDate && moment(date).isSame(segmentStartDate, 'day');
        const startOfSelectedDay = date && date.startOf('day');
        setStartDate(isSegmentStartDate && segmentStartDate ? moment.utc(segmentStartDate) : startOfSelectedDay);
        setDisplayValidationStartDate(false);
        setDisplayValidationEndDate(false);
    };

    const outsideStartDateRange = (day: Moment): boolean => {
        if (!segmentStartDate) return true;
        const segmentEnd = segmentEndDate || moment().subtract(1, 'days').endOf('day');

        if (!moment.isMoment(day)) return false;

        const lessThanMinNumberODaysBeforeEndDate = moment(day)
            .endOf('day')
            .isAfter(moment(segmentEnd).subtract(minNumberOfDays, 'days').endOf('day'));

        const beforeSegmentStartDate = moment(day).endOf('day').isBefore(moment(segmentStartDate).startOf('day'));

        return lessThanMinNumberODaysBeforeEndDate || beforeSegmentStartDate;
    };

    const outsideEndDateRange = (day: Moment): boolean => {
        if (!segmentStartDate) return true;
        const segmentEnd = segmentEndDate || moment().subtract(1, 'days').endOf('day');
        if (!moment.isMoment(day)) return false;

        const afterEndDate = moment(day).endOf('day').isAfter(moment(segmentEnd).endOf('day'));
        const beforeSegmentStartDate = moment(day).endOf('day').isBefore(moment(segmentStartDate).startOf('day'));
        const lessThanMinNumberOfDaysBeforeEndDate = moment(day)
            .endOf('day')
            .isBefore(moment(startDate).add(minNumberOfDays, 'days').endOf('day'));

        return afterEndDate || beforeSegmentStartDate || lessThanMinNumberOfDaysBeforeEndDate;
    };

    const validateDates = (): void => {
        const segmentEnd = segmentEndDate ? moment(segmentEndDate).endOf('day') : moment().startOf('day');
        const endDateBeforeSegmentEnd = endDate && endDate.isBefore(segmentEnd);
        const endDateValid =
            !!(endDate && !startDate) ||
            !!(
                endDate &&
                startDate &&
                endDate.isAfter(moment(startDate).add(minNumberOfDays - 1, 'days'), 'day') &&
                endDateBeforeSegmentEnd
            ) ||
            false;

        const startDateValid =
            !!(!endDate && startDate) ||
            (endDate && startDate && startDate.isBefore(moment(endDate).endOf('day'), 'hour')) ||
            false;
        let withinMaxNumberOfDays = true;
        if (startDate && endDate && maxNumberOfDays) {
            withinMaxNumberOfDays = moment(endDate).diff(moment(startDate), 'days') < maxNumberOfDays;
        }
        setDatesAreValid(withinMaxNumberOfDays && endDateValid && startDateValid);
        if (validateSelection) {
            setDisplayValidationStartDate(!startDateValid);
            let updatedEndDateHint = txt('RadonReportEndDateHint', { minDays: minNumberOfDays.toString() });
            if (!endDateBeforeSegmentEnd) updatedEndDateHint = txt('ReportEndDateBeforeEndHint');
            else if (maxNumberOfDays && !withinMaxNumberOfDays) {
                updatedEndDateHint = txt('Insight.MaxDaysHint', { maxDays: maxNumberOfDays.toString() });
            }
            setEndDateHint(updatedEndDateHint);
            setDisplayValidationEndDate(!endDateValid || !withinMaxNumberOfDays);
        }
    };

    useEffect(() => {
        validateDates();
    }, [validateSelection, startDate, endDate]);

    const initialVisibleMonth = (): Moment => {
        const threeMonthsBeforeEnd = moment(segmentEndDate).subtract(3, 'months');
        const startLessThanThreeMonthsAgo = moment(segmentStartDate).isAfter(threeMonthsBeforeEnd);
        const defaultStartDate = startLessThanThreeMonthsAgo ? moment(segmentStartDate) : threeMonthsBeforeEnd;
        const startWithinMaxNumberOfDays = maxNumberOfDays
            ? moment().subtract(maxNumberOfDays, 'days')
            : defaultStartDate;
        return startWithinMaxNumberOfDays.isAfter(defaultStartDate) ? startWithinMaxNumberOfDays : defaultStartDate;
    };

    const initialVisibleEndMonth = (): Moment => {
        if (endDate) {
            return moment(endDate);
        }
        if (startDate) {
            const maxDaysAfterStartDate = maxNumberOfDays && moment(startDate).add(maxNumberOfDays - 1, 'days');
            return maxDaysAfterStartDate && moment(segmentEndDate).isAfter(maxDaysAfterStartDate)
                ? maxDaysAfterStartDate
                : moment(segmentEndDate);
        }
        return moment(segmentEndDate);
    };

    return (
        <>
            <div className="radon-report__form__section__row">
                <DatePicker
                    id="startDate"
                    defaultValue={startDate || undefined}
                    onChange={onStartDateChange}
                    dateFormat={dateFormat}
                    label="StartDate"
                    mandatory
                    initialVisibleMonth={initialVisibleMonth()}
                    disabledDate={outsideStartDateRange}
                    displayDateValidation={displayValidationStartDate}
                    selectedDate={startDate}
                    selectedDateHint="ReportStartDateHint"
                    standardWidth
                    testId="start-date"
                />
            </div>
            <div className="radon-report__form__section__row">
                <DatePicker
                    id="endDate"
                    defaultValue={endDate || undefined}
                    onChange={onEndDateChange}
                    dateFormat={dateFormat}
                    label={`${txt('EndDate')} ${
                        minNumberOfDays !== 0
                            ? txt('MinNumberOfMeasurementDays', {
                                  days: minNumberOfDays.toString(),
                              })
                            : ''
                    }`}
                    mandatory
                    initialVisibleMonth={initialVisibleEndMonth()}
                    disabledDate={outsideEndDateRange}
                    displayDateValidation={displayValidationEndDate}
                    selectedDate={endDate}
                    selectedDateHint={endDateHint}
                    standardWidth
                    testId="end-date"
                />
            </div>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = state;
    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(DateToFromSelectorComponent);
