import dotProp from 'dot-prop-immutable';
import {
    ADD_PUBLIC_DASHBOARD_SUCCESS,
    DashboardReducerActions,
    FETCH_DASHBOARD_FAIL,
    FETCH_DASHBOARD_SENSOR,
    FETCH_DASHBOARD_SUCCESS,
    FETCH_SENSOR_SEGMENT,
    FETCH_SENSOR_SEGMENT_ERROR,
    FETCH_SENSOR_SEGMENT_SUCCESS,
    POLL_DASHBOARD_SENSOR_DATA,
    SELECT_DASHBOARD_SUCCESS,
    STOP_POLL_DASHBOARD_SENSOR_DATA,
} from '../actions/DashboardActions';
import { LOGOUT_SUCCESS, LogOutSuccess } from '../actions/LoginAndRegisterActions';
import { SensorData } from '../models/commonTypeScript';

export interface PollingTile {
    name: string;
    number: number;
    period: string;
    resolution: string;
    resolutionDuration: string;
}

export type DashboardSensorDataStore = {
    error: boolean;
    loading: boolean;
    sensorData: SensorData;
    minValues: { [tileId: string]: { [interval: string]: number } };
    averageValues: { [tileId: string]: { [interval: string]: number | undefined } };
    pollingTiles: {
        [tileId: string]: PollingTile;
    };
};

const initialState = {
    error: false,
    loading: false,
    sensorData: {},
    minValues: {},
    averageValues: {},
    pollingTiles: {},
};

export default (state = initialState, action: DashboardReducerActions | LogOutSuccess): DashboardSensorDataStore => {
    switch (action.type) {
        case FETCH_DASHBOARD_SUCCESS:
        case SELECT_DASHBOARD_SUCCESS:
        case ADD_PUBLIC_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                sensorData: action.dashboardData.sensorData,
                minValues: action.dashboardData.minValues,
                averageValues: action.dashboardData.averageValues,
            };
        case FETCH_DASHBOARD_FAIL:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case FETCH_SENSOR_SEGMENT:
        case FETCH_DASHBOARD_SENSOR:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case FETCH_SENSOR_SEGMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                sensorData: dotProp.merge(state.sensorData, action.payload.ref, action.payload.sensorData.chartData),
                minValues: dotProp.merge(state.minValues, action.payload.ref, action.payload.sensorData.minValues),
                averageValues: dotProp.merge(
                    state.averageValues,
                    action.payload.ref,
                    action.payload.sensorData.averageValues
                ),
            };
        case FETCH_SENSOR_SEGMENT_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case POLL_DASHBOARD_SENSOR_DATA:
            return {
                ...state,
                pollingTiles:
                    action.payload.selectedInterval.name === 'week'
                        ? dotProp.delete(state.pollingTiles, action.payload.tileId)
                        : dotProp.merge(state.pollingTiles, action.payload.tileId, action.payload.selectedInterval),
            };
        case STOP_POLL_DASHBOARD_SENSOR_DATA:
            return {
                ...state,
                pollingTiles: {},
            };
        case LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
};
