import React from 'react';

const WaveEnhanceIcon = (): React.ReactElement => (
    <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="device-svg-icons"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44ZM24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46Z"
        />
        <path d="M22.8105 21H17.959V12.4336H22.8105V13.6172H19.3652V15.9434H22.5938V17.1211H19.3652V19.8105H22.8105V21Z" />
        <path d="M27.9023 14.3906C28.6328 14.3906 29.2012 14.5801 29.6074 14.959C30.0176 15.334 30.2227 15.9375 30.2227 16.7695V21H28.8457V17.0273C28.8457 16.5234 28.7422 16.1465 28.5352 15.8965C28.3281 15.6426 28.0078 15.5156 27.5742 15.5156C26.9453 15.5156 26.5078 15.709 26.2617 16.0957C26.0195 16.4824 25.8984 17.043 25.8984 17.7773V21H24.5215V14.5137H25.5938L25.7871 15.3926H25.8633C26.0039 15.166 26.1777 14.9805 26.3848 14.8359C26.5957 14.6875 26.8301 14.5762 27.0879 14.502C27.3496 14.4277 27.6211 14.3906 27.9023 14.3906Z" />
        <path d="M8.8125 22.8828V25.1738C8.8125 25.4121 8.80469 25.6445 8.78906 25.8711C8.77734 26.0977 8.76367 26.2734 8.74805 26.3984H8.82422C8.96094 26.1719 9.12891 25.9863 9.32812 25.8418C9.52734 25.6934 9.75 25.582 9.99609 25.5078C10.2461 25.4336 10.5117 25.3965 10.793 25.3965C11.2891 25.3965 11.7109 25.4805 12.0586 25.6484C12.4062 25.8125 12.6719 26.0684 12.8555 26.416C13.043 26.7637 13.1367 27.2148 13.1367 27.7695V32H11.7598V28.0273C11.7598 27.5234 11.6562 27.1465 11.4492 26.8965C11.2422 26.6426 10.9219 26.5156 10.4883 26.5156C10.0703 26.5156 9.73828 26.6035 9.49219 26.7793C9.25 26.9512 9.07617 27.207 8.9707 27.5469C8.86523 27.8828 8.8125 28.293 8.8125 28.7773V32H7.43555V22.8828H8.8125Z" />
        <path d="M17.6074 25.3906C18.4277 25.3906 19.0469 25.5723 19.4648 25.9355C19.8867 26.2988 20.0977 26.8652 20.0977 27.6348V32H19.1191L18.8555 31.0801H18.8086C18.625 31.3145 18.4355 31.5078 18.2402 31.6602C18.0449 31.8125 17.8184 31.9258 17.5605 32C17.3066 32.0781 16.9961 32.1172 16.6289 32.1172C16.2422 32.1172 15.8965 32.0469 15.5918 31.9062C15.2871 31.7617 15.0469 31.543 14.8711 31.25C14.6953 30.957 14.6074 30.5859 14.6074 30.1367C14.6074 29.4688 14.8555 28.9668 15.3516 28.6309C15.8516 28.2949 16.6055 28.1094 17.6133 28.0742L18.7383 28.0332V27.6934C18.7383 27.2441 18.6328 26.9238 18.4219 26.7324C18.2148 26.541 17.9219 26.4453 17.543 26.4453C17.2188 26.4453 16.9043 26.4922 16.5996 26.5859C16.2949 26.6797 15.998 26.7949 15.709 26.9316L15.2637 25.959C15.5801 25.791 15.9395 25.6543 16.3418 25.5488C16.748 25.4434 17.1699 25.3906 17.6074 25.3906ZM18.7324 28.9004L17.8945 28.9297C17.207 28.9531 16.7246 29.0703 16.4473 29.2812C16.1699 29.4922 16.0312 29.7812 16.0312 30.1484C16.0312 30.4688 16.127 30.7031 16.3184 30.8516C16.5098 30.9961 16.7617 31.0684 17.0742 31.0684C17.5508 31.0684 17.9453 30.9336 18.2578 30.6641C18.5742 30.3906 18.7324 29.9902 18.7324 29.4629V28.9004Z" />
        <path d="M25.4062 25.3906C26.1367 25.3906 26.7051 25.5801 27.1113 25.959C27.5215 26.334 27.7266 26.9375 27.7266 27.7695V32H26.3496V28.0273C26.3496 27.5234 26.2461 27.1465 26.0391 26.8965C25.832 26.6426 25.5117 26.5156 25.0781 26.5156C24.4492 26.5156 24.0117 26.709 23.7656 27.0957C23.5234 27.4824 23.4023 28.043 23.4023 28.7773V32H22.0254V25.5137H23.0977L23.291 26.3926H23.3672C23.5078 26.166 23.6816 25.9805 23.8887 25.8359C24.0996 25.6875 24.334 25.5762 24.5918 25.502C24.8535 25.4277 25.125 25.3906 25.4062 25.3906Z" />
        <path d="M32.2676 32.1172C31.6543 32.1172 31.123 31.998 30.6738 31.7598C30.2246 31.5215 29.8789 31.1562 29.6367 30.6641C29.3945 30.1719 29.2734 29.5469 29.2734 28.7891C29.2734 28 29.4062 27.3555 29.6719 26.8555C29.9375 26.3555 30.3047 25.9863 30.7734 25.748C31.2461 25.5098 31.7871 25.3906 32.3965 25.3906C32.7832 25.3906 33.1328 25.4297 33.4453 25.5078C33.7617 25.582 34.0293 25.6738 34.248 25.7832L33.8379 26.8848C33.5996 26.7871 33.3555 26.7051 33.1055 26.6387C32.8555 26.5723 32.6152 26.5391 32.3848 26.5391C32.0059 26.5391 31.6895 26.623 31.4355 26.791C31.1855 26.959 30.998 27.209 30.873 27.541C30.752 27.873 30.6914 28.2852 30.6914 28.7773C30.6914 29.2539 30.7539 29.6562 30.8789 29.9844C31.0039 30.3086 31.1895 30.5547 31.4355 30.7227C31.6816 30.8867 31.9844 30.9688 32.3438 30.9688C32.6992 30.9688 33.0176 30.9258 33.2988 30.8398C33.5801 30.7539 33.8457 30.6426 34.0957 30.5059V31.7012C33.8496 31.8418 33.5859 31.9453 33.3047 32.0117C33.0234 32.082 32.6777 32.1172 32.2676 32.1172Z" />
        <path d="M38.2031 25.3906C38.7812 25.3906 39.2773 25.5098 39.6914 25.748C40.1055 25.9863 40.4238 26.3242 40.6465 26.7617C40.8691 27.1992 40.9805 27.7227 40.9805 28.332V29.0703H36.6504C36.666 29.6992 36.834 30.1836 37.1543 30.5234C37.4785 30.8633 37.9316 31.0332 38.5137 31.0332C38.9277 31.0332 39.2988 30.9941 39.627 30.916C39.959 30.834 40.3008 30.7148 40.6523 30.5586V31.6777C40.3281 31.8301 39.998 31.9414 39.6621 32.0117C39.3262 32.082 38.9238 32.1172 38.4551 32.1172C37.8184 32.1172 37.2578 31.9941 36.7734 31.748C36.293 31.498 35.916 31.127 35.6426 30.6348C35.373 30.1426 35.2383 29.5312 35.2383 28.8008C35.2383 28.0742 35.3613 27.457 35.6074 26.9492C35.8535 26.4414 36.1992 26.0547 36.6445 25.7891C37.0898 25.5234 37.6094 25.3906 38.2031 25.3906ZM38.2031 26.4277C37.7695 26.4277 37.418 26.5684 37.1484 26.8496C36.8828 27.1309 36.7266 27.543 36.6797 28.0859H39.6328C39.6289 27.7617 39.5742 27.4746 39.4688 27.2246C39.3672 26.9746 39.2109 26.7793 39 26.6387C38.793 26.498 38.5273 26.4277 38.2031 26.4277Z" />
    </svg>
);

export default WaveEnhanceIcon;
