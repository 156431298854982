import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { sensorUnits, statusColors } from '../../constants';
import WeatherIcon from '../../img/forecast-icons/forecast-icon-partially-cloudy';
import IconOfflineDevice from '../../img/icon-offline-device';
import IconCo2 from '../../img/sensorIcons/IconCo2';
import IconControlSignal from '../../img/sensorIcons/IconControlSignal';
import IconFlashOn from '../../img/sensorIcons/IconFlashOn';
import IconHumidity from '../../img/sensorIcons/IconHumidity';
import IconLight from '../../img/sensorIcons/IconLight';
import IconMoistGuard from '../../img/sensorIcons/IconMoistGuard';
import IconMold from '../../img/sensorIcons/IconMold';
import IconNoise from '../../img/sensorIcons/IconNoise';
import IconOccupancy from '../../img/sensorIcons/IconOccupancy';
import IconPM from '../../img/sensorIcons/IconPM';
import IconPressure from '../../img/sensorIcons/IconPressure';
import IconRadon from '../../img/sensorIcons/IconRadon';
import IconStraighten from '../../img/sensorIcons/IconStraighten';
import IconTemp from '../../img/sensorIcons/IconTemp';
import IconVentilationRate from '../../img/sensorIcons/IconVentilationRate';
import IconVirusRisk from '../../img/sensorIcons/IconVirusRisk';
import IconVoc from '../../img/sensorIcons/IconVoc';
import { SensorTypes } from '../../models/commonEnums';
import MaterialIcon from '../MaterialIcon';

type Props = {
    sensorValue: number | null | undefined;
    unit: string;
    sensor: string;
    displayStatusBar: boolean;
    unitTop: boolean;
    dotColor: string;
    isRadonAvg?: boolean;
};

const iconsForSensorType: { [key in SensorTypes]: React.ReactElement } = {
    humidity: <IconHumidity />,
    co2: <IconCo2 />,
    temp: <IconTemp />,
    radon: <IconRadon />,
    radonShortTermAvg: <IconRadon />,
    hourlyRadon: <IconRadon />,
    hourlyRadonStandardDeviation: <MaterialIcon name="iso" />,
    pressure: <IconPressure />,
    voc: <IconVoc />,
    light: <IconLight />,
    lux: <IconLight />,
    pm1: <IconPM />,
    pm10: <IconPM />,
    pm25: <IconPM />,
    mold: <IconMold />,
    virusRisk: <IconVirusRisk />,
    staleAir: <IconVirusRisk />,
    transmissionEfficiency: <IconVirusRisk />,
    virusSurvivalRate: <IconVirusRisk />,
    ventilationAmount: <IconVentilationRate />,
    ventilationRate: <IconVentilationRate />,
    airExchangeRate: <IconVentilationRate />,
    ventilationRunning: <IconVentilationRate />,
    historicVentilationRunning: <IconVentilationRate />,
    occupancy: <IconOccupancy />,
    occupants: <IconOccupancy />,
    presence: <IconOccupancy />,
    noise: <IconNoise />,
    sla: <IconNoise />,
    outdoorTemp: <IconTemp />,
    outdoorHumidity: <IconHumidity />,
    outdoorPressure: <IconPressure />,
    outdoorPm1: <IconPM />,
    outdoorPm10: <IconPM />,
    outdoorPm25: <IconPM />,
    totalPowerLost: <IconFlashOn />,
    moistGuard: <IconMoistGuard />,
    potentialPowerSaved: <IconFlashOn />,
    potentialPowerSavedPercent: <IconFlashOn />,
    zeroHeightPercent: <IconStraighten />,
    controlSignal: <IconControlSignal />,
    regulationHeight: <IconStraighten />,
    regulationPressure: <IconPressure />,
};

export const SensorIcon = ({ sensor }: { sensor: string }): React.ReactElement => {
    const icons = {
        ...iconsForSensorType,
        radonLongTermAvg: iconsForSensorType.radonShortTermAvg,
        radon: iconsForSensorType.radonShortTermAvg,
        weather: <WeatherIcon />,
        deviceOffline: <IconOfflineDevice />,
        dominantPollutant: <IconOccupancy />,
        public: <MaterialIcon name="remove_red_eye" />,
    };
    return icons[sensor as keyof typeof icons] || <MaterialIcon name="help" />;
};

export const roundSensorValue = (value: number | undefined | null, unit: string): string | undefined | null => {
    if (value !== undefined && value !== null) {
        if (value > 9999) {
            return `${(value / 1000).toFixed(0)}K`;
        }
        if ((unit === 'pci' && value % 1 !== 0) || unit === 'inhg') {
            return value.toFixed(1);
        }
        return value.toFixed(0);
    }
    return value;
};

export const SensorUnitOnlyName = ({
    name,
    sensorType,
}: {
    name: string;
    sensorType: SensorTypes;
}): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className="sensor__block sensor__block__only-name">
            <SensorIcon sensor={sensorType} />
            <span className="sensor__name">{txt(name)}</span>
        </div>
    );
};

const SensorUnit = ({
    sensor,
    sensorValue,
    unit,
    displayStatusBar,
    unitTop,
    dotColor,
    isRadonAvg,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const sensorHasValue = sensorValue !== undefined && sensorValue !== null && !Number.isNaN(sensorValue);
    const displayStatusDot = sensorHasValue && (dotColor === 'red' || dotColor === 'yellow' || displayStatusBar);

    const sensorUnit = sensorUnits[unit as keyof typeof sensorUnits];
    const value = roundSensorValue(sensorValue, unit);
    return (
        <div className={classNames('sensor__block', { 'sensor__block--no-unit': !unitTop })}>
            {isRadonAvg && (
                <div className="sensor__unit--top">
                    {sensorUnit} {isRadonAvg && `(${txt('24hourAvg')})`}
                </div>
            )}
            <div className={classNames('sensor__icon', { 'sensor__icon--unit-top': unitTop && !isRadonAvg })}>
                <SensorIcon sensor={sensor} />
            </div>
            <div className="sensor__data">
                {unitTop && !isRadonAvg && <div className="sensor__unit--top">{sensorUnit}</div>}
                <span className="sensor__value">
                    {sensorHasValue ? value : txt('NotAvailableAbbreviation')}
                    {sensorHasValue && !unitTop && (
                        <span
                            className={classNames({
                                sensor__unit: unit === 'pct',
                                'sensor__unit--risk': unit === 'riskIndex',
                            })}
                        >
                            {sensorUnit}
                        </span>
                    )}
                </span>
            </div>
            <div className={classNames('sensor__description', { 'sensor__description--padded': !displayStatusDot })}>
                {displayStatusDot && (
                    <div
                        className={classNames('sensor__line', {
                            'sensor__line--ok': dotColor === statusColors.green && displayStatusBar,
                            'sensor__line--alert': dotColor === statusColors.red,
                            'sensor__line--warning': dotColor === statusColors.yellow,
                        })}
                    />
                )}
                <span className="sensor__name">{txt(sensor)}</span>
            </div>
        </div>
    );
};

export default SensorUnit;
