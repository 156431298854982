import React from 'react';

const IconOccupancy = (): React.ReactElement => (
    <svg
        version="1.1"
        id="iconOccupancy"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
    >
        <path d="M8 17.25C7.86193 17.25 7.75 17.1381 7.75 17V14.5C7.75 12.9812 8.98122 11.75 10.5 11.75H13.5C15.0188 11.75 16.25 12.9812 16.25 14.5V17C16.25 17.1381 16.1381 17.25 16 17.25H8ZM6.25 17C6.25 17.9665 7.0335 18.75 8 18.75H16C16.9665 18.75 17.75 17.9665 17.75 17V14.5C17.75 12.1528 15.8472 10.25 13.5 10.25H10.5C8.15279 10.25 6.25 12.1528 6.25 14.5V17Z" />
        <path d="M12 4.75C11.0335 4.75 10.25 5.5335 10.25 6.5C10.25 7.4665 11.0335 8.25 12 8.25C12.9665 8.25 13.75 7.4665 13.75 6.5C13.75 5.5335 12.9665 4.75 12 4.75ZM8.75 6.5C8.75 4.70507 10.2051 3.25 12 3.25C13.7949 3.25 15.25 4.70507 15.25 6.5C15.25 8.29493 13.7949 9.75 12 9.75C10.2051 9.75 8.75 8.29493 8.75 6.5Z" />
        <path d="M19.25 18C19.25 18.4142 19.5858 18.75 20 18.75H22C22.9665 18.75 23.75 17.9665 23.75 17V14.5C23.75 12.1528 21.8472 10.25 19.5 10.25H19C18.5858 10.25 18.25 10.5858 18.25 11C18.25 11.4142 18.5858 11.75 19 11.75H19.5C21.0188 11.75 22.25 12.9812 22.25 14.5V17C22.25 17.1381 22.1381 17.25 22 17.25H20C19.5858 17.25 19.25 17.5858 19.25 18Z" />
        <path d="M18.25 4C18.25 3.58579 18.5858 3.25 19 3.25C20.7949 3.25 22.25 4.70507 22.25 6.5C22.25 8.29493 20.7949 9.75 19 9.75C18.5858 9.75 18.25 9.41421 18.25 9C18.25 8.58579 18.5858 8.25 19 8.25C19.9665 8.25 20.75 7.4665 20.75 6.5C20.75 5.5335 19.9665 4.75 19 4.75C18.5858 4.75 18.25 4.41421 18.25 4Z" />
        <path d="M4.75 18C4.75 18.4142 4.41421 18.75 4 18.75H2C1.0335 18.75 0.25 17.9665 0.25 17V14.5C0.25 12.1528 2.15279 10.25 4.5 10.25H5C5.41421 10.25 5.75 10.5858 5.75 11C5.75 11.4142 5.41421 11.75 5 11.75H4.5C2.98122 11.75 1.75 12.9812 1.75 14.5V17C1.75 17.1381 1.86193 17.25 2 17.25H4C4.41421 17.25 4.75 17.5858 4.75 18Z" />
        <path d="M5.75 4C5.75 3.58579 5.41421 3.25 5 3.25C3.20507 3.25 1.75 4.70507 1.75 6.5C1.75 8.29493 3.20507 9.75 5 9.75C5.41421 9.75 5.75 9.41421 5.75 9C5.75 8.58579 5.41421 8.25 5 8.25C4.0335 8.25 3.25 7.4665 3.25 6.5C3.25 5.5335 4.0335 4.75 5 4.75C5.41421 4.75 5.75 4.41421 5.75 4Z" />
    </svg>
);

export default IconOccupancy;
