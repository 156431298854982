import React from 'react';

const WaveNanoIcon = (): React.ReactElement => (
    <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="device-svg-icons"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 11H16C8.8203 11 3 16.8203 3 24C3 31.1797 8.8203 37 16 37H32C39.1797 37 45 31.1797 45 24C45 16.8203 39.1797 11 32 11ZM16 9C7.71573 9 1 15.7157 1 24C1 32.2843 7.71573 39 16 39H32C40.2843 39 47 32.2843 47 24C47 15.7157 40.2843 9 32 9H16Z"
        />
        <path d="M10.3013 19.504H12.3053L16.2533 25.864H16.2773V19.504H17.7893V28H15.8693L11.8373 21.436H11.8133V28H10.3013V19.504Z" />
        <path d="M23.1686 27.268H23.1326C22.9886 27.524 22.7566 27.736 22.4366 27.904C22.1166 28.064 21.7526 28.144 21.3446 28.144C21.1126 28.144 20.8686 28.112 20.6126 28.048C20.3646 27.992 20.1326 27.896 19.9166 27.76C19.7086 27.616 19.5326 27.432 19.3886 27.208C19.2526 26.976 19.1846 26.692 19.1846 26.356C19.1846 25.924 19.3046 25.58 19.5446 25.324C19.7926 25.068 20.1086 24.872 20.4926 24.736C20.8766 24.6 21.3006 24.512 21.7646 24.472C22.2366 24.424 22.6966 24.4 23.1446 24.4V24.256C23.1446 23.896 23.0126 23.632 22.7486 23.464C22.4926 23.288 22.1846 23.2 21.8246 23.2C21.5206 23.2 21.2286 23.264 20.9486 23.392C20.6686 23.52 20.4366 23.676 20.2526 23.86L19.5086 22.984C19.8366 22.68 20.2126 22.452 20.6366 22.3C21.0686 22.148 21.5046 22.072 21.9446 22.072C22.4566 22.072 22.8766 22.144 23.2046 22.288C23.5406 22.432 23.8046 22.62 23.9966 22.852C24.1886 23.084 24.3206 23.344 24.3926 23.632C24.4726 23.92 24.5126 24.208 24.5126 24.496V28H23.1686V27.268ZM23.1446 25.372H22.8206C22.5886 25.372 22.3446 25.384 22.0886 25.408C21.8326 25.424 21.5966 25.468 21.3806 25.54C21.1646 25.604 20.9846 25.7 20.8406 25.828C20.6966 25.948 20.6246 26.116 20.6246 26.332C20.6246 26.468 20.6526 26.584 20.7086 26.68C20.7726 26.768 20.8526 26.84 20.9486 26.896C21.0446 26.952 21.1526 26.992 21.2726 27.016C21.3926 27.04 21.5126 27.052 21.6326 27.052C22.1286 27.052 22.5046 26.92 22.7606 26.656C23.0166 26.392 23.1446 26.032 23.1446 25.576V25.372Z" />
        <path d="M26.1902 22.24H27.5582V23.164H27.5822C27.7102 22.876 27.9302 22.624 28.2422 22.408C28.5622 22.184 28.9382 22.072 29.3702 22.072C29.7462 22.072 30.0662 22.14 30.3302 22.276C30.6022 22.404 30.8222 22.576 30.9902 22.792C31.1662 23.008 31.2942 23.256 31.3742 23.536C31.4542 23.816 31.4942 24.104 31.4942 24.4V28H30.0542V24.808C30.0542 24.64 30.0422 24.464 30.0182 24.28C29.9942 24.096 29.9422 23.932 29.8622 23.788C29.7822 23.636 29.6702 23.512 29.5262 23.416C29.3902 23.32 29.2062 23.272 28.9742 23.272C28.7422 23.272 28.5422 23.32 28.3742 23.416C28.2062 23.504 28.0662 23.62 27.9542 23.764C27.8502 23.908 27.7702 24.076 27.7142 24.268C27.6582 24.452 27.6302 24.64 27.6302 24.832V28H26.1902V22.24Z" />
        <path d="M32.8386 25.096C32.8386 24.64 32.9186 24.228 33.0786 23.86C33.2466 23.484 33.4706 23.164 33.7506 22.9C34.0306 22.636 34.3626 22.432 34.7466 22.288C35.1306 22.144 35.5386 22.072 35.9706 22.072C36.4026 22.072 36.8106 22.144 37.1946 22.288C37.5786 22.432 37.9106 22.636 38.1906 22.9C38.4706 23.164 38.6906 23.484 38.8506 23.86C39.0186 24.228 39.1026 24.64 39.1026 25.096C39.1026 25.552 39.0186 25.968 38.8506 26.344C38.6906 26.72 38.4706 27.044 38.1906 27.316C37.9106 27.58 37.5786 27.788 37.1946 27.94C36.8106 28.092 36.4026 28.168 35.9706 28.168C35.5386 28.168 35.1306 28.092 34.7466 27.94C34.3626 27.788 34.0306 27.58 33.7506 27.316C33.4706 27.044 33.2466 26.72 33.0786 26.344C32.9186 25.968 32.8386 25.552 32.8386 25.096ZM34.3026 25.096C34.3026 25.32 34.3346 25.544 34.3986 25.768C34.4706 25.992 34.5746 26.192 34.7106 26.368C34.8466 26.544 35.0186 26.688 35.2266 26.8C35.4346 26.912 35.6826 26.968 35.9706 26.968C36.2586 26.968 36.5066 26.912 36.7146 26.8C36.9226 26.688 37.0946 26.544 37.2306 26.368C37.3666 26.192 37.4666 25.992 37.5306 25.768C37.6026 25.544 37.6386 25.32 37.6386 25.096C37.6386 24.872 37.6026 24.652 37.5306 24.436C37.4666 24.212 37.3666 24.012 37.2306 23.836C37.0946 23.66 36.9226 23.52 36.7146 23.416C36.5066 23.304 36.2586 23.248 35.9706 23.248C35.6826 23.248 35.4346 23.304 35.2266 23.416C35.0186 23.52 34.8466 23.66 34.7106 23.836C34.5746 24.012 34.4706 24.212 34.3986 24.436C34.3346 24.652 34.3026 24.872 34.3026 25.096Z" />
    </svg>
);

export default WaveNanoIcon;
