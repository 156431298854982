import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceHolder from 'react-placeholder';
import { connect } from 'react-redux';
import CheckBox from 'commons/src/components/input/Checkbox';
import SearchComponent from 'commons/src/components/input/SearchComponent';
import { userlane } from 'commons/src/components/placeholder';
import { WebhookEvent, WebhookEventTypes } from '../../../models/common';
import { Store } from '../../../reducers';
import { filterWebhookEventTypes } from './filterWebhookEventTypes';
import WebhookEventRow from './WebhookEventRow';

type StateProps = {
    webhookEvents: WebhookEvent[];
    loading: boolean;
};

type Props = StateProps;

export const WebhookEventsComponent = ({ webhookEvents, loading }: Props): React.ReactElement => {
    const [searchText, setSearchText] = useState('');
    const [selectedEventTypes, setSelectedEventTypes] = useState<WebhookEventTypes[]>([]);

    const { t: txt } = useTranslation();

    const toggleEventType = (eventType: WebhookEventTypes): void => {
        const eventsFiltered = selectedEventTypes.filter(it => it !== eventType);
        if (eventsFiltered.length === selectedEventTypes.length)
            setSelectedEventTypes([...selectedEventTypes, eventType]);
        else setSelectedEventTypes(eventsFiltered);
    };

    const filteredEvents: WebhookEvent[] = webhookEvents.filter(event => {
        const eventAsString = JSON.stringify(event);
        const filterOnSearch = eventAsString.toLowerCase().includes(searchText);

        // Filter on chip selection
        const filterOnChip = selectedEventTypes.length > 0 ? selectedEventTypes.includes(event.eventType) : true;

        return filterOnSearch && filterOnChip;
    });

    return (
        <div className="wide-form__form__section webhook-events">
            <h2 className="text-large">{txt('Webhooks.EventsHeader')}</h2>
            <SearchComponent expandable onChange={(value: string): void => setSearchText(value)} testId="text-field" />
            <div className="webhook-events__chips">
                {filterWebhookEventTypes(true).map((type, index) => (
                    <CheckBox
                        key={`webhook-event-chip-${type}-${index.toString()}`}
                        testId={`webhook-event-chip-${type}-${index.toString()}`}
                        label={type}
                        id={type}
                        onChange={(): void => toggleEventType(type)}
                        checked={selectedEventTypes.includes(type)}
                    />
                ))}
            </div>
            <ul className="list">
                <ReactPlaceHolder ready={!loading} customPlaceholder={userlane}>
                    {filteredEvents.map((event, index) => (
                        <WebhookEventRow
                            event={event}
                            key={`webhook-event-list-element-${event.hookId}-${index.toString()}`}
                        />
                    ))}
                </ReactPlaceHolder>
            </ul>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        webhooks: { webhookEvents },
        requests: {
            TEST_WEBHOOK: { loading: loadingTestWebhook },
            FETCH_WEBHOOK_EVENTS: { loading: loadingWebhookEvents },
        },
    } = store;

    return {
        loading: loadingTestWebhook || loadingWebhookEvents,
        webhookEvents,
    };
};

export default connect(mapStateToProps)(WebhookEventsComponent);
