import { ErrorType } from '../models/commonTypeScript';

export const isErrorType = (error: unknown): error is ErrorType => {
    return (error as ErrorType).error !== undefined;
};

export const toErrorType = (error: unknown, defaultError = 'SomethingWentWrongTryAgain'): ErrorType =>
    isErrorType(error) ? error : { error: defaultError, error_description: defaultError };

export const toErrorTypeWithMessage = (
    error: unknown,
    defaultMessage = 'SomethingWentWrongTryAgain'
): { error: ErrorType; message: string } => {
    const message = isErrorType(error) ? `ErrorCodes.${error.error}` : defaultMessage;
    return {
        error: toErrorType(error, defaultMessage),
        message,
    };
};
