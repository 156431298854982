import { takeEvery, call, put, select, all, CallEffect, PutEffect, SelectEffect, AllEffect } from 'redux-saga/effects';
import { INITIALIZE, initializeSuccess, appError } from '../actions';
import { fetchLocations } from '../actions/LocationActions';
import { getOrganizationInvites } from '../actions/OrganizationInvitesActions';
import { isConsumerAppPage } from '../commonFunctions';
import { Group } from '../models/commonTypeScript';
import { RequestActions } from '../models/RequestTypes';
import { getGroups, isLoggedIn } from '../reducers/reducerShortcuts';
import history from '../store/history';
import authSaga from './authentication/authService';
import { fetchUserInfoSaga } from './authentication/getUserInfo';
import { toErrorType } from './isErrorType';

const isIEBrowser = (): boolean => !!navigator.userAgent.match(/MSIE/g) || !!navigator.userAgent.match(/Trident/g);

const hasNoLocalStorage = (): boolean => {
    try {
        const test = 'test';
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return false;
    } catch (e) {
        return true;
    }
};

type InitializeAppSagaReturnType = Generator<
    CallEffect | RequestActions | SelectEffect | void | PutEffect | AllEffect<PutEffect>,
    void,
    Group[]
>;
export function* initializeAppSaga(): InitializeAppSagaReturnType {
    try {
        if (isIEBrowser() || hasNoLocalStorage()) {
            // check, if browser is IE
            yield history.push('/unsupported');
        } else {
            yield call(authSaga);
            const loggedIn = yield select(isLoggedIn);
            if (loggedIn) {
                yield call(fetchUserInfoSaga);

                if (!isConsumerAppPage()) {
                    const groups = yield select(getGroups);
                    yield put(getOrganizationInvites());
                    if (groups.length !== 0) {
                        yield put(fetchLocations());
                    }
                }
            }
            yield put(initializeSuccess());
        }
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(appError(asErrorType));
    }
}

export default function* initializeSagas(): Generator {
    yield all([takeEvery(INITIALIZE, initializeAppSaga)]);
}
