import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    numberOfDevices: number;
};

const LocationTileBody = ({ numberOfDevices }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    return (
        <div className="tile-body__inner">
            <div className="tile-body--icon-wrapper">{numberOfDevices}</div>
            <div className="tile-body__message">{txt('Devices')}</div>
        </div>
    );
};

export default LocationTileBody;
