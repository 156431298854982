import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
    fetchCustomDeviceSegment,
    pollDeviceData as pollDeviceDataAction,
    PollDeviceData,
    PollDeviceDataPayload,
    StopPollDeviceData,
    stopPollDeviceData,
} from 'commons/src/actions/DeviceActions';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { DEVICE_VIEWED_DETAILS } from 'commons/src/analytics/AnalyticsEvents';
import Error from 'commons/src/components/errorComponents/Error';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { sensorGraphPeriods } from 'commons/src/constants';
import DevicePageBody from 'commons/src/features/devicePage/DevicePageBody';
import { FullDeviceData, SelectedPeriod, SensorData } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { pageNotFoundUrl } from '../../components/errorComponents/PageNotFound';
import { Store } from '../../reducers';

type ConnectedProps = {
    devicePageDevices: { [serialNumber: string]: FullDeviceData };
    devicePageLoading: boolean;
    dateFormat: string;
    fetching: boolean;
    sensorData: { [serialNumber: string]: SensorData };
    error: boolean;
};

type ActionProps = {
    stopPollingDeviceData: () => void;
    onPollDeviceData: (payload: PollDeviceDataPayload) => void;
};

export type ParentProps = {
    children?: React.ReactElement;
    topChildren?: React.ReactElement;
    serialNumber: string;
    fetchOnLoad?: boolean;
    isPartner?: boolean;
    extraParams?: { [extraParam: string]: string | undefined };
    actionButtons?: ActionButton[];
    timeZone?: string;
};

export type Props = ConnectedProps & ActionProps & ParentProps;

export const CommonDeviceOverviewComponent = ({
    serialNumber,
    onPollDeviceData,
    devicePageDevices,
    fetching,
    stopPollingDeviceData,
    devicePageLoading,
    dateFormat,
    sensorData,
    error,
    children,
    topChildren,
    fetchOnLoad,
    extraParams,
    isPartner,
    actionButtons,
    timeZone,
}: Props): React.ReactElement => {
    const [selectedPeriod, setSelectedPeriod] = useState<SelectedPeriod>(sensorGraphPeriods.week);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const updateSelectedPeriod = (newPeriod: SelectedPeriod): void => {
        setSelectedPeriod(newPeriod);
    };

    const device = devicePageDevices[serialNumber] || {};
    const chartData = sensorData[serialNumber] || {};

    const pollDeviceData = (payload: PollDeviceDataPayload): void =>
        onPollDeviceData({
            ...payload,
            extraParams,
        });

    const fetchCustomSegment = (
        deviceSerialNumber: string,
        timeRange: SelectedPeriod,
        isCustom?: boolean,
        partner?: boolean,
        fetchWithExtraParams?: { [extraParam: string]: string | undefined }
    ): void => {
        const { startDate, endDate } = timeRange;
        const startDateInTimeZone = (timeZone ? moment.utc(startDate).tz(timeZone) : moment(startDate)).startOf('day');
        const endDateInTimeZone = (timeZone ? moment.utc(endDate).tz(timeZone) : moment(endDate)).endOf('day');
        dispatch(
            fetchCustomDeviceSegment(
                deviceSerialNumber,
                { ...timeRange, startDate: startDateInTimeZone, endDate: endDateInTimeZone },
                partner,
                fetchWithExtraParams
            )
        );
    };

    useEffect(() => {
        if (serialNumber === undefined) {
            navigate(pageNotFoundUrl('serialNumberNotDefined'));
        } else if (!fetching && fetchOnLoad) {
            pollDeviceData({
                serialNumber,
                selectedInterval: sensorGraphPeriods.week,
                fetching: !devicePageDevices[serialNumber],
                loading: !devicePageDevices[serialNumber],
            });
        }
        analyticsLogger(DEVICE_VIEWED_DETAILS, { pageType: PageType.Device });
        return (): void => {
            stopPollingDeviceData();
        };
    }, []);

    const firstSensor = Object.keys(chartData)[0];
    const missingChartData = !firstSensor || (firstSensor && !chartData[firstSensor][selectedPeriod.name]);
    if (error && !fetching && !devicePageLoading && missingChartData) {
        return <Error />;
    }

    return (
        <div className="container">
            <SubHeader actionButtons={actionButtons || []} />
            {topChildren}
            <DevicePageBody
                timeZone={timeZone}
                deviceId={serialNumber}
                devicePageLoading={devicePageLoading}
                fetching={fetching}
                dateFormat={dateFormat}
                device={device}
                endedSegment={false}
                lastRecord={device && device.latestSample}
                fetchCustomSegment={fetchCustomSegment}
                serialNumber={serialNumber}
                selectedPeriod={selectedPeriod}
                updateSelectedPeriod={updateSelectedPeriod}
                onPollDeviceData={pollDeviceData}
                stopPollingDeviceData={stopPollingDeviceData}
                fetchedDevices={devicePageDevices}
                isPartner={isPartner}
                extraParams={extraParams}
                macAddress={device && device.macAddress}
            />
            {children}
        </div>
    );
};

const mapStateToProps = (state: Store): ConnectedProps => {
    const {
        devicePage: { devices: devicePageDevices, loading: devicePageLoading, fetching, errorCode },
        deviceSensorData: { sensorData },
        userSettings: { dateFormat, loading: userSettingsLoading },
        locations: { error: locationError },
        app: { error: appError },
    } = state;

    return {
        devicePageDevices,
        devicePageLoading: userSettingsLoading || devicePageLoading,
        fetching,
        dateFormat,
        sensorData,
        error: locationError || !!errorCode || appError,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onPollDeviceData: (payload): PollDeviceData => dispatch(pollDeviceDataAction(payload)),
    stopPollingDeviceData: (): StopPollDeviceData => dispatch(stopPollDeviceData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonDeviceOverviewComponent);
