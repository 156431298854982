import { DashboardVisibility } from '../models/commonEnums';
import { CurrentDashboardResponse, DashboardProps, DashboardsResponse } from '../models/commonTypeScript';
import fetch from './fetch';
import getFetchHeaders from './getFetchHeaders';

export default async (): Promise<DashboardsResponse> =>
    fetch<DashboardsResponse>('/dashboards', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const updateDashboardConfig = async (
    config: { tiles: { id: string; type: string; width: number; ref: string }[] },
    dashboardId: string
): Promise<void> =>
    fetch<void>(`/dashboards/${dashboardId}`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(config),
    });

export const createPublicDashboard = async (payload: {
    name: string;
    visibility: DashboardVisibility;
}): Promise<CurrentDashboardResponse> =>
    fetch<CurrentDashboardResponse>('/dashboards', {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(payload),
    });
export const editPublicDashboardProps = async (payload: DashboardProps): Promise<void> =>
    fetch<void>(`/dashboards/${payload.id}/settings`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ name: payload.name, visibility: payload.visibility }),
    });

export const selectDashboard = async (id: string): Promise<CurrentDashboardResponse> =>
    fetch<CurrentDashboardResponse>(`/dashboards/${id}`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const deletePublicDashboard = async (id: string): Promise<void> =>
    fetch<void>(`/dashboards/${id}`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });
