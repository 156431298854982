import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { limitSubscription } from '../../commonFunctions';
import { SubscriptionPlan } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import { userIsHbs } from '../findUserType';
import MaterialIcon from '../MaterialIcon';
import styles from './NoSubscription.module.scss';

type StateProps = {
    subscriptionPlan?: SubscriptionPlan;
};
type ParentProps = {
    navigationType?: string;
};

type Props = StateProps & ParentProps;

const NoSubscription = ({ navigationType, subscriptionPlan }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const userIsB2B = userIsHbs();
    const limitingToggle = useSelector((store: Store) => store.toggles.enableDowngradeViewToggleOn);
    const subscriptionIsLimited = limitingToggle && limitSubscription(subscriptionPlan);
    if (!userIsB2B || !subscriptionIsLimited) {
        return <div />;
    }
    return (
        <div className={styles.subscriptionStatus}>
            {navigationType === 'slim' ? <MaterialIcon name="money_off" /> : txt('Subscription.LimitedPlan')}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { subscriptionForGroup },
    } = state;

    return {
        subscriptionPlan: subscriptionForGroup,
    };
};

export default connect(mapStateToProps)(NoSubscription);
