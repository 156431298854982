import { DeviceMetaDataProperties, InletAirMetaDataProperties } from 'commons/src/models/commonTypeScript';
import { Config } from './ThresholdSlider';

export const inletAirConfigurations: { [sensorType: string]: Config } = {
    co2: {
        recommended: { min: 500, max: 1000 },
        range: { min: 400, max: 1600, step: 10 },
        unit: 'ppm',
        descriptionKey: 'InletAirConfigThresholdsDescription.co2',
    },
    temp: {
        recommended: { min: 21, max: 25 },
        range: { min: 13, max: 28, step: 0.5 },
        unit: 'c',
        descriptionKey: 'InletAirConfigThresholdsDescription.temp',
    },
};
export const defaultInletAirConfig: InletAirMetaDataProperties = {
    inletEnableCo2: true,
    inletMinCo2: inletAirConfigurations.co2.recommended.min,
    inletMaxCo2: inletAirConfigurations.co2.recommended.max,
    inletEnableTemp: true,
    inletMinTemp: inletAirConfigurations.temp.recommended.min,
    inletMaxTemp: inletAirConfigurations.temp.recommended.max,
};

const validateConfiguration = (config: DeviceMetaDataProperties): string[] => {
    const errors: string[] = [];
    if (config.inletEnableCo2 && (!config.inletMinCo2 || !config.inletMaxCo2)) {
        errors.push('InletAirConfigValidation.missing_co2');
    }
    if (config.inletEnableTemp && (!config.inletMinTemp || !config.inletMaxTemp)) {
        errors.push('InletAirConfigValidation.missing_temp');
    }
    return errors;
};

export default validateConfiguration;
