import { generatePath } from 'react-router-dom';
import { ConfigResponse, SubscriptionPlan, PartnerLogoDetails, UserResponse } from '../models/commonTypeScript';
import fetch from './fetch';
import getFetchHeaders from './getFetchHeaders';

export interface UpdateUserSettingsRequest {
    units: {
        radonUnit: string;
        tempUnit: string;
        pressureUnit: string;
        vocUnit: string;
    };
    dateFormat?: string;
    language?: string;
}

export default async (settings: UpdateUserSettingsRequest): Promise<void> => {
    return fetch<void>('/user/preferences', {
        method: 'PATCH',
        headers: await getFetchHeaders(),
        body: JSON.stringify(settings),
    });
};

export const getUserInfo = async (): Promise<UserResponse> =>
    fetch<UserResponse>('/user', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const postActiveUserGroupId = async (userGroupId: string): Promise<UserResponse> =>
    fetch<UserResponse>('/user/active-group', {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ userGroupId }),
    });

export const getSpecs = async (): Promise<ConfigResponse> =>
    fetch<ConfigResponse>('/specs', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const fetchPartnerLogo = async (): Promise<PartnerLogoDetails> => {
    return fetch('/organization/partner-logo', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
};

export const getSubscriptionStatusForUserGroup = async (): Promise<SubscriptionPlan> =>
    fetch<SubscriptionPlan>(`/subscription/status`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const createPropertyDefinition = async (name: string): Promise<{ id: string }> =>
    fetch<{ id: string }>(`/specs/spaces/property-definition`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ name }),
    });

export const updatePropertyDefinition = async (id: string, name: string): Promise<void> =>
    fetch<void>(`/specs/spaces/property-definition/${id}`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ name }),
    });

export const deletePropertyDefinition = async (id: string): Promise<void> =>
    fetch<void>(`/specs/spaces/property-definition/${id}`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });

export const createPropertyValue = async (propertyDefinitionId: string, value: string): Promise<{ id: string }> => {
    const url = generatePath('/specs/spaces/property-definition/:propertyDefinitionId/value', { propertyDefinitionId });
    return fetch<{ id: string }>(url, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ value }),
    });
};

export const updatePropertyValue = async (
    propertyDefinitionId: string,
    optionId: string,
    name: string
): Promise<void> => {
    return fetch<void>(`/specs/spaces/property-definition/${propertyDefinitionId}/value/${optionId}`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ name }),
    });
};

export const deletePropertyValue = async (propertyDefinitionId: string, optionId: string): Promise<void> => {
    return fetch<void>(`/specs/spaces/property-definition/${propertyDefinitionId}/value/${optionId}`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });
};
