import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { setMeasurementSystem } from 'commons/src/commonFunctions';
import { FetchOutdoorInsightDataType, OutdoorInsightResponse } from '../actions/outdoorInsightActions';

export default async (payload: FetchOutdoorInsightDataType): Promise<OutdoorInsightResponse> => {
    const userMeasurementSystem = setMeasurementSystem(payload.unitPreferences);
    return fetch(
        `/locations/${payload.locationId}/outdoor/weather?from=${payload.from}&to=${payload.to}&measurementSystem=${userMeasurementSystem}`,
        {
            method: 'GET',
            headers: await getFetchHeaders(),
        }
    );
};
