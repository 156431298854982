import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsibleTable from '../../../components/collapsibleTable/CollapsibleTable';
import { MaterialTableColumn } from '../../../components/materialTable/models';
import { ORGANIZATION_HEALTH_TABLE_LENGTH } from '../../../constants';
import { OrganizationBuildingOverview } from '../../../models/organizationHealthModels';
import BuildingRowContent from './BuildingRowContent';
import BuildingRowHeader from './BuildingRowHeader';

type OrganizationHealthTableProps = {
    organizationBuilding: OrganizationBuildingOverview[];
    isReady: boolean;
    totalBuildings: number;
};

const OrganizationHealthTable = ({
    organizationBuilding,
    isReady,
    totalBuildings,
}: OrganizationHealthTableProps): React.ReactElement => {
    const { t: txt } = useTranslation();

    const columns: MaterialTableColumn[] = [
        {
            accessor: 'locationName',
            label: txt('Name'),
            sortable: true,
            width: '360px',
        },
        {
            accessor: 'hubsTotal',
            label: txt('Hubs'),
            sortable: true,
            width: '360px',
        },
        {
            accessor: 'devicesTotal',
            label: txt('Devices'),
            sortable: true,
            width: '380px',
        },
    ];

    const orgData = useMemo(
        () =>
            organizationBuilding.map(building => ({
                ...building,
                hubsTotal: building.hubStats.total,
                devicesTotal: building.deviceStats.total,
            })),
        [organizationBuilding]
    );

    return (
        <CollapsibleTable
            tableId="org_table"
            data={orgData}
            renderHeader={BuildingRowHeader}
            renderContent={BuildingRowContent}
            columns={columns}
            isReady={isReady}
            pagination={ORGANIZATION_HEALTH_TABLE_LENGTH}
            totalDataLength={totalBuildings}
            idKey="locationId"
        />
    );
};

export default OrganizationHealthTable;
