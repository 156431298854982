const monthAndWeekConfig = (
    txt: (text: string, stringAttrs?: { [textKey: string]: string }) => string
): { lang: { months: string[]; weekdays: string[] } } => {
    return {
        lang: {
            months: [
                txt('January'),
                txt('February'),
                txt('March'),
                txt('April'),
                txt('May'),
                txt('June'),
                txt('July'),
                txt('August'),
                txt('September'),
                txt('October'),
                txt('November'),
                txt('December'),
            ],
            weekdays: [
                txt('Sunday'),
                txt('Monday'),
                txt('Tuesday'),
                txt('Wednesday'),
                txt('Thursday'),
                txt('Friday'),
                txt('Saturday'),
            ],
        },
    };
};

export default monthAndWeekConfig;
