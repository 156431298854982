import React from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { FLOORPLAN_CLICKED_SENSOR } from 'commons/src/analytics/AnalyticsEvents';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { AnyDeviceType, FloorPlanType, FloorplanZone } from 'commons/src/models/commonTypeScript';
import { floorPlanZoneHasDevice } from '../../../reducers/Floorplans';
import { MODES } from './FloorPlanConstants';
import HubMarker from './HubMarker';

type Props = {
    floor: FloorPlanType;
    mode: MODES;
    selectedDeviceType: AnyDeviceType | undefined;
    selectedZone: FloorplanZone | undefined;
    selectZone: (zone: FloorplanZone, deviceType: AnyDeviceType | undefined) => void;
    resetMapView: () => void;
    selectedDevice: string | undefined;
    floorPlanEditorRef: React.MutableRefObject<{ zone: L.Polygon; marker: L.Marker } | undefined>;
};

const HubMarkers = ({
    floor,
    selectedZone,
    resetMapView,
    selectZone,
    selectedDevice,
    mode,
    floorPlanEditorRef,
    selectedDeviceType,
}: Props): React.ReactElement => {
    const mapMarkers = floor.zones.filter(floorZone => floorZone.boundary.length === 0);

    const listOfMarkerComponents = mapMarkers
        .filter(floorPlanZoneHasDevice)
        .reduce((markers: React.ReactElement[], hubMarker) => {
            const markerIsSelected = selectedZone && selectedZone.id === hubMarker.id;
            const handleHubClicked = (): void => {
                if (markerIsSelected) {
                    resetMapView();
                } else {
                    selectZone(hubMarker, DeviceTypeNames.hub);
                    analyticsLogger(FLOORPLAN_CLICKED_SENSOR, { pageType: PageType.Floorplan });
                }
            };

            const isEditing = selectedDevice === hubMarker.device.serialNumber && mode === MODES.EDIT;

            const markerComponent = (
                <HubMarker
                    isEditing={isEditing}
                    innerRef={isEditing ? floorPlanEditorRef : undefined}
                    key={`${hubMarker.id}:marker`}
                    marker={hubMarker.device}
                    onClick={handleHubClicked}
                />
            );

            return [...markers, markerComponent];
        }, []);

    const isHub = selectedDeviceType === DeviceTypeNames.hub;
    const hubInMap = mapMarkers
        .filter(floorPlanZoneHasDevice)
        .find(element => element.device.serialNumber === selectedDevice);
    const centerOfMap = useMap().getCenter(); // map && map.getCenter();
    if (mode === MODES.EDIT && !hubInMap && isHub && selectedDevice && centerOfMap) {
        const hubProps = { serialNumber: selectedDevice, position: centerOfMap };
        const handleClick = (): void => {
            // do nothing;
        };
        const newHub = (
            <HubMarker
                isEditing
                key={`${hubProps.serialNumber}:marker`}
                innerRef={floorPlanEditorRef}
                marker={hubProps}
                onClick={handleClick}
            />
        );
        return <span>{[...listOfMarkerComponents, newHub]}</span>;
    }
    return <div>{listOfMarkerComponents}</div>;
};

export default HubMarkers;
