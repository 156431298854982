import organizationProperties, {
    OrganizationPropertiesStore,
} from 'business-dashboard/src/reducers/organizationPropertiesReducer';
import apiClients, { ApiClientsState } from './apiClientReducer';
import app, { AppStore } from './App';
import config, { ConfigStore } from './Config';
import dashboardData, { DashboardData } from './Dashboard';

import dashboardSensorData, { DashboardSensorDataStore } from './DashboardSensorData';

import deviceConfig, { DeviceConfigStore } from './DeviceConfig';
import devicePage, { DevicePageState } from './DevicePage';
import devices, { DevicesStore } from './Devices';
import deviceSensorData, { DeviceSensorDataState } from './DeviceSensorData';
import downloadCSV, { CSVDownloadState } from './DownloadCSV';
import endedSegments, { EndedSegmentsStore } from './EndedSegments';
import endedSegmentData, { EndedSegmentDataStore } from './EndedSegmentsData';
import generateHomeRadonReport, { GenerateRadonHomeReportState } from './GenerateHomeRadonReport';
import locations, { LocationStore } from './Locations';

import locationsStatus, { LocationsStatusState } from './LocationsStatusReducer';
import login, { LoginState } from './Login';
import notificationBox, { NotificationBoxState } from './NotificationBox';
import organizationInvites, { OrganizationInvites } from './OrganizationInvites';
import outdoorAirInfoPage, { OutdoorAirInfoStore } from './OutdoorAirInfo';
import commonRequests, { CommonsRequestStore } from './requestReducer';
import toggles, { TogglesStore } from './Toggles';
import unsubscribeEmail, { UnsubscribeEmailAlertStore } from './UnsubscribeEmailAlert';
import userSettings, { UserSettingsStore } from './userSettings';
import verifySerialNumber, { VerifySerialNumberStore } from './VerifySerialNumber';

export type Store = {
    app: AppStore;
    commonRequests: CommonsRequestStore;
    config: ConfigStore;
    dashboardData: DashboardData;
    dashboardSensorData: DashboardSensorDataStore;
    deviceConfig: DeviceConfigStore;
    devicePage: DevicePageState;
    devices: DevicesStore;
    deviceSensorData: DeviceSensorDataState;
    downloadCSV: CSVDownloadState;
    endedSegments: EndedSegmentsStore;
    endedSegmentData: EndedSegmentDataStore;
    generateHomeRadonReport: GenerateRadonHomeReportState;
    locationsStatus: LocationsStatusState;
    locations: LocationStore;
    login: LoginState;
    notificationBox: NotificationBoxState;
    organizationInvites: OrganizationInvites;
    organizationProperties: OrganizationPropertiesStore;
    outdoorAirInfoPage: OutdoorAirInfoStore;
    toggles: TogglesStore;
    unsubscribeEmail: UnsubscribeEmailAlertStore;
    userSettings: UserSettingsStore;
    verifySerialNumber: VerifySerialNumberStore;
    apiClients: ApiClientsState;
};

const rootReducer = {
    app,
    commonRequests,
    config,
    dashboardData,
    dashboardSensorData,
    deviceConfig,
    devicePage,
    devices,
    deviceSensorData,
    downloadCSV,
    endedSegments,
    endedSegmentData,
    generateHomeRadonReport,
    locations,
    login,
    notificationBox,
    organizationInvites,
    organizationProperties,
    outdoorAirInfoPage,
    toggles,
    unsubscribeEmail,
    userSettings,
    verifySerialNumber,
    apiClients,
    locationsStatus,
};

export default rootReducer;
