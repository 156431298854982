import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { statusColor } from 'commons/src/commonFunctions';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import { sensorUnits } from 'commons/src/constants';
import { getWeatherIcon, icons } from 'commons/src/features/outdoorAirQualityPage/WeatherAttrs';
import { EnumStatusColors, SensorTypes } from 'commons/src/models/commonEnums';
import { CurrentSensorValuesType } from 'commons/src/models/commonTypeScript';
import { OutdoorSpace } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import styles from './SpacesOutdoorElement.module.scss';

type Props = {
    space?: OutdoorSpace;
};
const SpacesOutdoorElement = ({ space }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const pmThresholds = useSelector((state: Store) => state.config.thresholds.pm25);
    if (!space) {
        return <div />;
    }
    const weatherIcon = space?.weatherIcon && getWeatherIcon(space.weatherIcon as keyof typeof icons);

    const sensorElement = (sensor: CurrentSensorValuesType): ReactElement => {
        const dotColor = pmThresholds && statusColor(pmThresholds.ranges, sensor.value);
        return (
            <div className={styles.sensorElement} key={`outdoorSensor-${sensor.type}`}>
                {sensor.type === SensorTypes.pm25 && <div className={styles[dotColor || EnumStatusColors.grey]} />}
                {sensor.type === SensorTypes.temp ? weatherIcon : <SensorIcon sensor={sensor.type} />}
                {sensor.value?.toFixed(0)}
                {sensorUnits[sensor.unit as keyof typeof sensorUnits]}
            </div>
        );
    };
    return (
        <div className={styles.element}>
            <div className={styles.header}>
                <div>{txt('OutdoorAir')}</div>
                <div className={styles.providedBy}>{txt('Weather.ProvidedBy')}</div>
            </div>
            <div className={styles.sensorSection}>
                {space?.currentSensorValues.map(values => sensorElement(values))}
            </div>
        </div>
    );
};

export default SpacesOutdoorElement;
