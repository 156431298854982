import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import RequestActions from 'commons/src/models/RequestTypes';
import displayAlertBoxSaga from 'commons/src/sagas/displayAlertBox';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import {
    CreateFloorPlanSpaceZone,
    CreateFloorPlanSpaceZoneSuccess,
    createFloorPlanSpaceZoneSuccess,
    CreateSpaceFloorPlan,
    createSpaceFloorPlanSuccess,
    CreateSpaceFloorPlanSuccess,
    DeleteFloorPlanSpaceZone,
    DeleteFloorPlanSpaceZoneSuccess,
    deleteFloorPlanSpaceZoneSuccess,
    DeleteSpaceFloorPlan,
    deleteSpaceFloorPlanSuccess,
    EditFloorPlanSpaceZone,
    EditFloorPlanSpaceZoneSuccess,
    editFloorPlanSpaceZoneSuccess,
    EditSpaceFloorPlan,
    editSpaceFloorPlanSuccess,
    FloorPlanSpaceActionType,
    GetFloorPlanData,
    getFloorPlanDataSuccess,
    GetSpaceFloorPlans,
    getSpaceFloorPlansSuccess,
    GetSpaceFloorPlansSuccess,
} from '../actions/floorPlanSpaceActions';
import { CreateSpaceSuccess, createSpaceSuccess } from '../actions/spaceActions';
import {
    createSpaceFloorPlanZone,
    deleteFloorPlan,
    deleteSpaceFloorPlanZone,
    editFloorPlan,
    editSpaceFloorPlanZone,
    getFloorPlanDetails,
    getFloorPlans,
    uploadFloorPlan,
} from '../api/floorPlanSpaceAPI';
import { createSpace } from '../api/spaceApi';
import { FloorPlanData, SpaceFloorPlan } from '../models/spaceFloorPlanModels';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';
import { getProperties } from './spaceSaga';

type GetSpaceFloorPlanSaga = Generator<
    CallEffect | PutEffect<GetSpaceFloorPlansSuccess> | CallEffect<{ floorPlans: SpaceFloorPlan[] }> | RequestActions,
    void,
    { floorPlans: SpaceFloorPlan[] }
>;

function* getSpaceFloorPlansSaga({ locationId }: GetSpaceFloorPlans): GetSpaceFloorPlanSaga {
    try {
        const { floorPlans } = yield call(getFloorPlans, locationId);
        yield put(getSpaceFloorPlansSuccess(floorPlans, locationId));
        yield put(requestSuccess(RequestType.GetSpaceFloorPlans, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.GetSpaceFloorPlans, RequestActionType.Error));
    }
}

type CreateSpaceFloorPlanSaga = Generator<
    CallEffect | PutEffect<CreateSpaceFloorPlanSuccess> | CallEffect<{ id: string; image: string }> | RequestActions,
    void,
    { id: string; image: string }
>;

function* createSpaceFloorPlanSaga({ payload, locationId, imageFile }: CreateSpaceFloorPlan): CreateSpaceFloorPlanSaga {
    try {
        const formData = new FormData();
        formData.append('image', imageFile);
        formData.append('name', payload.name);
        formData.append('floor', payload.floor.toString());
        const { id, image } = yield call(uploadFloorPlan, formData, locationId);
        yield put(createSpaceFloorPlanSuccess(id, image, payload, locationId));
        yield put(requestSuccess(RequestType.CreateSpaceFloorPlan, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.CreateSpaceFloorPlan, RequestActionType.Error));
    }
}

type GetFloorPlanDetailsSaga = Generator<CallEffect<FloorPlanData> | PutEffect | RequestActions, void, FloorPlanData>;
function* getFloorPlanDetailsSaga({ locationId, floorPlanId }: GetFloorPlanData): GetFloorPlanDetailsSaga {
    try {
        const floorPlanData = yield call(getFloorPlanDetails, locationId, floorPlanId);
        yield put(getFloorPlanDataSuccess(floorPlanData, locationId, floorPlanId));
        yield put(requestSuccess(RequestType.GetFloorPlanData, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.GetFloorPlanData, RequestActionType.Error));
    }
}

type EditFloorPlanSaga = Generator<CallEffect | PutEffect | RequestActions, void, void>;

function* editFloorPlanSaga({ payload, floorPlanId, locationId }: EditSpaceFloorPlan): EditFloorPlanSaga {
    try {
        yield call(editFloorPlan, payload, floorPlanId, locationId);
        yield put(editSpaceFloorPlanSuccess(payload, floorPlanId, locationId));
        yield put(requestSuccess(RequestType.EditSpaceFloorPlan, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield call(displayAlertBoxSaga, `ErrorCodes.${asErrorType.error}`, false, true);
        yield put(requestError(asErrorType, RequestType.EditSpaceFloorPlan, RequestActionType.Error));
    }
}

type DeleteFloorPlanSaga = Generator<CallEffect | PutEffect | RequestActions, void, void>;
function* deleteFloorPlanSaga({ locationId, floorPlanId }: DeleteSpaceFloorPlan): DeleteFloorPlanSaga {
    try {
        yield call(deleteFloorPlan, floorPlanId, locationId);
        yield put(deleteSpaceFloorPlanSuccess(floorPlanId, locationId));
        yield put(requestSuccess(RequestType.DeleteSpaceFloorPlan, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.DeleteSpaceFloorPlan, RequestActionType.Error));
    }
}

type CreateFloorPlanSpaceZoneSaga = Generator<
    | CallEffect<void>
    | CallEffect<{ id: string }>
    | PutEffect<CreateFloorPlanSpaceZoneSuccess>
    | PutEffect<CreateSpaceSuccess>
    | RequestActions,
    void,
    { id: string }
>;
function* createFloorPlanSpaceZoneSaga({
    payload,
    spaceId,
    floorPlanId,
    locationId,
    spacePayload,
}: CreateFloorPlanSpaceZone): CreateFloorPlanSpaceZoneSaga {
    try {
        let requestSpaceId: string | undefined = spaceId;

        if (!spaceId && spacePayload) {
            const { id } = yield call(createSpace, spacePayload, locationId);
            const properties = getProperties(spacePayload.properties);
            yield put(
                createSpaceSuccess({
                    name: spacePayload.name,
                    placement: spacePayload.placement,
                    id,
                    locationId,
                    devices: [],
                    hubs: [],
                    properties,
                    endedSegments: [],
                    floorPlanId,
                })
            );
            requestSpaceId = id;
        }
        if (requestSpaceId) {
            yield call(createSpaceFloorPlanZone, payload, requestSpaceId, floorPlanId, locationId);
            yield put(
                createFloorPlanSpaceZoneSuccess(locationId, floorPlanId, requestSpaceId, {
                    ...payload,
                    id: requestSpaceId,
                })
            );
            yield put(requestSuccess(RequestType.CreateSpaceFloorPlanZone, RequestActionType.Success));
        }
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.CreateSpaceFloorPlanZone, RequestActionType.Error));
    }
}

type EditFloorPlanSpaceZoneSaga = Generator<
    CallEffect | PutEffect<EditFloorPlanSpaceZoneSuccess> | RequestActions,
    void,
    void
>;

function* editFloorPlanSpaceZoneSaga({
    payload,
    spaceId,
    floorPlanId,
    locationId,
}: EditFloorPlanSpaceZone): EditFloorPlanSpaceZoneSaga {
    try {
        yield call(editSpaceFloorPlanZone, payload, spaceId, floorPlanId, locationId);
        yield put(editFloorPlanSpaceZoneSuccess(locationId, floorPlanId, spaceId, payload));
        yield put(requestSuccess(RequestType.EditSpaceFloorPlanZone, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.EditSpaceFloorPlanZone, RequestActionType.Error));
    }
}

type DeleteFloorPlanSpaceZoneSaga = Generator<
    CallEffect | PutEffect<DeleteFloorPlanSpaceZoneSuccess> | RequestActions,
    void,
    void
>;

function* deleteFloorPlanSpaceZoneSaga({
    spaceId,
    floorPlanId,
    locationId,
}: DeleteFloorPlanSpaceZone): DeleteFloorPlanSpaceZoneSaga {
    try {
        yield call(deleteSpaceFloorPlanZone, spaceId, floorPlanId, locationId);
        yield put(deleteFloorPlanSpaceZoneSuccess(locationId, floorPlanId, spaceId));
        yield put(requestSuccess(RequestType.DeleteSpaceFloorPlanZone, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.DeleteSpaceFloorPlanZone, RequestActionType.Error));
    }
}

export default function* floorPlanSpaceSaga(): Generator {
    yield all([
        takeEvery(FloorPlanSpaceActionType.GetSpaceFloorPlansInit, getSpaceFloorPlansSaga),
        takeEvery(FloorPlanSpaceActionType.CreateSpaceFloorPlanInit, createSpaceFloorPlanSaga),
        takeEvery(FloorPlanSpaceActionType.GetFloorPlanDataInit, getFloorPlanDetailsSaga),
        takeEvery(FloorPlanSpaceActionType.EditSpaceFloorPlanInit, editFloorPlanSaga),
        takeEvery(FloorPlanSpaceActionType.DeleteSpaceFloorPlanInit, deleteFloorPlanSaga),
        takeEvery(FloorPlanSpaceActionType.CreateSpaceFloorPlanZoneInit, createFloorPlanSpaceZoneSaga),
        takeEvery(FloorPlanSpaceActionType.EditSpaceFloorPlanZoneInit, editFloorPlanSpaceZoneSaga),
        takeEvery(FloorPlanSpaceActionType.DeleteSpaceFloorPlanZoneInit, deleteFloorPlanSpaceZoneSaga),
    ]);
}
