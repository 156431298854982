import { RequestActionType } from 'commons/src/actions/requestActions';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import {
    BusinessActionRequestType as ActionRequestType,
    GetOrganizationResponse,
    InviteCreatedResponse,
    SendInviteBody,
    MembershipRolePayload,
} from '../models/common';

import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum OrganizationMemberActionType {
    ChangeMembershipRoleInit = 'CHANGE_MEMBERSHIP_ROLE/INIT',
    ChangeMembershipRoleSuccess = 'CHANGE_MEMBERSHIP_ROLE/SUCCESS',
    GetOrganizationMembersInit = 'GET_ORGANIZATION_MEMBERS/INIT',
    GetOrganizationMembersSuccess = 'GET_ORGANIZATION_MEMBERS/SUCCESS',
    GetOrganizationMembersError = 'GET_ORGANIZATION_MEMBERS/ERROR',
    ResendInviteInit = 'RESEND_INVITE/INIT',
    SendInviteInit = 'SEND_INVITE/INIT',
    SendInviteSuccess = 'SEND_INVITE/SUCCESS',
    SendInviteError = 'SEND_INVITE/ERROR',
    DeleteInviteInit = 'DELETE_INVITE/INIT',
    DeleteInviteSuccess = 'DELETE_INVITE/SUCCESS',
    DeleteInviteError = 'DELETE_INVITE/ERROR',
    RemoveMemberInit = 'REMOVE_MEMBER/INIT',
    RemoveMemberSuccess = 'REMOVE_MEMBER/SUCCESS',
    RemoveMemberError = 'REMOVE_MEMBER/ERROR',
    ToggleMobileSettingsMenu = 'TOGGLE_MOBILE_SETTINGS_MENU',
}

export interface GetOrganizationMembers extends ActionRequestType {
    type: OrganizationMemberActionType.GetOrganizationMembersInit;
}
export const getOrganizationMembers = (): GetOrganizationMembers => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.GetOrganizationMembers,
    type: OrganizationMemberActionType.GetOrganizationMembersInit as OrganizationMemberActionType.GetOrganizationMembersInit,
});

export interface GetOrganizationMembersSuccess extends ActionRequestType {
    type: OrganizationMemberActionType.GetOrganizationMembersSuccess;
    response: GetOrganizationResponse;
}
export const getOrganizationMembersSuccess = (response: GetOrganizationResponse): GetOrganizationMembersSuccess => ({
    requestActionType: RequestActionType.Success as RequestActionType.Success,
    requestType: RequestType.GetOrganizationMembers,
    type: OrganizationMemberActionType.GetOrganizationMembersSuccess as OrganizationMemberActionType.GetOrganizationMembersSuccess,
    response,
});

export interface GetOrganizationMembersError extends ActionRequestType {
    type: OrganizationMemberActionType.GetOrganizationMembersError;
    error: ErrorType;
}
export const getOrganizationMembersError = (error: ErrorType): GetOrganizationMembersError => ({
    requestActionType: RequestActionType.Error as RequestActionType.Error,
    requestType: RequestType.GetOrganizationMembers,
    type: OrganizationMemberActionType.GetOrganizationMembersError as OrganizationMemberActionType.GetOrganizationMembersError,
    error,
});

export interface ResendInvite extends ActionRequestType {
    type: OrganizationMemberActionType.ResendInviteInit;
    inviteId: string;
    loadingId: string;
}
export const resendInvite = (inviteId: string): ResendInvite => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.ResendInvite,
    loadingId: inviteId,
    type: OrganizationMemberActionType.ResendInviteInit as OrganizationMemberActionType.ResendInviteInit,
    inviteId,
});

export interface DeleteInvite extends ActionRequestType {
    type: OrganizationMemberActionType.DeleteInviteInit;
    inviteId: string;
    loadingId: string;
}
export const deleteInvite = (inviteId: string): DeleteInvite => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.DeleteInvite,
    loadingId: inviteId,
    type: OrganizationMemberActionType.DeleteInviteInit as OrganizationMemberActionType.DeleteInviteInit,
    inviteId,
});

export interface DeleteInviteSuccess extends ActionRequestType {
    type: OrganizationMemberActionType.DeleteInviteSuccess;
    inviteId: string;
}
export const deleteInviteSuccess = (inviteId: string): DeleteInviteSuccess => ({
    requestActionType: RequestActionType.Success as RequestActionType.Success,
    requestType: RequestType.DeleteInvite,
    type: OrganizationMemberActionType.DeleteInviteSuccess as OrganizationMemberActionType.DeleteInviteSuccess,
    inviteId,
});

export interface DeleteInviteError extends ActionRequestType {
    type: OrganizationMemberActionType.DeleteInviteError;
    error: ErrorType;
}
export const deleteInviteError = (error: ErrorType): DeleteInviteError => ({
    requestActionType: RequestActionType.Error as RequestActionType.Error,
    requestType: RequestType.DeleteInvite,
    type: OrganizationMemberActionType.DeleteInviteError as OrganizationMemberActionType.DeleteInviteError,
    error,
});

export interface SendInvite extends ActionRequestType {
    type: OrganizationMemberActionType.SendInviteInit;
    body: SendInviteBody;
}
export const sendInvite = (body: SendInviteBody): SendInvite => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.SendInvite,
    type: OrganizationMemberActionType.SendInviteInit as OrganizationMemberActionType.SendInviteInit,
    body,
});

export interface SendInviteSuccess extends ActionRequestType {
    type: OrganizationMemberActionType.SendInviteSuccess;
    response: InviteCreatedResponse;
}
export const sendInviteSuccess = (response: InviteCreatedResponse): SendInviteSuccess => ({
    requestActionType: RequestActionType.Success as RequestActionType.Success,
    requestType: RequestType.SendInvite,
    type: OrganizationMemberActionType.SendInviteSuccess as OrganizationMemberActionType.SendInviteSuccess,
    response,
});

export interface SendInviteError extends ActionRequestType {
    type: OrganizationMemberActionType.SendInviteError;
    error: ErrorType;
}
export const sendInviteError = (error: ErrorType): SendInviteError => ({
    requestActionType: RequestActionType.Error as RequestActionType.Error,
    requestType: RequestType.SendInvite,
    type: OrganizationMemberActionType.SendInviteError as OrganizationMemberActionType.SendInviteError,
    error,
});

export interface RemoveMember extends ActionRequestType {
    type: OrganizationMemberActionType.RemoveMemberInit;
    userId: string;
    loadingId: string;
}
export const removeMember = (userId: string): RemoveMember => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.RemoveMember,
    loadingId: userId,
    type: OrganizationMemberActionType.RemoveMemberInit as OrganizationMemberActionType.RemoveMemberInit,
    userId,
});

export interface RemoveMemberSuccess extends ActionRequestType {
    type: OrganizationMemberActionType.RemoveMemberSuccess;
    userId: string;
}
export const removeMemberSuccess = (userId: string): RemoveMemberSuccess => ({
    requestActionType: RequestActionType.Success as RequestActionType.Success,
    requestType: RequestType.RemoveMember,
    type: OrganizationMemberActionType.RemoveMemberSuccess as OrganizationMemberActionType.RemoveMemberSuccess,
    userId,
});

export interface RemoveMemberError extends ActionRequestType {
    type: OrganizationMemberActionType.RemoveMemberError;
    error: ErrorType;
}
export const removeMemberError = (error: ErrorType): RemoveMemberError => ({
    requestActionType: RequestActionType.Error as RequestActionType.Error,
    requestType: RequestType.RemoveMember,
    type: OrganizationMemberActionType.RemoveMemberError as OrganizationMemberActionType.RemoveMemberError,
    error,
});

export interface ChangeMembershipRole extends ActionRequestType {
    type: OrganizationMemberActionType.ChangeMembershipRoleInit;
    userId: string;
    payload: MembershipRolePayload;
}
export const changeMembershipRole = (userId: string, payload: MembershipRolePayload): ChangeMembershipRole => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.ChangeMembershipRole,
    type: OrganizationMemberActionType.ChangeMembershipRoleInit as OrganizationMemberActionType.ChangeMembershipRoleInit,
    userId,
    payload,
});

export interface ChangeMembershipRoleSuccess extends ActionRequestType {
    type: OrganizationMemberActionType.ChangeMembershipRoleSuccess;
    payload: MembershipRolePayload;
    userId: string;
}
export const changeMembershipRoleSuccess = (
    userId: string,
    payload: MembershipRolePayload
): ChangeMembershipRoleSuccess => ({
    requestActionType: RequestActionType.Success as RequestActionType.Success,
    requestType: RequestType.ChangeMembershipRole,
    type: OrganizationMemberActionType.ChangeMembershipRoleSuccess as OrganizationMemberActionType.ChangeMembershipRoleSuccess,
    payload,
    userId,
});

export type OrganizationMemberAction =
    | GetOrganizationMembers
    | GetOrganizationMembersSuccess
    | GetOrganizationMembersError
    | ChangeMembershipRole
    | ChangeMembershipRoleSuccess
    | ResendInvite
    | DeleteInvite
    | DeleteInviteSuccess
    | DeleteInviteError
    | SendInvite
    | SendInviteSuccess
    | SendInviteError
    | RemoveMember
    | RemoveMemberSuccess
    | RemoveMemberError;
