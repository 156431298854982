import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/joy/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import CircularProgress from '@mui/joy/CircularProgress';
import FormControl from '@mui/joy/FormControl';
import { AutocompleteChangeReason, FilterOptionsState } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './Combobox.module.scss';
import InputLabel from './InputLabel';

export type OptionType = { inputValue?: string; label: string; id?: string };

export type Props = {
    selectorOptions: OptionType[];
    onCreateNewOption: (label: string) => void;
    onSelect: (option: OptionType | null) => void;
    createOptionLoading: boolean;
    label: string;
    id: string;
    testId: string;
    disabled?: boolean;
    selectedOption?: OptionType;
};

const Combobox = ({
    selectorOptions,
    onCreateNewOption,
    onSelect,
    createOptionLoading,
    selectedOption,
    id,
    label,
    testId,
    disabled,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const filter = createFilterOptions<OptionType>();
    const [value, setValue] = useState<OptionType | null>(null);

    const selectOption = (option: OptionType | null): void => {
        if (option === null) {
            onSelect(null);
        } else if (option.inputValue) {
            onCreateNewOption(option.inputValue.trim());
        } else if (option.id) {
            onSelect({ id: option.id, label: option.label });
        }
    };
    useEffect((): void => {
        if (selectedOption !== value) {
            const newOption = selectedOption === undefined ? null : selectedOption;
            setValue(newOption);
        }
    }, [selectedOption]);

    const onChange = (
        event: SyntheticEvent,
        newValue: string | OptionType | null,
        reason: AutocompleteChangeReason
    ): void => {
        if (reason === 'selectOption') {
            selectOption(newValue as OptionType);
        } else if (reason === 'clear') {
            selectOption(null);
        }
        if (typeof newValue === 'string') {
            setValue({
                label: newValue,
            });
        } else setValue(newValue);
    };
    const getFilterOptions = (options: OptionType[], params: FilterOptionsState<OptionType>): OptionType[] => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        // Suggest the creation of a new value
        const isExisting = options.some(option => inputValue === option.label);
        if (inputValue !== '' && !isExisting) {
            filtered.push({
                inputValue,
                label: `${txt('Combobox.Create')} "${inputValue}"`,
            });
        }
        return filtered;
    };
    const getOptionLabel = (option: string | OptionType): string => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
            return option.inputValue;
        }
        // Regular option
        return option.label;
    };

    const renderElement = (
        props: Omit<React.HTMLAttributes<HTMLLIElement>, 'color'>,
        option: OptionType
    ): ReactElement => <AutocompleteOption {...props}>{option.label}</AutocompleteOption>;

    return (
        <FormControl id={id} className={styles.component}>
            <InputLabel htmlFor={id} label={label} />
            <Autocomplete
                value={value ?? null}
                loading={createOptionLoading}
                disabled={disabled || createOptionLoading}
                onChange={onChange}
                filterOptions={getFilterOptions}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo
                options={selectorOptions}
                getOptionLabel={getOptionLabel}
                renderOption={renderElement}
                placeholder={txt(selectorOptions.length <= 0 ? 'Combobox.CreateOption' : 'Combobox.SelectOption')}
                endDecorator={
                    createOptionLoading ? <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} /> : null
                }
                {...{ 'data-testid': testId }}
            />
        </FormControl>
    );
};

export default Combobox;
