import { takeEvery, put, call } from 'redux-saga/effects';
import { updateDashboardLayout, updateDashboardLayoutFailure } from '../../actions/DashboardActions';
import { FetchIndoorVsOutdoorTileData, OutdoorActionType } from '../../actions/OutdoorActions';
import fetchOutdoorComparisonData from '../../api/fetchIndoorVsOutdoorData';
import { DashboardTile, IndoorVsOutdoorTileContent, TileData, WeatherTileContent } from '../../models/commonTypeScript';

export function createDashboardConfig(
    content: IndoorVsOutdoorTileContent | WeatherTileContent,
    tileData: TileData,
    tiles: DashboardTile[]
): DashboardTile[] {
    return [...tiles, { ...tileData, content }];
}

export function* fetchIndoorVsOutdoorTileDataSaga({ payload }: FetchIndoorVsOutdoorTileData): Generator {
    const { tiles, tileData, dashboardId, newModule } = payload;
    try {
        const [locationId, serialNumber, sensorType] = tileData.ref.split('.');
        const response: IndoorVsOutdoorTileContent = yield call(
            fetchOutdoorComparisonData,
            locationId,
            serialNumber,
            sensorType
        );

        yield put(
            updateDashboardLayout({
                tiles: createDashboardConfig(response, tileData, tiles),
                newModule,
                dashboardId,
            })
        );
    } catch (error) {
        yield put(
            updateDashboardLayoutFailure(error.error === 'location' ? 'invalidOutdoorLocation' : 'AddNewTileError')
        );
    }
}

export default function* takeIndoorOutdoorTileActions(): Generator {
    yield takeEvery(OutdoorActionType.FetchIndoorVsOutdoorTileDataInit, fetchIndoorVsOutdoorTileDataSaga);
}
