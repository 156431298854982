import React from 'react';
import { useTranslation } from 'react-i18next';
import AppStoreImg from '../../../../img/AppStore.png';
import PlayStoreImg from '../../../../img/GooglePlayStore.png';
import styles from './SpaceAddDeviceApplinks.module.scss';

const linkToBusinessAppInAppStore = 'https://apps.apple.com/us/app/airthings-business/id6444059293';
const linkToBusinessAppInGooglePlayStore =
    'https://play.google.com/store/apps/details?id=com.airthings.afb&hl=en&gl=US&pli=1';

const SpaceAddDeviceAppLinks = (): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className={styles.body}>
            <div className={styles.text}>{txt('AddSpace.AddWithBusinessApp')}</div>
            <div className={styles.storeLinks}>
                <a href={linkToBusinessAppInAppStore} target="_blank" rel="noopener noreferrer">
                    <img src={AppStoreImg} alt={txt('AddDevice.AppStoreLink')} />
                </a>
                <a href={linkToBusinessAppInGooglePlayStore} target="_blank" rel="noopener noreferrer">
                    <img src={PlayStoreImg} alt={txt('AddDevice.PlayStoreLink')} />
                </a>
            </div>
        </div>
    );
};

export default SpaceAddDeviceAppLinks;
