/* eslint max-len: ["error", 1000] */
import React from 'react';

const ForecastSun = (): React.ReactElement => (
    <svg
        version="1.1"
        className="weather-icon"
        width="30px"
        height="30px"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
    >
        <path d="M6.6,7.6c0.2,0.2,0.5,0.3,0.8,0.3S8,7.8,8.2,7.6c0.5-0.5,0.5-1.2,0-1.7L6.6,4.2c-0.5-0.5-1.2-0.5-1.7,0 s-0.5,1.2,0,1.7L6.6,7.6z" />
        <path d="M5.5,14.3c0-0.7-0.5-1.2-1.2-1.2H1.9c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h2.4C4.9,15.5,5.5,14.9,5.5,14.3z" />
        <path d="M6.6,21l-1.7,1.7c-0.5,0.5-0.5,1.2,0,1.7c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3l1.7-1.7 c0.5-0.5,0.5-1.2,0-1.7C7.8,20.6,7.1,20.6,6.6,21z" />
        <path d="M15,23.8c-0.7,0-1.2,0.5-1.2,1.2v2.4c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2V25C16.2,24.3,15.7,23.8,15,23.8z" />
        <path d="M23.4,21c-0.5-0.5-1.2-0.5-1.7,0s-0.5,1.2,0,1.7l1.7,1.7c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3 c0.5-0.5,0.5-1.2,0-1.7L23.4,21z" />
        <path d="M28.1,13.1h-2.4c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h2.4c0.7,0,1.2-0.5,1.2-1.2 C29.3,13.6,28.8,13.1,28.1,13.1z" />
        <path d="M22.6,7.9c0.3,0,0.6-0.1,0.8-0.3l1.7-1.7c0.5-0.5,0.5-1.2,0-1.7s-1.2-0.5-1.7,0l-1.7,1.7 c-0.5,0.5-0.5,1.2,0,1.7C22,7.8,22.3,7.9,22.6,7.9z" />
        <path d="M15,4.8c0.7,0,1.2-0.5,1.2-1.2V1.2C16.2,0.5,15.7,0,15,0s-1.2,0.5-1.2,1.2v2.4C13.8,4.2,14.3,4.8,15,4.8z" />
        <path d="M15,6.5c-4.3,0-7.7,3.5-7.7,7.7c0,4.3,3.5,7.7,7.7,7.7c4.3,0,7.7-3.5,7.7-7.7S19.3,6.5,15,6.5z M15,19.6 c-3,0-5.4-2.4-5.4-5.4S12,8.8,15,8.8s5.4,2.4,5.4,5.4S18,19.6,15,19.6z" />
    </svg>
);
export default ForecastSun;
