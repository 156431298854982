import React from 'react';
import { BuildingHealthStats } from '../../../../models/buildingHealthModels';
import styles from './BuildingHealtStatusSummaryBar.module.scss';
import HealthSummary from './HealthSummary';

type Props = {
    hubs?: BuildingHealthStats;
    devices?: BuildingHealthStats;
};

const HealthStatusSummary = ({ hubs, devices }: Props): React.ReactElement => {
    return (
        <div className={styles.statusBarDeviceWrapper}>
            <HealthSummary stats={hubs} isHub />
            <HealthSummary stats={devices} isHub={false} />
        </div>
    );
};

export default HealthStatusSummary;
