import React from 'react';
import { connect } from 'react-redux';
import { mixpanelChatInformation } from 'commons/src/analytics';
import config from '../../config';
import { Environment } from '../../constants';
import { DeviceTypeNames, GroupType } from '../../models/commonEnums';
import { Group, IntercomB2BUserType, MixpanelChatUserType } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import { userIsHbs } from '../findUserType';
import Intercom from '../Intercom';
import groupToMixpanelCompany from './groupToMixpanelCompany';

type StateProps = {
    user: MixpanelChatUserType;
    loading: boolean;
    selectedGroup?: Group;
    b2bUserInfo: IntercomB2BUserType;
    groups: string[];
};

export const ChatWidgetComponent = ({
    user,
    loading,
    selectedGroup,
    b2bUserInfo,
    groups,
}: StateProps): React.ReactElement => {
    if (!loading && config.env !== Environment.dev) {
        // Only render if user details are present.

        mixpanelChatInformation(user, groups, userIsHbs());
        return <Intercom user={user} b2bUserInfo={b2bUserInfo} hbsOrgRole={selectedGroup && selectedGroup.role} />;
    }

    return <div />;
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: {
            email,
            userName,
            loading: userLoading,
            intercomUserHash,
            intercomUserHashB2B,
            userId,
            groups,
            selectedGroup,
        },
        devices: { devicesWithKeyInfo, hubs, loading: devicesLoading },
    } = state;

    /* eslint camelcase:0 */
    let product_wave1_amount = 0;
    let product_wave2_amount = 0;
    let product_waveplus_amount = 0;
    let product_waveco2_amount = 0;
    let product_wavemini_amount = 0;
    let product_spaceCo2Mini_amount = 0;

    Object.keys(devicesWithKeyInfo).forEach(key => {
        if (devicesWithKeyInfo[key].type === DeviceTypeNames.wave) product_wave1_amount += 1;
        if (devicesWithKeyInfo[key].type === DeviceTypeNames.wave2) product_wave2_amount += 1;
        if (devicesWithKeyInfo[key].type === DeviceTypeNames.wavePlus) product_waveplus_amount += 1;
        if (devicesWithKeyInfo[key].type === DeviceTypeNames.waveCo2) product_waveco2_amount += 1;
        if (devicesWithKeyInfo[key].type === DeviceTypeNames.mini) product_wavemini_amount += 1;
        if (devicesWithKeyInfo[key].type === DeviceTypeNames.spaceCo2Mini) product_spaceCo2Mini_amount += 1;
    });

    let selectedGroupInfo;
    if (selectedGroup && selectedGroup.id && selectedGroup.organizationName) {
        selectedGroupInfo = {
            id: selectedGroup.id,
            name: selectedGroup.organizationName,
            partner_type: selectedGroup.groupType === GroupType.partner ? 'b2b' : undefined,
            organization_id: selectedGroup.organizationId,
            managed_by_organization_id: selectedGroup && selectedGroup.managedByOrganization,
            created_at: selectedGroup.createdAt,
        };
    }

    const userMemberOfGroups = groups.map(group => group.groupName);
    const user: MixpanelChatUserType = {
        name: userName,
        email,
        isHbsUser: userIsHbs(),
        user_hash: intercomUserHash,
        user_id: userId,
        product_hub: hubs.length > 0,
        product_wave1: product_wave1_amount > 0,
        product_wave2: product_wave2_amount > 0,
        product_waveplus: product_waveplus_amount > 0,
        product_waveco2: product_waveco2_amount > 0,
        product_wavemini: product_wavemini_amount > 0,
        product_spaceCo2Mini: product_spaceCo2Mini_amount > 0,
        product_hub_amount: hubs.length,
        product_wave1_amount,
        product_wave2_amount,
        product_waveplus_amount,
        product_wavemini_amount,
        product_waveco2_amount,
        product_spaceCo2Mini_amount,
        groups: (userMemberOfGroups || []).join(', '),
        managed_by_user_group_id: selectedGroup && selectedGroup.managedByUserGroup,
    };

    const b2bUserInfo: IntercomB2BUserType = {
        name: userName,
        email,
        user_hash: intercomUserHashB2B,
        user_id: userId,
        groups: (userMemberOfGroups || []).join(', '),
        company: selectedGroupInfo,
    };

    if (userIsHbs() && selectedGroup) {
        user.company = groupToMixpanelCompany(selectedGroup);
    }

    return {
        user,
        loading: userLoading || devicesLoading,
        b2bUserInfo,
        groups: userMemberOfGroups,
    };
};

export default connect(mapStateToProps)(ChatWidgetComponent);
