import { RequestActionType } from 'commons/src/actions/requestActions';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { BreachType, BuildingFocusedSensorData } from '../models/buildingModels';
import { BusinessActionRequestType, SensorBreachThresholds } from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum BuildingsTimeOverThresholdActionType {
    GetFocusedSensorInit = 'GET_FOCUSED_SENSOR/INIT',
    BuildingsThresholdsSetFocusedSensorInit = 'BUILDINGS_THRESHOLDS_SET_FOCUSED_SENSOR/INIT',
    BuildingsThresholdsUpdateConfigInit = 'BUILDINGS_THRESHOLDS_UPDATE_CONFIG/INIT',
    BuildingsThresholdsUpdateConfigSuccess = 'BUILDINGS_THRESHOLDS_UPDATE_CONFIG_SUCCESS',
    BuildingsThresholdsSetFocusedSensorSuccess = 'BUILDINGS_THRESHOLDS_SET_FOCUSED_SENSOR_SUCCESS',
    GetBuildingsFocusedSensorDataInit = 'GET_BUILDINGS_FOCUSED_SENSOR_DATA/INIT',
    GetBuildingsFocusedSensorDataSuccess = 'GET_BUILDINGS_FOCUSED_SENSOR_DATA_SUCCESS',
    BuildingsThresholdsUnsetDefaultInit = 'BUILDINGS_THRESHOLDS_UNSET_DEFAULT/INIT',
    SetFocusedSensor = 'SET_FOCUSED_SENSOR',
}

export interface SetFocusedSensor extends BusinessActionRequestType {
    type: BuildingsTimeOverThresholdActionType.BuildingsThresholdsSetFocusedSensorInit;
    sensor: SensorTypes;
}

export const setFocusedSensor = (sensor: SensorTypes): SetFocusedSensor => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.BuildingsThresholdsSetFocusedSensor,
    type: BuildingsTimeOverThresholdActionType.BuildingsThresholdsSetFocusedSensorInit,
    sensor,
});

export interface SetFocusedSensorSuccess {
    type: BuildingsTimeOverThresholdActionType.BuildingsThresholdsSetFocusedSensorSuccess;
    response: {
        sensor: SensorTypes | undefined;
        thresholds: { value: number; type: BreachType }[];
        thresholdOptions: SensorBreachThresholds;
    };
}

export const setFocusedSensorSuccess = (response: {
    sensor: SensorTypes | undefined;
    thresholds: { value: number; type: BreachType }[];
    thresholdOptions: SensorBreachThresholds;
}): SetFocusedSensorSuccess => ({
    type: BuildingsTimeOverThresholdActionType.BuildingsThresholdsSetFocusedSensorSuccess,
    response,
});

export interface GetBuildingsFocusSensorData extends BusinessActionRequestType {
    type: BuildingsTimeOverThresholdActionType.GetBuildingsFocusedSensorDataInit;
    requestDetails: {
        thresholds: { type: BreachType; value: number }[];
        sensor: SensorTypes;
        to: string;
        useOpeningHours: boolean;
        from: string;
        resolution: string;
    };
}

export const getBuildingsSensorData = (requestDetails: {
    thresholds: { type: BreachType; value: number }[];
    sensor: SensorTypes;
    to: string;
    useOpeningHours: boolean;
    from: string;
    resolution: string;
}): GetBuildingsFocusSensorData => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.GetBuildingsFocusedSensorData,
    type: BuildingsTimeOverThresholdActionType.GetBuildingsFocusedSensorDataInit,
    requestDetails,
});

export interface GetBuildingsFocusedSensorDataSuccess {
    type: BuildingsTimeOverThresholdActionType.GetBuildingsFocusedSensorDataSuccess;
    locationsData: { [locationId: string]: BuildingFocusedSensorData };
    thresholds: { value: number; type: BreachType }[];
}

export const getBuildingsFocusedSensorDataSuccess = (
    locationsData: {
        [locationId: string]: BuildingFocusedSensorData;
    },
    thresholds: { value: number; type: BreachType }[]
): GetBuildingsFocusedSensorDataSuccess => ({
    type: BuildingsTimeOverThresholdActionType.GetBuildingsFocusedSensorDataSuccess,
    locationsData,
    thresholds,
});

export interface SetBuildingsFocusedSensorDefault extends BusinessActionRequestType {
    type: BuildingsTimeOverThresholdActionType.BuildingsThresholdsUpdateConfigInit;
    thresholds: { type: BreachType; value: number }[];
    sensor: SensorTypes;
}
export const setBuildingsFocusedSensorDefault = (
    thresholds: { type: BreachType; value: number }[],
    sensor: SensorTypes
): SetBuildingsFocusedSensorDefault => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.BuildingsThresholdsUpdateConfig,
    type: BuildingsTimeOverThresholdActionType.BuildingsThresholdsUpdateConfigInit,
    thresholds,
    sensor,
});

export interface SetBuildingsFocusedSensorDefaultSuccess {
    type: BuildingsTimeOverThresholdActionType.BuildingsThresholdsUpdateConfigSuccess;
    thresholds: { type: BreachType; value: number }[];
    sensor: SensorTypes;
}
export const setBuildingsFocusedSensorDefaultSuccess = (
    thresholds: { type: BreachType; value: number }[],
    sensor: SensorTypes
): SetBuildingsFocusedSensorDefaultSuccess => ({
    type: BuildingsTimeOverThresholdActionType.BuildingsThresholdsUpdateConfigSuccess,
    thresholds,
    sensor,
});

export interface GetFocusedSensor extends BusinessActionRequestType {
    type: BuildingsTimeOverThresholdActionType.GetFocusedSensorInit;
}

export const getBuildingsFocusedSensor = (): GetFocusedSensor => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.GetBuildingsThresholdsFocusedSensor,
    type: BuildingsTimeOverThresholdActionType.GetFocusedSensorInit,
});

export interface BuildingsThresholdsUnsetDefault extends BusinessActionRequestType {
    type: BuildingsTimeOverThresholdActionType.BuildingsThresholdsUnsetDefaultInit;
}

export const buildingsThresholdsUnsetDefault = (): BuildingsThresholdsUnsetDefault => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.BuildingsThresholdsUnsetDefault,
    type: BuildingsTimeOverThresholdActionType.BuildingsThresholdsUnsetDefaultInit,
});

export type BuildingsTimeOverThresholdActions =
    | SetFocusedSensorSuccess
    | GetBuildingsFocusedSensorDataSuccess
    | SetFocusedSensor
    | SetBuildingsFocusedSensorDefaultSuccess;
