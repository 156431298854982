import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { UpdateOrganizationPropertiesType } from '../actions/organizationPropertiesActions';
import {
    GetOrganizationLogoResponse,
    GetOrganizationResponse,
    MembershipRolePayload,
    SendInviteBody,
} from '../models/common';

export default async (): Promise<GetOrganizationResponse> =>
    fetch(`/members`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const inviteMember = async (invite: SendInviteBody): Promise<{ inviteId: string }> =>
    fetch('/invites', {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(invite),
    });

export const deleteInvite = async (inviteId: string): Promise<void> =>
    fetch(`/invites/${inviteId}`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });

export const resendInvite = async (inviteId: string): Promise<void> =>
    fetch(`/invites/${inviteId}/resend`, {
        method: 'POST',
        headers: await getFetchHeaders(),
    });

export const removeMember = async (userId: string): Promise<void> =>
    fetch(`/members/${userId}`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });

export const getOrganizationLogo = async (): Promise<GetOrganizationLogoResponse> =>
    fetch(`/organization/logo`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const updateOrganizationLogo = async (
    fileName: string,
    logoImage: string
): Promise<GetOrganizationLogoResponse> =>
    fetch('/organization/logo', {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ fileName, logoImage }),
    });

export const updateOrganizationProperties = async (properties: UpdateOrganizationPropertiesType): Promise<void> =>
    fetch('/organization', {
        method: 'PATCH',
        headers: await getFetchHeaders(),
        body: JSON.stringify(properties),
    });

export const changeMembershipRole = async (userId: string, payload: MembershipRolePayload): Promise<void> =>
    fetch(`/members/${userId}`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(payload),
    });
