import React from 'react';
import { Table, TableContainer } from '@mui/material';
import useTableScroll from './context/useTableScroll';
import styles from './DesktopMaterialTable.module.scss';
import MaterialTableData from './MaterialTableData';
import MaterialTableHeaders from './MaterialTableHeaders';
import { MaterialTableProps } from './models';

const DesktopMaterialTable = ({ tableId, size, locationId }: Partial<MaterialTableProps>): React.ReactElement => {
    const tableRef = useTableScroll<HTMLTableElement>(({ left }, element) => {
        if (element) {
            const body = element.querySelector('tbody');
            const header = element.querySelector('thead');
            if (header && body) {
                const isScrolled = left > 0 ? 'true' : 'false';
                body.setAttribute('data-scrolled', isScrolled);
                header.setAttribute('data-scrolled', isScrolled);
            }
        }
    });

    return (
        <TableContainer ref={tableRef} className={styles.desktopTable}>
            <Table
                aria-label={tableId}
                data-testid={tableId}
                id={tableId}
                className={styles.table}
                size={size ?? 'small'}
            >
                <MaterialTableHeaders />
                <MaterialTableData locationId={locationId} />
            </Table>
        </TableContainer>
    );
};

export default DesktopMaterialTable;
