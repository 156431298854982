import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { analyticsLogger, PageType } from '../../analytics';
import { DASHBOARD_CLICKED_TILE } from '../../analytics/AnalyticsEvents';
import NoDataTileBody from '../../components/tile/NoDataTileBody';
import { DashboardVisibility, SensorTypes } from '../../models/commonEnums';
import { Pollutants, Weather } from '../../models/commonTypeScript';
import { dayWeatherCodes, getWeatherIcon, icons } from '../outdoorAirQualityPage/WeatherAttrs';
import OutdoorSensorValue from './OutdoorSensorValue';
import TileHeaderMenu from './TileHeaderMenu';

export type Props = {
    visibility: DashboardVisibility;
    tileRef: string;
    menuProps: { tileId: string; tileRef: string; removeTile: (id: string, ref: string) => void };
    isDragging: boolean;
    currentWeather: Weather;
    currentPollutants: Pollutants;
    outdoorRef: { name: string; lat: number; lng: number };
};

const OutdoorAirQualityTileComponent = (props: Props): React.ReactElement => {
    const {
        tileRef,
        menuProps,
        isDragging,
        currentWeather: weather,
        outdoorRef,
        currentPollutants: pollutantsObject,
        visibility,
    } = props;
    const { t: txt } = useTranslation();
    const url = `/outdoor/${tileRef}`;
    const { pollutants } = pollutantsObject; // eslint-disable-line camelcase
    const preventLink = (e: SyntheticEvent<HTMLElement>): void => {
        if (isDragging) {
            e.preventDefault();
        } else {
            analyticsLogger(DASHBOARD_CLICKED_TILE, { pageType: PageType.Dashboard, tileType: 'OutdoorAirQuality' });
        }
    };

    const weatherError = !weather.iconCode;
    const pollutantsError = !pollutantsObject || !pollutants || Object.keys(pollutants).length === 0;
    const errorMessage = 'AirQualityDataUnavailable';
    return (
        <NavLink to={url} onClick={preventLink} className="tile-wrapper__link page-wrapper__column">
            <div className="tile-wrapper">
                <div
                    className={classNames(
                        'tile-header',
                        'tile-header--background',
                        `tile-header--background--${dayWeatherCodes[weather?.iconCode]}`
                    )}
                >
                    <div className="tile-header__title">
                        <h2 className="tile-header__title--name tile-header__title--name--background">
                            {outdoorRef.name}
                        </h2>
                        <span className="tile-header__title--address tile-header__title--address--background">
                            {txt('OutdoorAir')}
                        </span>
                    </div>
                    <div className="tile-header__controls tile-header__controls--weather">
                        {!weatherError && (
                            <div className="tile-header__weather">
                                <div
                                    className="tile-header__weather--description"
                                    title={weather && txt(`Weather${weather.iconCode}`)}
                                >
                                    {weather?.iconCode && getWeatherIcon(weather.iconCode as keyof typeof icons)}
                                </div>
                                <div className="tile-header__weather--value">
                                    {weather.sensors?.temperature.value.toFixed(0)}
                                    {'\u00B0'}
                                </div>
                            </div>
                        )}
                        <TileHeaderMenu
                            visibility={visibility}
                            tileId={menuProps.tileId}
                            onRemoveTile={(): void => menuProps.removeTile(menuProps.tileId, menuProps.tileRef)}
                            isSensorTile={false}
                        />
                    </div>
                </div>
                {!pollutantsError ? (
                    <div className="tile-body tile-body--devices">
                        <OutdoorSensorValue
                            sensor={SensorTypes.pm25}
                            concentration={pollutants[SensorTypes.pm25].value}
                            unit={pollutants[SensorTypes.pm25].unit}
                        />
                        <OutdoorSensorValue sensor="o3" concentration={pollutants.o3.value} unit={pollutants.o3.unit} />
                    </div>
                ) : (
                    <div className="tile-body">
                        <NoDataTileBody message={errorMessage} />
                    </div>
                )}
            </div>
        </NavLink>
    );
};

export default OutdoorAirQualityTileComponent;
