import { all, call, put, takeEvery, CallEffect, PutEffect } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import RequestActions, { RequestSuccessAction, RequestErrorAction } from 'commons/src/models/RequestTypes';
import displayAlertBoxSaga from 'commons/src/sagas/displayAlertBox';
import { toErrorType, toErrorTypeWithMessage } from 'commons/src/sagas/isErrorType';
import history from 'commons/src/store/history';
import {
    AddWebhook,
    addWebhookSuccess,
    DeleteWebhook,
    deleteWebhookSuccess,
    fetchWebhooksSuccess,
    FetchWebhookEvents,
    fetchWebhookEventsSuccess,
    TestWebhook,
    UpdateWebhookActiveState,
    updateWebhookSuccess,
    WebhooksActionType,
    UpdateWebhook,
    FetchWebhooksSuccess,
    AddWebhookSuccess,
    FetchWebhookEventsSuccess,
} from '../actions/integrationActions';
import getWebhooksData, {
    addNewWebhook,
    deleteWebhook,
    fetchWebhookEvents,
    testWebhook,
    updateWebhook,
} from '../api/integrationsApi';
import { paths } from '../constants';
import { Webhook, WebhookEvent } from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type FetchWebhooksSagaReturnType = Generator<
    CallEffect<{ webhooks: Webhook[] }> | PutEffect<FetchWebhooksSuccess> | RequestActions,
    void,
    { webhooks: Webhook[] }
>;
export function* fetchWebhooksSaga(): FetchWebhooksSagaReturnType {
    try {
        const response: { webhooks: Webhook[] } = yield call(getWebhooksData);
        yield put(fetchWebhooksSuccess(response.webhooks));
        yield put(requestSuccess(RequestType.FetchWebhooks, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchWebhooks, RequestActionType.Error));
    }
}

export function* updateWebhookActiveStateSaga({ webhook }: UpdateWebhookActiveState): Generator {
    try {
        yield call(updateWebhook, webhook);
        yield put(requestSuccess(RequestType.UpdateWebhookActiveState, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.UpdateWebhookActiveState, RequestActionType.Error));
    }
}

export function* updateWebhookSaga({ webhook }: UpdateWebhook): Generator {
    try {
        yield call(updateWebhook, webhook);
        yield put(updateWebhookSuccess(webhook));
        yield put(requestSuccess(RequestType.UpdateWebhook, RequestActionType.Success));
        yield call(displayAlertBoxSaga, 'Webhooks.UpdateSuccess', false, true);
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error, 'Webhooks.UpdateError');
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
        yield put(requestError(errorWithMessage.error, RequestType.UpdateWebhook, RequestActionType.Error));
    }
}

type AddWebhooksSagaReturnType = Generator<
    CallEffect<{ id: string }> | PutEffect<AddWebhookSuccess> | RequestSuccessAction | CallEffect | RequestErrorAction,
    void,
    { id: string }
>;

export function* addWebhookSaga({ webhook }: AddWebhook): AddWebhooksSagaReturnType {
    try {
        const response: { id: string } = yield call(addNewWebhook, webhook);
        history.push({ pathname: `/${paths.webhooks}/${response.id}` });
        yield put(addWebhookSuccess({ ...webhook, id: response.id }));
        yield put(requestSuccess(RequestType.AddWebhooks, RequestActionType.Success));
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error);
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
        yield put(requestError(errorWithMessage.error, RequestType.AddWebhooks, RequestActionType.Error));
    }
}

export function* deleteWebhookSaga({ webhookId }: DeleteWebhook): Generator {
    try {
        yield call(deleteWebhook, webhookId);
        history.replace(`/${paths.webhooks}`);
        yield put(deleteWebhookSuccess(webhookId));
        yield put(requestSuccess(RequestType.DeleteWebhook, RequestActionType.Success));
        yield call(displayAlertBoxSaga, 'Webhooks.DeleteSuccess', false, true);
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error, 'Webhooks.DeleteError');
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
        yield put(requestError(errorWithMessage.error, RequestType.DeleteWebhook, RequestActionType.Error));
    }
}

type FetchWebhookEventsSagaReturnType = Generator<
    CallEffect<{ events: WebhookEvent[] }> | PutEffect<FetchWebhookEventsSuccess> | RequestActions,
    void,
    { events: WebhookEvent[] }
>;

export function* fetchWebhookEventsSaga({ webhookId }: FetchWebhookEvents): FetchWebhookEventsSagaReturnType {
    try {
        const response: { events: WebhookEvent[] } = yield call(fetchWebhookEvents, webhookId);
        yield put(fetchWebhookEventsSuccess(response.events));
        yield put(requestSuccess(RequestType.FetchWebhookEvents, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchWebhookEvents, RequestActionType.Error));
    }
}

export function* testWebhookSaga({ webhookId }: TestWebhook): Generator {
    try {
        yield call(testWebhook, webhookId);
        yield call(fetchWebhookEventsSaga, { webhookId } as FetchWebhookEvents);
        yield put(requestSuccess(RequestType.TestWebhook, RequestActionType.Success));
    } catch (error) {
        const errorWithMessage = toErrorTypeWithMessage(error, 'Webhooks.TestError');
        yield call(displayAlertBoxSaga, errorWithMessage.message, true, true);
        yield put(requestError(errorWithMessage.error, RequestType.TestWebhook, RequestActionType.Error));
    }
}

export default function* webhookSagas(): Generator {
    yield all([
        takeEvery(WebhooksActionType.FetchWebhooksInit, fetchWebhooksSaga),
        takeEvery(WebhooksActionType.UpdateWebhookInit, updateWebhookSaga),
        takeEvery(WebhooksActionType.UpdateWebhookActiveStateInit, updateWebhookActiveStateSaga),
        takeEvery(WebhooksActionType.AddWebhookInit, addWebhookSaga),
        takeEvery(WebhooksActionType.DeleteWebhookInit, deleteWebhookSaga),
        takeEvery(WebhooksActionType.TestWebhookInit, testWebhookSaga),
        takeEvery(WebhooksActionType.FetchWebhookEventsInit, fetchWebhookEventsSaga),
    ]);
}
