import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import filterMenuElements from 'commons/src/components/menu/FilterMenuElements';
import styles from 'commons/src/components/menu/IconStatusDotOverlay.module.scss';
import NavigationSideBar from 'commons/src/components/menu/NavigationSideBar';
import { roleRestrictions } from 'commons/src/constants';
import AirthingsHorizontalLogo from 'commons/src/img/logos/Airthings_Logo_Horizontal_Dark_Grey.png';
import { FeatureToggleEnum, GroupType, RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { Group, PartnerLogoDetails } from 'commons/src/models/commonTypeScript';
import { NavigationItem } from 'commons/src/models/menuModels';
import { fetchOrganizationHealthStatusSummary } from '../../actions/healthStatusActions';
import { paths } from '../../constants';
import logo from '../../img/logos/airthings_for_business_logo.svg';
import simpleLogo from '../../img/logos/airthings_simple_logo.svg';
import { Store } from '../../reducers';

type StateProps = {
    managedByOrganizationDetails?: PartnerLogoDetails;
    selectedGroup?: Group;
    partnerLogo?: string;
    featureToggles: FeatureToggleEnum[];
    isLoggedIn: boolean;
};

type ParentProps = {
    publicPaths: (string | undefined)[];
};

type Props = StateProps & ParentProps;

export const NavigationComponent = ({
    managedByOrganizationDetails,
    partnerLogo,
    selectedGroup,
    featureToggles,
    publicPaths,
    isLoggedIn,
}: Props): React.ReactElement => {
    let currentPath = useLocation().pathname;

    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoggedIn && selectedGroup) {
            dispatch(fetchOrganizationHealthStatusSummary());
        }
    }, [isLoggedIn, selectedGroup]);

    const summary = useSelector((store: Store) => store.buildingsHealthStatus.healthStatusSummary);
    const summaryRequest = useSelector((state: Store) => state.requests.FETCH_ORGANIZATION_HEALTH_STATUS_SUMMARY);

    const extraClass = useMemo(() => {
        if (summaryRequest.loading && !summary) {
            return classNames(styles.dotStatus, styles.dotStatusWait);
        }
        const hasIssues = (summary?.offlineHubs ?? 0) + (summary?.offlineDevices ?? 0) > 0;
        return classNames(styles.dotStatus, hasIssues ? styles.dotStatusBad : styles.dotStatusGood);
    }, [summary, summaryRequest]);

    if (currentPath.includes('/app-devices')) {
        currentPath = '/app-devices/:device';
    }
    const isInPublicPath = publicPaths.map(path => `/${path}`).includes(currentPath);
    if (isInPublicPath) return <div />;

    const navigationItems: NavigationItem[] = [
        {
            id: 'buildings',
            key: 'Buildings',
            path: `/${paths.buildings}`,
            title: 'Buildings',
            iconName: 'business',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            excludedFeatureToggles: [FeatureToggleEnum.oldPartnerView],
            accessWithResourceFilter: true,
            requiredGroupTypes: [GroupType.business],
        },
        {
            id: 'status',
            key: 'Status',
            path: `/${paths.status}`,
            title: 'StatusPage.Header',
            iconName: 'wifi_off',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            excludedFeatureToggles: [FeatureToggleEnum.oldPartnerView],
            accessWithResourceFilter: true,
            extraClass,
            requiredGroupTypes: [GroupType.business],
        },
        {
            id: 'reports',
            key: 'Reports',
            path: `/${paths.reports}`,
            title: 'Reports.Reports',
            iconName: 'assignment',
            requiredRoleLevel: roleRestrictions.reports,
            excludedFeatureToggles: [FeatureToggleEnum.oldPartnerView],
            accessWithResourceFilter: true,
            requiredGroupTypes: [GroupType.business],
        },
        {
            id: 'dashboard',
            key: 'Dashboard',
            path: '/dashboard',
            title: 'Dashboard',
            iconName: 'dashboard',
            requiredRoleLevel: roleRestrictions.dashboardTiles,
            excludedFeatureToggles: [FeatureToggleEnum.oldPartnerView],
            accessWithResourceFilter: true,
            requiredGroupTypes: [GroupType.business],
        },
        {
            id: 'alerts',
            key: 'Alerts',
            path: `/${paths.alerts}`,
            title: 'NotificationAlerts.Alerts',
            iconName: 'notifications_active',
            requiredRoleLevel: roleRestrictions.alerts,
            excludedFeatureToggles: [FeatureToggleEnum.oldPartnerView],
            accessWithResourceFilter: false,
            requiredGroupTypes: [GroupType.business],
        },
        {
            id: 'partnerPage',
            key: 'Customers',
            path: `/${paths.partnerPage}`,
            title: 'Partner.Customers',
            iconName: 'people_outline',
            requiredRoleLevel: roleRestrictions.partner,
            accessWithResourceFilter: true,
            requiredGroupTypes: [GroupType.partner],
        },
        {
            id: 'integrations',
            key: 'Integrations',
            path: `/${paths.integrations}`,
            title: 'Integrations.Integrations',
            iconName: 'widgets',
            requiredRoleLevel: roleRestrictions.thirdPartyIntegration,
            accessWithResourceFilter: false,
            requiredGroupTypes: [],
        },
        {
            id: 'settings',
            key: 'Settings',
            path: `/${paths.settings}`,
            title: 'Account',
            iconName: 'account_circle',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            accessWithResourceFilter: true,
            requiredGroupTypes: [],
        },
        {
            id: 'analytics',
            key: 'Analytics',
            path: `/${paths.analytics}`,
            title: 'Analytics.AnalyticsBeta',
            iconName: 'monitoring',
            requiredRoleLevel: RequiredRoleLevel.ABOVE_ADMIN,
            accessWithResourceFilter: true,
            requiredGroupTypes: [GroupType.business, GroupType.partner],
            requiredFeatureToggles: [FeatureToggleEnum.quicksightIntegration],
        },
    ].filter(item => {
        const partnerOnlyPage = item.id === 'partnerPage';
        const orgIsPartner = selectedGroup && selectedGroup.groupType === GroupType.partner;
        return partnerOnlyPage ? orgIsPartner : true;
    });

    const accessibleNavigationItems: NavigationItem[] = filterMenuElements(
        navigationItems,
        selectedGroup?.groupType,
        selectedGroup?.role,
        (selectedGroup && selectedGroup.resourceFilterOn) || false,
        featureToggles
    );

    let displayedLogo = logo;
    let displayPartnerLogo = false;
    const partnerWithLogo = selectedGroup && selectedGroup.groupType === GroupType.partner && partnerLogo;
    if (partnerWithLogo) {
        displayedLogo = partnerWithLogo;
        displayPartnerLogo = true;
    } else if (managedByOrganizationDetails && selectedGroup) {
        const partnerBrandingOn = selectedGroup.partnerBranding;
        const managedByOrgLogo = managedByOrganizationDetails.logoImage;
        if (managedByOrgLogo && partnerBrandingOn) {
            displayedLogo = managedByOrgLogo;
            displayPartnerLogo = true;
        }
    }

    return (
        <NavigationSideBar
            navigationItems={accessibleNavigationItems}
            logo={displayedLogo}
            slimLogo={simpleLogo}
            styleClass="business-navigation"
            poweredByLogo={displayPartnerLogo ? AirthingsHorizontalLogo : undefined}
        />
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        login: { isLoggedIn },
        userSettings: { selectedGroup, managedByOrganizationDetails, featureToggles },
        organizationProperties: { logoImage: partnerLogo },
    } = store;
    return {
        managedByOrganizationDetails,
        selectedGroup,
        partnerLogo,
        featureToggles,
        isLoggedIn,
    };
};

export default connect(mapStateToProps)(NavigationComponent);
