import React, { useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { HideCookieBanner, hideCookieBanner } from '../../actions';
import { cookieDomain } from '../../config';
import { cookieLink } from '../../constants';
import { Store } from '../../reducers';

type ActionProps = {
    hideBanner: () => void;
};
type StateProps = {
    displayCookieBanner: boolean;
};

type Props = StateProps & ActionProps;

const TopCookieBanner = ({ displayCookieBanner, hideBanner }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const setCookie = (): void => {
        const aYearFromNow = moment().add(1, 'years').utc().format('ddd, DD MMM YYYY, HH:mm:ss');
        document.cookie = `accepts-cookies=true; expires=${aYearFromNow} UTC; path=/; domain=${cookieDomain}`;
        hideBanner();
        window.removeEventListener('scroll', setCookie);
    };

    useEffect(() => {
        if (displayCookieBanner) {
            window.addEventListener('scroll', setCookie);
        }
    }, []);

    if (!displayCookieBanner) {
        return <div />;
    }

    return (
        <div className="cookie-banner">
            <span className="cookie-banner__message">
                {txt('CookiePolicy')}
                <a className="cookie-banner__link" href={cookieLink} rel="noopener noreferrer" target="_blank">
                    {txt('CookiePolicyLinkText')}
                </a>
            </span>
            <button type="button" onClick={setCookie} className="cookie-banner__button">
                {txt('Close')}
            </button>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    hideBanner: (): HideCookieBanner => dispatch(hideCookieBanner()),
});

const mapStateToProps = (state: Store): StateProps => {
    const {
        app: { displayCookieBanner },
    } = state;
    return {
        displayCookieBanner,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopCookieBanner);
