import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { INSIGHT_VIEWED_REPORTS } from 'commons/src/analytics/AnalyticsEvents';
import PageHeader from 'commons/src/components/headers/PageHeader';
import { roleRestrictions } from 'commons/src/constants';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { Role } from 'commons/src/models/commonEnums';
import { paths } from '../../../constants';
import { Store } from '../../../reducers';
import InsightMainTile from './InsightMainTile';

type StateProps = {
    userRole: Role;
};

export type Props = StateProps;

export const InsightMainPageComponent = ({ userRole }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    useEffect(() => {
        analyticsLogger(INSIGHT_VIEWED_REPORTS, { pageType: PageType.Insight });
    }, []);

    return (
        <div className="radon-insight-view">
            <PageHeader title={txt('Reports.Reports')} />
            <div className="page-wrapper-flex page-wrapper-flex--wrap">
                <InsightMainTile
                    headerText="RadonInsight.RadonMitigation"
                    description="Insight.RadonInsightReportDescription"
                    href={`/${paths.radonInsight}`}
                    type="radon"
                />
                <InsightMainTile
                    headerText="Insight.IaqAnalysis"
                    description="Insight.IaqAnalysisDescription"
                    href={`/${paths.iaqInsight}`}
                    type="iaq"
                />
                <InsightMainTile
                    headerText="VirusInsight.VirusRiskInsight"
                    description="VirusInsight.VirusRiskTileDescription"
                    href={`/${paths.virusRiskInsight}`}
                    type="virusRisk"
                />
                <InsightMainTile
                    headerText="Insight.OutdoorVsIndoor"
                    description="Insight.OutdoorVsIndoorDescription"
                    href={`/${paths.outdoorInsightPage}`}
                    type="outdoorVsIndoor"
                />
                <InsightMainTile
                    headerText="InsightCertificate.IAQCertificate"
                    description="InsightCertificate.CertificateCardDescription"
                    href={`/${paths.iaqCertificate}`}
                    type="iaqCertificate"
                />
                {userRoleAboveRequiredLevel(userRole, roleRestrictions.createEditEmailSummary) && (
                    <InsightMainTile
                        headerText="EmailSummary.WeeklyBuildingsSummary"
                        description="EmailSummary.ReportsDescription"
                        href={`/${paths.emailSummaryReport}`}
                        type="emailSummary"
                        buttonText="EmailSummary.SetUpEmail"
                    />
                )}
                <InsightMainTile
                    headerText="Calibration.CalibrationReport"
                    description="Calibration.CalibrationDescription"
                    href={`/${paths.calibrationCertificate}`}
                    type="calibration"
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { selectedGroup },
    } = state;
    return {
        userRole: selectedGroup ? selectedGroup.role : Role.VIEWER,
    };
};

export default connect(mapStateToProps)(InsightMainPageComponent);
