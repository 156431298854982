import React from 'react';
import { Document, Font, PDFDownloadLink, StyleSheet, Text, View, Image, Page } from '@react-pdf/renderer';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DownloadPDFView from '../../../components/PDF/DownloadPdfView';
import {
    Footer,
    HeaderBlock,
    Logo,
    Paragraph,
    colorStyles,
    SubHeader,
    ParagraphNoPaddingTop,
    PageLineSlim,
    SubHeaderNoMargin,
    PageLinePaddedTop,
} from '../../../components/PDF/PdfConstants';
import { dateFormats, sensorUnits } from '../../../constants';
import DemiBold from '../../../fonts/open-sans-v28-latin-600.ttf';
import RegularFont from '../../../fonts/open-sans-v28-latin-regular.ttf';
import AirthingsLogo from '../../../img/logos/Airthings_Logo_Standard.png';
import { HomeRadonValues, PDFContext } from '../../../models/commonTypeScript';
import BuildingDetails from './BuildingDetails';
import { countryInfoLinks, whoRadonInfoLink } from './HomeReportCommons';
import RadonLevel from './RadonValue';
import ReportDetails from './ReportDetails';

Font.register({ family: 'DemiBold', src: DemiBold });
Font.register({ family: 'Regular', src: RegularFont });

const styles = StyleSheet.create({
    headerText: {
        fontSize: 25,
        fontFamily: 'DemiBold',
    },
    bold: {
        fontFamily: 'DemiBold',
    },
    pageNumbers: {
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    logoContainer: {
        height: '12vw',
        marginTop: -5,
        marginRight: '4vw',
        width: '20vw',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    smallLogo: {
        width: '10vw',
        height: '7vw',
    },
    body: {
        paddingTop: '5vw',
        paddingBottom: '80',
        paddingRight: '5vw',
        paddingLeft: '5vw',
        fontFamily: 'Regular',
    },
});

interface CommonProps {
    name: string;
    address: string;
    buildingType: string;
    buildingYear: number;
    roomType: string;
    floor: string;
    requiredMeasurementPeriodMonths: number;
    daysMeasured: number;
    serialNumber: string;
    radonValues: HomeRadonValues;
    dateFormat: keyof typeof dateFormats;
    reportId: string;
}

type GeneratePdfProps = {
    endDate: string;
    countryCode: string;
};

type Props = CommonProps & GeneratePdfProps;

interface PdfProperties extends CommonProps {
    reportPeriodHeader: string;
    txt: PDFContext;
    mdaValue: string;
    radonComment: string;
}

const Body = ({ children }: { children: React.ReactElement | React.ReactElement[] }): React.ReactElement => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Page size="A4" style={styles.body}>
        {children}
    </Page>
);

// Create Document Component
const PdfDocument = React.memo(
    ({
        reportPeriodHeader,
        txt,
        name,
        address,
        buildingType,
        buildingYear,
        roomType,
        floor,
        daysMeasured,
        serialNumber,
        radonValues,
        reportId,
        dateFormat,
        requiredMeasurementPeriodMonths,
        radonComment,
        mdaValue,
    }: PdfProperties) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Document>
            <Body>
                <HeaderBlock>
                    <View>
                        <Text style={styles.headerText}>{txt('CorentiumHomeRadonReport.PageTitle')}</Text>
                        <Paragraph>{reportPeriodHeader}</Paragraph>
                    </View>
                    <View style={styles.logoContainer}>
                        <Logo src={AirthingsLogo} />
                    </View>
                </HeaderBlock>
                <BuildingDetails
                    txt={txt}
                    address={address}
                    buildingType={buildingType}
                    buildingYear={buildingYear}
                    roomType={roomType}
                    floor={floor}
                    radonValue={radonValues}
                    daysMeasured={daysMeasured}
                />
                <PageLinePaddedTop />
                <View>
                    <Paragraph>{txt('RadonInsight.RadonDescription')}</Paragraph>
                </View>
                <SubHeader>
                    {radonValues.estimatedAnnualAverage
                        ? txt('CorentiumHomeRadonReport.EstimatedAnnualAverage')
                        : txt('CorentiumHomeRadonReport.RadonLevel')}
                </SubHeader>
                <RadonLevel report={radonValues} />
                <SubHeaderNoMargin>{txt('Comment')}</SubHeaderNoMargin>
                <ParagraphNoPaddingTop>{radonComment}</ParagraphNoPaddingTop>
                <PageLineSlim />
                <ReportDetails
                    name={name}
                    dateFormat={dateFormat}
                    txt={txt}
                    reportId={reportId}
                    serialNumber={serialNumber}
                    radonValues={radonValues}
                    requiredMeasurementPeriodMonths={requiredMeasurementPeriodMonths}
                    mdaValue={mdaValue}
                />
                <Footer fixed>
                    <Text
                        style={colorStyles.smallText}
                        render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }): string =>
                            `${pageNumber} / ${totalPages}`
                        }
                        fixed
                    />
                    <Image style={styles.smallLogo} src={AirthingsLogo} />
                </Footer>
            </Body>
        </Document>
    )
);

export const GeneratePdfComponent = (props: Props): React.ReactElement => {
    const {
        daysMeasured,
        endDate,
        serialNumber,
        dateFormat,
        name,
        address,
        buildingType,
        buildingYear,
        roomType,
        radonValues,
        reportId,
        requiredMeasurementPeriodMonths,
        floor,
        countryCode,
    } = props;
    const { t: txt } = useTranslation();
    const fromDate = moment(endDate).subtract(daysMeasured, 'days').format(dateFormats[dateFormat].shortFormat);
    const endDateFormatted = moment(endDate).format(dateFormats[dateFormat].shortFormat);
    const reportPeriodHeader = endDate
        ? txt('RadonInsight.ReportGeneratedPeriod', { from: fromDate, to: endDateFormatted })
        : '';
    const radonInfoLink: string = countryInfoLinks[countryCode] || whoRadonInfoLink;

    const radonComment = (): string => {
        const measurementType = radonValues.estimatedAnnualAverage
            ? txt('CorentiumHomeRadonReport.EstimatedAnnualAverage')
            : txt('CorentiumHomeRadonReport.RadonLevel');

        if (!radonValues.estimatedAnnualAverage && !radonValues.annualAverageCorrection) {
            return txt('CorentiumHomeRadonReport.RadonValueNotCalculated', {
                measurementType,
                infoLink: radonInfoLink,
                requiredMeasurementPeriod: requiredMeasurementPeriodMonths.toString(),
            });
        }
        if (
            (radonValues.estimatedAnnualAverage && radonValues.estimatedAnnualAverage < radonValues.referenceLevel) ||
            (!radonValues.estimatedAnnualAverage && radonValues.radonLongTermAvg < radonValues.referenceLevel)
        ) {
            return txt('CorentiumHomeRadonReport.RadonValueLow', {
                measurementType,
                unit: sensorUnits[radonValues.unit],
                infoLink: radonInfoLink,
                referenceLevel: radonValues.referenceLevel.toString(),
            });
        }
        return txt('CorentiumHomeRadonReport.RadonValueHigh', {
            measurementType,
            unit: sensorUnits[radonValues.unit],
            infoLink: radonInfoLink,
            referenceLevel: radonValues.referenceLevel.toString(),
        });
    };

    const fileName = `Home-Report_${serialNumber}_${fromDate}-${endDateFormatted}.pdf`;
    const mda = 3;
    const mdaValue = `${radonValues.unit === 'bq' ? mda : (mda / 37).toFixed(2)} ${sensorUnits[radonValues.unit]}`;

    const pdfProps: PdfProperties = {
        name,
        address,
        buildingType,
        buildingYear,
        roomType,
        floor,
        daysMeasured,
        serialNumber,
        radonValues,
        dateFormat,
        requiredMeasurementPeriodMonths,
        reportPeriodHeader,
        reportId,
        mdaValue,
        txt,
        radonComment: radonComment(),
    };

    return (
        <div className="form__button-container">
            <PDFDownloadLink document={<PdfDocument {...pdfProps} />} fileName={fileName}>
                {({ loading }): React.ReactElement => <DownloadPDFView loading={loading} fileName={fileName} />}
            </PDFDownloadLink>
        </div>
    );

    // use this to get inline version of pdf.
    /* return (
        <div className="form form__wide-container form__wide-container--pdf">
            <PDFViewer>
                <PdfDocument {...pdfProps} />
            </PDFViewer>
        </div>
    ); */
};

export default GeneratePdfComponent;
