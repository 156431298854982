import { createSelector } from '@reduxjs/toolkit';
import { DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { RequestState } from 'commons/src/reducers/requestReducer';
import { BuildingHealthRowData, BuildingHealthSummary } from '../../../models/buildingHealthModels';
import { SearchLocation } from '../../../models/buildingModels';
import { Store } from '../../../reducers';

export type SelectedBuildingHealthSummary = {
    summary: BuildingHealthSummary;
    summaryRequest: RequestState;
};

export type SelectedBuildingHubsHealth = {
    hubs: BuildingHealthRowData[];
    hubsRequest: RequestState;
};

export type SelectBuildingDevicesHealth = {
    devices: BuildingHealthRowData[];
    devicesRequest: RequestState;
    totalDevices: number;
};

export type LocationsData = {
    locations: SearchLocation[];
    isLoading: boolean;
};

const selectBuildingHealthRequest = (state: Store): RequestState => state.requests.FETCH_BUILDING_HEALTH_SUMMARY;
const selectBuildingHubsHealthRequest = (state: Store): RequestState => state.requests.FETCH_BUILDING_HEALTH_HUBS_STATS;
const selectBuildingDevicesHealthRequest = (state: Store): RequestState =>
    state.requests.FETCH_BUILLDING_HEALTH_DEVICES_STATS;
const selectBuildingHealth = (state: Store, buildingId: string): BuildingHealthSummary =>
    state.buildingsHealth[buildingId];

const selectBuildingHubsHealthData = (state: Store, buildingId: string): BuildingHealthRowData[] =>
    state.buildingsHealth?.[buildingId]?.hubs ?? [];

const selectBuildingDevicesHealthData = (state: Store, buildingId: string): BuildingHealthRowData[] =>
    state.buildingsHealth?.[buildingId]?.devices ?? [];

const selectBuildingDevicesTotal = (state: Store, buildingId: string): number =>
    state.buildingsHealth?.[buildingId]?.totalDevices ?? 0;

const selectLocationNames = (state: Store): { [locationId: string]: string } =>
    state.locations.locations.reduce((acc, current) => ({ ...acc, [current.id]: current.name }), {});
const selectDevicesLoading = (state: Store): boolean => state.devices.loading;

const selectHubs = (state: Store): DeviceWithKeyInfo[] => Object.values(state.devices.hubsWithKeyInfo);
const selectDevices = (state: Store): DeviceWithKeyInfo[] => Object.values(state.devices.devicesWithKeyInfo);

export const selectedBuildingHealthSummary = createSelector(
    [selectBuildingHealth, selectBuildingHealthRequest],
    (summary, summaryRequest): SelectedBuildingHealthSummary => ({ summary, summaryRequest })
);

export const selectBuildingHubsHealth = createSelector(
    [selectBuildingHubsHealthData, selectBuildingHubsHealthRequest],
    (hubs, hubsRequest): SelectedBuildingHubsHealth => ({ hubs, hubsRequest })
);

export const selectBuildingDevicesHealth = createSelector(
    [selectBuildingDevicesHealthData, selectBuildingDevicesHealthRequest, selectBuildingDevicesTotal],
    (devices, devicesRequest, totalDevices): SelectBuildingDevicesHealth => ({ devices, devicesRequest, totalDevices })
);

export const selectSearchLocation = createSelector(
    [selectHubs, selectDevices, selectLocationNames, selectDevicesLoading],
    (hubs, devices, names, isLoading): LocationsData => {
        const formattedHubs: SearchLocation[] =
            hubs?.map(({ segmentName, serialNumber, type, locationId, spaceId }) => ({
                segmentName,
                serialNumber,
                locationId,
                type,
                locationName: names[locationId],
                spaceId,
            })) ?? [];
        const formattedDevices: SearchLocation[] =
            devices?.map(({ segmentName, serialNumber, type, locationId, spaceId }) => ({
                segmentName,
                serialNumber,
                locationId,
                type: type as string,
                locationName: names[locationId],
                spaceId,
            })) ?? [];

        return { locations: [...formattedHubs, ...formattedDevices], isLoading };
    }
);
