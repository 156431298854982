import { IndoorVsOutdoorTileContent } from '../models/commonTypeScript';
import fetch from './fetch';
import getFetchHeaders from './getFetchHeaders';

export default async (
    locationId: string,
    serialNumber: string,
    sensorType: string
): Promise<IndoorVsOutdoorTileContent> =>
    fetch<IndoorVsOutdoorTileContent>(
        `/locations/${locationId}/outdoor/comparison/${serialNumber}?sensorType=${sensorType}`,
        {
            method: 'GET',
            headers: await getFetchHeaders(),
        }
    );
