import React, { useEffect } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { sensorUnits, dateFormats } from 'commons/src/constants';
import { DayUsageHours } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';
import { generatePlotBands as generatingPlotBands, highChartsTimeZoneOffset } from '../insightFunctions';
import OutdoorGraphConfig, { DoubleGraphConfig } from './OutdoorGraphConfig';

type ParentProps = {
    sensor: string;
    updateDate: (date: number) => void;
    indoorData: number[][] | undefined;
    averageIndoorValue: number;
    outdoorData: number[][];
    unit: keyof typeof sensorUnits;
    usageHours: { [day: string]: DayUsageHours };
    fromDate: Moment;
    toDate?: Moment;
    timeZone: string;
};
type StateProps = {
    dateFormat: keyof typeof dateFormats;
};

type Props = StateProps & ParentProps;

export const OutdoorSensorGraphComponent = (props: Props): React.ReactElement => {
    const {
        sensor,
        updateDate,
        dateFormat,
        indoorData,
        averageIndoorValue,
        outdoorData,
        unit,
        usageHours,
        fromDate,
        toDate,
        timeZone,
    } = props;
    const { t: txt } = useTranslation();

    const generatePlotBands = (): { color: string; from: number; to: number }[] => {
        return generatingPlotBands(usageHours, fromDate, timeZone);
    };

    const createConfig = (): DoubleGraphConfig =>
        OutdoorGraphConfig(
            outdoorData,
            indoorData,
            { from: fromDate, to: toDate },
            txt(`OutdoorInsight.Outdoor${sensor}GraphName`),
            indoorData && indoorData.length > 0
                ? txt(`OutdoorInsight.Indoor${sensor}GraphName`)
                : txt('NotAvailableAbbreviation'),
            dateFormat,
            updateDate,
            averageIndoorValue,
            sensorUnits[unit],
            generatePlotBands(),
            sensor,
            timeZone
        );

    const graphConfig = createConfig();

    useEffect((): void => {
        highChartsTimeZoneOffset(timeZone);
    }, []);

    return <HighchartsReact highcharts={Highcharts} options={graphConfig} />;
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = state;
    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(OutdoorSensorGraphComponent);
