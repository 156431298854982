import React from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import PageWrapper from 'commons/src/components/containers/PageWrapper';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { RequiredRoleLevel, Role } from 'commons/src/models/commonEnums';
import SpacesIllustration from '../../../img/spaces-illustration';
import styles from './EmptyBuilding.module.scss';

type Props = {
    userRole?: Role;
    requiredRoleLevel: RequiredRoleLevel;
    onClick: () => void;
};

const EmptyBuilding = ({ userRole, requiredRoleLevel, onClick }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const accessToAddDevice: boolean = userRole ? userRoleAboveRequiredLevel(userRole, requiredRoleLevel) : false;

    return (
        <PageWrapper pageType="wide">
            <div className={styles.body}>
                <SpacesIllustration />
                <div className={styles.content}>
                    <div className={styles.text}>{txt('AddSpace.EmptyBuildingState')}</div>
                    <div>
                        {accessToAddDevice ? (
                            <PrimaryButton
                                id="AddSpaceButton"
                                onClick={onClick}
                                color="primary"
                                title="AddSpace.AddSpace"
                            />
                        ) : (
                            <p className={styles.roleText}>
                                {txt('Access.RoleDoesNotHaveAccess', { role: txt(userRole || '') })}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};

export default EmptyBuilding;
