import { BusinessActionRequestType } from 'business-dashboard/src/models/common';
import { BusinessRequestType as RequestType } from 'business-dashboard/src/reducers/BusinessRequestType';
import { RequestActionType } from 'commons/src/actions/requestActions';
import DeviceHealthStatus from 'commons/src/models/deviceHealthStatus';
import { HealthStatusIssueDataSummary, OrganizationHealthStatusSummary } from '../models/buildingModels';

export enum HealthStatusActionType {
    FetchOrganizationHealthStatusSummaryInit = 'FETCH_ORGANIZATION_HEALTH_STATUS_SUMMARY/INIT',
    FetchOrganizationHealthStatusSummarySuccess = 'FETCH_ORGANIZATION_HEALTH_STATUS_SUMMARY_SUCCESS',
    FetchHealthStatusIssueDataInit = 'FETCH_HEALTH_STATUS_ISSUE_DATA/INIT',
    FetchHealthStatusIssueDataSuccess = 'FETCH_HEALTH_STATUS_ISSUE_DATA_SUCCESS',
}
// SUMMARY
export interface FetchOrganizationHealthStatusSummary extends BusinessActionRequestType {
    type: HealthStatusActionType.FetchOrganizationHealthStatusSummaryInit;
}

export const fetchOrganizationHealthStatusSummary = (): FetchOrganizationHealthStatusSummary => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchOrganizationHealthStatusSummary,
    type: HealthStatusActionType.FetchOrganizationHealthStatusSummaryInit,
});

export interface FetchHealthStatusIssueData extends BusinessActionRequestType {
    type: HealthStatusActionType.FetchHealthStatusIssueDataInit;
    status: DeviceHealthStatus;
}

export const fetchHealthStatusIssueData = (status: DeviceHealthStatus): FetchHealthStatusIssueData => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchHealthStatusIssueData,
    type: HealthStatusActionType.FetchHealthStatusIssueDataInit,
    status,
});

// SUCCESS
export type FetchOrganizationHealthStatusSummarySuccess = {
    type: HealthStatusActionType.FetchOrganizationHealthStatusSummarySuccess;
    healthStatusSummary: OrganizationHealthStatusSummary;
};

export const fetchOrganizationHealthStatusSummarySuccess = (
    healthStatusSummary: OrganizationHealthStatusSummary
): FetchOrganizationHealthStatusSummarySuccess => ({
    type: HealthStatusActionType.FetchOrganizationHealthStatusSummarySuccess,
    healthStatusSummary,
});

export type FetchHealthStatusIssueDataSuccess = {
    type: HealthStatusActionType.FetchHealthStatusIssueDataSuccess;
    healthStatusIssueData: HealthStatusIssueDataSummary;
    status: DeviceHealthStatus;
};

export const fetchHealthStatusIssueDataSuccess = (
    healthStatusIssueData: HealthStatusIssueDataSummary,
    status: DeviceHealthStatus
): FetchHealthStatusIssueDataSuccess => ({
    type: HealthStatusActionType.FetchHealthStatusIssueDataSuccess,
    healthStatusIssueData,
    status,
});

export type HealthStatusActions =
    | FetchOrganizationHealthStatusSummary
    | FetchOrganizationHealthStatusSummarySuccess
    | FetchHealthStatusIssueData
    | FetchHealthStatusIssueDataSuccess;
