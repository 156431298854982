import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapDeviceType } from 'commons/src/commonFunctions';
import Textarea from 'commons/src/components/input/Textarea';
import { dateFormats } from 'commons/src/constants';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';

type StateProps = {
    userName: string;
    dateFormat: keyof typeof dateFormats;
};

type ParentProps = {
    updateComment?: (comment: string) => void;
    deviceTypes: string[];
    comment: string;
    reportId: string;
    disclaimer?: string;
};

type Props = ParentProps & StateProps;

const ReportFooter = (props: Props): React.ReactElement => {
    const { userName, dateFormat, reportId, updateComment, deviceTypes, comment, disclaimer } = props;

    const { t: txt } = useTranslation();
    return (
        <div className="insight__comment-footer">
            <div className="insight__comment-footer__details">
                <div className="insight__comment-footer__details__element">
                    <span className="text-bold">
                        {txt('Report.Generated')}
                        {': '}
                    </span>
                    <span>
                        {moment().format(dateFormats[dateFormat].shortFormat)}{' '}
                        {moment().format(dateFormats[dateFormat].hourFormat)}
                    </span>
                </div>
                <div className="insight__comment-footer__details__element">
                    <span className="text-bold">
                        {txt('Report.MeasurementBy')}
                        {': '}
                    </span>
                    <span>{userName}</span>
                </div>
                <div className="insight__comment-footer__details__element">
                    <span className="text-bold">
                        {txt('Report.ReportId')}
                        {': '}
                    </span>
                    <span>{reportId}</span>
                </div>
                <div className="insight__comment-footer__details__element">
                    <span className="text-bold">
                        {txt('Insight.DeviceTypesInMeasurement')}
                        {': '}
                    </span>
                    {deviceTypes.map((type, i) => (
                        <span key={`device-${type}`}>
                            {`Airthings ${txt(`${mapDeviceType(type as AnyDeviceType)}FullName`)}${
                                deviceTypes.length > 1 && i < deviceTypes.length - 1 ? ', ' : ''
                            }`}
                        </span>
                    ))}
                </div>
                {disclaimer && (
                    <div className="insight__comment-footer__details__element">
                        <span className="text-bold">
                            {txt('Report.Disclaimer')}
                            {': '}
                        </span>
                        <span>{txt(disclaimer)}</span>
                    </div>
                )}
            </div>
            {!!updateComment && (
                <div className="insight__comment-footer__comment">
                    <Textarea
                        label="Insight.Comment"
                        id="ReportComments"
                        required={false}
                        defaultValue={comment}
                        displayValidationResult={false}
                        isValid
                        onChange={updateComment}
                        markedMandatory={false}
                        maxLength={1000}
                        rows={12}
                        maxRows={12}
                    />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { userName, dateFormat },
    } = state;
    return {
        userName,
        dateFormat,
    };
};

export default connect(mapStateToProps)(ReportFooter);
