import React from 'react';
import { Link } from 'react-router-dom';

import styles from './DeviceName.module.scss';

type DeviceNameProps = {
    name: string;
    serialNumber: string;
    spaceId?: string;
    locationId: string;
};

const DeviceName = ({ name, serialNumber, spaceId, locationId }: DeviceNameProps): React.ReactElement => {
    const linkToSpaces = `/buildings/${locationId}/spaces`;
    const to = spaceId ? `${linkToSpaces}/${spaceId}` : linkToSpaces;
    return (
        <div className={styles.deviceNameWrapper}>
            <Link to={to} className={styles.deviceName} target="_blank" rel="noopener noreferrer" title={name}>
                {name}
            </Link>
            {serialNumber && <sub className={styles.deviceSerialNumber}>{serialNumber}</sub>}
        </div>
    );
};

export default DeviceName;
