import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import Input from 'commons/src/components/input/Input';
import { KeyValuePairType } from '../../../models/common';

type Props = {
    id: string;
    keyHeader: string;
    keyHint: string;
    valueHeader: string;
    valueHint: string;
    onChange: (pair: KeyValuePairType) => void;
    pair: KeyValuePairType;
    showValidation: boolean;
    maxValueLength: number;
    maxKeyLength: number;
    removeRow: () => void;
    displayRemoveButton: boolean;
    hasHttpHeaderKey?: boolean;
};

export const isValidKey = (updatedKey: string, isHeaderKey?: boolean): boolean =>
    updatedKey.length > 0 &&
    updatedKey.indexOf(' ') === -1 &&
    (isHeaderKey === true ? !updatedKey.includes('_') : true);

export const MapInputRowComponent = (props: Props): React.ReactElement => {
    const {
        id,
        keyHeader,
        keyHint,
        valueHeader,
        valueHint,
        onChange,
        pair,
        showValidation,
        maxValueLength,
        maxKeyLength,
        displayRemoveButton,
        removeRow,
        hasHttpHeaderKey,
    } = props;
    const { t: txt } = useTranslation();

    const deleteRow = (e: SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        removeRow();
    };

    const updatePair = (e: SyntheticEvent<HTMLInputElement>): void => {
        e.preventDefault();
        const htmlId = e.currentTarget.id;
        const updatedInput = e.currentTarget.value;
        let updatedPair: KeyValuePairType = { key: pair.key, value: pair.value };
        // two input fields separated by id, one for key one for value.
        if (htmlId.includes('-key')) {
            updatedPair = { key: updatedInput.trim(), value: pair.value };
        } else if (htmlId.includes('-value')) {
            updatedPair = { key: pair.key, value: updatedInput };
        }
        onChange(updatedPair);
    };

    return (
        <div className="form__row" key={id}>
            <div className="form__field form__field--half">
                <Input
                    type="text"
                    id={`${id}-key`}
                    label={keyHeader}
                    required
                    maxLength={maxKeyLength}
                    hint={txt(keyHint)}
                    isValid={isValidKey(pair.key, hasHttpHeaderKey)}
                    validate={showValidation && !isValidKey(pair.key, hasHttpHeaderKey)}
                    onChange={updatePair}
                    currentValue={pair.key}
                    testAttr={`${id.toLowerCase()}-value`}
                    testId={`${id.toLowerCase()}-key`}
                />
            </div>
            <div className="form__field form__field--half">
                <Input
                    type="text"
                    id={`${id}-value`}
                    label={valueHeader}
                    validate={false}
                    required
                    maxLength={maxValueLength}
                    infoText={txt(valueHint)}
                    onChange={updatePair}
                    currentValue={pair.value}
                    testAttr={`${id.toLowerCase()}-value`}
                    testId={`${id.toLowerCase()}-value`}
                />
            </div>
            <div className="form__row form__row--undertext">
                {displayRemoveButton && (
                    <TertiaryButton
                        onClick={deleteRow}
                        title="Webhooks.RemovePair"
                        testAttr="remove-pair"
                        disabled={false}
                        testId="remove-pair"
                    />
                )}
            </div>
        </div>
    );
};

export default MapInputRowComponent;
