import React, { KeyboardEvent, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { mapDeviceType } from 'commons/src/commonFunctions';
import CheckBox from 'commons/src/components/input/Checkbox';
import { AnyDeviceType, DeviceWithKeyInfo, SegmentProperties } from 'commons/src/models/commonTypeScript';
import DeviceRequiredPropsSection from './DeviceRequiredPropsSection';

export type Props = {
    device: DeviceWithKeyInfo;
    segmentProperties: SegmentProperties;
    removeDeviceFromList: (e: SyntheticEvent<HTMLInputElement> | KeyboardEvent<HTMLButtonElement>) => void;
    slimView?: boolean;
    loading: boolean;
};

export const DeviceSelectorWithDetailsComponent = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { removeDeviceFromList, device, segmentProperties, slimView, loading } = props;

    const deviceDetails = (): React.ReactElement => (
        <div className="flex">
            <DeviceRequiredPropsSection device={device} segmentProps={segmentProperties} loading={loading} />
        </div>
    );

    return (
        <div className="form__row flex--spaced form__row__selected-device">
            <div className="flex flex--spaced form__row__selected-device">
                <div className="form__field--padded-small">
                    {device.segmentName}
                    <div className="tile-header__info--chip">{txt(mapDeviceType(device.type as AnyDeviceType))}</div>
                </div>
                {!slimView && <div className="form__row__selected-device__content--wide">{deviceDetails()}</div>}
                <div className="form__button-container form__button-container--float-top flex--align-center">
                    <CheckBox
                        indeterminate
                        label=""
                        id={device.serialNumber}
                        onChange={removeDeviceFromList}
                        checked
                        color="warning"
                    />
                </div>
            </div>
            <div className={classNames({ 'form__row__selected-device__content--slim': !slimView })}>
                {deviceDetails()}
            </div>
        </div>
    );
};

export default DeviceSelectorWithDetailsComponent;
