const generateImageFromSvg = (
    width: number,
    height: number,
    svg: string,
    callback: (response: { data: string; serialNumber: string }) => void,
    serialNumber: string
): void => {
    // SVG data URL from SVG string
    const svgData = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svg)))}`;
    // create canvas in memory(not in DOM)
    const canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    const context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    const image = new Image();
    // later when image loads run this
    image.onload = (): void => {
        // async (happens later)
        // clear canvas
        if (context) {
            context.clearRect(0, 0, width, height);
            // draw image with SVG data to canvas
            context.drawImage(image, 0, 0, width, height);
        }
        // snapshot canvas as png
        const pngData = canvas.toDataURL('image/png');
        // pass png data URL to callback
        callback({ data: pngData, serialNumber });
    }; // end async
    // start loading SVG data into in memory image
    image.src = svgData;
};

export default generateImageFromSvg;
