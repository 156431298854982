import React from 'react';
import L, { DivIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import BatteryIcon from 'commons/src/components/device/BatteryIcon';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import RssiSignalStrength from 'commons/src/components/sensors/RssiSignalStrength';
import styles from './MarkerIcons.module.scss';

const MarkerIcons = {
    SensorValue: (value: number | undefined): DivIcon =>
        new L.DivIcon({
            html: `${value ?? '-'}`,
            className: styles.marker,
            iconAnchor: [15, 15],
        }),
    Battery: (value: number | undefined): DivIcon =>
        new L.DivIcon({
            html: renderToStaticMarkup(<BatteryIcon batteryPercentage={value} />),
            className: styles.marker,
            iconAnchor: [15, 15],
        }),
    Rssi: (value: number | undefined): DivIcon =>
        new L.DivIcon({
            html: renderToStaticMarkup(<RssiSignalStrength inverted={false} rssi={value} displayDescription={false} />),
            className: styles.marker,
            iconAnchor: [15, 15],
        }),
    Offline: new L.DivIcon({
        html: renderToStaticMarkup(
            <MaterialIcon name="signal_cellular_connected_no_internet_4_bar" extraClass={styles.offlineMarker} />
        ),
        className: styles.marker,
        iconAnchor: [15, 15],
    }),
};
export default MarkerIcons;
