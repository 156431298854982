import React from 'react';

const WaveCo2Icon = (): React.ReactElement => (
    <svg
        version="1.1"
        className="device-svg-icons"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
    >
        <path
            d="M24,2c12.1,0,22,9.9,22,22s-9.9,22-22,22S2,36.1,2,24S11.9,2,24,2z M24,4C13,4,4,13,4,24
		s9,20,20,20s20-9,20-20S35,4,24,4z M15.5,25.9c0.7,0,1.4-0.1,1.9-0.4s1-0.6,1.4-1.1l0,0l-1.2-0.9c-0.2,0.3-0.5,0.5-0.9,0.7
		s-0.8,0.3-1.2,0.3c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.7-0.4-0.9-0.6c-0.3-0.3-0.4-0.6-0.6-1c-0.1-0.4-0.2-0.8-0.2-1.3
		c0-0.4,0.1-0.8,0.2-1.2c0.1-0.4,0.3-0.7,0.6-1c0.3-0.3,0.6-0.5,0.9-0.7s0.8-0.2,1.2-0.2c0.3,0,0.7,0.1,1.1,0.2
		c0.4,0.1,0.7,0.4,0.9,0.7l0,0l1.2-0.9c-0.2-0.2-0.4-0.4-0.6-0.6s-0.5-0.3-0.8-0.4c-0.3-0.1-0.6-0.2-0.9-0.2
		C16.2,17,15.9,17,15.6,17c-0.7,0-1.3,0.1-1.8,0.3c-0.6,0.2-1,0.5-1.5,0.9c-0.4,0.4-0.7,0.8-1,1.4S11,20.8,11,21.4
		c0,0.7,0.1,1.3,0.3,1.8s0.5,1,0.9,1.4c0.4,0.4,0.9,0.7,1.4,0.9C14.3,25.8,14.9,25.9,15.5,25.9z M24.1,25.9c0.7,0,1.3-0.1,1.8-0.3
		c0.6-0.2,1-0.5,1.5-0.9c0.4-0.4,0.7-0.9,1-1.4s0.3-1.2,0.3-1.8c0-0.7-0.1-1.3-0.3-1.8s-0.5-1-1-1.4c-0.4-0.4-0.9-0.7-1.5-0.9
		S24.8,17,24.1,17c-0.7,0-1.3,0.1-1.8,0.3s-1,0.5-1.4,0.9c-0.4,0.4-0.7,0.8-0.9,1.4s-0.3,1.2-0.3,1.8c0,0.7,0.1,1.3,0.3,1.8
		s0.5,1,0.9,1.4s0.9,0.7,1.4,0.9C22.8,25.8,23.4,25.9,24.1,25.9z M24.1,24.6c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.7-0.4-0.9-0.7
		s-0.5-0.6-0.6-1c-0.1-0.4-0.2-0.8-0.2-1.3c0-0.4,0.1-0.8,0.2-1.2c0.1-0.4,0.3-0.7,0.6-1c0.3-0.3,0.6-0.5,0.9-0.7
		c0.4-0.2,0.8-0.2,1.2-0.2c0.4,0,0.8,0.1,1.2,0.2c0.4,0.2,0.7,0.4,0.9,0.7c0.3,0.3,0.5,0.6,0.6,1C27,20.6,27,21,27,21.4
		c0,0.5-0.1,0.9-0.2,1.3c-0.1,0.4-0.3,0.7-0.6,1s-0.6,0.5-0.9,0.7C24.9,24.5,24.5,24.6,24.1,24.6z M36,31.7v-1.3h-3.8l2.4-2.3
		c0.2-0.2,0.4-0.4,0.5-0.5c0.2-0.2,0.3-0.4,0.4-0.6s0.2-0.4,0.3-0.7C36,26,36,25.8,36,25.5c0-0.4-0.1-0.8-0.2-1.1
		c-0.2-0.3-0.4-0.6-0.6-0.8s-0.6-0.4-0.9-0.5S33.5,23,33.2,23c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0.3-0.9,0.5
		c-0.2,0.2-0.5,0.5-0.6,0.8c-0.2,0.3-0.3,0.6-0.3,1l0,0l1.4,0.2c0.1-0.4,0.2-0.8,0.5-1c0.2-0.3,0.6-0.4,1-0.4c0.4,0,0.7,0.1,1,0.4
		c0.3,0.2,0.4,0.6,0.4,1c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.3-0.4,0.5-0.6,0.8l0,0l-3.3,3.2v1.3H36z"
        />
    </svg>
);

export default WaveCo2Icon;
