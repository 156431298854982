import React from 'react';

const HubIcon = (): React.ReactElement => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
        <defs>
            <path
                d="M28.5833333,0.416666667 C30.2401876,0.416666667 31.5833333,1.75981242 31.5833333,3.41666667
                L31.5833333,28.5833333 C31.5833333,30.2401876 30.2401876,31.5833333 28.5833333,31.5833333
                L3.41666667,31.5833333 C1.75981242,31.5833333 0.416666667,30.2401876 0.416666667,28.5833333
                L0.416666667,3.41666667 C0.416666667,1.75981242 1.75981242,0.416666667 3.41666667,0.416666667
                L28.5833333,0.416666667 Z M27.75,3.25 L4.25,3.25 C3.69771525,3.25 3.25,3.69771525 3.25,4.25 L3.25,4.25
                L3.25,27.75 C3.25,28.3022847 3.69771525,28.75 4.25,28.75 L4.25,28.75 L27.75,28.75 C28.3022847,28.75 28.75,28.3022847
                28.75,27.75 L28.75,27.75 L28.75,4.25 C28.75,3.69771525 28.3022847,3.25 27.75,3.25 L27.75,3.25 Z M16.0001562,7.5
                C17.2682512,7.5 18.2985784,8.5276305 18.2985784,9.79240651 C18.2985784,10.8595613 17.5535726,11.7607142
                16.5549478,12.0057645 L16.5549478,12.0057645 L16.5549478,15.1597997 L17.6566053,15.1597997 C18.6869325,15.1597997
                19.5270455,15.9977138 19.5270455,17.0253443 L19.5270455,17.0253443 L19.5270455,20.0212824 L21.9998308,21.4994894
                C22.7210598,20.7722432 23.8781965,20.5983365 24.7975654,21.151676 C25.8912974,21.8077786 26.2400235,23.2148419
                25.5901248,24.2978063 C24.9323005,25.3886756 23.5215448,25.736489 22.4357385,25.0882913 C21.5163696,24.5349518
                21.1280155,23.4361777 21.429188,22.4559763 L21.429188,22.4559763 L19.3130545,21.1912003 C19.0039563,21.7919689
                18.3778344,22.2109259 17.6566053,22.2109259 L17.6566053,22.2109259 L14.3357815,22.2109259 C13.6145525,22.2109259
                12.9884305,21.7919689 12.6793324,21.1912003 L12.6793324,21.1912003 L10.5711244,22.4559763 C10.872297,23.4440825
                10.4839429,24.5349518 9.56457397,25.0882913 C8.47084202,25.736489 7.0600863,25.3807708 6.41018761,24.2978063
                C5.76028891,23.2148419 6.10901504,21.8077786 7.1948214,21.1595809 C8.12211589,20.6062414 9.271327,20.7801481
                10.0084072,21.5152991 L10.0084072,21.5152991 L12.4732669,20.0370921 L12.4732669,17.041154 C12.4732669,16.0135235
                13.3133799,15.1756094 14.3437071,15.1756094 L14.3437071,15.1756094 L15.4453646,15.1756094 L15.4453646,12.0057645
                C14.4467398,11.7607142 13.701734,10.8674661 13.701734,9.79240651 C13.701734,8.5276305 14.7320612,7.5 16.0001562,7.5
                Z M17.624903,16.4008611 L14.3833351,16.4008611 C14.0504601,16.4008611 13.7809899,16.669626 13.7809899,17.0016297
                L13.7809899,17.0016297 L13.7809899,20.2347134 C13.7809899,20.5667171 14.0504601,20.835482 14.3833351,20.835482
                L14.3833351,20.835482 L17.624903,20.835482 C17.9577779,20.835482 18.2272481,20.5667171 18.2272481,20.2347134
                L18.2272481,20.2347134 L18.2272481,17.0016297 C18.2272481,16.669626 17.9577779,16.4008611
                17.624903,16.4008611 L17.624903,16.4008611 Z"
                id="path-1"
            />
        </defs>
        <g id="HBS-Dashboard" stroke="none">
            <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
            </mask>
            <g id="↳-Color" mask="url(#mask-2)">
                <g transform="translate(-1.000000, -1.000000)" id="Rectangle-13">
                    <rect x="0" y="0" width="34" height="34" />
                </g>
            </g>
        </g>
    </svg>
);

export default HubIcon;
