import React from 'react';
import { Units } from '../../models/commonTypeScript';
import InputLabel from '../input/InputLabel';
import RadioButtons from '../input/Radio';
import { getUnitPreferenceSelectors, userSettingsSelectors } from './UserPreferenceOptions';

type Props = {
    sensorUnits: Units;
    setSensorUnits: (units: Units) => void;
};

const UnitSelector = (props: Props): React.ReactElement => {
    const { sensorUnits, setSensorUnits } = props;

    const updatePreferences = (e: React.SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        const selectorName = e.currentTarget.name;
        if (selectorName === userSettingsSelectors.radonUnit) setSensorUnits({ ...sensorUnits, radonUnit: value });
        if (selectorName === userSettingsSelectors.tempUnit) setSensorUnits({ ...sensorUnits, tempUnit: value });
        if (selectorName === userSettingsSelectors.pressureUnit)
            setSensorUnits({ ...sensorUnits, pressureUnit: value });
        if (selectorName === userSettingsSelectors.vocUnit) setSensorUnits({ ...sensorUnits, vocUnit: value });
    };

    const settingsRow = (
        settingId: string,
        options: { label: string; value: string }[],
        header: string,
        selectedValue: string
    ): React.ReactElement => {
        const optionsWithCustomStyle = options.map(opt => ({
            ...opt,
            customStyle: 'modal__content__radio modal__content__radio--slim',
        }));
        return (
            <div key={settingId}>
                <RadioButtons
                    buttons={optionsWithCustomStyle}
                    selectorName={settingId}
                    row
                    header={header}
                    onChange={updatePreferences}
                    value={selectedValue}
                    labelId={`user-settings-${settingId}`}
                />
            </div>
        );
    };

    return (
        <div>
            <InputLabel htmlFor="unit-settings" label="UnitSettings" />
            {getUnitPreferenceSelectors(
                settingsRow,
                sensorUnits.radonUnit,
                sensorUnits.tempUnit,
                sensorUnits.pressureUnit,
                sensorUnits.vocUnit
            )}
        </div>
    );
};

export default UnitSelector;
