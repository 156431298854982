import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { useNavigate } from 'react-router-dom';
import PageHeader from 'commons/src/components/headers/PageHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats } from 'commons/src/constants';
import { GroupType } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { getCostPreview, getProductRatePlans } from '../../../../api/subscriptionApi';
import { ServicePeriod } from '../../../../models/commonEnums';
import {
    EstimatedSubscription,
    RatePlansPerPeriod,
    SubscriptionInfo,
    SubscriptionSeats,
    SubscriptionsOrderRequest,
} from '../../../../models/subscriptionModels';
import servicePeriodTranslation from '../commonSubscriptionFunctions';
import CostPreview from './CostPreview';
import ReviewAndConfirmSubscription from './ReviewAndConfirmSubscription';
import SetupSubscriptionView from './SetupSubscriptionView';
import styles from './StartSubscription.module.scss';
import { getRatePlansForSelection, getSeatsPerDevice, getTermPeriod } from './subscriptionFunctions';
import SummarySubscription from './SummarySubscription';

export type ParentProps = {
    loading: boolean;
    dateFormat: keyof typeof dateFormats;
    childUserGroupId?: string;
    selectedGroupType?: GroupType;
    userAllowedToManageSubscription: boolean;
    deviceCounts?: { [device: string]: number };
    subscriptionStartDate?: string;
};

export const StartSubscriptionComponent = ({
    loading,
    dateFormat,
    childUserGroupId,
    selectedGroupType,
    userAllowedToManageSubscription,
    deviceCounts,
    subscriptionStartDate,
}: ParentProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();

    if (!(userAllowedToManageSubscription || loading)) {
        navigate(-1);
    }

    useEffect(() => {
        document.body.className = 'blue-body';
        return (): void => {
            document.body.className = '';
        };
    }, []);

    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [stepNumber, setStepNumber] = useState(1);
    const [separateInvoice, setSeparateInvoice] = useState(false);
    const [ratePlans, setRatePlans] = useState<RatePlansPerPeriod | undefined>(undefined);
    const [ratePlansError, setRatePlansError] = useState<ErrorType | undefined>(undefined);
    const [ratePlansLoading, setRatePlansLoading] = useState(false);
    const [costPreview, setCostPreview] = useState<EstimatedSubscription | undefined>(undefined);
    const [costPreviewError, setCostPreviewError] = useState<ErrorType | undefined>(undefined);
    const [costPreviewLoading, setCostPreviewLoading] = useState(false);
    const [seatsPerDevice, setSeatsPerDevice] = useState<SubscriptionSeats[]>([]);
    const [servicePeriod, setServicePeriod] = useState(ServicePeriod.ThreeYears);
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');
    const [startDate, setStartDate] = useState(subscriptionStartDate ?? moment().format('YYYY-MM-DD'));

    const getRatePlans = useCallback(async () => {
        setRatePlansLoading(true);
        const response = await getProductRatePlans().catch(err => {
            setRatePlansError(err);
            setRatePlansLoading(false);
        });
        if (response) {
            setRatePlans(response.plans);
            setRatePlansLoading(false);
        }
    }, []);

    useEffect(() => {
        getRatePlans().catch(err => {
            setRatePlansLoading(false);
            setRatePlansError(err);
        });
    }, []);

    const getCost = useCallback(async (groupType: GroupType, groupId?: string, payload?: SubscriptionsOrderRequest) => {
        setCostPreviewLoading(true);
        if (payload) {
            const response = await getCostPreview(payload, groupType, groupId).catch((err: ErrorType) => {
                setCostPreviewError(err);
            });
            if (response) {
                setCostPreview(response);
            }
        }
        setCostPreviewLoading(false);
    }, []);

    const subscriptionPayload = (): SubscriptionsOrderRequest | undefined => {
        if (ratePlans) {
            return {
                startDate,
                purchaseOrderNumber,
                termPeriod: getTermPeriod(servicePeriod),
                productRatePlans: getRatePlansForSelection(ratePlans, servicePeriod, seatsPerDevice),
                invoiceSeparately: separateInvoice,
            };
        }
        return undefined;
    };

    const subscriptionInfo: SubscriptionInfo = {
        startDate,
        purchaseOrderNumber,
        servicePeriodText: servicePeriodTranslation(
            servicePeriod,
            txt(`Subscription.${servicePeriod}`),
            txt('Subscription.Discount'),
            ratePlans
        ),
        invoiceSeparately: separateInvoice,
    };

    const fetchLatestCostPreview = (
        groupType: GroupType,
        newServicePeriod: ServicePeriod,
        updatedSeats: SubscriptionSeats[]
    ): void => {
        if (updatedSeats.reduce((count, device) => count + device.seats, 0) > 0) {
            const productRatePlans = ratePlans
                ? getRatePlansForSelection(ratePlans, newServicePeriod, updatedSeats)
                : [];
            const payload = {
                startDate,
                purchaseOrderNumber,
                termPeriod: getTermPeriod(newServicePeriod),
                productRatePlans,
                invoiceSeparately: separateInvoice,
            };
            getCost(groupType, childUserGroupId, payload).catch(err => {
                setCostPreviewError(err);
            });
        }
    };

    useEffect((): void => {
        if (ratePlans && deviceCounts) {
            const updatedSeatsPerDevice = getSeatsPerDevice(deviceCounts || {}, ratePlans[servicePeriod].products);
            setSeatsPerDevice(updatedSeatsPerDevice);
        }
    }, [ratePlans, deviceCounts]);

    const updateSeats = (newServicePeriod: ServicePeriod, newSeats: SubscriptionSeats[]): void => {
        setSeatsPerDevice(newSeats);
        if (timer) {
            clearTimeout(timer);
        }
        const timerRef = setTimeout(() => {
            if (selectedGroupType != null) {
                fetchLatestCostPreview(selectedGroupType, newServicePeriod, newSeats);
            }
        }, 1000);
        setTimer(timerRef);
    };

    const updateServicePeriod = (newServicePeriod: ServicePeriod): void => {
        setServicePeriod(newServicePeriod);
        if (selectedGroupType != null) {
            fetchLatestCostPreview(selectedGroupType, newServicePeriod, seatsPerDevice);
        }
    };

    const toggleSeparateInvoice = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { checked } = e.currentTarget;
        setSeparateInvoice(checked);
    };

    const previewCostTable: React.ReactElement = (
        <CostPreview costPreview={costPreview} loading={costPreviewLoading} error={costPreviewError} />
    );

    if (stepNumber === 2) {
        return (
            <>
                <PageHeader title={txt('Subscription.StartSubscription')} />
                {ratePlansError ? (
                    <ResponseBox text={`ErrorCodes.${ratePlansError.error}`} />
                ) : (
                    <ReviewAndConfirmSubscription
                        setStepNumber={setStepNumber}
                        previewCostTable={previewCostTable}
                        newSubscription={subscriptionPayload()}
                        subscriptionInfo={subscriptionInfo}
                        childUserGroupId={childUserGroupId}
                        dateFormat={dateFormat}
                        groupType={selectedGroupType}
                        userAllowedToManageSubscription={userAllowedToManageSubscription}
                    />
                )}
            </>
        );
    }

    if (stepNumber === 3) {
        return (
            <>
                <PageHeader title={txt('Subscription.StartSubscription')} />
                {ratePlansError ? (
                    <ResponseBox text={`ErrorCodes.${ratePlansError.error}`} />
                ) : (
                    <SummarySubscription
                        previewCostTable={previewCostTable}
                        subscriptionInfo={subscriptionInfo}
                        dateFormat={dateFormat}
                        groupType={selectedGroupType}
                    />
                )}
            </>
        );
    }

    return (
        <>
            <PageHeader title={txt('Subscription.StartSubscription')} />
            <ReactPlaceholder ready={!loading && !ratePlansLoading} customPlaceholder={mediumFormLoader}>
                {ratePlansError ? (
                    <div className={styles.errorWrapper}>
                        <ResponseBox text={`ErrorCodes.${ratePlansError.error}`} />
                    </div>
                ) : (
                    <SetupSubscriptionView
                        seatsPerDevice={seatsPerDevice}
                        toggleSeparateInvoice={toggleSeparateInvoice}
                        separateInvoice={separateInvoice}
                        setSeatsPerDevice={updateSeats}
                        childUserGroupId={childUserGroupId}
                        previewCostTable={previewCostTable}
                        setStepNumber={setStepNumber}
                        servicePeriod={servicePeriod}
                        setServicePeriod={updateServicePeriod}
                        purchaseOrderNumber={purchaseOrderNumber}
                        setPurchaseOrderNumber={setPurchaseOrderNumber}
                        ratePlans={ratePlans}
                        groupType={selectedGroupType}
                        dateFormat={dateFormat}
                        startDate={startDate}
                        setStartDate={setStartDate}
                    />
                )}
            </ReactPlaceholder>
        </>
    );
};

export default StartSubscriptionComponent;
