import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';

type Props = {
    sectionNumber: string;
    name: string;
    description: string;
    openClose?: (e: SyntheticEvent<HTMLButtonElement> | SyntheticEvent<HTMLDivElement>) => void;
    headerAsButton: boolean;
    headerClosed: boolean;
    headerInactive: boolean;
    sectionId: string;
};

const WizardSectionHeader = (props: Props): React.ReactElement => {
    const { sectionNumber, name, description, openClose, headerAsButton, headerClosed, headerInactive, sectionId } =
        props;
    return (
        <div
            tabIndex={0}
            role="button"
            id={sectionId}
            onKeyUp={openClose}
            onClick={openClose}
            className={classNames('wizard__header', {
                'wizard__header--button': headerAsButton,
                'wizard__header--close': headerClosed,
            })}
        >
            <h2 className={classNames('wizard__header__text', { 'wizard__header__text--inactive': headerInactive })}>
                {sectionNumber}
                {name}
            </h2>
            <span className="wizard__header__text wizard__header__text--summary">{description}</span>
        </div>
    );
};

export default WizardSectionHeader;
