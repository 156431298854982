import React from 'react';
import { CssVarsProvider as JoyCssVarsProvider, THEME_ID as JOY_THEME_ID } from '@mui/joy/styles';
import { ThemeProvider, THEME_ID as MATERIAL_THEME_ID } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as UnstableHistoryRouter } from 'react-router-dom';

// Import components
import ChatWidget from 'commons/src/components/banners/ChatWidget';
import TopCookieBanner from 'commons/src/components/banners/CookieBanner';
import DemoBanner from 'commons/src/components/banners/DemoBanner';
import SupportBanner from 'commons/src/components/banners/SupportBanner';
import NotificationBox from 'commons/src/components/responseMessages/NotificationBox';
import ScrollToTop from 'commons/src/components/ScrollToTop';
import HbsOnboardingModal from 'commons/src/features/HbsOnboarding/HbsOnboardingModal';
import IntlWrapper from 'commons/src/i18n/IntlWrapper';

// Import constants
import history from 'commons/src/store/history';
import { theme, joyTheme } from 'commons/src/styleTheme';
import Navigation from './features/navigation/Navigation';
import RouteList, { publicRoutes } from './routes/RouteList';

// Import store
import store from './store';

// Import styles
import 'commons/src/polyfills';
import './styles/index.scss';

/*
    Flagged unstable for UnstableHistoryRouter because it's not compatible with react@18.
    Possibly will be fixed in next patch release.
    https://github.com/remix-run/react-router/releases/tag/v6.1.1
*/

// Ts error ignored below. Read more: https://github.com/remix-run/react-router/issues/9630
const BusinessDashboard = (): React.ReactElement => (
    <Provider store={store}>
        <IntlWrapper>
            <ThemeProvider theme={{ [MATERIAL_THEME_ID]: theme }}>
                <JoyCssVarsProvider theme={{ [JOY_THEME_ID]: joyTheme }}>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <UnstableHistoryRouter history={history}>
                        <TopCookieBanner />
                        <DemoBanner />
                        <SupportBanner />
                        <NotificationBox />
                        <Navigation publicPaths={publicRoutes.map(route => route.path)} />
                        <ChatWidget />
                        <HbsOnboardingModal />
                        <ScrollToTop>
                            <RouteList />
                        </ScrollToTop>
                    </UnstableHistoryRouter>
                </JoyCssVarsProvider>
            </ThemeProvider>
        </IntlWrapper>
    </Provider>
);

export default BusinessDashboard;
