import React, { useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceHolder from 'react-placeholder';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { userlane } from 'commons/src/components/placeholder';
import { dateFormats } from 'commons/src/constants';
import { FetchAlertEvents, fetchAlertEvents } from '../../../actions/thirdPartyIntegrationActions';
import { NotificationAlertEvent } from '../../../models/common';
import { Store } from '../../../reducers';
import AlertHistoryRow from './AlertEventRow';

interface AlertsEventsSortedByDate {
    [dateTime: string]: NotificationAlertEvent[];
}

type ActionProps = {
    onFetchAlertEvents: () => void;
};

type StateProps = {
    loading: boolean;
    alertEvents: NotificationAlertEvent[];
    dateFormat: keyof typeof dateFormats;
};

export type Props = ActionProps & StateProps;

export const AlertEventsOverviewComponent = (props: Props): React.ReactElement => {
    const { loading, onFetchAlertEvents, alertEvents, dateFormat } = props;
    const { t: txt } = useTranslation();

    useEffect((): void => {
        onFetchAlertEvents();
    }, []);

    const getAlertDate = (date: string): string => {
        const now = moment();
        const yesterday = moment().add(-1, 'days');
        if (moment.utc(date).local().isSame(now, 'day')) return txt('NotificationAlerts.Today');
        if (moment.utc(date).local().isSame(yesterday, 'day')) return txt('NotificationAlerts.Yesterday');
        return moment
            .utc(date)
            .local()
            .format(dateFormats[dateFormat as keyof typeof dateFormats].alertDateTime);
    };

    const alertEventsSortedByDate: AlertsEventsSortedByDate = {};
    alertEvents.forEach(alert => {
        const date = moment
            .utc(alert.dateTime)
            .local()
            .format(dateFormats[dateFormat as keyof typeof dateFormats].shortFormat);
        if (alertEventsSortedByDate[date]) alertEventsSortedByDate[date].push(alert);
        else alertEventsSortedByDate[date] = [alert];
    });

    const alertEventRows = (): React.ReactElement[] =>
        Object.values(alertEventsSortedByDate).map(dateTimeGroup => (
            <div key={`alert-event-${dateTimeGroup[0].id}`}>
                <div className="inline-header-lined">
                    <h2 className="inline-header-lined__text">{getAlertDate(dateTimeGroup[0].dateTime)}</h2>
                </div>
                {dateTimeGroup.map(event => (
                    <AlertHistoryRow alertEvent={event} key={event.id} />
                ))}
            </div>
        ));

    return (
        <div>
            <h2 className="notification-alert__history__header">{txt('NotificationAlerts.Events')}</h2>
            <ul className="list">
                <ReactPlaceHolder ready={!loading} customPlaceholder={userlane}>
                    {alertEvents.length === 0 ? (
                        <div className="settings-details-container">{txt('NotificationAlerts.NoNewEvents')}</div>
                    ) : (
                        alertEventRows()
                    )}
                </ReactPlaceHolder>
            </ul>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        thirdPartyIntegrations: { alertEvents },
        userSettings: { dateFormat },
        requests: {
            FETCH_THIRD_PARTY_INTEGRATIONS: { loading: loadingThirdPartyIntegrations },
            FETCH_ALERT_EVENTS: { loading: loadingAlertEvents },
        },
    } = state;

    return {
        alertEvents,
        dateFormat,
        loading: loadingThirdPartyIntegrations || loadingAlertEvents,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onFetchAlertEvents: (): FetchAlertEvents => dispatch(fetchAlertEvents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertEventsOverviewComponent);
