import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CellRenderResult } from './CustomerListElement';
import styles from './CustomerSummaryTableRowSlim.module.scss';

type Props = {
    totalCustomers: number;
    totalSeats: number;
    totalDevices: number;
};

const Cell = ({ children, title }: { children?: CellRenderResult; title: string }): ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className={styles.element}>
            <span className={styles.elementName}>{txt(title)}</span>
            <span className={styles.elementValue}>{children}</span>
        </div>
    );
};

const CustomerTableRowSlimComponent = ({ totalCustomers, totalSeats, totalDevices }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className={styles.lastTableRow}>
            <h3 className={styles.header}>
                <span className={styles.elementName}>{txt('Subscription.TotalCustomers')}</span>
                <span className={styles.elementValue}>{totalCustomers}</span>
            </h3>
            <Cell title="Subscription.TotalSeats">{totalSeats}</Cell>
            <Cell title="Subscription.TotalDevices">
                <div>
                    <span className={styles.summaryCellText}>{totalDevices}</span>
                    <span className={styles.summaryCellTextSmall}>{txt('Subscription.HubsExcluded')}</span>
                </div>
            </Cell>
        </div>
    );
};

export default CustomerTableRowSlimComponent;
