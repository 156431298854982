import { SegmentProperties } from 'commons/src/models/commonTypeScript';
import {
    FETCH_SEGMENT_LABELS_SUCCESS,
    SegmentPropertiesActions,
    SegmentPropertiesActionType,
} from '../actions/segmentPropertiesActions';
import { KeyValuePairType } from '../models/common';

export interface SegmentPropertiesStore {
    segmentProperties: {
        [serialNumber: string]: SegmentProperties;
    };
    segmentLabels: {
        [serialNumber: string]: KeyValuePairType[];
    };
    filters: {
        [locationId: string]: {
            [filterType: string]: { name: string; devices: string[] }[];
        };
    };
}

const initialStore: SegmentPropertiesStore = {
    segmentProperties: {},
    segmentLabels: {},
    filters: {},
};

const reducer = (
    state: SegmentPropertiesStore = initialStore,
    action: SegmentPropertiesActions
): SegmentPropertiesStore => {
    switch (action.type) {
        case SegmentPropertiesActionType.UpdateSegmentPropertiesSuccess:
        case SegmentPropertiesActionType.FetchSegmentPropertiesSuccess:
            return {
                ...state,
                segmentProperties: {
                    ...state.segmentProperties,
                    [action.serialNumber]: action.segmentProperties,
                },
            };
        case SegmentPropertiesActionType.FetchGeneratedSegmentPublicUrlSuccess:
            return {
                ...state,
                segmentProperties: {
                    ...state.segmentProperties,
                    [action.payload.serialNumber]: {
                        ...state.segmentProperties[action.payload.serialNumber],
                        publicUrlPath: action.payload.publicUrlPath,
                    },
                },
            };
        case FETCH_SEGMENT_LABELS_SUCCESS:
        case SegmentPropertiesActionType.UpdateSegmentLabelsSuccess:
            return {
                ...state,
                segmentLabels: {
                    ...state.segmentLabels,
                    [action.serialNumber]: action.segmentLabels,
                },
            };
        case SegmentPropertiesActionType.FetchSegmentPropertyFiltersSuccess:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.locationId]: action.payload,
                },
            };
        default:
            return {
                ...state,
            };
    }
};

export default reducer;

export const labels = (
    store: { segmentPropertiesStore: SegmentPropertiesStore },
    serialNumber: string
): KeyValuePairType[] => store.segmentPropertiesStore.segmentLabels[serialNumber];
