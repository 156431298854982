import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import CircleButton from 'commons/src/components/buttons/CircleButton';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { SensorThresholds, ThresholdRange } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../../reducers';
import { getThresholdRanges } from '../spaceFunctions';
import SpaceThreshold from './SpaceThreshold';
import styles from './SpaceThresholdsComponent.module.scss';

type Props = {
    selectedSensor: SensorTypes;
    thresholds: { [sensor: string]: SensorThresholds };
    setThresholds: (thresholds: { [sensor: string]: SensorThresholds }) => void;
};

type StateProps = {
    defaultThresholds: { [sensor: string]: SensorThresholds };
};

const SpaceThresholdsComponent = ({
    selectedSensor,
    thresholds,
    setThresholds,
    defaultThresholds,
}: Props & StateProps): ReactElement => {
    const ranges: { modifiedRanges: ThresholdRange[]; lowest: number; highest: number } = getThresholdRanges(
        thresholds[selectedSensor]?.ranges,
        selectedSensor
    );
    const defaultRanges: { modifiedRanges: ThresholdRange[]; lowest: number; highest: number } = getThresholdRanges(
        defaultThresholds[selectedSensor]?.ranges,
        selectedSensor
    );

    const showResetButton = ranges.lowest !== defaultRanges.lowest || ranges.highest !== defaultRanges.highest;

    const onResetThresholds = (): void => {
        const resetThresholds = { ...thresholds };
        resetThresholds[selectedSensor].ranges = defaultThresholds[selectedSensor].ranges;
        setThresholds(resetThresholds);
    };

    return (
        <div className={styles.thresholdsContainer}>
            {ranges.modifiedRanges.map((range, index) => {
                const key = `range-${selectedSensor}-${index}`;
                return (
                    <SpaceThreshold
                        key={key}
                        selectedSensor={selectedSensor}
                        customThresholds={thresholds}
                        setCustomThresholds={setThresholds}
                        range={range}
                        index={index}
                    />
                );
            })}
            {showResetButton && (
                <div className={styles.threshold}>
                    <CircleButton onClick={onResetThresholds} iconName="refresh" color="tertiary" size="small" />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        config: { thresholds },
    } = state;
    return {
        defaultThresholds: thresholds,
    };
};

export default connect(mapStateToProps)(SpaceThresholdsComponent);
