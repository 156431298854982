import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Cell, Column, Row, useTable } from 'react-table';
import { mapDeviceType } from 'commons/src/commonFunctions';
import spinner from 'commons/src/img/spinner';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';
import { DeviceSeat, EstimatedSubscription, Subscription } from '../../../models/subscriptionModels';
import { getFormattedCost } from './SubscriptionCommons';
import styles from './SubscriptionCostTable.module.scss';

type ParentProps = {
    subscription?: Subscription | EstimatedSubscription;
    estimatedCostText?: string;
    noDataText: string;
    loading?: boolean;
};

export type Props = ParentProps;

const ProductNameCell = ({ row, cell }: { row: Row; cell: Cell }): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { currency, costPerDevice } = row.original as DeviceSeat;
    const formattedCost = getFormattedCost(currency, costPerDevice);
    return (
        <div>
            <div>{txt(`${mapDeviceType(cell.value as AnyDeviceType)}FullName`)}</div>
            <div className={styles.productCost}>{formattedCost}</div>
        </div>
    );
};

const TotalCost = ({ row }: { row: Row }): React.ReactElement => {
    const { currency, totalCostWithoutDiscount } = row.original as DeviceSeat;
    const formattedCost = getFormattedCost(currency, totalCostWithoutDiscount);
    return <span>{formattedCost}</span>;
};

const CostRowTotalCost = ({ row }: { row: Row }): React.ReactElement => {
    const { currency, cost } = row.original as { currency: string; cost: number; description: string };
    const formattedCost = getFormattedCost(currency, cost);
    return <span>{formattedCost}</span>;
};

const SubscriptionCostTable = ({ subscription, estimatedCostText, noDataText, loading }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const columns = useMemo<Column[]>(
        () => [
            {
                Header: txt('Subscription.Product'),
                accessor: 'deviceType',
                className: styles.firstCell,
                Cell: ProductNameCell,
            },
            {
                Header: txt('Subscription.Seats'),
                accessor: 'seats',
                className: styles.seatsCell,
            },
            {
                Header: txt('Subscription.Total'),
                accessor: 'totalCostWithoutDiscount',
                className: styles.rightAlignedCell,
                Cell: TotalCost,
            },
        ],
        []
    );

    const costRowColumns = useMemo<Column[]>(
        () => [
            {
                accessor: 'description',
                className: styles.costDescriptionCell,
            },
            {
                accessor: 'cost',
                className: styles.rightAlignedCell,
                Cell: CostRowTotalCost,
            },
        ],
        []
    );

    const sortedSubscriptionData =
        subscription?.deviceSeats?.sort((a, b) =>
            txt(`${a.deviceType}FullName`).localeCompare(txt(`${b.deviceType}FullName`))
        ) || [];

    const {
        termPeriod,
        commitmentDiscountedCost,
        commitmentDiscountPercentage,
        discountedCost,
        discountPercentage,
        estimatedCostForBillingPeriod,
        currency,
        estimatedTotalCost,
    } = subscription || {};

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const durationInYears = subscription?.durationInYears || 0;

    const estimatedCost = {
        description: txt(estimatedCostText || 'Zuora.EstimatedCostForBillingPeriod'),
        cost: estimatedTotalCost || estimatedCostForBillingPeriod,
        currency,
    };

    const costRowData = [estimatedCost];
    if (discountedCost) {
        const discountData = {
            description: `${txt('Zuora.Discount')} (${discountPercentage}%)`,
            cost: discountedCost,
            currency,
        };
        costRowData.unshift(discountData);
    }
    if (commitmentDiscountedCost) {
        const duration = termPeriod ? termPeriod / 12 : durationInYears;
        const billingPeriodDiscount = {
            description: `${duration} ${txt(duration === 1 ? 'Zuora.Year' : 'Zuora.Years')} ${txt(
                'Zuora.DiscountLowerCase'
            )} (${commitmentDiscountPercentage}%)`,
            cost: commitmentDiscountedCost,
            currency,
        };
        costRowData.unshift(billingPeriodDiscount);
    }

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: sortedSubscriptionData,
    });

    const {
        getTableBodyProps: getCostTableBodyProps,
        rows: costRows,
        prepareRow: prepareCostRow,
    } = useTable({
        columns: costRowColumns,
        data: costRowData,
    });

    return (
        <div className={styles.previewWrapper}>
            {loading && <div className={styles.spinner}>{spinner}</div>}
            <div {...getTableProps()} className={loading ? styles.loadingTable : styles.table}>
                {headerGroups.map(headerGroup => (
                    <div {...headerGroup.getHeaderGroupProps({ className: styles.header })}>
                        {headerGroup.headers.map(column => {
                            return (
                                <div
                                    {...column.getHeaderProps([
                                        {
                                            className: classNames(styles.cell, column.className),
                                        },
                                    ])}
                                >
                                    <span>{column.render('Header')}</span>
                                </div>
                            );
                        })}
                    </div>
                ))}
                <div {...getTableBodyProps()}>
                    {rows.length > 0 &&
                        rows.map(row => {
                            prepareRow(row);
                            return (
                                <div {...row.getRowProps()}>
                                    <div className={styles.row}>
                                        {row.cells.map(({ getCellProps, render, column }) => {
                                            return (
                                                <div
                                                    {...getCellProps({
                                                        className: classNames(styles.cell, column.className),
                                                    })}
                                                >
                                                    <span>{render('Cell')}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <div {...getCostTableBodyProps()}>
                    {rows.length > 0 &&
                        costRows.map((row, index) => {
                            prepareCostRow(row);
                            return (
                                <div {...row.getRowProps()}>
                                    <div className={styles.row}>
                                        {row.cells.map(({ getCellProps, render, column }) => {
                                            return (
                                                <div
                                                    {...getCellProps({
                                                        className: classNames(
                                                            styles.cell,
                                                            column.className,
                                                            index === costRows.length - 1 && styles.boldText
                                                        ),
                                                    })}
                                                >
                                                    <span>{render('Cell')}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                </div>
                {rows.length === 0 && <div className={styles.noData}>{txt(noDataText)}</div>}
            </div>
        </div>
    );
};

export default SubscriptionCostTable;
