import React from 'react';
import { connect } from 'react-redux';
import Error from 'commons/src/components/errorComponents/Error';
import { getSelectedGroupFromStorage } from 'commons/src/components/findUserType';
import NewsletterSignup from 'commons/src/features/settingsPage/NewsletterSignup';
import UserInfo from 'commons/src/features/settingsPage/UserSettings/UserInfo';
import UserPreferences from 'commons/src/features/settingsPage/UserSettings/UserPreferences';
import { SelectedGroup } from 'commons/src/models/commonTypeScript';
import { Store } from 'commons/src/reducers';
import WeeklyReportSignUp from './WeeklyReportSignUp';

type Props = {
    appError: boolean;
};

const Overview = ({ appError }: Props): React.ReactElement => {
    const selectedGroup: SelectedGroup | null = getSelectedGroupFromStorage();

    if (appError) {
        return <Error />;
    }
    return (
        <div>
            <UserInfo />
            <UserPreferences />
            {selectedGroup?.userGroupId && <WeeklyReportSignUp />}
            <NewsletterSignup />
        </div>
    );
};

const mapStateToProps = (store: Store): Props => {
    const {
        app: { error },
    } = store;
    return {
        appError: error,
    };
};

export default connect(mapStateToProps)(Overview);
