import { roleRestrictions } from 'commons/src/constants';
import { AccountCommonTabs } from 'commons/src/features/settingsPage/commonMenuConfig';
import { RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { TabOption } from 'commons/src/models/menuModels';
import { paths } from '../../constants';

const BusinessAccountSubMenus: TabOption[] = [
    {
        text: 'Overview',
        route: paths.settings,
        path: `/${paths.settings}`,
        testAttr: 'business-settings-overview',
        id: 'businessSettingsOverview',
        requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
        requiredGroupTypes: [],
    },
    ...AccountCommonTabs,
    {
        text: 'Organization',
        route: paths.organization,
        testAttr: 'settings-organization',
        id: 'settings-organization',
        path: `/${paths.organization}`,
        requiredRoleLevel: roleRestrictions.usersAndOrgManagement,
        requiredGroupTypes: [],
    },
    {
        text: 'Users',
        route: paths.userManagement,
        path: `/${paths.userManagement}`,
        id: 'settingsUserMgmt',
        testAttr: 'settings-user-mgmt',
        requiredRoleLevel: roleRestrictions.usersAndOrgManagement,
        requiredGroupTypes: [],
    },
    {
        text: 'Subscription.Subscription',
        route: paths.subscription,
        path: `/${paths.subscription}`,
        id: 'settingsSubscription',
        testAttr: 'settings-subscription',
        requiredRoleLevel: roleRestrictions.subscriptions,
        excludedFeatureToggles: [],
        requiredGroupTypes: [],
    },
];

export default BusinessAccountSubMenus;
