/* eslint max-len: ["error", 3000] */
import React from 'react';

const IconMenuEllipsis = (): React.ReactElement => (
    <svg
        version="1.1"
        id="icon-vertical-ellipsis"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 4 15"
        className="menu-ellipsis"
    >
        <path
            id="Shape"
            className="st0"
            d="M3,10.5H1c-0.3,0-0.5,0.1-0.7,0.3C0.1,11,0,11.2,0,11.5v2c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3h2c0.3,0,0.5-0.1,0.7-0.3C3.9,13.9,4,13.7,4,13.4v-2c0-0.3-0.1-0.5-0.3-0.7C3.5,10.6,3.3,10.5,3,10.5z"
        />
        <path
            id="Shape_1_"
            className="st0"
            d="M3,5.2H1c-0.3,0-0.5,0.1-0.7,0.3S0,6,0,6.2v2c0,0.3,0.1,0.5,0.3,0.7C0.5,9.1,0.7,9.2,1,9.2h2c0.3,0,0.5-0.1,0.7-0.3C3.9,8.7,4,8.5,4,8.2v-2C4,6,3.9,5.7,3.7,5.5C3.5,5.3,3.3,5.2,3,5.2z"
        />
        <path
            id="Shape_2_"
            className="st0"
            d="M3.7,0.3C3.5,0.1,3.3,0,3,0H1C0.7,0,0.5,0.1,0.3,0.3S0,0.7,0,1v2c0,0.3,0.1,0.5,0.3,0.7C0.5,3.8,0.7,3.9,1,3.9h2c0.3,0,0.5-0.1,0.7-0.3C3.9,3.5,4,3.2,4,3V1C4,0.7,3.9,0.5,3.7,0.3z"
        />
    </svg>
);
export default IconMenuEllipsis;
