import React from 'react';
import classNames from 'classnames';
import { useSortBy, useTable, useExpanded, Column, Row } from 'react-table';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { BuildingSummaryHealthStatus } from '../../../../../models/buildingModels';
import ExpandedCustomerRow from '../../../../partner/components/ExpandedCustomerRow';
import { EmptyTable } from '../../../buildingStatus/components/common';

type SortedArrowProps = {
    isSortedDesc?: boolean;
};

const SortedArrow = ({ isSortedDesc }: SortedArrowProps): React.ReactElement =>
    isSortedDesc ? <MaterialIcon name="arrow_drop_up" /> : <MaterialIcon name="arrow_drop_down" />;

type Props = {
    data:
        | {
              locationId: string;
              locationName: string;
              hubs: number;
              sensorDevices: number;
              display: {
                  sensorDevices: string;
                  hubs: string;
              };
          }[]
        | {
              userGroupId: string;
              customerName: string;
              totalLocations: number;
              hubs: number;
              sensorDevices: number;
              buildings: number;
              offlineLocations: BuildingSummaryHealthStatus[];
              display: {
                  buildings: string;
                  sensorDevices: string;
                  hubs: string;
              };
          }[];
    columns: Column[];
    emptyTableText: string;
    styles: { [key: string]: string };
};

export default function HealthStatusTable({ data, columns, emptyTableText, styles }: Props): React.ReactElement {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
        useExpanded
    );

    const expandedTable = (row: Row): React.ReactElement => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { offlineLocations } = row.original;
        return <ExpandedCustomerRow offlineLocations={offlineLocations} />;
    };

    return (
        <div {...getTableProps()} className={styles.table}>
            {headerGroups.map(headerGroup => (
                <div {...headerGroup.getHeaderGroupProps({ className: styles.header })}>
                    {headerGroup.headers.map(column => {
                        return (
                            <div
                                role="button"
                                tabIndex={0}
                                onKeyUp={(e): void => {
                                    if (e.key === 'Enter') column.toggleSortBy();
                                }}
                                {...column.getHeaderProps([
                                    {
                                        className: classNames(styles.cell, column.className),
                                    },
                                    column.getSortByToggleProps(),
                                ])}
                            >
                                {column.isSorted ? <SortedArrow isSortedDesc={column.isSortedDesc} /> : <div />}
                                <span>{column.render('Header')}</span>
                            </div>
                        );
                    })}
                </div>
            ))}
            <div {...getTableBodyProps()}>
                {rows.length > 0 ? (
                    rows.map(row => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()}>
                                <div className={styles.row}>
                                    {row.cells.map(({ getCellProps, render, column }) => {
                                        return (
                                            <div
                                                {...getCellProps({
                                                    className: classNames(styles.cell, column.className),
                                                })}
                                            >
                                                <span>{render('Cell')}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                                {row.isExpanded ? expandedTable(row) : null}
                            </div>
                        );
                    })
                ) : (
                    <EmptyTable emptyTableText={emptyTableText} />
                )}
            </div>
        </div>
    );
}
