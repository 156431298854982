import React from 'react';

const SearchIllustration = (): React.ReactElement => (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="53" viewBox="0 0 64 53" fill="none">
        <path
            d="M35.5567 34.6476C43.7258 32.909 48.9387 24.8772 47.2 16.7082C45.4614 8.53911 37.4297 3.32622 29.2606 5.06484C21.0916 6.80346 15.8787 14.8352 17.6173 23.0043C19.3559 31.1733 27.3877 36.3862 35.5567 34.6476Z"
            fill="#FFD138"
        />
        <path
            d="M28.6657 36.8076C36.8522 37.6756 44.1924 31.7429 45.0605 23.5564C45.9285 15.3699 39.9958 8.02971 31.8093 7.16163C23.6228 6.29356 16.2826 12.2263 15.4145 20.4128C14.5465 28.5993 20.4792 35.9395 28.6657 36.8076Z"
            stroke="black"
            strokeWidth="1.60054"
            strokeMiterlimit="10"
        />
        <path
            d="M33.8918 36.4365L37.9866 49.7433"
            stroke="black"
            strokeWidth="1.60054"
            strokeMiterlimit="10"
            strokeLinecap="round"
        />
        <path
            d="M10.0956 40.6585C10.2142 40.3232 10.0524 40.0919 9.73141 39.9493C9.23513 39.7447 9.03415 39.4762 8.87761 38.6723C8.71108 37.8473 8.30675 37.2516 7.50738 36.9688C6.36762 36.5655 5.24825 37.2197 4.77195 38.5658C4.40697 39.5972 4.5628 40.5344 5.16318 41.1008C5.33146 41.2648 5.49554 41.3751 5.7173 41.4536C6.0319 41.5649 6.28659 41.4519 6.39973 41.1322C6.49098 40.8743 6.43409 40.6743 6.15084 40.458C5.64709 40.0941 5.48365 39.572 5.70447 38.948C5.94718 38.2621 6.53098 37.9406 7.13954 38.1559C7.64495 38.3347 7.87591 38.666 8.03158 39.4232C8.19661 40.236 8.52183 40.7109 9.18196 40.9445L9.30058 40.9865C9.64096 41.1069 9.96785 41.0195 10.0956 40.6585ZM12.2874 41.434C12.4243 41.0472 12.2395 40.6511 11.863 40.5179C11.4865 40.3847 11.0937 40.5764 10.9569 40.9632C10.8236 41.3397 11.0066 41.741 11.3831 41.8742C11.7596 42.0074 12.1542 41.8105 12.2874 41.434Z"
            fill="black"
        />
        <path
            d="M9.1211 9.15759C9.34174 9.03175 9.36226 8.83112 9.25534 8.6042C9.08259 8.26187 9.09927 8.02294 9.39002 7.51524C9.68514 6.99156 9.7447 6.48076 9.44464 5.95463C9.01681 5.20448 8.10625 5.03554 7.22032 5.5408C6.54145 5.92797 6.18812 6.50735 6.24822 7.09386C6.26262 7.26108 6.29982 7.39731 6.38306 7.54326C6.50115 7.75032 6.69158 7.80815 6.90203 7.68813C7.07175 7.59134 7.13438 7.45665 7.08225 7.20748C6.97986 6.77555 7.13525 6.41702 7.54597 6.18278C7.99742 5.92531 8.46311 6.02409 8.69154 6.42462C8.88126 6.75727 8.85145 7.04417 8.58205 7.5262C8.29179 8.04262 8.24588 8.45117 8.49367 8.88564L8.5382 8.96371C8.66597 9.18774 8.8835 9.2931 9.1211 9.15759ZM9.94384 10.6002C10.1984 10.455 10.2835 10.1546 10.1422 9.90678C10.0009 9.65899 9.69897 9.57925 9.44439 9.72444C9.1966 9.86576 9.10814 10.1681 9.24946 10.4159C9.39078 10.6637 9.69606 10.7415 9.94384 10.6002Z"
            fill="black"
        />
        <path
            d="M55.6562 22.1191C56.0757 22.3009 56.3868 22.1138 56.5981 21.7147C56.9044 21.0963 57.2665 20.8622 58.3116 20.7326C59.3844 20.592 60.1843 20.1261 60.6179 19.1258C61.236 17.6995 60.4959 16.2059 58.8115 15.4758C57.5208 14.9164 56.3049 15.0334 55.5252 15.7533C55.3 15.9547 55.144 16.1554 55.0237 16.4329C54.8531 16.8265 54.9755 17.1632 55.3756 17.3366C55.6983 17.4765 55.9599 17.4212 56.2624 17.077C56.7738 16.4631 57.4579 16.2997 58.2387 16.6381C59.0971 17.0101 59.4578 17.7874 59.1277 18.5489C58.8536 19.1814 58.4083 19.4483 57.4232 19.581C56.3661 19.7207 55.7282 20.0959 55.3702 20.9219L55.3059 21.0704C55.1212 21.4963 55.2045 21.9233 55.6562 22.1191ZM54.4675 24.8619C54.9515 25.0717 55.476 24.8697 55.6802 24.3986C55.8843 23.9275 55.6731 23.4066 55.1891 23.1969C54.718 22.9927 54.1871 23.1918 53.9829 23.663C53.7787 24.1341 53.9964 24.6577 54.4675 24.8619Z"
            fill="black"
        />
    </svg>
);

export default SearchIllustration;
