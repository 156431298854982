import { RequestActionType } from 'commons/src/actions/requestActions';
import { AddZoneGroupPayload, BusinessActionRequestType } from '../models/common';
import RequestType from '../reducers/BusinessRequestType';

export enum ZoneGroupActions {
    AddZoneGroupInit = 'ADD_ZONE_GROUP/INIT',
}

export interface AddZoneGroup extends BusinessActionRequestType {
    type: ZoneGroupActions.AddZoneGroupInit;
    payload: AddZoneGroupPayload;
    locationId: string;
}
export const addZoneGroup = (payload: AddZoneGroupPayload, locationId: string): AddZoneGroup => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.AddZoneGroup,
    type: ZoneGroupActions.AddZoneGroupInit,
    payload,
    locationId,
});
