import React, { createContext, PropsWithChildren, useMemo } from 'react';
import { MaterialTableProps, MaterialTableContextValues } from '../models';
import useTableColumns from './useTableColumns';
import useTableData from './useTableData';

export const MaterialTableContext = createContext<MaterialTableContextValues | null>(null);

const MaterialTableContextComponent = ({
    children,
    data,
    columns,
    tableId,
    isReady,
    pagination,
    size,
    idKey,
    columnManager,
    totalDataLength,
    allowColumnManagement,
}: PropsWithChildren<MaterialTableProps>): React.ReactElement => {
    const totalLength = totalDataLength ?? data.length;
    const { tableData, sortData, handlePaginationChange, paginatedData, paginationMetadata, selectedPage } =
        useTableData(data, pagination);
    const { sortChangeHandler, tableColumns, availableColumns, getSelectedColumn, updateColumns } =
        useTableColumns(columns);

    const onHeaderClick =
        <T extends { accessor: string; sortable: boolean }>(header: T) =>
        (event: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
            event.preventDefault();
            const newDirection = sortChangeHandler(header);
            sortData(newDirection, header.accessor);
        };

    const value = useMemo(
        () => ({
            data: tableData,
            columns: tableColumns,
            onHeaderClick,
            getSelectedColumn,
            availableColumns,
            tableId,
            isReady,
            pagination,
            size,
            idKey,
            columnManager,
            handlePaginationChange,
            paginatedData,
            paginationMetadata,
            selectedPage,
            totalLength,
            updateColumns,
            allowColumnManagement,
        }),
        [
            tableData,
            tableColumns,
            onHeaderClick,
            getSelectedColumn,
            availableColumns,
            tableId,
            isReady,
            pagination,
            size,
            idKey,
            columnManager,
            handlePaginationChange,
            paginatedData,
            paginationMetadata,
            selectedPage,
            totalLength,
            updateColumns,
            allowColumnManagement,
        ]
    );

    return <MaterialTableContext.Provider value={value}>{children}</MaterialTableContext.Provider>;
};

export default MaterialTableContextComponent;
