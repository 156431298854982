import React, { useEffect, useState } from 'react';
import { Document, Font, PDFDownloadLink, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { analyticsLogger, ReportType } from '../../../../analytics';
import { DEVICE_SIMPLE_RADON_REPORT_DOWNLOADED } from '../../../../analytics/AnalyticsEvents';
import DownloadPDFView from '../../../../components/PDF/DownloadPdfView';
import {
    Body,
    HeaderBlock,
    Logo,
    LogoContainer,
    Paragraph,
    SubHeaderNoMargin,
    Footer,
} from '../../../../components/PDF/PdfConstants';
import ReportDetails from '../../../../components/PDF/ReportDetails';
import { dateFormats, sensorUnits } from '../../../../constants';
import DemiBold from '../../../../fonts/open-sans-v28-latin-600.ttf';
import RegularFont from '../../../../fonts/open-sans-v28-latin-regular.ttf';
import AirthingsLogo from '../../../../img/logos/Airthings_Logo_Standard.png';
import { PDFContext } from '../../../../models/commonTypeScript';
import BuildingDetails from './BuildingDetails';
import DeviceDetails, { RadonInsightSensorData } from './DeviceDetails';
import Thresholds from './Thresholds';

Font.register({ family: 'DemiBold', src: DemiBold });
Font.register({ family: 'Regular', src: RegularFont });

const styles = StyleSheet.create({
    headerText: {
        fontSize: 25,
        fontFamily: 'DemiBold',
    },
    bold: {
        fontFamily: 'DemiBold',
    },
    smallText: {
        fontSize: 10,
    },
    smallLogo: {
        width: '12vw',
        height: '8vw',
    },
});

type GenerateProps = {
    dateFormat: keyof typeof dateFormats;
    toDate: string;
    fromDate: string;
    reportData: {
        fromDate: string;
        toDate: string;
        room: string;
        floor: string;
        ventilation: string;
        buildingType?: string;
        buildingYear?: string;
        address: string;
    };
    unit: keyof typeof sensorUnits;
    data: RadonInsightSensorData;
    serialNumber: string;
    deviceType: string;
    segmentName: string;
    userName: string;
    thresholds: number[];
    reportId: string;
    numberOfDays: string;
};

type PdfProps = {
    reportPeriodHeader: string;
    thresholds: number[];
    txt: PDFContext;
    reportId: string;
    dateFormat: keyof typeof dateFormats;
    userName: string;
    reportData: {
        fromDate: string;
        toDate: string;
        room: string;
        floor: string;
        ventilation: string;
        buildingType?: string;
        buildingYear?: string;
        address: string;
    };
    unit: keyof typeof sensorUnits;
    data: RadonInsightSensorData;
    serialNumber: string;
    deviceType: string;
    segmentName: string;
    numberOfDays: string;
};

// Create Document Component
const PdfDocument = ({
    reportPeriodHeader,
    txt,
    reportData,
    data,
    deviceType,
    serialNumber,
    unit,
    segmentName,
    thresholds,
    userName,
    dateFormat,
    reportId,
    numberOfDays,
}: PdfProps): React.ReactElement => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Document>
        <Body>
            <HeaderBlock>
                <View>
                    <Text style={styles.headerText}>{txt('RadonInsight.RadonMeasurementReport')}</Text>
                    <SubHeaderNoMargin>{txt('RadonInsight.ReportSubtext')}</SubHeaderNoMargin>
                    <Paragraph>{reportPeriodHeader}</Paragraph>
                </View>
                <LogoContainer>
                    <Logo src={AirthingsLogo} />
                </LogoContainer>
            </HeaderBlock>
            <BuildingDetails
                txt={txt}
                ventilationType={reportData.ventilation}
                buildingYear={reportData.buildingYear || ''}
                buildingType={reportData.buildingType || ''}
                address={reportData.address}
            />
            <DeviceDetails
                data={data}
                segmentName={segmentName}
                unit={unit}
                txt={txt}
                floor={reportData.floor}
                serialNumber={serialNumber}
                deviceType={deviceType}
                roomType={reportData.room}
                thresholds={thresholds}
                numberOfDays={numberOfDays}
            />
            <Thresholds txt={txt} unit={unit} thresholds={thresholds} />
            <View>
                <Paragraph>
                    {txt('RadonInsight.RadonDescriptionConsumer', {
                        lld: data.radonStep1Lld ? `${data.radonStep1Lld} ${sensorUnits[unit]}` : ' ',
                    })}
                </Paragraph>
            </View>
            <ReportDetails
                userName={userName}
                dateFormat={dateFormat}
                txt={txt}
                reportId={reportId}
                footerTextKey="RadonInsight.ConsumerReportFooter"
            />
            <Footer fixed>
                <Text
                    style={styles.smallText}
                    render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }): string =>
                        `${pageNumber} / ${totalPages}`
                    }
                    fixed
                />
                <Image style={styles.smallLogo} src={AirthingsLogo} />
            </Footer>
        </Body>
    </Document>
);

const logDownloadReport = (): void => {
    analyticsLogger(DEVICE_SIMPLE_RADON_REPORT_DOWNLOADED, { reportType: ReportType.Radon });
};

const GeneratePdf = (props: GenerateProps): React.ReactElement | null => {
    const { t: txt } = useTranslation();
    const {
        fromDate,
        toDate,
        reportData,
        data,
        deviceType,
        segmentName,
        serialNumber,
        unit,
        thresholds,
        dateFormat,
        reportId,
        userName,
        numberOfDays,
    } = props;
    const reportPeriodHeader = txt('RadonInsight.ReportGeneratedPeriod', { from: fromDate, to: toDate });

    const pdfProps: PdfProps = {
        reportPeriodHeader,
        reportData,
        unit,
        data,
        serialNumber,
        deviceType,
        segmentName,
        thresholds,
        dateFormat,
        userName,
        reportId,
        numberOfDays,
        txt,
    };

    const [properties, setProperties] = useState<PdfProps | undefined>(undefined);

    useEffect(() => {
        if (!properties) {
            setProperties(pdfProps);
        }
    }, [pdfProps]);

    if (!properties) {
        return null;
    }
    const fileName = `RadonReport_${segmentName}_${fromDate}-${toDate}.pdf`;
    return (
        <div
            className="form__button-container"
            onClick={logDownloadReport}
            onKeyDown={logDownloadReport}
            role="button"
            tabIndex={0}
        >
            <PDFDownloadLink document={<PdfDocument {...properties} />} fileName={fileName}>
                {({ loading }): React.ReactElement => <DownloadPDFView loading={loading} fileName={fileName} />}
            </PDFDownloadLink>
        </div>
    );

    // use this to get inline version of pdf.
    /* return (
        <div className="form form__wide-container form__wide-container--pdf">
            <PDFViewer>
                <PdfDocument {...properties} />
            </PDFViewer>
        </div>
    ); */
};

export default React.memo(GeneratePdf);
