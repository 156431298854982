import { takeEvery, call, put, CallEffect, PutEffect } from 'redux-saga/effects';
import {
    DownloadCSVActionType,
    DownloadCSVError,
    downloadCSVError,
    DownloadCSVSuccess,
    downloadCSVSuccess,
} from '../../actions/DownloadCSVActions';
import { downloadCSV, CSVDownloadAction } from '../../api/generateDownload';
import { DownloadCSVResponse } from '../../models/commonTypeScript';

type DownloadCSVSagaActionType = Generator<
    CallEffect<DownloadCSVResponse[]> | PutEffect<DownloadCSVSuccess> | PutEffect<DownloadCSVError>,
    void,
    DownloadCSVResponse[]
>;
export function* downloadCSVSaga({ serialNumber, segmentId, from, to }: CSVDownloadAction): DownloadCSVSagaActionType {
    try {
        const CSVDownloadDetails = yield call(downloadCSV, { serialNumber, segmentId, from, to });
        yield put(downloadCSVSuccess(CSVDownloadDetails));
    } catch (error) {
        yield put(downloadCSVError(error));
    }
}

export default function* DownloadCSVSaga(): Generator {
    yield takeEvery(DownloadCSVActionType.DownloadCSVInit, downloadCSVSaga);
}
