import React from 'react';

const SpaceCo2MiniIcon = (): React.ReactElement => (
    <svg
        className="device-svg-icons"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.7754 13.5117C15.9707 13.5117 15.3379 13.7969 14.877 14.3672C14.416 14.9375 14.1855 15.7246 14.1855 16.7285C14.1855 17.7793 14.4062 18.5742 14.8477 19.1133C15.293 19.6523 15.9355 19.9219 16.7754 19.9219C17.1387 19.9219 17.4902 19.8867 17.8301 19.8164C18.1699 19.7422 18.5234 19.6484 18.8906 19.5352V20.7363C18.2188 20.9902 17.457 21.1172 16.6055 21.1172C15.3516 21.1172 14.3887 20.7383 13.7168 19.9805C13.0449 19.2188 12.709 18.1309 12.709 16.7168C12.709 15.8262 12.8711 15.0469 13.1953 14.3789C13.5234 13.7109 13.9961 13.1992 14.6133 12.8438C15.2305 12.4883 15.9551 12.3105 16.7871 12.3105C17.6621 12.3105 18.4707 12.4941 19.2129 12.8613L18.709 14.0273C18.4199 13.8906 18.1133 13.7715 17.7891 13.6699C17.4688 13.5645 17.1309 13.5117 16.7754 13.5117Z"
            fill="#21282D"
        />
        <path
            d="M28.3418 16.7051C28.3418 18.0996 27.9922 19.1836 27.293 19.957C26.5977 20.7305 25.6113 21.1172 24.334 21.1172C23.041 21.1172 22.0469 20.7344 21.3516 19.9688C20.6602 19.1992 20.3145 18.1074 20.3145 16.6934C20.3145 15.2793 20.6621 14.1934 21.3574 13.4355C22.0566 12.6777 23.0527 12.2988 24.3457 12.2988C25.6191 12.2988 26.6035 12.6836 27.2988 13.4531C27.9941 14.2227 28.3418 15.3066 28.3418 16.7051ZM21.8027 16.7051C21.8027 17.7598 22.0156 18.5605 22.4414 19.1074C22.8672 19.6504 23.498 19.9219 24.334 19.9219C25.166 19.9219 25.793 19.6523 26.2148 19.1133C26.6406 18.5742 26.8535 17.7715 26.8535 16.7051C26.8535 15.6543 26.6426 14.8574 26.2207 14.3145C25.8027 13.7715 25.1777 13.5 24.3457 13.5C23.5059 13.5 22.8711 13.7715 22.4414 14.3145C22.0156 14.8574 21.8027 15.6543 21.8027 16.7051Z"
            fill="#21282D"
        />
        <path
            d="M15.834 36L13.3496 28.8457H13.3027C13.3691 29.9082 13.4023 30.9043 13.4023 31.834V36H12.1309V27.4336H14.1055L16.4844 34.248H16.5195L18.9688 27.4336H20.9492V36H19.6016V31.7637C19.6016 31.3379 19.6113 30.7832 19.6309 30.0996C19.6543 29.416 19.6738 29.002 19.6895 28.8574H19.6426L17.0703 36H15.834Z"
            fill="#21282D"
        />
        <path
            d="M24.4355 36H23.0586V29.5195H24.4355V36ZM22.9766 27.8027C22.9766 27.5566 23.043 27.3672 23.1758 27.2344C23.3125 27.1016 23.5059 27.0352 23.7559 27.0352C23.998 27.0352 24.1855 27.1016 24.3184 27.2344C24.4551 27.3672 24.5234 27.5566 24.5234 27.8027C24.5234 28.0371 24.4551 28.2227 24.3184 28.3594C24.1855 28.4922 23.998 28.5586 23.7559 28.5586C23.5059 28.5586 23.3125 28.4922 23.1758 28.3594C23.043 28.2227 22.9766 28.0371 22.9766 27.8027Z"
            fill="#21282D"
        />
        <path
            d="M32.1113 36H30.7285V32.0156C30.7285 31.5156 30.627 31.1426 30.4238 30.8965C30.2246 30.6504 29.9062 30.5273 29.4688 30.5273C28.8867 30.5273 28.4609 30.6992 28.1914 31.043C27.9219 31.3867 27.7871 31.9629 27.7871 32.7715V36H26.4102V29.5195H27.4883L27.6816 30.3691H27.752C27.9473 30.0605 28.2246 29.8223 28.584 29.6543C28.9434 29.4863 29.3418 29.4023 29.7793 29.4023C31.334 29.4023 32.1113 30.1934 32.1113 31.7754V36Z"
            fill="#21282D"
        />
        <path
            d="M35.4043 36H34.0273V29.5195H35.4043V36ZM33.9453 27.8027C33.9453 27.5566 34.0117 27.3672 34.1445 27.2344C34.2812 27.1016 34.4746 27.0352 34.7246 27.0352C34.9668 27.0352 35.1543 27.1016 35.2871 27.2344C35.4238 27.3672 35.4922 27.5566 35.4922 27.8027C35.4922 28.0371 35.4238 28.2227 35.2871 28.3594C35.1543 28.4922 34.9668 28.5586 34.7246 28.5586C34.4746 28.5586 34.2812 28.4922 34.1445 28.3594C34.0117 28.2227 33.9453 28.0371 33.9453 27.8027Z"
            fill="#21282D"
        />
        <path
            d="M33.7505 23H29.3955V22.2178L31.0522 20.5522C31.5415 20.0513 31.8652 19.6968 32.0234 19.4888C32.1846 19.2778 32.3018 19.0801 32.375 18.8955C32.4482 18.7109 32.4849 18.5132 32.4849 18.3022C32.4849 18.0122 32.397 17.7837 32.2212 17.6167C32.0483 17.4497 31.8081 17.3662 31.5005 17.3662C31.2544 17.3662 31.0156 17.4116 30.7842 17.5024C30.5557 17.5933 30.2905 17.7573 29.9888 17.9946L29.4307 17.3135C29.7881 17.0117 30.1353 16.7979 30.4722 16.6719C30.8091 16.5459 31.168 16.4829 31.5488 16.4829C32.1465 16.4829 32.6255 16.6396 32.9858 16.9531C33.3462 17.2637 33.5264 17.6826 33.5264 18.21C33.5264 18.5 33.4736 18.7754 33.3682 19.0361C33.2656 19.2969 33.106 19.5664 32.8892 19.8447C32.6753 20.1201 32.3179 20.4937 31.8169 20.9653L30.7007 22.0464V22.0903H33.7505V23Z"
            fill="#21282D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44ZM24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46Z"
            fill="#21282D"
        />
    </svg>
);

export default SpaceCo2MiniIcon;
