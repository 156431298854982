import { SensorThresholds } from 'commons/src/models/commonTypeScript';
import {
    FETCH_VIRUS_RISK_INSIGHT_DATA_SUCCESS,
    FetchVirusRiskInsightData,
    FetchVirusRiskInsightDataSuccess,
    InsightActionType,
} from '../actions/insightActions';
import { VirusRiskInsightData } from '../models/common';

export type VirusRiskInsightState = {
    locationId: string | undefined;
    deviceReports: VirusRiskInsightData[];
    reportId: string;
    includeLogo: boolean;
    thresholds: { [sensor: string]: SensorThresholds };
    errors: { error: string; serialNumber: string }[];
    possibleRiskFactors: { riskName: string; sensorName: string }[];
};

const initialState: VirusRiskInsightState = {
    locationId: undefined,
    deviceReports: [],
    reportId: '',
    possibleRiskFactors: [],
    includeLogo: false,
    thresholds: {},
    errors: [],
};

export default (
    state: VirusRiskInsightState = initialState,
    action: FetchVirusRiskInsightData | FetchVirusRiskInsightDataSuccess
): VirusRiskInsightState => {
    switch (action.type) {
        case InsightActionType.FetchVirusRiskInsightDataInit:
            return {
                ...state,
                includeLogo: action.payload.includeLogo || false,
            };
        case FETCH_VIRUS_RISK_INSIGHT_DATA_SUCCESS:
            return {
                ...state,
                locationId: action.payload.locationId,
                deviceReports: action.payload.deviceReports,
                reportId: action.payload.reportId,
                includeLogo: action.payload.includeLogo || false,
                thresholds: action.payload.thresholds,
                errors: action.payload.errors,
                possibleRiskFactors: action.payload.possibleRiskFactors,
            };
        default:
            return state;
    }
};
