import React, { FocusEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import DeviceIcon from '../../img/deviceIcons/icon_device';
import MaterialIcon from '../MaterialIcon';

export type Props = {
    path: string;
    title: string;
    onClick?: () => void;
    iconName?: string;
    navigationType: string;
    id?: string;
    onBlur?: (event: FocusEvent<HTMLElement>) => void;
    extraClass?: string;
    invertedColors?: boolean;
};

interface NavigationItemContentProps {
    iconName?: string;
    navigationType: string;
    title: string;
    txt: (text: string) => string;
    extraClass?: string;
}

const NavigationItemContent = ({
    iconName,
    navigationType,
    title,
    txt,
    extraClass,
}: NavigationItemContentProps): React.ReactElement => (
    <li className="sidebar-navigation__items-container__element">
        {iconName !== undefined &&
            (iconName === 'deviceIcon' ? (
                <DeviceIcon />
            ) : (
                <MaterialIcon
                    extraClass={classNames('sidebar-navigation__items-container__item__icon', extraClass)}
                    name={iconName}
                />
            ))}
        <span
            className={classNames({
                'sidebar-navigation__items-container__item--slim': navigationType === 'slim',
            })}
        >
            {txt(title)}
        </span>
    </li>
);

const NavigationItem = ({
    path,
    title,
    iconName,
    navigationType,
    id,
    onBlur,
    onClick,
    extraClass,
    invertedColors,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { pathname, search } = useLocation();

    if (id === 'shop') {
        return (
            <a
                id={id}
                href={path}
                rel="noopener noreferrer"
                target="_blank"
                className="sidebar-navigation__items-container__item"
            >
                <NavigationItemContent
                    title={title}
                    iconName={iconName}
                    navigationType={navigationType}
                    txt={txt}
                    extraClass={extraClass}
                />
            </a>
        );
    }
    const pathQuery = path.includes('?') && path.substring(path.indexOf('?'));
    const elementWithEmptyQuery = pathQuery && pathQuery.length === 9 ? path.substring(0, path.indexOf('?')) : path;
    const elementIsActive =
        path === pathname || path === `${pathname}${search}` || elementWithEmptyQuery === `${pathname}${search}`;
    return (
        <NavLink
            id={id}
            className={(): string =>
                classNames('sidebar-navigation__items-container__item', {
                    'sidebar-navigation__items-container__item--active':
                        (elementIsActive && !invertedColors) || (!elementIsActive && invertedColors),
                    'sidebar-navigation__items-container__item--inverted': invertedColors,
                })
            }
            onBlur={onBlur}
            onClick={onClick}
            to={path}
        >
            <NavigationItemContent
                title={title}
                iconName={iconName}
                navigationType={navigationType}
                txt={txt}
                extraClass={extraClass}
            />
        </NavLink>
    );
};

export default NavigationItem;
