import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DeviceInfo from 'commons/src/components/device/DeviceInfo';
import LabeledText from 'commons/src/components/LabeledText';
import { dateFormats } from 'commons/src/constants';
import { SegmentProperties, BuildingType, DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';
import GeneratePdf from '../insightPdfs/OutdoorPDF/GenerateOutdoorReportComponent';
import BuildingInfo from '../reportSelector/BuildingInfo';

export type ParentProps = {
    building?: BuildingType;
    device: DeviceWithKeyInfo;
    numberOfGraphs: number;
    listOfImages: { data: string; sensorType: string }[];
    generateChartError: boolean;
    serialNumber: string;
    includeLogo: boolean;
    reportId: string;
    optionalBuildingProps: string[];
};

type StateProps = {
    segmentProperties: { [serialNumber: string]: SegmentProperties };
    userName: string;
    dateFormat: keyof typeof dateFormats;
    logoImage?: string;
};

type Props = StateProps & ParentProps;

export const OutdoorResultSummaryComponent = ({
    building,
    device,
    segmentProperties,
    userName,
    listOfImages,
    dateFormat,
    generateChartError,
    numberOfGraphs,
    serialNumber,
    includeLogo,
    logoImage,
    reportId,
    optionalBuildingProps,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const fromDate = moment().subtract(1, 'weeks').format(dateFormats[dateFormat].shortFormat);
    const toDate = moment().format(dateFormats[dateFormat].shortFormat);

    return (
        <div id="buildingOutdoorResultSummary" className="form__wide-container insight-tile insight-tile--full-width">
            <div className="outdoor-insight-tile__header outdoor-insight-tile__header--no-border">
                <h3 className="outdoor-insight-tile__header__name" id="building-header">
                    {txt('OutdoorInsight.OutdoorInsightResultTitle')}
                </h3>
                {building && !generateChartError && listOfImages && listOfImages.length === numberOfGraphs && (
                    <GeneratePdf
                        fromDate={fromDate}
                        toDate={toDate}
                        dateFormat={dateFormat}
                        building={building}
                        device={device}
                        chartImages={listOfImages}
                        segmentProperties={segmentProperties[serialNumber]}
                        userName={userName}
                        logoImage={includeLogo ? logoImage : undefined}
                        reportId={reportId}
                    />
                )}
            </div>
            <div className="inline-header-lined inline-header-lined--no-margin-top" />
            <BuildingInfo building={building} validate={false} optionalBuildingProps={optionalBuildingProps} />
            <div className="inline-header-lined inline-header-lined--no-margin-top" />
            {device && (
                <div className="form__row">
                    <div className="form__field--standard-width">
                        <DeviceInfo serialNumber={device.serialNumber} deviceType={device.type} />
                    </div>
                    <div className="form__field--standard-width">
                        <LabeledText
                            label="Floor"
                            largeText
                            invalid={
                                segmentProperties[device.serialNumber] &&
                                segmentProperties[device.serialNumber].floor === undefined
                            }
                            id={`deviceFloor${device.serialNumber}`}
                            value={
                                segmentProperties[device.serialNumber] &&
                                segmentProperties[device.serialNumber].floor !== undefined
                                    ? `${segmentProperties[device.serialNumber].floor}`
                                    : '-'
                            }
                        />
                    </div>
                    <div className="form__field--standard-width">
                        <LabeledText
                            label="RoomType"
                            largeText
                            invalid={device && !segmentProperties[device.serialNumber].roomType}
                            id={`roomType${device.serialNumber}`}
                            value={
                                segmentProperties[device.serialNumber] &&
                                segmentProperties[device.serialNumber].roomType
                                    ? txt(`RoomTypeOptions.${segmentProperties[device.serialNumber].roomType}`)
                                    : '-'
                            }
                        />
                    </div>
                </div>
            )}
            <div className="inline-header-lined inline-header-lined--no-margin-top" />
            <h3>{txt('OutdoorInsight.OutdoorReportSummaryTitle')}</h3>
            <p className="insight-tile__content__text insight-tile__content__text-block">
                {txt('OutdoorInsight.OutdoorReportSummaryDescription')}
            </p>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat, userName },
        segmentPropertiesStore: { segmentProperties },
        organizationProperties: { logoImage },
    } = state;

    return {
        dateFormat,
        userName,
        segmentProperties,
        logoImage,
    };
};

export default connect(mapStateToProps)(OutdoorResultSummaryComponent);
