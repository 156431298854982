import React, { SyntheticEvent, useState, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CircleButton, { CircleButtonColor } from 'commons/src/components/buttons/CircleButton';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import { deviceTypes, roleRestrictions } from 'commons/src/constants';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import spinner from 'commons/src/img/spinner';
import { Role, SensorTypes } from 'commons/src/models/commonEnums';
import { AnyDeviceType, DeviceType, FloorplanZone } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';
import DeviceList from './DeviceList';
import { MODES } from './FloorPlanConstants';

type ToolbarActionProps = {
    onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
    icon: string | React.ReactElement;
    color: CircleButtonColor;
    // anchorRef?: RefObject<HTMLInputElement>;
};

export const ToolbarAction = ({ onClick, icon, color }: ToolbarActionProps): React.ReactElement => {
    const testAttr = typeof icon === 'string' ? `floor-fab-${icon}` : 'floor-icon-fab-button';
    return <CircleButton onClick={onClick} iconName={icon} testAttr={testAttr} color={color} />;
};

type StateProps = {
    updating: boolean;
    userRole?: Role;
};

export type ParentProps = {
    mode: MODES;
    activeSensor: string | undefined;
    setMode: (mode: MODES) => void;
    onEdit: () => void;
    onRemove: () => void;
    onCancel: () => void;
    onSave: () => void;
    onSelectDevice: (serialNumber: string, deviceType: AnyDeviceType) => void;
    onUndo: () => void;
    onSelectSensor: (sensorType: string | undefined) => void;
    locationId: string;
    allDevices: { [serialnumber: string]: DeviceType };
    floorZones: FloorplanZone[];
};

export type Props = StateProps & ParentProps;

export const ToolbarComponent = (props: Props): React.ReactElement => {
    const {
        mode,
        activeSensor,
        updating,
        setMode,
        onEdit,
        onRemove,
        onCancel,
        onSave,
        onUndo,
        onSelectDevice,
        onSelectSensor,
        locationId,
        userRole,
        allDevices,
        floorZones,
    } = props;
    const { t: txt } = useTranslation();
    const [showFilterSensorList, setShowFilterSensorList] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const devices: DeviceType[] = floorZones
        .filter(zone => zone.device !== undefined)
        .map(zone => allDevices[zone.device ? zone.device.serialNumber : ''])
        .filter(device => device !== undefined);

    const filterChangeHandler = (sensor: string): void => {
        if (sensor === 'unset') {
            onSelectSensor(undefined);
        } else {
            onSelectSensor(sensor);
        }

        setShowFilterSensorList(false);
    };

    const handleFilterDropdown = (): void => {
        setShowFilterSensorList(!showFilterSensorList);
    };

    const sensorsInDevices = devices.reduce((sensors: string[], device) => {
        const deviceSensors = (deviceTypes[device.type] && deviceTypes[device.type].sensors) || [];
        const newSensors = deviceSensors.filter(sensor => !sensors.includes(sensor as SensorTypes));
        return [...sensors, ...newSensors];
    }, []);

    const renderFilterButton = (): React.ReactElement => {
        let activeIcon: string | React.ReactElement;
        if (activeSensor) {
            activeIcon = <SensorIcon sensor={activeSensor} />;
        } else {
            activeIcon = 'filter_list';
        }
        const filterSensors = [
            SensorTypes.temp,
            SensorTypes.voc,
            SensorTypes.radonShortTermAvg,
            SensorTypes.hourlyRadon,
            SensorTypes.co2,
            SensorTypes.humidity,
            SensorTypes.pressure,
            SensorTypes.pm25,
        ];
        const filterSensorsInDevices = filterSensors.filter(sensor => sensorsInDevices.includes(sensor));
        const numberOfFilterDevicesIsOdd = (filterSensorsInDevices.length + 1) % 2 === 1; // adding one as there is a all sensors option

        const filterSensorElements = (): React.ReactElement[] =>
            filterSensorsInDevices.map(filterSensor => (
                <CircleButton
                    key={`sensorElement${filterSensor}`}
                    onClick={(): void => filterChangeHandler(filterSensor)}
                    iconName={<SensorIcon sensor={filterSensor} />}
                    testAttr={filterSensor.toLowerCase()}
                    color={activeSensor === filterSensor ? 'primary' : 'secondary'}
                />
            ));

        return (
            <div className="toolbar__filter">
                <div className="toolbar__filter__selector">
                    <div className="toolbar__action-wrapper">
                        <ToolbarAction
                            onClick={handleFilterDropdown}
                            icon={showFilterSensorList ? 'close' : activeIcon}
                            color="secondary"
                        />
                    </div>
                    <span className="toolbar__filter__selector__text">
                        {activeSensor ? txt(activeSensor) : txt('FloorPlan.Filter')}
                    </span>
                </div>
                <div className={classNames('toolbar__sensors', { 'toolbar__sensors--show': showFilterSensorList })}>
                    <CircleButton
                        onClick={(): void => filterChangeHandler('unset')}
                        iconName="filter_list"
                        testAttr="all-sensors"
                        color={activeSensor === undefined ? 'primary' : 'secondary'}
                    />
                    {filterSensorElements()}
                    {numberOfFilterDevicesIsOdd && <div className="toolbar__sensors__placeholder" />}
                </div>
            </div>
        );
    };

    const renderActionButtons = (): React.ReactElement | null => {
        if (updating) {
            return (
                <div className="toolbar__action-wrapper">
                    {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
                    <ToolbarAction onClick={(): void => {}} icon={spinner} color="tertiary" />
                </div>
            );
        }

        switch (mode) {
            case MODES.VIEW:
                return (
                    <div className="toolbar__action-wrapper">
                        <ToolbarAction onClick={(): void => setMode(MODES.ADD_DEVICE)} icon="add" color="secondary" />
                    </div>
                );
            case MODES.ADD_DEVICE:
                return (
                    <div className="toolbar__action-wrapper">
                        <ToolbarAction onClick={onCancel} icon="close" color="tertiary" />
                    </div>
                );
            case MODES.DRAW:
                return (
                    <div className="toolbar__action-wrapper">
                        <ToolbarAction onClick={onCancel} icon="close" color="tertiary" />
                        <ToolbarAction onClick={onUndo} icon="undo" color="secondary" />
                    </div>
                );
            case MODES.SELECTED:
                return (
                    <div className="toolbar__action-wrapper">
                        <ToolbarAction onClick={onCancel} icon="close" color="tertiary" />
                        <ToolbarAction onClick={onRemove} icon="delete_forever" color="alert" />
                        <ToolbarAction onClick={onEdit} icon="edit" color="secondary" />
                    </div>
                );
            case MODES.EDIT:
                return (
                    <div className="toolbar__action-wrapper">
                        <ToolbarAction onClick={onCancel} icon="close" color="tertiary" />
                        <ToolbarAction onClick={onSave} icon="check" color="confirmed" />
                    </div>
                );
            default:
                return null;
        }
    };

    const allowedToEdit = userRole && userRoleAboveRequiredLevel(userRole, roleRestrictions.editDeviceOrBuilding);

    return (
        <div className="toolbar">
            {renderFilterButton()}
            <div className="toolbar__actions">
                <DeviceList
                    isOpen={mode === MODES.ADD_DEVICE}
                    onSelectDevice={onSelectDevice}
                    onCancel={onCancel}
                    locationId={locationId}
                    anchorRef={anchorRef}
                />
                <div className="toolbar__buttons">
                    <div className="toolbar__filter__selector" ref={anchorRef}>
                        {allowedToEdit && (
                            <span className="toolbar__filter__selector__text">
                                {mode === MODES.VIEW && txt('AddDevice.AddDevice')}
                            </span>
                        )}
                        {allowedToEdit && renderActionButtons()}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const { selectedGroup } = store.userSettings;
    const { updating } = store.floorplans;
    return {
        userRole: selectedGroup && selectedGroup.role,
        updating,
    };
};

export default connect(mapStateToProps)(ToolbarComponent);
