import { FeatureToggleEnum, RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { TabOption } from 'commons/src/models/menuModels';
import { Path, paths } from '../../constants';

const PartnerTabs: TabOption[] = [
    {
        text: 'Overview',
        id: 'partnerOverview',
        route: paths.partnerPage as Path,
        path: `/${paths.partnerPage}`,
        testAttr: 'overview',
        requiredRoleLevel: RequiredRoleLevel.ABOVE_ADMIN,
        requiredGroupTypes: [],
    },
    {
        text: 'Status',
        id: 'partnerStatus',
        route: paths.partnerStatus as Path,
        path: `/${paths.partnerStatus}`,
        testAttr: 'status',
        requiredRoleLevel: RequiredRoleLevel.ABOVE_ADMIN,
        requiredGroupTypes: [],
    },
    {
        text: 'Partner.Summary',
        id: 'partnerSummary',
        route: paths.partnerSummary as Path,
        path: `/${paths.partnerSummary}`,
        testAttr: 'summary',
        requiredRoleLevel: RequiredRoleLevel.ABOVE_ADMIN,
        requiredFeatureToggles: [FeatureToggleEnum.partnerInsightSummary],
        requiredGroupTypes: [],
    },
];

export default PartnerTabs;
