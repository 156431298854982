import { HomeReportResponse, ErrorType } from '../models/commonTypeScript';

export enum HomeReportActionType {
    GenerateRadonHomeReportInit = 'GENERATE_RADON_HOME_REPORT/INIT',
    GenerateRadonHomeReportSuccess = 'GENERATE_RADON_HOME_REPORT_SUCCESS',
    GenerateRadonHomeReportError = 'GENERATE_RADON_HOME_REPORT_ERROR',
}

export type HomeReportData = {
    serialNumber: string;
    data: {
        captchaToken: string;
        name: string;
        address: string;
        coords: { lat: number; lng: number };
        buildingType: string;
        buildingYear: number;
        roomType: string;
        floor: string;
        checkCode: string;
        duration: number;
        radonLongTermAvg: number;
        ended: string;
        countryCode: string;
    };
};

export interface GenerateRadonHomeReport {
    type: HomeReportActionType.GenerateRadonHomeReportInit;
    payload: HomeReportData;
}
export const generateRadonHomeReport = (payload: HomeReportData): GenerateRadonHomeReport => ({
    type: HomeReportActionType.GenerateRadonHomeReportInit,
    payload,
});

export interface GenerateRadonHomeReportSuccess {
    type: HomeReportActionType.GenerateRadonHomeReportSuccess;
    reportData: HomeReportResponse;
}
export const generateRadonHomeReportSuccess = (reportData: HomeReportResponse): GenerateRadonHomeReportSuccess => ({
    type: HomeReportActionType.GenerateRadonHomeReportSuccess,
    reportData,
});

export interface GenerateRadonHomeReportError {
    type: HomeReportActionType.GenerateRadonHomeReportError;
    error: ErrorType;
}
export const generateRadonHomeReportError = (error: ErrorType): GenerateRadonHomeReportError => ({
    type: HomeReportActionType.GenerateRadonHomeReportError,
    error,
});

export type HomeReportReducerActions =
    | GenerateRadonHomeReport
    | GenerateRadonHomeReportSuccess
    | GenerateRadonHomeReportError;
