import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { sensorUnits, statusColors } from '../../constants';

type Props = {
    sensorValue: number | null | undefined;
    sensorUnit: string;
    title: string;
    dotColor: string;
};

const SensorValue = ({ title, dotColor, sensorUnit, sensorValue }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const sensorHasValue = sensorValue !== undefined && sensorValue !== null && !Number.isNaN(sensorValue);
    const unitIsSymbol = sensorUnit === 'c' || sensorUnit === 'f' || sensorUnit === 'pct' || sensorUnit === 'riskIndex';

    return (
        <div className="sensor__block">
            <div className="sensor__title text-right">{title}</div>
            <div className="sensor__data">
                {sensorHasValue ? (
                    <div className="sensor__data centered">
                        <div
                            className={classNames('sensor__line', {
                                'sensor__line--ok': dotColor === statusColors.green,
                                'sensor__line--alert': dotColor === statusColors.red,
                                'sensor__line--warning': dotColor === statusColors.yellow,
                            })}
                        />
                        <div
                            className={classNames('sensor__value sensor__value--single-line text-bold', {
                                block: !unitIsSymbol,
                                'sensor__value--block': !unitIsSymbol,
                            })}
                        >
                            {sensorValue}
                            <div className="sensor__unit">{sensorUnits[sensorUnit as keyof typeof sensorUnits]}</div>
                        </div>
                    </div>
                ) : (
                    txt('NotAvailableAbbreviation')
                )}
            </div>
        </div>
    );
};

export default SensorValue;
