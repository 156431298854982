import React, { forwardRef, ReactElement, useEffect, useImperativeHandle, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import CheckBox from 'commons/src/components/input/Checkbox';
import Input from 'commons/src/components/input/Input';
import NumberInput from 'commons/src/components/input/Number';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { measurementUnits } from 'commons/src/constants';
import { PredefinedProperty, PropertyType } from 'commons/src/models/commonEnums';
import useCustomProperties from '../../../hooks/useCustomProperties';
import { PropertyPayload, SpacePropertyDefinitionAndValue } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import SpaceCustomProperties from './SpaceCustomProperties';
import SpacePropertyComboBox from './SpacePropertyComboBox';
import styles from './SpaceSettingsForm.module.scss';

export type Props = {
    spaceName?: string;
    locationId: string;
    spaceId?: string;
    editProperty: (propertyId: string) => void;
};

export type FormActions = {
    IsValid: () => boolean;
    Values: () => { name: string; updateDeviceNames: boolean; properties: PropertyPayload[] };
    Clear: () => void;
};

export type FormState = {
    [PredefinedProperty: string]: SpacePropertyDefinitionAndValue;
};

const SpaceSettingsForm = forwardRef<FormActions, Props>(
    ({ spaceName, locationId, spaceId, editProperty }, ref): ReactElement => {
        const { t: txt } = useTranslation();
        const { predefinedProperties, customProperties } = useCustomProperties(locationId, spaceId);
        const { units } = useSelector((store: Store) => store.userSettings);
        const request = useSelector((state: Store) => state.commonRequests.CREATE_PROPERTY_VALUE);
        const [displayValidation, setDisplayValidation] = useState(false);
        const [updateDeviceNames, setUpdateDeviceNames] = useState<boolean>(false);

        const [name, setName] = useState(spaceName ?? '');
        const [formState, setFormState] = useState<FormState>({});
        const [selectedCustomProperties, setSelectedCustomProperties] = useState<{ [key: string]: string | null }>({});

        const updateFormState = (key: PredefinedProperty, value: string | undefined): void => {
            setFormState(current => ({ ...current, [key]: { ...current[key], propertyValue: value } }));
        };

        useEffect(() => {
            const currentSelectedCustomProps: { [key: string]: string | null } = {};
            customProperties.forEach(customProperty => {
                if (customProperty.propertyValue) {
                    currentSelectedCustomProps[customProperty.propertyDefinitionId] = customProperty.propertyValue;
                }
            });
            setSelectedCustomProperties(currentSelectedCustomProps);
        }, []);

        const selectCustomProperty = (payload: { propertyId?: string; propertyValue: string | null }): void => {
            setSelectedCustomProperties(current => {
                if (payload.propertyId) {
                    return { ...current, [payload.propertyId]: payload.propertyValue };
                }
                return current;
            });
        };

        const getProperties = (): PropertyPayload[] => {
            const predefined = Object.values(formState)
                .map(formValue => {
                    const { propertyType } = formValue.propertyDefinition;
                    const value =
                        propertyType === PropertyType.Number && formValue.propertyValue
                            ? parseFloat(formValue.propertyValue)
                            : formValue.propertyValue;
                    return {
                        propertyDefinitionId: formValue.propertyDefinitionId,
                        predefinedType: formValue.propertyDefinition.predefinedType,
                        propertyType,
                        value,
                    };
                })
                .filter(property => property.value !== undefined && property.value !== '') as PropertyPayload[];
            const custom = Object.entries(selectedCustomProperties).map(([key, formValue]) => ({
                propertyDefinitionId: key,
                predefinedType: PredefinedProperty.Custom,
                propertyType: PropertyType.Selection,
                value: formValue,
            })) as PropertyPayload[];
            return [...predefined, ...custom];
        };

        useEffect(() => {
            setFormState(predefinedProperties);
        }, []);

        useImperativeHandle(ref, () => ({
            IsValid: (): boolean => {
                if (name.length > 1) return true;
                setDisplayValidation(true);
                return false;
            },
            Values: (): { name: string; updateDeviceNames: boolean; properties: PropertyPayload[] } => ({
                name,
                updateDeviceNames,
                properties: getProperties(),
            }),
            Clear: (): void => {
                setName('');
                setFormState(predefinedProperties);
                setSelectedCustomProperties({});
            },
        }));

        return (
            <>
                <Input
                    type="text"
                    id="SpaceName"
                    label="Name"
                    markedMandatory
                    isValid={name.length > 1}
                    validate={displayValidation && name.length <= 1}
                    maxLength={50}
                    autoComplete="off"
                    onChange={(event): void => setName(event.currentTarget.value)}
                    currentValue={name}
                    testId="add-space-name"
                />
                {spaceId && (
                    <div className={styles.checkbox}>
                        <CheckBox
                            id="updateDeviceNames"
                            checked={updateDeviceNames}
                            label={txt('Space.EditSpaceRenameDevicesToggle')}
                            onChange={(): void => setUpdateDeviceNames(!updateDeviceNames)}
                        />
                    </div>
                )}
                <h2 className={styles.subHeader}>
                    <Trans i18nKey="Space.SpaceProperties">
                        <span className={styles.organizePropsHeader} />
                    </Trans>
                </h2>
                <div className={styles.row}>
                    {predefinedProperties.FLOOR && (
                        <NumberInput
                            label="SpaceProperties.Floor"
                            step={1}
                            id="floor-input"
                            validate={false}
                            markedMandatory={false}
                            currentValue={formState.FLOOR?.propertyValue || ''}
                            onChange={(event): void => {
                                const value: number = parseInt(event.currentTarget.value, 10);
                                updateFormState(PredefinedProperty.Floor, value.toString());
                            }}
                            minValue={-10}
                            maxValue={500}
                        />
                    )}
                    {predefinedProperties.ROOM_TYPE && (
                        <div className={styles.dropdownWithButton}>
                            <div>
                                <SpacePropertyComboBox
                                    label="SpaceProperties.RoomType"
                                    property={predefinedProperties.ROOM_TYPE}
                                    selectedValue={formState.ROOM_TYPE?.propertyValue}
                                    onSelect={({ propertyValue }): void =>
                                        updateFormState(PredefinedProperty.RoomType, propertyValue ?? undefined)
                                    }
                                />
                            </div>
                            <div className={styles.editButton}>
                                <TertiaryButton
                                    title="Edit"
                                    onClick={(): void => editProperty(formState.ROOM_TYPE.propertyDefinitionId)}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.numbersRow}>
                    {predefinedProperties.HEIGHT && (
                        <NumberInput
                            customLabel={txt('SpaceProperties.Height', {
                                unit:
                                    measurementUnits[units.lengthUnit as keyof typeof measurementUnits] &&
                                    measurementUnits[units.lengthUnit as keyof typeof measurementUnits].length,
                            })}
                            label=""
                            step={0.1}
                            id="input-height"
                            validate={false}
                            currentValue={formState.HEIGHT?.propertyValue || ''}
                            onChange={(event): void =>
                                updateFormState(PredefinedProperty.Height, event.currentTarget.value)
                            }
                        />
                    )}
                    {predefinedProperties.AREA && (
                        <NumberInput
                            customLabel={txt('SpaceProperties.Area', {
                                unit:
                                    measurementUnits[units.lengthUnit as keyof typeof measurementUnits] &&
                                    measurementUnits[units.lengthUnit as keyof typeof measurementUnits].area,
                            })}
                            label=""
                            step={0.1}
                            id="input-area"
                            validate={false}
                            currentValue={formState.AREA?.propertyValue || ''}
                            onChange={(event): void =>
                                updateFormState(PredefinedProperty.Area, event.currentTarget.value)
                            }
                        />
                    )}
                </div>
                <div className={styles.dimensionDescription}>{txt('Space.WhyDimensions')}</div>
                <div className={styles.dropdownWithButton}>
                    {predefinedProperties.VENTILATION_ZONE && (
                        <SpacePropertyComboBox
                            label="SpaceProperties.Ventilation"
                            property={predefinedProperties.VENTILATION_ZONE}
                            selectedValue={formState.VENTILATION_ZONE?.propertyValue}
                            onSelect={({ propertyValue }): void =>
                                updateFormState(PredefinedProperty.VentilationZone, propertyValue ?? undefined)
                            }
                        />
                    )}
                    <div className={styles.editButton}>
                        <TertiaryButton
                            title="Edit"
                            onClick={(): void => editProperty(formState.VENTILATION_ZONE.propertyDefinitionId)}
                        />
                    </div>
                </div>
                <SpaceCustomProperties
                    customProperties={customProperties}
                    selectProperty={selectCustomProperty}
                    selectedCustomProps={selectedCustomProperties}
                    editProperty={editProperty}
                />
                {request.error && <ResponseBox text={`ErrorCodes.${request.error.error}`} />}
            </>
        );
    }
);

export default SpaceSettingsForm;
