import React from 'react';
import { useTranslation } from 'react-i18next';
import { mapDeviceType } from 'commons/src/commonFunctions';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';
import styles from './DeviceAndSeatCount.module.scss';

type Props = {
    deviceCounts: { deviceType: string; count: number; seatCount?: number }[];
    displaySeatCount: boolean;
};
const DeviceAndSeatCount = ({ deviceCounts, displaySeatCount }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className={styles.deviceCounts}>
            {deviceCounts.map(device => (
                <div key={device.deviceType} className={styles.deviceCountElement}>
                    <div className={styles.deviceCountElementHeader}>
                        {txt(`${mapDeviceType(device.deviceType as AnyDeviceType)}FullName`)}
                    </div>
                    {displaySeatCount && (
                        <div>
                            {device.seatCount} {txt('Subscription.Seats')}
                        </div>
                    )}
                    <div>
                        {device.count} {txt('Partner.DeployedDevices')}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DeviceAndSeatCount;
