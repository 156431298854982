import React, { useEffect, useState } from 'react';
import { Document, Font, PDFDownloadLink, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { analyticsLogger, PageType, ReportType } from 'commons/src/analytics';
import { INSIGHT_DOWNLOADED_REPORT } from 'commons/src/analytics/AnalyticsEvents';
import { mapDeviceType } from 'commons/src/commonFunctions';
import DownloadPDFView from 'commons/src/components/PDF/DownloadPdfView';
import {
    Body,
    HeaderBlock,
    Logo,
    LogoContainer,
    PageLine,
    Paragraph,
    colorStyles,
} from 'commons/src/components/PDF/PdfConstants';
import { dateFormats } from 'commons/src/constants';
import DemiBold from 'commons/src/fonts/open-sans-v28-latin-600.ttf';
import RegularFont from 'commons/src/fonts/open-sans-v28-latin-regular.ttf';
import {
    AnyDeviceType,
    BuildingType,
    DeviceType,
    SegmentProperties,
    SensorThresholds,
} from 'commons/src/models/commonTypeScript';
import BusinessLogo from '../../../../img/logos/business-logo.png';
import { PDFContext, VirusRiskInsightData } from '../../../../models/common';
import BuildingDetails from '../BuildingDetails';
import ReportDetails from '../IaqInsightPdf/ReportDetails';
import DeviceResult from './DeviceResult';
import PossibleSolutions from './PossibleSolutions';
import Summary from './Summary';

Font.register({ family: 'DemiBold', src: DemiBold });
Font.register({ family: 'Regular', src: RegularFont });

const styles = StyleSheet.create({
    headerText: {
        fontSize: 25,
        fontFamily: 'DemiBold',
        maxWidth: '60vw',
    },
    bold: {
        fontFamily: 'DemiBold',
    },
    pageNumbers: {
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    smallLogo: {
        width: '10vw',
        height: '8vw',
    },
    footer: {
        width: '100%',
        position: 'absolute',
        bottom: 25,
        left: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '0 30',
        alignItems: 'baseline',
    },
});

type GenerateProps = {
    devicesInEachLevel: { GOOD: number; FAIR: number; POOR: number };
    dateFormat: keyof typeof dateFormats;
    toDate: string | null;
    fromDate: string | null;
    building: BuildingType | undefined;
    deviceReports: VirusRiskInsightData[];
    thresholds: { [sensor: string]: SensorThresholds };
    devices: { [serialNumber: string]: DeviceType };
    segmentProperties: { [serialNumber: string]: SegmentProperties };
    possibleRiskFactors: { riskName: string; sensorName: string }[];
    logo?: string;
    comment: string;
    listOfImages: { data: string; serialNumber: string }[];
    reportDetails: {
        deviceTypes: string[];
        userName: string;
        reportId: string;
    };
};

type PdfProperties = {
    devicesInEachLevel: { GOOD: number; FAIR: number; POOR: number };
    reportPeriodHeader: string;
    building: BuildingType;
    txt: PDFContext;
    devices: { [serialNumber: string]: DeviceType };
    dateFormat: keyof typeof dateFormats;
    deviceReports: VirusRiskInsightData[];
    thresholds: { [sensor: string]: SensorThresholds };
    possibleRiskFactors: { riskName: string; sensorName: string }[];
    logo?: string;
    comment: string;
    segmentProperties: { [serialNumber: string]: SegmentProperties };
    listOfImages: { data: string; serialNumber: string }[];
    deviceTypesInReport: string[];
    reportDetails: {
        deviceTypes: string[];
        userName: string;
        reportId: string;
    };
};

// Create Document Component
const PdfDocument = React.memo(
    ({
        reportPeriodHeader,
        building,
        txt,
        dateFormat,
        deviceReports,
        thresholds,
        devices,
        devicesInEachLevel,
        reportDetails,
        segmentProperties,
        possibleRiskFactors,
        deviceTypesInReport,
        logo,
        listOfImages,
        comment,
    }: PdfProperties) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Document>
            <Body>
                <HeaderBlock>
                    <View>
                        <Text style={styles.headerText}>{txt('VirusInsight.VirusSummaryHeader')}</Text>
                        <Paragraph>{reportPeriodHeader}</Paragraph>
                    </View>
                    <LogoContainer>
                        <Logo src={logo || BusinessLogo} />
                    </LogoContainer>
                </HeaderBlock>
                <BuildingDetails txt={txt} building={building} dateFormat={dateFormat} />
                <PageLine />
                <Summary txt={txt} devicesInEachLevel={devicesInEachLevel} threshold={thresholds.virusRisk} />
                <PossibleSolutions txt={txt} />
                <PageLine />
                <View break>
                    {deviceReports.map(report => {
                        const device: DeviceType = devices[report.serialNumber];
                        return (
                            <DeviceResult
                                listOfImages={listOfImages}
                                key={`result-${report.serialNumber}`}
                                txt={txt}
                                deviceReport={report}
                                thresholds={thresholds}
                                segmentProps={segmentProperties[report.serialNumber]}
                                device={{
                                    segmentName: device.segmentName,
                                    type: device.type,
                                    serialNumber: device.serialNumber,
                                    locationId: device.locationId,
                                    segmentId: device.segmentId,
                                }}
                                possibleRiskFactors={possibleRiskFactors}
                            />
                        );
                    })}
                </View>
                <ReportDetails
                    userName={reportDetails.userName}
                    dateFormat={dateFormat}
                    txt={txt}
                    reportId={reportDetails.reportId}
                    deviceTypes={deviceTypesInReport}
                    comment={comment}
                />
                <Paragraph style={colorStyles.smallText}>{txt('VirusInsight.ReportDisclaimer')}</Paragraph>
                <View style={styles.footer} fixed>
                    <Text
                        style={colorStyles.smallText}
                        render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }): string =>
                            `${pageNumber} / ${totalPages}`
                        }
                        fixed
                    />
                    <Image style={styles.smallLogo} src={BusinessLogo} />
                </View>
            </Body>
        </Document>
    )
);

const logDownloadReport = (): void => {
    analyticsLogger(INSIGHT_DOWNLOADED_REPORT, { pageType: PageType.Insight, reportType: ReportType.VirusRisk });
};

const GeneratePdf = (props: GenerateProps): React.ReactElement | null => {
    const {
        dateFormat,
        fromDate,
        toDate,
        building,
        deviceReports,
        thresholds,
        devicesInEachLevel,
        devices,
        segmentProperties,
        possibleRiskFactors,
        logo,
        comment,
        reportDetails,
        listOfImages,
    } = props;
    let pdfProps: PdfProperties | undefined;
    const { t: txt } = useTranslation();
    const reportPeriodHeader =
        fromDate && toDate ? txt('RadonInsight.ReportGeneratedPeriod', { from: fromDate, to: toDate }) : '';
    const deviceTypesInReport = reportDetails.deviceTypes.map(
        (type, i) =>
            `Airthings ${txt(`${mapDeviceType(type as AnyDeviceType)}FullName`)}${
                reportDetails.deviceTypes.length > 1 && i < reportDetails.deviceTypes.length - 1 ? ', ' : ''
            }`
    );

    if (building) {
        pdfProps = {
            reportPeriodHeader,
            building,
            txt,
            dateFormat,
            thresholds,
            deviceReports,
            logo,
            comment,
            reportDetails,
            deviceTypesInReport,
            listOfImages,
            devicesInEachLevel,
            segmentProperties,
            possibleRiskFactors,
            devices,
        };
    }
    const [properties, setProperties] = useState<PdfProperties | undefined>(undefined);

    useEffect(() => {
        if (!properties) {
            setProperties(pdfProps);
        }
    }, [pdfProps]);

    if (!properties) {
        return null;
    }

    const fileName = `VirusRiskReport_${properties.building.name}_${fromDate}-${toDate}.pdf`;
    return (
        <div
            className="form__button-container"
            onClick={logDownloadReport}
            onKeyDown={logDownloadReport}
            role="button"
            tabIndex={0}
        >
            <PDFDownloadLink
                document={<PdfDocument {...properties} />}
                fileName={`VirusRiskReport_${properties.building.name}_${fromDate}-${toDate}.pdf`}
            >
                {({ loading }): React.ReactElement => <DownloadPDFView loading={loading} fileName={fileName} />}
            </PDFDownloadLink>
        </div>
    );

    // use this to get inline version of pdf.
    /* return (
        <div className="form form__wide-container">
            <PDFViewer>
                <PdfDocument {...properties} />
            </PDFViewer>
        </div>
    ); */
};

export default GeneratePdf;
