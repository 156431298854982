import React from 'react';
import { dateFormats, sensorUnits } from '../../../../constants';
import StandAloneSpinner from '../../../../img/StandAloneSpinner';
import { RadonInsightSensorData } from './DeviceDetails';
import SimpleRadonReportPdfGenerator from './SimpleRadonReportPdfGenerator';

type Props = {
    serialNumber: string;
    reportData: {
        fromDate: string;
        toDate: string;
        duration: number;
        room: string;
        floor: string;
        ventilation: string;
        buildingType?: string;
        buildingYear?: string;
        address: string;
    };
    unit: keyof typeof sensorUnits;
    data?: RadonInsightSensorData;
    deviceType: string;
    segmentName: string;
    thresholds?: number[];
    dateFormat: keyof typeof dateFormats;
    reportId?: string;
    userName: string;
    loading: boolean;
};

const RadonReportResponse = (props: Props): React.ReactElement => {
    const {
        serialNumber,
        userName,
        reportData,
        segmentName,
        deviceType,
        unit,
        data,
        thresholds,
        dateFormat,
        reportId,
        loading,
    } = props;
    if (!data || !reportId || !thresholds || loading) {
        return (
            <div className="modal__content__spinner">
                <StandAloneSpinner />
            </div>
        );
    }
    return (
        <div>
            <SimpleRadonReportPdfGenerator
                toDate={reportData.toDate}
                fromDate={reportData.fromDate}
                reportData={reportData}
                numberOfDays={reportData.duration.toString()}
                unit={unit}
                data={data}
                serialNumber={serialNumber}
                deviceType={deviceType}
                segmentName={segmentName}
                thresholds={thresholds}
                dateFormat={dateFormat}
                userName={userName}
                reportId={reportId}
            />
        </div>
    );
};

export default RadonReportResponse;
