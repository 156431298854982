import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import RequestActions from 'commons/src/models/RequestTypes';
import displayAlertBoxSaga from 'commons/src/sagas/displayAlertBox';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import {
    ToggleActiveFederatedClient,
    ToggleActiveFederatedClientSuccess,
    toggleActiveFederatedClientSuccess,
    CreateFederatedClient,
    CreateFederatedClientSuccess,
    createFederatedClientSuccess,
    DeleteSsoClient,
    deleteSsoClientSuccess,
    DeleteSsoClientSuccess,
    GetFederatedClientsSuccess,
    getFederatedClientsSuccess,
    SingleSignOnActionType,
    VerifySsoClientDomain,
    verifySsoClientDomainSuccess,
    VerifySsoClientDomainSuccess,
    EditFederatedClientSuccess,
    EditFederatedClient,
    editFederatedClientSuccess,
} from '../actions/singleSignOnActions';
import {
    toggleActiveClient,
    createClient,
    getClientInfo,
    deleteClient,
    verifyClientDomain,
    editClient,
} from '../api/singleSignOnApi';
import {
    ActivatedClientResponse,
    SingleSignOnClient,
    SingleSignOnResponse,
    VerifyClientDomainResponse,
} from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type CreateFederatedClientReturnType = Generator<
    CallEffect<SingleSignOnClient> | PutEffect<CreateFederatedClientSuccess> | RequestActions,
    void,
    SingleSignOnClient
>;

export function* createFederatedClient({ request }: CreateFederatedClient): CreateFederatedClientReturnType {
    const { issuer, domain, clientId, clientSecret, scope } = request;
    try {
        const response: SingleSignOnClient = yield call(createClient, {
            issuer,
            domain,
            clientId,
            clientSecret,
            scope,
        });
        yield put(createFederatedClientSuccess(response));
        yield put(requestSuccess(RequestType.CreateFederatedClient, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.CreateFederatedClient, RequestActionType.Error));
    }
}

type EditFederatedClientReturnType = Generator<
    CallEffect<void> | CallEffect | PutEffect<EditFederatedClientSuccess> | RequestActions,
    void,
    SingleSignOnClient
>;

export function* editFederatedClient({ request, singleSignOnId }: EditFederatedClient): EditFederatedClientReturnType {
    const { issuer, domain, clientId, clientSecret, scope } = request;
    try {
        yield call(
            editClient,
            {
                issuer,
                domain,
                clientId,
                clientSecret,
                scope,
            },
            singleSignOnId
        );
        yield put(editFederatedClientSuccess({ singleSignOnId, clientId, scope, issuer }));
        yield put(requestSuccess(RequestType.EditFederatedClient, RequestActionType.Success));
        yield call(displayAlertBoxSaga, 'ChangeSuccessMessage', false, true);
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.EditFederatedClient, RequestActionType.Error));
    }
}

type ToggleActiveFederatedClientReturnType = Generator<
    CallEffect<ActivatedClientResponse> | PutEffect<ToggleActiveFederatedClientSuccess> | RequestActions,
    void,
    ActivatedClientResponse
>;

export function* toggleActiveFederatedClient({
    singleSignOnId,
    active,
}: ToggleActiveFederatedClient): ToggleActiveFederatedClientReturnType {
    try {
        yield call(toggleActiveClient, singleSignOnId, active);
        yield put(toggleActiveFederatedClientSuccess({ singleSignOnId, active }));
        yield put(requestSuccess(RequestType.ToggleActiveFederatedClient, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.ToggleActiveFederatedClient, RequestActionType.Error));
    }
}

type GetFederatedClientReturnType = Generator<
    CallEffect<SingleSignOnResponse> | PutEffect<GetFederatedClientsSuccess> | RequestActions,
    void,
    SingleSignOnResponse
>;

export function* getFederatedClient(): GetFederatedClientReturnType {
    try {
        const response: SingleSignOnResponse = yield call(getClientInfo);
        yield put(getFederatedClientsSuccess(response));
        yield put(requestSuccess(RequestType.GetFederatedClients, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.GetFederatedClients, RequestActionType.Error));
    }
}

type DeleteSsoClientReturnType = Generator<
    CallEffect<void> | PutEffect<DeleteSsoClientSuccess> | RequestActions,
    void,
    string
>;

export function* deleteSsoClient({ singleSignOnId }: DeleteSsoClient): DeleteSsoClientReturnType {
    try {
        yield call(deleteClient, singleSignOnId);
        yield put(deleteSsoClientSuccess(singleSignOnId));
        yield put(requestSuccess(RequestType.DeleteSsoClient, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.DeleteSsoClient, RequestActionType.Error));
    }
}

type VerifySsoClientDomainReturnType = Generator<
    CallEffect<VerifyClientDomainResponse> | PutEffect<VerifySsoClientDomainSuccess> | RequestActions,
    void,
    VerifyClientDomainResponse
>;

export function* verifySsoClientDomain({ singleSignOnId }: VerifySsoClientDomain): VerifySsoClientDomainReturnType {
    try {
        const response: VerifyClientDomainResponse = yield call(verifyClientDomain, singleSignOnId);
        yield put(verifySsoClientDomainSuccess(response));
        yield put(requestSuccess(RequestType.VerifySsoClientDomain, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.VerifySsoClientDomain, RequestActionType.Error));
    }
}

export default function* singleSignOnSaga(): Generator {
    yield all([
        takeEvery(SingleSignOnActionType.CreateFederatedClientInit, createFederatedClient),
        takeEvery(SingleSignOnActionType.EditFederatedClientInit, editFederatedClient),
        takeEvery(SingleSignOnActionType.ToggleActiveFederatedClientInit, toggleActiveFederatedClient),
        takeEvery(SingleSignOnActionType.GetFederatedClientsInit, getFederatedClient),
        takeEvery(SingleSignOnActionType.DeleteSsoClientInit, deleteSsoClient),
        takeEvery(SingleSignOnActionType.VerifySsoClientDomainInit, verifySsoClientDomain),
    ]);
}
