import { useContext, useEffect } from 'react';
import { SearchContext } from './Search';

type UseSearchCallback = (
    search: <T extends Record<string, unknown>>(data: T[]) => T[],
    searchTerm: string | null
) => void;

const useSearch = (cb?: UseSearchCallback): null => {
    const { search, searchValue } = useContext(SearchContext);

    useEffect((): void => {
        if (cb) {
            cb(search(searchValue), searchValue);
        }
    }, [searchValue]);

    return null;
};

export default useSearch;
