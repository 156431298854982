import { SelectGroupActionType, SelectGroupReducerActions } from 'commons/src/actions/selectGroupActions';
import { OrganizationMemberAction, OrganizationMemberActionType } from '../actions/organizationMemberActions';
import { SingleSignOnAction, SingleSignOnActionType } from '../actions/singleSignOnActions';
import { InviteCreatedResponse, InvitedMember, Member, SingleSignOnClient } from '../models/common';

export interface OrganizationStore {
    members: Member[];
    invited: InvitedMember[];
    singleSignOnClients: SingleSignOnClient[];
}

const initialStore: OrganizationStore = {
    invited: [],
    members: [],
    singleSignOnClients: [],
};

const makePerson = (invite: InviteCreatedResponse): InvitedMember => ({
    inviteId: invite.inviteId,
    name: invite.invitedUserName,
    email: invite.invitedUserEmail,
    status: invite.status,
    role: invite.role,
});

const organizationReducer = (
    store: OrganizationStore = initialStore,
    action: OrganizationMemberAction | SingleSignOnAction | SelectGroupReducerActions
): OrganizationStore => {
    switch (action.type) {
        case OrganizationMemberActionType.GetOrganizationMembersSuccess:
            return {
                ...store,
                members: action.response.members,
                invited: action.response.invited,
            };
        case OrganizationMemberActionType.SendInviteSuccess:
            return {
                ...store,
                members: store.members,
                invited: [...store.invited, makePerson(action.response)],
            };
        case OrganizationMemberActionType.DeleteInviteSuccess:
            return {
                ...store,
                members: store.members,
                invited: store.invited.filter(member => member.inviteId !== action.inviteId),
            };
        case OrganizationMemberActionType.RemoveMemberSuccess:
            return {
                ...store,
                members: store.members.filter(member => member.userId !== action.userId),
                invited: store.invited,
            };
        case OrganizationMemberActionType.ChangeMembershipRoleSuccess: {
            const indexOfEditedMember = store.members.findIndex(member => member.userId === action.userId);
            const copyOfMembersList = [...store.members];
            copyOfMembersList[indexOfEditedMember] = {
                ...copyOfMembersList[indexOfEditedMember],
                ...action.payload,
                role: action.payload.role,
                resources: action.payload.resources,
                name: action.payload.name,
            };
            return {
                ...store,
                members: copyOfMembersList,
            };
        }
        case SingleSignOnActionType.CreateFederatedClientSuccess:
            return {
                ...store,
                singleSignOnClients: [...store.singleSignOnClients, action.response],
            };
        case SingleSignOnActionType.GetFederatedClientsSuccess:
            return {
                ...store,
                singleSignOnClients: action.response.singleSignOnClients,
            };
        case SingleSignOnActionType.EditFederatedClientSuccess:
            return {
                ...store,
                singleSignOnClients: (store.singleSignOnClients ? [...store.singleSignOnClients] : []).map(client => {
                    if (client.singleSignOnId === action.response.singleSignOnId) {
                        return {
                            ...client,
                            clientId: action.response.clientId,
                            issuer: action.response.issuer,
                            scope: action.response.scope,
                        };
                    }
                    return client;
                }),
            };
        case SingleSignOnActionType.DeleteSsoClientSuccess: {
            return {
                ...store,
                singleSignOnClients: store.singleSignOnClients.filter(
                    client => client.singleSignOnId !== action.singleSignOnId
                ),
            };
        }
        case SingleSignOnActionType.ToggleActiveFederatedClientSuccess:
            return {
                ...store,
                singleSignOnClients: (store.singleSignOnClients ? [...store.singleSignOnClients] : []).map(client => {
                    if (client.singleSignOnId === action.response.singleSignOnId) {
                        return { ...client, active: action.response.active };
                    }
                    return client;
                }),
            };
        case SingleSignOnActionType.VerifySsoClientDomainSuccess:
            return {
                ...store,
                singleSignOnClients: (store.singleSignOnClients ? [...store.singleSignOnClients] : []).map(client => {
                    if (client.domain === action.response.emailDomain) {
                        return {
                            ...client,
                            domainTxtRecordVerifiedAt: action.response.date,
                        };
                    }
                    return client;
                }),
            };
        case SelectGroupActionType.SelectGroupSuccess:
            return initialStore;
        default:
            return store;
    }
};

export default organizationReducer;
