import React from 'react';
import { useTranslation } from 'react-i18next';
import RoomOccupancyImg from 'commons/src/img/Illustrations/VirusRisk/room-occupancy.svg';
import SurvivalRateImg from 'commons/src/img/Illustrations/VirusRisk/survival-rate.svg';
import TransmissionEfficiencyImg from 'commons/src/img/Illustrations/VirusRisk/transmission-efficiency.svg';
import VentilationRateImg from 'commons/src/img/Illustrations/VirusRisk/ventilation-rate.svg';

const VirusInfoFactorsComponent = (): React.ReactElement => {
    const { t: txt } = useTranslation();

    return (
        <div className="modal__content__body">
            <p>{txt('InsightRiskFactor.VirusRiskExplained')}</p>
            <div className="modal__content__risk-factors">
                <div className="modal__content__risk-factors__element">
                    <img alt={txt('InsightRiskFactor.SurvivalRateIllustration')} src={SurvivalRateImg} />
                    <h2>{txt('InsightRiskFactor.virusSurvivalRate')}</h2>
                    <p>{txt('InsightRiskFactor.VirusSurvivalRateDescription')}</p>
                </div>
                <div className="modal__content__risk-factors__element">
                    <img alt={txt('InsightRiskFactor.RoomOccupancyIllustration')} src={RoomOccupancyImg} />
                    <h2>{txt('InsightRiskFactor.roomOccupancy')}</h2>
                    <p>{txt('InsightRiskFactor.RoomOccupancyDescription')}</p>
                </div>
                <div className="modal__content__risk-factors__element">
                    <img alt={txt('InsightRiskFactor.VentilationRateIllustration')} src={VentilationRateImg} />
                    <h2>{txt('InsightRiskFactor.ventilationRate')}</h2>
                    <p>{txt('InsightRiskFactor.VentilationRateDescription')}</p>
                </div>
                <div className="modal__content__risk-factors__element">
                    <img
                        alt={txt('InsightRiskFactor.TransmissionEfficiencyIllustration')}
                        src={TransmissionEfficiencyImg}
                    />
                    <h2>{txt('InsightRiskFactor.transmissionEfficiency')}</h2>
                    <p>{txt('InsightRiskFactor.TransmissionEfficiencyDescription')}</p>
                </div>
            </div>
        </div>
    );
};

export default VirusInfoFactorsComponent;
