import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { statusColor } from 'commons/src/commonFunctions';
import {
    FlexWrapper,
    SensorColumns,
    ColoredCircle,
    coloredCircleStyles,
    colorStyles,
    ColoredDot,
} from 'commons/src/components/PDF/PdfConstants';
import { sensorUnits, colors } from 'commons/src/constants';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { ThresholdRange } from 'commons/src/models/commonTypeScript';
import { PDFContext } from '../../../../models/common';
import { DeviceReport } from '../../iaqInsight/ResultTable';

const BORDER_COLOR = colors.greyMist;
const COL1_WIDTH = 33;
const COLN_WIDTH = (100 - COL1_WIDTH) / 3;

const styles = StyleSheet.create({
    greyBackground: {
        backgroundColor: colors.greyPorcelain,
    },
    firstColumn: {
        width: `${COL1_WIDTH}%`,
    },
    smallCircle: {
        height: '28px',
        width: '28px',
        borderWidth: 3.5,
        marginRight: '5px',
    },
    lightText: {
        fontSize: '10',
        color: colors.greyShuttle,
    },
    boldText: {
        fontSize: '10',
        fontFamily: 'DemiBold',
    },
    deviceNameStatusView: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row',
    },
    headerCell: {
        width: `${COLN_WIDTH}%`,
        padding: '5',
    },
    tableCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '40',
        width: `${COLN_WIDTH}%`,
        borderTop: `1 solid ${BORDER_COLOR}`,
    },
});

type Props = {
    txt: PDFContext;
    sensor: string;
    ranges: ThresholdRange[];
    unit: keyof typeof sensorUnits;
    sensorColumns: SensorColumns;
};
const DataTable = (props: Props): React.ReactElement => {
    const { txt, sensor, ranges, sensorColumns, unit } = props;
    const headers = ['AverageValue', 'InsightIaq.LowestMeasurement', 'InsightIaq.HighestMeasurement'];

    const deviceNameAndStatus = (device: DeviceReport): React.ReactElement => {
        const color = statusColor(ranges, device.average);
        return (
            <View style={styles.deviceNameStatusView}>
                <ColoredCircle
                    style={[coloredCircleStyles[color as keyof typeof coloredCircleStyles], styles.smallCircle]}
                />
                <View>
                    <Text style={colorStyles.smallText}>{device.name}</Text>
                    <Text style={styles.lightText}>
                        {device.roomType ? txt(`RoomTypeOptions.${device.roomType}`) : ''}
                    </Text>
                </View>
            </View>
        );
    };

    const valueStatus = (value: number | undefined, isAverage?: boolean): React.ReactElement | null => {
        const color = statusColor(ranges, value);
        const upperCaseUnit = unit.toString().toUpperCase();
        const displayedUnit = sensor === SensorTypes.temp ? `${sensorUnits[unit]}${upperCaseUnit}` : sensorUnits[unit];
        if (value === undefined) {
            if (isAverage) {
                return <Text style={colorStyles.smallText}>{txt('NoData')}</Text>;
            }
            return null;
        }
        return (
            <FlexWrapper>
                <ColoredDot style={colorStyles[color as keyof typeof colorStyles]} />
                <Text style={styles.boldText}>{`${value} `}</Text>
                <Text style={colorStyles.smallText}>{displayedUnit}</Text>
            </FlexWrapper>
        );
    };

    return (
        <View>
            <View>
                <View style={styles.tableRow}>
                    <View style={[styles.headerCell, styles.firstColumn]}>
                        <Text style={styles.boldText}>{txt('InsightIaq.DetailsWithinOpeningHours')}</Text>
                    </View>
                    {headers.map(headerText => (
                        <View style={styles.headerCell} key={`pdf-headers-${headerText}`}>
                            <Text style={colorStyles.smallText}>{txt(headerText)}</Text>
                        </View>
                    ))}
                </View>
                {sensorColumns.map(device => (
                    <View style={styles.tableRow} wrap={false} key={`${device.serialNumber}-${sensor}`}>
                        <View style={[styles.tableCell, styles.firstColumn]}>{deviceNameAndStatus(device)}</View>
                        <View style={styles.tableCell}>{valueStatus(device.average, true)}</View>
                        <View style={styles.tableCell}>{valueStatus(device.lowestMeasurement)}</View>
                        <View style={styles.tableCell}>{valueStatus(device.highestMeasurement)}</View>
                    </View>
                ))}
            </View>
        </View>
    );
};

export default DataTable;
