import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import LabeledText from 'commons/src/components/LabeledText';
import { dateFormats } from 'commons/src/constants';
import { SubscriptionInfo } from '../../../../models/subscriptionModels';
import styles from './BillingInfoSection.module.scss';

type ParentProps = {
    previewCostTable: React.ReactElement;
    subscriptionInfo: SubscriptionInfo;
    dateFormat: keyof typeof dateFormats;
    subscriptionNumber?: string;
};
const BillingInfoSection = ({
    previewCostTable,
    subscriptionInfo,
    dateFormat,
    subscriptionNumber,
}: ParentProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { startDate, purchaseOrderNumber, invoiceSeparately, servicePeriodText } = subscriptionInfo;
    return (
        <div className={styles.infoWrapper}>
            <div className={styles.subscriptionInfo}>
                <LabeledText
                    noPadding
                    label="StartDate"
                    value={dayjs(startDate).format(dateFormats[dateFormat].format)}
                    id="SubscriptionStartDate"
                    largeText
                />
                {subscriptionNumber && (
                    <LabeledText
                        noPadding
                        label="Subscription.SubscriptionId"
                        value={subscriptionNumber || ''}
                        id="SubscriptionId"
                        largeText
                    />
                )}
                <LabeledText
                    noPadding
                    label="Subscription.PONumber"
                    value={purchaseOrderNumber}
                    id="purchaseOrderRef"
                    largeText
                />
                <LabeledText
                    noPadding
                    label="Subscription.ServicePeriod"
                    value={servicePeriodText}
                    id="servicePeriod"
                    largeText
                />
                <LabeledText
                    noPadding
                    label="Subscription.SeparateInvoice"
                    value={invoiceSeparately ? txt('Yes') : txt('No')}
                    id="separateInvoice"
                    largeText
                />
            </div>
            <div className={styles.subscriptionDetailsWrapper}>{previewCostTable}</div>
        </div>
    );
};
export default BillingInfoSection;
