import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
    fetchCustomDeviceSegment,
    FetchCustomDeviceSegment,
    pollDeviceData,
    PollDeviceData,
    stopPollDeviceData,
    StopPollDeviceData,
} from '../../actions/DeviceActions';
import Error from '../../components/errorComponents/Error';
import { pageNotFoundUrl } from '../../components/errorComponents/PageNotFound';
import { getSelectedGroupFromStorage } from '../../components/findUserType';
import { sensorGraphPeriods } from '../../constants';
import { FullDeviceData, SensorData, SelectedPeriod, SelectedGroup } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import DevicePageBody from './DevicePageBody';

type ConnectedProps = {
    devicePageDevices: { [serialNumber: string]: FullDeviceData };
    sensorData: { [serialNumber: string]: SensorData };
    userSettingsLoading: boolean;
    devicePageLoading: boolean;
    dateFormat: string;
    fetching: boolean;
    loadingApp: boolean;
    isLoggedIn: boolean;
    error: boolean;
};

type ActionProps = {
    fetchCustomSegment: (serialNumber: string, selectedPeriod: SelectedPeriod, isCustom?: boolean) => void;
    stopPollingDeviceData: () => void;
    onPollDeviceData: (
        arg0: {
            serialNumber: string;
            selectedInterval: SelectedPeriod;
            fetching: boolean;
            loading: boolean;
        },
        withPolling?: boolean
    ) => void;
};

type Props = ConnectedProps & ActionProps;

const AppDevicePageComponent = ({
    devicePageDevices,
    devicePageLoading,
    dateFormat,
    sensorData,
    error,
    fetchCustomSegment,
    stopPollingDeviceData,
    onPollDeviceData,
    fetching,
    loadingApp,
    userSettingsLoading,
    isLoggedIn,
}: Props): React.ReactElement => {
    const { device: serialNumber } = useParams() as { device: string };
    const navigate = useNavigate();
    const [selectedPeriod, setSelectedPeriod] = useState<SelectedPeriod>(sensorGraphPeriods.week);
    const selectedGroupInLocalStorage: SelectedGroup | null = getSelectedGroupFromStorage();

    const fetchSensorData = (setFetching: boolean, setLoading: boolean): void => {
        if (selectedGroupInLocalStorage?.userGroupId) {
            onPollDeviceData(
                {
                    serialNumber,
                    selectedInterval: sensorGraphPeriods.week,
                    fetching: setFetching,
                    loading: setLoading,
                },
                false
            );
        }
    };

    useEffect(() => {
        const rootElement = document.getElementById('root');
        if (serialNumber === undefined) {
            navigate(pageNotFoundUrl('serialNumberNotDefined'));
        } else if (!loadingApp && isLoggedIn && !fetching) {
            fetchSensorData(!devicePageDevices[serialNumber], !devicePageDevices[serialNumber]);
        }
        if (rootElement) {
            rootElement.className = 'no-margin';
        }
    }, [loadingApp]);

    const updateSelectedPeriod = (value: SelectedPeriod): void => setSelectedPeriod(value);

    const device = devicePageDevices[serialNumber] || {};
    const chartData = sensorData[serialNumber] || {};

    const firstSensor = Object.keys(chartData)[0];
    const missingChartData = !firstSensor || (firstSensor && !chartData[firstSensor][selectedPeriod.name]);
    if (error && !fetching && !devicePageLoading && missingChartData) {
        return <Error />;
    }

    return (
        <div className="container">
            <DevicePageBody
                deviceId={serialNumber}
                devicePageLoading={devicePageLoading || userSettingsLoading}
                fetching={fetching}
                dateFormat={dateFormat}
                device={device}
                endedSegment={false}
                lastRecord={device && device.latestSample}
                fetchCustomSegment={fetchCustomSegment}
                serialNumber={serialNumber}
                selectedPeriod={selectedPeriod}
                updateSelectedPeriod={updateSelectedPeriod}
                onPollDeviceData={onPollDeviceData}
                stopPollingDeviceData={stopPollingDeviceData}
                fetchedDevices={devicePageDevices}
                withPolling={false}
            />
        </div>
    );
};

const mapStateToProps = (state: Store): ConnectedProps => {
    const {
        devicePage: { devices: devicePageDevices, loading: devicePageLoading, fetching, errorCode },
        deviceSensorData: { sensorData },
        userSettings: { dateFormat, loading: userSettingsLoading },
        app: { loading: loadingApp, error: appError },
        login: { isLoggedIn },
    } = state;

    return {
        devicePageDevices,
        devicePageLoading,
        userSettingsLoading,
        fetching,
        dateFormat,
        sensorData,
        loadingApp,
        isLoggedIn,
        error: !!errorCode || appError,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    fetchCustomSegment: (serialNumber, timeRange): FetchCustomDeviceSegment =>
        dispatch(fetchCustomDeviceSegment(serialNumber, timeRange)),
    onPollDeviceData: (payload, withPolling): PollDeviceData => dispatch(pollDeviceData(payload, withPolling)),
    stopPollingDeviceData: (): StopPollDeviceData => dispatch(stopPollDeviceData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppDevicePageComponent);
