import React from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import ForbiddenAccess from 'commons/src/components/errorComponents/ForbiddenAccess';
import PageNotFound from 'commons/src/components/errorComponents/PageNotFound';
import SpinnerPage from 'commons/src/components/placeholder/SpinnerPage';
import { roleRestrictions } from 'commons/src/constants';
import AddModule from 'commons/src/features/addModule/AddModule';
import AddModuleType from 'commons/src/features/addModule/AddModuleType';
import HomeReportV2 from 'commons/src/features/CorentiumHomeReportFormPage/HomeReportV2';
import Dashboard from 'commons/src/features/dashboard/Dashboard';
import AddPublicDashboard from 'commons/src/features/dashboard/PublicDashboardSettings/AddPublicDashboard';
import AppDevicePage from 'commons/src/features/devicePage/AppDevicePage';
import GenerateRadonReportPage from 'commons/src/features/devicePage/dropdownOptions/SimpleRadonReport/GenerateRadonReportPage';
import AddEditApiClient from 'commons/src/features/integrations/apiIntegration/AddEditApiClient';
import ApiIntegrationPage from 'commons/src/features/integrations/apiIntegration/ApiIntegrationPage';
import OutdoorAirQualityPage from 'commons/src/features/outdoorAirQualityPage/OutdoorAirQualityPage';
import SecuritySettings from 'commons/src/features/settingsPage/security/SecuritySettings';
import UnsubscribePage from 'commons/src/features/unsubscribeEmailPage/UnsubscribePage';
import { FeatureToggleEnum, RequiredRoleLevel } from 'commons/src/models/commonEnums';
import Protected from 'commons/src/Protected';
import BusinessAccountOverview from '../features/account/BusinessAccountOverview';
import BusinessAccountPageLayout from '../features/account/BusinessAccountPageLayout';
import OrganizationConfig from '../features/account/organization/OrganizationConfigComponent';
import CreateSubscription from '../features/account/subscription/CreateSubscription';
import Subscription from '../features/account/subscription/Subscription';
import UpdateSubscription from '../features/account/subscription/UpdateSubscription';
import UserManagement from '../features/account/userManagement/UserManagement';
import AddEditAlert from '../features/alerts/addEdit/AddEditAlert';
import AlertsOverview from '../features/alerts/AlertsOverview';
import AlertsPageLayout from '../features/alerts/AlertsPageLayout';
import AlertEventsOverview from '../features/alerts/history/AlertEventsOverview';
import AlertHistory from '../features/alerts/history/AlertHistory';
import AnalyticsPage from '../features/analytics/AnalyticsPage';
import AddBuilding from '../features/buildings/addBuilding/AddBuilding';
import BuildingInsights from '../features/buildings/building/BuildingInsights';
import BuildingPageLayout from '../features/buildings/building/BuildingPage';
import BuildingVirtualDevices from '../features/buildings/building/BuildingVirtualDevices';
import BuildingSettings from '../features/buildings/buildingEditDetails/BuildingSettings';
import ThresholdBreachReportPage from '../features/buildings/buildingInsight/ThresholdBreachReport/ThresholdBreachReportPage';
import Buildings from '../features/buildings/Buildings';
import AddGroup from '../features/buildings/deviceGroup/AddGroup';
import EmailSummary from '../features/buildings/emailSummary/EmailSummary';
import DeviceOverview from '../features/device/DeviceOverview';
import DevicePageLayout from '../features/device/DevicePageLayout';
import DeviceSettings from '../features/device/dropdownOptions/DeviceSettings';
import PreviousMeasurementPage from '../features/device/PreviousMeasurementPage';
import IntegrationsOverview from '../features/integrations/IntegrationsOverview';
import IntegrationsPageLayout from '../features/integrations/IntegrationsPageLayout';
import AddEditMqttClient from '../features/integrations/mqtt/AddEditMqttClient';
import MqttPage from '../features/integrations/mqtt/MqttPage';
import AddThirdPartyIntegration from '../features/integrations/thirdParty/AddThirdPartyIntegration';
import EditThirdPartyMapping from '../features/integrations/thirdParty/EditThirdPartyMapping';
import ThirdPartyPage from '../features/integrations/thirdParty/ThirdPartyPage';
import AddEditWebhook from '../features/integrations/webhooks/AddEditWebhook';
import WebhooksPage from '../features/integrations/webhooks/WebhooksPage';
import PartnerAddCustomer from '../features/partner/addCustomer/PartnerAddCustomer';
import PartnerSubAccountDetails from '../features/partner/customerPage/PartnerSubAccountDetails';
import PartnerSubAccountLayout from '../features/partner/customerPage/PartnerSubAccountLayout';
import PartnerOverview from '../features/partner/PartnerOverview';
import PartnerPage from '../features/partner/PartnerPage';
import PartnerStatus from '../features/partner/PartnerStatus';
import PartnerCreateSubscription from '../features/partner/subscription/PartnerCreateSubscription';
import PartnerUpdateSubscription from '../features/partner/subscription/PartnerUpdateSubscription';
import PartnerSummary from '../features/partner/summary/PartnerSummary';
import CalibrationCertificate from '../features/reports/calibrationCertificate/CreateCalibrationCertificate';
import IaqCertificatePage from '../features/reports/iaqCertificate/IaqCertificatePage';
import IAQInsightPage from '../features/reports/iaqInsight/IAQInsightPage';
import InsightMainPage from '../features/reports/insightMenuPage/InsightMainPage';
import OutdoorInsightPage from '../features/reports/outdoorInsight/OutdoorInsightPage';
import RadonInsightPage from '../features/reports/radonInsight/RadonInsightPage';
import VirusRiskPage from '../features/reports/virusRisk/VirusRiskPage';
import SpacePageRoute from '../features/spaces/space/SpacePage';
import SpacesLandingPage from '../features/spaces/spaces/SpacesLandingPage';
import BuildingsHealthStatus from '../features/status/buildingsStatus/BuildingsHealthStatus';
import BuildingStatus from '../features/status/buildingStatus/BuildingStatus';

export type DashboardRoute = RouteObject;

export const publicRoutes: DashboardRoute[] = [
    { path: 'home-report', element: <HomeReportV2 /> },
    { path: 'app-devices/:device', element: <AppDevicePage /> },
    { path: 'registration', element: <SpinnerPage /> },
    { path: 'unsubscribe/integrations', element: <UnsubscribePage /> },
];

const index = true;
export const businessRoutes: DashboardRoute[] = [
    { index, element: <Buildings /> },
    {
        path: 'dashboard',
        element: <Dashboard />,
    },
    {
        path: 'add-dashboard',
        element: (
            <Protected minRoleLevel={roleRestrictions.editPublicDashboard}>
                <AddPublicDashboard />
            </Protected>
        ),
    },
    {
        path: 'dashboard-settings',
        element: (
            <Protected minRoleLevel={roleRestrictions.editPublicDashboard}>
                <AddPublicDashboard />
            </Protected>
        ),
    },
    {
        path: 'add-tile',
        children: [
            { index, element: <AddModule /> },
            { path: ':type', element: <AddModuleType /> },
        ],
    },
    {
        path: 'outdoor',
        children: [{ path: ':locationId', element: <OutdoorAirQualityPage /> }],
    },
    {
        path: 'reports',
        element: <Protected minRoleLevel={roleRestrictions.reports} />,
        children: [
            { index, element: <InsightMainPage /> },
            { path: 'radon', element: <RadonInsightPage /> },
            { path: 'iaq-certificate', element: <IaqCertificatePage /> },
            { path: 'iaq', element: <IAQInsightPage /> },
            { path: 'virus-risk', element: <VirusRiskPage /> },
            { path: 'outdoor', element: <OutdoorInsightPage /> },
            { path: 'email-summary', element: <EmailSummary /> },
            { path: 'calibration-certificate', element: <CalibrationCertificate /> },
        ],
    },
    {
        path: 'settings',
        element: <BusinessAccountPageLayout />,
        children: [
            { index, element: <BusinessAccountOverview /> },
            { path: 'security', element: <SecuritySettings /> },
            { path: 'organization', element: <OrganizationConfig /> },
            {
                path: 'user',
                element: (
                    <Protected minRoleLevel={roleRestrictions.usersAndOrgManagement}>
                        <UserManagement />
                    </Protected>
                ),
            },
            {
                path: 'subscription',
                element: (
                    <Protected minRoleLevel={roleRestrictions.subscriptions}>
                        <Subscription />
                    </Protected>
                ),
            },
        ],
    },
    {
        path: 'settings/subscription/start-subscription',
        element: (
            <Protected minRoleLevel={roleRestrictions.subscriptions}>
                <CreateSubscription />
            </Protected>
        ),
    },
    {
        path: 'settings/subscription/add-seats/:subscriptionNumber',
        element: (
            <Protected minRoleLevel={roleRestrictions.subscriptions}>
                <UpdateSubscription />
            </Protected>
        ),
    },
    {
        path: 'devices/:serialNumber',
        children: [
            {
                path: '*',
                element: <DevicePageLayout />,
                children: [
                    { index, element: <DeviceOverview /> },
                    {
                        path: 'change-location',
                        element: (
                            <Protected minRoleLevel={roleRestrictions.editDeviceOrBuilding}>
                                <DeviceSettings />
                            </Protected>
                        ),
                    },
                    {
                        path: 'report',
                        element: <Protected minRoleLevel={roleRestrictions.reports} />,
                        children: [{ path: 'radon', element: <GenerateRadonReportPage /> }],
                    },
                ],
            },
            {
                path: ':segmentId',
                children: [
                    { index, element: <PreviousMeasurementPage /> },
                    {
                        path: 'report',
                        element: <Protected minRoleLevel={roleRestrictions.reports} />,
                        children: [{ path: 'radon', element: <GenerateRadonReportPage /> }],
                    },
                ],
            },
        ],
    },
    {
        path: 'analytics',
        children: [
            {
                index,
                element: (
                    <Protected
                        minRoleLevel={RequiredRoleLevel.ABOVE_ADMIN}
                        featureToggle={FeatureToggleEnum.quicksightIntegration}
                    >
                        <AnalyticsPage />
                    </Protected>
                ),
            },
        ],
    },
    {
        path: 'buildings',
        children: [
            { index, element: <Buildings /> },
            {
                path: 'add-building',
                element: (
                    <Protected minRoleLevel={roleRestrictions.editBuilding}>
                        <AddBuilding />
                    </Protected>
                ),
            },
            {
                path: 'email-summary',
                element: (
                    <Protected minRoleLevel={roleRestrictions.editDeviceOrBuilding}>
                        <EmailSummary />
                    </Protected>
                ),
            },
            {
                path: ':buildingId/add-group',
                element: <Protected minRoleLevel={roleRestrictions.editDeviceOrBuilding} />,
                children: [
                    { index, element: <AddGroup /> },
                    { path: 'edit-device/:device', element: <DeviceSettings /> },
                ],
            },
            {
                path: ':buildingId/insights/threshold-breach',
                element: <ThresholdBreachReportPage />,
            },
            {
                path: ':buildingId',
                element: <BuildingPageLayout />,
                children: [
                    {
                        index,
                        element: <SpacesLandingPage />,
                    },
                    { path: 'spaces', element: <SpacesLandingPage /> },
                    { path: 'spaces/:spaceId', element: <SpacePageRoute /> },
                    { path: 'insights', element: <BuildingInsights /> },
                    { path: 'control', element: <BuildingVirtualDevices /> },
                    { path: 'settings', element: <BuildingSettings /> },
                    { path: 'status', element: <BuildingStatus /> },
                ],
            },
        ],
    },
    {
        path: 'alerts',
        element: (
            <Protected minRoleLevel={roleRestrictions.alerts} accessWithResourceFilter={false}>
                <AlertsPageLayout />
            </Protected>
        ),
        children: [
            { index, element: <AlertsOverview /> },
            { path: 'history', element: <AlertEventsOverview /> },
        ],
    },
    {
        path: 'alerts',
        element: <Protected minRoleLevel={roleRestrictions.alerts} accessWithResourceFilter={false} />,
        children: [
            { path: 'history/:historyId', element: <AlertHistory /> },
            { path: 'add-alert', element: <AddEditAlert /> },
            { path: 'alert/:alertId', element: <AddEditAlert /> },
        ],
    },
    {
        path: 'integrations',
        element: (
            <Protected minRoleLevel={roleRestrictions.thirdPartyIntegration} accessWithResourceFilter={false}>
                <IntegrationsPageLayout />
            </Protected>
        ),
        children: [
            { index, element: <IntegrationsOverview /> },
            { path: 'webhooks', element: <WebhooksPage /> },
            { path: 'api-integration', element: <ApiIntegrationPage /> },
            { path: 'third-party', element: <ThirdPartyPage /> },
            { path: 'mqtt', element: <MqttPage /> },
        ],
    },
    {
        path: 'integrations',
        children: [
            {
                path: 'webhooks',
                element: <Protected minRoleLevel={roleRestrictions.webhooks} accessWithResourceFilter={false} />,
                children: [
                    { path: 'add-webhook', element: <AddEditWebhook /> },
                    { path: ':webhookId', element: <AddEditWebhook /> },
                ],
            },
            {
                path: 'api-integration',
                element: <Protected minRoleLevel={roleRestrictions.api} accessWithResourceFilter={false} />,
                children: [
                    { path: 'add-api-client', element: <AddEditApiClient /> },
                    { path: ':clientId', element: <AddEditApiClient /> },
                ],
            },
            {
                path: 'mqtt',
                element: <Protected minRoleLevel={roleRestrictions.mqtt} accessWithResourceFilter={false} />,
                children: [
                    { path: 'add-mqtt-client', element: <AddEditMqttClient /> },
                    { path: ':clientId', element: <AddEditMqttClient /> },
                ],
            },
            {
                path: 'third-party',
                element: (
                    <Protected minRoleLevel={roleRestrictions.thirdPartyIntegration} accessWithResourceFilter={false} />
                ),
                children: [
                    { path: 'add-third-party-integration', element: <AddThirdPartyIntegration /> },
                    { path: ':integrationId', element: <EditThirdPartyMapping /> },
                ],
            },
        ],
    },
    {
        path: 'partner',
        element: (
            <Protected minRoleLevel={roleRestrictions.partner}>
                <PartnerPage />
            </Protected>
        ),
        children: [
            { index, element: <PartnerOverview /> },
            { path: 'status', element: <PartnerStatus /> },
            { path: 'summary', element: <PartnerSummary /> },
        ],
    },
    {
        path: 'partner',
        children: [
            {
                path: 'add-customer',
                element: (
                    <Protected minRoleLevel={roleRestrictions.partnerAddCustomer}>
                        <PartnerAddCustomer />
                    </Protected>
                ),
            },
            {
                path: ':childUserGroupId/start-subscription',
                element: (
                    <Protected minRoleLevel={roleRestrictions.subscriptions}>
                        <PartnerCreateSubscription />
                    </Protected>
                ),
            },
            {
                path: ':childUserGroupId/add-seats/:subscriptionNumber',
                element: (
                    <Protected minRoleLevel={roleRestrictions.subscriptions}>
                        <PartnerUpdateSubscription />
                    </Protected>
                ),
            },
            {
                path: ':childUserGroupId',
                element: <PartnerSubAccountLayout />,
                children: [{ index, element: <PartnerSubAccountDetails /> }],
            },
        ],
    },
    {
        path: 'status',
        element: (
            <Protected minRoleLevel={roleRestrictions.buildingsStatus}>
                <BuildingsHealthStatus />
            </Protected>
        ),
    },
    { path: '404', element: <PageNotFound /> },
    { path: 'unsupported', element: <PageNotFound /> },
    { path: 'forbidden', element: <ForbiddenAccess /> },
    { path: '*', element: <PageNotFound /> },
];

const RouteList = (): React.ReactElement => {
    return <>{useRoutes([...businessRoutes, ...publicRoutes]) || <div />} </>;
};

export default RouteList;
