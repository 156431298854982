import { put, takeEvery } from 'redux-saga/effects';
import { CloseHBSOnboardingModal, OrganizationInvitesActionType } from '../actions/OrganizationInvitesActions';
import { selectGroup } from '../actions/selectGroupActions';

function* acceptedOrganizationInviteSaga({ selectedGroup }: CloseHBSOnboardingModal): Generator {
    try {
        yield put(selectGroup(selectedGroup));
    } catch (error) {
        // If something goes wrong dont change organization
    }
}

export default function* AcceptedOrganizationInviteSaga(): Generator {
    yield takeEvery(OrganizationInvitesActionType.CloseHBSOnboardingModalInit, acceptedOrganizationInviteSaga);
}
