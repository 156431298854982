import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import { FlexWrapperSpaced } from 'commons/src/components/PDF/PdfConstants';
import { dateFormats } from 'commons/src/constants';
import { PDFContext } from 'commons/src/models/commonTypeScript';

const componentStyles = StyleSheet.create({
    footer: {
        fontSize: '8',
        marginTop: '3vw',
    },
    boldText: {
        fontSize: '8',
        fontFamily: 'DemiBold',
    },
    smallText: {
        fontSize: '8',
    },
    flexWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '40vw',
    },
    smallTextParagraph: {
        fontSize: '8',
        width: '50vw',
    },
});

type Props = {
    userName: string;
    dateFormat: keyof typeof dateFormats;
    txt: PDFContext;
    reportId: string;
    deviceTypes: string[];
    comment: string;
};

const ReportDetails = ({ userName, dateFormat, reportId, deviceTypes, txt, comment }: Props): React.ReactElement => (
    <View style={componentStyles.footer} wrap={false}>
        <FlexWrapperSpaced>
            <View>
                <View style={componentStyles.flexWrapper}>
                    <Text style={componentStyles.boldText}>
                        {txt('Report.Generated')}
                        {': '}
                    </Text>
                    <Text style={componentStyles.smallText}>
                        {moment().format(dateFormats[dateFormat].shortFormat)}{' '}
                        {moment().format(dateFormats[dateFormat].hourFormat)}
                    </Text>
                </View>
                <View style={componentStyles.flexWrapper}>
                    <Text style={componentStyles.boldText}>
                        {txt('Report.MeasurementBy')}
                        {': '}
                    </Text>
                    <Text style={componentStyles.smallText}>{userName}</Text>
                </View>
                <View style={componentStyles.flexWrapper}>
                    <Text style={componentStyles.boldText}>
                        {txt('Report.ReportId')}
                        {': '}
                    </Text>
                    <Text style={componentStyles.smallText}>{reportId}</Text>
                </View>
                <View style={componentStyles.flexWrapper}>
                    <Text style={componentStyles.boldText}>
                        {txt('Insight.DeviceTypesInMeasurement')}
                        {': '}
                    </Text>
                    {deviceTypes.map(type => (
                        <Text style={componentStyles.smallText} key={`device-${type}`}>
                            {type}
                        </Text>
                    ))}
                </View>
            </View>
            <View style={componentStyles.flexWrapper}>
                <Text style={componentStyles.boldText}>
                    {txt('Insight.Comment')}
                    {': '}
                </Text>
                <Text style={componentStyles.smallText}>{comment}</Text>
            </View>
        </FlexWrapperSpaced>
    </View>
);

export default ReportDetails;
