/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import { getValidLanguageTagForMoment } from 'commons/src/commonFunctions';
import { dateFormats, tooltipBorderColor, colors } from 'commons/src/constants';
import humanizedTimeString from '../../buildings/buildingInsight/buildingInsightCommonFunctions';
import styles from './UsageOverTimeDayView.module.scss';

type Props = {
    chartData: number[][];
    paddedDays: number[][];
    firstDateOfFirstWeek: string;
    highestValue: number;
    dateFormat: keyof typeof dateFormats;
    txt: (text: string) => string;
    onClick: (event: Highcharts.PointClickEventObject) => void;
    bottomLegend: boolean;
};

export const getDate = (x: number, y: number, firstDateOfFirstWeek: string) => {
    const daysAfterFirstDate = y * 7 + x;
    return dayjs(firstDateOfFirstWeek).add(daysAfterFirstDate, 'days');
};

const usageOverTimeHeatMapConfig = ({
    chartData,
    firstDateOfFirstWeek,
    highestValue,
    dateFormat,
    txt,
    onClick,
    bottomLegend,
    paddedDays,
}: Props) => ({
    credits: {
        enabled: false,
    },
    title: {
        text: '',
    },
    chart: {
        type: 'heatmap',
        plotBorderWidth: 0,
        height: bottomLegend ? 290 : 250,
    },
    xAxis: {
        categories: [
            txt('Weekdays.MondayShort'),
            txt('Weekdays.TuesdayShort'),
            txt('Weekdays.WednesdayShort'),
            txt('Weekdays.ThursdayShort'),
            txt('Weekdays.FridayShort'),
            txt('Weekdays.SaturdayShort'),
            txt('Weekdays.SundayShort'),
        ],
        opposite: true,
        lineWidth: 0,
    },
    yAxis: {
        gridLineWidth: 0,
        title: null,
        reversed: true,
        labels: {
            enabled: false,
        },
    },
    colorAxis: {
        min: 0,
        minColor: colors.white,
        maxColor: colors.blueAzul,
        tickInterval: (highestValue * 60) / 3,
        labels: {
            formatter(this: Highcharts.BubbleLegendFormatterContextObject): string {
                return `${(this.value / 60).toFixed(0)}${txt('TimeUnit.HoursShort')}`;
            },
        },
        maxValue: highestValue * 60,
    },
    tooltip: {
        borderColor: tooltipBorderColor,
        style: {
            color: colors.white,
        },
        formatter(this: Highcharts.TooltipFormatterContextObject): string | undefined {
            const { x, y, value } = this.point.options;
            if (!Number.isNaN(x) && !Number.isNaN(y)) {
                const date = getDate(x as number, y as number, firstDateOfFirstWeek);
                const formattedDate = date.format(dateFormats[dateFormat].format);
                const validLanguageKey = getValidLanguageTagForMoment();
                const dayOfWeek = date.locale(validLanguageKey).format('dddd');
                const duration = !Number.isNaN(value) && humanizedTimeString(value as number, true);
                return `${dayOfWeek}<br/>${formattedDate}<br/>${duration}`;
            }
            return '';
        },
    },
    legend: bottomLegend
        ? {
              align: 'left',
              layout: 'horizontal',
              verticalAlign: 'bottom',
              symbolWidth: 250,
              symbolHeight: 10,
              y: 0,
              margin: 10,
          }
        : {
              align: 'right',
              layout: 'vertical',
              margin: 0,
              verticalAlign: 'top',
              y: 20,
              symbolHeight: 180,
              symbolWidth: 10,
          },
    series: [
        {
            borderWidth: 5,
            borderColor: colors.white,
            data: chartData,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                color: colors.blackShark,
                formatter(this: Highcharts.TooltipFormatterContextObject): string {
                    const { x, y } = this.point.options;
                    if (!Number.isNaN(x) && !Number.isNaN(y)) {
                        const date = getDate(x as number, y as number, firstDateOfFirstWeek);
                        return date.format('D');
                    }
                    return '';
                },
            },
            events: {
                click(event: Highcharts.PointClickEventObject): void {
                    onClick(event);
                },
            },
        },
        {
            name: 'paddedDays',
            data: paddedDays,
            className: styles.greyDatesSeries,
            borderWidth: 5,
            borderColor: colors.white,
            allowPointSelected: false,
            enableMouseTracking: false,
            opacity: 1,
            states: {
                inactive: {
                    enabled: false,
                    opacity: 1,
                },
            },
            dataLabels: {
                enabled: true,
                formatter(this: Highcharts.TooltipFormatterContextObject): string {
                    const { x, y } = this.point.options;
                    if (!Number.isNaN(x) && !Number.isNaN(y)) {
                        const date = getDate(x as number, y as number, firstDateOfFirstWeek);
                        return `<div>${date.format('D')}</div>`;
                    }
                    return '';
                },
            },
        },
    ],
});

export default usageOverTimeHeatMapConfig;

export type UsageOverTimeHeatMapConfig = ReturnType<typeof usageOverTimeHeatMapConfig>;
