/* eslint max-len: ["error", 1000] */
import React from 'react';

const ForecastThunder = (): React.ReactElement => (
    <svg
        version="1.1"
        className="weather-icon"
        width="30px"
        height="30px"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
    >
        <path
            d="M22.5,6.3h-0.8c-0.5-1.6-1.4-3.1-2.7-4.2C17.4,0.8,15.4,0,13.3,0C9.1,0,5.5,3,4.7,7C2.5,8.1,1,10.3,1,12.9 c0,3.6,2.9,6.5,6.5,6.5h1.8c0.7,0,1.2-0.5,1.2-1.2S10,17,9.3,17H7.5c-2.3,0-4.1-1.8-4.1-4.1c0-1.8,1.1-3.3,2.8-3.9l0.7-0.3L7,8
        c0.4-3.2,3.1-5.6,6.3-5.6c3.1,0,5.8,2.3,6.3,5.4l0.2,1h2.7c2.3,0,4.1,1.8,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1h-0.7 c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h0.7c3.6,0,6.5-3,6.5-6.6C29,9.2,26.1,6.3,22.5,6.3z"
        />
        <path d="M18.9,17.9c-0.1-0.2-0.3-0.3-0.5-0.3h-2.3l2.5-4.3c0.1-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.3-0.2-0.4-0.2h-2.9 c-0.2,0-0.5,0.2-0.5,0.4l-3,7.2c-0.1,0.2,0,0.4,0.1,0.5c0.1,0.1,0.3,0.2,0.4,0.2h2l-2.4,4.5c-0.4,0.6-0.2,1.3,0.4,1.7 c0.6,0.3,1.3,0.1,1.6-0.5l5.1-8.2C19,18.3,19,18.1,18.9,17.9z" />
    </svg>
);
export default ForecastThunder;
