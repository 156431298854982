import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Store } from '../../reducers';
import TertiaryButton from '../buttons/TertiaryButton';
import MaterialIcon from '../MaterialIcon';

type StateProps = {
    message: string;
    error: boolean;
    display: boolean;
    fade: boolean;
};

export const NotificationBox = ({ message, error, display, fade }: StateProps): React.ReactElement | null => {
    const { t: txt } = useTranslation();
    if (display) {
        return (
            <div className={classNames({ 'alert-box__background': !fade })}>
                <div className={classNames('alert-box', { 'alert-box--error': error, 'alert-box--fade': fade })}>
                    <div
                        className={classNames('alert-box__icon-container', {
                            'alert-box__icon-container--error': error,
                        })}
                    >
                        {!error ? (
                            <MaterialIcon extraClass="icon-circle" name="check_circle_outline" />
                        ) : (
                            <MaterialIcon extraClass="icon-circle" name="error_outline" />
                        )}
                    </div>
                    <div className="alert-box__text">
                        {txt(message)}
                        {error && !fade && (
                            <span className="alert-box__text--button">
                                <TertiaryButton
                                    title="Refresh"
                                    size="medium"
                                    onClick={(): void => {
                                        window.location.reload();
                                    }}
                                />
                                {txt('AndTryAgain')}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        );
    }
    return null;
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        notificationBox: { display, message, error, fade },
    } = state;
    return {
        display,
        message,
        error,
        fade,
    };
};
export default connect(mapStateToProps)(NotificationBox);
