import { OutdoorData, TileData } from '../models/commonTypeScript';
import { CommonActionRequestType, CommonRequestType } from '../reducers/requestReducer';
import { DashboardTilePayload } from './DashboardActions';
import { RequestActionType } from './requestActions';

export enum OutdoorActionType {
    FetchOutdoorAirInfoInit = 'FETCH_OUTDOOR_AIR_INFO/INIT',
    FetchOutdoorAirInfoSuccess = 'FETCH_OUTDOOR_AIR_INFO_SUCCESS',
    FetchOutdoorTileDataInit = 'FETCH_OUTDOOR_TILE_DATA/INIT',
    FetchIndoorVsOutdoorTileDataInit = 'FETCH_INDOOR_VS_OUTDOOR_TILE_DATA/INIT',
}

export interface FetchOutdoorAirInfo extends CommonActionRequestType {
    type: OutdoorActionType.FetchOutdoorAirInfoInit;
    locationId: string;
}
export const fetchOutdoorAirInfo = (locationId: string): FetchOutdoorAirInfo => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.FetchOutdoorAirInfo,
    type: OutdoorActionType.FetchOutdoorAirInfoInit,
    locationId,
});

export interface FetchOutdoorAirInfoSuccess {
    type: OutdoorActionType.FetchOutdoorAirInfoSuccess;
    payload: { locationId: string; content: OutdoorData };
}
export const fetchOutdoorAirInfoSuccess = (payload: {
    locationId: string;
    content: OutdoorData;
}): FetchOutdoorAirInfoSuccess => ({
    type: OutdoorActionType.FetchOutdoorAirInfoSuccess,
    payload,
});

export interface FetchOutdoorTileData {
    type: OutdoorActionType.FetchOutdoorTileDataInit;
    payload: OutdoorTilePayload;
}
export interface OutdoorTilePayload extends DashboardTilePayload {
    tileData: TileData;
}
export const fetchOutdoorTileData = (payload: OutdoorTilePayload): FetchOutdoorTileData => ({
    type: OutdoorActionType.FetchOutdoorTileDataInit,
    payload,
});

export interface FetchIndoorVsOutdoorTileData {
    type: OutdoorActionType.FetchIndoorVsOutdoorTileDataInit;
    payload: IndoorVsOutdoorPayload;
}
export interface IndoorVsOutdoorPayload extends DashboardTilePayload {
    tileData: TileData;
}
export const fetchIndoorVsOutdoorTileData = (payload: IndoorVsOutdoorPayload): FetchIndoorVsOutdoorTileData => ({
    type: OutdoorActionType.FetchIndoorVsOutdoorTileDataInit,
    payload,
});

export type OutdoorReducerActions = FetchOutdoorAirInfo | FetchOutdoorAirInfoSuccess;
