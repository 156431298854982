/* eslint max-len: ["error", 1000] */
import React from 'react';

const ForecastRain = (): React.ReactElement => (
    <svg
        version="1.1"
        className="weather-icon"
        width="30px"
        height="30px"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
    >
        <path d="M9.4,22.2c0.3-0.6,0.1-1.3-0.5-1.6c-0.6-0.3-1.3-0.1-1.6,0.5L6,23.4C5.6,24,5.8,24.7,6.4,25 c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0,0.8-0.2,1-0.6L9.4,22.2z" />
        <path d="M16.7,22.2c0.3-0.6,0.1-1.3-0.5-1.6c-0.6-0.3-1.3-0.1-1.6,0.5l-1.3,2.4C13,24,13.2,24.7,13.7,25 c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0,0.8-0.2,1-0.6L16.7,22.2z" />
        <path d="M23.6,20.5C23,20.2,22.3,20.4,22,21l-1.3,2.4c-0.3,0.6-0.1,1.3,0.5,1.6c0.2,0.1,0.4,0.2,0.6,0.2 c0.4,0,0.8-0.2,1-0.6l1.3-2.4C24.4,21.6,24.2,20.9,23.6,20.5z" />
        <path d="M11.1,24.6c-0.6-0.3-1.3-0.1-1.6,0.5l-1.3,2.4c-0.3,0.6-0.1,1.3,0.5,1.6c0.2,0.1,0.4,0.2,0.6,0.2 c0.4,0,0.8-0.2,1-0.6l1.3-2.4C11.9,25.7,11.7,25,11.1,24.6z" />
        <path d="M18.4,24.6c-0.6-0.3-1.3-0.1-1.6,0.5l-1.4,2.4c-0.3,0.6-0.1,1.3,0.5,1.6c0.2,0.1,0.4,0.2,0.6,0.2 c0.4,0,0.8-0.2,1-0.6l1.3-2.4C19.2,25.7,19,25,18.4,24.6z" />
        <path
            d="M13.3,2.4C10.1,2.4,7.4,4.8,7,8L6.9,8.7L6.2,9c-1.7,0.6-2.8,2.1-2.8,3.9c0,2.3,1.8,4.1,4.1,4.1h15 c2.3,0,4.1-1.8,4.1-4.1s-1.8-4.1-4.1-4.1h-2.7l-0.2-1C19.1,4.7,16.4,2.4,13.3,2.4
                M22.5,19.4h-15c-3.6,0-6.5-2.9-6.5-6.5 C1,10.3,2.5,8.1,4.7,7c0.8-4,4.4-7,8.6-7c2.1,0,4.1,0.8,5.7,2.1c1.3,1.1,2.2,2.6,2.7,4.2h0.8c3.6,0,6.5,2.9,6.5,6.5 S26.1,19.4,22.5,19.4"
        />
    </svg>
);
export default ForecastRain;
