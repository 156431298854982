import { produce } from 'immer';
import { OutdoorDataAction, OutdoorDataActions } from '../actions/outdoorDataActions';
import { OutdoorSensorData } from '../models/outdoorDataModels';

export interface OutdoorDataStore {
    outdoorData: {
        [fetchedPeriod: string]: {
            [sensorType: string]: OutdoorSensorData;
        };
    };
    locationId: string;
}

const initialState: OutdoorDataStore = {
    outdoorData: {},
    locationId: '',
};

/* eslint-disable no-param-reassign */
const reducer = (state: OutdoorDataStore = initialState, action: OutdoorDataAction): OutdoorDataStore =>
    produce(state, draft => {
        switch (action.type) {
            case OutdoorDataActions.FetchOutdoorDataSuccess: {
                const { fetchedPeriod, data, locationId } = action;
                const outdoorData = data.reduce((acc, sensorData) => {
                    acc[sensorData.sensorType] = sensorData;
                    return acc;
                }, {} as { [sensorType: string]: OutdoorSensorData });
                if (locationId !== state.locationId) {
                    draft.outdoorData = { [fetchedPeriod]: outdoorData };
                } else {
                    draft.outdoorData[fetchedPeriod] = outdoorData;
                }
                draft.locationId = locationId;
                break;
            }
            default:
                break;
        }
    });

export default reducer;
