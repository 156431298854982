import { takeEvery, call, put, select, SelectEffect, PutEffect, CallEffect } from 'redux-saga/effects';
import { fetchDashboard } from '../../actions/DashboardActions';
import { clearDeviceData } from '../../actions/DeviceActions';
import { fetchLocations } from '../../actions/LocationActions';
import { RequestActionType, requestError, requestSuccess } from '../../actions/requestActions';
import {
    fetchUserInfo,
    SettingsActionType,
    UpdateUserPreferences,
    updateUserPreferencesSuccess,
    UpdateUserProfile,
    updateUserProfileSuccess,
} from '../../actions/SettingsActions';
import { updateUserProfile } from '../../api/accountsApiUser';
import updateUserSettings from '../../api/updateSettings';
import { userIsHbs } from '../../components/findUserType';
import i18next from '../../i18n';
import { ErrorType, Units } from '../../models/commonTypeScript';
import { getUnits } from '../../reducers/reducerShortcuts';
import { CommonRequestType as RequestType } from '../../reducers/requestReducer';
import { toErrorType } from '../isErrorType';

export function* updateUserProfileSaga({
    name,
}: UpdateUserProfile): Generator<CallEffect | SelectEffect | PutEffect, void, Units> {
    try {
        yield call(updateUserProfile, name);
        yield put(updateUserProfileSuccess(name));
        yield put(requestSuccess(RequestType.UpdateUserProfile, RequestActionType.Success));
    } catch (error) {
        const asErrorType: ErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.UpdateUserProfile, RequestActionType.Error));
    }
}

export function* updateUserPreferencesSaga({
    request,
}: UpdateUserPreferences): Generator<CallEffect | SelectEffect | PutEffect, void, Units> {
    const { dateFormat, language, units } = request;
    try {
        yield call(updateUserSettings, { units, dateFormat, language });

        if (request && request.language) {
            i18next.changeLanguage(request.language).then();
        }

        const prevUnits: Units = yield select(getUnits);
        if (
            prevUnits.radonUnit !== units.radonUnit ||
            prevUnits.pressureUnit !== units.pressureUnit ||
            prevUnits.tempUnit !== units.tempUnit ||
            prevUnits.vocUnit !== units.vocUnit ||
            (prevUnits.lengthUnit && prevUnits.lengthUnit !== units.lengthUnit)
        ) {
            yield put(clearDeviceData());
            if (!userIsHbs()) {
                yield put(fetchLocations());
            }
            yield put(fetchDashboard());
            yield put(fetchUserInfo());
        } else {
            yield put(updateUserPreferencesSuccess(request));
        }

        yield put(requestSuccess(RequestType.UpdateUserPreferences, RequestActionType.Success));
    } catch (error) {
        const asErrorType: ErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.UpdateUserPreferences, RequestActionType.Error));
    }
}

export default function* updateUserSettingsSagas(): Generator {
    yield takeEvery(SettingsActionType.UpdateUserPreferences, updateUserPreferencesSaga);
    yield takeEvery(SettingsActionType.UpdateUserProfile, updateUserProfileSaga);
}
