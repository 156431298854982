import { RequestActionType } from 'commons/src/actions/requestActions';
import { BusinessActionRequestType, DownloadCsvPayload } from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum DownloadCSVActionType {
    DownloadSensorDataCsvInit = 'DOWNLOAD_SENSOR_DATA_CSV/INIT',
}

export interface DownloadSensorDataCSV extends BusinessActionRequestType {
    type: DownloadCSVActionType.DownloadSensorDataCsvInit;
    payload: DownloadCsvPayload;
    locationId: string;
}

export const downloadSensorDataCSV = (payload: DownloadCsvPayload, locationId: string): DownloadSensorDataCSV => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.DownloadSensorDataCsv,
    type: DownloadCSVActionType.DownloadSensorDataCsvInit,
    payload,
    locationId,
});
