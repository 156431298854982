import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ReactPdfDownloadModal from 'commons/src/components/PDF/ReactPdfDownloadModal';
import { fullwidthListElement } from 'commons/src/components/placeholder';
import { dateFormats } from 'commons/src/constants';
import filterOnFeatureToggles from 'commons/src/features/featureToggle/filterOnFeatureToggles';
import { groupName } from 'commons/src/features/group';
import { GroupType } from 'commons/src/models/commonEnums';
import { SubscriptionName } from '../../../components/subscriptionComponents/YourPlan';
import { CustomerListElement, SortParams, SubAccountDetails } from '../../../models/partner';
import { Store } from '../../../reducers';
import { EmptyTable } from '../../status/buildingStatus/components/common';
import { subAccountLandingPage } from '../customerPage/PartnerSubAccountMenus';
import { CellRenderResult, CustomerListHeader, PendingChip, toCustomerListElement } from './CustomerListElement';
import CustomerSummaryTableRowSlim from './CustomerSummaryTableRowSlim';
import styles from './CustomerTable.module.scss';
import CustomerTableRowSlim from './CustomerTableRowSlim';
import FullWidthTable from './FullWidthTable';

type StateProps = {
    dateFormat: keyof typeof dateFormats;
    customers: SubAccountDetails[];
    name: string;
    loading: boolean;
    groupType?: GroupType;
};

export type ParentProps = {
    newCustomerId?: string;
    searchWord: string;
    downloadModalOpen: boolean;
    setDownloadModalOpen: (open: boolean) => void;
};

export type Props = StateProps & ParentProps;

const sortDate = (customer1: CustomerListElement, customer2: CustomerListElement): number =>
    moment(customer1.createdAt).unix() - moment(customer2.createdAt).unix();

const getSearchParamsValues = (headers: CustomerListHeader[]): SortParams => ({
    headerKey: headers[0].objectKey,
    direction: 1,
});

const CustomerTableComponent = ({
    dateFormat,
    searchWord,
    customers,
    downloadModalOpen,
    setDownloadModalOpen,
    name,
    loading,
    groupType,
    newCustomerId,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();

    const allHeaders: CustomerListHeader[] = [
        {
            label: 'Name',
            objectKey: 'name',
            render: (customer: CustomerListElement): CellRenderResult => customer.name,
            requiredGroupTypes: [],
        },
        {
            label: 'DateCreated',
            objectKey: 'createdAt',
            render: ({ createdAt }: CustomerListElement): CellRenderResult =>
                moment(createdAt).format(dateFormats[dateFormat].calendarFormat),
            requiredGroupTypes: [],
        },
        {
            label: 'Subscription.Subscription',
            objectKey: 'subscriptionType',
            render: ({ subscriptionType }: CustomerListElement): CellRenderResult => (
                <SubscriptionName subscriptionType={subscriptionType} />
            ),
            requiredGroupTypes: [],
        },
        {
            label: 'Subscription.Seats',
            objectKey: 'totalNumberOfSeats',
            render: ({ totalNumberOfSeats }: CustomerListElement): CellRenderResult => {
                const countOfSubscriptions = totalNumberOfSeats ?? '-';
                return <div>{countOfSubscriptions}</div>;
            },
            requiredGroupTypes: [],
        },
        {
            label: 'Devices',
            objectKey: 'totalNumberOfDevices',
            render: ({ totalNumberOfDevices }: CustomerListElement): CellRenderResult => {
                return <div>{totalNumberOfDevices}</div>;
            },
            requiredGroupTypes: [],
        },
        {
            label: '',
            objectKey: 'pending',
            render: ({ pending }: CustomerListElement): CellRenderResult => (pending ? <PendingChip /> : <div />),
            requiredGroupTypes: [],
        },
    ];
    const headers = filterOnFeatureToggles(allHeaders, groupType);
    const customerArray: CustomerListElement[] = toCustomerListElement(customers);
    const getCsvData = (): { [key: string]: string | number | boolean }[] =>
        customerArray.map(customer => {
            const { name: orgName, userGroupId, ...rest } = customer;
            return { name: orgName, accountId: userGroupId, ...rest };
        });

    const totalSeatAndDevices = customerArray.reduce(
        (counts: { seatsCount: number; devicesCount: number }, currentValue) => {
            const seatCountForCustomer = currentValue.totalNumberOfSeats ?? 0;
            const deviceCountForCustomer = currentValue.totalNumberOfDevices ?? 0;
            return {
                seatsCount: seatCountForCustomer + counts.seatsCount,
                devicesCount: deviceCountForCustomer + counts.devicesCount,
            };
        },
        { seatsCount: 0, devicesCount: 0 }
    );

    const sorted = (sortDetails: SortParams): CustomerListElement[] => {
        const { headerKey, direction } = sortDetails;

        const modifyCreatedAtForPending = (customer: CustomerListElement): CustomerListElement => ({
            ...customer,
            createdAt: customer.pending ? '1970-01-01T00:00:00' : customer.createdAt,
        });

        const sortedList = customerArray.sort((customer1, customer2): number => {
            switch (headerKey) {
                case 'name':
                case 'subscriptionType':
                    return (customer1[headerKey] || '').localeCompare(customer2[headerKey] || '');
                case 'createdAt':
                    return sortDate(customer1, customer2);
                case 'pending':
                    return sortDate(modifyCreatedAtForPending(customer1), modifyCreatedAtForPending(customer2));
                case 'totalNumberOfDevices':
                case 'totalNumberOfSeats':
                    return (customer1[headerKey] || -1) - (customer2[headerKey] || -1);
                default:
                    return 0;
            }
        });
        if (direction === -1) {
            return sortedList.reverse();
        }
        return sortedList;
    };

    const [sortDir, setSortDir] = React.useState<SortParams>(getSearchParamsValues(headers));
    const [sortedList, setSortedList] = useState<CustomerListElement[]>(customerArray);

    useEffect((): void => {
        if (sortDir.direction !== null) {
            const newSorting = sorted(sortDir);
            setSortedList(newSorting);
        }
    }, [sortDir]);

    const filteredSortedList = sortedList.filter(listElement => listElement.name.toLowerCase().includes(searchWord));

    const redirectToCustomerPage = (customer: CustomerListElement): void => {
        navigate(
            { pathname: `/${subAccountLandingPage(customer)}` },
            {
                state: sortDir,
            }
        );
    };

    const csvFileName = `${name}_customer_list.csv`;
    return (
        // returning slim table when in slim view else wide-table
        <>
            {downloadModalOpen && (
                <ReactPdfDownloadModal
                    title="Download"
                    description="Partner.DownloadCustomerList"
                    onClose={(): void => setDownloadModalOpen(false)}
                >
                    <div className="form__button-container">
                        <CSVLink data={getCsvData()} separator=";" filename={csvFileName}>
                            {csvFileName}
                        </CSVLink>
                    </div>
                </ReactPdfDownloadModal>
            )}
            <ReactPlaceholder ready={!loading} customPlaceholder={fullwidthListElement}>
                <div className={styles.LimitingWarning}>
                    <MaterialIcon name="info" />
                    {txt('Subscription.SubscriptionWillBeBlocked')}
                </div>
                <ul className={styles.slimTable}>
                    {filteredSortedList.map(customer => (
                        <CustomerTableRowSlim
                            newCustomerId={newCustomerId}
                            key={`customer-row-${customer.userGroupId}`}
                            customer={customer}
                            headers={headers}
                        />
                    ))}
                    <CustomerSummaryTableRowSlim
                        totalCustomers={customers.length}
                        totalSeats={totalSeatAndDevices.seatsCount}
                        totalDevices={totalSeatAndDevices.devicesCount}
                    />
                </ul>
                <FullWidthTable
                    newCustomerId={newCustomerId}
                    customers={filteredSortedList}
                    dateFormat={dateFormat}
                    sortDir={sortDir}
                    setSortDir={setSortDir}
                    redirectToCustomer={redirectToCustomerPage}
                    totalSeatAndDevices={totalSeatAndDevices}
                />
                {filteredSortedList.length === 0 && <EmptyTable emptyTableText={txt('BuildingStatus.NoCustomers')} />}
            </ReactPlaceholder>
        </>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { dateFormat, selectedGroup, loading },
        partner: { customers },
    } = store;

    return {
        dateFormat,
        customers: customers || [],
        loading,
        groupType: selectedGroup?.groupType,
        name: groupName(selectedGroup),
    };
};

export default connect(mapStateToProps)(CustomerTableComponent);
