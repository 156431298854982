import React, { useEffect } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fullwidthListElement } from 'commons/src/components/placeholder';
import { dateFormats } from 'commons/src/constants';
import { GroupType } from 'commons/src/models/commonEnums';
import { Group } from 'commons/src/models/commonTypeScript';
import { fetchPartnerDetails } from '../../../actions/partnerActions';
import { SubAccountDetails } from '../../../models/partner';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import StartSubscription from '../../account/subscription/StartSubscription/StartSubscription';

type ParentProps = {
    subscriptionStartDate?: string;
};

type StateProps = {
    customers: SubAccountDetails[];
    loading: boolean;
    dateFormat: keyof typeof dateFormats;
    selectedGroup?: Group;
};

type Props = ParentProps & StateProps;

const PartnerCreateSubscription = ({
    customers,
    loading,
    dateFormat,
    selectedGroup,
    subscriptionStartDate,
}: Props): React.ReactElement => {
    const dispatch = useDispatch();
    const { childUserGroupId } = useParams() as { childUserGroupId: string };
    const customer = customers.find(customerDetails => customerDetails.userGroupId === childUserGroupId);
    const selectedGroupType = selectedGroup?.groupType;
    const userAllowedToManageSubscription = selectedGroupType === GroupType.partner;

    useEffect((): void => {
        if (customers.length === 0) {
            dispatch(fetchPartnerDetails());
        }
    }, [selectedGroupType]);

    return (
        <ReactPlaceholder ready customPlaceholder={fullwidthListElement}>
            <StartSubscription
                loading={loading}
                dateFormat={dateFormat}
                childUserGroupId={childUserGroupId}
                selectedGroupType={selectedGroupType}
                userAllowedToManageSubscription={userAllowedToManageSubscription}
                deviceCounts={customer?.numberOfDevices}
                subscriptionStartDate={subscriptionStartDate}
            />
        </ReactPlaceholder>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { loading: loadingSettings, dateFormat, selectedGroup },
        partner: { customers },
        requests: {
            [RequestType.FetchPartnerDetails]: { loading: loadingPartnerDetails },
        },
    } = store;

    return {
        customers,
        loading: loadingSettings || loadingPartnerDetails,
        dateFormat,
        selectedGroup,
    };
};

export default connect(mapStateToProps)(PartnerCreateSubscription);
