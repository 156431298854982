import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import { sensorUnits } from 'commons/src/constants';
import { Rating, SensorTypes } from 'commons/src/models/commonEnums';
import { ErrorType, LocationsStatus, LocationType, SensorThresholds } from 'commons/src/models/commonTypeScript';
import { CommonRequestType } from 'commons/src/reducers/requestReducer';
import { Store } from '../../../reducers';
import RequestType from '../../../reducers/BusinessRequestType';
import BuildingTileSensorFocus from './BuildingTileSensorFocus';

type StateProps = {
    locationsStatus: LocationsStatus;
    fetchLocationsStatusLoading: boolean;
    thresholds: { [sensor: string]: SensorThresholds };
    focusedSensor?: SensorTypes;
    focusedSensorError?: ErrorType;
};

export type ParentProps = {
    location: LocationType;
};

export type Props = StateProps & ParentProps;

const tileSensorTypes = [SensorTypes.co2, SensorTypes.temp];

export const BuildingTileComponent = ({
    location,
    locationsStatus,
    fetchLocationsStatusLoading,
    thresholds,
    focusedSensor,
    focusedSensorError,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const statusForLocation = locationsStatus[location.id];
    const numberOfDevices = location.devices && location.devices.length;

    const renderSensorStatus = (sensorType: string): React.ReactElement => {
        const numberOfCriticalDevices = statusForLocation !== undefined ? statusForLocation[sensorType] : 0;
        let sensorStatusText = txt('AllGood');
        if (numberOfCriticalDevices > 0) {
            const sensorThresholds = thresholds[sensorType];
            const sensorRanges = sensorThresholds?.ranges?.filter(range => range.rating === Rating.POOR);
            if (sensorRanges?.length === 1) {
                const breachedValue = `${sensorRanges[0].from}${sensorUnits[sensorThresholds.unit]}`;
                sensorStatusText = txt('SensorStatus.Above', {
                    numberOfDevices: numberOfCriticalDevices.toString(),
                    breachedValue,
                });
            } else if (sensorRanges?.length === 2) {
                const lowerValue = sensorRanges.find(range => !!range.to);
                const upperValue = sensorRanges.find(range => !!range.from);
                const sensorUnit = sensorUnits[sensorThresholds.unit];
                const breachedValues = `${lowerValue && lowerValue.to}${sensorUnit} - ${
                    upperValue && upperValue.from
                }${sensorUnit}`;
                sensorStatusText = txt('SensorStatus.Outside', {
                    numberOfDevices: numberOfCriticalDevices.toString(),
                    breachedValues,
                });
            }
        }
        return (
            <div
                className={classNames('building-tile__content__sensor-status', {
                    'building-tile__content__sensor-status--greyed': numberOfCriticalDevices === 0,
                })}
                key={sensorType}
            >
                <div className="building-tile__content__sensor-status__sensor">
                    <SensorIcon sensor={sensorType} />
                    <span className="building-tile__content__sensor-status--margin-left">{txt(sensorType)}</span>
                </div>
                <div className="text-medium">
                    {numberOfCriticalDevices > 0 ? (
                        <div className="sensor__line sensor__line--alert sensor__line--no-margin-left" />
                    ) : (
                        <div className="sensor__line sensor__line--ok sensor__line--no-margin-left" />
                    )}
                    {sensorStatusText}
                </div>
            </div>
        );
    };

    return (
        <NavLink to={`/buildings/${location.id}`} className="building-tile__link page-wrapper__column" data-tile>
            <div className="building-tile">
                <div className="building-tile__content">
                    <div className="centered">
                        <div className="building-tile__content__text building-tile__content__header">
                            <h2 className="building-tile__content__text--title">{location.name}</h2>
                        </div>
                    </div>
                    {focusedSensor && !focusedSensorError ? (
                        <BuildingTileSensorFocus numberOfDevices={numberOfDevices} locationId={location.id} />
                    ) : (
                        <>
                            <div>
                                {numberOfDevices} {txt('DevicesLowerCase')}
                            </div>
                            {numberOfDevices > 0 && !fetchLocationsStatusLoading && (
                                <div className="flex flex--spaced">
                                    {tileSensorTypes.map(sensor => renderSensorStatus(sensor))}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </NavLink>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        config: { thresholds },
        locationsStatus: { locationsStatus },
        buildingsOverview: { focusedSensor },
        commonRequests: {
            [CommonRequestType.FetchLocationsStatus]: { loading: fetchLocationsStatusLoading },
        },
        requests: {
            [RequestType.GetBuildingsFocusedSensorData]: { error: focusedSensorDataError },
            [RequestType.BuildingsThresholdsSetFocusedSensor]: { error: focusedSensorError },
        },
    } = state;
    return {
        locationsStatus,
        fetchLocationsStatusLoading,
        thresholds,
        focusedSensor,
        focusedSensorError: focusedSensorError || focusedSensorDataError,
    };
};

export default connect(mapStateToProps)(BuildingTileComponent);
