import React from 'react';
import styles from './BoldText.module.scss';

type Props = {
    text: string;
};

const BoldText = ({ text }: Props): React.ReactElement => <div className={styles.boldText}>{text}</div>;

export default BoldText;
