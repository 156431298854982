import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'commons/src/components/input/Input';
import { IntegrationExtraAuth } from '../../../../models/common';

type Props = {
    onUpdate: (inputValues: IntegrationExtraAuth, isValid: boolean) => void;
    inputValues: { [key: string]: string };
    displayValidation: boolean;
};

const EiendomsappenIntegration = (props: Props): React.ReactElement => {
    const { inputValues, displayValidation, onUpdate } = props;
    const { t: txt } = useTranslation();

    const validateInputs = (): boolean => {
        const validCompanyId = !!inputValues.companyId && inputValues.companyId.length > 0;
        const validIntegrationId = !!inputValues.integrationId && inputValues.integrationId.length > 0;
        return validCompanyId || validIntegrationId;
    };

    const updateInput = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value, id } = e.currentTarget;
        const validInput = validateInputs();
        onUpdate({ ...inputValues, [id]: value }, validInput);
    };

    const companyId = inputValues.companyId || '';
    const integrationId = inputValues.integrationId || '';
    return (
        <>
            <div className="form__row form__row--centered">
                <div className="form__field form__field--medium-width">
                    <Input
                        type="text"
                        id="companyId"
                        validate={displayValidation && companyId.length === 0}
                        isValid={companyId.length > 0}
                        hint={txt('ThirdParty.InputHint', { input: txt('ThirdParty.CompanyIdPlaceholder') })}
                        maxLength={80}
                        onChange={updateInput}
                        placeholder={txt('ThirdParty.CompanyIdPlaceholder')}
                        testId="input-company-id"
                    />
                </div>
            </div>
            <div className="form__row form__row--centered">
                <div className="form__field form__field--medium-width">
                    <Input
                        type="text"
                        id="integrationId"
                        validate={displayValidation && integrationId.length === 0}
                        isValid={integrationId.length > 0}
                        hint={txt('ThirdParty.InputHint', { input: txt('ThirdParty.IntegrationIdPlaceholder') })}
                        maxLength={80}
                        onChange={updateInput}
                        placeholder={txt('ThirdParty.IntegrationIdPlaceholder')}
                        testId="input-integration-id"
                    />
                </div>
            </div>
        </>
    );
};

export default EiendomsappenIntegration;
