import fetchFromDashboardApi from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { KeyValuePairType, LabelsPayload } from '../models/common';
import { NewPartnerCustomerRequest, PartnerCustomerDetails, UpdatePartnerCustomerRequest } from '../models/partner';

export const createKeyValueMap = (labels: KeyValuePairType[]): { labels: { [label: string]: string } } => {
    const labelsObject: LabelsPayload = {};
    labels.forEach(label => {
        labelsObject[label.key] = label.value;
    });
    return { labels: labelsObject };
};

export const createCustomer = async ({ ...customer }: NewPartnerCustomerRequest): Promise<PartnerCustomerDetails> => {
    const customerWithLabels = {
        ...customer,
        ...createKeyValueMap(customer.labels),
    };
    return fetchFromDashboardApi('/partner/customer', {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(customerWithLabels),
    });
};

export const updateCustomer = async ({ ...customer }: UpdatePartnerCustomerRequest): Promise<void> => {
    const customerWithLabels = {
        ...customer,
        ...createKeyValueMap(customer.labels),
    };
    return fetchFromDashboardApi(`/partner/customer?partnerManagedUserGroupId=${customer.userGroupId}`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(customerWithLabels),
    });
};
