import { combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { initialize } from 'commons/src/actions/';
import { getSelectedGroupFromStorage } from 'commons/src/components/findUserType';
import { GroupType } from 'commons/src/models/commonEnums';
import commonReducers from 'commons/src/reducers';
import configureStore from 'commons/src/store/configureStore';
import businessReducers from './reducers';
import sagas from './sagas';

const rootReducer = combineReducers({ ...commonReducers, ...businessReducers });
export type RootReducer = typeof rootReducer;
const store = configureStore(rootReducer, sagas);
export type Store = typeof store;

const userGroupType = localStorage && getSelectedGroupFromStorage()?.groupType;
const consumerUser = userGroupType === GroupType.consumer;

/**
 * store.dispatch(initialize) is triggered for consumer users even though the business dashboard is not rendered.
 * To prevent this from happening and for initialize to be called twice we add the if statement around the dispatch.
 */
if (!consumerUser) {
    store.dispatch(initialize());
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useTypedDispatch: () => AppDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
