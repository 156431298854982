import React from 'react';
import Slider from '@mui/material/Slider';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import { sensorUnits } from 'commons/src/constants';
import { SensorTypes } from '../../models/commonEnums';

export type Props = {
    sensor: string;
    unit: keyof typeof sensorUnits;
    value: number;
    min: number;
    max: number;
    updateSlider: (thresholdValue: number, rangeColor: string) => void;
    color: string;
};

const SensorSliderComponent = ({ color, sensor, unit, value, max, min, updateSlider }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const onChange = (e: Event, sliderValues: number | number[]): void => {
        const thresholdValue = Array.isArray(sliderValues) ? sliderValues[0] : sliderValues;
        updateSlider(thresholdValue, color);
    };
    return (
        <div>
            <div className="input__slider">
                <div
                    className={classNames('list-tile__status-circle', {
                        'sensor__line--warning': color === 'yellow',
                        'sensor__line--alert': color === 'red',
                    })}
                >
                    <div className="list-tile__status-circle__middle" />
                    <div className="list-tile__status-circle__content list-tile__status-circle__content--small">
                        <SensorIcon sensor={SensorTypes[sensor as SensorTypes]} />
                    </div>
                </div>
                <span className="input__slider__text">
                    {[
                        txt(sensor),
                        ` (${sensorUnits[unit]}) - `,
                        txt(color === 'red' ? 'LedBehaviour.RedLimit' : 'LedBehaviour.YellowLimit'),
                    ]}
                </span>
            </div>
            <div className="input__slider__wrapper">
                <Slider
                    className="input__slider__line"
                    track="normal"
                    size="small"
                    value={value}
                    min={min}
                    max={max}
                    valueLabelDisplay="on"
                    onChange={onChange}
                    step={50}
                    marks
                    classes={{ valueLabel: 'input__slider__value-label' }}
                />
            </div>
        </div>
    );
};

export default SensorSliderComponent;
