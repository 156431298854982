import React, { KeyboardEvent, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import SearchDropdown from 'commons/src/components/dropdown/SearchDropdown';
import { VirtualDeviceType } from 'commons/src/models/commonEnums';
import { BuildingType, DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';
import GroupDeviceDetails from './GroupDeviceDetails';
import isDeviceAllowedInGroup from './IsDeviceAllowedInGroup';

type StateProps = {
    devices: { [serialNumber: string]: DeviceWithKeyInfo };
};

type ParentProps = {
    building: BuildingType | undefined;
    setSelectedDevices: (selectedDevices: DeviceWithKeyInfo[]) => void;
    selectedDevices: DeviceWithKeyInfo[];
    editingDevice: boolean;
    groupType?: VirtualDeviceType;
    slimView?: boolean;
};
type OptionSelector = { id: string; name: string };

type Props = StateProps & ParentProps;

export const GroupDeviceInfoComponent = ({
    building,
    setSelectedDevices,
    selectedDevices,
    editingDevice,
    groupType,
    slimView,
    devices,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const selectDevice = (selected: OptionSelector): void => {
        const device = devices && devices[selected.id];
        if (device) {
            setSelectedDevices([...selectedDevices, device]);
        }
    };

    const removeDeviceFromList = (e: SyntheticEvent<HTMLInputElement> | KeyboardEvent<HTMLButtonElement>): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (e?.key && e?.key !== 'Enter') {
            return;
        }
        const deviceToRemove = e.currentTarget.value;
        const updatedList = selectedDevices.filter(device => device.serialNumber !== deviceToRemove);
        setSelectedDevices(updatedList);
    };

    const isNotAlreadySelected = (device: DeviceWithKeyInfo): boolean =>
        !selectedDevices.find(selected => selected.serialNumber === device.serialNumber);

    const locationDevices =
        (building &&
            building.devices
                .map(serialNumber => devices[serialNumber])
                .filter(device => device && isNotAlreadySelected(device) && isDeviceAllowedInGroup(device, groupType))
                .map(device => ({ id: device.serialNumber, name: device.segmentName, deviceType: device.type }))) ||
        [];

    const sortedDevices = selectedDevices.sort((deviceA, deviceB) =>
        deviceA.segmentName.localeCompare(deviceB.segmentName)
    );
    return (
        <>
            {editingDevice ? (
                <h3>{txt('Devices')}</h3>
            ) : (
                <div className="insight-tile__header insight-tile__header--padded insight-tile__header--no-border">
                    <h2 id="device-header">{txt('Devices')}</h2>
                </div>
            )}
            <div className="form__row">
                <div
                    className={classNames('form__field', {
                        'form__field--no-margin-left': editingDevice,
                        'form__field--standard-width': !editingDevice,
                    })}
                >
                    <SearchDropdown listOfElements={locationDevices} onSelect={selectDevice} />
                </div>
            </div>
            {sortedDevices.map(device => (
                <GroupDeviceDetails
                    key={`selected-${device && device.serialNumber}`}
                    device={device}
                    removeDeviceFromList={removeDeviceFromList}
                    slimView={slimView}
                />
            ))}
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { devicesWithKeyInfo },
    } = state;
    return {
        devices: devicesWithKeyInfo,
    };
};

export default connect(mapStateToProps)(GroupDeviceInfoComponent);
