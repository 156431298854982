import React, { useEffect, useState } from 'react';
import { Document, Font, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { analyticsLogger, PageType, ReportType } from 'commons/src/analytics';
import { INSIGHT_DOWNLOADED_REPORT } from 'commons/src/analytics/AnalyticsEvents';
import { mapDeviceType } from 'commons/src/commonFunctions';
import DownloadPDFView from 'commons/src/components/PDF/DownloadPdfView';
import {
    Body,
    HeaderBlock,
    Logo,
    colorStyles,
    LogoContainer,
    Paragraph,
    PageLine,
    SensorColumns,
    SubHeaderNoMargin,
    Footer,
    SmallLogo,
    PaddedAverage,
    FlexWrapperWrapped,
} from 'commons/src/components/PDF/PdfConstants';
import { dateFormats } from 'commons/src/constants';
import DemiBold from 'commons/src/fonts/open-sans-v28-latin-600.ttf';
import RegularFont from 'commons/src/fonts/open-sans-v28-latin-regular.ttf';
import {
    AnyDeviceType,
    BuildingType,
    DeviceType,
    SegmentProperties,
    SensorThresholds,
} from 'commons/src/models/commonTypeScript';
import BusinessLogo from '../../../../img/logos/business-logo.png';
import { IaqInsightData, PDFContext } from '../../../../models/common';
import BuildingDetails from '../BuildingDetails';
import ReportDetails from './ReportDetails';
import ResultSummary from './ResultSummary';
import SensorResult from './SensorResult';

Font.register({ family: 'DemiBold', src: DemiBold });
Font.register({ family: 'Regular', src: RegularFont });

const styles = StyleSheet.create({
    headerText: {
        fontSize: 25,
        fontFamily: 'DemiBold',
        maxWidth: '60vw',
    },
    bold: {
        fontFamily: 'DemiBold',
    },
    pageNumbers: {
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
});

type GenerateProps = {
    dateFormat: keyof typeof dateFormats;
    toDate: string | null;
    fromDate: string | null;
    building: BuildingType | undefined;
    reports: { [sensor: string]: IaqInsightData };
    thresholds: { [sensor: string]: SensorThresholds };
    devices: { [serialNumber: string]: DeviceType };
    segmentProperties: { [serialNumber: string]: SegmentProperties };
    logo?: string;
    comment: string;
    listOfImages: { data: string; serialNumber: string }[];
    reportDetails: {
        deviceTypes: string[];
        userName: string;
        reportId: string;
    };
};

type PdfProperties = {
    reportPeriodHeader: string;
    building: BuildingType;
    txt: PDFContext;
    dateFormat: keyof typeof dateFormats;
    reports: { [sensor: string]: IaqInsightData };
    thresholds: { [sensor: string]: SensorThresholds };
    logo?: string;
    comment: string;
    listOfImages: { data: string; serialNumber: string }[];
    deviceTypesInReport: string[];
    columns: { [sensor: string]: SensorColumns };
    reportDetails: {
        deviceTypes: string[];
        userName: string;
        reportId: string;
    };
};

// Create Document Component
const PdfDocument = React.memo(
    ({
        reportPeriodHeader,
        building,
        columns,
        txt,
        dateFormat,
        reports,
        thresholds,
        reportDetails,
        deviceTypesInReport,
        logo,
        listOfImages,
        comment,
    }: PdfProperties) => {
        const reportsSortedBySensor = Object.keys(reports).sort((sensorA, sensorB) => sensorA.localeCompare(sensorB));

        const sensorResultSummary = (sensors: string[]): React.ReactElement[] =>
            sensors.map(sensor => (
                <PaddedAverage key={`summary-${sensor}`}>
                    <ResultSummary
                        txt={txt}
                        value={reports[sensor].averageValueWithinOpeningHours}
                        thresholds={thresholds[sensor]}
                        sensor={sensor}
                    />
                </PaddedAverage>
            ));

        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Document>
                <Body>
                    <HeaderBlock>
                        <View>
                            <Text style={styles.headerText}>{txt('InsightIaq.IAQReport')}</Text>
                            <Paragraph>{reportPeriodHeader}</Paragraph>
                        </View>
                        <LogoContainer>
                            <Logo src={logo || BusinessLogo} />
                        </LogoContainer>
                    </HeaderBlock>
                    <BuildingDetails txt={txt} building={building} dateFormat={dateFormat} />
                    <PageLine />
                    <SubHeaderNoMargin>{txt('InsightIaq.AverageMeasurementOpeningHours')}</SubHeaderNoMargin>
                    <FlexWrapperWrapped>{sensorResultSummary(reportsSortedBySensor)}</FlexWrapperWrapped>
                    <Paragraph>{txt('InsightIaq.MeasurementResultExplained')}</Paragraph>
                    <PageLine />
                    <View>
                        {reportsSortedBySensor.map(sensor => (
                            <SensorResult
                                listOfImages={listOfImages}
                                key={`result-${sensor}`}
                                sensor={sensor}
                                txt={txt}
                                report={reports[sensor]}
                                ranges={thresholds[sensor].ranges}
                                unit={thresholds[sensor].unit}
                                sensorColumns={columns[sensor]}
                            />
                        ))}
                    </View>
                    <ReportDetails
                        userName={reportDetails.userName}
                        dateFormat={dateFormat}
                        txt={txt}
                        reportId={reportDetails.reportId}
                        deviceTypes={deviceTypesInReport}
                        comment={comment}
                    />
                    <Footer fixed>
                        <Text
                            style={colorStyles.smallText}
                            render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }): string =>
                                `${pageNumber} / ${totalPages}`
                            }
                            fixed
                        />
                        <SmallLogo src={BusinessLogo} />
                    </Footer>
                </Body>
            </Document>
        );
    }
);

const logDownloadReport = (): void => {
    analyticsLogger(INSIGHT_DOWNLOADED_REPORT, { pageType: PageType.Insight, reportType: ReportType.IAQInsight });
};

export const GeneratePdfComponent = (props: GenerateProps): React.ReactElement | null => {
    const {
        dateFormat,
        fromDate,
        toDate,
        building,
        reports,
        thresholds,
        devices,
        segmentProperties,
        logo,
        comment,
        reportDetails,
        listOfImages,
    } = props;
    let pdfProps: PdfProperties | undefined;
    const { t: txt } = useTranslation();
    const reportPeriodHeader =
        fromDate && toDate ? txt('RadonInsight.ReportGeneratedPeriod', { from: fromDate, to: toDate }) : '';
    const deviceTypesInReport = reportDetails.deviceTypes.map(
        (type, i) =>
            `Airthings ${txt(`${mapDeviceType(type as AnyDeviceType)}FullName`)}${
                reportDetails.deviceTypes.length > 1 && i < reportDetails.deviceTypes.length - 1 ? ', ' : ''
            }`
    );

    const columns = Object.keys(reports).reduce((columnObject, sensorKey) => {
        const sensorColumns = Object.keys(reports[sensorKey].devicesOpeningHoursValues)
            .map(serialNumber => {
                const device = devices[serialNumber];
                const segmentProps = segmentProperties[serialNumber];
                return {
                    ...reports[sensorKey].devicesOpeningHoursValues[serialNumber],
                    name: device.segmentName,
                    serialNumber,
                    roomType: segmentProps && segmentProps.roomType,
                };
            })
            .sort((deviceA, deviceB) => deviceA.name.localeCompare(deviceB.name));
        return { ...columnObject, [sensorKey]: sensorColumns };
    }, {});

    const columnsGenerated = Object.keys(columns).length === Object.keys(reports).length;
    if (columnsGenerated && building) {
        pdfProps = {
            reportPeriodHeader,
            building,
            txt,
            dateFormat,
            thresholds,
            reports,
            logo,
            comment,
            columns,
            reportDetails,
            deviceTypesInReport,
            listOfImages,
        };
    }
    const [properties, setProperties] = useState<PdfProperties | undefined>(undefined);

    useEffect(() => {
        if (!properties) {
            setProperties(pdfProps);
        }
    }, [pdfProps]);

    if (!properties) {
        return null;
    }

    const fileName = `IAQReport_${properties.building.name}_${fromDate}-${toDate}.pdf`;
    return (
        <div
            className="form__button-container"
            onClick={logDownloadReport}
            onKeyDown={logDownloadReport}
            role="button"
            tabIndex={0}
        >
            <PDFDownloadLink
                document={<PdfDocument {...properties} />}
                fileName={`IAQReport_${properties.building.name}_${fromDate}-${toDate}.pdf`}
            >
                {({ loading }): React.ReactElement => <DownloadPDFView loading={loading} fileName={fileName} />}
            </PDFDownloadLink>
        </div>
    );

    /*
    // use this to get inline version of pdf.
    return (
        <div className="form form__wide-container">
            <PDFViewer>
                <PdfDocument {...properties} />
            </PDFViewer>
        </div>
    ); */
};

export default GeneratePdfComponent;
