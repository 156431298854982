import React from 'react';
import classNames from 'classnames';

type Props = {
    open: boolean;
    onClick: () => void;
};

const HamburgerIcon = ({ open, onClick }: Props): React.ReactElement => (
    <button
        type="button"
        data-testid="data-hamburger-button"
        className="sidebar-navigation__items-container--hamburger"
        onClick={onClick}
    >
        <div className={classNames('menu-icon', { open })}>
            <span className="menu-icon__line first" />
            <span className="menu-icon__line second" />
            <span className="menu-icon__line third" />
        </div>
    </button>
);

export default HamburgerIcon;
