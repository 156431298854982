import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { sensorRating } from 'commons/src/commonFunctions';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { sensorUnits } from 'commons/src/constants';
import { Rating, SensorTypes } from 'commons/src/models/commonEnums';
import { SensorThresholds } from 'commons/src/models/commonTypeScript';

type Props = {
    value?: number;
    isAverage?: boolean;
    sensorRanges: SensorThresholds;
    sensor: string;
};

const TableRowSensorStatus = (props: Props): React.ReactElement | null => {
    const {
        sensor,
        value,
        isAverage,
        sensorRanges: { ranges, unit },
    } = props;
    const { t: txt } = useTranslation();

    const valueRating = sensorRating(ranges, value);
    const displayedUnit: string =
        sensor === SensorTypes.temp ? `${sensorUnits[unit]}${(unit as string).toUpperCase()}` : sensorUnits[unit];
    if (value === undefined) {
        if (isAverage) {
            return (
                <div className="device-insight__error-response device-insight__error-response--slim">
                    <MaterialIcon extraClass="icon-color--red" name="error_outline" />
                    {txt('NoData')}
                </div>
            );
        }
        return null;
    }
    return (
        <>
            <div
                className={classNames('sensor__line', {
                    'sensor__line--ok': valueRating === Rating.GOOD,
                    'sensor__line--alert': valueRating === Rating.POOR,
                    'sensor__line--warning': valueRating === Rating.FAIR,
                })}
            />
            <span className="text-large text-bold">{parseFloat(value.toFixed(1))}</span> <span>{displayedUnit}</span>
        </>
    );
};

export default TableRowSensorStatus;
