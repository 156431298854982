import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { headerPlaceholder } from '../../components/placeholder';
import { Weather } from '../../models/commonTypeScript';
import { getWeatherIcon } from './WeatherAttrs';

export type Props = {
    locationName: string;
    loading: boolean;
    weather: Weather;
};

const AiqHeader = ({ locationName, loading, weather }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <ReactPlaceholder ready={!loading} customPlaceholder={headerPlaceholder}>
            <div className="outdoor__header">
                <div className="outdoor__header__info outdoor__header__info--no-icon">
                    <div className="outdoor__header__info__top">
                        <h1>{txt(locationName)}</h1>
                    </div>
                    <div className="outdoor__header__info__bottom">
                        <div className="outdoor__header__location-name">
                            <p>{txt('OutdoorAir')}</p>
                        </div>
                    </div>
                </div>
                {weather?.iconCode && (
                    <div className="weather-header">
                        <div className="weather-header__content">
                            <span
                                className="weather-header__content__icon"
                                title={weather && txt(`Weather${weather.iconCode}`)}
                            >
                                {getWeatherIcon(weather.iconCode)}
                            </span>
                            <span className="weather-header__content__value">
                                {weather?.sensors?.temperature.value.toFixed(0)}
                                {'\u00B0'}
                            </span>
                        </div>
                        <div className="weather-header__description">{txt(`Weather${weather.iconCode}`)}</div>
                    </div>
                )}
            </div>
        </ReactPlaceholder>
    );
};

export default AiqHeader;
