import React, { useEffect } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fullwidthListElement } from 'commons/src/components/placeholder';
import { dateFormats } from 'commons/src/constants';
import { GroupType } from 'commons/src/models/commonEnums';
import { ErrorType, Group } from 'commons/src/models/commonTypeScript';
import { fetchPartnerDetails } from '../../../actions/partnerActions';
import { getSubscriptionForForPartnerCustomer } from '../../../actions/subscriptionActions';
import { SubAccountDetails } from '../../../models/partner';
import { Subscription } from '../../../models/subscriptionModels';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import AddSeatsPage from '../../account/subscription/addToSubscription/AddSeatsPage';

type StateProps = {
    error?: ErrorType;
    loading: boolean;
    dateFormat: keyof typeof dateFormats;
    customers: SubAccountDetails[];
    customerSubscription?: Subscription[];
    customersInState: boolean;
    selectedGroup?: Group;
};

type Props = StateProps;

const PartnerUpdateSubscription = ({
    error,
    loading,
    dateFormat,
    customerSubscription,
    customersInState,
    selectedGroup,
    customers,
}: Props): React.ReactElement => {
    const { childUserGroupId } = useParams();
    const dispatch = useDispatch();
    const selectedGroupType = selectedGroup?.groupType;
    const userAllowedToManageSubscription = selectedGroupType === GroupType.partner;
    const customer = customers?.find(customerDetails => customerDetails.userGroupId === childUserGroupId);

    useEffect(() => {
        if (childUserGroupId) {
            dispatch(getSubscriptionForForPartnerCustomer(childUserGroupId));
        }
    }, [childUserGroupId]);

    useEffect((): void => {
        if (!customersInState && selectedGroupType === GroupType.partner) {
            dispatch(fetchPartnerDetails());
        }
    }, [selectedGroupType]);

    return (
        <ReactPlaceholder ready customPlaceholder={fullwidthListElement}>
            <AddSeatsPage
                error={error}
                loading={loading}
                dateFormat={dateFormat}
                customerSubscription={customerSubscription}
                selectedGroupType={selectedGroupType}
                userAllowedToManageSubscription={userAllowedToManageSubscription}
                childUserGroupId={childUserGroupId}
                deviceCounts={customer?.numberOfDevices}
            />
        </ReactPlaceholder>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { loading: loadingSettings, dateFormat, selectedGroup },
        requests: {
            [RequestType.GetSubscriptionForPartnerCustomer]: { error, loading },
        },
        partner: { customerSubscription, customers },
    } = store;

    return {
        error,
        loading: loading || loadingSettings,
        dateFormat,
        customerSubscription: customerSubscription?.subscriptions,
        customersInState: customers.length > 0,
        customers,
        selectedGroup,
    };
};

export default connect(mapStateToProps)(PartnerUpdateSubscription);
