import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { radonInsightPlaceholder } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats, sensorUnits } from 'commons/src/constants';
import { BuildingType, DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { RadonInsightSensorData } from '../../../models/common';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import { generateImage } from '../GraphConfig';
import { createObserverAndFetchSvg } from '../insightFunctions';
import BuildingResultSummary from './BuildingResultSummary';
import DeviceResult from './DeviceResult';
import DeviceResultMissingData from './DeviceResultMissingData';
import ReportFooter from './ReportFooter';

type ParentProps = {
    from: Moment | null;
    to: Moment | null;
    optionalBuildingProps: string[];
};

type StateProps = {
    dateFormat: keyof typeof dateFormats;
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    loading: boolean;
    locationId: string | undefined;
    thresholds: number[];
    reports: RadonInsightSensorData[];
    errors: { error: string; serialNumber: string }[];
    unit: keyof typeof sensorUnits;
    buildings: { [buildingId: string]: BuildingType };
};

type Props = ParentProps & StateProps;

export const InsightResultViewComponent = (props: Props): React.ReactElement => {
    const {
        from,
        to,
        devicesWithKeyInfo,
        locationId,
        dateFormat,
        loading,
        reports,
        errors,
        unit,
        thresholds,
        optionalBuildingProps,
        buildings,
    } = props;
    const { t: txt } = useTranslation();
    const [listOfImages, setListOfImages] = useState<{ data: string; serialNumber: string }[]>([]);
    const [generateChartError, setChartError] = useState(false);

    const getSvg = async (): Promise<void> => {
        const images: { data: string; serialNumber: string }[] = [];
        const updateListOfImages = (response: { data: string; serialNumber: string }): void => {
            images.push(response);
        };
        await reports.forEach(report => {
            generateImage(updateListOfImages, setChartError, report.serialNumber);
        });
        await setListOfImages(images);
    };

    useEffect((): void => {
        if (!loading) {
            const element = document.getElementById('resultHeader');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
                const observer = createObserverAndFetchSvg('buildingResultSummary', getSvg);
                observer.observe(document, {
                    childList: true,
                    subtree: true,
                });
            }
        }
    }, [loading]);

    const building = locationId ? buildings[locationId] : undefined;
    const usageHours = building ? building.usageHours : {};
    const fromDate = from && moment(from).format(dateFormats[dateFormat].shortFormat);
    const toDate = to && moment(to).format(dateFormats[dateFormat].shortFormat);

    const devicesInEachLevel = reports.reduce(
        (devicesInLevels, report) => {
            const indexOfLevel = thresholds.findIndex(threshold => report.radonStep2 < threshold);
            const updatedLevels = { ...devicesInLevels };
            if (indexOfLevel === 0) updatedLevels.good = devicesInLevels.good + 1;
            else if (indexOfLevel === 1) updatedLevels.fair = devicesInLevels.fair + 1;
            else updatedLevels.poor = devicesInLevels.poor + 1;
            return updatedLevels;
        },
        { good: 0, fair: 0, poor: 0 }
    );

    const sortedReports = reports.sort((reportA, reportB) => {
        const deviceAName =
            devicesWithKeyInfo[reportA.serialNumber] && devicesWithKeyInfo[reportA.serialNumber].segmentName;
        const deviceBName =
            devicesWithKeyInfo[reportB.serialNumber] && devicesWithKeyInfo[reportB.serialNumber].segmentName;
        if (deviceAName === undefined || deviceBName === undefined) {
            // these were reported in sentry as undefined 17 times (04.10.21) Adding this sentry tracker to see if we can figure out why if it happens again.
            Sentry.captureEvent({
                message: 'sorted reports failed',
                extra: {
                    deviceAName,
                    deviceBName,
                    reportASerialNumber: reportA.serialNumber,
                    reportBSerialNumber: reportB.serialNumber,
                    devicesWithKeyInfo,
                    reports,
                },
            });
        }
        return deviceAName && deviceBName ? deviceAName.localeCompare(deviceBName) : 0;
    });

    return (
        <div className="page-wrapper-flex page-wrapper-flex--content-margin">
            <div id="resultHeader" className="inline-header-lined">
                <h2 className="inline-header-lined__text">
                    {!loading && fromDate && toDate
                        ? txt('RadonInsight.ReportGeneratedPeriod', { from: fromDate, to: toDate })
                        : txt('Loading')}
                </h2>
            </div>
            <ReactPlaceholder ready={!loading} customPlaceholder={radonInsightPlaceholder}>
                {building && (
                    <BuildingResultSummary
                        devicesInEachLevel={devicesInEachLevel}
                        building={building}
                        listOfImages={listOfImages}
                        fromDate={fromDate}
                        toDate={toDate}
                        generateChartError={generateChartError}
                        optionalBuildingProps={optionalBuildingProps}
                    />
                )}
                {sortedReports.map(deviceResult => (
                    <DeviceResult
                        key={deviceResult.serialNumber}
                        unit={unit}
                        thresholds={thresholds}
                        result={deviceResult}
                        usageHours={usageHours}
                    />
                ))}
                {errors.map(errorDevice => (
                    <DeviceResultMissingData
                        key={errorDevice.serialNumber}
                        serialNumber={errorDevice.serialNumber}
                        error={errorDevice.error}
                    />
                ))}
                <ReportFooter />
                {generateChartError && (
                    <div className="form form__wide-container">
                        <ResponseBox text="SomethingWentWrong" subtext={txt('RadonInsight.GeneratePdfError')} />
                    </div>
                )}
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
        devices: { devicesWithKeyInfo },
        buildings: { buildings },
        requests: {
            [RequestType.FetchRadonInsightData]: { loading },
        },
        radonInsight: { locationId, thresholds, reports, errors, unit },
    } = state;
    return {
        dateFormat,
        devicesWithKeyInfo,
        buildings,
        loading,
        locationId,
        thresholds,
        reports,
        errors,
        unit,
    };
};

export default connect(mapStateToProps)(InsightResultViewComponent);
