import { RefObject, useCallback, useRef } from 'react';
import { throttle } from 'lodash';
import useEventListener from 'commons/src/hooks/useEventListener';

type ScrollPosition = {
    top: number;
    left: number;
};

// Detect current table scroll position
// Applied debounce logic and passive scrolling for performance reasons
const useTableScroll = <T extends HTMLElement>(
    cb: (position: ScrollPosition, element: T | null, event: Event) => void
): RefObject<T> => {
    const ref = useRef<T>(null);

    const throttledScrollHandler = useCallback(
        throttle((event: Event) => {
            const target = event.target as T;
            const left = target.scrollLeft;
            const top = target.scrollTop;
            cb({ left, top }, ref.current, event);
        }, 800),
        [cb]
    );

    useEventListener('scroll', throttledScrollHandler, ref, { passive: true });
    return ref;
};

export default useTableScroll;
