import { dateFormats, sensorUnits } from '../../constants';
import { userIsHbs } from '../findUserType';

export const userSettingsSelectors = {
    radonUnit: 'radonUnit',
    tempUnit: 'tempUnit',
    pressureUnit: 'pressureUnit',
    vocUnit: 'vocUnit',
    dateFormat: 'dateFormat',
    lengthUnit: 'lengthUnit',
};

const unitPreferenceOptions = [
    {
        settingId: userSettingsSelectors.radonUnit,
        options: [
            { label: sensorUnits.bq, value: 'bq' },
            { label: sensorUnits.pci, value: 'pci' },
        ],
        header: 'radonShortTermAvg',
    },
    {
        settingId: userSettingsSelectors.tempUnit,
        options: [
            { label: 'Units.UnitCelsius', value: 'c' },
            { label: 'Units.UnitFahrenheit', value: 'f' },
        ],
        header: 'Temperature',
    },
    {
        settingId: userSettingsSelectors.pressureUnit,
        options: [
            { label: sensorUnits.hpa, value: 'hpa' },
            { label: sensorUnits.inhg, value: 'inhg' },
        ],
        header: 'pressure',
    },
    {
        settingId: userSettingsSelectors.vocUnit,
        options: [
            { label: sensorUnits.ppb, value: 'ppb' },
            { label: sensorUnits.mgpc, value: 'mgpc' },
        ],
        header: 'voc',
    },
];

const preferenceOptions = [
    ...unitPreferenceOptions,
    {
        settingId: userSettingsSelectors.dateFormat,
        options: [
            { label: 'DateFormattingDDMMYYYY', value: dateFormats.EUR.type },
            { label: 'DateFormattingMMDDYYYY', value: dateFormats.US.type },
        ],
        header: 'DateFormat',
    },
];
if (userIsHbs()) {
    preferenceOptions.push({
        settingId: userSettingsSelectors.lengthUnit,
        options: [
            { label: 'Units.UnitMeter', value: 'm' },
            { label: 'Units.UnitFeet', value: 'ft' },
        ],
        header: 'LengthUnit',
    });
}

export const getUnitPreferenceSelectors = (
    settingsRow: (
        settingId: string,
        options: { label: string; value: string }[],
        header: string,
        selectedValue: string
    ) => React.ReactElement,
    selectedRadonUnit: string,
    selectedTempUnit: string,
    selectedPressureUnit: string,
    selectedVocUnit: string,
    excludedSensors: string[] = []
): React.ReactElement[] => {
    const filteredOptions = unitPreferenceOptions.filter(option => !excludedSensors.includes(option.settingId));
    return filteredOptions.map(preference => {
        let selectorValue = selectedRadonUnit;
        if (preference.settingId === userSettingsSelectors.pressureUnit) selectorValue = selectedPressureUnit;
        if (preference.settingId === userSettingsSelectors.tempUnit) selectorValue = selectedTempUnit;
        if (preference.settingId === userSettingsSelectors.vocUnit) selectorValue = selectedVocUnit || 'ppb';
        return settingsRow(preference.settingId, preference.options, preference.header, selectorValue);
    });
};

const getUserPreferenceSelectors = (
    settingsRow: (
        settingId: string,
        options: { label: string; value: string }[],
        header: string,
        selectedValue: string
    ) => React.ReactElement,
    selectedRadonUnit: string,
    selectedTempUnit: string,
    selectedPressureUnit: string,
    selectedVocUnit: string,
    selectedDateFormat: string,
    selectedMeasurementSystem?: string
): React.ReactElement[] =>
    preferenceOptions.map(preference => {
        let selectorValue = selectedRadonUnit;
        if (preference.settingId === userSettingsSelectors.dateFormat) selectorValue = selectedDateFormat;
        if (preference.settingId === userSettingsSelectors.pressureUnit) selectorValue = selectedPressureUnit;
        if (preference.settingId === userSettingsSelectors.tempUnit) selectorValue = selectedTempUnit;
        if (preference.settingId === userSettingsSelectors.vocUnit) selectorValue = selectedVocUnit;
        if (preference.settingId === userSettingsSelectors.lengthUnit) selectorValue = selectedMeasurementSystem || 'm';
        return settingsRow(preference.settingId, preference.options, preference.header, selectorValue);
    });

export default getUserPreferenceSelectors;
