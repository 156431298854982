import { takeEvery, put, call, CallEffect, PutEffect } from 'redux-saga/effects';
import RequestActions from 'commons/src/models/RequestTypes';
import {
    fetchOutdoorAirInfoSuccess,
    OutdoorActionType,
    FetchOutdoorAirInfo,
    FetchOutdoorAirInfoSuccess,
} from '../../actions/OutdoorActions';
import { RequestActionType, requestError, requestSuccess } from '../../actions/requestActions';
import fetchTileData from '../../api/fetchOutdoorAirInfo';
import { WeatherTileContent } from '../../models/commonTypeScript';
import { CommonRequestType } from '../../reducers/requestReducer';
import { toErrorType } from '../isErrorType';

type FetchOutDoorAirInfoSagaActionType = Generator<
    CallEffect<WeatherTileContent> | PutEffect<FetchOutdoorAirInfoSuccess> | RequestActions,
    void,
    WeatherTileContent
>;

export function* fetchOutdoorAirInfoSaga({ locationId }: FetchOutdoorAirInfo): FetchOutDoorAirInfoSagaActionType {
    try {
        const response = yield call(fetchTileData, locationId);
        yield put(
            fetchOutdoorAirInfoSuccess({
                locationId,
                content: {
                    sourceApi: response.sourceApi,
                    pollutants: response.currentPollutants,
                    weather: response.currentWeather,
                },
            })
        );
        yield put(requestSuccess(CommonRequestType.FetchOutdoorAirInfo, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.FetchOutdoorAirInfo, RequestActionType.Error));
    }
}

export default function* FetchOutdoorAirInfoSaga(): Generator {
    yield takeEvery(OutdoorActionType.FetchOutdoorAirInfoInit, fetchOutdoorAirInfoSaga);
}
