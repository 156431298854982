import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess, requestStreaming } from 'commons/src/actions/requestActions';
import RequestActions from 'commons/src/models/RequestTypes';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import {
    fetchBuildingHealthSummarySuccess,
    FetchBuildingHealthSummaryData,
    FetchBuildingHealthSummarySuccess,
    FetchBuildingHealthHubsStats,
    FetchBuildingHealthHubsStatsSuccess,
    FetchBuildingHealthDeviceStats,
    FetchBuildingHealthDevicesStatsSuccess,
    fetchBuildingHealthDevicesStatsSuccess,
    fetchBuildingHealthHubsStatsSuccess,
    BuildingHealthActionType,
} from '../actions/buildingHealthActions';
import {
    FetchHealthStatusIssueData,
    fetchHealthStatusIssueDataSuccess,
    FetchHealthStatusIssueDataSuccess,
    fetchOrganizationHealthStatusSummarySuccess,
    FetchOrganizationHealthStatusSummarySuccess,
    HealthStatusActionType,
} from '../actions/healthStatusActions';
import {
    getBuildingHealthSummary,
    fetchHubsHealtData,
    fetchDevicesHealthData,
    getOrganizationHealthStatusSummary,
    getHealthStatusIssueData,
} from '../api/healthStatus';
import { BUILDING_HEALTH_TABLE_LENGTH } from '../constants';
import {
    BuildingHealthSummary,
    FetchHubsHealthResponse,
    FetchDevicesHealthResponse,
} from '../models/buildingHealthModels';
import { HealthStatusIssueDataSummary, OrganizationHealthStatusSummary } from '../models/buildingModels';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

// ********** Health Summary ********** //

type FetchBuildingHealthSummaryEffects =
    | CallEffect
    | PutEffect<FetchBuildingHealthSummaryData>
    | PutEffect<FetchBuildingHealthSummarySuccess>
    | RequestActions;

type FetchBuildingHealthSummaryReturnType = Generator<FetchBuildingHealthSummaryEffects, void, BuildingHealthSummary>;

export function* fetchBuildingHealthSummarySaga({
    locationId,
}: FetchBuildingHealthSummaryData): FetchBuildingHealthSummaryReturnType {
    try {
        const buildingHealthSummary = yield call(getBuildingHealthSummary, locationId);
        yield put(fetchBuildingHealthSummarySuccess(locationId, buildingHealthSummary));
        yield put(requestSuccess(RequestType.FetchBuildingHealthSummary, RequestActionType.Success));
    } catch (error) {
        yield put(requestError(toErrorType(error), RequestType.FetchBuildingHealthSummary, RequestActionType.Error));
    }
}

// ********** Organization Health ********** //

type FetchOrganizationHealthStatusSummaryEffect =
    | CallEffect<OrganizationHealthStatusSummary>
    | PutEffect<FetchOrganizationHealthStatusSummarySuccess>
    | RequestActions;

type FetchOrganizationHealthStatusSummarySagaReturnType = Generator<
    FetchOrganizationHealthStatusSummaryEffect,
    void,
    OrganizationHealthStatusSummary
>;

export function* fetchOrganizationHealthStatusSummaryStatusSaga(): FetchOrganizationHealthStatusSummarySagaReturnType {
    try {
        const organizationHealthStatusSummary = yield call(getOrganizationHealthStatusSummary);
        yield put(fetchOrganizationHealthStatusSummarySuccess(organizationHealthStatusSummary));
        yield put(requestSuccess(RequestType.FetchOrganizationHealthStatusSummary, RequestActionType.Success));
    } catch (error) {
        yield put(
            requestError(toErrorType(error), RequestType.FetchOrganizationHealthStatusSummary, RequestActionType.Error)
        );
    }
}

type FetchHealthStatusIssueDataEffects =
    | CallEffect<HealthStatusIssueDataSummary>
    | PutEffect<FetchHealthStatusIssueDataSuccess>
    | RequestActions;

type FetchHealthStatusIssueDataReturnType = Generator<
    FetchHealthStatusIssueDataEffects,
    void,
    HealthStatusIssueDataSummary
>;

export function* fetchHealthStatusIssueDataSaga({
    status,
}: FetchHealthStatusIssueData): FetchHealthStatusIssueDataReturnType {
    try {
        const healthStatusSummary = yield call(getHealthStatusIssueData, status);
        yield put(fetchHealthStatusIssueDataSuccess(healthStatusSummary, status));
        yield put(requestSuccess(RequestType.FetchHealthStatusIssueData, RequestActionType.Success));
    } catch (error) {
        yield put(requestError(toErrorType(error), RequestType.FetchHealthStatusIssueData, RequestActionType.Error));
    }
}
// ********** Hubs health stats ********** //

type FetchBuldingHealthHubsStatsEffects =
    | CallEffect<FetchHubsHealthResponse>
    | PutEffect<FetchBuildingHealthHubsStatsSuccess>
    | RequestActions;

type FetchBuildingHealthHubaStatsReturnType = Generator<
    FetchBuldingHealthHubsStatsEffects,
    void,
    FetchHubsHealthResponse
>;

export function* fetchBuildingHealthHubsDataSaga({
    locationId,
}: FetchBuildingHealthHubsStats): FetchBuildingHealthHubaStatsReturnType {
    try {
        const { hubs } = yield call(fetchHubsHealtData, locationId);
        yield put(fetchBuildingHealthHubsStatsSuccess(locationId, hubs));
        yield put(requestSuccess(RequestType.FetchBuildingHealthHubsStats, RequestActionType.Success));
    } catch (error) {
        yield put(requestError(toErrorType(error), RequestType.FetchBuildingHealthHubsStats, RequestActionType.Error));
    }
}

// ********** Devices health stats ********** //

type FetchBuildingHealthDevicesStatsEffects =
    | CallEffect<FetchDevicesHealthResponse>
    | PutEffect<FetchBuildingHealthDevicesStatsSuccess>
    | RequestActions;

type FetchBuildingHealthDevicesStatsReturnType = Generator<
    FetchBuildingHealthDevicesStatsEffects,
    void,
    FetchDevicesHealthResponse
>;

export function* fetchBuildingHealthDevicesDataSaga({
    locationId,
}: FetchBuildingHealthDeviceStats): FetchBuildingHealthDevicesStatsReturnType {
    try {
        let offset = 0;
        let devicesFetchedSoFar = 0;
        let totalDevicesToFetch = 0;
        do {
            const { totalDevices, devices } = yield call(
                fetchDevicesHealthData,
                locationId,
                BUILDING_HEALTH_TABLE_LENGTH,
                offset
            );

            if (offset === 0) {
                yield put(requestStreaming(RequestType.FetchBuildingHealthDevicesStats, RequestActionType.Streaming));
                totalDevicesToFetch = totalDevices;
            }
            offset += devices.length;
            devicesFetchedSoFar += devices.length;

            yield put(fetchBuildingHealthDevicesStatsSuccess(locationId, devices, totalDevices));
        } while (devicesFetchedSoFar < totalDevicesToFetch);
        yield put(requestSuccess(RequestType.FetchBuildingHealthDevicesStats, RequestActionType.Success));
    } catch (error) {
        yield put(
            requestError(toErrorType(error), RequestType.FetchBuildingHealthDevicesStats, RequestActionType.Error)
        );
    }
}

export default function* BuildingsHealthStatusSagas(): Generator {
    yield all([
        takeEvery(BuildingHealthActionType.FetchBuildingHealthSummary, fetchBuildingHealthSummarySaga),
        takeEvery(BuildingHealthActionType.FetchBuildingHealthHubStats, fetchBuildingHealthHubsDataSaga),
        takeEvery(BuildingHealthActionType.FetchBuildingHealthDevicesStats, fetchBuildingHealthDevicesDataSaga),
        takeEvery(
            HealthStatusActionType.FetchOrganizationHealthStatusSummaryInit,
            fetchOrganizationHealthStatusSummaryStatusSaga
        ),
        takeEvery(HealthStatusActionType.FetchHealthStatusIssueDataInit, fetchHealthStatusIssueDataSaga),
    ]);
}
