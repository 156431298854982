import React, { SyntheticEvent, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Cell, Column, Row, useTable } from 'react-table';
import { mapDeviceType } from 'commons/src/commonFunctions';
import NumberInput from 'commons/src/components/input/Number';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';
import { ServicePeriod } from '../../../../models/commonEnums';
import { SubscriptionSeats } from '../../../../models/subscriptionModels';
import styles from './AddSeatsTable.module.scss';

type UpdateSeats = (updatedSeats: SubscriptionSeats, newServicePeriod: ServicePeriod) => void;
export type ParentProps = {
    seatsPerDevice: SubscriptionSeats[];
    updateSeats: UpdateSeats;
    servicePeriod?: ServicePeriod;
    addingSeats?: boolean;
};

const ProductNameCell = ({ cell, row }: { cell: Cell; row: Row }): React.ReactElement => {
    const { t: txt } = useTranslation();
    const columnData = cell.column;
    const rowData = row.original as SubscriptionSeats;
    const { costPerDevice } = rowData;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { addingSeats, servicePeriod } = columnData;
    const deviceName = txt(`${mapDeviceType(cell.value as AnyDeviceType)}FullName`);
    return (
        <div>
            <div>{deviceName}</div>
            {addingSeats && (
                <div className={styles.subText}>
                    {costPerDevice}/{txt(`Subscription.${servicePeriod}`)}
                </div>
            )}
        </div>
    );
};

const SeatsCountCell = ({ cell, row }: { cell: Cell; row: Row }): React.ReactElement => {
    const { t: txt } = useTranslation();
    const rowData = row.original as SubscriptionSeats;
    const { deployedSeats, seats } = rowData;
    const columnData = cell.column;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { updateSeats, addingSeats, servicePeriod } = columnData;
    const updateSeatsCount = (event: SyntheticEvent<HTMLInputElement>): void => {
        const value = parseInt(event.currentTarget.value, 10);
        updateSeats({ ...rowData, seats: value }, servicePeriod);
    };

    const newSeatsAdded = seats - deployedSeats;
    const subText = addingSeats
        ? `${newSeatsAdded} ${txt('AddSeats.Added')}`
        : `${deployedSeats} ${txt('Partner.DeployedDevices')}`;
    return (
        <>
            <div className={styles.seatsCounter}>
                <NumberInput
                    label=""
                    step={1}
                    minValue={0}
                    id="seatsInput"
                    validate={false}
                    currentValue={seats.toString()}
                    onChange={updateSeatsCount}
                    testId="seats-input"
                />
            </div>
            <div className={styles.smallText}>{subText}</div>
        </>
    );
};

export default function AddSeatsTable({
    seatsPerDevice,
    updateSeats,
    servicePeriod,
    addingSeats,
}: ParentProps): React.ReactElement {
    const { t: txt } = useTranslation();

    const columns = useMemo<Column[]>(
        () => [
            {
                Header: txt('Subscription.Product'),
                accessor: 'deviceType',
                className: styles.firstCell,
                servicePeriod,
                addingSeats,
                Cell: ProductNameCell,
            },
            {
                Header: txt('Subscription.Seats'),
                accessor: 'seats',
                className: styles.seatsCell,
                Cell: SeatsCountCell,
                updateSeats,
                servicePeriod,
                addingSeats,
            },
        ],
        [servicePeriod, seatsPerDevice]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: seatsPerDevice,
    });

    return (
        <div {...getTableProps()} className={styles.table}>
            {headerGroups.map(headerGroup => (
                <div {...headerGroup.getHeaderGroupProps({ className: styles.header })}>
                    {headerGroup.headers.map(column => {
                        return (
                            <div
                                {...column.getHeaderProps([
                                    {
                                        className: classNames(styles.cell, column.className),
                                    },
                                ])}
                            >
                                <span>{column.render('Header')}</span>
                            </div>
                        );
                    })}
                </div>
            ))}
            <div {...getTableBodyProps()}>
                {rows.length > 0 &&
                    rows.map(row => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()}>
                                <div className={styles.row}>
                                    {row.cells.map(({ getCellProps, render, column }) => {
                                        return (
                                            <div
                                                {...getCellProps({
                                                    className: classNames(styles.cell, column.className),
                                                })}
                                            >
                                                <span>{render('Cell')}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
