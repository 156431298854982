import { Rating } from 'commons/src/models/commonEnums';
import { ThresholdRange } from 'commons/src/models/commonTypeScript';

const deviceLevel = (value: number, ranges: ThresholdRange[]): Rating => {
    const inRange = ranges.find(range => {
        const aboveFrom = !range.from || (range.from && value >= range.from);
        const belowTo = !range.to || (range.to && value < range.to);
        return aboveFrom && belowTo;
    });
    return inRange ? inRange.rating : Rating.GOOD;
};

export default deviceLevel;
