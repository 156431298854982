// https://developers.intercom.com/installing-intercom/docs/basic-javascript

import React, { useEffect } from 'react';
import { Role } from 'aws-sdk/clients/s3';
import { INTERCOM_APP_ID, INTERCOM_B2B_APP_ID } from '../constants';
import { IntercomB2BUserType, MixpanelChatUserType } from '../models/commonTypeScript';
import { userIsHbs } from './findUserType';

const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
const widgetUrl = 'https://widget.intercom.io/widget/';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IntercomAPI = (...args: any[]): void => {
    if (canUseDOM && window.Intercom) {
        window.Intercom(...args);
    }
};

type Props = {
    user: MixpanelChatUserType;
    b2bUserInfo: IntercomB2BUserType;
    hbsOrgRole?: Role;
};

const Intercom = ({ user, b2bUserInfo, hbsOrgRole }: Props): React.ReactElement => {
    if (!canUseDOM) return <div />;
    const b2bUser = userIsHbs();
    const showB2BIntercom = b2bUser;

    const initializeIntercom = (): void => {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        const itercomWidgetUrl = `${widgetUrl}${showB2BIntercom ? INTERCOM_B2B_APP_ID : INTERCOM_APP_ID}`;
        script.async = true;
        script.src = itercomWidgetUrl;
        head.appendChild(script);
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect((): (() => void) => {
        if (!window.Intercom) {
            initializeIntercom();
        }
        if (showB2BIntercom) {
            window.intercomSettings = {
                ...b2bUserInfo,
                app_id: INTERCOM_B2B_APP_ID,
                api_base: 'https://api-iam.eu.intercom.io',
                custom_launcher_selector: '#open_intercom',
            };
        } else {
            window.intercomSettings = { ...user, hbsOrgRole, app_id: INTERCOM_APP_ID };
        }
        IntercomAPI('boot');

        return (): void => {
            if (canUseDOM && window.Intercom) {
                IntercomAPI('shutdown');
                delete window.Intercom;
                delete window.intercomSettings;
            }
        };
    }, []);

    return <div />;
};

export default Intercom;
