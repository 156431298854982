import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import DeviceHealthStatus from '../../models/deviceHealthStatus';
import MaterialIcon from '../MaterialIcon';
import styles from './RssiComponent.module.scss';
import RssiSignalStrength from './RssiSignalStrength';

type Props = {
    rssi: number | undefined;
    healthStatus: DeviceHealthStatus;
};

const RssiComponent = ({ healthStatus, rssi }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const hasData = !Number.isNaN(Number(rssi));

    if (healthStatus === DeviceHealthStatus.offline) {
        return (
            <div className={styles.offline}>
                <MaterialIcon name="signal_cellular_connected_no_internet_4_bar" />
                {hasData ? [rssi, ' dBm'] : txt('DeviceSettings.Offline')}
            </div>
        );
    }
    if (hasData) {
        return (
            <div className={styles.online}>
                <RssiSignalStrength inverted={false} rssi={rssi} displayDescription={false} />
                {[rssi, ' dBm']}
            </div>
        );
    }
    return <div />;
};

export default RssiComponent;
