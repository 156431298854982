import React from 'react';

const DeviceIcon = ({ className }: { className?: string }): React.ReactElement => (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
        <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8 S16.4,20,12,20z" />
        <circle cx="8.5" cy="16.5" r="0.5" />
        <circle cx="15.5" cy="16.5" r="0.5" />
        <circle cx="12" cy="12" r="2" />
    </svg>
);

export default DeviceIcon;
