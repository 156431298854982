import React from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'commons/src/components/dropdown/MultipleAttrDropdown';
import { ServicePeriod } from '../../../../models/commonEnums';
import { RatePlansPerPeriod } from '../../../../models/subscriptionModels';
import servicePeriodTranslation from '../commonSubscriptionFunctions';
import styles from './EstimatedCost.module.scss';

type Props = {
    previewCostTable: React.ReactElement;
    ratePlans?: RatePlansPerPeriod;
    servicePeriod: ServicePeriod;
    setServicePeriod: (period: ServicePeriod) => void;
};

export const EstimateCostComponent = ({
    previewCostTable,
    ratePlans,
    servicePeriod,
    setServicePeriod,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const servicePeriodOptions = Object.keys(ServicePeriod).map(period => ({
        id: period,
        inputValue: servicePeriodTranslation(
            period,
            txt(`Subscription.${period}`),
            txt('Subscription.Discount'),
            ratePlans
        ),
    }));

    const onSelectServicePeriod = (selected: { id: string; inputValue: string }): void => {
        const period = selected.id as ServicePeriod;
        setServicePeriod(period);
    };

    return (
        <div className={styles.ratePlanSection}>
            <div className={styles.header}>{txt('Subscription.EstimatedCost')}</div>
            <div className={styles.content}>
                <div className="form__field form__field--single-width">
                    <Dropdown
                        loading={false}
                        id="servicePeriod"
                        title="Subscription.ServicePeriod"
                        value={servicePeriodTranslation(
                            servicePeriod,
                            txt(`Subscription.${servicePeriod}`),
                            txt('Subscription.Discount'),
                            ratePlans
                        )}
                        defaultOption={servicePeriodTranslation(
                            servicePeriod,
                            txt(`Subscription.${servicePeriod}`),
                            txt('Subscription.Discount'),
                            ratePlans
                        )}
                        onSelect={onSelectServicePeriod}
                        options={servicePeriodOptions}
                        testAttr="service-period-list"
                    />
                </div>
                {previewCostTable}
            </div>
            <div className={styles.text}>{txt('Subscription.PreviewEstimateCostDescription')}</div>
        </div>
    );
};

export default EstimateCostComponent;
