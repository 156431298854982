import React from 'react';
import { useTranslation } from 'react-i18next';
import ResponseBox from '../../components/responseMessages/ResponseBox';
import { A4BSupportMail } from '../../constants';
import { LocationRfRegionResponse } from '../../models/commonTypeScript';

export type Props = {
    onSuccessText: string;
    onSuccessSubText: string;
    onFailText: string;
    onFailSubText: string;
    rfRegionResponse?: LocationRfRegionResponse;
};

const RegionChangeResponseBox = (props: Props): React.ReactElement | null => {
    const { t: txt } = useTranslation();
    const { rfRegionResponse, onSuccessText, onFailText, onSuccessSubText, onFailSubText } = props;

    if (!rfRegionResponse) return null;

    const showRedBox = !rfRegionResponse.validChange;
    const showGreenBox = rfRegionResponse.changesRegion && rfRegionResponse.validChange;
    const text = showRedBox ? onFailText : onSuccessText;
    const subText = (
        <span id="rfRegionResponseBox">
            {showRedBox ? onFailSubText : onSuccessSubText} {txt('LocationRfRegion.ContactInfo')}
            <a href={`mailto: ${A4BSupportMail}`}>{A4BSupportMail}</a>
        </span>
    );

    if (!showRedBox && !showGreenBox) return null;

    return <ResponseBox greenBox={showGreenBox} text={text} subtext={subText} />;
};

export default RegionChangeResponseBox;
