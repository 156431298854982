import React from 'react';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { mapDeviceType } from 'commons/src/commonFunctions';
import { BoldText, SubHeader, FlexWrapperSpaced } from 'commons/src/components/PDF/PdfConstants';
import ViewImage from 'commons/src/img/deviceIcons/device-png-view.png';
import PlusImage from 'commons/src/img/deviceIcons/device-png-wave-plus.png';
import WaveImage from 'commons/src/img/deviceIcons/device-png-wave.png';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { AnyDeviceType, DeviceWithKeyInfo, SegmentProperties } from 'commons/src/models/commonTypeScript';
import { PDFContext } from '../../../models/common';

const styles = StyleSheet.create({
    deviceInfoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    deviceIcon: {
        width: 40,
        height: 40,
        padding: 5,
    },
});

type DeviceDetailsProps = {
    txt: PDFContext;
    device: DeviceWithKeyInfo;
    deviceProps: SegmentProperties;
};

type DeviceInfoProps = {
    txt: PDFContext;
    serialNumber: string;
    deviceType: string;
};

export const DeviceInfo = (props: DeviceInfoProps): React.ReactElement => {
    const { serialNumber, deviceType, txt } = props;
    const viewTypes = [DeviceTypeNames.viewRadon, DeviceTypeNames.viewPlusBusiness, DeviceTypeNames.viewPlus];
    let deviceIcon = WaveImage;
    if (deviceType === DeviceTypeNames.wavePlus) deviceIcon = PlusImage;
    else if (viewTypes.includes(deviceType as DeviceTypeNames)) deviceIcon = ViewImage;

    return (
        <View style={styles.deviceInfoWrapper}>
            <Image style={styles.deviceIcon} src={deviceIcon} />
            <View>
                <Text>{[txt('DeviceType'), ': ', txt(`${mapDeviceType(deviceType as AnyDeviceType)}FullName`)]}</Text>
                <Text>{`${txt('SerialNumber')}: ${serialNumber}`}</Text>
            </View>
        </View>
    );
};

const DeviceDetails = ({ txt, device, deviceProps }: DeviceDetailsProps): React.ReactElement => (
    <View>
        <FlexWrapperSpaced style={{ marginBottom: '20px' }}>
            <SubHeader>{device.segmentName}</SubHeader>
            <DeviceInfo serialNumber={device.serialNumber} deviceType={device.type} txt={txt} />
            <View>
                <BoldText>{txt('Floor')}</BoldText>
                <Text>
                    {deviceProps && deviceProps.floor !== undefined ? deviceProps.floor.toString() : txt('NotSet')}
                </Text>
            </View>
            <View>
                <BoldText>{txt('RoomType')}</BoldText>
                <Text>
                    {deviceProps && deviceProps.roomType
                        ? txt(`RoomTypeOptions.${deviceProps.roomType}`)
                        : txt('NotSet')}
                </Text>
            </View>
            <View />
        </FlexWrapperSpaced>
    </View>
);

export default DeviceDetails;
