import React from 'react';

const WaveMiniIcon = (): React.ReactElement => (
    <svg
        version="1.1"
        className="device-svg-icons"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
    >
        <path
            d="M24,2C11.9,2,2,11.9,2,24s9.9,22,22,22s22-9.9,22-22S36.1,2,24,2z M24,4c11,0,20,9,20,20
            s-9,20-20,20S4,35,4,24S13,4,24,4z M14.4,20.5H12V29h1.4v-6.8h0L16,29h1.1l2.6-6.8h0V29h1.5v-8.5h-2.4l-2.2,6h0L14.4,20.5z
             M24.8,23.2h-1.4V29h1.4V23.2z M30.1,23.1c-0.6,0-0.6,0-1.1,0.3c-0.4,0.3-0.4,0.3-0.6,0.6l-0.1,0.1h0v-0.9h-1.4V29h1.4v-3.2
             c0-0.3,0-0.3,0.1-0.6c0.1-0.3,0.1-0.3,0.2-0.5c0.2-0.2,0.2-0.2,0.4-0.4c0.3-0.1,0.3-0.1,0.6-0.1c0.3,0,0.3,0,0.6,0.1
             c0.2,0.1,0.2,0.1,0.3,0.4c0.1,0.2,0.1,0.2,0.2,0.5c0,0.2,0,0.2,0,0.4l0,0.2V29h1.4v-3.6c0-0.4,0-0.4-0.1-0.9
             c-0.1-0.4-0.1-0.4-0.4-0.7s-0.3-0.3-0.7-0.5S30.6,23.1,30.1,23.1z M35.7,23.2h-1.4V29h1.4V23.2z M24.1,20.4c-0.4,0-0.4,0-0.7,0.3
             c-0.3,0.3-0.3,0.3-0.3,0.6s0,0.3,0.3,0.6c0.3,0.3,0.3,0.3,0.7,0.3s0.4,0,0.7-0.2c0.3-0.2,0.3-0.2,0.3-0.6s0-0.4-0.3-0.6
             C24.5,20.4,24.5,20.4,24.1,20.4z M34.9,20.4c-0.4,0-0.4,0-0.7,0.3C34,20.9,34,20.9,34,21.3s0,0.3,0.3,0.6c0.3,0.3,0.3,0.3,0.7,0.3
             s0.4,0,0.7-0.2s0.3-0.2,0.3-0.6s0-0.4-0.3-0.6S35.3,20.4,34.9,20.4z"
        />
    </svg>
);

export default WaveMiniIcon;
