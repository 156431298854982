import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './LinkButton.module.scss';

type Props = {
    title?: string;
    link: string;
    testId?: string;
    icon?: React.ReactElement;
    small?: boolean;
    noLineBreak?: boolean;
};

const LinkButton = ({ title, testId, link, icon, small, noLineBreak }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <a
            {...{ 'data-testid': testId }}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(styles.button, {
                [styles.smallButton]: small,
                [styles.noLineBreak]: noLineBreak,
            })}
        >
            <div className={styles.icon}>{icon}</div>
            {title && txt(title)}
        </a>
    );
};
export default LinkButton;
