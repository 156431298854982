/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Highcharts from 'highcharts';
import { dateFormats, tooltipBorderColor, colors } from 'commons/src/constants';

type ConfigInput = {
    dateFormat: keyof typeof dateFormats;
    onClick: (event: PointerEvent) => void;
    graphId: string;
    language: string;
    text: (text: string) => string;
    series: {
        name: string;
        data: [number, number | null][];
        color: string;
        className: string;
    }[];
};

const heatingCoolingConfig = ({ dateFormat, onClick, graphId, series, text, language }: ConfigInput) => ({
    credits: {
        enabled: false,
    },
    title: {
        text: '',
    },
    chart: {
        id: graphId,
        height: 260,
        type: 'column',
        spacingBottom: 5,
        className: 'building-insight__card__section__graph--details',
        style: {
            fontFamily: 'OpenSans-Regular',
        },
    },
    tooltip: {
        borderColor: tooltipBorderColor,
        style: {
            color: colors.white,
        },
        enabled: !!language,
        hideDelay: 0,
        shared: true,
        formatter(this: Highcharts.TooltipFormatterContextObject): string {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { x, points } = this;
            const numberOfDevices = (points || []).reduce((total, timeStamp) => {
                return total + (timeStamp.y || 0);
            }, 0);
            const day = `${Highcharts.dateFormat(
                dateFormats[dateFormat].weekDay,
                x as number
            )} <br/> ${Highcharts.dateFormat(dateFormats[dateFormat].date, x as number)}`;
            return `${text(day)}, <br/>${numberOfDevices} ${text('DevicesLowerCase')}`;
        },
    },
    xAxis: {
        lineColor: 'transparent',
        tickLength: 0,
        type: 'dateTime',
        gridLineWidth: 0,
        tickInterval: 24 * 3600 * 1000,
        margin: 0,
        labels: {
            formatter(): string | React.ReactElement {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const { value } = this;
                return Highcharts.dateFormat(dateFormats[dateFormat].axisDate.week, value);
            },
        },
    },
    yAxis: {
        min: 0,
        gridLineWidth: 1,
        title: {
            text: text('BuildingEnergy.NumberOfDevices'),
        },
    },
    series,
    plotOptions: {
        series: {
            type: 'column',
            stacking: 'normal',
            showInLegend: true,
            pointWidth: 15,
            connectNulls: false,
            dataLabels: {
                enabled: true,
                inside: true,
            },
            states: {
                hover: { lineWidthPlus: 0, opacity: 0.6 },
                select: {
                    opacity: 0.6,
                    borderColor: 'black',
                    borderWidth: 2,
                },
            },
            allowPointSelect: true,
            animation: { duration: 1200 },
            borderRadius: 4,
            events: {
                click(event: PointerEvent): void {
                    onClick(event);
                },
            },
        },
    },
    legend: {
        symbolRadius: 3,
    },
    exporting: {
        sourceWidth: 1000,
        sourceHeight: 200,
        chartOptions: {
            subtitle: null,
        },
        buttons: {
            contextButton: {
                enabled: false,
            },
        },
    },
});

export default heatingCoolingConfig;
export type HeatingCoolingConfig = ReturnType<typeof heatingCoolingConfig>;
