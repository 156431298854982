import React, { useState } from 'react';
import classNames from 'classnames';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { BUILDING_THRESHOLD_BREACH_INSIGHT_BAR_SELECTED } from 'commons/src/analytics/AnalyticsEvents';
import { dateFormats } from 'commons/src/constants';
import { Resolution, SensorTypes, TimePeriod } from 'commons/src/models/commonEnums';
import { BuildingInsight } from '../../../models/buildingModels';
import { Store } from '../../../reducers';
import generateDetailsConfig from './BuildingInsightDetailsGraphConfig';
import TotalByDeviceBreakDown from './TotalByDeviceBreakDown';

type StateProps = {
    dateFormat: keyof typeof dateFormats;
    language: string;
};
export type ParentProps = {
    sensor: SensorTypes;
    insight: BuildingInsight;
    timeZone?: string;
    selectedPeriod: {
        toDate: string;
        fromDate: string;
        resolution: Resolution;
        name: TimePeriod;
    };
    selectedHighThreshold: { id: string; inputValue: string };
    selectedLowThreshold?: { id: string; inputValue: string };
};

export type Props = ParentProps & StateProps;

export const BuildingSensorTotalByDevice = ({
    dateFormat,
    timeZone,
    insight,
    selectedHighThreshold,
    selectedLowThreshold,
    selectedPeriod,
    sensor,
    language,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [selectedDevice, setSelectedDevice] = useState<string | undefined>();
    const {
        thresholds,
        totalDeviceData: { devicesInTimeFrame },
    } = insight;

    const onGraphClick = (clickData: PointerEvent): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const selectedSerialNumber = clickData.point.category;
        if (selectedDevice === selectedSerialNumber) {
            setSelectedDevice(undefined);
        } else {
            analyticsLogger(BUILDING_THRESHOLD_BREACH_INSIGHT_BAR_SELECTED, {
                pageType: PageType.Building,
                barType: 'device',
            });
            setSelectedDevice(selectedSerialNumber);
        }
    };
    const devicesSortedByOutsideTime = devicesInTimeFrame.sort((device1, device2) => {
        if (
            device1.overThresholdsTime + device1.underThresholdsTime >
            device2.overThresholdsTime + device2.underThresholdsTime
        )
            return -1;
        return 1;
    });
    const detailsData = devicesSortedByOutsideTime.map(device => device.overThresholdsTime);
    const lowerLimitChartData = devicesSortedByOutsideTime.map(device => device.underThresholdsTime);
    const displayLegends = thresholds.length > 1;
    const categories = devicesSortedByOutsideTime.map(device => device.serialNumber);

    const graphDetailsConfig = generateDetailsConfig(
        detailsData,
        lowerLimitChartData,
        categories,
        devicesInTimeFrame,
        onGraphClick,
        displayLegends ? 200 : 175,
        txt,
        displayLegends,
        selectedHighThreshold.inputValue,
        false,
        true,
        `totalByDevice${sensor}`,
        selectedLowThreshold && selectedLowThreshold.inputValue
    );

    return (
        <div className="building-insight__card__body">
            <div
                className={classNames('building-insight__card__section', {
                    'building-insight__card__section--with-legend': displayLegends,
                })}
            >
                <div className="building-insight__card__section--content">
                    <HighchartsReact highcharts={Highcharts} options={graphDetailsConfig} />
                </div>
            </div>
            <TotalByDeviceBreakDown
                insight={insight}
                selectedDevice={selectedDevice}
                selectedHighThreshold={selectedHighThreshold}
                selectedLowThreshold={selectedLowThreshold}
                selectedPeriod={selectedPeriod}
                timeZone={timeZone}
                dateFormat={dateFormat}
                language={language}
                sensor={sensor}
            />
        </div>
    );
};
const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { dateFormat, language },
    } = store;
    return {
        dateFormat,
        language,
    };
};

export default connect(mapStateToProps)(BuildingSensorTotalByDevice);
