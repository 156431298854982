import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import LabeledText from 'commons/src/components/LabeledText';
import { dateFormats } from 'commons/src/constants';
import { BuildingType, DayUsageHours } from 'commons/src/models/commonTypeScript';

const defaultUsageHours = {
    monday: { closed: false },
    tuesday: { closed: false },
    wednesday: { closed: false },
    thursday: { closed: false },
    friday: { closed: false },
    saturday: { closed: false },
    sunday: { closed: false },
};

export type Props = {
    optionalBuildingProps: string[];
    building?: BuildingType | null;
    dateFormat: keyof typeof dateFormats;
    validate: boolean;
};

const OpeningHours = (props: Props): React.ReactElement => {
    const { optionalBuildingProps, building, dateFormat, validate } = props;
    const { t: txt } = useTranslation();
    const usageHoursRequired = !optionalBuildingProps.includes('openingHours');
    const usageHours: { [day: string]: DayUsageHours } = {
        ...defaultUsageHours,
        ...(building && building.usageHours),
    };
    const alwaysClosed = !Object.values(usageHours).some(day => !day.closed);

    return (
        <div>
            <h3>{txt('Building.DayScheduleOpeningHours')}</h3>
            <div className="form__row">
                {Object.keys(usageHours).map(day => {
                    const from = usageHours[day].from
                        ? moment(usageHours[day].from, 'HH:mm').format(dateFormats[dateFormat].hourFormat)
                        : '';
                    const to = usageHours[day].to
                        ? moment(usageHours[day].to, 'HH:mm').format(dateFormats[dateFormat].hourFormat)
                        : '';
                    const invalid =
                        (!!building &&
                            validate &&
                            usageHoursRequired &&
                            !((usageHours[day].from && usageHours[day].to) || usageHours[day].closed)) ||
                        alwaysClosed;
                    return (
                        <div key={day} className="labeled-text form__row__time-input">
                            <LabeledText
                                label={day.replace(/^\w/, c => c.toUpperCase())}
                                id={day}
                                invalid={invalid}
                                value={
                                    usageHours[day].closed && !alwaysClosed ? txt('Building.Closed') : `${from} - ${to}`
                                }
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default OpeningHours;
