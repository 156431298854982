import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { SegmentProperties } from 'commons/src/models/commonTypeScript';
import { LabelsPayload } from '../models/common';

const url = (serialNumber: string, segmentId: string): string =>
    `/devices/${serialNumber}/segments/${segmentId}/properties`;

export const fetchSegmentProperties = async (serialNumber: string, segmentId: string): Promise<SegmentProperties> =>
    fetch(url(serialNumber, segmentId), {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const updateSegmentProperties = async (
    serialNumber: string,
    segmentId: string,
    segmentProperties: SegmentProperties
): Promise<void> =>
    fetch(url(serialNumber, segmentId), {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(segmentProperties),
    });

export const patchSegmentProperties = async (
    serialNumber: string,
    segmentId: string,
    segmentProperties: SegmentProperties
): Promise<void> =>
    fetch(url(serialNumber, segmentId), {
        method: 'PATCH',
        headers: await getFetchHeaders(),
        body: JSON.stringify(segmentProperties),
    });

export const fetchSegmentLabels = async (serialNumber: string): Promise<{ labels: LabelsPayload }> =>
    fetch(`/devices/${serialNumber}/segments/latest/labels`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const updateSegmentLabels = async (serialNumber: string, labels: { labels: LabelsPayload }): Promise<void> =>
    fetch(`/devices/${serialNumber}/segments/latest/labels`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(labels),
    });

interface FetchUrlPayload {
    serialNumber: string;
}
export const fetchGeneratedSegmentPublicUrl = async ({ serialNumber }: FetchUrlPayload): Promise<{ urlPath: string }> =>
    fetch(`/devices/${serialNumber}/segments/latest/properties/public-url`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const getSegmentPropFilters = async (
    locationId: string
): Promise<{ [filterType: string]: { name: string; devices: string[] }[] }> =>
    fetch<{ [filterType: string]: { name: string; devices: string[] }[] }>(
        `/locations/${locationId}/segment-properties`,
        {
            method: 'GET',
            headers: await getFetchHeaders(),
        }
    );
