import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ModalHeader from 'commons/src/components/modals/ModalHeader';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import { sensorUnits } from 'commons/src/constants';
import logo from '../../../../img/logos/airthings_for_business_logo.svg';
import { BreachType } from '../../../../models/buildingModels';
import { mockEmailPreview } from '../__mocks__/EmailSummaryMocks';
import {
    EmailPreviewLocation,
    EmailPreviewContent,
    EmailPreviewSegment,
    EmailPreviewThreshold,
} from '../EmailSummaryModels';
import styles from './EmailSummaryPreview.module.scss';

export type Props = {
    onClose: () => void;
};

const EmailSummaryPreviewModalComponent = ({ onClose }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const fromMinutesToFormattedTime = (totalMinutes: number): string => {
        const hours = Math.floor(totalMinutes / 60);
        const remainingMinutes = totalMinutes % 60;

        if (hours === 0) return `${remainingMinutes}min`;
        if (remainingMinutes === 0) return `${hours}h`;
        return `${hours}h ${remainingMinutes}min`;
    };

    const timeOverAndUnderText = (rules: EmailPreviewThreshold[], unit: keyof typeof sensorUnits): string => {
        const timeOver = rules.find(rule => rule.type === BreachType.over)?.value;
        const timeUnder = rules.find(rule => rule.type === BreachType.under)?.value;

        if (rules.length === 1) {
            return `Time over ${timeOver}${sensorUnits[unit]}`;
        }
        return `Time over ${timeOver}${sensorUnits[unit]} and below ${timeUnder}${sensorUnits[unit]}`;
    };

    const renderSegmentRow = (segment: EmailPreviewSegment, rowNumber: number): React.ReactElement => (
        <div className={styles.segment} key={rowNumber}>
            <span>{`${rowNumber}. ${segment.name}`}</span>
            <span className={styles.hyphenSpacing}>{' - '}</span>
            <span>{fromMinutesToFormattedTime(segment.minutesOutside)}</span>
        </div>
    );

    const renderLocationRow = (location: EmailPreviewLocation, index: number): React.ReactElement => (
        <div className={styles.location} key={index}>
            <div className={classNames('small-padding-bottom', styles.flexCenter)}>
                <span className={styles.locationName}>{location.name}</span>
                <span className="text-small">
                    {`${location.numOfBreachedDevices}/${location.totalDevices} devices`}
                </span>
            </div>
            {location.segments.map((segment, rowNumber) => renderSegmentRow(segment, rowNumber + 1))}
        </div>
    );

    const renderSensorBlock = (sensor: EmailPreviewContent): React.ReactElement => (
        <div className={styles.sensorBlock} key={sensor.sensorType}>
            <div className={styles.flexCenter}>
                <div className={styles.sensorIcon}>
                    <SensorIcon sensor={sensor.sensorType} />
                </div>
                <div>{txt(sensor.sensorType)}</div>
                <div className={styles.hyphenSpacing}>{' - '}</div>
                <div>{timeOverAndUnderText(sensor.rules, sensor.sensorUnit)}</div>
            </div>
            {sensor.locations.map((location, index) => renderLocationRow(location, index))}
        </div>
    );

    return (
        <Modal
            isOpen
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--size-large"
            overlayClassName="modal modal__overlay"
        >
            <ModalHeader headerText="Email preview" onClose={onClose} />
            <div className={styles.body}>
                <div className={styles.content}>
                    <img src={logo} className={styles.logo} alt="Airthings Logo" />
                    <h3 className={styles.h3}>Last week &quot;Time over threshold&quot; summary</h3>
                    <p className={styles.subHeader}>[last week dates]</p>
                    <div className={styles.flexCenter}>
                        <MaterialIcon name="info" extraClass="margin-10-right" />
                        <div>Showing devices within opening hours</div>
                    </div>
                    {mockEmailPreview.map(data => renderSensorBlock(data))}
                </div>
            </div>
        </Modal>
    );
};

export default EmailSummaryPreviewModalComponent;
