import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { sensorUnits } from 'commons/src/constants';
import { Rating, SensorTypes } from 'commons/src/models/commonEnums';
import { ThresholdRange } from 'commons/src/models/commonTypeScript';

export type Props = {
    ranges: ThresholdRange[];
    unit: keyof typeof sensorUnits;
    sensorType: keyof typeof SensorTypes;
    devicesInEachLevel?: { [Rating.GOOD]: number; [Rating.FAIR]: number; [Rating.POOR]: number };
};

const ThresholdRanges = ({ ranges, unit, sensorType, devicesInEachLevel }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const thresholdValues = (): React.ReactElement[] | null =>
        ranges.length > 0
            ? ranges.map((range, index) => {
                  let headerText = 'ThresholdKeys.NormalLevel';
                  if (range.rating === Rating.FAIR) headerText = 'ThresholdKeys.ActionLevel';
                  else if (range.rating === Rating.POOR) headerText = 'ThresholdKeys.WarningLevel';
                  const devicesOnLevel = devicesInEachLevel && devicesInEachLevel[range.rating];
                  const thresholdKey = `${sensorType}-${index}-range`;
                  return (
                      <div key={thresholdKey} className="threshold-ranges__element">
                          <div>
                              <div
                                  className={classNames('sensor__line', {
                                      'sensor__line--ok': range.rating === Rating.GOOD,
                                      'sensor__line--alert': range.rating === Rating.POOR,
                                      'sensor__line--warning': range.rating === Rating.FAIR,
                                  })}
                              />
                              <span>{txt(headerText)} </span>
                              <div className="threshold-ranges__element__margin-row">
                                  {range.from ? '≥' : '<'}
                                  {range.from && [' ', range.from, sensorUnits[unit]]} {range.to && range.from && '<'}
                                  {range.to && [' ', range.to, sensorUnits[unit]]}
                              </div>
                              {devicesInEachLevel && (
                                  <div className="threshold-ranges__element__margin-row text-large">
                                      {devicesOnLevel}{' '}
                                      {devicesOnLevel === 1 ? txt('DeviceLowerCase') : txt('DevicesLowerCase')}
                                  </div>
                              )}
                          </div>
                      </div>
                  );
              })
            : null;
    return <div className="threshold-ranges">{thresholdValues()}</div>;
};

export default ThresholdRanges;
