import { all, takeEvery, put, call } from 'redux-saga/effects';
import { initialize } from '../actions';
import { SelectGroup, SelectGroupActionType, selectGroupSuccess } from '../actions/selectGroupActions';
import { postActiveUserGroupId } from '../api/updateSettings';
import { getSelectedGroupFromStorage, setSelectedGroupInStorage } from '../components/findUserType';
import { GroupType } from '../models/commonEnums';
import { SelectedGroup } from '../models/commonTypeScript';
import history from '../store/history';

function* selectGroupSaga({ organizationId, userGroupId, groupType }: SelectGroup): Generator {
    const currentSelectedGroup: SelectedGroup | null = getSelectedGroupFromStorage();

    if (groupType === GroupType.consumer) {
        localStorage.removeItem('hbs');
    }

    if (currentSelectedGroup?.userGroupId === userGroupId) {
        yield put(selectGroupSuccess());
        return;
    }

    setSelectedGroupInStorage({ organizationId, userGroupId, groupType });

    if (
        !(groupType === GroupType.consumer && currentSelectedGroup?.groupType === GroupType.consumer) &&
        (groupType === GroupType.consumer || currentSelectedGroup?.groupType === GroupType.consumer)
    ) {
        // Going to or coming from consumer version requires a full reload
        if (!currentSelectedGroup?.userGroupId) {
            window.location.reload();
        } else window.location.assign('/');
    } else if (currentSelectedGroup?.userGroupId) {
        yield put(selectGroupSuccess());
        history.push('/');
    }
    yield call(postActiveUserGroupId, userGroupId);
    yield put(initialize());
}

export default function* selectGroupSagas(): Generator {
    yield all([takeEvery(SelectGroupActionType.SelectGroupInit, selectGroupSaga)]);
}
