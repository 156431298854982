import React from 'react';
import { useTranslation } from 'react-i18next';
import ViewPlusImg from '../../../img/deviceIcons/img-view-plus-screen.png';
import { DisplayScreenOptions } from '../../../models/commonTypeScript';
import ScreenPreview from './ScreenPreview';

export type Props = {
    screenOptions: DisplayScreenOptions[];
    selectedOptions: string[];
    publicUrl?: string;
};

export const DisplaySelectorComponent = ({ screenOptions, selectedOptions, publicUrl }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    return (
        <div className="edit-device edit-device__screen-selector">
            <div className="edit-device__screen-preview">
                <img className="edit-device__screen-preview__img" src={ViewPlusImg} alt={txt('ViewPlusImg')} />
                <ScreenPreview screenOptions={screenOptions} selectedOptions={selectedOptions} publicUrl={publicUrl} />
            </div>
        </div>
    );
};

export default DisplaySelectorComponent;
