import React, { useEffect } from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import InactiveSubscriptionContent from './InactiveSubscriptionContent';
import styles from './InactiveSubscriptionModal.module.scss';

type ParentProps = {
    displayTabs: boolean;
};

const InactiveSubscriptionModal = ({ displayTabs }: ParentProps): React.ReactElement => {
    const headerElement = document.getElementById('pageHeader');
    const hamburgerElement = document.getElementById('hamburgerHeader');

    const updateHeaderHeight = (): void => {
        const updatedHeaderElement = document.getElementById('pageHeader');
        const updatedHamburgerElement = document.getElementById('hamburgerHeader');
        const hamburgerHeight = updatedHamburgerElement?.offsetHeight || 0;
        const newHeight = updatedHeaderElement?.offsetHeight || 0;
        const root = document.documentElement;
        root.style.setProperty('--header-height', `${newHeight + hamburgerHeight}px`);
    };

    useEffect(() => {
        updateHeaderHeight();
    }, [headerElement, hamburgerElement, displayTabs]);

    useEffect(() => {
        window.addEventListener('resize', updateHeaderHeight);
        return (): void => window.removeEventListener('resize', updateHeaderHeight);
    }, []);

    return (
        <Modal
            isOpen
            appElement={document.body}
            className={classNames('modal__content modal__content--size-medium')}
            overlayClassName={styles.overlay}
        >
            <InactiveSubscriptionContent />
        </Modal>
    );
};

export default InactiveSubscriptionModal;
