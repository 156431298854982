import { RequestActionType } from 'commons/src/actions/requestActions';
import { BusinessActionRequestType, NewWebhook, Webhook, WebhookEvent } from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum WebhooksActionType {
    FetchWebhooksInit = 'FETCH_WEBHOOKS/INIT',
    UpdateWebhookInit = 'UPDATE_WEBHOOK/INIT',
    UpdateWebhookActiveStateInit = 'UPDATE_WEBHOOK_ACTIVE_STATE/INIT',
    AddWebhookInit = 'ADD_WEBHOOK/INIT',
    TestWebhookInit = 'TEST_WEBHOOK/INIT',
    DeleteWebhookInit = 'DELETE_WEBHOOK/INIT',
    FetchWebhookEventsInit = 'FETCH_WEBHOOK_EVENTS/INIT',
}

export interface FetchWebhooks extends BusinessActionRequestType {
    type: WebhooksActionType.FetchWebhooksInit;
}
export const fetchWebhooks = (): FetchWebhooks => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchWebhooks,
    type: WebhooksActionType.FetchWebhooksInit,
});

export const FETCH_WEBHOOKS_SUCCESS = 'FETCH_WEBHOOKS_SUCCESS';
export interface FetchWebhooksSuccess {
    type: typeof FETCH_WEBHOOKS_SUCCESS;
    webhooks: Webhook[];
}
export const fetchWebhooksSuccess = (webhooks: Webhook[]): FetchWebhooksSuccess => ({
    type: FETCH_WEBHOOKS_SUCCESS,
    webhooks,
});

export interface UpdateWebhookActiveState extends BusinessActionRequestType {
    type: WebhooksActionType.UpdateWebhookActiveStateInit;
    webhook: Webhook;
}
export const updateWebhookActiveState = (webhook: Webhook): UpdateWebhookActiveState => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdateWebhookActiveState,
    type: WebhooksActionType.UpdateWebhookActiveStateInit,
    webhook,
});

export interface UpdateWebhook extends BusinessActionRequestType {
    type: WebhooksActionType.UpdateWebhookInit;
    webhook: Webhook;
}
export const updateWebhook = (webhook: Webhook): UpdateWebhook => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdateWebhook,
    type: WebhooksActionType.UpdateWebhookInit,
    webhook,
});

export const UPDATE_WEBHOOK_SUCCESS = 'UPDATE_WEBHOOK_SUCCESS';
export interface UpdateWebhookSuccess {
    type: typeof UPDATE_WEBHOOK_SUCCESS;
    webhook: Webhook;
}
export const updateWebhookSuccess = (webhook: Webhook): UpdateWebhookSuccess => ({
    type: UPDATE_WEBHOOK_SUCCESS,
    webhook,
});

export interface AddWebhook extends BusinessActionRequestType {
    type: WebhooksActionType.AddWebhookInit;
    webhook: NewWebhook;
}
export const addWebhook = (webhook: NewWebhook): AddWebhook => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.AddWebhooks,
    type: WebhooksActionType.AddWebhookInit,
    webhook,
});

export const ADD_WEBHOOK_SUCCESS = 'ADD_WEBHOOK_SUCCESS';
export interface AddWebhookSuccess {
    type: typeof ADD_WEBHOOK_SUCCESS;
    webhook: Webhook;
}
export const addWebhookSuccess = (webhook: Webhook): AddWebhookSuccess => ({ type: ADD_WEBHOOK_SUCCESS, webhook });

export interface DeleteWebhook extends BusinessActionRequestType {
    type: WebhooksActionType.DeleteWebhookInit;
    webhookId: string;
}
export const deleteWebhook = (webhookId: string): DeleteWebhook => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.DeleteWebhook,
    type: WebhooksActionType.DeleteWebhookInit,
    webhookId,
});

export const DELETE_WEBHOOK_SUCCESS = 'DELETE_WEBHOOK_SUCCESS';

export interface DeleteWebhookSuccess {
    type: typeof DELETE_WEBHOOK_SUCCESS;
    webhookId: string;
}

export const deleteWebhookSuccess = (webhookId: string): DeleteWebhookSuccess => ({
    type: DELETE_WEBHOOK_SUCCESS,
    webhookId,
});

export interface TestWebhook extends BusinessActionRequestType {
    type: WebhooksActionType.TestWebhookInit;
    webhookId: string;
}
export const testWebhook = (webhookId: string): TestWebhook => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.TestWebhook,
    type: WebhooksActionType.TestWebhookInit,
    webhookId,
});

export interface FetchWebhookEvents extends BusinessActionRequestType {
    type: WebhooksActionType.FetchWebhookEventsInit;
    webhookId: string;
}
export const fetchWebhookEvents = (webhookId: string): FetchWebhookEvents => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchWebhookEvents,
    type: WebhooksActionType.FetchWebhookEventsInit,
    webhookId,
});

export const FETCH_WEBHOOK_EVENTS_SUCCESS = 'FETCH_WEBHOOK_EVENTS_SUCCESS';
export interface FetchWebhookEventsSuccess {
    type: typeof FETCH_WEBHOOK_EVENTS_SUCCESS;
    events: WebhookEvent[];
}

export const fetchWebhookEventsSuccess = (events: WebhookEvent[]): FetchWebhookEventsSuccess => ({
    type: FETCH_WEBHOOK_EVENTS_SUCCESS,
    events,
});

export type WebhookAction =
    | FetchWebhooks
    | UpdateWebhook
    | UpdateWebhookActiveState
    | AddWebhook
    | DeleteWebhook
    | TestWebhook
    | FetchWebhookEvents;

export type WebhookReducerActions =
    | FetchWebhookEventsSuccess
    | AddWebhookSuccess
    | FetchWebhooksSuccess
    | DeleteWebhookSuccess
    | UpdateWebhookActiveState
    | UpdateWebhookSuccess
    | FetchWebhooks;
