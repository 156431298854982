import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { fullwidthListElement } from 'commons/src/components/placeholder';
import { GroupType } from 'commons/src/models/commonEnums';
import { BillingType } from '../../../models/commonEnums';
import { Subscription } from '../../../models/subscriptionModels';
import EditPoModal from './EditPoModal';
import SubscriptionCostTable from './SubscriptionCostTable';
import styles from './SubscriptionTableExpandedView.module.scss';

type ParentProps = {
    subscription: Subscription;
    childUserGroupId?: string;
    groupType: GroupType;
};

export type Props = ParentProps;

const SubscriptionBillingType = ({
    durationInYears,
    billingType,
}: {
    durationInYears: number;
    billingType: BillingType;
}): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className={styles.billingDuration}>
            <MaterialIcon name="receipt" />
            {durationInYears} {txt(durationInYears === 1 ? 'Zuora.Year' : 'Zuora.Years')} -{' '}
            {txt(`Zuora.${billingType.toLowerCase()}Billing`)}
        </div>
    );
};

const SubscriptionTableExpandedView = ({ subscription, childUserGroupId, groupType }: Props): React.ReactElement => {
    const [editPoOpen, setEditPoOpen] = React.useState(false);
    return (
        <ReactPlaceholder ready customPlaceholder={fullwidthListElement}>
            <div className={styles.viewWrapper}>
                <SubscriptionBillingType
                    billingType={subscription.billingType}
                    durationInYears={subscription.durationInYears}
                />
                <div className={styles.billingDuration}>
                    {subscription.poNumber && (
                        <>
                            <MaterialIcon name="key" /> {subscription.poNumber}
                        </>
                    )}
                    <TertiaryButton
                        title="Subscription.UpdatePoRef"
                        onClick={(): void => setEditPoOpen(true)}
                        size="small"
                    />
                </div>
                <EditPoModal
                    isOpen={editPoOpen}
                    poNumber={subscription.poNumber}
                    onClose={(): void => setEditPoOpen(false)}
                    subscriptionNumber={subscription.subscriptionNumber}
                    groupType={groupType}
                    customerUserGroupId={childUserGroupId}
                />
                <SubscriptionCostTable subscription={subscription} noDataText="Subscription.NoSeatsInSubscription" />
            </div>
        </ReactPlaceholder>
    );
};

export default SubscriptionTableExpandedView;
