/* eslint max-len: ["error", 1000] */
import React from 'react';

const ForecastSnow = (): React.ReactElement => (
    <svg
        version="1.1"
        className="weather-icon"
        width="30px"
        height="30px"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
    >
        <path
            d="M27.2,20.2l-2.3-1.3l1.8-1.1c0.6-0.4,0.8-1.2,0.5-1.8s-1.2-0.8-1.8-0.5l-3.1,1.8l-1.9-1.1V13l2-1.1l3.1,1.8
            c0.6,0.4,1.4,0.1,1.8-0.5s0.1-1.4-0.5-1.8L25,10.3L27.3,9c0.6-0.2,0.8-1,0.4-1.6s-1.2-0.8-1.8-0.5l-2.3,1.3V6.1
            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v3.6l-2,1.2l-2.7-1.5V6.9l3.1-1.8c0.6-0.4,0.8-1.2,0.5-1.8c-0.4-0.6-1.2-0.8-1.8-0.5
            l-1.8,1.1V1.3C16.4,0.6,15.8,0,15.1,0s-1.3,0.6-1.3,1.3v2.6L12,2.8c-0.6-0.4-1.4-0.1-1.8,0.5C9.8,4,10,4.8,10.7,5.1l3.1,1.8v2.4
            l-2.7,1.6l-2-1.2V6.1c0-0.7-0.6-1.3-1.3-1.3S6.5,5.4,6.5,6.1v2.1L4.3,6.9C3.6,6.5,2.8,6.8,2.5,7.4C2.1,8,2.3,8.8,3,9.2l2.3,1.3
            l-1.8,1.1C2.9,12,2.7,12.8,3,13.4s1.2,0.8,1.8,0.5l3-1.9l2,1.1v3.1l-2.1,1.2l-3.1-1.8c-0.6-0.4-1.4-0.1-1.8,0.5
            c-0.4,0.6-0.1,1.4,0.5,1.8L5.1,19l-2.3,1.3c-0.6,0.4-0.8,1.2-0.5,1.8c0.4,0.6,1.2,0.8,1.8,0.5l2.3-1.3v2.1c0,0.7,0.6,1.3,1.3,1.3
            c0.8-0.1,1.4-0.7,1.4-1.4v-3.6l2-1.2l2.6,1.5v2.5l-3.1,1.8c-0.6,0.3-0.9,1.1-0.5,1.7s1.2,0.8,1.8,0.5l1.8-1.1V28
            c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-2.6l1.8,1.1c0.6,0.4,1.4,0.1,1.8-0.5s0.1-1.4-0.5-1.8l-3.1-1.8V20l2.7-1.6l2,1.1v3.6
            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3V21l2.3,1.3c0.6,0.4,1.4,0.1,1.8-0.5C28,21.4,27.8,20.6,27.2,20.2z M18.1,16.4l-3,1.7
            l-3-1.7V13l3-1.7l3,1.7V16.4z"
        />
    </svg>
);
export default ForecastSnow;
