import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createPropertyDefinition } from 'commons/src/actions/SettingsActions';
import { analyticsLogger } from 'commons/src/analytics';
import { SPACE_CREATED_CUSTOM_PROPERTY } from 'commons/src/analytics/AnalyticsEvents';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import Input from 'commons/src/components/input/Input';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { PropertyType } from 'commons/src/models/commonEnums';
import { Store } from '../../../reducers';
import styles from './SpaceAddCustomProperty.module.scss';

type NewCustomPropertyProps = {
    exists: (newLabel: string | null) => boolean;
};

const SpaceAddCustomProperty = ({ exists }: NewCustomPropertyProps): React.ReactElement => {
    const dispatch = useDispatch();
    const { t: txt } = useTranslation();
    const [showForm, setShowForm] = useState(false);
    const [propertyName, setPropertyName] = useState('');
    const [displayValidation, setDisplayValidation] = useState(false);
    const [isPropertyCreating, setIsPropertyCreating] = useState(false);

    const request = useSelector((state: Store) => state.commonRequests.CREATE_PROPERTY_DEFINITION);

    const isValid = propertyName.length > 1 && !exists(propertyName);

    const close = (): void => {
        setShowForm(false);
        setPropertyName('');
        setDisplayValidation(false);
    };

    const createProperty = (): void => {
        setDisplayValidation(true);
        if (isValid) {
            setIsPropertyCreating(true);
            dispatch(createPropertyDefinition(propertyName, PropertyType.Selection));
            analyticsLogger(SPACE_CREATED_CUSTOM_PROPERTY, {
                propertyName,
                propertyType: PropertyType.Selection,
            });
        }
    };

    useEffect(() => {
        if (isPropertyCreating && !request.loading) {
            setIsPropertyCreating(false);
            setShowForm(false);
        }
    }, [request]);

    if (!showForm)
        return (
            <TertiaryButton
                onClick={(): void => setShowForm(true)}
                size="medium"
                icon={<MaterialIcon name="add" />}
                title="CustomProperty.AddProperty"
            />
        );

    return (
        <div className={styles.newPropertyWrapper}>
            <div className={styles.input}>
                <Input
                    type="text"
                    id="CustomProperty.PropertyName"
                    label="CustomProperty.PropertyName"
                    isValid={isValid}
                    validate={displayValidation && !isValid}
                    maxLength={30}
                    autoComplete="off"
                    onChange={(e): void => setPropertyName(e.currentTarget.value)}
                    currentValue={propertyName}
                    testId="add-space-name"
                    placeholder={txt('CustomProperty.PropertyNameExample')}
                    disabled={isPropertyCreating}
                />
            </div>
            <TertiaryButton
                size="medium"
                icon={<MaterialIcon name="check" />}
                title=""
                onClick={createProperty}
                disabled={isPropertyCreating}
            />
            <TertiaryButton
                size="medium"
                noColor
                icon={<MaterialIcon name="close" />}
                title=""
                onClick={close}
                disabled={isPropertyCreating}
            />
        </div>
    );
};

export default SpaceAddCustomProperty;
