import {
    AllDevicesResponse,
    DeviceConfigResponse,
    DeviceConfigUpdate,
    DeviceMetaData,
    DeviceTileContent,
    DeviceType,
    HubData,
    SensorValueTileContent,
} from '../models/commonTypeScript';
import fetch, { fetchFromOldDashboardApi } from './fetch';
import getFetchHeaders from './getFetchHeaders';

export const deleteDevice = async (serialNumber: string): Promise<void> =>
    fetch<void>(`/devices/${serialNumber}`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });

// TODO USES OLD DASHBOARD-API
export const editVirtualSegment = async (
    serialNumber: string,
    name: string,
    metaData?: DeviceMetaData
): Promise<void> =>
    fetchFromOldDashboardApi<void>(`/devices/${serialNumber}/segments/latest`, {
        method: 'PATCH',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ name, metaData }),
    });

export const verifySerialNumber = async (serialNumber: string, checkCode?: string): Promise<void> =>
    fetch<void>('/devices/validate', {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ serialNumber, checkCode }),
    });

export const getDeviceConfig = async (serialNumber: string, segmentId: string): Promise<DeviceConfigResponse> =>
    fetch<DeviceConfigResponse>(`/devices/${serialNumber}/segments/${segmentId}/configuration`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const updateDeviceConfig = async (
    config: DeviceConfigUpdate,
    serialNumber: string,
    segmentId: string
): Promise<void> =>
    fetch<void>(`/devices/${serialNumber}/segments/${segmentId}/configuration`, {
        method: 'PATCH',
        headers: await getFetchHeaders(),
        body: JSON.stringify(config),
    });

export const fetchAllDevices = async (locationId?: string): Promise<AllDevicesResponse> => {
    return fetch<AllDevicesResponse>(locationId ? `/locations/${locationId}/devices` : '/devices', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
};

export const fetchDeviceCurrentValues = async (
    serialNumber: string,
    sensor?: string
): Promise<DeviceTileContent | SensorValueTileContent> => {
    const sensorParam = sensor ? `sensor-value-tile?sensorType=${sensor}` : 'device-tile';
    return fetch<DeviceTileContent | SensorValueTileContent>(`/devices/${serialNumber}/${sensorParam}`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
};

export const getSegmentDetails = async (serialNumber: string, segmentId: string): Promise<DeviceType> =>
    fetch<DeviceType>(`/devices/${serialNumber}/segments/${segmentId}/device/`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const getHubData = async (serialNumber: string): Promise<HubData> =>
    fetch<HubData>(`/hubs/${serialNumber}`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
