import dotProp from 'dot-prop-immutable';
import {
    CHANGE_LOCATION,
    CHANGE_LOCATION_FAIL,
    CHANGE_LOCATION_SUCCESS,
    CLEAR_DEVICE_DATA,
    DeviceActionType,
    DeviceReducerActions,
    EDIT_DEVICE_SAVE,
    FETCH_CUSTOM_DEVICE_SEGMENT,
    FETCH_CUSTOM_DEVICE_SEGMENT_ERROR,
    FETCH_CUSTOM_DEVICE_SEGMENT_SUCCESS,
    POLL_DEVICE_DATA,
    STOP_POLL_DEVICE_DATA,
} from '../actions/DeviceActions';
import { LOGOUT_SUCCESS, LogOutSuccess } from '../actions/LoginAndRegisterActions';
import { ErrorType, FullDeviceData } from '../models/commonTypeScript';

export type DevicePageState = {
    devices: {
        [serialNumber: string]: FullDeviceData;
    };
    loading: boolean;
    fetching: boolean;
    serialNumber?: string;
    errorCode?: number;
    error?: ErrorType;
    updateSegmentDetailsError?: string;
};

const initialState: DevicePageState = {
    devices: {},
    loading: true,
    fetching: false,
    serialNumber: undefined,
    errorCode: undefined,
    error: undefined,
    updateSegmentDetailsError: undefined,
};

export default (state = initialState, action: DeviceReducerActions | LogOutSuccess): DevicePageState => {
    switch (action.type) {
        case FETCH_CUSTOM_DEVICE_SEGMENT:
            return {
                ...state,
                loading: false,
                fetching: true,
                serialNumber: action.serialNumber,
                errorCode: undefined,
                error: undefined,
            };
        case POLL_DEVICE_DATA:
            return {
                ...state,
                loading: action.payload.loading || state.loading,
                fetching: action.payload.fetching || state.fetching,
                serialNumber: action.payload.serialNumber,
                errorCode: undefined,
                error: undefined,
            };
        case FETCH_CUSTOM_DEVICE_SEGMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                fetching: false,
                serialNumber: undefined,
                devices: dotProp.merge(
                    state.devices,
                    `${action.serialNumber}.fetchedIntervals`,
                    action.segment.fetchedIntervals
                ),
                errorCode: undefined,
                error: undefined,
            };
        case DeviceActionType.FetchDeviceSegmentSuccess:
            return {
                ...state,
                loading: false,
                fetching: false,
                serialNumber: undefined,
                devices: dotProp.set(state.devices, action.serialNumber, action.segment.deviceData),
                errorCode: undefined,
                error: undefined,
            };
        case DeviceActionType.FetchDeviceSegmentError:
        case FETCH_CUSTOM_DEVICE_SEGMENT_ERROR:
            return {
                ...state,
                loading: false,
                fetching: false,
                serialNumber: undefined,
                errorCode: action.error && action.error.error_code,
                error: action.error,
            };
        case DeviceActionType.DeleteDeviceSuccess:
            return {
                ...state,
                devices: dotProp.delete(state.devices, action.serialNumber),
            };
        case EDIT_DEVICE_SAVE:
        case CHANGE_LOCATION:
            return {
                ...state,
                loading: true,
                updateSegmentDetailsError: undefined,
            };
        case CHANGE_LOCATION_SUCCESS: {
            const { serialNumber, locationName, name, locationId } = action.payload;
            return {
                ...state,
                devices: state.devices[serialNumber]
                    ? dotProp.merge(state.devices, serialNumber, {
                          locationName,
                          locationId: locationId || state.devices[serialNumber].locationId,
                          segmentName: name,
                      })
                    : state.devices,
                loading: false,
                updateSegmentDetailsError: undefined,
            };
        }
        case CHANGE_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                updateSegmentDetailsError: action.error.error,
            };
        case DeviceActionType.RenameSegmentSuccess: {
            return {
                ...state,
                devices:
                    action.segmentId === 'latest' && state.devices[action.serialNumber]
                        ? dotProp.merge(state.devices, action.serialNumber, { segmentName: action.name })
                        : state.devices,
            };
        }
        case STOP_POLL_DEVICE_DATA:
            return {
                ...state,
                loading: false,
                fetching: false,
            };
        case CLEAR_DEVICE_DATA:
        case LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
};
