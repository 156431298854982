import delay from '@redux-saga/delay-p';
import moment, { DurationInputArg1, DurationInputArg2, Moment } from 'moment';
import {
    takeEvery,
    put,
    call,
    race,
    take,
    select,
    CallEffect,
    SelectEffect,
    PutEffect,
    AllEffect,
    RaceEffect,
    TakeEffect,
} from 'redux-saga/effects';
import {
    STOP_POLL_DASHBOARD_SENSOR_DATA,
    fetchSensorSegmentSuccess,
    fetchSensorSegmentError,
    POLL_DASHBOARD_SENSOR_DATA,
    PollDashboardSensorData,
} from '../../actions/DashboardActions';
import { fetchDeviceSegmentError } from '../../actions/DeviceActions';
import { AddLocationSuccess } from '../../actions/LocationActions';
import fetchSegment from '../../api/segment';
import { drawGraphTime, pollingDelayMs } from '../../constants';
import {
    DashboardSensorData,
    DeviceResponse,
    DashboardWithChartData,
    SelectedPeriod,
    FullDeviceData,
    DeviceDataType,
    DeviceType,
    SensorResponse,
} from '../../models/commonTypeScript';
import { dashboardSensorData, isLoggedIn, pollingTiles } from '../../reducers/reducerShortcuts';
import { generateAverageValue } from '../DashboardSagas/fetchDashboardSensorTile';
import { toErrorType } from '../isErrorType';
import zipChartData from './zipChartData';

const updateDashboardData = (
    response: DeviceResponse,
    ref: string,
    sensorType: string,
    serialNumber: string,
    selectedInterval: SelectedPeriod,
    prevDashboard: DashboardSensorData,
    nowTime: Moment = moment.utc()
): DashboardWithChartData => {
    const tileDataToUpdate = prevDashboard.sensorData[ref][selectedInterval.name];
    const newSensorData = response.sensors.find(sensor => sensor.type === sensorType);
    const newDataOffset = response.offsets;

    const initialMinValue = tileDataToUpdate ? prevDashboard.minValues[ref][selectedInterval.name] : undefined;
    const segmentStartTime = moment.utc(response.segmentStart).valueOf();

    const { newChartData, minValue } = zipChartData(
        newDataOffset,
        newSensorData as SensorResponse,
        segmentStartTime,
        initialMinValue
    );

    let chartData = newChartData;

    if (tileDataToUpdate.length > 0) {
        const lastTimestampOfCurrentData =
            tileDataToUpdate.length > 0 ? tileDataToUpdate[tileDataToUpdate.length - 1][0] : 0;
        const indexOfNewData =
            newChartData.length > 0
                ? newChartData.findIndex(dataPoint => dataPoint[0] > lastTimestampOfCurrentData)
                : -1;
        chartData =
            indexOfNewData >= 0 ? [...tileDataToUpdate, ...newChartData.slice(indexOfNewData)] : tileDataToUpdate;
    }

    const beginningOfDataPeriod = nowTime
        .clone()
        .subtract(selectedInterval.number as DurationInputArg1, selectedInterval.period as DurationInputArg2)
        .valueOf();
    const indexOfFirstSampleInSelectedPeriod = chartData.findIndex(dataPoint => dataPoint[0] >= beginningOfDataPeriod);
    const sensorData = chartData.slice(
        indexOfFirstSampleInSelectedPeriod > 0 ? indexOfFirstSampleInSelectedPeriod - 1 : 0
    );
    const averageValue = sensorData.length > 0 ? generateAverageValue(sensorData) : undefined;

    return {
        chartData: { [selectedInterval.name]: sensorData },
        minValues: { [selectedInterval.name]: minValue },
        averageValues: { [selectedInterval.name]: averageValue },
    };
};

export const setFetchInterval = (
    sensorType: string,
    serialNumber: string,
    prevDashboard: DashboardSensorData,
    selectedInterval: SelectedPeriod,
    isoTime: Moment = moment()
): { from: string; to: string; lastDataOlderThanResolutionDuration: boolean } => {
    const prevSensorPeriod = prevDashboard.sensorData[`${serialNumber}-${sensorType}`][selectedInterval.name];
    const lastSegmentTime =
        prevSensorPeriod && prevSensorPeriod.length > 0 ? prevSensorPeriod[prevSensorPeriod.length - 1][0] : 0;

    const lastSegmentTimeFormatted = moment.utc(lastSegmentTime).toISOString();
    const startOfPeriodISOTime = isoTime
        .clone()
        .subtract(selectedInterval.number as DurationInputArg1, selectedInterval.period as DurationInputArg2)
        .toISOString();
    const startOfPeriod = moment(startOfPeriodISOTime).subtract(selectedInterval.resolutionDuration).toISOString();

    const fromISO = moment(lastSegmentTimeFormatted).isAfter(startOfPeriod) ? lastSegmentTimeFormatted : startOfPeriod;
    const from = fromISO.slice(0, fromISO.lastIndexOf('.'));

    const toISO = isoTime.clone().toISOString();
    const to = toISO.slice(0, toISO.lastIndexOf('.'));

    const fetchIfBeforeTime = selectedInterval.resolution
        ? moment().subtract(selectedInterval.resolutionDuration)
        : moment().subtract(5, 'minutes');

    const lastDataOlderThanResolutionDuration = moment.utc(from).isBefore(fetchIfBeforeTime);
    return { from, to, lastDataOlderThanResolutionDuration };
};

type PollSensorSegmentSagaType = Generator<
    CallEffect | CallEffect<DashboardSensorData> | SelectEffect | PutEffect<AddLocationSuccess> | PutEffect | void,
    void,
    DashboardSensorData &
        DeviceResponse & { device: FullDeviceData } & DeviceDataType &
        string & { from: string; to: string; lastDataOlderThanResolutionDuration: boolean }
>;

export function* pollSensorSegmentSaga({
    payload,
}:
    | PollDashboardSensorData
    | {
          payload: {
              serialNumber: string;
              selectedInterval: SelectedPeriod;
              sensorType: string;
              device: DeviceType;
              ref: string;
              tileId: string;
          };
      }): PollSensorSegmentSagaType {
    const { serialNumber, selectedInterval, sensorType, ref, tileId } = payload;

    let loggedIn = yield select(isLoggedIn);
    let isInIntervalsToFetch = true;
    while (loggedIn && isInIntervalsToFetch) {
        try {
            yield call(delay, pollingDelayMs);
            const prevDashboard = yield select(dashboardSensorData);
            const fetchAttrs = yield call(setFetchInterval, sensorType, serialNumber, prevDashboard, selectedInterval);
            const response = yield call(fetchSegment, serialNumber, {
                from: fetchAttrs.from,
                to: fetchAttrs.to,
                resolution: selectedInterval.resolution,
                sensors: sensorType,
            });
            yield put(
                fetchSensorSegmentSuccess({
                    sensorData: updateDashboardData(
                        response,
                        ref,
                        sensorType,
                        serialNumber,
                        selectedInterval,
                        prevDashboard
                    ),
                    ref,
                })
            );
        } catch (error) {
            const errorAsErrorType = toErrorType(error);
            yield put(fetchSensorSegmentError(errorAsErrorType));
        } finally {
            const intervalsToFetch = yield select(pollingTiles);
            isInIntervalsToFetch = intervalsToFetch[tileId] && intervalsToFetch[tileId].name === selectedInterval.name;
            loggedIn = yield select(isLoggedIn);
        }
    }
}

type FetchDashboardDataSagaType = Generator<
    | CallEffect
    | AllEffect<PutEffect>
    | CallEffect<DashboardSensorData>
    | SelectEffect
    | RaceEffect<CallEffect<PollSensorSegmentSagaType> | TakeEffect>
    | PutEffect<AddLocationSuccess>
    | PutEffect
    | void,
    void,
    DashboardSensorData &
        DeviceResponse &
        DeviceDataType & { from: string; to: string; lastDataOlderThanResolutionDuration: boolean }
>;

export function* fetchDashboardDataSaga({ payload }: PollDashboardSensorData): FetchDashboardDataSagaType {
    const { serialNumber, selectedInterval, sensorType, ref } = payload;

    try {
        const prevDashboard = yield select(dashboardSensorData);
        const fetchAttrs = yield call(setFetchInterval, sensorType, serialNumber, prevDashboard, selectedInterval);
        const loggedIn = yield select(isLoggedIn);

        if (loggedIn && fetchAttrs.lastDataOlderThanResolutionDuration) {
            yield call(delay, drawGraphTime);
            const response = yield call(fetchSegment, serialNumber, {
                from: fetchAttrs.from,
                to: fetchAttrs.to,
                resolution: selectedInterval.resolution,
                sensors: sensorType,
            });
            yield put(
                fetchSensorSegmentSuccess({
                    sensorData: updateDashboardData(
                        response,
                        ref,
                        sensorType,
                        serialNumber,
                        selectedInterval,
                        prevDashboard
                    ),
                    ref,
                })
            );
        }
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(fetchDeviceSegmentError(serialNumber, errorAsErrorType));
    } finally {
        yield race({
            task: call(pollSensorSegmentSaga, { payload }),
            cancel: take(STOP_POLL_DASHBOARD_SENSOR_DATA),
        });
    }
}

export default function* PollDashboardSensorDataSaga(): Generator {
    yield takeEvery(POLL_DASHBOARD_SENSOR_DATA, fetchDashboardDataSaga);
}
