import React from 'react';
import { TableHead, TableSortLabel, TableCell, TableRow } from '@mui/material';
import classNames from 'classnames';
import InputInfo from 'commons/src/components/input/InputInfo';
import ColumnFilter from './ColumnFilter';
import styles from './DesktopMaterialTable.module.scss';
import useMaterialTableContext from './hooks/useMaterialTableContext';

type MaterialTableHeadersProps = {
    className?: string;
};

const MaterialTableHeaders = ({ className }: MaterialTableHeadersProps): React.ReactElement => {
    const { columns, getSelectedColumn, onHeaderClick, isReady, allowColumnManagement } = useMaterialTableContext();
    return (
        <TableHead
            className={classNames(styles.tableHeader, styles.scrollBody, className)}
            data-testid="materialTableHeader"
        >
            <TableRow className={styles.tableRow}>
                {columns.map(({ accessor, align, sortable, label, infoBox, width, locked }) => {
                    const order = sortable ? getSelectedColumn(accessor) : false;
                    return (
                        <TableCell
                            key={accessor}
                            align={align}
                            sortDirection={order}
                            variant="head"
                            width={width}
                            data-sticky={locked}
                            className={classNames(styles.tableCell, { [styles.sticky]: locked })}
                            data-testid={`materialTable-cell-${accessor}`}
                        >
                            <div className={styles.cellBody}>
                                <TableSortLabel
                                    active={Boolean(order)}
                                    direction={order || undefined}
                                    onClick={onHeaderClick({ accessor, sortable })}
                                    className={styles.header}
                                    disabled={!sortable || !isReady}
                                    hideSortIcon={Boolean(infoBox)}
                                    data-testid="materialTable-sort-button"
                                >
                                    {label}
                                </TableSortLabel>
                                {infoBox ? <InputInfo infoText={infoBox} infoDirectionDown /> : null}
                            </div>
                        </TableCell>
                    );
                })}
            </TableRow>
            {allowColumnManagement && (
                <tr>
                    <td>
                        <ColumnFilter />
                    </td>
                </tr>
            )}
        </TableHead>
    );
};

export default MaterialTableHeaders;
