import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getDotColor } from '../../commonFunctions';
import { SensorComponent } from '../../components/sensors/SensorComponent';
import SensorLabel from '../../components/sensors/SensorLabel';
import SensorValue from '../../components/sensors/SensorValue';
import { languages, mapMoldLinkToLanguage, sensorGraphHeights } from '../../constants';
import { SensorTypes } from '../../models/commonEnums';
import {
    AggregateTypeValues,
    AverageSensorPeriodValues,
    ExtraSeriesValues,
    MinSensorPeriodValues,
    SelectedPeriod,
    SensorData,
    VirtualDeviceData,
} from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import { CommonRequestType } from '../../reducers/requestReducer';
import { chartOnMouseOver } from './SensorConfig';
import SensorGraph from './SensorGraph';
import UnitAndEdgeValues from './UnitAndEdgeValues';

export type PassedProps = {
    deviceId: string;
    sensor: { type: SensorTypes; unit: string; thresholds: Array<number> };
    isExpanded: boolean;
    isMobile: boolean;
    selectedInterval: SelectedPeriod;
    dateFormat: string;
    displayStatusBar: boolean;
    displayResetZoom: () => void;
    timeZone?: string;
};

type StateProps = {
    language: string;
    sensorData?: { [serialNumber: string]: SensorData };
    virtualSensorData?: { [serialNumber: string]: SensorData };
    endedSegmentData?: { [serialNumber: string]: SensorData };
    minValues?: MinSensorPeriodValues;
    averageValues?: AverageSensorPeriodValues;
    virtualMinValues?: MinSensorPeriodValues;
    virtualAverageValues?: AverageSensorPeriodValues;
    aggregateTypes?: AggregateTypeValues;
    extraSeries?: ExtraSeriesValues;
    extraVirtualSeries?: ExtraSeriesValues;
    endedMinValues?: MinSensorPeriodValues;
    endedAverageValues?: AverageSensorPeriodValues;
    loadingVirtualSensorData: boolean;
    virtualDeviceData: { [serialNumber: string]: VirtualDeviceData };
};

export type Props = PassedProps & StateProps;

export const SensorTileComponent = (props: Props): React.ReactElement => {
    const {
        sensorData = {},
        virtualSensorData = {},
        endedSegmentData = {},
        sensor,
        isExpanded,
        isMobile,
        selectedInterval,
        minValues = {},
        virtualMinValues = {},
        endedMinValues = {},
        averageValues = {},
        virtualAverageValues = {},
        endedAverageValues = {},
        displayStatusBar,
        dateFormat,
        displayResetZoom,
        language,
        deviceId,
        extraSeries = {},
        extraVirtualSeries = {},
        aggregateTypes = {},
        loadingVirtualSensorData,
        virtualDeviceData,
        timeZone,
    } = props;

    const { type, unit } = sensor;
    const endedData = endedSegmentData[deviceId];
    const currentData = { ...sensorData[deviceId], ...virtualSensorData[deviceId] };
    const chartData = (endedData || currentData)[type];

    const currentExtraSeries = { ...extraSeries[deviceId], ...extraVirtualSeries[deviceId] };
    const extraSeriesForDevice = currentExtraSeries[type];
    const virtualSensorUnitsForDevice = virtualDeviceData[deviceId]?.sensors;
    const extraSeriesUnitsForSensor = virtualSensorUnitsForDevice
        ? virtualSensorUnitsForDevice.find(sensorInfo => sensorInfo.type === type)
        : null;
    const deviceDataAverageValues = averageValues[deviceId];
    const deviceAverageValues = deviceDataAverageValues
        ? { ...deviceDataAverageValues, ...virtualAverageValues[deviceId] }
        : undefined;
    const endedAverages = endedAverageValues[deviceId];
    const averageSensorValues = (deviceAverageValues || endedAverages)[type];

    const deviceDataMinValues = minValues[deviceId];
    const deviceMinValues = deviceDataMinValues ? { ...deviceDataMinValues, ...virtualMinValues[deviceId] } : undefined;
    const endedMin = endedMinValues[deviceId];
    const minSensorValues = (deviceMinValues || endedMin)[type];
    const { t: txt } = useTranslation();

    let chartHeight = isExpanded ? sensorGraphHeights.deviceViewExpanded : sensorGraphHeights.deviceViewMinimized;
    chartHeight = isMobile ? sensorGraphHeights.mobileView : chartHeight;

    const showDecimalsForUnit = ['pci', 'inhg'];
    let averageValue = selectedInterval && averageSensorValues && averageSensorValues[selectedInterval.name];
    averageValue =
        averageValue &&
        (showDecimalsForUnit.includes(sensor.unit) ? averageValue : parseInt(averageValue.toFixed(0), 10));
    const averageDotColor =
        sensor.thresholds.length > 0 ? getDotColor(sensor.thresholds, sensor.type, averageValue) : '';

    const moldRiskLink = (): React.ReactElement => {
        const moldLink = mapMoldLinkToLanguage[language];
        return (
            <a href={moldLink} target="_blank" rel="noopener noreferrer" className="sensor-row__current-value__link">
                {txt('WhatIsmold')}
            </a>
        );
    };

    const isMoldRisk = type === SensorTypes.mold;
    const isHourlyRadonStD = type === SensorTypes.hourlyRadonStandardDeviation;
    const sensorHasValue = averageValue !== undefined && averageValue !== null && !Number.isNaN(averageValue);
    const extraSeriesForInterval = (!!extraSeriesForDevice && extraSeriesForDevice[selectedInterval.name]) || {};
    const showExtraSeriesName =
        Object.keys(extraSeriesForInterval).length > 0 && Object.values(extraSeriesForInterval)[0].length > 0;
    const showEdgeValues =
        isExpanded &&
        sensorHasValue &&
        sensor.type !== SensorTypes.presence &&
        sensor.type !== SensorTypes.airExchangeRate;
    const customSensorName = (): string | undefined => {
        switch (sensor.type) {
            case SensorTypes.presence:
                return 'SensorName.PresenceAndOccupancy';
            default:
                return undefined;
        }
    };

    return (
        <>
            {isHourlyRadonStD && <div className="single-device__header">BETA features:</div>}
            <div
                className={classNames('sensor-row', {
                    'sensor-row--expanded': isExpanded,
                    'sensor-row--mobile': isMobile,
                })}
            >
                {isMobile ? (
                    <div className="sensor-row__current-value-mobile">
                        <SensorLabel sensorType={type} textUppercase />
                        {sensor.type !== SensorTypes.presence && sensor.type !== SensorTypes.airExchangeRate && (
                            <SensorValue
                                sensorValue={averageValue}
                                sensorUnit={unit}
                                title="Average"
                                dotColor={averageDotColor}
                            />
                        )}
                    </div>
                ) : (
                    <div className="sensor-row__current-value">
                        <SensorComponent
                            dotColor={averageDotColor}
                            value={averageValue}
                            sensorType={type}
                            unit={unit}
                            displayStatusBar={displayStatusBar}
                            customSensorName={customSensorName()}
                        />
                        {isMoldRisk && moldRiskLink()}
                        {showEdgeValues && (
                            <UnitAndEdgeValues
                                chartData={chartData}
                                selectedInterval={selectedInterval}
                                sensor={sensor}
                            />
                        )}
                    </div>
                )}
                <div
                    className={isMobile ? 'sensor-row__historical-mobile' : 'sensor-row__historical'}
                    onMouseMove={(e): void => chartOnMouseOver(e.nativeEvent, false)}
                    onMouseLeave={(e): void => chartOnMouseOver(e.nativeEvent, true)}
                >
                    <SensorGraph
                        timeZone={timeZone}
                        chartData={chartData}
                        extraSeries={extraSeriesForDevice}
                        aggregateTypes={aggregateTypes[deviceId]}
                        selectedInterval={selectedInterval}
                        thresholds={sensor.thresholds}
                        chartHeight={chartHeight}
                        sensorType={sensor.type}
                        unit={sensor.unit}
                        minValues={minSensorValues}
                        averageValues={averageSensorValues}
                        dateFormat={dateFormat}
                        displayResetZoom={displayResetZoom}
                        loadingVirtualSensor={loadingVirtualSensorData}
                        extraSeriesUnitsForSensor={extraSeriesUnitsForSensor?.series}
                        showExtraSeriesName={showExtraSeriesName}
                    />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { language },
        deviceSensorData: {
            sensorData,
            virtualSensorData,
            virtualMinValues,
            virtualAverageValues,
            minValues,
            averageValues,
            extraSeries,
            aggregateTypes,
            extraVirtualSeries,
            virtualDeviceData,
        },
        endedSegmentData: {
            sensorData: endedSegmentData,
            averageValues: endedAverageValues,
            minValues: endedMinValues,
        },
        commonRequests: {
            [CommonRequestType.FetchCustomSegmentVirtualSensors]: { loading: loadingCustomVirtualSensors },
            [CommonRequestType.PollVirtualDeviceSensorData]: { loading: loadingVirtualSensorData },
        },
    } = state;

    return {
        language: Object.prototype.hasOwnProperty.call(languages, language) ? language : 'en',
        sensorData,
        endedSegmentData,
        minValues,
        averageValues,
        endedAverageValues,
        endedMinValues,
        extraSeries,
        aggregateTypes,
        virtualSensorData,
        virtualMinValues,
        virtualAverageValues,
        extraVirtualSeries,
        loadingVirtualSensorData: loadingCustomVirtualSensors || loadingVirtualSensorData,
        virtualDeviceData,
    };
};

export default connect(mapStateToProps)(SensorTileComponent);
