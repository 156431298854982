import moment from 'moment-timezone';
import { BuildingType } from 'commons/src/models/commonTypeScript';

/**
 * List of timezones that are deprecated as of Java 8
 * ref: https://docs.oracle.com/javase/8/docs/api/java/time/ZoneId.html#SHORT_IDS
 */
const invalidTimeZones: string[] = [
    'ACT',
    'AET',
    'AGT',
    'ART',
    'AST',
    'BET',
    'BST',
    'CAT',
    'CNT',
    'CST',
    'CTT',
    'EAT',
    'ECT',
    'IET',
    'IST',
    'JST',
    'MIT',
    'NET',
    'NST',
    'PLT',
    'PNT',
    'PRT',
    'PST',
    'SST',
    'VST',
    'EST',
    'MST',
    'HST',
];

const timezoneOptions = (): { id: string; inputValue: string }[] => {
    return moment.tz
        .names()
        .reduce((memo: { name: string; offset: number }[], tz) => {
            if (!invalidTimeZones.includes(tz)) {
                memo.push({
                    name: tz,
                    offset: moment.tz(tz).utcOffset(),
                });
            }
            return memo;
        }, [])
        .sort((a, b) => a.offset - b.offset)
        .reduce((memo: { id: string; inputValue: string }[], tz) => {
            const timezone = tz.offset ? moment.tz(tz.name).format('Z') : '';
            memo.push({ id: tz.name, inputValue: `(GMT${timezone}) ${tz.name}` });
            return memo;
        }, []);
};

export const findTimeZoneValues = (location?: BuildingType): { id: string; inputValue: string } => {
    const zoneName = location && location.timezone ? location.timezone : moment.tz.guess();
    const offset = moment.tz(zoneName).utcOffset();
    const timezone = offset ? moment.tz(zoneName).format('Z') : '';
    return { id: zoneName, inputValue: `(GMT${timezone}) ${zoneName}` };
};

export default timezoneOptions;
