import React from 'react';

const IconVentilationRate = (): React.ReactElement => (
    <svg
        id="iconVentilationRate"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        width="24px"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
    >
        <path
            fillRule="evenodd"
            d="M15.8334 8.57134C16.6469 7.66215 17.143 6.4605 17.143 5.14286C17.143 2.30254 14.8404 0 12.0001 0C11.432 0 10.9715 0.460507 10.9715 1.02857V6.96001C10.0611 7.14482 9.23798 7.57013 8.57142 8.16674C7.66224 7.35322 6.46047 6.85714 5.1429 6.85714C2.30255 6.85714 0 9.15968 0 12C0 12.5681 0.460511 13.0286 1.02858 13.0286H6.96007C7.14488 13.939 7.5702 14.7621 8.16682 15.4287C7.35324 16.3379 6.85719 17.5395 6.85719 18.8571C6.85719 21.6975 9.15975 24 12.0001 24C12.5682 24 13.0287 23.5395 13.0287 22.9714V17.04C13.9391 16.8552 14.7621 16.4299 15.4287 15.8333C16.3379 16.6469 17.5395 17.1429 18.8571 17.1429C21.6974 17.1429 24 14.8403 24 12C24 11.4319 23.5395 10.9714 22.9714 10.9714H17.0406C16.8558 10.061 16.43 9.2379 15.8334 8.57134ZM11.9972 8.91428C11.9982 8.91429 11.9991 8.91429 12.0001 8.91429C13.6986 8.91428 15.0766 10.2866 15.0858 11.983C15.0857 11.9886 15.0856 11.9943 15.0856 12C15.0856 12.0057 15.0857 12.0114 15.0858 12.017C15.0766 13.7134 13.6986 15.0857 12.0001 15.0857C10.2959 15.0857 8.91435 13.7042 8.91435 12C8.91435 10.2968 10.2943 8.91583 11.9972 8.91428ZM13.0287 6.96001C13.4281 7.04109 13.8107 7.16845 14.1706 7.33626C14.7368 6.7761 15.0858 6.00016 15.0858 5.14286C15.0858 3.79932 14.2272 2.65633 13.0287 2.23273V6.96001ZM17.0401 13.0286C16.959 13.428 16.8316 13.8107 16.6638 14.1706C17.2239 14.7367 17.9999 15.0857 18.8571 15.0857C20.2007 15.0857 21.3436 14.2271 21.7673 13.0286H17.0401ZM10.9715 17.04C10.5721 16.9589 10.1895 16.8315 9.82956 16.6637C9.26337 17.2239 8.91435 17.9999 8.91435 18.8571C8.91435 20.2007 9.77302 21.3437 10.9715 21.7673V17.04ZM6.96007 10.9714C7.04115 10.572 7.16851 10.1894 7.33631 9.8295C6.7761 9.26324 6.00028 8.91428 5.1429 8.91428C3.79935 8.91428 2.65635 9.77294 2.23274 10.9714H6.96007Z"
        />
        <path d="M10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12Z" />
    </svg>
);

export default IconVentilationRate;
