import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { dateFormats, sensorUnits } from 'commons/src/constants';
import { SegmentProperties, BuildingType, DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { RadonInsightSensorData } from '../../../models/common';
import { Store } from '../../../reducers';
import GeneratePdf from '../insightPdfs/radonInsightPdf/GenerateReportComponent';
import BuildingInfo from '../reportSelector/BuildingInfo';
import ThresholdLevelsView from '../ThresholdLevelsView';

type ParentProps = {
    building: BuildingType;
    devicesInEachLevel: { good: number; fair: number; poor: number };
    listOfImages: { data: string; serialNumber: string }[];
    fromDate: string | null;
    toDate: string | null;
    generateChartError: boolean;
    optionalBuildingProps: string[];
};

type StateProps = {
    dateFormat: keyof typeof dateFormats;
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    numberOfDevicesMissingData: number;
    reports: RadonInsightSensorData[];
    reportId: string;
    unit: keyof typeof sensorUnits;
    thresholds: number[];
    segmentProperties: { [serialNumber: string]: SegmentProperties };
    userName: string;
    logoImage?: string;
    includeLogo: boolean;
};

type Props = ParentProps & StateProps;

export const BuildingResultSummaryComponent = (props: Props): React.ReactElement => {
    const {
        thresholds,
        dateFormat,
        reports,
        numberOfDevicesMissingData,
        unit,
        building,
        devicesWithKeyInfo,
        devicesInEachLevel,
        reportId,
        segmentProperties,
        userName,
        fromDate,
        toDate,
        listOfImages,
        logoImage,
        includeLogo,
        generateChartError,
        optionalBuildingProps,
    } = props;
    const { t: txt } = useTranslation();

    const allDevicesGood = devicesInEachLevel.poor + devicesInEachLevel.fair === 0;
    const numberOfDevicesWithHighestAlert =
        devicesInEachLevel.poor > 0 ? devicesInEachLevel.poor : devicesInEachLevel.fair;
    const warningTextKey =
        numberOfDevicesWithHighestAlert === 1
            ? 'RadonInsight.SingleDeviceAtWarningLevel'
            : 'RadonInsight.DevicesAtWarningLevel';
    const alertTextKey =
        numberOfDevicesWithHighestAlert === 1
            ? 'RadonInsight.SingleDeviceAtHighRisk'
            : 'RadonInsight.DevicesAtHighRisk';
    const numberOfDevicesWithAlertTextKey = devicesInEachLevel.poor > 0 ? alertTextKey : warningTextKey;
    const sortedReports = reports.sort((reportA, reportB) =>
        devicesWithKeyInfo[reportA.serialNumber].segmentName.localeCompare(
            devicesWithKeyInfo[reportB.serialNumber].segmentName
        )
    );

    return (
        <div id="buildingResultSummary" className="form__wide-container insight-tile insight-tile--full-width">
            <div className="insight-tile__header insight-tile__header--no-border">
                <h3 className="insight-tile__header__name">{building.name}</h3>
                {sortedReports.length > 0 &&
                    !generateChartError &&
                    building &&
                    unit &&
                    listOfImages.length === sortedReports.length &&
                    fromDate &&
                    toDate && (
                        <GeneratePdf
                            fromDate={fromDate}
                            devicesInEachLevel={devicesInEachLevel}
                            toDate={toDate}
                            dateFormat={dateFormat}
                            building={building}
                            devices={devicesWithKeyInfo}
                            chartImages={listOfImages}
                            segmentProperties={segmentProperties}
                            userName={userName}
                            thresholds={thresholds}
                            unit={unit}
                            reports={sortedReports}
                            reportId={reportId}
                            logo={includeLogo ? logoImage : undefined}
                        />
                    )}
            </div>
            <div className="inline-header-lined inline-header-lined--no-margin-top" />
            <BuildingInfo building={building} validate={false} optionalBuildingProps={optionalBuildingProps} />
            <div className="inline-header-lined inline-header-lined--no-margin-top" />
            <div className="insight-tile__content insight-tile__content--wrap">
                <div className="insight-tile__content__building-summary">
                    <div className="insight-tile__content__text">
                        <div
                            className={classNames('sensor__line', {
                                'sensor__line--ok': devicesInEachLevel.poor === 0 && devicesInEachLevel.fair === 0,
                                'sensor__line--alert': devicesInEachLevel.poor > 0,
                                'sensor__line--warning': devicesInEachLevel.poor === 0 && devicesInEachLevel.fair > 0,
                            })}
                        />
                        <span className="text-large text-bold">
                            {allDevicesGood
                                ? txt('RadonInsight.AllDevicesWithinRecommendedLevel')
                                : txt(numberOfDevicesWithAlertTextKey, {
                                      numberOfDevices: numberOfDevicesWithHighestAlert.toString(),
                                  })}
                        </span>
                        <p className="insight-tile__content__text--large">
                            {txt('RadonInsight.LongTermVsOpeningHoursHeader')}
                        </p>
                        <p>{txt('RadonInsight.LongTermVsOpeningHoursDescription')}</p>
                    </div>
                </div>
                <ThresholdLevelsView thresholds={thresholds} unit={unit} devicesInEachLevel={devicesInEachLevel} />
                {numberOfDevicesMissingData > 0 && (
                    <div className="device-insight__error-response device-insight__error-response--slim text-small">
                        <MaterialIcon extraClass="icon-color--red" name="error_outline" />
                        {txt('RadonInsight.DevicesMissingDataForReport', {
                            numberOfDevices: numberOfDevicesMissingData.toString(),
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat, userName },
        devices: { devicesWithKeyInfo },
        segmentPropertiesStore: { segmentProperties },
        organizationProperties: { logoImage },
        radonInsight: { thresholds, reports, errors, reportId, includeLogo, unit },
    } = state;
    return {
        dateFormat,
        devicesWithKeyInfo,
        numberOfDevicesMissingData: errors.length,
        reports,
        reportId,
        unit,
        thresholds,
        segmentProperties,
        userName,
        logoImage,
        includeLogo,
    };
};

export default connect(mapStateToProps)(BuildingResultSummaryComponent);
