import React, { useEffect } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { sensorUnits, colors, dateFormats } from 'commons/src/constants';
import IconNoData from 'commons/src/img/icon-no-data';
import { Rating, SensorTypes } from 'commons/src/models/commonEnums';
import { ThresholdRange, DayUsageHours } from 'commons/src/models/commonTypeScript';

import { Store } from '../../../reducers';
import { addNullPointsToChartData, generatePlotBands, highChartsTimeZoneOffset } from '../insightFunctions';
import GraphConfig, { SensorGraphConfig } from './IaqGraphConfig';

type ParentProps = {
    chartData: number[][];
    unit: keyof typeof sensorUnits;
    ranges: ThresholdRange[];
    usageHours: { [day: string]: DayUsageHours };
    sensor: string;
    from: Moment | null;
    to: Moment | null;
    graphId: string;
    timeZone: string;
};

type StateProps = {
    dateFormat: keyof typeof dateFormats;
};

type Props = StateProps & ParentProps;

export const IaqInsightGraphComponent = (props: Props): React.ReactElement => {
    const { chartData, ranges, unit, timeZone, dateFormat, usageHours, sensor, from, to, graphId } = props;
    const { t: txt } = useTranslation();
    const setFromObjectWithStrings = (obj: { [prop: string]: string }, key: string): string => obj[key];
    const setThresholds = (): ({ color: string; value: number } | { color: string })[] => {
        if (ranges.length === 0) {
            return [{ color: colors.graphGreen }];
        }
        return ranges.map(range => {
            let color = colors.graphGreen;
            if (range.rating === Rating.FAIR) color = colors.graphYellow;
            else if (range.rating === Rating.POOR) {
                if (sensor === SensorTypes.temp && range.from === null) {
                    color = colors.graphBlue;
                } else color = colors.graphRed;
            }
            return range.to ? { value: range.to, color } : { color };
        }, []);
    };

    const sensorMinValue = (): number => {
        let minValue: number = chartData.length > 0 ? chartData[0][1] : 0;
        chartData.forEach(dataPoint => {
            const value = dataPoint[1];
            if (value < minValue) minValue = value;
        });
        return minValue;
    };

    useEffect((): void => {
        highChartsTimeZoneOffset(timeZone);
    }, []);

    const createConfig = (): SensorGraphConfig => {
        const chartHeight = 240;
        const generatedThresholds = setThresholds();
        const minValue = sensorMinValue();
        const unitInUse = setFromObjectWithStrings(sensorUnits, unit as string);
        return GraphConfig(
            addNullPointsToChartData(chartData, from, to),
            generatedThresholds,
            unitInUse,
            chartHeight,
            minValue,
            dateFormat,
            generatePlotBands(usageHours, from, timeZone),
            sensor,
            graphId
        );
    };

    const sensorHasData = chartData.length > 0;
    const config = createConfig();
    return sensorHasData ? (
        <HighchartsReact highcharts={Highcharts} options={config} />
    ) : (
        <div className="centered">
            <div className="centered__content">
                {IconNoData}
                <div>{txt('NotEnoughData')}</div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = state;
    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(IaqInsightGraphComponent);
