import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import InactiveSubscriptionPageBlur from '../../features/inactiveSubscription/InactiveSubscriptionPageBlur';
import { FeatureToggleEnum, GroupType, Role } from '../../models/commonEnums';
import { TabOption } from '../../models/menuModels';
import { Store } from '../../reducers';
import MaterialIcon from '../MaterialIcon';
import filterMenuElements from '../menu/FilterMenuElements';
import HeaderTabNavigation from './HeaderTabNavigation';

type StateProps = {
    userRole?: Role;
    featureToggles?: FeatureToggleEnum[];
    groupType?: GroupType;
};

type PassedProps = {
    title: string;
    tabs?: TabOption[];
    customHeader?: React.ReactElement;
    subHeader?: string;
    subHeaderClick?: () => void;
};

export type Props = PassedProps & StateProps;

export const PageHeaderComponent = ({
    title,
    customHeader,
    userRole,
    subHeader,
    tabs,
    subHeaderClick,
    featureToggles,
    groupType,
}: Props): React.ReactElement => {
    const allowedTabsForDisplay = tabs && filterMenuElements(tabs, groupType, userRole, undefined, featureToggles);
    const displayTabs = userRole && allowedTabsForDisplay && allowedTabsForDisplay.length > 0;
    return (
        <div id="pageHeader" className="page-header--padded">
            <div className="page-header">
                <div className="page-header__margin">
                    <div
                        className={classNames('single-device__header', {
                            'single-device__header--subtext': !!subHeader,
                        })}
                    >
                        <div className="single-device__header--full-width">
                            <div className="page-header__name-padded">{customHeader || <h1>{title}</h1>}</div>
                            {subHeader && !subHeaderClick && <div>{subHeader}</div>}
                            {subHeader && subHeaderClick && (
                                <button
                                    type="button"
                                    className="page-header__back-link page-header__back-link--config-view"
                                    onClick={subHeaderClick}
                                >
                                    <MaterialIcon name="chevron_left" />
                                    {subHeader}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {displayTabs && <HeaderTabNavigation tabs={allowedTabsForDisplay} />}
            <InactiveSubscriptionPageBlur displayTabs={!!displayTabs} />
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { selectedGroup, featureToggles },
    } = store;
    return {
        userRole: selectedGroup && selectedGroup.role,
        groupType: selectedGroup?.groupType,
        featureToggles,
    };
};

export default connect(mapStateToProps)(PageHeaderComponent);
