import React from 'react';
import classNames from 'classnames';
import styles from './MaterialIcon.module.scss';

type Props = {
    name: string;
    fill?: boolean;
    extraClass?: string;
    id?: string;
};

/*
    Icon names can be found here:
    https://fonts.google.com/icons?icon.query=info
*/
const MaterialIcon = ({ name, id, extraClass, fill = false }: Props): React.ReactElement => (
    <i id={id} className={classNames('material-symbols-outlined', { [styles.filled]: fill }, extraClass)}>
        {name}
    </i>
);
export default MaterialIcon;
