import React from 'react';
import Slider from '@mui/material/Slider';
import { sensorUnits } from 'commons/src/constants';

export interface Config {
    recommended: { min: number; max: number };
    range: { min: number; max: number; step: number };
    unit: keyof typeof sensorUnits;
    descriptionKey: string;
}

export type Props = {
    config: Config;
    onChange: (thresholdValue: number | number[]) => void;
    value: number[];
};

const getRecommendationMark = ({ value }: { value: number }): { label: React.ReactElement; value: number } => ({
    value,
    label: (
        <span>
            <br />
            {value}
        </span>
    ),
});

const ThresholdSlider = ({ value, onChange, config }: Props): React.ReactElement => {
    const updateThresholds = (e: Event, thresholdValue: number | number[]): void => {
        onChange(thresholdValue);
    };
    return (
        <Slider
            className="input__slider__line"
            size="small"
            track="normal"
            value={value}
            defaultValue={[config.recommended.min, config.recommended.max]}
            valueLabelDisplay="on"
            onChange={updateThresholds}
            classes={{ valueLabel: 'input__slider__value-label' }}
            marks={[
                getRecommendationMark({ value: config.recommended.min }),
                getRecommendationMark({ value: config.recommended.max }),
            ]}
            {...config.range}
        />
    );
};

export default ThresholdSlider;
