import React from 'react';
import { TextRow, RectShape } from 'react-placeholder/lib/placeholders';
import { colors } from '../../constants';
import IconNoData from '../../img/icon-no-data';
import CircledSensorUnit from '../sensors/CircledSensorUnit';

export const tile = (
    <div className="page-wrapper--column">
        <div className="tile-wrapper placeholder">
            <div className="tile-header">
                <TextRow color={colors.greyAsh} className="placeholder__tile-body  show-loading-animation" />
            </div>
            <div className="tile-body">
                <TextRow color={colors.greyAsh} className="placeholder__tile-body  show-loading-animation" />
                <TextRow color={colors.greyAsh} className="placeholder__tile-body  show-loading-animation" />
                <TextRow color={colors.greyAsh} className="placeholder__tile-body  show-loading-animation" />
            </div>
        </div>
    </div>
);

export const slimTile = (
    <div className="page-wrapper--column">
        <div className="building-tile placeholder">
            <div className="placeholder__fullwidth-wrapper">
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
            </div>
        </div>
    </div>
);

export const cardTile = (
    <div className="summaryCardPlaceholder placeholder">
        <div className="placeholder__fullwidth-wrapper">
            <TextRow
                color={colors.greyAsh}
                className="placeholder__til e-body placeholder__tile-body--slim  show-loading-animation"
            />
            <TextRow
                color={colors.greyAsh}
                className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
            />
            <TextRow
                color={colors.greyAsh}
                className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
            />
        </div>
    </div>
);

export const mediumFormLoaderImage = (
    <div className="page-wrapper__medium page-wrapper__medium--white">
        <TextRow color={colors.greyAsh} className="form__header" />
        <div className="page-wrapper__inner page-wrapper__inner--slim">
            <div className="form">
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
            </div>
        </div>
    </div>
);

const insightTileLoader = (
    <div className="insight-tile">
        <h3>
            <TextRow
                color={colors.greyAsh}
                className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
            />
        </h3>
        <div className="insight-tile__content">
            <CircledSensorUnit unit="none" lld={undefined} value={undefined} sensorType="radon" thresholds={[]} />
            <div className="insight-tile--full-width">
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
            </div>
        </div>
    </div>
);

const insightGraphLoader = (
    <div className="form__wide-container insight-tile insight-tile--full-width">
        <h3>
            <TextRow
                color={colors.greyAsh}
                className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
            />
            <TextRow
                color={colors.greyAsh}
                className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
            />
        </h3>
        <div className="insight-tile__content">
            <RectShape color={colors.greyPorcelain} style={{ width: '100%', height: '160px' }} />
        </div>
        <h3>
            <TextRow
                color={colors.greyAsh}
                className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
            />
        </h3>
    </div>
);

export const radonInsightPlaceholder = (
    <>
        {insightTileLoader}
        {insightTileLoader}
        {insightGraphLoader}
    </>
);

export const insightReportPlaceholder = (
    <>
        {insightGraphLoader}
        {insightGraphLoader}
        {insightGraphLoader}
    </>
);

export const graphLoadingPlaceholder = (loadingText: string): React.ReactElement => (
    <div className="centered" style={{ height: '70%' }}>
        <div className="centered__content">
            {IconNoData}
            <div>{loadingText}</div>
        </div>
    </div>
);

export const mediumSectionLoader = (
    <div className="page-wrapper__medium page-wrapper__medium--white">
        <TextRow color="white" className="form__header" />
        <div className="page-wrapper__inner page-wrapper__inner--slim">
            <div className="form">
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
            </div>
        </div>
    </div>
);

export const mediumFormLoader = (
    <div className="page-wrapper__medium page-wrapper__medium--white">
        <TextRow color="white" className="form__header" />
        <div className="page-wrapper__inner page-wrapper__inner--slim">
            <div className="form">
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
            </div>
            <div className="form">
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-body placeholder__tile-body--slim  show-loading-animation"
                />
            </div>
        </div>
    </div>
);

const fullWithElement = (
    <div className="placeholder__tile-fullwidth-wrapper">
        <div className="placeholder__tile-fullwidth-wrapper--element">
            <div className="column column-right">
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-fullwidth-element  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-fullwidth-element  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-fullwidth-element  show-loading-animation"
                />
            </div>
        </div>
    </div>
);

export const fullwidth = (
    <div className="placeholder__tile-fullwidth-wrapper">
        <div className="placeholder__tile-fullwidth-wrapper--element">
            <div className="column column-right">
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-fullwidth-element  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-fullwidth-element  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-fullwidth-element  show-loading-animation"
                />
            </div>
        </div>
        <div className="placeholder__tile-fullwidth-wrapper--element">
            <div className="column column-right">
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-fullwidth-element  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-fullwidth-element  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-fullwidth-element  show-loading-animation"
                />
            </div>
        </div>
    </div>
);

export const fullwidthListElement = (
    <div className="placeholder__tile-fullwidth-wrapper">
        <div className="info-header">
            <div style={{ width: '100%' }}>
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-fullwidth-element  show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-fullwidth-element  show-loading-animation"
                />
            </div>
        </div>
    </div>
);

export const thresholdBreachElement = (
    <div className="placeholder__threshold-breach">
        <TextRow
            color={colors.greyAsh}
            className="placeholder__threshold-breach__header-element  show-loading-animation"
        />
        <div className="building-insight__card__body">
            <div className="placeholder__threshold-breach__section">
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__threshold-breach__section--element show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__threshold-breach__section--element show-loading-animation"
                />
            </div>
            <div className="placeholder__threshold-breach__section placeholder__threshold-breach__section--right">
                <TextRow
                    color={colors.greyConcrete}
                    className="placeholder__threshold-breach__section--element show-loading-animation"
                />
                <TextRow
                    color={colors.greyConcrete}
                    className="placeholder__threshold-breach__section--element show-loading-animation"
                />
            </div>
        </div>
    </div>
);

const elementCard = (
    <div className="placeholder__event-list-element">
        <TextRow
            color={colors.greyAsh}
            className="placeholder__threshold-breach__section--element show-loading-animation"
        />
        <TextRow
            color={colors.greyAsh}
            className="placeholder__threshold-breach__section--element show-loading-animation"
        />
    </div>
);

export const insightSectionPlaceHolder = (
    <div className="placeholder__threshold-breach">
        <div className="building-insight__card__body">
            <div className="placeholder__threshold-breach__section placeholder__threshold-breach__section--wide">
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__threshold-breach__section--element show-loading-animation"
                />
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__threshold-breach__section--element show-loading-animation"
                />
            </div>
            <div className="placeholder__threshold-breach__section placeholder__threshold-breach__section--right placeholder__threshold-breach__section--right--slim">
                <TextRow
                    color={colors.greyConcrete}
                    className="placeholder__threshold-breach__section--element show-loading-animation"
                />
                <TextRow
                    color={colors.greyConcrete}
                    className="placeholder__threshold-breach__section--element show-loading-animation"
                />
            </div>
        </div>
    </div>
);

export const energyInsightPlaceholder = (
    <>
        <div className="placeholder__event-list">
            {elementCard}
            {elementCard}
            {elementCard}
        </div>
        {insightSectionPlaceHolder}
    </>
);

export const list = (
    <div className="container">
        <div className="info-header">
            <div style={{ width: '100%' }}>
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-fullwidth-element  show-loading-animation"
                />
            </div>
            <div style={{ width: '100%' }}>
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-fullwidth-element  show-loading-animation"
                />
            </div>
            <div style={{ width: '100%' }}>
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-fullwidth-element  show-loading-animation"
                />
            </div>
            <div style={{ width: '100%' }}>
                <TextRow
                    color={colors.greyAsh}
                    className="placeholder__tile-fullwidth-element  show-loading-animation"
                />
            </div>
        </div>
    </div>
);

export const userlane = (
    <div className="placeholder__tile-fullwidth-wrapper--element member list__row">
        <div style={{ width: '100%' }}>
            <TextRow color={colors.greyAsh} className="placeholder__tile-fullwidth-element  show-loading-animation" />
            <TextRow color={colors.greyAsh} className="placeholder__tile-fullwidth-element  show-loading-animation" />
        </div>
    </div>
);

export const headerPlaceholder = (
    <div className="page-header page-header__margin page-header--padded">
        <div className="single-device__header">
            <h1 className="page-header__name-padded">
                <TextRow color={colors.greyAsh} className="placeholder__device-header  show-loading-animation" />
            </h1>
        </div>
        <div>
            <div className="page-header__button-container" />
        </div>
    </div>
);

export const squareTilesPlaceholder = (
    <div className="page-wrapper">
        <div>
            {tile}
            {tile}
            {tile}
            {tile}
            {tile}
            {tile}
        </div>
    </div>
);

export const buildingTilesPlaceholder = (
    <div className="page-wrapper">
        {slimTile}
        {slimTile}
        {slimTile}
        {slimTile}
        {slimTile}
        {slimTile}
        {slimTile}
        {slimTile}
        {slimTile}
    </div>
);

export const slimTilesPlaceholder = (
    <div className="page-wrapper">
        <div>
            {slimTile}
            {slimTile}
            {slimTile}
            {slimTile}
            {slimTile}
            {slimTile}
        </div>
    </div>
);
export const sensorPlaceholder = (
    <div>
        {fullwidth}
        {fullwidth}
        {fullwidth}
    </div>
);

export const fullWidthListPlaceholder = (
    <div className="container">
        {fullWithElement}
        {fullWithElement}
        {fullWithElement}
    </div>
);

export const fullListPlaceholder = (
    <div>
        <TextRow color={colors.greyAsh} className="placeholder__tile-fullwidth-element  show-loading-animation" />
        <TextRow color={colors.greyAsh} className="placeholder__tile-fullwidth-element  show-loading-animation" />
        <TextRow color={colors.greyAsh} className="placeholder__tile-fullwidth-element  show-loading-animation" />
        <TextRow color={colors.greyAsh} className="placeholder__tile-fullwidth-element  show-loading-animation" />
        <TextRow color={colors.greyAsh} className="placeholder__tile-fullwidth-element  show-loading-animation" />
        <TextRow color={colors.greyAsh} className="placeholder__tile-fullwidth-element  show-loading-animation" />
    </div>
);
