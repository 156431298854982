import { RequestActionType } from 'commons/src/actions/requestActions';
import {
    ThirdPartyIntegration,
    NewThirdPartyIntegration,
    ThirdPartyIntegrationMapping,
    ThirdPartyAddedIntegration,
    NewThirdPartyMapping,
    EditNotificationAlert,
    NewNotificationAlert,
    ThirdPartyIntegrationAlert,
    NotificationAlertEvent,
    BusinessActionRequestType,
} from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum ThirdPartyIntegrationActionType {
    FetchThirdPartyIntegrationsInit = 'FETCH_THIRD_PARTY_INTEGRATIONS/INIT',
    AddThirdPartyIntegrationInit = 'ADD_THIRD_PARTY_INTEGRATION/INIT',
    DeleteThirdPartyIntegrationInit = 'DELETE_THIRD_PARTY_INTEGRATION',
    FetchThirdPartyIntegrationClientIDInit = 'FETCH_THIRD_PARTY_INTEGRATION_CLIENT_ID/INIT',
    AddThirdPartyAlertInit = 'ADD_THIRD_PARTY_ALERT/INIT',
    UpdateThirdPartyAlertInit = 'UPDATE_THIRD_PARTY_ALERT/INIT',
    FetchThirdPartyIntegrationMappingInit = 'FETCH_THIRD_PARTY_INTEGRATION_MAPPING/INIT',
    RegisterThirdPartyIntegrationMappingInit = 'REGISTER_THIRD_PARTY_INTEGRATION_MAPPING/INIT',
    UpdateThirdPartyIntegrationMappingInit = 'UPDATE_THIRD_PARTY_INTEGRATION_MAPPING/INIT',
    UpdateNotificationAlertActiveStateInit = 'UPDATE_ALERT_ACTIVE_STATE/INIT',
    DeleteNotificationAlertInit = 'DELETE_NOTIFICATION_ALERT/INIT',
    FetchAlertEventsInit = 'FETCH_ALERT_EVENTS/INIT',
    AddEmailTriggerInit = 'ADD_EMAIL_TRIGGER/INIT',
    UpdateEmailTriggerInit = 'UPDATE_EMAIL_TRIGGER/INIT',
}

export interface FetchThirdPartyIntegrations extends BusinessActionRequestType {
    type: ThirdPartyIntegrationActionType.FetchThirdPartyIntegrationsInit;
}
export const fetchThirdPartyIntegrations = (): FetchThirdPartyIntegrations => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchThirdPartyIntegrations,
    type: ThirdPartyIntegrationActionType.FetchThirdPartyIntegrationsInit,
});

export const FETCH_THIRD_PARTY_INTEGRATIONS_SUCCESS = 'FETCH_THIRD_PARTY_INTEGRATIONS_SUCCESS';
export interface FetchThirdPartyIntegrationsSuccess {
    type: typeof FETCH_THIRD_PARTY_INTEGRATIONS_SUCCESS;
    thirdPartyIntegrations: ThirdPartyIntegration[];
    notificationAlerts: ThirdPartyIntegrationAlert[];
}
export const fetchThirdPartyIntegrationsSuccess = (thirdPartyIntegrations: {
    integrations: ThirdPartyIntegration[];
    notificationAlerts: ThirdPartyIntegrationAlert[];
}): FetchThirdPartyIntegrationsSuccess => ({
    type: FETCH_THIRD_PARTY_INTEGRATIONS_SUCCESS,
    thirdPartyIntegrations: thirdPartyIntegrations.integrations,
    notificationAlerts: thirdPartyIntegrations.notificationAlerts,
});

export interface AddThirdPartyIntegration extends BusinessActionRequestType {
    type: ThirdPartyIntegrationActionType.AddThirdPartyIntegrationInit;
    integration: NewThirdPartyIntegration;
}
export const addThirdPartyIntegration = (integration: NewThirdPartyIntegration): AddThirdPartyIntegration => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.AddThirdPartyIntegration,
    type: ThirdPartyIntegrationActionType.AddThirdPartyIntegrationInit,
    integration,
});

export const ADD_THIRD_PARTY_INTEGRATION_SUCCESS = 'ADD_THIRD_PARTY_INTEGRATION_SUCCESS';
export interface AddThirdPartyIntegrationSuccess {
    type: typeof ADD_THIRD_PARTY_INTEGRATION_SUCCESS;
    integration: ThirdPartyAddedIntegration;
}
export const addThirdPartyIntegrationSuccess = (
    integration: ThirdPartyAddedIntegration
): AddThirdPartyIntegrationSuccess => ({
    type: ADD_THIRD_PARTY_INTEGRATION_SUCCESS,
    integration,
});

export interface DeleteThirdPartyIntegration extends BusinessActionRequestType {
    type: ThirdPartyIntegrationActionType.DeleteThirdPartyIntegrationInit;
    integrationId: string;
}
export const deleteThirdPartyIntegration = (integrationId: string): DeleteThirdPartyIntegration => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.DeleteThirdPartyIntegration,
    type: ThirdPartyIntegrationActionType.DeleteThirdPartyIntegrationInit,
    integrationId,
});

export const DELETE_THIRD_PARTY_INTEGRATION_SUCCESS = 'DELETE_THIRD_PARTY_INTEGRATION_SUCCESS';
export interface DeleteThirdPartyIntegrationSuccess {
    type: typeof DELETE_THIRD_PARTY_INTEGRATION_SUCCESS;
    integrationId: string;
}

export const deleteThirdPartyIntegrationSuccess = (integrationId: string): DeleteThirdPartyIntegrationSuccess => ({
    type: DELETE_THIRD_PARTY_INTEGRATION_SUCCESS,
    integrationId,
});

export interface FetchThirdPartyIntegrationClientID extends BusinessActionRequestType {
    type: ThirdPartyIntegrationActionType.FetchThirdPartyIntegrationClientIDInit;
    integration: NewThirdPartyIntegration;
}
export const fetchThirdPartyIntegrationClientID = (
    integration: NewThirdPartyIntegration
): FetchThirdPartyIntegrationClientID => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchThirdPartyIntegrationClientID,
    type: ThirdPartyIntegrationActionType.FetchThirdPartyIntegrationClientIDInit,
    integration,
});

// Mapping
export interface FetchThirdPartyIntegrationMapping extends BusinessActionRequestType {
    type: ThirdPartyIntegrationActionType.FetchThirdPartyIntegrationMappingInit;
    integrationId: string;
}
export const fetchThirdPartyIntegrationMapping = (integrationId: string): FetchThirdPartyIntegrationMapping => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchThirdPartyIntegrationMapping,
    type: ThirdPartyIntegrationActionType.FetchThirdPartyIntegrationMappingInit,
    integrationId,
});

export const FETCH_THIRD_PARTY_INTEGRATION_MAPPING_SUCCESS = 'FETCH_THIRD_PARTY_INTEGRATION_MAPPING_SUCCESS';
export interface FetchThirdPartyIntegrationMappingSuccess {
    type: typeof FETCH_THIRD_PARTY_INTEGRATION_MAPPING_SUCCESS;
    thirdPartyMapping: ThirdPartyIntegrationMapping;
}

export const fetchThirdPartyIntegrationMappingSuccess = (
    thirdPartyMapping: ThirdPartyIntegrationMapping
): FetchThirdPartyIntegrationMappingSuccess => ({
    type: FETCH_THIRD_PARTY_INTEGRATION_MAPPING_SUCCESS,
    thirdPartyMapping,
});

export interface UpdateThirdPartyIntegrationMapping extends BusinessActionRequestType {
    type: ThirdPartyIntegrationActionType.UpdateThirdPartyIntegrationMappingInit;
    integrationId: string;
    thirdPartyMapping: NewThirdPartyMapping[];
}
export const updateThirdPartyIntegrationMapping = (
    integrationId: string,
    thirdPartyMapping: NewThirdPartyMapping[]
): UpdateThirdPartyIntegrationMapping => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdateThirdPartyIntegrationMapping,
    type: ThirdPartyIntegrationActionType.UpdateThirdPartyIntegrationMappingInit,
    integrationId,
    thirdPartyMapping,
});

export const UPDATE_THIRD_PARTY_INTEGRATION_MAPPING_SUCCESS = 'UPDATE_THIRD_PARTY_INTEGRATION_MAPPING_SUCCESS';
export interface UpdateThirdPartyIntegrationMappingSuccess {
    type: typeof UPDATE_THIRD_PARTY_INTEGRATION_MAPPING_SUCCESS;
}

export const updateThirdPartyIntegrationMappingSuccess = (): UpdateThirdPartyIntegrationMappingSuccess => ({
    type: UPDATE_THIRD_PARTY_INTEGRATION_MAPPING_SUCCESS,
});

export interface RegisterThirdPartyIntegrationMapping extends BusinessActionRequestType {
    type: ThirdPartyIntegrationActionType.RegisterThirdPartyIntegrationMappingInit;
    integrationId: string;
    thirdPartyMapping: NewThirdPartyMapping[];
}
export const registerThirdPartyIntegrationMapping = (
    integrationId: string,
    thirdPartyMapping: NewThirdPartyMapping[]
): RegisterThirdPartyIntegrationMapping => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.RegisterThirdPartyIntegrationMapping,
    type: ThirdPartyIntegrationActionType.RegisterThirdPartyIntegrationMappingInit,
    integrationId,
    thirdPartyMapping,
});

export const REGISTER_THIRD_PARTY_INTEGRATION_MAPPING_SUCCESS = 'REGISTER_THIRD_PARTY_INTEGRATION_MAPPING_SUCCESS';
export interface RegisterThirdPartyIntegrationMappingSuccess {
    type: typeof REGISTER_THIRD_PARTY_INTEGRATION_MAPPING_SUCCESS;
}

export const registerThirdPartyIntegrationMappingSuccess = (): RegisterThirdPartyIntegrationMappingSuccess => ({
    type: REGISTER_THIRD_PARTY_INTEGRATION_MAPPING_SUCCESS,
});

// Alerts
export interface AddNotificationAlert extends BusinessActionRequestType {
    type: ThirdPartyIntegrationActionType.AddThirdPartyAlertInit;
    integrationId: string;
    integrationType: string;
    notificationAlert: NewNotificationAlert;
}
export const addNotificationAlert = (
    integrationId: string,
    integrationType: string,
    notificationAlert: NewNotificationAlert
): AddNotificationAlert => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.AddThirdPartyAlert,
    type: ThirdPartyIntegrationActionType.AddThirdPartyAlertInit,
    notificationAlert,
    integrationId,
    integrationType,
});

export const ADD_NOTIFICATION_ALERT_SUCCESS = 'ADD_NOTIFICATION_ALERT_SUCCESS';
export interface AddNotificationAlertSuccess {
    type: typeof ADD_NOTIFICATION_ALERT_SUCCESS;
    notificationAlert: NewNotificationAlert;
    integrationId: string;
    integrationType: string;
    triggerId: string;
}

export const addNotificationAlertSuccess = (
    notificationAlert: NewNotificationAlert,
    integrationId: string,
    integrationType: string,
    triggerId: string
): AddNotificationAlertSuccess => ({
    type: ADD_NOTIFICATION_ALERT_SUCCESS,
    notificationAlert,
    integrationId,
    integrationType,
    triggerId,
});

export interface UpdateNotificationAlert extends BusinessActionRequestType {
    type: ThirdPartyIntegrationActionType.UpdateThirdPartyAlertInit;
    notificationAlert: EditNotificationAlert;
}
export const updateNotificationAlert = (notificationAlert: EditNotificationAlert): UpdateNotificationAlert => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdateThirdPartyAlert,
    type: ThirdPartyIntegrationActionType.UpdateThirdPartyAlertInit,
    notificationAlert,
});

export const UPDATE_THIRD_PARTY_ALERT_SUCCESS = 'UPDATE_THIRD_PARTY_ALERT_SUCCESS';
export interface UpdateNotificationAlertSuccess {
    type: typeof UPDATE_THIRD_PARTY_ALERT_SUCCESS;
    notificationAlert: EditNotificationAlert;
}

export const updateNotificationAlertSuccess = (
    notificationAlert: EditNotificationAlert
): UpdateNotificationAlertSuccess => ({ type: 'UPDATE_THIRD_PARTY_ALERT_SUCCESS', notificationAlert });

export interface UpdateAlertActiveState extends BusinessActionRequestType {
    type: ThirdPartyIntegrationActionType.UpdateNotificationAlertActiveStateInit;
    notificationAlert: EditNotificationAlert;
}
export const updateNotificationAlertActiveState = (
    notificationAlert: EditNotificationAlert
): UpdateAlertActiveState => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdateAlertActiveState,
    type: ThirdPartyIntegrationActionType.UpdateNotificationAlertActiveStateInit,
    notificationAlert,
});

export interface DeleteNotificationAlert extends BusinessActionRequestType {
    type: ThirdPartyIntegrationActionType.DeleteNotificationAlertInit;
    integrationId: string;
    triggerId: string;
    integrationType: string;
}
export const deleteNotificationAlert = (
    integrationId: string,
    triggerId: string,
    integrationType: string
): DeleteNotificationAlert => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.DeleteNotificationAlert,
    type: ThirdPartyIntegrationActionType.DeleteNotificationAlertInit,
    integrationId,
    triggerId,
    integrationType,
});

export const DELETE_NOTIFICATION_ALERT_SUCCESS = 'DELETE_NOTIFICATION_ALERT_SUCCESS';
export interface DeleteNotificationAlertSuccess {
    type: typeof DELETE_NOTIFICATION_ALERT_SUCCESS;
    integrationId: string;
    triggerId: string;
}
export const deleteNotificationAlertSuccess = (
    integrationId: string,
    triggerId: string
): DeleteNotificationAlertSuccess => ({ type: DELETE_NOTIFICATION_ALERT_SUCCESS, integrationId, triggerId });

export interface FetchAlertEvents extends BusinessActionRequestType {
    type: ThirdPartyIntegrationActionType.FetchAlertEventsInit;
}
export const fetchAlertEvents = (): FetchAlertEvents => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchAlertEvents,
    type: ThirdPartyIntegrationActionType.FetchAlertEventsInit,
});

export const FETCH_ALERT_EVENTS_SUCCESS = 'FETCH_ALERT_EVENTS_SUCCESS';
export interface FetchAlertEventsSuccess {
    type: typeof FETCH_ALERT_EVENTS_SUCCESS;
    alertEvents: NotificationAlertEvent[];
}

export const fetchAlertEventsSuccess = (alertEvents: NotificationAlertEvent[]): FetchAlertEventsSuccess => ({
    type: FETCH_ALERT_EVENTS_SUCCESS,
    alertEvents,
});

export interface AddEmailTrigger extends BusinessActionRequestType {
    type: ThirdPartyIntegrationActionType.AddEmailTriggerInit;
    integration: NewThirdPartyIntegration;
    trigger: NewNotificationAlert;
}
export const addEmailTrigger = (
    integration: NewThirdPartyIntegration,
    trigger: NewNotificationAlert
): AddEmailTrigger => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.AddEmailTrigger,
    type: ThirdPartyIntegrationActionType.AddEmailTriggerInit,
    integration,
    trigger,
});

export interface UpdateEmailTrigger extends BusinessActionRequestType {
    type: ThirdPartyIntegrationActionType.UpdateEmailTriggerInit;
    integration: NewThirdPartyIntegration;
    trigger: EditNotificationAlert;
}
export const updateEmailTrigger = (
    integration: NewThirdPartyIntegration,
    trigger: EditNotificationAlert
): UpdateEmailTrigger => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdateEmailTrigger,
    type: ThirdPartyIntegrationActionType.UpdateEmailTriggerInit,
    integration,
    trigger,
});

export type ThirdPartyIntegrationAction =
    | FetchThirdPartyIntegrations
    | AddThirdPartyIntegration
    | FetchThirdPartyIntegrationClientID
    | FetchThirdPartyIntegrationMapping
    | DeleteThirdPartyIntegration
    | UpdateThirdPartyIntegrationMapping
    | AddNotificationAlert
    | UpdateNotificationAlert
    | UpdateAlertActiveState
    | RegisterThirdPartyIntegrationMapping
    | DeleteNotificationAlert
    | FetchAlertEvents
    | AddEmailTrigger
    | UpdateEmailTrigger;

export type ThirdPartyIntegrationReducerActions =
    | RegisterThirdPartyIntegrationMappingSuccess
    | FetchAlertEventsSuccess
    | AddThirdPartyIntegrationSuccess
    | AddNotificationAlertSuccess
    | FetchThirdPartyIntegrations
    | FetchThirdPartyIntegrationsSuccess
    | FetchThirdPartyIntegrationMappingSuccess
    | UpdateNotificationAlertSuccess
    | UpdateAlertActiveState;
