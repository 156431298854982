import { DownloadCSVActionType, DownloadCSVReducerActions } from '../actions/DownloadCSVActions';

export type CSVDownloadDetailsType = { filename: string; url: string };

export type CSVDownloadState = {
    error: boolean;
    loading: boolean;
    CSVDownloadDetails: CSVDownloadDetailsType[];
};

const initialState: CSVDownloadState = {
    error: false,
    loading: false,
    CSVDownloadDetails: [],
};

export default (state: CSVDownloadState = initialState, action: DownloadCSVReducerActions): CSVDownloadState => {
    switch (action.type) {
        case DownloadCSVActionType.DownloadCSVInit:
            return {
                ...state,
                loading: true,
                error: false,
                CSVDownloadDetails: [],
            };
        case DownloadCSVActionType.DownloadCSVSuccess:
            return {
                ...state,
                error: false,
                loading: false,
                CSVDownloadDetails: action.CSVDownloadDetails,
            };
        case DownloadCSVActionType.DownloadCSVError:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case DownloadCSVActionType.CloseCSVModalInit:
            return {
                ...state,
                error: false,
                loading: false,
            };
        default:
            return state;
    }
};
