import React from 'react';
import { Document, Font, PDFDownloadLink, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { BUILDING_THRESHOLD_BREACH_DOWNLOADED_REPORT } from 'commons/src/analytics/AnalyticsEvents';
import DownloadPDFView from 'commons/src/components/PDF/DownloadPdfView';
import {
    Body,
    colorStyles,
    HeaderBlock,
    Logo,
    LogoContainer,
    Footer,
    SmallLogo,
} from 'commons/src/components/PDF/PdfConstants';
import { dateFormats } from 'commons/src/constants';
import DemiBold from 'commons/src/fonts/open-sans-v28-latin-600.ttf';
import RegularFont from 'commons/src/fonts/open-sans-v28-latin-regular.ttf';
import { BuildingType } from 'commons/src/models/commonTypeScript';
import BusinessLogo from '../../../../img/logos/business-logo.png';
import { BuildingInsightChartData } from '../../../../models/buildingModels';
import { PDFContext } from '../../../../models/common';
import BuildingDetails from '../../../reports/insightPdfs/BuildingDetails';
import ThresholdBreachDeviceBreakDownPdf from './ThresholdBreachDeviceBreakDownPdf';

Font.register({ family: 'DemiBold', src: DemiBold });
Font.register({ family: 'Regular', src: RegularFont });

const styles = StyleSheet.create({
    headerText: {
        fontSize: 25,
        fontFamily: 'DemiBold',
        maxWidth: '60vw',
    },
    subHeader: {
        fontSize: 13,
        paddingTop: 20,
    },
    smallText: {
        fontSize: 12,
    },
    graphHeader: {
        paddingTop: 10,
        paddingBottom: 10,
        fontFamily: 'DemiBold',
        fontSize: 12,
    },
    bold: {
        fontFamily: 'DemiBold',
    },
    pageNumbers: {
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    graph: {
        width: '90vw',
        marginBottom: '1vw',
    },
});

interface ReportProps {
    dateFormat: keyof typeof dateFormats;
    sensor: string;
    withinOpeningHours: boolean;
    thresholdsText: string;
    numberOfDevicesInBuilding: number;
    numberOfDevicesWithDataOutsideForTimeFrame: number;
    reportGeneratedForPeriodText: string;
    building: BuildingType;
    trendOverTimeGraph?: string;
    totalByDeviceGraph?: string;
    logoImage?: string;
    breakDownGraphs: { data: string; serialNumber: string }[];
    totalDeviceData: {
        devicesOverTime: { [serialNumber: string]: BuildingInsightChartData[] };
        devicesInTimeFrame: {
            serialNumber: string;
            name: string;
            overThresholdsTime: number;
            underThresholdsTime: number;
        }[];
    };
}
export interface GenerateReportProps extends ReportProps {
    fileDownloadName: string;
}
interface PdfProperties extends ReportProps {
    txt: PDFContext;
}

// Create Document Component
const PdfDocument = React.memo(
    ({
        txt,
        dateFormat,
        sensor,
        reportGeneratedForPeriodText,
        withinOpeningHours,
        thresholdsText,
        numberOfDevicesWithDataOutsideForTimeFrame,
        numberOfDevicesInBuilding,
        trendOverTimeGraph,
        totalByDeviceGraph,
        breakDownGraphs,
        building,
        logoImage,
        totalDeviceData,
    }: PdfProperties) => {
        const sensorReplacedSubText = txt(sensor).replace('CO₂', 'CO2');
        const subHeaderText = `${thresholdsText} - ${numberOfDevicesWithDataOutsideForTimeFrame}/${numberOfDevicesInBuilding} ${txt(
            'DevicesLowerCase'
        )}`;
        const totalByDeviceChartHeight = numberOfDevicesWithDataOutsideForTimeFrame * 20 + 20;
        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Document>
                <Body>
                    <HeaderBlock>
                        <View>
                            <Text style={styles.headerText}>
                                {sensorReplacedSubText} {txt('BuildingInsight.TimeOverThreshold').toLowerCase()}
                            </Text>
                            <Text style={styles.subHeader}>{subHeaderText}</Text>
                            <Text style={styles.smallText}>{reportGeneratedForPeriodText}</Text>
                            <Text style={styles.smallText}>
                                {txt(
                                    withinOpeningHours
                                        ? 'BuildingInsight.ReportWithinOpeningHours'
                                        : 'BuildingInsight.ReportNoOpeningHours'
                                )}
                            </Text>
                        </View>
                        <LogoContainer>
                            <Logo src={logoImage || BusinessLogo} />
                        </LogoContainer>
                    </HeaderBlock>
                    <BuildingDetails txt={txt} building={building} dateFormat={dateFormat} />
                    <Text style={styles.subHeader}>{txt('Overview')}</Text>
                    <View wrap={false}>
                        <Text style={styles.graphHeader}>{txt('BuildingInsight.TrendOverTime')}</Text>
                        <Image style={styles.graph} src={trendOverTimeGraph} />
                    </View>
                    <View wrap={false}>
                        <Text style={styles.graphHeader}>{txt('BuildingInsight.TotalByDevice')}</Text>
                        <Image src={totalByDeviceGraph} style={[styles.graph, { height: totalByDeviceChartHeight }]} />
                    </View>
                    <Text style={styles.subHeader}>{txt('BuildingInsight.BreakdownPerDevice')}</Text>
                    <View>
                        {breakDownGraphs.map(breakDownGraph => (
                            <ThresholdBreachDeviceBreakDownPdf
                                key={breakDownGraph.serialNumber}
                                graph={breakDownGraph.data}
                                graphId={breakDownGraph.serialNumber}
                                totalDeviceData={totalDeviceData}
                            />
                        ))}
                    </View>
                    <Footer fixed>
                        <Text
                            style={colorStyles.smallText}
                            render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }): string =>
                                `${pageNumber} / ${totalPages}`
                            }
                            fixed
                        />
                        <SmallLogo src={BusinessLogo} />
                    </Footer>
                </Body>
            </Document>
        );
    }
);

export const GeneratePdfComponent = ({
    dateFormat,
    building,
    sensor,
    withinOpeningHours,
    thresholdsText,
    numberOfDevicesInBuilding,
    numberOfDevicesWithDataOutsideForTimeFrame,
    reportGeneratedForPeriodText,
    trendOverTimeGraph,
    totalByDeviceGraph,
    breakDownGraphs,
    totalDeviceData,
    logoImage,
    fileDownloadName,
}: GenerateReportProps): React.ReactElement | null => {
    const { t: txt } = useTranslation();

    const pdfProps = {
        txt,
        dateFormat,
        building,
        sensor,
        withinOpeningHours,
        thresholdsText,
        numberOfDevicesInBuilding,
        numberOfDevicesWithDataOutsideForTimeFrame,
        reportGeneratedForPeriodText,
        trendOverTimeGraph,
        totalByDeviceGraph,
        breakDownGraphs,
        totalDeviceData,
        logoImage,
    };

    const logDownloadReport = (): void => {
        analyticsLogger(BUILDING_THRESHOLD_BREACH_DOWNLOADED_REPORT, {
            pageType: PageType.Building,
            payload: { sensor, withinOpeningHours },
        });
    };
    return (
        <div
            className="form__button-container"
            onClick={logDownloadReport}
            onKeyDown={logDownloadReport}
            role="button"
            tabIndex={0}
        >
            <PDFDownloadLink document={<PdfDocument {...pdfProps} />} fileName={fileDownloadName}>
                {({ loading }): React.ReactElement => <DownloadPDFView loading={loading} fileName={fileDownloadName} />}
            </PDFDownloadLink>
        </div>
    );

    /* // use this to get inline version of pdf.
    return (
        <div className="form form__wide-container">
            <PDFViewer>
                <PdfDocument {...pdfProps} />
            </PDFViewer>
        </div>
    ); */
};

export default GeneratePdfComponent;
