import { takeEvery, call, put, CallEffect, PutEffect } from 'redux-saga/effects';
import {
    organizationInviteResponseSuccess,
    organizationInviteResponseError,
    OrganizationInvitesActionType,
    OrganizationInviteResponse,
} from '../../actions/OrganizationInvitesActions';
import { fetchUserInfoSuccess } from '../../actions/SettingsActions';
import { respondToInvite } from '../../api/organizationInvites';
import { getUserInfo } from '../../api/updateSettings';
import { userIsHbs } from '../../components/findUserType';
import { organizationInviteResponses } from '../../constants';
import { UserResponse } from '../../models/commonTypeScript';
import displayAlertBoxSaga from '../displayAlertBox';

const updateHbsStatus = (): void => {
    const isHbs = userIsHbs();
    if (!isHbs) {
        localStorage.removeItem('hbs');
        window.location.reload(); // reload to redirect back to consumer view;
    }
};

export function* organizationInviteResponseSaga({
    invite,
    status,
}: OrganizationInviteResponse): Generator<CallEffect | PutEffect, void, UserResponse> {
    try {
        yield call(respondToInvite, invite, status);
        if (status === organizationInviteResponses.accept) {
            const response = yield call(getUserInfo);
            yield put(fetchUserInfoSuccess(response));
            yield put(organizationInviteResponseSuccess(invite.id));
        } else {
            updateHbsStatus();
        }
    } catch (error) {
        yield put(organizationInviteResponseError(error && error));
        const errorMessage = error.error ? `ErrorCodes.${error.error}` : 'InvitationInvalidContactSupport';
        yield call(displayAlertBoxSaga, errorMessage, true, false);
    }
}

export default function* OrganizationInviteResponseSagas(): Generator {
    yield takeEvery(OrganizationInvitesActionType.OrganizationInviteResponseInit, organizationInviteResponseSaga);
}
