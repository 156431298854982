import { BusinessRequestType as RequestType } from 'business-dashboard/src/reducers/BusinessRequestType';
import { RequestActionType } from 'commons/src/actions/requestActions';

// Import models
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { BuildingHealthRowData } from '../models/buildingHealthModels';
import { BusinessActionRequestType } from '../models/common';
import { OrganizationBuildingOverview } from '../models/organizationHealthModels';

export enum OrganizationHealthActionType {
    FetchOrganizationHealthOverview = 'FETCH_ORGANIZATION_HEALTH_OVERVIEW/INIT',
    FetchOrganizationHealthOverviewSuccess = 'FETCH_ORGANIZATION_HEALTH_OVERVIEW_SUCCESS',
    FetchOrganizationBuildingData = 'FETCH_ORGANIZATION_BUILDING_DATA/INIT',
    FetchOrganizationBuildingDataSuccess = 'FETCH_ORGANIZATION_BUILDING_DATA_SUCCESS',
    UpdateOrganizationBuildingRequestState = 'UPDATE_ORGANIZATION_BUILDING_REQUEST_STATE',
}

// ********** Summary ********* //

export interface FetchOrganizationHealthOverviewData extends BusinessActionRequestType {
    type: OrganizationHealthActionType.FetchOrganizationHealthOverview;
}

export const fetchOrganizationHealth = (): FetchOrganizationHealthOverviewData => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.FetchOrganizationHealth,
    type: OrganizationHealthActionType.FetchOrganizationHealthOverview,
});

export interface FetchOrganizationHealthOverviewSuccess {
    type: OrganizationHealthActionType.FetchOrganizationHealthOverviewSuccess;
    buildings: OrganizationBuildingOverview[];
    totalLocations: number;
}

export const fetchOrganizationHealthOverviewSuccess = (
    buildings: OrganizationBuildingOverview[],
    totalLocations: number
): FetchOrganizationHealthOverviewSuccess => ({
    type: OrganizationHealthActionType.FetchOrganizationHealthOverviewSuccess,
    buildings,
    totalLocations,
});

// ********** Building request ********* //

export interface UpdateOrganizationBuildingRequest {
    type: OrganizationHealthActionType.UpdateOrganizationBuildingRequestState;
    locationId: string;
    requestState: RequestActionType;
    error?: ErrorType;
}

export const updateOrganizationBuildingRequest = (
    locationId: string,
    requestState: RequestActionType,
    error?: ErrorType
): UpdateOrganizationBuildingRequest => ({
    type: OrganizationHealthActionType.UpdateOrganizationBuildingRequestState,
    locationId,
    requestState,
    error,
});

// ********** Building data ********* //

export interface FetchOrganizationBuildingData {
    type: OrganizationHealthActionType.FetchOrganizationBuildingData;
    locationId: string;
}

export const fetchOrganizationBuildingData = (locationId: string): FetchOrganizationBuildingData => ({
    type: OrganizationHealthActionType.FetchOrganizationBuildingData,
    locationId,
});

export interface FetchOrganizationBuildingSuccess {
    type: OrganizationHealthActionType.FetchOrganizationBuildingDataSuccess;
    locationId: string;
    data: BuildingHealthRowData[];
    totalData: number;
}

export const fetchOrganizationBuildingSuccess = (
    locationId: string,
    data: BuildingHealthRowData[],
    totalData: number
): FetchOrganizationBuildingSuccess => ({
    type: OrganizationHealthActionType.FetchOrganizationBuildingDataSuccess,
    locationId,
    data,
    totalData,
});

//* ***** Actions ******/
export type OrganizationHealthActions =
    | FetchOrganizationHealthOverviewData
    | FetchOrganizationHealthOverviewSuccess
    | FetchOrganizationBuildingData
    | FetchOrganizationBuildingSuccess
    | UpdateOrganizationBuildingRequest;
