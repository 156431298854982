import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPropertyValue } from 'commons/src/actions/SettingsActions';
import { analyticsLogger } from 'commons/src/analytics';
import { SPACE_CREATED_CUSTOM_PROPERTY_VALUE } from 'commons/src/analytics/AnalyticsEvents';
import Combobox, { OptionType } from 'commons/src/components/input/Combobox';
import { PropertyType } from 'commons/src/models/commonEnums';
import { SpacePropertyDefinitionAndValue } from '../../../models/spaceModels';
import { Store } from '../../../reducers';

type PropertyComboboxProps = {
    label?: string;
    id?: string;
    selectedValue?: string;
    testId?: string;
    property: SpacePropertyDefinitionAndValue;
    onSelect: (payload: { propertyId?: string; propertyValue: string | null }) => void;
};

const getInitialOptions = (property?: SpacePropertyDefinitionAndValue): OptionType[] => {
    if (!property) return [];
    return property.propertyOptions?.map(({ id, name }): OptionType => ({ id, label: name })) ?? [];
};

const SpacePropertyComboBox = ({
    id,
    testId,
    label,
    property,
    onSelect,
    selectedValue,
}: PropertyComboboxProps): React.ReactElement | null => {
    const [createOptionLoading, setCreateOptionLoading] = useState(false);
    const [createdLabel, setCreatedLabel] = useState<string | null>();

    const dispatch = useDispatch();
    const request = useSelector((state: Store) => state.commonRequests.CREATE_PROPERTY_VALUE);

    useEffect(() => {
        if (!request.loading && createOptionLoading && createdLabel) {
            // This is the id of freshly created option
            const createdId = property.propertyOptions?.find(prop => prop.name === createdLabel)?.id;
            setCreateOptionLoading(false);
            onSelect({
                propertyId: property.propertyDefinitionId,
                propertyValue: createdId ?? null,
            });
        } else if (request.error) {
            setCreateOptionLoading(false);
        }
    }, [request, createdLabel]);

    const onCreateOption = (newLabel: string): void => {
        if (property.propertyDefinitionId) {
            setCreateOptionLoading(true);
            setCreatedLabel(newLabel);
            dispatch(createPropertyValue(property.propertyDefinitionId, newLabel, PropertyType.Selection));
            analyticsLogger(SPACE_CREATED_CUSTOM_PROPERTY_VALUE, {
                propertyDefinitionId: property.propertyDefinitionId,
                propertyValue: newLabel,
                propertyType: PropertyType.Selection,
            });
        }
    };

    const onSelectOption = (option: OptionType | null): void => {
        onSelect({
            propertyId: property.propertyDefinitionId,
            propertyValue: option?.id ?? null,
        });
    };

    const selectorOptions = useMemo(() => getInitialOptions(property), [property]);
    const selectedItem = selectorOptions.find(value => value.id === selectedValue);

    return (
        <Combobox
            label={label ?? property.propertyDefinition.propertyName}
            id={id ?? property.propertyDefinitionId}
            testId={testId ?? property.propertyDefinitionId}
            createOptionLoading={createOptionLoading}
            disabled={createOptionLoading || request.loading}
            selectorOptions={selectorOptions}
            selectedOption={selectedItem}
            onCreateNewOption={onCreateOption}
            onSelect={onSelectOption}
        />
    );
};

export default SpacePropertyComboBox;
