import React from 'react';

const BacnetIcon = (): React.ReactElement => (
    <svg version="1.0" id="Layer_1" className="device-svg-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path
            fill="#3F4E5A"
            d="M2 24C2 11.856 11.856 2 24 2C36.144 2 46 11.856 46 24C46 36.144 36.144 46 24 46C11.856 46 2 36.144 2
            24ZM24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543
            44 24 44Z"
        />
        <path
            fill="#3F4E5A"
            d="M12.2426 15.504H15.5546C15.8746 15.504 16.1906 15.544 16.5026 15.624C16.8146 15.696 17.0906 15.82
        17.3306 15.996C17.5786 16.164 17.7786 16.384 17.9306 16.656C18.0826 16.928 18.1586 17.256 18.1586 17.64C18.1586
        18.12 18.0226 18.516 17.7506 18.828C17.4786 19.14 17.1226 19.364 16.6826 19.5V19.524C17.2186 19.596 17.6586
        19.804 18.0026 20.148C18.3466 20.492 18.5186 20.952 18.5186 21.528C18.5186 21.992 18.4266 22.384 18.2426
        22.704C18.0586 23.016 17.8146 23.268 17.5106 23.46C17.2146 23.652 16.8706 23.792 16.4786 23.88C16.0946 23.96
        15.7026 24 15.3026 24H12.2426V15.504ZM13.7546 18.96H15.0986C15.6026 18.96 15.9866 18.86 16.2506 18.66C16.5146
        18.46 16.6466 18.176 16.6466 17.808C16.6466 17.424 16.5106 17.152 16.2386 16.992C15.9666 16.832 15.5426 16.752
        14.9666 16.752H13.7546V18.96ZM13.7546 22.728H15.1106C15.3026 22.728 15.5106 22.716 15.7346 22.692C15.9586 22.66
        16.1626 22.6 16.3466 22.512C16.5386 22.424 16.6946 22.296 16.8146 22.128C16.9426 21.96 17.0066 21.736 17.0066
        21.456C17.0066 21.008 16.8546 20.696 16.5506 20.52C16.2466 20.344 15.7866 20.256 15.1706 20.256H13.7546V22.728ZM22.6762
        15.504H23.9842L27.6442 24H25.9162L25.1242 22.056H21.4402L20.6722 24H18.9802L22.6762 15.504ZM24.5962 20.76L23.2882
        17.304L21.9562 20.76H24.5962ZM34.4059 17.496C34.1819 17.192 33.8899 16.972 33.5299 16.836C33.1699 16.692 32.8179
        16.62 32.4739 16.62C32.0339 16.62 31.6339 16.7 31.2739 16.86C30.9139 17.02 30.6019 17.24 30.3379 17.52C30.0819
        17.8 29.8819 18.128 29.7379 18.504C29.6019 18.88 29.5339 19.288 29.5339 19.728C29.5339 20.192 29.6019 20.616
        29.7379 21C29.8739 21.384 30.0659 21.716 30.3139 21.996C30.5699 22.268 30.8739 22.48 31.2259 22.632C31.5779
        22.784 31.9739 22.86 32.4139 22.86C32.8699 22.86 33.2739 22.772 33.6259 22.596C33.9779 22.412 34.2619 22.172
        34.4779 21.876L35.6899 22.728C35.3139 23.2 34.8539 23.568 34.3099 23.832C33.7659 24.088 33.1299 24.216 32.4019
        24.216C31.7379 24.216 31.1259 24.108 30.5659 23.892C30.0139 23.668 29.5379 23.36 29.1379 22.968C28.7379
        22.568 28.4259 22.096 28.2019 21.552C27.9779 21 27.8659 20.392 27.8659 19.728C27.8659 19.048 27.9819 18.436
        28.2139 17.892C28.4539 17.34 28.7779 16.872 29.1859 16.488C29.6019 16.104 30.0899 15.808 30.6499 15.6C31.2099
        15.392 31.8179 15.288 32.4739 15.288C32.7459 15.288 33.0299 15.316 33.3259 15.372C33.6219 15.42 33.9059 15.5
        34.1779 15.612C34.4499 15.716 34.7059 15.848 34.9459 16.008C35.1859 16.168 35.3899 16.36 35.5579 16.584L34.4059
        17.496ZM15.3874 28.24H16.7554V29.164H16.7794C16.9074 28.876 17.1274 28.624 17.4394 28.408C17.7594 28.184 18.1354
        28.072 18.5674 28.072C18.9434 28.072 19.2634 28.14 19.5274 28.276C19.7994 28.404 20.0194 28.576 20.1874 28.792C20.3634
        29.008 20.4914 29.256 20.5714 29.536C20.6514 29.816 20.6914 30.104 20.6914 30.4V34H19.2514V30.808C19.2514 30.64 19.2394
        30.464 19.2154 30.28C19.1914 30.096 19.1394 29.932 19.0594 29.788C18.9794 29.636 18.8674 29.512 18.7234
        29.416C18.5874 29.32 18.4034 29.272 18.1714 29.272C17.9394 29.272 17.7394 29.32 17.5714 29.416C17.4034 29.504
        17.2634 29.62 17.1514 29.764C17.0474 29.908 16.9674 30.076 16.9114 30.268C16.8554 30.452 16.8274 30.64 16.8274
        30.832V34H15.3874V28.24ZM26.4278 30.544C26.4278 30.36 26.3998 30.184 26.3438 30.016C26.2958 29.848 26.2158
        29.7 26.1038 29.572C25.9918 29.444 25.8478 29.344 25.6718 29.272C25.5038 29.192 25.3038 29.152 25.0718 29.152C24.6398
        29.152 24.2718 29.284 23.9678 29.548C23.6718 29.804 23.5078 30.136 23.4758 30.544H26.4278ZM27.8678 31.192C27.8678
        31.256 27.8678 31.32 27.8678 31.384C27.8678 31.448 27.8638 31.512 27.8558 31.576H23.4758C23.4918 31.784 23.5438
        31.976 23.6318 32.152C23.7278 32.32 23.8518 32.468 24.0038 32.596C24.1558 32.716 24.3278 32.812 24.5198
        32.884C24.7118 32.956 24.9118 32.992 25.1198 32.992C25.4798 32.992 25.7838 32.928 26.0318 32.8C26.2798
        32.664 26.4838 32.48 26.6438 32.248L27.6038 33.016C27.0358 33.784 26.2118 34.168 25.1318 34.168C24.6838
        34.168 24.2718 34.1 23.8958 33.964C23.5198 33.82 23.1918 33.62 22.9118 33.364C22.6398 33.108 22.4238 32.796
        22.2638 32.428C22.1118 32.052 22.0358 31.628 22.0358 31.156C22.0358 30.692 22.1118 30.272 22.2638 29.896C22.4238
        29.512 22.6398 29.188 22.9118 28.924C23.1838 28.652 23.5038 28.444 23.8718 28.3C24.2478 28.148 24.6518 28.072
        25.0838 28.072C25.4838 28.072 25.8518 28.14 26.1878 28.276C26.5318 28.404 26.8278 28.6 27.0758 28.864C27.3238
        29.12 27.5158 29.444 27.6518 29.836C27.7958 30.22 27.8678 30.672 27.8678 31.192ZM28.4344
        29.392V28.24H29.4424V26.572H30.8584V28.24H32.2984V29.392H30.8584V32.068C30.8584 32.324 30.9024 32.536 30.9904
        32.704C31.0864 32.872 31.2944 32.956 31.6144 32.956C31.7104 32.956 31.8144 32.948 31.9264 32.932C32.0384 32.908
        32.1384 32.876 32.2264 32.836L32.2744 33.964C32.1464 34.012 31.9944 34.048 31.8184 34.072C31.6424 34.104 31.4744
        34.12 31.3144 34.12C30.9304 34.12 30.6184 34.068 30.3784 33.964C30.1384 33.852 29.9464 33.704 29.8024 33.52C29.6664
        33.328 29.5704 33.112 29.5144 32.872C29.4664 32.624 29.4424 32.36 29.4424 32.08V29.392H28.4344Z"
        />
    </svg>
);

export default BacnetIcon;
