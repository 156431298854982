import React from 'react';
import { batteryLevels } from '../../constants';
import IconBatteryDisconnected from '../../img/icon-battery-disconnected';
import IconBatteryGood from '../../img/icon-battery-good';
import IconBatteryLow from '../../img/icon-battery-low';
import IconBatteryModerate from '../../img/icon-battery-moderate';

export type Props = {
    batteryPercentage: number | undefined;
    isHubConnectionLost?: boolean;
};

const BatteryIconComponent = (props: Props): React.ReactElement => {
    const { batteryPercentage, isHubConnectionLost } = props;
    if (batteryPercentage === undefined || isHubConnectionLost) {
        return <IconBatteryDisconnected />;
    }
    if (batteryPercentage > batteryLevels.good) {
        return <IconBatteryGood />;
    }
    if (batteryPercentage < batteryLevels.low) {
        return <IconBatteryLow />;
    }
    return <IconBatteryModerate />;
};

export default BatteryIconComponent;
