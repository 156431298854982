import React, { useEffect, FocusEvent, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ComponentTab } from '../../models/menuModels';

type Props = {
    tabs: ComponentTab[];
    activeTab: number;
    setMenuOpen: (open: boolean) => void;
    menuOpen: boolean;
    setActiveTab: (activeTab: number) => void;
};

const ComponentTabMobileMenu = ({
    tabs,
    activeTab,
    setActiveTab,
    menuOpen,
    setMenuOpen,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const activeTabName = tabs[activeTab].title;
    const activeTabId = tabs[activeTab].id;

    useEffect(() => {
        setActiveTab(activeTab);
    }, []);

    useEffect(() => {
        if (menuOpen && activeTab) {
            const focusedItem = document.getElementById(activeTabName);
            if (focusedItem) {
                focusedItem.focus();
            }
        }
    }, [menuOpen]);

    const closeMenu = (): void => {
        setMenuOpen(false);
    };
    const onClick = (e: SyntheticEvent<HTMLButtonElement>): void => {
        const buttonId = e.currentTarget.id;
        const buttonIndex = tabs.findIndex(tab => tab.id === buttonId);
        setActiveTab(buttonIndex);
        closeMenu();
    };

    const navItemBlur = (event: FocusEvent<HTMLElement>): void => {
        const enterTarget = event.relatedTarget;
        if (!enterTarget) {
            closeMenu();
        }
    };
    const openCloseMenu = (): void => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="tab-menu-mobile">
            <div
                role="button"
                id="settings-menu"
                className="tab-menu-mobile__button"
                tabIndex={0}
                onBlur={navItemBlur}
                onClick={openCloseMenu}
                onKeyUp={(event): void => {
                    if (event.key !== 'Enter') return;
                    openCloseMenu();
                }}
            >
                <div className="sidebar-navigation__items-container__item sidebar-navigation__items-container__item--active">
                    <li className="sidebar-navigation__items-container__element">
                        <span>{txt(activeTabName)}</span>
                    </li>
                </div>
                <i className="material-symbols-outlined">keyboard_arrow_down</i>
            </div>
            <div className="position-root">
                <ul
                    className={classNames('tab-menu-mobile__dropdown', 'tab-menu-mobile__dropdown--component-tab', {
                        'fixed-menu--active': menuOpen,
                    })}
                >
                    {tabs.map(menuItem => (
                        <button
                            type="button"
                            key={menuItem.id}
                            id={menuItem.id}
                            className={classNames(
                                'sidebar-navigation__items-container__item',
                                'sidebar-navigation__items-container__item--component-tab',
                                {
                                    'sidebar-navigation__items-container__item--active': activeTabId === menuItem.id,
                                }
                            )}
                            onClick={onClick}
                        >
                            <li className="sidebar-navigation__items-container__element">
                                <span>{menuItem.title}</span>
                            </li>
                        </button>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ComponentTabMobileMenu;
