import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import {
    FetchIaqInsightDataType,
    FetchVirusRiskInsightDataType,
    IaqInsightResponse,
    RadonInsightResponse,
    VirusRiskInsightResponse,
} from '../actions/insightActions';

type ReportPayload = { from: string; to: string; serialNumbers: string[]; locationId: string };

export default async (payload: ReportPayload): Promise<RadonInsightResponse> =>
    fetch(`/locations/${payload.locationId}/reports/radon`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(payload),
    });

export const getIaqReport = async (payload: FetchIaqInsightDataType): Promise<IaqInsightResponse> =>
    fetch(`/locations/${payload.locationId}/reports/iaq`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(payload),
    });

export const getVirusRiskReport = async (payload: FetchVirusRiskInsightDataType): Promise<VirusRiskInsightResponse> =>
    fetch(`/locations/${payload.locationId}/reports/virus`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(payload),
    });
