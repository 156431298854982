import React from 'react';
import { TextRow } from 'react-placeholder/lib/placeholders';
import { colors } from 'commons/src/constants';
import styles from './Common.module.scss';

const offlineHubTablePlaceholder = (
    <div className={styles.placeholderTable}>
        <TextRow color={colors.greyAsh} className={styles.placeholderHeader} />
        <div className={styles.placeholderBody}>
            <TextRow
                color={colors.greyAsh}
                className="placeholder__tile-body placeholder__tile-body show-loading-animation"
            />
        </div>
    </div>
);

const offlineDeviceTablePlaceholder = (
    <div className={styles.placeholderTable}>
        <TextRow color={colors.greyAsh} className={styles.placeholderHeader} />
        <div className={styles.placeholderBody}>
            <TextRow
                color={colors.greyAsh}
                className="placeholder__tile-body placeholder__tile-body show-loading-animation"
            />
            <TextRow
                color={colors.greyAsh}
                className="placeholder__tile-body placeholder__tile-body show-loading-animation"
            />
            <TextRow
                color={colors.greyAsh}
                className="placeholder__tile-body placeholder__tile-body show-loading-animation"
            />
        </div>
    </div>
);

export const offlineDeviceModalPlaceholder = (
    <div className={styles.infoModalPlaceholder}>
        <TextRow
            color={colors.greyAsh}
            className="placeholder__tile-body placeholder__tile-body show-loading-animation"
        />
        <TextRow
            color={colors.greyAsh}
            className="placeholder__tile-body placeholder__tile-body show-loading-animation"
        />
        <TextRow
            color={colors.greyAsh}
            className="placeholder__tile-body placeholder__tile-body show-loading-animation"
        />
    </div>
);

const offlineTablePlaceholder = (
    <>
        {offlineHubTablePlaceholder}
        {offlineDeviceTablePlaceholder}
    </>
);

export default offlineTablePlaceholder;
