import React from 'react';
import { useTranslation } from 'react-i18next';
import CircleButton from '../buttons/CircleButton';

type Props = {
    openSection: (open: boolean) => void;
    headerIsOpen: boolean;
    headerText: string;
    subtext: string;
    subComponent?: React.ReactElement;
    showSubComponent?: boolean;
    translateSubText?: boolean;
    translateHeaderText?: boolean;
    testAttr?: string;
    loading?: boolean;
};

const CollapsableSectionHeader = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const {
        openSection,
        headerIsOpen,
        headerText,
        subtext,
        subComponent,
        loading,
        testAttr,
        translateSubText = true,
        translateHeaderText = true,
        showSubComponent = false,
    } = props;
    return (
        <div className="flex flex--spaced">
            <div>
                <h3>{translateHeaderText ? txt(headerText) : headerText}</h3>
                <div className="text-medium">{translateSubText ? txt(subtext) : subtext}</div>
                {showSubComponent && subComponent}
            </div>
            <CircleButton
                onClick={(): void => openSection(!headerIsOpen)}
                testAttr={`expand-section-${testAttr}`}
                iconName={headerIsOpen ? 'expand_less' : 'expand_more'}
                color="secondary"
                loading={loading}
            />
        </div>
    );
};

export default CollapsableSectionHeader;
