import * as React from 'react';
import {
    TableHead,
    TableContainer,
    Paper,
    Box,
    TableSortLabel,
    TableCell,
    TableRow,
    TableBody,
    Table,
} from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { dateFormats } from 'commons/src/constants';
import { SubscriptionName } from '../../../components/subscriptionComponents/YourPlan';
import { CustomerListElement, SortParams } from '../../../models/partner';
import { PendingChip } from './CustomerListElement';
import styles from './FullWidthTable.module.scss';

type Order = 'asc' | 'desc';

interface HeadCell {
    disablePadding: boolean;
    id: keyof CustomerListElement;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'DateCreated',
    },
    {
        label: 'Subscription.Subscription',
        id: 'subscriptionType',
        numeric: false,
        disablePadding: false,
    },
    {
        label: 'Subscription.Seats',
        id: 'totalNumberOfSeats',
        numeric: true,
        disablePadding: false,
    },
    {
        label: 'Devices',
        id: 'totalNumberOfDevices',
        numeric: true,
        disablePadding: false,
    },
    {
        label: '',
        id: 'pending',
        numeric: true,
        disablePadding: false,
    },
];

type TableRowProps = {
    customer: CustomerListElement;
    dateFormat: keyof typeof dateFormats;
    redirectToCustomer: (customer: CustomerListElement) => void;
    newCustomerId?: string;
};

const CustomerTableRow = ({
    customer,
    dateFormat,
    redirectToCustomer,
    newCustomerId,
}: TableRowProps): React.ReactElement => {
    const handleClick = (): void => {
        redirectToCustomer(customer);
    };
    const newCustomer = customer.userGroupId === newCustomerId;

    return (
        <TableRow
            hover
            onClick={handleClick}
            onKeyUp={(e): void => {
                if (e.key === 'Enter') handleClick();
            }}
            tabIndex={0}
            role="button"
            id={customer.userGroupId}
            className={newCustomer ? styles.newCustomerRow : styles.tableRow}
        >
            <TableCell>
                <div className={styles.customerName}>{customer.name}</div>
            </TableCell>
            <TableCell>{dayjs(customer.createdAt).format(dateFormats[dateFormat].calendarFormat)}</TableCell>
            <TableCell align="right">
                <SubscriptionName alignRight subscriptionType={customer.subscriptionType} />
            </TableCell>
            <TableCell align="right">{customer.totalNumberOfSeats ?? '-'}</TableCell>
            <TableCell align="right">{customer.totalNumberOfDevices}</TableCell>
            <TableCell align="right">{customer.pending ? <PendingChip /> : <div />}</TableCell>
        </TableRow>
    );
};

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof CustomerListElement) => void;
    order: Order;
    orderBy: string;
}

const EnhancedTableHead = ({ order, orderBy, onRequestSort }: EnhancedTableProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const createSortHandler = (property: keyof CustomerListElement) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            className={styles.headerCell}
                        >
                            {txt(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" className={styles.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

type TableProps = {
    customers: CustomerListElement[];
    dateFormat: keyof typeof dateFormats;
    sortDir: SortParams;
    setSortDir: ({ headerKey, direction }: SortParams) => void;
    redirectToCustomer: (customer: CustomerListElement) => void;
    totalSeatAndDevices: { seatsCount: number; devicesCount: number };
    newCustomerId?: string;
};

const FullWidthTable = ({
    customers,
    dateFormat,
    setSortDir,
    sortDir,
    redirectToCustomer,
    totalSeatAndDevices,
    newCustomerId,
}: TableProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const order = sortDir.direction === -1 ? 'desc' : 'asc';

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof CustomerListElement): void => {
        const isAsc = sortDir.headerKey === property && sortDir.direction === -1;
        setSortDir({ headerKey: property, direction: isAsc ? 1 : -1 });
    };

    return (
        <Box sx={{ width: '100%' }} className={styles.table}>
            <Paper sx={{ width: '100%', mb: 2, borderRadius: '10px', boxShadow: 'unset' }}>
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small">
                        <EnhancedTableHead
                            order={order}
                            orderBy={sortDir.headerKey}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {customers.map(row => (
                                <CustomerTableRow
                                    newCustomerId={newCustomerId}
                                    customer={row}
                                    redirectToCustomer={redirectToCustomer}
                                    dateFormat={dateFormat}
                                    key={`customer-row-${row.userGroupId}`}
                                />
                            ))}
                            <TableRow>
                                <TableCell>
                                    <div className={styles.bold}>TotalCustomers: {customers.length}</div>
                                </TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell align="right">
                                    {txt('Subscription.TotalSeats')} {totalSeatAndDevices.seatsCount}
                                </TableCell>
                                <TableCell align="right">
                                    {txt('Subscription.TotalDevices')} {totalSeatAndDevices.devicesCount}
                                    <div className={styles.summaryCellTextSmall}>
                                        {txt('Subscription.HubsExcluded')}
                                    </div>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};

export default FullWidthTable;
