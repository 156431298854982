import React from 'react';
import classNames from 'classnames';
import QRCodeSVG from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { SensorIcon } from '../../../components/sensors/SensorUnit';
import { sensorUnits } from '../../../constants';
import ViewTripleScreenOption from '../../../img/view-triple-screen-option';
import { ScreenOptions } from '../../../models/commonEnums';
import { DisplayScreenOptions } from '../../../models/commonTypeScript';

export type Props = {
    screenOptions: DisplayScreenOptions[];
    selectedOptions: string[];
    publicUrl?: string;
};

const ScreenPreview = (props: Props): React.ReactElement | null => {
    const { t: txt } = useTranslation();
    const { screenOptions, selectedOptions, publicUrl } = props;

    const screenProps: DisplayScreenOptions[] = selectedOptions
        .map(id => screenOptions.find(option => option.id === id))
        .filter(screenOption => screenOption !== undefined);

    const getUnit = (unit: keyof typeof sensorUnits): React.ReactElement => {
        const displayedUnit = sensorUnits[unit];
        if (!displayedUnit || displayedUnit.length > 1) return <div />;
        return <span className="edit-device__screen-preview__content__unit">{displayedUnit}</span>;
    };

    if (screenProps.length === 1 && screenProps[0]) {
        const screenProp = screenProps[0];

        if (screenProp.id === ScreenOptions.co2TempHumidity) {
            return (
                <div className="edit-device__screen-preview__content--triple-screen">
                    <ViewTripleScreenOption />
                </div>
            );
        }
        if (screenProp.id === ScreenOptions.blank) return null;

        let displayedText = `DisplaySettings.${screenProp.title.key}`;
        if (screenProps[0].id === 'publicQr') displayedText = 'DisplaySettings.PublicQRText';
        if (screenProps[0].id === 'weatherAndTemp') displayedText = 'Weather5';
        return (
            <div
                className={classNames('edit-device__screen-preview__content', {
                    'edit-device__screen-preview__content--small': screenProp.id === ScreenOptions.publicQr,
                })}
            >
                {screenProp.id === ScreenOptions.publicQr && publicUrl ? (
                    <QRCodeSVG value={publicUrl} size={80} id="qrCode" />
                ) : (
                    <SensorIcon sensor={screenProp.iconName} />
                )}
                <div className="edit-device__screen-preview__content__single-wrapper">
                    <div className="edit-device__screen-preview__content__value">
                        {screenProp.defaultValue}
                        {getUnit(screenProp.unit)}
                    </div>
                    {txt(displayedText)}
                </div>
            </div>
        );
    }

    const getText = (textKey: string): string => {
        const shortVersion = txt(`DisplaySettings.Short${textKey}`);
        if (shortVersion.length < 10) return shortVersion;
        return txt(`DisplaySettings.${textKey}`);
    };
    return (
        <div className="edit-device__screen-preview__content edit-device__screen-preview__content--double">
            {screenProps.map(screen =>
                screen.id === 'publicQr' && publicUrl ? (
                    <div
                        key={`screen-props${screen.id}`}
                        className="edit-device__screen-preview__content__double-wrapper"
                    >
                        <QRCodeSVG value={publicUrl} size={80} id="qrCode" />
                    </div>
                ) : (
                    <div
                        key={`screen-props${screen.id}`}
                        className="edit-device__screen-preview__content__double-wrapper"
                    >
                        <div className="edit-device__screen-preview__content__value">
                            {screen.defaultValue}
                            {getUnit(screen.unit)}
                        </div>
                        <div className="flex edit-device__screen-preview__content__text">
                            <SensorIcon sensor={screen.iconName} />
                            {getText(screen.title.key)}
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default ScreenPreview;
