import React from 'react';
import { Cell } from 'react-table';
import styles from './PartnerSummary.module.scss';

export const FirstColumnCell = ({ cell }: { cell: Cell }): React.ReactElement => {
    return <div className="text-bold text-medium">{cell.value}</div>;
};

export const TextColumnCell = ({ cell }: { cell: Cell }): React.ReactElement => {
    return <div className="text-medium">{cell.value || '-'}</div>;
};

export const renderThresholdsRow = (row: string, sensorUnit: string, thresholds: number[]): React.ReactElement => {
    const render = (): React.ReactElement => {
        switch (row) {
            case '0':
                return <div>{['< ', `${thresholds[0]}${sensorUnit}`]}</div>;
            case '1':
                return thresholds.length > 1 ? (
                    <div>{[`${thresholds[0]}${sensorUnit}`, `- ${thresholds[1]}${sensorUnit}`]}</div>
                ) : (
                    <div />
                );
            case '2':
                return <div>{['> ', `${thresholds[0]}${sensorUnit}`]}</div>;
            default:
                return <div />;
        }
    };
    return render();
};

export const AveragesCountCell = ({ cell }: { cell: Cell }): React.ReactElement => {
    const { sensorUnit, thresholds, totalDevices } = cell.value;
    const thresholdsRow = renderThresholdsRow(cell.row.id, sensorUnit, thresholds);
    return (
        <div>
            <div className={styles.textCell}>{totalDevices}</div>
            <div className={styles.thresholdText}>{thresholdsRow}</div>
        </div>
    );
};
