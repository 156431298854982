import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import { FetchUserInfo, fetchUserInfo } from 'commons/src/actions/SettingsActions';
import RequestActions from 'commons/src/models/RequestTypes';
import displayAlertBoxSaga from 'commons/src/sagas/displayAlertBox';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import history from 'commons/src/store/history';
import {
    CreatePartnerCustomer,
    CreatePartnerCustomerSuccess,
    createPartnerCustomerSuccess,
    PartnerCustomerActions,
    UpdatePartnerCustomer,
    updatePartnerCustomerSuccess,
} from '../../actions/partnerCustomerActions';
import { createCustomer, updateCustomer } from '../../api/partnerCustomer';
import { paths } from '../../constants';
import { PartnerCustomerDetails } from '../../models/partner';
import { BusinessRequestType as RequestType } from '../../reducers/BusinessRequestType';

type CreatePartnerCustomerSagaReturnType = Generator<
    | CallEffect<PartnerCustomerDetails>
    | PutEffect<CreatePartnerCustomerSuccess>
    | PutEffect<FetchUserInfo>
    | RequestActions
    | CallEffect,
    void,
    PartnerCustomerDetails
>;

export function* createPartnerCustomerSaga({ customer }: CreatePartnerCustomer): CreatePartnerCustomerSagaReturnType {
    try {
        const newCustomer = yield call(createCustomer, customer);
        yield put(createPartnerCustomerSuccess(newCustomer));
        yield put(requestSuccess(RequestType.CreatePartnerCustomer, RequestActionType.Success));
        yield put(fetchUserInfo());
        history.push(`/${paths.partnerPage}?customerUserGroupId=${newCustomer.userGroupId}`);
        yield call(displayAlertBoxSaga, 'Partner.CustomerCreatedSuccessfully', false, true);
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.CreatePartnerCustomer, RequestActionType.Error));
    }
}

export function* editPartnerCustomerSaga({ customer }: UpdatePartnerCustomer): Generator {
    try {
        yield call(updateCustomer, customer);
        yield put(updatePartnerCustomerSuccess());
        yield put(requestSuccess(RequestType.UpdatePartnerCustomer, RequestActionType.Success));
        yield call(displayAlertBoxSaga, 'Partner.CustomerUpdatedSuccessfully', false, true);
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.UpdatePartnerCustomer, RequestActionType.Error));
    }
}

export default function* PartnerCustomerSagas(): Generator {
    yield all([
        takeEvery(PartnerCustomerActions.CreatePartnerCustomerInit, createPartnerCustomerSaga),
        takeEvery(PartnerCustomerActions.UpdatePartnerCustomerInit, editPartnerCustomerSaga),
    ]);
}
