import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import IconMenuEllipsis from '../../img/icon-vertical-ellipsis';
import { FeatureToggleEnum, GroupType, Role } from '../../models/commonEnums';
import { MenuItem } from '../../models/menuModels';
import { Store } from '../../reducers';
import MaterialIcon from '../MaterialIcon';
import filterMenuElements from '../menu/FilterMenuElements';
import styles from './DropdownOptions.module.scss';

type StateProps = {
    userRole?: Role;
    featureToggles?: FeatureToggleEnum[];
    groupType?: GroupType;
};

export type ParentProps = {
    options: MenuItem[];
    dropdownHeader?: string;
    dotMenu?: boolean;
    customButton?: React.ReactElement;
    id: string;
    alignRight?: boolean;
};

type Props = StateProps & ParentProps;

export const DropdownOptionsComponent = ({
    options,
    dropdownHeader,
    dotMenu,
    customButton,
    userRole,
    featureToggles,
    id,
    groupType,
    alignRight,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [inFocus, setInFocus] = useState(0);

    const onDropdownClick = (e: React.SyntheticEvent<HTMLElement>): void => {
        const buttonElement = document.getElementById(id);
        if (buttonElement) buttonElement.focus();
        e.preventDefault();
        e.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
        setInFocus(0);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLElement>): void => {
        if (!isDropdownOpen) {
            return;
        }
        const optionsLength = options.length;
        let toBeFocused;

        switch (e.key) {
            case 'ArrowDown':
                if (inFocus >= optionsLength) {
                    toBeFocused = 1;
                } else {
                    toBeFocused = inFocus + 1;
                }

                break;
            case 'ArrowUp':
                if (inFocus <= 1) {
                    toBeFocused = optionsLength;
                } else {
                    toBeFocused = inFocus - 1;
                }

                break;
            case 'Enter':
                if (inFocus === 0) {
                    onDropdownClick(e);
                } else {
                    options[inFocus - 1].onClick();
                }

                break;
            default:
                return;
        }

        e.preventDefault();
        e.stopPropagation();
        if (toBeFocused) {
            setInFocus(toBeFocused);
        }
    };

    const onBlur = (e: React.SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        const buttonElement = document.getElementById(id);
        if (buttonElement) buttonElement.blur();
        setIsDropdownOpen(false);
    };

    const onSelect = (e: React.MouseEvent<HTMLDivElement>, onClick: () => void): void => {
        e.stopPropagation();
        e.preventDefault();
        if (e.button === 0) {
            setIsDropdownOpen(false);
            onClick();
        }
    };

    const onTouchSelect = (e: React.TouchEvent<HTMLDivElement>, onClick: () => void): void => {
        e.stopPropagation();
        e.preventDefault();
        setIsDropdownOpen(false);
        onClick();
    };

    const dropdownButton = (): React.ReactElement | null => {
        if (customButton) return customButton;
        if (dotMenu) return <IconMenuEllipsis />;
        return null;
    };

    const allowedOptionsForDisplay: MenuItem[] =
        options && filterMenuElements(options, groupType, userRole, undefined, featureToggles);

    if (!allowedOptionsForDisplay || !allowedOptionsForDisplay.length) {
        return <div />;
    }

    return (
        <div className={styles.dropdownWrapper}>
            <button
                type="button"
                id={id}
                data-testid={id}
                title={txt('Options')}
                aria-label={txt('Options')}
                className={classNames(styles.button, {
                    [styles.dotButton]: dotMenu,
                })}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onClick={onDropdownClick}
                onTouchEnd={onDropdownClick}
                data-tile-menu
            >
                {dropdownHeader}
                {dropdownButton()}
            </button>
            {isDropdownOpen && (
                <div
                    role="list"
                    className={classNames(styles.list, {
                        [styles.leftAligned]: dotMenu && !alignRight,
                        [styles.rightAligned]: dotMenu && alignRight,
                    })}
                    data-tile-dropdown-open
                >
                    {allowedOptionsForDisplay?.map(
                        ({ onClick, text, selector, isSelected, bottomLine, icon, disabled }, i) => (
                            <div
                                role="button"
                                id={text}
                                tabIndex={0}
                                onMouseDown={(e): void => {
                                    if (disabled !== true) onSelect(e, onClick);
                                }}
                                onTouchEnd={(e): void => {
                                    if (disabled !== true) onTouchSelect(e, onClick);
                                }}
                                key={`dropdown-option-element-${i + 1}`}
                                className={classNames(styles.listOption, {
                                    [styles.optionIsFocused]: inFocus === i + 1,
                                    [styles.selectorOption]: selector && !isSelected,
                                    [styles.optionSelected]: isSelected,
                                    [styles.bottomLine]: bottomLine,
                                    [styles.optionDisabled]: disabled,
                                })}
                                data-tile-menu-option
                            >
                                {isSelected && <MaterialIcon name="check" />}
                                {icon && <span className={styles.optionIcon}>{icon}</span>}
                                {txt(text)}
                            </div>
                        )
                    )}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const { userSettings } = store;
    return {
        userRole: userSettings?.selectedGroup?.role,
        groupType: userSettings?.selectedGroup?.groupType,
        featureToggles: userSettings && userSettings.featureToggles,
    };
};

export default connect(mapStateToProps)(DropdownOptionsComponent);
