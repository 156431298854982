import React from 'react';
import { useTranslation } from 'react-i18next';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';

const RiskSolutionElement = (header: string, description: string, sensor: string): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className="modal__content__risk-factors__element">
            <div className="modal__content__risk-factors__element__solution">
                <SensorIcon sensor={sensor} />
                <h2>{txt(header)}</h2>
            </div>
            <p>{txt(description)}</p>
        </div>
    );
};

const VirusInfoSolutionsComponent = (): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className="modal__content__body">
            <p>{txt('InsightRiskFactor.VirusSolutionsExplained')}</p>
            <div className="modal__content__risk-factors">
                {RiskSolutionElement(
                    'VirusInsight.WarmerTemperature',
                    'InsightRiskFactor.WarmerTemperaturesSolutionDescription',
                    'temp'
                )}
                {RiskSolutionElement(
                    'VirusInsight.DecreaseOccupancy',
                    'InsightRiskFactor.DecreaseOccupancyDescription',
                    'occupancy'
                )}
                {RiskSolutionElement(
                    'VirusInsight.IncreaseVentilationRate',
                    'InsightRiskFactor.IncreaseVentilationRateDescription',
                    'ventilationRate'
                )}
                {RiskSolutionElement(
                    'VirusInsight.RetainHumidityLevels',
                    'InsightRiskFactor.RetainHumidityLevelsDescription',
                    'humidity'
                )}
            </div>
        </div>
    );
};

export default VirusInfoSolutionsComponent;
