import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
    ValidateLocationRfRegion,
    validateLocationRfRegion,
    ValidateLocationRfRegionClearState,
    validateLocationRfRegionClearState,
} from '../../actions/LocationActions';
import TertiaryButton from '../../components/buttons/TertiaryButton';
import MultiAttrDropdown from '../../components/dropdown/MultipleAttrDropdown';
import { userIsHbs } from '../../components/findUserType';
import spinner from '../../img/spinner';
import { LocationRfRegionResponse, LocationType } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import { CommonRequestType } from '../../reducers/requestReducer';
import RegionChangeResponseBox from './RegionChangeResponseBox';

type StateProps = {
    locations: LocationType[];
    loading: boolean;
    rfRegionResponse?: LocationRfRegionResponse;
    rfRegionValidationLoading: boolean;
};

type ActionProps = {
    validateRfRegion: (countryCode: string, locationId?: string) => void;
    clearRfRegionState: () => void;
};

type ParentProps = {
    value: string | null;
    displayValidation: boolean;
    validSelection: boolean;
    onSelectLocation: (arg0: { id: string; locationName: string }) => void;
    buttonOnClick?: (e: React.SyntheticEvent<HTMLElement>) => void;
};

type Props = ParentProps & ActionProps & StateProps;

export const PickExistingLocationComponent = (props: Props): React.ReactElement => {
    const {
        locations,
        loading,
        value,
        displayValidation,
        validSelection,
        onSelectLocation,
        buttonOnClick,
        rfRegionResponse,
        rfRegionValidationLoading,
        validateRfRegion,
        clearRfRegionState,
    } = props;
    const { t: txt } = useTranslation();

    useEffect(() => (): void => clearRfRegionState(), []);

    const dropdownOnSelect = (location: { id: string; inputValue: string }): void => {
        const { id, inputValue } = location;
        onSelectLocation({ id, locationName: inputValue });

        if (userIsHbs()) {
            const selectedLocation = locations && locations.find(loc => loc.id === id);
            const countryCode = selectedLocation && selectedLocation.countryCode;
            validateRfRegion(countryCode || '', id);
        }
    };

    const options = !loading
        ? locations.map(location => ({
              inputValue: location.name,
              id: location.id,
          }))
        : [];

    return (
        <div className="form form--medium-padding">
            <MultiAttrDropdown
                id="pickExistingLocation"
                title="Location"
                defaultOption="ChooseLocation"
                value={value}
                options={options}
                loading={loading}
                hint="LocationHint"
                validate={displayValidation}
                isValid={validSelection}
                onSelect={dropdownOnSelect}
                testAttr="location-dropdown"
                inputInfo="LocationRfRegion.InfoText"
            />
            {rfRegionValidationLoading ? (
                <div className="centered">{spinner}</div>
            ) : (
                <RegionChangeResponseBox
                    rfRegionResponse={rfRegionResponse}
                    onFailText="LocationRfRegion.InvalidChange"
                    onSuccessText="LocationRfRegion.ValidChangeLocation"
                    onFailSubText={txt('LocationRfRegion.InvalidChangeDescription')}
                    onSuccessSubText={txt('LocationRfRegion.ValidChangeLocationDescription', {
                        region: rfRegionResponse ? txt(`${rfRegionResponse.newRfRegion}`) : '',
                    })}
                />
            )}
            {buttonOnClick && (
                <TertiaryButton id="changeLocationButton" title="AddNewLocation" onClick={buttonOnClick} />
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { locations, loading, rfRegionResponse },
        commonRequests: {
            [CommonRequestType.ValidateLocationRfRegion]: { loading: rfRegionValidationLoading },
        },
    } = state;

    return {
        locations,
        loading,
        rfRegionValidationLoading,
        rfRegionResponse,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    clearRfRegionState: (): ValidateLocationRfRegionClearState => dispatch(validateLocationRfRegionClearState()),
    validateRfRegion: (countryCode: string, locationId?: string): ValidateLocationRfRegion =>
        dispatch(validateLocationRfRegion(countryCode, locationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PickExistingLocationComponent);
