import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { BoldText, colorStyles, ColoredDot } from 'commons/src/components/PDF/PdfConstants';
import { sensorUnits } from 'commons/src/constants';
import { PDFContext } from '../../../../models/common';

const componentStyles = StyleSheet.create({
    thresholdWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '3vw',
    },
    thresholdsContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '2.5vw',
        justifyContent: 'space-between',
        marginRight: '20vw',
    },
    numberOfDevicesText: {
        paddingTop: 6,
    },
});

type ThresholdProps = {
    headerValue: string;
    unit: keyof typeof sensorUnits;
    threshold: number;
    color: string;
    arrow: string;
    nextThreshold?: number;
    numberOfDevices: number;
    txt: PDFContext;
};

const ThresholdComponent = ({
    headerValue,
    threshold,
    unit,
    color,
    arrow,
    nextThreshold,
    numberOfDevices,
    txt,
}: ThresholdProps): React.ReactElement => (
    <View style={componentStyles.thresholdWrapper}>
        <ColoredDot style={colorStyles[color as keyof typeof colorStyles]} />
        <View>
            <BoldText style={colorStyles.smallText}>{headerValue}</BoldText>
            <Text style={colorStyles.smallText}>
                {arrow}
                {threshold}
                {sensorUnits[unit]}
                {nextThreshold && ['  ', '< ', nextThreshold, ' ', unit && sensorUnits[unit]]}
            </Text>
            <Text style={[componentStyles.numberOfDevicesText, colorStyles.smallText]}>
                {numberOfDevices} {numberOfDevices === 1 ? txt('DeviceLowerCase') : txt('DevicesLowerCase')}
            </Text>
        </View>
    </View>
);

type Props = {
    headerValues: string[];
    unit: keyof typeof sensorUnits;
    thresholds: number[];
    devicesInEachLevel: { good: number; fair: number; poor: number };
    txt: PDFContext;
};

const Thresholds = ({ headerValues, thresholds, devicesInEachLevel, unit, txt }: Props): React.ReactElement => (
    <View style={componentStyles.thresholdsContainer}>
        <ThresholdComponent
            headerValue={headerValues[0]}
            threshold={thresholds[0]}
            numberOfDevices={devicesInEachLevel.good}
            unit={unit}
            color="green"
            arrow="<"
            txt={txt}
        />
        <ThresholdComponent
            headerValue={headerValues[1]}
            threshold={thresholds[0]}
            nextThreshold={thresholds[1]}
            numberOfDevices={devicesInEachLevel.fair}
            unit={unit}
            color="yellow"
            arrow={'\u2265'}
            txt={txt}
        />
        <ThresholdComponent
            headerValue={headerValues[2]}
            threshold={thresholds[1]}
            numberOfDevices={devicesInEachLevel.poor}
            unit={unit}
            color="red"
            arrow={'\u2265'}
            txt={txt}
        />
    </View>
);

export default Thresholds;
