import moment from 'moment';
import { TokenResponse } from '../models/commonTypeScript';
import { updateStorage } from '../sagas/authentication/authService';
import { logoutAndClearStorage } from '../sagas/authentication/clearStorageAndRedirect';
import { refreshAccessToken } from './accountsApiTokens';

export type FetchHeadersType = { Authorization: string };

const returnAccountsTokens = (response: TokenResponse): HeadersInit => {
    const now = moment().unix();
    const expiresAt = now + response.expires_in;
    const responseObject = {
        accessToken: response.access_token,
        refreshToken: response.refresh_token,
        expiresAt,
    };
    updateStorage(responseObject, expiresAt);
    return { Authorization: responseObject.accessToken };
};

let refreshPromiseAccounts: Promise<TokenResponse>;

// eslint-disable-next-line no-promise-executor-return
const delay = async (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

const waitForTokenToBeSet = (): Promise<void> => {
    return new Promise<void>(resolve => {
        let i = 1;
        const waitForToken = (): void => {
            if (localStorage.getItem('accessToken')) {
                resolve();
            } else {
                delay(100).then(() => {
                    i += 1;
                    if (i < 20) {
                        return waitForToken();
                    }
                    return resolve();
                });
            }
        };
        // eslint-disable-next-line no-promise-executor-return
        return waitForToken();
    });
};

const getFetchHeaders = async (): Promise<HeadersInit> => {
    const refreshToken = localStorage.getItem('refreshToken');
    const accessToken = localStorage.getItem('accessToken');
    const expiresTimeFromLocalStorage = localStorage.getItem('expiresAt');
    const expiresAt = expiresTimeFromLocalStorage && parseInt(expiresTimeFromLocalStorage, 10);
    const now = moment().unix();

    const tokenExpiresInLessThan2Minutes = expiresAt && now >= expiresAt - 2 * 60;
    const expiredToken = !expiresAt || tokenExpiresInLessThan2Minutes;
    const waitingForCodeExchange = localStorage.getItem('fetchingTokens') === 'true';
    if (waitingForCodeExchange) {
        // on login code is exchanged for tokens, wait for tokens to be set in localStorage.
        const waitForToken = waitForTokenToBeSet();
        return waitForToken.then(() => {
            const newToken = localStorage.getItem('accessToken');
            if (newToken) return Promise.resolve({ Authorization: newToken as string });

            const errorObject = { errorMessage: 'Tokens not set' };
            // log out user if token not found in time.
            logoutAndClearStorage();
            return Promise.reject(errorObject);
        });
    }
    if (!refreshToken) {
        const errorObject = { errorMessage: 'User not logged in' };
        // log out user if refresh token is missing
        logoutAndClearStorage();
        return Promise.reject(errorObject);
    }
    if (accessToken && !expiredToken) {
        return Promise.resolve({ Authorization: accessToken });
    }
    if (refreshPromiseAccounts) {
        return refreshPromiseAccounts.then(response => ({ Authorization: response.json.access_token }));
    }
    refreshPromiseAccounts = refreshAccessToken(refreshToken);
    return refreshPromiseAccounts.then(response => {
        refreshPromiseAccounts = null;
        return returnAccountsTokens(response);
    });
};

export default getFetchHeaders;
