/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import * as Highcharts from 'highcharts';
import 'highcharts/modules/offline-exporting';
import 'highcharts/modules/export-data';
import moment, { Moment } from 'moment';
import { dateFormats, colors, tooltipBorderColor } from 'commons/src/constants';

type series = {
    name: string;
    linkedParent: {
        legendItem: {
            textStr: string;
            element: {
                innerHTML: string;
            };
        };
    };
    legendItem: {
        textStr: string;
        element: {
            innerHTML: string;
        };
    };
};

interface PointFormatterContext {
    points: { series: series; x: number; y: number }[];
}

const graphConfig = (
    outdoorData: number[][],
    indoorData: number[][] | undefined,
    timePeriod: { from: Moment; to?: Moment },
    outdoorGraphName: string,
    indoorGraphName: string,
    dateFormat: keyof typeof dateFormats,
    updateDate: (date: number) => void,
    averageValue: number,
    unit: string,
    plotBands: { color: string; from: number; to: number }[],
    sensorType: string,
    timezone: string
) => ({
    xAxis: {
        type: 'datetime',
        lineColor: 'transparent',
        tickLength: 0,
        tickPixelInterval: 130,
        margin: 0,
        gridLineWidth: 1,
        startOnTick: true,
        dateTimeLabelFormats: {
            day: dateFormats[dateFormat].axisDateWithDay,
        },
        tickInterval: 24 * 3600 * 1000,
        min: moment(timePeriod.from).valueOf(),
        max: timePeriod.to && moment(timePeriod.to).valueOf(),
        crosshair: {
            width: 2,
            color: 'black',
        },
        plotBands,
        labels: {
            formatter(this: { value: number }): string {
                return Highcharts.dateFormat(dateFormats[dateFormat].axisDate.week, this.value);
            },
        },
    },
    series: [
        {
            id: 'outdoorGraph',
            data: outdoorData,
            name: `${outdoorGraphName}`,
            color: colors.blueAzul,
        },
        {
            id: 'indoorGraph',
            data: indoorData,
            name: `${indoorGraphName}`,
            showInLegend: indoorData !== undefined,
            color: colors.graphGreen,
        },
    ],
    yAxis: {
        gridLineWidth: 0,
        title: false,
        tickPixelInterval: 40,
        plotLines: [
            {
                zIndex: 0,
                color: 'grey',
                dashStyle: 'Dash',
                value: averageValue,
                width: 1,
            },
        ],
    },
    credits: { enabled: false },
    title: { text: ' ' },
    chart: {
        id: sensorType,
        type: 'line',
        spacingBottom: 5,
        height: 280,
        style: {
            fontFamily: 'OpenSans-Regular',
        },
        scrollablePlotArea: {
            minWidth: 700,
            scrollPositionX: 0,
            minHeight: 700,
            opacity: 0,
        },
    },
    tooltip: {
        borderColor: tooltipBorderColor,
        style: {
            color: colors.white,
        },
        enabled: true,
        hideDelay: 0,
        shared: true,
        formatter(this: PointFormatterContext) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { x, points } = this;
            const content = points.map(pointIndex => {
                const value = { ...pointIndex };
                if (pointIndex.series.name === `${indoorGraphName}`) {
                    return `${value.y}${unit} (${indoorGraphName})`;
                }
                if (pointIndex.series.name === `${outdoorGraphName}`) {
                    return `${value.y}${unit} (${outdoorGraphName})`;
                }
                return '';
            });

            const utcOffset = moment.tz(timezone).utcOffset();
            updateDate(this.points[0].x + utcOffset * 60 * 1000);
            return [
                `${Highcharts.dateFormat(dateFormats[dateFormat].weekDay, x)}<br/>
                ${Highcharts.dateFormat(dateFormats[dateFormat].date, x)}
                ${Highcharts.dateFormat(dateFormats[dateFormat].hourTime, x)}<br/>`,
                ...content,
            ].join('<br/>');
        },
    },
    plotOptions: {
        series: {
            type: 'spline',
            showInLegend: true,
            lineWidth: 2,
            marker: {
                enabled: false,
                symbol: 'circle',
                states: {
                    hover: {
                        enabled: true,
                    },
                },
            },
            states: {
                hover: {
                    lineWidthPlus: 0,
                },
                inactive: {
                    opacity: 1,
                },
            },
        },
    },
    legend: {
        symbolRadius: 3,
        itemStyle: {
            fontWeight: 'normal',
        },
    },
    exporting: {
        sourceWidth: 1000,
        sourceHeight: 250,
        chartOptions: {
            subtitle: null,
        },
        buttons: {
            contextButton: {
                enabled: false,
            },
        },
    },
});
export type DoubleGraphConfig = ReturnType<typeof graphConfig>;
export default graphConfig;
