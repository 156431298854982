/* eslint max-len: ["error", 1000] */
import React from 'react';

const NotFoundIcon = (): React.ReactElement => (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 90 90" className="no-content-page__icon">
        <polygon id="Fill-1" className="st0" points="0,86.9 2.6,89.5 50.5,41.6 47.9,39 " />
        <polygon id="Fill-2" className="st0" points="34.4,29.4 49.1,29.4 49.1,25.7 34.4,25.7 " />
        <polygon id="Fill-3" className="st0" points="74.8,29.4 89.5,29.4 89.5,25.7 74.8,25.7 " />
        <polygon id="Fill-4" className="st0" points="60.1,55.1 63.7,55.1 63.7,40.4 60.1,40.4 " />
        <polygon id="Fill-5" className="st0" points="60.1,14.7 63.7,14.7 63.7,0 60.1,0 " />
        <polygon id="Fill-6" className="st0" points="72.3,19.8 82.7,9.4 80.1,6.8 69.7,17.2 " />
        <polygon id="Fill-7" className="st0" points="69.7,37.9 80.1,48.3 82.7,45.7 72.3,35.3 " />
        <polygon id="Fill-8" className="st0" points="41.1,9.4 51.5,19.8 54.1,17.2 43.7,6.8 " />
    </svg>
);
export default NotFoundIcon;
