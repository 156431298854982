import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import TimePeriodSelector from 'commons/src/components/buttons/TimePeriodSelector';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { SelectedPeriod } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../../reducers';
import { buildingSelector } from '../../../../reducers/selectors/buildingSelector';
import styles from './GraphActions.module.scss';

type Props = {
    fetchDigestForPeriod: (period: SelectedPeriod) => void;
    fetchDataForPeriod: (period: SelectedPeriod) => void;
    selectedPeriod: SelectedPeriod;
    setSelectedPeriod: (period: SelectedPeriod) => void;
    spaceId: string;
    locationId: string;
};
const SpaceGraphActions = ({
    fetchDataForPeriod,
    spaceId,
    fetchDigestForPeriod,
    setSelectedPeriod,
    selectedPeriod,
    locationId,
}: Props): ReactElement => {
    const spaceStartDate = useSelector((state: Store) => state.spaceData.space[spaceId]?.spaceStartDate);
    const { timezone } = useSelector((state: Store) => buildingSelector(state, locationId));
    const { dateFormat } = useSelector((state: Store) => state.userSettings);

    const isMobile = false;
    const loading = false;
    const selectGraphPeriod = (period: SelectedPeriod): void => {
        fetchDigestForPeriod(period);
        fetchDataForPeriod(period);
        setSelectedPeriod(period);
    };
    const onRefreshData = (): void => {
        fetchDataForPeriod(selectedPeriod);
    };
    return (
        <div className={styles.topBar}>
            <div className={styles.timeSelector}>
                <TimePeriodSelector
                    limitTimeSelector={false}
                    selectGraphPeriod={selectGraphPeriod}
                    selectedPeriod={selectedPeriod}
                    dateFormat={dateFormat}
                    isMobile={isMobile}
                    loading={loading}
                    endedSegment={false}
                    segmentStartDate={spaceStartDate}
                    timeZone={timezone}
                />
            </div>
            <TertiaryButton
                onClick={onRefreshData}
                title="Space.FetchLatestData"
                icon={<MaterialIcon name="refresh" />}
            />
        </div>
    );
};

export default SpaceGraphActions;
