import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import BatteryComponent from 'commons/src/components/sensors/BatteryComponent';
import RssiComponent from 'commons/src/components/sensors/RssiComponent';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import SpaceSensorValue from 'commons/src/components/sensors/SpaceSensorValue';
import { paths } from 'commons/src/constants';
import { IndoorSpace, SpaceDevice } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import { spacesSelector } from '../../spaces/space/spaceSelectors';
import styles from './FloorMapSensorValues.module.scss';

type Props = {
    onClose: () => void;
    selectedSpaceId: string;
    locationId: string;
};

const FloorMapSensorValuesComponent = ({ selectedSpaceId, locationId, onClose }: Props): ReactElement => {
    const [activeDeviceIndex, setActiveDevice] = useState<number>(0);
    const navigate = useNavigate();
    const { t: txt } = useTranslation();

    const {
        spaces: { spaces },
    } = useSelector((store: Store) => spacesSelector(store, locationId));

    const goToSpace = (): void => {
        navigate(`/${paths.buildings}/${locationId}/spaces/${selectedSpaceId}`);
    };

    const selectedSpace = spaces.find(it => it.id === selectedSpaceId) as IndoorSpace | undefined;
    if (!selectedSpace) {
        return <div />;
    }

    const renderDevice = (): ReactElement => {
        const device: SpaceDevice = selectedSpace.devices[activeDeviceIndex];
        if (!device) {
            return (
                <div className={styles.devices}>
                    <div className={styles.noDeviceInSpace}>{txt('Spaces.NoDevices')}</div>
                </div>
            );
        }
        return (
            <div className={styles.devices} key={device.serialNumber}>
                <div className={styles.deviceInfo}>
                    <div className={styles.serialNumber}>{device.serialNumber}</div>
                    <div className={styles.metaData}>
                        <RssiComponent rssi={device.rssi} healthStatus={device.healthStatus} />
                        <BatteryComponent
                            batteryPercentage={device.batteryPercentage}
                            healthStatus={device.healthStatus}
                            noBatteryText
                        />
                    </div>
                </div>
                <div className={styles.sensors}>
                    {device.currentSensorValues.slice(0, 5).map(cv => (
                        <div className={styles.sensor} key={cv.type}>
                            <div className={styles.sensorIcon}>
                                <SensorIcon sensor={cv.type} />
                            </div>
                            <SpaceSensorValue
                                value={cv.value}
                                unit={cv.unit}
                                type={cv.type}
                                thresholds={cv.thresholds}
                                showSensorType={false}
                                key={cv.type}
                                healthStatus={device.healthStatus}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className={styles.body}>
            <div className={styles.header}>
                <div className={styles.headerTitle}>{selectedSpace.name}</div>
                <TertiaryButton onClick={onClose} noColor icon={<MaterialIcon name="close" />} />
            </div>
            <div className={styles.content}>
                {renderDevice()}
                <div className={styles.deviceNavigation}>
                    {selectedSpace.devices.length > 1 &&
                        selectedSpace.devices.map((device, index) => (
                            <TertiaryButton
                                onClick={(): void => setActiveDevice(index)}
                                key={device.serialNumber}
                                trailingIcon={
                                    <div
                                        className={
                                            index === activeDeviceIndex ? styles.navIconActive : styles.navIconInactive
                                        }
                                    />
                                }
                            />
                        ))}
                </div>
                <div className={styles.toSpaceButton}>
                    <TertiaryButton
                        onClick={goToSpace}
                        title="Spaces.GoToSpace"
                        size="medium"
                        trailingIcon={<MaterialIcon name="arrow_forward" />}
                    />
                </div>
            </div>
        </div>
    );
};

export default FloorMapSensorValuesComponent;
