import { roleRestrictions } from 'commons/src/constants';
import { FeatureToggleEnum, GroupType } from 'commons/src/models/commonEnums';
import { TabOption } from 'commons/src/models/menuModels';
import { integrationsMqtt, Path, paths } from '../../constants';

const IntegrationsSubMenus: TabOption[] = [
    {
        text: 'Integrations.Overview',
        route: paths.integrations as Path,
        path: `/${paths.integrations}`,
        id: 'integrationsOverview',
        testAttr: 'integrations-overview',
        requiredRoleLevel: roleRestrictions.thirdPartyIntegration,
        requiredGroupTypes: [],
    },
    {
        text: 'Webhooks.Webhooks',
        route: paths.webhooks as Path,
        path: `/${paths.webhooks}`,
        id: 'integrationsWebhooks',
        testAttr: 'integrations-webhooks',
        requiredRoleLevel: roleRestrictions.webhooks,
        excludedFeatureToggles: [FeatureToggleEnum.oldPartnerView],
        requiredGroupTypes: [GroupType.business],
    },
    {
        text: 'ApiIntegration.ApiIntegration',
        route: paths.apiIntegration as Path,
        path: `/${paths.apiIntegration}`,
        id: 'integrationsApi',
        testAttr: 'integrations-api',
        requiredRoleLevel: roleRestrictions.api,
        requiredGroupTypes: [],
    },
    {
        text: 'Mqtt.Mqtt',
        route: paths.mqtt as Path,
        path: `/${paths.mqtt}`,
        id: integrationsMqtt,
        testAttr: 'integrations-mqtt',
        requiredRoleLevel: roleRestrictions.mqtt,
        excludedFeatureToggles: [FeatureToggleEnum.oldPartnerView],
        requiredGroupTypes: [GroupType.business],
    },
    {
        text: 'ThirdParty.ThirdParty',
        route: paths.thirdParty as Path,
        path: `/${paths.thirdParty}`,
        id: 'integrationsThirdParty',
        testAttr: 'integrations-third-party',
        requiredRoleLevel: roleRestrictions.thirdPartyIntegration,
        excludedFeatureToggles: [FeatureToggleEnum.oldPartnerView],
        requiredGroupTypes: [GroupType.business],
    },
];

export default IntegrationsSubMenus;
