import React from 'react';
import { TableCell, TableSortLabel, TableHead, TableRow } from '@mui/material';
import useMaterialTableContext from './hooks/useMaterialTableContext';

const MobileMaterialTableHeader = (): React.ReactElement => {
    const { columns, getSelectedColumn, onHeaderClick } = useMaterialTableContext();
    const header = columns[0];
    const order = header.sortable ? getSelectedColumn(header.accessor) : false;

    return (
        <TableHead>
            <TableRow>
                <TableCell key={header.accessor} align={header.align} sortDirection={order} variant="head">
                    <TableSortLabel
                        active={Boolean(order)}
                        direction={order || undefined}
                        onClick={onHeaderClick({ accessor: header.accessor, sortable: header.sortable })}
                        disabled={!header.sortable}
                        hideSortIcon={Boolean(header.infoBox)}
                    >
                        {header.label}
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

export default MobileMaterialTableHeader;
