/* eslint max-len: ["error", 1000] */
import React from 'react';

const ForecastFog = (): React.ReactElement => (
    <svg
        version="1.1"
        className="weather-icon"
        width="30px"
        height="30px"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
    >
        <path d="M28.7,17.8H1.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h27.3c0.7,0,1.3-0.6,1.3-1.3S29.4,17.8,28.7,17.8z" />
        <path d="M26.1,21.8H3.9c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h22.2c0.7,0,1.3-0.6,1.3-1.3S26.8,21.8,26.1,21.8z" />
        <path d="M23.5,16.4c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3h-17c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3H23.5z" />
        <path
            d="M30,13.7c0-3.9-3.1-7-7-7h-0.9c-0.5-1.7-1.5-3.3-2.9-4.5C17.6,0.9,15.4,0,13.2,0C8.7,0,4.8,3.2,4,7.5
            C1.6,8.7,0,11,0,13.8c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-1.9,1.2-3.5,3-4.2l0.8-0.3l0.1-0.8c0.4-3.4,3.3-6,6.8-6
            c3.3,0,6.2,2.5,6.8,5.8l0.2,1.1H23c2.5,0,4.4,1.9,4.4,4.4c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3C30,13.8,30,13.8,30,13.7 C30,13.8,30,13.7,30,13.7z"
        />
    </svg>
);
export default ForecastFog;
