import { Initialize, INITIALIZE } from '../actions';
import {
    ADD_PUBLIC_DASHBOARD_SUCCESS,
    DashboardReducerActions,
    EDIT_PUBLIC_DASHBOARD_SETTINGS_SUCCESS,
    FETCH_DASHBOARD,
    FETCH_DASHBOARD_DEVICE_TILE,
    FETCH_DASHBOARD_FAIL,
    FETCH_DASHBOARD_SENSOR,
    FETCH_DASHBOARD_SUCCESS,
    SELECT_DASHBOARD_SUCCESS,
    UPDATE_DASHBOARD_LAYOUT,
    UPDATE_DASHBOARD_LAYOUT_FAILURE,
    UPDATE_DASHBOARD_LAYOUT_SUCCESS,
} from '../actions/DashboardActions';
import { LOGOUT_SUCCESS, LogOutSuccess } from '../actions/LoginAndRegisterActions';
import { DashboardVisibility } from '../models/commonEnums';
import { DashboardProps, DashboardTile } from '../models/commonTypeScript';

export type DashboardData = {
    error: boolean;
    loading: boolean;
    oldDashboardTileSetup: DashboardTile[];
    dashboardTiles: DashboardTile[];
    statusMessage: string;
    newModuleLoading: boolean;
    updateDashboardError: boolean;
    dashboardProps?: DashboardProps;
    dashboardList: { name: string; id: string }[];
};

const initialState = {
    error: false,
    loading: false,
    oldDashboardTileSetup: [],
    dashboardTiles: [],
    statusMessage: '',
    newModuleLoading: false,
    updateDashboardError: false,
    dashboardList: [],
    dashboardProps: undefined,
};

const getDashboardProps = (payload: {
    id: string;
    name: string;
    visibility: DashboardVisibility;
    publicUrl?: string;
}): DashboardProps => ({
    id: payload.id,
    name: payload.name,
    visibility: payload.visibility,
    publicUrl: payload.publicUrl,
});

const getDashboardPropsFromList = (id: string, dashboardList?: DashboardProps[]): DashboardProps | undefined =>
    dashboardList && dashboardList.find(props => props.id === id);

export default (state = initialState, action: DashboardReducerActions | Initialize | LogOutSuccess): DashboardData => {
    switch (action.type) {
        case INITIALIZE:
            return {
                ...state,
                loading: false,
            };
        case FETCH_DASHBOARD:
            return {
                ...state,
                loading: true,
            };
        case FETCH_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                dashboardTiles: action.dashboardData.tiles,
                dashboardProps: getDashboardProps(action.dashboardData),
                dashboardList: action.dashboards,
            };
        case FETCH_DASHBOARD_FAIL:
            return {
                ...state,
                error: true,
                loading: false,
                statusMessage: action.statusMessage,
            };
        case FETCH_DASHBOARD_DEVICE_TILE:
        case FETCH_DASHBOARD_SENSOR:
            return {
                ...state,
                newModuleLoading: true,
            };
        case UPDATE_DASHBOARD_LAYOUT:
            return {
                ...state,
                oldDashboardTileSetup: state.dashboardTiles,
                dashboardTiles: action.config.tiles,
                error: false,
                newModuleLoading: true,
                updateDashboardError: false,
                statusMessage: '',
            };
        case UPDATE_DASHBOARD_LAYOUT_SUCCESS:
            return {
                ...state,
                error: false,
                statusMessage: action.statusMessage,
                newModuleLoading: false,
                updateDashboardError: false,
            };
        case UPDATE_DASHBOARD_LAYOUT_FAILURE:
            return {
                ...state,
                dashboardTiles: state.oldDashboardTileSetup,
                statusMessage: action.statusMessage,
                updateDashboardError: true,
                newModuleLoading: false,
            };
        case ADD_PUBLIC_DASHBOARD_SUCCESS:
            return {
                ...state,
                oldDashboardTileSetup: [],
                dashboardTiles: action.dashboardData.tiles,
                dashboardProps: getDashboardProps(action.dashboardData),
                dashboardList: [...state.dashboardList, getDashboardProps(action.dashboardData)],
            };
        case SELECT_DASHBOARD_SUCCESS:
            return {
                ...state,
                error: false,
                oldDashboardTileSetup: [],
                dashboardTiles: action.dashboardData.tiles,
                dashboardProps: getDashboardPropsFromList(action.id, state.dashboardList),
            };
        case EDIT_PUBLIC_DASHBOARD_SETTINGS_SUCCESS:
            return {
                ...state,
                dashboardProps:
                    state.dashboardProps && state.dashboardProps?.id === action.payload.id
                        ? action.payload
                        : state.dashboardProps,
            };
        case LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
};
