import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { OrganizationInviteType, Group } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import HbsWelcome from './HbsWelcome';
import JoinOrganizationInvite from './JoinOrganizationInvite';
import UpdateSettings from './UpdateSettings';

type Props = {
    acceptedInviteId?: string;
    invites: OrganizationInviteType[];
    groups: Group[];
    inviteModalOpen: boolean;
    invitesFetched: boolean;
};

export const HBSOnboardingModalComponent = (props: Props): React.ReactElement => {
    const { acceptedInviteId, invites, groups, inviteModalOpen, invitesFetched } = props;
    const [settingsUpdated, setSettingsUpdated] = useState(false);

    useEffect((): void => {
        if (invitesFetched && invites && invites.length === 0 && !acceptedInviteId) {
            setSettingsUpdated(true);
        }
    }, [acceptedInviteId, invites, invitesFetched]);

    const acceptedInvite = invites && invites.length > 0 && invites.find(invite => invite.id === acceptedInviteId);
    const userGroup = acceptedInvite ? groups.find(group => group.id === acceptedInvite.userGroupId) : undefined;

    const modalContent = (): React.ReactElement => {
        if (invites && invites.length > 0 && !acceptedInviteId) return <JoinOrganizationInvite />;
        if (!settingsUpdated) return <UpdateSettings settingsUpdated={(): void => setSettingsUpdated(true)} />;

        return <HbsWelcome acceptedInviteGroup={userGroup} />;
    };
    return (
        <Modal
            isOpen={inviteModalOpen}
            appElement={document.body}
            className="modal__content modal__content--hbs-on-boarding"
            overlayClassName="modal modal__overlay"
        >
            {modalContent()}
        </Modal>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        organizationInvites: { acceptedInviteId, invites, inviteModalOpen, invitesFetched },
        userSettings: { groups },
    } = state;

    return {
        acceptedInviteId,
        groups,
        invites,
        inviteModalOpen,
        invitesFetched,
    };
};

export default connect(mapStateToProps)(HBSOnboardingModalComponent);
