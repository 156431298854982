import { RequestActionType } from 'commons/src/actions/requestActions';
import { SelectedPeriod } from 'commons/src/models/commonTypeScript';
import { BusinessActionRequestType } from '../models/common';
import { OutdoorSensorData } from '../models/outdoorDataModels';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum OutdoorDataActions {
    FetchOutdoorDataInit = 'FETCH_OUTDOOR_DATA/INIT',
    FetchOutdoorDataSuccess = 'FETCH_OUTDOOR_DATA_SUCCESS',
}

export interface FetchOutdoorData extends BusinessActionRequestType {
    type: OutdoorDataActions.FetchOutdoorDataInit;
    locationId: string;
    selectedInterval: SelectedPeriod;
}

export const fetchOutdoorData = (locationId: string, selectedInterval: SelectedPeriod): FetchOutdoorData => ({
    type: OutdoorDataActions.FetchOutdoorDataInit,
    requestType: RequestType.FetchOutdoorData,
    requestActionType: RequestActionType.Init,
    locationId,
    selectedInterval,
});

export interface FetchOutdoorDataSuccess {
    type: OutdoorDataActions.FetchOutdoorDataSuccess;
    data: OutdoorSensorData[];
    fetchedPeriod: string;
    locationId: string;
}

export const fetchOutdoorDataSuccess = (
    data: OutdoorSensorData[],
    fetchedPeriod: string,
    locationId: string
): FetchOutdoorDataSuccess => ({
    type: OutdoorDataActions.FetchOutdoorDataSuccess,
    data,
    fetchedPeriod,
    locationId,
});

export type OutdoorDataAction = FetchOutdoorDataSuccess;
