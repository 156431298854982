import { all, call, put, takeEvery, CallEffect, PutEffect } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import { SegmentProperties } from 'commons/src/models/commonTypeScript';
import RequestActions from 'commons/src/models/RequestTypes';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import {
    FetchGeneratedSegmentPublicUrlError,
    fetchGeneratedSegmentPublicUrlError,
    fetchGeneratedSegmentPublicUrlInit,
    FetchGeneratedSegmentPublicUrlInit,
    FetchGeneratedSegmentPublicUrlSuccess,
    fetchGeneratedSegmentPublicUrlSuccess,
    FetchSegmentLabels,
    FetchSegmentLabelsSuccess,
    fetchSegmentLabelsSuccess,
    fetchSegmentPropertiesError,
    FetchSegmentProperties,
    FetchSegmentPropertiesSuccess,
    fetchSegmentPropertiesSuccess,
    SegmentPropertiesActionType,
    SetDevicePubliclyAvailable,
    setDevicePubliclyAvailableSuccess,
    SetSegmentLabels,
    updateSegmentLabelsSuccess,
    updateSegmentPropertiesError,
    SetSegmentProperties,
    updateSegmentPropertiesSuccess,
    FetchSegmentPropsFiltersSuccess,
    FetchSegmentPropsFilters,
    fetchSegmentPropsFiltersSuccess,
} from '../actions/segmentPropertiesActions';
import {
    fetchGeneratedSegmentPublicUrl,
    fetchSegmentLabels,
    fetchSegmentProperties,
    getSegmentPropFilters,
    patchSegmentProperties,
    updateSegmentLabels,
    updateSegmentProperties,
} from '../api/segmentPropertiesApi';
import { KeyValuePairType, LabelsPayload } from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export const createKeyValuePairs = (labels: LabelsPayload): { key: string; value: string }[] =>
    Object.keys(labels).map(key => ({ key, value: labels[key] }));

export const createKeyValueMap = (labels: KeyValuePairType[]): { labels: { [label: string]: string } } => {
    const labelsObject: LabelsPayload = {};
    labels.forEach(label => {
        labelsObject[label.key] = label.value;
    });
    return { labels: labelsObject };
};

export const removeEmptyKeyValuePair = (pairs: KeyValuePairType[]): KeyValuePairType[] => {
    let lastPairIsValid = false;
    if (pairs.length > 0) {
        const lastPair = pairs[pairs.length - 1];
        lastPairIsValid = lastPair.key.length > 0;
    }
    if (lastPairIsValid) return pairs;
    const pairsCopy = [...pairs];
    pairsCopy.pop();
    return pairsCopy;
};

function* updateSegmentPropertiesSaga(action: SetSegmentProperties): Generator {
    try {
        yield call(updateSegmentProperties, action.serialNumber, 'latest', action.segmentProperties);
        yield put(updateSegmentPropertiesSuccess(action.segmentProperties, action.serialNumber));
        yield put(requestSuccess(RequestType.UpdateSegmentProperties, RequestActionType.Success));
    } catch (e) {
        const errorAsErrorType = toErrorType(e);
        yield put(updateSegmentPropertiesError(errorAsErrorType));
    }
}

function* setPubliclyAvailableSaga(action: SetDevicePubliclyAvailable): Generator {
    try {
        yield call(patchSegmentProperties, action.serialNumber, 'latest', {
            publiclyAvailable: action.publiclyAvailable,
        });
        if (!action.hasPublicUrl) {
            yield put(fetchGeneratedSegmentPublicUrlInit({ serialNumber: action.serialNumber }));
        }
        yield put(setDevicePubliclyAvailableSuccess(action.publiclyAvailable, action.serialNumber));
        yield put(requestSuccess(RequestType.SetDevicePubliclyAvailable, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.SetDevicePubliclyAvailable, RequestActionType.Error));
    }
}

type FetchSegmentPropertiesSagaReturnType = Generator<
    CallEffect<SegmentProperties> | PutEffect<FetchSegmentPropertiesSuccess> | RequestActions,
    void,
    SegmentProperties
>;

function* fetchSegmentPropertiesSaga(action: FetchSegmentProperties): FetchSegmentPropertiesSagaReturnType {
    try {
        const segmentProperties: SegmentProperties = yield call(fetchSegmentProperties, action.serialNumber, 'latest');
        yield put(fetchSegmentPropertiesSuccess(segmentProperties, action.serialNumber));
    } catch (e) {
        const errorAsErrorType = toErrorType(e);
        yield put(fetchSegmentPropertiesError(errorAsErrorType));
    }
}

type FetchSegmentLabelsSagaReturnType = Generator<
    CallEffect<{ labels: LabelsPayload }> | PutEffect<FetchSegmentLabelsSuccess> | RequestActions,
    void,
    { labels: LabelsPayload }
>;

export function* fetchSegmentLabelsSaga({ serialNumber }: FetchSegmentLabels): FetchSegmentLabelsSagaReturnType {
    try {
        const response: { labels: LabelsPayload } = yield call(fetchSegmentLabels, serialNumber);
        yield put(fetchSegmentLabelsSuccess(serialNumber, createKeyValuePairs(response.labels)));
        yield put(requestSuccess(RequestType.FetchSegmentLabels, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchSegmentLabels, RequestActionType.Error));
    }
}

export function* updateSegmentLabelsSaga(action: SetSegmentLabels): Generator {
    const keyValuePairs = removeEmptyKeyValuePair(action.segmentLabels);
    try {
        yield call(updateSegmentLabels, action.serialNumber, createKeyValueMap(keyValuePairs));
        yield put(updateSegmentLabelsSuccess(keyValuePairs, action.serialNumber));
        yield put(requestSuccess(RequestType.UpdateSegmentLabels, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.UpdateSegmentLabels, RequestActionType.Error));
    }
}

type FetchGeneratedSegmentPublicUrlSagaReturnType = Generator<
    | CallEffect<{ urlPath: string }>
    | PutEffect<FetchGeneratedSegmentPublicUrlSuccess>
    | PutEffect<FetchGeneratedSegmentPublicUrlError>
    | RequestActions,
    void,
    { urlPath: string }
>;

function* fetchGeneratedSegmentPublicUrlSaga({
    payload,
}: FetchGeneratedSegmentPublicUrlInit): FetchGeneratedSegmentPublicUrlSagaReturnType {
    try {
        const { urlPath } = yield call(fetchGeneratedSegmentPublicUrl, payload);
        yield put(
            fetchGeneratedSegmentPublicUrlSuccess({ serialNumber: payload.serialNumber, publicUrlPath: urlPath })
        );
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(fetchGeneratedSegmentPublicUrlError(errorAsErrorType));
    }
}

type FetchSegmentPropertyFilterSagaReturnType = Generator<
    | CallEffect<{ [filterType: string]: { name: string; devices: string[] }[] }>
    | PutEffect<FetchSegmentPropsFiltersSuccess>
    | RequestActions,
    void,
    { [filterType: string]: { name: string; devices: string[] }[] }
>;

function* fetchSegmentPropertyFilterSaga({
    locationId,
}: FetchSegmentPropsFilters): FetchSegmentPropertyFilterSagaReturnType {
    try {
        const response = yield call(getSegmentPropFilters, locationId);
        yield put(fetchSegmentPropsFiltersSuccess(locationId, response));
        yield put(requestSuccess(RequestType.FetchSegmentPropertyFilters, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchSegmentPropertyFilters, RequestActionType.Error));
    }
}

export default function* segmentPropertiesSagas(): Generator {
    yield all([
        takeEvery(SegmentPropertiesActionType.FetchSegmentPropertiesInit, fetchSegmentPropertiesSaga),
        takeEvery(SegmentPropertiesActionType.FetchSegmentLabelsInit, fetchSegmentLabelsSaga),
        takeEvery(SegmentPropertiesActionType.FetchGeneratedSegmentPublicUrlInit, fetchGeneratedSegmentPublicUrlSaga),
        takeEvery(SegmentPropertiesActionType.UpdateSegmentPropertiesInit, updateSegmentPropertiesSaga),
        takeEvery(SegmentPropertiesActionType.UpdateSegmentLabelsInit, updateSegmentLabelsSaga),
        takeEvery(SegmentPropertiesActionType.SetDevicePubliclyAvailableInit, setPubliclyAvailableSaga),
        takeEvery(SegmentPropertiesActionType.FetchSegmentPropertyFiltersInit, fetchSegmentPropertyFilterSaga),
    ]);
}
