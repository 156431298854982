import React from 'react';
import styles from './Firmware.module.scss';

type FirmwareProps = {
    firmware: object;
};

export const formatFirmware = (firmware: object): string[] => {
    return Object.entries(firmware)
        .map(([key, value]) => {
            if (!value) return null;
            return `${key}: ${value}`;
        })
        .filter(Boolean) as string[];
};

const Firmware = ({ firmware }: FirmwareProps): React.ReactElement => {
    if (!firmware) return <div />;
    const formattedFirmware = formatFirmware(firmware);
    return (
        <div className={styles.firmwareWrapper}>
            {formattedFirmware.map(version => (
                <span key={version} className={styles.version}>
                    {version}
                </span>
            ))}
        </div>
    );
};

export default Firmware;
