import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateSerialnumber } from 'commons/src/actions/AddDeviceActions';
import { getDeviceTypeFromPrefix, isTestDevice } from 'commons/src/commonFunctions';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { Store } from '../../../../reducers';
import { UseDeviceValidateProps, UseDeviceValidateValues } from './models';

export const addDeviceTypes: DeviceTypeNames[] = [
    DeviceTypeNames.spaceCo2Mini,
    DeviceTypeNames.viewCo2,
    DeviceTypeNames.viewPlusBusiness,
    DeviceTypeNames.waveNano,
    DeviceTypeNames.wavePlus,
    DeviceTypeNames.mini,
    DeviceTypeNames.hub,
];

export const isSerialNumberPrefixValid = (serialNumber: string): boolean => {
    const devicePrefix: string = serialNumber.substring(0, 4);
    const deviceType: string | undefined = getDeviceTypeFromPrefix(devicePrefix);
    return addDeviceTypes.includes(deviceType as DeviceTypeNames);
};

const isSerialNumberValid = (serialNumber: string): boolean => {
    if (serialNumber.length !== 10) return false;

    const isPrefixValid: boolean = isSerialNumberPrefixValid(serialNumber);
    const isSerialNumberTesting: boolean = isTestDevice(serialNumber);
    const isSerialNumberNumeric = !Number.isNaN(Number(serialNumber));
    return isSerialNumberTesting || (isSerialNumberNumeric && isPrefixValid);
};

const useValidateDevice = ({ serialNumber, checkCode }: UseDeviceValidateProps): UseDeviceValidateValues => {
    const dispatch = useDispatch();
    const [isVerificationInProgress, setIsVerificationInProgress] = useState(false);
    const [isDeviceVerified, setIsDeviceVerified] = useState(false);

    const verification = useSelector((state: Store) => state.verifySerialNumber);

    const deviceType: DeviceTypeNames | undefined = getDeviceTypeFromPrefix(serialNumber) as
        | DeviceTypeNames
        | undefined;
    const isDeviceWithShortHexCode: boolean =
        deviceType === DeviceTypeNames.spaceCo2Mini || deviceType === DeviceTypeNames.waveNano;

    const isCheckCodeLengthCorrect = (enteredCheckCode?: string): boolean =>
        isDeviceWithShortHexCode ? enteredCheckCode?.length === 6 : enteredCheckCode?.length === 7;

    const serialNumberHint = serialNumber.length ? 'Space.SerialNumberInvalid' : 'Space.SerialNumberMissing';
    const checkCodeHint = checkCode.length ? 'Space.CheckChodeInvalid' : 'Space.CheckCodeMissing';

    useEffect(() => {
        // Device is no longer valid if dependencies change
        setIsDeviceVerified(false);
        setIsVerificationInProgress(false);
    }, [serialNumber, checkCode]);

    useEffect(() => {
        if (!verification.loading) {
            setIsVerificationInProgress(false);
            setIsDeviceVerified(verification.serialNumberValid);
        }
    }, [verification]);

    const verifyDevice = (): void => {
        // Once user enter all information in valid format - fetch request checking validity on backend
        // Skip if device is already verified, verification in progress or data incomplete
        if (
            !isDeviceVerified &&
            !isVerificationInProgress &&
            isSerialNumberValid(serialNumber) &&
            isCheckCodeLengthCorrect(checkCode)
        ) {
            setIsVerificationInProgress(true);
            setIsDeviceVerified(false);
            dispatch(validateSerialnumber({ serialNumber, checkCode }));
        }
    };

    return {
        isDeviceVerified,
        isVerificationInProgress,
        error: verification.error,
        verifyDevice,
        isSerialNumberValid: () => isSerialNumberValid(serialNumber),
        isCheckCodeValid: () => isCheckCodeLengthCorrect(checkCode),
        serialNumberHint,
        checkCodeHint,
    };
};

export default useValidateDevice;
