import React from 'react';
import * as L from 'leaflet';
import { LatLngExpression } from 'leaflet';
import { Marker, Polygon } from 'react-leaflet';
import { colors, sensorGraphColors, statusColors } from 'commons/src/constants';
import {
    AnyDeviceType,
    CurrentSensorValuesType,
    DeviceType,
    FloorPlanType,
    FloorplanZone,
} from 'commons/src/models/commonTypeScript';
import { floorPlanZoneHasDevice } from '../../../reducers/Floorplans';
import DeviceMarkerIcons from './DeviceMarkerIcons';
import { MODES } from './FloorPlanConstants';

type Props = {
    floor: FloorPlanType;
    mode: MODES;
    selectedZone: FloorplanZone | undefined;
    selectZone: (zone: FloorplanZone, deviceType: AnyDeviceType | undefined) => void;
    resetMapView: () => void;
    selectedSensor: string | undefined;
    devices: { [serialnumber: string]: DeviceType };
};

const FloorPlanZones = ({
    floor,
    selectZone,
    selectedZone,
    resetMapView,
    selectedSensor,
    devices,
    mode,
}: Props): React.ReactElement => {
    const calculateZoneColor = (serialNumber: string): string => {
        const device = devices[serialNumber];
        if (device === undefined) return statusColors.grey;
        const sensorColor = (currentSensorValue: CurrentSensorValuesType): string => {
            const { thresholds, value, type } = currentSensorValue;

            if (thresholds.length === 0) return statusColors.green;
            if (value === undefined) return statusColors.grey;

            const colorIndex = thresholds.findIndex((threshold: number) => threshold > value);
            if (colorIndex < 0) return statusColors.red;

            const colorCode = sensorGraphColors[`${colorIndex}${type}` as keyof typeof sensorGraphColors];
            if (colorCode === colors.graphGreen) return statusColors.green;
            if (colorCode === colors.graphYellow) return statusColors.yellow;
            return statusColors.red;
        };

        if (!selectedSensor) {
            const sensorColors = device.currentSensorValues.map((currentValue: CurrentSensorValuesType) =>
                sensorColor(currentValue)
            );
            if (sensorColors.indexOf(statusColors.red) > -1) return statusColors.red;
            if (sensorColors.indexOf(statusColors.yellow) > -1) return statusColors.yellow;
            if (sensorColors.indexOf(statusColors.green) > -1) return statusColors.green;
            return statusColors.grey;
        }

        const sensor = device.currentSensorValues.find(
            (currentValue: CurrentSensorValuesType) => currentValue.type === selectedSensor
        );
        return sensor === undefined ? statusColors.grey : sensorColor(sensor);
    };

    const polygonSensorValue = (sensor: CurrentSensorValuesType | undefined): string | null => {
        if (sensor === undefined || sensor.value === undefined || Number.isNaN(sensor.value)) return null;
        return sensor.value.toString();
    };

    const zones = floor.zones
        .filter<Required<FloorplanZone>>(floorPlanZoneHasDevice)
        .reduce((features: React.ReactElement[], floorplanZone) => {
            const zonePositions = floorplanZone.boundary.map((points): LatLngExpression => [points.lat, points.lng]);
            if (zonePositions.length === 0) {
                // only return device with zone - not markers
                return features;
            }

            const zoneIsSelected = selectedZone && selectedZone.id === floorplanZone.id;

            const onClick = (): void => {
                if (zoneIsSelected) resetMapView();
                else selectZone(floorplanZone, undefined);
            };

            const zoneColor = calculateZoneColor(floorplanZone.device.serialNumber);
            const markerPosition: LatLngExpression = [
                floorplanZone.device.position.lat,
                floorplanZone.device.position.lng,
            ];

            const zoneAreaColor = zoneColor === statusColors.yellow ? colors.yellowGlow : zoneColor;

            const device: DeviceType = devices[floorplanZone.device.serialNumber];
            const deviceSensor = device && device.currentSensorValues.find(sensor => sensor.type === selectedSensor);

            let icon: L.DivIcon = DeviceMarkerIcons.Default;
            if (selectedSensor) {
                const sensorValue = polygonSensorValue(deviceSensor);
                if (sensorValue) icon = DeviceMarkerIcons.SensorValueIcon(sensorValue, zoneColor);
            }

            let lineWeight = 1;
            if (zoneIsSelected) lineWeight = 3;

            const polygon = (
                <Polygon
                    key={`${floorplanZone.id}:polygon`}
                    positions={zonePositions}
                    color={zoneIsSelected ? zoneAreaColor : 'black'}
                    opacity={0.5}
                    weight={lineWeight}
                    fillColor={zoneAreaColor}
                    eventHandlers={{ click: onClick }}
                >
                    {!(zoneIsSelected && mode === MODES.EDIT) && (
                        <Marker
                            key={`${floorplanZone.id}:marker`}
                            position={markerPosition}
                            icon={icon}
                            eventHandlers={{ click: onClick }}
                        />
                    )}
                </Polygon>
            );
            return [...features, polygon];
        }, []);

    return <div>{zones}</div>;
};

export default FloorPlanZones;
