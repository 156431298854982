import { takeEvery, call, put, all, CallEffect, PutEffect } from 'redux-saga/effects';
import {
    GenerateRadonHomeReport,
    generateRadonHomeReportSuccess,
    generateRadonHomeReportError,
    HomeReportActionType,
} from '../actions/HomeReportActions';
import { generateHomeReport as generateHomeReportV2 } from '../api/generateHomeReport';

import { HomeReportResponse } from '../models/commonTypeScript';

export function* generateHomeRadonReportV2Saga({
    payload,
}: GenerateRadonHomeReport): Generator<CallEffect<HomeReportResponse> | PutEffect, void, HomeReportResponse> {
    try {
        const response = yield call(generateHomeReportV2, payload);
        yield put(generateRadonHomeReportSuccess(response));
    } catch (error) {
        yield put(generateRadonHomeReportError(error));
    }
}

export default function* homeReportSagas(): Generator {
    yield all([takeEvery(HomeReportActionType.GenerateRadonHomeReportInit, generateHomeRadonReportV2Saga)]);
}
