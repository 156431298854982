import React, { useState, ChangeEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import InputLabel from './InputLabel';

type Props = {
    label: string;
    id: string;
    required: boolean;
    hint?: string;
    displayValidationResult: boolean;
    isValid: boolean;
    onChange: (text: string) => void;
    onBlur?: () => void;
    autoComplete?: string;
    defaultValue?: string;
    maxLength: number;
    markedMandatory: boolean;
    testAttr?: string;
    rows: number;
    maxRows: number;
    inputInfo?: string;
    testId?: string;
};

const Textarea = (props: Props): React.ReactElement => {
    const {
        label,
        rows,
        id,
        required,
        hint,
        displayValidationResult,
        isValid = false,
        autoComplete,
        maxLength,
        markedMandatory,
        testAttr,
        defaultValue,
        maxRows,
        onChange,
        onBlur,
        inputInfo,
        testId,
    } = props;
    const { t: txt } = useTranslation();
    const [value, setValue] = useState(defaultValue || '');

    const onUpdate = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        const text = e.currentTarget.value;
        const numberOfLines = (text.match(/\n/g) || []).length + 1;
        if (numberOfLines <= maxRows) {
            setValue(text);
            onChange(text);
        }
        if (e.type === 'blur' && onBlur) {
            onBlur();
        }
    };
    const testAttribute = testAttr || 'data';
    return (
        <div className="input-container">
            <InputLabel htmlFor={id} label={label} mandatory={markedMandatory} infoText={inputInfo} />
            <textarea
                className={classNames('form__attr form__attr--textarea', {
                    'form__attr--invalid': !isValid && displayValidationResult,
                })}
                id={id}
                rows={rows}
                required={required}
                onChange={onUpdate}
                onBlur={onUpdate}
                value={value}
                autoComplete={autoComplete}
                maxLength={maxLength}
                {...{ [`data-${testAttribute}`]: true }}
                data-testid={testId}
            />
            {displayValidationResult && !isValid && <span className="input-container__error">{hint && txt(hint)}</span>}
        </div>
    );
};

export default Textarea;
