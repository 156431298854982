import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { LocationType } from 'commons/src/models/commonTypeScript';
import { BuildingFocusedSensorData } from '../../models/buildingModels';
import { Store } from '../../reducers';
import BuildingTile from './buildingTile/BuildingTile';
import style from './SortedBuildings.module.scss';

type StateProps = {
    locations: LocationType[];
    focusedSensor?: SensorTypes;
    locationsData: { [locationId: string]: BuildingFocusedSensorData };
};

type Props = StateProps;

const SortedBuildings = ({ locations, focusedSensor, locationsData }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const sortedLocations = [...locations].sort((location1, location2) => location1.name.localeCompare(location2.name));

    if (focusedSensor) {
        const { outsideThresholds, allGood } = sortedLocations.reduce(
            (
                acc: { outsideThresholds: LocationType[]; allGood: LocationType[] },
                location: LocationType
            ): { outsideThresholds: LocationType[]; allGood: LocationType[] } => {
                const thresholdInfoForLocation = locationsData[location.id];
                const numberOfDevicesWithBreach = thresholdInfoForLocation?.numberOfDevicesWithBreach || 0;
                if (numberOfDevicesWithBreach > 0) {
                    return {
                        ...acc,
                        outsideThresholds: [...acc.outsideThresholds, location],
                    };
                }
                return {
                    ...acc,
                    allGood: [...acc.allGood, location],
                };
            },
            {
                outsideThresholds: [],
                allGood: [],
            }
        );
        return (
            <div data-buildings-with-content>
                {outsideThresholds.length > 0 && (
                    <div className="page-wrapper">
                        <h2 className={style.subHeader}>{txt('BuildingInsight.OutsideThresholds')}</h2>
                        {outsideThresholds.map(location => (
                            <BuildingTile key={location.id} location={location} />
                        ))}
                    </div>
                )}
                {allGood.length > 0 && (
                    <div className="page-wrapper page-header--padded">
                        <h2 className={style.subHeader}>{txt('BuildingInsight.WithinThresholds')}</h2>
                        {allGood.map(location => (
                            <BuildingTile key={location.id} location={location} />
                        ))}
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="page-wrapper page-header--padded" data-buildings-with-content>
            {sortedLocations.map(location => (
                <BuildingTile key={location.id} location={location} />
            ))}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { locations },
        buildingsOverview: { focusedSensor, locationsData },
    } = state;
    return {
        locations,
        focusedSensor,
        locationsData,
    };
};

export default connect(mapStateToProps)(SortedBuildings);
