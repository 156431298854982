import { LocationsStatusAction, LocationStatusActions } from '../actions/LocationsStatusActions';
import { LocationsStatus } from '../models/commonTypeScript';

export type LocationsStatusState = {
    locationsStatus: LocationsStatus;
};

const initialState = {
    locationsStatus: {},
};

export default (state = initialState, action: LocationsStatusAction): LocationsStatusState => {
    switch (action.type) {
        case LocationStatusActions.FetchLocationsStatusSuccess:
            return {
                ...state,
                locationsStatus: action.response.locations,
            };
        default:
            return state;
    }
};
