import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsLogger } from '../../../analytics';
import { BUILDING_SORT_DEVICES } from '../../../analytics/AnalyticsEvents';
import { isVirtualDevice } from '../../../commonFunctions';
import Dropdown from '../../../components/dropdown/MultipleAttrDropdown';
import { userIsHbs } from '../../../components/findUserType';
import MaterialIcon from '../../../components/MaterialIcon';
import { dateFormats } from '../../../constants';
import { DeviceType, LocationType, HubData } from '../../../models/commonTypeScript';
import DeviceSort from './DeviceSort';
import HubSection from './HubSection';

type Props = {
    devices: {
        [serialNumber: string]: DeviceType;
    };
    onlyVirtualDevices: boolean;
    onlyPhysicalDevices: boolean;
    hubs: HubData[];
    location: LocationType;
    dateFormat: keyof typeof dateFormats;
    showLocationHeader: boolean;
    url?: string;
};

export const LocationSectionComponent = ({
    location,
    devices,
    dateFormat,
    hubs,
    showLocationHeader,
    onlyPhysicalDevices,
    onlyVirtualDevices,
    url,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const sortByOptions = [
        { id: 'deviceType', inputValue: txt('DeviceType') },
        { id: 'name', inputValue: txt('Name') },
    ];
    const [sortBy, setSortBy] = useState(sortByOptions[1]);
    const selectSortBy = (sortSelected: { id: string; inputValue: string }): void => {
        analyticsLogger(BUILDING_SORT_DEVICES, { sortBy: sortBy.id });
        setSortBy(sortSelected);
    };

    const locationDevices = location
        ? location.devices
              .map(serialNumber => devices[serialNumber])
              .filter(device => {
                  const deviceType = device && device.type;
                  if (!deviceType) return false;
                  if (onlyVirtualDevices) {
                      return isVirtualDevice(deviceType);
                  }
                  if (onlyPhysicalDevices) {
                      return !isVirtualDevice(deviceType);
                  }
                  return true;
              })
        : [];
    const locationHubs: HubData[] =
        location && hubs
            ? location.hubs
                  .map(serialNumber => hubs.find(hub => hub.serialNumber === serialNumber))
                  .filter(h => h !== undefined)
            : [];

    if (locationDevices.length === 0 && locationHubs.length === 0) {
        return <div />;
    }

    const sortedListOfDevices = locationDevices.sort((device1, device2) =>
        device1.segmentName.localeCompare(device2.segmentName)
    );
    return (
        <div className="page-section--full-width" id={location.id}>
            {showLocationHeader && (
                <div className="inline-header-lined">
                    <h2 className="inline-header-lined__text">
                        {location.name}
                        <span className="inline-header-lined__info-chip">{locationDevices.length}</span>
                    </h2>
                </div>
            )}
            <HubSection hubs={locationHubs} dateFormat={dateFormat} />
            {userIsHbs() && (
                <div className="form__field flex flex--align-center form__field--standard-width">
                    <MaterialIcon name="sort" extraClass="small-padding-right" />
                    <Dropdown
                        options={sortByOptions}
                        id="sortDevicesDropdown"
                        defaultOption={sortBy.inputValue}
                        value={sortBy.inputValue}
                        testAttr="sort-devices-dropdown"
                        onSelect={selectSortBy}
                        title=""
                    />
                </div>
            )}
            <div className="page-wrapper">
                <DeviceSort devices={sortedListOfDevices} sortBy={sortBy.id} url={url} />
            </div>
        </div>
    );
};

export default LocationSectionComponent;
