import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Dispatch } from 'redux';
import { FetchApiClients, fetchApiClients } from 'commons/src/actions/apiClientActions';
import PageHeader from 'commons/src/components/headers/PageHeader';
import { Store } from 'commons/src/reducers';
import ConsumerIntegrationMenuConfig from './ConsumerIntegrationMenuConfig';

type StateProps = {
    loadingApiClients: boolean;
};

type ActionProps = {
    getApiClients: () => void;
};

type Props = StateProps & ActionProps;

export const ConsumerIntegrationsViewComponent = (props: Props): React.ReactElement => {
    const { getApiClients, loadingApiClients } = props;
    const { t: txt } = useTranslation();

    useEffect(() => {
        if (!loadingApiClients) {
            getApiClients();
        }
    }, []);

    return (
        <>
            <PageHeader title={txt('Integrations.Integrations')} tabs={ConsumerIntegrationMenuConfig} />
            <div className="page-wrapper-flex settings-container">
                <Outlet />
            </div>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        commonRequests: {
            FETCH_API_CLIENTS: { loading: loadingApiClients },
        },
    } = state;
    return {
        loadingApiClients,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    getApiClients: (): FetchApiClients => dispatch(fetchApiClients()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerIntegrationsViewComponent);
