import moment from 'moment';
import { takeEvery, put, call } from 'redux-saga/effects';
import {
    FETCH_DASHBOARD_SENSOR,
    fetchSensorSegmentSuccess,
    fetchSensorSegmentError,
    updateDashboardLayout,
    FetchDashboardSensor,
} from '../../actions/DashboardActions';
import fetchSegment from '../../api/segment';
import { SensorTypes } from '../../models/commonEnums';
import {
    ChartDataType,
    DashboardTile,
    DeviceResponse,
    ExtraSeries,
    SelectedPeriod,
    SensorResponse,
    SensorTileContent,
    TileData,
} from '../../models/commonTypeScript';

const millisecondsToSeconds = (milliseconds: number): number => milliseconds / 1000;

export const generateAverageValue = (chartData: number[][]): number => {
    let prevSampleTime = millisecondsToSeconds(chartData[0][0]) - 1;
    let prevSampleValue = 0;

    const sumDataPoints = chartData.reduce((sum, sample) => {
        const sampleTime = millisecondsToSeconds(sample[0]);
        const sampleValue = sample[1];
        const value = sampleValue + (sampleTime - (prevSampleTime + 1)) * ((sampleValue + prevSampleValue) / 2);
        prevSampleValue = sampleValue;
        prevSampleTime = sampleTime;
        return sum + value;
    }, 0);
    return +(
        sumDataPoints /
        (millisecondsToSeconds(chartData[chartData.length - 1][0]) - (millisecondsToSeconds(chartData[0][0]) - 1))
    ).toFixed(1);
};

export const createChartData = (
    device: DeviceResponse,
    sensor: SensorResponse | undefined,
    segmentStartTime: number
): {
    chartData: number[][];
    minValue: number | undefined;
    averageValue: number | undefined;
    extraSeries: ExtraSeries;
} => {
    let minValue = sensor && sensor.measurements[0];
    const chartData =
        minValue !== undefined && sensor
            ? device.offsets[sensor.offsetType].map((interval: number, index: number) => {
                  const measurement = sensor.measurements[index];
                  if (minValue === undefined || minValue > measurement) {
                      minValue = measurement;
                  }
                  return [segmentStartTime + interval * 1000, measurement];
              })
            : [];

    const averageValue = chartData.length > 0 ? generateAverageValue(chartData) : undefined;

    const extraSeries = ((sensor && sensor.series) || [])
        .map(s => ({
            [s.aggregateType || s.type]: sensor
                ? device.offsets[sensor.offsetType].map((interval, index) => {
                      const measurementValue = s.measurements[index];
                      if (minValue === undefined || minValue > measurementValue) {
                          minValue = measurementValue;
                      }
                      return [segmentStartTime + interval * 1000, measurementValue];
                  })
                : [],
        }))
        .reduce((a, b) => ({ ...a, ...b }), {});

    return { chartData, minValue, averageValue, extraSeries };
};

export function createGraphArrays(
    device: DeviceResponse,
    timeRange: SelectedPeriod,
    sensorType: SensorTypes
): {
    chartData: ChartDataType;
    minValues: { [fetchedPeriod: string]: number | undefined };
    averageValues: { [fetchedPeriod: string]: number | undefined };
} {
    const segmentStartTime = moment(`${device.segmentStart}Z`).valueOf();
    const sensor = device.sensors.find(sensorObject => sensorObject.type === sensorType);
    const graphData = createChartData(device, sensor, segmentStartTime);

    return {
        chartData: { [timeRange.name]: graphData.chartData },
        minValues: { [timeRange.name]: graphData.minValue },
        averageValues: { [timeRange.name]: graphData.averageValue },
    };
}

const createDashboardConfig = (
    device: DeviceResponse,
    serialNumber: string,
    tiles: DashboardTile[],
    sensorData: TileData,
    sensorType: SensorTypes
): DashboardTile[] => {
    const sensor = device.sensors.find(sensorObject => sensorObject.type === sensorType);
    const content: SensorTileContent = {
        serialNumber,
        locationName: device.locationName,
        segmentName: device.segmentName,
        segmentStart: device.segmentStart,
        segmentEnd: device.segmentEnd,
        sensorType: sensor && sensor.type,
        unit: sensor && sensor.unit,
        thresholds: sensor && sensor.thresholds,
    };
    return [...tiles, { ...sensorData, content }];
};

export function* fetchDashboardSensorSaga({ payload }: FetchDashboardSensor, isoTime = moment()): Generator {
    const { tiles, sensorData, dashboardId, sensor } = payload;
    const { serialNumber, selectedInterval, sensorType } = sensor;
    const { number, period, resolutionDuration } = selectedInterval;

    const ref = `${serialNumber}-${sensorType}`;

    const toISO = isoTime.toISOString();
    const to = toISO.slice(0, toISO.lastIndexOf('.'));

    const fromISOTime = isoTime.clone().subtract(number, period).toISOString();
    const fromISO = moment(fromISOTime).subtract(resolutionDuration).toISOString();
    const from = fromISO.slice(0, fromISO.lastIndexOf('.'));
    try {
        const response: DeviceResponse = yield call(fetchSegment, serialNumber, {
            from,
            to,
            resolution: selectedInterval.resolution,
            sensors: sensorType,
        });

        yield put(
            fetchSensorSegmentSuccess({
                sensorData: createGraphArrays(response, selectedInterval, sensorType),
                ref,
            })
        );
        yield put(
            updateDashboardLayout({
                tiles: createDashboardConfig(response, serialNumber, tiles, sensorData, sensorType),
                newModule: true,
                dashboardId,
            })
        );
    } catch (error) {
        yield put(fetchSensorSegmentError(error));
    }
}

export default function* takeDashboardSensorActions(): Generator {
    yield takeEvery(FETCH_DASHBOARD_SENSOR, fetchDashboardSensorSaga);
}
