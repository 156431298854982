import React from 'react';
import { DeviceType } from '../../../models/commonTypeScript';
import GroupOfDevices from './GroupOfDevices';
import LocationDevices from './LocationDevices';

export type Props = {
    devices: DeviceType[];
    sortBy: string;
    url?: string;
};

export const DeviceSortComponent = ({ devices, sortBy, url }: Props): React.ReactElement => {
    if (sortBy === 'deviceType') {
        const devGroups = devices.reduce((groups: { type: string; devices: DeviceType[] }[], device) => {
            const existingGroupIndex: number = groups.findIndex(group => group.type === device.type);
            const deviceTypeInGroups = existingGroupIndex >= 0;
            const deviceTypeDevices = deviceTypeInGroups ? [...groups[existingGroupIndex].devices, device] : [device];
            const allTheGroups = !deviceTypeInGroups
                ? [...groups, { type: device.type, devices: deviceTypeDevices }]
                : groups;
            if (deviceTypeInGroups) {
                allTheGroups[existingGroupIndex] = { type: device.type, devices: deviceTypeDevices };
            }
            return allTheGroups;
        }, []);
        return (
            <>
                {devGroups.map(group => (
                    <GroupOfDevices key={group.type} group={group} url={url} />
                ))}
            </>
        );
    }

    return <LocationDevices devices={devices} url={url} />;
};

export default DeviceSortComponent;
