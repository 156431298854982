import React from 'react';
import { View, Image, StyleSheet } from '@react-pdf/renderer';
import { BoldText, Paragraph, SubHeader } from 'commons/src/components/PDF/PdfConstants';

export const styles = StyleSheet.create({
    smallText: {
        fontSize: 10,
    },
    pageLineSlim: {
        width: '90vw',
        height: 10,
        border: '0 solid #99B1C2',
        borderTopWidth: 1,
        textAlign: 'center',
        margin: '5 0 5 0',
    },
    graph: {
        width: '90vw',
        margin: '0vw 0 2vw',
    },
});

type Props = {
    chartImage: { data: string; sensorType: string };
    graphHeader: string;
    graphSubHeader: string;
    graphDescription: string;
};

const DeviceTile = (props: Props): React.ReactElement => {
    const { chartImage, graphHeader, graphSubHeader, graphDescription } = props;

    return (
        <View wrap={false}>
            <SubHeader>{graphHeader}</SubHeader>
            {chartImage && chartImage.data && <Image style={styles.graph} src={chartImage.data} />}
            <BoldText style={styles.smallText}>{graphSubHeader}</BoldText>
            <Paragraph>{graphDescription}</Paragraph>
            <View style={styles.pageLineSlim} />
        </View>
    );
};

export default DeviceTile;
