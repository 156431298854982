import delay from '@redux-saga/delay-p';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { showAlertBox, hideAlertBox, InitializationActionType, DisplayAlertBoxSagaAction } from '../actions';

export default function* displayAlertBoxSaga(message: string, error: boolean, fade: boolean): Generator {
    yield put(showAlertBox(message, error, fade));
    if (fade) {
        yield call(delay, 6000);
        yield put(hideAlertBox());
    }
}

export function* displayAlertBoxSagaByAction({ message, error, fade }: DisplayAlertBoxSagaAction): Generator {
    yield put(showAlertBox(message, error, fade));
    if (fade) {
        yield call(delay, 6000);
        yield put(hideAlertBox());
    }
}

export function* alertBoxSagas(): Generator {
    yield all([takeEvery(InitializationActionType.DisplayAlertBoxSagaAction, displayAlertBoxSagaByAction)]);
}
