import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { sensorUnits } from 'commons/src/constants';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { BreachType, BuildingFocusedSensorData } from '../models/buildingModels';
import { EmailSummaryPutRequest, EmailSummaryResponse } from '../models/emailSummaryModels';

export const getEmailSummary = async (): Promise<EmailSummaryResponse> =>
    fetch('/email-summary', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const putEmailSummary = async (payload: EmailSummaryPutRequest): Promise<void> =>
    fetch('/email-summary', {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(payload),
    });

export const updateLocationMoldRiskSegments = async (locationId: string, segmentIds: string[]): Promise<void> => {
    return fetch<void>(`/locations/${locationId}/mold-risk-devices`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ segmentIdsWithMoldRiskEnabled: segmentIds }),
    });
};

export const fetchLocationMoldRiskSegments = async (
    locationId: string
): Promise<{ segmentIdsWithMoldRiskEnabled: string[] }> =>
    fetch<{ segmentIdsWithMoldRiskEnabled: string[] }>(`/locations/${locationId}/mold-risk-devices`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export type GetDefaultConfigResponse = {
    sensor: SensorTypes;
    thresholds: { type: BreachType; value: number }[];
    thresholdDefaults: {
        over: number[];
        under?: number[];
        unit: keyof typeof sensorUnits;
    };
};

export const getThresholdBreachDefaults = async (): Promise<{ config: GetDefaultConfigResponse }> =>
    fetch<{ config: GetDefaultConfigResponse }>('/locations/breaches/config', {
        method: 'GET',
        headers: await getFetchHeaders(),
    });

export const setConfigThresholdBreach = async (payload: {
    sensor: SensorTypes;
    thresholds?: { type: BreachType; value: number }[];
}): Promise<GetDefaultConfigResponse> =>
    fetch<GetDefaultConfigResponse>('/locations/breaches/config', {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(payload),
    });

type ThresholdBreachRequest = {
    thresholds: { type: BreachType; value: number }[];
    sensor: SensorTypes;
    to: string;
    useOpeningHours: boolean;
    from: string;
    resolution: string;
};

export type ThresholdBreachResponse = {
    sensor: SensorTypes;
    unit: keyof typeof sensorUnits;
    locationData: { [locationId: string]: BuildingFocusedSensorData };
    thresholds: { value: number; type: BreachType }[];
    limit: number;
    offset: number;
    totalLocations: number;
};

export const getThresholdBreachesForAllBuildings = async (
    payload: ThresholdBreachRequest,
    offset: number,
    limit: number
): Promise<ThresholdBreachResponse> =>
    fetch<ThresholdBreachResponse>(`/locations/breaches?offset=${offset}&limit=${limit} `, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(payload),
    });

export const deleteThresholdBreachDefaults = async (): Promise<void> =>
    fetch<void>('/locations/breaches/config', {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });
