import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchOutdoorAirInfo } from '../../actions/OutdoorActions';
import NoContent from '../../components/emptyStates/NoContent';
import Error from '../../components/errorComponents/Error';
import { sensorPlaceholder } from '../../components/placeholder';
import { ErrorType, LocationType, OutdoorData } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import { CommonRequestType } from '../../reducers/requestReducer';
import AqiHeader from './AqiHeader';
import OutdoorSensorRow from './OutdoorSensorRow';

type StateProps = {
    locations: LocationType[];
    outdoorLocations: {
        [key: string]: {
            locationId: string;
            content: OutdoorData;
        };
    };
    loading: boolean;
    error?: ErrorType;
};

type Props = StateProps;

export const OutdoorAirQualityPageComponent = ({
    loading,
    error,
    locations,
    outdoorLocations,
}: Props): ReactElement => {
    const { locationId } = useParams() as { locationId: string };
    const dispatch = useDispatch();
    const { t: txt } = useTranslation();
    const locationDetails = locations.find(loc => loc.id === locationId);
    const location = outdoorLocations[locationId] || {};
    const pollutants = location?.content?.pollutants?.pollutants;
    const missingPollutantData = !pollutants;
    const weather = location.content && location.content.weather;
    const locationName = locationDetails?.name || '';

    useEffect(() => {
        dispatch(fetchOutdoorAirInfo(locationId));
    }, []);

    if (error) {
        return <Error />;
    }

    const renderRows = (): ReactElement[] =>
        pollutants &&
        Object.keys(pollutants).map(key => (
            <OutdoorSensorRow key={`outdoor-sensor-${key}`} sensor={key} {...pollutants[key]} />
        ));

    return (
        <div className="single-device">
            <AqiHeader locationName={locationName} loading={loading} weather={weather} />
            <div className="container">
                <ReactPlaceholder ready={!loading} customPlaceholder={sensorPlaceholder}>
                    {!missingPollutantData && !loading ? (
                        <div>
                            <div className="single-device__header-row">
                                <div className="single-device__header-row__graph">
                                    <div className="single-device__header-row__pollutant">
                                        {txt('PollutantsInLocation')}
                                    </div>
                                </div>
                            </div>
                            {renderRows()}
                        </div>
                    ) : (
                        <div className="page-wrapper">
                            <NoContent noContentText="AirQualityDataUnavailable" />
                        </div>
                    )}
                    <div className="breezometer-link">
                        {txt('PoweredBy')}
                        {location?.content?.sourceApi === 'openWeatherMap' && (
                            <a href="https://openweathermap.org/" target="_blank" rel="noopener noreferrer">
                                {location?.content?.sourceApi}
                            </a>
                        )}
                    </div>
                </ReactPlaceholder>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        outdoorAirInfoPage: { locations: outdoorLocations },
        locations: { locations },
        commonRequests: {
            [CommonRequestType.FetchOutdoorAirInfo]: { loading, error },
        },
    } = state;

    return {
        locations,
        outdoorLocations,
        loading,
        error,
    };
};

export default connect(mapStateToProps)(OutdoorAirQualityPageComponent);
