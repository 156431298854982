import React from 'react';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { useTranslation } from 'react-i18next';
import { dateFormats } from '../constants';
import InputLabel from './input/InputLabel';
import MaterialIcon from './MaterialIcon';

export type Props = {
    id: string;
    onChange: (date: Moment) => void;
    dateFormat: keyof typeof dateFormats;
    placeholder?: string;
    label?: string;
    mandatory?: boolean;
    initialVisibleMonth?: Moment;
    defaultValue?: Moment;
    disabledDate?: (moment: Moment) => boolean;
    showToday?: boolean;
    displayDateValidation?: boolean;
    selectedDate?: Moment | null;
    selectedDateHint?: string;
    standardWidth?: boolean;
    showHint?: boolean;
    testId?: string;
    month?: boolean;
};

const DatePickerWithMoment = generatePicker<Moment>(momentGenerateConfig);
export const DatePickerComponent = (props: Props): React.ReactElement => {
    const {
        label,
        id,
        defaultValue,
        onChange,
        showToday = false,
        dateFormat,
        disabledDate,
        mandatory = false,
        initialVisibleMonth,
        displayDateValidation,
        selectedDate,
        selectedDateHint,
        placeholder,
        standardWidth,
        showHint = true,
        testId,
        month,
    } = props;
    const { t: txt } = useTranslation();

    const disabledRange = disabledDate || ((date: Moment): boolean => date > moment());

    const onDateSelect = (value: Moment | null): void => {
        if (value !== null) {
            onChange(value);
        }
    };

    return (
        <div
            className={classNames('input-container', {
                'input-container--invalid': displayDateValidation,
                'form__field--standard-width': standardWidth,
            })}
        >
            {label && <InputLabel htmlFor={id} label={label} mandatory={mandatory} />}
            <DatePickerWithMoment
                id={id}
                onChange={onDateSelect}
                placeholder={txt(placeholder || 'Date')}
                format={!month ? dateFormats[dateFormat].calendarFormat : undefined}
                suffixIcon={<MaterialIcon name="date_range" />}
                allowClear={false}
                defaultPickerValue={initialVisibleMonth}
                showToday={showToday}
                disabledDate={disabledRange}
                value={selectedDate}
                defaultValue={defaultValue}
                data-testid={testId}
                picker={month ? 'month' : undefined}
            />
            {displayDateValidation && showHint && (
                <div className="input-container__error">
                    {selectedDate === null ? txt('InvalidDate') : txt(selectedDateHint || '')}
                </div>
            )}
        </div>
    );
};

export default DatePickerComponent;
