import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deviceIsView } from 'commons/src/commonFunctions';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import StandAloneSpinner from 'commons/src/img/StandAloneSpinner';
import usbcCable from 'commons/src/img/usbcCable.png';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import {
    DeviceTypeBatteryConfig,
    ErrorType,
    SensorInterval,
    SensorSampleInterval,
} from 'commons/src/models/commonTypeScript';
import { fetchBatteryLifeAtBuildingLevel } from '../../../api/buildingApi';
import BatteryLevelDisplayed from '../../device/dropdownOptions/BatteryLevelDisplayed';
import BatterySettingsSelector from '../../device/dropdownOptions/BatterySettingSelector';
import ToggleSensorOnOff from '../../device/dropdownOptions/ToggleSensorOnOff';

type ParentProps = {
    locationId: string;
    configurations: DeviceTypeBatteryConfig[];
    deviceType: string;
    updateConfig: (deviceType: string, updatedIntervals: DeviceTypeBatteryConfig[]) => void;
};

export type Props = ParentProps;

export const ConfigureBatteryLifeComponent = ({
    locationId,
    configurations,
    updateConfig,
    deviceType,
}: Props): React.ReactElement | null => {
    const { t: txt } = useTranslation();
    const [batteryLife, setBatteryLife] = useState<number | undefined>();
    const [batteryLifeError, setBatteryLifeError] = useState<ErrorType | undefined>(undefined);
    const [batteryLifeLoading, setBatteryLifeLoading] = useState(true);

    const updateSensorIntervals = (sensor: string, value: number): void => {
        const updatedSensorInterval = configurations.map(interval =>
            interval.sensorType === sensor ? { ...interval, current: value } : interval
        );
        updateConfig(deviceType, updatedSensorInterval);
    };

    const { toggleOptions, selectorOptions } = configurations.reduce(
        (
            intervalOptions: { toggleOptions: SensorSampleInterval[]; selectorOptions: SensorSampleInterval[] },
            sensorIntervalOption
        ) => {
            const isOnOffToggle = sensorIntervalOption.options.length === 2 && sensorIntervalOption.options.includes(0);
            if (isOnOffToggle) {
                return { ...intervalOptions, toggleOptions: [...intervalOptions.toggleOptions, sensorIntervalOption] };
            }
            return { ...intervalOptions, selectorOptions: [...intervalOptions.selectorOptions, sensorIntervalOption] };
        },
        { toggleOptions: [], selectorOptions: [] }
    );

    const getAverageBatteryLifeForWavePlus = useCallback(
        async (sensorInterval: SensorInterval) => {
            await fetchBatteryLifeAtBuildingLevel(locationId, sensorInterval, deviceType)
                .then(response => {
                    setBatteryLife(response.batteryLifetimeEstimateInWeeks);
                    setBatteryLifeLoading(false);
                })
                .catch(err => {
                    setBatteryLifeError(err);
                    setBatteryLifeLoading(false);
                });
        },
        [deviceType]
    );

    const fetchBatteryLifeForDeviceType = (sensorInterval: SensorInterval): void => {
        setBatteryLifeLoading(true);
        getAverageBatteryLifeForWavePlus(sensorInterval).catch(err => {
            setBatteryLifeError(err);
            setBatteryLifeLoading(false);
        });
    };

    useEffect(() => {
        const initialSensorIntervals = configurations.reduce(
            (obj, interval) => ({ ...obj, [interval.sensorType]: interval.current }),
            {}
        );
        fetchBatteryLifeForDeviceType(initialSensorIntervals);
    }, [configurations]);

    const isViewDevice = deviceIsView(deviceType as DeviceTypeNames);
    return (
        <div className="page-wrapper__inner page-wrapper__inner--slim">
            <div className="segment-properties-form__battery-estimate building-config">
                <div>
                    {batteryLifeLoading ? (
                        <StandAloneSpinner />
                    ) : (
                        <BatteryLevelDisplayed
                            estimatedBatteryLife={batteryLife}
                            estimateBatteryError={batteryLifeError}
                        />
                    )}
                    <div className="segment-properties-form__text segment-properties-form__text--grey segment-properties-form__text--whitespace text-medium">
                        * {txt('DeviceSettings.EstimatedLifetime')}
                        <br />
                    </div>
                </div>
                <div className="battery-life__toggle-sensor-wrapper flex flex--wrap">
                    {toggleOptions.map(interval => (
                        <div key={`batteryOptions${interval.sensorType}`} className="battery-life__sensor-selector">
                            <ToggleSensorOnOff
                                onSelect={updateSensorIntervals}
                                interval={interval}
                                selectedInterval={interval.current}
                                deviceType={deviceType}
                            />
                        </div>
                    ))}
                    {selectorOptions.map(interval => (
                        <div key={`batteryOptions${interval.sensorType}`} className="battery-life__sensor-selector">
                            <BatterySettingsSelector
                                disabled={false}
                                onSelect={updateSensorIntervals}
                                interval={interval}
                                selectedInterval={interval.current}
                                slimWidth
                            />
                        </div>
                    ))}
                </div>
                {(isViewDevice || deviceType === DeviceTypeNames.spaceCo2Mini) && (
                    <div>
                        <div className="flex flex--align-center">
                            <MaterialIcon name="error_outline" />
                            <span className="small-padding-left">
                                {txt('BuildingSettings.BatteryLifeReducedHeader')}
                            </span>
                        </div>
                        <div className="text-small battery-life__outside-opening-hours-info">
                            {txt(
                                deviceType === DeviceTypeNames.viewPlusBusiness
                                    ? 'BuildingSettings.BatteryLifeViewPlusReducedText'
                                    : 'BuildingSettings.BatteryLifeReducedText'
                            )}
                        </div>
                    </div>
                )}
                {isViewDevice && (
                    <div className="flex flex--align-center">
                        <img
                            src={usbcCable}
                            alt={txt('DeviceSettings.USBCImage')}
                            className="change-location__charger-image"
                        />
                        <div className="text-small">{txt('DeviceSettings.USBPoweredDescription')}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ConfigureBatteryLifeComponent;
