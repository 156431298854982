import React from 'react';
import styles from './TabNumberBadge.module.scss';

type Props = {
    value: number;
};

export default function TabNumberBadge({ value }: Props): React.ReactElement {
    return (
        <div className={styles.badge}>
            <span>{value}</span>
        </div>
    );
}
