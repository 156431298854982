import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import { mapDeviceType } from 'commons/src/commonFunctions';
import { FlexWrapperSpaced } from 'commons/src/components/PDF/PdfConstants';
import { dateFormats } from 'commons/src/constants';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';
import { PDFContext } from '../../../../models/common';

const styles = StyleSheet.create({
    footer: {
        fontSize: 8,
        marginTop: '3vw',
    },
    smallText: {
        fontSize: 8,
    },
    flexWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    smallTextParagraph: {
        fontSize: 8,
        padding: '2vw 0',
    },
});

type Props = {
    userName: string;
    dateFormat: keyof typeof dateFormats;
    txt: PDFContext;
    reportId: string;
    deviceTypes: string[];
};

const ReportFooter = ({ userName, dateFormat, reportId, deviceTypes, txt }: Props): React.ReactElement => (
    <View style={styles.footer}>
        <FlexWrapperSpaced>
            <View>
                <View style={styles.flexWrapper}>
                    <Text style={styles.smallText}>
                        {txt('Report.Generated')}
                        {': '}
                    </Text>
                    <Text style={styles.smallText}>
                        {moment().format(dateFormats[dateFormat].shortFormat)}{' '}
                        {moment().format(dateFormats[dateFormat].hourFormat)}
                    </Text>
                </View>
                <View style={styles.flexWrapper}>
                    <Text style={styles.smallText}>
                        {txt('Report.MeasurementBy')}
                        {': '}
                    </Text>
                    <Text style={styles.smallText}>{userName}</Text>
                </View>
                <View style={styles.flexWrapper}>
                    <Text style={styles.smallText}>
                        {txt('Report.ReportId')}
                        {': '}
                    </Text>
                    <Text style={styles.smallText}>{reportId}</Text>
                </View>
            </View>
            <View>
                <View style={styles.flexWrapper}>
                    <Text style={styles.smallText}>
                        {txt('OutdoorInsight.ReportSamplingMethod')}
                        {': '}
                    </Text>
                    <Text style={styles.smallText}>{txt('Active')}</Text>
                </View>
                <View style={styles.flexWrapper}>
                    <Text style={styles.smallText}>
                        {txt('OutdoorInsight.ReportMeasurementMethod')}
                        {': '}
                    </Text>
                    <Text style={styles.smallText}>{txt('OutdoorInsight.ReportContinuous')}</Text>
                </View>
                <View style={styles.flexWrapper}>
                    <Text style={styles.smallText}>
                        {txt('Insight.DeviceTypesInMeasurement')}
                        {': '}
                    </Text>
                    {deviceTypes.map(type => (
                        <Text style={styles.smallText} key={`device-${type}`}>{`Airthings ${txt(
                            `${mapDeviceType(type as AnyDeviceType)}FullName`
                        )}`}</Text>
                    ))}
                </View>
            </View>
        </FlexWrapperSpaced>
        <Text style={styles.smallTextParagraph}>{txt('OutdoorInsight.FooterReportTechnicalInformation')}</Text>
    </View>
);

export default ReportFooter;
