import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { limitSubscription } from 'commons/src/commonFunctions';
import { subscriptionDevicesIncludingOldOnce } from 'commons/src/constants';
import {
    DeviceTypeNames,
    GroupType,
    PartnerCustomerSubscriptionStatus,
    SubscriptionType,
} from 'commons/src/models/commonEnums';
import { FeatureToggleRequirement } from 'commons/src/models/featureToggleModels';
import { CustomerListElement, SubAccountDetails } from '../../../models/partner';
import styles from './CustomerListElement.module.scss';

export type CellRenderResult = ReactElement | string | number;

export interface CustomerListHeader extends FeatureToggleRequirement {
    label: string;
    objectKey: keyof CustomerListElement;
    render: (customer: CustomerListElement) => CellRenderResult;
    requiredGroupTypes: GroupType[];
}

const deviceRequiresSubscription = (deviceType?: DeviceTypeNames): boolean => {
    return !!deviceType && subscriptionDevicesIncludingOldOnce.includes(deviceType);
};
const getNumberOfActiveDevices = (customerDevices: { [deviceType: string]: number }): number =>
    Object.keys(customerDevices).reduce((total, deviceType) => {
        const numberOfDevicesOfType = customerDevices[deviceType];
        const deviceIsASubscriptionDevice = deviceRequiresSubscription(deviceType as DeviceTypeNames);
        if (deviceIsASubscriptionDevice) {
            return total + numberOfDevicesOfType;
        }
        return total;
    }, 0);

export const toCustomerListElement = (customers: SubAccountDetails[]): CustomerListElement[] =>
    customers.map(customer => {
        const totalNumberOfDevices = getNumberOfActiveDevices(customer.numberOfDevices);
        const subscriptionIsLimited = limitSubscription(customer.subscriptionPlan);
        let subscriptionType: PartnerCustomerSubscriptionStatus | SubscriptionType =
            totalNumberOfDevices > (customer.totalNumberOfSeats || 0)
                ? PartnerCustomerSubscriptionStatus.InsufficientSeats
                : PartnerCustomerSubscriptionStatus.Active;
        if (customer.totalNumberOfSeats === undefined) {
            subscriptionType = PartnerCustomerSubscriptionStatus.NotFound;
        } else if (totalNumberOfDevices === 0 && customer.totalNumberOfSeats === 0) {
            subscriptionType = PartnerCustomerSubscriptionStatus.NoDevices;
        }
        if (subscriptionIsLimited) {
            subscriptionType = SubscriptionType.LIMITED;
        }

        const common: CustomerListElement = {
            name: customer.organizationName,
            userGroupId: customer.userGroupId,
            createdAt: customer.createdAt,
            totalNumberOfDevices,
            pending: !!customer.pending,
            totalNumberOfSeats: customer.totalNumberOfSeats,
            subscriptionType,
        };
        return common;
    });

export const PendingChip = (): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className={styles.pendingCell}>
            <div className={styles.pendingElement}>{txt('Partner.PendingSubAccount')}</div>
        </div>
    );
};
