import { RequestActionType } from 'commons/src/actions/requestActions';
import { sensorUnits } from 'commons/src/constants';
import { dayWeatherCodes } from 'commons/src/features/outdoorAirQualityPage/WeatherAttrs';
import { Units } from 'commons/src/models/commonTypeScript';
import { BusinessActionRequestType } from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum InsightActionType {
    FetchOutdoorInsightDataInit = 'FETCH_OUTDOOR_INSIGHT_DATA/INIT',
}

export type OutdoorInsightResponse = {
    history: {
        [sensorType: string]: {
            data: number[][];
            unit: keyof typeof sensorUnits;
            weatherIcon?: [number, keyof typeof dayWeatherCodes][];
        };
    };
};

export type FetchOutdoorInsightDataType = {
    from: string;
    to: string;
    locationId: string;
    unitPreferences: Units;
};

export interface FetchOutdoorInsightData extends BusinessActionRequestType {
    type: InsightActionType.FetchOutdoorInsightDataInit;
    payload: FetchOutdoorInsightDataType;
}
export const fetchOutdoorInsightData = (payload: FetchOutdoorInsightDataType): FetchOutdoorInsightData => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchOutdoorInsightData,
    type: InsightActionType.FetchOutdoorInsightDataInit,
    payload,
});

export const FETCH_OUTDOOR_INSIGHT_DATA_SUCCESS = 'FETCH_OUTDOOR_INSIGHT_DATA_SUCCESS';
export interface FetchOutdoorInsightDataSuccess {
    type: typeof FETCH_OUTDOOR_INSIGHT_DATA_SUCCESS;
    payload: OutdoorInsightResponse;
}
export const fetchOutdoorInsightDataSuccess = (payload: OutdoorInsightResponse): FetchOutdoorInsightDataSuccess => ({
    type: FETCH_OUTDOOR_INSIGHT_DATA_SUCCESS,
    payload,
});

export type InsightAction = FetchOutdoorInsightData;
