import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import PollutantSensor from './PollutantSensor';

export type Props = {
    value: number;
    unit?: string;
    sensor: string;
};

export const OutdoorSensorRowComponent = ({ value, sensor, unit }: Props): ReactElement => {
    const textKey = `Description${sensor.toUpperCase()}`;
    return (
        <div className="sensor-row">
            <div className="sensor-row__current-value">
                <PollutantSensor sensor={sensor} concentration={value} unit={unit} />
            </div>
            <div className="sensor-row__historical sensor-row__historical--text">
                <Trans i18nKey={textKey}>
                    <sub />
                </Trans>
            </div>
        </div>
    );
};

export default OutdoorSensorRowComponent;
