import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import RequestActions from 'commons/src/models/RequestTypes';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import {
    AddSeatsToSubscription,
    CreateSubscriptionForDirectCustomer,
    createSubscriptionForDirectCustomerSuccess,
    CreateSubscriptionForDirectCustomerSuccess,
    CreateSubscriptionForPartnerCustomer,
    CreateSubscriptionForPartnerCustomerSuccess,
    createSubscriptionForPartnerCustomerSuccess,
    GetSubscriptionForEndCustomerSuccess,
    getSubscriptionForEndCustomerSuccess,
    GetSubscriptionForPartnerCustomer,
    getSubscriptionForPartnerCustomerSuccess,
    GetSubscriptionForPartnerCustomerSuccess,
    SubscriptionActions,
    UpdatePoRef,
    updatePoRefSuccess,
    UpdatePoRefSuccess,
} from '../../actions/subscriptionActions';
import {
    addSeats,
    getSubscriptionForEndCustomer,
    getSubscriptionForPartnerCustomerApi,
    startSubscription,
    startSubscriptionForDirectCustomer,
    updatePoRef,
} from '../../api/subscriptionApi';
import { Subscription } from '../../models/subscriptionModels';
import { BusinessRequestType as RequestType } from '../../reducers/BusinessRequestType';

type GetSubscriptionForEndCustomerSagaReturnType = Generator<
    CallEffect<Subscription[]> | PutEffect<GetSubscriptionForEndCustomerSuccess> | RequestActions,
    void,
    Subscription[]
>;

export function* getSubscriptionForEndCustomerSaga(): GetSubscriptionForEndCustomerSagaReturnType {
    try {
        const response = yield call(getSubscriptionForEndCustomer);
        yield put(getSubscriptionForEndCustomerSuccess(response));
        yield put(requestSuccess(RequestType.GetSubscriptionForEndCustomer, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.GetSubscriptionForEndCustomer, RequestActionType.Error));
    }
}

type GetSubscriptionForPartnerCustomerSagaReturnType = Generator<
    CallEffect<Subscription[]> | PutEffect<GetSubscriptionForPartnerCustomerSuccess> | RequestActions,
    void,
    Subscription[]
>;

export function* getSubscriptionForPartnerCustomerSaga({
    customerUserGroupId,
}: GetSubscriptionForPartnerCustomer): GetSubscriptionForPartnerCustomerSagaReturnType {
    try {
        const response = yield call(getSubscriptionForPartnerCustomerApi, customerUserGroupId);
        yield put(getSubscriptionForPartnerCustomerSuccess(response, customerUserGroupId));
        yield put(requestSuccess(RequestType.GetSubscriptionForPartnerCustomer, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(
            requestError(errorAsErrorType, RequestType.GetSubscriptionForPartnerCustomer, RequestActionType.Error)
        );
    }
}

type CreateSubscriptionForPartnerCustomerSagaReturnType = Generator<
    | CallEffect<{ subscriptionNumber: string }>
    | RequestActions
    | PutEffect<CreateSubscriptionForPartnerCustomerSuccess>,
    void,
    { subscriptionNumber: string }
>;

export function* createSubscriptionForPartnerCustomerSaga({
    customerUserGroupId,
    subscriptionDetails,
}: CreateSubscriptionForPartnerCustomer): CreateSubscriptionForPartnerCustomerSagaReturnType {
    try {
        const { subscriptionNumber } = yield call(startSubscription, customerUserGroupId, subscriptionDetails);
        yield put(createSubscriptionForPartnerCustomerSuccess(subscriptionNumber));
        yield put(requestSuccess(RequestType.CreateSubscriptionForPartnerCustomer, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(
            requestError(errorAsErrorType, RequestType.CreateSubscriptionForPartnerCustomer, RequestActionType.Error)
        );
    }
}

type CreateSubscriptionForDirectCustomerSagaReturnType = Generator<
    CallEffect<{ subscriptionNumber: string }> | RequestActions | PutEffect<CreateSubscriptionForDirectCustomerSuccess>,
    void,
    { subscriptionNumber: string }
>;

export function* createSubscriptionForDirectCustomerSaga({
    subscriptionDetails,
}: CreateSubscriptionForDirectCustomer): CreateSubscriptionForDirectCustomerSagaReturnType {
    try {
        const { subscriptionNumber } = yield call(startSubscriptionForDirectCustomer, subscriptionDetails);
        yield put(createSubscriptionForDirectCustomerSuccess(subscriptionNumber));
        yield put(requestSuccess(RequestType.CreateSubscriptionForDirectCustomer, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(
            requestError(errorAsErrorType, RequestType.CreateSubscriptionForDirectCustomer, RequestActionType.Error)
        );
    }
}

type AddSeatsToSubscriptionSagaReturnType = Generator<
    CallEffect<{ subscriptionNumber: string }> | RequestActions,
    void
>;

export function* addSeatsToSubscriptionSaga({
    customerUserGroupId,
    updatedSubscription,
    subscriptionNumber,
    groupType,
}: AddSeatsToSubscription): AddSeatsToSubscriptionSagaReturnType {
    try {
        yield call(addSeats, updatedSubscription, subscriptionNumber, groupType, customerUserGroupId);
        yield put(requestSuccess(RequestType.AddSeatsToSubscription, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.AddSeatsToSubscription, RequestActionType.Error));
    }
}

type UpdatePoRefSagaReturnType = Generator<
    CallEffect<void> | PutEffect<UpdatePoRefSuccess> | RequestActions,
    void,
    void
>;
export function* updatePoRefSaga({
    subscriptionNumber,
    poRef,
    groupType,
    customerUserGroupId,
}: UpdatePoRef): UpdatePoRefSagaReturnType {
    try {
        yield call(updatePoRef, subscriptionNumber, poRef, groupType, customerUserGroupId);
        yield put(updatePoRefSuccess(subscriptionNumber, poRef));
        yield put(requestSuccess(RequestType.UpdatePoRef, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.UpdatePoRef, RequestActionType.Error));
    }
}

export default function* SubscriptionSaga(): Generator {
    yield all([
        takeEvery(SubscriptionActions.GetSubscriptionForEndCustomerInit, getSubscriptionForEndCustomerSaga),
        takeEvery(SubscriptionActions.GetSubscriptionForPartnerCustomerInit, getSubscriptionForPartnerCustomerSaga),
        takeEvery(
            SubscriptionActions.CreateSubscriptionForPartnerCustomerInit,
            createSubscriptionForPartnerCustomerSaga
        ),
        takeEvery(SubscriptionActions.CreateSubscriptionForDirectCustomerInit, createSubscriptionForDirectCustomerSaga),
        takeEvery(SubscriptionActions.AddSeatsToSubscriptionInit, addSeatsToSubscriptionSaga),
        takeEvery(SubscriptionActions.UpdatePoRefInit, updatePoRefSaga),
    ]);
}
