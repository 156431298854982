import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { colors } from 'commons/src/constants';
import { BuildingFocusedSensorData } from '../../../models/buildingModels';
import { Store } from '../../../reducers';
import humanizedTimeString from '../buildingInsight/buildingInsightCommonFunctions';
import styles from './BuildingTileSensorFocus.module.scss';
import buildingTileSparkLine from './buildingTileSparkLine';

type StateProps = {
    locationsData: { [locationId: string]: BuildingFocusedSensorData };
};

type ParentProps = {
    numberOfDevices: number;
    locationId: string;
};

type Props = ParentProps & StateProps;

const BuildingTileSensorFocus = ({ numberOfDevices, locationId, locationsData }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const thresholdInfoForLocation = locationsData[locationId];

    const dateSortedThresholdData =
        thresholdInfoForLocation?.trendData.sort((day1, day2) => moment(day1.date).unix() - moment(day2.date).unix()) ||
        [];

    const graphValues =
        dateSortedThresholdData.map(trendData => trendData.underThresholdsTime + trendData.overThresholdsTime) || [];
    const highestTimeBreach = Math.max(...graphValues);
    const graphData = dateSortedThresholdData.map(trendData => {
        const totalValue = trendData.underThresholdsTime + trendData.overThresholdsTime;
        return {
            y: totalValue,
            color: totalValue >= highestTimeBreach ? colors.redPersian : colors.greyMist,
        };
    });
    const sparkLineConfig = buildingTileSparkLine(graphData);

    const numberOfDevicesWithBreach = thresholdInfoForLocation?.numberOfDevicesWithBreach || 0;
    return (
        <div>
            <div className={styles.outsideBreachWrapper}>
                <div>
                    <div className={styles.subHeader}>{txt('BuildingTile.DevicesOutsideThreshold')}</div>
                    <div className={styles.value}>
                        <span className={styles.boldValue}> {numberOfDevicesWithBreach}</span>{' '}
                        {txt('BuildingTile.DevicesOfTotal')} {numberOfDevices}
                    </div>
                </div>
                {numberOfDevicesWithBreach > 0 && (
                    <div className={styles.graphWrapper}>
                        <HighchartsReact highcharts={Highcharts} options={sparkLineConfig} />
                        {(highestTimeBreach / 60).toFixed(0)}
                        {txt('TimeUnit.HoursShort')}
                    </div>
                )}
            </div>
            {numberOfDevicesWithBreach > 0 && (
                <div className={styles.longestBreach}>
                    <div className={styles.subHeader}>{txt('BuildingTile.HighestTotalDevice')}</div>
                    <div className={styles.longestBreachDevice}>
                        <div className={styles.deviceName}>
                            {thresholdInfoForLocation.deviceWithGreatestBreach.name}
                        </div>
                        {humanizedTimeString(thresholdInfoForLocation.deviceWithGreatestBreach.totalMinutes, true)}
                    </div>
                </div>
            )}
            {numberOfDevices > 0 && numberOfDevicesWithBreach === 0 ? (
                <div className={styles.freshAir}>
                    <MaterialIcon name="wb_sunny" extraClass={styles.icon} />
                    {txt('BuildingTile.BreathOfFreshAir')}
                </div>
            ) : (
                <div />
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        buildingsOverview: { locationsData },
    } = state;
    return {
        locationsData,
    };
};

export default connect(mapStateToProps)(BuildingTileSensorFocus);
