import React from 'react';
import ForecastCloudy from '../../img/forecast-icons/forecast-icon-cloudy';
import ForecastFog from '../../img/forecast-icons/forecast-icon-fog';
import ForecastHazy from '../../img/forecast-icons/forecast-icon-hazy';
import ForecastOvercast from '../../img/forecast-icons/forecast-icon-overcast';
import ForecastPartiallyCloudy from '../../img/forecast-icons/forecast-icon-partially-cloudy';
import ForecastRainThunder from '../../img/forecast-icons/forecast-icon-rain-thunder';
import ForecastRain from '../../img/forecast-icons/forecast-icon-rainy';
import ForecastSnow from '../../img/forecast-icons/forecast-icon-snow';
import ForecastSnowRain from '../../img/forecast-icons/forecast-icon-snow-rain';
import ForecastSun from '../../img/forecast-icons/forecast-icon-sun';
import ForecastThunder from '../../img/forecast-icons/forecast-icon-thunder';

export const dayWeatherCodes = {
    1: 'sunny',
    2: 'sunny',
    3: 'sunny',
    4: 'sunny',
    5: 'partly-cloudy',
    6: 'partly-cloudy',
    7: 'partly-cloudy',
    8: 'partly-cloudy',
    9: 'partly-cloudy',
    10: 'cloudy-thunder',
    11: 'cloudy-thunder',
    12: 'cloudy-thunder',
    13: 'clear-hazy',
    14: 'clear-hazy',
    15: 'clear-hazy',
    16: 'foggy',
    17: 'foggy',
    18: 'foggy',
    19: 'cloudy',
    20: 'cloudy',
    21: 'cloudy',
    22: 'overcast',
    23: 'rain',
    24: 'snow',
    25: 'rain',
    26: 'snow',
    27: 'rain-thunder',
    28: 'rain',
    29: 'snow',
    30: 'rain',
    31: 'rain',
    32: 'snow',
    33: 'rain',
    34: 'snow',
    35: 'snow-rain',
};

export const icons = {
    1: <ForecastSun />,
    2: <ForecastSun />,
    3: <ForecastSun />,
    4: <ForecastSun />,
    5: <ForecastPartiallyCloudy />,
    6: <ForecastPartiallyCloudy />,
    7: <ForecastPartiallyCloudy />,
    8: <ForecastPartiallyCloudy />,
    9: <ForecastPartiallyCloudy />,
    10: <ForecastThunder />,
    11: <ForecastThunder />,
    12: <ForecastThunder />,
    13: <ForecastHazy />,
    14: <ForecastHazy />,
    15: <ForecastHazy />,
    16: <ForecastFog />,
    17: <ForecastFog />,
    18: <ForecastFog />,
    19: <ForecastCloudy />,
    20: <ForecastCloudy />,
    21: <ForecastCloudy />,
    22: <ForecastOvercast />,
    23: <ForecastRain />,
    24: <ForecastSnow />,
    25: <ForecastRain />,
    26: <ForecastSnow />,
    27: <ForecastRainThunder />,
    28: <ForecastRain />,
    29: <ForecastSnow />,
    30: <ForecastRain />,
    31: <ForecastRain />,
    32: <ForecastSnow />,
    33: <ForecastRain />,
    34: <ForecastSnow />,
    35: <ForecastSnowRain />,
};

export const getWeatherIcon = (iconNumber: keyof typeof icons): React.ReactElement | null => icons[iconNumber] || null;
