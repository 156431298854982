import { RequestActionType } from 'commons/src/actions/requestActions';
import { SelectGroup } from 'commons/src/actions/selectGroupActions';
import { BuildingSummaryHealthStatus } from '../models/buildingModels';
import { BusinessActionRequestType } from '../models/common';
import { PartnerCustomerDetails } from '../models/partner';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum PartnerActions {
    FetchPartnerDetailsInit = 'FETCH_PARTNER_DETAILS/INIT',
    FetchPartnerDetailsSuccess = 'FETCH_PARTNER_DETAILS_SUCCESS',
    TogglePartnerBrandingInit = 'TOGGLE_PARTNER_BRANDING/INIT',
    TogglePartnerBrandingSuccess = 'TOGGLE_PARTNER_BRANDING_SUCCESS',
    FetchOfflineDevicesInit = 'FETCH_OFFLINE_DEVICES/INIT',
    FetchOfflineDevicesSuccess = 'FETCH_OFFLINE_DEVICES_SUCCESS',
}

export interface FetchPartnerDetails extends BusinessActionRequestType {
    type: PartnerActions.FetchPartnerDetailsInit;
}

export const fetchPartnerDetails = (): FetchPartnerDetails => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchPartnerDetails,
    type: PartnerActions.FetchPartnerDetailsInit,
});

export interface TogglePartnerBranding extends BusinessActionRequestType {
    type: PartnerActions.TogglePartnerBrandingInit;
    partnerBranding: boolean;
}
export const togglePartnerBranding = (partnerBranding: boolean): TogglePartnerBranding => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.TogglePartnerBranding,
    type: PartnerActions.TogglePartnerBrandingInit,
    partnerBranding,
});

export interface FetchPartnerOfflineDevices extends BusinessActionRequestType {
    type: PartnerActions.FetchOfflineDevicesInit;
}

export const fetchPartnerOfflineDevices = (): FetchPartnerOfflineDevices => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchPartnerOfflineDevices,
    type: PartnerActions.FetchOfflineDevicesInit,
});

export interface PartnerCustomersResponse {
    totalCustomers: number;
    customers: PartnerOfflineDevicesResponse[];
}

export interface PartnerOfflineDevicesResponse {
    userGroupId: string;
    customerName: string;
    totalLocations: number;
    totalDevices: number;
    offlineLocations: BuildingSummaryHealthStatus[];
}

export interface FetchPartnerOfflineDevicesSuccess {
    type: PartnerActions.FetchOfflineDevicesSuccess;
    payload: PartnerCustomersResponse;
}

export const fetchPartnerOfflineDevicesSuccess = (
    payload: PartnerCustomersResponse
): FetchPartnerOfflineDevicesSuccess => ({
    type: PartnerActions.FetchOfflineDevicesSuccess,
    payload,
});

export interface PartnerDetailsResponse {
    customers: PartnerCustomerDetails[];
    partnerBranding: boolean;
}

export interface FetchPartnerDetailsSuccess {
    type: PartnerActions.FetchPartnerDetailsSuccess;
    payload: PartnerDetailsResponse;
}

export const fetchPartnerDetailsSuccess = (payload: PartnerDetailsResponse): FetchPartnerDetailsSuccess => ({
    type: PartnerActions.FetchPartnerDetailsSuccess,
    payload,
});

export interface TogglePartnerBrandingSuccess {
    type: PartnerActions.TogglePartnerBrandingSuccess;
    partnerBranding: boolean;
}

export const togglePartnerBrandingSuccess = (partnerBranding: boolean): TogglePartnerBrandingSuccess => ({
    type: PartnerActions.TogglePartnerBrandingSuccess,
    partnerBranding,
});

export type PartnerAction = FetchPartnerDetails | TogglePartnerBranding | FetchPartnerOfflineDevices;
export type PartnerReducerActions =
    | SelectGroup
    | FetchPartnerDetailsSuccess
    | TogglePartnerBrandingSuccess
    | FetchPartnerOfflineDevicesSuccess;
