import React, { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { radonInsightPlaceholder } from 'commons/src/components/placeholder';
import { dayWeatherCodes } from 'commons/src/features/outdoorAirQualityPage/WeatherAttrs';
import { SensorTypes } from 'commons/src/models/commonEnums';
import {
    SensorData,
    AverageSensorPeriodValues,
    OutdoorSensorData,
    BuildingType,
    DeviceWithKeyInfo,
} from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import { generateImage } from '../GraphConfig';
import FooterSection from '../iaqInsight/FooterSection';
import { createObserverAndFetchSvg } from '../insightFunctions';
import OutdoorInsightGraphTile from './OutdoorInsightGraphTile';
import OutdoorResultSummary from './OutdoorResultSummary';

export type ParentProps = {
    sensors: string[];
    serialNumber: string;
    locationId: string;
    includeLogo: boolean;
    optionalBuildingProps: string[];
};

type StateProps = {
    sensorData: { [serialNumber: string]: SensorData };
    averageValues: AverageSensorPeriodValues;
    buildings: { [buildingId: string]: BuildingType };
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    outdoorData: {
        history: {
            [sensorType: string]: OutdoorSensorData;
        };
    };
    loading: boolean;
    reportId?: string;
};

export type DefaultProps = {
    oneWeekAgo?: Moment;
};

type Props = ParentProps & StateProps & DefaultProps;

export const OutdoorInsightResultViewComponent = (props: Props): React.ReactElement => {
    const {
        sensors,
        sensorData,
        loading,
        serialNumber,
        averageValues,
        outdoorData,
        locationId,
        buildings,
        includeLogo,
        devicesWithKeyInfo,
        reportId,
        oneWeekAgo,
        optionalBuildingProps,
    } = props;
    const building = buildings[locationId];
    const device = devicesWithKeyInfo[serialNumber];
    const { t: txt } = useTranslation();
    const [listOfImages, setListOfImages] = useState<{ data: string; sensorType: string }[]>([]);
    const [generateChartError, setChartError] = useState(false);
    const [numberOfGraphs, setNumberOfGraphs] = useState(0);

    const getSvg = async (): Promise<void> => {
        const images: { data: string; sensorType: string }[] = [];
        const updateListOfImages = (response: { data: string; serialNumber: string }): void => {
            images.push({ data: response.data, sensorType: response.serialNumber });
        };
        await setNumberOfGraphs(sensors.length);
        await sensors.forEach(sensor => {
            generateImage(updateListOfImages, setChartError, sensor);
        });
        await setListOfImages(images);
    };

    useEffect((): void => {
        if (!loading) {
            const element = document.getElementById('resultHeader');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
                const observer = createObserverAndFetchSvg('buildingOutdoorResultSummary', getSvg);
                observer.observe(document, {
                    childList: true,
                    subtree: true,
                });
            }
        }
    }, [loading]);

    const createWeatherArray = (sensorType: string): [number, keyof typeof dayWeatherCodes][] => {
        const outdoorHistoryDataForSensor = outdoorData?.history[sensorType];
        return outdoorHistoryDataForSensor?.weatherIcons || [];
    };

    const deviceSensorData = sensorData[serialNumber];

    const generatedSensorGraphs =
        oneWeekAgo &&
        building &&
        deviceSensorData &&
        !loading &&
        sensors
            .sort((sensorA, sensorB) => sensorA.localeCompare(sensorB))
            .map(sensorName => (
                <OutdoorInsightGraphTile
                    fromDate={oneWeekAgo}
                    header={sensorName}
                    key={`outdoor-graph-tile-${sensorName}-${serialNumber}`}
                    loading={loading}
                    indoorData={deviceSensorData[sensorName]}
                    averageIndoorValue={averageValues[serialNumber][sensorName]}
                    outdoorData={outdoorData.history[sensorName] ? outdoorData.history[sensorName].data : []}
                    unit={outdoorData.history[sensorName] && outdoorData.history[sensorName].unit}
                    building={building}
                    weatherIcons={sensorName === SensorTypes.temp ? createWeatherArray(sensorName) : []}
                />
            ));

    return (
        <div className="page-wrapper-flex page-wrapper-flex--content-margin">
            <div id="resultHeader" className="inline-header-lined">
                <h2 className="inline-header-lined__text">
                    {!loading ? txt('OutdoorInsight.OutdoorReportGenerated') : txt('Loading')}
                </h2>
            </div>
            <ReactPlaceholder ready={!loading} customPlaceholder={radonInsightPlaceholder}>
                {building && reportId && (
                    <OutdoorResultSummary
                        building={building}
                        generateChartError={generateChartError}
                        listOfImages={listOfImages}
                        device={device}
                        numberOfGraphs={numberOfGraphs}
                        serialNumber={serialNumber}
                        includeLogo={includeLogo}
                        reportId={reportId}
                        optionalBuildingProps={optionalBuildingProps}
                    />
                )}
                {!loading && generatedSensorGraphs}
                {reportId && <FooterSection deviceTypes={[device.type]} comment="" reportId={reportId} />}
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        deviceSensorData: { sensorData, averageValues },
        buildings: { buildings },
        devicePage: { fetching: fetchingSensorData },
        devices: { devicesWithKeyInfo },
        outdoorInsight: { outdoorData, reportId },
        requests: {
            [RequestType.FetchOutdoorInsightData]: { loading },
        },
    } = state;

    return {
        sensorData,
        outdoorData,
        averageValues,
        loading: loading || fetchingSensorData,
        buildings,
        devicesWithKeyInfo,
        reportId,
    };
};

OutdoorInsightResultViewComponent.defaultProps = {
    oneWeekAgo: moment().subtract(1, 'weeks'),
};

export default connect(mapStateToProps)(OutdoorInsightResultViewComponent);
