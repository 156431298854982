import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import RequestActions from 'commons/src/models/RequestTypes';
import {
    AddPublicDashboard,
    AddPublicDashboardSuccess,
    addPublicDashboardSuccess,
    DashboardActionType,
    DeletePublicDashboard,
    EditPublicDashboardSettings,
    editPublicDashboardSettingsSuccess,
    fetchDashboard,
    SelectDashboard,
    selectDashboard,
} from '../../actions/DashboardActions';
import { RequestActionType, requestError, requestSuccess } from '../../actions/requestActions';
import { createPublicDashboard, deletePublicDashboard, editPublicDashboardProps } from '../../api/dashboard';
import { getDashboardPath } from '../../commonFunctions';
import { CurrentDashboardResponse } from '../../models/commonTypeScript';
import { CommonRequestType } from '../../reducers/requestReducer';
import history from '../../store/history';
import { toErrorType } from '../isErrorType';
import { createSensorGraphArrays } from './dashboardData';

type CreatePublicDashboardSagaActionType = Generator<
    | CallEffect<CurrentDashboardResponse>
    | PutEffect<AddPublicDashboardSuccess>
    | PutEffect<SelectDashboard>
    | RequestActions
    | void,
    void,
    CurrentDashboardResponse
>;
export function* createPublicDashboardSaga({ payload }: AddPublicDashboard): CreatePublicDashboardSagaActionType {
    try {
        const response = yield call(createPublicDashboard, payload);
        yield put(
            addPublicDashboardSuccess(
                createSensorGraphArrays(response, { sensorData: {}, minValues: {}, averageValues: {} })
            )
        );
        yield put(requestSuccess(CommonRequestType.AddPublicDashboard, RequestActionType.Success));
        yield put(selectDashboard(response.id));
        const dashboardPath = getDashboardPath();
        yield history.push(dashboardPath);
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.AddPublicDashboard, RequestActionType.Error));
    }
}

export function* editPublicDashboardSettingsSaga({ payload }: EditPublicDashboardSettings): Generator {
    try {
        yield call(editPublicDashboardProps, payload);
        yield put(editPublicDashboardSettingsSuccess(payload));
        yield put(requestSuccess(CommonRequestType.EditPublicCDashboardSettings, RequestActionType.Success));
        const dashboardPath = getDashboardPath();
        yield history.push(dashboardPath);
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.EditPublicCDashboardSettings, RequestActionType.Error));
    }
}

export function* deletePublicDashboardSaga({ id }: DeletePublicDashboard): Generator {
    try {
        yield call(deletePublicDashboard, id);
        yield put(requestSuccess(CommonRequestType.DeletePublicDashboard, RequestActionType.Success));
        yield put(fetchDashboard());
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.DeletePublicDashboard, RequestActionType.Error));
    }
}

export default function* takePublicDashboardActions(): Generator {
    yield all([
        takeEvery(DashboardActionType.AddPublicDashboard, createPublicDashboardSaga),
        takeEvery(DashboardActionType.EditPublicDashboardSettings, editPublicDashboardSettingsSaga),
        takeEvery(DashboardActionType.DeletePublicDashboard, deletePublicDashboardSaga),
    ]);
}
