import React from 'react';
import classNames from 'classnames';
import { Column, useSortBy, useTable } from 'react-table';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';
import { EmptyTable } from './common';

type SortedArrowProps = {
    isSortedDesc?: boolean;
};

type Props = {
    tableData:
        | {
              id: string;
              name: string;
              lastSeen: string | undefined;
              deviceType: AnyDeviceType;
              suggestedAction: string;
          }[]
        | {
              id: string;
              name: string;
              offlineHubs: string;
              offlineSensorDevices: string;
          }[];
    columns: Column[];
    darkHeader?: boolean;
    emptyTableMessage: string;
    styles: { [key: string]: string };
};

const SortedArrow = ({ isSortedDesc }: SortedArrowProps): React.ReactElement =>
    isSortedDesc ? <MaterialIcon name="arrow_drop_up" /> : <MaterialIcon name="arrow_drop_down" />;

const BuildingHealthTable = ({
    darkHeader,
    columns,
    tableData,
    emptyTableMessage,
    styles,
}: Props): React.ReactElement => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data: tableData,
        },
        useSortBy
    );

    return (
        <div {...getTableProps()} className={styles.table}>
            {headerGroups.map(headerGroup => (
                <div
                    {...headerGroup.getHeaderGroupProps({
                        className: darkHeader ? `${styles.header} ${styles.darkHeader}` : styles.header,
                    })}
                >
                    {headerGroup.headers.map(column => (
                        <div
                            role="button"
                            tabIndex={0}
                            onKeyUp={(e): void => {
                                if (e.key === 'Enter') column.toggleSortBy();
                            }}
                            {...column.getHeaderProps([
                                { className: classNames(styles.cell, column.className) },
                                column.getSortByToggleProps(),
                            ])}
                        >
                            {column.isSorted ? <SortedArrow isSortedDesc={column.isSortedDesc} /> : <div />}
                            <span>{column.render('Header')}</span>
                        </div>
                    ))}
                </div>
            ))}
            <div {...getTableBodyProps()}>
                {rows.length > 0 ? (
                    rows.map(row => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps({ className: styles.row })}>
                                {row.cells.map(({ getCellProps, render, column }) => {
                                    return (
                                        <div
                                            {...getCellProps({
                                                className: classNames(styles.cell, column.className),
                                            })}
                                        >
                                            <span>{render('Cell')}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })
                ) : (
                    <EmptyTable emptyTableText={emptyTableMessage} />
                )}
            </div>
        </div>
    );
};
export default BuildingHealthTable;
