import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import CheckBox from 'commons/src/components/input/Checkbox';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { GetOrganizationLogo, getOrganizationLogo } from '../../../actions/organizationPropertiesActions';
import { paths } from '../../../constants';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';

type ParentProps = {
    includeLogo: boolean;
    updateIncludeLogo: (included: boolean) => void;
};
type ActionProps = {
    fetchLogo: () => void;
};

type StateProps = {
    logo?: string;
    loading: boolean;
};
type Props = ParentProps & StateProps & ActionProps;

export const LogoSectionComponent = (props: Props): React.ReactElement => {
    const { logo, includeLogo, fetchLogo, updateIncludeLogo, loading } = props;

    const { t: txt } = useTranslation();
    const [logoError, setLogoError] = useState(false);
    useEffect(() => {
        fetchLogo();
    }, []);

    const onChange = (): void => {
        if (logo) {
            updateIncludeLogo(!includeLogo);
        } else if (!loading) {
            setLogoError(true);
        }
    };

    return (
        <div>
            <div className="insight-tile__header insight-tile__header--padded insight-tile__header--no-border">
                <h2>{txt('Insight.Logo')}</h2>
            </div>
            <div className="form__row form__row--padded-small">
                <CheckBox
                    id="logo"
                    label={<span>{txt('Insight.CompanyLogoInPDF')}</span>}
                    labelIsElement
                    checked={includeLogo}
                    onChange={onChange}
                    disabled={loading}
                />
            </div>
            {logoError && (
                <div className="error-message error-message--with-icon">
                    <MaterialIcon name="error_outline" />
                    <Trans i18nKey="Insight.UploadLogoInOrgSettingsToInclude">
                        <Link to={`/${paths.organization}`} />
                    </Trans>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        organizationProperties: { logoImage: logo },
        requests: {
            [BusinessRequestType.GetOrganizationLogo]: { loading },
        },
    } = state;
    return {
        logo,
        loading,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    fetchLogo: (): GetOrganizationLogo => dispatch(getOrganizationLogo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoSectionComponent);
