import {
    FETCH_THIRD_PARTY_INTEGRATIONS_SUCCESS,
    FETCH_THIRD_PARTY_INTEGRATION_MAPPING_SUCCESS,
    ThirdPartyIntegrationActionType,
    ADD_THIRD_PARTY_INTEGRATION_SUCCESS,
    ADD_NOTIFICATION_ALERT_SUCCESS,
    UPDATE_THIRD_PARTY_ALERT_SUCCESS,
    FETCH_ALERT_EVENTS_SUCCESS,
    ThirdPartyIntegrationReducerActions,
} from '../actions/thirdPartyIntegrationActions';
import {
    ThirdPartyAddedIntegration,
    ThirdPartyIntegration,
    ThirdPartyIntegrationMapping,
    ThirdPartyIntegrationAlert,
    NotificationAlertEvent,
} from '../models/common';

export type ThirdPartyIntegrationsState = {
    thirdPartyIntegrations: ThirdPartyIntegration[];
    thirdPartyMapping: ThirdPartyIntegrationMapping | undefined;
    integration: ThirdPartyAddedIntegration | undefined;
    notificationAlerts: ThirdPartyIntegrationAlert[];
    alertEvents: NotificationAlertEvent[];
};

const initialState: ThirdPartyIntegrationsState = {
    thirdPartyIntegrations: [],
    thirdPartyMapping: undefined,
    integration: undefined,
    notificationAlerts: [],
    alertEvents: [],
};

const { FetchThirdPartyIntegrationsInit, UpdateNotificationAlertActiveStateInit } = ThirdPartyIntegrationActionType;

export default (
    state: ThirdPartyIntegrationsState = initialState,
    action: ThirdPartyIntegrationReducerActions
): ThirdPartyIntegrationsState => {
    switch (action.type) {
        case FetchThirdPartyIntegrationsInit:
            return {
                ...state,
            };
        case FETCH_THIRD_PARTY_INTEGRATIONS_SUCCESS:
            return {
                ...state,
                thirdPartyIntegrations: action.thirdPartyIntegrations,
                notificationAlerts: action.notificationAlerts,
            };
        case ADD_NOTIFICATION_ALERT_SUCCESS: {
            const newNotificationAlert = {
                ...action.notificationAlert,
                integrationId: action.integrationId,
                integrationType: action.integrationType,
                id: action.triggerId,
            };
            const updatedAlertList = [...state.notificationAlerts, newNotificationAlert];
            return {
                ...state,
                notificationAlerts: updatedAlertList,
            };
        }
        case FETCH_ALERT_EVENTS_SUCCESS: {
            return {
                ...state,
                alertEvents: action.alertEvents,
            };
        }
        case ADD_THIRD_PARTY_INTEGRATION_SUCCESS:
            return {
                ...state,
                integration: action.integration,
            };
        case FETCH_THIRD_PARTY_INTEGRATION_MAPPING_SUCCESS:
            return {
                ...state,
                thirdPartyMapping: action.thirdPartyMapping,
            };
        case UPDATE_THIRD_PARTY_ALERT_SUCCESS: {
            const indexOfEditedAlert = state.notificationAlerts.findIndex(
                notificationAlert => notificationAlert.id === action.notificationAlert.triggerId
            );
            const updatedAlert = {
                ...action.notificationAlert.trigger,
                integrationId: action.notificationAlert.integrationId,
                id: action.notificationAlert.triggerId,
                integrationType: action.notificationAlert.integrationType,
            };
            const copyOfAlertList = [...state.notificationAlerts];
            copyOfAlertList[indexOfEditedAlert] = updatedAlert;
            return {
                ...state,
                notificationAlerts: copyOfAlertList,
            };
        }
        case UpdateNotificationAlertActiveStateInit: {
            const indexOfEditedAlert = state.notificationAlerts.findIndex(
                notificationAlert => notificationAlert.id === action.notificationAlert.triggerId
            );
            const copyOfAlertList = [...state.notificationAlerts];
            copyOfAlertList[indexOfEditedAlert] = {
                ...action.notificationAlert.trigger,
                id: action.notificationAlert.triggerId,
                integrationId: action.notificationAlert.integrationId,
                integrationType: action.notificationAlert.integrationType,
            };
            return {
                ...state,
                notificationAlerts: copyOfAlertList,
            };
        }
        default:
            return state;
    }
};
