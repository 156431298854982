import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { ACCOUNT_VIEWED_OVERVIEW } from 'commons/src/analytics/AnalyticsEvents';
import PageHeader from 'commons/src/components/headers/PageHeader';
import BusinessAccountSubMenus from './businessMenuConfig';

const BusinessAccountPageLayout = (): React.ReactElement => {
    useEffect((): void => {
        analyticsLogger(ACCOUNT_VIEWED_OVERVIEW, { pageType: PageType.Account });
    }, []);
    const { t: txt } = useTranslation();
    return (
        <>
            <PageHeader title={txt('Account')} tabs={BusinessAccountSubMenus} />
            <div className="page-wrapper__medium">
                <Outlet />
            </div>
        </>
    );
};

export default BusinessAccountPageLayout;
