import React from 'react';
import { useTranslation } from 'react-i18next';
import { hubConnectionIsUp, lastSyncedDate } from '../../commonFunctions';
import { dateFormats } from '../../constants';
import HubIcon from '../../img/deviceIcons/new-icon-hub-text_48px';
import styles from './HubListHeader.module.scss';

export type Props = {
    name: string;
    lastSeen: string | undefined;
    serialNumber: string;
    dateFormat: string;
};

const HubListHeader = ({ name, lastSeen, dateFormat, serialNumber }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const hubRecentlySynced = hubConnectionIsUp(lastSeen);
    const lastSynced = lastSeen && lastSyncedDate(lastSeen, dateFormat as keyof typeof dateFormats);

    return (
        <div className={styles.header}>
            <div className={styles.section}>
                <HubIcon />
            </div>
            <div className={styles.section}>
                <div>
                    <h2 className={styles.name}>{name}</h2>
                    <span className={styles.address}>{serialNumber}</span>
                    {!hubRecentlySynced && lastSeen && (
                        <div className={styles.lastSynced}>{[txt('LastConnection'), ': ', lastSynced]}</div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default HubListHeader;
