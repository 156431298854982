import moment from 'moment';
import { takeEvery, put, call, PutEffect, CallEffect } from 'redux-saga/effects';
import {
    FETCH_SENSOR_SEGMENT,
    fetchSensorSegmentSuccess,
    fetchSensorSegmentError,
    pollDashboardSensorData,
    FetchSensorSegment,
} from '../actions/DashboardActions';
import fetchSegment from '../api/segment';
import { ChartDataType, DeviceResponse, SelectedPeriod } from '../models/commonTypeScript';
import { createChartData } from './DashboardSagas/fetchDashboardSensorTile';

export function createGraphArrays(
    device: DeviceResponse,
    timeRange: SelectedPeriod,
    sensorType: string
): {
    chartData: ChartDataType;
    minValues: { [fetchedPeriod: string]: number | undefined };
    averageValues: { [fetchedPeriod: string]: number | undefined };
} {
    const segmentStartTime = moment(`${device.segmentStart}Z`).valueOf();
    const sensor = device.sensors.find(sensorObject => sensorObject.type === sensorType);
    const graphData = createChartData(device, sensor, segmentStartTime);

    return {
        chartData: { [timeRange.name]: graphData.chartData || [] },
        minValues: { [timeRange.name]: graphData.minValue },
        averageValues: { [timeRange.name]: graphData.averageValue },
    };
}

export function* fetchSensorSegmentSaga(
    { payload }: FetchSensorSegment,
    isoTime = moment()
): Generator<PutEffect | CallEffect, void, DeviceResponse> {
    const { serialNumber, selectedInterval, sensorType, tileId } = payload;
    const { number, period, resolutionDuration } = selectedInterval;
    const ref = `${serialNumber}-${sensorType}`;

    const toISO = isoTime.toISOString();
    const to = toISO.slice(0, toISO.lastIndexOf('.'));

    const fromISOTime = isoTime.clone().subtract(number, period).toISOString();
    const fromISO = moment(fromISOTime).subtract(resolutionDuration).toISOString();
    const from = fromISO.slice(0, fromISO.lastIndexOf('.'));

    try {
        const response = yield call(fetchSegment, serialNumber, {
            from,
            to,
            resolution: selectedInterval.resolution,
            sensors: sensorType,
        });
        yield put(
            fetchSensorSegmentSuccess({
                sensorData: createGraphArrays(response, selectedInterval, sensorType),
                ref,
            })
        );
        yield put(
            pollDashboardSensorData({
                serialNumber,
                selectedInterval,
                sensorType,
                device: response,
                ref,
                tileId,
            })
        );
    } catch (error) {
        yield put(fetchSensorSegmentError(error));
    }
}

export default function* fetchSensorSegmentSagas(): Generator {
    yield takeEvery(FETCH_SENSOR_SEGMENT, fetchSensorSegmentSaga);
}
