import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import {
    PageLine,
    Paragraph,
    SensorColumns,
    SubHeader,
    FlexWrapperSpaced,
    Graph,
} from 'commons/src/components/PDF/PdfConstants';
import { sensorFullNameKey, sensorUnits } from 'commons/src/constants';
import { ThresholdRange } from 'commons/src/models/commonTypeScript';
import { IaqInsightData, PDFContext } from '../../../../models/common';
import AverageValues from './AverageValues';
import ResultTable from './ResultTable';
import ThresholdRanges from './Thresholds';

const styles = StyleSheet.create({
    viewSize: { width: '52vw' },
});

type Props = {
    txt: PDFContext;
    sensor: string;
    ranges: ThresholdRange[];
    unit: keyof typeof sensorUnits;
    report: IaqInsightData;
    listOfImages: { data: string; serialNumber: string }[];
    sensorColumns: SensorColumns;
};

const SensorResult = (props: Props): React.ReactElement => {
    const { txt, sensor, ranges, unit, report, listOfImages, sensorColumns } = props;
    const image = listOfImages.length > 0 && listOfImages.find(chart => chart.serialNumber === sensor);
    const sensorReplacedSubText = txt(sensorFullNameKey(sensor)).replace('CO₂', 'CO2');
    const sensorDescriptionReplaceSubText = txt(`InsightIaq.${sensor}Description`).replace(/CO₂/gi, 'CO2');
    return (
        <View>
            <View wrap={false}>
                <FlexWrapperSpaced>
                    <View style={styles.viewSize}>
                        <SubHeader>{sensorReplacedSubText}</SubHeader>
                        <ThresholdRanges ranges={ranges} unit={unit} sensorType={sensor} txt={txt} />
                    </View>
                    <View>
                        <AverageValues
                            txt={txt}
                            value={report.averageValueWithinOpeningHours}
                            ranges={ranges}
                            unit={unit}
                            headerText="InsightIaq.AverageWithinOpeningHours"
                            sensor={sensor}
                            displayCircle
                        />
                        <AverageValues
                            txt={txt}
                            value={report.averageValue}
                            ranges={ranges}
                            unit={unit}
                            headerText="InsightIaq.AverageValueNoException"
                            sensor={sensor}
                            displayCircle={false}
                        />
                    </View>
                </FlexWrapperSpaced>
                <Paragraph>{sensorDescriptionReplaceSubText}</Paragraph>
                {image && image.data && <Graph src={image.data} />}
            </View>
            <ResultTable sensor={sensor} txt={txt} ranges={ranges} unit={unit} sensorColumns={sensorColumns} />
            <PageLine />
        </View>
    );
};

export default SensorResult;
