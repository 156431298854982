import * as React from 'react';
import { useTranslation } from 'react-i18next';

export type Props = {
    error?: boolean;
    untranslatedErrorTitle?: string;
    untranslatedErrorMessage?: string;
    children: React.ReactElement;
};

const ModalErrorCatcher = ({
    error,
    children,
    untranslatedErrorMessage,
    untranslatedErrorTitle,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    if (error) {
        return (
            <div className="modal__content__description modal__content__description--small">
                {txt(untranslatedErrorTitle || 'ErrorTitle')} {txt(untranslatedErrorMessage || 'GenerateReportError')}
            </div>
        );
    }
    return children;
};

export default ModalErrorCatcher;
