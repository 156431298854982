import { takeEvery, call, put, select, all, CallEffect, PutEffect } from 'redux-saga/effects';
import {
    UPDATE_DASHBOARD_LAYOUT,
    updateDashboardLayoutSuccess,
    updateDashboardLayoutFailure,
    UpdateDashboardLayout,
    FetchDashboardDeviceTile,
    updateDashboardLayout,
    FETCH_DASHBOARD_DEVICE_TILE,
} from '../../actions/DashboardActions';
import { updateDashboardConfig } from '../../api/dashboard';
import { fetchDeviceCurrentValues } from '../../api/devices';
import { getDashboardPath } from '../../commonFunctions';
import { DashboardTile, DeviceTileContent, SensorValueTileContent } from '../../models/commonTypeScript';
import { demoMode } from '../../reducers/reducerShortcuts';
import history from '../../store/history';

export function handleNewModule(): void {
    const dashboardPath = getDashboardPath();
    history.push(dashboardPath);
    let lastItem: Element;
    setTimeout(() => {
        const itemsArray = document.querySelectorAll('.react-grid-item');
        lastItem = itemsArray[itemsArray.length - 1];
        lastItem.className = 'react-grid-item react-draggable added-module';
        lastItem.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }, 200);
    setTimeout(() => {
        lastItem.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }, 600);
}

export function* setDashboardConfigSaga({ config }: UpdateDashboardLayout): Generator {
    const { newModule, dashboardId } = config;
    try {
        const isDemoMode = yield select(demoMode);

        if (!isDemoMode) {
            const tiles = config.tiles.map(tile => ({ ...tile, content: undefined }));
            yield call(updateDashboardConfig, { tiles }, dashboardId);
        }
        if (newModule) {
            yield call(handleNewModule);
        }
        yield put(updateDashboardLayoutSuccess('ConfigSaved'));
    } catch (error) {
        const errorMessage = error.error ? `ErrorCodes.${error.error}` : undefined;
        const statusMessage = newModule ? 'AddNewTileError' : 'ConfigChangeFailed';
        yield put(updateDashboardLayoutFailure(errorMessage || statusMessage));
    }
}

type FetchDashboardDeviceTileSagaActionType = Generator<
    CallEffect<DeviceTileContent | SensorValueTileContent> | PutEffect,
    void,
    DeviceTileContent | SensorValueTileContent
>;
export function* fetchDashboardDeviceTileSaga({
    payload,
}: FetchDashboardDeviceTile): FetchDashboardDeviceTileSagaActionType {
    const { tiles, tileData, dashboardId, serialNumber, sensor, newModule } = payload;
    try {
        const newTileData = yield call(fetchDeviceCurrentValues, serialNumber, sensor);
        const newTileConfig: DashboardTile = { ...tileData, content: newTileData };
        yield put(
            updateDashboardLayout({
                tiles: [...tiles, newTileConfig],
                newModule: true,
                dashboardId,
            })
        );
    } catch (error) {
        const errorMessage = error.error ? `ErrorCodes.${error.error}` : undefined;
        const statusMessage = newModule ? 'AddNewTileError' : 'ConfigChangeFailed';
        yield put(updateDashboardLayoutFailure(errorMessage || statusMessage));
    }
}

export default function* takeDashboardConfigActions(): Generator {
    yield all([
        takeEvery(UPDATE_DASHBOARD_LAYOUT, setDashboardConfigSaga),
        takeEvery(FETCH_DASHBOARD_DEVICE_TILE, fetchDashboardDeviceTileSaga),
    ]);
}
