import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { CustomerListElement } from '../../../models/partner';
import { subAccountLandingPage } from '../customerPage/PartnerSubAccountMenus';
import { CellRenderResult, CustomerListHeader } from './CustomerListElement';
import styles from './CustomerTableRowSlim.module.scss';

type Props = {
    customer: CustomerListElement;
    headers: CustomerListHeader[];
    newCustomerId?: string;
};

const Cell = ({ children, title }: { children: CellRenderResult; title: string }): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className={styles.element}>
            <span className={styles.elementName}>{txt(title)}</span>
            <span className={styles.elementValue}>{children}</span>
        </div>
    );
};

const RenderColumn = ({
    header,
    customer,
}: {
    header: CustomerListHeader;
    customer: CustomerListElement;
}): ReactElement => {
    switch (header.objectKey) {
        case 'name':
            return <h3 className={styles.header}>{header.render(customer)}</h3>;
        default:
            return <Cell title={header.label}>{header.render(customer)}</Cell>;
    }
};

const CustomerTableRowSlimComponent = ({ customer, headers, newCustomerId }: Props): React.ReactElement => {
    const isNewCustomer = newCustomerId === customer.userGroupId;
    return (
        <NavLink
            to={`/${subAccountLandingPage(customer)}`}
            className={classNames(styles.tableRow, {
                [styles.newCustomer]: isNewCustomer,
            })}
        >
            {headers.map(header => (
                <RenderColumn header={header} customer={customer} key={header.objectKey} />
            ))}
        </NavLink>
    );
};

export default CustomerTableRowSlimComponent;
