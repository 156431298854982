/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as Highcharts from 'highcharts';
import { dateFormats, tooltipBorderColor, colors } from 'commons/src/constants';
import humanizedTimeString from './buildingInsightCommonFunctions';

dayjs.extend(utc);
dayjs.extend(timezone);

type GraphProps = {
    chartData: [number, number][];
    chartDataLowerThresholdBreaches: [number, number][];
    dateFormat: keyof typeof dateFormats;
    onClick: (clickEvent: PointerEvent) => void;
    displayLegends: boolean;
    isHourFormat: boolean;
    highValue: string;
    lowValue: string | undefined;
    chartHeight: number;
    text: (text: string) => string;
    allowGraphClick: boolean;
    graphId: string;
    language?: string;
    timeZone?: string;
};
const generateConfig = ({
    chartData,
    chartDataLowerThresholdBreaches,
    dateFormat,
    onClick,
    displayLegends,
    isHourFormat,
    highValue,
    lowValue,
    chartHeight,
    text,
    allowGraphClick,
    graphId,
    language,
    timeZone,
}: GraphProps) => ({
    credits: {
        enabled: false,
    },
    title: {
        text: '',
    },
    chart: {
        id: graphId,
        height: chartHeight,
        type: 'column',
        spacingBottom: 5,
        className: 'building-insight__card__section__graph--details',
        style: {
            fontFamily: 'OpenSans-Regular',
        },
    },
    tooltip: {
        borderColor: tooltipBorderColor,
        style: {
            color: colors.white,
        },
        enabled: !!language,
        hideDelay: 0,
        shared: true,
        formatter(this: Highcharts.TooltipFormatterContextObject): string {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { x, points } = this;
            const outsideTime = (points || []).reduce((total, thresholdBreachTime) => {
                return total + (thresholdBreachTime.y || 0);
            }, 0);
            const readableYValue = humanizedTimeString(outsideTime as number);
            const day = timeZone
                ? `${dayjs.tz(x, timeZone).format('dddd')} <br/> ${dayjs
                      .tz(x, timeZone)
                      .format(dateFormats[dateFormat].format)}`
                : '';
            const timeStamp = timeZone ? dayjs.tz(x, timeZone).format(dateFormats[dateFormat].timeOfDay) : '';
            return `${day}, ${isHourFormat ? timeStamp : ''} <br/>${readableYValue}`;
        },
    },
    xAxis: {
        lineColor: 'transparent',
        tickLength: 0,
        type: 'dateTime',
        gridLineWidth: 0,
        tickInterval: isHourFormat ? 6 * 3600 * 1000 : 24 * 3600 * 1000,
        margin: 0,
        labels: {
            formatter(): string | React.ReactElement {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const { value } = this;
                const timeStamp = timeZone ? dayjs.tz(value, timeZone).format(dateFormats[dateFormat].timeOfDay) : '';
                const momentDate = timeZone ? dayjs.utc(value).format(dateFormats[dateFormat].momentWeek) : '';
                return isHourFormat ? `<strong> ${momentDate}</strong> ${timeStamp}` : momentDate;
            },
        },
    },
    yAxis: {
        min: 0,
        gridLineWidth: 1,
        tickInterval: 60,
        title: {
            text: null,
        },
        labels: {
            formatter(): string {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const { value } = this;
                const yValue = value / 60;
                return `${yValue}${text('TimeUnit.HoursShort')}`;
            },
        },
    },
    series: [
        {
            name: `${text('BuildingInsight.Over')} ${highValue}`,
            data: chartData,
            color: colors.redPersian,
            className: 'building-insight__card__section__graph',
        },
        {
            name: `${text('BuildingInsight.Under')} ${lowValue}`,
            data: chartDataLowerThresholdBreaches,
            color: colors.blueCelestial,
            className: 'building-insight__card__section__graph--lower',
        },
    ],
    plotOptions: {
        series: {
            type: 'column',
            stacking: 'normal',
            showInLegend: displayLegends,
            pointWidth: 15,
            connectNulls: false,
            marker: {
                enabled: false,
                symbol: 'circle',
            },
            states: {
                hover: { lineWidthPlus: 0 },
            },
            allowPointSelect: allowGraphClick,
            animation: { duration: 1200 },
            borderRadius: 4,
            events: {
                click(event: PointerEvent): void {
                    onClick(event);
                },
            },
        },
    },
    exporting: {
        sourceWidth: chartHeight === 125 ? 600 : 1000,
        sourceHeight: chartHeight,
        chartOptions: {
            subtitle: null,
        },
        buttons: {
            contextButton: {
                enabled: false,
            },
        },
    },
});

export default generateConfig;

export type GenerateConfig = ReturnType<typeof generateConfig>;
