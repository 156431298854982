import React from 'react';
import classNames from 'classnames';

// Import helpers
import { rssiThresholds } from '../../constants';

// Import styles
import styles from './RssiSignalStrength.module.scss';

type Colors = 'none' | 'red' | 'yellow' | 'blue' | 'green' | 'black';

export type Props = {
    rssi?: number;
    color?: Colors;
    inverted?: boolean;
    displayDescription?: boolean;
    isHubConnectionLost?: boolean;
    height?: number;
};

const RssiSignalStrength = ({
    rssi,
    inverted = false,
    displayDescription = false,
    isHubConnectionLost = false,
    height = 11,
    color = 'black',
}: Props): React.ReactElement | null => {
    if (Number.isNaN(Number(rssi))) return null;

    const signalLines = (): React.ReactElement[] =>
        [...Array(4)].map((_, i) => {
            let threshold = rssiThresholds.oneBars;
            if (i === 1) threshold = rssiThresholds.twoBars;
            if (i === 2) threshold = rssiThresholds.threeBars;
            if (i === 3) threshold = rssiThresholds.fourBars;
            const key = `rssi-signal-strength-${i}`;
            return (
                <div
                    key={key}
                    className={classNames(styles.rssi_bar, {
                        [styles.rssi_one_bar]: i === 0,
                        [styles.rssi_two_bars]: i === 1,
                        [styles.rssi_three_bars]: i === 2,
                        [styles.rssi_four_bars]: i === 3,
                        [styles[color]]: !!rssi && rssi > threshold,
                        [styles.disconnected]: isHubConnectionLost,
                        [styles.inverted]: inverted,
                    })}
                />
            );
        });

    return (
        <div
            style={{ '--barHeight': `${height}px` } as React.CSSProperties}
            className={classNames(styles.rssi_wrapper, {
                [styles.rssi_with_description]: displayDescription,
            })}
        >
            <div className={styles.rssi_wrapper}>{signalLines()}</div>
            {displayDescription && (
                <span className={styles.rssi_desctiption}>
                    {rssi}
                    {' dBm'}
                </span>
            )}
        </div>
    );
};

export default RssiSignalStrength;
