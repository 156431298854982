import React, { useEffect, useState } from 'react';
import { Document, Font, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { analyticsLogger, PageType, ReportType } from 'commons/src/analytics';
import { INSIGHT_DOWNLOADED_REPORT } from 'commons/src/analytics/AnalyticsEvents';
import {
    Body,
    HeaderBlock,
    Logo,
    LogoContainer,
    PageLine,
    Paragraph,
    SubHeaderNoMargin,
    Footer,
    SmallLogo,
} from 'commons/src/components/PDF/PdfConstants';
import PdfDownloadButton from 'commons/src/components/PDF/PdfDownloadButton';
import ReportDetails from 'commons/src/components/PDF/ReportDetails';
import { dateFormats, sensorUnits } from 'commons/src/constants';
import DemiBold from 'commons/src/fonts/open-sans-v28-latin-600.ttf';
import RegularFont from 'commons/src/fonts/open-sans-v28-latin-regular.ttf';
import { BuildingType, DeviceWithKeyInfo, SegmentProperties } from 'commons/src/models/commonTypeScript';
import BusinessLogo from '../../../../img/logos/business-logo.png';
import { PDFContext, RadonInsightSensorData } from '../../../../models/common';
import BuildingDetails from '../BuildingDetails';
import DeviceTile from './DeviceTile';
import Summary from './Summary';
import Thresholds from './Thresholds';

Font.register({ family: 'DemiBold', src: DemiBold });
Font.register({ family: 'Regular', src: RegularFont });

const styles = StyleSheet.create({
    headerText: {
        fontSize: 25,
        fontFamily: 'DemiBold',
    },
    bold: {
        fontFamily: 'DemiBold',
    },
    smallText: {
        fontSize: 10,
    },
});

type GenerateProps = {
    dateFormat: keyof typeof dateFormats;
    toDate: string;
    fromDate: string;
    chartImages: { data: string; serialNumber: string }[];
    building: BuildingType;
    devices: { [serialNumber: string]: DeviceWithKeyInfo };
    segmentProperties: { [serialNumber: string]: SegmentProperties };
    userName: string;
    thresholds: number[];
    unit: keyof typeof sensorUnits;
    reports: RadonInsightSensorData[];
    reportId: string;
    devicesInEachLevel: { good: number; fair: number; poor: number };
    logo?: string;
};

type PdfProps = {
    reportPeriodHeader: string;
    headerValues: string[];
    unit: keyof typeof sensorUnits;
    segmentProperties: { [serialNumber: string]: SegmentProperties };
    thresholds: number[];
    building: BuildingType;
    chartImages: { data: string; serialNumber: string }[];
    devices: { [serialNumber: string]: DeviceWithKeyInfo };
    txt: PDFContext;
    dateFormat: keyof typeof dateFormats;
    userName: string;
    reports: RadonInsightSensorData[];
    reportId: string;
    devicesInEachLevel: { good: number; fair: number; poor: number };
    logo?: string;
};

// Create Document Component
const PdfDocument = ({
    reportPeriodHeader,
    headerValues,
    thresholds,
    unit,
    chartImages,
    building,
    segmentProperties,
    txt,
    devices,
    dateFormat,
    reportId,
    userName,
    devicesInEachLevel,
    reports,
    logo,
}: PdfProps): React.ReactElement => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Document>
        <Body>
            <HeaderBlock>
                <View>
                    <Text style={styles.headerText}>{txt('RadonInsight.RadonMeasurementReport')}</Text>
                    <SubHeaderNoMargin>{txt('RadonInsight.ReportSubtext')}</SubHeaderNoMargin>
                    <Paragraph>{reportPeriodHeader}</Paragraph>
                </View>
                <LogoContainer>
                    <Logo src={logo || BusinessLogo} />
                </LogoContainer>
            </HeaderBlock>
            <BuildingDetails txt={txt} building={building} dateFormat={dateFormat} />
            <PageLine />
            <Summary txt={txt} devicesInEachLevel={devicesInEachLevel} />
            <Thresholds
                headerValues={headerValues}
                txt={txt}
                unit={unit}
                thresholds={thresholds}
                devicesInEachLevel={devicesInEachLevel}
            />
            <PageLine />
            <View break>
                {reports.map(report => (
                    <DeviceTile
                        chartImages={chartImages}
                        key={report.serialNumber}
                        segmentProps={segmentProperties[report.serialNumber]}
                        thresholds={thresholds}
                        data={report}
                        device={devices[report.serialNumber]}
                        unit={unit}
                        txt={txt}
                    />
                ))}
            </View>
            <ReportDetails userName={userName} dateFormat={dateFormat} txt={txt} reportId={reportId} />
            <Footer fixed>
                <Text
                    style={styles.smallText}
                    render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }): string =>
                        `${pageNumber} / ${totalPages}`
                    }
                    fixed
                />
                <SmallLogo src={BusinessLogo} />
            </Footer>
        </Body>
    </Document>
);

const logDownloadReport = (): void => {
    analyticsLogger(INSIGHT_DOWNLOADED_REPORT, { pageType: PageType.Insight, reportType: ReportType.Radon });
};

const GeneratePdf = (props: GenerateProps): React.ReactElement | null => {
    const {
        dateFormat,
        userName,
        fromDate,
        toDate,
        devices,
        chartImages,
        building,
        segmentProperties,
        thresholds,
        unit,
        reportId,
        reports,
        logo,
        devicesInEachLevel,
    } = props;
    const { t: txt } = useTranslation();
    const reportPeriodHeader = txt('RadonInsight.ReportGeneratedPeriod', { from: fromDate, to: toDate });

    const headerValues = [
        txt('ThresholdKeys.NormalLevel'),
        txt('ThresholdKeys.ActionLevel'),
        txt('ThresholdKeys.WarningLevel'),
    ];

    const pdfProps = {
        reportPeriodHeader,
        unit,
        headerValues,
        thresholds,
        chartImages,
        building,
        devices,
        segmentProperties,
        txt,
        dateFormat,
        userName,
        reports,
        reportId,
        devicesInEachLevel,
        logo,
    };

    const [properties, setProperties] = useState<PdfProps | undefined>(undefined);

    useEffect(() => {
        if (!properties) {
            setProperties(pdfProps);
        }
    }, [pdfProps]);

    if (!properties) {
        return null;
    }
    return (
        <PdfDownloadButton
            document={<PdfDocument {...properties} />}
            fileName={`RadonReport_${building.name}_${fromDate}-${toDate}.pdf`}
            allPropsReady
            onClick={logDownloadReport}
        />
    );

    // use this to get inline version of pdf.
    /* return (
        <div className="form form__wide-container">
            <PDFViewer>
                <PdfDocument {...properties} />
            </PDFViewer>
        </div>
    ); */
};

export default React.memo(GeneratePdf);
