import { takeEvery, call, put } from 'redux-saga/effects';
import {
    unsubscribeFromEmailAlertError,
    unsubscribeFromEmailAlertSuccess,
    UnsubscribeFromEmailAlert,
    UnsubscribeAlertEmailActionType,
} from '../actions/UnsubscribeAlertEmailActions';
import unsubscribeEmailAlert from '../api/unsubscribeAlertEmail';

export function* unsubscribeAlertEmailSaga({ email, id, externalAuthId }: UnsubscribeFromEmailAlert): Generator {
    try {
        yield call(unsubscribeEmailAlert, id, email, externalAuthId);
        yield put(unsubscribeFromEmailAlertSuccess());
    } catch (error) {
        yield put(unsubscribeFromEmailAlertError(error));
    }
}

export default function* unsubscribeEmailAlertSagas(): Generator {
    yield takeEvery(UnsubscribeAlertEmailActionType.UnsubscribeFromEmailAlertInit, unsubscribeAlertEmailSaga);
}
