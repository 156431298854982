import React from 'react';
import { useTranslation } from 'react-i18next';
import { PartnerOfflineDevicesResponse } from '../../../actions/partnerActions';
import { CSVFiles } from '../../../models/common';
import DownloadCsvButton from '../../status/buildingStatus/components/DownloadCsvButton';

type ParentProps = {
    offlineDevices: PartnerOfflineDevicesResponse[];
    organizationName?: string;
};

type Props = ParentProps;

export const DownloadPartnerCsvHealthStatusComponent = ({
    offlineDevices,
    organizationName,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const offlineDevicesInCustomerBuildingsCsvData = offlineDevices.flatMap(customer =>
        customer.offlineLocations.map(location => ({
            customerName: customer.customerName,
            locationName: location.locationName,
            totalHubs: location.hubs,
            offlineHubs: location.offlineHubs,
            totalSensorDevices: location.sensorDevices,
            offlineSensorDevices: location.offlineSensorDevices,
        }))
    );

    const offlineCsvData = {
        fileName: `${organizationName} - ${txt('DeviceSettings.Offline')}`,
        content: [...offlineDevicesInCustomerBuildingsCsvData],
    };

    let csvFiles: CSVFiles[] = [];
    if (offlineCsvData.content.length > 0) csvFiles = [...csvFiles, offlineCsvData];

    return <DownloadCsvButton csvFiles={csvFiles} modalDescription="BuildingStatus.DownloadOfflineDevices" />;
};

export default DownloadPartnerCsvHealthStatusComponent;
