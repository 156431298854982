import jwtDecode from 'jwt-decode';
import { takeEvery, put, call } from 'redux-saga/effects';
import { LOGOUT, logOutSuccess, logOutFailure } from '../../actions/LoginAndRegisterActions';
import { revokeDashboardRefreshTokenAccountsApi } from '../../api/accountsApiTokens';
import { getQueryParams } from '../../commonFunctions';
import config from '../../config';
import { registrationPath } from '../../constants';
import { clearStorage } from './clearStorageAndRedirect';

const setNewUrl = (): string => {
    const pathName: string | null = `${window.location.pathname}${window.location.search}`;

    const constructUrl = (params: string): string => {
        return `${config.accountsLogoutUrl}${params}`;
    };

    const stateParam: string = pathName.length > 1 ? `&state=${btoa(pathName)}` : '';
    return constructUrl(stateParam);
};

const redirect = (): void => {
    const searchQuery = window.location.search;
    const queryParams = getQueryParams(searchQuery);
    if (queryParams.inviteId && window.location.pathname === registrationPath) {
        window.location.assign(`${config.accountsRegisterUrl}&inviteId=${queryParams.inviteId}`);
    } else {
        const newUrl = setNewUrl();
        window.location.assign(newUrl);
    }
};

export function* logoutSaga(): Generator {
    try {
        // revoking token, clears user data cache:
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
            yield call(revokeDashboardRefreshTokenAccountsApi, refreshToken);
        }

        yield call(clearStorage);
        yield put(logOutSuccess());
        redirect();
    } catch (error) {
        yield put(logOutFailure('LogoutError'));
        clearStorage();
        redirect();
    }
}

export const logoutAfterIdleTime = (accessToken: string | null): void => {
    const decodedToken = accessToken && jwtDecode(accessToken);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const timeOut = decodedToken && decodedToken.idle_timeout;
    if (timeOut) {
        let timer: string | number | NodeJS.Timeout | undefined;
        const logOutUser = (): void => {
            clearStorage();
            redirect();
        };

        const resetTimer = (): void => {
            clearTimeout(timer);
            timer = setTimeout(logOutUser, timeOut * 1000); //
        };

        window.addEventListener('load', resetTimer, true);
        const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
        events.forEach(name => {
            document.addEventListener(name, resetTimer, true);
        });
    }
};

export default function* logOutSagas(): Generator {
    yield takeEvery(LOGOUT, logoutSaga);
}
