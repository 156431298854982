/* eslint max-len: ["error", 1000] */
import React from 'react';

const IconNoise = (): React.ReactElement => (
    <svg
        version="1.1"
        id="iconNoise"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        width="24px"
        height="24px"
    >
        <path d="M2 8.07144C2.59173 8.07144 3.07143 8.55114 3.07143 9.14287V14.8572C3.07143 15.4489 2.59173 15.9286 2 15.9286C1.40826 15.9286 0.92857 15.4489 0.92857 14.8572V9.14287C0.92857 8.55114 1.40826 8.07144 2 8.07144Z" />
        <path d="M7 0.928558C7.59174 0.928558 8.07143 1.40825 8.07143 1.99999V22C8.07143 22.5917 7.59174 23.0714 7 23.0714C6.40827 23.0714 5.92857 22.5917 5.92857 22V1.99999C5.92857 1.40825 6.40827 0.928558 7 0.928558Z" />
        <path d="M12 3.78571C12.5917 3.78571 13.0714 4.2654 13.0714 4.85713V19.1428C13.0714 19.7346 12.5917 20.2143 12 20.2143C11.4083 20.2143 10.9286 19.7346 10.9286 19.1428V4.85713C10.9286 4.2654 11.4083 3.78571 12 3.78571Z" />
        <path d="M17 0.928558C17.5917 0.928558 18.0714 1.40825 18.0714 1.99999V22C18.0714 22.5917 17.5917 23.0714 17 23.0714C16.4083 23.0714 15.9286 22.5917 15.9286 22V1.99999C15.9286 1.40825 16.4083 0.928558 17 0.928558Z" />
        <path d="M22 8.07144C22.5917 8.07144 23.0714 8.55114 23.0714 9.14287V14.8572C23.0714 15.4489 22.5917 15.9286 22 15.9286C21.4083 15.9286 20.9286 15.4489 20.9286 14.8572V9.14287C20.9286 8.55114 21.4083 8.07144 22 8.07144Z" />
    </svg>
);

export default IconNoise;
