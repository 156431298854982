import React, { useEffect, useState } from 'react';
import { Text, View, Document, StyleSheet, Font, Page } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { analyticsLogger, PageType, ReportType } from 'commons/src/analytics';
import { INSIGHT_DOWNLOADED_REPORT } from 'commons/src/analytics/AnalyticsEvents';
import {
    HeaderBlock,
    Logo,
    LogoContainer,
    Paragraph,
    SubHeaderNoMargin,
    Footer,
    SmallLogo,
    colorStyles,
} from 'commons/src/components/PDF/PdfConstants';
import PdfDownloadButton from 'commons/src/components/PDF/PdfDownloadButton';
import { dateFormats, sensorFullNameKey, colors } from 'commons/src/constants';
import DemiBold from 'commons/src/fonts/open-sans-v28-latin-600.ttf';
import RegularFont from 'commons/src/fonts/open-sans-v28-latin-regular.ttf';
import { SegmentProperties, BuildingType, DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import BusinessLogo from '../../../../img/logos/business-logo.png';
import { PDFContext } from '../../../../models/common';
import BuildingDetails from '../BuildingDetails';
import DeviceDetails from '../DeviceDetails';
import ReportFooter from './Footer';
import GraphTile, { styles as graphStyles } from './GraphTile';

Font.register({ family: 'DemiBold', src: DemiBold });
Font.register({ family: 'Regular', src: RegularFont });

const styles = StyleSheet.create({
    headerText: {
        fontSize: 25,
        fontFamily: 'DemiBold',
        maxWidth: '60vw',
    },
    bold: {
        fontFamily: 'DemiBold',
    },
    pageLine: {
        width: '90vw',
        height: 10,
        border: `0 solid ${colors.greyNepal}`,
        borderTopWidth: 1,
        textAlign: 'center',
        margin: '25 0 20 0',
    },
    body: {
        paddingTop: '6vw',
        paddingBottom: 65,
        paddingRight: '5vw',
        paddingLeft: '5vw',
        fontFamily: 'Regular',
    },
});

export const Body = ({ children }: { children: React.ReactElement | React.ReactElement[] }): React.ReactElement => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Page size="A4" style={styles.body}>
        {children}
    </Page>
);

type GenerateProps = {
    dateFormat: keyof typeof dateFormats;
    toDate: string;
    fromDate: string;
    chartImages: { data: string; sensorType: string }[];
    building: BuildingType;
    device: DeviceWithKeyInfo;
    segmentProperties: SegmentProperties;
    userName: string;
    logoImage?: string;
    reportId: string;
};

type Props = {
    reportPeriodHeader: string;
    segmentProperties: SegmentProperties;
    building: BuildingType;
    chartImages: { data: string; sensorType: string }[];
    device: DeviceWithKeyInfo;
    txt: PDFContext;
    dateFormat: keyof typeof dateFormats;
    userName: string;
    logoImage?: string;
    reportId: string;
};

const PdfDocument = ({
    reportPeriodHeader,
    chartImages,
    building,
    segmentProperties,
    txt,
    device,
    dateFormat,
    userName,
    logoImage,
    reportId,
}: Props): React.ReactElement => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Document>
        <Body>
            <HeaderBlock>
                <View>
                    <Text style={styles.headerText}>{txt('OutdoorInsight.ReportHeader')}</Text>
                    <SubHeaderNoMargin>{txt('OutdoorInsight.ReportSubHeader')}</SubHeaderNoMargin>
                    <Paragraph>{reportPeriodHeader}</Paragraph>
                </View>
                <LogoContainer>
                    <Logo src={logoImage || BusinessLogo} />
                </LogoContainer>
            </HeaderBlock>
            <BuildingDetails txt={txt} building={building} dateFormat={dateFormat} />
            <View style={styles.pageLine} />
            <DeviceDetails txt={txt} deviceProps={segmentProperties} device={device} />
            <View style={graphStyles.pageLineSlim} />
            <View>
                {chartImages.map(chartImage => (
                    <GraphTile
                        chartImage={chartImage}
                        graphHeader={txt(sensorFullNameKey(chartImage.sensorType))}
                        graphSubHeader={txt(`OutdoorInsight.Outdoor${chartImage.sensorType}Title`)}
                        graphDescription={txt(`OutdoorInsight.Outdoor${chartImage.sensorType}Description`)}
                        key={`graph-tile-${chartImage.sensorType}-${device.serialNumber}`}
                    />
                ))}
            </View>
            <ReportFooter
                userName={userName}
                dateFormat={dateFormat}
                txt={txt}
                reportId={reportId}
                deviceTypes={[device.type]}
            />
            <Footer fixed>
                <Text
                    style={colorStyles.smallText}
                    render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }): string =>
                        `${pageNumber} / ${totalPages}`
                    }
                    fixed
                />
                <SmallLogo src={BusinessLogo} />
            </Footer>
        </Body>
    </Document>
);

const logDownloadReport = (): void => {
    analyticsLogger(INSIGHT_DOWNLOADED_REPORT, { pageType: PageType.Insight, reportType: ReportType.Outdoor });
};

const GeneratePdf = (props: GenerateProps): React.ReactElement => {
    const {
        dateFormat,
        userName,
        fromDate,
        toDate,
        device,
        chartImages,
        building,
        segmentProperties,
        logoImage,
        reportId,
    } = props;
    const { t: txt } = useTranslation();
    const reportPeriodHeader = txt('RadonInsight.ReportGeneratedPeriod', { from: fromDate, to: toDate });

    const pdfProps = {
        reportPeriodHeader,
        chartImages,
        building,
        device,
        segmentProperties,
        txt,
        dateFormat,
        userName,
        logoImage,
        reportId,
    };

    const [canRender, setCanRender] = useState(false);

    useEffect(() => {
        let isMounted = true;

        if (chartImages && isMounted) {
            setCanRender(true);
        }
        return (): void => {
            isMounted = false;
        };
    });

    return (
        <PdfDownloadButton
            document={<PdfDocument {...pdfProps} />}
            fileName={`OutdoorReport_${building.name}_${fromDate}-${toDate}.pdf`}
            onClick={logDownloadReport}
            allPropsReady={canRender}
        />
    );
};

export default GeneratePdf;
