import React, { SyntheticEvent } from 'react';
import { roomTypeOptions, buildingTypeOptions, floorOptions } from '../../components/dropdown/commonOptions';
import Dropdown, { Option } from '../../components/dropdown/MultipleAttrDropdown';
import Input from '../../components/input/Input';
import SearchAddress from '../../components/maps/SearchAddress';
import { LocationAddressType } from '../../models/commonTypeScript';

type Props = {
    buildingType: string;
    updateAddress: (locationAddress: LocationAddressType) => void;
    address: string;
    roomType: string;
    floor: string;
    invalidAddress: boolean;
    invalidBuildingYear: boolean;
    invalidBuildingType: boolean;
    invalidRoomType: boolean;
    invalidFloor: boolean;
    onInputChange: (e: SyntheticEvent<HTMLInputElement>) => void;
    onDropdownChange: (id: string, value: string) => void;
    createDropdownOption: (term: string, context?: string | null) => Option;
    selectedOptionValue: (term: string, context: string) => string;
    getText: (term: string) => string;
};

export const LocationFormSectionComponent = (props: Props): React.ReactElement => {
    const {
        buildingType,
        roomType,
        createDropdownOption,
        floor,
        getText,
        invalidAddress,
        invalidBuildingType,
        invalidBuildingYear,
        address,
        updateAddress,
        invalidFloor,
        invalidRoomType,
        onDropdownChange,
        onInputChange,
        selectedOptionValue,
    } = props;

    const setAddress = (newAddress: LocationAddressType): void => {
        updateAddress(newAddress);
    };

    const onBuildingTypeChange = (value: Option): void => {
        onDropdownChange('buildingType', value.id);
    };

    const onFloorTypeChange = (value: Option): void => {
        onDropdownChange('floor', value.id);
    };

    const onRoomTypeChange = (value: Option): void => {
        onDropdownChange('roomType', value.id);
    };

    const roomTypes = roomTypeOptions.map(term => createDropdownOption(term, 'RoomTypeOptions'));
    const buildingTypes = buildingTypeOptions.map(term => createDropdownOption(term, 'BuildingTypeOptions'));
    const floors = floorOptions.map(term => createDropdownOption(term, 'FloorTypeOptions'));

    return (
        <div className="radon-report__form__section radon-report__form__section--border">
            <h2 className="radon-report__form__section__header">{getText('LocationFormSectionHeader')}</h2>
            <div className="location-pick-map__container input-container">
                <SearchAddress
                    loadingElement={<div className="form__attr form__attr--input" />}
                    onPlaceChanged={setAddress}
                    address={address}
                    displayValidation={invalidAddress}
                    isValid={!invalidAddress}
                />
            </div>
            <div className="radon-report__form__section__row-resp-column-collapse">
                <Dropdown
                    id="buildingSelector"
                    title="BuildingType"
                    isRequired
                    isValid={false}
                    testAttr="building-type"
                    value={selectedOptionValue(buildingType, 'BuildingTypeOptions')}
                    defaultOption="Select"
                    options={buildingTypes}
                    loading={false}
                    hint="BuildingTypeHint"
                    validate={invalidBuildingType}
                    onSelect={onBuildingTypeChange}
                />
                <Input
                    type="text"
                    id="buildingYear"
                    label="BuildingYear"
                    autoComplete="building-year"
                    maxLength={4}
                    hint="BuildingYearHint"
                    isValid={false}
                    validate={invalidBuildingYear}
                    onChange={onInputChange}
                />
            </div>
            <div className="radon-report__form__section__row-resp-column-collapse">
                <Dropdown
                    id="roomSelector"
                    title="RoomType"
                    testAttr="room-type"
                    defaultOption="Select"
                    options={roomTypes}
                    value={selectedOptionValue(roomType, 'RoomTypeOptions')}
                    loading={false}
                    hint="RoomTypeHint"
                    validate={invalidRoomType}
                    isValid={false}
                    onSelect={onRoomTypeChange}
                    isRequired
                />
                <Dropdown
                    id="floorSelector"
                    title="Floor"
                    testAttr="floor"
                    value={selectedOptionValue(floor, 'FloorTypeOptions')}
                    defaultOption="Select"
                    options={floors}
                    loading={false}
                    hint="FloorHint"
                    validate={invalidFloor}
                    isValid={false}
                    onSelect={onFloorTypeChange}
                    isRequired
                />
            </div>
        </div>
    );
};

export default LocationFormSectionComponent;
